// @flow
import React from 'react';
import { connect } from 'react-redux';
import { mapBundledPermission } from '@helpers/connect';
import { UserConfig as UserConfigView, UserConfigFormWrapper } from '../components/views';
import type { User, UserInput, BundledPermissions } from '../types';
import { userActions, logout } from '../redux/modules/context/user';
import { Loading } from '../components/elements';

type Props = {
  user: User,
  dispatch: Function,
  isLoading: boolean,
  userLoading: boolean,
  permissions: BundledPermissions,
};

class UserConfig extends React.Component<Props> {
  componentWillMount() {
    this.props.dispatch(userActions.setUserLoading(false));
  }

  render() {
    const { user, isLoading, userLoading } = this.props;

    return (
      <UserConfigFormWrapper
        user={user}
        onLogout={this.handleLogout}
        permissions={this.props.permissions}
      >
        {userLoading && <Loading />}
        {!userLoading &&
          <UserConfigView
            formData={user}
            onUpdateUser={this.handleUpdateUser}
            isLoading={isLoading}
          />
        }
      </UserConfigFormWrapper>
    );
  }

  handleLogout = () => this.props.dispatch(logout());

  handleUpdateUser = (data: UserInput) => {
    this.props.dispatch(userActions.updateUser(data));
  };
}

const mapStateToProps = (state) => ({
  user: state.context.user,
  isLoading: state.context.userUpdating || false,
  context: state.context,
  userLoading: state.context.userLoading || false,
  permissions: mapBundledPermission(state),
});

export default connect<any, any, any, any, any, any>(
  mapStateToProps,
)(UserConfig);
