// @flow
import React from 'react';
import { connect } from 'react-redux';
import { SettingsSms as SettingsSmsView } from '@views';
import type { User, BundledPermissions } from '@types';
import { logout } from '@context/user';
import { mapBundledPermission } from '@helpers/connect';

type Props = {
  user: User,
  dispatch: Function,
  permissions: BundledPermissions,
};

class SettingsSms extends React.Component<Props> {
  render() {
    const { user } = this.props;

    return (
      <SettingsSmsView
        user={user}
        onLogout={this.handleLogout}
        permissions={this.props.permissions}
      />
    );
  }
  handleLogout = () => this.props.dispatch(logout());
}

const mapStateToProps = (state) => ({
  user: state.context.user,
  permissions: mapBundledPermission(state),
});

export default connect<any, any, any, any, any, any>(
  mapStateToProps,
)(SettingsSms);
