// @flow
import React from 'react';
import { Link } from 'react-router-dom';
import { isEmail } from 'validator';
import { createNsTranslator, fSufixTwoPoints } from '@helpers/helpers';
import { withTranslation } from 'react-i18next';
import { Button, Input, Icon } from '@elements';
import type { Errors, FormElementData, AuthInProgress } from '@types';
import { ExternalPlatformSwitch } from '@blocks';
import classnames from 'classnames';
import './SignUp.scss';

type Props = {
  authErrors: Errors,
  inProgress: AuthInProgress,
  onSignupStep1: Function,
  isLoading: boolean,
  t: Function,
};

type State = {
  email: ?string,
  password: ?string,
  errorMessages: Errors,
  isSubmitted: boolean,
};
class SignUp extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      isSubmitted: false,
      errorMessages: [],
    };
  }

  render() {
    const {
      email, password, errorMessages,
      isSubmitted,
    } = this.state;
    const { authErrors, inProgress, isLoading } = this.props;

    const _ = createNsTranslator('onboarding', this.props.t);

    let errors;
    if (isSubmitted) {
      errors = errorMessages.length ? errorMessages : authErrors;
    }

    const render = (isMobile: boolean) => () => (
      <div id='signUpPage' className={classnames('horzPublicLayout', { isMobile })}>

        <div className='main'>

          {!isMobile && (
            <div className="signupHeader">
              <Link className="logo" to='/' />
              <hr className="fade" />
            </div>
          )}

          <div className="primaryCenter step1Form">
            <form className='primaryCenterContent' onSubmit={this.handleOnSubmit} method="POST">
              <h1>
                <Icon iconName='lock' className='icon' size={28} />
                <span>{_('label/create_your_account')}</span>
              </h1>
              <Input
                type='email'
                name='email'
                autoComplete='email'
                label={_('label/your_email', null, fSufixTwoPoints)}
                value={email || ''}
                onChange={this.handleInputChange.bind(this)}
              />
              <Input
                type='password'
                name='password'
                autoComplete='new-password'
                label={_('label/create_your_password', null, fSufixTwoPoints)}
                placeholder={_('placeholder/password')}
                value={password || ''}
                onChange={this.handleInputChange.bind(this)}
              />

              <span className='signupError'>{errors && errors[0]}</span>

              <div className="center">
                <Button
                  className='primary'
                  text={_('action/create_account')}
                  onClick={this.onSignupStep1}
                  disabled={inProgress}
                  isLoading={isLoading}
                  loadingMessage={_('label/sending', 'global')}
                />
              </div>
            </form>
          </div>
          <div className='center col'>
            <span>{_('label/received_invite')}</span>
            <Link to='/entrar'>{_('label/login_now')}</Link>
          </div>
        </div>
      </div>
    );

    return (
      <ExternalPlatformSwitch
        desktop={render(false)}
        mobile={render(true)}
      />
    );
  }


  handleOnSubmit = (e: Object) => {
    e.preventDefault();
    e.stopPropagation();
    this.onSignupStep1();
  };

  handleInputChange = (data: FormElementData) => {
    const { name, value } = data;
    this.setState({ [name]: value, isSubmitted: false });
  };

  onSignupStep1 = () => {
    const { email, password } = this.state;
    const errorMessages = [];
    const _ = createNsTranslator('sigonboardingup', this.props.t);

    if (!email) {
      errorMessages.push(_('error/email_empty'));
    }

    if (!password) {
      errorMessages.push(_('error/password'));
    }

    if (email && !isEmail(email)) {
      errorMessages.push(_('error/email_invalid', 'global'));
    }

    if (!errorMessages.length) {
      this.props.onSignupStep1(email, password);
      this.setState({ errorMessages: [], isSubmitted: true });
    } else {
      this.setState({ errorMessages, isSubmitted: true });
    }
  };
}

export default withTranslation()(SignUp);
