export default {
  'label/report_blocked': 'Você não tem permissão para acessar os relatórios financeiros.',
  'label/general/report_blocked': 'Você não tem permissão para acessar este relatório.',
  'menu/treatment': 'Atendimento',
  'menu/inquiries': 'Consultas',
  'menu/debts': 'Devedores',
  'menu/financial': 'Financeiro',
  'menu/payments': 'Pagamentos',
  'menu/receipts': 'Recibos',
  'menu/sms': 'SMS',
  'menu/services': 'Serviços',
  'debt/debtor_patients': 'Pacientes Devedores',
  'debt/debit_playments': 'Pagamentos em Débito',
  'debt/patients_with_negative_balance': 'Pacientes com Saldo Negativo',
  'debt/empty_title': 'Não há nenhum paciente em débito',
  'debt/empty_hint': 'Todos os pacientes devedores aparecerão automaticamente aqui.',
  'appointments/confirmed': 'Confirmadas',
  'appointments/attended_patients': 'Pacientes atendidos',
  'appointments/patient_missed': 'Paciente faltou',
  'appointments/canceled_by_patient': 'Canceladas pelo paciente',
  'appointments/canceled_by_sms': 'Canceladas por SMS',
  'appointments/canceled_by_doctor': 'Canceladas pelo consultório',
  'appointments/title': 'Relatório de Consultas',
  'filters/status/confirmed': 'Contratado',
  'filters/status/forecasted': 'Previsto',
  'filters/status/canceled': 'Cancelado',
  'filters/status/health_insurance': 'Plano de Saúde',
  'filters/label_period': 'Período',
  'filters/status': 'Status',
  'filters/fiscal_note_only': 'Somente Nota Fiscal',
  'payments/title': 'Pagamentos Recebidos',
  'payments/empty_title': 'Não há nenhum pagamento registrado na data selecionada.',
  'payments/empty_hint': 'Tente buscar uma data diferente.',
  'receipts/empty_title': 'Não há nenhum recibo na data selecionada.',
  'receipts/empty_hint': 'Tente buscar uma data diferente.',
  'receipts/receipts': 'Recibos',
  'receipts/title': 'Lista de Recibos',
  'services/title': 'Serviços',
  'services/max_limit_reached': 'O período selecionado possui muitos serviços. Mostrando apenas {maxLimit} serviços.',
  'services/empty_title': 'Não há nenhum serviço contratado na data selecionada.',
  'services/empty_hint': 'Tente buscar uma data diferente.',
  'services/hired_list': 'Lista de Serviços Contratados',
  'sms/package': 'Pacote',
  'sms/sent': 'SMS Enviados',
  'sms/remaining_sms_balance': 'Saldo Restante de SMS',
  'sms/total_sent_sms': 'Total de SMS Enviados',
  'sms/total_contrated_sms': 'Total de SMS Contratados',
  'sms/not_contracted_message': 'Você não comprou nenhum pacote de SMS. Para saber mais,',
  'sms/contracted_sms_packages': 'Pacotes de SMS Contratados',
  'sms/sent_report': 'Relatório de Envios',
  'sms/messages_per_sms': 'Mensagens por SMS',
};
