import agenda from './agenda';
import alerts from './alerts';
import constants from './constants';
import financial from './financial';
import global from './global';
import layout from './layout';
import myPlan from './myPlan';
import onboarding from './onboarding';
import patientList from './patientList';
import patientProfile from './patientProfile';
import reports from './reports';
import settings from './settings';
import subscription from './subscription';
import timeline from './timeline';
import indicators from './indicators';
import toasts from './toasts';
import deleteMyAccount from './deleteMyAccount';

export default {
  agenda,
  alerts,
  constants,
  financial,
  global,
  layout,
  my_plan: myPlan,
  onboarding,
  patient_list: patientList,
  patientProfile,
  reports,
  settings,
  subscription,
  timeline,
  indicators,
  toasts,
  deleteMyAccount,
};
