// @flow
import React from 'react';
import { get } from 'lodash';
import { connect } from 'react-redux';
import { PatientIndicatorsGraphModal as PatientIndicatorsGraphModalView } from '@views';
import { closeActiveModal } from '@context/modals';

type Props = {
  dispatch: Function,
  modalData: any,
};

class PatientIndicatorsGraphModal extends React.Component<Props> {
  render() {
    const { modalData } = this.props;
    return (
      <PatientIndicatorsGraphModalView
        onCloseModal={this.handleCloseModal}
        modalData={modalData}
      />
    );
  }
  handleCloseModal = () => {
    this.props.dispatch(closeActiveModal());
  };
}

const mapStateToProps = (state) => ({
  modalData: get(state, ['context', 'modals', 'data'], {}),
});

export default connect<any, any, any, any, any, any>(mapStateToProps)(PatientIndicatorsGraphModal);



