// @flow

export const periodsOptions = [
  // { value: 'future', _locale: 'constants:periods/future' },
  { value: 'today', _locale: 'constants:periods/today' },
  { value: 'yesterday', _locale: 'constants:periods/yesterday' },
  { value: 'this_month', _locale: 'constants:periods/null' },
  { value: 'last_month', _locale: 'constants:periods/last_month' },
  { value: 'custom_month', _locale: 'constants:periods/custom_month' },
  { value: 'custom', _locale: 'constants:periods/custom_date' },
];

export const DOCTOR_SELECTOR_SEARCH_EDGE = 7;

export const brStates = [
  { text: 'AC', value: 'AC' },
  { text: 'AL', value: 'AL' },
  { text: 'AP', value: 'AP' },
  { text: 'AM', value: 'AM' },
  { text: 'BA', value: 'BA' },
  { text: 'CE', value: 'CE' },
  { text: 'DF', value: 'DF' },
  { text: 'ES', value: 'ES' },
  { text: 'GO', value: 'GO' },
  { text: 'MA', value: 'MA' },
  { text: 'MT', value: 'MT' },
  { text: 'MS', value: 'MS' },
  { text: 'MG', value: 'MG' },
  { text: 'PA', value: 'PA' },
  { text: 'PB', value: 'PB' },
  { text: 'PR', value: 'PR' },
  { text: 'PE', value: 'PE' },
  { text: 'PI', value: 'PI' },
  { text: 'RJ', value: 'RJ' },
  { text: 'RN', value: 'RN' },
  { text: 'RS', value: 'RS' },
  { text: 'RO', value: 'RO' },
  { text: 'RR', value: 'RR' },
  { text: 'SC', value: 'SC' },
  { text: 'SP', value: 'SP' },
  { text: 'SE', value: 'SE' },
  { text: 'TO', value: 'TO' },
];

export const weekDays = [
  { value: 1, _locale: 'constants:weekday/monday' },
  { value: 2, _locale: 'constants:weekday/tuesday' },
  { value: 3, _locale: 'constants:weekday/wednesday' },
  { value: 4, _locale: 'constants:weekday/thursday' },
  { value: 5, _locale: 'constants:weekday/friday' },
  { value: 6, _locale: 'constants:weekday/saturday' },
  { value: 7, _locale: 'constants:weekday/sunday' },
];

export const months = [
  { value: '1', _locale: 'constants:months/january' },
  { value: '2', _locale: 'constants:months/february' },
  { value: '3', _locale: 'constants:months/march' },
  { value: '4', _locale: 'constants:months/april' },
  { value: '5', _locale: 'constants:months/may' },
  { value: '6', _locale: 'constants:months/june' },
  { value: '7', _locale: 'constants:months/july' },
  { value: '8', _locale: 'constants:months/august' },
  { value: '9', _locale: 'constants:months/september' },
  { value: '10', _locale: 'constants:months/october' },
  { value: '11', _locale: 'constants:months/november' },
  { value: '12', _locale: 'constants:months/december' },
];

export const years = [
  { text: '2024', value: '2024' },
  { text: '2023', value: '2023' },
  { text: '2022', value: '2022' },
  { text: '2021', value: '2021' },
  { text: '2020', value: '2020' },
  { text: '2019', value: '2019' },
  { text: '2018', value: '2018' },
  { text: '2017', value: '2017' },
  { text: '2016', value: '2016' },
  { text: '2015', value: '2015' },
  { text: '2014', value: '2014' },
];

export const durations = [
  { text: '10 min', value: 10 },
  { text: '15 min', value: 15 },
  { text: '20 min', value: 20 },
  { text: '25 min', value: 25 },
  { text: '30 min', value: 30 },
  { text: '40 min', value: 40 },
  { text: '45 min', value: 45 },
  { text: '50 min', value: 50 },
  { text: '1h', value: 60 },
  { text: '1h 15min', value: 75 },
  { text: '1h 20min', value: 80 },
  { text: '1h 30min', value: 90 },
  { text: '1h 40min', value: 100 },
  { text: '1h 45min', value: 105 },
  { text: '2h', value: 120 },
  { text: '2h 15min', value: 135 },
  { text: '2h 30min', value: 150 },
  { text: '2h 45min', value: 165 },
  { text: '3h', value: 180 },
  { text: '3h 30min', value: 210 },
  { text: '4h', value: 240 },
  { text: '4h 30min', value: 270 },
  { text: '5h', value: 300 },
  { text: '5h 30min', value: 330 },
  { text: '6h', value: 360 },
  { text: '7h', value: 420 },
  { text: '8h', value: 480 },
  { text: '9h', value: 540 },
  { text: '10h', value: 600 },
  { text: '11h', value: 660 },
  { text: '12h', value: 720 },
  { text: '24h', value: 1440 },
];

export const paymentMethods = [
  { value: 'money', _locale: 'constants:payments_type/money' },
  { value: 'bank_transfer', _locale: 'constants:payments_type/bank_transfer' },
  { value: 'debit_card', _locale: 'constants:payments_type/debit_card' },
  { value: 'credit_card', _locale: 'constants:payments_type/credit_card' },
  { value: 'check', _locale: 'constants:payments_type/check' },
];

export const paymentMethodsMap = {
  money: 'constants:payments_type/money',
  bank_transfer: 'constants:payments_type/bank_transfer',
  credit_card: 'constants:payments_type/credit_card',
  debit_card: 'constants:payments_type/debit_card',
  check: 'constants:payments_type/check',
};

export const patientMaritalStatuses = [
  { value: '1', _locale: 'constants:martial_status/not_married' },
  { value: '2', _locale: 'constants:martial_status/married' },
  { value: '3', _locale: 'constants:martial_status/separate' },
  { value: '4', _locale: 'constants:martial_status/divorced' },
  { value: '5', _locale: 'constants:martial_status/widower' },
  { value: '6', _locale: 'constants:martial_status/stable_union' },
];

export const professionCodes = [
  // Grupo 1 - Dentista
  { value: 101, _locale: 'constants:profession/dental_surgeon' },
  { value: 102, _locale: 'constants:profession/bucomaxillofacial_surgeon' },
  { value: 103, _locale: 'constants:profession/general_clinical_dentist' },
  { value: 104, _locale: 'constants:profession/aesthetic_dentistry' },
  { value: 105, _locale: 'constants:profession/endodontist' },
  { value: 106, _locale: 'constants:profession/implantodontist' },
  { value: 107, _locale: 'constants:profession/pediatric_dentistry' },
  { value: 108, _locale: 'constants:profession/orthodontist' },
  { value: 109, _locale: 'constants:profession/periodontist' },
  { value: 110, _locale: 'constants:profession/protester' },
  // Grupo 2 - Médico
  { value: 201, _locale: 'constants:profession/general_practitioner' },
  { value: 202, _locale: 'constants:profession/allergist_and_immunologist' },
  { value: 203, _locale: 'constants:profession/anesthesiologist' },
  { value: 204, _locale: 'constants:profession/angiologist' },
  { value: 205, _locale: 'constants:profession/cardiologist' },
  { value: 206, _locale: 'constants:profession/cardiovascular_surgeon' },
  { value: 207, _locale: 'constants:profession/head_and_neck_surgeon' },
  { value: 208, _locale: 'constants:profession/digestive_system_surgeon' },
  { value: 209, _locale: 'constants:profession/general_surgeon' },
  { value: 210, _locale: 'constants:profession/pediatric_surgeon' },
  { value: 211, _locale: 'constants:profession/plastic_surgeon' },
  { value: 212, _locale: 'constants:profession/thoracic_surgeon' },
  { value: 213, _locale: 'constants:profession/general_clinic' },
  { value: 214, _locale: 'constants:profession/coloproctologist' },
  { value: 215, _locale: 'constants:profession/dermatologist' },
  { value: 216, _locale: 'constants:profession/endocrinologist' },
  { value: 217, _locale: 'constants:profession/physiatrist' },
  { value: 218, _locale: 'constants:profession/gastroenterologist' },
  { value: 219, _locale: 'constants:profession/geneticist' },
  { value: 220, _locale: 'constants:profession/geriatra' },
  { value: 221, _locale: 'constants:profession/gynecologist' },
  { value: 222, _locale: 'constants:profession/hematologist' },
  { value: 223, _locale: 'constants:profession/homeopath' },
  { value: 224, _locale: 'constants:profession/infectologist' },
  { value: 225, _locale: 'constants:profession/mastologist' },
  { value: 240, _locale: 'constants:profession/sports_doctor' },
  { value: 226, _locale: 'constants:profession/doctor_of_labor' },
  { value: 227, _locale: 'constants:profession/nephrologist' },
  { value: 228, _locale: 'constants:profession/neurosurgeon' },
  { value: 229, _locale: 'constants:profession/neurologist' },
  { value: 230, _locale: 'constants:profession/nutrologo' },
  { value: 231, _locale: 'constants:profession/ophthalmologist' },
  { value: 232, _locale: 'constants:profession/oncologist' },
  { value: 233, _locale: 'constants:profession/orthopaedist_and_traumatologist' },
  { value: 234, _locale: 'constants:profession/otorhinolaryngologist' },
  { value: 235, _locale: 'constants:profession/pediatrician' },
  { value: 236, _locale: 'constants:profession/pneumologist' },
  { value: 237, _locale: 'constants:profession/psychiatrist' },
  { value: 241, _locale: 'constants:profession/radiologist' },
  { value: 238, _locale: 'constants:profession/rheumatologist' },
  { value: 239, _locale: 'constants:profession/urologist' },
  // Grupo 3 - Outros
  { value: 301, _locale: 'constants:profession/other_profession' },
  { value: 302, _locale: 'constants:profession/acupuncturist' },
  { value: 303, _locale: 'constants:profession/social_worker' },
  { value: 315, _locale: 'constants:profession/biomedical' },
  { value: 317, _locale: 'constants:profession/physical_educator' },
  { value: 319, _locale: 'constants:profession/pharmaceutical' },
  { value: 317, _locale: 'constants:profession/physical_educator' },
  { value: 316, _locale: 'constants:profession/physiologist' },
  { value: 304, _locale: 'constants:profession/physiotherapist' },
  { value: 305, _locale: 'constants:profession/speech_language_pathologist' },
  { value: 306, _locale: 'constants:profession/beautician' },
  { value: 307, _locale: 'constants:profession/nutritionist' },
  { value: 308, _locale: 'constants:profession/psychologist' },
  { value: 318, _locale: 'constants:profession/psychopedagogue' },
  { value: 309, _locale: 'constants:profession/quiropraxista' },
  { value: 310, _locale: 'constants:profession/nursing_technician' },
  { value: 311, _locale: 'constants:profession/oral_health_technician' },
  { value: 314, _locale: 'constants:profession/natural_therapist' },
  { value: 312, _locale: 'constants:profession/occupational_therapist' },
  { value: 313, _locale: 'constants:profession/veterinarian' },
  { value: 399, _locale: 'constants:profession/secretary_or_administrator' },
];


export const indicatorColors = [
  {
    // text: 'Azul',
    value: 1,
    color: 'rgb(52, 170, 220)',
    _locale: 'constants:colors/blue',
  },
  {
    // text: 'Verde',
    value: 2,
    color: 'rgb(101, 219, 57)',
    _locale: 'constants:colors/green',
  },
  {
    // text: 'Laranja',
    value: 3,
    color: 'rgb(255, 149, 0)',
    _locale: 'constants:colors/orange',
  },
  {
    // text: 'Marrom',
    value: 4,
    color: 'rgb(162, 132, 94)',
    _locale: 'constants:colors/brown',
  },
  {
    // text: 'Rosa',
    value: 5,
    color: 'rgb(255, 45, 85)',
    _locale: 'constants:colors/pink',
  },
  {
    // text: 'Violeta',
    value: 6,
    color: 'rgb(204, 115, 225)',
    _locale: 'constants:colors/violet',
  },
  {
    // text: 'Amarelo',
    value: 7,
    color: 'rgb(255, 204, 0)',
    _locale: 'constants:colors/yellow',
  },
  {
    // text: 'Azul Escuro',
    value: 8,
    color: 'rgb(3, 155, 229)',
    _locale: 'constants:colors/dark_blue',
  },
  {
    // text: 'Verde Escuro',
    value: 9,
    color: 'rgb(67, 160, 71)',
    _locale: 'constants:colors/dark_green',
  },
  {
    // text: 'Laranja Escuro',
    value: 10,
    color: 'rgb(251, 140, 0)',
    _locale: 'constants:colors/dark_orange',
  },
  {
    // text: 'Marrom Escuro',
    value: 11,
    color: 'rgb(121, 85, 72)',
    _locale: 'constants:colors/dark_brown',
  },
  {
    // text: 'Rosa Escuro',
    value: 12,
    color: 'rgb(233, 30, 99)',
    _locale: 'constants:colors/dark_pink',
  },
  {
    // text: 'Violeta Escuro',
    value: 13,
    color: 'rgb(156, 39, 176)',
    _locale: 'constants:colors/dark_violet',
  },
  {
    // text: 'Amarelo Escuro',
    value: 14,
    color: 'rgb(251, 192, 45)',
    _locale: 'constants:colors/dark_yellow',
  },
  {
    // text: 'Cinza',
    value: 15,
    color: 'rgb(158, 158, 158)',
    _locale: 'constants:colors/grey',
  },
];


export const pdfFonts = [
  { text: 'Arial', value: 'font_arial', className: 'font_pdf_arial', fontFamily: 'Arial, sans-serif' },
  { text: 'Tinos', value: 'font_tinos', className: 'font_pdf_tinos', fontFamily: 'Tinos, sans-serif' },
  { text: 'Lato', value: 'font_lato', className: 'font_pdf_lato', fontFamily: 'Lato, sans-serif' },
  { text: 'Montserrat', value: 'font_montserrat', className: 'font_pdf_montserrat', fontFamily: 'Montserrat, sans-serif' },
  { text: 'Quicksand', value: 'font_quicksand', className: 'font_pdf_quicksand', fontFamily: 'Quicksand, sans-serif' },
  { text: 'Roboto', value: 'font_roboto', className: 'font_pdf_roboto', fontFamily: 'Roboto, sans-serif' },
];

export const professions = {
  main: [
    { code: 's200', _locale: 'constants:profession/medic' },
    { code: 's100', _locale: 'constants:profession/dentist' },
    { code: 308, _locale: 'constants:profession/psychologist' },
    { code: 304, _locale: 'constants:profession/physiotherapist' },
    { code: 313, _locale: 'constants:profession/veterinarian' },
    { code: 306, _locale: 'constants:profession/beautician' },
    { code: 305, _locale: 'constants:profession/speech_language_pathologist' },
    { code: 's300', _locale: 'constants:profession/other_profession' },
  ],
  specializations: {
    s100: [
      { code: 102, _locale: 'constants:profession/bucomaxillofacial_surgeon' },
      { code: 101, _locale: 'constants:profession/dental_surgeon' },
      { code: 103, _locale: 'constants:profession/general_clinical_dentist' },
      { code: 104, _locale: 'constants:profession/aesthetic_dentistry' },
      { code: 105, _locale: 'constants:profession/endodontist' },
      { code: 106, _locale: 'constants:profession/implantodontist' },
      { code: 107, _locale: 'constants:profession/pediatric_dentistry' },
      { code: 108, _locale: 'constants:profession/orthodontist' },
      { code: 109, _locale: 'constants:profession/periodontist' },
      { code: 110, _locale: 'constants:profession/protester' },
    ],
    s200: [
      { code: 201, _locale: 'constants:profession/general_practitioner' },
      { code: 202, _locale: 'constants:profession/allergist_and_immunologist' },
      { code: 203, _locale: 'constants:profession/anesthesiologist' },
      { code: 204, _locale: 'constants:profession/angiologist' },
      { code: 205, _locale: 'constants:profession/cardiologist' },
      { code: 206, _locale: 'constants:profession/cardiovascular_surgeon' },
      { code: 207, _locale: 'constants:profession/head_and_neck_surgeon' },
      { code: 208, _locale: 'constants:profession/digestive_system_surgeon' },
      { code: 209, _locale: 'constants:profession/general_surgeon' },
      { code: 210, _locale: 'constants:profession/pediatric_surgeon' },
      { code: 211, _locale: 'constants:profession/plastic_surgeon' },
      { code: 212, _locale: 'constants:profession/thoracic_surgeon' },
      { code: 213, _locale: 'constants:profession/general_clinic' },
      { code: 214, _locale: 'constants:profession/coloproctologist' },
      { code: 215, _locale: 'constants:profession/dermatologist' },
      { code: 216, _locale: 'constants:profession/endocrinologist' },
      { code: 217, _locale: 'constants:profession/physiatrist' },
      { code: 218, _locale: 'constants:profession/gastroenterologist' },
      { code: 219, _locale: 'constants:profession/geneticist' },
      { code: 220, _locale: 'constants:profession/geriatra' },
      { code: 221, _locale: 'constants:profession/gynecologist' },
      { code: 222, _locale: 'constants:profession/hematologist' },
      { code: 223, _locale: 'constants:profession/homeopath' },
      { code: 224, _locale: 'constants:profession/infectologist' },
      { code: 225, _locale: 'constants:profession/mastologist' },
      { code: 240, _locale: 'constants:profession/sports_doctor' },
      { code: 226, _locale: 'constants:profession/doctor_of_labor' },
      { code: 227, _locale: 'constants:profession/nephrologist' },
      { code: 228, _locale: 'constants:profession/neurosurgeon' },
      { code: 229, _locale: 'constants:profession/neurologist' },
      { code: 230, _locale: 'constants:profession/nutrologo' },
      { code: 231, _locale: 'constants:profession/ophthalmologist' },
      { code: 232, _locale: 'constants:profession/oncologist' },
      { code: 233, _locale: 'constants:profession/orthopaedist_and_traumatologist' },
      { code: 234, _locale: 'constants:profession/otorhinolaryngologist' },
      { code: 235, _locale: 'constants:profession/pediatrician' },
      { code: 236, _locale: 'constants:profession/pneumologist' },
      { code: 237, _locale: 'constants:profession/psychiatrist' },
      { code: 241, _locale: 'constants:profession/radiologist' },
      { code: 238, _locale: 'constants:profession/rheumatologist' },
      { code: 239, _locale: 'constants:profession/urologist' },
    ],
    s300: [
      { code: 302, _locale: 'constants:profession/acupuncturist' },
      { code: 303, _locale: 'constants:profession/social_worker' },
      { code: 315, _locale: 'constants:profession/biomedical' },
      { code: 317, _locale: 'constants:profession/physical_educator' },
      { code: 316, _locale: 'constants:profession/physiologist' },
      { code: 304, _locale: 'constants:profession/physiotherapist' },
      { code: 305, _locale: 'constants:profession/speech_language_pathologist' },
      { code: 306, _locale: 'constants:profession/beautician' },
      { code: 307, _locale: 'constants:profession/nutritionist' },
      { code: 308, _locale: 'constants:profession/psychologist' },
      { code: 309, _locale: 'constants:profession/quiropraxista' },
      { code: 310, _locale: 'constants:profession/nursing_technician' },
      { code: 311, _locale: 'constants:profession/oral_health_technician' },
      { code: 314, _locale: 'constants:profession/natural_therapist' },
      { code: 312, _locale: 'constants:profession/occupational_therapist' },
      { code: 313, _locale: 'constants:profession/veterinarian' },
      { code: 399, _locale: 'constants:profession/secretary_or_administrator' },
      { code: 301, _locale: 'constants:profession/other_profession' },
    ],
  },
};

export const countryLanguages = {
  BR: 'pt-BR',
  AO: 'pt-BR',
  // BE: 'pt-BR',
  BO: 'es',
  AR: 'es',
  CL: 'es',
  CO: 'es',
  DO: 'es',
  EC: 'es',
  ES: 'es',
  MX: 'es',
  PA: 'es',
  PE: 'es',
  PT: 'pt-BR',
};

export const OFFLINE_SEARCH_EDGE = 10;
