// @flow
import React from 'react';
import { connect } from 'react-redux';
import { get } from 'lodash';
import { router } from '../routes/router';
import { SignUpClinic as SignUpClinicView } from '../components/views';
import { actions as authActions } from '../redux/modules/auth';
import type { AuthState, Dispatch } from '../types';

type Props = {
  auth: AuthState,
  dispatch: Function,
  userId: number,
  isLoading: boolean,
};

class SignUpClinic extends React.Component<Props> {
  componentWillMount() {
    const { dispatch } = this.props;
    dispatch(authActions.resetSignUpState());
  }

  componentWillReceiveProps(nextProps: Props) {
    if (nextProps.auth.inProgress) {
      // Redirect on signup final step (step 4) initialization.
      this.props.dispatch(router.pushPath('root'));
    }
  }
  render() {
    const { auth, isLoading } = this.props;

    return (
      <SignUpClinicView
        authErrors={get(auth, ['errors'])}
        onSignupStep4={this.handleSignupStep4}
        isLoading={isLoading}
      />
    );
  }

  handleSignupStep4 = (clinicSize: any) => {
    const { userId } = this.props;
    this.props.dispatch(authActions.signupStep4({ userId, clinicSize }));
  };
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  userId: get(state, ['context', 'user', 'id']),
  isLoading: get(state, ['auth', 'inProgress']),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({ dispatch });

export default connect<any, any, any, any, any, any>(
  mapStateToProps,
  mapDispatchToProps,
)(SignUpClinic);
