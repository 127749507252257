// @flow
import React from 'react';
import { get } from 'lodash';
import { connect } from 'react-redux';
import { ReportsAppointments as ReportsAppointmentsView } from '@views';
import { getReportsAppointments, getReportsAppointmentsDownload } from '@context/reportsAppointments';
import { mapPlanIncludes, mapPermissionTo } from '@helpers/connect';
import type { DoctorsState, ReportsAppointmentsState, Dispatch, PlanInclude, PermissionTo } from '@types';

type Props = {
  dispatch: Function,
  doctors: DoctorsState,
  reportData: ReportsAppointmentsState,
  defaultDoctorId: number,
  isManager: boolean,
  planInclude: PlanInclude,
  permissionTo : PermissionTo,
}

class ReportsAppointments extends React.Component<Props> {
  render() {
    const {
      doctors, reportData, defaultDoctorId, isManager, planInclude, permissionTo,
    } = this.props;

    return (
      <ReportsAppointmentsView
        onGetReport={this.handleGetReport}
        doctors={doctors}
        data={get(reportData, 'data', {})}
        isLoading={get(reportData, 'isLoading', false)}
        defaultDoctorId={defaultDoctorId}
        isManager={isManager}
        isFeatureEnabled={planInclude.financial}
        reportsSelectDoctor={permissionTo.reportsSelectDoctor}
        isReportBlocked={!permissionTo.reports}
        onDownloadReport={this.handleDownloadReport}
      />
    );
  }

  handleGetReport = (startDate: string, endDate: string, doctorId: number | null) => {
    const { dispatch } = this.props;
    dispatch(getReportsAppointments(startDate, endDate, doctorId));
  };

  handleDownloadReport = (startDate: string, endDate: string, doctorId: number | null) => {
    const { dispatch } = this.props;
    dispatch(getReportsAppointmentsDownload(startDate, endDate, doctorId));
  };
}

const mapStateToProps = (state) => ({
  doctors: get(state, ['context', 'doctors'], {}),
  reportData: get(state, ['context', 'reportsAppointments'], {}),
  defaultDoctorId: get(state, ['context', 'user', 'advancedData', 'doctorId']),
  isManager: get(state, ['context', 'user', 'advancedData', 'manager'], false),
  planInclude: mapPlanIncludes(state),
  permissionTo: mapPermissionTo(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({ dispatch });

export default connect<any, any, any, any, any, any>(mapStateToProps, mapDispatchToProps)(ReportsAppointments);
