// @flow
import React from 'react';
import AppStatusBar from '../AppStatusBar/AppStatusBar';


const AppMobileNoHeader = () => (
  <AppStatusBar color="white" prevColor="default" />
);


export default AppMobileNoHeader;
