export default {
  'label/basic_data': 'Dados Básicos',
  'label/name': 'Nome',
  'label/gender': 'Sexo',
  'label/birth_date': 'Data de Nascimento',
  'label/cpf': 'CPF',
  'label/legal_id': 'Identidade',
  'label/n_legal_id': 'Nº de Identidade',
  'label/profession': 'Profissão',
  'label/place_of_birth': 'Naturalidade',
  'label/marital_status': 'Estado Civil',
  'label/responsible_doctor': 'Responsável',
  'label/recommend_by': 'Indicação',
  'label/code': 'Código',
  'label/health_isurance': 'Plano de Saúde',
  'label/insurance_company': 'Convênio',
  'label/insurance_plan': 'Plano',
  'label/insurance_number': 'Número da Carteira',
  'label/insurance_expiration_date': 'Data de Validade',
  'label/mobile_phone': 'Celular',
  'label/home_phone': 'Telefone Fixo',
  'label/other_phone': 'Telefone Adicional',
  'label/email': 'E-mail',
  'label/instagram': 'Instagram',
  'label/father_and_mother': 'Pai e Mãe',
  'label/father': 'Pai',
  'label/mother': 'Mãe',
  'label/guardian_name': 'Responsável',
  'label/guardian_and_relationship': 'Responsável e Parentesco',
  'label/guardian_relationship': 'Parentesco',
  'label/additional_information': 'Informações Adicionais',
  'label/patient_profile_title': 'Dados de Cadastro',
  'label/address': 'Endereço',
  'label/contact': 'Contato',
  'label/adress_zipcode': 'CEP',
  'hint/incomplete': 'Esse paciente foi criado da agenda e está com cadastro incompleto.',
  'hint/double': 'Esse paciente foi marcado como duplicado.',
  'action/delete_patient': 'Excluir Paciente',
  'label/patientform/basic_data': 'Dados Básicos',
  'label/patientform/contacts': 'Contato',
  'label/patientform/address': 'Endereço',
  'label/patientform/insurance': 'Plano de Saúde',
  'label/patientform/notes': 'Informações Adicionais',
  'label/patientform/edit_patient': 'Editar Paciente',
  'label/patientform/new_patient': 'Novo Paciente',
};
