// @flow
import React from 'react';
import classnames from 'classnames';
import './Radio.scss';

type Props = {
  label?: string,
  className?: string,
};

export default class Radio extends React.Component<Props> {
  render() {
    const {
      label, className,
    } = this.props;

    const cssClasses = classnames(['radio', className]);

    return (
      <div className={cssClasses}>
        <input type="radio" /> {label}
      </div>
    );
  }
}
