import React from 'react';
import { PlatformSwitch, BlockedFeature, AppMobileHeader, ReportsMenu } from '@blocks';
import { useTranslationNs } from '@helpers/helpers';


type Props = {
  backLinkTo: string,
  title?: string,
};

const BlockedReport = ({ backLinkTo, title }: Props) => {
  const [_] = useTranslationNs('reports');

  return (
    <div id="blockedReport" className="appContent reportsPage">
      <PlatformSwitch
        inject
        className="platformSwitcher"
        desktop={() => (
          <React.Fragment>
            <ReportsMenu />
            <section className="mainSection">
              <BlockedFeature
                simpleStyle
                hideUpgradeButton
                customMessage={_('label/general/report_blocked')}
                hint={_('label/blocked/hint/global', 'global')}
                iconName="info"
              />
            </section>
          </React.Fragment>
        )}
        mobile={() => (
          <React.Fragment>
            <AppMobileHeader title={title} backLinkTo={backLinkTo} />
            <BlockedFeature
              simpleStyle
              hideUpgradeButton
              customMessage={_('label/general/report_blocked')}
              hint={_('label/blocked/hint/global', 'global')}
              iconName="info"
            />
          </React.Fragment>
        )}
      />
    </div>
  );
};


export default BlockedReport;
