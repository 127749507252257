// @flow
import React from 'react';
import classNames from 'classnames';
import { get, includes, map, toString, set, isArray } from 'lodash';
import {
  Input, RadioGroup, TextArea, CheckboxGroup, SelectBox,
} from '@elements';


type Props = {
  templateFields: Object,
  fieldsValues: Object,
  handleChangeCheckboxGroup: Function,
  handleFormChange: Function,
  handleRadioGroupChange: Function,
  isDeleting: boolean,
  isSaving: boolean,
};


const CustomFormFields = ({
  fieldsValues, templateFields, handleChangeCheckboxGroup, handleFormChange,
  handleRadioGroupChange, isDeleting, isSaving }: Props) => map<any, any>(templateFields, (field, key) => {
    let items;
    let value;
    let className;
    const { conditionalFieldId, conditionalFieldValue } = field;
    if (conditionalFieldId && conditionalFieldValue) {
      const storedConditionalFieldValue = get(fieldsValues, toString(conditionalFieldId), []);
      const conditionMet = includes(storedConditionalFieldValue, conditionalFieldValue)
      || (isArray(storedConditionalFieldValue) && conditionalFieldValue === '__any__' && storedConditionalFieldValue.length > 0);
      if (conditionMet) {
        className = 'show';
      } else {
        className = 'hide';
        return null;
      }
    } else {
      className = 'show';
    }


    const itemKey = `${toString(field.id)}:${key}`;

    // common input props
    const inputProps = {
      name: toString(field.id),
      className,
    };

    const fieldClassNames = classNames({
      isConditionalField: !!conditionalFieldId,
    }, className);


    if (field.label) {
      if (field.type === 'check_box') { // checkbox group takes groupLabel prop
        set(inputProps, ['groupLabel'], field.label);
      } else {
        set(inputProps, ['label'], field.label);
      }
    }
    if (field.unit) {
      set(inputProps, ['unit'], field.unit);
    }


    switch (field.type) {
      case 'text_field':
        value = get(fieldsValues, [toString(field.id)], '');
        return (
          <TextArea
            key={itemKey}
            uncontrolled
            {...inputProps}
            defaultValue={value}
            onChange={handleFormChange}
            disabled={isSaving || isDeleting}
            className={fieldClassNames}
          />
        );

      case 'text_area':
        value = get(fieldsValues, [toString(field.id)], '');
        return (
          <TextArea
            uncontrolled
            {...inputProps}
            key={itemKey}
            defaultValue={value}
            onChange={handleFormChange}
            disabled={isSaving || isDeleting}
            className={fieldClassNames}
          />
        );

      case 'number_field':
        value = get(fieldsValues, [toString(field.id)], '');
        return (
          <div className="numberField">
            <Input
              uncontrolled
              {...inputProps}
              key={itemKey}
              type="text"
              defaultValue={value}
              onChange={handleFormChange}
              disabled={isSaving || isDeleting}
              className={fieldClassNames}
            />
          </div>
        );

      case 'check_box':
        value = get(fieldsValues, [toString(field.id)], []);
        items = map<any, any>(field.values, (val) => ({ label: val, value: val }));
        return (
          <CheckboxGroup
            uncontrolled
            {...inputProps}
            key={itemKey}
            id={field.id}
            items={items}
            checked={value}
            onChange={handleChangeCheckboxGroup(field.id)}
            disabled={isSaving || isDeleting}
            className={classNames(fieldClassNames, 'checkBox')}
          />
        );

      case 'select_tag':
        items = map<any, any>(field.values, (val) => ({ text: val, value: val }));
        value = get(fieldsValues, [toString(field.id)], null);
        return (
          <SelectBox
            usePortal
            uncontrolled
            {...inputProps}
            key={itemKey}
            options={items}
            defaultValue={value}
            name={toString(field.id)}
            onChange={handleFormChange}
            disabled={isSaving || isDeleting}
            className={fieldClassNames}
          />
        );

      case 'radio_group':
        items = map<any, any>(field.values, (val) => ({
          text: val,
          value: val,
        }));
        value = get(fieldsValues, [toString(field.id)], null);
        return (
          <RadioGroup
            uncontrolled
            key={itemKey}
            {...inputProps}
            id={field.id}
            selectGroupName={`customForm-${field.id}`}
            items={items}
            defaultValue={value}
            onChange={handleRadioGroupChange(field.id)}
            disabled={isSaving || isDeleting}
            className={classNames(fieldClassNames, 'radioGroup', { multitensRadioGroup: items.length > 3 })}
          />
        );
      case 'header':
        return (
          <div
            key={itemKey}
            className="headerHolder"
          >
            <span id={`header-${field.id}`}>{field.label}</span>
          </div>
        );

      default:
        return <div key={itemKey} />;
    }
  });

// $FlowFixMe
export default React.memo(CustomFormFields);
