// @flow
import React from 'react';
import moment from 'moment';
import { get, isString, map, toString } from 'lodash';
import { connect } from 'react-redux';
import { PatientsList as PatientsListView } from '@views';
import { actions } from '@context/modals';
import { patientActions, searchPatients } from '@context/patients';
import { tagTemplateActions } from '@context/tagTemplates';
import type { DoctorsState, PatientsState, Dispatch, TagTemplateState, Clinic, PatientsPaginationMeta, PermissionTo, PatientFiltersState } from '@types';
import { mapPermissionTo } from '@helpers/connect';
import { createNsTranslator, isPresent } from '@helpers/helpers';
import { withTranslation } from 'react-i18next';

type Props = {
  doctors: DoctorsState,
  patients: PatientsState,
  dispatch: Function,
  tagTemplates: TagTemplateState,
  clinic: Clinic,
  orderDoctorsByProfession: boolean,
  pagination: PatientsPaginationMeta,
  pageMap: Object,
  permissionTo: PermissionTo,
  t: Function,
  filters: PatientFiltersState,
  userWithoutClinic: boolean,
};

type State = {
  activeSearch: ?string,
  activeSearchReady: boolean,
  immediateLoading: boolean,
};

class PatientsList extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      activeSearch: get(this.props.patients, 'searchByValue') || '',
      activeSearchReady: isPresent(get(this.props.patients, 'searchByValue') || ''),
      immediateLoading: false,
    };
    this._ = createNsTranslator('patient_list', this.props.t);
  }

  componentDidMount() {
    this.fetchPatients(this.props);
  }

  componentWillReceiveProps(nextProps: Props) {
    this._ = createNsTranslator('patient_list', nextProps.t);

    if (this.props.userWithoutClinic === nextProps.userWithoutClinic) return;

    this.fetchPatients(nextProps);
  }

  render() {
    const { activeSearch, activeSearchReady } = this.state;
    const {
      patients, doctors, tagTemplates, clinic, permissionTo,
      orderDoctorsByProfession, pagination, pageMap, filters,
    } = this.props;
    // console.warn(filters);

    const singleDoctor = get(clinic, 'singleDoctor', false);

    let searchedPatients;
    let filteredPatients;
    const patientsCollection: Object = {};

    if (activeSearch && activeSearchReady) {
      filteredPatients = null;
      const patientIds = get(patients, ['searchBy', `_${activeSearch}_`]);
      map(patientIds, (id) => {
        patientsCollection[id] = get(patients, ['data', id]);
      });
      searchedPatients = {
        ...patients,
        data: patientsCollection,
      };
    } else if (filters && filters.filterBy) {
      searchedPatients = null;
      if (filters.filterBy === 'deleted') {
        // Handle deleted patients filter
        filteredPatients = {
          ...patients,
          data: get(patients, ['filterBy', 'deleted']),
        };
      } else {
        // Handle patients from regular filters
        const patientIds = get(patients, ['filterBy', filters.filterBy, toString(filters.filterValue)]);

        map(patientIds, (id) => {
          patientsCollection[id] = get(patients, ['data', id]);
        });
        filteredPatients = {
          ...patients,
          data: patientsCollection,
        };
      }
    }

    let listLabel = null;

    switch (filters.type) {
      case 'tagIds':
        listLabel = <h1>{this._('label/tag')}: <span className="tagStyle">{get(tagTemplates, ['data', get(filters, 'filterValue'), 'name'], '')}</span></h1>;
        break;
      case 'bornInMonth':
        listLabel = <h1>{this._('action/see_birthdays_of')}</h1>;
        break;
      case 'lastAppointmentTime':
        listLabel = <h1>{this._('action/last_appointment') + ':'}</h1>;
        break;
      case 'doctorId':
        listLabel = <h1>{get(this.props, ['doctors', 'data', get(filters, 'filterValue'), 'nameWithTitle'])}</h1>;
        break;
      case 'deleted':
        listLabel = <h1>{this._('action/deleted_patients')}</h1>;
        break;
      default:
        listLabel = <h1>{this._('action/all_patients', 'global')}</h1>;
        break;
    }

    listLabel = isPresent(listLabel) ? listLabel : (<h1>{this._('action/all_patients', 'global')}</h1>);

    return (
      <PatientsListView
        activeFilter={filters}
        immediateLoading={this.state.immediateLoading}
        activeSearch={activeSearch || ''}
        doctors={doctors}
        patients={searchedPatients || filteredPatients || patients}
        onNewPatientClick={this.handleNewPatientClick}
        onSelectFilter={this.handleSelectFilter}
        onSearch={this.handleSearch}
        listLabel={listLabel}
        tagTemplates={tagTemplates}
        singleDoctor={singleDoctor}
        orderDoctorsByProfession={orderDoctorsByProfession}
        onPaginatePatients={this.handlePaginate}
        pagination={pagination}
        pageMap={pageMap}
        patientsListFilterDoctor={permissionTo.patientsListFilterDoctor}
        patientsListShowDoctorName={permissionTo.patientsListShowDoctorName}
      />
    );
  }
  handleLoadPatients = (page: number) => {
    this.setState({ immediateLoading: true });
    this.props.dispatch(patientActions.getPatients(page, true, () => {
      this.setState({ immediateLoading: false });
    }));
  }

  handlePaginate = (page: number) => {
    this.setState({ immediateLoading: true });
    this.props.dispatch(patientActions.getPatients(page, false, () => {
      this.setState({ immediateLoading: false });
    }));
  }

  handleNewPatientClick = () => {
    this.props.dispatch(actions.openModal('PatientFormModal'));
  };

  dispatchSearch = (searchData: string, page: number) => {
    this.setState({ immediateLoading: true });
    this.props.dispatch(patientActions.searchPatients(searchData, page, () => {
      this.setState({ immediateLoading: false });
    }));
  };


  handleSearch = (searchData: string, page: number = 1) => {
    if (isString(searchData) && (String(searchData).trim().length >= 3 || String(searchData).trim().length === 0)) {
      this.setState({
        activeSearch: searchData,
        activeSearchReady: false,
      }, () => {
        this.dispatchSearch(searchData, page);
        this.setState({
          activeSearch: searchData,
          activeSearchReady: true,
        });
      });
    }
  };

  _: Function;

  handleSelectFilter = (item) => {
    let filterBy;
    let filterValue;

    switch (item.type) {
      case 'tagIds':
        filterBy = 'tagIds';
        filterValue = item.value;
        break;
      case 'bornInMonth':
        const currentMonth = moment().month() + 1;
        filterBy = 'bornInMonth';
        filterValue = item.value || currentMonth;
        break;
      case 'lastAppointmentTime':
        filterBy = 'lastAppointmentTime';
        filterValue = item.value || '30-days';
        break;
      case 'doctorId':
        filterBy = 'doctorId';
        filterValue = item.value;
        break;
      case 'deleted':
        filterBy = 'deleted';
        filterValue = 'true';
        break;
      default:
        filterBy = '';
        filterValue = '';
        break;
    }

    if (isPresent(item.type)) {
      this.setState({ immediateLoading: true });
      this.props.dispatch(patientActions.filterPatients(filterBy, filterValue, item.type, 1, () => {
        this.setState({ immediateLoading: false });
      }));
    } else {
      this.handleLoadPatients(1);
    }

    this.setState({
      activeSearch: null,
    });
  };

  fetchPatients(data: Props) {
    const { dispatch, filters, userWithoutClinic, patients } = data;

    if (userWithoutClinic) return;

    const activeSearch = get(patients, 'searchByValue') || '';

    dispatch(tagTemplateActions.listTagTemplate());

    if (isPresent(activeSearch)) {
      dispatch(searchPatients(activeSearch || ''), 1);

      return;
    }

    if (!filters.type) {
      dispatch(patientActions.getPatients(1));

      return;
    }


    dispatch(patientActions.filterPatients(get(filters, 'filterBy'), get(filters, 'filterValue'), get(filters, 'type')));
  }
}

const mapStateToProps = (state) => ({
  doctors: state.context.doctors,
  patients: state.context.patients,
  tagTemplates: state.context.tagTemplates,
  clinic: state.context.clinics.activeClinic,
  orderDoctorsByProfession: get(state, ['context', 'clinics', 'activeClinic', 'orderDoctorsByProfession'], false),
  pagination: get(state, ['context', 'patients', 'pagination'], {}),
  pageMap: get(state, ['context', 'patients', 'pageMap'], {}),
  permissionTo: mapPermissionTo(state),
  filters: get(state, ['context', 'patients', 'filters'], {}),
  userWithoutClinic: false,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({ dispatch });

export default connect<any, any, any, any, any, any>(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(PatientsList));
