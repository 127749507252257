// @flow
import React from 'react';
import { useTranslationNs } from '@helpers/helpers';
import { Button, Icon, Spinner } from '../../elements';

type Props = {
  onDelete?: ?Function,
  isDeleting?: boolean,
  onSave: Function,
  isSaving?: boolean,
  onCancel: Function,
};


const FormFooterModal = ({ onDelete, onCancel, onSave, isDeleting, isSaving }: Props) => {
  const [_] = useTranslationNs('global');

  if (onDelete) {
    return (
      <div className="modalFooter">
        <Button
          className='deleteIconButton'
          onClick={onDelete}
        >
          {isDeleting ? <Spinner /> : <Icon iconName="delete" />}
        </Button>
        <div>
          <Button text={_('action/cancel')} onClick={onCancel} />
          <Button className="primary" text={_('action/save')} onClick={onSave} isLoading={isSaving} />
        </div>
      </div>
    );
  }

  return (
    <div className="modalFooter">
      <Button text={_('action/cancel')} onClick={onCancel} />
      <Button className="primary" text={_('action/save')} onClick={onSave} isLoading={isSaving} />
    </div>
  );
};

// $FlowFixMe
export default React.memo(FormFooterModal);
