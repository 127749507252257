// @flow
import React from 'react';
import { isEqual } from 'lodash';
import classnames from 'classnames';
import { isPresent } from '@helpers/helpers';
import './Icon.scss';

type Props = {
  className?: string,
  fill?: string,
  iconName: string,
  size: number,
  onClick?: Function,
  spin?: boolean,
  disabled?: boolean,
};

export default class Icon extends React.Component<Props> {
  static defaultProps = {
    size: 24,
  }

  shouldComponentUpdate(nextProps: Props) {
    return !isEqual(this.props, nextProps);
  }

  render() {
    const {
      iconName, className,
      size, fill, spin,
    } = this.props;

    const cssClasses = classnames(['icon', iconName, className], { spin });

    let style = {
      width: size,
      height: size,
    };

    if (isPresent(fill)) {
      style = {
        ...style,
        fill,
      };
    }

    return (
      <svg
        className={cssClasses}
        fill={fill}
        style={style}
        onClick={this.handleClick}
      >
        <use xlinkHref={`#${iconName}`} />
      </svg>
    );
  }

  handleClick = () => {
    const { onClick, disabled } = this.props;
    if (onClick && !disabled) onClick();
  };
}
