// @flow
import React from 'react';
import { concat, includes, without, get, last } from 'lodash';
import { withRouter } from 'react-router-dom';
import { router } from '@router';
import { SettingsMenu, FormFooter, PlatformSwitch, AppMobileHeader, AppMobileFooter } from '@blocks';
import type { DocumentsTemplate, User, FormElementData, BundledPermissions } from '@types';
import {
  Breadcrumb, Input, Checkbox, TextArea,
} from '@elements';
import { withTranslation } from 'react-i18next';
import { createNsTranslator } from '@helpers/helpers';

type Props = {
  documentsTemplate: DocumentsTemplate,
  history: Object,
  user: User,
  onCreateDocumentsTemplate: Function,
  onUpdateDocumentsTemplate: Function,
  onDeleteDocumentsTemplate: Function,
  onLogout: Function,
  t: Function,
  permissions: BundledPermissions,
};

type State = {
  name: string,
  content: string,
  showTitle: boolean,
  showDate: boolean,
  errors: Array<string>,
};

class DocumentsTemplateForm extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    const { documentsTemplate } = this.props;
    this.state = {
      name: documentsTemplate.name || '',
      content: documentsTemplate.content || '',
      showTitle: documentsTemplate.showTitle || false,
      showDate: documentsTemplate.showDate || false,
      errors: [],
    };
  }

  render() {
    const {
      name, content, showTitle,
      errors,
    } = this.state;
    const { documentsTemplate, user, onLogout } = this.props;

    const _ = createNsTranslator('settings', this.props.t);

    const breadcrumbLinks = [
      { text: _('label/prescription_menu/title'), linkTo: '/ajustes/modelos' },
      { text: _('label/documents/list/title'), linkTo: '/ajustes/modelos/documentos' },
    ];

    if (documentsTemplate.id) { // if we are editing an existing documents template
      breadcrumbLinks.push({ text: name });
    } else { // if we are creating a new documents template
      breadcrumbLinks.push({ text: _('label/documents/list/new_doc') });
    }

    const pageContent = (
      <React.Fragment>
        <div className="toolbar dashed toolbarSteticFix">
          <h1>{get(last(breadcrumbLinks), 'text')}</h1>
        </div>
        <Input
          type="text"
          name="name"
          autofocus
          className="field"
          label={_('label/documents/form/name')}
          value={name}
          onChange={this.handleFormChange}
          onBlur={this.handleFormBlur}
          error={includes(errors, 'name') ? _('label/documents/form/error_empty') : ''}
          required
        />
        <TextArea
          name="content"
          className="field prescriptionRtEditor"
          label={_('label/text', 'global')}
          value={content}
          onChange={this.handleFormChange}
          onBlur={this.handleFormBlur}
          error={includes(errors, 'content') ? _('label/documents/form/error_empty') : ''}
          placeholder={_('label/documents/form/placeholder_content')}
          required
        />
        <br/>
        <Checkbox
          name="showTitle"
          label={_('label/documents/form/show_print_model')}
          isChecked={showTitle}
          onChange={this.handleFormChange}
        />
        {documentsTemplate.id ? (
          <FormFooter
            onSave={this.onSaveDocumentsTemplate}
            onCancel={this.onCancel}
            includeDelete
            onDelete={this.onDeleteDocumentsTemplate.bind(this, documentsTemplate.id)}
          />
        ) : (
          <FormFooter onSave={this.onSaveDocumentsTemplate} onCancel={this.onCancel} />
        )}
      </React.Fragment>
    );
    return (
      <div className="appContent settingsPage">
        <PlatformSwitch
          inject
          desktop={() => (
            <React.Fragment>
              <aside className="sidebar">
                <SettingsMenu user={user} onLogout={onLogout} permissions={this.props.permissions} />
              </aside>
              <section className="mainSection">
                <div className="centerContent" id="clinicConfigDocumentsTemplatesDesktopForm">
                  <Breadcrumb items={breadcrumbLinks} />
                  {pageContent}
                </div>
              </section>
            </React.Fragment>
          )}
          mobile={() => (
            <React.Fragment>
              <AppMobileHeader title={_('label/documents/list/title')} backLinkTo="/ajustes/modelos/receituarios" />
              <section className="mainSection">
                <div className="centerContent">{pageContent}</div>
              </section>
              <AppMobileFooter />
            </React.Fragment>
          )}
        />
      </div>
    );
  }

  checkForErrors = (value: (string | number | boolean), fieldName: string) => {
    const { errors } = this.state;

    if (value === '') {
      this.setState({ errors: concat(errors, [fieldName]) });
    } else if (includes(errors, fieldName)) {
      this.setState({ errors: without(errors, fieldName) });
    }
  };

  handleFormChange = (data: FormElementData) => {
    const { name, value } = data;
    this.setState({ [name]: value }, () => this.checkForErrors(value, name));
  };

  handleFormBlur = (data: FormElementData) => {
    const { name, value } = data;
    this.checkForErrors(value, name);
  };

  onSaveDocumentsTemplate = () => {
    const {
      documentsTemplate,
      onUpdateDocumentsTemplate,
      onCreateDocumentsTemplate,
    } = this.props;
    const {
      name, content, showTitle,
      showDate,
    } = this.state;

    const data = {
      name,
      content,
      showTitle,
      showDate,
    };
    const errors = [];

    if (name === '') {
      if (!includes(this.state.errors, 'name')) {
        this.setState({ errors: concat(errors, ['name']) });
      }
      errors.push('name');
    }

    if (content === '') {
      if (!includes(this.state.errors, 'content')) {
        this.setState({ errors: concat(errors, ['content']) });
      }
      errors.push('content');
    }

    if (!errors.length) {
      if (documentsTemplate.id) { // if we are editing an existing documents template
        onUpdateDocumentsTemplate(documentsTemplate.id, data);
        this.redirectToDocumentsList();
      } else { // if we are creating a new documents template
        onCreateDocumentsTemplate(data);
        this.redirectToDocumentsList();
      }
    }
  };

  onDeleteDocumentsTemplate = (templateId: number) => {
    this.props.onDeleteDocumentsTemplate(templateId);
    this.redirectToDocumentsList();
  };

  onCancel = () => {
    this.redirectToDocumentsList();
  };

  redirectToDocumentsList = () => {
    this.props.history.push(router.getRoutePath('documentsTemplatesList'));
  };
}

export default withTranslation()(withRouter(DocumentsTemplateForm));
