// @flow
import React, { useEffect, useMemo } from 'react';
import { isFunction } from 'lodash';
import { useSelector } from 'react-redux';
import type { Node } from 'react';
import type { UiState } from '@types';
import { checkIfIsMobile } from '@helpers/helpers';

type Props = {
  id?: string,
  className?: string,
  desktop: Function | Node,
  mobile: Function | Node,
  inject?: boolean, // does not place div element
};

export type State = {
  window: {
    width: number,
    height: number,
  },
  orientation: string,
};

const PlatformSwitch = ({ desktop, mobile, className, id, inject }: Props) => {
  const ui: UiState = useSelector(state => state.ui);

  const isMobile = useMemo(() => checkIfIsMobile(ui), [ui]);

  const mobileContent: Function = useMemo(() => (isFunction(mobile) ? mobile : () => mobile), [mobile]);
  const dektopContent: Function = useMemo(() => (isFunction(desktop) ? desktop : () => desktop), [desktop]);

  useEffect(() => {
    if (!isFunction(desktop)) {
      // eslint-disable-next-line
      console.warn("%c Waning avoid passing prop desktop on PlatformSwitch as a ReactJS Element, prefer pass an Function", "color: red;");
    }
    if (!isFunction(mobile)) {
      // eslint-disable-next-line
      console.warn("%c Waning avoid passing prop mobile on PlatformSwitch as a ReactJS Element, prefer pass an Function", "color: red;");
    }
  }, [desktop, mobile]);

  if (inject) {
    return (
      <React.Fragment>
        {isMobile ? mobileContent(window.isCordovaApp) : dektopContent(window.isCordovaApp)}
      </React.Fragment>
    );
  }

  return (
    <div id={id} className={className}>
      { isMobile ? mobileContent(window.isCordovaApp) : dektopContent(window.isCordovaApp) }
    </div>
  );
};


// $FlowFixMe
export default React.memo(PlatformSwitch);

