// @flow
import React from 'react';
import { AppMobileHeader, SettingsMenu, AppMobileFooter } from '../../blocks';
import type { User, BundledPermissions } from '../../../types';
import './Settings.scss';

type Props = {
  user: User,
  onLogout: Function,
  permissions: BundledPermissions,
};

export default class SettingsList extends React.Component<Props> {
  render() {
    const { user, onLogout } = this.props;

    return (
      <React.Fragment>
        <AppMobileHeader title="Ajustes" hideBack />
        <div id="settingsListPage" className="appContent">
          <section className="mainSection noPadding">
            <SettingsMenu user={user} onLogout={onLogout} permissions={this.props.permissions} />
          </section>
        </div>
        <AppMobileFooter />
      </React.Fragment>
    );
  }
}
