// @flow
import { get, omit, isFunction } from 'lodash';
import Immutable from 'seamless-immutable';
import moment from 'moment';
import { reportError, createCachedAction } from '@helpers/helpers';
import { Api } from '../../../helpers';
import TagTemplateService from '../../../services/TagTemplateService';
import { router } from '../../../routes/router';
import type
{
  Action,
  Dispatch,
  TagTemplateInput,
  ContextState,
} from '../../../types';

// ------------------------------------
// Constants
// ------------------------------------
const TAG_TEMPLATES_LOADING = 'tagTemplates.TAG_TEMPLATES_LOADING';
const TAG_TEMPLATES_LOADED = 'tagTemplates.TAG_TEMPLATES_LOADED';
const TAG_TEMPLATES_LOADING_FAILED = 'tagTemplates.TAG_TEMPLATES_LOADING_FAILED';

const TAG_TEMPLATE_UPDATE_IN_PROGRESS = 'tagTemplates.TAG_TEMPLATE_UPDATE_IN_PROGRESS';
const TAG_TEMPLATE_UPDATE_SUCCESS = 'tagTemplates.TAG_TEMPLATE_UPDATE_SUCCESS';
const TAG_TEMPLATE_UPDATE_FAILED = 'tagTemplates.TAG_TEMPLATE_UPDATE_FAILED';

const TAG_TEMPLATE_DELETE_IN_PROGRESS = 'tagTemplates.TAG_TEMPLATE_DELETE_IN_PROGRESS';
const TAG_TEMPLATE_DELETE_SUCCESS = 'tagTemplates.TAG_TEMPLATE_DELETE_SUCCESS';
const TAG_TEMPLATE_DELETE_FAILED = 'tagTemplates.TAG_TEMPLATE_DELETE_FAILED';

const TAG_TEMPLATE_CREATE_IN_PROGRESS = 'tagTemplates.TAG_TEMPLATE_CREATE_IN_PROGRESS';
const TAG_TEMPLATE_CREATE_SUCCESS = 'tagTemplates.TAG_TEMPLATE_CREATE_SUCCESS';
const TAG_TEMPLATE_CREATE_FAILED = 'tagTemplates.TAG_TEMPLATE_CREATE_FAILED';

// ------------------------------------
// Actions
// ------------------------------------
export const createTagTemplate = (tagTemplate: TagTemplateInput, callback: Function = () => {}) => (dispatch: Dispatch) => {
  dispatch({ type: TAG_TEMPLATE_CREATE_IN_PROGRESS });
  Api.post(router.getApiRoute('createTagTemplate', null), {
    data: { tagTemplate },
    success: (response) => {
      dispatch({
        type: TAG_TEMPLATE_CREATE_SUCCESS,
        tagTemplate: get(response, ['data', 'tagTemplate']),
      });
      if (isFunction(callback)) {
        callback(true);
      }
    },
    error: (error) => {
      dispatch({ type: TAG_TEMPLATE_CREATE_FAILED, error: { message: error.message } });
      dispatch(reportError(error));
      if (isFunction(callback)) {
        callback(false);
      }
    },
  });
};

export const updateTagTemplate = (id: number, tagTemplate: TagTemplateInput, callback: Function = () => {}) => (dispatch:Dispatch) => {
  dispatch({ type: TAG_TEMPLATE_UPDATE_IN_PROGRESS });
  Api.patch(router.getApiRoute('updateTagTemplate', { id }), {
    data: tagTemplate,
    success: (response) => {
      dispatch({
        type: TAG_TEMPLATE_UPDATE_SUCCESS,
        tagTemplate: get(response, ['tagTemplate']),
      });
      if (isFunction(callback)) {
        callback(true);
      }
    },
    error: (error) => {
      dispatch({ type: TAG_TEMPLATE_UPDATE_FAILED, error: { message: error.message } });
      dispatch(reportError(error));
      if (isFunction(callback)) {
        callback(false);
      }
    },
  });
};

export const deleteTagTemplate = (id: number, callback: Function = () => {}) => (dispatch: Dispatch) => {
  dispatch({ type: TAG_TEMPLATE_DELETE_IN_PROGRESS });
  Api.destroy(router.getApiRoute('deleteTagTemplate', { id }), {
    success: () => {
      dispatch({ type: TAG_TEMPLATE_DELETE_SUCCESS, deletedId: id });
      if (isFunction(callback)) {
        callback(true);
      }
    },
    error: (error) => {
      dispatch({ type: TAG_TEMPLATE_DELETE_FAILED, error: { message: error.message } });
      dispatch(reportError(error));
      if (isFunction(callback)) {
        callback(false);
      }
    },
  });
};

// export const listTagTemplate = () => (dispatch: Dispatch) => {
//   dispatch({ type: TAG_TEMPLATES_LOADING });
//   Api.get(router.getApiRoute('listTagTemplates', null), {
//     success: (response) => {
//       dispatch({
//         type: TAG_TEMPLATES_LOADED,
//         tagTemplates: response.tagTemplates,
//       });
//     },
//     error: (error) => {
//       dispatch({ type: TAG_TEMPLATES_LOADING_FAILED, error: { message: error.message } });
//       dispatch(reportError(error));
//     },
//   });
// };

export const listTagTemplate = (ignoreCache?: boolean = false) => (dispatch: Dispatch) => {
  dispatch(createCachedAction(
    TAG_TEMPLATES_LOADING, TAG_TEMPLATES_LOADED, TAG_TEMPLATES_LOADING_FAILED,
    12,
    () => Api.get(router.getApiRoute('listTagTemplates', null)),
    ['context', 'tagTemplates', 'ttl'],
    (response: any) => ({
      tagTemplates: response.tagTemplates,
    }),
  )(ignoreCache, {}));
};

export const tagTemplateActions = {
  listTagTemplate,
  createTagTemplate,
  updateTagTemplate,
  deleteTagTemplate,
};

// ------------------------------------
// Reducer
// ------------------------------------
export default function tagTemplatesReducer (state: ContextState, action: Action): ContextState {
  switch (action.type) {
    case TAG_TEMPLATES_LOADING:
      return state
        .setIn(['tagTemplates', 'isLoading'], true)
        .setIn(['tagTemplates', 'errors'], null);
    case TAG_TEMPLATES_LOADED:
      const normalized = TagTemplateService.normalizeTagTemplates(action.tagTemplates);

      return state
        .setIn(['tagTemplates', 'isLoading'], false)
        .setIn(['tagTemplates', 'isSaving'], false)
        .setIn(['tagTemplates', 'errors'], null)
        .setIn(['tagTemplates', 'ttl'], get(action, 'ttl', null))
        .setIn(['tagTemplates', 'data'], normalized.tagTemplates)
        .setIn(['tagTemplates', 'lastFetch'], moment().toISOString());
    case TAG_TEMPLATES_LOADING_FAILED:
      return state
        .setIn(['tagTemplates', 'isLoading'], false)
        .setIn(['tagTemplates', 'errors'], [action.error.message]);

    case TAG_TEMPLATE_UPDATE_IN_PROGRESS:
      return state
        .setIn(['tagTemplates', 'isSaving'], true)
        .setIn(['tagTemplates', 'errors'], null);
    case TAG_TEMPLATE_UPDATE_SUCCESS:
      return state
        .setIn(['tagTemplates', 'isSaving'], false)
        .setIn(['tagTemplates', 'errors'], null)
        .setIn(['tagTemplates', 'data', action.tagTemplate.id], action.tagTemplate);

    case TAG_TEMPLATE_UPDATE_FAILED:
      return state
        .setIn(['tagTemplates', 'isSaving'], false)
        .setIn(['tagTemplates', 'errors'], [action.error.message]);

    case TAG_TEMPLATE_CREATE_IN_PROGRESS:
      return state
        .setIn(['tagTemplates', 'isSaving'], true)
        .setIn(['tagTemplates', 'errors'], null);
    case TAG_TEMPLATE_CREATE_SUCCESS:
      return state
        .setIn(['tagTemplates', 'isSaving'], false)
        .setIn(['tagTemplates', 'errors'], null)
        .setIn(['tagTemplates', 'data', action.tagTemplate.id], action.tagTemplate);
    case TAG_TEMPLATE_CREATE_FAILED:
      return state
        .setIn(['tagTemplates', 'isSaving'], false)
        .setIn(['tagTemplates', 'errors'], [action.error.message]);

    case TAG_TEMPLATE_DELETE_IN_PROGRESS:
      return state
        .setIn(['tagTemplates', 'isSaving'], true)
        .setIn(['tagTemplates', 'errors'], null);

    case TAG_TEMPLATE_DELETE_SUCCESS:
      const tagTemplatesList = get(state, ['tagTemplates', 'data'], Immutable({}));
      const newTagTemplatesList = omit(tagTemplatesList, [String(action.deletedId)]);
      return state
        .setIn(['tagTemplates', 'isSaving'], false)
        .setIn(['tagTemplates', 'data'], newTagTemplatesList);

    case TAG_TEMPLATE_DELETE_FAILED:
      return state
        .setIn(['tagTemplates', 'isSaving'], false)
        .setIn(['tagTemplates', 'errors'], [action.error.message]);
    default: return state;
  }
}
