// @flow
import React, { useCallback, useMemo } from 'react';
import moment from 'moment';
import { map, get } from 'lodash';
import { SelectBox, Toggle, Input, Button, Modal, DayPickerInput } from '@elements';
import { periodsOptions, months, years } from '@helpers/constants';
import type { DoctorsState, FormElementData } from '@types';
import { sort, alphabeticCompare, useTranslationNs, useTranslatedConstant } from '@helpers/helpers';
import classnames from 'classnames';
import './ReportsFilters.scss';

type Props = {
  doctors: DoctorsState,
  isManager: boolean,
  isLoading: boolean,
  onChangeFilter: Function,
  withNotaFiscal?: boolean,
  status?: string,
  notaFiscal?: boolean,
  doctor: string,
  startDate?: string,
  endDate?: string,
  isMobile?: boolean,
  year?: number;
  month: number;
  reportsSelectDoctor: boolean,
  onCloseMobileModal?: Function,
  period?: string;
};


const ReportsFilters = ({
  doctors, isLoading, doctor, status, withNotaFiscal, isManager, period, reportsSelectDoctor,
  isMobile, startDate, endDate, onChangeFilter, onCloseMobileModal, ...props
} : Props) => {
  const [_] = useTranslationNs('reports');

  const year = useMemo(() => String(props.year), [props.year]);
  const month = useMemo(() => String(props.month), [props.month]);

  const notaFiscal = useMemo(() => Boolean(props.notaFiscal), [props.notaFiscal]);

  const filterStatusOptions = useMemo(() => [
    { text: _('filters/status/confirmed'), value: 'confirmed' },
    { text: _('filters/status/forecasted'), value: 'forecasted' },
    { text: _('filters/status/canceled'), value: 'canceled' },
    { text: _('filters/status/health_insurance'), value: 'health_insurance' },
  ], [_]);

  const [translatedPeriodOptions] = useTranslatedConstant(periodsOptions);
  const [translatedMonths] = useTranslatedConstant(months);

  const doctorsData = useMemo(() => get(doctors, ['data'], {}), [doctors]);

  const doctorItens = useMemo(() => map<any, any>(doctorsData, (doc) => ({
    value: doc.id,
    text: doc.nameWithTitle,
    name: doc.name,
  })), [doctorsData]);

  const doctorsItems = useMemo(() => [
    { text: _('action/all_doctors', 'global'), value: 'all' },
    ...sort(doctorItens, alphabeticCompare('name')),
  ], [doctorItens, _]);

  const handleChangeFilter = useCallback((data: FormElementData) => {
    onChangeFilter(data);
  }, [onChangeFilter]);

  const handleChangeFilterDate = useCallback((data: FormElementData) => {
    const value = moment(String(data.value)).format('YYYY-MM-DD');
    onChangeFilter({ name: data.name, value });
  }, [onChangeFilter]);

  const handleCloseMobileModal = useCallback(() => {
    if (onCloseMobileModal) {
      onCloseMobileModal();
    }
  }, [onCloseMobileModal]);

  const content = (mobile: boolean) => (
    <div className={classnames('reportsFilter', { mobile })}>
      <SelectBox
        disabled={isLoading}
        className="input-periods"
        label={_('filters/label_period')}
        placeholder={_('label/today', 'global')}
        name="period"
        onChange={handleChangeFilter}
        value={period}
        options={translatedPeriodOptions}
        changeablePlaceholder
      />
      {period === 'custom_month' &&
      <SelectBox
        name="month"
        disabled={isLoading}
        className="filter-month"
        label={_('label/month', 'global')}
        options={translatedMonths}
        value={month}
        onChange={handleChangeFilter}
        changeablePlaceholder
      />
        }
      {period === 'custom' && (
      <React.Fragment>
        <DayPickerInput
          advancedMode
          label="Data Inicial"
          className="filter-date-picker"
          name="startDate"
          date={moment(startDate, 'YYYY-MM-DD')}
          onSelect={handleChangeFilterDate}
        />
        <DayPickerInput
          advancedMode
          label="Data Final"
          className="filter-date-picker"
          name="endDate"
          date={moment(endDate, 'YYYY-MM-DD')}
          onSelect={handleChangeFilterDate}
        />
      </React.Fragment>
      )}
      {period === 'custom_month' &&
      <SelectBox
        name="year"
        disabled={isLoading}
        className="filter-year"
        label={_('label/year', 'global')}
        options={years}
        value={year}
        onChange={handleChangeFilter}
        changeablePlaceholder
      />
        }
      {!isManager &&
      <div className="inputHolder filter-type">
        <label className="formInputLabel">{_('label/doctor', 'global')}</label>
        <Input disabled value={get(doctorsData, [doctor, 'nameWithTitle'])} />
      </div>
        }
      {isManager && reportsSelectDoctor &&
      <SelectBox
        disabled={isLoading}
        name="doctor"
        className="input-doctors"
        label={_('label/doctor', 'global')}
        options={doctorsItems}
        value={doctor}
        onChange={handleChangeFilter}
        changeablePlaceholder
      />
        }
      {status &&
      <SelectBox
        name="status"
        disabled={isLoading}
        className="filter-type"
        label={_('filters/status')}
        options={filterStatusOptions}
        value={status}
        onChange={onChangeFilter}
        changeablePlaceholder
      />
        }
      {withNotaFiscal &&
      <Toggle
        name="notaFiscal"
        label={_('filters/fiscal_note_only')}
        onChange={onChangeFilter}
        isChecked={notaFiscal}
      />
        }
    </div>
  );

  if (isMobile) {
    return (
      <Modal className="asView">
        <div className="modalHeader actionHeader">
          <span>&nbsp;</span>
          <span>Filtros de Serviços</span>
          <Button className="backButton primary" onClick={handleCloseMobileModal}>
            {String(_('action/filter', 'global')).toUpperCase()}
          </Button>
        </div>
        <div className="modalGrid">
          <div className="modalContainer">
            <div className="modalContent">
              {content(true)}
            </div>
          </div>
        </div>
      </Modal>
    );
  }

  return content(false);
};


export default ReportsFilters;
