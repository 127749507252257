/**
 * Application Routes
 */
export const ROUTES_CONSTRUCTOR = [
  { name: 'root', path: '/' },
  { name: 'appHome', path: '/bem-vindo' },
  { name: 'ghostLogin', path: '/admin' },
  { name: 'signUp', path: '/cadastro' },
  { name: 'signUpBasic', path: '/cadastro/dados-basicos' },
  { name: 'signUpProfession', path: '/cadastro/profissao' },
  { name: 'signUpClinic', path: '/cadastro/clinica' },
  { name: 'newPassword', path: '/cadastro/senha/nova' },
  { name: 'newClinic', path: '/cadastro/clinica/nova' },
  { name: 'getStarted', path: '/vamos-comecar' },
  { name: 'login', path: '/entrar' },
  { name: 'forgotPassword', path: '/esqueci-senha' },
  { name: 'backupRequest', path: '/ajustes/suporte/backup' },

  { name: 'agenda', path: '/agenda' },

  { name: 'patientsList', path: '/pacientes/lista' },
  { name: 'patient', path: '/pacientes/:id' },
  { name: 'patientTimeline', path: '/paciente/:id/prontuario' },
  { name: 'patientDeleted', path: '/paciente/:id/deletado' },
  { name: 'patientProfile', path: '/paciente/:id/cadastro' },
  { name: 'patientMessages', path: '/paciente/:id/mensagens' },

  { name: 'patientFinancial', path: '/paciente/:id/financeiro' },
  { name: 'patientIndicators', path: '/paciente/:id/indicadores' },
  { name: 'patientServices', path: '/paciente/:id/financeiro/servicos' },
  { name: 'patientPayments', path: '/paciente/:id/financeiro/pagamentos' },
  { name: 'patientReceipts', path: '/paciente/:id/financeiro/recibos' },

  { name: 'reportsList', path: '/relatorios' },
  { name: 'reportsAppointments', path: '/relatorios/consultas' },
  { name: 'reportsDebt', path: '/relatorios/dividas' },
  { name: 'reportsPayments', path: '/relatorios/pagamentos' },
  { name: 'reportsReceipts', path: '/relatorios/recibos' },
  { name: 'reportsServices', path: '/relatorios/servicos' },
  { name: 'reportsSms', path: '/relatorios/sms' },

  { name: 'settingsList', path: '/ajustes' },
  { name: 'userConfig', path: '/ajustes/meu-perfil' },
  { name: 'changePassword', path: '/ajustes/trocar-senha' },
  { name: 'recordsPrivacy', path: '/ajustes/privacidade' },
  { name: 'clinicConfigMenu', path: '/ajustes/clinica' },
  { name: 'clinicConfigAddress', path: '/ajustes/clinica/endereco' },
  { name: 'clinicConfigWorkHours', path: '/ajustes/clinica/horario' },
  { name: 'clinicConfigRooms', path: '/ajustes/clinica/salas' },
  { name: 'clinicConfigPatientFields', path: '/ajustes/clinica/campos-paciente' },
  { name: 'doctorsList', path: '/ajustes/doutores' },
  { name: 'doctorForm', path: '/ajustes/doutores/:id' },
  { name: 'workhours', path: '/ajustes/doutores/:id/horarios-de-atendimento' },
  { name: 'secretariesList', path: '/ajustes/secretarias' },
  { name: 'secretaryForm', path: '/ajustes/secretarias/:id' },
  { name: 'customFormsList', path: '/ajustes/formularios' },
  { name: 'prescriptionsMenu', path: '/ajustes/modelos' },
  { name: 'documentsTemplatesList', path: '/ajustes/modelos/documentos' },
  { name: 'documentsTemplateForm', path: '/ajustes/modelos/documentos/:id' },
  { name: 'drugsPrescriptionsTemplatesList', path: '/ajustes/modelos/receituarios' },
  { name: 'drugsPrescriptionsTemplateForm', path: '/ajustes/modelos/receituarios/:id' },
  { name: 'examsPrescriptionsTemplatesList', path: '/ajustes/modelos/exames' },
  { name: 'examsPrescriptionsTemplateForm', path: '/ajustes/modelos/exames/:id' },

  { name: 'memedSignup', path: '/ajustes/modelos/memed' },
  { name: 'tagTemplatesList', path: '/ajustes/etiquetas' },
  { name: 'tagTemplateForm', path: '/ajustes/etiquetas/:id' },
  { name: 'serviceTemplatesList', path: '/ajustes/servicos/:doctorId' },
  { name: 'serviceTemplateNewForm', path: '/ajustes/servico/:doctorId/novo' },
  { name: 'serviceTemplateEditForm', path: '/ajustes/servicos/:doctorId/:id' },
  { name: 'settingsSms', path: '/ajustes/sms' },
  { name: 'printConfig', path: '/ajustes/impressao' },
  { name: 'printLogoConfig', path: '/ajustes/impressao/logo-da-clinica' },
  { name: 'prescriptionsPrintConfig', path: '/ajustes/impressao/receituario' },
  { name: 'controlledPrescriptionsPrintConfig', path: '/ajustes/impressao/receituario-controlado' },
  { name: 'generalPrintConfig', path: '/ajustes/impressao/geral' },
  { name: 'support', path: '/ajustes/suporte' },
  { name: 'deleteMyAccount', path: '/ajustes/deletar-minha-conta' },

  { name: 'subscriptionWall', path: '/assinar' },
  { name: 'benefits', path: '/beneficios' },
  { name: 'pricing', path: '/planos' },
  { name: 'subscriptionAddress', path: '/pagamento/endereco' },
  { name: 'subscriptionPayment', path: '/pagamento/metodo' },
  { name: 'subscriptionInfo', path: '/ajustes/meu-plano' },
  { name: 'subscriptionComplete', path: '/pagamento/completo' },

  { name: 'notFound', path: '/not-found' },
];
