// @flow
import React, { useState, useCallback, useEffect, useRef } from 'react';
import classNames from 'classnames';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import { capitalize, map, toNumber, get } from 'lodash';
import { getMemedPrescription } from '@helpers/memed';
import { recordActions } from '@context/records';
import { Spinner, Icon, Button } from '@elements';
import { handleMomenti18n, isPresent, useTranslationNs, useIsMobile } from '@helpers/helpers';
import ReactHtmlParser from 'react-html-parser';

type MemedMedicationITem = {
  name: string,
  posology: string | null,
  quantity: number | null,
  unit: string | null,
}

type Props = {
    id: number,
    prescription: {
      has_content: boolean,
      medications: MemedMedicationITem[],
    };
};

const RecordPrescription = ({ id, prescription }: Props) => {
  const [_, i18n] = useTranslationNs('timeline');

  useEffect(() => handleMomenti18n(i18n, moment), [i18n]);

  const [fetching, setFetching] = useState(false);
  const [fetchError, setFetchError] = useState(false);
  const [medications, setMedications] = useState([]);
  const [firstRenderized, setFirstRenderized] = useState(false);
  const hashRef = useRef('');

  const doctorId = useSelector((state) => get(state, ['context', 'user', 'advancedData', 'doctorId']));
  const dispatch = useDispatch();

  const [isMobile] = useIsMobile();

  const prescriptionDoctorId = get(prescription, ['doctorId'], null);
  const memedPrintToken = get(prescription, ['memed_print_token'], null);

  const isMyOwnPrescription = doctorId === prescriptionDoctorId;

  const showPrintButton = Boolean(memedPrintToken) && isMyOwnPrescription && (!fetchError && !fetching);

  const onPrintClick = useCallback(() => {
    if (!memedPrintToken) return;

    dispatch(recordActions.printMemedPrescription(id, memedPrintToken));
  }, [dispatch, id, memedPrintToken]);

  const fetchPrescription = useCallback(() => {
    setFetchError(false);
    setFetching(true);
    getMemedPrescription(id, memedPrintToken)
      .then(response => {
        setMedications((response.medications || []).map((item) => ({
          name: item.nome,
          posology: isPresent(item.posologia) ? capitalize(item.posologia) : null,
          quantity: isPresent(item.quantidade) ? toNumber(item.quantidade) : null,
          unit: isPresent(item.unit || item.unidade) ? item.unit || item.unidade : null,
        })));
        setFirstRenderized(true);
        setFetching(false);
        window.localforage.setItem(`memed-prescription-${id}`, {
          response,
          __fetchDate: moment().toISOString(),
        });
      })
      .catch(() => {
        setFetchError(true);
        setFetching(false);
      });
  }, [id, memedPrintToken]);

  useEffect(() => {
    const hash = `${id}-${memedPrintToken}`;

    if (prescription.has_content) {
      setMedications(prescription.medications);
      setFirstRenderized(true);
      return;
    }

    if (hash !== hashRef.current && !fetching) {
      fetchPrescription();
      hashRef.current = hash;
    }
  }, [fetchPrescription, id, memedPrintToken, fetching, prescription.has_content, prescription.medications]);

  return (
    <div className={classNames('memedPrescriptionRecord', { fetching, fetchError })}>
      <div>
        <div className="title">
          Receituário Memed
          {showPrintButton && !isMobile && (
            <React.Fragment>
              <Button onClick={onPrintClick} className="btn outlineButton downloadBtn memedActionButton">
                <Icon iconName="print" className="icon" size={18} />&nbsp;Imprimir
              </Button>
            </React.Fragment>
          )}

          {showPrintButton && isMobile && (
            <React.Fragment>
              <Button onClick={onPrintClick} className="btn outlineButton newShareFeature buttonLike downloadBtn memedActionButton shareMemedPrescription">
                <Icon iconName="share" className="icon" size={18} />
              </Button>
            </React.Fragment>
          )}
          <br/>
          {!fetchError && fetching && (<Spinner text={_('label/loading_memed_prescriptions')} />)}
        </div>
      </div>

      {!fetchError && !fetching && firstRenderized && (
        <table
          className={classNames('table medicationsList', 'animate__animated animate__fadeIn')}
        >
          {map(medications, (medication: MemedMedicationITem) => (
            <tr>
              <td className="medicationTd">
                <div className="medicationTd-title">{medication.name}</div>
                {!!medication.posology && (
                <div className="medicationTd-posology">
                  {ReactHtmlParser(medication.posology || '')}
                </div>
                )}
                {!!medication.quantity && medication.quantity > 0 && (
                <div className="medicationTd-quantity-label">
                  <label>Qtd: </label>
                  <span>{medication.quantity}{medication.unit ? ` ${medication.unit}` : ''}</span>
                </div>
                )}
                {medication.quantity === 0 && isPresent(medication.unit) && (
                <div className="medicationTd-quantity-label">
                  <label>{medication.unit}</label>
                </div>
                )}
              </td>
            </tr>
          ))}
        </table>
      )}

      {fetchError && (
        <div className="fetchErrorMessage">
          {_('label/memed_error')}&nbsp;
          <span className="actionLink" onClick={fetchPrescription}>
            {_('label/memed_error_link')}
          </span>
        </div>
      )}
    </div>
  );
};


// $FlowFixMe
export default React.memo(RecordPrescription);
