// @flow
import React from 'react';
import { withTranslation } from 'react-i18next';
import { Icon, ClickableList } from '../../elements';
import './ReportsMenu.scss';

type Props = {
  t: Function,
};
class ReportsMenu extends React.Component<Props> {
  componentDidMount() {
    window.lastSettingsScroll = 0;
  }

  render() {
    const { t } = this.props;

    const items = [
      {
        type: 'heading',
        text: t('menu/financial'),
      },
      {
        linkTo: '/relatorios/dividas',
        customRendererData: {
          text: t('menu/debts'),
          icon: 'arrow-right',
        },
      },
      {
        linkTo: '/relatorios/servicos',
        customRendererData: {
          text: t('menu/services'),
          icon: 'arrow-right',
        },
      },
      {
        linkTo: '/relatorios/pagamentos',
        customRendererData: {
          text: t('menu/payments'),
          icon: 'arrow-right',
        },
      },
      {
        linkTo: '/relatorios/recibos',
        customRendererData: {
          text: t('menu/receipts'),
          icon: 'arrow-right',
        },
      },
      {
        type: 'heading',
        text: t('menu/treatment'),
      },
      {
        linkTo: '/relatorios/consultas',
        customRendererData: {
          text: t('menu/inquiries'),
          icon: 'arrow-right',
        },
      },
      {
        linkTo: '/relatorios/sms',
        customRendererData: {
          text: t('menu/sms'),
          icon: 'arrow-right',
        },
      },
    ];

    const renderer = (item) => ([
      <span key="text">{item.text}</span>,
      <Icon key="icon" className="icon" iconName={item.icon} />,
    ]);

    return (
      <aside className="sidebar">
        <ClickableList
          items={items}
          customRenderer={renderer}
        />
      </aside>
    );
  }
}

export default withTranslation('reports')(ReportsMenu);
