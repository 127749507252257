// @flow
import React from 'react';
import { get, size } from 'lodash';
import { SettingsMenu, FormFooter, PlatformSwitch, AppMobileHeader, AppMobileFooter } from '@blocks';
import { Input, InputMask, Breadcrumb, Toggle } from '@elements';
import type { User, FormElementData, BundledPermissions } from '@types';
import { createValidator } from '@helpers/validator';
import { withForm } from '@hocs';
import { withTranslation } from 'react-i18next';
import { createNsTranslator, handleSubmitEvent, UserNotifications } from '@helpers/helpers';

type State = {
  errors: Object,
};

type Props = {
  user: User,
  onSave: Function,
  onDelete?: Function,
  doctorId: number,
  user: User,
  onSave: Function,
  onLogout: Function,
  handleFormChange: Function,
  handleTouchField: Function,
  bindValidation: Function,
  formData: Object,
  touchedFields: Object,
  changedFields: Object,
  permissions: BundledPermissions,
  t: Function,
  onCancel: Function,
  formEdited: Function,
  isSaving: boolean,
};

const validator = createValidator()
  .field('name')
  .present('Informe um nome')
  .field('price')
  .minNumber(0.0, 'Informe um valor');

class ServiceTemplateForm extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      errors: {},
    };
    this.props.bindValidation(this.handleValidation);
  }

  componentDidMount() {
    handleSubmitEvent('.serviceTemplateForm', () => {
      if (this.props.formEdited()) {
        this.onSaveService();
      }
    });
  }

  render() {
    const { name, price, healthInsurance } = this.props.formData;
    const {
      onDelete, user, doctorId, handleFormChange, handleTouchField,
      onLogout, isSaving,
    } = this.props;

    const _ = createNsTranslator('settings', this.props.t);

    const title = _('label/services/form/new_service');

    const breadcrumbLinks = [
      { text: _('label/services/form/service_value'), linkTo: `/ajustes/servicos/${doctorId}` },
      { text: _('label/services/add_service') },
    ];

    const pageContent = (
      <React.Fragment>
        <Input
          type="text"
          name="name"
          autofocus
          className="field"
          label={_('label/name', 'global')}
          required
          value={name}
          onChange={handleFormChange}
          onBlur={handleTouchField}
          error={get(this.state.errors, 'name', null)}
        />
        <InputMask
          type="currency"
          name="price"
          label={_('label/value', 'global')}
          className="field"
          required
          value={price}
          onChange={handleFormChange || 0}
          onBlur={handleTouchField}
          error={get(this.state.errors, 'price', null)}
        />
        <br />
        <Toggle
          name="healthInsurance"
          key="toggleSwitch"
          label={_('label/status_health_insurance', 'global')}
          onChange={handleFormChange}
          isChecked={healthInsurance}
          error={get(this.state.errors, 'healthInsurance', null)}
        />
        <FormFooter
          onSave={this.onSaveService}
          onDelete={onDelete}
          onCancel={this.handleCancel}
          isLoading={isSaving}
        />
      </React.Fragment>
    );

    return (
      <PlatformSwitch
        inject
        desktop={() => (
          <div className="appContent settingsPage">
            <aside className="sidebar">
              <SettingsMenu user={user} onLogout={onLogout} permissions={this.props.permissions} />
            </aside>
            <section className="mainSection">
              <div className="centerContent serviceTemplateForm">
                <Breadcrumb items={breadcrumbLinks} />
                <div className="toolbar dashed">
                  <h1>{title}</h1>
                </div>
                {pageContent}
              </div>
            </section>
          </div>
        )}
        mobile={() => (
          <React.Fragment>
            <AppMobileHeader title={title} onBackClick={this.handleCancel} />
            <div className="appContent settingsPage">
              <section className="mainSection">
                <div className="centerContent">{pageContent}</div>
              </section>
            </div>
            <AppMobileFooter />
          </React.Fragment>
        )}
      />
    );
  }

  onSaveService = () => this.handleValidation(true).then(valid => {
    if (valid) {
      const { onSave, formData } = this.props;
      onSave(formData);
    }
  });

  handleCancel = () => {
    if (!size(this.props.changedFields)) {
      this.props.onCancel();
    } else {
      UserNotifications.confirmI18n('alert/confirm_quit_form', null, null, true)
        .then((value) => {
          if (value) {
            this.props.onCancel();
          }
        });
    }
  };

  handleValidation = (isSubmit: boolean = false): Promise<boolean> => new Promise((resolve: Function) => {
    const { formData, touchedFields } = this.props;
    const result = validator.validate(formData, touchedFields, isSubmit);
    this.setState({ errors: result.errors }, () => resolve(result.valid));
  });


  handleFormChange = (data: FormElementData) => {
    const { name, value } = data;
    this.setState({ [name]: value });
  };
}

const initialData = {
  price: 0,
};

export default withTranslation()(withForm(initialData)(ServiceTemplateForm));
