// @flow
import React from 'react';
import ReactDOM from 'react-dom';
import classnames from 'classnames';
import { rndId } from '@helpers/helpers';
import { CSSTransition } from 'react-transition-group';
import './Tooltip.scss';

type Props = {
  text: string,
  className?: string, // For container
  toolTipClass?: string,
  children?: any,
  keepVisibleOnHoverToolTip?: boolean, // keep tooltip's collision box
};

type State = {
  visible: boolean,
  top: number,
  left: number,
};

export default class Tooltip extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      visible: false,
      top: 0,
      left: 0,
    };
    this.elementUid = `tooltip${rndId()}`;
  }

  componentWillMount() {
    document.addEventListener('mousemove', this.handleMouseMove);
  }

  componentWillUnmount() {
    this.setState({ visible: false });
    document.removeEventListener('mousemove', this.handleMouseMove);
  }

  render() {
    const {
      children, className, text, toolTipClass,
    } = this.props;

    const classes = classnames(
      'elementToolTipContainer',
      className || '',
    );

    const toolTipClasses = classnames(
      'elementToolTip',
      toolTipClass || '',
    );

    const portalElement = ReactDOM.createPortal((
      <CSSTransition
        in={this.state.visible}
        timeout={300}
        classNames="tooltipFade"
        unmountOnExit
        key="portal-1"
      >
        <div
          ref={this.handleTooltipRef}
          className={toolTipClasses}
          id={this.elementUid}
          style={{
            top: this.state.top,
            left: this.state.left,
          }}
        >
          {text}
        </div>
      </CSSTransition>
    ), window.document.body);

    return [
      portalElement,
      (
        <span
          ref={this.handleContainerRef}
          className={classes}
          key="span"
        >
          {children}
        </span>
      ),
    ];
  }

  containerRef: any = null;
  tollTipRef: any = null;
  elementUid = '';

  updateElementPosition = () => {
    const containerOffSet = this.containerRef ? this.containerRef.getBoundingClientRect() : null;
    if (containerOffSet) {
      this.setState({
        top: (containerOffSet.top - containerOffSet.height) - 30,
        left: containerOffSet.left,
      });
    }
  }

  mouseInsideContainer = (event: Object) => {
    const divRect = this.containerRef ? this.containerRef.getBoundingClientRect() : null;

    return divRect && event.clientX >= divRect.left && event.clientX <= divRect.right &&
    event.clientY >= divRect.top && event.clientY <= divRect.bottom;
  };

  mouseInsideToolTip = (event: Object) => {
    if (!this.props.keepVisibleOnHoverToolTip) return false;

    const divRect = this.tollTipRef ? this.tollTipRef.getBoundingClientRect() : null;

    return divRect && event.clientX >= divRect.left && event.clientX <= divRect.right &&
    event.clientY >= divRect.top && event.clientY <= divRect.bottom;
  };

  handleMouseMove = (event: Object) => {
    if (this.mouseInsideContainer(event) || this.mouseInsideToolTip(event)) {
      this.showToolTip();
    } else {
      this.hideToolTip();
    }
  };

  frameStep = () => {
    this.updateElementPosition();
    if (this.state.visible) {
      window.requestAnimationFrame(this.frameStep);
    }
  };

  showToolTip = () => {
    if (this.state.visible === false) {
      this.setState({ visible: true }, this.frameStep);
    }
  };

  hideToolTip = () => {
    if (this.state.visible === true) {
      this.setState({ visible: false }, this.frameStep);
    }
  }

  handleContainerRef = (ref: any) => {
    this.containerRef = ReactDOM.findDOMNode(ref);
  };
  handleTooltipRef = (ref: any) => {
    this.tollTipRef = ReactDOM.findDOMNode(ref);
  };
}
