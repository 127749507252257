// @flow
import Immutable from 'seamless-immutable';
import _ from 'lodash';
import { actionTypes as auth } from './auth';
import { appControlTypes } from './appControl';
import userReducer from './context/user';
import clinicsReducer from './context/clinics';
import patientsReducer from './context/patients';
import modalsReducer from './context/modals';
import toastsReducer from './context/toasts';
import cordovaReducer from './context/cordova';
import cordovaPurchaseReducer from './context/cordovaPurchase';
import recordsReducer from './context/records';
import customFormsReducer from './context/customForms';
import customFormTemplatesReducer from './context/customFormTemplates';
import drugsPrescriptionsReducer from './context/drugsPrescriptions';
import drugsPrescriptionsTemplatesReducer from './context/drugsPrescriptionsTemplates';
import examsPrescriptionsReducer from './context/examsPrescriptions';
import examsPrescriptionsTemplatesReducer from './context/examsPrescriptionsTemplates';
import documentsReducer from './context/documents';
import documentsTemplatesReducer from './context/documentsTemplates';
import attachmentsReducer from './context/attachments';
import appointmentsReducer from './context/appointments';
import serviceTemplatesReducer from './context/serviceTemplates';
import servicesReducer from './context/services';
import paymentsReducer from './context/payments';
import receiptsReducer from './context/receipts';
import subscriptionReducer from './context/subscription';
import tagTemplatesReducer from './context/tagTemplates';
import reportsAppointmentsReducer from './context/reportsAppointments';
import reportsSmsReducer from './context/reportsSms';
import reportsDebitReducer from './context/reportsDebit';
import layoutReducer from './context/layout';
import indicatorsReducer from './context/indicators';
import type { ContextState, Action } from '../../types';

// ------------------------------------
// Actions
// ------------------------------------
export const actions = {};

// ------------------------------------
// Reducer
// ------------------------------------
export default function contextReducer (passedState: ContextState = Immutable({}), action: Action) {
  const state = _.reduce([
    userReducer,
    clinicsReducer,
    patientsReducer,
    modalsReducer,
    toastsReducer,
    cordovaReducer,
    cordovaPurchaseReducer,
    recordsReducer,
    customFormsReducer,
    customFormTemplatesReducer,
    drugsPrescriptionsReducer,
    drugsPrescriptionsTemplatesReducer,
    examsPrescriptionsReducer,
    examsPrescriptionsTemplatesReducer,
    documentsReducer,
    documentsTemplatesReducer,
    attachmentsReducer,
    appointmentsReducer,
    serviceTemplatesReducer,
    servicesReducer,
    paymentsReducer,
    receiptsReducer,
    tagTemplatesReducer,
    reportsAppointmentsReducer,
    reportsSmsReducer,
    reportsDebitReducer,
    subscriptionReducer,
    layoutReducer,
    indicatorsReducer,
  ], (s, reducer) => reducer(s, action), passedState);

  switch (action.type) {
    case auth.AUTH_LOGGED_OUT:
      return Immutable({});

    case appControlTypes.RESET_STORE:
      return Immutable({ inProgress: true });

    default:
      return state;
  }
}
