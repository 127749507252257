// @flow
import React, { useState, useEffect, useCallback } from 'react';
import { Spinner } from '@elements';
import { easyOpenCache } from '@helpers/helpers';

type Props = {
  src: string;
  className: string;
  style: any;
}

const CachedImage = ({ src, className, style }: Props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(false);
  const [imageSource, setImageSource] = useState(null);


  const loadImage = useCallback(() => {
    setIsLoading(true);
    easyOpenCache('cached-image-view', (cache) => {
      const raiseError = () => {
        setIsLoading(false);
        setError(true);
      };

      const request = () => fetch(src).then((response) => {
        if (response.ok) {
          response.blob().then((blob) => {
            const urlCreator = window.URL || window.webkitURL;
            setImageSource(urlCreator.createObjectURL(blob));
            setIsLoading(false);
            if (cache) {
              cache.add(src);
            }
          }).catch(raiseError);
        } else {
          raiseError();
        }
      }).catch(raiseError);

      if (cache) {
        cache.match(src, {
          ignoreSearch: true,
          ignoreVary: true,
        }).then((response) => {
          if (response && response.ok) {
            response.blob().then((blob) => {
              const urlCreator = window.URL || window.webkitURL;
              setImageSource(urlCreator.createObjectURL(blob));
              setIsLoading(false);
            }).catch(raiseError);
          } else {
            request();
          }
        }).catch(raiseError);
      } else {
        request();
      }
    });
  }, [src]);

  useEffect(() => {
    setIsLoading(true);
    loadImage();

    return () => {
      setIsLoading(true);
      loadImage();
    };
  }, [loadImage, src]);

  useEffect(() => () => {
    const urlCreator = window.URL || window.webkitURL;
    urlCreator.revokeObjectURL(imageSource);
  }, [imageSource]);

  useEffect(() => () => {
    const urlCreator = window.URL || window.webkitURL;
    urlCreator.revokeObjectURL(imageSource);
  });

  return (
    <div className="cachedImage">
      {!isLoading && !error && (
        <img src={imageSource} className={className} alt="img" style={style} />
      )}
      {isLoading && !error && (
        <Spinner/>
      )}
      {error && !isLoading && (
        <div>
          Ocorreu um erro ao carregar esta imagem
        </div>
      )}
    </div>
  );
};

// $FlowFixMe
export default React.memo(CachedImage);
