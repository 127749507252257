// @flow
import React from 'react';
import { connect } from 'react-redux';
import { get } from 'lodash';
import { logout } from '@context/user';
import { mapBundledPermission } from '@helpers/connect';
import { PrintConfig as PrintConfigView } from '../components/views';
import type { User, BundledPermissions } from '../types';


type Props = {
  user: User,
  dispatch: Function,
  permissions: BundledPermissions,
  isManager: boolean,
  isDoctor: boolean,
};

class PrintConfig extends React.Component<Props> {
  render() {
    const { user, isManager, isDoctor } = this.props;

    return (
      <PrintConfigView
        user={user}
        onLogout={this.handleLogout}
        permissions={this.props.permissions}
        isManager={isManager}
        isDoctor={isDoctor}
      />
    );
  }
  handleLogout = () => this.props.dispatch(logout());
}

const mapStateToProps = (state) => ({
  user: state.context.user,
  permissions: mapBundledPermission(state),
  isManager: get(state, ['context', 'user', 'advancedData', 'manager'], null),
  isDoctor: get(state, ['context', 'user', 'roleType'], null) === 'Doctor',
});

export default connect<any, any, any, any, any, any>(
  mapStateToProps,
)(PrintConfig);
