// @flow
import React from 'react';
import {
  filter, startsWith, toLower,
  map,
} from 'lodash';
import classnames from 'classnames';
import { Input, Icon, ClickableList } from '../';
import type { FormElementDataString } from '../../../types';
import './Autocomplete.scss';

type State = {
  isOpen: boolean,
  suggestions: Array<any>,
  autocompleteValue: string,
};

type Props = {
  placeholder?: string,
  identificator?: string,
  suggestions: Array<any>,
  onSelect: Function,
  onSearch?: Function,
  className?: string,
  icon?: string,
};

export default class Autocomplete extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isOpen: false,
      suggestions: props.suggestions || [],
      autocompleteValue: '',
    };
  }

  componentWillReceiveProps(nextProps: Props) {
    if (this.props.suggestions || nextProps.suggestions) {
      this.setState({ suggestions: nextProps.suggestions });
    }
  }

  render() {
    const { isOpen, suggestions, autocompleteValue } = this.state;
    const {
      placeholder, className, identificator,
      icon,
    } = this.props;

    const nameIdentificator = identificator || 'label';
    const cssClasses = classnames(['autocompleteWrapper', className]);

    const rendererData = [];
    map<any, any>(suggestions, (suggestion, key) => {
      const data = {
        customRendererData: {
          id: suggestion.id,
          value: suggestion[nameIdentificator],
          key: suggestion.id || key,
          fullSuggestion: suggestion,
        },
      };
      rendererData.push(data);
    });

    const renderer = (item) => (
      <div
        className='suggestion'
        key={item.id || item.key}
        onClick={this.onSelectSuggestion.bind(this, item.fullSuggestion)}
      >
        {item.value}
      </div>
    );

    return (
      <div className={cssClasses}>
        <Input
          placeholder={placeholder}
          className="autocompleteInput"
          onChange={this.handleInputChange.bind(this)}
          value={autocompleteValue}
        />

        {icon &&
          <Icon
            iconName={icon}
            className="icon"
            size={15}
          />
        }

        {isOpen && suggestions && suggestions.length > 0 &&
          <div className='suggestionsWrapper'>
            <ClickableList
              className='suggestions'
              items={rendererData}
              customRenderer={renderer}
            />
          </div>
        }
      </div>
    );
  }

  onSelectSuggestion = (item: any) => {
    this.setState({
      isOpen: false,
      autocompleteValue: '',
    });
    this.props.onSelect(item);
  };

  handleInputChange = (params: FormElementDataString) => {
    const { value } = params;
    let isOpen;
    if (value.length > 0) {
      this.onSearch(value);
      isOpen = true;
    } else {
      isOpen = false;
    }
    this.setState({
      isOpen,
      autocompleteValue: value,
    });
  };

  onSearch = (value: string) => {
    const { identificator, suggestions } = this.props;
    let filteredSuggestions;

    if (this.props.onSearch) {
      this.props.onSearch(value);
    } else {
      filteredSuggestions = filter(suggestions, (suggestion) => {
        const forMatch = toLower(suggestion[identificator]);
        const matchWith = toLower(value);
        return startsWith(forMatch, matchWith);
      });
      this.setState({ suggestions: filteredSuggestions });
    }
    this.setState({
      isOpen: true,
      suggestions: filteredSuggestions || suggestions,
    });
  };

  handleOutsideClick = (e: Object) => {
    const autocompleteInteraction = filter(e.path, (element) => (
      element.id === 'autocomplete'
    ));

    filter(e.path, (element) => (
      element.id === 'autocomplete'
    ));

    // Close menu on outside click
    if (!autocompleteInteraction.length && this.state.isOpen) {
      this.setState({ isOpen: false });
    }
  };

  handleEscKeyPress = () => {
    this.setState({ isOpen: false });
  };
}
