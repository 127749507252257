// @flow

export const appointmentModalTabItems = (_: Function) => [
  { title: _('label/new_appointment'), value: 1 },
  { title: _('label/blocked_time'), value: 2 },
];


export const deletePromptItems = (_: Function) => [
  {
    text: _('action/delete_only'),
    customRendererData: {
      action: 'delete_only',
    },
  },
  {
    text: _('action/delete_future'),
    customRendererData: {
      action: 'delete_future',
    },
  },
  {
    text: _('action/cancel', 'global'),
    customRendererData: {
      action: 'cancel',
    },
  },
];
