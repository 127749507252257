// @flow
import Immutable from 'seamless-immutable';
import axios from 'axios';
import querystring from 'querystring';
import {
  get, omit, concat,
  toString, uniq, map,
  dropRight, merge, filter, forEach, toNumber, first,
} from 'lodash';
import jq from 'jquery';
import type {
  Action,
  Dispatch,
  ContextState,
  RecordForm,
} from '@types';
import { slugify, reportError, UserNotifications, isPresent, fsPrintf } from '@helpers/helpers';
import { Api } from '../../../helpers';
import RecordService from '../../../services/RecordService';
import { router } from '../../../routes/router';
import { attachmentActions } from './attachments';
import showPDF, { socialShareFile } from '../../middleware/showPDF';
import { appointmentActions } from './appointments';


// ------------------------------------
// Constants
// ------------------------------------
const RECORDS_LOADING = 'records.RECORDS_LOADING';
const RECORDS_LOADED = 'records.RECORDS_LOADED';
const RECORDS_LOADING_FAILED = 'records.RECORDS_LOADING_FAILED';

const RECORDS_RESTORE_LOADING = 'records.RECORDS_RESTORE_LOADING';
const RECORDS_RESTORE_LOADED = 'records.RECORDS_RESTORE_LOADED';
const RECORDS_RESTORE_LOADING_FAILED = 'records.RECORDS_RESTORE_LOADING_FAILED';

const FUTURE_RECORDS_LOADING = 'records.FUTURE_RECORDS_LOADING';
const FUTURE_RECORDS_LOADED = 'records.FUTURE_RECORDS_LOADED';
const FUTURE_RECORDS_LOADING_FAILED = 'records.FUTURE_RECORDS_LOADING_FAILED';

const RECORD_LOADING = 'records.RECORD_LOADING';
const RECORD_LOADED = 'records.RECORD_LOADED';
const RECORD_LOADING_FAILED = 'records.RECORD_LOADING_FAILED';

const RECORD_CREATE_IN_PROGRESS = 'records.RECORD_CREATE_IN_PROGRESS';
const RECORD_CREATE_SUCCESS = 'records.RECORD_CREATE_SUCCESS';
const RECORD_CREATE_FAILED = 'records.RECORD_CREATE_FAILED';

const RECORD_UPDATE_IN_PROGRESS = 'records.RECORD_UPDATE_IN_PROGRESS';
const RECORD_UPDATE_SUCCESS = 'records.RECORD_UPDATE_SUCCESS';
const RECORD_UPDATE_FAILED = 'records.RECORD_UPDATE_FAILED';

const RECORD_UPDATE_DATE_IN_PROGRESS = 'records.RECORD_UPDATE_DATE_IN_PROGRESS';
const RECORD_UPDATE_DATE_SUCCESS = 'records.RECORD_UPDATE_DATE_SUCCESS';
const RECORD_UPDATE_DATE_FAILED = 'records.RECORD_UPDATE_DATE_FAILED';

const RECORD_AUTOSAVE_IN_PROGRESS = 'records.RECORD_AUTOSAVE_IN_PROGRESS';
const RECORD_AUTOSAVE_SUCCESS = 'records.RECORD_AUTOSAVE_SUCCESS';
const RECORD_AUTOSAVE_FAILED = 'records.RECORD_AUTOSAVE_FAILED';

const RECORD_DELETE_IN_PROGRESS = 'records.RECORD_DELETE_IN_PROGRESS';
const RECORD_DELETE_SUCCESS = 'records.RECORD_DELETE_SUCCESS';
const RECORD_DELETE_FAILED = 'records.RECORD_DELETE_FAILED';

const GET_SIGNED_UPLOAD_DATA = 'records.GET_SIGNED_UPLOAD_DATA';
const GET_SIGNED_UPLOAD_DATA_ERROR = 'records.GET_SIGNED_UPLOAD_DATA_ERROR';

const UPLOAD_TO_S3 = 'records.UPLOAD_TO_S3';
const UPLOAD_TO_S3_PROGRESS = 'records.UPLOAD_TO_S3_PROGRESS';
const UPLOAD_TO_S3_FAILED = 'records.UPLOAD_TO_S3_FAILED';

const SET_RECORD_ATTACHMENT = 'records.SET_RECORD_ATTACHMENT';
const SET_RECORD_ATTACHMENT_SUCCESS = 'records.SET_RECORD_ATTACHMENT_SUCCESS';
const SET_RECORD_ATTACHMENT_ERROR = 'records.SET_RECORD_ATTACHMENT_ERROR';

const SINGLE_RECORD_SHOW_PDF_IN_PROGRESS = 'records.SINGLE_RECORD_SHOW_PDF_IN_PROGRESS';
const SINGLE_RECORD_SHOW_PDF_SUCCESS = 'records.SINGLE_RECORD_SHOW_PDF_SUCCESS';
const SINGLE_RECORD_SHOW_PDF_FAILED = 'records.SINGLE_RECORD_SHOW_PDF_FAILED';

const ALL_RECORDS_SHOW_PDF_IN_PROGRESS = 'records.ALL_RECORDS_SHOW_PDF_IN_PROGRESS';
const ALL_RECORDS_SHOW_PDF_SUCCESS = 'records.ALL_RECORDS_SHOW_PDF_SUCCESS';
const ALL_RECORDS_SHOW_PDF_FAILED = 'records.ALL_RECORDS_SHOW_PDF_FAILED';

const RECORD_UPDATE_VISIBILITY_IN_PROGRESS = 'records.RECORD_UPDATE_VISIBILITY_IN_PROGRESS';
const RECORD_UPDATE_VISIBILITY_SUCCESS = 'records.RECORD_UPDATE_VISIBILITY_SUCCESS';
const RECORD_UPDATE_VISIBILITY_FAILED = 'records.RECORD_UPDATE_VISIBILITY_FAILED';

const RECORD_SET_EDIT_MODE = 'records.RECORD_SET_EDIT_MODE';

const RECORD_MEMED_ADD_ID_IN_PROGRESS = 'records.RECORD_MEMED_ADD_ID_IN_PROGRESS';
const RECORD_MEMED_ADD_ID_SUCCESS = 'records.RECORD_MEMED_ADD_ID_SUCCESS';
const RECORD_MEMED_ADD_ID_FAILED = 'records.RECORD_MEMED_ADD_ID_FAILED';

const RECORD_MEMED_REMOVE_ID_IN_PROGRESS = 'records.RECORD_MEMED_REMOVE_ID_IN_PROGRESS';
const RECORD_MEMED_REMOVE_ID_SUCCESS = 'records.RECORD_MEMED_REMOVE_ID_SUCCESS';
const RECORD_MEMED_REMOVE_ID_FAILED = 'records.RECORD_MEMED_REMOVE_ID_FAILED';

// ------------------------------------
// Actions
// ------------------------------------

const allowSetEditMode = (getState: Function, recordId: number | null, patientId: number) => {
  let editModeMap = [];

  forEach(get(getState(), ['context', 'records', 'editModeList']), (item, id) => {
    editModeMap.push({ isOpen: item.editing, id: toNumber(id), patientId: item.patientId });
  });


  // remove closed records from list
  editModeMap = filter(editModeMap, (item) => item.isOpen);

  const validRecordId = recordId === null ? true : get(first(editModeMap), 'id') !== toNumber(recordId);

  const conflicts = [];

  forEach(editModeMap, (sample) => {
    if (validRecordId && get(sample, 'isOpen') && toNumber(sample.patientId) === toNumber(patientId)) {
      conflicts.push(sample);
    }
  });

  if (conflicts.length > 0) {
    UserNotifications.recordFriendlyAlert('info/finalize_record_tip/blocked_edit').then(() => {
      const recordsContentWrapper = document.querySelector('.recordsContentWrapper');
      const scrollTo = document.querySelector('.recordsContentWrapper > .registers > .openRegister');
      const container = document.querySelector('#timelinePage > section.mainSection');
      if (recordsContentWrapper && scrollTo && container) {
        jq(container).animate({
          scrollTop: jq(scrollTo).offset().top - jq(recordsContentWrapper).offset().top,
        });
      }
    });

    return false;
  }

  return true;
};


export const setEditMode = (recordId:number, isEditMode: boolean, patientId: number) => (
  (dispatch: Dispatch, getState: Function) => {
    if (allowSetEditMode(getState, recordId, patientId)) {
      dispatch({ type: RECORD_SET_EDIT_MODE, isEditMode, recordId, patientId });
    }
  }
);

export const directShare = (
  patientId: number, recordId: number, resource: 'examsPrescriptions' | 'drugsPrescriptions' | 'documents',
  resourceId: number, respondWithUrls: (wtt: string | null, mail: string | null) => void) =>
  (dispatch: Dispatch, getState: Function) => {
    const patient = get(getState(), ['context', 'patients', 'data', toString(patientId)]);

    const doctorNameWithTitle = get(getState(), ['context', 'user', 'nameWithTitle']);

    const tFn = (key: string, fallback: string) => (window.i18n.t ? window.i18n.t(key) : fallback);


    const routeMaps = {
      examsPrescriptions: 'sharingExamsPrescriptions',
      drugsPrescriptions: 'sharingDrugsPrescriptions',
      documents: 'sharingDocuments',
    };

    const subjectMap = {
      examsPrescriptions: () => tFn('timeline:message/url_share_exam/subject', ''),
      drugsPrescriptions: () => tFn('timeline:message/url_share_receipt/subject', ''),
      documents: () => tFn('timeline:message/url_share_doc/subject', ''),
    };

    const bodyMap = {
      examsPrescriptions: (drName: string, url: string) => fsPrintf(drName, url)(tFn('timeline:message/url_share_exam', '')),
      drugsPrescriptions: (drName: string, url: string) => fsPrintf(drName, url)(tFn('timeline:message/url_share_receipt', '')),
      documents: (drName: string, url: string) => fsPrintf(drName, url)(tFn('timeline:message/url_share_doc', '')),
    };

    Api.get(router.getApiRoute(get(routeMaps, resource), { id: resourceId, recordId }), {
      success: (response) => {
        const link = get(response, 'link');

        if (!isPresent(link)) {
          dispatch(reportError('shareLink'));
          return;
        }

        const subject = encodeURI(subjectMap[resource]());
        const mailToMessage = encodeURI(bodyMap[resource](doctorNameWithTitle, link));
        const email = encodeURI(get(patient, 'email', ''));
        const mailToHref = `mailto:${email}?subject=${subject}&body=${mailToMessage}`;

        const wttMessage = encodeURI(bodyMap[resource](doctorNameWithTitle, link));
        const phone = encodeURI(`+55${get(patient, 'mobilePhone', '')}`);
        const wttHref = `https://api.whatsapp.com/send?phone=${phone}&text=${wttMessage}`;

        return respondWithUrls(wttHref, mailToHref);
      },
      error: (error) => {
        respondWithUrls(null, null);
        dispatch(reportError(error));
      },
    });
  };

export const updateRecordDate = (id: number, date: string) => (
  (dispatch: Dispatch) => {
    dispatch({ type: RECORD_UPDATE_DATE_IN_PROGRESS, id });
    Api.patch(router.getApiRoute('updateRecordDate', { id }), {
      data: {
        date,
      },
      success: () => {
        dispatch({ type: RECORD_UPDATE_DATE_SUCCESS, date, recordId: id });
      },
      error: (error) => {
        dispatch({ type: RECORD_UPDATE_DATE_FAILED, error: { message: error.message }, id });
        dispatch(reportError(error));
      },
    });
  }
);


export const updateRecordVisibility = (id: number, level: number) => (
  (dispatch: Dispatch) => {
    dispatch({ type: RECORD_UPDATE_VISIBILITY_IN_PROGRESS, id });
    Api.patch(router.getApiRoute('updateRecordVisibility', { id, level }), {
      data: { },
      success: () => {
        dispatch({ type: RECORD_UPDATE_VISIBILITY_SUCCESS, level, recordId: id });
      },
      error: (error) => {
        dispatch({ type: RECORD_UPDATE_VISIBILITY_FAILED, error: { message: error.message }, id });
        dispatch(reportError(error));
      },
    });
  }
);

export const getRecords = (patientId: number) => (
  (dispatch: Dispatch) => {
    dispatch({ type: RECORDS_LOADING });
    Api.get(router.getApiRoute('getRecords', { id: patientId }), {
      success: (response) => {
        dispatch({ type: RECORDS_LOADED, patientId, records: response.records });
      },
      error: (error) => {
        dispatch({ type: RECORDS_LOADING_FAILED, error: { message: error.message } });
        dispatch(reportError(error));
      },
    });
  }
);

export const getFutureRecords = (patientId: number) => (
  (dispatch: Dispatch) => {
    dispatch({ type: FUTURE_RECORDS_LOADING });
    Api.get(router.getApiRoute('getFutureRecords', { id: patientId }), {
      success: (response) => {
        dispatch({ type: FUTURE_RECORDS_LOADED, patientId, futureAppointments: response.futureAppointments });
      },
      error: (error) => {
        dispatch({ type: FUTURE_RECORDS_LOADING_FAILED, error: { message: error.message } });
        dispatch(reportError(error));
      },
    });
  }
);

export const getRecord = (recordId: number) => (
  (dispatch: Dispatch) => {
    dispatch({ type: RECORD_LOADING });
    Api.get(router.getApiRoute('getRecord', { id: recordId }), {
      success: (response) => {
        dispatch({ type: RECORD_LOADED, record: response });
      },
      error: (error) => {
        dispatch({ type: RECORD_LOADING_FAILED, error: { message: error.message } });
        dispatch(reportError(error));
      },
    });
  }
);

export const createRecord = (patientId: number, record: RecordForm) => (
  (dispatch: Dispatch, getState: Function) => {
    if (allowSetEditMode(getState, null, patientId)) {
      dispatch({ type: RECORD_CREATE_IN_PROGRESS });
      Api.post(router.getApiRoute('createRecord', { id: patientId }), {
        data: { record },
        success: (response) => {
          dispatch({ type: RECORD_CREATE_SUCCESS, patientId, record: response.data });
        },
        error: (error) => {
          dispatch({ type: RECORD_CREATE_FAILED, error: { message: error.message } });
          dispatch(reportError(error));
        },
      });
    }
  }
);

export const updateRecord = (record: RecordService, patientId: number) => (
  (dispatch: Dispatch) => {
    dispatch({ type: RECORD_UPDATE_IN_PROGRESS, id: record.id });
    Api.patch(router.getApiRoute('updateRecord', { id: record.id }), {
      data: { record },
      success: (response) => {
        dispatch({ type: RECORD_UPDATE_SUCCESS, record: response });
        dispatch(setEditMode(record.id, false, patientId));
        dispatch(appointmentActions.resetPatientAppointmentsCache(patientId));
      },
      error: (error) => {
        dispatch({ type: RECORD_UPDATE_FAILED, error: { message: error.message }, id: record.id });
        dispatch(reportError(error));
      },
    });
  }
);

export const addMemedIds = (recordId:number, memedPrescriptionId: number, doctorId: number, prescription: Object) => (
  (dispatch: Dispatch) => {
    dispatch({ type: RECORD_MEMED_ADD_ID_IN_PROGRESS, recordId });
    Api.post(router.getApiRoute('addMemedIds', { recordId }), {
      data: { memedPrescriptionId, doctorId, prescription },
      success: (response) => {
        const data = get(response, ['data', 'memedPrescription']);
        dispatch({ type: RECORD_MEMED_ADD_ID_SUCCESS, data, recordId });
      },
      error: (error) => {
        dispatch({ type: RECORD_MEMED_ADD_ID_FAILED, error: { message: error.message }, recordId });
        dispatch(reportError(error));
      },
    });
  }
);

export const removeMemedId = (recordId:number, integrationId: number) => (
  (dispatch: Dispatch) => {
    dispatch({ type: RECORD_MEMED_REMOVE_ID_IN_PROGRESS, recordId });
    Api.destroy(router.getApiRoute('removeMemedId', { recordId, integrationId }), {
      success: () => {
        dispatch({
          type: RECORD_MEMED_REMOVE_ID_SUCCESS, recordId, integrationId,
        });
      },
      error: (error) => {
        dispatch({ type: RECORD_MEMED_REMOVE_ID_FAILED, error: { message: error.message }, recordId });
        dispatch(reportError(error));
      },
    });
  }
);

export const autosaveRecord = (record: RecordService) => (
  (dispatch: Dispatch) => {
    dispatch({ type: RECORD_AUTOSAVE_IN_PROGRESS, id: record.id });
    Api.patch(router.getApiRoute('updateRecord', { id: record.id }), {
      data: { record },
      success: (response) => {
        dispatch({ type: RECORD_AUTOSAVE_SUCCESS, record: response });
      },
      error: (error) => {
        dispatch({ type: RECORD_AUTOSAVE_FAILED, error: { message: error.message }, id: record.id });
        dispatch(reportError(error));
      },
    });
  }
);

export const deleteRecord = (recordId: number, patientId: number) => (
  (dispatch: Dispatch) => {
    dispatch({ type: RECORD_DELETE_IN_PROGRESS, id: recordId });
    Api.destroy(router.getApiRoute('deleteRecord', { id: recordId }), {
      success: (response) => {
        let record = response;
        if (get(response, 'record')) {
          record = get(response, 'record');
        }
        dispatch({
          type: RECORD_DELETE_SUCCESS, recordId, patientId, record,
        });
      },
      error: (error) => {
        dispatch({ type: RECORD_DELETE_FAILED, error: { message: error.message }, id: recordId });
        dispatch(reportError(error));
      },
    });
  }
);

export const restoreRecord = (recordId: number, cb?: Function) => (
  (dispatch: Dispatch) => {
    dispatch({ type: RECORDS_RESTORE_LOADING, recordId });
    Api.patch(router.getApiRoute('restoreRecord', { id: recordId }), {
      success: (response) => {
        let record = response;
        if (get(response, 'record')) {
          record = get(response, 'record');
        }
        dispatch({
          type: RECORDS_RESTORE_LOADED, record,
        });
        if (cb) {
          cb(true);
        }
      },
      error: (error) => {
        dispatch(reportError(error));
        dispatch({ type: RECORDS_RESTORE_LOADING_FAILED, error: { message: error.message }, recordId });
        if (cb) {
          cb(false);
        }
      },
    });
  }
);

export const uploadRecordAttachment = (recordId: number, file: Object, type: string) => (
  (dispatch: Dispatch) => {
    dispatch({ type: GET_SIGNED_UPLOAD_DATA });
    const route = router.getApiRoute('getSignedUploadData', null);
    const fileName = String(slugify(file.name)).toLowerCase();
    const routePath = `${route.path}?title=${fileName}`;

    const apiRoute = {
      ...route,
      path: routePath,
    };

    Api.get(apiRoute, {
      success: (response) => {
        let { signedData } = response;

        // Decode PDF binary data ONLY to flat data
        if (file.type === 'application/pdf') {
          const decoder = new TextDecoder('utf-8');
          const binaryData = new Uint8Array(response);
          // eslint-disable-next-line
          signedData = JSON.parse(decoder.decode(binaryData)).signedData;
        }

        const formData = new FormData();
        formData.append('key', signedData.key);
        formData.append('acl', signedData.acl);
        formData.append('policy', signedData.policy);
        formData.append('x-amz-credential', signedData['x-amz-credential']);
        formData.append('x-amz-algorithm', signedData['x-amz-algorithm']);
        formData.append('x-amz-date', signedData['x-amz-date']);
        formData.append('x-amz-signature', signedData['x-amz-signature']);
        formData.append('file', file);
        const attachmentId = signedData.key.split('/')[1];

        // UPLOAD TO AWS S3 BUCKET
        const tempFile = {
          id: attachmentId,
          name: fileName,
          type,
          processing: true,
          uploading: false,
          blob: null,
        };
        dispatch({ type: UPLOAD_TO_S3, recordId, tempFile });
        dispatch({
          type: UPLOAD_TO_S3_PROGRESS, recordId, tempFile, progress: 0,
        });

        const signedDataApiRoute = {
          path: signedData.action,
        };

        Api.post(signedDataApiRoute, {
          data: formData,
          onUploadProgress: (progressEvent) => {
            const totalLength = progressEvent.lengthComputable ?
              progressEvent.total : progressEvent.target.getResponseHeader('content-length')
            || progressEvent.target.getResponseHeader('x-decompressed-content-length');
            if (totalLength !== null) {
              const progress = Math.round((progressEvent.loaded * 100) / totalLength);
              dispatch({
                type: UPLOAD_TO_S3_PROGRESS, recordId, tempFile, progress,
              });
            }
          },
          success: () => {
            const directUploadUrl = signedData.action + '/' + encodeURIComponent(dropRight(signedData.key.split('/')).join('/') + '/' + fileName);
            const attachmentData = {
              tempId: attachmentId,
              name: fileName,
              dimensions: `${file.width}x${file.height}`,
              directUploadUrl,
              fileContentType: file.type,
              lsId: file.lastModified,
            };

            const processedFile = {
              id: attachmentId,
              name: fileName,
              type,
              processing: false,
              uploading: true,
              blob: directUploadUrl,
            };

            dispatch({ type: SET_RECORD_ATTACHMENT, processedFile, recordId });

            // Create attachment and return data through action to continue with record update
            dispatch(attachmentActions.createRecordAttachment(recordId, attachmentData));
          },
          error: (err) => {
            dispatch({
              type: UPLOAD_TO_S3_FAILED,
              recordId,
              tempFile,
              error: { message: err.message },
            });
            dispatch(reportError(err));
          },
        });
      },
      error: (error) => {
        dispatch({ type: GET_SIGNED_UPLOAD_DATA_ERROR, recordId, error: { message: error.message } });
        dispatch(reportError(error));
      },
    });
  }
);


const pdfApiRoute = (routeName, params, search?: string = '') => {
  const route = router.getApiRoute(routeName, params);
  return {
    ...route,
    path: `${route.path}.pdf${search || ''}`,
  };
};

export const showSingleRecordPDF = (recordId: number, simple: boolean, showSignature: boolean) => showPDF.action({
  title: 'Record PDF',
  apiRoute: pdfApiRoute('showSingleRecordPDF', { recordId }, '?' + querystring.stringify({ simple, show_signature: showSignature })),
  prepare: SINGLE_RECORD_SHOW_PDF_IN_PROGRESS,
  success: SINGLE_RECORD_SHOW_PDF_SUCCESS,
  error: SINGLE_RECORD_SHOW_PDF_FAILED,
});


export const printMemedPrescription = (id: number, memedToken: string) => (
  async (dispatch: Dispatch) => {
    try {
      const response = await axios.get(`${window.MEMED_API_BASE_URL}/v1/prescricoes/${id}/url-document/full?token=${memedToken}`);

      const prescriptionUrl = get(response.data, ['data', '0', 'attributes', 'link'], null);

      const isSharePluginEnabled = window.isCordovaApp && ((!!window.plugins && !!window.plugins.socialsharing) || (!!window.plugins && !!window.plugins.PrintPDF));

      if (isSharePluginEnabled) {
        const prescriptionResponse = await axios.get(prescriptionUrl, { responseType: 'blob' });

        dispatch({
          type: 'toasts.OPEN_TOAST',
          data: {
            type: 'success', text: 'Compartilhando PDF', fileURL: undefined, close: true, timeout: 10000,
          },
        });

        socialShareFile(prescriptionResponse.data, id, dispatch);
      } else {
        dispatch({
          type: 'toasts.OPEN_TOAST',
          data: {
            type: 'success', text: 'PDF pronto', fileURL: prescriptionUrl, close: true, timeout: 10000,
          },
        });
      }
    } catch (error) {
      dispatch(reportError(error));
    }
  });

export const showAllRecordsPDF = (patientId: number) => showPDF.action({
  title: 'Records PDF',
  apiRoute: pdfApiRoute('showAllRecordsPDF', { patientId }),
  prepare: ALL_RECORDS_SHOW_PDF_IN_PROGRESS,
  success: ALL_RECORDS_SHOW_PDF_SUCCESS,
  error: ALL_RECORDS_SHOW_PDF_FAILED,
});


export const recordActions = {
  getRecords,
  getFutureRecords,
  getRecord,
  createRecord,
  updateRecord,
  deleteRecord,
  uploadRecordAttachment,
  showSingleRecordPDF,
  showAllRecordsPDF,
  autosaveRecord,
  updateRecordVisibility,
  setEditMode,
  addMemedIds,
  printMemedPrescription,
  removeMemedId,
  restoreRecord,
};

export const recordTypes = {
  SET_RECORD_ATTACHMENT_SUCCESS,
  SET_RECORD_ATTACHMENT_ERROR,
};

// ------------------------------------
// Reducer
// ------------------------------------
export default function recordsReducer (state: ContextState, action: Action): ContextState {
  let normalized;
  let recordIdsByPatient;
  let drugsPrescriptions;
  let drugsPrescriptionIdsByRecords;
  let examsPrescriptions;
  let examsPrescriptionIdsByRecords;
  let documents;
  let documentIdsByRecords;
  let customForms;
  let customFormIdsByRecords;
  let attachments;
  let attachmentIdsByRecords;
  let currentRecordsData;
  let currentRecordsByPatient;
  let currentDrugsPrescriptionsData;
  let currentDrugsPrescriptionsByRecord;
  let currentExamsPrescriptionsData;
  let currentExamsPrescriptionsByRecord;
  let currentDocumentsData;
  let currentDocumentsByRecord;
  let currentCustomFormsData;
  let currentCustomFormsByRecord;
  let currentAttachmentsData;
  let currentTempFiles;
  let tempFiles;

  switch (action.type) {
    case RECORDS_LOADING:
      return state
        .setIn(['records', 'isLoading'], true)
        .setIn(['records', 'errors'], null);

    case RECORDS_LOADED:
      normalized = RecordService.normalizeRecords(action.records);
      recordIdsByPatient = RecordService.extractRecordIdsByPatient(action.patientId, action.records);
      drugsPrescriptions = RecordService.extractEntityFromRecords(action.records, 'drugsPrescriptions');
      drugsPrescriptionIdsByRecords = RecordService.extractEntityIdsByRecords(action.records, 'drugsPrescriptions');
      examsPrescriptions = RecordService.extractEntityFromRecords(action.records, 'examsPrescriptions');
      examsPrescriptionIdsByRecords = RecordService.extractEntityIdsByRecords(action.records, 'examsPrescriptions');
      documents = RecordService.extractEntityFromRecords(action.records, 'documents');
      documentIdsByRecords = RecordService.extractEntityIdsByRecords(action.records, 'documents');
      customForms = RecordService.extractEntityFromRecords(action.records, 'customForms');
      customFormIdsByRecords = RecordService.extractEntityIdsByRecords(action.records, 'customForms');
      attachments = RecordService.extractEntityFromRecords(action.records, 'attachments');
      attachmentIdsByRecords = RecordService.extractEntityIdsByRecords(action.records, 'attachments');

      currentRecordsData = Immutable(get(state, ['records', 'data'], {}));
      currentRecordsByPatient = Immutable(get(state, ['records', 'byPatient'], {}));
      currentDrugsPrescriptionsData = Immutable(get(state, ['drugsPrescriptions', 'data'], {}));
      currentDrugsPrescriptionsByRecord = Immutable(get(state, ['drugsPrescriptions', 'byRecord'], {}));
      currentExamsPrescriptionsData = Immutable(get(state, ['examsPrescriptions', 'data'], {}));
      currentExamsPrescriptionsByRecord = Immutable(get(state, ['examsPrescriptions', 'byRecord'], {}));
      currentDocumentsData = Immutable(get(state, ['documents', 'data'], {}));
      currentDocumentsByRecord = Immutable(get(state, ['documents', 'byRecord'], {}));
      currentCustomFormsData = Immutable(get(state, ['customForms', 'data'], {}));
      currentCustomFormsByRecord = Immutable(get(state, ['customForms', 'byRecord'], {}));
      currentAttachmentsData = Immutable(get(state, ['attachments', 'data'], {}));
      // currentAttachmentsByRecord = Immutable(get(state, ['attachments', 'byRecord'], {}));

      return state
        .setIn(['records', 'isLoading'], false)
        .setIn(['records', 'lastFetchFor', toString(action.patientId)], (new Date()).toISOString())
        .setIn(['records', 'isCreating'], false)
        .setIn(['records', 'errors'], null)
        .setIn(['records', 'updatingIds'], {})
        .setIn(['records', 'data'], currentRecordsData.merge(normalized))
        .setIn(['records', 'byPatient'], currentRecordsByPatient.merge(recordIdsByPatient))
        .setIn(['drugsPrescriptions', 'data'], currentDrugsPrescriptionsData.merge(drugsPrescriptions))
        .setIn(['drugsPrescriptions', 'byRecord'], currentDrugsPrescriptionsByRecord.merge(drugsPrescriptionIdsByRecords))
        .setIn(['examsPrescriptions', 'data'], currentExamsPrescriptionsData.merge(examsPrescriptions))
        .setIn(['examsPrescriptions', 'byRecord'], currentExamsPrescriptionsByRecord.merge(examsPrescriptionIdsByRecords))
        .setIn(['documents', 'data'], currentDocumentsData.merge(documents))
        .setIn(['documents', 'byRecord'], currentDocumentsByRecord.merge(documentIdsByRecords))
        .setIn(['customForms', 'data'], currentCustomFormsData.merge(customForms))
        .setIn(['customForms', 'byRecord'], currentCustomFormsByRecord.merge(customFormIdsByRecords))
        .setIn(['attachments', 'data'], currentAttachmentsData.merge(attachments))
        .setIn(['attachments', 'byRecord'], attachmentIdsByRecords);

    case RECORDS_LOADING_FAILED:
      return state
        .setIn(['records', 'isLoading'], false)
        .setIn(['records', 'isCreating'], false)
        .setIn(['records', 'errors'], [action.error.message]);

    case FUTURE_RECORDS_LOADING:
      return state
        .setIn(['futureRecords', 'isLoading'], true)
        .setIn(['futureRecords', 'errors'], null);

    case FUTURE_RECORDS_LOADED:
      normalized = RecordService.normalizeRecords(action.futureAppointments);
      recordIdsByPatient = RecordService.extractRecordIdsByPatient(action.patientId, action.futureAppointments);
      currentRecordsData = Immutable(get(state, ['futureRecords', 'data'], {}));
      currentRecordsByPatient = Immutable(get(state, ['futureRecords', 'byPatient'], {}));
      return state
        .setIn(['futureRecords', 'isLoading'], false)
        .setIn(['records', 'lastFutureFetchFor', toString(action.patientId)], (new Date()).toISOString())
        .setIn(['futureRecords', 'errors'], null)
        .setIn(['futureRecords', 'data'], currentRecordsData.merge(normalized))
        .setIn(['futureRecords', 'byPatient'], currentRecordsByPatient.merge(recordIdsByPatient));

    case FUTURE_RECORDS_LOADING_FAILED:
      return state
        .setIn(['futureRecords', 'isLoading'], false)
        .setIn(['futureRecords', 'errors'], [action.error.message]);

    case RECORDS_RESTORE_LOADING:
      return state
        .setIn(['records', 'isRestoring'], true)
        .setIn(['records', 'errors'], null);

    case RECORDS_RESTORE_LOADED:
      return state
        .setIn(['records', 'isRestoring'], false)
        .setIn(['records', 'data'], { ...get(state, ['records', 'data'], {}), [get(action, ['record', 'id'])]: RecordService.normalizeRecord(action.record) })
        .setIn(['records', 'isRestoring'], false);

    case RECORDS_RESTORE_LOADING_FAILED:
      return state
        .setIn(['records', 'isRestoring'], false)
        .setIn(['records', 'errors'], [action.error.message]);

    case RECORD_LOADING:
      return state
        .setIn(['records', 'isLoading'], true)
        .setIn(['records', 'errors'], null);

    case RECORD_LOADED:
      currentRecordsByPatient = get(state, ['records', 'byPatient', action.record.patientId.toString()], []);
      return state
        .setIn(['records', 'isLoading'], false)
        .setIn(['records', 'errors'], null)
        .setIn(['records', 'data', action.record.id], action.record)
        .setIn(['records', 'byPatient', action.record.patientId], uniq(concat(currentRecordsByPatient, [action.record.id])));

    case RECORD_LOADING_FAILED:
      return state
        .setIn(['records', 'isLoading'], false)
        .setIn(['records', 'errors'], [action.error.message]);

    case RECORD_CREATE_IN_PROGRESS:
      return state
        .setIn(['records', 'isCreating'], true)
        .setIn(['records', 'errors'], null);

    case RECORD_CREATE_SUCCESS:
      currentRecordsByPatient = get(state, ['records', 'byPatient', toString(action.patientId)]) || Immutable([]);
      return state
        .setIn(['records', 'isCreating'], false)
        .setIn(['records', 'errors'], null)
        .setIn(['records', 'data', action.record.id], action.record)
        .setIn(['records', 'editModeList'], { [action.record.id]: {
          patientId: action.patientId,
          editing: true,
        } })
        .setIn(['records', 'byPatient', action.patientId], concat(currentRecordsByPatient, [action.record.id]));

    case RECORD_CREATE_FAILED:
      return state
        .setIn(['records', 'isCreating'], false)
        .setIn(['records', 'errors'], [action.error.message]);

    case RECORD_UPDATE_IN_PROGRESS:
      return state
        .setIn(['records', 'isSaving'], true)
        .setIn(['records', 'updatingIds', toString(action.id)], true)
        .setIn(['records', 'errors'], null);

    case RECORD_UPDATE_SUCCESS:
      const recordDrugsPrescriptions = get(action.record, ['details', 'drugsPrescriptions']);
      const recordExamsPrescriptions = get(action.record, ['details', 'examsPrescriptions']);
      const recordDocuments = get(action.record, ['details', 'documents']);
      const recordCustomForms = get(action.record, ['details', 'customForms']);
      const recordImageAttachments = get(action.record, ['details', 'imageAttachments']);
      const recordFileAttachments = get(action.record, ['details', 'fileAttachments']);
      // drugsPrescriptions, examsPrescriptions, documents, custom forms, and attachments transformation from collection to list of ids
      const record = {
        ...action.record,
        details: {
          ...action.record.details,
          drugsPrescriptions: map(recordDrugsPrescriptions, (dp) => (dp.id)),
          examsPrescriptions: map(recordExamsPrescriptions, (ep) => (ep.id)),
          documents: map(recordDocuments, (document) => (document.id)),
          customForms: map(recordCustomForms, (customForm) => (customForm.id)),
          imageAttachments: map(recordImageAttachments, (imageAttachment) => (imageAttachment.id)),
          fileAttachments: map(recordFileAttachments, (fileAttachment) => (fileAttachment.id)),
        },
      };

      return state
        .setIn(['records', 'isSaving'], false)
        .setIn(['records', 'errors'], null)
        .setIn(['records', 'updatingIds', action.record.id], false)
        .setIn(['records', 'data', action.record.id], record);

    case RECORD_UPDATE_FAILED:
      return state
        .setIn(['records', 'isSaving'], false)
        .setIn(['records', 'updatingIds', toString(action.id)], false)
        .setIn(['records', 'errors'], [action.error.message]);


    case RECORD_AUTOSAVE_IN_PROGRESS:
      return state
        .setIn(['records', 'isAutoSaving'], true)
        .setIn(['records', 'updatingIds', toString(action.id)], true)
        .setIn(['records', 'errors'], null);

    case RECORD_AUTOSAVE_SUCCESS:
      const rrecordDrugsPrescriptions = get(action.record, ['details', 'drugsPrescriptions']);
      const rrecordExamsPrescriptions = get(action.record, ['details', 'examsPrescriptions']);
      const rrecordDocuments = get(action.record, ['details', 'documents']);
      const rrecordCustomForms = get(action.record, ['details', 'customForms']);
      const rrecordImageAttachments = get(action.record, ['details', 'imageAttachments']);
      const rrecordFileAttachments = get(action.record, ['details', 'fileAttachments']);
      // drugsPrescriptions, examsPrescriptions, documents, custom forms, and attachments transformation from collection to list of ids
      const rrecord = {
        ...action.record,
        details: {
          ...action.record.details,
          drugsPrescriptions: map(rrecordDrugsPrescriptions, (dp) => (dp.id)),
          examsPrescriptions: map(rrecordExamsPrescriptions, (ep) => (ep.id)),
          documents: map(rrecordDocuments, (document) => (document.id)),
          customForms: map(rrecordCustomForms, (customForm) => (customForm.id)),
          imageAttachments: map(rrecordImageAttachments, (imageAttachment) => (imageAttachment.id)),
          fileAttachments: map(rrecordFileAttachments, (fileAttachment) => (fileAttachment.id)),
        },
      };

      return state
        .setIn(['records', 'isAutoSaving'], false)
        .setIn(['records', 'errors'], null)
        .setIn(['records', 'updatingIds', action.record.id], false)
        .setIn(['records', 'data', action.record.id], rrecord);

    case RECORD_AUTOSAVE_FAILED:
      return state
        .setIn(['records', 'isAutoSaving'], false)
        .setIn(['records', 'updatingIds', toString(action.id)], false)
        .setIn(['records', 'errors'], [action.error.message]);


    case RECORD_DELETE_IN_PROGRESS:
      return state
        .setIn(['records', 'isSaving'], true)
        .setIn(['records', 'deletingIds', toString(action.id)], true)
        .setIn(['records', 'errors'], null);

    case RECORD_DELETE_SUCCESS:
      const { recordId, patientId } = action;
      const patientRecords = get(state, ['records', 'byPatient', patientId.toString()]);

      return state
        .setIn(['records', 'isSaving'], false)
        .setIn(['records', 'errors'], null)
        .setIn(['records', 'editModeList'], {})
        .setIn(['records', 'byPatient', patientId], patientRecords)
        .setIn(['records', 'deletingIds', toString(recordId)], false)
        .setIn(['records', 'data', toString(recordId)], action.record);

    case RECORD_DELETE_FAILED:
      return state
        .setIn(['records', 'isSaving'], false)
        .setIn(['records', 'deletingIds', toString(action.id)], false)
        .setIn(['records', 'errors'], [action.error.message]);

    case UPLOAD_TO_S3:
      currentTempFiles = get(state.records, ['data', toString(action.recordId), 'tempFiles', action.tempFile.type], {});
      return state
        .setIn(['records', 'data', action.recordId, 'tempFiles', action.tempFile.type, action.tempFile.id], merge(currentTempFiles, action.tempFile));

    case UPLOAD_TO_S3_FAILED:
      currentTempFiles = get(state.records, ['data', toString(action.recordId), 'tempFiles', action.tempFile.type], {});
      tempFiles = omit(currentTempFiles, toString(action.tempFile.id));
      return state
        .setIn(['records', 'data', action.recordId, 'tempFiles', action.tempFile.type], tempFiles);

    case SET_RECORD_ATTACHMENT:
      currentTempFiles = get(state.records, ['data', toString(action.recordId), 'tempFiles', action.processedFile.type], {});
      return state
        .setIn(['records', 'data', action.recordId, 'tempFiles', action.processedFile.type, action.processedFile.id], action.processedFile);

    case SET_RECORD_ATTACHMENT_SUCCESS:
      const recordAttachments = get(state, ['records', 'data', toString(action.recordId), 'details', action.attachmentScope], []);
      currentTempFiles = get(state.records, ['data', toString(action.recordId), 'tempFiles', action.attachmentScope], {});
      tempFiles = omit(currentTempFiles, action.tempId);
      return state
        .setIn(['records', 'errors'], null)
        .setIn(['records', 'data', action.recordId, 'tempFiles', action.attachmentScope], tempFiles)
        .setIn(['records', 'data', action.recordId, 'details', action.attachmentScope], concat(recordAttachments, [action.data.id]));

    case SET_RECORD_ATTACHMENT_ERROR:
      currentTempFiles = get(state.records, ['data', toString(action.recordId), 'tempFiles', action.attachmentScope], {});
      tempFiles = omit(currentTempFiles, toString(action.tempId));
      return state
        .setIn(['records', 'data', action.recordId, 'tempFiles', action.attachmentScope], tempFiles || {})
        .setIn(['records', 'errors'], [action.errorMessage]);


    case RECORD_UPDATE_DATE_IN_PROGRESS:
      return state
        .setIn(['records', 'isSaving'], true)
        .setIn(['records', 'updatingIds', toString(action.id)], true)
        .setIn(['records', 'errors'], null);

    case RECORD_UPDATE_DATE_SUCCESS:
      return state
        .setIn(['records', 'isSaving'], false)
        .setIn(['records', 'errors'], null)
        .setIn(['records', 'data', String(action.recordId), 'date'], action.date)
        .setIn(['records', 'updatingIds', action.recordId], false);

    case RECORD_UPDATE_DATE_FAILED:
      return state
        .setIn(['records', 'isSaving'], false)
        .setIn(['records', 'updatingIds', toString(action.id)], false)
        .setIn(['records', 'errors'], [action.error.message]);


    case ALL_RECORDS_SHOW_PDF_IN_PROGRESS:
      return state
        .setIn(['records', 'isPrinting'], true);
    case ALL_RECORDS_SHOW_PDF_SUCCESS:
      return state
        .setIn(['records', 'isPrinting'], false);
    case ALL_RECORDS_SHOW_PDF_FAILED:
      return state
        .setIn(['records', 'isPrinting'], false);



    case RECORD_UPDATE_VISIBILITY_IN_PROGRESS:
      return state
        .setIn(['records', 'isSaving'], true)
        .setIn(['records', 'updatingIds', toString(action.id)], true)
        .setIn(['records', 'errors'], null);

    case RECORD_UPDATE_VISIBILITY_SUCCESS:
      return state
        .setIn(['records', 'isSaving'], false)
        .setIn(['records', 'errors'], null)
        .setIn(['records', 'data', String(action.recordId), 'visibilityLevel'], action.level)
        .setIn(['records', 'updatingIds', action.recordId], false);

    case RECORD_UPDATE_VISIBILITY_FAILED:
      return state
        .setIn(['records', 'isSaving'], false)
        .setIn(['records', 'updatingIds', toString(action.id)], false)
        .setIn(['records', 'errors'], [action.error.message]);

    case RECORD_SET_EDIT_MODE:
      if (action.isEditMode) {
        return state.setIn(['records', 'editModeList'], { [String(action.recordId)]: {
          patientId: action.patientId,
          editing: action.isEditMode,
        } });
      } else {
        return state.setIn(['records', 'editModeList', String(action.recordId)], {
          patientId: action.patientId,
          editing: action.isEditMode,
        });
      }

    case RECORD_MEMED_ADD_ID_IN_PROGRESS:
      return state
        .setIn(['records', 'isSaving'], true)
        .setIn(['records', 'updatingIds', toString(action.recordId)], true)
        .setIn(['records', 'errors'], null);

    case RECORD_MEMED_ADD_ID_SUCCESS:
      const memedPrescriptions = get(state, ['records', 'data', String(action.recordId), 'details', 'memedPrescriptions'], []);

      return state
        .setIn(['records', 'isSaving'], false)
        .setIn(['records', 'errors'], null)
        .setIn(['records', 'data', String(action.recordId), 'details', 'memedPrescriptions'], [...memedPrescriptions, action.data])
        .setIn(['records', 'updatingIds', action.recordId], false);

    case RECORD_MEMED_ADD_ID_FAILED:
      return state
        .setIn(['records', 'isSaving'], false)
        .setIn(['records', 'updatingIds', toString(action.recordId)], false)
        .setIn(['records', 'errors'], [action.error.message]);

    case RECORD_MEMED_REMOVE_ID_IN_PROGRESS:
      return state
        .setIn(['records', 'isSaving'], true)
        .setIn(['records', 'updatingIds', toString(action.recordId)], true)
        .setIn(['records', 'errors'], null);

    case RECORD_MEMED_REMOVE_ID_SUCCESS:
      const oldMemedPrescriptions = get(state, ['records', 'data', String(action.recordId), 'details', 'memedPrescriptions'], []);
      const integrationId = get(action, 'integrationId');
      return state
        .setIn(['records', 'isSaving'], false)
        .setIn(['records', 'errors'], null)
        .setIn(['records', 'data', String(action.recordId), 'details', 'memedPrescriptions'], filter(oldMemedPrescriptions, p => p.memedId !== integrationId))
        .setIn(['records', 'updatingIds', action.recordId], false);

    case RECORD_MEMED_REMOVE_ID_FAILED:
      return state
        .setIn(['records', 'isSaving'], false)
        .setIn(['records', 'updatingIds', toString(action.recordId)], false)
        .setIn(['records', 'errors'], [action.error.message]);

    case UPLOAD_TO_S3_PROGRESS:
      return state
        .setIn(['records', 'data', action.recordId, 'tempFiles', action.tempFile.type, action.tempFile.id, 'progress'], action.progress);

    default:
      return state;
  }
}
