// @flow
import React from 'react';
import snakeCaseKeys from 'snakecase-keys';
import { isEqual } from 'lodash';

type Props = {
  appId: string,
  email?: string,
  name?: string,
  lastSeen?: string,
  userId?: number,
  userHash?: string,
  tag?: string,
};

export default class Intercom extends React.Component<Props> {
  constructor(props: Props) {
    super(props);

    const { appId, ...otherProps } = props;

    const canUseDOM = !!(
      (typeof window !== 'undefined' &&
      window.document && window.document.createElement)
    );

    if (appId && canUseDOM) {
      window.intercomSettings = snakeCaseKeys(props);
      this.includeIntercomScript();
      if (window.Intercom) {
        window.Intercom('boot', snakeCaseKeys(otherProps));
      }
    }
  }

  componentWillReceiveProps(nextProps: Props) {
    const { appId, ...otherProps } = nextProps;

    if (window.Intercom && !isEqual(nextProps, this.props)) {
      window.Intercom('update', snakeCaseKeys(otherProps));
    }
  }

  shouldComponentUpdate(nextProps: Props) {
    return !isEqual(this.props, nextProps);
  }

  render() {
    return false;
  }

  includeIntercomScript = () => {
    const { appId, ...otherProps } = this.props;
    const intercom = window.Intercom;

    if (typeof intercom === 'function') {
      intercom('reattach_activator');
      intercom('update', snakeCaseKeys(otherProps));
    } else {
      const i = (...args) => { i.c(args); };
      i.q = [];
      i.c = (args) => { i.q.push(args); };
      window.Intercom = i;
      const load = () => {
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.async = true;
        script.src = `https://widget.intercom.io/widget/${appId}`;
        const x = document.getElementsByTagName('script')[0];
        if (x.parentNode) {
          x.parentNode.insertBefore(script, x);
        }
      };
      // Inject intercom
      load();
    }
  }
}
