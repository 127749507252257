// @flow
import React from 'react';
import Slider from 'react-slick';
import { Button, Icon, Spinner } from '@elements';
import type { SubscriptionTrackInfo } from '@types';
import { withTranslation } from 'react-i18next';
import { createNsTranslator, fsPrintf } from '@helpers/helpers';
import { SubscriptionHeader } from '@blocks';
import './SubscriptionWall.scss';

type Props = {
  tackInfo: SubscriptionTrackInfo,
  isLoading: boolean,
  subscriptionStatus: string,
  trialEndsInDays: number,
  t: Function,
};

class SubscriptionWall extends React.Component<Props> {
  render() {
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: true,
    };

    const { isLoading, subscriptionStatus, trialEndsInDays } = this.props;

    const _ = createNsTranslator('subscription', this.props.t);

    return (
      <div id="paywall" className="appContent plansPage settingsPage">
        <SubscriptionHeader/>
        <section className="mainSection">

          {subscriptionStatus &&
            <div className="deadlineWarning">
              {!isLoading && subscriptionStatus === 'end_of_trial' &&
                <React.Fragment>
                  <span className="counter">{_('label/end_of_trial_counter')}</span>
                  <span className="subscribeNow">{_('label/end_of_trial_subscribe_now')}</span>
                </React.Fragment>
              }
              {!isLoading && subscriptionStatus === 'trialing' &&
                <React.Fragment>
                  <span className="counter">{_('label/trialing_counter', null, fsPrintf(trialEndsInDays))}</span>
                  <span className="subscribeNow">{_('label/trialing_subscribe_now')}</span>
                </React.Fragment>
              }
              {isLoading && <Spinner />}
            </div>
          }


          <h1>{_('label/message_essential')}</h1>
          <Slider {...settings}>
            <div>
              <Icon iconName="color-calendar" className="icon" />
              <h2>{_('label/agenda', 'global')}</h2>
              <p>{_('label/agenda_promo')}</p>
            </div>
            <div>
              <Icon iconName="color-timeline" className="icon" />
              <h2>{_('label/records', 'global')}</h2>
              <p>{_('label/records_promo')}</p>
            </div>
          </Slider>

          <div className="actionsFooter">
            <Button className="primary" text={_('action/view_plans')} linkTo="/beneficios" />
          </div>
        </section>
      </div>
    );
  }
}

export default withTranslation()(SubscriptionWall);
