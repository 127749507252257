// @flow
import type { Action, ContextState, Dispatch, CodovaPrintPDFData } from '@types';

// ------------------------------------
// Constants
// ------------------------------------
export const CORDOVA_PRINT_PDF_START = 'cordova.PRINT_PDF_START';
export const CORDOVA_PRINT_PDF_SUCCESS = 'cordova.PRINT_PDF_SUCCESS';
export const CORDOVA_PRINT_PDF_FAILED = 'cordova.PRINT_PDF_FAILED';

// ------------------------------------
// Actions
// ------------------------------------
export const cordovaPrintPDF = (data: CodovaPrintPDFData) => (
  (dispatch: Dispatch) => {
    dispatch({ type: CORDOVA_PRINT_PDF_START, data });
    window.plugins.PrintPDF.isPrintingAvailable(isAvailable => {
      if (!isAvailable) {
        return dispatch({
          type: CORDOVA_PRINT_PDF_FAILED,
          error: { message: 'Printing is not available on your device.' },
        });
      }
      window.plugins.PrintPDF.print({
        data: data.data,
        type: 'Data',
        title: data.title || 'Print PDF',
        success: () => {
          dispatch({ type: CORDOVA_PRINT_PDF_SUCCESS });
        },
        error: (errorJson) => {
          const errorData = JSON.parse(errorJson);
          dispatch({ type: CORDOVA_PRINT_PDF_FAILED, error: { message: errorData.error } });
        },
      });
    });
  }
);

export const cordovaActions = {
  cordovaPrintPDF,
};

// ------------------------------------
// Reducer
// ------------------------------------
export default function cordovaReducer (state: ContextState, action: Action): ContextState {
  switch (action.type) {
    case CORDOVA_PRINT_PDF_START:
      return state
        .setIn(['cordova', 'pdfActive'], true)
        .setIn(['cordova', 'errors'], null)
        .setIn(['cordova', 'pdfData'], action.data);

    case CORDOVA_PRINT_PDF_SUCCESS:
      return state
        .setIn(['cordova', 'pdfActive'], false)
        .setIn(['cordova', 'errors'], null)
        .setIn(['cordova', 'pdfData'], null);

    case CORDOVA_PRINT_PDF_FAILED:
      return state
        .setIn(['cordova', 'pdfActive'], false)
        .setIn(['cordova', 'errors'], [action.error.message])
        .setIn(['cordova', 'pdfData'], null);

    default:
      return state;
  }
}
