// @flow
import React from 'react';
import { connect } from 'react-redux';
import { logout } from '@context/user';
import { mapBundledPermission } from '@helpers/connect';
import { ClinicConfigMenu as ClinicConfigMenuView } from '../components/views';
import type { User, BundledPermissions } from '../types';

type Props = {
  user: User,
  dispatch: Function,
  permissions: BundledPermissions,
};

class ClinicConfigMenu extends React.Component<Props> {
  render() {
    const { user } = this.props;

    return (
      <ClinicConfigMenuView
        user={user}
        onLogout={this.handleLogout}
        permissions={this.props.permissions}
      />
    );
  }
  handleLogout = () => this.props.dispatch(logout());
}

const mapStateToProps = (state) => ({
  user: state.context.user,
  permissions: mapBundledPermission(state),
});

export default connect<any, any, any, any, any, any>(
  mapStateToProps,
)(ClinicConfigMenu);
