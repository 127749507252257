// @flow
import { get } from 'lodash';
import { reportError } from '@helpers/helpers';
import { Api } from '../../../helpers';
import { router } from '../../../routes/router';
import type
{
  Action,
  Dispatch,
  ContextState,
} from '../../../types';

// ------------------------------------
// Constants
// ------------------------------------
const REPORTS_DEBIT_LOADING = 'reportsDebit.REPORTS_DEBIT_LOAD';
const REPORTS_DEBIT_LOADED = 'reportsDebit.REPORTS_DEBIT_LOADED';
const REPORTS_DEBIT_LOADING_FAILED = 'reportsDebit.REPORTS_DEBIT_LOADING_FAILED';

export const getReportsDebit = () => (dispatch: Dispatch) => {
  dispatch({ type: REPORTS_DEBIT_LOADING });
  Api.get(router.getApiRoute('getReportsDebit', null), {
    success: (response) => {
      dispatch({
        type: REPORTS_DEBIT_LOADED,
        reportData: response,
      });
    },
    error: (error) => {
      dispatch({ type: REPORTS_DEBIT_LOADING_FAILED, error: { message: error.message } });
      dispatch(reportError(error));
    },
  });
};

// ------------------------------------
// Reducer
// ------------------------------------
export default function reportsDebitReducer (state: ContextState, action: Action): ContextState {
  switch (action.type) {
    case REPORTS_DEBIT_LOADING:
      if (get(state, ['reportsDebit', 'data'], null)) return state;

      return state
        .setIn(['reportsDebit', 'isLoading'], true)
        .setIn(['reportsDebit', 'errors'], null);

    case REPORTS_DEBIT_LOADED:
      return state
        .setIn(['reportsDebit', 'isLoading'], false)
        .setIn(['reportsDebit', 'data'], get(action, 'reportData', {}))
        .setIn(['reportsDebit', 'errors'], null);

    case REPORTS_DEBIT_LOADING_FAILED:
      return state
        .setIn(['reportsDebit', 'isLoading'], false)
        .setIn(['reportsDebit', 'errors'], [action.error.message]);

    default:
      return state;
  }
}
