// @flow
import React from 'react';
import { withTranslation } from 'react-i18next';
import { createNsTranslator } from '@helpers/helpers';
import { Button } from '@elements';
import './SubscriptionInfoIOS.scss';

class SubscriptionInfoIOS extends React.Component <any> {
  render() {
    const _ = createNsTranslator('my_plan', this.props.t);

    return (
      <div id="subscriptionInfoIOS">
        <h2>{_('label/ios_msg_title')}</h2>
        <hr />
        <div className="textContent">
          <p>{_('label/ios_msg_body_line_1')}</p>
          <p>{_('label/ios_msg_body_line_2')}</p>
          <p>{_('label/ios_msg_body_line_3')}</p>
        </div>
        <div className="buttonLine">
          <Button className="primary" onClick={this.handleHelpClick}>
            {_('label/need_help')}
          </Button>
        </div>
      </div>
    );
  }

  handleHelpClick = () => {
    if (window.Intercom) {
      window.Intercom('show');
    }
  };
}

export default withTranslation()(SubscriptionInfoIOS);
