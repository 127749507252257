// @flow
import React from 'react';
import { connect } from 'react-redux';
import { get } from 'lodash';
import { logout } from '@context/user';
import { mapBundledPermission } from '@helpers/connect';
import { router } from '@router';
import { ServiceTemplateForm as ServiceTemplateFormView } from '../components/views';
import { serviceTemplateActions } from '../redux/modules/context/serviceTemplates';
import type { Dispatch, User, ServicesState, BundledPermissions } from '../types';

type Props = {
  dispatch: Function,
  services: ServicesState,
  user: User,
  id: number,
  doctorId: number,
  permissions: BundledPermissions,
};

class ServiceTemplateEditForm extends React.Component<Props> {
  render() {
    const {
      user, services, id,
      doctorId,
    } = this.props;

    const service = get(services, ['data', id.toString()]);

    return (
      <ServiceTemplateFormView
        onSave={this.handleSaveService}
        onDelete={this.handleDeleteService}
        formData={service}
        doctorId={doctorId}
        user={user}
        onLogout={this.handleLogout}
        isSaving={get(this.props, ['services', 'isSaving'], false)}
        permissions={this.props.permissions}
        onCancel={this.handleBack}
      />
    );
  }

  handleLogout = () => this.props.dispatch(logout());

  handleBack = () => this.props.dispatch(router.pushPath('serviceTemplatesList', { doctorId: this.props.doctorId }));

  handleSaveService = (data: Object) => {
    const { dispatch, id, doctorId } = this.props;
    dispatch(serviceTemplateActions.updateServiceTemplate(doctorId, id, data));
  };

  handleDeleteService = () => {
    const { dispatch, id, doctorId } = this.props;
    dispatch(serviceTemplateActions.deleteServiceTemplate(doctorId, id));
  };
}

const mapStateToProps = (state, ownProps) => ({
  services: state.context.serviceTemplates,
  id: get(ownProps, ['match', 'params', 'id']),
  doctorId: get(ownProps, ['match', 'params', 'doctorId']),
  permissions: mapBundledPermission(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({ dispatch });

export default connect<any, any, any, any, any, any>(
  mapStateToProps,
  mapDispatchToProps,
)(ServiceTemplateEditForm);
