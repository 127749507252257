// @flow
import React from 'react';
import { isPresent, createNsTranslator, fSufixTwoPoints } from '@helpers/helpers';
import { SettingsMenu, FormFooter, PlatformSwitch, AppMobileHeader, AppMobileFooter } from '@blocks';
import { Input, Breadcrumb } from '@elements';
import type { User, BundledPermissions } from '@types';
import { withTranslation } from 'react-i18next';
import './ChangePassword.scss';

type Props = {
  user: User,
  isLoading: boolean,
  savingPasswordSuccess: boolean,
  changePasswordError: boolean,
  onSubmit: Function,
  onLogout: Function,
  onCancel: Function,
  t: Function,
  permissions: BundledPermissions,
};

type State = {
  oldPassword: string,
  password: string,
  procError: boolean,
}

class ChangePassword extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      oldPassword: '',
      password: '',
      procError: false,
    };
  }

  render() {
    const {
      user, isLoading, savingPasswordSuccess, onLogout,
    } = this.props;
    const { oldPassword, password, procError } = this.state;

    const _ = createNsTranslator('settings', this.props.t);

    const breadcrumbLinks = [
      { text: _('label/my_profile'), linkTo: '/ajustes/meu-perfil' },
      { text: _('label/change_password') },
    ];

    const passError = !isPresent(password) ? _('error/password', 'global') : '';

    const pageContent = (
      <React.Fragment>
        <Input
          disabled={isLoading}
          name="oldPassword"
          type="password"
          label={_('label/old_password', null, fSufixTwoPoints)}
          value={oldPassword}
          onChange={this.handleChangePassword}
          error={procError ? this.getError() : ''}
          autoComplete="current-password"
        />
        <Input
          disabled={isLoading}
          name="password"
          type="password"
          label={_('label/new_password', null, fSufixTwoPoints)}
          value={password}
          onChange={this.handleChangePassword}
          error={procError ? passError : ''}
          autoComplete="new-password"
        />

        <FormFooter onSave={this.onSave} isLoading={isLoading} onCancel={this.handleCancel} />
      </React.Fragment>
    );

    return (

      <PlatformSwitch
        inject
        desktop={() => (
          <div id="changePasswordPage" className="appContent settingsPage">
            <aside className="sidebar">
              <SettingsMenu user={user} onLogout={onLogout} permissions={this.props.permissions} />
            </aside>
            <section className="mainSection">
              <div className="centerContent">
                <Breadcrumb items={breadcrumbLinks} />
                <div className="toolbar dashed">
                  <h1>{_('label/change_password')}</h1>
                  {savingPasswordSuccess &&
                    <p className="messageSuccess" >{_('label/old_password_success')}</p>
                    }
                </div>
                {pageContent}
              </div>
            </section>
          </div>
        )}
        mobile={() => (
          <React.Fragment>
            <AppMobileHeader title={_('label/change_password')} backLinkTo="/ajustes/meu-perfil" />
            <div id="changePasswordPage" className="appContent settingsPage">
              <section className="mainSection">
                <div className="centerContent">
                  {savingPasswordSuccess &&
                    <p className="messageSuccess" >{_('label/old_password_success')}</p>
                  }
                  {pageContent}
                </div>
              </section>
            </div>
            <AppMobileFooter />
          </React.Fragment>
        )}
      />
    );
  }

  handleChangePassword = (data: Object) => this.setState({ [data.name]: data.value, procError: false });

  getError = ():any => {
    const _ = createNsTranslator('settings', this.props.t);

    if (this.props.changePasswordError) {
      return _('error/invalid_password', 'global');
    }
    if (!isPresent(this.state.oldPassword)) {
      return _('error/password', 'global');
    }
  };

  handleCancel = () => this.props.onCancel();

  onSave = () => {
    const { password, oldPassword } = this.state;
    this.setState({ procError: true });
    if (isPresent(password) && isPresent(oldPassword)) {
      this.props.onSubmit(password, oldPassword);
    }
  };
}

export default withTranslation()(ChangePassword);
