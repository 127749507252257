// @flow
import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { Icon } from '@elements';
import { useIsMobile } from '@helpers/helpers';
import { useDispatch } from 'react-redux';
import { getUser } from '@context/user';
import { getSubscriptionUserInfo } from '@context/subscription';
import './SubscriptionHeader.scss';

type Props = {
  backTo?: string,
  forceVisibility?: boolean,
};


const SubscriptionHeader = ({ backTo, forceVisibility }: Props) => {
  const dispatch = useDispatch();

  const history = useHistory();
  const [isMobile, isIpad] = useIsMobile();

  const handleBack = useCallback(() => {
    if (backTo) {
      history.replace(backTo);
    }
    dispatch(getUser());
    dispatch(getSubscriptionUserInfo());
  }, [backTo, history, dispatch]);

  const handleClose = useCallback(() => {
    history.replace('/ajustes');
    dispatch(getUser());
    dispatch(getSubscriptionUserInfo());
  }, [dispatch, history]);

  if (!(isMobile || isIpad) && !forceVisibility) return null;

  return (
    <div className="subscriptionHeader">
      <div className="sbcontent">
        {backTo && (
        <div className="backButton" onClick={handleBack}>
          <Icon iconName="arrow-left" size={25}/>
        </div>
        )}
        <div className="closeBtn" onClick={handleClose}/>
      </div>
      <hr/>
    </div>
  );
};


export default SubscriptionHeader;
