// @flow
import React from 'react';
import { get, pick } from 'lodash';
import { connect } from 'react-redux';
import { ClinicConfigAddress as ClinicConfigAddressView } from '@views';
import type { User, Clinic, BundledPermissions } from '@types';
import { updateClinic } from '@context/clinics';
import { logout } from '@context/user';
import { mapBundledPermission } from '@helpers/connect';

type Props = {
  user: User,
  isLoading: boolean,
  clinic: Clinic,
  dispatch: Function,
  permissions: BundledPermissions,
};

class ClinicConfigAddress extends React.Component<Props> {
  render() {
    const { user, isLoading } = this.props;

    return (
      <ClinicConfigAddressView
        user={user}
        isLoading={isLoading}
        onSave={this.handleOnSave}
        formData={this.getFormData()}
        onLogout={this.handleLogout}
        permissions={this.props.permissions}
      />
    );
  }

  handleLogout = () => this.props.dispatch(logout());

  handleOnSave = (data: Object) => {
    const { dispatch, clinic } = this.props;
    dispatch(updateClinic(get(clinic, 'id'), data));
  };

  getFormData = () => pick(this.props.clinic, [
    'name',
    'phone',
    'addressZipcode',
    'addressStreet',
    'addressNumber',
    'addressExtra',
    'addressNeighborhood',
    'addressCity',
    'addressState',
  ]);
}

const mapStateToProps = (state) => ({
  user: state.context.user,
  isLoading: get(state, ['context', 'clinics', 'isSaving'], null),
  clinic: get(state, ['context', 'clinics', 'activeClinic'], {}),
  permissions: mapBundledPermission(state),
});

export default connect<any, any, any, any, any, any>(
  mapStateToProps,
)(ClinicConfigAddress);
