// @flow
import React from 'react';
import { get, map, sortBy, findIndex, size, isEmpty, filter } from 'lodash';
import { PatientsFilters, PlatformSwitch, AppMobileHeader, EmptyState, AppMobileFooter } from '@blocks';
import { ClickableList, Button, Icon, Loading, Dropdown, SearchInput, FormattedText } from '@elements';
import { shortenName, isPresent, createNsTranslator, titleCase, sort, alphabeticCompare } from '@helpers/helpers';
import { withTranslation } from 'react-i18next';
import type { DoctorsState, PatientsState, TagTemplateState, PatientsPaginationMeta } from '@types';
import './PatientsList.scss';

const BIRTHDAY_FILTER_OPTIONS = [
  { text: 'Janeiro', type: 'bornInMonth', value: 1 },
  { text: 'Fevereiro', type: 'bornInMonth', value: 2 },
  { text: 'Março', type: 'bornInMonth', value: 3 },
  { text: 'Abril', type: 'bornInMonth', value: 4 },
  { text: 'Maio', type: 'bornInMonth', value: 5 },
  { text: 'Junho', type: 'bornInMonth', value: 6 },
  { text: 'Julho', type: 'bornInMonth', value: 7 },
  { text: 'Agosto', type: 'bornInMonth', value: 8 },
  { text: 'Setembro', type: 'bornInMonth', value: 9 },
  { text: 'Outubro', type: 'bornInMonth', value: 10 },
  { text: 'Novembro', type: 'bornInMonth', value: 11 },
  { text: 'Dezembro', type: 'bornInMonth', value: 12 },
];

const RECENT_APPOINTMENTS_FILTER_OPTIONS = [
  { text: 'Nos últimos 30 dias', type: 'lastAppointmentTime', value: '30-days' },
  { text: 'Nos ultimos 3 meses', type: 'lastAppointmentTime', value: '3-months' },
  { text: 'Há mais de 6 meses', type: 'lastAppointmentTime', value: 'over-6-months' },
  { text: 'Há mais de 1 ano', type: 'lastAppointmentTime', value: 'over-1-year' },
  { text: 'Há mais de 4 anos', type: 'lastAppointmentTime', value: 'over-4-years' },
];

type Props = {
  activeFilter: ?{
    filterBy: string,
    filterValue: string | number,
  },
  activeSearch: string,
  doctors: DoctorsState,
  patients: PatientsState,
  listLabel: ?string,
  onNewPatientClick: Function,
  onPaginatePatients: Function,
  onSelectFilter: Function,
  onSearch: Function,
  tagTemplates: TagTemplateState,
  singleDoctor: boolean,
  orderDoctorsByProfession: boolean,
  pagination: PatientsPaginationMeta,
  pageMap: Object,
  patientsListFilterDoctor: boolean,
  patientsListShowDoctorName: boolean,
  immediateLoading: boolean,
  t: Function,
};

type State = {
  search: boolean,
  mobileFilters: boolean,
};


class PatientsList extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      search: false,
      mobileFilters: false,
    };
    window.lastSettingsScroll = 0;
    this.lastActiveFilterType = get(props, ['activeFilter', 'type']) + '-' + get(props, ['activeFilter', 'filterValue']);
    window.clearSearchInput = this.clearSearchInput;
  }


  componentWillReceiveProps(nextProps: Props) {
    const nextFilter = get(nextProps, ['activeFilter', 'type'], '') + '-' + get(nextProps, ['activeFilter', 'filterValue'], '');
    if (nextFilter !== this.lastActiveFilterType) {
      this.lastActiveFilterType = nextFilter;
      this.clearSearchInput();
    }
  }

  render() {
    const { mobileFilters } = this.state;
    const {
      activeFilter, activeSearch, doctors,
      patients, onNewPatientClick, tagTemplates,
      onSelectFilter, listLabel, onSearch, singleDoctor,
      orderDoctorsByProfession, pagination, pageMap,
      patientsListFilterDoctor,
      patientsListShowDoctorName, immediateLoading,
    } = this.props;

    const _ = createNsTranslator('patient_list', this.props.t);

    const activeFilterType = get(activeFilter, 'filterBy');
    const activeFilterValue = get(activeFilter, 'filterValue');

    const filterBy = get(activeFilter, 'filterBy', null);

    const totalPage = parseInt(get(pagination, 'total_pages', 1), 10);
    const currentPage = parseInt(get(pagination, 'current_page', 1), 10);
    const totalEntries = parseInt(get(pagination, 'total_entries', 0), 10);

    // Guard against potential null doctors
    if (!doctors || !patients) return false;

    let patientData = get(patients, 'data', {});
    const pagedPatientsData = {};

    map(pageMap, (ids, p: string) => {
      if (parseInt(p, 10) <= currentPage) {
        map(ids, patientId => {
          pagedPatientsData[patientId] = get(patientData, String(patientId), {});
        });
      }
    });

    patientData = pagedPatientsData;

    patientData = filter(patientData, p => !isEmpty(p));

    const noPatients = !size(patientData) && !(patients.isLoading || immediateLoading);
    const isLoading = !size(patientData) && (patients.isLoading || immediateLoading);

    let items = [];
    if (!isLoading) {
      let sortedPatients;
      if (activeFilterType === 'bornInMonth') {
        sortedPatients = sortBy(patientData, 'birthdateDay');
      } else {
        sortedPatients = sort(patientData, alphabeticCompare('name'));
      }

      items = map<any, any>(sortedPatients, (patient) => ({
        key: patient.id,
        linkTo: isPresent(patient.deletedAt) ? `/paciente/${patient.id}/deletado` : `/paciente/${patient.id}/prontuario`,
        customRendererData: {
          patient: patient.name,
          doctor: shortenName(get(doctors, ['data', patient.doctorId, 'nameWithTitle'], '')),
          birthDay: activeFilterType === 'bornInMonth' ? `dia ${patient.birthdateDay}` : null,
        },
      }));
    }



    // to prevent wrapping multiple elements on same level use array
    const renderer = (mobile: boolean) => (item, key) => ([
      <span key={`patient-${key}`}>{item.patient}</span>,
      <span className="action" key={`doctor-${key}`}>
        {(!singleDoctor && patientsListShowDoctorName && !item.birthDay) && <span className="doctorName">{item.doctor}</span>}
        {item.birthDay && <span className="doctorName">{item.birthDay}</span>}
        {!mobile && <span className="linkAction">{_('action/view_medical_record', 'global')}</span>}
        {mobile && <Icon iconName="arrow-right" />}
      </span>,
    ]);

    let content;
    let contentIsEmpty = false;


    const isFilteringMobile = window.isCordovaApp && (
      Boolean(activeSearch) || Boolean(activeFilterType) || Boolean(activeFilterValue) || Boolean(filterBy)
    );

    if (isLoading) {
      content = <Loading key="content" />;
    } else if (noPatients) {
      contentIsEmpty = true;
      content = (
        <EmptyState
          key="content"
          className="emptyStatePatients"
          title={_('empty/title')}
          hint={_('empty/hint')}
          button={
            isFilteringMobile && (
              <Button onClick={this.resetAllFilters}>
                {_('empty/all_patients')}
              </Button>
            )
          }
        />
      );
    } else {
      content = (
        <PlatformSwitch
          key="content"
          inject
          desktop={() => (
            <ClickableList
              className="desktopCard disableScroll"
              items={items}
              customRenderer={renderer(false)}
            />
          )}
          mobile={() => (
            <ClickableList
              className="desktopCard disableScroll"
              items={items}
              customRenderer={renderer(true)}
            />
          )}
        />
      );
    }
    // Concat load more button if have more pages to load
    if (currentPage < totalPage && !isLoading) {
      content = [
        content,
        contentIsEmpty ? null : (
          <Button key="loadmore" className="loadMoreBtn listItem" onClick={this.handleLoadMore} disabled={immediateLoading}>
            <span className="linkAction">{_('action/load_more_patients')}</span>
          </Button>
        ),
      ];
    }

    let listTitle;
    let listDropdownFilter;
    let listDropdownActiveItem;

    if (activeFilterType && (activeFilterType !== '')) {
      if (activeFilterType === 'bornInMonth') {
        listDropdownActiveItem = findIndex(BIRTHDAY_FILTER_OPTIONS, ['value', activeFilterValue]);
        listDropdownFilter = (
          <Dropdown
            className="filter"
            items={BIRTHDAY_FILTER_OPTIONS}
            selectedItem={BIRTHDAY_FILTER_OPTIONS[listDropdownActiveItem]}
            onSelectItem={this.handleFilterClick}
          />
        );
      } else if (activeFilterType === 'lastAppointmentTime') {
        listDropdownActiveItem = findIndex(RECENT_APPOINTMENTS_FILTER_OPTIONS, ['value', activeFilterValue]);
        listDropdownFilter = (
          <Dropdown
            className="filter"
            items={RECENT_APPOINTMENTS_FILTER_OPTIONS}
            selectedItem={RECENT_APPOINTMENTS_FILTER_OPTIONS[listDropdownActiveItem]}
            onSelectItem={this.handleFilterClick}
          />
        );
      }
      listTitle = (
        <div className="titleFilter">
          {listLabel}
          {listDropdownFilter}
          <span className="count">(<FormattedText text={totalEntries} type="number" />)</span>
        </div>
      );
    } else {
      listTitle = (
        <div className="titleFilter">
          {listLabel}
          <span className="count">(<FormattedText text={totalEntries} type="number" />)</span>
        </div>
      );
    }

    const inputRef = (ref) => {
      this.inputRef = ref;
    };

    const showTip = activeFilterValue === 'reschedule';

    return (
      <PlatformSwitch
        inject
        className="platformSwitcher"
        desktop={() => (
          <div id="patientsPage" className="appContent">

            <aside className="sidebar">
              <div className="sidebarActions">
                <Button className="primary" text={_('action/new_patient', null, titleCase)} onClick={onNewPatientClick} />
              </div>
              <PatientsFilters
                orderDoctorsByProfession={orderDoctorsByProfession}
                activeFilter={activeFilter}
                doctors={doctors}
                onSelectFilter={onSelectFilter}
                tagTemplates={tagTemplates}
                patientsListFilterDoctor={patientsListFilterDoctor}
              />
            </aside>

            <section className="mainSection">
              <div id="patientList" className="centerContent">
                <div className="searchToolbar">
                  {listTitle}
                  <div className="searchBox">
                    <SearchInput
                      clear
                      mobileAutofocus
                      debounced
                      key="search"
                      placeholder={_('hint/search')}
                      value={activeSearch}
                      defaultValue={activeSearch}
                      onChange={onSearch}
                      inputRef={inputRef}
                    />
                  </div>
                </div>
                {showTip && (
                  <div className="tagTip">
                    {_('label/reschedule_text')}
                    &nbsp;
                    <a href={_('label/reschedule_video_link')} target="_blank">
                      {_('label/reschedule_link')}
                    </a>
                  </div>
                )}
                {content}
              </div>
            </section>
          </div>
        )}
        mobile={() => (
          <React.Fragment>
            {!mobileFilters &&
              <AppMobileHeader hideTitle={this.state.search} hideBack title="Pacientes" disableHeader>
                {this.state.search &&
                  <div className="headerActions searchToolbar mobile">
                    <SearchInput
                      mobileAutofocus
                      clear
                      debounced
                      className="mobileHeaderSearchInput"
                      placeholder={_('hint/search')}
                      value={activeSearch}
                      onChange={onSearch}
                      defaultValue={activeSearch}
                      autoFocus
                    />
                    <Button onClick={this.handleSearchClickDisable} className="transparentButton cancelSearchPatientBtn">
                      Cancelar
                    </Button>
                  </div>
                }
                {!this.state.search &&
                  <div className="headerActions">

                    <Icon iconName="search" className="icon" onClick={this.handleSearchClick} />
                    <Icon iconName="filter" className="icon" onClick={this.handleMobileFilterClick} fill={filterBy ? '#09C75E' : '#fff'} />
                    {/* <Icon iconName="refresh" className="icon" onClick={this.handleRefreshClick} /> */}
                  </div>
                }
              </AppMobileHeader>
            }
            {mobileFilters &&
              <AppMobileHeader title="Filtrar Pacientes" onBackClick={this.handleMobileFilterClickHide} />
            }
            <div id="patientsPage" className="appContent">
              <div className="mainSection noPadding">
                {!mobileFilters &&
                <div className="buttonHolder">
                  <Button className="primary" text={_('action/new_patient', null, titleCase)} onClick={onNewPatientClick} />
                </div>
              }
                {mobileFilters &&
                <PatientsFilters
                  orderDoctorsByProfession={orderDoctorsByProfession}
                  activeFilter={activeFilter}
                  doctors={doctors}
                  onSelectFilter={this.handleMobileSelectFilter}
                  tagTemplates={tagTemplates}
                  patientsListFilterDoctor={patientsListFilterDoctor}
                />
              }
                {!mobileFilters &&
                <div className="scrollMobilePatientList">
                  {showTip && (
                    <div className="tagTip">
                      {_('label/reschedule_text')}
                      &nbsp;
                      <a href={_('label/reschedule_video_link')} target="_blank">
                        {_('label/reschedule_link')}
                      </a>
                    </div>
                  )}
                  {!mobileFilters && content}
                </div>
              }
              </div>
            </div>
            {!mobileFilters && <AppMobileFooter />}
          </React.Fragment>
        )}
      />
    );
  }

  inputRef: any;
  lastActiveFilterType: any = '';

  handleSearchClick = () => {
    this.setState({ search: true });
  };

  handleSearchClickDisable = () => {
    this.setState({ search: false }, () => this.props.onSearch(''));
  };

  handleFilterClick = (item: Object) => {
    this.props.onSelectFilter(item);
  };

  handleMobileFilterClick = () => {
    this.setState({ mobileFilters: true });
  };

  handleMobileFilterClickHide = () => {
    this.setState({ mobileFilters: false });
  };

  handleMobileSelectFilter = (item: Object) => {
    this.setState({ mobileFilters: false }, () => this.props.onSelectFilter(item));
  };

  handleRefreshClick = () => {
    this.props.onSearch('');
  };

  handleLoadMore = () => {
    const nextPage = get(this.props.pagination, 'current_page', 1) + 1;
    if (isPresent(this.props.activeSearch)) {
      this.props.onSearch(this.props.activeSearch, nextPage);
    } else {
      this.props.onPaginatePatients(nextPage);
    }
  };

  clearSearchInput = () => {
    if (this.inputRef) {
      this.inputRef.value = '';
      if (typeof this.inputRef.onReset === 'function') {
        this.inputRef.onReset();
      }
    }
  };

  resetAllFilters = () => {
    this.handleMobileSelectFilter({ type: '' });
    this.clearSearchInput();
    this.handleSearchClickDisable();
  }
}


export default withTranslation()(PatientsList);
