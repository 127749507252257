// @flow
import React from 'react';
import { get } from 'lodash';
import { connect } from 'react-redux';
import { PatientDeleted as PatientDeletedView } from '@views';
import type { Patient } from '@types';
import { getPatient, recoverPatient } from '@context/patients';

type Props = {
  dispatch: Function,
  patient: Patient,
  patientId: number,
  isLoading: boolean,
};

class PatientDeleted extends React.Component<Props> {
  componentDidMount() {
    this.props.dispatch(getPatient(this.props.patientId, false));
  }

  render() {
    const { patient, isLoading } = this.props;
    return (
      <PatientDeletedView
        patient={patient}
        isLoading={isLoading}
        onRecover={this.handleRecover}
      />
    );
  }

  handleRecover = () => {
    this.props.dispatch(recoverPatient(this.props.patientId));
  }
}

const mapStateToProps = (state, ownProps: any) => ({
  user: state.context.user,
  patientId: ownProps.match.params.id,
  patient: get(state, ['context', 'patients', 'data', ownProps.match.params.id]),
  isLoading: get(state, ['context', 'patients', 'isLoading'], false),
});

export default connect<any, any, any, any, any, any>(
  mapStateToProps,
)(PatientDeleted);
