// @flow
import type { Patient } from '@types';
import { get } from 'lodash';
import { shortenName, keepFirstPresent } from '@helpers/helpers';

export const blockedTimeListItemsRender = (
  showRecurrent: boolean, activeModal: any, activeMenuItem: any,
  doctor: any, singleDoctor: boolean, recurrentDaysCount: number, isNew: boolean, isLockedForLoading: boolean, _: Function) => [
  {
    className: 'titleField',
    customRendererData: {
      type: 'titleField',
      // disabled: isLockedForLoading,
      input: {
        icon: 'ban',
        placeholder: _('label/title'),
      },
    },
  },
  ...(showRecurrent ? [{
    className: (activeMenuItem === 'recurringSchedule') ? 'listItem active' : null,
    customRendererData: {
      action: 'recurringSchedule',
      icon: 'time',
      text: _('label/recurring_schedule'),
      name: 'recurringSchedule',
      // hidden: !showRecurrent,
      disabled: !showRecurrent,
      counter: !isNew ? recurrentDaysCount : null,
      // disabled: isLockedForLoading,
    },
  }] : []),
  {
    className: (activeMenuItem === 'doctor') ? 'listItem active' : 'listItem',
    customRendererData: {
      action: 'doctor',
      icon: 'doctor',
      text: doctor ? shortenName(doctor.nameWithTitle) : _('label/doctor_not_found', 'global'),
      name: 'doctor',
      disabled: !isNew || isLockedForLoading,
      forceHidden: singleDoctor,
    },
  },
];
type AppointmentLIRenderInput = {
    showRecurrent: boolean,
    activeModal: any,
    activeMenuItem: any,
    patient: ?Patient,
    recurrentDaysCount: number,
    isNew: boolean,
    singleRoom: boolean,
    singleDoctor: boolean,
    appointmentDoctorSelect: boolean,
    currentRoom: any,
    doctor: any,
    services: any,
    isFinatialEnabled: boolean,
    showNotes: boolean,
    isLockedForLoading: boolean,
    isWhatsAppConfirmationEnabled: boolean,
    _: Function
};

export const appointmentModalListItemsRender = ({
  activeMenuItem, currentRoom, doctor, appointmentDoctorSelect, isFinatialEnabled, isNew, patient, recurrentDaysCount,
  services, showRecurrent, singleDoctor, singleRoom, showNotes, isLockedForLoading, _, isWhatsAppConfirmationEnabled,
} : AppointmentLIRenderInput) => [
  {
    className: (activeMenuItem === 'patient') ? 'listItem active' : null,
    customRendererData: {
      action: 'patient',
      icon: 'patient',
      text: patient ? shortenName(patient.name) : _('label/patient', 'global'),
      phone: keepFirstPresent(patient, ['mobilePhone', 'homePhone', 'otherPhone']),
      name: 'patient',
      validate: 'patientId',
      // disabled: isLockedForLoading,
      type: 'patient',
    },
  },
  {
    className: (activeMenuItem === 'recurringSchedule') ? 'listItem active' : null,
    customRendererData: {
      action: 'recurringSchedule',
      icon: 'time',
      validate: 'recurrency',
      text: _('label/recurring_schedule'),
      name: 'recurringSchedule',
      hidden: !showRecurrent,
      disabled: !showRecurrent,
      counter: !isNew ? recurrentDaysCount : null,
      // disabled: isLockedForLoading,
    },
  },
  {
    className: (activeMenuItem === 'status') ? 'listItem active' : 'listItem',
    customRendererData: {
      action: 'status',
      icon: 'info',
      text: _('label/status'),
      name: 'status',
      type: 'status',
      hidden: isNew,
      // disabled: isLockedForLoading,
    },
  },
  {
    className: (activeMenuItem === 'whatsAppConfirmation') ? 'listItem active' : 'listItem',
    customRendererData: {
      action: 'whatsAppConfirmation',
      icon: 'whatsapp',
      text: _('label/whatsApp'),
      name: 'whatsAppConfirmation',
      type: 'whatsAppConfirmation',
      hidden: isNew || !isWhatsAppConfirmationEnabled,
    },
  },
  ...(singleRoom ? [] : [
    {
      className: (activeMenuItem === 'room') ? 'listItem active' : 'listItem',
      customRendererData: {
        action: 'room',
        icon: 'location',
        text: get(currentRoom, ['name'], _('label/room', 'global')),
        name: 'room',
        forceHidden: singleRoom,
        // disabled: isLockedForLoading,
      },
    },
  ]),
  ...(singleDoctor || !appointmentDoctorSelect ? [] : [
    {
      className: (activeMenuItem === 'doctor') ? 'listItem active' : 'listItem',
      customRendererData: {
        action: 'doctor',
        icon: 'doctor',
        text: doctor ? shortenName(doctor.nameWithTitle) : _('label/doctor_not_found', 'global'),
        name: 'doctor',
        disabled: !isNew || isLockedForLoading,
        forceHidden: singleDoctor,
      },
    },
  ]),
  ...(isFinatialEnabled ? [{
    className: (activeMenuItem === 'contractedServices') ? 'listItem active' : null,
    customRendererData: {
      action: 'contractedServices',
      icon: 'services',
      text: _('label/contracted_services'),
      name: 'contractedServices',
      counter: !isNew ? services && services.length : null,
      // disabled: isLockedForLoading,
    },
  }] : []),
  {
    className: 'notesField',
    preventClick: true,
    customRendererData: {
      type: 'notesField',
      input: {
        icon: 'notes',
        placeholder: _('label/notes', 'global'),
      },
      checkbox: {
        label: _('label/show_obs'),
        checked: showNotes,
      },
      // disabled: isLockedForLoading,
    },
  },
];
