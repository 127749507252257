export default {
  'empty/title': 'Nenhum paciente encontrado',
  'empty/title_reschedule': 'Não há pacientes com a etiqueta reagendar',
  'empty/all_patients': 'Ver Todos os Pacientes',
  'empty/hint': 'Você sabia que além de buscar por nome, você também pode buscar pelo telefone celular?',
  'action/load_more_patients': 'Ver mais pacientes',
  'action/new_patient': 'Novo paciente',
  'action/see_birthdays_of': 'Ver aniversariantes de',
  'action/last_appointment': 'Último Atendimento',
  'action/deleted_patients': 'Pacientes Excluídos',
  'hint/search': 'Busque pelo nome, telefone ou CPF',
  'label/born_in_month': 'Aniversariantes do Mês',
  'label/recently_served': 'Atendidos Recentemente',
  'label/recently_served_at_6_months': 'Há mais de 6 meses',
  'label/deleted_patients': 'Excluídos',
  'label/tag': 'Etiqueta',
  'label/reschedule_text': 'Quando uma consulta é cancelada, o paciente recebe uma etiqueta "reagendar" automaticamente.',
  'label/reschedule_video_link': 'https://www.loom.com/share/2d65bdec14764baea964d6db38f7f7f7',
  'label/reschedule_link': 'Ver Vídeo',
};
