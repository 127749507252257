// @flow
import React, { useMemo } from 'react';
import { get, size } from 'lodash';
import { Badge, Loading, FormattedText, Table } from '@elements';
import type { ServicesState } from '@types';
import { sort, dateCompare, useTranslationNs } from '@helpers/helpers';


type Props = {
    services: ServicesState,
    isServicesLoading: boolean,
    serviceLoadingIds: Object,
    maxListItens: number,
    toggleServiceItemMenu: Function,
    singleDoctor: boolean,
};

const PatientFinancialServices = ({
  services, isServicesLoading, serviceLoadingIds, maxListItens, toggleServiceItemMenu,
  singleDoctor,
}: Props) => {
  const [_] = useTranslationNs('financial');

  const servicesData = sort(get(services, ['data'], {}), dateCompare('date', 'desc'));

  const onItemClick = (service) => toggleServiceItemMenu(service.id, service.status === 'health_insurance')();

  const columns = useMemo(() => [
    {
      dataIndex: 'date',
      content: _('label/date', 'global'),
      onRenderItem: (service) => <FormattedText type="date" text={service.date} />,
    },
    {
      dataIndex: 'service_name',
      content: _('label/service', 'global'),
      onRenderItem: (service) => <React.Fragment><div>{service.name}</div><div>{service.notes}</div></React.Fragment>,
    },
    {
      dataIndex: 'doctorName',
      content: _('label/doctor', 'global'),
      hidden: singleDoctor,
    },
    {
      dataIndex: 'status',
      content: _('label/status', 'global'),
      onRenderItem: (service) => <Badge className={service.status} />,
    },
    {
      dataIndex: 'price',
      content: _('label/value', 'global'),
      onRenderItem: (service) => <FormattedText type="currency" text={service.price} />,
    },
  ], [_, singleDoctor]);

  if (isServicesLoading && size(get(services, 'data', [])) === 0) {
    return (
      <div className={window.isCordovaApp ? 'mobileLoaderFinacialFix' : ''}>
        <Loading />
      </div>
    );
  }

  return (
    <Table
      enablePagination
      itemKey="id"
      limit={maxListItens}
      columns={columns}
      onItemClick={onItemClick}
      items={servicesData}
      mobileClickLabel="Editar"
      onGetLoadingItem={(service) => get(serviceLoadingIds, service.id, false)}
      emptyMessage={_('label/services_empty/title')}
    />
  );
};

// $FlowFixMe
export default React.memo(PatientFinancialServices);
