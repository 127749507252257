// @flow
import { get, indexOf } from 'lodash';
import { formatBytes } from './formats';

export const wrapValue = (defaultValue: any, value: any, uncontrolled: any) => (defaultValue || uncontrolled ? ({ defaultValue }) : ({ value }));

export const isPtBR = (lng?: string) => String(lng || window.i18n.language).toLowerCase() === 'pt' || String(lng || window.i18n.language).toLowerCase() === 'pt-br';

export function memorySizeOf(obj: any) {
  let bytes = 0;
  // eslint-disable-next-line
  function sizeOf(obj: any) {
    if (obj !== null && obj !== undefined) {
      switch (typeof obj) {
        case 'number':
          bytes += 8;
          break;
        case 'string':
          bytes += obj.length * 2;
          break;
        case 'boolean':
          bytes += 4;
          break;
        case 'object':
          const objClass: any = Object.prototype.toString.call(obj).slice(8, -1);
          if (objClass === 'Object' || objClass === 'Array') {
            for (const key in obj) {
              // eslint-disable-next-line
              if (!obj.hasOwnProperty(key)) continue;
              sizeOf(obj[key]);
            }
          } else bytes += obj.toString().length * 2;
          break;
        default: break;
      }
    }
    return bytes;
  }
  return formatBytes(sizeOf(obj));
}


export const notifyBug = (message: any) => {
  if (window.tron && window.tron.error) {
    window.tron.error(message);
  }
};

export const globalT = (key: string, fallback: string) => (window.i18n.t ? window.i18n.t(`global:${key}`) : fallback);

export const reportError = (error: any) => {
  if (window.navigator.onLine) {
    notifyBug({
      errorType: 'api-request-error:fn-reportError',
      error,
    });

    const errorMessage = get(error, 'message', globalT('error/request', 'Ocorreu um erro ao executar esta requisição'));

    return {
      type: 'toasts.OPEN_TOAST',
      data: {
        type: 'error', text: errorMessage, close: true, timeout: 30000, error,
      },
    };
  } else {
    return {
      type: 'toasts.OPEN_TOAST',
      data: {
        type: 'warning', text: globalT('error/request_internet', 'Ocorreu um erro. Verifique sua conexão com a internet.'), close: true, timeout: 30000, error,
      },
    };
  }
};

export const isCordovaApp = ():boolean => window.isCordovaApp;

export const getPlatform = () => {
  const iosPlatformInstances = ['iPhone', 'iPad'];
  if (!window.isCordovaApp) {
    return 'web';
  } else if (window.navigator && (indexOf(iosPlatformInstances, window.navigator.platform) > -1)) {
    return 'ios';
  }
  return 'android';
};

export const isBetaApp = () => get(window, 'isBetaApp', false);
