type Props = {
  dispatch: Function,
  getState: Function,
};

export default function callAPI ({ dispatch, getState }: Props) {
  return next => action => {
    const {
      types,
      contextPath,
      call,
      parseResponse = response => response,
      shouldCall = () => true,
      payload = {},
    } = action;

    if (!types) {
      // Normal action without all dispatch types. Will execute regular manually typed action
      return next(action);
    }

    if (
      !Array.isArray(types) ||
      types.length !== 3 ||
      !types.every(type => typeof type === 'string')
    ) {
      throw new Error('Expected an array of three string types.');
    }

    if (typeof call !== 'function') {
      throw new Error('Expected fetch to be a function.');
    }

    if (!shouldCall(getState())) {
      return null;
    }


    let loadingType;
    let successType;
    let failureType;
    let loadingAction;
    let failureAction;

    if (types.length === 3) {
      [loadingType, successType, failureType] = types;
      loadingAction = { ...payload, type: loadingType };
      failureAction = { ...payload, type: failureType };
    } else {
      [successType] = types;
      loadingAction = { ...payload, type: 'context.LOADING', _path: contextPath };
      failureAction = { ...payload, type: 'context.LOAD_ERROR', _path: contextPath };
    }

    // 1. Dispatch LOADING action
    dispatch(loadingAction);

    // 2. Call API
    return call(getState()).then(
      // 3a. API successfully passed - Dispatch success action
      response => dispatch({ ...payload, type: successType, ...parseResponse(response.data) }),
      // 3b. API failed - Dispatch failure action
      error => dispatch({ ...failureAction, error }),
    );
  };
}
