// @flow
import {
  get, omit, concat,
  without,
} from 'lodash';
import { reportError } from '@helpers/helpers';
import { Api } from '../../../helpers';
import { router } from '../../../routes/router';
import type {
  Action,
  Dispatch,
  ContextState,
  ExamsPrescriptionInput,
} from '../../../types';
import showPDF from '../../middleware/showPDF';


const NOP = () => {};

// ------------------------------------
// Constants
// ------------------------------------
const EXAMS_PRESCRIPTION_SHOW_PDF_IN_PROGRESS = 'examsPrescriptions.EXAMS_PRESCRIPTION_SHOW_PDF_IN_PROGRESS';
const EXAMS_PRESCRIPTION_SHOW_PDF_SUCCESS = 'examsPrescriptions.EXAMS_PRESCRIPTION_SHOW_PDF_SUCCESS';
const EXAMS_PRESCRIPTION_SHOW_PDF_FAILED = 'examsPrescriptions.EXAMS_PRESCRIPTION_SHOW_PDF_FAILED';

const EXAMS_PRESCRIPTION_CREATE_IN_PROGRESS = 'examsPrescriptions.EXAMS_PRESCRIPTION_CREATE_IN_PROGRESS';
const EXAMS_PRESCRIPTION_CREATE_SUCCESS = 'examsPrescriptions.EXAMS_PRESCRIPTION_CREATE_SUCCESS';
const EXAMS_PRESCRIPTION_CREATE_FAILED = 'examsPrescriptions.EXAMS_PRESCRIPTION_CREATE_FAILED';

const EXAMS_PRESCRIPTION_UPDATE_IN_PROGRESS = 'examsPrescriptions.EXAMS_PRESCRIPTION_UPDATE_IN_PROGRESS';
const EXAMS_PRESCRIPTION_UPDATE_SUCCESS = 'examsPrescriptions.EXAMS_PRESCRIPTION_UPDATE_SUCCESS';
const EXAMS_PRESCRIPTION_UPDATE_FAILED = 'examsPrescriptions.EXAMS_PRESCRIPTION_UPDATE_FAILED';

const EXAMS_PRESCRIPTION_DELETE_IN_PROGRESS = 'examsPrescriptions.EXAMS_PRESCRIPTION_DELETE_IN_PROGRESS';
const EXAMS_PRESCRIPTION_DELETE_SUCCESS = 'examsPrescriptions.EXAMS_PRESCRIPTION_DELETE_SUCCESS';
const EXAMS_PRESCRIPTION_DELETE_FAILED = 'examsPrescriptions.EXAMS_PRESCRIPTION_DELETE_FAILED';

// ------------------------------------
// Actions
// ------------------------------------
export const showExamsPrescriptionPDF = (recordId: number, prescriptionId: number, showSignature: boolean, sendEmail: boolean = false) => showPDF.action({
  title: 'Exams Prescription PDF',
  apiRoute: router.getApiRoute('showExamsPrescriptionPDF', { recordId, id: prescriptionId, showSignature: showSignature && 'true', sendEmail: sendEmail && true }),
  prepare: EXAMS_PRESCRIPTION_SHOW_PDF_IN_PROGRESS,
  success: EXAMS_PRESCRIPTION_SHOW_PDF_SUCCESS,
  error: EXAMS_PRESCRIPTION_SHOW_PDF_FAILED,
  sendEmail,
});

export const createExamsPrescription = (recordId: number, examsPrescription: ExamsPrescriptionInput, cb: Function = NOP) => (
  (dispatch: Dispatch) => {
    dispatch({ type: EXAMS_PRESCRIPTION_CREATE_IN_PROGRESS });
    Api.post(router.getApiRoute('createExamsPrescription', { recordId }), {
      data: { examsPrescription },
      success: (response) => {
        dispatch({ type: EXAMS_PRESCRIPTION_CREATE_SUCCESS, recordId, examsPrescription: response.data.examsPrescription });
        cb(true);
      },
      error: (error) => {
        dispatch({ type: EXAMS_PRESCRIPTION_CREATE_FAILED, error: { message: error.message } });
        dispatch(reportError(error));
        cb(false);
      },
    });
  }
);

export const createAndShowExamsPrescriptionPDF = (recordId: number, examsPrescription: ExamsPrescriptionInput, cb: Function = NOP) => (
  (dispatch: Dispatch) => {
    dispatch({ type: EXAMS_PRESCRIPTION_CREATE_IN_PROGRESS });
    Api.post(router.getApiRoute('createExamsPrescription', { recordId }), {
      data: { examsPrescription },
      success: (createResponse) => {
        dispatch({ type: EXAMS_PRESCRIPTION_CREATE_SUCCESS, recordId, examsPrescription: createResponse.data.examsPrescription });
        dispatch(showExamsPrescriptionPDF(recordId, createResponse.data.examsPrescription.id, false));
        cb(true);
      },
      error: (error) => {
        dispatch({ type: EXAMS_PRESCRIPTION_CREATE_FAILED, error: { message: error.message } });
        dispatch(reportError(error));
        cb(false);
      },
    });
  }
);

export const updateExamsPrescription = (recordId: number, prescriptionId: number, examsPrescription: ExamsPrescriptionInput, cb: Function = NOP) => (
  (dispatch: Dispatch) => {
    dispatch({ type: EXAMS_PRESCRIPTION_UPDATE_IN_PROGRESS });
    Api.patch(router.getApiRoute('updateExamsPrescription', { recordId, id: prescriptionId }), {
      data: { examsPrescription },
      success: (response) => {
        dispatch({ type: EXAMS_PRESCRIPTION_UPDATE_SUCCESS, examsPrescription: response.examsPrescription });
        cb(true);
      },
      error: (error) => {
        dispatch({ type: EXAMS_PRESCRIPTION_UPDATE_FAILED, error: { message: error.message } });
        dispatch(reportError(error));
        cb(false);
      },
    });
  }
);

export const updateAndShowExamsPrescriptionPDF = (recordId: number, prescriptionId: number, examsPrescription: ExamsPrescriptionInput, cb: Function = NOP) => (
  (dispatch: Dispatch) => {
    dispatch({ type: EXAMS_PRESCRIPTION_UPDATE_IN_PROGRESS });
    Api.patch(router.getApiRoute('updateExamsPrescription', { recordId, id: prescriptionId }), {
      data: { examsPrescription },
      success: (updateResponse) => {
        dispatch({ type: EXAMS_PRESCRIPTION_UPDATE_SUCCESS, examsPrescription: updateResponse.examsPrescription });
        dispatch(showExamsPrescriptionPDF(recordId, prescriptionId, false));
        cb(true);
      },
      error: (error) => {
        dispatch({ type: EXAMS_PRESCRIPTION_UPDATE_FAILED, error: { message: error.message } });
        dispatch(reportError(error));
        cb(false);
      },
    });
  }
);

export const deleteExamsPrescription = (recordId: number, prescriptionId: number, cb: Function = NOP) => (
  (dispatch: Dispatch) => {
    dispatch({ type: EXAMS_PRESCRIPTION_DELETE_IN_PROGRESS });
    Api.destroy(router.getApiRoute('deleteExamsPrescription', { recordId, id: prescriptionId }), {
      success: () => {
        dispatch({ type: EXAMS_PRESCRIPTION_DELETE_SUCCESS, recordId, deletedPrescriptionId: prescriptionId });
        cb(true);
      },
      error: (error) => {
        dispatch({ type: EXAMS_PRESCRIPTION_DELETE_FAILED, error: { message: error.message } });
        dispatch(reportError(error));
        cb(false);
      },
    });
  }
);

export const examsPrescriptionActions = {
  showExamsPrescriptionPDF,
  createExamsPrescription,
  createAndShowExamsPrescriptionPDF,
  updateExamsPrescription,
  updateAndShowExamsPrescriptionPDF,
  deleteExamsPrescription,
};

// ------------------------------------
// Reducer
// ------------------------------------
export default function examsPrescriptionsReducer (state: ContextState, action: Action): ContextState {
  let recordId;
  let recordPrescriptions;
  switch (action.type) {
    case EXAMS_PRESCRIPTION_CREATE_IN_PROGRESS:
      return state
        .setIn(['examsPrescriptions', 'isSaving'], true)
        .setIn(['examsPrescriptions', 'errors'], null);

    case EXAMS_PRESCRIPTION_CREATE_SUCCESS:
      recordId = action.recordId.toString();
      const newPrescriptionId = action.examsPrescription.id;
      const currentPrescriptionsByRecord = get(state, ['examsPrescriptions', 'byRecord', recordId], []);
      recordPrescriptions = get(state, ['records', 'data', recordId, 'details', 'examsPrescriptions'], []);
      return state
        .setIn(['examsPrescriptions', 'isSaving'], false)
        .setIn(['examsPrescriptions', 'errors'], null)
        .setIn(['examsPrescriptions', 'data', newPrescriptionId], action.examsPrescription)
        .setIn(['examsPrescriptions', 'byRecord', recordId], concat(currentPrescriptionsByRecord, [newPrescriptionId]))
        .setIn(['records', 'data', recordId, 'details', 'examsPrescriptions'], concat(recordPrescriptions, [newPrescriptionId]));

    case EXAMS_PRESCRIPTION_CREATE_FAILED:
      return state
        .setIn(['examsPrescriptions', 'isSaving'], false)
        .setIn(['examsPrescriptions', 'errors'], [action.error.message]);

    case EXAMS_PRESCRIPTION_UPDATE_IN_PROGRESS:
      return state
        .setIn(['examsPrescriptions', 'isSaving'], true)
        .setIn(['examsPrescriptions', 'errors'], null);

    case EXAMS_PRESCRIPTION_UPDATE_SUCCESS:
      return state
        .setIn(['examsPrescriptions', 'isSaving'], false)
        .setIn(['examsPrescriptions', 'errors'], null)
        .setIn(['examsPrescriptions', 'data', action.examsPrescription.id], action.examsPrescription);

    case EXAMS_PRESCRIPTION_UPDATE_FAILED:
      return state
        .setIn(['examsPrescriptions', 'isSaving'], false)
        .setIn(['examsPrescriptions', 'errors'], [action.error.message]);

    case EXAMS_PRESCRIPTION_DELETE_IN_PROGRESS:
      return state
        .setIn(['examsPrescriptions', 'isDeleting'], true)
        .setIn(['examsPrescriptions', 'errors'], null);

    case EXAMS_PRESCRIPTION_DELETE_SUCCESS:
      const deletedId = action.deletedPrescriptionId;
      recordId = action.recordId.toString();
      const data = get(state, ['examsPrescriptions', 'data'], {});
      const byRecord = get(state, ['examsPrescriptions', 'byRecord', recordId], []);
      recordPrescriptions = get(state, ['records', 'data', recordId, 'details', 'examsPrescriptions'], []);
      return state
        .setIn(['examsPrescriptions', 'isDeleting'], false)
        .setIn(['examsPrescriptions', 'errors'], null)
        .setIn(['examsPrescriptions', 'data'], omit(data, deletedId.toString()))
        .setIn(['examsPrescriptions', 'byRecord', recordId], without(byRecord, deletedId))
        .setIn(['records', 'data', recordId, 'details', 'examsPrescriptions'], without(recordPrescriptions, deletedId));

    case EXAMS_PRESCRIPTION_DELETE_FAILED:
      return state
        .setIn(['examsPrescriptions', 'isDeleting'], false)
        .setIn(['examsPrescriptions', 'errors'], [action.error.message]);

    default:
      return state;
  }
}
