// @flow
import { useEffect, useRef, useState } from 'react';
import { get } from 'lodash';

import { useTranslation, getI18n } from 'react-i18next';
import * as rRedux from 'react-redux';
import { checkIfIsMobile } from './layout';
import { UserNotifications } from '@helpers/helpers';
import { openModal } from '@context/modals';
import { router } from '@router';
import { getPatient } from '@context/patients';

const reactRedux: any = rRedux;

export const createNsTranslator = (defaultNs: string, t: Function) => (s: string, ns?: string | null = defaultNs, filterFn?: Function) =>
  (filterFn ? filterFn(String(t(`${(ns || defaultNs)}:${s}`))) : String(t(`${(ns || defaultNs)}:${s}`)));


export const useTranslationNs = (ns: string) => {
  const [t, i18n] = useTranslation();
  return [createNsTranslator(ns, t), i18n];
};

type ConstantItem = {
  _locale: string,
}

type TranslatedConstant = ConstantItem & {
  text: string,
}

export const translateConstant = (constant: ConstantItem[]) => constant.map<TranslatedConstant>(item => ({
  ...item,
  text: getI18n().t(item._locale),
}));

export const useTranslatedConstant = (constant: ConstantItem[]) => {
  const translatedConstant = translateConstant(constant);

  return [translatedConstant];
};

export const handleMomenti18n = (i18n: Object, momentInstance: any) => {
  const lng = String(get(i18n, 'language')).toLowerCase();
  if (lng !== 'pt-br' && lng !== 'pt') {
    momentInstance.locale('es');
  } else {
    momentInstance.locale('pt-br');
  }
};


// [IsMobile, isIPad or Landscape]
export const useIsMobile = () => {
  const { ui } = reactRedux.useSelector((state) => ({
    ui: state.ui,
  }));

  const isMobile = checkIfIsMobile(ui);
  // Mobile platform is defined as a cordova app on a device with a screen width less than 1020px
  return [isMobile, isMobile && window.isCordovaApp && window.innerWidth >= 1024];
};

type WindowSizeHookResponse = {
  width: number,
  height: number,
}

export const useWindowSize = (): WindowSizeHookResponse => {
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []); // Empty array ensures that effect is only run on mount

  return windowSize;
};

export function useEventListener(eventName: string, handler: Function, element: any = window) {
  // Create a ref that stores handler
  const savedHandler = useRef();

  // Update ref.current value if handler changes.
  // This allows our effect below to always get latest handler ...
  // ... without us needing to pass it in effect deps array ...
  // ... and potentially cause effect to re-run every render.
  useEffect(() => {
    savedHandler.current = handler;
  }, [handler]);

  useEffect(
    () => {
      // Make sure element supports addEventListener
      // On
      const isSupported = element && element.addEventListener;
      if (!isSupported) return;

      // Create event listener that calls handler function stored in ref
      const eventListener = event => (savedHandler.current ? savedHandler.current(event) : () => { });

      // Add event listener
      element.addEventListener(eventName, eventListener);

      // Remove event listener on cleanup
      return () => {
        element.removeEventListener(eventName, eventListener);
      };
    },
    [eventName, element], // Re-run if eventName or element changes
  );
}


export const useNotifyUserAboutRequiredShareInfo = (patient: any, onDeny?: Function): ((method: 'email' | 'wtt' | 'birthdate') => void) => {
  const dispatch = rRedux.useDispatch();

  return (method: 'email' | 'wtt' | 'birthdate') => {
    if (!method) return;
    const goToPatientEdit = () => {
      dispatch(router.pushPath('patientProfile', { id: patient.id }));
      if (patient.full) {
        const getFieldToFocus = () => {
          switch (method) {
            case 'email': return 'email';
            case 'wtt': return 'mobilePhone';
            case 'birthdate': return 'birthdate';
            default: return null;
          }
        };

        dispatch(openModal('PatientFormModal', {
          id: patient.id,
          focus: getFieldToFocus(),
        }));
      } else {
        dispatch(getPatient(patient.id, true));
      }
    };

    if (method === 'email') {
      UserNotifications.customConfirmI18n(
        'error/direct_share/email/not_present',
        'button/direct_share/confirm_button/ok',
        'button/direct_share/confirm_button/cancel',
        'color-timeline',
        '#000',
        false,
      ).then((value) => {
        if (value) {
          goToPatientEdit();
        }
      });
    }

    if (method === 'birthdate') {
      UserNotifications.customConfirmI18n(
        'error/direct_share/birthdate/not_present',
        'button/direct_share/confirm_button/ok',
        'button/direct_share/confirm_button/cancel',
        'color-timeline',
        '#000',
        false,
      ).then((value) => {
        if (value) {
          return goToPatientEdit();
        }

        if (onDeny) {
          onDeny();
        }
      });
    }

    if (method === 'wtt') {
      UserNotifications.customConfirmI18n(
        'error/direct_share/mobile_phone/not_present',
        'button/direct_share/confirm_button/ok',
        'button/direct_share/confirm_button/cancel',
        'color-timeline',
        '#000',
        false,
      ).then((value) => {
        if (value) {
          goToPatientEdit();
        }
      });
    }
  };
};
