// @flow
import type { Action, ContextState, Dispatch } from '../../../types';

// ------------------------------------
// Constants
// ------------------------------------
export const OPEN_TOAST = 'toasts.OPEN_TOAST';
export const CLOSE_ACTIVE_TOAST = 'toasts.CLOSE_ACTIVE_TOAST';

// ------------------------------------
// Actions
// ------------------------------------
export const openToast = (data?: Object = {}) => (
  (dispatch: Dispatch) => {
    dispatch({ type: OPEN_TOAST, data });
  }
);

export const closeActiveToast = () => (
  (dispatch: Dispatch) => {
    dispatch({ type: CLOSE_ACTIVE_TOAST });
  }
);

export const toastActions = {
  openToast,
  closeActiveToast,
};

// ------------------------------------
// Reducer
// ------------------------------------
export default function toastsReducer (state: ContextState, action: Action): ContextState {
  switch (action.type) {
    case OPEN_TOAST:
      return state
        .setIn(['toasts', 'active'], true)
        .setIn(['toasts', 'data'], action.data);

    case CLOSE_ACTIVE_TOAST:
      return state
        .setIn(['toasts', 'active'], false)
        .setIn(['toasts', 'data'], null);

    default:
      return state;
  }
}
