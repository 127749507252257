// @flow
import React from 'react';
import { get, keys, map } from 'lodash';
import { SettingsMenu, PlatformSwitch, AppMobileHeader, ConditionalFeature, AppMobileFooter } from '@blocks';
import { Button, ClickableList, ProgressBar, Loading } from '@elements';
import type { CustomFormTemplatesState, User, BundledPermissions } from '@types';
import { sort, alphabeticCompare, createNsTranslator } from '@helpers/helpers';
import { withTranslation } from 'react-i18next';

type Props = {
  customFormTemplates: CustomFormTemplatesState,
  user: User,
  onLogout: Function,
  t: Function,
  permissions: BundledPermissions,
  featureEnabled: boolean,
};

class CustomFormsList extends React.Component<Props> {
  render() {
    const {
      customFormTemplates, user, onLogout, featureEnabled,
    } = this.props;

    const _ = createNsTranslator('settings', this.props.t);

    const templateIds = keys(customFormTemplates.data);
    const templateItems = sort(map(templateIds, (templateId) => {
      const template = get(customFormTemplates, ['data', templateId]);
      return {
        text: template.name,
        preventClick: true,
      };
    }), alphabeticCompare('text'));
    const templatesLoading = get(customFormTemplates, ['isLoading']);

    const content = (
      <React.Fragment>
        <div className="toolbar dashed">
          <h1>{_('label/custom_forms/forms_list')}</h1>
        </div>
        {templateIds.length === 0 && templatesLoading && <Loading />}
        {templateItems.length > 0 && (
        <ClickableList
          items={templateItems}
          card
        />
        )}
        <p>
          {_('label/custom_forms/text1')}
        </p>
        <p>
          {_('label/custom_forms/contact_text')}
        </p>
        <Button text={_('action/talk_support', 'global')} onClick={this.onSupportButtonClick} />
      </React.Fragment>
    );

    return (
      <PlatformSwitch
        inject
        desktop={() => (
          <div id="doctorsPage" className="appContent settingsPage">
            {templatesLoading && templateIds.length > 0 && <ProgressBar show={templatesLoading} />}
            <aside className="sidebar">
              <SettingsMenu user={user} onLogout={onLogout} permissions={this.props.permissions} />
            </aside>
            <section className="mainSection">
              <ConditionalFeature enabled={featureEnabled}>
                <div className="centerContent">
                  {content}
                </div>
              </ConditionalFeature>
            </section>
          </div>
        )}
        mobile={() => (
          <React.Fragment>
            <AppMobileHeader title="Formulários" />
            <div id="doctorsPage" className="appContent settingsPage">
              {templatesLoading && <ProgressBar show={templatesLoading} />}
              <section className="mainSection">
                <ConditionalFeature enabled={featureEnabled}>
                  <div className="centerContent">
                    {content}
                  </div>
                </ConditionalFeature>
              </section>
            </div>
            <AppMobileFooter />
          </React.Fragment>
        )}
      />
    );
  }

  onSupportButtonClick = () => {
    if (window.Intercom) {
      window.Intercom('show');
    }
  };
}

export default withTranslation()(CustomFormsList);
