// @flow
import React from 'react';
import { map, get } from 'lodash';
import { SettingsMenu, PlatformSwitch, AppMobileHeader, EmptyState, AppMobileFooter } from '@blocks';
import { Button, ClickableList, Icon } from '@elements';
import type { User, Secretaries, BundledPermissions } from '@types';
import { sort, alphabeticCompare, createNsTranslator } from '@helpers/helpers';
import { withTranslation } from 'react-i18next';

type Props = {
  user: User,
  secretaries: Secretaries,
  onLogout: Function,
  t: Function,
  permissions: BundledPermissions,
};

const sortSecretaries = secretaries => sort(secretaries, alphabeticCompare('text'));

class SecretariesList extends React.Component<Props> {
  render() {
    const {
      user, secretaries, onLogout,
    } = this.props;

    const _ = createNsTranslator('settings', this.props.t);

    const secretariesItems = map(secretaries, (secretary: any) => ({
      customRendererData: {
        text: secretary.name,
        manager: get(secretary, 'manager', false),
      },
      linkTo: `/ajustes/secretarias/${secretary.id}`,
    }));


    const render = (item) => ([
      <span key="text">{item.text}</span>,
      item.manager ? <Icon key="icon" className="icon" iconName="account-manager" /> : null,
    ]);


    const pageContent = (
      <React.Fragment>
        <div className="toolbar dashed">
          <h1>{_('label/secretaries/list/title')}</h1>
          <Button className="primary" text={_('label/secretaries/list/invite')} linkTo="/ajustes/secretarias/nova" />
        </div>
        {secretariesItems.length > 0 ? (
          <ClickableList items={sortSecretaries(secretariesItems)} card customRenderer={render} />
        ) : (
          <EmptyState
            padding={50}
            title={_('label/secretaries/list/empty')}
            hint={() => (
              <React.Fragment>
                {_('label/secretaries/list/hint_1')}
                <br />
                {_('label/secretaries/list/hint_2')}
              </React.Fragment>
            )}
          />
        )}
      </React.Fragment>
    );

    return (
      <PlatformSwitch
        inject
        desktop={() => (
          <div className="appContent settingsPage">
            <aside className="sidebar">
              <SettingsMenu user={user} onLogout={onLogout} permissions={this.props.permissions} />
            </aside>
            <section className="mainSection">
              <div className="centerContent">
                {pageContent}
              </div>
            </section>
          </div>
        )}
        mobile={() => (
          <React.Fragment>
            <AppMobileHeader title={_('label/secretaries/list/users')} backLinkTo="/ajustes" />
            <div className="appContent settingsPage">
              <section className="mainSection">
                <div className="centerContent">{pageContent}</div>
              </section>
            </div>
            <AppMobileFooter />
          </React.Fragment>
        )}
      />
    );
  }
}

export default withTranslation()(SecretariesList);
