// @flow
import React from 'react';
import { SettingsMenu, AppMobileHeader, PlatformSwitch, AppMobileFooter } from '@blocks';
import { Button, ClickableList, Loading, Icon } from '@elements';
import type { User, BundledPermissions } from '@types';
import { withTranslation } from 'react-i18next';
import { createNsTranslator } from '@helpers/helpers';

type TagTemplateListItem = {
  linkTo?: ?string,
  customRendererData: {
    id: number,
    text: string,
    special: boolean,
  }
};

type Props = {
  user: User,
  onLogout: Function,
  isLoading: boolean,
  tagTemplates: Array<{
    id: number,
    text: string,
    linkTo?: ?string,
    preventClick: boolean,
  }>,
  permissions: BundledPermissions,
  tagTemplates: Array<TagTemplateListItem>,
  onShowBlockMessage: Function,
  t: Function,
};

const alphaSort = (a, b) => String(a.text).localeCompare(b.text);

class TagTemplatesList extends React.Component<Props> {
  render() {
    const {
      user, isLoading, onLogout, tagTemplates,
    } = this.props;

    const _ = createNsTranslator('settings', this.props.t);

    const renderer = (item: Object, key: string) => [
      <div key={`text-${key}`}>{item.text}</div>,
      item.special ? <Icon key={`icon-${key}`} iconName="lock" /> : null,
    ];

    const content = (
      <ClickableList
        items={tagTemplates}
        onSort={alphaSort}
        card
        customRenderer={renderer}
        onListItemClick={this.handleItemClick}
      />
    );

    return (
      <PlatformSwitch
        inject
        desktop={() => (
          <div className="appContent settingsPage">
            <aside className="sidebar">
              <SettingsMenu user={user} onLogout={onLogout} permissions={this.props.permissions} />
            </aside>
            <section className="mainSection">
              <div className="centerContent">
                <div className="toolbar dashed">
                  <h1>{_('label/tags/list/title')}</h1>
                  <Button className="primary" text={_('label/tags/list/new')} linkTo="/ajustes/etiquetas/novo" />
                </div>
                {isLoading ? <Loading /> : content}
              </div>
            </section>
          </div>
        )}
        mobile={() => (
          <React.Fragment>
            <AppMobileHeader title={_('label/tags/list/title')} backLinkTo="/ajustes" />
            <div className="appContent settingsPage">
              <section className="mainSection">
                <div className="centerContent">
                  <div className="toolbar dashed">
                    {_('label/tags/list/title')}
                    <Button className="primary" text={_('label/tags/list/new')} linkTo="/ajustes/etiquetas/novo" />
                  </div>
                  {isLoading ? <Loading /> : content}
                </div>
              </section>
            </div>
            <AppMobileFooter />
          </React.Fragment>
        )}
      />
    );
  }

  handleItemClick = (item: Object) => {
    if (item.special) {
      this.props.onShowBlockMessage();
    }
  };
}

export default withTranslation()(TagTemplatesList);
