// @flow
import React from 'react';
import { connect } from 'react-redux';
import { get } from 'lodash';
import { actions as authActions } from '../redux/modules/auth';
import { SignUpBasic as SignUpBasicView } from '../components/views';
import type { AuthState, Dispatch } from '../types';

type Props = {
  auth: AuthState,
  userId: number,
  dispatch: Function,
};

class SignUpBasic extends React.Component<Props> {
  componentWillMount() {
    const { dispatch } = this.props;
    dispatch(authActions.resetSignUpState());
  }

  render() {
    const { auth, userId } = this.props;

    return (
      <SignUpBasicView
        authErrors={get(auth, ['errors'])}
        inProgress={auth.inProgress}
        userId={userId}
        onSignupStep2={this.handleSignupStep2}
      />
    );
  }

  handleSignupStep2 = (userId: number, name: string, gender: string, mobilePhone: string, subscribeEmailList: boolean) => {
    this.props.dispatch(authActions.signupStep2({
      userId,
      name,
      mobilePhone,
      gender,
      subscribeEmailList,
    }));
  };
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  userId: get(state, ['context', 'user', 'id']),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({ dispatch });

export default connect<any, any, any, any, any, any>(
  mapStateToProps,
  mapDispatchToProps,
)(SignUpBasic);
