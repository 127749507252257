export default {
  'label/new_appointment': 'Nueva Cita',
  'action/refresh': 'Actualizar Agenda',
  'action/all_rooms': 'Todas las Salas',
  'label/all_subfilters': 'Todos',
  'label/calendar_day': 'Día',
  'label/calendar_week': 'Semana',
  'label/calendar_today': 'Hoy',
  'label/blocked_time': 'Tiempo Bloqueado',
  'label/hour': 'Hora',
  'action/select_month': 'Selecciona el mes',
  'label/duration': 'Duración',
  'action/hide': 'Eliminar de la Agenda',
  'action/delete_only': 'Eliminar solo este evento',
  'action/delete_future': 'Eliminar todos los eventos futuros',
  'action/schedule_for': 'Programar para',
  'label/title': 'Título',
  'label/recurring_schedule': 'Programación Recurrente',
  'label/status': 'Status',
  'label/whatsApp': 'Confirmar vía WhatsApp',
  'label/sendMessage': 'Enviar mensaje',
  'label/contracted_services': 'Servicios Contratados',
  'label/show_obs': 'Mostrar observaciones en el expediente',
  'label/status/scheduled': 'Cita Programada',
  'label/status/sms_sent': 'SMS Enviado',
  'label/status/sms_confirmed': 'Confirmado por el Paciente',
  'label/status/sms_canceled': 'Cancelado por el Paciente',
  'label/status/confirmed': 'Cita Confirmada',
  'label/status/patient_arrived': 'Paciente Llegó',
  'label/status/patient_attended': 'Paciente Atendido',
  'label/status/patient_missed': 'Paciente Faltó a la Cita',
  'label/status/patient_canceled': 'Paciente Canceló la Cita',
  'label/status/canceled_by_doctor': 'Cita Cancelada por el Consultorio',
  'label/change_status': 'Cambiar Status',
  'label/services/empty_edit': 'La cita no tiene servicios',
  'label/services/empty_new': 'El Doctor seleccionado no tiene servicios',
  'label/services/empty_search': 'Ningún servicio encontrado para esta búsqueda',
  'label/services/recents': 'Más Recientes',
  'label/services/private': 'Particular',
  'label/services/health_plan': 'Plan de Salud',
  'label/services/loading': 'Cargando servicios',
  'action/services/add_services': 'Agregar servicios',
  'label/patient_vip': 'Paciente con Gratuidad',
  'label/services/service_list': 'Lista de Servicios',
  'action/services/view_selected_services': 'Ver todos los servicios seleccionados',
  'label/rooms/select_one_room': 'Selecciona una sala',
  'label/recurrency/recurrent_appointment': 'Cita Recurrente',
  'action/recurrency/enable_recurrency': 'Programación semanal',
  'action/recurrency/enable_biweekly_recurrency': 'Programación quincenal',
  'label/recurrency/ends_after': 'Termina después de',
  'label/recurrency/delete_all_future_events': 'Eliminar todos los eventos futuros',
  'label/doctor_select/title': 'Selecciona un Doctor',
  'label/doctor_select/loading': 'Cargando doctores',
  'label/patient_select/add_patient': 'Registrar nuevo paciente',
  'label/patient_select/search_patient': 'Buscar paciente',
  'label/patient_select/search_all_patients': 'Todos los pacientes',
  'label/patient_select/reschedule_patients': 'Pacientes con etiqueta para reprogramar',
  'label/patient_select/loading': 'Cargando pacientes',
  'label/patient_form/name': 'Nombre',
  'label/patient_form/birthdate': 'Fecha de nacimiento',
  'label/patient_form/cpf': 'CPF',
  'label/patient_form/legalId': 'Identidad',
  'label/patient_form/mobile_phone': 'Teléfono Móvil',
  'label/patient_form/home_phone': 'Teléfono Fijo',
  'label/patient_form/email': 'E-mail',
  'label/patient_form/recommended_by': 'Recomendación',
  'label/patient_form/profession': 'Profesión',
  'label/patient_form/placeOfBirth': 'Lugar de nacimiento',

  'label/patient_form/maritalStatusCode': 'Estado Civil',
  'label/patient_form/doctorId': 'Responsable',
  'label/patient_form/medic': 'Médico',
  'label/patient_form/mobilePhone': 'Teléfono Móvil',
  'label/patient_form/homePhone': 'Teléfono Fijo',
  'label/patient_form/otherPhone': 'Teléfono Adicional',
  'label/patient_form/father': 'Padre',
  'label/patient_form/mother': 'Madre',
  'label/patient_form/guardianName': 'Responsable',
  'label/patient_form/guardianRelationship': 'Parentesco',
  'label/patient_form/insuranceCompany': 'Seguro',
  'label/patient_form/insurancePlan': 'Plan',
  'label/patient_form/insuranceNumber': 'Número de Póliza',
  'label/patient_form/insuranceExpirationDate': 'Fecha de Vencimiento',

  'label/patient_form/insurance_plan': 'Plan de Salud',
  'label/patient_form/title': 'Nuevo Paciente',
  'label/patient_info/title': 'Resumen de Registro',
  'label/patient_info/responsible': 'Responsable',
  'label/patient_info/other_phone': 'Teléfono Adicional',
  'label/appointment_with': 'Cita con',
  'label/appointment_sms_canceled': 'Cita cancelada por el paciente',
  'label/appointment_patient_msg': 'Paciente dejó un mensaje',

  'label/patient_form/patient_code': 'Código',

  'action/click_to_create_appointment_at': 'Haz clic para crear una cita a las',
  'action/click_to_create_appointment': 'Haz clic para crear una cita',
};
