// @flow
import React from 'react';
import type { Node } from 'react';
import classnames from 'classnames';
import './DoubleModal.scss';

type Props = {
  children: Node,
  className?: string,
  id?: string,
  closeOnOverlayClick?: Function,
};

export default class DoubleModal extends React.Component<Props> {
  render() {
    const {
      children,
      className,
      id,
      closeOnOverlayClick,
    } = this.props;

    const cssClasses = classnames(['doubleModal', className]);

    return (
      <div id={id} className={cssClasses}>
        <div className="doubleModalOverlay" onClick={closeOnOverlayClick} />
        <div className="doubleModalLayout">
          <div className="doubleModalGrid">
            { children }
          </div>
        </div>
      </div>
    );
  }
}
