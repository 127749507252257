// @flow
import React from 'react';
import { PatientServices as PatientServicesView } from '../components/views';

export default class PatientServices extends React.Component<void> {
  render() {
    return (
      <PatientServicesView />
    );
  }
}
