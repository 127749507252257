// @flow
import React from 'react';
import { get, keys, map } from 'lodash';
import { SettingsMenu, PlatformSwitch, AppMobileHeader, ConditionalFeature, AppMobileFooter } from '@blocks';
import { Breadcrumb, Button, ClickableList, ProgressBar, Loading } from '@elements';
import type { User, BundledPermissions } from '@types';
import { sort, alphabeticCompare, createNsTranslator } from '@helpers/helpers';
import { withTranslation } from 'react-i18next';

type Props = {
  user: User,
  examsPrescriptionsTemplates: Object,
  onLogout: Function,
  permissions: BundledPermissions,
  featureEnabled: boolean,
  t: Function,
};

class ExamsPrescriptionsTemplatesList extends React.Component<Props> {
  render() {
    const {
      examsPrescriptionsTemplates, user, onLogout, featureEnabled,
    } = this.props;

    const _ = createNsTranslator('settings', this.props.t);

    const breadcrumbLinks = [
      { text: _('label/prescription_menu/title'), linkTo: '/ajustes/modelos' },
      { text: _('label/exam_models/list/title') },
    ];

    const templateIds = keys(examsPrescriptionsTemplates.data);
    const templateItems = sort(map(templateIds, (templateId) => {
      const template = get(examsPrescriptionsTemplates, ['data', templateId]);
      return {
        text: template.name,
        linkTo: `/ajustes/modelos/exames/${template.id}`,
      };
    }), alphabeticCompare('text'));
    const templatesLoading = get(examsPrescriptionsTemplates, ['isLoading']);
    const noTemplates = (!templateItems.length && !templatesLoading);

    let noTemplatesContent;
    if (noTemplates) {
      noTemplatesContent = (
        <div>
          <h1 className='noResultsMessage'>
            {_('label/exam_models/list/empty_title')}
          </h1>
          <span>{_('label/exam_models/list/empty_hint')}</span>
        </div>
      );
    } else if (templatesLoading && templateItems.length === 0) {
      noTemplatesContent = (
        <div className='loadingWrapper'>
          <Loading />
        </div>
      );
    }


    const pageContent = (
      <React.Fragment>
        <div className="toolbar dashed">
          <h1>{_('label/exam_models/list/title')}</h1>
          <Button className="primary" text={_('label/exam_models/list/new_exam')} linkTo="/ajustes/modelos/exames/novo" />
        </div>
        {noTemplatesContent}
        {templateItems.length > 0 && (
          <ClickableList items={templateItems} card />
        )}
      </React.Fragment>
    );
    return (
      <PlatformSwitch
        inject
        desktop={() => (
          <div className="appContent settingsPage">
            {!noTemplates && templatesLoading && <ProgressBar show />}
            <aside className="sidebar">
              <SettingsMenu user={user} onLogout={onLogout} permissions={this.props.permissions} />
            </aside>
            <section className="mainSection">
              <ConditionalFeature enabled={featureEnabled}>
                <div className="centerContent">
                  <Breadcrumb items={breadcrumbLinks} />
                  {pageContent}
                </div>
              </ConditionalFeature>
            </section>
          </div>
        )}
        mobile={() => (
          <React.Fragment>
            <AppMobileHeader title={_('label/exam_models/list/title')} backLinkTo="/ajustes/modelos" />
            <div className="appContent settingsPage">
              {!noTemplates && templatesLoading && <ProgressBar show />}
              <section className="mainSection">
                <ConditionalFeature enabled={featureEnabled}>
                  <div className="centerContent">{pageContent}</div>
                </ConditionalFeature>
              </section>
            </div>
            <AppMobileFooter />
          </React.Fragment>
        )}
      />
    );
  }
}

export default withTranslation()(ExamsPrescriptionsTemplatesList);
