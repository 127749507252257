// @flow
import React from 'react';
import { SettingsMenu, PlatformSwitch, AppMobileHeader, AppMobileFooter } from '@blocks';
import type { User, BundledPermissions } from '@types';

type Props = {
  user: User,
  onLogout: Function,
  permissions: BundledPermissions,
};

export default class SettingsSms extends React.Component<Props> {
  render() {
    const { user, onLogout } = this.props;

    return (
      <PlatformSwitch
        inject
        desktop={() => (
          <div id="myPlansPage" className="appContent settingsPage">
            <aside className="sidebar">
              <SettingsMenu user={user} onLogout={onLogout} permissions={this.props.permissions} />
            </aside>
            <section className="mainSection">
              <div className="centerContent">
                <div className="toolbar dashed">
                  <h1>SMS</h1>
                </div>
              </div>
            </section>
          </div>
        )}
        mobile={() => (
          <React.Fragment>
            <AppMobileHeader title="Configurações de Impressão" />
            <div id="myPlansPage" className="appContent settingsPage">
              <section className="mainSection">
                <div className="toolbar dashed">
                  <h1>SMS</h1>
                </div>
              </section>
            </div>
            <AppMobileFooter />
          </React.Fragment>
        )}
      />
    );
  }

  onSupportButtonClick = () => {
    if (window.Intercom) {
      window.Intercom('show');
    }
  };
}
