// @flow
import moment from 'moment';
import { get, isFunction, map, omit, dropRight } from 'lodash';
import { isPresent, reportError } from '@helpers/helpers';
import { appControlActions } from '../appControl';
import { Api } from '../../../helpers';
import ClinicService from '../../../services/ClinicService';
import { router } from '../../../routes/router';
import type
{
  Action,
  ContextState,
  Clinic,
  SimpleClinic,
  Dispatch,
  ClinicInput,
  SecretaryInput,
  DoctorUpdateInput,
  ControlledPrescriptionLayoutInput,
  GeneralPrintConfigInput,
  PrescritionPrintConfigInput,
  DoctorCreateInput,
  SecretaryCreateInput,
} from '../../../types';
import showPDF from '../../middleware/showPDF';

type PatientsResponseType = {
  clinic: Clinic,
  multiClinics: Array<SimpleClinic>,
};

// ------------------------------------
// Constants
// ------------------------------------
const CLINICS_LOADING = 'clinics.CLINICS_LOADING';
const CLINICS_LOADED = 'clinics.CLINICS_LOADED';
const CLINICS_LOADING_FAILED = 'clinics.CLINICS_LOADING_FAILED';

const CLINICS_UPDATE_LOADING = 'clinics.CLINICS_UPDATE_LOADING';
const CLINICS_UPDATE_LOADED = 'clinics.CLINICS_UPDATE_LOADED';
const CLINICS_UPDATE_FAILED = 'clinics.CLINICS_UPDATE_FAILED';

const CLINICS_SET_ACTIVE_LOADING = 'clinics.CLINICS_SET_ACTIVE_LOADING';
const CLINICS_SET_ACTIVE_LOAD_SUCCESS = 'clinics.CLINICS_SET_ACTIVE_LOAD_SUCCESS';
const CLINICS_SET_ACTIVE_LOAD_FAILED = 'clinics.CLINICS_SET_ACTIVE_LOAD_FAILED';

const CLINICS_SET_CLINIC_SAVING = 'clinics.CLINICS_SET_CLINIC_SAVING';

const CLINICS_SET_MEMED_LOAD_STATUS = 'clinics.CLINICS_SET_MEMED_LOAD_STATUS';

const CLINICS_UPDATE_SECRETARY_LOADING = 'clinics.CLINICS_UPDATE_SECRETARY_LOADING';
const CLINICS_UPDATE_SECRETARY_LOADED = 'clinics.CLINICS_UPDATE_SECRETARY_LOADED';
const CLINICS_UPDATE_SECRETARY_FAILED = 'clinics.CLINICS_UPDATE_SECRETARY_FAILED';

const CLINICS_UPDATE_CONTROLLED_PRESCRIPTION_LAYOUT_LOADING = 'clinics.CLINICS_UPDATE_CONTROLLED_PRESCRIPTION_LAYOUT_LOADING';
const CLINICS_UPDATE_CONTROLLED_PRESCRIPTION_LAYOUT_LOADED = 'clinics.CLINICS_UPDATE_CONTROLLED_PRESCRIPTION_LAYOUT_LOADED';
const CLINICS_UPDATE_CONTROLLED_PRESCRIPTION_LAYOUT_FAILED = 'clinics.CLINICS_UPDATE_CONTROLLED_PRESCRIPTION_LAYOUT_FAILED';

const CLINICS_UPDATE_GENERAL_PRINT_CONFIG_LOADING = 'clinics.CLINICS_UPDATE_GENERAL_PRINT_CONFIG_LOADING';
const CLINICS_UPDATE_GENERAL_PRINT_CONFIG_LOADED = 'clinics.CLINICS_UPDATE_GENERAL_PRINT_CONFIG_LOADED';
const CLINICS_UPDATE_GENERAL_PRINT_CONFIG_FAILED = 'clinics.CLINICS_UPDATE_GENERAL_PRINT_CONFIG_FAILED';

const CLINICS_FETCH_PRESCRIPTION_PRINT_CONFIG_LOADING = 'clinics.CLINICS_FETCH_PRESCRIPTION_PRINT_CONFIG_LOADING';
const CLINICS_FETCH_PRESCRIPTION_PRINT_CONFIG_LOADED = 'clinics.CLINICS_FETCH_PRESCRIPTION_PRINT_CONFIG_LOADED';
const CLINICS_FETCH_PRESCRIPTION_PRINT_CONFIG_FAILED = 'clinics.CLINICS_FETCH_PRESCRIPTION_PRINT_CONFIG_FAILED';

const CLINICS_UPDATE_PRESCRIPTION_PRINT_CONFIG_LOADING = 'clinics.CLINICS_UPDATE_PRESCRIPTION_PRINT_CONFIG_LOADING';
const CLINICS_UPDATE_PRESCRIPTION_PRINT_CONFIG_LOADED = 'clinics.CLINICS_UPDATE_PRESCRIPTION_PRINT_CONFIG_LOADED';
const CLINICS_UPDATE_PRESCRIPTION_PRINT_CONFIG_FAILED = 'clinics.CLINICS_UPDATE_PRESCRIPTION_PRINT_CONFIG_FAILED';

const CLINICS_UPDATE_DOCTOR_LOADING = 'clinics.CLINICS_UPDATE_DOCTOR_LOADING';
const CLINICS_UPDATE_DOCTOR_LOADED = 'clinics.CLINICS_UPDATE_DOCTOR_LOADED';
const CLINICS_UPDATE_DOCTOR_FAILED = 'clinics.CLINICS_UPDATE_DOCTOR_FAILED';

const CLINICS_CREATE_DOCTOR_LOADING = 'clinics.CLINICS_CREATE_DOCTOR_LOADING';
const CLINICS_CREATE_DOCTOR_LOADED = 'clinics.CLINICS_CREATE_DOCTOR_LOADED';
const CLINICS_CREATE_DOCTOR_FAILED = 'clinics.CLINICS_CREATE_DOCTOR_FAILED';

const CLINICS_CREATE_SECRETARY_LOADING = 'clinics.CLINICS_CREATE_SECRETARY_LOADING';
const CLINICS_CREATE_SECRETARY_LOADED = 'clinics.CLINICS_CREATE_SECRETARY_LOADED';
const CLINICS_CREATE_SECRETARY_FAILED = 'clinics.CLINICS_CREATE_SECRETARY_FAILED';

const CLINICS_DELETE_SECRETARY_LOADING = 'clinics.CLINICS_DELETE_SECRETARY_LOADING';
const CLINICS_DELETE_SECRETARY_LOADED = 'clinics.CLINICS_DELETE_SECRETARY_LOADED';
const CLINICS_DELETE_SECRETARY_FAILED = 'clinics.CLINICS_DELETE_SECRETARY_FAILED';

const CLINICS_UPLOAD_LOGO_LOADING = 'clinics.CLINICS_UPLOAD_LOGO_LOADING';
const CLINICS_UPLOAD_LOGO_LOADED = 'clinics.CLINICS_UPLOAD_LOGO_LOADED';
const CLINICS_UPLOAD_LOGO_FAILED = 'clinics.CLINICS_UPLOAD_LOGO_FAILED';

const CLINICS_DELETE_LOGO_LOADING = 'clinics.CLINICS_DELETE_LOGO_LOADING';
const CLINICS_DELETE_LOGO_LOADED = 'clinics.CLINICS_DELETE_LOGO_LOADED';
const CLINICS_DELETE_LOGO_FAILED = 'clinics.CLINICS_DELETE_LOGO_FAILED';

// ------------------------------------
// Actions
// ------------------------------------

export const deleteClinicLogo = (clinicId: number, cb?: Function) => (
  (dispatch: Dispatch) => {
    dispatch({ type: CLINICS_DELETE_LOGO_LOADING });
    Api.destroy(router.getApiRoute('deleteClinicLogo', { clinicId }), {
      success: () => {
        dispatch({
          type: CLINICS_DELETE_LOGO_LOADED,
        });
        if (cb) {
          cb(true);
        }
      },
      error: (error) => {
        dispatch({ type: CLINICS_DELETE_LOGO_FAILED, error: { message: error.message } });
        dispatch(reportError(error));
        if (cb) {
          cb(false);
        }
      },
    });
  }
);


export const uploadClinicLogo = (file: Object, fileType: string, sizes: Object) => (
  (dispatch: Dispatch) => {
    const getSignedUploadData = router.getApiRoute('getSignedUploadData');
    const getSignedUploadDataRoute = {
      ...getSignedUploadData,
      path: `${getSignedUploadData.path}?title=${file.name}`,
    };

    Api.get(getSignedUploadDataRoute, {
      success: (response) => {
        const { signedData } = response;

        const formData = new FormData();
        formData.append('key', signedData.key);
        formData.append('acl', signedData.acl);
        formData.append('policy', signedData.policy);
        formData.append('x-amz-credential', signedData['x-amz-credential']);
        formData.append('x-amz-algorithm', signedData['x-amz-algorithm']);
        formData.append('x-amz-date', signedData['x-amz-date']);
        formData.append('x-amz-signature', signedData['x-amz-signature']);
        formData.append('file', file);

        // Uploading image to S3
        const directUploadUrl = signedData.action + '/' + encodeURIComponent(dropRight(signedData.key.split('/')).join('/') + '/' + file.name);
        dispatch({ type: CLINICS_UPLOAD_LOGO_LOADING });

        const signedDataActionApiRoute = {
          path: signedData.action,
          fallback: false,
        };

        Api.post(signedDataActionApiRoute, {
          data: formData,
          success: () => {
            const attachmentData = {
              dimensions: `${sizes.width}x${sizes.height}`,
              directUploadUrl,
              fileContentType: file.type || fileType,
              lsId: file.lastModified,
            };

            Api.post(router.getApiRoute('setPatientClinicLogo', {}), {
              data: {
                clinicLogo: attachmentData,
              },
              success: (clinicLogoRes) => {
                dispatch({
                  type: CLINICS_UPLOAD_LOGO_LOADED,
                  clinicLogo: clinicLogoRes.data.clinicLogo,
                });
              },
              error: (ClinicLogoError) => {
                dispatch({
                  type: CLINICS_UPLOAD_LOGO_FAILED,
                  error: { message: ClinicLogoError.message },
                });
                dispatch(reportError(ClinicLogoError));
              },
            });
          },
          error: (uploadError) => {
            dispatch({
              type: CLINICS_UPLOAD_LOGO_FAILED,
              error: { message: uploadError.message },
            });
            dispatch(reportError(uploadError));
          },
        });
      },
      error: (signError) => {
        dispatch({
          type: CLINICS_UPLOAD_LOGO_FAILED,
          error: { message: signError.message },
        });
        dispatch(reportError(signError));
      },
    });
  }
);

export const createDoctor = (doctor: DoctorCreateInput, callback: Function = () => {}) => (
  (dispatch: Dispatch) => {
    dispatch({ type: CLINICS_CREATE_DOCTOR_LOADING });
    Api.post(router.getApiRoute('createDoctor', { }), {
      data: { doctor },
      success: (response) => {
        dispatch({
          type: CLINICS_CREATE_DOCTOR_LOADED,
          doctor: get(response, ['data', 'doctor']),
        });
        callback(true);
      },
      error: (error) => {
        dispatch({ type: CLINICS_CREATE_DOCTOR_FAILED, error: { message: error.message } });
        const text = isPresent(error.message) ? error.message : 'Ocorreu um erro ao executar esta requisição';
        dispatch(reportError(error));
        callback(false, text);
      },
    });
  }
);

export const createSecretary = (secretary: SecretaryCreateInput, callback: Function = () => {}) => (
  (dispatch: Dispatch) => {
    dispatch({ type: CLINICS_CREATE_SECRETARY_LOADING });
    Api.post(router.getApiRoute('createSecretary', { }), {
      data: { secretary },
      success: (response) => {
        dispatch({
          type: CLINICS_CREATE_SECRETARY_LOADED,
          secretary: get(response, ['data', 'secretary']),
        });
        callback(true);
      },
      error: (error) => {
        dispatch({ type: CLINICS_CREATE_SECRETARY_FAILED, error: { message: error.message } });
        const text = isPresent(error.message) ? error.message : 'Ocorreu um erro ao executar esta requisição';
        dispatch(reportError(error));
        callback(false, text);
      },
    });
  }
);

export const previewPrescriptionLayout = (doctorId: number) => showPDF.action({
  title: 'Receipt PDF',
  apiRoute: router.getApiRoute('previewPrescriptionLayout', { doctorId }),
  prepare: '_any_action',
  success: '_any_action',
  failed: '_any_action',
});

export const updatePrintPrecriptionLayout = (doctorId: number, data: PrescritionPrintConfigInput, callback: Function = () => {}) => (
  (dispatch: Dispatch) => {
    dispatch({ type: CLINICS_UPDATE_PRESCRIPTION_PRINT_CONFIG_LOADING });
    Api.patch(router.getApiRoute('updatePrintPrecriptionLayout', { doctorId }), {
      data,
      success: (response) => {
        dispatch({
          type: CLINICS_UPDATE_PRESCRIPTION_PRINT_CONFIG_LOADED,
          prescriptionLayout: get(response, 'prescription_layout'),
        });
        callback(true);
      },
      error: (error) => {
        dispatch({ type: CLINICS_UPDATE_PRESCRIPTION_PRINT_CONFIG_FAILED, error: { message: error.message } });
        dispatch(reportError(error));
        callback(false);
      },
    });
  }
);

export const fetchPrescriptionLayout = (doctorId: number, callback: Function = () => {}) => (
  (dispatch: Dispatch) => {
    dispatch({ type: CLINICS_FETCH_PRESCRIPTION_PRINT_CONFIG_LOADING });
    Api.get(router.getApiRoute('fetchPrescriptionLayout', { doctorId }), {
      success: (response) => {
        dispatch({
          type: CLINICS_FETCH_PRESCRIPTION_PRINT_CONFIG_LOADED,
          prescriptionLayout: get(response, 'prescription_layout'),
        });
        callback(true);
      },
      error: (error) => {
        dispatch({ type: CLINICS_FETCH_PRESCRIPTION_PRINT_CONFIG_FAILED, error: { message: error.message } });
        dispatch(reportError(error));
        callback(false);
      },
    });
  }
);


export const updateControlledPrescriptionLayout = (data: ControlledPrescriptionLayoutInput, callback: Function = () => {}) => (
  (dispatch: Dispatch) => {
    dispatch({ type: CLINICS_UPDATE_CONTROLLED_PRESCRIPTION_LAYOUT_LOADING });
    Api.patch(router.getApiRoute('updateControlledPrescriptionLayout', {}), {
      data,
      success: (response) => {
        dispatch({
          type: CLINICS_UPDATE_CONTROLLED_PRESCRIPTION_LAYOUT_LOADED,
          clinic: get(response, 'clinic'),
        });
        callback(true);
      },
      error: (error) => {
        dispatch({ type: CLINICS_UPDATE_CONTROLLED_PRESCRIPTION_LAYOUT_FAILED, error: { message: error.message } });
        dispatch(reportError(error));
        callback(false);
      },
    });
  }
);

export const updateGeneralPrintConfig = (data: GeneralPrintConfigInput, callback: Function = () => {}) => (
  (dispatch: Dispatch) => {
    dispatch({ type: CLINICS_UPDATE_GENERAL_PRINT_CONFIG_LOADING });
    Api.patch(router.getApiRoute('updateGeneralPrintConfig', {}), {
      data: { clinic: data },
      success: (response) => {
        dispatch({
          type: CLINICS_UPDATE_GENERAL_PRINT_CONFIG_LOADED,
          clinic: get(response, 'clinic'),
        });
        callback(true);
      },
      error: (error) => {
        dispatch({ type: CLINICS_UPDATE_GENERAL_PRINT_CONFIG_FAILED, error: { message: error.message } });
        dispatch(reportError(error));
        callback(false);
      },
    });
  }
);

export const deleteSecretary = (id: number, callback: Function = () => {}) => (
  (dispatch: Dispatch) => {
    dispatch({ type: CLINICS_DELETE_SECRETARY_LOADING, id });
    Api.destroy(router.getApiRoute('deleteSecretary', { id }), {
      success: () => {
        dispatch({
          type: CLINICS_DELETE_SECRETARY_LOADED,
          id,
        });
        callback(true);
      },
      error: (error) => {
        dispatch({ type: CLINICS_DELETE_SECRETARY_FAILED, error: { message: error.message }, id });
        dispatch(reportError(error));
        callback(false);
      },
    });
  }
);

export const updateSecretary = (id: number, secretary: SecretaryInput, callback: Function = () => {}) => (
  (dispatch: Dispatch) => {
    dispatch({ type: CLINICS_UPDATE_SECRETARY_LOADING });
    Api.patch(router.getApiRoute('updateSecretary', { id }), {
      data: { secretary },
      success: (response) => {
        dispatch({
          type: CLINICS_UPDATE_SECRETARY_LOADED,
          secretary: get(response, 'secretary'),
        });
        callback(true);
      },
      error: (error) => {
        dispatch({ type: CLINICS_UPDATE_SECRETARY_FAILED, error: { message: error.message } });
        dispatch(reportError(error));
        callback(false);
      },
    });
  }
);

export const updateDoctor = (id: number, doctor: DoctorUpdateInput, callback: Function = () => {}) => (
  (dispatch: Dispatch) => {
    dispatch({ type: CLINICS_UPDATE_DOCTOR_LOADING });
    Api.patch(router.getApiRoute('updateDoctor', { id }), {
      data: { doctor },
      success: (response) => {
        dispatch({
          type: CLINICS_UPDATE_DOCTOR_LOADED,
          doctor: get(response, 'doctor'),
        });
        callback(true);
      },
      error: (error) => {
        dispatch({ type: CLINICS_UPDATE_DOCTOR_FAILED, error: { message: error.message } });
        dispatch(reportError(error));
        callback(false);
      },
    });
  }
);

export const setActiveClinic = (id: number) => (
  (dispatch: Dispatch) => {
    dispatch({ type: CLINICS_SET_ACTIVE_LOADING });
    dispatch(router.pushPath('patientsList', {}));
    Api.get(router.getApiRoute('getClinic', { id }), {
      success: (response) => {
        dispatch({
          type: CLINICS_SET_ACTIVE_LOAD_SUCCESS,
          activeClinic: response.clinic,
          multiClinics: response.multiClinics,
        });

        dispatch(appControlActions.updateApp());
      },
      error: (error) => {
        dispatch({ type: CLINICS_SET_ACTIVE_LOAD_FAILED, error: { message: error.message } });
        dispatch(reportError(error));
      },
    });
  }
);

export const updateClinic = (id: number, clinic: ClinicInput, callback?: Function) => (
  (dispatch: Dispatch) => {
    dispatch({ type: CLINICS_UPDATE_LOADING });
    Api.patch(router.getApiRoute('updateClinic', { id }), {
      data: { clinic },
      success: (response) => {
        dispatch({
          type: CLINICS_UPDATE_LOADED,
          activeClinic: get(response, 'clinic', {}),
        });
        if (isFunction(callback)) {
          callback(true);
        }
      },
      error: (error) => {
        dispatch({ type: CLINICS_UPDATE_FAILED, error: { message: error.message } });
        dispatch(reportError(error));
        if (isFunction(callback)) {
          callback(false);
        }
      },
    });
  }
);

export const setClinicSaving = (isSaving: boolean) => (
  (dispatch: Dispatch) => {
    dispatch({ type: CLINICS_SET_CLINIC_SAVING, isSaving });
  }
);

export const setMemedLoadedStatus = (isLoaded: boolean) => (
  (dispatch: Dispatch) => {
    dispatch({ type: CLINICS_SET_MEMED_LOAD_STATUS, isLoaded });
  }
);

const getClinics = (callback?: Function | null = null, activeClinicId?: number) => ({
  types: [CLINICS_LOADING, CLINICS_LOADED, CLINICS_LOADING_FAILED],
  payload: {},

  shouldCall: () => true,
  call: () => {
    if (activeClinicId) {
      return Api.get(router.getApiRoute('getClinicsWithActive', { id: activeClinicId }));
    }

    return Api.get(router.getApiRoute('getClinics', null));
  },
  parseResponse: (response: PatientsResponseType) => {
    if (callback) {
      callback(response);
    }

    window.userWithoutClinic = get(response, 'userWithoutClinic', false);

    return {
      activeClinic: response.clinic,
      multiClinics: response.multiClinics,
      userWithoutClinic: get(response, 'userWithoutClinic', false),
    };
  },
});

export const clinicActions = {
  getClinics,
  updateClinic,
  setClinicSaving,
  setActiveClinic,
  setMemedLoadedStatus,
  updateSecretary,
  updateDoctor,
  updateControlledPrescriptionLayout,
  updateGeneralPrintConfig,
  fetchPrescriptionLayout,
  createDoctor,
  createSecretary,
  deleteSecretary,
};

export const actionTypes = {
  CLINICS_LOADED,
};

// ------------------------------------
// Reducer
// ------------------------------------
export default function clinicsReducer (state: ContextState, action: Action): ContextState {
  let normalizedActiveClinic;
  let secretaries;
  let managerIds;
  let nonManagerIds;
  switch (action.type) {
    case CLINICS_LOADING:
      return state
        .setIn(['clinics', 'isLoading'], true)
        .setIn(['doctors', 'isLoading'], true)
        .setIn(['secretaries', 'isLoading'], true)
        .setIn(['clinics', 'errors'], null);

    case CLINICS_LOADED:
      const { activeClinic, multiClinics } = action;
      const normalized = ClinicService.normalizeActiveClinic(activeClinic);
      const normalizedMulticlinics = ClinicService.normalizeMulticlinics(multiClinics);
      return state
        .setIn(['clinics', 'isLoading'], false)
        .setIn(['secretaries', 'isLoading'], false)
        .setIn(['secretaries', 'isSaving'], false)
        .setIn(['clinics', 'errors'], null)
        .setIn(['clinics', 'activeClinic'], normalized.activeClinic)
        .setIn(['clinics', 'userWithoutClinic'], get(action, 'userWithoutClinic', false))
        .setIn(['clinics', 'data'], normalizedMulticlinics)
        .setIn(['clinics', 'lastFetch'], moment().toISOString())
        .setIn(['doctors', 'isLoading'], false)
        .setIn(['doctors', 'data'], normalized.doctors)
        .setIn(['secretaries', 'data'], normalized.secretaries)
        .setIn(['secretaries', 'managerIds'], normalized.secretaryManagerIds)
        .setIn(['secretaries', 'nonManagerIds'], normalized.secretaryNoManagerIds)
        .setIn(['doctors', 'lastFetch'], moment().toISOString()); // TODO: should be moved into DoctorsReducer

    case CLINICS_LOADING_FAILED:
      return state
        .setIn(['clinics', 'isLoading'], false)
        .setIn(['secretaries', 'isLoading'], false)
        .setIn(['doctors', 'isLoading'], false)
        .setIn(['clinics', 'errors'], [action.error.message]);


    case CLINICS_UPDATE_LOADING:
      return state
        .setIn(['clinics', 'isSaving'], true);

    case CLINICS_UPDATE_LOADED:
      normalizedActiveClinic = ClinicService.normalizeActiveClinic(action.activeClinic);
      return state
        .setIn(['clinics', 'isSaving'], false)
        .setIn(['clinics', 'activeClinic'], normalizedActiveClinic.activeClinic);

    case CLINICS_UPDATE_FAILED:
      return state
        .setIn(['clinics', 'isSaving'], false)
        .setIn(['clinics', 'errors'], [action.error.message]);

    case CLINICS_SET_CLINIC_SAVING:
      return state.setIn(['clinics', 'isSaving'], get(action, 'isSaving', false));

    case CLINICS_SET_ACTIVE_LOADING:
      return state
        .setIn(['clinics', 'isLoading'], true)
        .setIn(['doctors', 'isLoading'], true)
        .setIn(['secretaries', 'isLoading'], true)
        .setIn(['clinics', 'errors'], null);

    case CLINICS_SET_ACTIVE_LOAD_SUCCESS:
      const normalizedNew = ClinicService.normalizeActiveClinic(get(action, 'activeClinic', {}));
      const normalizedMulticlinicsNew = ClinicService.normalizeMulticlinics(get(action, 'multiClinics', {}));
      return state
        .setIn(['clinics', 'isLoading'], false)
        .setIn(['secretaries', 'isLoading'], false)
        .setIn(['secretaries', 'isSaving'], false)
        .setIn(['clinics', 'errors'], null)
        .setIn(['clinics', 'activeClinic'], normalizedNew.activeClinic)
        .setIn(['clinics', 'data'], normalizedMulticlinicsNew)
        .setIn(['clinics', 'lastFetch'], moment().toISOString())
        .setIn(['doctors', 'isLoading'], false)
        .setIn(['doctors', 'data'], normalizedNew.doctors)
        .setIn(['appointments'], {})
        .setIn(['secretaries', 'data'], normalizedNew.secretaries)
        .setIn(['secretaries', 'managerIds'], normalizedNew.secretaryManagerIds)
        .setIn(['secretaries', 'nonManagerIds'], normalizedNew.secretaryNoManagerIds)
        .setIn(['doctors', 'lastFetch'], moment().toISOString()); // TODO: should be moved into DoctorsReducer

    case CLINICS_SET_ACTIVE_LOAD_FAILED:
      return state
        .setIn(['clinics', 'isLoading'], false)
        .setIn(['secretaries', 'isLoading'], false)
        .setIn(['doctors', 'isLoading'], false)
        .setIn(['clinics', 'errors'], [action.error.message]);

    case CLINICS_SET_MEMED_LOAD_STATUS:
      return state
        .setIn(['clinics', 'isMemedLoaded'], action.isLoaded);

      // Secretary

    case CLINICS_CREATE_SECRETARY_LOADING:
      return state
        .setIn(['secretaries', 'isSaving'], true);

    case CLINICS_CREATE_SECRETARY_LOADED:
      managerIds = get(state, ['secretaries', 'managerIds'], []);
      nonManagerIds = get(state, ['secretaries', 'nonManagerIds'], []);

      if (action.secretary.manager) {
        managerIds = [...managerIds, action.secretary.id];
      } else {
        nonManagerIds = [...nonManagerIds, action.secretary.id];
      }

      return state
        .setIn(['secretaries', 'isSaving'], false)
        .setIn(['secretaries', 'managerIds'], managerIds)
        .setIn(['secretaries', 'nonManagerIds'], nonManagerIds)
        .setIn(['secretaries', 'data', String(action.secretary.id)], action.secretary);

    case CLINICS_CREATE_SECRETARY_FAILED:
      return state
        .setIn(['secretaries', 'isSaving'], false)
        .setIn(['secretaries', 'errors'], [action.error.message]);

    case CLINICS_UPDATE_SECRETARY_LOADING:
      return state
        .setIn(['secretaries', 'isSaving'], true);

    case CLINICS_UPDATE_SECRETARY_LOADED:
    // manager
      secretaries = get(state, ['secretaries', 'data'], {});

      managerIds = [];
      nonManagerIds = [];

      map(secretaries, (secretaryItem) => {
        const aSecretary = get(action, 'secretary', {});
        const secretary = secretaryItem.id === aSecretary.id ? aSecretary : secretaryItem;
        if (secretary.manager) {
          managerIds.push(secretary.id);
        } else {
          nonManagerIds.push(secretary.id);
        }
      });

      return state
        .setIn(['secretaries', 'isSaving'], false)
        .setIn(['secretaries', 'managerIds'], managerIds)
        .setIn(['secretaries', 'nonManagerIds'], nonManagerIds)
        .setIn(['secretaries', 'data', String(action.secretary.id)], action.secretary);

    case CLINICS_UPDATE_SECRETARY_FAILED:
      return state
        .setIn(['secretaries', 'isSaving'], true)
        .setIn(['secretaries', 'errors'], [action.error.message]);

    // Doctors
    case CLINICS_UPDATE_DOCTOR_LOADING:
      return state
        .setIn(['doctors', 'isSaving'], true);

    case CLINICS_UPDATE_DOCTOR_LOADED:
      return state
        .setIn(['doctors', 'isSaving'], false)
        .setIn(['doctors', 'data', String(action.doctor.id)], action.doctor);

    case CLINICS_UPDATE_DOCTOR_FAILED:
      return state
        .setIn(['doctors', 'isSaving'], false)
        .setIn(['doctors', 'errors'], [action.error.message]);

    case CLINICS_CREATE_DOCTOR_LOADING:
      return state
        .setIn(['doctors', 'isSaving'], true);

    case CLINICS_CREATE_DOCTOR_LOADED:
      const doctorIds = get(state, ['clinics', 'activeClinic', 'doctorIds'], []);
      return state
        .setIn(['doctors', 'isSaving'], false)
        .setIn(['clinics', 'activeClinic', 'doctorIds'], [...doctorIds, action.doctor.id])
        .setIn(['doctors', 'data', String(action.doctor.id)], action.doctor);

    case CLINICS_CREATE_DOCTOR_FAILED:
      return state
        .setIn(['doctors', 'isSaving'], false)
        .setIn(['doctors', 'errors'], [action.error.message]);

      // Update Controlled Prescription layout

    case CLINICS_UPDATE_CONTROLLED_PRESCRIPTION_LAYOUT_LOADING:
      return state
        .setIn(['clinics', 'isSaving'], true);

    case CLINICS_UPDATE_CONTROLLED_PRESCRIPTION_LAYOUT_LOADED:
      normalizedActiveClinic = ClinicService.normalizeActiveClinic(action.clinic);
      return state
        .setIn(['clinics', 'isSaving'], false)
        .setIn(['doctors', 'data'], normalizedActiveClinic.doctors)
        .setIn(['clinics', 'activeClinic'], normalizedActiveClinic.activeClinic);

    case CLINICS_UPDATE_CONTROLLED_PRESCRIPTION_LAYOUT_FAILED:
      return state
        .setIn(['clinics', 'isSaving'], false)
        .setIn(['clinics', 'errors'], [action.error.message]);

      // Update General Print Config

    case CLINICS_UPDATE_GENERAL_PRINT_CONFIG_LOADING:
      return state
        .setIn(['clinics', 'isSaving'], true);

    case CLINICS_UPDATE_GENERAL_PRINT_CONFIG_LOADED:
      normalizedActiveClinic = ClinicService.normalizeActiveClinic(action.clinic);
      return state
        .setIn(['clinics', 'isSaving'], false)
        .setIn(['doctors', 'data'], normalizedActiveClinic.doctors)
        .setIn(['clinics', 'activeClinic'], normalizedActiveClinic.activeClinic);

    case CLINICS_UPDATE_GENERAL_PRINT_CONFIG_FAILED:
      return state
        .setIn(['clinics', 'isSaving'], false)
        .setIn(['clinics', 'errors'], [action.error.message]);
      // prescription layout

    case CLINICS_FETCH_PRESCRIPTION_PRINT_CONFIG_LOADING:
      return state
        .setIn(['clinics', 'prescritionPrintConfig', 'isLoading'], true);

    case CLINICS_FETCH_PRESCRIPTION_PRINT_CONFIG_LOADED:
      return state
        .setIn(['clinics', 'prescritionPrintConfig', 'isLoading'], false)
        .setIn(['clinics', 'prescritionPrintConfig', 'data'], action.prescriptionLayout)
        .setIn(['clinics', 'prescritionPrintConfig', 'lastFetch'], moment().toISOString());

    case CLINICS_FETCH_PRESCRIPTION_PRINT_CONFIG_FAILED:
      return state
        .setIn(['clinics', 'prescritionPrintConfig', 'isLoading'], false)
        .setIn(['clinics', 'prescritionPrintConfig', 'errors'], [action.error.message]);

    case CLINICS_UPDATE_PRESCRIPTION_PRINT_CONFIG_LOADING:
      return state
        .setIn(['clinics', 'prescritionPrintConfig', 'isSaving'], true);

    case CLINICS_UPDATE_PRESCRIPTION_PRINT_CONFIG_LOADED:
      return state
        .setIn(['clinics', 'prescritionPrintConfig', 'isSaving'], false)
        .setIn(['clinics', 'prescritionPrintConfig', 'data'], action.prescriptionLayout);

    case CLINICS_UPDATE_PRESCRIPTION_PRINT_CONFIG_FAILED:
      return state
        .setIn(['clinics', 'prescritionPrintConfig', 'isSaving'], false)
        .setIn(['clinics', 'prescritionPrintConfig', 'errors'], [action.error.message]);

    case CLINICS_DELETE_SECRETARY_LOADING:
      return state
        .setIn(['secretaries', 'isDeleting'], true);


      // Delete secretary

    case CLINICS_DELETE_SECRETARY_LOADED:
      secretaries = get(state, ['secretaries', 'data'], {});
      secretaries = omit(secretaries, String(action.id));

      managerIds = [];
      nonManagerIds = [];

      map(secretaries, (secretaryItem) => {
        const aSecretary = get(action, 'secretary', {});
        const secretary = secretaryItem.id === aSecretary.id ? aSecretary : secretaryItem;
        if (secretary.manager) {
          managerIds.push(secretary.id);
        } else {
          nonManagerIds.push(secretary.id);
        }
      });

      return state
        .setIn(['secretaries', 'managerIds'], managerIds)
        .setIn(['secretaries', 'nonManagerIds'], nonManagerIds)
        .setIn(['secretaries', 'data'], secretaries)
        .setIn(['secretaries', 'isDeleting'], false);

    case CLINICS_DELETE_SECRETARY_FAILED:
      return state
        .setIn(['secretaries', 'isDeleting'], false)
        .setIn(['secretaries', 'errors'], [action.error.message]);


    case CLINICS_UPLOAD_LOGO_LOADING:
      return state
        .setIn(['clinics', 'isUploadingLogo'], true);

    case CLINICS_UPLOAD_LOGO_LOADED:
      return state
        .setIn(['clinics', 'isUploadingLogo'], false)
        .setIn(['clinics', 'activeClinic', 'clinicLogo'], get(action, 'clinicLogo'));

    case CLINICS_UPLOAD_LOGO_FAILED:
      return state
        .setIn(['clinics', 'isUploadingLogo'], false);

    case CLINICS_DELETE_LOGO_LOADING:
      return state
        .setIn(['clinics', 'isDeletingLogo'], true);

    case CLINICS_DELETE_LOGO_LOADED:
      return state
        .setIn(['clinics', 'isDeletingLogo'], false)
        .setIn(['clinics', 'activeClinic', 'clinicLogo'], null);

    case CLINICS_DELETE_LOGO_FAILED:
      return state
        .setIn(['clinics', 'isDeletingLogo'], false);

    default:
      return state;
  }
}
