// @flow
import React from 'react';
import moment from 'moment';
import { isEqual } from 'lodash';
import { withTranslation } from 'react-i18next';
import { createNsTranslator, handleMomenti18n } from '@helpers/helpers';
import { Icon, Button } from '../../elements';
import './CalendarSwitcher.scss';

type Props = {
  isMobile?: boolean,
  selectedDate: Object,
  onChangeMode: Function,
  onNextWeek: Function,
  onPrevWeek: Function,
  onSetSelectedToCurrentDate: Function,
  onPrevDayClick: Function,
  onNextDayClick: Function,
  onMobileFilterClick?: Function,
  onToggleMobileDayPicker?: Function,
  switchCalendarBy: string,
  mobileGreenFilter?: boolean,
  t: Function;
  i18n: Object;
};

class CalendarSwitcher extends React.Component<Props> {
  shouldComponentUpdate(nextProps: Props) {
    return !isEqual(this.props, nextProps);
  }

  render() {
    const {
      isMobile, switchCalendarBy, onMobileFilterClick,
      mobileGreenFilter,
    } = this.props;

    handleMomenti18n(this.props.i18n, moment);

    const selectedDate = this.props.selectedDate || moment();

    const _ = createNsTranslator('agenda', this.props.t);

    // Week days from - to
    const selectedWeekStartDay = selectedDate.clone().isoWeekday(1).format('DD'); // e.g. 0 - 31
    const selectedWeekEndDay = selectedDate.clone().isoWeekday(7).format('DD'); // e.g. 0 - 31
    // Week months Jan - Jan or Jan - Feb ...
    const selectedWeekStartDayMonth = selectedDate.clone().isoWeekday(1).format('MMM'); // Name of month in which week starts
    const selectedWeekEndDayMonth = selectedDate.clone().isoWeekday(7).format('MMM'); // Name of month in which week ends
    // Selected date
    const selectedDay = selectedDate.clone().format('DD'); // e.g. 0 - 31
    const selectedMonth = selectedDate.clone().format('MM'); // e.g. 01 - 12
    const selectedDayName = selectedDate.clone().format('ddd'); // e.g. Seg - Dom


    /** Week type switch
      * if selected day starts in one month and ends in another show (e.g. 26/fev - 04/mar)
      * in other cases show range + month (e.g. 19 a 25 fev)
    */
    const weekSwitch = selectedWeekStartDayMonth === selectedWeekEndDayMonth ? (
      <span className="currentWeek">{ `${selectedWeekStartDay} a ${selectedWeekEndDay} ${selectedWeekEndDayMonth}` }</span>
    ) : (
      <span className="currentWeek">{ `${selectedWeekStartDay}/${selectedWeekStartDayMonth} - ${selectedWeekEndDay}/${selectedWeekEndDayMonth}` }</span>
    );
    // Week switch with format like Seg, 19/02
    const daySwitch = <span className="currentWeek">{ `${selectedDayName}, ${selectedDay}/${selectedMonth}` }</span>;

    const switchContent = switchCalendarBy === 'week' ? weekSwitch : daySwitch;

    // check if current date is equal to selected data - look just on day-month-yera, not hours
    const isSelectedDateToday = moment(selectedDate.format()).isSame(moment().format(), 'day');

    return (
      <React.Fragment>
        {isMobile ? (
          <React.Fragment>
            <Icon
              iconName="calendar-picker"
              className="icon"
              onClick={this.handleToggleMobileDayPicker.bind(this)}
            />
            <Icon iconName="filter" className="icon" onClick={onMobileFilterClick} fill={mobileGreenFilter ? '#09C75E' : '#fff'} />

            <div className="weekSwitcher">
              <Icon
                iconName="arrow-left"
                className="icon"
                onClick={this.onPrevDayClick.bind(this)}
              />

              <span className="currentWeek" onClick={this.handleToggleMobileDayPicker.bind(this)}>
                {switchContent}
              </span>

              <Icon
                iconName="arrow-right"
                className="icon"
                onClick={this.onNextDayClick.bind(this)}
              />
            </div>

          </React.Fragment>
        ) : (
          <div>

            <div className="weekSwitcher">
              <Button
                className="prev"
                onClick={switchCalendarBy === 'week' ? this.onPrevWeek.bind(this) : this.onPrevDayClick.bind(this)}
              >
                <Icon iconName="arrow-left" className="icon" />
              </Button>

              { switchContent }

              <Button
                className="next"
                onClick={switchCalendarBy === 'week' ? this.onNextWeek.bind(this) : this.onNextDayClick.bind(this)}
              >
                <Icon iconName="arrow-right" className="icon" />
              </Button>
            </div>

            <div className="datepickerToolbar">
              <Button
                text={_('label/calendar_today')}
                className={isSelectedDateToday ? 'active' : null}
                onClick={this.setSelectedToCurrentDate.bind(this)}
              />

              <div className="buttonSet">
                <Button
                  text={_('label/calendar_day')}
                  className={switchCalendarBy === 'day' ? 'active' : null}
                  onClick={this.handleSwitchCalendarBy.bind(this, 'day')}
                />
                <Button
                  text={_('label/calendar_week')}
                  className={switchCalendarBy === 'week' ? 'active' : null}
                  onClick={this.handleSwitchCalendarBy.bind(this, 'week')}
                />
              </div>
            </div>

          </div>
        )}
      </React.Fragment>
    );
  }

  onNextWeek = () => {
    this.props.onNextWeek();
  };

  onPrevWeek = () => {
    this.props.onPrevWeek();
  };

  handleSwitchCalendarBy = (switchBy: string) => {
    this.props.onChangeMode(switchBy);
  };

  setSelectedToCurrentDate = () => {
    this.props.onSetSelectedToCurrentDate();
  };

  onPrevDayClick = () => {
    this.props.onPrevDayClick();
  };

  onNextDayClick = () => {
    this.props.onNextDayClick();
  };

  handleToggleMobileDayPicker = () => {
    if (this.props.onToggleMobileDayPicker) {
      this.props.onToggleMobileDayPicker();
    }
  }
}

export default withTranslation()(CalendarSwitcher);
