import { map } from 'lodash';

// expects array of documents template objects: [{id: 1, ...}, {id: 2, ...}, ...]
// => returns documents template object: {1: {...}, 2: {...}, ...}
function normalizeDocumentsTemplates (inputTemplates) {
  const templates = {};

  map(inputTemplates, (template) => {
    templates[template.id] = template;
  });

  return {
    documentsTemplates: templates,
  };
}

export default {
  normalizeDocumentsTemplates,
};
