export default {
  'label/day': 'Dia',
  'label/low_day': 'dia',
  'label/start': 'Inicio',
  'label/end': 'Fim',
  'label/appointment': 'Consulta',
  'label/appointments': 'consultas',
  'label/call_phone': 'Ligar',
  'label/searching': 'Procurando...',
  'label/deleting': 'Excluindo...',
  'label/add_progress': 'Adicionando...',
  'label/searching2': 'Buscando...',
  'label/adress_zipcode': 'CEP',
  'label/adress_streeet': 'Rua/Avenida',
  'label/adress_number': 'Número',
  'label/adress_complement': 'Complemento',
  'label/address_neighborhood': 'Bairro',
  'label/adress_city': 'Cidade',
  'label/adress_state': 'Estado',
  'label/clinic': 'Clínica',
  'label/address': 'Endereço',
  'label/name': 'Nome',
  'label/email': 'E-mail',
  'label/profession': 'Profissão',
  'label/number': 'Número',
  'label/date_and': 'e',
  'label/months': 'Meses',
  'label/days': 'dias',
  'label/tag': 'Etiqueta',
  'label/tag_plural': 'Etiquetas',
  'label/date': 'Data',
  'label/payment_method_short': 'Meio',
  'label/payment_method': 'Forma de Pagamento',
  'label/month': 'Mês',
  'label/year': 'Ano',
  'label/private': 'Particular',
  'label/value': 'Valor',
  'label/patient': 'Paciente',
  'label/service': 'Serviço',
  'label/doctor': 'Doutor',
  'label/doctors': 'Doutores',
  'label/doctor_not_found': 'Doutor não encontrado',
  'label/room': 'Sala',
  'label/notes': 'Observações',
  'label/today': 'Hoje',
  'label/type': 'Tipo',
  'label/total': 'Total',
  'label/cpf': 'CPF',
  'label/last_change': 'Última Alteração',
  'label/gender': 'Sexo',
  'label/gender_male': 'Masculino',
  'label/gender_female': 'Feminino',
  'label/balance': 'Saldo',
  'label/status': 'Status',
  'label/resume': 'Resumo',
  'label/ammount': 'Quantidade',
  'label/agenda': 'Agenda',
  'label/scheduled': 'Agendadas',
  'label/quantity': 'Quantidade',
  'label/average_value': 'Valor Médio',
  'label/table_value': 'Valor de Tabela',
  'label/patient_record': 'Cadastro',
  'label/records': 'Prontuário',
  'label/financial': 'Financeiro',
  'label/indicators': "Indicadores 'Beta'",
  'label/details': 'Detalhes',
  'label/years': 'Anos',
  'label/status_forecasted': 'Previsto',
  'label/status_confirmed': 'Contratado',
  'label/status_canceled': 'Cancelado',
  'label/status_health_insurance': 'Plano de Saúde',
  'label/status_unverified': 'Não verificado',
  'label/status_verified': 'Verificado',
  'label/text': 'Texto',
  'label/status_on_debt': 'Em débito',
  'label/patient_name': 'Nome do Paciente',
  'label/patient_cpf': 'CPF do Paciente',
  'label/council_doc': 'Conselho Profissional',
  'label/saving': 'Salvando...',
  'label/saving_simple': 'Salvando',
  'label/inviting': 'Convidando',
  'label/patient_deleted': 'Paciente excluído',
  'label/patient_deleted_in_day': 'no dia',
  'label/patient_deleted_by': 'Deletado por',
  'label/recover_patient': 'RECUPERAR PACIENTE',
  'label/sending': 'Enviando...',
  'label/loading': 'Carregando...',
  'label/medications': 'Medicamentos',
  'label/options': 'Opções',
  'label/processing': 'Processando...',
  'label/attachment': 'Anexo',
  'label/phone': 'Telefone',
  'label/credit_card': 'Cartão de Crédito',
  'label/boleto': 'Boleto ou PIX',
  'label/wait': 'Aguarde',
  'label/wating': 'Aguarde...',
  'label/mobile_phone': 'Celular',
  'label/profession_spec': 'Profissão/Especialidade',
  'label/manager': 'Administrador',
  'label/contact': 'Contato',
  'label/full_name': 'Nome Completo',
  'label/birth_date': 'Data de Nascimento',
  'label/print_configs': 'Configurações de Impressão',
  'label/blocked_permission_patient_title': 'Você não tem permissão para acessar o cadastro deste paciente.',
  'label/blocked/hint/global': 'Converse com o responsável pela sua clínica.',
  // Actions
  'action/all_doctors': 'Todos os Doutores',
  'action/filter': 'Filtrar',
  'action/reload': 'Recarregar',
  'action/cancel': 'Cancelar',
  'action/back': 'Voltar',
  'action/save': 'Salvar',
  'action/save_print': 'Salvar e Imprimir',
  'action/saved': 'Salvo',
  'action/all_patients': 'Todos os Pacientes',
  'action/download_report': 'Baixar Relatório',
  'action/share': 'Compartilhar',
  'action/print': 'Imprimir',
  'action/view_patient': 'Ver Paciente',
  'action/view_medical_record': 'Ver Prontuário',
  'action/edit': 'Editar',
  'action/invite': 'Convidar',
  'action/delete': 'Excluir',
  'action/see_more': 'Ver mais',
  'action/see_less': 'Ver menos',
  'action/add': 'Adicionar',
  'action/contact': 'Entrar em Contato',
  'action/talk_support': 'Falar com o suporte',
  'action/exit': 'Sair',
  'action/select_one_doctor': 'Selecione um Doutor',
  'action/expand': 'Mostrar mais',
  'action/hide': 'Esconder',
  'action/hide_list': 'esconder',
  'action/show_list': 'revelar',
  'action/change': 'Alterar',
  // Messages
  'msg/contact_support': 'Entre em contato com o suporte',
  'msg/required_field': 'Campor requerido',
  'msg/invalid_cpf': 'CPF inválido',
  // Global Errors
  'error/date': 'Data inválida.',
  'error/email': 'Endereço de e-mail inválido.',
  'error/email_empty': 'Insira um email.',
  'error/invalid_email': 'Endereço de e-mail inválido.',
  'error/empty': 'Não pode estar vazio',
  'error/name': 'Informe um nome',
  'error/tag_name': 'Insira o nome desta etiqueta',
  'error/invalid_hour': 'Hora inválida',
  'error/full_name': 'Informe o nome completo',
  'error/profession_spec': 'Selecione uma profissão/especialidade',
  'error/profession': 'Informe uma profissão',
  'error/password': 'Informe uma senha',
  'error/invalid_password': 'Senha inválida',
  'error/cpf': 'CPF Inválido',
  'error/phone': 'Informe um telefone',
  'error/cep': 'Informe um CEP válido',
  'error/street': 'Informe o nome da rua',
  'error/addr_num': 'Informe o número',
  'error/neighborhood': 'Informe o seu bairro',
  'error/city': 'Informe uma cidade',
  'error/state': 'Selecione um estado',
  'error/credit_card_num': 'Informe o número do cartão',
  'error/credit_card_name': 'Informe o nome do titular',
  'error/credit_card_cvv': 'Informe o CVV',
  'error/credit_card_month': 'Informe o mês',
  'error/credit_card_year': 'Informe o ano',
  'error/invalid_credit_card': 'Cartão inválido',
  'error/email_invalid': 'E-mail inválido.',
  'error/request': 'Ocorreu um erro ao executar esta requisição',
  'error/request_internet': 'Ocorreu um erro. Verifique sua conexão com a internet.',
  // print settings
  'error/validation/print/margin_top': 'Informe um número entre 45 e 150',
  'error/validation/print/margin_bottom': 'Informe um número entre 60 e 150',
  'error/validation/print/margin_left': 'Informe um número entre 0 e 100',
  // Formats and masks
  'format/week_date_01': 'dddd, DD [de] MMM [de] YYYY',
  'format/week_date_01_at': 'ddd, DD [de] MMM [de] YYYY [às]',
  'format/date_01': 'DD [de] MMM [de] YYYY',
  'format/date_01_at': 'DD [de] MMM [de] YYYY [às]',
  'format/date_02': 'DD [de] MMMM',
  'format/date_02_time': 'DD [de] MMM [de] YYYY [às] HH:mm',
  'format/dd_mm_yyyy': 'DD-MM-YYYY',
  'format/date_3': '[dia] DD [de] MMMM [de] YYYY [ás] HH:mm',
  // Others
  'label/blocked_feature': 'Você não tem acesso à esta funcionalidade.',
  'action/blocked_feature_upgrade': 'Fazer Upgrade',
  'message/images_saved': 'Imagem salva',
  'message/download_error': 'Ocorreu um erro ao baixar este arquivo',
};
