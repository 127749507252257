// @flow
import React, { useCallback } from 'react';
import './Alert.scss';

type Props = {
  text: string,
  // type?: string,
  onClick?: Function,
};


export default ({ text, onClick }: Props) => {
  const handleClick = useCallback(() => {
    if (onClick) {
      onClick();
    }
  }, [onClick]);
  return (
    <p className="alertInfo" onClick={handleClick}>
      {text}
    </p>
  );
};

