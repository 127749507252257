// @flow
import React, { useCallback, useMemo, useRef, useEffect } from 'react';
import { get } from 'lodash';
import './Toggle.scss';

type Props = {
  name?: string,
  label?: string,
  onChange?: Function,
  isChecked?: boolean,
};


const Toggle = ({ isChecked, label, name, onChange }:Props) => {
  const inputRef = useRef(null);

  const flatId = useMemo(() => (label ? `${label}-${Date.now()}` : Date.now()), [label]);

  const handleChange = useCallback((e: Object) => {
    const checked = get(e, ['target', 'checked'], false);

    if (onChange) {
      onChange({ name, value: !!checked });
    }
  }, [name, onChange]);

  useEffect(() => {
    if (isChecked && inputRef.current) {
      inputRef.current.checked = isChecked;
    }
  }, [isChecked]);


  return (
    <div className="toggleHolder">
      <div className="toggle">
        <input
          type="checkbox"
          name="toggle"
          className="toggleCheckbox"
          id={flatId}
          checked={isChecked === true}
          onChange={handleChange}
          ref={inputRef}
        />
        <label className="toggleTrack" htmlFor={flatId}>
          <span className="toggleInner" />
          <span className="toggleSwitch" />
        </label>
      </div>
      <label className="toggleLabel" htmlFor={flatId}>{label}</label>
    </div>
  );
};


export default Toggle;

