// @flow

import React, { useCallback, useState, useRef } from 'react';
import { get } from 'lodash';
import { Button, Icon, SwipeTabs, ImageView } from '@elements';
import { useTranslationNs, UserNotifications } from '@helpers/helpers';
import type { Patient } from '@types';


type Props = {
    handleToggleDetailsWidgets?: Function,
    handleToggleMobileWidgets?: Function,
    onDeletePatientProfileImage: Function,
    onSelectPatientProfileImage: Function,
    patient?: Patient,
    active: 'profile' | 'timeline' | 'finacial',
};

type ImageType = {
  dimensions: string,
  id: number,
  name: string,
  size: number,
  thumbUrl: string,
  url: string,
};


const PatientMobileHeader = ({
  handleToggleDetailsWidgets, handleToggleMobileWidgets,
  patient, active, onDeletePatientProfileImage, onSelectPatientProfileImage,
}: Props) => {
  //
  const imagesFetchIntervals = useRef({});
  // use i18next
  const [_] = useTranslationNs('timeline');

  const [loadingProfileImageId, setLoadingProfileImageId] = useState(null);

  const profileImage = get(patient, 'profileImage');
  const imageProgress = profileImage ? get(profileImage, 'progress') : null;
  const imageInProgress = imageProgress && imageProgress.length > 0;
  const imageLoading = !imageInProgress && loadingProfileImageId;
  const imageReady = profileImage && !imageLoading && !imageInProgress && !loadingProfileImageId;

  const onGetImageFail = useCallback((message: string) => {
    // eslint-disable-next-line
    console.log('Image fetch failed because: ' + message);
  }, []);

  // Request image
  const onGetImageDataURLSuccess = useCallback((imageUrl: string) => {
    const type = 'DATA_URL';
    const imgSrc = type === 'DATA_URL' ? 'data:image/jpeg;base64,' + imageUrl : imageUrl;
    // console.log('onGetImageSuccess: ' + (imageUrl && imageUrl.length));
    let file: any;
    fetch(imgSrc)
      .then(res => res.blob())
      .then(blob => {
        const patientId = get(patient, 'id');
        const fileExtension = blob.type.split('/')[1];
        file = new W3C_File([blob], `patient_profile_${Date.now()}.${fileExtension}`, { type: blob.type });

        if (patientId && (blob.type === 'image/png' || blob.type === 'image/jpg' || blob.type === 'image/jpeg' || file.type === 'image/jfif')) {
          const img = new Image();
          img.src = imgSrc;
          img.onload = () => {
            file.width = img.naturalWidth;
            file.height = img.naturalHeight;
            onSelectPatientProfileImage(file);

            if (loadingProfileImageId) {
              clearInterval(imagesFetchIntervals.current.current[loadingProfileImageId]);
              setLoadingProfileImageId(null);
            }
          };
        }
      });
  }, [loadingProfileImageId, onSelectPatientProfileImage, patient]);

  // Take picture using device camera and retrieve image as base64-encoded string
  const onGetImageFromCamera = useCallback(() => {
    navigator.camera.getPicture(onGetImageDataURLSuccess, onGetImageFail, {
      quality: 50,
      destinationType: navigator.camera.DestinationType.DATA_URL,
      encodingType: navigator.camera.EncodingType.JPEG,
      targetHeight: 800,
      targetWidth: 800,
      correctOrientation: true,
    });
  }, [onGetImageDataURLSuccess, onGetImageFail]);

  // Retrieve image file location from specified source
  const onGetImageFromGalery = useCallback(() => {
    navigator.camera.getPicture(onGetImageDataURLSuccess, onGetImageFail, {
      quality: 50,
      destinationType: navigator.camera.DestinationType.DATA_URL,
      sourceType: navigator.camera.PictureSourceType.PHOTOLIBRARY,
      encodingType: navigator.camera.EncodingType.JPEG,
      targetHeight: 800,
      targetWidth: 800,
    });
  }, [onGetImageDataURLSuccess, onGetImageFail]);

  // Handle mobile image upload
  const handleOpenImageUploadChoice = useCallback(() => {
    if (!profileImage && window.plugins && window.plugins.actionsheet) {
      const options = {
        androidTheme: window.plugins.actionsheet.ANDROID_THEMES.THEME_DEVICE_DEFAULT_LIGHT, // default is THEME_TRADITIONAL
        title: _('action/choose_an_action'),
        buttonLabels: [_('action/take_photo'), _('action/album'), _('action/cancel')],
      };
      window.plugins.actionsheet.show(options, (buttonIndex) => {
        switch (buttonIndex) {
          case 1:
            onGetImageFromCamera();
            break;
          case 2:
            onGetImageFromGalery();
            break;
          case 3:
            window.plugins.actionsheet.hide();
            break;
          default:
            window.plugins.actionsheet.hide();
            break;
        }
      });
    }
  }, [onGetImageFromCamera, onGetImageFromGalery, profileImage, _]);

  const tryFetchImage = (img: ImageType) => {
    const imageId = img.id;

    // eslint-disable-next-line
    const imgInstance = new Image();
    imgInstance.src = img.thumbUrl;
    imgInstance.onload = () => {
      setLoadingProfileImageId(null);
      clearInterval(imagesFetchIntervals.current[imageId]);
    };
  };

  const handleImageErrored = (img: ImageType) => () => {
    imagesFetchIntervals.current[img.id] = setInterval(() => {
      tryFetchImage(img);
    }, 3000);

    setTimeout(() => {
      clearInterval(imagesFetchIntervals.current[img.id]);
    }, 30000); // reset fetch interval after 30s
    setLoadingProfileImageId(img.id);
  };


  let patientPhotoClasses = 'patientPhoto';
  if (imageInProgress || imageLoading) {
    patientPhotoClasses += ' imageInProgress';
  }

  const tabItems: Object[] = [
    { title: _('label/patient_record', 'global'), linkTo: `/paciente/${get(patient, 'id')}/cadastro` },
    { title: _('label/records', 'global'), linkTo: `/paciente/${get(patient, 'id')}/prontuario` },
    { title: _('label/financial', 'global'), linkTo: `/paciente/${get(patient, 'id')}/financeiro` },
  ];

  let activeTab = '';

  if (active === 'profile') {
    activeTab = _('label/patient_record', 'global');
  } else if (active === 'timeline') {
    activeTab = _('label/records', 'global');
  } else if (active === 'finacial') {
    activeTab = _('label/financial', 'global');
  }

  const profileImagePreparing = (imageInProgress || imageLoading) && (
    <span className='imageProgressIndicator'>{imageLoading ? _('label/loading', 'global') : `${imageProgress || ''}...`}</span>
  );

  const handleDeletePatientProfileImage = () => {
    UserNotifications.confirmI18n('alert/confirm_delete_file', 'warning', '#FFA433', true)
      .then((isDeleteFileConfirmed) => {
        if (isDeleteFileConfirmed) onDeletePatientProfileImage();
      });
  };

  const profileImageElement = imageReady && (
    <ImageView
      src={profileImage.url}
      thumbnailSrc={profileImage.thumbUrl}
      thumbnailClassName="photo"
      alt="patient profile"
      onClickDelete={handleDeletePatientProfileImage}
      onErrorLoad={handleImageErrored(profileImage)}
    />
  );

  return (
    <React.Fragment>
      <div className="patientHeader">
        <div className="sideBtnHolder">
          {handleToggleDetailsWidgets && (
            <Button className="notesBtn sideBtn">
              <Icon iconName="medical-info" onClick={handleToggleDetailsWidgets} />
            </Button>
          ) }
          {handleToggleMobileWidgets && (
            <Button className="widgetsBtn sideBtn">
              <Icon iconName="user-details" onClick={handleToggleMobileWidgets} />
            </Button>
          )}
        </div>

        <div className={patientPhotoClasses} onClick={handleOpenImageUploadChoice}>
          {profileImage ? (profileImagePreparing || profileImageElement) : null}
        </div>

        <SwipeTabs
          className="dark"
          items={tabItems}
          pageSize={3}
          active={activeTab}
          value={activeTab}
        />

      </div>
    </React.Fragment>
  );
};


// $FlowFixMe
export default React.memo(PatientMobileHeader);
