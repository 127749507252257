/**
 * Api Routes
 */
export const API_ROUTES_CONSTRUCTOR = [
  // Auth Processes
  { name: 'logout', path: `${window.API}/api/v2/auth/sign_out` },
  { name: 'auth', path: `${window.API}/api/v2/auth/sign_in` },
  { name: 'signupStep1', path: `${window.API}/api/v2/auth/step_1` },
  { name: 'signupStep2', path: `${window.API}/api/v2/auth/step_2` },
  { name: 'signupStep3', path: `${window.API}/api/v2/auth/step_3` },
  { name: 'signupStep4', path: `${window.API}/api/v2/auth/step_4` },
  // END Auth Processes

  { name: 'newBoleto', path: `${window.API}/api/v2/subscription/new_boleto` },
  { name: 'ghostLogin', path: `${window.API}/api/v2/adm/ghost` },

  { name: 'setPatientClinicLogo', path: `${window.API}/api/v2/clinic_logos` },
  { name: 'deleteClinicLogo', path: `${window.API}/api/v2/clinic_logos/:clinicId` },

  { name: 'getAvailablePlans', path: `${window.API}/api/v2/subscription/available_plans` },
  { name: 'getClinics', path: `${window.API}/api/v2/clinics` },
  { name: 'getClinicsWithActive', path: `${window.API}/api/v2/clinics?id=:id` },
  { name: 'createClinic', path: `${window.API}/api/v2/clinics` },
  { name: 'getClinic', path: `${window.API}/api/v2/clinics?id=:id` },
  { name: 'updateClinic', path: `${window.API}/api/v2/clinics/:id` },
  { name: 'getPatients', path: `${window.API}/api/v2/patients` },
  { name: 'getPatient', path: `${window.API}/api/v2/patients/:id`, fallback: true },
  { name: 'showPatientPDF', path: `${window.API}/api/v2/patients/:id.pdf` },
  { name: 'patientFinancialReport', path: `${window.API}/api/v2/patients/:id/financial.pdf` },
  { name: 'getPatientFinancial', path: `${window.API}/api/v2/patients/:id/financial` },
  { name: 'getSuggestedPatients', path: `${window.API}/api/v2/patients/for_new_appointment` },
  { name: 'createPatient', path: `${window.API}/api/v2/patients` },
  { name: 'updatePatient', path: `${window.API}/api/v2/patients/:id` },
  { name: 'addMedication', path: `${window.API}/api/v2/patients/:patientId/medications` },
  { name: 'deleteMedication', path: `${window.API}/api/v2/patients/:patientId/medications/:id` },
  { name: 'addProblem', path: `${window.API}/api/v2/patients/:patientId/problems` },
  { name: 'deleteProblem', path: `${window.API}/api/v2/patients/:patientId/problems/:id` },
  { name: 'updatePatientHistoryNotes', path: `${window.API}/api/v2/patients/:id/history_notes` },
  { name: 'deletePatient', path: `${window.API}/api/v2/patients/:id` },
  { name: 'restorePatient', path: `${window.API}/api/v2/patients/:id/restore` },
  { name: 'recoverPatient', path: `${window.API}/api/v2/patients/:id/restore` },
  { name: 'setPatientProfileImage', path: `${window.API}/api/v2/patients/:id/patient_profile_images` },
  { name: 'deletePatientProfileImage', path: `${window.API}/api/v2/patients/:id/patient_profile_images/:image_id` },

  { name: 'addMemedIds', path: `${window.API}/api/v2/records/:recordId/memed_prescriptions` },
  { name: 'removeMemedId', path: `${window.API}/api/v2/records/:recordId/memed_prescriptions/:integrationId` },
  { name: 'getRecords', path: `${window.API}/api/v2/patients/:id/records` },
  { name: 'getFutureRecords', path: `${window.API}/api/v2/patients/:id/records/future` },
  { name: 'getRecord', path: `${window.API}/api/v2/records/:id`, fallback: true },
  { name: 'createRecord', path: `${window.API}/api/v2/patients/:id/records` },
  { name: 'updateRecord', path: `${window.API}/api/v2/records/:id` },
  { name: 'updateRecordVisibility', path: `${window.API}/api/v2/records/:id/update_visibility/:level` },
  { name: 'updateRecordDate', path: `${window.API}/api/v2/records/:id/update_date` },
  { name: 'deleteRecord', path: `${window.API}/api/v2/records/:id` },
  { name: 'restoreRecord', path: `${window.API}/api/v2/records/:id/restore` },

  { name: 'showDrugsPrescriptionPDF', path: `${window.API}/api/v2/records/:recordId/drugs_prescriptions/:id.pdf?show_signature=:showSignature&send_email=:sendEmail`, fallback: true },
  { name: 'createDrugsPrescription', path: `${window.API}/api/v2/records/:recordId/drugs_prescriptions` },
  { name: 'updateDrugsPrescription', path: `${window.API}/api/v2/records/:recordId/drugs_prescriptions/:id` },
  { name: 'deleteDrugsPrescription', path: `${window.API}/api/v2/records/:recordId/drugs_prescriptions/:id` },

  { name: 'getDrugsPrescriptionsTemplates', path: `${window.API}/api/v2/drugs_prescriptions_templates` },
  { name: 'createDrugsPrescriptionsTemplate', path: `${window.API}/api/v2/drugs_prescriptions_templates` },
  { name: 'updateDrugsPrescriptionsTemplate', path: `${window.API}/api/v2/drugs_prescriptions_templates/:id` },
  { name: 'deleteDrugsPrescriptionsTemplate', path: `${window.API}/api/v2/drugs_prescriptions_templates/:id` },

  { name: 'showExamsPrescriptionPDF', path: `${window.API}/api/v2/records/:recordId/exams_prescriptions/:id.pdf?show_signature=:showSignature&send_email=:sendEmail`, fallback: true },
  { name: 'createExamsPrescription', path: `${window.API}/api/v2/records/:recordId/exams_prescriptions` },
  { name: 'updateExamsPrescription', path: `${window.API}/api/v2/records/:recordId/exams_prescriptions/:id` },
  { name: 'deleteExamsPrescription', path: `${window.API}/api/v2/records/:recordId/exams_prescriptions/:id` },

  { name: 'getExamsPrescriptionsTemplates', path: `${window.API}/api/v2/exams_prescriptions_templates` },
  { name: 'createExamsPrescriptionsTemplate', path: `${window.API}/api/v2/exams_prescriptions_templates` },
  { name: 'updateExamsPrescriptionsTemplate', path: `${window.API}/api/v2/exams_prescriptions_templates/:id` },
  { name: 'deleteExamsPrescriptionsTemplate', path: `${window.API}/api/v2/exams_prescriptions_templates/:id` },

  { name: 'showDocumentPDF', path: `${window.API}/api/v2/records/:recordId/documents/:id.pdf?show_signature=:showSignature&send_email=:sendEmail`, fallback: true },
  { name: 'createDocument', path: `${window.API}/api/v2/records/:recordId/documents` },
  { name: 'updateDocument', path: `${window.API}/api/v2/records/:recordId/documents/:id` },
  { name: 'deleteDocument', path: `${window.API}/api/v2/records/:recordId/documents/:id` },

  { name: 'getDocumentsTemplates', path: `${window.API}/api/v2/documents_templates` },
  { name: 'createDocumentsTemplate', path: `${window.API}/api/v2/documents_templates` },
  { name: 'updateDocumentsTemplate', path: `${window.API}/api/v2/documents_templates/:id` },
  { name: 'deleteDocumentsTemplate', path: `${window.API}/api/v2/documents_templates/:id` },

  { name: 'showCustomFormPDF', path: `${window.API}/api/v2/records/:recordId/custom_forms/:id.pdf`, fallback: true },
  { name: 'createCustomForm', path: `${window.API}/api/v2/records/:recordId/custom_forms` },
  { name: 'updateCustomForm', path: `${window.API}/api/v2/records/:recordId/custom_forms/:id` },
  { name: 'deleteCustomForm', path: `${window.API}/api/v2/records/:recordId/custom_forms/:id` },

  { name: 'getCustomFormTemplates', path: `${window.API}/api/v2/custom_form_templates` },

  { name: 'getSignedUploadData', path: `${window.API}/api/v2/attachments/signed_url` },
  { name: 'createRecordAttachment', path: `${window.API}/api/v2/records/:recordId/attachments` },
  { name: 'getRecordAttachment', path: `${window.API}/api/v2/records/:recordId/attachments/:id` },
  { name: 'deleteRecordAttachment', path: `${window.API}/api/v2/records/:recordId/attachments/:id` },
  { name: 'downloadAttachment', path: `${window.API}/api/v2/records/:recordId/attachments/:id/download` },

  { name: 'getAppointments', path: `${window.API}/api/v2/appointments` },
  { name: 'getAppointment', path: `${window.API}/api/v2/appointments/:id` },
  { name: 'createAppointment', path: `${window.API}/api/v2/appointments` },
  { name: 'updateAppointment', path: `${window.API}/api/v2/appointments/:id` },
  { name: 'deleteAppointment', path: `${window.API}/api/v2/appointments/:id` },
  { name: 'updateAppointmentStatus', path: `${window.API}/api/v2/appointments/:id/update_status` },
  { name: 'hideAppointment', path: `${window.API}/api/v2/appointments/:id/hide` },
  { name: 'getAppointmentWhatsAppConfirmationLink', path: `${window.API}/api/v2/appointments/:id/wpp_confirmation_link` },

  { name: 'getDoctorServices', path: `${window.API}/api/v2/doctors/:doctorId/service_templates`, fallback: true },
  { name: 'getServiceTemplates', path: `${window.API}/api/v2/doctors/:doctorId/service_templates` },
  { name: 'createServiceTemplate', path: `${window.API}/api/v2/doctors/:doctorId/service_templates` },
  { name: 'deleteServiceTemplate', path: `${window.API}/api/v2/doctors/:doctorId/service_templates/:id` },
  { name: 'updateServiceTemplate', path: `${window.API}/api/v2/doctors/:doctorId/service_templates/:id` },

  { name: 'getRecommendedServices', path: `${window.API}/api/v2/doctors/:doctorId/service_templates/recommended/:patientId` },

  { name: 'updateSecretary', path: `${window.API}/api/v2/secretaries/:id` },
  { name: 'deleteSecretary', path: `${window.API}/api/v2/secretaries/:id` },
  { name: 'createDoctor', path: `${window.API}/api/v2/doctors` },
  { name: 'createSecretary', path: `${window.API}/api/v2/secretaries` },
  { name: 'updateControlledPrescriptionLayout', path: `${window.API}/api/v2/print_configs/controlled_prescriptions_layout` },
  { name: 'updateGeneralPrintConfig', path: `${window.API}/api/v2/print_configs/reports_layout` },
  { name: 'updateDoctor', path: `${window.API}/api/v2/doctors/:id` },
  { name: 'fetchPrescriptionLayout', path: `${window.API}/api/v2/print_configs/:doctorId/prescription_layout` },
  { name: 'previewPrescriptionLayout', path: `${window.API}/api/v2/print_configs/:doctorId/prescription_layout/preview.pdf` },
  { name: 'updatePrintPrecriptionLayout', path: `${window.API}/api/v2/print_configs/:doctorId/prescription_layout` },

  { name: 'getServices', path: `${window.API}/api/v2/reports/services` },
  { name: 'getServicesReport', path: `${window.API}/api/v2/reports/services.xlsx` },
  { name: 'deleteService', path: `${window.API}/api/v2/patients/:patientId/services/:id` },
  { name: 'updateService', path: `${window.API}/api/v2/patients/:patientId/services/:id` },
  { name: 'createService', path: `${window.API}/api/v2/patients/:patientId/services` },

  { name: 'getPayments', path: `${window.API}/api/v2/reports/payments` },
  { name: 'getPaymentsReport', path: `${window.API}/api/v2/reports/payments.xlsx` },
  { name: 'getReceipts', path: `${window.API}/api/v2/reports/receipts` },
  { name: 'getReceiptsReport', path: `${window.API}/api/v2/reports/receipts.xlsx` },

  { name: 'getReceipt', path: `${window.API}/api/v2/patients/:patientId/receipts/:id` },
  { name: 'showReceiptPDF', path: `${window.API}/api/v2/patients/:patientId/receipts/:id.pdf` },
  { name: 'createReceipt', path: `${window.API}/api/v2/patients/:patientId/receipts` },
  { name: 'getNewReceipt', path: `${window.API}/api/v2/patients/:patientId/receipts/new` },
  { name: 'updateReceipt', path: `${window.API}/api/v2/patients/:patientId/receipts/:id` },
  { name: 'deleteReceipt', path: `${window.API}/api/v2/patients/:patientId/receipts/:id` },

  { name: 'getReportsAppointments', path: `${window.API}/api/v2/reports/appointments` },
  { name: 'getReportsAppointmentsDownload', path: `${window.API}/api/v2/reports/appointments.xlsx` },
  { name: 'getReportsSms', path: `${window.API}/api/v2/reports/sms` },
  { name: 'getReportsDebit', path: `${window.API}/api/v2/reports/indebted` },

  { name: 'getReportAppointmentsSummary', path: `${window.API}/api/v2/reports/appointments_summary` },

  { name: 'getPatientServices', path: `${window.API}/api/v2/patients/:patientId/services` },

  { name: 'getPatientPayments', path: `${window.API}/api/v2/patients/:patientId/payments` },
  { name: 'createPayment', path: `${window.API}/api/v2/patients/:patientId/payments` },
  { name: 'updatePayment', path: `${window.API}/api/v2/patients/:patientId/payments/:id` },
  { name: 'deletePayment', path: `${window.API}/api/v2/patients/:patientId/payments/:id` },

  { name: 'getPatientReceipts', path: `${window.API}/api/v2/patients/:patientId/receipts` },

  { name: 'showSingleRecordPDF', path: `${window.API}/api/v2/records/:recordId`, fallback: true },
  { name: 'showAllRecordsPDF', path: `${window.API}/api/v2/patients/:patientId/records` },

  { name: 'addPatientTag', path: `${window.API}/api/v2/patients/:patientId/tags` },
  { name: 'removePatientTag', path: `${window.API}/api/v2/patients/:patientId/tags/:id` },
  { name: 'createAndConnectPatientTag', path: `${window.API}/api/v2/patients/:patientId/tags/create_and_connect` },

  { name: 'getUser', path: `${window.API}/api/v2/user`, fallback: true },
  { name: 'updateUser', path: `${window.API}/api/v2/user` },
  { name: 'deleteMyAccount', path: `${window.API}/api/v2/user/delete_account` },
  { name: 'memedSignup', path: `${window.API}/api/v2/user/configure_memed` },
  { name: 'updateDoctorPrivacy', path: `${window.API}/api/v2/doctors/:id/privacy_update` },
  { name: 'requestBackup', path: `${window.API}/api/v2/user/request_backup` },
  { name: 'createDoctorWorkHours', path: `${window.API}/api/v2/doctors/:doctorId/work_hours` },
  { name: 'deleteDoctorWorkHours', path: `${window.API}/api/v2/doctors/:doctorId/work_hours/:id` },
  { name: 'recoverPassword', path: `${window.API}/api/v2/auth/password` },
  { name: 'updateUserPassword', path: `${window.API}/api/v2/user/update_password` },
  { name: 'getRecomendedService', path: `${window.API}/api/v2/doctors/:doctorId/service_templates/recommended/:patientId` },
  { name: 'createTemplateWithService', path: `${window.API}/api/v2/doctors/:doctorId/service_templates/with_service/:patientId` },

  // ------------- Sharing -----------------------------

  { name: 'sharingDrugsPrescriptions', path: `${window.API}/api/v2/records/:recordId/drugs_prescriptions/:id/sharing_link` },
  { name: 'sharingExamsPrescriptions', path: `${window.API}/api/v2/records/:recordId/exams_prescriptions/:id/sharing_link` },
  { name: 'sharingDocuments', path: `${window.API}/api/v2/records/:recordId/documents/:id/sharing_link` },

  // ------------- tagTemplates -----------------------
  { name: 'createTagTemplate', path: `${window.API}/api/v2/tag_templates` },
  { name: 'getTagTemplate', path: `${window.API}/api/v2/tag_templates/:id` },
  { name: 'updateTagTemplate', path: `${window.API}/api/v2/tag_templates/:id` },
  { name: 'deleteTagTemplate', path: `${window.API}/api/v2/tag_templates/:id` },
  { name: 'listTagTemplates', path: `${window.API}/api/v2/tag_templates` },

  // ------------- indicators -----------------------
  { name: 'listIndicators', path: `${window.API}/api/v2/indicators` },
  { name: 'createMeasure', path: `${window.API}/api/v2/patients/:patientId/measures` },
  { name: 'updateMeasure', path: `${window.API}/api/v2/patients/:patientId/measures/:measureId` },
  { name: 'deleteMeasure', path: `${window.API}/api/v2/patients/:patientId/measures/:measureId` },
  { name: 'listMeasures', path: `${window.API}/api/v2/patients/:patientId/measures` },

  // -------------- Subscriptions --------------------------
  { name: 'subscriptionUpdateUserInfo', path: `${window.API}/api/v2/subscription/update_user_info` },
  { name: 'subscriptionUpdatePaymentType', path: `${window.API}/api/v2/subscription/update_payment_type` },
  { name: 'getSubscriptionTrack', path: `${window.API}/api/v2/subscription/track/:trackName` },
  { name: 'getSubscriptionUserInfo', path: `${window.API}/api/v2/subscription` },
  { name: 'getActiveSubscriptionUserInfo', path: `${window.API}/api/v2/subscription` },
  { name: 'appstoreSubscription', path: `${window.API}/api/v2/appstore_subscription` },
  { name: 'selectPlan', path: `${window.API}/api/v2/subscription` },

  // ------------------- Dashboard ----------------------
  { name: 'dashboard', path: 'https://beta.doutore.com/dashboard' },
  { name: 'doutoreAI', path: 'https://beta.doutore.com/ai' },
];
