export default {
  'label/title': 'Mi plan',
  'label/will_cancel/text_line_1': 'Tu suscripción se cancelará el día',
  'label/will_cancel/text_line_2': 'Has solicitado la cancelación de tu suscripción.',
  'label/will_cancel/text_line_3': 'Si deseas suscribirte nuevamente, contacta a nuestro equipo.',
  'label/cc_past_due/text_line_1': 'No pudimos renovar tu suscripción el día',
  'label/cc_past_due/text_line_2': 'Actualiza tu tarjeta de crédito o contacta a nuestro equipo.',
  'label/boleto_active/text_line_1': 'Tu suscripción vence el día {0} y aún no se ha identificado el pago.',
  'label/boleto_active/text_line_2': 'El boleto por el valor de R$ {0} vencerá el día {1}.',
  'label/boleto_active/text_line_3': 'Si ya has pagado este boleto, por favor, ignora este mensaje. Tu pago debería ser confirmado en hasta 3 días hábiles.',
  'label/payment_method': 'Forma de Pago',
  'label/credit_card': 'Tarjeta de Crédito',
  'label/boleto': 'Boleto o PIX',
  'label/special': 'Especial',
  'label/inactive': 'Inactivo',
  'label/sms_credits': 'Crédito restante',
  'label/summary_text': 'Plan contratado el {0} con vencimiento todos los días {1}.',
  'label/summary_text_year': 'Plan contratado el {0} con vencimiento anual el día {1}.',
  'action/talk_support': 'Hablar con Soporte',
  'action/updated_credit_card': 'Actualizar Tarjeta',
  'action/cancel_sms_plan': 'Cancelar Plan de SMS',
  'action/request_sms_plan': 'Contratar Plan de SMS',
  'action/change_plan': 'Cambiar Plan',
  'action/change': 'Cambiar',
  'action/pay': 'Pagar',
  'action/cancel_plan': 'Cancelar Suscripción',
  'action/subscribe_again': 'Suscribirse de Nuevo',
  // V2
  'label/ios_msg_title': 'Has suscrito a Doutore a través de iOS',
  'label/ios_msg_body_line_1': 'Tu suscripción se renovará automáticamente cada mes.',
  'label/ios_msg_body_line_2': 'Si quieres cancelar la suscripción de Doutore, necesitas ir a tu iOS en Configuración > iTunes y App Store > Suscripciones.',
  'label/ios_msg_body_line_3': 'Desafortunadamente, no podemos cancelar la suscripción por ti, debes hacerlo siguiendo las instrucciones anteriores.',
  'label/need_help': '¿Necesitas ayuda?',

  'label/boleto_active_expired_charge/title': 'Tu suscripción venció el día {0}',
  'label/boleto_active_expired_charge/text_line_1': 'No hemos recibido el pago del boleto con el valor {0}, que vencía el día {1}.',
  'label/boleto_active_expired_charge/text_line_2': 'Emite un nuevo boleto y págalo inmediatamente. Tu cuenta será bloqueada por falta de pago.',
  'label/boleto_active_expired_charge/text_line_3': 'Si ya has pagado este boleto, por favor, ignora este mensaje. Recibiremos la confirmación de tu pago en 1 día hábil.',

  'label/boleto_past_due_expired_charge/title': 'Tu suscripción venció el día {0}',
  'label/boleto_past_due_expired_charge/text_line_1': 'No hemos recibido el pago del boleto que vencía el día {0}.',
  'label/boleto_past_due_expired_charge/text_line_2': 'Emite un nuevo boleto y págalo inmediatamente. Tu cuenta será bloqueada por falta de pago.',
  'label/boleto_past_due_expired_charge/text_line_3': 'Si ya has pagado este boleto, por favor, ignora este mensaje. Recibiremos la confirmación de tu pago en 1 día hábil.',

  'label/boleto_past_due/title': 'Tu suscripción venció el día {0} y no se ha identificado el pago',
  'label/boleto_past_due/text_line_1': 'Tu boleto por el valor de {0} vencerá el día {1}.',
  'label/boleto_past_due/text_line_2': 'Si ya has pagado este boleto, por favor, ignora este mensaje. Tu pago debería ser confirmado en hasta 3 días hábiles.',

  'label/boleto_active_without_charge/title': 'Texto aquí para Sin cargo XD',
  'label/boleto_active_without_charge/text_line_1': 'lorem impsum.',
  'label/boleto_active_without_charge/text_line_2': 'lorem impsum lorem impsum',

  'label/expired/text_line_1': 'No hemos recibido el pago de tu suscripción.',
  'label/expired/text_line_2': 'Tu suscripción venció el día {0} y no hemos recibido el pago. Realiza el pago para desbloquear tu cuenta.',

  'label/canceled/text_line_1': 'Tu cuenta en Doutore está cancelada.',
  'label/canceled/text_line_2': '¿Te gustaría volver a utilizarla?',

  'action/request_new_boleto': 'Generar Nuevo Boleto',
  'action/view_boleto': 'Ver Boleto',
  'label/plan': 'Plan',

  'label/sms_title': 'SMS',
  'label/sms_credits_2': 'Saldo Restante de SMS',
  'action/details': 'Detalles',

  'label/boleto_message_days': 'Recibirás un boleto por correo electrónico, siempre 5 días antes de la fecha de vencimiento',
  'action/fix_payment_info_problems_label': 'DATOS DE PAGO',
  'action/fix_payment_info_problems': 'Tus datos de pago están incompletos',
  'action/fix_payment_info_problems_edit': 'Editar',
};
