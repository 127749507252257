// @flow
import React from 'react';
import { SettingsMenu, PlatformSwitch, AppMobileHeader, AppMobileFooter } from '@blocks';
import { Breadcrumb, Button, DragAndDropFile, Spinner } from '@elements';
import type { User, BundledPermissions } from '@types';
import { withForm } from '@hocs';
import { withTranslation } from 'react-i18next';
import { createNsTranslator } from '@helpers/helpers';
import './PrintClinicLogo.scss';

type Props = {
  onLogout: Function,
  user: User,
  isLoading: boolean,
  isDeletingLogo: boolean,
  onUploadLogo: Function,
  onUploadLogo: Function,
  onRemoveLogo: Function,
  t: Function,
  permissions: BundledPermissions,
  clinicLogo: ?string,
};

class PrintClinicLogo extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
    this.systemDialogEl = React.createRef();
  }
  render() {
    const {
      user, isLoading, onLogout, onRemoveLogo, clinicLogo, isDeletingLogo,
    } = this.props;

    const _ = createNsTranslator('settings', this.props.t);

    const breadcrumbLinks = [
      { text: _('label/print_config/menu/title'), linkTo: '/ajustes/impressao' },
      { text: _('label/print_config/menu/logo') },
    ];

    const pageContent = (
      <React.Fragment>
        {!isLoading && clinicLogo && (
          <div className="logoImagePreview">
            <img src={clinicLogo} alt={_('label/print_config/menu/title')} />
          </div>
        )}
        {!clinicLogo && (
          <DragAndDropFile onDropFiles={this.handleDropFiles} dropText={_('label/drop_logo')} disabled={isDeletingLogo || isLoading}>
            <div className="emptyLogo" onClick={this.handleSelectfileClick}>
              {isLoading ? <Spinner text={_('label/sending', 'global')} /> : _('label/no_clinic_logo')}
            </div>
          </DragAndDropFile>
        )}
        <input
          type="file"
          accept="image/*"
          ref={this.systemDialogEl}
          id="uploadInput"
          style={{ display: 'none' }}
          onChange={this.handleFileSelected}
        />
        <div className="actions">
          <Button disabled={isDeletingLogo || isLoading} onClick={this.handleSelectfileClick} className="selectLogoBtn">{_('action/select_clinic_logo')}</Button>
          <Button disabled={isDeletingLogo || isLoading} onClick={onRemoveLogo} className="selectLogoBtn">{_('action/remove_logo')}</Button>
        </div>
      </React.Fragment>
    );

    return (
      <PlatformSwitch
        inject
        desktop={() => (
          <div id="printClinicLogo" className="appContent settingsPage">
            <aside className="sidebar">
              <SettingsMenu user={user} onLogout={onLogout} permissions={this.props.permissions} />
            </aside>
            <section className="mainSection">
              <div className="centerContent">
                <Breadcrumb items={breadcrumbLinks} />
                <div className="toolbar dashed">
                  <h1>{_('label/clinic_logo')}</h1>
                </div>
                {pageContent}
              </div>
            </section>
          </div>
        )}
        mobile={() => (
          <React.Fragment>
            <AppMobileHeader title={_('label/clinic_logo')} backLinkTo="/ajustes/impressao" />
            <div id="printClinicLogo" className="appContent settingsPage">
              <section className="mainSection">
                <div className="centerContent">
                  {pageContent}
                </div>
              </section>
            </div>
            <AppMobileFooter />
          </React.Fragment>
        )}
      />
    );
  }

  systemDialogEl: any;

  handleFileSelected = (e) => {
    this.handleFile(e.target.files[0]);
  }

  handleDropFiles = (files) => {
    this.handleFile(files[0]);
  };

  handleFile = (file) => {
    const reader = new window.FileReader();

    reader.onload = (e) => {
      const img = new Image();

      img.onload = () => {
        this.props.onUploadLogo(file, { width: img.naturalWidth, height: img.naturalHeight });
      };

      img.src = e.target.result;
    };

    reader.readAsDataURL(file);
  };

  handleSelectfileClick = () => {
    // Reset dialog
    if (this.systemDialogEl.current) {
      this.systemDialogEl.current.type = '';
      this.systemDialogEl.current.type = 'file';
      this.systemDialogEl.current.value = '';
      this.systemDialogEl.current.click();
    }
  };

  handleCancel = () => {

  };
}

export default withTranslation()(withForm()(PrintClinicLogo));
