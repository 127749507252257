import type { MiddlewareApi } from '@types';
import { cordovaPrintPDF } from '../modules/context/cordova';
import { OPEN_TOAST, CLOSE_ACTIVE_TOAST } from '../modules/context/toasts';
import { Api } from '../../helpers';
import { pdfToBase64, isPresent } from '@helpers/helpers';
import sanitize from 'sanitize-filename';
import moment from 'moment';
import { extension } from 'mime-to-extensions';

const ACTION_TYPE = '__showPDF';

export const socialShareFile = (fileBlob: Blob, title, dispatch) => {
  const reader = new FileReader();
  reader.onloadend = () => {
    if (!window.plugins || !window.plugins.socialsharing) {
      dispatch({
        type: OPEN_TOAST,
        data: {
          type: 'error', text: 'Compartilhamento não suportado', close: true, timeout: 10000,
        },
      });
      setTimeout(() => dispatch({ type: CLOSE_ACTIVE_TOAST }), 3000);
      return;
    }

    const filename = sanitize(`${moment().unix()}-${title}`).toLowerCase().replace(/\.[^/.]+$/, '');

    window.plugins.socialsharing.share(isPresent(title) ? title : `${moment().unix()}-file.${extension(fileBlob.type)}`, filename, reader.result, null, () => {
      setTimeout(() => dispatch({ type: CLOSE_ACTIVE_TOAST }), 2000);
    }, () => {
      dispatch({
        type: OPEN_TOAST,
        data: {
          type: 'error', text: 'Compartilhamento falhou', close: true, timeout: 10000,
        },
      });
    });
  };
  reader.readAsDataURL(fileBlob);
};

const actionHandler = (dispatch, {
  apiRoute, title, prepare, success, error, sendEmail,
}) => {
  // prepare, success, error can be either functions or string representing the action type
  // this helper function makes it easier to call them.
  const callDispatcher = (fn, params) => {
    if (typeof fn === 'string') {
      dispatch({ type: fn, ...params });
    } else if (typeof fn === 'function') {
      fn(dispatch, params);
    }
  };


  callDispatcher(prepare);

  if (!window.isCordovaApp) {
    if (sendEmail) {
      dispatch({ type: OPEN_TOAST, data: { type: 'inProgress', text: 'Enviando PDF...' } });
    } else {
      dispatch({ type: OPEN_TOAST, data: { type: 'inProgress', text: 'Preparando PDF...' } });
    }
  }

  Api.get(apiRoute, {
    success: response => {
      const file = new Blob([response], { type: 'application/pdf' });

      if (window.isCordovaApp && ((!!window.plugins && !!window.plugins.socialsharing) || (!!window.plugins && !!window.plugins.PrintPDF))) {
        if (!!window.plugins && !!window.plugins.socialsharing) {
          dispatch({
            type: OPEN_TOAST,
            data: {
              type: 'success', text: 'Compartilhando PDF', fileURL: undefined, close: true, timeout: 10000,
            },
          });
          socialShareFile(file, title, dispatch);
          callDispatcher(success);
        } else {
          pdfToBase64(response)
            .then((base64: string) => {
              callDispatcher(success);
              dispatch(cordovaPrintPDF({ title, data: base64 }));
            });
          callDispatcher(success);
        }
      } else {
        const fileURL = URL.createObjectURL(file);
        callDispatcher(success);

        if (sendEmail) {
          dispatch({
            type: OPEN_TOAST,
            data: {
              type: 'success', text: 'PDF Enviado', fileURL, close: true, timeout: 10000,
            },
          });
        } else {
          dispatch({
            type: OPEN_TOAST,
            data: {
              type: 'success', text: 'PDF pronto', fileURL, close: true, timeout: 10000,
            },
          });
        }
      }
    },
    error: err => {
      callDispatcher(error, { message: err.message });
      dispatch({
        type: OPEN_TOAST,
        data: {
          type: 'error', text: 'Ocorreu um erro ao gerar PDF...', timeout: 30000, close: true,
        },
      });
    },
  });
};

export default function showPDF ({ dispatch }: MiddlewareApi) {
  return next => action => {
    if (action.type !== ACTION_TYPE) {
      return next(action);
    }
    actionHandler(dispatch, action);
  };
}

showPDF.action = ({
  apiRoute, prepare, success, error, sendEmail, socialShare, title,
}) => ({
  type: ACTION_TYPE, apiRoute, prepare, success, error, sendEmail, socialShare, title,
});

