// @flow
import { Log, UserNotifications } from '@helpers/helpers';
import localforage from 'localforage';
import immutable from 'seamless-immutable';

const stores = {
  APP_STORE: 'APP_STORE',
};

const getLSItem = () => localStorage.getItem(window.APP_LS_KEY);

class IDBEndinge {
  constructor() {
    window.idb = this;
  }

  /**
   *
   */
  fetchInitialState = (): Promise<any> => new Promise(resolve => {
    const result = () => localforage.getItem(stores.APP_STORE).then(resolve).catch(Log.error);
    if (getLSItem()) {
      this.migrate().then(immutable(result));
    } else {
      result();
    }
  });

  storeState = (state: Object): Promise<any> => new Promise(resolve => {
    const mutableState = state && state.asMutable ? state.asMutable() : state;
    localforage.setItem(stores.APP_STORE, mutableState).then(resolve).catch((error) => {
      window.store_errors = (window.store_errors || 0) + 1;

      if (window.store_errors > 10 && window.clearAppStorage) {
        UserNotifications
          .confirmI18n('')
          .then(confirmed => {
            if (confirmed) {
              window.clearAppStorage();
            } else {
              window.store_errors = 0;
            }
          });
      }
      Log.error(error);
    });
  });

  reset = (): Promise<any> => new Promise(resolve => {
    localforage.removeItem(stores.APP_STORE).then(resolve).catch(Log.error);
  });

  migrate = () => new Promise(resolve => {
    Log.info('Migrating from old storage');
    const oldData = getLSItem();
    if (oldData) {
      try {
        const data = JSON.parse(oldData);
        if (data) {
          localforage.setItem(stores.APP_STORE, data).then(() => {
            localStorage.removeItem(window.APP_LS_KEY);
            resolve();
          }).catch(Log.error);
        }
      } catch (e) {
        resolve();
      }
    }
  });
}


export default (): Promise<any> => new Promise(resolve => {
  localforage.config({
    driver: [
      localforage.INDEXEDDB,
      localforage.WEBSQL,
      localforage.LOCALSTORAGE],
    name: window.APP_LS_KEY,
    version: '1',
  });
  window.localforage = localforage;
  localforage.ready().then(() => {
    resolve(new IDBEndinge());
    let storageEngine = '';

    switch (localforage.driver()) {
      case 'asyncStorage': storageEngine = 'IndexedDB'; break;
      case 'localStorageWrapper': storageEngine = 'LocalStorage'; break;
      case 'webSQLStorage': storageEngine = 'WebSQL'; break;
      default: storageEngine = 'unknown'; break;
    }

    Log.info(`Using ${storageEngine} as storage engine`);
  }).catch(() => {
    Log.error('No available storage method found');
  });
});
