// @flow
import React from 'react';
import type { Record } from '@types';
import { get, isEqual } from 'lodash';
import moment from 'moment';
import { withTranslation } from 'react-i18next';
import { createNsTranslator, isCordovaApp } from '@helpers/helpers';
import { Button } from '@elements';
import classNames from 'classnames';
import PlatformSwitch from '../../PlatformSwitch/PlatformSwitch';

type Props = {
  record: Record,
  isDeleted: boolean,
  t: Function,
  onRestoreRecord: Function,
};

class RecordCanceled extends React.Component<Props> {
  shouldComponentUpdate(nextProps: Props) {
    return !isEqual(this.props, nextProps);
  }

  render() {
    const {
      record,
    } = this.props;

    const _ = createNsTranslator('timeline', this.props.t);

    const appointmentNotes = get(record, ['appointmentData', 'appointmentNotes'], '');
    const dateFormat = record.appointmentData ? `${_('format/date_01_at', 'global')} ${record.appointmentData.time}` : _('format/date_02');

    const render = (isMobile: boolean) => () => (
      <div className={classNames('recordCanceled', { canceledRecordMobileContent: isMobile || isCordovaApp() })}>
        <div className="top">
          <div className="dateWrapper">
            <span>{`${moment(record.date).format(dateFormat)}`}</span>
            <span className="userName">{get(record, 'userName')}</span>
          </div>
          {this.getReason()}
          {(record.isRestorable && !isMobile) && (
            <div className="actions">
              <Button onClick={this.handleRestore}>{_('action/restore_deleted_record')}</Button>
            </div>
          )}
        </div>

        {appointmentNotes && <span className="appointmentsNotes">{appointmentNotes}</span>}
      </div>
    );

    return <PlatformSwitch
      desktop={render(false)}
      mobile={render(true)}
    />;
  }

  getReason = () => {
    const appointmentData = get(this.props.record, ['appointmentData']);
    const _ = createNsTranslator('timeline', this.props.t);
    const dateFormat = appointmentData ? _('format/date_02_time', 'global') : _('format/date_02', 'global');

    const formatDate = (date: string) => (moment(date).isValid() ? moment(date).format(dateFormat) : 'Data Inválida');
    if (this.props.isDeleted) {
      return <span className="canceledMessage">{_('label/message/canceled')} {formatDate(get(this.props.record, ['deletedAt']))}</span>;
    }
    if (appointmentData.canceledByDoctorAt) {
      return <span className="canceledMessage">{_('label/message/canceled_in_day')} {formatDate(appointmentData.canceledByDoctorAt)}</span>;
    } else if (appointmentData.canceledByPatientAt) {
      return <span className="canceledMessage">{_('label/message/patient_canceled_in_day')} {formatDate(appointmentData.canceledByPatientAt)}</span>;
    } else if (appointmentData.canceledBySmsAt) {
      return <span className="canceledMessage">{_('label/message/sms_canceled_in_day')} {formatDate(appointmentData.canceledBySmsAt)}</span>;
    } else if (appointmentData.patientMissedAt) {
      return <span className="canceledMessage">{_('label/message/patient_missed')}</span>;
    }
  };

  handleRestore = () => {
    this.props.onRestoreRecord(this.props.record.id);
  };
}

export default withTranslation()(RecordCanceled);
