// @flow
import React, { useState, useRef, useCallback } from 'react';
import { Modal, Button, Icon, Loading } from '@elements';
import { PlatformSwitch } from '@blocks';
import { usePdf } from './PDFjs';
import './PDFPreview.scss';

type Props = {
    docUrl: ?string,
    pdfName: string,
    onClose: Function,
};

const PDFPreview = ({ docUrl, onClose, pdfName }: Props) => {
  const [page, setPage] = useState(1);
  const canvasRef = useRef(null);

  const { pdfDocument } = usePdf({
    file: docUrl,
    page,
    canvasRef,
  });

  const pagination = useCallback((dir: 'next' | 'prev') => () => {
    if (!(pdfDocument && pdfDocument.numPages)) return;

    if (dir === 'next') {
      setPage(p => (p + 1 > pdfDocument.numPages ? p : p + 1));
    } else {
      setPage(p => (p - 1 < 1 ? 1 : p - 1));
    }
  }, [pdfDocument]);


  return (
    <PlatformSwitch
      inject
      desktop={() => (
        <Modal onClose={onClose} className="pdfPreview">
          <div className="modalHeader">
            <div className="titleContainer">
              {Boolean(pdfDocument && pdfDocument.numPages) && (
              <React.Fragment>
                <Button onClick={pagination('prev')} className="linkButton" disabled={page === 1}>
                  <Icon iconName="arrow-left" />
                </Button>
                <span className="modalTitle">{pdfName}&nbsp;({page}/{pdfDocument.numPages})&nbsp;</span>
                <Button className="linkButton" onClick={pagination('next')} disabled={page === pdfDocument.numPages}>
                  <Icon iconName="arrow-right" />
                </Button>
              </React.Fragment>
              )}
            </div>
          </div>
          <div className="modalGrid">
            <div className="modalContainer">
              <div className="modalContent manageTagsContainer">
                {!pdfDocument && <Loading />}
                <canvas ref={canvasRef} />
              </div>
            </div>
          </div>
        </Modal>
      )}
      mobile={() => (
        <Modal onClose={onClose} className="pdfPreview asView">
          <div className="modalHeader">
            <Button onClick={onClose}>
              <Icon iconName="close" className="icon" />
            </Button>

            {Boolean(pdfDocument && pdfDocument.numPages) && (
            <Button onClick={pagination('prev')} className="linkButton" disabled={page === 1}>
              <Icon iconName="arrow-left" />
            </Button>
            )}
            {Boolean(pdfDocument && pdfDocument.numPages) && (
              <span className="title">{page}/{pdfDocument.numPages}</span>
            )}
            {Boolean(pdfDocument && pdfDocument.numPages) && (
            <Button className="linkButton" onClick={pagination('next')} disabled={page === pdfDocument.numPages}>
              <Icon iconName="arrow-right" />
            </Button>
            )}
          </div>
          <div className="modalGrid">
            <div className="modalContainer">
              <div className="modalContent manageTagsContainer">
                {!pdfDocument && <Loading />}
                <canvas ref={canvasRef} />
              </div>
            </div>
          </div>
        </Modal>
      )}
    />
  );
};

export default PDFPreview;
