// @flow
import React from 'react';
import { get } from 'lodash';
import { connect } from 'react-redux';
import { ClinicConfigRooms as ClinicConfigRoomsView } from '@views';
import type { User, Room, BundledPermissions } from '@types';
import { logout } from '@context/user';
import { updateClinic } from '@context/clinics';
import { mapBundledPermission } from '@helpers/connect';

type Props = {
  user: User,
  rooms: Array<Room>,
  dispatch: Function,
  clinicId: number,
  isLoading: boolean,
  permissions: BundledPermissions,
};

class ClinicConfigRooms extends React.Component<Props> {
  render() {
    const { user, rooms, isLoading } = this.props;
    return (
      <ClinicConfigRoomsView
        user={user}
        rooms={rooms}
        onSave={this.handleSave}
        isLoading={isLoading}
        onLogout={this.handleLogout}
        permissions={this.props.permissions}
      />
    );
  }

  handleLogout = () => this.props.dispatch(logout());

  handleSave = (data: Object) => {
    const { dispatch, clinicId } = this.props;
    dispatch(updateClinic(clinicId, {
      ...data,
      numberOfRooms: Object.keys(data).length,
    }));
  };
}

const mapStateToProps = (state) => ({
  user: state.context.user,
  rooms: get(state, ['context', 'clinics', 'activeClinic', 'rooms'], []),
  clinicId: get(state, ['context', 'clinics', 'activeClinic', 'id'], null),
  isLoading: get(state, ['context', 'clinics', 'isSaving'], null),
  permissions: mapBundledPermission(state),
});

export default connect<any, any, any, any, any, any>(
  mapStateToProps,
)(ClinicConfigRooms);
