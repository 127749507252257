// @flow
import React from 'react';
import { connect } from 'react-redux';
import { router } from '@router';
import { get } from 'lodash';
import { openModal } from '@context/modals';
import { deletePatient, getPatient, showPatientPDF, resetLoadingState, patientActions } from '@context/patients';
import { PatientProfile as PatientProfileView } from '@views';
import { mapPermissionTo } from '@helpers/connect';
import { isPresent, UserNotifications } from '@helpers/helpers';
import type { Dispatch, Patient, Doctors, Clinic, PermissionTo } from '@types';


type Props = {
  dispatch: Function,
  patient: Patient,
  clinic: Clinic,
  patientId: number,
  id: number,
  doctors: Doctors,
  isPatientLoading: boolean,
  isPatientSaving: boolean,
  isPatientPrinting: boolean,
  getFullPatientError: boolean,
  permissionTo : PermissionTo,
};

class PatientProfile extends React.Component<Props> {
  componentWillMount() {
    const {
      patient, dispatch, isPatientLoading,
      patientId, getFullPatientError,
    } = this.props;

    dispatch(resetLoadingState());

    if (isPresent(get(patient, 'deletedAt'))) {
      this.props.dispatch(router.pushPath('patientDeleted', { id: patientId }));
    }

    if (!getFullPatientError && !isPatientLoading) {
      dispatch(getPatient(patientId));
    }
  }

  componentWillReceiveProps(nextProps: Props) {
    if (isPresent(get(nextProps.patient, 'deletedAt'))) {
      this.props.dispatch(router.pushPath('patientDeleted', { id: nextProps.patientId }));
    }
    const isFull = get(nextProps, ['patient', 'full'], false);
    const { dispatch, isPatientLoading } = this.props;
    if (nextProps.getFullPatientError && !isFull && !isPatientLoading && nextProps.patient) {
      dispatch(getPatient(nextProps.patientId));
    }
  }

  render() {
    const {
      patient, doctors, clinic,
      isPatientSaving, isPatientPrinting, permissionTo, isPatientLoading,
    } = this.props;

    return (
      <PatientProfileView
        patient={patient}
        doctors={doctors}
        clinic={clinic}
        // onNewPatientClick={this.handleNewPatientClick}
        onDeleteButtonClick={this.handleDeleteButtonClick}
        onEditPatientClick={this.handleEditPatientClick}
        isPatientSaving={isPatientSaving}
        isPatientPrinting={isPatientPrinting}
        onPrintClick={this.handlePrintClick}
        patientFormSelectDoctor={permissionTo.patientFormSelectDoctor}
        onRefresh={this.handleRefreshButtonClick}
        isLoading={isPatientLoading}
        onDeletePatientProfileImage={this.handleDeletePatientProfileImage}
        onSelectPatientProfileImage={this.handleSelectPatientProfileImage}
      />
    );
  }

  handleSelectPatientProfileImage = (file: Object, fileType: string) => {
    const patientId = get(this.props, ['patient', 'id']);
    this.props.dispatch(patientActions.uploadPatientProfileImage(patientId, file, fileType));
  };

  handleDeletePatientProfileImage = () => {
    const patientId = get(this.props, ['patient', 'id']);
    const imageId = get(this.props, ['patient', 'profileImage', 'id']);
    this.props.dispatch(patientActions.deletePatientProfileImage(patientId, imageId));
  };

  // handleNewPatientClick = () => {
  //   this.props.dispatch(openModal('PatientFormModal'));
  // };

  handleEditPatientClick = () => {
    const { id, dispatch, patient } = this.props;
    if (patient.full) {
      dispatch(openModal('PatientFormModal', { id }));
    } else {
      dispatch(getPatient(id, true));
    }
  };
  handleDeleteButtonClick = () => {
    const { dispatch, id } = this.props;

    UserNotifications.confirmI18n('alert/delete_patient_question', 'warning', '#FFA433', true)
      .then((value) => {
        if (value) {
          dispatch(deletePatient(id, () => {
            dispatch(router.pushPath('patientsList'));
          }));
        }
      });
  };

  handleRefreshButtonClick = () => {
    const { dispatch, patientId } = this.props;
    dispatch(getPatient(patientId));
  };

  handlePrintClick = () => {
    const { dispatch, id } = this.props;
    dispatch(showPatientPDF(id));
  }
}

const mapStateToProps = (state, ownProps: Object) => ({
  patient: get(state.context, ['patients', 'data', ownProps.match.params.id]),
  getFullPatientError: get(state.context, ['patients', 'getFullPatientError'], false),
  patientId: get(ownProps, ['match', 'params', 'id']),
  isPatientLoading: get(state, ['context', 'patients', 'isLoading'], false),
  isPatientSaving: get(state, ['context', 'patients', 'isSaving'], false),
  isPatientPrinting: get(state, ['context', 'patients', 'isPrinting'], false),
  doctors: state.context.doctors.data,
  clinic: get(state, ['context', 'clinics', 'activeClinic'], {}),
  id: get(ownProps, ['match', 'params', 'id']),
  permissionTo: mapPermissionTo(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({ dispatch });

export default connect<any, any, any, any, any, any>(
  mapStateToProps,
  mapDispatchToProps,
)(PatientProfile);
