// @flow
import React from 'react';
import { concat, includes, without } from 'lodash';
import { SettingsMenu, FormFooter, PlatformSwitch, AppMobileHeader, AppMobileFooter } from '@blocks';
import type { DrugsPrescriptionsTemplate, User, FormElementData, BundledPermissions } from '@types';
import {
  Breadcrumb,
  Input, Checkbox, TextArea,
} from '@elements';
import { withTranslation } from 'react-i18next';
import { createNsTranslator, isPresent } from '@helpers/helpers';


type Props = {
  drugsPrescriptionsTemplate: DrugsPrescriptionsTemplate,
  user: User,
  isSaving: boolean,
  onCreateDrugsPrescriptionsTemplate: Function,
  onUpdateDrugsPrescriptionsTemplate: Function,
  onDeleteDrugsPrescriptionsTemplate: Function,
  redirectToPrescriptionsList: Function,
  onLogout: Function,
  t: Function,
  permissions: BundledPermissions,
};

type State = {
  name: string,
  notes: string,
  controlledDrugs: boolean,
  errors: Array<string>,
};

class DrugsPrescriptionsTemplateForm extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    const { drugsPrescriptionsTemplate } = this.props;
    this.state = {
      name: drugsPrescriptionsTemplate.name || '',
      notes: drugsPrescriptionsTemplate.notes || '',
      controlledDrugs: drugsPrescriptionsTemplate.controlledDrugs || false,
      errors: [],
    };
  }

  render() {
    const {
      name, notes, controlledDrugs,
      errors,
    } = this.state;

    const _ = createNsTranslator('settings', this.props.t);

    const { drugsPrescriptionsTemplate, user, onLogout, isSaving } = this.props;

    const breadcrumbLinks = [
      { text: _('label/prescription_menu/title'), linkTo: '/ajustes/modelos' },
      { text: _('label/drugs_prescription_teplates/list/title'), linkTo: '/ajustes/modelos/receituarios' },
    ];

    const isNew = !isPresent(drugsPrescriptionsTemplate.id);

    if (!isNew) { // if we are editing an existing drugs prescriptions template
      breadcrumbLinks.push({ text: name });
    } else { // if we are creating a new drugs prescriptions template
      breadcrumbLinks.push({ text: _('label/drugs_prescription_teplates/list/new_prescription') });
    }

    const pageContent = (
      <React.Fragment>
        <Input
          type="text"
          name="name"
          autofocus
          className="field"
          label={_('label/drugs_prescription_teplates/form/model_name')}
          value={name}
          onChange={this.handleFormChange}
          onBlur={this.handleFormBlur}
          error={includes(errors, 'name') ? _('label/drugs_prescription_teplates/form/error_empty') : ''}
          required
          disabled={isSaving}
        />
        <TextArea
          name="notes"
          className="field prescriptionRtEditor"
          label={_('label/text', 'global')}
          value={notes}
          onChange={this.handleFormChange}
          onBlur={this.handleFormBlur}
          error={includes(errors, 'notes') ? _('label/drugs_prescription_teplates/form/error_empty') : ''}
          placeholder={_('label/drugs_prescription_teplates/form/placeholder_content')}
          required
          disabled={isSaving}
        />
        <br />
        <Checkbox
          name="controlledDrugs"
          label={_('label/drugs_prescription_teplates/form/controlled_checkbox')}
          isChecked={controlledDrugs}
          onChange={this.handleFormChange}
          disabled={isSaving}
        />
        {drugsPrescriptionsTemplate.id ? (
          <FormFooter
            onSave={this.onSaveDrugsPrescriptionsTemplate}
            onCancel={this.onCancel}
            isLoading={isSaving}
            includeDelete
            onDelete={this.onDeleteDrugsPrescriptionsTemplate.bind(this, drugsPrescriptionsTemplate.id)}
          />
        ) : (
          <FormFooter onSave={this.onSaveDrugsPrescriptionsTemplate} onCancel={this.onCancel} isLoading={isSaving}/>
        )}
        <button type="submit" onClick={this.onSaveDrugsPrescriptionsTemplate}/>
      </React.Fragment>
    );

    return (
      <div className="appContent settingsPage">
        <PlatformSwitch
          inject
          desktop={() => (
            <React.Fragment>
              <aside className="sidebar">
                <SettingsMenu user={user} onLogout={onLogout} permissions={this.props.permissions} />
              </aside>
              <section className="mainSection" id="drugsPrescriptionsTemplateFormDesktopForm">
                <div className="centerContent">
                  <Breadcrumb items={breadcrumbLinks} />
                  <div className="toolbar dashed toolbarSteticFix">
                    <h1>{isNew ? _('label/drugs_prescription_teplates/list/new_prescription') : name}</h1>
                  </div>
                  {pageContent}
                </div>
              </section>
            </React.Fragment>
          )}
          mobile={() => (
            <React.Fragment>
              <AppMobileHeader title={isNew ? _('label/drugs_prescription_teplates/list/new_prescription') : name} backLinkTo="/ajustes/modelos/receituarios" />
              <section className="mainSection">
                <div className="centerContent">{pageContent}</div>
              </section>
              <AppMobileFooter />
            </React.Fragment>
          )}
        />
      </div>
    );
  }

  checkForErrors = (value: (string | number | boolean), fieldName: string) => {
    const { errors } = this.state;

    if (value === '') {
      this.setState({ errors: concat(errors, [fieldName]) });
    } else if (includes(errors, fieldName)) {
      this.setState({ errors: without(errors, fieldName) });
    }
  };

  handleFormChange = (data: FormElementData) => {
    const { name, value } = data;
    this.setState({ [name]: value }, () => this.checkForErrors(value, name));
  };

  handleFormBlur = (data: FormElementData) => {
    const { name, value } = data;
    this.checkForErrors(value, name);
  };

  // onChangeControlledDrugs = (value: boolean) => {
  //   this.setState({ controlledDrugs: value });
  // };

  onSaveDrugsPrescriptionsTemplate = () => {
    const {
      drugsPrescriptionsTemplate,
      onUpdateDrugsPrescriptionsTemplate,
      onCreateDrugsPrescriptionsTemplate,
    } = this.props;
    const {
      name,
      notes,
      controlledDrugs,
    } = this.state;

    const data = {
      name,
      notes,
      controlledDrugs,
    };
    const errors = [];

    if (name === '') {
      if (!includes(this.state.errors, 'name')) {
        this.setState({ errors: concat(errors, ['name']) });
      }
      errors.push('name');
    }

    if (notes === '') {
      if (!includes(this.state.errors, 'notes')) {
        this.setState({ errors: concat(errors, ['notes']) });
      }
      errors.push('notes');
    }

    if (!errors.length) {
      if (drugsPrescriptionsTemplate.id) { // if we are editing an existing drugs prescriptions template
        onUpdateDrugsPrescriptionsTemplate(drugsPrescriptionsTemplate.id, data);
      } else { // if we are creating a new drugs prescriptions template
        onCreateDrugsPrescriptionsTemplate(data);
      }
    }
  };

  onDeleteDrugsPrescriptionsTemplate = (templateId: number) => {
    this.props.onDeleteDrugsPrescriptionsTemplate(templateId);
  };

  onCancel = () => {
    this.props.redirectToPrescriptionsList(true);
  };
}

export default withTranslation()(DrugsPrescriptionsTemplateForm);
