// @flow
import React from 'react';
import { get } from 'lodash';
import JQuery from 'jquery';
import './ScrollUP.scss';

type Props = {
  scrollElement: any,
  className?: string,
};

type State = {
  visible: boolean
}

export default class ScrollUP extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      visible: false,
    };
  }

  componentWillMount() {
    this.intervalPointer = window.setInterval(() => {
      const canScroll = get(this.props.scrollElement, 'scrollTop', 0) > 1500;

      if (canScroll && this.state.visible === false) {
        this.setState({ visible: true });
      } else if (!canScroll && this.state.visible === true) {
        this.setState({ visible: false });
      }
    }, 500);
  }

  componentWillUnmount() {
    window.clearInterval(this.intervalPointer);
  }
  render() {
    return (
      <div className={`elementScrollUp ${this.props.className || ''} ${this.state.visible ? '' : 'hidden'}`} onClick={this.handleOnClick}>
        <i className="arrow" />
        <div className="ball">TOPO</div>
      </div>
    );
  }

  handleOnClick = () => {
    JQuery(this.props.scrollElement).animate({ scrollTop: 0 }, 'slow');
  };

  intervalPointer: any = null;
}
