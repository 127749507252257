// @flow
import React from 'react';
import { AppMobileHeader } from '../../../../blocks';

export default class PatientServices extends React.Component<any> {
  render() {
    return (
      <div className="appContent">
        <AppMobileHeader title="PatientServices" className="showBack" />
        PatientServices
      </div>
    );
  }
}
