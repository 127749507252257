// @flow
import React from 'react';
import { map } from 'lodash';
import { isValidBrPhone } from '@helpers/validator/rules/testers';
import type{ Errors, FormElementData, AuthInProgress } from '@types';
import { Button, Checkbox, Input, InputMask, RadioGroup, StepProgressBar } from '@elements';
import { ExternalPlatformSwitch } from '@blocks';
import { withTranslation } from 'react-i18next';
import { createNsTranslator, fSufixTwoPoints } from '@helpers/helpers';
import classnames from 'classnames';
import SignUpLogo from './SignUpLogo';

type Props = {
  authErrors: Errors,
  inProgress: AuthInProgress,
  userId: number,
  onSignupStep2: Function,
  t: Function,
};

type State = {
  name: ?string,
  gender: string,
  subscribeEmailList: boolean,
  mobilePhone: ?string,
  isSubmitted: boolean,
  errorMessages: Errors,
};

class SignUp extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      name: '',
      gender: 'male',
      mobilePhone: '',
      isSubmitted: false,
      errorMessages: [],
      subscribeEmailList: true,
    };
  }

  render() {
    const {
      name, gender, mobilePhone, subscribeEmailList,
      isSubmitted, errorMessages,
    } = this.state;
    const { authErrors, inProgress } = this.props;

    let errors;
    if (isSubmitted) {
      errors = errorMessages.length ? errorMessages : authErrors;
    }

    const _ = createNsTranslator('onboarding', this.props.t);

    const renderContent = (isMobile: boolean) => (
      <div id='signUpBasicPage' className='horzPublicLayout'>

        <form className="main" onSubmit={this.handleOnSubmit} method="POST">
          {!isMobile && (
            <div className="signupHeader">
              <SignUpLogo linkTo="/" />
              <hr className="fade" />
            </div>
          )}

          <div className="center col">
            <StepProgressBar steps={4} currentyStep={2} />
            <h1>{_('label/step2_call')}</h1>
          </div>
          <div className="basicSignupFormContainer step2Form">
            <div className={classnames('basicSignupForm', { desktopCard: !isMobile })}>
              <Input
                label={_('label/complete_name')}
                name='name'
                value={name}
                onChange={this.handleInputChange.bind(this)}
              />

              {map(errors, error => (
                <span className='signupError'>{_(error)}</span>
              ))}

              <RadioGroup
                name='gender'
                label={_('label/gender', 'global', fSufixTwoPoints)}
                defaultType='gender'
                selectGroupName='gender'
                value={gender}
                onChange={this.handleInputChange.bind(this)}
              />

              <InputMask
                type='phone'
                name='mobilePhone'
                label={_('label/mobile_phone')}
                value={mobilePhone}
                onChange={this.handleInputChange.bind(this)}
              />

              <Checkbox
                label={_('label/step2_call_email')}
                name="subscribeEmailList"
                isChecked={subscribeEmailList}
                onChange={this.handleInputChange.bind(this)}
              />

              {isMobile && <br/>}

              {!isMobile && (
                <hr className='dashed' />
              )}

              <div className="center">
                <Button
                  className='primary'
                  text={_('action/next')}
                  onClick={this.onSignupStep2}
                  disabled={inProgress}
                  isLoading={inProgress}
                  loadingMessage={_('label/sending', 'global')}
                />
              </div>
            </div>

          </div>
        </form>
      </div>
    );

    return (
      <ExternalPlatformSwitch
        desktop={() => renderContent(false)}
        mobile={() => renderContent(true)}
      />
    );
  }

  handleOnSubmit = (e: Object) => {
    e.preventDefault();
    e.stopPropagation();
    this.onSignupStep2();
  };

  handleInputChange = (data: FormElementData) => {
    const { name, value } = data;
    // this.setState({ [name]: value, isSubmitted: false });
    this.setState({ [name]: value });
  };

  onSignupStep2 = () => {
    const { name, gender, mobilePhone, subscribeEmailList } = this.state;
    const { userId } = this.props;

    const errorMessages = [];

    if (!name) {
      errorMessages.push('error/name');
    }

    if (!isValidBrPhone.run(mobilePhone, { allowEmpty: true })) {
      errorMessages.push('error/phone');
    }

    if (!errorMessages.length) {
      this.props.onSignupStep2(userId, name, gender, mobilePhone, subscribeEmailList);
      this.setState({ errorMessages: [], isSubmitted: true });
    } else {
      this.setState({ errorMessages, isSubmitted: true });
    }
  };
}

export default withTranslation()(SignUp);
