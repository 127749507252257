// @flow
import React from 'react';
import moment from 'moment';
// import 'moment/locale/pt';
import { map, isEqual } from 'lodash';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { router } from '@router';
import type { FutureAppointment, Errors } from '@types';
import { ClickableList, Icon } from '@elements';
import { handleMomenti18n, createNsTranslator } from '@helpers/helpers';
import './FutureRecords.scss';

type State = {
  isExpanded: boolean,
};

type Props = {
  futureRecords: Array<FutureAppointment>,
  history: Object,
  errors: ?Errors,
  t: Function,
  i18n: Object,
};

class FutureRecords extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isExpanded: false,
    };
  }

  shouldComponentUpdate(nextProps: Props, nextState: State) {
    return !isEqual(this.props, nextProps) || !isEqual(this.state, nextState);
  }

  render() {
    const { isExpanded } = this.state;
    const { futureRecords, errors } = this.props;

    const _ = createNsTranslator('timeline', this.props.t);

    handleMomenti18n(this.props.i18n, moment);

    const futureRecordsData = [{
      className: 'listItem expander',
      customRendererData: {
        type: 'expanderMainItem',
        value: `${futureRecords.length} ${_('label/future_appointments')}`,
      },
    }];

    if (isExpanded) {
      map(futureRecords, (futureRecord) => {
        const record = {
          customRendererData: {
            preventClick: true,
            date: futureRecord.date,
            time: futureRecord.time,
            doctorUserName: futureRecord.userName,
            value: futureRecord.id,
            type: 'futureRecord',
            doctorId: futureRecord.doctorId,
          },
        };
        futureRecordsData.push(record);
      });
    }

    const renderer = (item) => {
      let listItem;
      if (item.type === 'expanderMainItem') {
        listItem = (
          <div>
            {item.value}
            <Icon
              iconName={isExpanded ? 'arrow-up' : 'arrow-down'}
              className='icon'
            />
          </div>
        );
      } else {
        listItem = (
          <div>
            <span
              className='agendaLink'
              onClick={this.handleDateClick.bind(this, item)}
            >
              {moment(item.date).format(_('format/week_date_01_at', 'global'))}&nbsp;
              {item.time}
            </span>
            <br />
            {item.doctorUserName}
          </div>
        );
      }
      return listItem;
    };

    return (
      <div className="futureRecords">
        {errors ? (
          <p className='error'>{_('label/errors/future_appointments')}</p>
        ) : (
          <ClickableList
            items={futureRecordsData}
            customRenderer={renderer}
            onListItemClick={this.handleListToggle.bind(this)}
          />
        )}
      </div>
    );
  }

  handleDateClick = (item: Object) => {
    const { date, doctorId } = item;
    const _ = createNsTranslator('timeline', this.props.t);
    this.props.history.push(`${router.getRoutePath('agenda')}?date=${moment(date).format(_('format/dd_mm_yyyy', 'global'))}&doctorId=${doctorId || null}`);
  };

  handleListToggle = (item) => {
    if (item.type === 'expanderMainItem') {
      this.setState({ isExpanded: !this.state.isExpanded });
    }
  };
}

export default withRouter(withTranslation()(FutureRecords));
