// @flow
import React from 'react';
import { map, findIndex, get, toNumber, isEqual } from 'lodash';
import { Icon, Button, Modal, ClickableList } from '@elements';
import type { Room, Clinic } from '@types';

type Props = {
  clinic: Clinic,
  selectedRoom: number,
  onChange: Function,
  setModal: Function,
  isLockedForLoading: boolean,
  _: Function,
};

const mapRoomsToClickableList = (isLockedForLoading: boolean) => ({ name, number }) => ({
  text: name,
  number,
  className: `listItem ${isLockedForLoading ? 'wait' : ''}`,
});

const findSelectedRoomPredicate = (selectedRoom: number) => (room: Room) => toNumber(room.number) === toNumber(selectedRoom);

export default class RoomSelectModal extends React.Component<Props> {
  shouldComponentUpdate(nextProps: Props) {
    return !isEqual(this.props, nextProps);
  }
  render() {
    const {
      selectedRoom, clinic, _,
    } = this.props;

    const clinicRooms = get(clinic, 'rooms');

    return (
      <Modal id="roomModal" className="secondaryModal">
        <div className="modalHeader actionHeader">
          <Button className="backButton" onClick={this.handleBack}>
            <Icon iconName="arrow-left" className="icon" />
          </Button>
          <span className="backButtonLabel">{_('label/rooms/select_one_room')}</span>
        </div>
        <ClickableList
          activeItemIndex={findIndex(clinicRooms, findSelectedRoomPredicate(selectedRoom))}
          items={map(clinicRooms, mapRoomsToClickableList(this.props.isLockedForLoading))}
          onListItemClick={this.onListItemClick}
        />
      </Modal>
    );
  }

  onListItemClick = (room: Room) => {
    if (this.props.isLockedForLoading) return;
    this.props.onChange({ name: 'room', value: room.number });
    this.handleBack();
  };

  handleBack = () => this.props.setModal(null);
}
