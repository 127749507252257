// @flow
import React, { useMemo } from 'react';
import classNames from 'classnames';
import { get, has, findKey, isEmpty, find } from 'lodash';
import { isPresent } from '@helpers/helpers';
import moment from 'moment';

type Props = {
  event: Object,
  isFinatialEnabled: boolean,
  loadingItems: Object,
  isMobile: boolean,
  clinicDoctors: any,
  agendaFilter: Object,
  clinic: Object,
  classList: string,
  _: Function,
  user: Object,
  snapDuration: any,
};

const icons = {
  arrived: 'event_seat',
  confirmed: 'event_available',
  completed: 'done',
  missed: 'event_busy',
  canceled: 'highlight_off',
};
/**
 * The default-duration- * class accepts only a few values
 * to avoid an unaccepted value and bad rendering of the element we keep
 * them in the values supported by css style.
 */
const clampDuration = (duration) => {
  if (duration <= 15) return 15;
  if (duration > 15 && duration <= 20) return 20;
  if (duration > 20 && duration <= 30) return 30;
  if (duration > 30 && duration <= 40) return 40;
  if (duration > 40 && duration <= 45) return 45;
  if (duration > 45 && duration <= 50) return 50;
  if (duration > 50 && duration <= 60) return 60;
  if (duration > 60 && duration <= 75) return 75;
  if (duration > 75) return 90;

  return 90;
};

/**
 * This component is used to render the first node and its contents,
 * compared to the first node of this component we only use its classes
 * and its children the rest is dismissed, so avoid adding
 * properties to the parent node or event listeners to it.
 */
const CalendarEvent = ({
  event, isFinatialEnabled, loadingItems, clinicDoctors, agendaFilter, isMobile, clinic, classList, _, user,
  snapDuration,
}: Props) => {
  //
  const isNew = useMemo(() => !isPresent(event.doctorId), [event]);
  const highPrivacyBlocked = useMemo(() => !isNew && get(event, 'restricted', false), [isNew, event]);
  const isLoading = useMemo(() => get(loadingItems, String(get(event, 'id', '')), false), [loadingItems, event]);
  const badge = useMemo(() => get(event, 'badge', false), [event]);
  const restricted = useMemo(() => event.restricted || highPrivacyBlocked, [event, highPrivacyBlocked]);
  const shortEvent = useMemo(() => parseInt(event.duration, 10) <= 15, [event]);
  const eventColor = useMemo(() => get(clinicDoctors, [event.doctorId, 'color']), [clinicDoctors, event]);

  const indebit = useMemo(
    () => (isFinatialEnabled ? get(event, ['patient', 'indebt'], false) && !highPrivacyBlocked : false),
    [isFinatialEnabled, event, highPrivacyBlocked],
  );

  const getEventDuration = () => {
    const end = moment(get(event, ['_instance', 'range', 'end']));
    const start = moment(get(event, ['_instance', 'range', 'start']));

    return moment.duration(end.diff(start)).asMinutes();
  };

  const fcContentClasses = useMemo(() => ({
    indebit,
    shortEvent,
    isLoading,
  }), [
    indebit,
    shortEvent,
    isLoading,
  ]);
  /**
   * Render mobile ghost event
   */
  if (event.isGhost && isMobile) {
    const startTime = moment(event.start).format('H:mm');
    const duration = getEventDuration();

    return (
      <div className={classNames(classList, {
        'fc-select-helper': true,
        'fc-event-10-min': duration === 10,
      })}
      >
        <div className={classNames('fc-content', fcContentClasses)} id={`event-id-${get(event, 'id', '')}`} >
          <div className="fc-title">
            {isMobile && (
              <div className="fc-title-mobile">{_('action/click_to_create_appointment')}</div>
            )}
            {!isMobile && (
              <div className="fc-title-mobile">{_('action/click_to_create_appointment_at')}&nbsp;{startTime}</div>
            )}
          </div>
        </div>
      </div>
    );
  }
  /**
   * Render new event
   */
  if (isNew) {
    const dtEvent = {
      ...event,
      ...(event._instance ? {
        start: get(event, ['_instance', 'range', 'start']),
        end: get(event, ['_instance', 'range', 'end']),
      } : {}),
    };

    const defaultDuration = get(clinicDoctors[(
      (get(agendaFilter, ['doctor', 'length']) ? get(agendaFilter, ['doctor', String(0)]) : null) ||
      (!get(user, ['roleType']) === 'Doctor' && !get(user, ['advancedData', 'doctorId'])) ||
      get(agendaFilter, ['doctor', String(1)]) ||
      findKey(clinicDoctors)
    )], 'defaultAppointmentDuration');

    const isDayClick = moment.utc(dtEvent.end).diff(dtEvent.start, 'minutes') === moment(`${moment().format('YYYY-MM-DD')} ${snapDuration}`).minute();

    const initialDuration = moment.utc(dtEvent.end).diff(moment.utc(dtEvent.start), 'minutes');
    const duration = isDayClick ? defaultDuration : initialDuration;

    let defaultDurationClass = null;
    if (initialDuration < defaultDuration) {
      dtEvent.end = moment(dtEvent.start).add(defaultDuration, 'minutes');
      defaultDurationClass = `default-duration default-duration-${clampDuration(duration)}`;
    }
    dtEvent.new = true;


    const startTime = moment.utc(dtEvent.start).format('H:mm');
    const endTime = moment.utc(dtEvent.end).format('H:mm');
    const appointmentDuration = getEventDuration();


    return (
      <div className={classNames(classList, defaultDurationClass, {
        'fc-select-helper': true,
        'fc-event-10-min': appointmentDuration === 10,
      })}
      >
        <div className={classNames('fc-content', fcContentClasses)} id={`event-id-${get(dtEvent, 'id', '')}`} >
          <div className="fc-title">
            {isMobile && (
              <div className="fc-title-mobile">Clique para criar consulta</div>
            )}
            {!isMobile && (
              <div className="fc-title-web">
                {startTime}  - {endTime}
                <span className="fc-title-duration"> ({duration}&nbsp;min)</span>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }

  /**
   * Render default event
   */

  let selectedRoomName = '';

  if (!restricted && (get(clinic, ['numberOfRooms']) !== 1) && get(agendaFilter, ['showAllRooms'], false)) {
    const clinicRooms = get(clinic, ['rooms']);
    const selectedRoom = find(clinicRooms, (room) => (room.number === event.room));
    selectedRoomName = get(selectedRoom, 'name', '');
  }
  const withoutPatient = (event.withoutPatient || isEmpty(event.patient));
  const duration = getEventDuration();

  return (
    <div
      className={classNames(
        classList,
        {
          'fc-event-restricted': restricted,
          'fc-event-canceled': !restricted && badge && (badge === 'canceled'),
          'fc-event-without-patient': !event.isBackgroundEvent && !restricted && withoutPatient,
          'fc-event-10-min': duration === 10,
        },
        !restricted && eventColor ? `fc-event-color-${eventColor}` : null,
      )}
    >
      {badge && has(icons, badge) && (
        <svg className={`svgicon fc-event-icon ${badge}`}>
          <use xlinkHref={`#${get(icons, badge)}`} />
        </svg>
      )}
      <div className={classNames('fc-content', fcContentClasses)} id={`event-id-${get(event, 'id', '')}`} >
        { !restricted && indebit && (<span className="notifyDot" />)}
        { !restricted && isLoading && (
          <span className="isLoadingSpinner">
            <svg
              className="svgicon"
              fill="rgb(0, 0, 0)"
              style={{ width: 16, height: 16 }}
            >
              <use xlinkHref="#spinner" />
            </svg>
          </span>
        )}
        <div className="fc-title">{event.title}</div>
        { !restricted && get(event, 'notes') && (
          <div className="fc-event-obs">{event.notes}</div>
        )}
        {!restricted && !(event.withoutPatient || isEmpty(event.patient)) && (
          <div className="fc-event-obs">{selectedRoomName}</div>
        )}
      </div>
      {!isLoading && <div className="fc-resizer fc-end-resizer" />}
    </div>
  );
};

// $FlowFixMe
export default React.memo(CalendarEvent);
