// @flow
import React from 'react';
import { Link } from 'react-router-dom';
import { Icon } from '@elements';

export default class ServerError extends React.Component<any> {
  render() {
    return (
      <div className="pageServerError">
        <Icon iconName="info" />
        <h1>Não foi possível conectar com o Doutore</h1>

        <Link to='/'>Tentar novamente</Link>
      </div>
    );
  }
}
