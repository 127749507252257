// @flow
import React from 'react';
import { SettingsMenu, PlatformSwitch, AppMobileHeader, FormFooter, AppMobileFooter } from '@blocks';
import { RadioGroup, Spinner } from '@elements';
import type { User, BundledPermissions } from '@types';
import { withForm } from '@hocs';
import { get } from 'lodash';
import { withTranslation } from 'react-i18next';
import { createNsTranslator, handleSubmitEvent } from '@helpers/helpers';

type Props = {
  user: User,
  onSave: Function,
  handleFormChange: Function,
  onLogout: Function,
  formData: Object,
  resetData: Function,
  isLoading: boolean,
  userLoading: boolean,
  permissions: BundledPermissions,
  formEdited: Function,
  setInitialData: Function,
  t: Function,
};

class RecordsPrivacy extends React.Component<Props> {
  componentDidMount() {
    handleSubmitEvent('#recordsPrivacySubmitHandler', () => this.handleSave());
  }

  render() {
    const {
      user, handleFormChange, resetData, formData, isLoading, userLoading,
      onLogout,
    } = this.props;

    const _ = createNsTranslator('settings', this.props.t);

    const edited = this.props.formEdited();

    const items = [
      { text: _('label/records_privacy/opt/only_me'), value: 1 },
      { text: _('label/records_privacy/opt/doctors'), value: 2 },
      { text: _('label/records_privacy/opt/all'), value: 3 },
    ];

    const content = (
      <React.Fragment>
        <div className="toolbar dashed">
          <h1>{_('label/records_privacy/title')}</h1>
        </div>
        {userLoading && <Spinner text={_('label/records_privacy/loading')} />}
        <RadioGroup
          multiline
          disabled={userLoading}
          name="doctorDefaultVisibilityLevel"
          selectGroupName="doctorDefaultVisibilityLevel"
          label={_('label/records_privacy/opt/input_desctiption')}
          items={items}
          onChange={handleFormChange}
          value={get(formData, 'doctorDefaultVisibilityLevel', '')}
        />

        <FormFooter onSave={this.handleSave} onCancel={resetData} isLoading={isLoading || userLoading} edited={edited} editFeature cancelLinkTo="/ajustes" />
      </React.Fragment>
    );

    return (
      <PlatformSwitch
        inject
        desktop={() => (
          <div className="appContent settingsPage">
            <aside className="sidebar">
              <SettingsMenu user={user} onLogout={onLogout} permissions={this.props.permissions} />
            </aside>
            <section className="mainSection">
              <div className="centerContent">
                <form id="recordsPrivacySubmitHandler">
                  {content}
                </form>
              </div>
            </section>
          </div>
        )}
        mobile={() => (
          <React.Fragment>
            <AppMobileHeader title="Privacidade" />
            <div className="appContent settingsPage">
              <section className="mainSection">
                <div className="centerContent">
                  {content}
                </div>
              </section>
            </div>
            <AppMobileFooter />
          </React.Fragment>
        )}
      />
    );
  }

  handleSave = () => {
    const { onSave, formData, setInitialData, isLoading } = this.props;
    if (!isLoading) {
      setInitialData(formData);
      onSave(get(formData, 'doctorDefaultVisibilityLevel', 0));
    }
  };
}

export default withTranslation()(withForm({})(RecordsPrivacy));
