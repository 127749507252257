// @flow
import React from 'react';
import { get, filter, toNumber } from 'lodash';
import { connect } from 'react-redux';
import { router } from '@router';
import { isSecretary, shortenName, isPresent, checkIfIsMobile } from '@helpers/helpers';
import { actions } from '@context/modals';
import { getPatient, setPatientFinancialLoading, getPatientFinancial, patientActions, printPatientFinancialReport } from '@context/patients';
import { serviceActions } from '@context/services';
import { paymentActions } from '@context/payments';
import { receiptActions } from '@context/receipts';
import type { Dispatch, DoctorsState, Patient, ServicesState, PaymentsState, ReceiptsState, PaymentInput, ServiceInput, PlanInclude, UiState } from '@types';
import { PatientFinancial as PatientFinancialView } from '@views';
import { mapPlanIncludes } from '@helpers/connect';
import { withRouter } from 'react-router-dom';
import { push as pushPath } from 'connected-react-router';

type Props = {
  dispatch: Function,
  patient: Patient,
  patientId: number,
  services: ServicesState,
  payments: PaymentsState,
  receipts: ReceiptsState,
  isPatientLoading: boolean,
  paymentLoadingIds: Object,
  serviceLoadingIds: Object,
  isServicesLoading: boolean,
  isPaymentsLoading: boolean,
  isReceiptsLoading: boolean,
  showDoctorSelector: boolean,
  doctors: DoctorsState,
  isBalanceLoading: boolean,
  userId: number,
  planInclude: PlanInclude,
  path: string,
  ui: UiState,
  getFullPatientError: boolean,
  singleDoctor: boolean,
  isPatientPrinting: boolean,
};

const clearPath = (path: string) => String(path).replace('/paciente/:id/financeiro', '').replace('/', '').trim();

class PatientFinancial extends React.Component<Props> {
  componentWillMount() {
    if (!this.props.planInclude.financial) {
      return;
    }

    if (isPresent(get(this.props.patient, 'deletedAt'))) {
      this.props.dispatch(router.pushPath('patientDeleted', { id: this.props.patientId }));
    }

    const {
      patient, patientId, dispatch, getFullPatientError,
    } = this.props;

    if (!getFullPatientError && (!patient || (patient && !patient.full && !this.props.isPatientLoading))) {
      dispatch(getPatient(patientId));
    }
    if (patient && !checkIfIsMobile(this.props.ui)) {
      if (!this.props.isServicesLoading) {
        this.handleGetServices(patientId);
      }
      if (!this.props.isPaymentsLoading) {
        this.handleGetPayments(patientId);
      }
      if (!this.props.isReceiptsLoading) {
        this.handleGetReceipts(patientId);
      }
      dispatch(setPatientFinancialLoading(patientId, false));
    }
    this.handleBalanceRequest();
  }

  componentWillReceiveProps(nextProps) {
    if (!nextProps.planInclude.financial) {
      return;
    }

    if (isPresent(get(nextProps.patient, 'deletedAt'))) {
      this.props.dispatch(router.pushPath('patientDeleted', { id: nextProps.patientId }));
    }

    const { patient, patientId, dispatch } = this.props;

    // if (!nextProps.getFullPatientError && (nextProps.patient && !nextProps.patient.full && !this.props.isPatientLoading)) {
    //   dispatch(getPatient(patientId));
    // }

    if (!patient && nextProps.patient && !checkIfIsMobile(nextProps.ui)) {
      this.handleGetServices(patientId);
      this.handleGetPayments(patientId);
      this.handleGetReceipts(patientId);
      dispatch(setPatientFinancialLoading(patientId, false));
    }
    if (this.props.patientId !== nextProps.patientId) {
      this.handleBalanceRequest();
    }
  }

  render() {
    const {
      patient, paymentLoadingIds, serviceLoadingIds, doctors, showDoctorSelector,
      services, payments, receipts, isPaymentsLoading, isServicesLoading, isBalanceLoading,
      planInclude, path, singleDoctor, isPatientPrinting,
    } = this.props;

    const servicesData = filter(get(services, 'data', {}), s => toNumber(s.patientId) === get(services, 'currentPatientId'));
    const paymentsData = filter(get(payments, 'data', {}), p => toNumber(p.patientId) === get(payments, 'currentPatientId'));
    const receiptsData = filter(get(receipts, 'data', {}), r => toNumber(r.patientId) === get(receipts, 'currentPatientId'));

    return (
      <PatientFinancialView
        isLoading={!patient}
        patient={patient}
        services={{ ...services, data: servicesData }}
        payments={{ ...payments, data: paymentsData }}
        receipts={{ ...receipts, data: receiptsData }}
        onNewServicesClick={this.handleNewServicesClick}
        onNewPaymentClick={this.handleNewPaymentClick}
        onShowReceiptClick={this.handleShowReceiptClick}
        onNewReceiptClick={this.handleNewReceiptClick}
        onOpenEditService={this.handleOpenEditService}
        onOpenEditPayment={this.handleOpenEditPayment}
        onUpdatePayment={this.handleOnUpdatePayment}
        onUpdateService={this.handleOnUpdateService}
        paymentLoadingIds={paymentLoadingIds}
        serviceLoadingIds={serviceLoadingIds}
        isPaymentsLoading={isPaymentsLoading}
        isServicesLoading={isServicesLoading}
        isFeatureEnabled={planInclude.financial}
        doctors={doctors}
        isBalanceLoading={isBalanceLoading}
        showDoctorSelector={showDoctorSelector}
        onRequestBalance={this.handleBalanceRequest}
        path={clearPath(path)}
        onRefresh={this.handleRefresh}
        onNavigate={this.handleNavigate}
        onDeletePatientProfileImage={this.handleDeletePatientProfileImage}
        onSelectPatientProfileImage={this.handleSelectPatientProfileImage}
        singleDoctor={singleDoctor}
        onPrintReport={this.handlePrintReport}
        isPatientPrinting={isPatientPrinting}
      />
    );
  }

  handlePrintReport = () => {
    const patientId = get(this.props, ['patient', 'id']);
    this.props.dispatch(printPatientFinancialReport(patientId));
  };

  handleSelectPatientProfileImage = (file, fileType) => {
    const patientId = get(this.props, ['patient', 'id']);
    this.props.dispatch(patientActions.uploadPatientProfileImage(patientId, file, fileType));
  };

  handleDeletePatientProfileImage = () => {
    const patientId = get(this.props, ['patient', 'id']);
    const imageId = get(this.props, ['patient', 'profileImage', 'id']);
    this.props.dispatch(patientActions.deletePatientProfileImage(patientId, imageId));
  };

  handleNavigate = (path: string) => {
    this.props.dispatch(pushPath(path));
  };

  handleBalanceRequest = () => {
    const { dispatch, patientId } = this.props;
    dispatch(getPatientFinancial(patientId));
  };

  handleNewServicesClick = (doctorId: number) => {
    this.props.dispatch(actions.openModal('ServicesNewListModal', {
      doctorId,
      doctorName: this.getDoctorName(doctorId),
    }));
  };

  handleOpenEditService = (id: number) => {
    this.props.dispatch(actions.openModal('ServiceFormModal', { id }));
  };

  handleNewPaymentClick = (doctorId: number) => {
    const { patient } = this.props;
    this.props.dispatch(actions.openModal('PaymentNewFormModal', {
      patientId: patient.id,
      doctorId,
      doctorName: this.getDoctorName(doctorId),
    }));
  };

  handleOpenEditPayment = (data) => {
    const { paymentId, patientId } = data;
    this.props.dispatch(actions.openModal('PaymentEditFormModal', { paymentId, patientId }));
  };

  handleShowReceiptClick = (receiptId: number) => {
    const { patient, dispatch } = this.props;
    dispatch(actions.openModal('ReceiptModal', { patientId: patient.id, receiptId }));
  };

  handleNewReceiptClick = (doctorId: number, price: number, date: string) => {
    const { patient, dispatch, userId } = this.props;
    dispatch(actions.openModal('ReceiptNewFormModal', {
      doctorId: !doctorId ? userId : doctorId,
      patientId: patient.id,
      price,
      date,
    }));
  };

  handleGetServices = (patientId) => {
    this.props.dispatch(serviceActions.getPatientServices(patientId));
  };

  handleGetPayments = (patientId) => {
    this.props.dispatch(paymentActions.getPatientPayments(patientId));
  };

  handleGetReceipts = (patientId) => {
    if (this.props.isReceiptsLoading) return;
    this.props.dispatch(receiptActions.getPatientReceipts(patientId));
  };


  handleRefresh= (action: string) => {
    switch (action) {
      case 'services': this.handleGetServices(this.props.patientId); break;
      case 'payments': this.handleGetPayments(this.props.patientId); break;
      case 'receipts': this.handleGetReceipts(this.props.patientId); break;
      default:
        if (!checkIfIsMobile(this.props.ui)) {
          this.handleGetServices(this.props.patientId);
          this.handleGetPayments(this.props.patientId);
          this.handleGetReceipts(this.props.patientId);
        }
        break;
    }
  };

  handleOnUpdatePayment = (id: number, data: PaymentInput) => {
    const { dispatch } = this.props;
    const patientID = get(this.props.patient, 'id');
    if (patientID) {
      dispatch(paymentActions.updatePayment(patientID, id, data));
    }
  };

  handleOnUpdateService = (id: number, data: ServiceInput) => {
    const { dispatch } = this.props;
    const patientID = get(this.props.patient, 'id');
    if (patientID) {
      dispatch(serviceActions.updateService(patientID, id, data));
    }
  };

  getDoctorName = (doctorId: number) => shortenName(get(this.props, ['doctors', 'data', String(doctorId), 'nameWithTitle'], ''));
}


const mapStateToProps = (state, ownProps: Object) => ({
  patient: get(state.context, ['patients', 'data', ownProps.match.params.id]),
  getFullPatientError: get(state.context, ['patients', 'getFullPatientError'], false),
  patientId: get(ownProps, ['match', 'params', 'id']),
  isPatientLoading: get(state, ['context', 'patients', 'isLoading'], false),
  isPatientPrinting: get(state, ['context', 'patients', 'isPrinting'], false),
  isReceiptsLoading: get(state, ['context', 'receipts', 'isLoading'], false),
  isBalanceLoading: get(state, ['context', 'patients', 'isFinancialLoading'], false),
  isServicesLoading: get(state, ['context', 'services', 'isSaving'], false) || get(state, ['context', 'services', 'isLoading'], false),
  isPaymentsLoading: get(state, ['context', 'payments', 'isSaving'], false) || get(state, ['context', 'services', 'isLoading'], false),
  services: state.context.services,
  payments: state.context.payments,
  receipts: state.context.receipts,
  doctors: get(state, ['context', 'doctors'], {}),
  paymentLoadingIds: get(state, ['context', 'payments', 'updatingIds'], {}),
  serviceLoadingIds: get(state, ['context', 'services', 'updatingIds'], {}),
  showDoctorSelector: isSecretary(state),
  userId: get(state, ['context', 'user', 'advancedData', 'doctorId']),
  planInclude: mapPlanIncludes(state),
  path: get(ownProps, ['match', 'path'], ''),
  singleDoctor: get(state, ['context', 'clinics', 'activeClinic', 'singleDoctor'], false),
  ui: state.ui,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({ dispatch });

export default connect<any, any, any, any, any, any>(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(PatientFinancial));
