import React from 'react';
import { get, isEqual } from 'lodash';
import InputMaskModule from 'react-text-mask';
import { wrapValue } from '@helpers/helpers';
import { withTranslation } from 'react-i18next';
import maskMap from './masks';
import { Input } from '../../elements';
import { FormInput } from '../../blocks';
import classNames from 'classnames';


type Props = {
  onChange: Function,
  value?: string | number,
  defaultValue?: string | number,
  placeholder?: string,
  type: string,
  label?: string,
  className?: string,
  hint?: string,
  error?: string,
  name?: string,
  id?: string,
  disabled?: boolean,
  required?: boolean,
  uncontrolled?: boolean,
  i18n: Object,
  autofocus?: boolean,
  mobileAutofocus?: boolean,
  elementRef?: any,
};

const clean = (str: string, chars: Array<string> = ['\\', '.', '-', '/']) =>
  String(str).replace(new RegExp(`[${chars.map(i => `\\${i}`).join()}]`, 'g'), '');


class InputMask extends React.Component<Props> {
  constructor(props: Props) {
    super(props);

    this.maskModule = React.createRef();
  }

  shouldComponentUpdate(nextProps) {
    return !isEqual(this.props, nextProps);
  }

  render() {
    const {
      placeholder,
      value, label, hint,
      error, type, defaultValue,
      i18n, name, id, disabled, required, className,
      uncontrolled, elementRef,
    } = this.props;

    const autofocus = this.props.autofocus && !window.isCordovaApp ? true : this.props.mobileAutofocus;

    if (type === 'currency') {
      return (
        <div onClick={e => e.stopPropagation()}>
          <Input
            type="currency"
            placeholder={placeholder}
            name={name}
            label={label}
            {...wrapValue(defaultValue, value, uncontrolled)}
            error={error || ''}
            onChange={this.handleOnChangeCurrency}
            disabled={disabled === true}
            required={required}
            {...(elementRef ? { ref: elementRef } : {})}
            id={id}
            autofocus={autofocus}
          />
        </div>
      );
    }

    const settings = maskMap(get(i18n, 'language', '').toLowerCase());

    const pipe = get(settings, [type, 'pipe'], null);
    const filter = get(settings, [type, 'filter'], []);
    const maskConf = get(settings, [type, 'mask']);
    const inputType = get(settings, [type, 'type']);

    return (
      <FormInput
        label={label}
        required={required}
        onLabelClick={this.handleLabelClick}
        error={error}
        hint={hint}
        className={classNames(className, `input-name-${name}`)}
      >
        {maskConf && (
          <InputMaskModule
            keepCharPositions={false}
            showMask={false}
            guide={false}
            placeholderChar="_"
            type={inputType}
            name={name}
            mask={settings[type].mask}
            id={id}
            {...wrapValue(defaultValue, clean(value || '', filter), uncontrolled)}
            placeholder={placeholder || ''}
            disabled={disabled}
            onChange={this.handleOnChange}
            {...(pipe ? { pipe } : {})}
            {...(elementRef ? { ref: elementRef } : {})}
            autoFocus={autofocus}
          />
        )}
        {!maskConf && (
          <Input
            type="text"
            autofocus={autofocus}
            {...(elementRef ? { ref: elementRef } : {})}
            value={value || ''}
            name={name}
            id={id}
            placeholder={placeholder || ''}
            onChange={this.handleOnChange}
          />
        )}
      </FormInput>
    );
  }

  maskModule: any;

  handleOnChangeCurrency = (data) => {
    const {
      onChange,
    } = this.props;
    onChange({ name: data.name, value: data.value });
  };

  handleOnChange = (e) => {
    const {
      type, onChange, name, i18n,
    } = this.props;

    const settings = maskMap(get(i18n, 'language', '').toLowerCase());
    const value = get(settings, [type, 'mask']) ? e.target.value : get(e, 'value', '');
    const filters = get(settings, [type, 'filter'], []);
    const cleanValue = clean(value || '', filters);

    onChange({ name, value: cleanValue });
  };
}

export default withTranslation()(InputMask);
