// @flow
import React from 'react';
import { connect } from 'react-redux';
import { logout } from '@context/user';
import { mapBundledPermission } from '@helpers/connect';
import { Support as SupportView } from '../components/views';
import { appControlActions } from '../redux/modules/appControl';
import type { User, Dispatch, BundledPermissions } from '../types';
import { get } from 'lodash';

type Props = {
  user: User,
  accessToken: string,
  appVersion: string,
  dispatch: Function,
  permissions: BundledPermissions,
  subscriptionActive: boolean,
};

class Support extends React.Component<Props> {
  render() {
    const { accessToken, user, appVersion, subscriptionActive } = this.props;

    return (
      <SupportView
        accessToken={accessToken}
        user={user}
        appVersion={appVersion}
        onUpdateApp={this.handleUpdateApp}
        isIntercomEnabled={this.props.permissions.planInclude.intercom}
        onLogout={this.handleLogout}
        permissions={this.props.permissions}
        subscriptionActive={subscriptionActive}
      />
    );
  }

  handleLogout = () => this.props.dispatch(logout());

  handleUpdateApp = () => {
    this.props.dispatch(appControlActions.updateApp());
  };
}

const mapStateToProps = (state) => ({
  accessToken: get(state, ['auth', 'token'], null),
  user: state.context.user,
  appVersion: state.appVersion.version,
  permissions: mapBundledPermission(state),
  subscriptionActive: get(state, ['context', 'user', 'advancedData', 'subscriptionStatus']) === 'active',
});

const mapDispatchToProps = (dispatch: Dispatch) => ({ dispatch });

export default connect<any, any, any, any, any, any>(
  mapStateToProps,
  mapDispatchToProps,
)(Support);
