import React from 'react';
import { isFunction } from 'lodash';
import { withTranslation } from 'react-i18next';
import { createNsTranslator } from '@helpers/helpers';
import './HiddenLines.scss';

type Props = {
  text: string,
  maxChars: number,
  children: any,
  scrollContainer?: any,
  t: Function,
};

type State = {
  showMore: boolean,
};

class HiddenLines extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      showMore: false,
    };
  }
  render() {
    const { showMore } = this.state;
    const { text, maxChars, children } = this.props;

    const inLimit = String(text).trim().length > maxChars;

    const contentText = inLimit ? String(text).substring(0, maxChars) + '...' : text;
    const viewText = showMore ? text : contentText;

    const content = isFunction(children) ? children(viewText) : viewText;

    const _ = createNsTranslator('global', this.props.t);

    return (
      <React.Fragment>
        <span ref={ref => { this.scroolRef = ref; }} />
        {content}
        {!showMore && inLimit && <div className="showMoreCallToAction" onClick={this.handleShowClick}>▼ {_('action/expand')}</div>}
        {showMore && inLimit && <div className="showMoreCallToAction" onClick={this.handleHideClick}>▲ {_('action/hide')}</div>}
      </React.Fragment>
    );
  }

  scroolRef: any = null;

  handleShowClick = () => {
    this.setState({ showMore: true });
  };

  handleHideClick = () => {
    this.setState({ showMore: false }, this.scroolToRef);
  };

  scroolToRef = () => {
    if (this.props.scrollContainer && this.scroolRef) {
      // TODO: Scrool to relative element position
    }
  };
}

export default withTranslation()(HiddenLines);
