// @flow
import React from 'react';
import { connect } from 'react-redux';
import { get, toString, pick } from 'lodash';
import { ControlledPrescriptionsPrintConfig as ControlledPrescriptionsPrintConfigView } from '@views';
import type { User, Clinic, DoctorsState, BundledPermissions } from '@types';
import { clinicActions } from '@context/clinics';
import { logout } from '@context/user';
import { mapBundledPermission } from '@helpers/connect';

type Props = {
  user: User,
  clinic: Clinic,
  doctors: DoctorsState,
  doctorId: number,
  dispatch: Function,
  isSaving: boolean,
  permissions: BundledPermissions,
};

class ControlledPrescriptionsPrintConfig extends React.Component<Props> {
  render() {
    const {
      user, isSaving,
    } = this.props;

    return (
      <ControlledPrescriptionsPrintConfigView
        user={user}
        formData={this.getFormData()}
        isLoading={isSaving}
        onSave={this.handleOnSave}
        onLogout={this.handleLogout}
        permissions={this.props.permissions}
      />
    );
  }

  handleLogout = () => this.props.dispatch(logout());

  getFormData = () => {
    const {
      clinic, doctors, doctorId,
    } = this.props;
    const doctor = get(doctors, ['data', toString(doctorId)], {});

    return {
      ...pick(clinic, [
        'phone',
        'addressCity',
        'addressState',
        'addressZipcode',
        'addressStreet',
        'addressNumber',
        'addressExtra',
        'addressNeighborhood',
      ]),
      fullName: doctor.fullName,
      councilDoc: doctor.councilDoc,
    };
  };

  handleOnSave = (data: Object) => {
    const { dispatch } = this.props;
    dispatch(clinicActions.updateControlledPrescriptionLayout({
      doctor: {
        fullName: data.fullName,
        councilDoc: data.councilDoc,
      },
      clinic: pick(data, [
        'phone',
        'addressCity',
        'addressState',
        'addressZipcode',
        'addressStreet',
        'addressNumber',
        'addressExtra',
        'addressNeighborhood',
      ]),
    }));
  }
}

const mapStateToProps = (state) => ({
  user: get(state, ['context', 'user'], {}),
  activeClinic: get(state, ['context', 'clinics', 'activeClinic'], {}),
  clinic: get(state, ['context', 'clinics', 'activeClinic'], {}),
  isSaving: get(state, ['context', 'clinics', 'isSaving'], false),
  doctors: get(state, ['context', 'doctors'], {}),
  doctorId: get(state, ['context', 'user', 'advancedData', 'doctorId']),
  permissions: mapBundledPermission(state),
});

export default connect<any, any, any, any, any, any>(
  mapStateToProps,
)(ControlledPrescriptionsPrintConfig);
