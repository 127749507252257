export const heightSeries = [
  {
    name: '3',
    boy: [
      { x: 0, y: 56 },
      { x: 1, y: 61 },
      { x: 2, y: 65 },
      { x: 3, y: 67.5 },
      { x: 4, y: 70 },
      { x: 5, y: 72 },
      { x: 6, y: 74 },
      { x: 7, y: 76 },
      { x: 8, y: 77 },
      { x: 9, y: 79 },
      { x: 10, y: 80.5 },
      { x: 11, y: 82 },
      { x: 12, y: 83 },
      { x: 13, y: 84 },
      { x: 14, y: 85.5 },
      { x: 15, y: 86 },
      { x: 16, y: 88 },
      { x: 17, y: 89 },
      { x: 18, y: 90 },
      { x: 19, y: 91.5 },
      { x: 20, y: 92.5 },
      { x: 21, y: 93.5 },
      { x: 22, y: 95 },
      { x: 23, y: 96 },
      { x: 24, y: 97 },
      // { x: 25, y: 97 },
      // { x: 26, y: 98 },
      // { x: 27, y: 99 },
      // { x: 28, y: 100 },
      // { x: 29, y: 101 },
      // { x: 30, y: 102 },
      // { x: 31, y: 103 },
      // { x: 32, y: 104 },
      // { x: 33, y: 105 },
      // { x: 34, y: 106 },
      // { x: 35, y: 106.5 },
      // { x: 36, y: 107 },
      // { x: 37, y: 108 },
      // { x: 38, y: 109 },
      // { x: 39, y: 109.5 },
      // { x: 40, y: 110 },
      // { x: 41, y: 111 },
      // { x: 42, y: 112 },
      // { x: 43, y: 112.5 },
      // { x: 44, y: 113 },
      // { x: 45, y: 114 },
      // { x: 46, y: 114.5 },
      // { x: 47, y: 115 },
      // { x: 48, y: 116 },
      // { x: 49, y: 116.5 },
      // { x: 50, y: 117 },
      // { x: 51, y: 118 },
      // { x: 52, y: 119 },
      // { x: 53, y: 119.5 },
      // { x: 54, y: 120 },
      // { x: 55, y: 120.5 },
      // { x: 56, y: 121 },
      // { x: 57, y: 122 },
      // { x: 58, y: 122.5 },
      // { x: 59, y: 123 },
      // { x: 60, y: 124 },
    ],
    girl: [],
  },
  {
    name: '2',
    boy: [
      { x: 0, y: 54 },
      { x: 1, y: 59 },
      { x: 2, y: 62.5 },
      { x: 3, y: 65 },
      { x: 4, y: 67.5 },
      { x: 5, y: 69.5 },
      { x: 6, y: 71.5 },
      { x: 7, y: 73 },
      { x: 8, y: 75 },
      { x: 9, y: 76.5 },
      { x: 10, y: 78 },
      { x: 11, y: 79.5 },
      { x: 12, y: 81 },
      { x: 13, y: 82 },
      { x: 14, y: 83 },
      { x: 15, y: 84.5 },
      { x: 16, y: 85.6 },
      { x: 17, y: 87 },
      { x: 18, y: 88 },
      { x: 19, y: 89 },
      { x: 20, y: 90 },
      { x: 21, y: 91 },
      { x: 22, y: 92 },
      { x: 23, y: 93 },
      { x: 24, y: 94 },
      // { x: 25, y: 94 },
      // { x: 26, y: 95 },
      // { x: 27, y: 96 },
      // { x: 28, y: 97 },
      // { x: 29, y: 98 },
      // { x: 30, y: 99 },
      // { x: 31, y: 99.5 },
      // { x: 32, y: 100.5 },
      // { x: 33, y: 101 },
      // { x: 34, y: 102 },
      // { x: 35, y: 103 },
      // { x: 36, y: 103.5 },
      // { x: 37, y: 104 },
      // { x: 38, y: 105 },
      // { x: 39, y: 106 },
      // { x: 40, y: 106.5 },
      // { x: 41, y: 107 },
      // { x: 42, y: 107.5 },
      // { x: 43, y: 108.5 },
      // { x: 44, y: 109 },
      // { x: 45, y: 109.5 },
      // { x: 46, y: 110 },
      // { x: 47, y: 111 },
      // { x: 48, y: 112 },
      // { x: 49, y: 112.5 },
      // { x: 50, y: 113 },
      // { x: 51, y: 113.5 },
      // { x: 52, y: 114 },
      // { x: 53, y: 114.5 },
      // { x: 54, y: 115 },
      // { x: 55, y: 115.5 },
      // { x: 56, y: 116 },
      // { x: 57, y: 117 },
      // { x: 58, y: 117.5 },
      // { x: 59, y: 118 },
      // { x: 60, y: 119 },
    ],
    girl: [],
  },
  {
    name: '0',
    boy: [
      { x: 0, y: 50 },
      { x: 1, y: 55 },
      { x: 2, y: 58.5 },
      { x: 3, y: 61 },
      { x: 4, y: 63.5 },
      { x: 5, y: 65.5 },
      { x: 6, y: 67.5 },
      { x: 7, y: 69 },
      { x: 8, y: 70.5 },
      { x: 9, y: 72 },
      { x: 10, y: 73.5 },
      { x: 11, y: 74.5 },
      { x: 12, y: 76 },
      { x: 13, y: 77 },
      { x: 14, y: 78 },
      { x: 15, y: 79 },
      { x: 16, y: 80 },
      { x: 17, y: 81 },
      { x: 18, y: 82 },
      { x: 19, y: 83 },
      { x: 20, y: 84 },
      { x: 21, y: 85 },
      { x: 22, y: 86 },
      { x: 23, y: 87 },
      { x: 24, y: 87.5 },
      // { x: 25, y: 88 },
      // { x: 26, y: 89 },
      // { x: 27, y: 89.5 },
      // { x: 28, y: 90.5 },
      // { x: 29, y: 91 },
      // { x: 30, y: 92 },
      // { x: 31, y: 92.5 },
      // { x: 32, y: 93 },
      // { x: 33, y: 94 },
      // { x: 34, y: 94.5 },
      // { x: 35, y: 95.5 },
      // { x: 36, y: 96 },
      // { x: 37, y: 97 },
      // { x: 38, y: 97.5 },
      // { x: 39, y: 98 },
      // { x: 40, y: 98.5 },
      // { x: 41, y: 99 },
      // { x: 42, y: 100 },
      // { x: 43, y: 100.5 },
      // { x: 44, y: 101 },
      // { x: 45, y: 101.5 },
      // { x: 46, y: 102 },
      // { x: 47, y: 102.5 },
      // { x: 48, y: 103 },
      // { x: 49, y: 104 },
      // { x: 50, y: 104.5 },
      // { x: 51, y: 105 },
      // { x: 52, y: 105.5 },
      // { x: 53, y: 106 },
      // { x: 54, y: 106.5 },
      // { x: 55, y: 107 },
      // { x: 56, y: 108 },
      // { x: 57, y: 108.5 },
      // { x: 58, y: 109 },
      // { x: 59, y: 109.5 },
      // { x: 60, y: 110 },
    ],
    girl: [],
  },
  {
    name: '-2',
    boy: [
      { x: 0, y: 46 },
      { x: 1, y: 51 },
      { x: 2, y: 54.5 },
      { x: 3, y: 57 },
      { x: 4, y: 59.5 },
      { x: 5, y: 61.5 },
      { x: 6, y: 63.5 },
      { x: 7, y: 65 },
      { x: 8, y: 66 },
      { x: 9, y: 67.5 },
      { x: 10, y: 69 },
      { x: 11, y: 70 },
      { x: 12, y: 71 },
      { x: 13, y: 72 },
      { x: 14, y: 73 },
      { x: 15, y: 74 },
      { x: 16, y: 75 },
      { x: 17, y: 76 },
      { x: 18, y: 77 },
      { x: 19, y: 77.5 },
      { x: 20, y: 78.5 },
      { x: 21, y: 79 },
      { x: 22, y: 80 },
      { x: 23, y: 81 },
      { x: 24, y: 81.5 },
      // { x: 25, y: 82 },
      // { x: 26, y: 82.5 },
      // { x: 27, y: 83 },
      // { x: 28, y: 84 },
      // { x: 29, y: 84.5 },
      // { x: 30, y: 85 },
      // { x: 31, y: 85.5 },
      // { x: 32, y: 86 },
      // { x: 33, y: 87 },
      // { x: 34, y: 87.5 },
      // { x: 35, y: 88 },
      // { x: 36, y: 88.5 },
      // { x: 37, y: 89 },
      // { x: 38, y: 89.5 },
      // { x: 39, y: 90 },
      // { x: 40, y: 91 },
      // { x: 41, y: 91.5 },
      // { x: 42, y: 92 },
      // { x: 43, y: 92.5 },
      // { x: 44, y: 93 },
      // { x: 45, y: 93.5 },
      // { x: 46, y: 94 },
      // { x: 47, y: 94.5 },
      // { x: 48, y: 95 },
      // { x: 49, y: 95.5 },
      // { x: 50, y: 96 },
      // { x: 51, y: 96.5 },
      // { x: 52, y: 97 },
      // { x: 53, y: 97.5 },
      // { x: 54, y: 98 },
      // { x: 55, y: 98.5 },
      // { x: 56, y: 99 },
      // { x: 57, y: 99.5 },
      // { x: 58, y: 100 },
      // { x: 59, y: 100.5 },
      // { x: 60, y: 101 },
    ],
    girl: [],
  },
  {
    name: '-3',
    boy: [
      { x: 0, y: 44 },
      { x: 1, y: 49 },
      { x: 2, y: 52.5 },
      { x: 3, y: 55 },
      { x: 4, y: 57.5 },
      { x: 5, y: 59.5 },
      { x: 6, y: 61 },
      { x: 7, y: 63 },
      { x: 8, y: 64 },
      { x: 9, y: 65.5 },
      { x: 10, y: 67 },
      { x: 11, y: 68 },
      { x: 12, y: 69 },
      { x: 13, y: 70 },
      { x: 14, y: 71 },
      { x: 15, y: 71.5 },
      { x: 16, y: 72.5 },
      { x: 17, y: 73 },
      { x: 18, y: 74 },
      { x: 19, y: 75 },
      { x: 20, y: 75.5 },
      { x: 21, y: 76 },
      { x: 22, y: 77 },
      { x: 23, y: 78 },
      { x: 24, y: 78.5 },
      // { x: 25, y: 78.5 },
      // { x: 26, y: 79 },
      // { x: 27, y: 80 },
      // { x: 28, y: 80.5 },
      // { x: 29, y: 81 },
      // { x: 30, y: 82 },
      // { x: 31, y: 82.5 },
      // { x: 32, y: 83 },
      // { x: 33, y: 83.5 },
      // { x: 34, y: 84 },
      // { x: 35, y: 84.5 },
      // { x: 36, y: 85 },
      // { x: 37, y: 85.5 },
      // { x: 38, y: 86 },
      // { x: 39, y: 86.5 },
      // { x: 40, y: 87 },
      // { x: 41, y: 87.5 },
      // { x: 42, y: 88 },
      // { x: 43, y: 88.5 },
      // { x: 44, y: 89 },
      // { x: 45, y: 89.5 },
      // { x: 46, y: 90 },
      // { x: 47, y: 90 },
      // { x: 48, y: 90.5 },
      // { x: 49, y: 91 },
      // { x: 50, y: 91.5 },
      // { x: 51, y: 92 },
      // { x: 52, y: 92.5 },
      // { x: 53, y: 93 },
      // { x: 54, y: 93 },
      // { x: 55, y: 93.5 },
      // { x: 56, y: 94 },
      // { x: 57, y: 94.5 },
      // { x: 58, y: 95 },
      // { x: 59, y: 95.5 },
      // { x: 60, y: 96 },
    ],
    girl: [],
  },
];

export const weightSeries = [
  {
    name: '3',
    boy: [
      { x: 0, y: 5 },
      { x: 1, y: 6.5 },
      { x: 2, y: 7.5 },
      { x: 3, y: 8.5 },
      { x: 4, y: 9.5 },
      { x: 5, y: 10.2 },
      { x: 6, y: 10.8 },
      { x: 7, y: 11.5 },
      { x: 8, y: 12 },
      { x: 9, y: 12.5 },
      { x: 10, y: 12.8 },
      { x: 11, y: 13.2 },
      { x: 12, y: 13.5 },
      { x: 13, y: 13.8 },
      { x: 14, y: 14.2 },
      { x: 15, y: 14.5 },
      { x: 16, y: 14.75 },
      { x: 17, y: 15 },
      { x: 18, y: 15.25 },
      { x: 19, y: 15.5 },
      { x: 20, y: 15.8 },
      { x: 21, y: 16.2 },
      { x: 22, y: 16.5 },
      { x: 23, y: 16.75 },
      { x: 24, y: 17 },
      // { x: 25, y: 17.5 },
      // { x: 26, y: 17.75 },
      // { x: 27, y: 18 },
      // { x: 28, y: 18.25 },
      // { x: 29, y: 18.5 },
      // { x: 30, y: 18.8 },
      // { x: 31, y: 19.1 },
      // { x: 32, y: 19.5 },
      // { x: 33, y: 19.75 },
      // { x: 34, y: 20 },
      // { x: 35, y: 20.4 },
      // { x: 36, y: 20.6 },
      // { x: 37, y: 21 },
      // { x: 38, y: 21.25 },
      // { x: 39, y: 21.5 },
      // { x: 40, y: 22 },
      // { x: 41, y: 22.25 },
      // { x: 42, y: 22.5 },
      // { x: 43, y: 22.75 },
      // { x: 44, y: 23 },
      // { x: 45, y: 23.4 },
      // { x: 46, y: 23.6 },
      // { x: 47, y: 24 },
      // { x: 48, y: 24.25 },
      // { x: 49, y: 24.5 },
      // { x: 50, y: 25 },
      // { x: 51, y: 25.25 },
      // { x: 52, y: 25.5 },
      // { x: 53, y: 25.75 },
      // { x: 54, y: 26 },
      // { x: 55, y: 26.5 },
      // { x: 56, y: 26.75 },
      // { x: 57, y: 27 },
      // { x: 58, y: 27.25 },
      // { x: 59, y: 27.5 },
      // { x: 60, y: 28 },
    ],
    girl: [],
  },
  {
    name: '2',
    boy: [
      { x: 0, y: 4.5 },
      { x: 1, y: 6 },
      { x: 2, y: 7 },
      { x: 3, y: 8 },
      { x: 4, y: 8.6 },
      { x: 5, y: 9.25 },
      { x: 6, y: 9.8 },
      { x: 7, y: 10.25 },
      { x: 8, y: 10.75 },
      { x: 9, y: 11.2 },
      { x: 10, y: 11.5 },
      { x: 11, y: 11.8 },
      { x: 12, y: 12.2 },
      { x: 13, y: 12.5 },
      { x: 14, y: 12.75 },
      { x: 15, y: 13 },
      { x: 16, y: 13.25 },
      { x: 17, y: 13.5 },
      { x: 18, y: 13.75 },
      { x: 19, y: 14 },
      { x: 20, y: 14.25 },
      { x: 21, y: 14.5 },
      { x: 22, y: 14.75 },
      { x: 23, y: 15 },
      { x: 24, y: 15.25 },
      // { x: 25, y: 15.5 },
      // { x: 26, y: 15.75 },
      // { x: 27, y: 16 },
      // { x: 28, y: 16.25 },
      // { x: 29, y: 16.5 },
      // { x: 30, y: 16.75 },
      // { x: 31, y: 17 },
      // { x: 32, y: 17.25 },
      // { x: 33, y: 17.5 },
      // { x: 34, y: 17.75 },
      // { x: 35, y: 18 },
      // { x: 36, y: 18.25 },
      // { x: 37, y: 18.5 },
      // { x: 38, y: 18.75 },
      // { x: 39, y: 19 },
      // { x: 40, y: 19.25 },
      // { x: 41, y: 19.5 },
      // { x: 42, y: 19.75 },
      // { x: 43, y: 20 },
      // { x: 44, y: 20.25 },
      // { x: 45, y: 20.5 },
      // { x: 46, y: 20.75 },
      // { x: 47, y: 21 },
      // { x: 48, y: 21.25 },
      // { x: 49, y: 21.5 },
      // { x: 50, y: 21.75 },
      // { x: 51, y: 22 },
      // { x: 52, y: 22.25 },
      // { x: 53, y: 22.5 },
      // { x: 54, y: 22.75 },
      // { x: 55, y: 23 },
      // { x: 56, y: 23.25 },
      // { x: 57, y: 23.5 },
      // { x: 58, y: 23.75 },
      // { x: 59, y: 24 },
      // { x: 60, y: 24.25 },
    ],
    girl: [],
  },
  {
    name: '0',
    boy: [
      { x: 0, y: 3.25 },
      { x: 1, y: 4.75 },
      { x: 2, y: 5.75 },
      { x: 3, y: 6.5 },
      { x: 4, y: 7 },
      { x: 5, y: 7.5 },
      { x: 6, y: 8 },
      { x: 7, y: 8.25 },
      { x: 8, y: 8.5 },
      { x: 9, y: 9 },
      { x: 10, y: 9.25 },
      { x: 11, y: 9.5 },
      { x: 12, y: 9.75 },
      { x: 13, y: 10 },
      { x: 14, y: 10.25 },
      { x: 15, y: 10.5 },
      { x: 16, y: 10.75 },
      { x: 17, y: 11 },
      { x: 18, y: 11.1 },
      { x: 19, y: 11.25 },
      { x: 20, y: 11.5 },
      { x: 21, y: 11.75 },
      { x: 22, y: 11.9 },
      { x: 23, y: 12 },
      { x: 24, y: 12.1 },
      // { x: 25, y: 12.25 },
      // { x: 26, y: 12.5 },
      // { x: 27, y: 12.6 },
      // { x: 28, y: 12.8 },
      // { x: 29, y: 13 },
      // { x: 30, y: 13.1 },
      // { x: 31, y: 13.4 },
      // { x: 32, y: 13.5 },
      // { x: 33, y: 13.6 },
      // { x: 34, y: 13.8 },
      // { x: 35, y: 14 },
      // { x: 36, y: 14.25 },
      // { x: 37, y: 14.4 },
      // { x: 38, y: 14.5 },
      // { x: 39, y: 14.6 },
      // { x: 40, y: 14.8 },
      // { x: 41, y: 15 },
      // { x: 42, y: 15.2 },
      // { x: 43, y: 15.4 },
      // { x: 44, y: 15.5 },
      // { x: 45, y: 15.6 },
      // { x: 46, y: 15.8 },
      // { x: 47, y: 16 },
      // { x: 48, y: 16.2 },
      // { x: 49, y: 16.4 },
      // { x: 50, y: 16.5 },
      // { x: 51, y: 16.75 },
      // { x: 52, y: 17 },
      // { x: 53, y: 17.2 },
      // { x: 54, y: 17.4 },
      // { x: 55, y: 17.5 },
      // { x: 56, y: 17.6 },
      // { x: 57, y: 17.8 },
      // { x: 58, y: 18 },
      // { x: 59, y: 18.2 },
      // { x: 60, y: 18.4 },
    ],
    girl: [],
  },
  {
    name: '-2',
    boy: [
      { x: 0, y: 2.5 },
      { x: 1, y: 3.5 },
      { x: 2, y: 4.25 },
      { x: 3, y: 4.8 },
      { x: 4, y: 5.5 },
      { x: 5, y: 5.8 },
      { x: 6, y: 6.25 },
      { x: 7, y: 6.6 },
      { x: 8, y: 6.8 },
      { x: 9, y: 7.2 },
      { x: 10, y: 7.4 },
      { x: 11, y: 7.6 },
      { x: 12, y: 7.75 },
      { x: 13, y: 8 },
      { x: 14, y: 8.1 },
      { x: 15, y: 8.25 },
      { x: 16, y: 8.4 },
      { x: 17, y: 8.5 },
      { x: 18, y: 8.6 },
      { x: 19, y: 8.8 },
      { x: 20, y: 9 },
      { x: 21, y: 9.1 },
      { x: 22, y: 9.25 },
      { x: 23, y: 9.5 },
      { x: 24, y: 9.75 },
      // { x: 25, y: 9.9 },
      // { x: 26, y: 10 },
      // { x: 27, y: 10.1 },
      // { x: 28, y: 10.2 },
      // { x: 29, y: 10.3 },
      // { x: 30, y: 10.4 },
      // { x: 31, y: 10.5 },
      // { x: 32, y: 10.6 },
      // { x: 33, y: 10.8 },
      // { x: 34, y: 10.9 },
      // { x: 35, y: 11 },
      // { x: 36, y: 11.2 },
      // { x: 37, y: 11.3 },
      // { x: 38, y: 11.4 },
      // { x: 39, y: 11.5 },
      // { x: 40, y: 11.6 },
      // { x: 41, y: 11.8 },
      // { x: 42, y: 11.9 },
      // { x: 43, y: 12 },
      // { x: 44, y: 12.2 },
      // { x: 45, y: 12.3 },
      // { x: 46, y: 12.4 },
      // { x: 47, y: 12.5 },
      // { x: 48, y: 12.6 },
      // { x: 49, y: 12.8 },
      // { x: 50, y: 12.9 },
      // { x: 51, y: 13 },
      // { x: 52, y: 12.2 },
      // { x: 53, y: 12.3 },
      // { x: 54, y: 12.4 },
      // { x: 55, y: 13.5 },
      // { x: 56, y: 12.6 },
      // { x: 57, y: 12.8 },
      // { x: 58, y: 12.9 },
      // { x: 59, y: 14 },
      // { x: 60, y: 14.1 },
    ],
    girl: [],
  },
  {
    name: '-3',
    boy: [
      { x: 0, y: 2 },
      { x: 1, y: 3 },
      { x: 2, y: 3.75 },
      { x: 3, y: 4.3 },
      { x: 4, y: 4.8 },
      { x: 5, y: 5.25 },
      { x: 6, y: 5.6 },
      { x: 7, y: 6 },
      { x: 8, y: 6.25 },
      { x: 9, y: 6.5 },
      { x: 10, y: 6.6 },
      { x: 11, y: 6.8 },
      { x: 12, y: 7.1 },
      { x: 13, y: 7.25 },
      { x: 14, y: 7.4 },
      { x: 15, y: 7.5 },
      { x: 16, y: 7.6 },
      { x: 17, y: 7.8 },
      { x: 18, y: 8 },
      { x: 19, y: 8.1 },
      { x: 20, y: 8.2 },
      { x: 21, y: 8.3 },
      { x: 22, y: 8.4 },
      { x: 23, y: 8.5 },
      { x: 24, y: 8.6 },
      // { x: 25, y: 8.7 },
      // { x: 26, y: 8.9 },
      // { x: 27, y: 9 },
      // { x: 28, y: 9.1 },
      // { x: 29, y: 9.2 },
      // { x: 30, y: 9.3 },
      // { x: 31, y: 9.4 },
      // { x: 32, y: 9.5 },
      // { x: 33, y: 9.6 },
      // { x: 34, y: 9.7 },
      // { x: 35, y: 9.8 },
      // { x: 36, y: 9.9 },
      // { x: 37, y: 10 },
      // { x: 38, y: 10.1 },
      // { x: 39, y: 10.2 },
      // { x: 40, y: 10.3 },
      // { x: 41, y: 10.4 },
      // { x: 42, y: 10.5 },
      // { x: 43, y: 10.6 },
      // { x: 44, y: 10.7 },
      // { x: 45, y: 10.8 },
      // { x: 46, y: 10.9 },
      // { x: 47, y: 11 },
      // { x: 48, y: 11.2 },
      // { x: 49, y: 11.3 },
      // { x: 50, y: 11.4 },
      // { x: 51, y: 11.5 },
      // { x: 52, y: 11.6 },
      // { x: 53, y: 11.7 },
      // { x: 54, y: 11.8 },
      // { x: 55, y: 11.9 },
      // { x: 56, y: 12 },
      // { x: 57, y: 12.2 },
      // { x: 58, y: 12.3 },
      // { x: 59, y: 12.4 },
      // { x: 60, y: 12.5 },
    ],
    girl: [],
  },
];

export const imcSeries = [
  {
    name: '3',
    boy: [],
    girl: [],
  },
  {
    name: '2',
    boy: [],
    girl: [],
  },
  {
    name: '1',
    boy: [],
    girl: [],
  },
  {
    name: '0',
    boy: [
      { x: 60, y: 15.25 },
      { x: 61, y: 15.26 },
      { x: 62, y: 15.28 },
      { x: 63, y: 15.30 },
      { x: 64, y: 15.31 },
      { x: 65, y: 15.32 },
      { x: 66, y: 15.33 },
      { x: 67, y: 15.34 },
      { x: 68, y: 15.35 },
      { x: 69, y: 15.36 },
      { x: 70, y: 15.38 },
      { x: 71, y: 15.39 },
      { x: 72, y: 15.40 },
      { x: 73, y: 15.40 },
      { x: 74, y: 15.41 },
      { x: 75, y: 15.42 },
      { x: 76, y: 15.43 },
      { x: 77, y: 15.44 },
      { x: 78, y: 15.45 },
      { x: 79, y: 15.46 },
      { x: 80, y: 15.47 },
      { x: 81, y: 15.48 },
      { x: 82, y: 15.49 },
      { x: 83, y: 15.50 },
      { x: 84, y: 15.50 },
      { x: 85, y: 15.52 },
      { x: 86, y: 15.54 },
      { x: 87, y: 15.56 },
      { x: 88, y: 15.58 },
      { x: 89, y: 15.60 },
      { x: 90, y: 15.62 },
      { x: 91, y: 15.64 },
      { x: 92, y: 15.66 },
      { x: 93, y: 15.68 },
      { x: 94, y: 15.70 },
      { x: 95, y: 15.72 },
      { x: 96, y: 15.75 },
      { x: 97, y: 15.78 },
      { x: 98, y: 15.80 },
      { x: 99, y: 15.82 },
      { x: 100, y: 15.84 },
      { x: 101, y: 15.86 },
      { x: 102, y: 15.88 },
      { x: 103, y: 15.90 },
      { x: 104, y: 15.92 },
      { x: 105, y: 15.94 },
      { x: 106, y: 15.96 },
      { x: 107, y: 15.98 },
      { x: 108, y: 16.00 },
      { x: 109, y: 16.04 },
      { x: 110, y: 16.08 },
      { x: 111, y: 16.12 },
      { x: 112, y: 16.16 },
      { x: 113, y: 16.20 },
      { x: 114, y: 16.24 },
      { x: 115, y: 16.28 },
      { x: 116, y: 16.32 },
      { x: 117, y: 16.36 },
      { x: 118, y: 16.40 },
      { x: 119, y: 16.45 },
      { x: 120, y: 16.50 },
    ],
    girl: [],
  },
  {
    name: '-1',
    boy: [],
    girl: [],
  },
  {
    name: '-2',
    boy: [
      { x: 60, y: 13.00 },
      { x: 61, y: 13.00 },
      { x: 62, y: 13.00 },
      { x: 63, y: 13.00 },
      { x: 64, y: 13.00 },
      { x: 65, y: 13.00 },
      { x: 66, y: 13.00 },
      { x: 67, y: 13.00 },
      { x: 68, y: 13.00 },
      { x: 69, y: 13.00 },
      { x: 70, y: 13.00 },
      { x: 71, y: 13.00 },
      { x: 72, y: 13.00 },
      { x: 73, y: 13.00 },
      { x: 74, y: 13.02 },
      { x: 75, y: 13.03 },
      { x: 76, y: 13.04 },
      { x: 77, y: 13.05 },
      { x: 78, y: 13.05 },
      { x: 79, y: 13.06 },
      { x: 80, y: 13.07 },
      { x: 81, y: 13.08 },
      { x: 82, y: 13.09 },
      { x: 83, y: 13.10 },
      { x: 84, y: 13.10 },
      { x: 85, y: 13.10 },
      { x: 86, y: 13.11 },
      { x: 87, y: 13.12 },
      { x: 88, y: 13.14 },
      { x: 89, y: 13.16 },
      { x: 90, y: 13.18 },
      { x: 91, y: 13.20 },
      { x: 92, y: 13.21 },
      { x: 93, y: 13.22 },
      { x: 94, y: 13.23 },
      { x: 95, y: 13.24 },
      { x: 96, y: 13.25 },
      { x: 97, y: 13.28 },
      { x: 98, y: 13.30 },
      { x: 99, y: 13.32 },
      { x: 100, y: 13.34 },
      { x: 101, y: 13.36 },
      { x: 102, y: 13.38 },
      { x: 103, y: 13.40 },
      { x: 104, y: 13.42 },
      { x: 105, y: 13.44 },
      { x: 106, y: 13.46 },
      { x: 107, y: 13.48 },
      { x: 108, y: 13.50 },
      { x: 109, y: 13.52 },
      { x: 110, y: 13.54 },
      { x: 111, y: 13.56 },
      { x: 112, y: 13.58 },
      { x: 113, y: 13.60 },
      { x: 114, y: 13.62 },
      { x: 115, y: 13.64 },
      { x: 116, y: 13.66 },
      { x: 117, y: 13.68 },
      { x: 118, y: 13.70 },
      { x: 119, y: 13.72 },
      { x: 120, y: 13.75 },
    ],
    girl: [],
  },
  {
    name: '-3',
    boy: [
      { x: 60, y: 12.1 },
      { x: 61, y: 12.11 },
      { x: 62, y: 12.12 },
      { x: 63, y: 12.13 },
      { x: 64, y: 12.14 },
      { x: 65, y: 12.16 },
      { x: 66, y: 12.17 },
      { x: 67, y: 12.18 },
      { x: 68, y: 12.19 },
      { x: 69, y: 12.2 },
      { x: 70, y: 12.21 },
      { x: 71, y: 12.22 },
      { x: 72, y: 12.23 },
      { x: 73, y: 12.24 },
      { x: 74, y: 12.25 },
      { x: 75, y: 12.26 },
      { x: 76, y: 12.28 },
      { x: 77, y: 12.29 },
      { x: 78, y: 12.30 },
      { x: 79, y: 12.31 },
      { x: 80, y: 12.32 },
      { x: 81, y: 12.33 },
      { x: 82, y: 12.34 },
      { x: 83, y: 12.35 },
      { x: 84, y: 12.36 },
      { x: 85, y: 12.38 },
      { x: 86, y: 12.39 },
      { x: 87, y: 12.40 },
      { x: 88, y: 12.41 },
      { x: 89, y: 12.42 },
      { x: 90, y: 12.43 },
      { x: 91, y: 12.44 },
      { x: 92, y: 12.45 },
      { x: 93, y: 12.46 },
      { x: 94, y: 12.47 },
      { x: 95, y: 12.49 },
      { x: 96, y: 12.50 },
      { x: 97, y: 12.50 },
      { x: 98, y: 12.50 },
      { x: 99, y: 12.50 },
      { x: 100, y: 12.50 },
      { x: 101, y: 12.50 },
      { x: 102, y: 12.50 },
      { x: 103, y: 12.50 },
      { x: 104, y: 12.50 },
      { x: 105, y: 12.50 },
      { x: 106, y: 12.50 },
      { x: 107, y: 12.50 },
      { x: 108, y: 12.50 },
      { x: 109, y: 12.52 },
      { x: 110, y: 12.54 },
      { x: 111, y: 12.56 },
      { x: 112, y: 12.58 },
      { x: 113, y: 12.60 },
      { x: 114, y: 12.62 },
      { x: 115, y: 12.64 },
      { x: 116, y: 12.66 },
      { x: 117, y: 12.68 },
      { x: 118, y: 12.70 },
      { x: 119, y: 12.72 },
      { x: 120, y: 12.75 },
    ],
    girl: [],
  },
];
