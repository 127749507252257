export default {
  'label/no_results_records': 'No hay ningún registro en el expediente de este paciente.',
  'label/no_results_records_deleted': 'No hay ningún registro eliminado en el expediente de este paciente.',
  'label/no_results_attachment': 'Ningún adjunto encontrado en el expediente de este paciente.',
  'label/no_results_prescription': 'Ninguna prescripción encontrada en el expediente de este paciente.',
  'label/no_results_docs': 'Ningún documento encontrado en el expediente de este paciente.',
  'label/no_results_exams': 'Ningún examen encontrado en el expediente de este paciente.',
  'label/no_results_forms': 'Ningún formulario encontrado en el expediente de este paciente.',
  'label/creating': 'Creando...',
  'label/medic_info': 'Información Médica',
  'label/reminder': 'Recordatorio',
  'label/medications': 'Medicamentos',
  'label/tag_input': 'Escribe el nombre de una etiqueta...',
  'label/problem_input': 'Escribe un problema...',
  'label/create_new_tag': 'Crear nueva etiqueta:',
  'label/create_new_problem': 'Agregar Nuevo Problema',
  'label/create_new_medication': 'Escribe el nombre de un medicamento...',
  'label/no_tags': 'Sin etiquetas.',
  'label/problems': 'Problemas',
  'label/not_permission': 'No tienes permiso para ver el contenido de este registro',
  'label/record_created_in': 'Registro creado en ',
  'label/migration_data_label': 'Registro de una migración de datos',
  'label/message/canceled': 'Registro eliminado el día',
  'label/message/canceled_in_day': 'Cita Cancelada por la Clínica el día',
  'label/message/patient_canceled_in_day': 'Cita Cancelada por el Paciente el día',
  'label/message/sms_canceled_in_day': 'Cita Cancelada por el Paciente por SMS el día',
  'label/message/patient_missed': 'El paciente faltó a la cita',
  'label/appointment_note': 'Observaciones de la Cita en la Agenda',
  'label/receipts': 'Recetas',
  'label/drop_file': 'Suelta aquí para adjuntar a este registro',
  'label/forms': 'Formularios',
  'label/constrolled_receipts': 'Recetas Controladas',
  'label/exam_request': 'Solicitud de Exámenes',
  'label/write_annotations': 'Escribe tus anotaciones',
  'label/future_appointments': 'Citas Futuras',
  'label/errors/future_appointments': 'Ocurrió un error al cargar las citas futuras.',
  'label/loading_memed_prescriptions': 'Cargando Memed...',
  'label/memed_medication': 'Medicamento',
  'label/memed_posology': 'Posología',
  'label/memed_qtd': 'Cant',
  'label/memed_': '',
  'label/memed_error': 'Ocurrió un error al consultar el Memed',
  'label/memed_error_link': 'haz clic aquí para intentar nuevamente',
  'label/docs': 'Documentos',
  'label/saved_doc_models': 'Modelos Guardados',
  'label/doc_title_opt': 'Título del Documento (opcional)',
  'label/doc_content_placeholder': 'Escribe aquí el texto del documento o elige un modelo al lado.',
  'label/doc_title': 'Título del Documento',
  'label/today_date': 'Fecha de Hoy',
  'label/saved_receipts_models': 'Modelos Guardados',
  'label/prescriptions_drugs': 'Prescripciones',
  'label/receipt_for': 'Prescripción para',
  'label/receipt_textarea_placeholder': 'Escribe aquí la prescripción o elige un modelo al lado.',
  'label/controlled_drugs': 'Recetas Controladas',
  'label/new_request': 'Nueva Solicitud de Exámenes',
  'label/new_request_edit': 'Editar Solicitud de Exámenes',
  'label/exams': 'Pedidos de Exámenes',
  'label/saved_exams_models': 'Modelos Guardados',
  'label/exam_prescription_to': 'Pedido de exámenes para',
  'label/i_request': 'Solicito',
  'label/exam_textarea_placeholder': 'Escribe aquí el pedido de exámenes o elige un modelo al lado.',
  'label/include_request_in_header': '\'Solicito:\' al principio del pedido',
  'label/white_paper': 'Hoja en Blanco',
  'label/letterhead_paper': 'Papel con Membrete',
  'label/print': 'Impresión',
  // MSG
  'msg/patient_name_copied': 'Nombre del paciente copiado.',
  // Actions
  'action/filter_records': 'Filtrar registros',
  'action/edit_perint_settings': 'Editar',
  'action/new_record': 'Nuevo Registro',
  'action/save_reminder': 'Guardar Recordatorio',
  'action/add_tag': 'Agregar Etiqueta',
  'action/add_problem': 'Agregar Problema',
  'action/add_medications': 'Agregar Medicamento',
  'action/filter/all_records': 'Todos los Registros',
  'action/filter/forms': 'Solo Formularios',
  'action/filter/my_records': 'Mis Registros',
  'action/filter/attachments': 'Solo Adjuntos',
  'action/filter/prescriptions': 'Solo Prescripciones',
  'action/download': 'Descargar',
  'action/delete_file': 'Eliminar',
  'action/fast_preview': 'Vista Rápida',
  'action/add_new_medication': 'Agregar Nuevo Medicamento',
  'action/change_date': 'Cambiar Fecha',
  'action/print_record': 'Imprimir Registro',
  'action/share_header': 'Compartir',
  'action/print_record_share': 'Compartir Registro',
  'action/print_full_record': 'Imprimir Expediente Completo',
  'action/print_full_record_share': 'Compartir Expediente Completo',
  'action/delete_record': 'Eliminar Registro',
  'action/visibility/label': '¿Quién puede ver el contenido de este registro?',
  'action/visibility/all': 'Todos - Doctores y secretarias de la clínica',
  'action/visibility/only_doctors': 'Doctores - Solo los doctores de la clínica',
  'action/visibility/only_me': 'Solo yo',
  'action/content_add/attachments': 'Adjuntos',
  'action/content_add/camera': 'De la Cámara',
  'action/content_add/from_files': 'De los Archivos',
  'action/content_add/receipts': 'Prescripción',
  'action/content_add/prescription': 'Prescripción',
  'action/content_add/exam': 'Exámenes',
  'action/content_add/docs': 'Documento',
  'action/content_add/forms': 'Formularios',
  'action/start_treatment': 'Iniciar Atención',
  'action/finalize_appointment': 'Finalizar Cita',
  'action/new_doc': 'Nuevo Documento',
  'action/new_receipt': 'Nueva Prescripción',
  'action/choose_an_action': 'Elige una opción',
  'action/take_photo': 'Tomar una foto',
  'action/album': 'Galería de fotos',
  'action/filter/docs': 'Solo Documentos',
  'action/filter/not_empty': 'Registros con Contenido',
  'action/filter/exams': 'Solo Exámenes',
  'action/filter/deleted': 'Registros Eliminados',
  'action/restore_deleted_record': 'Restaurar',
  'message/url_share_receipt': '{0} ha enviado su receta digital.\nHaga clic en el enlace para acceder: {1}',
  'message/url_share_exam': '{0} ha enviado su solicitud de examen.\nHaga clic en el enlace para acceder: {1}',
  'message/url_share_doc': '{0} ha enviado un documento.\nHaga clic en el enlace para acceder: {1}',
  'message/url_share_receipt/subject': 'Su receta digital',
  'message/url_share_exam/subject': 'Su solicitud de examen',
  'message/url_share_doc/subject': 'Su documento',
  'action/cancel': 'Cancelar',
};
