// @flow
import type { Action, ContextState } from '../../../types';

// ------------------------------------
// Constants
// ------------------------------------
const OPEN_MODAL = 'modals.OPEN_MODAL';
const CLOSE_ACTIVE_MODAL = 'modals.CLOSE_ACTIVE_MODAL';

const OPEN_MEMED_MODAL = 'modals.OPEN_MEMED_MODAL';

// ------------------------------------
// Actions
// ------------------------------------
export const openModal = (modalName: string, data?: Object = {}) => ({
  type: OPEN_MODAL,
  modalName,
  data,
});

export const openMemedModal = (data?: Object = {}) => ({
  type: OPEN_MEMED_MODAL,
  data,
});

export const closeActiveModal = () => ({
  type: CLOSE_ACTIVE_MODAL,
});

export const actions = {
  openModal,
  openMemedModal,
  closeActiveModal,
};

// ------------------------------------
// Reducer
// ------------------------------------
export default function modalsReducer (state: ContextState, action: Action): ContextState {
  switch (action.type) {
    case OPEN_MODAL:
      return state
        .setIn(['modals', 'active'], action.modalName)
        .setIn(['modals', 'data'], action.data);
    case OPEN_MEMED_MODAL:
      return state
        .setIn(['modals', 'active'], false)
        .setIn(['modals', 'isMemedOpen'], true)
        .setIn(['modals', 'data'], action.data);
    case CLOSE_ACTIVE_MODAL:
      return state
        .setIn(['modals', 'active'], false)
        .setIn(['modals', 'isMemedOpen'], false)
        .setIn(['modals', 'data'], null);
    default:
      return state;
  }
}
