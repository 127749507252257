// @flow
import React from 'react';
import moment from 'moment';
import { isEqual, get, filter, map, forEach, isFunction } from 'lodash';
import type { FormElementData } from '../types';

type WithFormProps = {
  formData: ?Object,
}

type WithFormState = {
  formData: Object,
  compareData: Object,
  touchedFields: { [field: string]: boolean },
  changedFields: { [field: string]: boolean },
  onValidation: ?Function,
}
/*
    Props example

    type Props = {
      formData: Object,
      touchedFields: Object,
      changedFields: Object,
      bindValidation: Function,
      handleFormChange: Function,
      handleTouchField: Function,
      formChange: Function,
      touchField: Function,
      resetData: Function,
    };
*/

/**
 * Inject's formData and handleFormChange on WrappedComponent
 * @param {Object} initialData Initial form data (Note: this object is concatenated with formData prioritizing the values of the formData )
 * @param {string} patchFormMethod ignore | keep | replace
 * The pros injected in WrappedComponent is
 * @prop {Function(data: FormElementData): Promise<void>} handleFormChange handles form field changing and touches field
 * @prop {Function(data: FormElementData): Promise<void>} handleTouchField handle in special onBlur event
 * @prop {Function(field:string, value: any): Promise<void>} formChange change/set a value in formData
 * @prop {Function(field:string, touched: boolean = true): Promise<void>} touchField touche's or untouche's field by touch param,
 * @prop {Function(void): void} resetData resetFormData
 * this method changes the touchedFields prop (Recomended use in Blur events)
 * @prop {Object} formData the form data itself
 * @prop {{[field:string]: boolean}} touchedFields list of touched fields
 * @prop {{[field:string]: boolean}} changedFields list of changed fields
 * @prop {Function(void)} bindValidation bind validation event
 * @prop {Function(void)} resetData reset form data
 */
export const withForm = (initialData: Object = {}, patchFormMethod: string | Function = 'ignore') => (WrappedComponent: any) =>
  class extends React.Component<WithFormProps, WithFormState> {
    constructor(props: WithFormProps) {
      super(props);
      this.state = {
        formData: {
          ...(initialData || {}),
          ...get(this.props, 'formData', {}),
        },
        compareData: {
          ...(initialData || {}),
          ...get(this.props, 'formData', {}),
        },
        touchedFields: {},
        changedFields: {},
        onValidation: () => {},
      };
    }

    /**
     * Here we use compare data to update based on first change not local state that is formData
     * compareData is internally used only
     */
    componentWillReceiveProps(nextProps: WithFormProps) {
      // TODO: implements default patch null
      if (isFunction(patchFormMethod)) {
        const dt = patchFormMethod(this.props.formData, nextProps.formData, this.props, nextProps);
        this.setState({ formData: dt, compareData: dt });
      } else if (patchFormMethod !== 'ignore' && !isEqual(this.state.compareData, nextProps.formData)) {
        const changedData = {};
        if (patchFormMethod === 'keep') {
          const changedFields = map(this.state.changedFields, (changed: boolean, key: string) => key);
          forEach(changedFields, fieldName => {
            changedData[fieldName] = this.state.formData[fieldName];
          });
        }
        const data = {
          ...get(nextProps, 'formData', initialData) || initialData,
          ...changedData, // force keep current data
        };
        this.setState({ formData: data, compareData: data });
      }
    }

    shouldComponentUpdate(nextProps: Object, nextState: Object) {
      return !isEqual(this.props, nextProps) || !isEqual(this.state, nextState);
    }

    render() {
      const {
        handleFormChange, touchField, handleTouchField, formChange, handleBindValidation, toggleChangeField,
        handleFormEdited, hanbdleSetFormEdited, handleResetChanges, handleSetDefaultData, setInitialData,
      } = this;
      const {
        formData, touchedFields, changedFields, compareData,
      } = this.state;
      const newProps = {
        ...this.props,
        handleFormChange,
        formData,
        touchedFields,
        touchField,
        handleTouchField,
        formChange,
        changedFields,
        bindValidation: handleBindValidation,
        formEdited: handleFormEdited,
        setFormEdited: hanbdleSetFormEdited,
        toggleChangedField: toggleChangeField,
        resetData: handleResetChanges,
        updateDefaultData: handleSetDefaultData,
        setInitialData,
        initialData: compareData,
      };

      return (<WrappedComponent {...newProps} />);
    }


    handleSetDefaultData = (formData: Object) => this.setState({ compareData: formData });
    setInitialData = (formData: Object) => this.setState({
      compareData: formData, formData, touchedFields: {}, changedFields: {},
    });

    handleFormEdited = () => filter(this.state.changedFields, it => it === true).length > 0;

    hanbdleSetFormEdited = () => (edited: boolean = true) => this.setState({ changedFields: edited ? this.state.changedFields : {} });

    handleFormChange = (data: FormElementData): Promise<void> => new Promise(resolve => {
      const fieldName = get(data, 'name', 'unknownField');
      const fieldValue = get(data, 'value', get(initialData, fieldName, ''));
      // Handle Composed change data
      if (data._isComposedChangeEvent === true) {
        const composedFields = get(data, 'composedValue', {});
        const mergeFields = {};
        const changedFields = [];

        forEach(composedFields, (val, key) => {
          mergeFields[key] = moment.isMoment(val) && val.isValid() ? val.format('YYYY-MM-DD') : val;
          changedFields.push(key);
        });

        this.setState({
          formData: {
            ...this.state.formData,
            ...mergeFields,
          },
        }, this.handleComposedFormChangeCallback(changedFields, resolve));
      } else {
        if (fieldName === 'unknownField') {
          // eslint-disable-next-line
          console.warn(`%c Unknown field found with value "${fieldValue}"`, 'background: #ff4326; color: #ffff');
        }

        this.setState({
          formData: {
            ...this.state.formData,
            [fieldName]: moment.isMoment(fieldValue) ? fieldValue.format('YYYY-MM-DD') : fieldValue,
          },
        }, this.handleFormChangeCallback(fieldName, resolve));
      }
    }).then(() => this.tryValidation());


    handleFormChangeCallback = (fieldName: string, resolve: Function) => () => this.touchField(fieldName)
      .then(() => {
        this.toggleChangeField(fieldName).then(resolve);
      });

    handleComposedFormChangeCallback = (fields: Array<string>, resolve: Function) => () => this.touchFields(fields)
      .then(() => {
        this.toggleChangeFields(fields).then(resolve);
      });


    formChange = (name: string, value: any): Promise<void> => this.handleFormChange({ name, value });

    touchField = (name: string, touched: boolean = true): Promise<void> => new Promise(resolve => this.setState({
      touchedFields: {
        ...this.state.touchedFields,
        [name]: touched,
      },
    }, resolve)).then(() => this.tryValidation());


    touchFields = (fields: Array<string>, touched: boolean = true): Promise<void> => new Promise(resolve => {
      const touchedFields = {};

      forEach(fields, field => {
        touchedFields[field] = touched;
      });
      this.setState({
        touchedFields: {
          ...this.state.touchedFields,
          ...touchedFields,
        },
      }, resolve);
    }).then(() => this.tryValidation());

    toggleChangeField = (name: string, changed: boolean = true): Promise<void> => new Promise(resolve => this.setState({
      changedFields: {
        ...this.state.changedFields,
        [name]: changed,
      },
    }, resolve)).then(() => this.tryValidation());

    toggleChangeFields = (fields: Array<string>, changed: boolean = true): Promise<void> => new Promise(resolve => {
      const changedFields = {};

      forEach(fields, field => {
        changedFields[field] = changed;
      });
      this.setState({
        changedFields: {
          ...this.state.changedFields,
          ...changedFields,
        },
      }, resolve);
    }).then(() => this.tryValidation());

    tryValidation = () => {
      if (this.state.onValidation) {
        this.state.onValidation(false);
      }
    };


    handleTouchField = (data: FormElementData) => this.touchField(get(data, 'name', 'unknownField'));

    handleBindValidation = (fn: Function) => this.setState({ onValidation: typeof fn === 'function' ? fn : (() => {}) });

    handleResetChanges = () => {
      this.setState({ formData: this.state.compareData, touchedFields: {}, changedFields: {} });
    }
  };

