// @flow
import React from 'react';
import { find, size } from 'lodash';
import { SettingsMenu, EmptyState, PlatformSwitch, AppMobileHeader, ConditionalFeature, AppMobileFooter, DoctorSelector } from '@blocks';
import { Button, ClickableList, Dropdown, Spinner, FormattedText, SearchInput } from '@elements';
import type { User, BundledPermissions } from '@types';
import { sort, alphabeticCompare, createNsTranslator, filterSearch, isPresent } from '@helpers/helpers';
import { withTranslation } from 'react-i18next';
import { OFFLINE_SEARCH_EDGE } from '@helpers/constants';

type Item = {
  linkTo: string,
  customRendererData: {
    name: string,
    price: string,
  },
};

type Props = {
  user: User,
  doctorId: number,
  doctorsData: any,
  doctorsList: any,
  servicesItems: Array<Item>,
  healthInsuranceItems: Array<Item>,
  servicesLoading: boolean,
  isFeatureEnabled: boolean,
  reportsSelectDoctor: boolean,
  onLogout: Function,
  t: Function,
  permissions: BundledPermissions,
  editAllServicesTemplates: boolean,
  pushPath: Function,
};

type State = {
  showDoctorSelector: boolean,
  searchTerm: string,
};


class ServiceTemplatesList extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      showDoctorSelector: false,
      searchTerm: '',
    };
  }

  render() {
    const {
      user, doctorId, doctorsList, servicesLoading, isFeatureEnabled,
      onLogout, reportsSelectDoctor, editAllServicesTemplates, doctorsData,
    } = this.props;

    const _ = createNsTranslator('settings', this.props.t);

    let servicesContent = _('label/loading', 'global');
    let healthInsuranceContent = _('label/loading', 'global');

    let servicesItems = sort(this.props.servicesItems, alphabeticCompare(['name']));
    let healthInsuranceItems = sort(this.props.healthInsuranceItems, alphabeticCompare(['name']));


    const totalServices = size(servicesItems) + size(healthInsuranceItems);

    servicesItems = isPresent(this.state.searchTerm) ? filterSearch(servicesItems, this.state.searchTerm, ['customRendererData', 'name']) : servicesItems;
    healthInsuranceItems = isPresent(this.state.searchTerm) ? filterSearch(healthInsuranceItems, this.state.searchTerm, ['customRendererData', 'name']) : healthInsuranceItems;

    const renderer = (item) => ([
      <span key="name">{item.name}</span>,
      <span key="price"><FormattedText type="currency" text={item.price} /></span>,
    ]);

    const searching = isPresent(this.state.searchTerm);



    if (servicesItems.length === 0) {
      servicesContent = (
        <React.Fragment>
          {!searching && (
            <EmptyState
              className="inlineEmptyStateMobile"
              title={_('label/services/empty_title1')}
              hint={_('label/services/empty_hint1')}
            />
          )}
          {searching && (
            <EmptyState
              className="inlineEmptyStateMobile"
              title={_('label/services/search_empty')}
            />
          )}
        </React.Fragment>
      );
    } else {
      servicesContent = (
        <ClickableList
          items={servicesItems}
          customRenderer={renderer}
          card
        />
      );
    }

    if (healthInsuranceItems.length === 0) {
      healthInsuranceContent = (
        <React.Fragment>
          {!searching && (
            <EmptyState
              className="inlineEmptyStateMobile"
              title={_('label/services/empty_title2')}
              hint={_('label/services/empty_hint2')}
            />
          )}
          {searching && (
            <EmptyState
              className="inlineEmptyStateMobile"
              title={_('label/services/search_empty')}
            />
          )}
        </React.Fragment>
      );
    } else {
      healthInsuranceContent = (
        <ClickableList
          items={healthInsuranceItems}
          customRenderer={renderer}
          card
        />
      );
    }

    const pageContent = (
      <React.Fragment>
        <DoctorSelector isVisible={this.state.showDoctorSelector} onClose={this.handleCloseDoctorSelector} doctors={doctorsData} onSelect={this.handleSelectDoctor}/>
        {totalServices > OFFLINE_SEARCH_EDGE && (
          <div className="searchBox-services-settings">
            <SearchInput debounced onChange={this.handleSearch} autofocus mobileAutofocus />
          </div>
        )}
        <div className="firstList">
          <div>
            <div className="toolbar dashed">
              <h1>{_('label/services/private')}</h1>
              <Button className="primary" text={_('label/services/add_service')} linkTo={`/ajustes/servico/${doctorId}/novo`} />
            </div>
            {!servicesLoading ? servicesContent : <Spinner text={_('label/loading', 'global')} />}
          </div>
        </div>

        <div>
          <div className="toolbar dashed">
            <h1>{_('label/services/insurance_plan')}</h1>
            <Button className="primary" text={_('label/services/add_plan')} linkTo={`/ajustes/servico/${doctorId}/novo?insurance=true`} />
          </div>
          {!servicesLoading ? healthInsuranceContent : <Spinner text={_('label/loading', 'global')} />}
        </div>
      </React.Fragment>
    );

    return (
      <PlatformSwitch
        inject
        desktop={() => (
          <div className="appContent settingsPage">
            <aside className="sidebar">
              <SettingsMenu user={user} onLogout={onLogout} permissions={this.props.permissions} />
            </aside>
            <section className="mainSection">
              {isFeatureEnabled && reportsSelectDoctor && editAllServicesTemplates && (
                <div className="topBar">
                  <Dropdown
                    className="titleFilter withOpacity"
                    items={[]}
                    selectedItem={find(doctorsList, item => parseInt(item ? item.id : 0, 10) === parseInt(doctorId, 10))}
                    onSelectItem={this.handleSelectDoctor}
                    onClick={this.handleShowDoctorSelector}
                  />
                </div>
              )}
              <ConditionalFeature enabled={isFeatureEnabled} transparent>
                <div className="centerContent">
                  {pageContent}
                </div>
              </ConditionalFeature>
            </section>
          </div>
        )}
        mobile={() => (
          <React.Fragment>
            <AppMobileHeader title={_('action/menu/sevices_list')} backLinkTo="/ajustes" />
            <div className="appContent settingsPage">
              <ConditionalFeature enabled={isFeatureEnabled} transparent>
                {isFeatureEnabled && reportsSelectDoctor && editAllServicesTemplates && (
                  <div className="topBar mobile">
                    <Dropdown
                      className="titleFilter withOpacity"
                      items={[]}
                      selectedItem={find(doctorsList, item => parseInt(item ? item.id : 0, 10) === parseInt(doctorId, 10))}
                      onSelectItem={this.handleSelectDoctor}
                      onClick={this.handleShowDoctorSelector}
                    />
                  </div>
                )}
                <section className="mainSection">
                  <div className="centerContent">
                    {pageContent}
                  </div>
                </section>
              </ConditionalFeature>
            </div>
            <AppMobileFooter />
          </React.Fragment>
        )}
      />
    );
  }

  handleSearch = (text: string) => this.setState({ searchTerm: text });

  handleSelectDoctor = (doctorId: number) => {
    this.props.pushPath(`/ajustes/servicos/${doctorId}`);
  };

  handleCloseDoctorSelector = () => {
    this.setState({ showDoctorSelector: false });
  };

  handleShowDoctorSelector = () => {
    this.setState({ showDoctorSelector: true });
  };
}

export default withTranslation()(ServiceTemplatesList);
