// @flow
import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { PlatformSwitch, SettingsMenu, AppMobileFooter, AppMobileHeader, FormFooter, BlockedFeature } from '@blocks';
import type { User, BundledPermissions } from '@types';
import { Breadcrumb, Input, SelectBox, RadioGroup, InputMask, Button } from '@elements';
import { get } from 'lodash';
import { useDispatch } from 'react-redux';
import { useTranslationNs, fSufixTwoPoints, isPresent } from '@helpers/helpers';
import { brStates } from '@helpers/constants';
import { isValidCpf } from '@helpers/validator/rules/testers';
import { openToast } from '@context/toasts';
import moment from 'moment';
import './MemedSignup.scss';

type Props = {
  isLoading: boolean,
  error: string | null,
  token: string | null,
  planIncludeMemed: boolean,
  onSignup: Function,
  user: User,
  onLogout: Function,
  permissions: BundledPermissions,
  doctor: Object,
};

const formatDate = (dateString: string) => {
  const dt = moment(dateString);

  return dt.isValid() ? dt.format('DD/MM/YYYY') : '';
};

const MemedSignup = ({ error, isLoading, onSignup, token, planIncludeMemed = false, user, onLogout, permissions, doctor }: Props) => {
  const [_] = useTranslationNs('settings');


  const [formData, setFormData] = useState({
    addressState: get(user, 'addressState', ''),
    birthDate: formatDate(get(user, 'birthDate', '')),
    cpf: get(user, 'cpf', ''),
    gender: get(user, 'gender', ''),
    councilDoc: get(doctor, 'councilDoc', ''),
  });
  const [errors, setErrors] = useState({});

  const breadcrumbLinks = [
    { text: _('label/prescription_menu/title'), linkTo: '/ajustes/modelos' },
    { text: _('label/prescription_menu/memed') },
  ];

  const dispatch = useDispatch();

  useEffect(() => {
    if (!error) return;

    dispatch(openToast({
      type: 'error',
      text: error,
      close: true,
      timeout: 3000,
    }));
  }, [dispatch, error]);

  const userNamerSplits = useMemo(() => String(get(user, 'name', '')).split(' '), [user]);

  const handleFormChange = useCallback(({ name, value }) => {
    setFormData(f => ({ ...f, [name]: value }));
  }, []);

  const showIntercom = useCallback(() => {
    if (window.Intercom) {
      window.Intercom('show');
    }
  }, []);


  const handleSave = useCallback(() => {
    const { addressState, birthDate, cpf, gender, councilDoc } = formData;

    if (!isPresent(councilDoc)) {
      setErrors({ councilDoc: _('msg/required_field', 'global') });
    } else if (!isPresent(addressState)) {
      setErrors({ addressState: _('msg/required_field', 'global') });
    } else if (!isPresent(cpf)) {
      setErrors({ cpf: _('msg/required_field', 'global') });
    } else if (isPresent(cpf) && !isValidCpf.run(cpf)) {
      setErrors({ cpf: _('msg/invalid_cpf', 'global') });
    } else if (!isPresent(birthDate)) {
      setErrors({ birthDate: _('msg/required_field', 'global') });
    } else if (!isPresent(gender)) {
      setErrors({ gender: _('msg/required_field', 'global') });
    } else {
      setErrors({});
      onSignup({ addressState, birthDate, cpf, gender, councilDoc });
    }
  }, [formData, _, onSignup]);

  const messageContent = () => (
    <React.Fragment>
      <div className="toolbar dashed">
        <h1>{_('label/prescription_menu/memed')}</h1>
      </div>
      <p>Parabéns, você já possui integração com a MEMED pelo Doutore</p>
      <p>Em caso de dúvidas ou problemas, fale com o suporte</p>
      <Button intercom>Falar com Suporte</Button>
    </React.Fragment>
  );

  const pageContent = () => (
    <React.Fragment>
      <div className="toolbar dashed">
        <h1>{_('label/prescription_menu/memed')}</h1>
      </div>

      <div className="tagTip">
        Agora você consegue usar o receituário digital da Memed dentro do Doutore. Preencha os dados abaixo e ative essa opção.
        &nbsp;
        <a href={window.location.href} onClick={showIntercom}>Saiba mais</a>
      </div>
      <form>
        <Input
          required
          type="text"
          name="councilDoc"
          className="field"
          label={_('label/memed_singup/form/council_doc')}
          value={get(formData, 'councilDoc', '')}
          onChange={handleFormChange}
          error={get(errors, 'councilDoc')}
        />
        <SelectBox
          required
          name="addressState"
          label={_('label/memed_singup/form/address_state') + ':'}
          options={brStates}
          onChange={handleFormChange}
          value={get(formData, 'addressState', '')}
          disabled={isLoading}
          error={get(errors, 'addressState', null)}
        />
        <InputMask
          type="cpf"
          name="cpf"
          value={get(formData, 'cpf', '')}
          label={_('label/cpf', 'global', fSufixTwoPoints)}
          required
          onChange={handleFormChange}
          error={get(errors, 'cpf', null)}
        />
        <InputMask
          type="date"
          name="birthDate"
          value={get(formData, 'birthDate', '')}
          label={_('label/birth_date', 'global', fSufixTwoPoints)}
          required
          onChange={handleFormChange}
          error={get(errors, 'birthDate', null)}
        />


        <RadioGroup
          className="col-12"
          uncontrolled
          label={_('label/gender', 'global', fSufixTwoPoints)}
          name="gender"
          defaultType="gender"
          selectGroupName="gender"
          defaultValue={get(formData, 'gender', '')}
          onChange={handleFormChange}
        />
      </form>
      {userNamerSplits.length < 2 && (
        <p className="memedSignup error">Seu cadastro não tem um sobrenome, cadastre o seu nome completo para continuar.</p>
      )}
      <FormFooter onSave={handleSave} isLoading={isLoading} disabledSave={userNamerSplits.length < 2} saveText="Ativar Memed"/>
    </React.Fragment>
  );

  return (
    <PlatformSwitch
      inject
      desktop={() => (
        <div className="appContent settingsPage">
          <aside className="sidebar">
            <SettingsMenu user={user} onLogout={onLogout} permissions={permissions} />
          </aside>
          <section className="mainSection">
            {planIncludeMemed && (
              <div className="centerContent">
                <Breadcrumb items={breadcrumbLinks} />
                {!isPresent(token) ? pageContent() : messageContent()}
              </div>
            )}

            {!planIncludeMemed && <BlockedFeature simpleStyle iconName="lock" />}
          </section>
        </div>
      )}
      mobile={() => (
        <React.Fragment>
          <AppMobileHeader title={_('label/prescription_menu/memed')} backLinkTo="/ajustes/modelos" />
          <div className="appContent settingsPage">
            <section className="mainSection">
              {planIncludeMemed && (
                <div className="centerContent">
                  {!isPresent(token) ? pageContent() : messageContent()}
                </div>
              )}

              {!planIncludeMemed && <BlockedFeature simpleStyle iconName="lock" />}
            </section>
          </div>
          <AppMobileFooter />
        </React.Fragment>
      )}
    />
  );
};


export default MemedSignup;

