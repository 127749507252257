import React from 'react';
import { SettingsMenu, AppMobileHeader, PlatformSwitch, AppMobileFooter } from '@blocks';
import type { User, BundledPermissions } from '@types';
import { withTranslation } from 'react-i18next';
import { createNsTranslator } from '@helpers/helpers';

type Props = {
    children: any,
    user: User,
    onLogout: Function,
    permissions: BundledPermissions,
    t: Function,
};

class UserConfigFormWrapper extends React.Component<Props> {
  render() {
    const _ = createNsTranslator('settings', this.props.t);
    return (
      <PlatformSwitch
        inject
        desktop={() => (
          <div className="appContent settingsPage">
            <aside className="sidebar">
              <SettingsMenu user={this.props.user} onLogout={this.props.onLogout} permissions={this.props.permissions} />
            </aside>
            <section className="mainSection">
              <div className="centerContent">
                {this.props.children}
              </div>
            </section>
          </div>
        )}
        mobile={() => (
          <React.Fragment>
            <AppMobileHeader title={_('label/my_record')} backLinkTo="/ajustes" />
            <div id="userConfigPage" className="appContent settingsPage">
              <section className="mainSection">
                <div className="centerContent">
                  {this.props.children}
                </div>
              </section>
            </div>
            <AppMobileFooter />
          </React.Fragment>
        )}
      />
    );
  }
}

export default withTranslation()(UserConfigFormWrapper);
