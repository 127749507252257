// @flow
import React, { useCallback } from 'react';
import classnames from 'classnames';
import { useTranslationNs } from '@helpers/helpers';
import { Button, Icon } from '@elements';
import './BlockedFeature.scss';

type Props = {
  className?: string,
  customMessage?: string,
  customButton?: string,
  hint?: string | Function,
  hideUpgradeButton?: boolean,
  simpleStyle?: boolean,
  removeBg?: boolean,
  iconSize?: number,
  iconName?: string,
};
const BlockedFeature = ({
  className, customMessage, hideUpgradeButton, customButton, hint, simpleStyle,
  iconSize, iconName, removeBg,
}: Props) => {
  const onBtnClick = useCallback(() => {
    if (window.Intercom) {
      window.Intercom('show');
    }
  }, []);

  const [_] = useTranslationNs('global');

  const cssClasses = classnames(['blockedFeature', className, { simpleStyle, removeBg }]);

  return (
    <div className={cssClasses}>
      <Icon iconName={iconName || 'lock'} className="icon" size={iconSize || 500} />
      <h2>{customMessage || _('label/blocked_feature')}</h2>
      {hint && <h3>{typeof hint === 'function' ? hint() : hint}</h3>}
      {!hideUpgradeButton && <Button className="primary" text={customButton || _('action/blocked_feature_upgrade')} onClick={onBtnClick} />}
    </div>
  );
};

export default BlockedFeature;
