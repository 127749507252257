// @flow
import React from 'react';
import { Button, Input, InputMask, AddressFields } from '@elements';
import { withForm } from '@hocs';
import { createValidator } from '@validator';
import { get } from 'lodash';
import { withTranslation } from 'react-i18next';
import { createNsTranslator, fSufixTwoPoints, handleSubmitEvent, parseUrlParams } from '@helpers/helpers';
import { PlatformSwitch, AppMobileHeader } from '@blocks';
import './SubscriptionAddress.scss';

type Props = {
    formData: Object,
    touchedFields: Object,
    bindValidation: Function,
    handleFormChange: Function,
    handleTouchField: Function,
    onSave: Function,
    isLoading: boolean,
    t: Function,
};

type State = {
  errors: Object,
}

const validator = createValidator()
  .field('name')
  .present(_ => _('error/name'))
  .field('cpf')
  .cpf(_ => _('error/cpf'), false)
  .field('paymentPhone')
  .brPhone(_ => _('error/phone'))
  .field('addressZipcode')
  .brZipCode(_ => _('error/cep'))
  .field('addressStreet')
  .minChars(1, _ => _('error/street'), false, false)
  .field('addressNumber')
  .minChars(1, _ => _('error/addr_num'), false, false)
  .field('addressNeighborhood')
  .minChars(1, _ => _('error/neighborhood'), false, false)
  .field('addressCity')
  .minChars(1, _ => _('error/city'), false, false)
  .field('addressState')
  .minChars(1, _ => _('error/state'), false, false);

class SubscriptionAddress extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      errors: {},
    };
  }

  componentWillMount() {
    this.props.bindValidation(this.handleValidation);
  }

  componentDidMount() {
    if (!window.isCordovaApp) {
      handleSubmitEvent('#subscriptionAddressHandler', this.onSave);
    }
  }

  render() {
    const _ = createNsTranslator('subscription', this.props.t);

    const {
      handleFormChange, handleTouchField, formData, isLoading,
    } = this.props;

    const { errors } = this.state;

    const { backto } = parseUrlParams(window.location.hash) || {};


    const renderContent = (isMobile: boolean) => (
      <div id="addressPage" className="appContent formPage">
        {isMobile && (
        <AppMobileHeader backLinkTo={backto || '/planos'} >
          <div className="headerTitle ">{_('label/subscription_data_title')}</div>
          <div>&nbsp;</div>
        </AppMobileHeader>
        )}
        <section className="mainSection">
          <div className="centerContent">
            <div className="desktopCard">
              {!isMobile && (
              <div className="toolbar">
                <h1>{_('label/subscription_data_title')}</h1>
              </div>
              )}
              <div id="subscriptionAddressHandler">
                <Input
                  disabled={isLoading}
                  name="name"
                  autofocus
                  type="text"
                  label={_('label/name_input', null, fSufixTwoPoints)}
                  onChange={handleFormChange}
                  onBlur={handleTouchField}
                  value={get(formData, 'name', '')}
                  error={get(errors, 'name', null)}
                  required
                />

                <div className="split inputBottom">
                  <InputMask
                    disabled={isLoading}
                    type="cpf"
                    name="cpf"
                    label={_('label/cpf', 'global', fSufixTwoPoints)}
                    onChange={handleFormChange}
                    onBlur={handleTouchField}
                    value={get(formData, 'cpf', '')}
                    error={get(errors, 'cpf', null)}
                    required
                  />
                  <InputMask
                    disabled={isLoading}
                    name="paymentPhone"
                    className="InputPaymentPhone"
                    type="phone"
                    label={_('label/phone', 'global', fSufixTwoPoints)}
                    onChange={handleFormChange}
                    onBlur={handleTouchField}
                    value={get(formData, 'paymentPhone', '')}
                    error={get(errors, 'paymentPhone', null)}
                    required
                  />

                </div>

                <AddressFields
                  required
                  onChange={handleFormChange}
                  onBlur={handleTouchField}
                  addressZipcode={get(formData, 'addressZipcode', '')}
                  addressStreet={get(formData, 'addressStreet', '')}
                  addressNumber={get(formData, 'addressNumber', '')}
                  addressNeighborhood={get(formData, 'addressNeighborhood', '')}
                  addressCity={get(formData, 'addressCity', '')}
                  addressState={get(formData, 'addressState', '')}
                  addressExtra={get(formData, 'addressExtra', '')}
                  errors={this.state.errors}
                />

                <div className="actionsFooter">
                  <Button className="primary" text={_('action/goto_payment')} onClick={this.onSave} isLoading={isLoading} />
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );

    return (
      <PlatformSwitch
        inject
        desktop={() => renderContent(false)}
        mobile={() => renderContent(true)}
      />
    );
  }

  onSave = () => this.handleValidation(true).then(valid => {
    if (valid) {
      const { onSave, formData } = this.props;
      onSave(formData);
    }
  });


  handleValidation = (isSubmit: boolean = false): Promise<boolean> => new Promise((resolve: Function) => {
    const { formData, touchedFields } = this.props;
    const result = validator.validate(formData, touchedFields, isSubmit);
    this.setState({ errors: result.errors }, () => resolve(result.valid));
  });
}


export default withTranslation()(withForm()(SubscriptionAddress));
