import React from 'react';
import { get, map, toArray, first, size } from 'lodash';
import { ModalClickableList } from '@elements';
import type { ActionItem, DoctorsState } from '@types';
import { sort, alphabeticCompare, createNsTranslator } from '@helpers/helpers';
import { withTranslation } from 'react-i18next';
import { DOCTOR_SELECTOR_SEARCH_EDGE } from '@helpers/constants';
import './DoctorSelector.scss';

type Props = {
    isVisible: boolean,
    onClose: Function,
    onSelect: Function,
    doctors: DoctorsState,
    t: Function,
};



const mapDoctorsToClickableList = (doctors, _: Function) => {
  let doctorListItem = toArray(map(doctors, item => ({
    value: get(item, 'id', ''),
    name: get(item, 'name', ''),
    text: get(item, 'nameWithTitle', ''),
  })));

  doctorListItem = sort(doctorListItem, alphabeticCompare('name'));

  return [
    {
      type: 'heading',
      text: _('action/select_one_doctor'),
      className: 'doctorSelectHeading',
    },
    ...doctorListItem,
  ];
};
class DoctorSelector extends React.Component<Props> {
  componentWillReceiveProps(next: Props) {
    if (next.isVisible) {
      const doctors = toArray(get(next.doctors, 'data', {}));
      if (doctors.length === 1) {
        next.onSelect(get(first(doctors), 'id'));
        next.onClose();
      }
    }
  }

  render() {
    const { doctors, isVisible, t } = this.props;
    const _ = createNsTranslator('global', t);
    const doctorsData = get(doctors, ['data'], {});
    const doctorsItems = mapDoctorsToClickableList(doctorsData, _);

    if (!isVisible) {
      return null;
    }

    return (
      <div className="doctorSelector">
        <ModalClickableList
          search={size(doctorsItems) >= DOCTOR_SELECTOR_SEARCH_EDGE}
          items={doctorsItems}
          onListItemClick={this.handleSelectDoctor}
          onClose={this.closeModal}
        />
      </div>
    );
  }

  closeModal = () => this.props.onClose();

  handleSelectDoctor = (item: ActionItem) => {
    const { onClose, onSelect } = this.props;
    if (item.value) {
      onSelect(parseInt(item.value, 10));
      onClose();
    } else {
      onClose();
    }
  };
}

export default withTranslation()(DoctorSelector);
