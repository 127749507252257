// @flow
import React from 'react';
import classnames from 'classnames';
import { useTranslationNs } from '@helpers/helpers';
import './Badge.scss';

type Props = {
  label?: string,
  className?: string,
};

const Badge = ({ label, className }: Props) => {
  const [_] = useTranslationNs('global');

  const cssClasses = classnames(['badge', className]);

  let text;
  switch (className) {
    case 'forecasted':
      text = _('label/status_forecasted');
      break;

    case 'confirmed':
      text = _('label/status_confirmed');
      break;
    case 'canceled':
      text = _('label/status_canceled');
      break;
    case 'health_insurance':
      text = _('label/status_health_insurance');
      break;
    case 'unverified':
      text = _('label/status_unverified');
      break;
    case 'verified':
      text = _('label/status_verified');
      break;
    case 'on_debt':
      text = _('label/status_on_debt');
      break;
    default:
      text = label;
      break;
  }

  return (
    <div className={cssClasses}>{text}</div>
  );
};

// $FlowFixMe
export default React.memo(Badge);
