// @flow
import React from 'react';
import { get } from 'lodash';
import { connect } from 'react-redux';
import { Pricing as PricingView } from '@views';
import type { PlanType, UserAvailablePlans } from '@types';
import { selectPlan, getSubscriptionTrack, getAvailablePlans, getSubscriptionUserInfo } from '@context/subscription';


type Props = {
  dispatch: Function,
  availablePlans: UserAvailablePlans;
  availablePlansLoading: boolean,
  isSelectingPlan: boolean,
  paymentMethod: string,
};

class Pricing extends React.Component<Props> {
  componentWillMount() {
    this.props.dispatch(getSubscriptionTrack('pricing'));
    this.props.dispatch(getSubscriptionUserInfo());
    this.props.dispatch(getAvailablePlans());
  }
  render() {
    return (
      <PricingView
        onSelectPlan={this.handleSelectPlan}
        availablePlans={this.props.availablePlans}
        availablePlansLoading={this.props.availablePlansLoading}
        isSelectingPlan={this.props.isSelectingPlan}
      />
    );
  }

  handleSelectPlan = (plan: PlanType, value: number) => {
    const { dispatch } = this.props;
    dispatch(selectPlan(plan, value, this.props.paymentMethod === 'stripe'));
  };
}


const mapStateToProps = (state) => ({
  availablePlans: get(state, ['context', 'subscription', 'availablePlans']),
  availablePlansLoading: get(state, ['context', 'subscription', 'availablePlansLoading']),
  isSelectingPlan: get(state, ['context', 'subscription', 'isSelectingPlan']),
  paymentMethod: get(state, ['context', 'subscription', 'subscriptionData', 'paymentMethod']),
});

export default connect<any, any, any, any, any, any>(
  mapStateToProps,
)(Pricing);
