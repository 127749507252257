import React from 'react';
import moment from 'moment';
import { startCase, isEqual, map, capitalize } from 'lodash';
import DayPicker from 'react-day-picker';
import { formatDate } from 'react-day-picker/moment';
import { withTranslation } from 'react-i18next';
import { handleMomenti18n } from '@helpers/helpers';
import classNames from 'classnames';
// import 'moment/locale/pt'; // Include the locale utils designed for moment
import { Icon, Button } from '../../elements';
import { ErrorBoundaryView } from '@blocks';
import './CustomDayPicker.scss';

const WEEKDAYS_SHORT_PT = ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb'];
const WEEKDAYS_SHORT_ES = ['Dom', 'Lun', 'Mar', 'Mié', 'Jue', 'Vie', 'Sáb'];

type Props = {
  showButtons?: boolean,
  withOverlay?: boolean,
  advancedMode?: boolean,
  isOpen?: boolean,
  showToday?: boolean,
  selectedDate: moment.Moment,
  onSelectDay: ?Function,
  onMonthChange: ?Function,
  onCancel?: Function,
  onAccept?: Function,
  acceptOnSelect?: boolean,
  i18n: Object,
  disableWeekends?: boolean;
};


type State = {
  yearMode: boolean,
  monthMode: boolean,
  yearStart: number,
  yearEnd: number,
  montSelectYear: number,
};

class CustomDayPicker extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      yearMode: false,
      monthMode: false,
      yearStart: moment().year() - 6,
      yearEnd: moment().year() + 6,
      montSelectYear: moment().year(),
    };
  }

  componentWillReceiveProps(nextProps: Props) {
    handleMomenti18n(nextProps.i18n, moment);
    const nDate = moment(nextProps.selectedDate);
    const pDate = moment(this.props.selectedDate);
    if (nDate.isValid() && pDate.year() !== nDate.year()) {
      this.setState({
        yearStart: nDate.year() - 6,
        montSelectYear: nDate.year(),
        yearEnd: nDate.year() + 6,
      });
    }
  }

  shouldComponentUpdate(nextProps: Props, nextState: State) {
    return !isEqual(this.props, nextProps) || !isEqual(this.state, nextState);
  }

  renderMonthPicker = () => {
    const activeMonth = moment(this.props.selectedDate).month();
    const months = this.props.i18n.language === 'es' ?
      ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'] :
      ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'];


    return (
      <div className="ymPicker" >
        <div className="ymToolbar">
          <div className="btnPag" onClick={this.onPaginateMonthYear(false)}><Icon iconName="arrow-left" /></div>
          <div className="ymLabel clickable" onClick={this.handleCaptionClick('year')}>{this.state.montSelectYear}</div>
          <div className="btnPag" onClick={this.onPaginateMonthYear(true)}><Icon iconName="arrow-right" /></div>
        </div>
        <div className="ymList">
          {map(months, (month: string | null, index: string) => (
            <div key={`month-${index}`} className={`ymItem ${activeMonth === index ? 'active' : ''}`} onClick={this.onSetMonth(index, this.state.montSelectYear)}>{month}</div>
          ))}
        </div>
      </div>
    );
  };

  renderYearPicker = () => {
    const { yearStart, yearEnd } = this.state;

    const years = [];

    for (let y = yearStart; y < yearEnd; y++) {
      years.push(<div key={`year-${y}`} className={`ymItem ${this.state.montSelectYear === y ? 'active' : ''}`} onClick={this.onSetYear(y)}>{y}</div>);
    }

    return (
      <div className="ymPicker" >
        <div className="ymToolbar">
          <div className="btnPag" onClick={this.onPaginateYear(false)}><Icon iconName="arrow-left" /></div>
          <div className="ymLabel">{yearStart} - {yearEnd - 1}</div>
          <div className="btnPag" onClick={this.onPaginateYear(true)}><Icon iconName="arrow-right" /></div>
        </div>
        <div className="ymList">{years}</div>
      </div>
    );
  };

  render() {
    const {
      showButtons,
      withOverlay,
      isOpen,
      acceptOnSelect,
      disableWeekends,
      showToday,
    } = this.props;

    const {
      monthMode, yearMode,
    } = this.state;


    const customNavbar = (data) => (
      <div className="customNavElement">
        <Icon
          className="icon prevButton"
          iconName="arrow-left"
          size={18}
          onClick={this.onChangeMonth.bind(this, data, 'prev')}
        />
        <Icon
          className="icon nextButton"
          iconName="arrow-right"
          size={18}
          onClick={this.onChangeMonth.bind(this, data, 'next')}
        />
      </div>
    );

    const customCaption = (data) => {
      const month = capitalize(moment(data.date).format('MMMM'));
      return (
        <div className="customCaptionElement">
          <span onClick={this.handleCaptionClick('month')}>{`${startCase(formatDate(data.date, 'YYYY', 'pt'))}`}&nbsp;{month}</span>
        </div>
      );
    };

    let selectedDate = moment(this.props.selectedDate);
    selectedDate = selectedDate.isValid() ? selectedDate : moment();

    // calculate selected date
    const currentYear = selectedDate.year();
    const currentMonth = selectedDate.month();

    const modifiers = {
      highlighted: selectedDate.toDate(), // selected date
    };

    const dayPicker = (
      <div className="dayPickerContainer">
        <div className="customDayPickerWrapper">
          <ErrorBoundaryView>
            {!monthMode && !yearMode && (
              <DayPicker
                showOutsideDays
                modifiers={modifiers}
                className="customDayPicker"
                captionElement={customCaption}
                disabledDays={disableWeekends ? { daysOfWeek: [0, 6] } : undefined}
                navbarElement={customNavbar}
                firstDayOfWeek={1}
                month={new Date(currentYear, currentMonth)} // month that is currentlly shown
                onDayClick={this.handleSelectDay.bind(this)}
                weekdaysShort={this.props.i18n.language === 'es' ? WEEKDAYS_SHORT_ES : WEEKDAYS_SHORT_PT}
                locale={this.props.i18n.language === 'es' ? 'es' : 'pt'}
              />
            )}
          </ErrorBoundaryView>
          {!monthMode && yearMode && this.renderYearPicker()}
          {monthMode && !yearMode && this.renderMonthPicker()}
          {showButtons &&
          <div className={classNames('actionButtonsWrapper', { spaceBt: !acceptOnSelect || showToday })}>
            {showToday && (
              <Button
                onClick={this.onTodayClick}
                className={classNames('todayButton', { active: moment().format('YYYY-MM-DD') === moment(selectedDate).format('YYYY-MM-DD') })}
                text="Hoje"
              />
            )}
            <Button
              onClick={this.onCancel.bind(this)}
              className={`cancelButton ${!acceptOnSelect ? '' : 'fullWidth'}`}
              text="Cancelar"
            />
            {!acceptOnSelect &&
              <Button
                onClick={this.onAccept.bind(this)}
                className="acceptButton primary"
                text="Ok"
              />
            }
          </div>
        }
        </div>
      </div>
    );

    let element;
    if (withOverlay) {
      element = isOpen ? (
        <div className="dayPickerOverlay">
          { dayPicker }
        </div>
      ) : (
        null
      );
    } else {
      element = dayPicker;
    }

    return element;
  }


  onChangeMonth = (data, direction) => {
    let month;
    if (direction === 'prev') {
      data.onPreviousClick();
      month = data.previousMonth;
    } else {
      data.onNextClick();
      month = data.nextMonth;
    }

    if (this.props.onMonthChange) {
      this.props.onMonthChange(month);
    }
    return data;
  };

  handleSelectDay = (date) => {
    if (this.props.onSelectDay) {
      let nDate = date;
      const weekDay = moment(date).isoWeekday();
      if (this.props.disableWeekends) {
        if (weekDay === 6) {
          nDate = moment(date).isoWeekday(5);
        } else if (weekDay === 7) {
          nDate = moment(date).isoWeekday(5);
        }
      }
      this.props.onSelectDay(nDate);
      if (this.props.acceptOnSelect) {
        this.props.onAccept();
      }
    }
  };

  onCancel = () => {
    if (this.props.onCancel) {
      this.props.onCancel();
    }
  };

  handleCaptionClick = (mode: 'year' | 'month') => () => {
    if (!this.props.advancedMode) return;
    if (mode === 'year') {
      this.setState({ yearMode: true, monthMode: false });
    } else {
      this.setState({ yearMode: false, monthMode: true });
    }
  };

  setCalendarMode = () => this.setState({ yearMode: false, monthMode: false });

  onSetYear = (year: number) => () => {
    this.setState({ montSelectYear: year, yearMode: false, monthMode: true });
  };

  onPaginateYear = (next: boolean) => () => {
    if (next) {
      this.setState({
        yearStart: this.state.yearStart + 12,
        yearEnd: this.state.yearStart + 24,
      });
    } else {
      const y = this.state.yearStart - 12;
      this.setState({
        yearStart: y,
        yearEnd: y + 12,
      });
    }
  };

  onPaginateMonthYear = (next: boolean) => () => {
    if (next) {
      this.setState({ montSelectYear: this.state.montSelectYear + 1 });
    } else {
      this.setState({ montSelectYear: this.state.montSelectYear - 1 });
    }
  };

  onSetMonth = (month: number, year: number) => () => {
    const dt = moment(this.props.selectedDate)
      .set('year', year)
      .set('month', month)
      .toDate();

    if (this.props.onMonthChange) {
      this.props.onMonthChange(dt);
    }

    this.setCalendarMode();
  };

  onAccept = () => {
    if (this.props.onAccept) {
      this.props.onAccept();
    }
  };

  onTodayClick = () => {
    if (this.props.onSelectDay) {
      let nDate = moment();
      const weekDay = moment().isoWeekday();
      if (this.props.disableWeekends) {
        if (weekDay === 6) {
          nDate = moment().isoWeekday(5);
        } else if (weekDay === 7) {
          nDate = moment().isoWeekday(5);
        }
      }
      this.props.onSelectDay(nDate);
      if (this.props.acceptOnSelect) {
        this.props.onAccept();
      }
    }
  };
}

export default withTranslation()(CustomDayPicker);
