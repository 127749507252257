/* eslint-disable no-console */
// @flow
import { get } from 'lodash';
import axios from 'axios';
import type { Patient, Record } from '@types';
import { isPresent } from './helpers';
import moment from 'moment';

// Setting up features for the prescription functionality
const prescriptionFeatures = {
  // Various configuration flags for the prescription features
  // Each key represents a specific feature and is set to either true or false
  // These settings control various aspects of prescription behavior
  alwaysSendSMS: false,
  deletePatient: false,
  historyPrescription: true,
  newPrescription: true,
  optionsPrescription: true,
  removePatient: false,
  editPatient: false,
  setPatientAllergy: false,
  autocompleteExams: true,
  autocompleteIndustrialized: true,
  autocompleteManipulated: true,
  autocompleteCompositions: true,
  autocompletePeripherals: true,
  copyMedicalRecords: false,
  conclusionModalEdit: false,
  buttonClose: false,
  newFormula: true,
};

// Type definition for parameters required to create a Memed prescription
type CreateMemedPrescriptionParams = {
  patient: Patient;
  record: Record;
  saveCallBack: Function;
  onDelete: Function;
  onOpenMemed?: Function;
}

// Definition of the MemedPrescription class
class MemedPrescription {
  constructor({
    patient,
    saveCallBack,
    onDelete,
    onOpenMemed,
  }: CreateMemedPrescriptionParams) {
    // Early return if prerequisites are not met
    if (!window.MdHub || !isPresent(get(patient, 'name'))) return;

    const birthday = get(patient, 'birthdate', null);
    // Formatting patient's birthdate to DD/MM/YYYY
    const formattedBirthday = birthday
      ? moment(birthday, 'YYYY-MM-DD').format('DD/MM/YYYY')
      : '';

    // Determine if cpf is present
    const patientCpf = get(patient, 'cpf', null);
    const patientWithoutCpf = !patientCpf;

    // Determine the endereco
    const addressStreet = get(patient, 'addressStreet', '');
    const addressNumber = get(patient, 'addressNumber', '');
    const patientAddress = addressStreet ? `${addressStreet}, ${addressNumber}` : '';

    // Constructing patient data object for Memed prescription
    const patientData = {
      // Mapping patient's details to required fields for Memed prescription
      idExterno: 'patient::' + get(patient, 'id'),
      nome: get(patient, 'name', ''),
      cpf: patientCpf,
      withoutCpf: patientWithoutCpf,
      data_nascimento: formattedBirthday,
      endereco: patientAddress,
      cidade: get(patient, 'addressCity', ''),
      telefone: get(patient, 'mobilePhone', ''),
    };

    // Function to handle saved prescription event
    const prescricaoSalvaEvent = (prescriptionData) => {
      // Logic to handle the event when a prescription is saved
      const { prescricao: prescription } = prescriptionData;
      if (!get(window, ['saveMemed', String(prescription.id)])) {
        saveCallBack(prescription.id, prescription);
        if (!window.saveMemed) {
          // disallow multiple event firing
          window.saveMemed = {};
        }
        window.saveMemed[prescription.id] = true;
      }
    };

    // Accessing global MdHub object
    const { MdHub } = window;

    // Sending commands to the Memed platform via MdHub
    // These commands set up various aspects of the prescription platform like features and patient data
    // Nested command sends to ensure sequential execution
    console.debug('%%% Sending commands to plataforma.prescricao');
    try {
      MdHub.command.send('plataforma.prescricao', 'setFeatureToggle', prescriptionFeatures).then(() => {
        console.debug('%%% Feature toggle set with data:', prescriptionFeatures);
        MdHub.command.send('plataforma.prescricao', 'setPaciente', patientData).then(() => {
          console.debug('%%% Patient data set. This step always works. Data:', patientData);
          // MdHub.module.show("plataforma.prescricao");
          MdHub.command.send('plataforma.prescricao', 'newPrescription').then(() => {
            console.debug('%%% New prescription created. Sometimes this step fails');
            MdHub.event.add('prescricaoExcluida', (id: number) => {
              console.debug(`Prescription deleted: ${id}`);
              onDelete(id);
            });
            MdHub.event.add('prescricaoImpressa', prescricaoSalvaEvent);
            console.debug('%%% Prescription saved event added');
            window.MdHub.module.show('plataforma.prescricao');
            console.debug('%%% Memed prescription module shown');
            if (onOpenMemed) {
              console.debug('%%% onOpenMemed callback executed');
              onOpenMemed();
            }
          });
        });
      });
    } catch (error) {
      console.error('An unexpected error occurred outside the promise chain of plataforma.prescricao:', error);
    }

    // Adding event listener to handle module hide event
    MdHub.event.add(
      'core:moduleHide',
      ({ moduleName }) => {
        if (moduleName === 'plataforma.prescricao') {
          // Logic to execute when the prescription module is hidden
          MdHub.command.send('plataforma.prescricao', 'setPaciente', {});
          MdHub.event.remove('prescricaoImpressa');
        }
      },
    );
  }
  // Function to show the prescription module
  showModule = () => (window.MdHub ? window.MdHub.module.show('plataforma.prescricao') : null);
  // Function to hide the prescription module
  hideModule = () => (window.MdHub ? window.MdHub.module.hide('plataforma.prescricao') : null);
}

// Function to create a new MemedPrescription instance
export const createMemedPrescription = (params: CreateMemedPrescriptionParams) => new MemedPrescription(params);

// Function to retrieve a Memed prescription by its ID
export const getMemedPrescription = (id: number, memedToken: string): Promise<any> => new Promise((resolve, reject) => {
  // Constructing the endpoint URL for the API call
  const endpoint = `${window.MEMED_API_BASE_URL}/v1/prescricoes/${id}?token=${memedToken}`;

  // Creating an axios instance with custom configuration
  const api = axios.create({
    timeout: 10000,
    headers: { accept: 'application/json' },
  });

  // Making a GET request to the API and resolving or rejecting the promise based on the response
  api
    .get(endpoint)
    .then(response => {
      // Resolving with relevant data extracted from the response
      resolve({
        // Extracting and formatting necessary data from the API response
        date: get(response, ['data', 'data', 'attributes', 'data']),
        time: get(response, ['data', 'data', 'attributes', 'horario']),
        medications: get(response, ['data', 'data', 'attributes', 'medicamentos']),
      });
    })
    .catch(reject); // Rejecting the promise if the API call fails
});
/* eslint-enable no-console */
