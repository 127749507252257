// @flow
import React from 'react';
import type { Node } from 'react';
import { Link } from 'react-router-dom';
import { map } from 'lodash';
import './Breadcrumb.scss';

type Props = {
  children?: Node,
  backLink?: string,
  items?: Array<{
    text: string,
    linkTo?: string,
  }>,
};

export default class Breadcrumb extends React.Component<Props> {
  render() {
    const {
      children,
      backLink,
      items,
    } = this.props;

    const linkItems = [];

    if (backLink) {
      linkItems.push(<li key={backLink} className="backLink"><Link to={backLink} >&#8672; Voltar</Link></li>);
    } else if (items) {
      map(items, (item) => {
        if (item.linkTo) {
          linkItems.push(<li key={`${item.text}-link`}><Link to={item.linkTo}>{item.text}</Link></li>);
          linkItems.push(<li key={`${item.text}-sp-char`}>&#8674;</li>);
        } else {
          linkItems.push(<li key={`${item.text}-link`}>{item.text}</li>);
        }
      });
    }

    return (
      <div className="breadcrumb">
        <ul>
          { (linkItems.length > 0) ? linkItems : children }
        </ul>
      </div>
    );
  }
}
