// @flow
import React from 'react';
import { get } from 'lodash';
import { connect } from 'react-redux';
import { logout } from '@context/user';
import { uploadClinicLogo, deleteClinicLogo } from '@context/clinics';
import { mapBundledPermission } from '@helpers/connect';
import { PrintClinicLogo as PrintClinicLogoView } from '@views';
import type { User, BundledPermissions } from '@types';


type Props = {
  user: User,
  dispatch: Function,
  permissions: BundledPermissions,
  clinicLogo: ?string,
  isUploadingLogo: boolean,
  isDeletingLogo: boolean,
  clinicLogoId: number,
};

class PrintConfig extends React.Component<Props> {
  render() {
    const {
      user, clinicLogo, isUploadingLogo, isDeletingLogo,
    } = this.props;

    return (
      <PrintClinicLogoView
        user={user}
        onLogout={this.handleLogout}
        permissions={this.props.permissions}
        onUploadLogo={this.handleLogoUpload}
        onRemoveLogo={this.handleRemoveLogo}
        clinicLogo={clinicLogo}
        isLoading={isUploadingLogo}
        isDeletingLogo={isDeletingLogo}
      />
    );
  }
  handleLogout = () => this.props.dispatch(logout());

  handleLogoUpload = (file: Object, sizes: Object) => {
    if (this.props.clinicLogo) {
      this.props.dispatch(deleteClinicLogo(this.props.clinicLogoId, () => {
        this.props.dispatch(uploadClinicLogo(file, file.type, sizes));
      }));
    } else {
      this.props.dispatch(uploadClinicLogo(file, file.type, sizes));
    }
  };

  handleRemoveLogo = () => {
    this.props.dispatch(deleteClinicLogo(this.props.clinicLogoId));
  };
}

const mapStateToProps = (state) => ({
  user: state.context.user,
  permissions: mapBundledPermission(state),
  clinicLogo: get(state, ['context', 'clinics', 'activeClinic', 'clinicLogo', 'url'], null),
  clinicLogoId: get(state, ['context', 'clinics', 'activeClinic', 'clinicLogo', 'id'], null),
  isUploadingLogo: get(state, ['context', 'clinics', 'isUploadingLogo'], false),
  isDeletingLogo: get(state, ['context', 'clinics', 'isDeletingLogo'], false),
});

export default connect<any, any, any, any, any, any>(
  mapStateToProps,
)(PrintConfig);
