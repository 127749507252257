export default {
  'months/january': 'Enero',
  'months/february': 'Febrero',
  'months/march': 'Marzo',
  'months/april': 'Abril',
  'months/may': 'Mayo',
  'months/june': 'Junio',
  'months/july': 'Julio',
  'months/august': 'Agosto',
  'months/september': 'Septiembre',
  'months/october': 'Octubre',
  'months/november': 'Noviembre',
  'months/december': 'Diciembre',
  'weekday/monday': 'Lunes',
  'weekday/tuesday': 'Martes',
  'weekday/wednesday': 'Miercoles',
  'weekday/thursday': 'Jueves',
  'weekday/friday': 'Viernes',
  'weekday/saturday': 'Sabado',
  'weekday/sunday': 'Domingo',
  'payments_type/money': 'Efectivo',
  'payments_type/check': 'Cheque',
  'payments_type/credit_card': 'Tarjeta de Crédito',
  'payments_type/debit_card': 'Tarjeta de Débito',
  'payments_type/bank_transfer': 'Transferencia Bancaria',
  'profession/medic': 'Médico',
  'profession/dentist': 'Dentista',
  'profession/dental_surgeon': 'Cirujano Dentista',
  'profession/bucomaxillofacial_surgeon': 'Cirujano Bucomaxilofacial',
  'profession/general_clinical_dentist': 'Dentista Clínico General',
  'profession/aesthetic_dentistry': 'Odontología Estética',
  'profession/endodontist': 'Endodoncista',
  'profession/implantodontist': 'Implantólogo',
  'profession/pediatric_dentistry': 'Odontopediatra',
  'profession/orthodontist': 'Ortodoncista',
  'profession/periodontist': 'Periodoncista',
  'profession/protester': 'Protesista',
  'profession/general_practitioner': 'Médico General',
  'profession/allergist_and_immunologist': 'Alergista e Inmunólogo',
  'profession/anesthesiologist': 'Anestesiólogo',
  'profession/angiologist': 'Angiólogo',
  'profession/cardiologist': 'Cardiólogo',
  'profession/cardiovascular_surgeon': 'Cirujano Cardiovascular',
  'profession/head_and_neck_surgeon': 'Cirujano de Cabeza y Cuello',
  'profession/digestive_system_surgeon': 'Cirujano del Aparato Digestivo',
  'profession/general_surgeon': 'Cirujano General',
  'profession/pediatric_surgeon': 'Cirujano Pediátrico',
  'profession/plastic_surgeon': 'Cirujano Plástico',
  'profession/thoracic_surgeon': 'Cirujano Torácico',
  'profession/general_clinic': 'Médico Clínico',
  'profession/coloproctologist': 'Coloproctólogo',
  'profession/dermatologist': 'Dermatólogo',
  'profession/endocrinologist': 'Endocrinólogo',
  'profession/physiatrist': 'Fisiatra',
  'profession/gastroenterologist': 'Gastroenterólogo',
  'profession/geneticist': 'Genetista',
  'profession/geriatra': 'Geriatra',
  'profession/gynecologist': 'Ginecólogo y Obstetra',
  'profession/hematologist': 'Hematólogo',
  'profession/homeopath': 'Homeópata',
  'profession/infectologist': 'Infectólogo',
  'profession/mastologist': 'Mastólogo',
  'profession/sports_doctor': 'Médico del Deporte',
  'profession/doctor_of_labor': 'Médico Laboral',
  'profession/nephrologist': 'Nefrólogo',
  'profession/neurosurgeon': 'Neurocirujano',
  'profession/neurologist': 'Neurólogo',
  'profession/nutrologo': 'Nutricionista',
  'profession/ophthalmologist': 'Oftalmólogo',
  'profession/oncologist': 'Oncólogo',
  'profession/orthopaedist_and_traumatologist': 'Ortopedista y Traumatólogo',
  'profession/otorhinolaryngologist': 'Otorrinolaringólogo',
  'profession/pediatrician': 'Pediatra',
  'profession/pneumologist': 'Neumólogo',
  'profession/psychiatrist': 'Psiquiatra',
  'profession/radiologist': 'Radiólogo',
  'profession/rheumatologist': 'Reumatólogo',
  'profession/urologist': 'Urólogo',
  'profession/other_profession': 'Otra Profesión',
  'profession/acupuncturist': 'Acupunturista',
  'profession/social_worker': 'Trabajador Social',
  'profession/physical_educator': 'Educador Físico',
  'profession/physiotherapist': 'Fisioterapeuta',
  'profession/speech_language_pathologist': 'Fonoaudiólogo',
  'profession/beautician': 'Esteticista',
  'profession/nutritionist': 'Nutricionista',
  'profession/psychologist': 'Psicólogo',
  'profession/quiropraxista': 'Quiropráctico',
  'profession/nursing_technician': 'Enfermero',
  'profession/oral_health_technician': 'Técnico en Salud Bucal',
  'profession/occupational_therapist': 'Terapeuta Ocupacional',
  'profession/veterinarian': 'Veterinario',
  'profession/natural_therapist': 'Terapeuta Natural',
  'profession/biomedical': 'Biomédico',
  'profession/physiologist': 'Fisiólogo',
  'profession/pharmaceutical': 'Farmacéutico',
  'profession/psychopedagogue': 'Psicopedagogo',
  'profession/secretary_or_administrator': 'Secretario/Administrador',
  'colors/blue': 'Azul',
  'colors/green': 'Verde',
  'colors/orange': 'Naranja',
  'colors/brown': 'Café',
  'colors/pink': 'Rosa',
  'colors/violet': 'Morado',
  'colors/yellow': 'Amarillo',
  'colors/dark_blue': 'Azul Oscuro',
  'colors/dark_green': 'Verde Oscuro',
  'colors/dark_orange': 'Naranja Oscuro',
  'colors/dark_brown': 'Café Oscuro',
  'colors/dark_pink': 'Rosa Oscuro',
  'colors/dark_violet': 'Morado Oscuro',
  'colors/dark_yellow': 'Amarillo Oscuro',
  'colors/grey': 'Gris',
  'martial_status/not_married': 'Soltero',
  'martial_status/married': 'Casado',
  'martial_status/separate': 'Separado',
  'martial_status/divorced': 'Divorciado',
  'martial_status/widower': 'Viudo',
  'martial_status/stable_union': 'Unión Estable',
  'periods/future': 'Futuro',
  'periods/today': 'Hoy',
  'periods/yesterday': 'Ayer',
  'periods/null': 'Este Mes',
  'periods/last_month': 'Mes Pasado',
  'periods/custom_month': 'Elija el Mes',
  'periods/custom_date': 'Elija la Fecha',
};
