// @flow
import React from 'react';
import { Link } from 'react-router-dom';
import { Icon } from '@elements';
import './NotFound.scss';

export default class NotFound extends React.Component<any> {
  render() {
    return (
      <div className="pageNotFound">
        <Icon iconName="info" />
        <h1>
          Página não encontrada
          <br />
          (erro 404)
        </h1>
        <Link to='/'>Voltar</Link>
      </div>
    );
  }
}
