// @flow
import type { ComponentType } from 'react';
import type { PlanInclude, PermissionTo, BundledPermissions } from '@types';
// $FlowFixMe
import { shallowEqual, useSelector, connect } from 'react-redux';
import { get } from 'lodash';
import { isPresent } from '@helpers/helpers';

const utils = {
  getPlanInclude: (state: Object, key: string, stateIscontext: boolean) =>
    get(state, [...(stateIscontext ? [] : ['context']), 'user', 'advancedData', 'planInclude', key], false),

  getPermissionTo: (state: Object, key: string) =>
    get(state, ['context', 'user', 'advancedData', 'permissionTo', key], false),
};

export const mapPlanIncludes = (state: Object, stateIscontext: boolean = false): PlanInclude => ({
  financial: utils.getPlanInclude(state, 'financial', stateIscontext),
  intercom: utils.getPlanInclude(state, 'intercom', stateIscontext),
  basicWidgets: utils.getPlanInclude(state, 'basicWidgets', stateIscontext),
  newFeatures: utils.getPlanInclude(state, 'newFeatures', stateIscontext),
  examsDrugsDocuments: utils.getPlanInclude(state, 'examsDrugsDocuments', stateIscontext),
  customForms: utils.getPlanInclude(state, 'customForms', stateIscontext),
  advancedWidgets: utils.getPlanInclude(state, 'advancedWidgets', stateIscontext),
  attachments: utils.getPlanInclude(state, 'attachments', stateIscontext),
  inviteDoctors: utils.getPlanInclude(state, 'inviteDoctors', stateIscontext),
  memed: isPresent(get(state, ['context', 'user', 'advancedData', 'memedToken'], '')),
  newMemed: utils.getPlanInclude(state, 'newMemed', stateIscontext),
  timeline: utils.getPlanInclude(state, 'timeline', stateIscontext),
  indicators: utils.getPlanInclude(state, 'indicators', stateIscontext),
  whatsappConfimation: utils.getPlanInclude(state, 'whatsappConfimation', stateIscontext),
});

export const mapPermissionTo = (state: Object): PermissionTo => ({
  patientsListFilterDoctor: utils.getPermissionTo(state, 'patientsListFilterDoctor'),
  patientFormSelectDoctor: utils.getPermissionTo(state, 'patientFormSelectDoctor'),
  reportsSelectDoctor: utils.getPermissionTo(state, 'reportsSelectDoctor'),
  backup: utils.getPermissionTo(state, 'backup'),
  reports: utils.getPermissionTo(state, 'reports'),
  smsReports: utils.getPermissionTo(state, 'smsReports'),
  singleUser: get(state, ['context', 'clinics', 'activeClinic', 'singleUser']),
  singleDoctor: get(state, ['context', 'clinics', 'activeClinic', 'singleDoctor']),
  experimentalFeatures: get(state, ['context', 'clinics', 'activeClinic', 'experimentalFeatures']),
  showRooms: get(state, ['context', 'clinics', 'activeClinic', 'showRooms']),
  agendaDoctorFilter: utils.getPermissionTo(state, 'agendaDoctorFilter'),
  doctorShowSignature: utils.getPermissionTo(state, 'doctorShowSignature'),
  editAllServicesTemplates: utils.getPermissionTo(state, 'editAllServicesTemplates'),
  advancedSettings: utils.getPermissionTo(state, 'advancedSettings'),
  appointmentDoctorSelect: utils.getPermissionTo(state, 'appointmentDoctorSelect'),
  patientsListShowDoctorName: utils.getPermissionTo(state, 'patientsListShowDoctorName'),
  dashboard: utils.getPermissionTo(state, 'dashboard'),
  indicators: utils.getPermissionTo(state, 'indicators'),
});

// Hook
export const usePermissionTo = ():PermissionTo => useSelector(mapPermissionTo, shallowEqual);
// HOC
export const withPermissionTo = (Component: ComponentType<*>) => connect<any, any, any, any, any, any>(mapPermissionTo)(Component);

export const mapBundledPermission = (state: Object): BundledPermissions => ({
  permissionTo: mapPermissionTo(state),
  planInclude: mapPlanIncludes(state),
  subscriptionStatus: get(state, ['context', 'user', 'advancedData', 'subscriptionStatus'], 'active'),
  blocked: get(state, ['context', 'user', 'advancedData', 'blocked'], false),
  isManager: get(state, ['context', 'user', 'advancedData', 'manager'], false),
  isDependent: get(state, ['context', 'user', 'advancedData', 'isDependent'], false),
  dashboard: get(state, ['context', 'user', 'advancedData', 'permissionTo', 'dashboard'], false),
});
