// @flow
import React from 'react';
import { connect } from 'react-redux';
import { MessageFormModal } from '../../components/views';
import { actions } from '../../redux/modules/context/modals';
import type { Dispatch } from '../../types';

type Props = {
  dispatch: Function,
};

class MessageFormModalContainer extends React.Component<Props> {
  render() {
    return (
      <MessageFormModal onClose={this.closeModal} />
    );
  }

  closeModal = () => {
    this.props.dispatch(actions.closeActiveModal());
  };
}

const mapDispatchToProps = (dispatch: Dispatch) => ({ dispatch });

export default connect<any, any, any, any, any, any>(
  null,
  mapDispatchToProps,
)(MessageFormModalContainer);
