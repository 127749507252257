// @flow
import React from 'react';
import { get, map, isEqual, filter } from 'lodash';
import type { Record, Attachment as AttachmentType, AttachmentsState } from '@types';
import moment from 'moment';
import { isPresent, sort, numberCompare, createNsTranslator, handleMomenti18n } from '@helpers/helpers';
import { PDFPreview, AttachmentPreview } from '@elements';
import { FileAttachment, ImagesPreview } from '@blocks';
import { withTranslation } from 'react-i18next';
import './Attachment.scss';

type Props ={
    record: Record,
    attachments: Array<AttachmentType>,
    allAttachments: AttachmentsState,
    onDeleteAttachment: Function,
    onDownloadAttachment: Function,
    t: Function,
    i18n: Object,
}

type State = {
    isLightboxOpen: boolean,
    currentImageId: number | null,
    pdfPreviewDoc: any,
    pdfName: any,
}

const fileAttachmentDropdownList = (_: Function) => [
  { text: _('action/download'), action: 'download' },
  { text: _('action/delete_file'), action: 'delete' },
];
class Attachment extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isLightboxOpen: false,
      currentImageId: null,
      pdfPreviewDoc: null,
      pdfName: '',
    };
  }

  shouldComponentUpdate(next: Props, nState: State) {
    return !isEqual(this.props.record, next.record)
      || !isEqual(this.props.attachments, next.attachments)
      || !isEqual(this.state, nState)
      || !isEqual(this.props.allAttachments, next.allAttachments);
  }

  render () {
    const { record, attachments, allAttachments } = this.props;
    const { isLightboxOpen, currentImageId } = this.state;

    handleMomenti18n(this.props.i18n, moment);

    const _ = createNsTranslator('timeline', this.props.t);

    const date = moment(get(record, 'date'));

    const files = filter(attachments, item => !isPresent(get(item, 'thumbUrl')));

    const recordImagesIds = get(record, ['details', 'imageAttachments']);
    const recordImages = sort(filter(attachments, (attachment) => (
      recordImagesIds.includes(attachment.id)
    )), numberCompare('id'));

    const thumbs = this.getThumbs();

    const thumbItems = map<any, any>(thumbs, (thumb: AttachmentType) => (
      <AttachmentPreview
        img={thumb}
        alt={thumb.name}
        onClick={this.handleOpenLightbox(thumb.id)}
      />
    ));

    const filesItems = map<any, any>(files, (file: AttachmentType) => (
      <FileAttachment
        file={file}
        fileAttachmentDropdownList={fileAttachmentDropdownList(_)}
        onSelectFileAction={this.onSelectFileAction}
        showPDF={this.handleShowPdfDoc}
      />
    ));
    return (
      <div className="attachmentViewItem">
        {this.state.pdfPreviewDoc && (
        <PDFPreview
          docUrl={this.state.pdfPreviewDoc}
          onClose={this.closePdfDoc}
          pdfName={this.state.pdfName}
        />
        )}

        {isLightboxOpen && Boolean(recordImagesIds) && (
          <ImagesPreview
            recordId={record.id}
            first={currentImageId}
            images={recordImages}
            onClose={this.handleCloseLightbox}
            onRequestDownloadImage={this.onRequestDownloadImage}
            isDownloading={allAttachments.isDownloading}
            downloadUrls={allAttachments.downloadUrls}
            isEdit={false}
            onDelete={() => {}}
            onDisplayAttachmentFileName={() => {}}
          />
        )}
        <div className="attachmentBody">
          <div className="attachmentThumbs">{thumbItems}</div>
          <br />
          <div className="fileAttachments">{filesItems}</div>
        </div>
        <div className="attachinfo">
          <span className="attachmentDate"> {date.isValid() ? date.format(_('format/date_01')) : 'Invalid Date'}</span>
          <span className="attachmentDoctor"> {get(record, 'userName', '')}</span>
        </div>
      </div>
    );
  }

  getThumbs = () => sort(filter(this.props.attachments, item => isPresent(get(item, 'thumbUrl'))), numberCompare('id'));

  onSelectFileAction = (file: AttachmentType) => (item: Object) => {
    const { onDeleteAttachment, record } = this.props;
    if (item.action === 'download') {
      const win = window.open(file.downloadUrl, '_blank');
      win.focus();
      this.props.onDownloadAttachment(record.id, file.id);
    }

    if (item.action === 'showPDF') {
      this.handleShowPdfDoc(file.downloadUrl, file.name)();
    }

    if (item.action === 'delete') {
      onDeleteAttachment(record.id, file.id);
    }
  };

  onDeleteAttachment = (file: AttachmentType) => () => {
    const { onDeleteAttachment, record } = this.props;
    onDeleteAttachment(record.id, file.id);
  };

  closePdfDoc = () => this.setState({ pdfPreviewDoc: null, pdfName: '' });

  handleShowPdfDoc = (pdfPreviewDoc?: string | null, pdfName: string) => () => {
    this.setState({ pdfPreviewDoc, pdfName });
  };

  handleCloseLightbox = () => {
    this.setState({ isLightboxOpen: false });
  };

  handleOpenLightbox = (id: number) => () => {
    this.setState({ isLightboxOpen: true, currentImageId: id });
  }

  onRequestDownloadImage = (id: number, callback: Function) => {
    const { onDownloadAttachment, record } = this.props;
    onDownloadAttachment(record.id, id, callback, true);
  }
}

export default withTranslation()(Attachment);
