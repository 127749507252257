// @flow
import React from 'react';
import { connect } from 'react-redux';
import { get } from 'lodash';
import { logout } from '@context/user';
import { mapBundledPermission, mapPlanIncludes } from '@helpers/connect';
import { ExamsPrescriptionsTemplatesList as ExamsPrescriptionsTemplatesListView } from '../components/views';
import { examsPrescriptionsTemplateActions } from '../redux/modules/context/examsPrescriptionsTemplates';
import type { Dispatch, ExamsPrescriptionsTemplatesState, User, BundledPermissions, PlanInclude } from '../types';

type Props = {
  examsPrescriptionsTemplates: ExamsPrescriptionsTemplatesState,
  user: User,
  dispatch: Function,
  permissions: BundledPermissions,
  planInclude: PlanInclude,
};

class ExamsPrescriptionsTemplatesList extends React.Component<Props> {
  componentWillMount() {
    this.props.dispatch(examsPrescriptionsTemplateActions.getExamsPrescriptionsTemplates(true));
  }

  render() {
    const { user, examsPrescriptionsTemplates, planInclude } = this.props;

    return (
      <ExamsPrescriptionsTemplatesListView
        user={user}
        examsPrescriptionsTemplates={examsPrescriptionsTemplates}
        onLogout={this.handleLogout}
        permissions={this.props.permissions}
        featureEnabled={planInclude.examsDrugsDocuments}
      />
    );
  }
  handleLogout = () => this.props.dispatch(logout());
}

const mapStateToProps = (state) => ({
  examsPrescriptionsTemplates: get(state.context, ['examsPrescriptionsTemplates'], {}),
  user: state.context.user,
  permissions: mapBundledPermission(state),
  planInclude: mapPlanIncludes(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({ dispatch });

export default connect<any, any, any, any, any, any>(
  mapStateToProps,
  mapDispatchToProps,
)(ExamsPrescriptionsTemplatesList);
