// @flow
import React from 'react';
import { connect } from 'react-redux';
import { router } from '@router';
import { receiptActions } from '@context/receipts';
import { ReportsReceipts as ReportsReceiptsView } from '@views';
import { get } from 'lodash';
import { mapPlanIncludes, mapPermissionTo } from '@helpers/connect';
import type { Dispatch, DoctorsState, ReceiptsState, PlanInclude, PermissionTo } from '@types';

type Props = {
  dispatch: Function,
  doctors: DoctorsState,
  receipts: ReceiptsState,
  defaultDoctorId: number,
  isManager: boolean,
  planInclude: PlanInclude,
  permissionTo : PermissionTo,
};

class ReportsReceipts extends React.Component<Props> {
  render() {
    const {
      doctors, receipts, defaultDoctorId, isManager, planInclude, permissionTo,
    } = this.props;

    return (
      <ReportsReceiptsView
        onGetReceipts={this.handleGetReceipts}
        receipts={receipts}
        doctors={doctors}
        onItemClick={this.handleItemClick}
        onDownlodReport={this.handleDownloadReport}
        defaultDoctorId={defaultDoctorId}
        isManager={isManager}
        isReportBlocked={!permissionTo.reports}
        isFeatureEnabled={planInclude.financial}
        reportsSelectDoctor={permissionTo.reportsSelectDoctor}
      />
    );
  }

  handleGetReceipts = (startDate: string, endDate: string, doctorId: number | null) => {
    const { dispatch } = this.props;
    dispatch(receiptActions.getReceipts(startDate, endDate, doctorId));
  };

  handleItemClick = (id: number) => {
    const { dispatch } = this.props;
    dispatch(router.pushPath('patientFinancial', { id }));
  };

  handleDownloadReport = (startDate: string, endDate: string, doctorId: number | null) => {
    const { dispatch } = this.props;
    dispatch(receiptActions.getReceiptsReport(startDate, endDate, doctorId));
  };
}

const mapStateToProps = (state) => ({
  doctors: state.context.doctors,
  receipts: state.context.receipts,
  defaultDoctorId: get(state, ['context', 'user', 'advancedData', 'doctorId']),
  isManager: get(state, ['context', 'user', 'advancedData', 'manager']),
  planInclude: mapPlanIncludes(state),
  permissionTo: mapPermissionTo(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({ dispatch });

export default connect<any, any, any, any, any, any>(
  mapStateToProps,
  mapDispatchToProps,
)(ReportsReceipts);
