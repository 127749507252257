// @flow

export const requestPersistentFS = () => new Promise<any>((resolve, reject) => {
  window.requestFileSystem(window.LocalFileSystem.PERSISTENT, 0, (fs) => {
    resolve(fs);
  }, (e) => reject(e));
});

export const saveToDeviceDownloadFolder = (blob: Blob, fileName: string) => new Promise<string>((resolve, reject) => {
  if (window.cordova && window.cordova.platformId !== 'browser') {
    requestPersistentFS().then((fs) => {
      fs.root.getFile(fileName, { create: true, exclusive: false }, (fileEntry) => {
        fileEntry.createWriter((fileWriter) => {
          //
          fileWriter.onwriteend = () => {
            resolve(fileEntry.toURL());
            // resolve(window.Ionic.WebView.convertFileSrc(fileEntry.toURL()));
          };
          fileWriter.onerror = (error) => reject(error);

          fileWriter.write(blob);
          //
        }, (error) => reject(error));
      }, (error) => reject(error));
    }).catch((e) => reject(e));
  } else {
    reject();
  }
});
