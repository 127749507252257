// @flow
import React from 'react';
import {
  RadioGroup, StepProgressBar,
  ClickableList, Loading,
  // Button, Icon,
} from '@elements';
import { withTranslation } from 'react-i18next';
import { createNsTranslator } from '@helpers/helpers';
import { ExternalPlatformSwitch } from '@blocks';
import SignUpLogo from './SignUpLogo';

type Props = {
  authErrors: Object,
  isLoading: boolean,
  onSignupStep4: Function,
  t: Function,
};

type ClinicItemType = {
  text: string,
  value: string,
};

const clinicSizeItems = (_: Function) => [
  { text: _('label/clinic_work_style_alone'), value: 'alone' },
  { text: _('label/clinic_work_style_with_secretary'), value: 'with_secretary' },
  { text: _('label/clinic_work_style_up_to_5'), value: 'up_to_5' },
  { text: _('label/clinic_work_style_more_than_5'), value: 'more_than_5' },
];

class SignUpClinic extends React.Component<Props> {
  render() {
    const { authErrors, isLoading } = this.props;
    const _ = createNsTranslator('onboarding', this.props.t);

    const renderer = (item) => (
      <RadioGroup name="clinicSize" items={item} selectGroupName="signupType" />
    );

    const renderContent = (isMobile: boolean) => (
      <div id="signUpClinicPage" className="horzPublicLayout">
        {!isMobile && (
          <div className="signupHeader">
            <SignUpLogo linkTo="/" />
            <hr className="fade" />
          </div>
        )}
        <div className="main">
          <div className="center col">
            <StepProgressBar steps={4} currentyStep={4} />

            <div className="narrative">
              <h1>{_('label/clinic_work_style_label')}</h1>
            </div>
            {isLoading && <div className="signUpLayoutLeft signUpLoader"><Loading /></div>}
            {!isLoading && (
            <ClickableList
              className="desktopCard mobileCard"
              customRenderer={renderer}
              items={clinicSizeItems(_)}
              onListItemClick={this.onSelectClinic}
            />
            )}

            <span className='signupError'>{authErrors && authErrors[0]}</span>

            {/* <p className="endOfSignupMsg">Pronto! O Doutore está 100% customizado para você.</p> */}
            {/* <Button className="primary" text="Acessar o Doutore" disabled /> */}

          </div>
        </div>

      </div>
    );

    return (
      <ExternalPlatformSwitch
        desktop={() => renderContent(false)}
        mobile={() => renderContent(true)}
      />
    );
  }

  onSelectClinic = (clinic: ClinicItemType) => {
    const { value } = clinic;
    this.props.onSignupStep4(value);
  }
}

export default withTranslation()(SignUpClinic);
