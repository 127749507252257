export default {
  'label/agenda': 'Agenda',
  'label/patients': 'Pacientes',
  'label/reports': 'Relatórios',
  'label/settings': 'Ajustes',
  'label/support': 'Suporte',
  'label/user_guide': 'Guia do Doutore',
  'label/help': 'Ajuda',
  'label/remaining_trial_days': 'dias para testar grátis',
  'action/see_details': 'Ver detalhes',
  'action/reload': 'Atualizar',
  'action/ai': 'Inteligência Artificial',
  'action/click_to_resolve': 'Clique aqui para resolver',
  'action/logout': 'Sair do Doutore',
  'label/account_will_be_canceled': 'A sua conta será cancelada em breve.',
  'label/payment_problem': 'Não recebemos o pagamento do Doutore.',
  'label/online_support': 'Suporte Online',
  'label/dashboard': 'Painel de Controle',
  'label/beta_app': 'Versão Beta',
};
