// @flow
import React from 'react';
import { Link, matchPath, withRouter } from 'react-router-dom';
import { Icon } from '@elements';
import { map, forEach, isEmpty, get } from 'lodash';
import { connect } from 'react-redux';
import type { PlanInclude } from '@types';
import { mapPlanIncludes } from '@helpers/connect';
import { withTranslation } from 'react-i18next';
import { createNsTranslator } from '@helpers/helpers';
import { compose } from 'redux';
import './AppMobileFooter.scss';

type Props = {
  location: Object,
  planInclude: PlanInclude,
  t: Function,
};

type State = {
  keyboardVisible: boolean,
};

const menuItens = (intercom: boolean, _: Function) => [
  {
    match: ['/agenda'],
    text: _('label/agenda'),
    icon: 'calendar',
    linkTo: '/agenda',
  },
  {
    match: ['/pacientes/lista', '/paciente/:id/prontuario'],
    text: _('label/patients'),
    icon: 'contacts',
    linkTo: '/pacientes/lista',
  },
  {
    match: ['/relatorios', '/relatorios/:anyroute'],
    text: _('label/reports'),
    icon: 'reports',
    linkTo: '/relatorios',
  },
  ...(intercom ? [{
    match: [],
    text: _('label/support'),
    icon: 'help',
    onClick: () => {
      if (window.Intercom) {
        window.Intercom('show');
      }
    },
  }] : []),
  {
    match: ['/ajustes', '/assinar'],
    text: _('label/settings'),
    icon: 'settings',
    linkTo: '/ajustes',
  },
];

class AppMobileFooter extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      keyboardVisible: false,
    };
  }
  componentWillMount() {
    window.addEventListener('keyboardDidShow', this.handleKeyboardShow);
    window.addEventListener('keyboardDidHide', this.handleKeyaboardHide);
  }

  componentWillUnmount() {
    window.removeEventListener('keyboardDidShow', this.handleKeyboardShow);
    window.removeEventListener('keyboardDidHide', this.handleKeyaboardHide);
  }

  render() {
    const _ = createNsTranslator('layout', this.props.t);

    if (this.state.keyboardVisible) {
      return null;
    }

    return (
      <div className="appMobileFooter">
        <ul>
          {map(menuItens(this.props.planInclude.intercom, _), (item, index:number) => (
            <li className={this.isActiveClass(item.match)} key={index}>
              {item.onClick &&
                <a onClick={item.onClick}>
                  <Icon iconName={item.icon} size={18} />
                  <span>{item.text}</span>
                </a>
              }
              {!item.onClick &&
                <Link to={get(item, 'linkTo')} className={this.isActiveClass(item.match)}>
                  <Icon iconName={item.icon} size={18} />
                  <span>{item.text}</span>
                </Link>
              }
            </li>
          ))}
        </ul>
      </div>
    );
  }

  handleKeyboardShow= () => this.setState({ keyboardVisible: true });

  handleKeyaboardHide= () => this.setState({ keyboardVisible: false });

  isActiveClass = (matches: Array<string>) => {
    let count = 0;

    forEach(matches, route => {
      const match = matchPath(this.props.location.pathname, {
        path: route,
        exact: false,
      });

      if (!isEmpty(match)) {
        count++;
      }
    });
    return count > 0 ? 'active' : '';
  };
}


const mapStateToProps = (state, ownProps) => ({
  user: get(state, ['context', 'user']),
  planInclude: mapPlanIncludes(state),
  location: get(ownProps, ['location']),
});

export default compose(
  withRouter,
  withTranslation(),
  connect(mapStateToProps),
)(AppMobileFooter);

