// @flow
import React from 'react';
import moment from 'moment';
import { get } from 'lodash';
import { isPresent, isPresentOneOf, createNsTranslator } from '@helpers/helpers';
import { PlatformSwitch, AppMobileHeader, PatientMobileHeader, AppMobileFooter, BlockedFeature } from '@blocks';
import { Button, Icon, FormattedText, Alert, MobilePhoneLinks } from '@elements';
import type { Patient, Doctors, Clinic } from '@types';
import { withTranslation } from 'react-i18next';
import PatientLayout from '../../../../layouts/PatientLayout';
import './PatientProfile.scss';

type Props = {
  patient: Patient,
  doctors: Doctors,
  clinic: Clinic,
  // onNewPatientClick: Function,
  onDeleteButtonClick: Function,
  onEditPatientClick: Function,
  isPatientSaving: boolean,
  isPatientPrinting: boolean,
  patientFormSelectDoctor: boolean,
  isLoading: boolean,
  onPrintClick: Function,
  onRefresh: Function,
  t: Function,
  onDeletePatientProfileImage: Function,
  onSelectPatientProfileImage: Function,
};

const handleDate = (date: ?string, fallbackValue?: string):string => {
  const parsedDate = moment(String(date), 'YYYY-MM-DD');
  if (parsedDate.isValid()) {
    return parsedDate.format('DD/MM/YYYY');
  }
  return fallbackValue || '';
};

const getDoctorName = (doctors: Object, patient: Object) => get(doctors, [patient.doctorId, 'name'], '');

class PatientProfile extends React.Component<Props> {
  render() {
    const {
      patient, doctors, clinic, onDeleteButtonClick, onEditPatientClick, onPrintClick,
      isPatientSaving, isPatientPrinting, patientFormSelectDoctor, onDeletePatientProfileImage,
      onSelectPatientProfileImage,
    } = this.props;
    if (!patient) {
      return null;
    }

    const _ = createNsTranslator('patientProfile', this.props.t);

    const gender = {
      male: _('label/gender_male'),
      female: _('label/gender_female'),
    };

    const hideProfile = get(patient, 'hideProfile', false);


    const print = isPatientPrinting
      ? <Icon iconName="spinner" />
      : <Icon iconName={window.isCordovaApp ? 'share' : 'print'} onClick={onPrintClick} size={window.isCordovaApp ? 20 : 24}/>;

    const content = () => (
      <React.Fragment>
        <div>
          <span className="heading">{_('label/basic_data')}</span>
          <table className="infoTable">
            <tbody>
              <tr>
                <td>{_('label/name')}:</td>
                <td>{patient.name}</td>
              </tr>
              {isPresent(patient.gender) &&
              <tr>
                <td>{_('label/gender')}:</td>
                <td>{get(gender, patient.gender, '')}</td>
              </tr>
              }
              {isPresent(patient.number) &&
              <tr>
                <td>{_('label/code')}:</td>
                <td>{patient.number}</td>
              </tr>
              }
              {isPresent(patient.recommendedBy) &&
              <tr>
                <td>{_('label/recommend_by')}:</td>
                <td>{patient.recommendedBy}</td>
              </tr>
              }
              {isPresent(patient.birthdate) &&
              <tr>
                <td>{_('label/birth_date')}:</td>
                <td>{handleDate(patient.birthdate)}</td>
              </tr>
              }
              {isPresent(patient.cpf) &&
              <tr>
                <td>{_('label/cpf')}:</td>
                <td><FormattedText text={patient.cpf} type="cpf" /></td>
              </tr>
              }
              {isPresent(patient.legalId) &&
              <tr>
                <td>{_('label/legal_id')}:</td>
                <td>{patient.legalId}</td>
              </tr>
              }
              {isPresent(patient.profession) &&
              <tr>
                <td>{_('label/profession')}:</td>
                <td>{patient.profession}</td>
              </tr>
              }
              {isPresent(patient.placeOfBirth) &&
              <tr>
                <td>{_('label/place_of_birth')}:</td>
                <td>{patient.placeOfBirth}</td>
              </tr>
              }
              {isPresent(patient.maritalStatusCode) &&
              <tr>
                <td>{_('label/marital_status')}:</td>
                <td><FormattedText text={patient.maritalStatusCode} type="maritalStatus" /></td>
              </tr>
              }
              {isPresent(patient.extraField1) &&
              <tr>
                <td>{clinic.patientsExtraField1Label}</td>
                <td>{patient.extraField1}</td>
              </tr>
              }
              {isPresent(patient.extraField2) &&
              <tr>
                <td>{clinic.patientsExtraField2Label}</td>
                <td>{patient.extraField2}</td>
              </tr>
              }
              {isPresent(patient.extraField3) &&
              <tr>
                <td>{clinic.patientsExtraField3Label}</td>
                <td>{patient.extraField3}</td>
              </tr>
              }
              {isPresent(getDoctorName(doctors, patient)) && patientFormSelectDoctor &&
              <tr>
                <td>{_('label/responsible_doctor')}:</td>
                <td>{getDoctorName(doctors, patient)}</td>
              </tr>
              }
            </tbody>
          </table>
          {isPresentOneOf(patient, ['insuranceCompany', 'insurancePlan', 'insuranceNumber', 'insuranceExpirationDate']) &&
          <span className="heading">{_('label/health_isurance')}</span>
                        }
          <table className="infoTable">
            <tbody>
              {isPresent(patient.insuranceCompany) &&
              <tr>
                <td>{_('label/insurance_company')}:</td>
                <td>{patient.insuranceCompany}</td>
              </tr>
                            }
              {isPresent(patient.insurancePlan) &&
              <tr>
                <td>{_('label/insurance_plan')}:</td>
                <td>{patient.insurancePlan}</td>
              </tr>
                            }
              {isPresent(patient.insuranceNumber) &&
              <tr>
                <td>{_('label/insurance_number')}:</td>
                <td>{patient.insuranceNumber}</td>
              </tr>
                            }
              {isPresent(patient.insuranceExpirationDate) &&
              <tr>
                <td>{_('label/insurance_expiration_date')}:</td>
                <td>{patient.insuranceExpirationDate}</td>
              </tr>
                            }
            </tbody>
          </table>
        </div>

        <div>
          {isPresentOneOf(patient, ['mobilePhone', 'homePhone', 'otherPhone',
            'instagram', 'father', 'mother', 'guardianName', 'guardianRelationship',
            'extraContactField1', 'extraContactField2', 'extraContactField3', 'email',
          ]) &&
          <span className="heading">{_('label/contact')}</span>
                      }
          <table className="infoTable noWidthFix">
            <tbody>
              {isPresent(patient.mobilePhone) &&
              <tr>
                <td>{_('label/mobile_phone')}:</td>
                <td>
                  <FormattedText text={patient.mobilePhone} type="phone" />
                  <MobilePhoneLinks mobilePhone={patient.mobilePhone} whatsapp />
                </td>
              </tr>
                            }
              {isPresent(patient.homePhone) && (
                <tr>
                  <td>{_('label/home_phone')}:</td>
                  <td>
                    <FormattedText text={patient.homePhone} type="phone" />
                    <MobilePhoneLinks mobilePhone={patient.homePhone} />
                  </td>
                </tr>
              )}
              {isPresent(patient.otherPhone) &&
              <tr>
                <td>{_('label/other_phone')}:</td>
                <td>
                  <FormattedText text={patient.otherPhone} type="phone" />
                  <MobilePhoneLinks mobilePhone={patient.otherPhone} whatsapp />
                </td>
              </tr>
                            }
              {isPresent(patient.email) &&
              <tr>
                <td>{_('label/email')}:</td>
                <td><a href={`mailto:${get(patient, 'email')}`}>{patient.email}</a></td>
              </tr>
                            }
              {isPresent(patient.instagram) &&
              <tr>
                <td>{_('label/instagram')}:</td>
                <td>@{patient.instagram}</td>
              </tr>
                            }
              {isPresent(patient.father) &&
              <tr>
                <td>{_('label/father')}:</td>
                <td>{patient.father}</td>
              </tr>
                            }
              {isPresent(patient.mother) &&
              <tr>
                <td>{_('label/mother')}:</td>
                <td>{patient.mother}</td>
              </tr>
                            }
              {isPresent(patient.guardianName) &&
              <tr>
                <td>{_('label/guardian_name')}:</td>
                <td>{patient.guardianName}</td>
              </tr>
                            }
              {isPresent(patient.guardianRelationship) &&
              <tr>
                <td>{_('label/guardian_relationship')}:</td>
                <td>{patient.guardianRelationship}</td>
              </tr>
                            }
              {isPresent(patient.extraContactField1) &&
              <tr>
                <td>{clinic.patientsExtraContactField1Label}</td>
                <td>{patient.extraContactField1}</td>
              </tr>
                            }
              {isPresent(patient.extraContactField2) &&
              <tr>
                <td>{clinic.patientsExtraContactField2Label}</td>
                <td>{patient.extraContactField2}</td>
              </tr>
                            }
              {isPresent(patient.extraContactField3) &&
              <tr>
                <td>{clinic.patientsExtraContactField3Label}</td>
                <td>{patient.extraContactField3}</td>
              </tr>
                            }
            </tbody>
          </table>

          {
            isPresentOneOf(patient, ['addressStreet', 'addressExtra', 'addressNeighborhood',
              'addressZipcode', 'addressCity', 'addressState']) &&
              <span className="heading">{_('label/address')}</span>
          }
          <div className="address">
            {patient.addressStreet} {patient.addressNumber} {patient.addressExtra}
            <br />{patient.addressNeighborhood}
            {patient.addressZipcode &&
            <FormattedText text={patient.addressZipcode} type="cep">
              {addressZipcode => <span><br />{_('label/adress_zipcode')}:&nbsp;{addressZipcode}</span>}
            </FormattedText>
                          }
            <br />{patient.addressCity} {patient.addressState}
          </div>

          {isPresent(patient.notes) &&
          <React.Fragment>
            <span className="heading">{_('label/additional_information')}</span>
            <div className="infoTable">
              <FormattedText type="breaklines" text={patient.notes} />
            </div>
          </React.Fragment>
                        }
        </div>
      </React.Fragment>
    );

    const hideProfileContent = hideProfile && (
      <div style={{ width: '100%' }}>
        <BlockedFeature
          simpleStyle
          hideUpgradeButton
          iconName="lock"
          iconSize={400}
          customMessage={_('label/blocked_permission_patient_title', 'global')}
          hint={_('label/blocked/hint/global', 'global')}
        />
      </div>
    );


    return (
      <PlatformSwitch
        className="platformSwitcher"
        desktop={() => (
          <div id="timelinePage" className="appContent">
            <PatientLayout patient={patient}>
              {hideProfileContent}
              {!hideProfile && (
                <section className="mainSection">
                  <div className="centerContent">
                    <div className="toolbar">
                      <h1>{_('label/patient_profile_title')}</h1>
                      <div className="group-btn-tooolbar">
                        <span className="cursorPointer printButton">{print}</span>
                        <Button text="Editar" className="secondary" onClick={onEditPatientClick} />
                      </div>
                    </div>

                    {!patient.completed &&
                    <Alert text={_('hint/incomplete')} />
                      }

                    {patient.duplicated &&
                    <Alert text={_('hint/double')} />
                      }

                    <div className="desktopCard padding patientProfileTable">
                      <div className="split">
                        {content()}
                      </div>
                    </div>
                    <Button
                      text="Excluir Paciente"
                      className="outsideAction deleteButton dark"
                      onClick={onDeleteButtonClick}
                      isLoading={isPatientSaving}
                      loadingMessage={_('label/deleting', 'global')}
                    />
                  </div>
                </section>
              )}
            </PatientLayout>
          </div>
        )}
        mobile={() => (
          <React.Fragment>
            <AppMobileHeader
              absoluteBacklink
              className="showBack centerTitlefix"
              backLinkTo="/pacientes/lista"
              title={patient.name}
            />

            <div id="profilePage" className="appContent">


              <section className="mainSection noPadding">

                <PatientMobileHeader
                  onDeletePatientProfileImage={onDeletePatientProfileImage}
                  onSelectPatientProfileImage={onSelectPatientProfileImage}
                  patient={patient}
                  active="profile"
                />

                <div className="padding patientProfileTable">
                  {hideProfileContent}
                  {!hideProfile && (
                    <React.Fragment>
                      <div className="toolbar">
                        <h1>{_('label/patient_profile_title')}</h1>
                        {print}
                        <Button text={_('action/edit', 'global')} className="primary" onClick={onEditPatientClick} />
                      </div>
                      {content()}
                      <Button text={_('action/delete_patient')} className="deleteButton dark" onClick={onDeleteButtonClick} />
                    </React.Fragment>
                  )}
                </div>
              </section>

            </div>
            <AppMobileFooter />
          </React.Fragment>
        )}
      />

    );
  }

  handleRefresh = () => {
    if (!this.props.isLoading) {
      this.props.onRefresh();
    }
  };
}

export default withTranslation()(PatientProfile);
