// @flow
import Immutable from 'seamless-immutable';
import _ from 'lodash';
import windowReducer from './ui/window';
import { actionTypes as auth } from './auth';
import type { UiState, Action } from '../../types';

// ------------------------------------
// Actions
// ------------------------------------
export const actions = {};

// ------------------------------------
// Reducer
// ------------------------------------
export default function uiReducer (passedState: UiState = Immutable({}), action: Action) {
  const state = _.reduce([
    windowReducer,
  ], (s, reducer) => reducer(s, action), passedState);

  switch (action.type) {
    case auth.AUTH_LOGGED_OUT:
      return Immutable({});

    default:
      return state;
  }
}
