// @flow
import React, { useState, useCallback } from 'react';
import { weekDays } from '@constants';
import { InputMask, Button, SelectBox } from '@elements';
import { get } from 'lodash';
import classnames from 'classnames';
import { useTranslationNs, useTranslatedConstant } from '@helpers/helpers';
import './WorkHoursForm.scss';

type Props = {
  onSave?: Function,
  title?: string,
  mobile?: boolean,
};


const initialData = {
  weekday: 1,
  start: '08:00',
  end: '16:00',
};

const WorkHoursForm = ({
  mobile, onSave, title,
}: Props) => {
  const [_] = useTranslationNs('settings');
  const [formData, setFormData] = useState(initialData);

  const [translatedWeekDays] = useTranslatedConstant(weekDays);

  const handleClick = useCallback(() => {
    if (onSave) {
      onSave(formData);
    }
  }, [onSave, formData]);

  const handleFormChange = useCallback((data: Object) => {
    setFormData(fd => ({
      ...fd,
      [data.name]: data.value,
    }));
  }, []);

  return (
    <div className="addWorkHourContainer" id="addWorkHours">
      {/* <p className="titleAddWorkHours">Adicionar novo horário de atendimento</p> */}
      <p className="titleAddWorkHours">{title || _('label/doctors_workhours/title2')}</p>
      <div id="form" className={classnames('addWorkHoursForm', { mobile })}>
        <SelectBox
          name="weekday"
          label={_('label/doctors_workhours/weekday')}
          options={translatedWeekDays}
          value={get(formData, 'weekday', '')}
          onChange={handleFormChange}
        />
        <InputMask
          type="time"
          label={_('label/start', 'global')}
          name="start"
          value={get(formData, 'start', '')}
          onChange={handleFormChange}
          required
        />
        <InputMask
          type="time"
          label={_('label/end', 'global')}
          name="end"
          value={get(formData, 'end', '')}
          onChange={handleFormChange}
          required
        />
      </div>
      <div className="footerActionsWh">
        <Button className="primary" text={_('action/add', 'global')} onClick={handleClick} />
      </div>
    </div>
  );
};

export default WorkHoursForm;
