// @flow
import React from 'react';
import { get } from 'lodash';
import { connect } from 'react-redux';
import { SubscriptionInfo as SubscriptionInfoView } from '@views';
import { getActiveSubscriptionUserInfo, newBoleto } from '@context/subscription';
import type { User, SubscriptionState, BundledPermissions } from '@types';
import { logout } from '@context/user';
import { mapBundledPermission } from '@helpers/connect';
import { router } from '@router';

type Props = {
  user: User,
  accessToken: string,
  dispatch: Function,
  subscription: SubscriptionState,
  permissions: BundledPermissions,
  isRequestingNewBoleto: boolean,
};

class SubscriptionInfo extends React.Component<Props> {
  componentWillMount() {
    this.props.dispatch(getActiveSubscriptionUserInfo());
  }
  render() {
    const { accessToken, user, subscription, isRequestingNewBoleto } = this.props;

    return (
      <SubscriptionInfoView
        accessToken={accessToken}
        user={user}
        subscription={subscription}
        onLogout={this.handleLogout}
        permissions={this.props.permissions}
        onGetNewBoleto={this.handleGetBoleto}
        isRequestingNewBoleto={isRequestingNewBoleto}
        onPayClick={this.handlePayClick}
        onResubscribeClick={this.handleResubscribeClick}
      />
    );
  }
  handleLogout = () => this.props.dispatch(logout());

  handleGetBoleto = () => {
    this.props.dispatch(newBoleto());
  };

  handlePayClick = () => {
    this.props.dispatch(router.pushPath('subscriptionPayment'));
  };

  handleResubscribeClick = () => {
    this.props.dispatch(router.pushPath('pricing'));
  };
}

const mapStateToProps = (state) => ({
  accessToken: get(state, ['auth', 'token'], null),
  user: state.context.user,
  subscription: get(state, ['context', 'subscription'], {}),
  isRequestingNewBoleto: get(state, ['context', 'subscription', 'isRequestingNewBoleto'], false),
  permissions: mapBundledPermission(state),
});

export default connect<any, any, any, any, any, any>(
  mapStateToProps,
)(SubscriptionInfo);
