// @flow
import React from 'react';
import { connect } from 'react-redux';
import { PrescriptionsPrintConfig as PrescriptionsPrintConfigView } from '@views';
import type { User, Doctor, PrescritionPrintConfig, BundledPermissions } from '@types';
import { get } from 'lodash';
import { fetchPrescriptionLayout, updatePrintPrecriptionLayout, previewPrescriptionLayout } from '@context/clinics';
import { logout } from '@context/user';
import { mapBundledPermission } from '@helpers/connect';

type Props = {
  user: User,
  doctor: Doctor,
  dispatch: Function,
  isLoading: boolean,
  isSaving: boolean,
  prescritionPrintConfig: PrescritionPrintConfig,
  permissions: BundledPermissions,
};

class PrescriptionsPrintConfig extends React.Component<Props> {
  componentWillMount() {
    const { doctor, dispatch } = this.props;
    dispatch(fetchPrescriptionLayout(doctor.id));
  }
  render() {
    const {
      user, isLoading, isSaving, prescritionPrintConfig,
    } = this.props;

    return (
      <PrescriptionsPrintConfigView
        user={user}
        formData={prescritionPrintConfig}
        isLoading={isLoading}
        onSave={this.handleOnSave}
        isSaving={isSaving}
        onLogout={this.handleLogout}
        permissions={this.props.permissions}
        onPreviewPDF={this.handlePreviewPDF}
      />
    );
  }
  handleLogout = () => this.props.dispatch(logout());
  handleOnSave = (prescriptionLayout: Object) => {
    const { doctor, dispatch } = this.props;
    dispatch(updatePrintPrecriptionLayout(doctor.id, { prescriptionLayout }));
  };
  handlePreviewPDF = () => {
    const { doctor, dispatch } = this.props;
    dispatch(previewPrescriptionLayout(doctor.id));
  };
}

const mapStateToProps = (state) => ({
  user: state.context.user,
  prescritionPrintConfig: get(state, ['context', 'clinics', 'prescritionPrintConfig', 'data'], {}),
  doctor: get(state, ['context', 'doctors', 'data', get(state, ['context', 'user', 'advancedData', 'doctorId'])], {}),
  isLoading: get(state, ['context', 'clinics', 'prescritionPrintConfig', 'isLoading'], false),
  isSaving: get(state, ['context', 'clinics', 'prescritionPrintConfig', 'isSaving'], false),
  permissions: mapBundledPermission(state),
});


export default connect<any, any, any, any, any, any>(
  mapStateToProps,
)(PrescriptionsPrintConfig);
