// @flow
import React from 'react';
import { Log } from '@helpers';
import { Button, Input, Icon, Alert } from '@elements';
import { MobileHide, WebHide } from '@blocks';
import { withTranslation } from 'react-i18next';
import { createNsTranslator } from '@helpers/helpers';
import './NewPassword.scss';

class NewPassword extends React.Component<any> {
  render() {
    const _ = createNsTranslator('onboarding', this.props.t);

    return (
      <div id="signUpPage" className="signUpLayout">
        <span className="circle" />

        <WebHide className="signUpLayoutHeader">
          <span className="logo" />
        </WebHide>

        <div className="signUpLayoutBody">

          <div className="form">
            <h1>
              <Icon iconName="lock" className="icon" size={28} />
              <span>{_('action/create_password')}</span>
            </h1>

            <Alert text={_('label/requre_define_password')} />
            <Input
              name="password"
              type="password"
              label={_('label/create_password_input')}
              onChange={this.onChangePassword.bind(this)}
              autoComplete="new-password"
            />

            <Button
              className="primary"
              text={_('action/save', 'global')}
            />
          </div>
          <MobileHide className="logo" />
        </div>
      </div>
    );
  }

  onChangePassword = (value: string) => {
    Log.info('Password changed: ', value);
  };
}

export default withTranslation()(NewPassword);
