// @flow
import React from 'react';
import { get } from 'lodash';
import moment from 'moment';
import {
  Icon, Button, Modal,
  Checkbox, ClickableList, Spinner,
} from '@elements';
import type { FullAppointment } from '@types';
import { isPresent, UserNotifications } from '@helpers/helpers';

type StatusData = {
  type: string,
  date: string,
  user?: string,
};

type Props = {
  fullAppointment: ?FullAppointment,
  setModal: Function,
  onChangeStatus: Function,
  _: Function,
  // isLockedForLoading: boolean,
};

export default class StatusModal extends React.Component<Props> {
  render() {
    const { fullAppointment, _ } = this.props;

    const isLoading = !get(fullAppointment, 'full', false);

    const smsSentAt = get(fullAppointment, 'smsSentAt', '');
    const confirmedBySmsAt = get(fullAppointment, 'confirmedBySmsAt', '');
    const canceledBySmsAt = get(fullAppointment, 'canceledBySmsAt', '');

    let statuses = [
      {
        className: isPresent(get(fullAppointment, 'createdAt')) ? 'listItem status checked' : 'listItem status',
        customRendererData: {
          icon: 'event_note',
          text: _('label/status/scheduled'),
          disabled: true,
          data: {
            date: get(fullAppointment, 'createdAt'),
            user: get(fullAppointment, 'createdByUserName'),
          },
        },
      },
    ];

    if (isPresent(smsSentAt)) {
      statuses = [
        ...statuses,
        {
          className: 'listItem status checked',
          customRendererData: {
            icon: 'mobile',
            text: _('label/status/sms_sent'),
            disabled: true,
            data: {
              date: smsSentAt,
            },
          },
        },
      ];
    }

    if (isPresent(confirmedBySmsAt)) {
      statuses = [
        ...statuses,
        {
          className: 'listItem status checked',
          customRendererData: {
            icon: 'mobile',
            text: _('label/status/sms_confirmed'),
            disabled: true,
            data: {
              date: confirmedBySmsAt,
            },
          },
        },
      ];
    }

    if (isPresent(canceledBySmsAt)) {
      statuses = [
        ...statuses,
        {
          className: 'listItem status checked',
          customRendererData: {
            icon: 'mobile',
            text: _('label/status/sms_canceled'),
            disabled: true,
            data: {
              date: canceledBySmsAt,
            },
          },
        },
      ];
    }

    statuses = [
      ...statuses,
      {
        className: isPresent(get(fullAppointment, 'confirmedAt', '')) ? 'listItem status checked' : 'listItem status',
        customRendererData: {
          icon: 'event_available',
          text: _('label/status/confirmed'),
          data: {
            type: 'confirmed',
            date: get(fullAppointment, 'confirmedAt'),
            user: get(fullAppointment, 'confirmedByUserName'),
          },
        },
      },
      {
        className: isPresent(get(fullAppointment, 'patientArrivedAt', '')) ? 'listItem status checked' : 'listItem status',
        customRendererData: {
          icon: 'event_seat',
          text: _('label/status/patient_arrived'),
          data: {
            type: 'patient_arrived',
            date: get(fullAppointment, 'patientArrivedAt'),
            user: get(fullAppointment, 'patientArrivedByUserName'),
          },
        },
      },
      {
        className: isPresent(get(fullAppointment, 'finishedAt', '')) ? 'listItem status checked' : 'listItem status',
        customRendererData: {
          icon: 'done',
          text: _('label/status/patient_attended'),
          data: {
            type: 'finished',
            date: get(fullAppointment, 'finishedAt'),
          },
        },
      },
      {
        className: isPresent(get(fullAppointment, 'patientMissedAt', '')) ? 'listItem status checked' : 'listItem status',
        customRendererData: {
          icon: 'event_busy',
          text: _('label/status/patient_missed'),
          data: {
            type: 'patient_missed',
            date: get(fullAppointment, 'patientMissedAt'),
            user: get(fullAppointment, 'patientMissedByUserName'),
          },
        },
      },
      {
        className: isPresent(get(fullAppointment, 'canceledByPatientAt', '')) ? 'listItem status checked' : 'listItem status',
        customRendererData: {
          icon: 'highlight_off',
          text: _('label/status/patient_canceled'),
          askDelete: true,
          data: {
            type: 'canceled_by_patient',
            date: get(fullAppointment, 'canceledByPatientAt'),
            user: get(fullAppointment, 'canceledByPatientByUserName'),
          },
        },
      },
      {
        className: isPresent(get(fullAppointment, 'canceledByDoctorAt', '')) ? 'listItem status checked' : 'listItem status',
        customRendererData: {
          icon: 'highlight_off',
          text: _('label/status/canceled_by_doctor'),
          askDelete: true,
          data: {
            type: 'canceled_by_doctor',
            date: get(fullAppointment, 'canceledByDoctorAt'),
            user: get(fullAppointment, 'canceledByDoctorByUserName'),
          },
        },
      },
    ];

    const statusesRenderer = (item) => {
      const date = get(item, ['data', 'date']);
      const user = get(item, ['data', 'user']);
      return (
        // <div className={`appointmentStatusItem${item.disabled || isLockedForLoading ? ' disabled' : ''}`}>
        <div className={`appointmentStatusItem${item.disabled ? ' disabled' : ''}`}>
          <Checkbox
            className='statusCheckboxes'
            key={item.key}
            onChange={this.onChangeStatus(item.data, item.disabled)}
            isChecked={item.data.date && item.data.date.length > 0}
            // disabled={item.disabled || isLockedForLoading}
          >
            <label className="text">
              <span>{item.text}</span>
              {date && isPresent(user) && <span className='user'>{user}</span>}
              {date && <span className='date'>{moment(date).format('DD/MMM [às] HH:mm')}</span>}
            </label>
          </Checkbox>
          <Icon iconName={item.icon} className="icon" size={20} />
        </div>
      );
    };

    return (
      <Modal id="patientModal" className="secondaryModal">
        <div className="modalHeader actionHeader">
          <Button className="backButton" onClick={this.handleOnBack}>
            <Icon iconName="arrow-left" className="icon" />
          </Button>
          <span className="backButtonLabel">{_('label/change_status')}</span>
        </div>
        {isLoading && <Spinner text="Carregando..." />}
        {!isLoading && (
          <ClickableList
            className="appointmentStatusItemNopad"
            items={statuses}
            customRenderer={statusesRenderer}
            onListItemClick={this.onItemClick}
          />
        )}
      </Modal>
    );
  }

  onItemClick = (item: Object) => {
    // if (item.disabled || this.props.isLockedForLoading) return;
    if (item.disabled) return;
    const isChecked = item.data.date && item.data.date.length > 0;
    this.onChangeStatus(item.data)({ value: !isChecked });
  };

  onChangeStatus = (data: StatusData, disabled: boolean = false) => (inputdata: Object) => {
    // if (disabled || this.props.isLockedForLoading) return;
    if (disabled) return;

    if ((data.type === 'canceled_by_patient' || data.type === 'canceled_by_doctor') && inputdata.value) {
      UserNotifications.confirmI18n('alert/hide/appointment', null, null, true)
        .then((confirmed) => {
          if (confirmed) {
            this.props.onChangeStatus(data, inputdata.value, true);
          } else {
            this.props.onChangeStatus(data, inputdata.value);
          }
        });
    } else {
      this.props.onChangeStatus(data, inputdata.value);
    }
  };

  handleOnBack = () => this.props.setModal(null);
}
