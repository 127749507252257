// @flow
import React from 'react';
import { get, map, toString } from 'lodash';
import { ClickableList, Icon, StepProgressBar, Loading } from '@elements';
import type { Errors } from '@types';
import { ExternalPlatformSwitch } from '@blocks';
import { withTranslation } from 'react-i18next';
import { createNsTranslator } from '@helpers/helpers';
import SignUpLogo from './SignUpLogo';
import imgGene from '../../../../images/doutore-gene.png';
import imgHeart from '../../../../images/doutore-heart.png';


type Props = {
  authErrors: Errors,
  userId: number,
  professions: Object,
  onSignupStep3: Function,
  t: Function,
  isLoading: boolean,
};

type ProfessionType = {
  code: string | number,
  name: string,
};

type State = {
  selectedProfession: ?ProfessionType
};

class SignUp extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      selectedProfession: null,
    };
  }

  render() {
    const { selectedProfession } = this.state;
    const { authErrors, isLoading, professions } = this.props;

    const _ = createNsTranslator('onboarding', this.props.t);

    const specializations = selectedProfession ? get(professions, ['specializations', toString(selectedProfession.code)]) : professions.main;

    const items = [];
    map(specializations, (specialization) => {
      const item = {
        customRendererData: {
          code: specialization.code,
          name: this.props.t(specialization._locale),
        },
      };
      items.push(item);
    });

    const renderer = (spec: boolean) => (item) => ([
      <img key={`img-${item.code}`} alt='.' src={spec ? imgGene : imgHeart} className='icon' />,
      <span key={`profession-${item.code}`}>{item.name}</span>,
    ]);


    const renderContent = (isMobile: boolean) => (
      <div id="signUpProfessionPage" className="horzPublicLayout">
        {!isMobile && (
          <div className="signupHeader">
            <SignUpLogo linkTo="/" />
            <hr className="fade" />
          </div>
        )}
        {isLoading && <div className="main signUpLoader"><Loading /></div>}
        {!isLoading &&
          <div className='main'>
            <div className="center col">
              <StepProgressBar steps={4} currentyStep={3} />
              <div className='narrative'>
                {selectedProfession ? ([
                  <span className='intro center'>
                    <span className='text'>{_('label/select_feedback')}</span>
                    <span
                      className='selectedProfession'
                      onClick={this.handleChangeProfession}
                    >
                      {this.props.t(selectedProfession.name)}
                      <Icon iconName='edit' className='icon' size={18} />
                    </span>
                    <span className='text' />
                  </span>,
                  <h1>{_('label/whats_your_specialty')}</h1>,
                ]) : (
                  <h1>{_('label/whats_your_profession')}</h1>
                )}
              </div>
              <span className='signupError'>{authErrors && authErrors[0]}</span>
            </div>


            <div className={`primaryCenter flexStart ${isMobile ? 'listPage' : ''}`}>
              <div className="primaryCenterContent">
                <ClickableList
                  className='desktopCard flexStart'
                  customRenderer={renderer(!!selectedProfession)}
                  items={items}
                  onListItemClick={this.onSelectProfession}
                />
              </div>
            </div>
          </div>
        }


      </div>
    );

    return (
      <ExternalPlatformSwitch
        desktop={() => renderContent(false)}
        mobile={() => renderContent(true)}
      />
    );
  }

  onSelectProfession = (profession: ProfessionType) => {
    const { professions, userId } = this.props;
    const professionSpecializations = get(professions, ['specializations', toString(profession.code)]);

    if (!professionSpecializations) {
      this.props.onSignupStep3(userId, profession.code);
    } else {
      this.setState({ selectedProfession: profession });
    }
  };

  handleChangeProfession = () => {
    this.setState({ selectedProfession: null });
  };
}

export default withTranslation()(SignUp);
