// @flow
import React, { useMemo } from 'react';
import { get } from 'lodash';
import { SettingsMenu, PlatformSwitch, AppMobileHeader, AppMobileFooter } from '@blocks';
import type { User, BundledPermissions } from '@types';
import { ClickableList } from '@elements';
import { useTranslationNs } from '@helpers/helpers';
import './Prescriptions.scss';

type Props = {
  user: User,
  onLogout: Function,
  permissions: BundledPermissions,
};

const PrescriptionsMenu = ({ user, onLogout, permissions }: Props) => {
  const [_] = useTranslationNs('settings');

  const items = useMemo(() => [
    {
      text: _('label/prescription_menu/prescriptions'),
      linkTo: '/ajustes/modelos/receituarios',
    },
    {
      text: _('label/prescription_menu/exams'),
      linkTo: '/ajustes/modelos/exames',
    },
    {
      text: _('label/prescription_menu/docs'),
      linkTo: '/ajustes/modelos/documentos',
    },
    ...(get(user, ['roleType']) === 'Doctor' ? [
      {
        text: _('label/prescription_menu/memed'),
        linkTo: '/ajustes/modelos/memed',
      },
    ] : []),
  ], [_, user]);

  const content = useMemo(() => (
    <React.Fragment>
      <div className="toolbar dashed">
        <h1>{_('label/prescription_menu/title')}</h1>
      </div>
      <ClickableList items={items} card />
    </React.Fragment>
  ), [_, items]);

  return (
    <PlatformSwitch
      inject
      desktop={() => (
        <div className="appContent settingsPage">
          <aside className="sidebar">
            <SettingsMenu user={user} onLogout={onLogout} permissions={permissions} />
          </aside>
          <section className="mainSection">
            <div className="centerContent">
              {content}
            </div>
          </section>
        </div>
      )}
      mobile={() => (
        <React.Fragment>
          <AppMobileHeader title={_('label/prescription_menu/title')} backLinkTo="/ajustes" />
          <div className="appContent settingsPage">
            <section className="mainSection">
              <div className="centerContent">
                {content}
              </div>
            </section>
          </div>
          <AppMobileFooter />
        </React.Fragment>
      )}
    />
  );
};

export default PrescriptionsMenu;
