export default {
  'label/basic_data': 'Datos Básicos',
  'label/name': 'Nombre',
  'label/gender': 'Sexo',
  'label/birth_date': 'Fecha de Nacimiento',
  'label/cpf': 'Tax ID',
  'label/legal_id': 'Identidad',
  'label/n_legal_id': 'Nº de Identidad',
  'label/profession': 'Profesión',
  'label/place_of_birth': 'Lugar de Nacimiento',
  'label/marital_status': 'Estado Civil',
  'label/responsible_doctor': 'Responsable',
  'label/recommend_by': 'Recomendación',
  'label/code': 'Código',
  'label/health_isurance': 'Plan de Salud',
  'label/insurance_company': 'Seguro',
  'label/insurance_plan': 'Plan',
  'label/insurance_number': 'Número de Plan',
  'label/insurance_expiration_date': 'Fecha de Vencimiento',
  'label/mobile_phone': 'Teléfono Móvil',
  'label/home_phone': 'Teléfono Fijo',
  'label/other_phone': 'Teléfono Adicional',
  'label/email': 'Correo Electrónico',
  'label/instagram': 'Instagram',
  'label/father_and_mother': 'Padre y Madre',
  'label/father': 'Padre',
  'label/mother': 'Madre',
  'label/guardian_name': 'Tutor',
  'label/guardian_and_relationship': 'Tutor y Parentesco',
  'label/guardian_relationship': 'Parentesco',
  'label/additional_information': 'Información Adicional',
  'label/patient_profile_title': 'Datos de Registro',
  'label/address': 'Dirección',
  'label/contact': 'Contacto',
  'label/adress_zipcode': 'Código Postal',
  'hint/incomplete': 'Este paciente fue creado desde la cita y tiene un registro incompleto.',
  'hint/double': 'Este paciente ha sido marcado como duplicado.',
  'action/delete_patient': 'Eliminar Paciente',
  'label/patientform/basic_data': 'Datos Básicos',
  'label/patientform/contacts': 'Contacto',
  'label/patientform/address': 'Dirección',
  'label/patientform/insurance': 'Plan de Salud',
  'label/patientform/notes': 'Información Adicional',
  'label/patientform/edit_patient': 'Editar Paciente',
  'label/patientform/new_patient': 'Nuevo Paciente',
};
