export default [
  { path: ['context', 'patients', 'isLoading'], value: false },
  { path: ['context', 'patients', 'searchByValue'], value: null },
  { path: ['context', 'patients', 'isSaving'], value: false },
  { path: ['context', 'patients', 'isFinancialLoading'], value: false },
  { path: ['context', 'patients', 'isSavingHistoryNote'], value: false },
  { path: ['context', 'patients', 'isPrinting'], value: false },

  { path: ['context', 'userLoading'], value: false },
  { path: ['context', 'userUpdating'], value: false },
  { path: ['context', 'user', 'userLoading'], value: false },
  { path: ['context', 'auth', 'inProgress'], value: false },
  { path: ['context', 'clinics', 'prescritionPrintConfig', 'isLoading'], value: false },

  { path: ['context', 'documents', 'isLoading'], value: false },
  { path: ['context', 'documents', 'isSaving'], value: false },

  { path: ['context', 'drugsPrescriptions', 'isLoading'], value: false },
  { path: ['context', 'drugsPrescriptions', 'isSaving'], value: false },

  { path: ['context', 'examsPrescriptions', 'isLoading'], value: false },
  { path: ['context', 'examsPrescriptions', 'isSaving'], value: false },
  { path: ['context', 'user', 'advancedData', 'memedSignupLoading'], value: false },
  { path: ['context', 'user', 'advancedData', 'memedError'], value: '' },

  { path: ['context', 'examsPrescription', 'isLoading'], value: false },
  { path: ['context', 'examsPrescription', 'isSaving'], value: false },

  { path: ['context', 'documentsTemplates', 'isLoading'], value: false },
  { path: ['context', 'documentsTemplates', 'isSaving'], value: false },

  { path: ['context', 'documentsTemplates', 'isLoading'], value: false },
  { path: ['context', 'documentsTemplates', 'isSaving'], value: false },

  { path: ['context', 'customForms', 'isLoading'], value: false },
  { path: ['context', 'customForms', 'isSaving'], value: false },

  { path: ['context', 'doctors', 'isLoading'], value: false },
  { path: ['context', 'doctors', 'isSaving'], value: false },
  { path: ['context', 'doctors', 'savingWorkHours'], value: false },
  { path: ['context', 'doctors', 'deletingWorkHours'], value: false },
  { path: ['context', 'doctors', 'userUpdating'], value: false },

  { path: ['context', 'clinics', 'isLoading'], value: false },
  { path: ['context', 'clinics', 'isSaving'], value: false },

  { path: ['context', 'secretaries', 'isLoading'], value: false },
  { path: ['context', 'secretaries', 'isSaving'], value: false },

  { path: ['context', 'toasts', 'active'], value: false },
  { path: ['context', 'toasts', 'data'], value: null },

  { path: ['context', 'layout', 'isLoading'], value: false },

  { path: ['context', 'patients', 'isLoading'], value: false },
  { path: ['context', 'patients', 'isSaving'], value: false },
  { path: ['context', 'patients', 'isFinancialLoading'], value: false },
  { path: ['context', 'patients', 'isSavingHistoryNote'], value: false },

  { path: ['context', 'tagTemplates', 'isLoading'], value: false },
  { path: ['context', 'tagTemplates', 'isSaving'], value: false },

  { path: ['context', 'attachments', 'downloadUrls'], value: {} },
  { path: ['context', 'attachments', 'isDownloading'], value: false },

  { path: ['context', 'subscription', 'isLoading'], value: false },
  { path: ['context', 'subscription', 'isSaving'], value: false },
  { path: ['context', 'subscription', 'availablePlansLoading'], value: false },
  { path: ['context', 'subscription', 'availablePlansError'], value: false },

  { path: ['context', 'services', 'updatingIds'], value: false },
  { path: ['context', 'services', 'isLoading'], value: false },
  { path: ['context', 'services', 'isLoadingReport'], value: false },
  { path: ['context', 'services', 'isSaving'], value: false },

  { path: ['context', 'payments', 'updatingIds'], value: false },
  { path: ['context', 'payments', 'isLoading'], value: false },
  { path: ['context', 'payments', 'isLoadingReport'], value: false },
  { path: ['context', 'payments', 'isSaving'], value: false },

  { path: ['context', 'records', 'updatingIds'], value: false },
  { path: ['context', 'records', 'isLoading'], value: false },
  { path: ['context', 'records', 'isAutoSaving'], value: false },
  { path: ['context', 'records', 'isCreating'], value: false },
  { path: ['context', 'records', 'deletingIds'], value: false },
  { path: ['context', 'records', 'updatingIds'], value: false },
  { path: ['context', 'records', 'isSaving'], value: false },

  { path: ['context', 'clinics', 'isLoading'], value: false },
  { path: ['context', 'clinics', 'isSaving'], value: false },
  { path: ['context', 'clinics', 'isMemedLoaded'], value: false },

  { path: ['context', 'appointments', 'isLoading'], value: false },
  { path: ['context', 'appointments', 'isSaving'], value: false },
  { path: ['context', 'appointments', 'filters', 'data'], value: {} },
  { path: ['context', 'appointments', 'filters', 'selectedDate'], value: null },
  { path: ['context', 'appointments', 'activeIncomingKey'], value: null },
  { path: ['context', 'appointments', 'incomingKeys'], value: {} },

  { path: ['context', 'receipts', 'isLoading'], value: false },
  { path: ['context', 'receipts', 'isSaving'], value: false },
  { path: ['context', 'receipts', 'isLoadingNew'], value: false },
  { path: ['context', 'receipts', 'isLoadingReport'], value: false },

  { path: ['ui', 'window'], value: null },

  { path: ['context', 'modals', 'isMemedOpen'], value: false },

];
