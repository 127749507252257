// @flow
import React from 'react';
import type { Node } from 'react';
import ReportPageTitle from './ReportPageTitle';

type TableProps = {
  children?: Node,
  title?: string,
};
class ReportPageTable extends React.Component<TableProps> {
  render() {
    return (
      <React.Fragment>
        {this.props.title && <ReportPageTitle>{this.props.title}</ReportPageTitle>}
        <section className="tableTrackHolder">
          <div className="tableTrack">
            {this.props.children}
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default ReportPageTable;
