// @flow
import React, { useCallback, useMemo, useState } from 'react';
import { get, map, toLower } from 'lodash';
import { isEqualValue, useTranslationNs } from '@helpers/helpers';
import { Input } from '../../elements';
// import { FormElementData } from '../../../types';

// TODO: use input FormElementData instead
type FormElementData = {
  name: string,
  value: string | number | Object
};

type Props = {
  id?: number,
  multiline?: boolean,
  defaultType?: string, // yes_no, gender
  items?: Array<{
    text: string | number | Object,
    value: string | number | Object | boolean,
  }>,
  label?: string,
  className?: string,
  disabled?: boolean,
  selectGroupName: string,
  value?: string | number | Object,
  defaultValue?: string | number | Object,
  onChange?: Function,
  name: string,
  uncontrolled?: boolean,
};

const RadioGroup = ({
  id, defaultType, items, label, className, disabled, selectGroupName, name, value, multiline, onChange, defaultValue,
  uncontrolled,
}: Props) => {
  const [selectedValue, setSelected] = useState(() => (defaultValue || uncontrolled ? defaultValue : value));

  const [_] = useTranslationNs('global');

  let buildItems;
  switch (defaultType) {
    case 'yes_no':
      buildItems = [{ text: 'Sim', value: true }, { text: 'Não', value: false }];
      break;
    case 'gender':
      buildItems = [{ text: _('label/gender_male'), value: 'male' }, { text: _('label/gender_female'), value: 'female' }];
      break;
    default:
      buildItems = items;
      break;
  }

  const onKeyDown = useCallback((item: any) => (e: any) => {
    if (e.keyCode === 32 || e.keyCode === 13) {
      e.stopPropagation();
      e.preventDefault();
      if (onChange) {
        onChange({ name, value: get(item, 'value') });
      }
      setSelected(get(item, 'value'));
    }
  }, [name, onChange]);

  const inputRenderer = useMemo(() => map(buildItems, (item) => {
    const itemName = `${selectGroupName}-${String(item.text)}`;
    const lowerItemName = toLower(itemName).trim().replace(/\s+/g, '');
    const radioId = id ? `radio-${lowerItemName}-${id}` : `radio-${lowerItemName}`;

    const labelElement = (
      <label
        className="text"
        htmlFor={radioId}
        tabIndex="0"
        onKeyDown={onKeyDown(item)}
      >
        {item.text}
      </label>
    );

    const renderContent = {
      // reserved
      node: labelElement,
      innerCssClasses: multiline ? 'radioHolder multiline' : 'radioHolder',
      // classic input props
      id: radioId,
      className: 'radio',
      name: selectGroupName,
      value: item.value,
      checked: defaultValue || uncontrolled ? isEqualValue(item.value, selectedValue) : isEqualValue(item.value, value),
    };

    return renderContent;
  }), [buildItems, selectGroupName, id, onKeyDown, multiline, defaultValue, uncontrolled, selectedValue, value]);


  let cssClasses = 'radioGroup';
  if (className) cssClasses += ` ${className}`;
  if (disabled) cssClasses += ' disabled';

  const handleInputChange = useCallback((data: FormElementData) => {
    if (onChange) {
      onChange({ name: get(data, 'name'), value: get(data, 'value') });
      setSelected(get(data, 'value'));
    }
  }, [onChange]);

  return (
    <Input
      name={name}
      label={label}
      type="radio"
      className={cssClasses}
      inputRenderer={inputRenderer}
      labelClassName="radioGroupLabel"
      onChange={handleInputChange}
      disabled={disabled}
      forceDisabled={disabled}
    />
  );
};

// $FlowFixMe
export default React.memo(RadioGroup);
