import { map } from 'lodash';

// expects array of custom form template objects: [{id: 1, ...}, {id: 2, ...}, ...]
// => returns custom form template object: {1: {...}, 2: {...}, ...}
function normalizeCustomFormTemplates (inputTemplates) {
  const templates = {};

  map(inputTemplates, (template) => {
    templates[template.id] = template;
  });

  return {
    customFormTemplates: templates,
  };
}

export default {
  normalizeCustomFormTemplates,
};
