import React, { useEffect, useMemo, useState } from 'react';
import 'moment/locale/es';
import 'moment/locale/pt';
import 'moment/locale/pt-br';
import { HashRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { createHashHistory as createHistory } from 'history';
import Bugsnag from '@bugsnag/js';
import configureStore from './redux/configureStore';
import fetchInitialState from './redux/initialState';
import { Api, Log } from './helpers';
import { cordovaPurchaseActions } from './redux/modules/context/cordovaPurchase';
import initializeDBEngine from './redux/middleware/idbEngine';
import initializeI18next from './i18n';
import './SocialSharing';
import { App, RefreshData } from './containers';
import Loading from './components/elements/Loading/Loading';
import ErrorBoundaryView from './components/blocks/ErrorBoundaryView/ErrorBoundaryView';

const createNotifiableError = (name, message) => ({ name, message });

const AppEntryPoint = () => {
  const [store, setStore] = useState(null);
  const [appCrashMessage, setAppCrashMessage] = useState(null);
  const [appStartStep, setAppStartStep] = useState('notStarted'); // 'notStarted' | 'starting' | 'step1Ready' | 'startingStep2' | 'ready'

  const history = useMemo(() => createHistory(), []);


  useEffect(() => {
    (async () => {
      if (appStartStep === 'notStarted') {
        try {
          Log.debug('Runing App Start Step 1');
          setAppStartStep('starting');

          Log.debug('Initializing I18Next...');
          await initializeI18next();
          Log.debug('Initializing DB Storage...');
          await initializeDBEngine();
          Log.debug('Fetching initial state...');
          const initialState = await fetchInitialState(window.__INITIAL_STATE__);

          setStore(configureStore(initialState, history));
          Log.debug('Start Step 1 Ready...');
          setAppStartStep('step1Ready');
        } catch (err) {
          setAppCrashMessage(err.message);
          Bugsnag.notify(createNotifiableError('StartError', err.message));
        }
      }
    })();
  }, [history, appStartStep]);

  useEffect(() => {
    try {
      if (appStartStep === 'step1Ready') {
        Log.debug('Runing App Start Step 2');
        setAppStartStep('startingStep2');

        if (window.isCordovaIos) {
          store.dispatch(cordovaPurchaseActions.init());
        }
        // Initialize API Context
        Api.initialize(store);

        if (window.calabash) {
          window.calabash.start(() => {
            Log.debug('Calabash Server started');
          }, () => {
            Log.debug('Error starting Calabash Server');
          });
        }

        setAppStartStep('ready');
        Log.debug('App Start Steps Completed...');
      }
    } catch (err) {
      setAppCrashMessage(err.message);
      Bugsnag.notify(createNotifiableError('StartError', err.message));
    }
  }, [store, appStartStep]);


  if (appCrashMessage) {
    return (
      <div id="errorBoundaryView">
        <h1>Algo deu errado!</h1>
        <div className="errorCopy">
          Tente recarregar o Doutore ou entre em contato com o suporte caso o erro persista.
        </div>
        <div>
          Mensagem de erro:
        </div>
        <div className="error">{appCrashMessage}</div>
      </div>
    );
  }

  if (!store || appStartStep !== 'ready') {
    return <Loading className="fullScreen"/>;
  }

  if (window.ENV !== 'production') {
    return (
      <ErrorBoundaryView>
        <Provider store={store}>
          <HashRouter>
            <RefreshData />
            <App store={store} />
          </HashRouter>
        </Provider>
      </ErrorBoundaryView>
    );
  }

  return (
    <ErrorBoundaryView>
      <Provider store={store}>
        <HashRouter>
          <RefreshData />
          <App store={store} />
        </HashRouter>
      </Provider>
    </ErrorBoundaryView>
  );
};


export default AppEntryPoint;
