// @flow
import React from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import { canUseDOM } from 'exenv';

export default class Portal extends React.Component<any> {
  static propTypes = {
    onOutClick: PropTypes.func,
  };

  constructor(props: any, context: any) {
    super(props, context);

    if (canUseDOM) {
      this.node = document.createElement('div');
      this.root = null;
    }
  }

  componentWillMount() {
    window.addEventListener('click', this.handleOutClick, true);
  }

  componentDidMount() {
    if (canUseDOM) {
      if (document.body) {
        document.body.appendChild(this.node);
      }
    }
  }

  componentWillUnmount() {
    if (canUseDOM) {
      window.removeEventListener('click', this.handleOutClick, true);
      if (document.body) {
        document.body.removeChild(this.node);
      }
    }
  }

  render() {
    const { onOutClick, ...props } = this.props;

    return ReactDOM.createPortal(
      <div {...props} ref={this.handleRootRef} />,
      this.node,
    );
  }

  node : any;
  root: any;

  handleOutClick = (e: any) => {
    const { onOutClick } = this.props;
    if (typeof onOutClick === 'function') {
      if (this.root && !this.root.contains(e.target)) {
        onOutClick(e);
      }
      if (!this.root) {
        onOutClick(e);
      }
    }
  };

  handleRootRef = (root: any) => {
    this.root = root;
  };
}
