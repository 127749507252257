// @flow
import React from 'react';
import { omit, isEqual, debounce } from 'lodash';

type Props = {
  formData?: ?Object,
}

type HocConfig = {
  autoRehydrate: boolean;
  defaultData: any;
}

type State = {
  rehydrating: boolean,
  initialData: Object,
}

/**
 * InjectedProps
 * formData: () => Object,
 * formEdited: () => boolean,
 * touchedFields: () => Object,
 * handleTouchField: Function,
 * handleFormChange: Function,
 * initialData: Object,
 * bindValidation: (fn: Function) => void;
 * rehydrateData: (data: Object, patch: boolean = false) => void; // when patch is true, then apply new attr above defaultdata and initialdata
 * rehydrating: boolean; // use to handle rehidrate on modals (avoid animation flashing)
 */
export const withUnForm = ({ autoRehydrate = true, defaultData = {} }: HocConfig) => (WrappedComponent: any) =>
  class WithUnform extends React.Component<Props, State> {
    constructor(props: Props) {
      super(props);
      this.state = {
        rehydrating: false,
        initialData: this.getResetedData(),
      };
      this.formData = this.getResetedData();
      this.touchedFields = {};
      this.formChanged = false;
      this.validation = () => { };
    }

    shouldComponentUpdate(nextProps: Props, nextState: State) {
      return !isEqual(this.props.formData, nextProps.formData)
        || nextState.rehydrating !== this.state.rehydrating
        || !isEqual(this.state.initialData, nextState.initialData)
        || !isEqual(this.props, nextProps);
    }

    render() {
      const { rehydrating, initialData } = this.state;

      window.forceUpdate = this.rehydrateData;

      if (rehydrating && autoRehydrate) return null;

      return (
        <React.Fragment>
          <WrappedComponent
            {...omit(this.props, ['formData'])}
            initialData={initialData}
            formData={this.getFormData}
            touchedFields={this.getTouchedFields}
            handleTouchField={this.handleTouchField}
            handleFormChange={this.handleFormChange}
            formChanged={this.getFormChanged}
            rehydrateData={this.rehydrateData}
            rehydrating={rehydrating}
            bindValidation={this.bindValidation}
          />
        </React.Fragment>
      );
    }
    /** UncontrolledState */
    formData: Object;
    touchedFields: Object;
    formChanged: boolean;
    validation: Function;
    /** !UncontrolledState */

    getFormData = () => this.formData;
    getTouchedFields = () => this.touchedFields;
    getFormChanged = () => this.formChanged;

    bindValidation = (fn: Function) => {
      this.validation = fn;
    };

    handleFormChange = debounce(({
      name, value, _isComposedChangeEvent, composedValue,
    }: any) => {
      if (_isComposedChangeEvent) {
        this.formData = {
          ...this.formData,
          ...composedValue,
        };
      } else {
        this.formData = {
          ...this.formData,
          [name]: value,
        };
      }
      this.formChanged = true;
      this.handleTouchField({ name });
    }, 100);

    rehydrateData = (initialData: Object, patch: boolean = false) => {
      const newData = patch ? {
        ...this.getResetedData(),
        ...initialData,
      } : initialData || this.getResetedData();

      this.formData = newData;
      this.touchedFields = {};
      this.formChanged = false;

      this.setState(
        { rehydrating: true, initialData: newData },
        () => this.setState({ rehydrating: false }, () => {
          this.handleValidation();
          this.touchedFields = {};
        }),
      );
    };

    handleTouchField = ({ name }: any) => {
      this.touchedFields = {
        ...this.touchedFields,
        [name]: true,
      };
      this.handleValidation();
    };

    getResetedData = () => ({
      ...defaultData,
      ...this.props.formData || {},
    });

    handleValidation = () => {
      if (this.validation) {
        this.validation();
      }
    };
  };

