// @flow
import React from 'react';
import { get } from 'lodash';
import ReactHtmlParser from 'react-html-parser';
import { HMLDOMInjector } from '@elements';


export const replaceHtmlEntitesToText = (text: string) => {
  const reChars = /&(nbsp|amp|quot|lt|gt);/g;
  const charMap = {
    nbsp: ' ',
    amp: '&',
    quot: '"',
    lt: '<',
    gt: '>',
  };

  return String(text || '')
    .replace(reChars, (match: string, entity: string) => get(charMap, entity, entity))
    .replace(/<br\s*[/]?>/gi, '\n');
};

const validTags = ['p', 'br', 'li', 'ul', 'ol', 'strong', 'u', 'i', 'h1', 'h2', 'h3', 'h4'];

/**
 * Kepps only valid html tags
 * Replace's invalid html tags to html entities.
 */
const replaceInvalidTags = (maybeString: string) => {
  const content = maybeString || '';

  const matchHtmlTags = /〰️(\/?[\w\s="/.':;#-\/\?]+)💮/gi;
  // const matchHtmlTags2 = /&lt;(.+?)&gt;/g;
  const validTagsMaxLen = Math.max(...validTags.map(tag => tag.length));

  return String(content)
    // Replace all tags values
    .replace(/</g, '〰️')
    .replace(/>/g, '💮')
    // Find valid tags and templace contents
    .replace(matchHtmlTags, (match: string, entity: string) => {
      const cleanEntity = entity.replace(/\//g, '');
      if (cleanEntity.length <= validTagsMaxLen && validTags.indexOf(cleanEntity) >= 0) {
        return `<${entity}>`;
      }
      return `&lt;${cleanEntity}&gt;`;
    })
    .replace(/(?:\r\n|\r|\n)/g, '<br/>')
    .replace(/〰️/g, '&lt;')
    .replace(/💮/g, '&gt;')
    .replace(/\s/g, '&thinsp;');
};


export const parteHtmlToReact = (text: string, pureHtml: boolean = false) => {
  if (pureHtml) {
    return <HMLDOMInjector __html={String(text || '').replace(/\u00a0/g, ' ').replace(/\&nbsp;/g, ' ')} />;
  }

  return ReactHtmlParser(replaceInvalidTags(String(text || '').replace(/\u00a0/g, ' ').replace(/\&nbsp;/g, ' ')));
};

