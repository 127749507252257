// @flow
import React from 'react';
import { connect } from 'react-redux';
import { get, pick } from 'lodash';
import { GeneralPrintConfig as GeneralPrintConfigView } from '@views';
import type { User, Clinic, BundledPermissions } from '@types';
import { clinicActions } from '@context/clinics';
import { logout } from '@context/user';
import { mapBundledPermission } from '@helpers/connect';


type Props = {
  user: User,
  clinic: Clinic,
  dispatch: Function,
  isSaving: boolean,
  permissions: BundledPermissions,
};
class GeneralPrintConfig extends React.Component<Props> {
  render() {
    const { user, isSaving } = this.props;

    return (
      <GeneralPrintConfigView
        user={user}
        formData={this.getFormData()}
        isLoading={isSaving}
        onSave={this.handleOnSave}
        onLogout={this.handleLogout}
        permissions={this.props.permissions}
      />
    );
  }

  handleLogout = () => this.props.dispatch(logout());

  getFormData = () => {
    const { clinic } = this.props;
    return {
      ...pick(clinic, [
        'name',
        'phone',
        'addressCity',
        'addressState',
        'addressZipcode',
        'addressStreet',
        'addressNumber',
        'addressExtra',
        'addressNeighborhood',
      ]),
    };
  };

  handleOnSave = (data: Object) => {
    const { dispatch } = this.props;
    dispatch(clinicActions.updateGeneralPrintConfig(pick(data, [
      'name',
      'phone',
      'addressCity',
      'addressState',
      'addressZipcode',
      'addressStreet',
      'addressNumber',
      'addressExtra',
      'addressNeighborhood',
    ])));
  }
}

const mapStateToProps = (state) => ({
  user: get(state, ['context', 'user'], {}),
  activeClinic: get(state, ['context', 'clinics', 'activeClinic'], {}),
  clinic: get(state, ['context', 'clinics', 'activeClinic'], {}),
  isSaving: get(state, ['context', 'clinics', 'isSaving'], false),
  permissions: mapBundledPermission(state),
});

export default connect<any, any, any, any, any, any>(
  mapStateToProps,
)(GeneralPrintConfig);
