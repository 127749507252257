// @flow
import Immutable from 'seamless-immutable';
import { downloadOrShare, isReportDownloadByEmail, reportError } from '@helpers/helpers';
import { get, omit, toNumber } from 'lodash';
import { Api } from '../../../helpers';
import ReceiptService from '../../../services/ReceiptService';
import { router } from '../../../routes/router';
import { CLOSE_ACTIVE_TOAST, OPEN_TOAST } from './toasts';
import type {
  Action,
  Dispatch,
  ContextState,
  ReceiptInput,
} from '../../../types';
import showPDF from '../../middleware/showPDF';


// ------------------------------------
// Constants
// ------------------------------------
const RECEIPTS_LOADING_IN_PROGRESS = 'receipts.RECEIPTS_LOADING_IN_PROGRESS';
const RECEIPTS_LOADED = 'receipts.RECEIPTS_LOADED';
const RECEIPTS_LOADING_FAILED = 'receipts.RECEIPTS_LOADING_FAILED';

const RECEIPT_CREATE_SUCCESS = 'receipts.RECEIPT_CREATE_SUCCESS';
const RECEIPT_CREATE_IN_PROGRESS = 'receipts.RECEIPT_CREATE_IN_PROGRESS';
const RECEIPT_CREATE_FAILED = 'receipts.RECEIPT_CREATE_FAILED';

const RECEIPT_UPDATE_IN_PROGRESS = 'receipts.RECEIPT_UPDATE_IN_PROGRESS';
const RECEIPT_UPDATE_SUCCESS = 'receipts.RECEIPT_UPDATE_SUCCESS';
const RECEIPT_UPDATE_FAILED = 'receipts.RECEIPT_UPDATE_FAILED';

const RECEIPT_DELETE_IN_PROGRES = 'receipts.RECEIPT_DELETE_IN_PROGRES';
const RECEIPT_DELETE_SUCCESS = 'receipts.RECEIPT_DELETE_SUCCESS';
const RECEIPT_DELETE_FAILED = 'receipts.RECEIPT_DELETE_FAILED';

const RECEIPT_GET_NEW_FORM_DATA_IN_PROGRESS = 'receipts.RECEIPT_GET_NEW_FORM_DATA_IN_PROGRESS';
const RECEIPT_GET_NEW_FORM_DATA_SUCCESS = 'receipts.RECEIPT_GET_NEW_FORM_DATA_SUCCESS';
const RECEIPT_GET_NEW_FORM_DATA_FAILED = 'receipts.RECEIPT_GET_NEW_FORM_DATA_FAILED';

const RECEIPT_SHOW_PDF_IN_PROGRESS = 'receipts.RECEIPT_SHOW_PDF_IN_PROGRESS';
const RECEIPT_SHOW_PDF_LOADED = 'receipts.RECEIPT_SHOW_PDF_LOADED';
const RECEIPT_SHOW_PDF_FAILED = 'receipts.RECEIPT_SHOW_PDF_FAILED';

const RECEIPT_REPORT_IN_PROGRESS = 'receipts.RECEIPT_REPORT_IN_PROGRESS';
const RECEIPT_REPORT_SUCCESS = 'receipts.RECEIPT_REPORT_SUCCESS';
const RECEIPT_REPORT_FAILED = 'receipts.RECEIPT_REPORT_FAILED';


// ------------------------------------
// Actions
// ------------------------------------

export const getReceiptsReport = (startDate: string, endDate: string, doctorId: number | null) => (
  (dispatch: Dispatch, getState: Function) => {
    dispatch({ type: OPEN_TOAST, data: { type: 'inProgress', text: 'Preparando relatório' } });
    dispatch({ type: RECEIPT_REPORT_IN_PROGRESS });
    const route = router.getApiRoute('getReceiptsReport', null);

    const userEmail = get(getState(), ['context', 'user', 'email']);

    const queryFilters = {
      'filter[doctorId]': doctorId,
      'filter[startDate]': startDate,
      'filter[endDate]': endDate,
    };

    const isDownload = !isReportDownloadByEmail(getState);

    if (isDownload) {
      downloadOrShare({
        url: route.path,
        state: getState(),
        mime: 'application/vnd.ms-excel',
        fileName: 'report_receipts_{t}.xlsx',
        dispatch,
        params: queryFilters,
      }).then(({ shared }) => {
        if (!shared) {
          dispatch({
            type: CLOSE_ACTIVE_TOAST,
          });
        }
        dispatch({
          type: RECEIPT_REPORT_SUCCESS,
        });
      }).catch((error) => {
        dispatch({
          type: OPEN_TOAST,
          data: {
            type: 'error', text: 'Ocorreu um erro ao processar seu relatório.', close: true, timeout: 10000,
          },
        });
        dispatch({ type: RECEIPT_REPORT_FAILED, error: { message: error.message } });
      });
    } else {
      Api.get(route, {
        query: queryFilters,
        success: () => {
          dispatch({
            type: OPEN_TOAST,
            data: {
              type: 'success', text: `Relatório enviado para o seu email ${userEmail}`, close: true, timeout: 10000,
            },
          });
          dispatch({
            type: RECEIPT_REPORT_SUCCESS,
          });
        },
        error: (error) => {
          dispatch({
            type: OPEN_TOAST,
            data: {
              type: 'error', text: 'Ocorreu um erro ao processar seu relatório.', close: true, timeout: 10000,
            },
          });
          dispatch({ type: RECEIPT_REPORT_FAILED, error: { message: error.message } });
          dispatch(reportError(error));
        },
      });
    }
  }
);

export const deleteReceipt = (patientId: number, id: number) => (
  (dispatch: Dispatch) => {
    dispatch({ type: RECEIPT_DELETE_IN_PROGRES });
    Api.destroy(router.getApiRoute('deleteReceipt', { patientId, id }), {
      success: () => {
        dispatch({ type: RECEIPT_DELETE_SUCCESS, deletedId: id });
      },
      error: (error) => {
        dispatch({ type: RECEIPT_DELETE_FAILED, error: { message: error.message } });
        dispatch(reportError(error));
      },
    });
  });


export const updateReceipt = (patientId: number, id: number, receipt:ReceiptInput) => (
  (dispatch: Dispatch) => {
    dispatch({ type: RECEIPT_UPDATE_IN_PROGRESS });
    Api.patch(router.getApiRoute('updateReceipt', { id, patientId }), {
      data: { receipt },
      success: (response) => {
        dispatch({ type: RECEIPT_UPDATE_SUCCESS, receipt: response.receipt, archivedId: id });
      },
      error: (error) => {
        dispatch({ type: RECEIPT_UPDATE_FAILED, error: { message: error.message } });
        dispatch(reportError(error));
      },
    });
  }
);

export const getNewReceipt = (patientId: number, doctorId: number) => (
  (dispatch: Dispatch) => {
    dispatch({ type: RECEIPT_GET_NEW_FORM_DATA_IN_PROGRESS });
    const route = router.getApiRoute('getNewReceipt', { patientId });
    Api.get(route, {
      query: { doctorId },
      success: (response) => {
        dispatch({
          type: RECEIPT_GET_NEW_FORM_DATA_SUCCESS,
          receipt: response.receipt,
        });
      },
      error: (error) => {
        dispatch({ type: RECEIPT_GET_NEW_FORM_DATA_FAILED, error: { message: error.message } });
        dispatch(reportError(error));
      },
    });
  }
);


export const getReceipts = (startDate: string, endDate: string, doctorId: number | null) => (
  (dispatch: Dispatch) => {
    dispatch({ type: RECEIPTS_LOADING_IN_PROGRESS });
    const route = router.getApiRoute('getReceipts', null);

    const queryFilters = {
      'filter[doctorId]': doctorId,
      'filter[startDate]': startDate,
      'filter[endDate]': endDate,
    };

    Api.get(route, {
      query: queryFilters,
      success: (response) => {
        dispatch({
          type: RECEIPTS_LOADED,
          receipts: response.receipts,
        });
      },
      error: (error) => {
        dispatch({ type: RECEIPTS_LOADING_FAILED, error: { message: error.message } });
        dispatch(reportError(error));
      },
    });
  }
);

export const getPatientReceipts = (patientId: number) => (
  (dispatch: Dispatch) => {
    dispatch({ type: RECEIPTS_LOADING_IN_PROGRESS, patientId });
    const route = router.getApiRoute('getPatientReceipts', { patientId });
    Api.get(route, {
      success: (response) => {
        dispatch({
          type: RECEIPTS_LOADED,
          receipts: response.receipts,
          patientId,
        });
      },
      error: (error) => {
        dispatch({ type: RECEIPTS_LOADING_FAILED, error: { message: error.message } });
        dispatch(reportError(error));
      },
    });
  }
);

export const createReceipt = (patientId: number, receipt: ReceiptInput, doctorId: number) => (
  (dispatch: Dispatch) => {
    dispatch({ type: RECEIPT_CREATE_IN_PROGRESS });
    const route = router.getApiRoute('createReceipt', { patientId });
    Api.post(route, {
      data: { receipt, doctorId },
      success: (response) => {
        dispatch({
          type: RECEIPT_CREATE_SUCCESS,
          receipt: get(response, ['data', 'receipt']),
        });
      },
      error: (error) => {
        dispatch({ type: RECEIPT_CREATE_FAILED, error: { message: error.message } });
        dispatch(reportError(error));
      },
    });
  }
);

export const showReceiptPDF = (id: number, patientId: number) => showPDF.action({
  title: 'Receipt PDF',
  apiRoute: router.getApiRoute('showReceiptPDF', { id, patientId }),
  prepare: RECEIPT_SHOW_PDF_IN_PROGRESS,
  success: RECEIPT_SHOW_PDF_LOADED,
  failed: RECEIPT_SHOW_PDF_FAILED,
});

export const receiptActions = {
  getReceipts,
  getPatientReceipts,
  showReceiptPDF,
  // getReceipt,
  getNewReceipt,
  createReceipt,
  updateReceipt,
  deleteReceipt,
  getReceiptsReport,
};

// ------------------------------------
// Reducer
// ------------------------------------
export default function receiptsReducer (state: ContextState, action: Action): ContextState {
  const receipt = get(action, ['receipt'], {});

  switch (action.type) {
    case RECEIPTS_LOADING_IN_PROGRESS:
      return state
        .setIn(['receipts', 'isLoading'], true)
        .setIn(['receipts', 'isLoadingReport'], false)
        .setIn(['receipts', 'currentPatientId'], toNumber(action.patientId))
        .setIn(['receipts', 'errors'], null);

    case RECEIPTS_LOADED:
      const normalized = ReceiptService.normalizeReceipts(action.receipts);
      return state
        .setIn(['receipts', 'isLoading'], false)
        .setIn(['receipts', 'errors'], null)
        .setIn(['receipts', 'currentPatientId'], toNumber(action.patientId))
        .setIn(['receipts', 'data'], normalized.receipts);

    case RECEIPTS_LOADING_FAILED:
      return state
        .setIn(['receipts', 'isLoading'], false)
        .setIn(['receipts', 'errors'], [action.error.message]);

    case RECEIPT_CREATE_IN_PROGRESS:
      return state
        .setIn(['receipts', 'isSaving'], true)
        .setIn(['receipts', 'errors'], null);

    case RECEIPT_CREATE_SUCCESS:
      return state
        .setIn(['receipts', 'isSaving'], false)
        .setIn(['receipts', 'errors'], null)
        .setIn(['receipts', 'data', String(receipt.id)], receipt);

    case RECEIPT_CREATE_FAILED:
      return state
        .setIn(['receipts', 'isSaving'], false)
        .setIn(['receipts', 'isLoading'], false)
        .setIn(['receipts', 'errors'], [action.error.message]);

    case RECEIPT_UPDATE_IN_PROGRESS:
      return state
        .setIn(['receipts', 'isSaving'], true)
        .setIn(['receipts', 'errors'], null);

    case RECEIPT_UPDATE_SUCCESS:
      const curreReceiptsList = get(state, ['receipts', 'data'], Immutable({}));
      const reacretedReceiptsList = omit(curreReceiptsList, [get(action, 'archivedId', null)]);
      reacretedReceiptsList[receipt.id] = receipt;
      return state
        .setIn(['receipts', 'isSaving'], false)
        .setIn(['receipts', 'errors'], null)
        .setIn(['receipts', 'data'], reacretedReceiptsList);

    case RECEIPT_UPDATE_FAILED:
      return state
        .setIn(['receipts', 'isSaving'], false)
        .setIn(['receipts', 'errors'], [action.error.message]);

    case RECEIPT_DELETE_IN_PROGRES:
      return state
        .setIn(['receipts', 'isSaving'], true)
        .setIn(['receipts', 'errors'], null);

    case RECEIPT_DELETE_SUCCESS:
      const receiptsList = get(state, ['receipts', 'data'], Immutable({}));
      const newReceiptsList = omit(receiptsList, [String(action.deletedId)]);
      return state
        .setIn(['receipts', 'isSaving'], false)
        .setIn(['receipts', 'data'], newReceiptsList);

    case RECEIPT_DELETE_FAILED:
      return state
        .setIn(['receipts', 'isSaving'], false)
        .setIn(['receipts', 'errors'], [action.error.message]);

    case RECEIPT_GET_NEW_FORM_DATA_IN_PROGRESS:
      return state
        .setIn(['receipts', 'isLoadingNew'], true)
        .setIn(['receipts', 'errors'], null);

    case RECEIPT_GET_NEW_FORM_DATA_SUCCESS:
      return state
        .setIn(['receipts', 'isLoadingNew'], false)
        .setIn(['receipts', 'errors'], null)
        .setIn(['receipts', 'formData'], get(action, 'receipt', Immutable({})));

    case RECEIPT_GET_NEW_FORM_DATA_FAILED:
      return state
        .setIn(['receipts', 'isSaving'], false)
        .setIn(['receipts', 'isLoading'], false)
        .setIn(['receipts', 'isLoadingNew'], false)
        .setIn(['receipts', 'errors'], [action.error.message]);

    case RECEIPT_REPORT_IN_PROGRESS:
      return state
        .setIn(['receipts', 'isLoadingReport'], true);

    case RECEIPT_REPORT_SUCCESS:
      return state
        .setIn(['receipts', 'isLoadingReport'], false);

    case RECEIPT_REPORT_FAILED:
      return state
        .setIn(['receipts', 'isLoadingReport'], false);

    default:
      return state;
  }
}
