// @flow
import React, { useMemo } from 'react';
import { get } from 'lodash';
import { SettingsMenu, PlatformSwitch, AppMobileHeader, AppMobileFooter } from '@blocks';
import type { User, BundledPermissions } from '@types';
import { ClickableList } from '@elements';
import { useTranslationNs } from '@helpers/helpers';

type Props = {
  user: User,
  onLogout: Function,
  permissions: BundledPermissions,
  isManager: boolean,
  isDoctor: boolean,
};

const PrintConfig = ({ user, onLogout, isManager, isDoctor, permissions }: Props) => {
  const [_] = useTranslationNs('settings');

  const items = useMemo(() => [
    {
      text: _('label/print_config/menu/general'),
      linkTo: '/ajustes/impressao/geral',
      hidden: !isManager,
    },
    {
      text: _('label/print_config/menu/eletronic_receipts'),
      linkTo: '/ajustes/impressao/receituario',
      hidden: !isDoctor,
    },
    {
      text: _('label/print_config/menu/controlled_receipts'),
      linkTo: '/ajustes/impressao/receituario-controlado',
      hidden: !isDoctor,
    },
    ...(window.isCordovaApp ? [] : [
      {
        text: _('label/print_config/menu/clinic_logo'),
        linkTo: '/ajustes/impressao/logo-da-clinica',
        hidden: !isManager,
      },
    ]),
  ].filter(it => !get(it, 'hidden')), [_, isDoctor, isManager]);

  return (
    <PlatformSwitch
      inject
      desktop={() => (
        <div className="appContent settingsPage">
          <aside className="sidebar">
            <SettingsMenu user={user} onLogout={onLogout} permissions={permissions} />
          </aside>
          <section className="mainSection">
            <div className="centerContent">
              <div className="toolbar dashed">
                <h1>{_('label/print_config/menu/title')}</h1>
              </div>
              <ClickableList items={items} card />
            </div>
          </section>
        </div>
      )}
      mobile={() => (
        <React.Fragment>
          <AppMobileHeader title={_('label/print_config/menu/title')} backLinkTo="/ajustes" />
          <div className="appContent settingsPage">
            <section className="mainSection">
              <ClickableList items={items} card />
            </section>
          </div>
          <AppMobileFooter />
        </React.Fragment>
      )}
    />
  );
};

export default PrintConfig;
