// @flow

import React from 'react';
import classNames from 'classnames';
import { map } from 'lodash';
import { Button, Icon, HiddenLines, Dropdown } from '@elements';
import type { Record, PermissionTo, Patient, PlanInclude } from '@types';
import { parteHtmlToReact as parseHtml } from '@helpers/helpers';

type Props = {
  maxTextChars: number,
  shareDropdownItems: Function,
  onShareClick: Function,
  actionClasses: any,
  examsPrescriptionsContent: any,
  isCordova: boolean,
  isEditable: any,
  isMobile: boolean,
  mainSection: any,
  onEditExamsPrescriptionClick: Function,
  onPrintExamsPrescriptionClick: Function,
  patient?: Patient,
  planInclude: PlanInclude,
  record: Record,
  permissionsTo: PermissionTo,
  printDropdownItems: Function,
  _: Function,
  isMine: boolean,
  directShareLoading: boolean,
};

const ExamsPrescriptionsContent = ({ _, actionClasses, examsPrescriptionsContent, isCordova, isEditable, isMobile, mainSection,
  maxTextChars, onEditExamsPrescriptionClick, onPrintExamsPrescriptionClick, onShareClick, patient, isMine, directShareLoading,
  permissionsTo, planInclude, printDropdownItems, record, shareDropdownItems }: Props) => map(examsPrescriptionsContent, (prescription) => (
    <div className={classNames('record examsPrescription freeText', actionClasses)} key={prescription.id}>
      <div className='examsPrescriptionsTitleWraper'>
        <span className={classNames('recordTitle', { isMobile: isCordova })}>{_('label/exam_request')}</span>
        <div className={classNames('examsPrescriptionActions recordContentActions', { isMobile: isMobile || isCordova })}>
          {isEditable && onEditExamsPrescriptionClick && patient && planInclude.examsDrugsDocuments &&
          <Button
            text={<React.Fragment><Icon iconName="edit" size={18} />{!window.isCordovaApp && (<React.Fragment>&nbsp;&nbsp;</React.Fragment>)}{!window.isCordovaApp && _('action/edit', 'global')}</React.Fragment>}
            className='outlineButton buttonLike'
            onClick={onEditExamsPrescriptionClick(record.id, prescription.id, patient.name)}
          />
        }
          {permissionsTo.doctorShowSignature && isMine && (
          <Dropdown
            className={window.isCordovaApp ? 'outlineButton singleIconFix buttonLike' : 'outlineButton buttonLike'}
            placeholder={(
              <React.Fragment>
                {window.isCordovaApp && (
                  <React.Fragment><Icon iconName="print" className='icon' size={18} /></React.Fragment>
                )}
                {!window.isCordovaApp && (
                  <React.Fragment><Icon iconName="print" className='icon' size={18} />{!window.isCordovaApp && (<React.Fragment>&nbsp;&nbsp;</React.Fragment>)}{_('action/print')}</React.Fragment>
                )}
              </React.Fragment>
            )}
            disableAutoModalTitle
            disableArrowIcon={isMobile}
            items={printDropdownItems(window.isCordovaApp ? 'Compartilhar impressão de Pedido de Exame' : 'Imprimir Pedido de Exame')}
            onSelectItem={({ action }) => onPrintExamsPrescriptionClick(record.id, prescription.id, action)()}
          />
          )}
          {!(permissionsTo.doctorShowSignature && isMine) && (
          <Button
            className='outlineButton buttonLike'
            onClick={onPrintExamsPrescriptionClick(record.id, prescription.id)}
          >
            <React.Fragment>
              {window.isCordovaApp && (
              <React.Fragment><Icon iconName="print" className='icon' size={18} /></React.Fragment>
              )}
              {!window.isCordovaApp && (
              <React.Fragment><Icon iconName="print" className='icon' size={18} />{!window.isCordovaApp && (<React.Fragment>&nbsp;&nbsp;</React.Fragment>)}{_('action/print')}</React.Fragment>
              )}
            </React.Fragment>
          </Button>
          )}
          {isMine && (
            <Dropdown
              className={window.isCordovaApp ? 'outlineButton singleIconFix newShareFeature buttonLike' : 'outlineButton newShareFeature buttonLike'}
              placeholder={(
                <React.Fragment>
                  {window.isCordovaApp && (
                  <React.Fragment><Icon iconName={directShareLoading ? 'spinner' : 'share'} className='icon' size={18} /></React.Fragment>
                  )}
                  {!window.isCordovaApp && (
                  <React.Fragment><Icon iconName={directShareLoading ? 'spinner' : 'share'} className='icon' size={18} />{!window.isCordovaApp && (<React.Fragment>&nbsp;&nbsp;</React.Fragment>)}{_('action/share')}</React.Fragment>
                  )}
                </React.Fragment>
            )}
              disableAutoModalTitle
              disableArrowIcon={isMobile}
              items={shareDropdownItems}
              onClick={onShareClick('examsPrescriptions', prescription.id)}
              forceToBeClosed={directShareLoading}
            />
          )}
          {/* <Button
          className='outlineButton buttonLike'
          onClick={onPrintExamsPrescriptionClick(record.id, prescription.id, true, true)}
        >
          <Icon iconName='mail-send' className='icon' size={18} />
        </Button> */}
        </div>
      </div>
      <div className="recordContent ritchTextContent">
        <HiddenLines maxChars={maxTextChars} text={prescription.notes} scrollContainer={mainSection}>
          {text => <div>{parseHtml(text, false)}</div>}
        </HiddenLines>
      </div>
    </div>
));



// $FlowFixMe
export default React.memo(ExamsPrescriptionsContent);
