// @flow
import React, { useState, useEffect, useMemo, useCallback, useRef } from 'react';
import moment from 'moment';
import { get, isEmpty, isEqual } from 'lodash';
import { useSelector } from 'react-redux';
import { deepEqual } from 'fast-equals';
import { PatientSummary } from '@blocks';
import { Icon, ClickableList, Button, ImageView } from '@elements';
import { arrayBufferToBase64, isPresent, useTranslationNs, UserNotifications, easyOpenCache } from '@helpers/helpers';
import type { Patient } from '@types';
import { mapPlanIncludes } from '@helpers/connect';
import Queue from 'smart-request-balancer';
import './PatientSidebar.scss';

const shallowEqual = (l, r) => deepEqual(l, r);

type Props = {
  patient: Patient,
  onSelectPatientProfileImage: Function,
  onCaptureProfileImage: Function,
  onDeletePatientProfileImage: Function,
  isFinatialEnabled: boolean,
};

const MAX_REQUEST_TRIES = 3;


const PatientSidebar = ({ patient, isFinatialEnabled, onCaptureProfileImage, onDeletePatientProfileImage, onSelectPatientProfileImage }: Props) => {
  const [thumbLoading, setThumbLoading] = useState(false);
  const [loadedImageThumb, setLoadedImageThumb] = useState(null);
  const [cachedThumbUrl, setCachedThumbUrl] = useState(null);
  const [renderTries, setRenderTries] = useState(0);
  const controlTries = useRef(0);
  const systemDialogEl = useRef(null);

  const planInclude = useSelector(state => mapPlanIncludes(state), shallowEqual);

  const queue = useMemo(() => new Queue({ rules: {
    default: {
      limit: 1,
      rate: 1,
      priority: 1,
    },
  } }), []);

  const setTries = useCallback((val: number) => {
    controlTries.current = val;
    setRenderTries(val);
  }, []);

  const [_] = useTranslationNs('patientSidebar');

  const thumbUrl = useMemo(() => get(patient, ['profileImage', 'thumbUrl']), [patient]);
  const loadThumbUrl = useCallback((url: any) => {
    if (!url) return null;

    const onError = () => {
      if (controlTries.current <= MAX_REQUEST_TRIES) {
        setTries(controlTries.current + 1);
        setTimeout(() => loadThumbUrl(url), 3000);
      } else {
        setTries(controlTries.current + 1);
      }
    };

    easyOpenCache('patients-profile-images', (cache: Cache | null) => {
      const executeRequest = () => {
        if (controlTries.current > MAX_REQUEST_TRIES) return setThumbLoading(false);

        setThumbLoading(true);

        queue.request(() => fetch(url, {
          method: 'GET',
          mode: 'cors',
        }).then((response) => {
          if (response.ok) {
            if (cache) {
              cache.add(url);
            }
            response.arrayBuffer().then((buffer) => {
              try {
                const base64Flag = 'data:image/jpeg;base64,';
                const imageStr = arrayBufferToBase64(buffer);
                setCachedThumbUrl(url);
                setThumbLoading(false);
                setLoadedImageThumb(base64Flag + imageStr);
              } catch (err) {
                onError();
              }
            }).catch(onError);
          } else {
            onError();
          }
        }).catch(onError), url, 'default');
      };

      if (cache) {
        cache.match(url, {
          ignoreSearch: true,
          ignoreVary: true,
        }).then((response) => {
          if (response.ok) {
            response.arrayBuffer().then((buffer) => {
              try {
                const base64Flag = 'data:image/jpeg;base64,';
                const imageStr = arrayBufferToBase64(buffer);
                setCachedThumbUrl(url);
                setThumbLoading(false);
                setLoadedImageThumb(base64Flag + imageStr);
              } catch (err) {
                onError();
              }
            }).catch(onError);
          } else {
            executeRequest();
          }
        }).catch(executeRequest);
      } else {
        setThumbLoading(true);
        executeRequest();
      }
    });
  }, [queue, setTries]);

  useEffect(() => {
    if (!isEqual(cachedThumbUrl, thumbUrl)) {
      setTries(0);
      loadThumbUrl(thumbUrl);
    }
  }, [cachedThumbUrl, loadThumbUrl, setTries, thumbUrl]);


  const profileImage = useMemo(() => get(patient, ['profileImage']), [patient]);
  const indebt = useMemo(() => get(patient, 'indebt', false), [patient]);

  const items = useMemo(() => [
    {
      linkTo: `/paciente/${get(patient, 'id')}/cadastro`,
      customRendererData: {
        text: _('label/patient_record', 'global'),
        icon: 'arrow-right',
      },
    },
    {
      linkTo: `/paciente/${get(patient, 'id')}/prontuario`,
      customRendererData: {
        text: _('label/records', 'global'),
        icon: 'arrow-right',
      },
    },
    ...(planInclude.indicators ? [{
      linkTo: `/paciente/${get(patient, 'id')}/indicadores`,
      customRendererData: {
        text: _('label/indicators', 'global'),
        icon: 'arrow-right',
      },
    }] : []),
    {
      linkTo: `/paciente/${get(patient, 'id')}/financeiro`,
      customRendererData: {
        text: _('label/financial', 'global'),
        icon: 'arrow-right',
        showNotification: isFinatialEnabled ? indebt : false,
      },
    },
  ], [_, indebt, isFinatialEnabled, patient, planInclude]);


  /**
   * Base State upload handling
   */
  const birthdate = get(patient, 'birthdate', '');
  const profession = get(patient, 'profession', '');
  const insuranceCompany = get(patient, 'insuranceCompany', '');

  const showSummaryBirthdate = useMemo(() => (birthdate ? moment(birthdate).isValid() : false), [birthdate]);
  const showSummaryProfession = useMemo(() => profession && !isEmpty(profession), [profession]);
  const showSummaryInsuranceCompany = useMemo(() => insuranceCompany && !isEmpty(insuranceCompany), [insuranceCompany]);

  const showSummary = useMemo(
    () => showSummaryBirthdate || showSummaryProfession || showSummaryInsuranceCompany,
    [showSummaryBirthdate, showSummaryInsuranceCompany, showSummaryProfession],
  );

  const imageProgress = useMemo(() => get(profileImage, 'progress', ''), [profileImage]);
  const imageInProgress = useMemo(() => get(imageProgress, 'length', 0) > 0 || thumbLoading, [imageProgress, thumbLoading]);
  const imageLoading = useMemo(() => !imageInProgress && thumbLoading, [imageInProgress, thumbLoading]);

  const imageReady = useMemo(
    () => profileImage && !imageLoading && !imageInProgress && loadedImageThumb && isPresent(loadedImageThumb),
    [imageInProgress, imageLoading, loadedImageThumb, profileImage],
  );

  const profileImagePreparing = useMemo(() => (imageInProgress || imageLoading) && (
    <span className='imageProgressIndicator'>{imageLoading ? _('label/sending') : _('label/loading')}</span>
  ), [_, imageInProgress, imageLoading]);

  const handleDeleteProfileImage = useCallback(() => {
    UserNotifications.confirmI18n('alert/confirm_delete_file', 'warning', '#FFA433', true)
      .then((deleteFile) => {
        if (deleteFile) {
          onDeletePatientProfileImage();
        }
      });
  }, [onDeletePatientProfileImage]);

  const profileImageThumb = useMemo(() => imageReady && (
    <ImageView
      src={profileImage.url}
      thumbnailSrc={thumbUrl}
      thumbnailClassName="photo"
      alt="patient profile"
      onClickDelete={handleDeleteProfileImage}
    />
  ), [imageReady, profileImage, thumbUrl, handleDeleteProfileImage]);

  const hasErrorLoadingImage = renderTries >= MAX_REQUEST_TRIES;

  const patientPhotoClasses = useMemo(() => (
    imageInProgress || imageLoading || hasErrorLoadingImage
      ? 'patientPhoto imageInProgress'
      : 'patientPhoto'
  ), [imageInProgress, imageLoading, hasErrorLoadingImage]);


  const renderer = useCallback((item, key) => ([
    <span key={`text-${key}`}>{item.text}</span>,
    <div key={`notification-${key}`}>
      {item.showNotification &&
        <span className="notifyDot" />
      }
      <Icon key={`icon-${key}`} className="icon" iconName={item.icon} />
    </div>,
  ]), []);

  const handleCaptureProfileImage = useCallback((e: Object) => {
    e.stopPropagation();
    onCaptureProfileImage();
  }, [onCaptureProfileImage]);

  const handleSelectPatientProfileImage = useCallback((e: Object) => {
    const { files } = e.target;
    const file = files[0];

    const patientId = get(patient, ['id']);

    if (patientId && (file.type === 'image/png' || file.type === 'image/jpg' || file.type === 'image/jpeg' || file.type === 'image/jfif')) {
      const reader = new FileReader();

      reader.onload = (readerEvent: any) => {
        const img = new Image();

        img.onload = () => {
          file.width = img.naturalWidth;
          file.height = img.naturalHeight;
          onSelectPatientProfileImage(file);
        };

        img.src = String(readerEvent.target.result);
      };

      reader.readAsDataURL(file);

      if (thumbLoading) {
        setThumbLoading(false);
      }
    }
  }, [onSelectPatientProfileImage, patient, thumbLoading]);

  const shouldRenderProfileImage = Boolean(profileImage) && !hasErrorLoadingImage;

  const shouldRenderCameraButton = !profileImage || hasErrorLoadingImage;

  const handleOpenSystemDialog = useCallback(() => {
    if (!shouldRenderCameraButton || !systemDialogEl.current) return;

    systemDialogEl.current.click();
  }, [shouldRenderCameraButton]);

  return (
    <aside className="sidebar left">
      <div
        className={patientPhotoClasses}
        onClick={handleOpenSystemDialog}
      >
        {shouldRenderProfileImage && (profileImagePreparing || profileImageThumb)}

        {shouldRenderCameraButton && (
          <Button
            className="addPhotoBtn"
            onClick={!thumbLoading ? handleCaptureProfileImage : null}
          >
            <Icon iconName="camera-add" size={19} />
          </Button>
        )}

        {hasErrorLoadingImage && (
          <span className='imageProgressIndicator'>Erro</span>
        )}

        <input
          id='systemDialogEl'
          type='file'
          multiple
          ref={systemDialogEl}
          style={{ display: 'none' }}
          onChange={handleSelectPatientProfileImage}
        />

      </div>

      <span className="patientName">{get(patient, 'name', '')}</span>

      <ClickableList
        items={items}
        customRenderer={renderer}
      />

      <PatientSummary patient={patient} mobileVisible={showSummary} />
    </aside>
  );
};

// $FlowFixMe
export default React.memo(PatientSidebar, isEqual);
