// @flow
import React from 'react';
import { connect } from 'react-redux';
import { get } from 'lodash';
import { PrescriptionDrugsFormModal } from '../../components/views';
import { actions } from '../../redux/modules/context/modals';
import { drugsPrescriptionActions } from '../../redux/modules/context/drugsPrescriptions';
import { drugsPrescriptionsTemplateActions } from '../../redux/modules/context/drugsPrescriptionsTemplates';
import type {
  Dispatch, DrugsPrescription, DrugsPrescriptionInput,
  DrugsPrescriptionsTemplatesState,
} from '../../types';

type Props = {
  recordId: number,
  patientName: string,
  drugsPrescription: DrugsPrescription,
  drugsPrescriptionsTemplates: DrugsPrescriptionsTemplatesState,
  dispatch: Function,
  isSaving: boolean,
  isDeleting: boolean,
};

class PrescriptionDrugsFormModalContainer extends React.Component<Props> {
  componentWillMount() {
    this.props.dispatch(drugsPrescriptionsTemplateActions.getDrugsPrescriptionsTemplates());
  }

  render() {
    const {
      recordId, patientName, drugsPrescription,
      drugsPrescriptionsTemplates, isSaving, isDeleting,
    } = this.props;

    return (
      <PrescriptionDrugsFormModal
        recordId={recordId}
        patientName={patientName}
        drugsPrescription={drugsPrescription}
        drugsPrescriptionsTemplates={drugsPrescriptionsTemplates}
        onAddNewDrugsPrescription={this.handleCreateDrugsPrescription}
        onAddNewAndPrintDrugsPrescription={this.handleCreateAndPrintDrugsPrescription}
        onUpdateDrugsPrescription={this.handleUpdateDrugsPrescription}
        onUpdateAndPrintDrugsPrescription={this.handleUpdateAndPrintDrugsPrescription}
        onDeleteDrugsPrescription={this.handleDeleteDrugsPrescription}
        onClose={this.closeModal}
        isSaving={isSaving}
        isDeleting={isDeleting}
      />
    );
  }

  closeModal = () => {
    this.props.dispatch(actions.closeActiveModal());
  };

  handleCreateDrugsPrescription = (recordId: number, drugsPrescription: DrugsPrescriptionInput) => {
    this.props.dispatch(drugsPrescriptionActions.createDrugsPrescription(recordId, drugsPrescription, this.closeModalOnSuccess));
  };

  handleCreateAndPrintDrugsPrescription = (recordId: number, drugsPrescription: DrugsPrescriptionInput) => {
    this.props.dispatch(drugsPrescriptionActions.createAndShowDrugsPrescriptionPDF(recordId, drugsPrescription, this.closeModalOnSuccess));
  };

  handleUpdateDrugsPrescription = (recordId: number, prescriptionId: number, drugsPrescription: DrugsPrescriptionInput) => {
    this.props.dispatch(drugsPrescriptionActions.updateDrugsPrescription(recordId, prescriptionId, drugsPrescription, this.closeModalOnSuccess));
  };

  handleUpdateAndPrintDrugsPrescription = (recordId: number, prescriptionId: number, drugsPrescription: DrugsPrescriptionInput) => {
    this.props.dispatch(drugsPrescriptionActions.updateAndShowDrugsPrescriptionPDF(recordId, prescriptionId, drugsPrescription, this.closeModalOnSuccess));
  };

  handleDeleteDrugsPrescription = (recordId: number, prescriptionId: number) => {
    this.props.dispatch(drugsPrescriptionActions.deleteDrugsPrescription(recordId, prescriptionId, this.closeModalOnSuccess));
  };

  closeModalOnSuccess = (success: boolean) => {
    if (success) {
      this.closeModal();
    }
  };
}

const mapStateToProps = (state) => ({
  recordId: state.context.modals.data.recordId,
  patientName: state.context.modals.data.patientName,
  isSaving: get(state, ['context', 'drugsPrescriptions', 'isSaving'], false),
  isDeleting: get(state, ['context', 'drugsPrescriptions', 'isDeleting'], false),
  drugsPrescription: get(state.context, ['drugsPrescriptions', 'data', state.context.modals.data.prescriptionId], {}),
  drugsPrescriptionsTemplates: get(state.context, ['drugsPrescriptionsTemplates'], {}),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({ dispatch });

export default connect<any, any, any, any, any, any>(
  mapStateToProps,
  mapDispatchToProps,
)(PrescriptionDrugsFormModalContainer);
