// @flow
import React from 'react';
import { includes, get } from 'lodash';
import { isPresent, shortenName } from '@helpers/helpers';
import { Icon, Input, Checkbox, FormattedText } from '@elements';
import moment from 'moment';

// TODO: Handle key's

export const dayPickerInputRenderer = (_: Function) => (date: any, callback: Function) => (
  <div className="calendarTrigger btn" onClick={callback}>
    <Icon iconName="calendar" className="icon" size={16} />
    <span className="dateLabel">{`${_('action/schedule_for')} ${moment(date, 'DD/MM/YYYY').format('DD/MM/YYYY')}`}</span>
    <Icon iconName="edit" className="icon" size={16} />
  </div>
);

export const appointmentModalListRenderer = (errors: Array<string>, handleFormChange: Function, notes: any, showNotes: boolean, title: any) => (item: Object) => {
  if (item.type === 'notesField') {
    return ([
      <Icon
        className="icon"
        key="leftModalInputIcon"
        iconName={item.input.icon}
        size={24}
      />,
      <Input
        key="leftModalInputPlaceholder"
        type="text"
        name="notes"
        placeholder={item.input.placeholder}
        onChange={handleFormChange}
        value={notes}
        disabled={item.disabled}
      />,
      isPresent(notes) && (
      <div key="leftModalCheckbox" id="notesOnRegistry">
        <Checkbox
          name="showNotes"
          label={item.checkbox.label}
          onChange={handleFormChange}
          isChecked={showNotes}
          disabled={item.disabled}
        />
      </div>
      ),
    ]);
  }

  if (item.type === 'titleField') {
    return ([
      <Icon
        className="icon"
        key="leftModalInputIcon"
        iconName={item.input.icon}
        size={24}
      />,
      <Input
        key="leftModalInputPlaceholder"
        type="text"
        name="title"
        placeholder={item.input.placeholder}
        onChange={handleFormChange}
        value={title}
        disabled={item.disabled}
      />,
    ]);
  }

  const isValid = !item.validate || (item.validate && !includes(errors, item.validate));

  return ([
    <span className={`appointmentModalText${item.disabled && ' disabled'}`} key={`appointmentModalText${item.id}${item.date}`}>
      <Icon iconName={item.icon} className="icon" size={24} />
      <span className={!isValid ? 'error' : null}>
        {item.text}
        {item.phone && (
          <div className="appointmentPatientItemPhone">
            <FormattedText type="phone" text={item.phone} />
          </div>
        )}
      </span>

      {(item.counter && item.counter > 0) ? <span className='counter'>{`(${item.counter})`}</span> : null}
    </span>,
    <Icon key="appointmentModaIcon" iconName="arrow-right" className="icon" />,
  ]);
};

export const newPatientRenderer = (item: Object) => ([
  <Icon key="patientSecondaryModalIcon" iconName={item.icon} className="icon" size={20} />,
  <span key="patientSecondaryModalText">{item.text}</span>,
]);

export const patientsListRenderer = (item: Object) => {
  const doctorName = shortenName(get(item, ['doctor', 'nameWithTitle'], ''));
  return [
    <span key="patientSecondaryModalPatient">{shortenName(item.patient.name)}</span>,
    <span key="patientSecondaryModalDoctor" className="doctorName">{doctorName}</span>,
  ];
};

export const servicesRenderer = (selectedServices: any, handleServicesClick: Function) => (item: Object) => (
  <div className='editCheckboxesWrapper servicePriceCheckboxWrapper' onClick={handleServicesClick(item.service.id)} key={item.key}>
    <div className="labelBox">
      <Checkbox
        className={includes(selectedServices, item.service.id) ? 'force-checked' : 'force-unchecked'}
        key={`${item.type}-${item.service.id}`}
        label={item.service.name}
        isChecked={includes(selectedServices, item.service.id)}
      />
    </div>
    <div className="priceBox">
      <span className='price piceLabel'>
        <FormattedText type="currency" text={item.service.price} />
      </span>
    </div>
  </div>
);

export const storedServicesRenderer = (handleServicesClick: Function) => (item: Object) => (
  <div className='editCheckboxesWrapper servicePriceCheckboxWrapper' onClick={handleServicesClick(item.service.id)} key={item.key}>
    <div className="labelBox">
      <Checkbox
        className="force-checked"
        label={item.service.name}
        disabled={!isPresent(get(item, ['service', 'id']))}
        isChecked
      />
    </div>
    <div className="priceBox">
      <span className='price piceLabel'>
        <FormattedText type="currency" text={item.service.price} />
      </span>
    </div>
  </div>
);
