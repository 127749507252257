// @flow
import { get } from 'lodash';
import jQuery from 'jquery';
import { isPresent, Log } from '@helpers/helpers';
import moment from 'moment';

export const handleScrollTimeOffset = (scrollTimeTriggered: boolean, scroolTimeHour: string, seTtriggered: Function) => {
  window.dispatchEvent(new window.Event('resize')); // handle calendar auto height

  const tryOnTimmer = () => setTimeout(() => {
    try {
      if (isPresent(scroolTimeHour) && !scrollTimeTriggered) {
        const scrollTime = jQuery(`tr[data-time$="${scroolTimeHour}:00"]`);
        const scrollWrapper = jQuery('.fc-time-grid-container');

        const scrollTimeTop = get(scrollTime.offset(), 'top', 1);
        const scrollWrapperTop = get(scrollWrapper.offset(), 'top', 1);

        const top = (scrollTimeTop - scrollWrapperTop) + 1;

        const edge = 200;

        if (scrollTime && scrollWrapper && top >= edge) {
          Log.info(`[AGENDA]: Scrolling agenda to ${top}px`);
          const scrollWrapperZ = scrollWrapper.get(0);
          if (scrollWrapperZ) {
            scrollWrapperZ.scrollTop = top;
            seTtriggered();
          } else {
            tryOnTimmer();
          }
        } else {
          tryOnTimmer();
        }
      }
    } catch (e) {
      tryOnTimmer();
      Log.error(e);
    }
  }, 100);
  tryOnTimmer();
};

export const handleFullCalendarTimerHover = (snapDuration: string) => {
  let mouseDown = false;
  const timeBox: any = jQuery('#agenda-calendar .fc').append('<span id="time-box"></span>') && jQuery('#time-box');

  const handleMouseDown = () => { mouseDown = true; };
  const handleMouseUp = () => { mouseDown = false; };
  const handleMouseleave = () => { timeBox.hide().empty(); };
  const handleMouseenter = () => { if (!mouseDown) timeBox.show(); };

  const _document = jQuery(document).off('.agenda-cursor-time');


  const handleMouseMove = (e: Object) => {
    try {
      const position = { top: e.pageY + 10, left: e.pageX + 10 };
      const slot = jQuery(e.target).closest('tr');
      const slotHeight = slot.height();
      const slotSensitivity = moment.duration(snapDuration).asMinutes();
      const slotDuration = moment.duration('00:30:00').asMinutes();
      const slotSegments = slotDuration / slotSensitivity;
      const positionInSlot = e.pageY - slot.offset().top;
      const slotDelta = Math.round((slotSegments - 1) * (positionInSlot / slotHeight)) * slotSensitivity;
      let slotTime = slot.data('time').replace(/(:..)$/, '');

      if (slotDelta > 0) {
        const slotStartTime = slotTime.split(':');
        const slotHours = slotStartTime[0];
        const slotMinutes = parseInt(slotStartTime[1], 10) + slotDelta;
        const leadingZero = slotMinutes < 10 ? '0' : '';
        slotTime = slotHours + ':' + leadingZero + slotMinutes;
      }

      timeBox.html(slotTime).offset(position);
    } catch (err) {
      Log.error(err);
    }
  };

  if (_document) {
    _document
      .on('mouseleave.agenda-cursor-time mousedown.agenda-cursor-time', '.fc-slats', handleMouseleave)
      .on('mousedown.agenda-cursor-time', handleMouseDown)
      .on('mouseup.agenda-cursor-time', handleMouseUp)
      .on('mouseenter.agenda-cursor-time', '.fc-slats', handleMouseenter)
      .on('mousemove.agenda-cursor-time', '.fc-slats tr', handleMouseMove);
  }

  return () => {
    if (_document) {
      _document
        .on('mouseleave.agenda-cursor-time mousedown.agenda-cursor-time', '.fc-slats', handleMouseleave)
        .on('mousedown.agenda-cursor-time', handleMouseDown)
        .on('mouseup.agenda-cursor-time', handleMouseUp)
        .on('mouseenter.agenda-cursor-time', '.fc-slats', handleMouseenter)
        .on('mousemove.agenda-cursor-time', '.fc-slats tr', handleMouseMove);
    }
  };
};
