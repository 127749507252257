// @flow
import React from 'react';
import { map } from 'lodash';
import { DrugsPrescription } from '@blocks';
import type {
  DrugsPrescription as DrugsPrescriptionType,
} from '@types';
import { sort, dateCompare } from '@helpers/helpers';

type Props = {
  drugsPrescriptions: Array<DrugsPrescriptionType>,
  patientName: string,
  onEditPrescriptionDrugsClick: Function,
  onPrintPrescriptionDrugsClick: Function,
};

export default class DrugsPrescriptions extends React.Component<Props> {
  render() {
    const drugsPrescriptions = sort(this.props.drugsPrescriptions, dateCompare(['record', 'date']));

    return (
      map<any, any>(drugsPrescriptions, (drugsPrescription) => (
        <div className="registers" key={drugsPrescription.id}>
          <DrugsPrescription
            {...this.props}
            drugsPrescription={drugsPrescription}
          />
        </div>
      ))
    );
  }
}
