// @flow
import React from 'react';
import type { Node } from 'react';
import classnames from 'classnames';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { get } from 'lodash';
import { Icon, AppStatusBar } from '@elements';
import { withTranslation } from 'react-i18next';
import { createNsTranslator, isPresent } from '@helpers/helpers';
import { compose } from 'redux';
import './AppMobileHeader.scss';

type Props = {
  title?: string,
  children?: Node,
  className?: string,
  hideTitle?: boolean,
  hideBack?: boolean,
  onBackClick?: Function,
  history: Object,
  backLinkTo?: string,
  backIcon?: string,
  disableHeader?: boolean,
  absoluteBacklink?: boolean,
  subscription?: any,
  t: Function,
};

class AppMobileHeader extends React.Component<Props> {
  render() {
    const {
      title,
      children,
      className,
      hideTitle,
      hideBack,
      backIcon,
      disableHeader,
      absoluteBacklink,
      subscription,
    } = this.props;

    const _ = createNsTranslator('layout', this.props.t);

    const noHeader = disableHeader ? null : <span className={`headerTitle ${absoluteBacklink ? 'absolute' : ''}`}>&nbsp;</span>;
    const pageHeader = isPresent(title) ? <span className={`headerTitle ${absoluteBacklink ? 'absolute' : ''}`}>{title}</span> : noHeader;

    const cssClasses = classnames([
      'appMobileHeader',
      className,
    ]);

    const willCancel = subscription === 'will_cancel';
    const pastDue = subscription === 'past_due';

    const headerAlerts = willCancel || pastDue ? (
      <div className="mobileDangerContainer">
        {willCancel &&
          <Link to="/ajustes/meu-plano" className="notificationHeader dangerHeader" >
            <span>{_('label/account_will_be_canceled')}</span>
            <span className="link">&nbsp;{_('action/see_details')}</span>
          </Link>
        }
        {pastDue &&
          <Link to="/ajustes/meu-plano" className="notificationHeader dangerHeader" >
            <span>{_('label/payment_problem')}</span>
            <span className="link">&nbsp;{_('action/click_to_resolve')}</span>
          </Link>
        }
      </div>
    ) : null;

    return (
      <React.Fragment>
        <AppStatusBar color="default" prevColor="default" />
        {headerAlerts}
        <div className={cssClasses}>
          {hideBack !== true && (
            <Icon
              iconName={backIcon || 'arrow-left'}
              className={`icon back ${absoluteBacklink ? 'absoluteBacklink' : ''}`}
              onClick={this.handleBackCLick}
            />
          )}
          {hideTitle !== true ? pageHeader : noHeader}
          {children}
        </div>
      </React.Fragment>
    );
  }

  handleBackCLick = () => {
    if (this.props.onBackClick) {
      if (this.props.onBackClick() === true) {
        this.goBack();
      }
    } else {
      this.goBack();
    }
  };

  goBack = () => {
    if (this.props.backLinkTo) {
      this.props.history.replace(this.props.backLinkTo);
    } else {
      this.props.history.goBack();
    }
  };
}
const mapStateToProps = (state) => ({
  subscription: get(state, ['context', 'user', 'advancedData', 'notifications', 'subscription'], false),
});
export default compose(
  withRouter,
  withTranslation(),
  connect(mapStateToProps),
)(AppMobileHeader);

