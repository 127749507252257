import moment from 'moment';
import i18next from 'i18next';
import { intervalToDuration, isDate, parseISO, format, parse } from 'date-fns';

export const getExtensiveAge = (birthdate: string, currentDate?: string) => {
  if (!birthdate) return '';

  const date = currentDate ? moment(currentDate, 'YYYY-MM-DD') : moment();
  const birthday = moment(birthdate, 'YYYY-MM-DD');

  const duration = intervalToDuration({
    start: birthday.toDate(),
    end: date.toDate(),
  });

  const patientYears = duration.years;
  const patientMonths = duration.months;
  const patientDays = duration.days;

  const _ = i18next.getFixedT(i18next.dir(), 'global');
  const patientDateParts = [];

  if (patientYears > 0) {
    const suffix = patientYears > 1 ? _('label/years') : _('label/year');

    patientDateParts.push(`${patientYears} ${suffix}`);
  }

  if (patientMonths > 0 && patientYears <= 15) {
    const suffix = patientMonths > 1 ? _('label/months') : _('label/month');

    patientDateParts.push(`${patientMonths} ${suffix}`);
  }

  if (patientDays > 0 && patientYears < 1) {
    const suffix = patientDays > 1 ? _('label/days') : _('label/low_day');

    patientDateParts.push(`${patientDays} ${suffix}`);
  }

  const patientDate = patientDateParts.length > 1 ? patientDateParts.join(' ' + _('label/date_and') + ' ') : patientDateParts[0];

  return patientDate;
};

export const toDate = (date: string | Date | any) => {
  if (!date) return new Date();

  if (isDate(date)) return date;

  if (moment.isMoment(date)) return date.toDate();

  if (typeof date !== 'string') return new Date();

  const isISODate = date.match(/^\d{4}-\d{2}-\d{2}$/);

  if (isISODate) return parseISO(date);

  const isFormattedDate = date.match(/^\d{2}\/\d{2}\/\d{4}$/);

  if (isFormattedDate) return parse(date, 'dd/MM/yyyy', new Date());

  return new Date();
};

export const formatDate = (date: string | Date | any) => {
  const parsedDate = toDate(date);

  return format(parsedDate, 'dd/MM/yyyy');
};

export const formatDateToApi = (date: string | Date | any) => {
  const parsedDate = toDate(date);

  return format(parsedDate, 'yyyy-MM-dd HH:mm:ss');
};
