export default {
  'label/my_profile': 'Mi Perfil',
  'label/my_record': 'Mi Cadastro',
  'label/change_password': 'Cambiar Contraseña',
  'label/old_password': 'Contraseña Anterior',
  'label/old_password_success': 'Contraseña cambiada con éxito',
  'label/new_password': 'Nueva Contraseña',
  'label/clinic_logo': 'Logo de la Clínica',
  'label/mail_accept_text': 'Quiero recibir correos electrónicos ocasionales de capacitación y soporte (promesa: nunca enviaremos spam)',
  // privacy
  'label/records_privacy/title': 'Privacidad de los Expedientes',
  'label/records_privacy/opt/input_desctiption': '¿Quién puede ver los expedientes de tus pacientes?',
  'label/records_privacy/opt/only_me': 'Sólo Yo',
  'label/records_privacy/opt/doctors': 'Doctores - Sólo los doctores de la clínica',
  'label/records_privacy/opt/all': 'Todos - Doctores y secretarias de la clínica',
  'label/records_privacy/loading': 'Cargando datos del usuario',
  // custom forms
  'label/custom_forms/forms_list': 'Lista de Formularios',
  'label/custom_forms/text1': 'Los clientes de los planes Concierge y Clínica pueden editar, eliminar o añadir un nuevo formulario.',
  'label/custom_forms/contact_text': 'Póngase en contacto con nuestro Chat Online para editar sus formularios.',
  // prescription menu
  'label/prescription_menu/title': 'Prescripcion Electrónica',
  'label/prescription_menu/prescriptions': 'Modelos de Prescripción',
  'label/prescription_menu/exams': 'Modelos de Pedido de Exámenes',
  'label/prescription_menu/docs': 'Modelos de Documento',
  'label/prescription_menu/memed': 'Recetario Memed',
  // drugs_prescription_teplates
  'label/drugs_prescription_teplates/list/title': 'Modelos de Prescripción',
  'label/drugs_prescription_teplates/list/empty_title': 'No Hay Modelos de Prescripción Grabados',
  'label/drugs_prescription_teplates/list/empty_hint': 'Puedes usar un modelo para agilizar tus citas.',
  'label/drugs_prescription_teplates/list/new': 'Nuevo Modelo',
  'label/drugs_prescription_teplates/list/new_prescription': 'Nueva Prescripción',
  'label/drugs_prescription_teplates/form/error_empty': 'no puede estar vacío',
  'label/drugs_prescription_teplates/form/placeholder_content': 'Escribe aquí el contenido de la prescripción',
  'label/drugs_prescription_teplates/form/controlled_checkbox': 'Recetario Controlado',
  'label/drugs_prescription_teplates/form/model_name': 'Nombre del Modelo',
  // exams models
  'label/exam_models/list/title': 'Modelos de Pedido de Exámenes',
  'label/exam_models/list/empty_title': 'No Hay Modelos de Pedido de Exámenes Registrados',
  'label/exam_models/list/empty_hint': 'Puedes usar un modelo para agilizar tus citas.',
  'label/exam_models/list/new_exam': 'Nuevo Modelo',
  'label/exam_models/form/error_empty': 'no puede estar vacío',
  'label/exam_models/form/placeholder_content': 'Escribe aquí el contenido del pedido de exámenes',
  'label/exam_models/form/model_name': 'Nombre del Modelo',
  // documents
  'label/documents/list/title': 'Modelos de Documento',
  'label/documents/list/empty_title': 'No Hay Modelos de Documento Guardados',
  'label/documents/list/empty_hint': 'Puedes utilizar un modelo para agilizar tus citas.',
  'label/documents/list/new_doc': 'Nuevo Modelo',
  'label/documents/form/title': 'Modelo de Documento',
  'label/documents/form/name': 'Nombre del Modelo',
  'label/documents/form/error_empty': 'no puede estar vacío',
  'label/documents/form/placeholder_content': 'Escribe aquí el contenido del documento',
  'label/documents/form/show_print_model': 'Mostrar el nombre del modelo al imprimir',
  // Clinic config address
  'label/clinic_addr/title': 'Datos de la Clínica/Consultorio',
  'label/clinic_addr/clinic_name': 'Nombre del Consultorio/Clínica',
  'label/clinic_addr/title2': 'Dirección de la Clínica',
  // Clinic doctors
  'label/clinic_doctors/list/title': 'Lista de Doctores',
  'label/clinic_doctors/list/title_mobile': 'Doctores',
  'label/clinic_doctors/list/invite': 'Invitar Doctor',
  'label/clinic_doctors/form/title_edit': 'Editar Doctor',
  'label/clinic_doctors/form/title_invite': 'Invitar Doctor',
  'label/clinic_doctors/form/name_in_receipt': 'Nombre completo para recibos',
  'label/clinic_doctors/form/council_doc': 'Consejo Profesional',
  'label/clinic_doctors/form/agenda_color': 'Color en la Agenda',
  'label/clinic_doctors/form/agenda_info': 'Información en la Agenda',
  'label/clinic_doctors/form/default_appointment_duration': 'Duración Estándar de la Cita',
  'label/clinic_doctors/form/default_room': 'Sala Estándar para Atención',
  'label/clinic_doctors/form/is_administrator': 'Administrador',
  'label/clinic_doctors/form/edit_workhours': 'Configurar Horarios de Atención',
  'label/clinic_doctors/form/administrator_hint': 'Los administradores pueden cambiar las configuraciones y ver los informes financieros completos.',
  // Doctors workhours
  'label/doctors_workhours/title': 'Horarios de Atención',
  'label/doctors_workhours/weekday': 'Día de la semana',
  'label/doctors_workhours/title2': 'Agregar nuevo horario de atención',
  // secretaries
  'label/secretaries/list/title': 'Lista de Secretarias',
  'label/secretaries/list/invite': 'Invitar Secretaria',
  'label/secretaries/list/empty': 'Invita a tu secretaria',
  'label/secretaries/list/hint_1': 'Pueden usar Doutore al mismo tiempo.',
  'label/secretaries/list/hint_2': 'Toda la información se sincroniza automáticamente.',
  'label/secretaries/list/users': 'Usuarios',
  'label/secretaries/form/edit_secretary': 'Editar Secretaria',
  'label/secretaries/form/manager_hint': 'Los administradores pueden cambiar las configuraciones y ver los informes financieros completos',
  // Agenda
  'label/agenda_menu/workhours': 'Horarios de Funcionamiento',
  'label/agenda_menu/rooms': 'Salas de Atención',
  'label/agenda_menu/title': 'Agenda',
  'label/agenda/show_weekends_in_agenda': 'Mostrar los fines de semana en la agenda',
  // Clinic rooms
  'label/rooms/title': 'Salas de Atención',
  'label/rooms/room_name': 'Nombre de la Sala',
  'label/rooms/title2': 'Datos de la Clínica/Consultorio',
  'label/rooms/number_of_rooms': 'Número de Salas',
  // Patient fields
  'label/patients_fields/title_mobile': 'Registro de Pacientes',
  'label/patients_fields/basic_data': 'Datos básicos',
  'label/patients_fields/hint': 'Mostrar campos adicionales en el registro del paciente',
  'label/patients_fields/code': 'Código',
  'label/patients_fields/custom_field': 'Campo Personalizado',
  'label/patients_fields/health_insurance': 'Seguro de Salud',
  'label/patients_fields/health_insurance_fields': 'Convenio, Plan, Número de tarjeta y Validez',
  // tags
  'label/tags/list/title': 'Lista de Etiquetas',
  'label/tags/list/new': 'Nueva Etiqueta',
  'label/tags/form/edit': 'Editar Etiqueta',
  'label/tags/form/title': 'Etiquetas',
  // Service templates
  'label/services/empty_title1': 'No Hay Servicios Particulares Registrados',
  'label/services/empty_hint1': 'Aquí estará tu tabla de precios con tus servicios y valores.',
  'label/services/empty_title2': 'No Hay Servicios de Seguro de Salud Registrados',
  'label/services/search_empty': 'No se encontraron servicios para el término buscado.',
  'label/services/empty_hint2': 'Aquí estará tu tabla de precios con tus servicios de seguro de salud y valores.',
  'label/services/add_service': 'Agregar Servicio',
  'label/services/add_plan': 'Agregar Plan',
  'label/services/private': 'Privado',
  'label/services/insurance_plan': 'Seguro de Salud',
  'label/services/form/new_service': 'Nuevo Servicio',
  'label/services/form/service_value': 'Valor de los Servicios',
  // Printing
  'label/print_config/menu/general': 'General',
  'label/print_config/menu/logo': 'Logotipo de la Clínica',
  'label/print_config/menu/eletronic_receipts': 'Recetas Electrónicas',
  'label/print_config/menu/controlled_receipts': 'Recetas Controladas',
  'label/print_config/menu/clinic_logo': 'Logotipo de la Clínica',
  'label/print_config/menu/memed_settings': 'Configuraciones de Memed',
  'label/print_config/menu/title': 'Impresión',
  'label/print_config/general/clinic_name': 'Nombre del Consultorio/Clínica',
  'label/print_config/general/title': 'Diseño de Informes',
  'label/print_config/prescription/letterhead': 'Papel con membrete',
  'label/print_config/prescription/nohead': 'Papel en blanco',
  'label/print_config/prescription/paper_type': '¿Qué tipo de papel va a poner en la impresora?',
  'label/print_config/prescription/line': 'Línea',
  'label/print_config/prescription/heading': 'Encabezado',
  'label/print_config/prescription/line_1': 'Título del Encabezado',
  'label/print_config/prescription/line_2': 'Texto del Encabezado',
  'label/print_config/prescription/preview_pdf': 'Ver Ejemplo',
  'label/print_config/prescription/footer': 'Texto del Pie de Página',
  'label/print_config/prescription/margins': 'Márgenes',
  'label/print_config/prescription/font': 'Fuente',
  'label/print_config/prescription/margin_top': 'Margen Superior',
  'label/print_config/prescription/margin_bottom': 'Margen Inferior',
  'label/print_config/prescription/margin_left': 'Margen Izquierdo',
  'label/print_config/prescription/margin_right': 'Margen Derecho',
  'label/print_config/prescription/title': 'Configuraciones de Impresión',
  'label/print_config/controlled/title': 'Recetas Controladas',
  'label/print_config/controlled/council_number': 'Número del Consejo',
  // Support
  'label/support/menu/guide': 'Guía del Doctor',
  'label/support/menu/terms': 'Términos de Uso',
  'label/support/menu/backup': 'Copia de Seguridad Manual',
  'label/support/menu/to_appx': 'Ir a Doctor (Versión Beta)',
  'label/support/menu/to_app': 'Ir a Doctor 4 (Versión Actual)',
  'label/support/menu/chat': 'Soporte por Chat Online',
  'label/support/menu/refresh': 'Actualizar el Doctor',
  'label/support/menu/privacyPolicy': 'Política de Privacidad',
  'label/support/menu/deleteMyAccount': 'Borrar Mi Cuenta',
  'label/support/menu/version': 'Doctor versión',
  'label/support/backup/success/title': '¡Todo en orden!',
  'label/support/backup/success/text': 'Vamos a procesar tus datos y te los enviaremos a tu correo electrónico',
  'label/support/backup/title': 'Copia de Seguridad Manual',
  'label/support/backup/text/line1': 'En Doctor, tus datos se guardan de forma segura en nuestros servidores.',
  'label/support/backup/text/line2': 'Hacemos copias de seguridad automáticas diarias de toda tu información y almacenamos la copia de seguridad en diferentes lugares del mundo: Estados Unidos, São Paulo y Europa.',
  'label/support/backup/text/line3': 'Si aún así quieres hacer una copia de seguridad manual de tus datos, utiliza los botones a continuación:',
  'label/support/backup_excel/text/line1_title': 'Copia de Seguridad de los Pacientes en Excel: ',
  'label/support/backup_excel/text/line1': 'Hoja de cálculo de Excel con los datos de registro de los pacientes.',
  'label/support/backup_excel/text/line2': 'Esta copia de seguridad se envía directamente a tu correo electrónico y puede tardar unos minutos u horas.',
  'label/support/backup_complete/text/line1_title': 'Copia de Seguridad Completa de los Expedientes:',
  'label/support/backup_complete/text/line1': 'Archivo con todos los expedientes y fotos de los pacientes.',
  'label/support/backup_complete/text/line2': 'Como esta copia de seguridad utiliza mucho procesamiento de nuestros servidores, se realiza solo en momentos de baja utilización del sistema, para no afectar el rendimiento de Doctor. Esta copia de seguridad se realiza manualmente y puede tardar horas o días.',
  'label/support/backup/request_backup_patients': 'Pedir Copia de Seguridad de los Pacientes',
  'label/support/backup/request_backup_complete': 'Pedir Copia de Seguridad Completa',
  'label/support/backup/support_action_lbl': 'En caso de dudas',
  'label/support/backup/support_action_btn': 'ponte en contacto',
  'label/support/backup/attention': 'Atención',
  'label/support/backup/wait': 'Espere...',
  'label/support/backup/attention_text': `Como la copia de seguridad manual utiliza mucho procesamiento de nuestros servidores,
  se realiza solo en momentos de baja utilización del sistema, para no afectar el rendimiento de Doctor.
  Esto puede llevar algunas horas.`,
  // menu
  'action/menu/my_plan': 'Mi Plan',
  'action/menu/plans': 'Planes',
  'action/menu/my_record': 'Mi Perfil',
  'action/menu/records_settings': 'Ajustes de Expediente',
  'action/menu/privacy': 'Privacidad',
  'action/menu/forms': 'Formularios',
  'action/menu/eletronic_records': 'Recetas Electrónicas',
  'action/menu/clinic_settings': 'Ajustes de la Clínica',
  'action/menu/my_clinic': 'Mi Clínica',
  'action/menu/doctors': 'Doctores',
  'action/menu/secretaries': 'Secretarias',
  'action/menu/agenda': 'Agenda',
  'action/menu/patients_data': 'Registro de Pacientes',
  'action/menu/advanced_settings': 'Ajustes Avanzados',
  'action/menu/tags': 'Etiquetas',
  'action/menu/sevices_list': 'Tabla de Servicios',
  'action/menu/printing': 'Impresión',
  'action/menu/support': 'Soporte Técnico',
  'action/menu/logout': 'Salir de Doutore',
  'action/remove_logo': 'Quitar Logotipo',
  'action/select_clinic_logo': 'Enviar Logotipo de la Clínica',
  'action/train_secretary': 'Quiero que se pongan en contacto para entrenar a mi secretaria',
  'label/no_clinic_logo': 'Arrastra aquí tu logotipo',
  'label/drop_logo': 'Suelta tu logotipo aquí.',
  'label/backup_request_blocked': 'No tienes acceso a esta funcionalidad',
  'label/action/change_clinic': 'Cambiar',
  'action/menu/change_clinic_title': 'Selecciona una clínica',
  // memed signup
  'label/memed_singup/form/council_doc': 'CRM',
  'label/memed_singup/form/address_state': 'Estado del Registro CRM',
};
