// @flow
import React from 'react';
import { get } from 'lodash';
import { connect } from 'react-redux';
import { BackupRequest as BackupRequestView } from '@views';
import { requestBackup, resetRequestBackup, logout } from '@context/user';
import { mapBundledPermission } from '@helpers/connect';
import type { User, BundledPermissions } from '@types';

type Props = {
  isLoading: boolean,
  success: boolean,
  dispatch: Function,
  userEmail: string,
  user: User,
  permissions: BundledPermissions,
};

type State = {
  immediateLoading: boolean,
}
class BackupRequest extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      immediateLoading: false,
    };
  }
  componentWillMount() {
    this.props.dispatch(resetRequestBackup());
  }

  render() {
    const {
      isLoading, success, userEmail, user, permissions,
    } = this.props;
    return (
      <BackupRequestView
        onRequest={this.handleRequest}
        isLoading={isLoading || this.state.immediateLoading}
        success={success}
        userEmail={userEmail}
        user={user}
        permissions={permissions}
        onLogout={this.handleLogout}
      />
    );
  }

  handleLogout = () => this.props.dispatch(logout());

  handleRequest = (complete: boolean) => {
    const { dispatch } = this.props;
    this.setState({ immediateLoading: true }, () => {
      if (complete) {
        dispatch(requestBackup('full', () => this.setState({ immediateLoading: false })));
      } else {
        dispatch(requestBackup('patients', () => this.setState({ immediateLoading: false })));
      }
    });
  };
}

const mapStateToProps = (state) => ({
  user: state.context.user,
  isLoading: get(state, ['context', 'user', 'isRequestingBackup'], false),
  success: get(state, ['context', 'user', 'backupRequestsuccess'], false),
  userEmail: get(state, ['context', 'user', 'email'], false),
  permissions: mapBundledPermission(state),
});

export default connect<any, any, any, any, any, any>(
  mapStateToProps,
)(BackupRequest);
