// @flow
import Immutable from 'seamless-immutable';
import {
  map, get, omit, concat, reject, isFunction, toString, dropRight, filter,
} from 'lodash';
import { slugify, reportError, isPresent } from '@helpers/helpers';
import { Api } from '../../../helpers';
import PatientService from '../../../services/PatientService';
import { router } from '../../../routes/router';
import { openModal } from './modals';
import showPDF from '../../middleware/showPDF';
import type
{
  Action,
  Dispatch,
  PatientInput,
  Patient,
  ContextState,
  PatientHistoryNoteInput,
  TagTemplates,
} from '../../../types';

// type PatientsResponseType = {
//   patients: Array<Patient>,
//   meta: {
//     current_page: number,
//     total_pages: number,
//     total_entries: number,
//   }
// };

// ------------------------------------
// Constants
// ------------------------------------
const PATIENTS_LOADING = 'patients.PATIENTS_LOADING';
const PATIENTS_LOADED = 'patients.PATIENTS_LOADED';
const PATIENTS_LOADING_FAILED = 'patients.PATIENTS_LOADING_FAILED';

const PATIENT_LOADING = 'patients.PATIENT_LOADING';
const PATIENT_LOADED = 'patients.PATIENT_LOADED';
const PATIENT_LOADING_FAILED = 'patients.PATIENT_LOADING_FAILED';

const PATIENT_GET_FINANCIAL_LOADING = 'patients.PATIENT_GET_FINANCIAL_LOADING';
const PATIENT_GET_FINANCIAL_STOP_LOADING = 'patients.PATIENT_GET_FINANCIAL_STOP_LOADING';
const PATIENT_GET_FINANCIAL_LOADED = 'patients.PATIENT_GET_FINANCIAL_LOADED';
const PATIENT_GET_FINANCIAL_LOADING_FAILED = 'patients.PATIENT_GET_FINANCIAL_LOADING_FAILED';

const PATIENT_UPDATE_IN_PROGRESS = 'patients.PATIENT_UPDATE_IN_PROGRESS';
const PATIENT_UPDATE_SUCCESS = 'patients.PATIENT_UPDATE_SUCCESS';
const PATIENT_UPDATE_FAILED = 'patients.PATIENT_UPDATE_FAILED';

const PATIENT_UPDATE_HISTORY_NOTE_IN_PROGRESS = 'patients.PATIENT_UPDATE_HISTORY_NOTE_IN_PROGRESS';
const PATIENT_UPDATE_HISTORY_NOTE_SUCCESS = 'patients.PATIENT_UPDATE_HISTORY_NOTE_SUCCESS';
const PATIENT_UPDATE_HISTORY_NOTE_FAILED = 'patients.PATIENT_UPDATE_HISTORY_NOTE_FAILED';

const FILTER_PATIENTS = 'patients.FILTER_PATIENTS';
const FILTER_PATIENTS_SUCCESS = 'patients.FILTER_PATIENTS_SUCCESS';
const FILTER_PATIENTS_FAILED = 'patients.FILTER_PATIENTS_FAILED';

const SEARCH_PATIENTS = 'patients.SEARCH_PATIENTS';
const SEARCH_PATIENTS_SUCCESS = 'patients.SEARCH_PATIENTS_SUCCESS';
const SEARCH_PATIENTS_FAILED = 'patients.SEARCH_PATIENTS_FAILED';

const GET_APPOINTMENT_PATIENTS = 'patients.GET_APPOINTMENT_PATIENTS';
const GET_APPOINTMENT_PATIENTS_SUCCESS = 'patients.GET_APPOINTMENT_PATIENTS_SUCCESS';
const GET_APPOINTMENT_PATIENTS_FAILED = 'patients.GET_APPOINTMENT_PATIENTS_FAILED';

const PATIENT_CREATE_IN_PROGRESS = 'patients.PATIENT_CREATE_IN_PROGRESS';
const PATIENT_CREATE_SUCCESS = 'patients.PATIENT_CREATE_SUCCESS';
const PATIENT_CREATE_FAILED = 'patients.PATIENT_CREATE_FAILED';

const PATIENT_DELETE_IN_PROGRESS = 'patients.PATIENT_DELETE_IN_PROGRESS';
const PATIENT_DELETE_SUCCESS = 'patients.PATIENT_DELETE_SUCCESS';
const PATIENT_DELETE_FAILED = 'patients.PATIENT_DELETE_FAILED';

const ADD_PATIENT_TAG_IN_PROGRESS = 'patients.ADD_PATIENT_TAG_IN_PROGRESS';
const ADD_PATIENT_TAG_SUCCESS = 'patients.ADD_PATIENT_TAG_SUCCESS';
const ADD_PATIENT_TAG_FAILED = 'patients.ADD_PATIENT_TAG_FAILED';

const REMOVE_PATIENT_TAG_IN_PROGRESS = 'patients.REMOVE_PATIENT_TAG_IN_PROGRESS';
const REMOVE_PATIENT_TAG_SUCCESS = 'patients.REMOVE_PATIENT_TAG_SUCCESS';
const REMOVE_PATIENT_TAG_FAILED = 'patients.REMOVE_PATIENT_TAG_FAILED';

const CREATE_AND_CONNECT_PATIENT_TAG_IN_PROGRESS = 'patients.CREATE_AND_CONNECT_PATIENT_TAG_IN_PROGRESS';
const CREATE_AND_CONNECT_PATIENT_TAG_SUCCESS = 'patients.CREATE_AND_CONNECT_PATIENT_TAG_SUCCESS';
const CREATE_AND_CONNECT_PATIENT_TAG_FAILED = 'patients.CREATE_AND_CONNECT_PATIENT_TAG_FAILED';

const UPDATE_TAGS_MODAL = 'patients.UPDATE_TAGS_MODAL';

const PATIENT_PROFILE_IMAGE_SIGNING = 'patients.PATIENT_PROFILE_IMAGE_SIGNING';
const PATIENT_PROFILE_IMAGE_SIGNING_FAILED = 'patients.PATIENT_PROFILE_IMAGE_SIGNING_FAILED';
const PATIENT_PROFILE_IMAGE_S3_UPLOADING = 'patients.PATIENT_PROFILE_IMAGE_S3_UPLOADING';
const PATIENT_PROFILE_IMAGE_S3_UPLOADING_FAILED = 'patients.PATIENT_PROFILE_IMAGE_S3_UPLOADING_FAILED';
const SET_PATIENT_PROFILE_IMAGE = 'patients.SET_PATIENT_PROFILE_IMAGE';
const SET_PATIENT_PROFILE_IMAGE_SUCCESS = 'patients.SET_PATIENT_PROFILE_IMAGE_SUCCESS';
const SET_PATIENT_PROFILE_IMAGE_FAILED = 'patients.SET_PATIENT_PROFILE_IMAGE_FAILED';
const DELETE_PATIENT_PROFILE_IMAGE = 'patients.DELETE_PATIENT_PROFILE_IMAGE';
const DELETE_PATIENT_PROFILE_IMAGE_SUCCESS = 'patients.DELETE_PATIENT_PROFILE_IMAGE_SUCCESS';
const DELETE_PATIENT_PROFILE_IMAGE_FAILED = 'patients.DELETE_PATIENT_PROFILE_IMAGE_FAILED';

const PATIENT_RECOVER_LOADING = 'patients.PATIENT_RECOVER_LOADING';
const PATIENT_RECOVER_LOADED = 'patients.PATIENT_RECOVER_LOADED';
const PATIENT_RECOVER_ERROR = 'patients.PATIENT_RECOVER_ERROR';

// MEDICATIONS
const PATIENT_ADD_MEDICATION_LOADING = 'patients.PATIENT_ADD_MEDICATION_LOADING';
const PATIENT_ADD_MEDICATION_LOADED = 'patients.PATIENT_ADD_MEDICATION_LOADED';
const PATIENT_ADD_MEDICATION_ERROR = 'patients.PATIENT_ADD_MEDICATION_ERROR';

const PATIENT_REMOVE_MEDICATION_LOADING = 'patients.PATIENT_REMOVE_MEDICATION_LOADING';
const PATIENT_REMOVE_MEDICATION_LOADED = 'patients.PATIENT_REMOVE_MEDICATION_LOADED';
const PATIENT_REMOVE_MEDICATION_ERROR = 'patients.PATIENT_REMOVE_MEDICATION_ERROR';

// PROBLEMS

const PATIENT_ADD_PROBLEM_LOADING = 'patients.PATIENT_ADD_PROBLEM_LOADING';
const PATIENT_ADD_PROBLEM_LOADED = 'patients.PATIENT_ADD_PROBLEM_LOADED';
const PATIENT_ADD_PROBLEM_ERROR = 'patients.PATIENT_ADD_PROBLEM_ERROR';

const PATIENT_REMOVE_PROBLEM_LOADING = 'patients.PATIENT_REMOVE_PROBLEM_LOADING';
const PATIENT_REMOVE_PROBLEM_LOADED = 'patients.PATIENT_REMOVE_PROBLEM_LOADED';
const PATIENT_REMOVE_PROBLEM_ERROR = 'patients.PATIENT_REMOVE_PROBLEM_ERROR';

const PATIENT_RESET_IS_LOADING = 'patients.PATIENT_RESET_IS_LOADING';

// PRINTING

const PATIENT_PRINT_PDF_IN_PROGRESS = 'patients.PATIENT_PRINT_PDF_IN_PROGRESS';
const PATIENT_PRINT_PDF_SUCCESS = 'patients.PATIENT_PRINT_PDF_SUCCESS';
const PATIENT_PRINT_PDF_FAILED = 'patients.PATIENT_PRINT_PDF_FAILED';
const PATIENT_CLEAR_APPOINTMENT_LOADING_STATE = 'patients.PATIENT_CLEAR_APPOINTMENT_LOADING_STATE';


// ------------------------------------
// Actions
// ------------------------------------

export const clearAppointmentLoadingState = () => (
  (dispatch: Dispatch) => {
    dispatch({ type: PATIENT_CLEAR_APPOINTMENT_LOADING_STATE });
  }
);

export const addProblem = (patientId:number, problem: string) => (
  (dispatch: Dispatch) => {
    dispatch({ type: PATIENT_ADD_PROBLEM_LOADING, patientId });
    Api.post(router.getApiRoute('addProblem', { patientId }), {
      data: {
        problem: {
          name: problem,
        },
      },
      success: (response) => {
        dispatch({
          type: PATIENT_ADD_PROBLEM_LOADED,
          problem: get(response, ['data', 'problem']),
          patientId,
        });
      },
      error: (error) => {
        dispatch({ type: PATIENT_ADD_PROBLEM_ERROR, error: { message: error.message }, patientId });
        dispatch(reportError(error));
      },
    });
  }
);


export const deleteProblem = (patientId:number, id: number) => (
  (dispatch: Dispatch) => {
    dispatch({ type: PATIENT_REMOVE_PROBLEM_LOADING, patientId });
    Api.destroy(router.getApiRoute('deleteProblem', { patientId, id }), {
      success: () => {
        dispatch({
          type: PATIENT_REMOVE_PROBLEM_LOADED,
          deleteId: id,
          patientId,
        });
      },
      error: (error) => {
        dispatch({ type: PATIENT_REMOVE_PROBLEM_ERROR, error: { message: error.message }, patientId });
        dispatch(reportError(error));
      },
    });
  }
);


export const addMedication = (patientId:number, medication: string) => (
  (dispatch: Dispatch) => {
    dispatch({ type: PATIENT_ADD_MEDICATION_LOADING, patientId });
    Api.post(router.getApiRoute('addMedication', { patientId }), {
      data: {
        medication: {
          name: medication,
        },
      },
      success: (response) => {
        dispatch({
          type: PATIENT_ADD_MEDICATION_LOADED,
          medication: get(response, ['data', 'medication']),
          patientId,
        });
      },
      error: (error) => {
        dispatch({ type: PATIENT_ADD_MEDICATION_ERROR, error: { message: error.message }, patientId });
        dispatch(reportError(error));
      },
    });
  }
);


export const deleteMedication = (patientId:number, id: number) => (
  (dispatch: Dispatch) => {
    dispatch({ type: PATIENT_REMOVE_MEDICATION_LOADING, patientId });
    Api.destroy(router.getApiRoute('deleteMedication', { patientId, id }), {
      success: () => {
        dispatch({
          type: PATIENT_REMOVE_MEDICATION_LOADED,
          deleteId: id,
          patientId,
        });
      },
      error: (error) => {
        dispatch({ type: PATIENT_REMOVE_MEDICATION_ERROR, error: { message: error.message }, patientId });
        dispatch(reportError(error));
      },
    });
  }
);

export const getPatients = (page: number = 1, resetFilters?: boolean, cb?: Function) => (
  (dispatch: Dispatch, getState: Function) => {
    dispatch({ type: PATIENTS_LOADING, resetFilters });


    const filterBy = get(getState(), ['context', 'patients', 'filters', 'filterBy'], null);
    const filterValue = get(getState(), ['context', 'patients', 'filters', 'filterValue'], null);

    const route = router.getApiRoute('getPatients', null);

    const queryFilter = isPresent(filterBy) && isPresent(filterValue) ? `&filter[${filterBy}]=${filterValue}` : '';

    const apiRoute = {
      ...route,
      path: `${route.path}?page=${page || 1}${queryFilter}`,
    };

    Api.get(apiRoute, {
      success: (response) => {
        dispatch({ type: PATIENTS_LOADED, patients: response.patients, meta: response.meta });
        if (cb) {
          cb(true);
        }
      },
      error: (error) => {
        dispatch({ type: PATIENTS_LOADING_FAILED, error: { message: error.message } });
        dispatch(reportError(error));
        if (cb) {
          cb(false);
        }
      },
    });
  }
);
// const getPatients = (forceReload: boolean = false) => ({
//   types: [PATIENTS_LOADING, PATIENTS_LOADED, PATIENTS_LOADING_FAILED],
//   payload: {},
//   shouldCall: (state: ContextState) => {
//     const patients = get(state, ['context', 'patients']);
//     return forceReload || (!get(patients, 'isLoading') && !size(get(patients, 'data')));
//   },
//   call: () => Api.get(router.getApiRoute('getPatients', null, true)),
//   parseResponse: (response: PatientsResponseType) => ({
//     patients: response.patients,
//   }),
// });

export const printPatientFinancialReport = (patientId: number) => (dispatch: Function, getState: Function) => {
  const patient = get(getState(), ['context', 'patients', 'data', String(patientId)], null);

  dispatch(showPDF.action({
    title: patient ? `Financeiro ${patient.name}` : 'Patient PDF',
    apiRoute: router.getApiRoute('patientFinancialReport', { id: patientId }),
    prepare: PATIENT_PRINT_PDF_IN_PROGRESS,
    success: PATIENT_PRINT_PDF_SUCCESS,
    error: PATIENT_PRINT_PDF_FAILED,
  }));
};

export const showPatientPDF = (id: number) => (dispatch: Function, getState: Function) => {
  const patient = get(getState(), ['context', 'patients', 'data', String(id)], null);

  dispatch(showPDF.action({
    title: patient ? `Perfil ${patient.name}` : 'Patient PDF',
    apiRoute: router.getApiRoute('showPatientPDF', { id }),
    prepare: PATIENT_PRINT_PDF_IN_PROGRESS,
    success: PATIENT_PRINT_PDF_SUCCESS,
    error: PATIENT_PRINT_PDF_FAILED,
  }));
};

export const getPatient = (id: number, showModal?: boolean) => (
  (dispatch: Dispatch) => {
    dispatch({ type: PATIENT_LOADING });
    Api.get(router.getApiRoute('getPatient', { id }), {
      success: ({ patient }) => {
        dispatch({ type: PATIENT_LOADED, patient, id: patient.id });
        if (showModal) {
          dispatch(openModal('PatientFormModal', { id }));
        }
      },
      error: (error) => {
        dispatch({ type: PATIENT_LOADING_FAILED, error: { message: error.message } });
        dispatch(reportError(error));
      },
    });
  }
);

export const recoverPatient = (id: number) => (
  (dispatch: Dispatch) => {
    dispatch({ type: PATIENT_RECOVER_LOADING });
    Api.patch(router.getApiRoute('recoverPatient', { id }), {
      success: (response) => {
        dispatch({ type: PATIENT_RECOVER_LOADED, patient: response.patient });
        dispatch(router.pushPath('patientProfile', { id }));
      },
      error: (error) => {
        dispatch({ type: PATIENT_RECOVER_ERROR, error: { message: error.message } });
        dispatch(reportError(error));
      },
    });
  }
);

/**
 * This is used to provide more solid client interaction
 * @param {number} patientId
 * @param {boolean} isLoading
 */
export const setPatientFinancialLoading = (patientId: number, isLoading: boolean = true) => (
  (dispatch: Dispatch) => {
    if (isLoading) {
      dispatch({ type: PATIENT_GET_FINANCIAL_LOADING });
    } else {
      dispatch({ type: PATIENT_GET_FINANCIAL_STOP_LOADING, id: patientId }); // Sending empty object may not cause changes on patient
    }
  }
);
/**
 * Update current patient financial attrs (indebt, financialBalance)
 * @param {number} patientId
 */
export const getPatientFinancial = (patientId: number) => (
  (dispatch: Dispatch) => {
    dispatch({ type: PATIENT_GET_FINANCIAL_LOADING });
    Api.get(router.getApiRoute('getPatientFinancial', { id: patientId }), {
      success: ({ patient }) => {
        dispatch({ type: PATIENT_GET_FINANCIAL_LOADED, data: patient, id: patientId });
      },
      error: (error) => {
        dispatch({ type: PATIENT_GET_FINANCIAL_LOADING_FAILED, error: { message: error.message } });
        dispatch(reportError(error));
      },
    });
  }
);

export const deletePatient = (id: number, callBack?: Function) => (
  (dispatch: Dispatch) => {
    dispatch({ type: PATIENT_DELETE_IN_PROGRESS });
    Api.destroy(router.getApiRoute('deletePatient', { id }), {
      success: () => {
        dispatch({ type: PATIENT_DELETE_SUCCESS, deleteId: id });
        if (isFunction(callBack)) {
          callBack(true);
        }
      },
      error: (error) => {
        dispatch({ type: PATIENT_DELETE_FAILED, error: { message: error.message } });
        dispatch(reportError(error));
        if (isFunction(callBack)) {
          callBack(false);
        }
      },
    });
  }
);
/**
 * Update patient
 * @param {number} id
 * @param {PatientInput} patient
 * @param {Function(boolean)} callback run this function when api call end's
 */
export const updatePatient = (id:number, patient: PatientInput, callback?: Function) => (
  (dispatch: Dispatch) => {
    dispatch({ type: PATIENT_UPDATE_IN_PROGRESS });
    Api.patch(router.getApiRoute('updatePatient', { id }), {
      data: { patient },
      success: (response) => {
        dispatch({ type: PATIENT_UPDATE_SUCCESS, patient: response.patient, id: get(response, ['patient', 'id']) });
        if (isFunction(callback)) {
          callback(true);
        }
      },
      error: (error) => {
        dispatch({ type: PATIENT_UPDATE_FAILED, error: { message: error.message, raw: error.raw } });
        dispatch(reportError(error));
        if (isFunction(callback)) {
          callback(false);
        }
      },
    });
  }
);

export const updatePatientHistoryNote = (id:number, historyNote: PatientHistoryNoteInput) => (
  (dispatch: Dispatch) => {
    dispatch({ type: PATIENT_UPDATE_HISTORY_NOTE_IN_PROGRESS });
    Api.post(router.getApiRoute('updatePatientHistoryNotes', { id }), {
      data: { historyNote },
      success: (response) => {
        dispatch({
          type: PATIENT_UPDATE_HISTORY_NOTE_SUCCESS,
          historyNote: get(response, ['data', 'historyNote']),
          patientId: id,
        });
      },
      error: (error) => {
        dispatch({ type: PATIENT_UPDATE_HISTORY_NOTE_FAILED, error: { message: error.message } });
        dispatch(reportError(error));
      },
    });
  }
);


export const filterPatients = (filterBy: string, filterValue: string | number, filterType: string, page: number = 1, cb?: Function) => (
  (dispatch: Dispatch) => {
    dispatch({
      type: FILTER_PATIENTS,
      filterBy,
      filterValue,
      filterType,
    });
    const route = router.getApiRoute('getPatients', null);

    const apiRoute = {
      ...route,
      path: `${route.path}?filter[${filterBy}]=${filterValue}&page=${page || 1}`,
    };

    Api.get(apiRoute, {
      success: (response) => {
        dispatch({
          type: FILTER_PATIENTS_SUCCESS,
          patients: response.patients,
          filterBy,
          filterValue,
          filterType,
          meta: response.meta,
        });
        if (cb) {
          cb(true);
        }
      },
      error: (error) => {
        dispatch({ type: FILTER_PATIENTS_FAILED, error: { message: error.message } });
        dispatch(reportError(error));
        if (cb) {
          cb(false);
        }
      },
    });
  }
);


export const searchPatients = (searchValue: string, page: number = 1, cb?: Function) => (
  (dispatch: Dispatch) => {
    dispatch({ type: SEARCH_PATIENTS });
    const route = router.getApiRoute('getPatients', null);

    const apiRoute = {
      ...route,
      path: `${route.path}?search=${searchValue}&page=${page || 1}`,
    };

    Api.get(apiRoute, {
      success: (response) => {
        dispatch({
          type: SEARCH_PATIENTS_SUCCESS,
          patients: response.patients,
          searchValue,
          meta: response.meta,
        });
        if (cb) {
          cb(true);
        }
      },
      error: (error) => {
        dispatch({ type: SEARCH_PATIENTS_FAILED, error: { message: error.message } });
        dispatch(reportError(error));
        if (cb) {
          cb(false);
        }
      },
    });
  }
);

export const getSuggestedPatients = (searchValue?: string) => (
  (dispatch: Dispatch) => {
    dispatch({ type: GET_APPOINTMENT_PATIENTS });

    let route = router.getApiRoute('getSuggestedPatients');
    let routePath = route.path;


    if (searchValue) {
      route = router.getApiRoute('getPatients', null);
      routePath = `${route.path}?search=${searchValue}`;
    }

    const apiRoute = {
      ...route,
      path: routePath,
    };

    Api.get(apiRoute, {
      success: (response) => {
        let completePatientsList;
        if (searchValue) {
          completePatientsList = get(response, ['meta', 'current_page']) === get(response, ['meta', 'total_pages']);
        }
        dispatch({
          type: GET_APPOINTMENT_PATIENTS_SUCCESS,
          patients: response.patients,
          suggested: response.suggestedPatients,
          completePatientsList: completePatientsList || response.completePatientsList,
        });
      },
      error: (error) => {
        dispatch({ type: GET_APPOINTMENT_PATIENTS_FAILED, error: { message: error.message } });
        dispatch(reportError(error));
      },
    });
  }
);

export const getSuggestedRescheduledPatients = () => (
  (dispatch: Dispatch) => {
    dispatch({ type: GET_APPOINTMENT_PATIENTS });


    const route = router.getApiRoute('getPatients', null);

    const apiRoute = {
      ...route,
      path: `${route.path}?filter[tagIds]=reschedule`,
    };

    Api.get(apiRoute, {
      success: (response) => {
        dispatch({
          type: GET_APPOINTMENT_PATIENTS_SUCCESS,
          patients: response.patients,
          suggested: {},
          completePatientsList: true,
        });
      },
      error: (error) => {
        dispatch({ type: GET_APPOINTMENT_PATIENTS_FAILED, error: { message: error.message } });
        dispatch(reportError(error));
      },
    });
  }
);
/**
 * Create a new patient
 * @param {*} patient
 * @param {Function(boolean)} callback run this function when api call end's
 */
export const createPatient = (patient: PatientInput, callback?: Function) => (
  (dispatch: Dispatch) => {
    dispatch({ type: PATIENT_CREATE_IN_PROGRESS });
    Api.post(router.getApiRoute('createPatient', null), {
      data: { patient },
      success: (response) => {
        dispatch({
          type: PATIENT_CREATE_SUCCESS,
          patient: response.data.patient,
        });
        if (isFunction(callback)) {
          callback(true, get(response, ['data', 'patient', 'id']));
        }
      },
      error: (error) => {
        dispatch({
          type: PATIENT_CREATE_FAILED,
          error: { message: error.message, raw: error.raw },
        });
        dispatch(reportError(error));
        if (isFunction(callback)) {
          callback(false, error);
        }
      },
    });
  }
);

export const addPatientTag = (tagTemplateId: number, patientId: number) => (
  (dispatch: Dispatch) => {
    dispatch({ type: ADD_PATIENT_TAG_IN_PROGRESS, patientId });

    const data = {
      tag: {
        tagTemplateId,
      },
    };

    Api.post(router.getApiRoute('addPatientTag', { patientId }), {
      data,
      success: (response) => {
        dispatch({
          type: ADD_PATIENT_TAG_SUCCESS,
          tag: response.data.tag,
          patientId,
        });
      },
      error: (error) => {
        dispatch({
          type: ADD_PATIENT_TAG_FAILED,
          error: { message: error.message },
          patientId,
        });
        dispatch(reportError(error));
      },
    });
  }
);

export const removePatientTag = (tagId: number, patientId: number) => (
  (dispatch: Dispatch) => {
    dispatch({ type: REMOVE_PATIENT_TAG_IN_PROGRESS, patientId });
    Api.destroy(router.getApiRoute('removePatientTag', { patientId, id: tagId }), {
      data: {},
      success: () => {
        dispatch({
          type: REMOVE_PATIENT_TAG_SUCCESS,
          patientId,
          tagId,
        });
      },
      error: (error) => {
        dispatch({
          type: REMOVE_PATIENT_TAG_FAILED,
          error: { message: error.message },
          patientId,
        });
        dispatch(reportError(error));
      },
    });
  }
);

export const createAndConnectPatientTag = (tagLabel: string, patientId: number) => (
  (dispatch: Dispatch) => {
    dispatch({ type: CREATE_AND_CONNECT_PATIENT_TAG_IN_PROGRESS });

    const data = {
      tagTemplate: {
        name: tagLabel,
      },
    };

    Api.post(router.getApiRoute('createAndConnectPatientTag', { patientId }), {
      data,
      success: (response) => {
        dispatch({
          type: CREATE_AND_CONNECT_PATIENT_TAG_SUCCESS,
          tag: response.data.tag,
          patientId,
        });
      },
      error: (error) => {
        dispatch({
          type: CREATE_AND_CONNECT_PATIENT_TAG_FAILED,
          error: { message: error.message },
        });
        dispatch(reportError(error));
      },
    });
  }
);

export const updateTagsModal = (patient: Patient, tagTemplates: TagTemplates) => (
  (dispatch: Dispatch) => (
    dispatch({ type: UPDATE_TAGS_MODAL, patient, tagTemplates })
  )
);

export const uploadPatientProfileImage = (patientId: number, file: Object, fileType?: string) => (
  (dispatch: Dispatch) => {
    // Preparing (signing) image
    dispatch({
      type: PATIENT_PROFILE_IMAGE_SIGNING,
      patientId,
    });

    const signedUrlRoute = router.getApiRoute('getSignedUploadData');
    const signedUrlApiRoute = {
      ...signedUrlRoute,
      path: `${signedUrlRoute.path}?title=${slugify(file.name)}`,
    };

    Api.get(signedUrlApiRoute, {
      success: (response) => {
        const { signedData } = response;

        const formData = new FormData();
        formData.append('key', signedData.key);
        formData.append('acl', signedData.acl);
        formData.append('policy', signedData.policy);
        formData.append('x-amz-credential', signedData['x-amz-credential']);
        formData.append('x-amz-algorithm', signedData['x-amz-algorithm']);
        formData.append('x-amz-date', signedData['x-amz-date']);
        formData.append('x-amz-signature', signedData['x-amz-signature']);
        formData.append('file', file);

        // Uploading image to S3
        const directUploadUrl = signedData.action + '/' + encodeURIComponent(dropRight(signedData.key.split('/')).join('/') + '/' + slugify(file.name));
        dispatch({
          type: PATIENT_PROFILE_IMAGE_S3_UPLOADING,
          patientId,
          blob: directUploadUrl,
        });

        const signedDataActionApiRoute = {
          path: signedData.action,
          fallback: false,
        };
        Api.post(signedDataActionApiRoute, {
          data: formData,
          success: () => {
            const attachmentData = {
              dimensions: `${file.width}x${file.height}`,
              directUploadUrl,
              fileContentType: file.type || fileType,
              lsId: file.lastModified,
            };

            // Processing image
            dispatch({
              type: SET_PATIENT_PROFILE_IMAGE,
              patientId,
            });

            const setPatientProfileImageRoutePath = router.getApiRoute('setPatientProfileImage', { id: patientId });
            Api.post(setPatientProfileImageRoutePath, {
              data: {
                profileImage: attachmentData,
              },
              success: (patientProfileRes) => {
                dispatch({
                  type: SET_PATIENT_PROFILE_IMAGE_SUCCESS,
                  patientId,
                  profileImage: patientProfileRes.data.profileImage,
                });
              },
              error: (profileImageError) => {
                dispatch({
                  type: SET_PATIENT_PROFILE_IMAGE_FAILED,
                  patientId,
                  error: { message: profileImageError.message },
                });
                dispatch(reportError(profileImageError));
              },
            });
          },
          error: (uploadError) => {
            dispatch({
              type: PATIENT_PROFILE_IMAGE_S3_UPLOADING_FAILED,
              patientId,
              error: { message: uploadError.message },
            });
            dispatch(reportError(uploadError));
          },
        });
      },
      error: (signError) => {
        dispatch({
          type: PATIENT_PROFILE_IMAGE_SIGNING_FAILED,
          patientId,
          error: { message: signError.message },
        });
        dispatch(reportError(signError));
      },
    });
  }
);

export const deletePatientProfileImage = (patientId: number, imageId: number) => (
  (dispatch: Dispatch) => {
    dispatch({ type: DELETE_PATIENT_PROFILE_IMAGE });

    Api.destroy(router.getApiRoute('deletePatientProfileImage', { id: patientId, image_id: imageId }), {
      success: () => {
        dispatch({
          type: DELETE_PATIENT_PROFILE_IMAGE_SUCCESS,
          patientId,
          imageId,
        });
      },
      error: (error) => {
        dispatch({
          type: DELETE_PATIENT_PROFILE_IMAGE_FAILED,
          patientId,
          error: { message: error.message },
        });
      },
    });
  }
);

export const resetLoadingState = () => (
  (dispatch: Dispatch) => {
    dispatch({ type: PATIENT_RESET_IS_LOADING });
  }
);

export const patientActions = {
  getPatient,
  getPatients,
  updatePatient,
  filterPatients,
  searchPatients,
  getSuggestedPatients,
  createPatient,
  deletePatient,
  updatePatientHistoryNote,
  addPatientTag,
  removePatientTag,
  createAndConnectPatientTag,
  updateTagsModal,
  getPatientFinancial,
  setPatientFinancialLoading,
  showPatientPDF,
  uploadPatientProfileImage,
  deletePatientProfileImage,
  resetLoadingState,
  clearAppointmentLoadingState,
  getSuggestedRescheduledPatients,
  printPatientFinancialReport,
};

// ------------------------------------
// Reducer
// ------------------------------------
export default function patientsReducer(state: ContextState, action: Action): ContextState {
  let normalized;
  let currentPatients;
  let newTags;
  let currentTags;
  let medications;
  let problems;
  let page;
  let currentPatientsData;
  let currentDeletedPatientsData;

  switch (action.type) {
    case PATIENT_RESET_IS_LOADING:
      return state
        .setIn(['patients', 'isLoading'], false)
        .setIn(['patients', 'rawError'], {})
        .setIn(['patients', 'isFinancialLoading'], false);

    case PATIENTS_LOADING:

      if (action.resetFilters) {
        return state
          .setIn(['patients', 'isLoading'], true)
          .setIn(['patients', 'filters', 'type'], null)
          .setIn(['patients', 'filters', 'filterBy'], '')
          .setIn(['patients', 'filters', 'filterValue'], '')
          .setIn(['patients', 'isFinancialLoading'], false)
          .setIn(['patients', 'errors'], null);
      }

      return state
        .setIn(['patients', 'isLoading'], true)
        .setIn(['patients', 'isFinancialLoading'], false)
        .setIn(['patients', 'errors'], null);


    case PATIENTS_LOADED:
      normalized = PatientService.normalizePatients(action.patients);

      page = get(action, ['meta', 'current_page'], 1);
      currentPatientsData = get(state, ['patients', 'data'], {});

      const filterPatientIdsBase = map({ ...normalized.patients, ...currentPatientsData }, (patient) => (patient.id));
      currentPatients = get(state, ['patients', 'data'], Immutable({}));


      const filterBy = get(state, ['patients', 'filters', 'filterBy'], '');
      const filterValue = get(state, ['patients', 'filters', 'filterValue'], '');

      currentDeletedPatientsData = get(state, ['patients', 'filterBy', 'deleted'], {});

      return state
        .setIn(['patients', 'isLoading'], false)
        .setIn(['patients', 'pagination'], action.meta)
        .setIn(['patients', 'errors'], null)
        .setIn(['patients', 'isFinancialLoading'], false)
        .setIn(['patients', 'data'], { ...normalized.patients, ...currentPatientsData })
        .setIn(['patients', 'pageMap', page], Object.keys(normalized.patients))
        .setIn(['patients', 'searchBy'], Immutable({}))
        .setIn(['patients', 'filterBy', filterBy, filterValue], filterPatientIdsBase)
        .setIn(['patients', 'filterBy', 'deleted'], { ...normalized.patients, ...currentDeletedPatientsData })
        .setIn(['patients', 'medicationLoading'], Immutable({}));

    case PATIENTS_LOADING_FAILED:
      return state
        .setIn(['patients', 'isLoading'], false)
        .setIn(['patients', 'isFinancialLoading'], false)
        .setIn(['patients', 'errors'], [action.error.message]);

    case PATIENT_LOADING:
      return state
        .setIn(['patients', 'isLoading'], true)
        .setIn(['patients', 'getFullPatientError'], false)
        .setIn(['patients', 'isFinancialLoading'], true)
        .setIn(['patients', 'errors'], null);

    case PATIENT_LOADED:
      return state
        .setIn(['patients', 'isLoading'], false)
        .setIn(['patients', 'isFinancialLoading'], false)
        .setIn(['patients', 'errors'], null)
        .setIn(['patients', 'getFullPatientError'], false)
        .setIn(['patients', 'medicationLoading'], Immutable({}))
        .setIn(['patients', 'data', action.id], action.patient);

    case PATIENT_LOADING_FAILED:
      return state
        .setIn(['patients', 'isLoading'], false)
        .setIn(['patients', 'isFinancialLoading'], false)
        .setIn(['patients', 'getFullPatientError'], true)
        .setIn(['patients', 'errors'], [action.error.message]);

    case PATIENT_UPDATE_IN_PROGRESS:
      return state
        .setIn(['patients', 'rawError'], {})
        .setIn(['patients', 'isSaving'], true)
        .setIn(['patients', 'errors'], null);


    case PATIENT_GET_FINANCIAL_LOADING:
      return state
        .setIn(['patients', 'isFinancialLoading'], true)
        .setIn(['patients', 'errors'], null);

    case PATIENT_GET_FINANCIAL_STOP_LOADING:
      return state
        .setIn(['patients', 'isFinancialLoading'], false)
        .setIn(['patients', 'errors'], null);

    case PATIENT_GET_FINANCIAL_LOADED:
      const data = get(action, ['data'], {});
      return state
        .setIn(['patients', 'isFinancialLoading'], false)
        .setIn(['patients', 'errors'], null)
        .setIn(['patients', 'data', toString(action.id)], {
          ...get(state, ['patients', 'data', toString(action.id)], {}),
          indebt: data.indebt,
          financialBalance: data.financialBalance || 0,
          sumOfServices: data.sumOfServices || 0,
          sumOfPayments: data.sumOfPayments || 0,
        });

    case PATIENT_GET_FINANCIAL_LOADING_FAILED:
      return state
        .setIn(['patients', 'isFinancialLoading'], false)
        .setIn(['patients', 'errors'], [action.error.message]);

    case PATIENT_UPDATE_SUCCESS:
      return state
        .setIn(['patients', 'rawError'], {})
        .setIn(['patients', 'isSaving'], false)
        .setIn(['patients', 'data', action.patient.id], action.patient);

    case PATIENT_UPDATE_FAILED:
      return state
        .setIn(['patients', 'isSaving'], false)
        .setIn(['patients', 'errors'], [action.error.message])
        .setIn(['patients', 'rawError'], action.error.raw);

    case FILTER_PATIENTS:
      return state
        .setIn(['patients', 'isLoading'], true)
        .setIn(['patients', 'filters', 'type'], action.filterType)
        .setIn(['patients', 'filters', 'filterBy'], action.filterBy)
        .setIn(['patients', 'filters', 'filterValue'], action.filterValue)
        .setIn(['patients', 'errors'], null);

    case FILTER_PATIENTS_SUCCESS:
      normalized = PatientService.normalizePatients(action.patients);
      currentDeletedPatientsData = get(state, ['patients', 'filterBy', 'deleted'], {});
      page = get(action, ['meta', 'current_page'], 1);
      if (action.filterBy === 'deleted') {
        // In case of deleted patients we store patient objects in state and not just the IDs
        return state
          .setIn(['patients', 'isLoading'], false)
          .setIn(['patients', 'pagination'], action.meta)
          .setIn(['patients', 'errors'], null)
          // .setIn(['patients', 'filters', 'type'], action.filterType)
          // .setIn(['patients', 'filters', 'filterBy'], action.filterBy)
          // .setIn(['patients', 'filters', 'filterValue'], action.filterValue)
          .setIn(['patients', 'pageMap', page], Object.keys(normalized.patients))
          .setIn(['patients', 'filterBy', 'deleted'], { ...normalized.patients, ...currentDeletedPatientsData });
      }
      // Regular filter fetching
      const filterPatientIds = map(action.patients, (patient: any) => (patient.id));
      currentPatients = get(state, ['patients', 'data'], Immutable({}));

      return state
        .setIn(['patients', 'isLoading'], false)
        .setIn(['patients', 'pagination'], action.meta)
        .setIn(['patients', 'errors'], null)
        .setIn(['patients', 'filters', 'type'], action.filterType)
        .setIn(['patients', 'filters', 'filterBy'], action.filterBy)
        .setIn(['patients', 'filters', 'filterValue'], action.filterValue)
        .setIn(['patients', 'pageMap', page], Object.keys(normalized.patients))
        .setIn(['patients', 'filterBy', action.filterBy, action.filterValue], filterPatientIds)
        .setIn(['patients', 'data'], currentPatients.merge(normalized.patients));

    case FILTER_PATIENTS_FAILED:
      return state
        .setIn(['patients', 'isLoading'], false)
        .setIn(['patients', 'errors'], [action.error.message]);

    case SEARCH_PATIENTS:
      return state
        .setIn(['patients', 'isLoading'], true)
        .setIn(['patients', 'errors'], null);

    case SEARCH_PATIENTS_SUCCESS:
      currentPatients = get(state, ['patients', 'data'], Immutable({}));
      const searchPatientIds = map(action.patients, (patient: any) => (patient.id));
      normalized = PatientService.normalizePatients(action.patients);
      page = get(action, ['meta', 'current_page'], 1);

      return state
        .setIn(['patients', 'isLoading'], false)
        .setIn(['patients', 'pagination'], action.meta)
        .setIn(['patients', 'errors'], null)
        .setIn(['patients', 'filters', 'type'], null)
        .setIn(['patients', 'filters', 'filterBy'], '')
        .setIn(['patients', 'filters', 'filterValue'], '')
        .setIn(['patients', 'data'], currentPatients.merge(normalized.patients))
        .setIn(['patients', 'pageMap', page], Object.keys(normalized.patients))
        .setIn(['patients', 'searchBy', `_${action.searchValue}_`], searchPatientIds)
        .setIn(['patients', 'searchByValue'], action.searchValue);

    case SEARCH_PATIENTS_FAILED:
      return state
        .setIn(['patients', 'isLoading'], false)
        .setIn(['patients', 'errors'], [action.error.message]);

    case GET_APPOINTMENT_PATIENTS:
      return state
        .setIn(['appointmentPatients', 'isLoading'], true)
        .setIn(['appointmentPatients', 'errors'], null);

    case GET_APPOINTMENT_PATIENTS_SUCCESS:
      return state
        .setIn(['appointmentPatients', 'isLoading'], false)
        .setIn(['appointmentPatients', 'errors'], null)
        .setIn(['appointmentPatients', 'completePatientsList'], action.completePatientsList || false)
        .setIn(['appointmentPatients', 'suggested'], action.suggested)
        .setIn(['appointmentPatients', 'patients'], action.patients);

    case GET_APPOINTMENT_PATIENTS_FAILED:
      return state
        .setIn(['appointmentPatients', 'isLoading'], false)
        .setIn(['appointmentPatients', 'errors'], [action.error.message]);

    case PATIENT_CREATE_IN_PROGRESS:
      return state
        .setIn(['patients', 'isSaving'], true)
        .setIn(['patients', 'rawError'], {})
        .setIn(['patients', 'lastCreatedPatientId'], null)
        .setIn(['patients', 'errors'], null);

    case PATIENT_CREATE_SUCCESS:
      const patients = get(state, ['patients', 'data'], Immutable({}));
      const newPatients = patients.merge({ [action.patient.id]: action.patient });
      return state
        .setIn(['patients', 'isSaving'], false)
        .setIn(['patients', 'rawError'], {})
        .setIn(['patients', 'lastCreatedPatientId'], action.patient.id)
        .setIn(['patients', 'data'], newPatients);

    case PATIENT_CREATE_FAILED:
      return state
        .setIn(['patients', 'isSaving'], false)
        .setIn(['patients', 'lastCreatedPatientId'], null)
        .setIn(['patients', 'errors'], [action.error.message])
        .setIn(['patients', 'rawError'], action.error.raw);

    case PATIENT_DELETE_IN_PROGRESS:
      return state
        .setIn(['patients', 'isSaving'], true)
        .setIn(['patients', 'errors'], null);

    case PATIENT_DELETE_SUCCESS:
      const patientsList = get(state, ['patients', 'data'], Immutable({}));
      const newPatientsList = omit(patientsList, [String(action.deleteId)]);
      return state
        .setIn(['patients', 'isSaving'], false)
        .setIn(['patients', 'data'], newPatientsList);

    case PATIENT_DELETE_FAILED:
      return state
        .setIn(['patients', 'isSaving'], false)
        .setIn(['patients', 'errors'], [action.error.message]);


    case PATIENT_UPDATE_HISTORY_NOTE_IN_PROGRESS:
      return state
        .setIn(['patients', 'isSavingHistoryNote'], true);

    case PATIENT_UPDATE_HISTORY_NOTE_SUCCESS:
      return state
        .setIn(['patients', 'isSavingHistoryNote'], false)
        .setIn(['patients', 'data', String(action.patientId), 'historyNote'], get(action, 'historyNote', ''));

    case PATIENT_UPDATE_HISTORY_NOTE_FAILED:
      return state
        .setIn(['patients', 'isSavingHistoryNote'], false)
        .setIn(['patients', 'errors'], [action.error.message]);

    case ADD_PATIENT_TAG_IN_PROGRESS:
      return state
        .setIn(['patients', 'tagsLoading', String(action.patientId)], true);

    case ADD_PATIENT_TAG_SUCCESS:
      currentTags = get(state, ['patients', 'data', String(action.patientId), 'tags']);
      newTags = concat(currentTags, [action.tag]);
      return state
        .setIn(['patients', 'tagsLoading', String(action.patientId)], false)
        .setIn(['patients', 'data', String(action.patientId), 'tags'], newTags);

    case ADD_PATIENT_TAG_FAILED:
      return state.setIn(['patients', 'tagsLoading', String(action.patientId)], true);

    case REMOVE_PATIENT_TAG_IN_PROGRESS:
      return state.setIn(['patients', 'tagsLoading', String(action.patientId)], true);

    case REMOVE_PATIENT_TAG_SUCCESS:
      const { tagId } = action;
      currentTags = get(state, ['patients', 'data', String(action.patientId), 'tags']);
      newTags = reject(currentTags, (tag) => (tag.id === tagId));
      return state
        .setIn(['patients', 'tagsLoading', String(action.patientId)], false)
        .setIn(['patients', 'data', String(action.patientId), 'tags'], newTags);

    case REMOVE_PATIENT_TAG_FAILED:
      return state.setIn(['patients', 'tagsLoading', String(action.patientId)], false);

    case CREATE_AND_CONNECT_PATIENT_TAG_SUCCESS:
      const { tag } = action;
      const newTag = {
        id: tag.templateId,
        name: tag.name,
        special: null,
      };

      const currentTagTemplates = get(state, ['tagTemplates', 'data']);
      const newTagTemplates = currentTagTemplates.merge({ [newTag.id]: newTag });
      currentTags = get(state, ['patients', 'data', String(action.patientId), 'tags']);
      newTags = concat(currentTags, [action.tag]);
      return state
        .setIn(['patients', 'data', String(action.patientId), 'tags'], newTags)
        .setIn(['tagTemplates', 'data'], newTagTemplates);

    case UPDATE_TAGS_MODAL:
      const currentModalData = get(state, ['modals', 'data'], Immutable({}));
      const newModalData = {
        ...currentModalData,
        patient: action.patient,
        tagTemplates: action.tagTemplates,
      };
      return state
        .setIn(['modals', 'data'], newModalData);

    case PATIENT_PROFILE_IMAGE_SIGNING:
      return state
        .setIn(['patients', 'data', action.patientId, 'profileImage', 'progress'], 'preparing');

    case PATIENT_PROFILE_IMAGE_S3_UPLOADING:
      return state
        .setIn(['patients', 'data', action.patientId, 'profileImage', 'progress'], 'uploading')
        .setIn(['patients', 'data', action.patientId, 'profileImage', 'blob'], action.blob);

    case SET_PATIENT_PROFILE_IMAGE:
      return state
        .setIn(['patients', 'data', action.patientId, 'profileImage', 'progress'], 'processing');

    case SET_PATIENT_PROFILE_IMAGE_SUCCESS:
      return state
        .setIn(['patients', 'data', action.patientId, 'profileImage'], action.profileImage);

    case SET_PATIENT_PROFILE_IMAGE_FAILED:
      return state
        .setIn(['patients', 'data', action.patientId, 'profileImage', 'errors'], [action.error.message])
        .setIn(['patients', 'data', action.patientId, 'profileImage', 'blob'], null)
        .setIn(['patients', 'data', action.patientId, 'profileImage', 'progress'], null);

    case PATIENT_PROFILE_IMAGE_S3_UPLOADING_FAILED:
      return state
        .setIn(['patients', 'data', action.patientId, 'profileImage', 'errors'], [action.error.message])
        .setIn(['patients', 'data', action.patientId, 'profileImage', 'blob'], null)
        .setIn(['patients', 'data', action.patientId, 'profileImage', 'progress'], null);

    case PATIENT_PROFILE_IMAGE_SIGNING_FAILED:
      return state
        .setIn(['patients', 'data', action.patientId, 'profileImage', 'errors'], [action.error.message])
        .setIn(['patients', 'data', action.patientId, 'profileImage', 'blob'], null)
        .setIn(['patients', 'data', action.patientId, 'profileImage', 'progress'], null);

    case DELETE_PATIENT_PROFILE_IMAGE_SUCCESS:
      const patient = get(state, ['patients', 'data', toString(action.patientId)]);
      const newPatientData = omit(patient, 'profileImage');

      return state
        .setIn(['patients', 'data', action.patientId], newPatientData);

    case DELETE_PATIENT_PROFILE_IMAGE_FAILED:
      return state
        .setIn(['patients', 'data', action.patientId, 'profileImage', 'errors'], [action.error.message]);


    case PATIENT_RECOVER_LOADING:
      return state
        .setIn(['patients', 'isLoading'], true);

    case PATIENT_RECOVER_LOADED:
      return state
        .setIn(['patients', 'isLoading'], false)
        .setIn(['patients', 'data', action.patient.id], action.patient);

    case PATIENT_RECOVER_ERROR:
      return state
        .setIn(['patients', 'isLoading'], false)
        .setIn(['patients', 'data', 'errors'], [action.error.message]);

      // medication
    case PATIENT_ADD_MEDICATION_LOADING:
      return state
        .setIn(['patients', 'medicationLoading', String(action.patientId)], true);

    case PATIENT_ADD_MEDICATION_LOADED:
      medications = get(state, ['patients', 'data', String(action.patientId), 'medications'], []);
      return state
        .setIn(['patients', 'medicationLoading', String(action.patientId)], false)
        .setIn(['patients', 'data', String(action.patientId), 'medications'], [...medications, action.medication]);

    case PATIENT_ADD_MEDICATION_ERROR:
      return state
        .setIn(['patients', 'medicationLoading', String(action.patientId)], false)
        .setIn(['patients', 'data', 'errors'], [action.error.message]);

    case PATIENT_REMOVE_MEDICATION_LOADING:
      return state
        .setIn(['patients', 'medicationDeleting', String(action.patientId)], true)
        .setIn(['patients', 'medicationLoading', String(action.patientId)], true);

    case PATIENT_REMOVE_MEDICATION_LOADED:
      medications = get(state, ['patients', 'data', String(action.patientId), 'medications'], []);
      return state
        .setIn(['patients', 'medicationDeleting', String(action.patientId)], false)
        .setIn(['patients', 'medicationLoading', String(action.patientId)], false)
        .setIn(['patients', 'data', String(action.patientId), 'medications'], filter(medications, med => med.id !== get(action, 'deleteId')));

    case PATIENT_REMOVE_MEDICATION_ERROR:
      return state
        .setIn(['patients', 'medicationDeleting', String(action.patientId)], false)
        .setIn(['patients', 'medicationLoading', String(action.patientId)], false)
        .setIn(['patients', 'data', 'errors'], [action.error.message]);

      // problems
    case PATIENT_ADD_PROBLEM_LOADING:
      return state
        .setIn(['patients', 'problemsLoading', String(action.patientId)], true);

    case PATIENT_ADD_PROBLEM_LOADED:
      problems = get(state, ['patients', 'data', String(action.patientId), 'problems'], []);
      return state
        .setIn(['patients', 'problemsLoading', String(action.patientId)], false)
        .setIn(['patients', 'data', String(action.patientId), 'problems'], [...problems, action.problem]);

    case PATIENT_ADD_PROBLEM_ERROR:
      return state
        .setIn(['patients', 'problemsLoading', String(action.patientId)], false)
        .setIn(['patients', 'data', 'errors'], [action.error.message]);

    case PATIENT_REMOVE_PROBLEM_LOADING:
      return state
        .setIn(['patients', 'problemsLoading', String(action.patientId)], true);

    case PATIENT_REMOVE_PROBLEM_LOADED:
      problems = get(state, ['patients', 'data', String(action.patientId), 'problems'], []);
      return state
        .setIn(['patients', 'problemsLoading', String(action.patientId)], false)
        .setIn(['patients', 'problemsDeleting', String(action.patientId)], false)
        .setIn(['patients', 'data', String(action.patientId), 'problems'], filter(problems, p => p.id !== get(action, 'deleteId')));

    case PATIENT_REMOVE_PROBLEM_ERROR:
      return state
        .setIn(['patients', 'problemsDeleting', String(action.patientId)], false)
        .setIn(['patients', 'problemsLoading', String(action.patientId)], false)
        .setIn(['patients', 'data', 'errors'], [action.error.message]);

    case PATIENT_PRINT_PDF_IN_PROGRESS:
      return state
        .setIn(['patients', 'isPrinting'], true);

    case PATIENT_PRINT_PDF_SUCCESS:
      return state
        .setIn(['patients', 'isPrinting'], false);

    case PATIENT_PRINT_PDF_FAILED:
      return state
        .setIn(['patients', 'isPrinting'], false);

    case PATIENT_CLEAR_APPOINTMENT_LOADING_STATE:
      return state
        .setIn(['appointmentPatients', 'isLoading'], false);
    default:
      return state;
  }
}
