// @flow
import React from 'react';
import { throttle } from 'lodash';
import { canUseDOM } from 'exenv';
import Portal from './Portal';

const listeners = {};

function fireListeners() {
  Object.keys(listeners).forEach(key => listeners[key]());
}

const documentBody = (): any => document.body;
const documentElement = (): any => document.documentElement;

function getPageOffset() {
  return {
    x: (window.pageXOffset !== undefined)
      ? window.pageXOffset
      : (documentElement() || documentBody().parentNode || documentBody()).scrollLeft,
    y: (window.pageYOffset !== undefined)
      ? window.pageYOffset
      : (documentElement() || documentBody().parentNode || documentBody()).scrollTop,
  };
}

function initDOMListener() {
  documentBody().addEventListener('mousewheel', throttle(fireListeners, 100, {
    leading: true,
    trailing: true,
  }));
  window.addEventListener('resize', throttle(fireListeners, 50, {
    leading: true,
    trailing: true,
  }));
}

if (canUseDOM) {
  if (document.body) {
    initDOMListener();
  } else {
    document.addEventListener('DOMContentLoaded', initDOMListener);
  }
}

let listenerIdCounter = 0;
function subscribe(fn) {
  listenerIdCounter += 1;
  const id = listenerIdCounter;
  listeners[id] = fn;
  return () => delete listeners[id];
}

type Props = {
    right?: number,
    left?: number,
    fullWidth?: bool,
    top?: number,
    onOutClick?: Function,
    component: string,
    className?: string,
    children?: any,
};

export default class RelativePortal extends React.Component<Props, any> {
  static defaultProps = {
    left: 0,
    top: 0,
    component: 'span',
  };

  constructor(props: Props) {
    super(props);

    this.state = {
      right: 0,
      left: 0,
      top: 0,
    };
  }


  componentDidMount() {
    this.unsubscribe = subscribe(this.handleScroll);
    this.handleScroll();
  }

  componentDidUpdate() {
    this.handleScroll();
  }

  componentWillUnmount() {
    this.unsubscribe();
  }

  render() {
    const {
      component: Comp, top, left, right, fullWidth, className, ...props
    } = this.props;

    const fromLeftOrRight = right !== undefined ?
      { right: this.state.right + right } :
      { left: this.state.left + left };

    const horizontalPosition = fullWidth ?
      { right: this.state.right + right, left: this.state.left + left } : fromLeftOrRight;

    return (
      <Comp
        ref={element => {
          this.element = element;
        }}
      >
        <Portal {...props}>
          <div
            style={{
              position: 'absolute',
              top: this.state.top + top,
              ...horizontalPosition,
            }}
            className={className}
          >
            {this.props.children}
          </div>
        </Portal>
      </Comp>
    );
  }

  unsubscribe: any;
  element: any;

  handleScroll = () => {
    if (this.element) {
      const rect = this.element.getBoundingClientRect();
      const pageOffset = getPageOffset();
      const top = pageOffset.y + rect.top;
      const right = documentElement().clientWidth - rect.right - pageOffset.x;
      const left = pageOffset.x + rect.left;

      if (top !== this.state.top || left !== this.state.left || right !== this.state.right) {
        this.setState({ left, top, right });
      }
    }
  }
}
