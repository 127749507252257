// @flow
import React from 'react';
import { find, isEmpty } from 'lodash';
import { Button, Loading } from '@elements';
import type { CordovaPurchaseProduct } from '@types';
import { withTranslation } from 'react-i18next';
import { createNsTranslator } from '@helpers/helpers';
import PlanItem from './PlanItem';
import { SubscriptionHeader } from '@blocks';
import Slider from 'react-slick';
import './PricingIos.scss';

type Props = {
  onSelectPlan: Function,
  onRestorePurchases: Function,
  products: Array<CordovaPurchaseProduct>,
  t: Function,
};


class PricingIos extends React.Component<Props> {
  componentWillMount() {
    window.document.body.classList.add('hideHeader');
  }

  componentWillUnmount() {
    window.document.body.classList.remove('hideHeader');
  }

  render() {
    const { products } = this.props;

    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: true,
    };

    const _ = createNsTranslator('subscription', this.props.t);

    const planBasic: Object = find(products, it => String(it.id).startsWith('basic'));
    const planComplete: Object = find(products, it => String(it.id).startsWith('complete'));
    const planConcierge: Object = find(products, it => String(it.id).startsWith('concierge'));

    const plans = {
      basic: {
        tmpType: 'basic',
        ...planBasic,
      },
      complete: {
        tmpType: 'complete',
        recommended: true,
        ...planComplete,
      },
      concierge: {
        tmpType: 'concierge',
        ...planConcierge,
      },
    };

    const availablePlansLoading = false;
    // console.log(JSON.stringify({products, plans, availablePlansLoading}));

    return (
      <div id="pricingPage" className="appContent plansPage settingsPage mobilePricingPageView pricingIos">
        <SubscriptionHeader backTo="/beneficios" forceVisibility/>
        <section className="mainSection">
          <div className="centerContent">
            <h1>{_('action/select_a_plan')}</h1>
            {availablePlansLoading && <Loading className="localloadingpage"/>}
            <div className="pricingOptions ios">
              <div className="princing-row">
                {!availablePlansLoading && (
                <Slider {...settings}>
                  {!isEmpty(plans.basic) && (
                  <div className="slideItem">
                    <PlanItem
                      isLoading={!plans.basic.loaded}
                      isSelectingPlan={false}
                      plan={plans.basic}
                      tmpType={plans.basic.tmpType}
                      handleSelectPlan={this.handleSelectPlan}
                      _={_}
                    />
                  </div>
                  )}
                  {!isEmpty(plans.complete) && (
                  <div className="slideItem">
                    <PlanItem
                      isLoading={!plans.complete.loaded}
                      isSelectingPlan={false}
                      plan={plans.complete}
                      tmpType={plans.complete.tmpType}
                      handleSelectPlan={this.handleSelectPlan}
                      _={_}
                    />
                  </div>
                  )}
                  {!isEmpty(plans.concierge) && (
                  <div className="slideItem">
                    <PlanItem
                      isLoading={!plans.concierge.loaded}
                      isSelectingPlan={false}
                      plan={plans.concierge}
                      tmpType={plans.concierge.tmpType}
                      handleSelectPlan={this.handleSelectPlan}
                      _={_}
                    />
                  </div>
                  )}
                  {/* <div className="slideItem">
                    <div className="princinf-footer-plan">
                      <div className="pricingPack clinicPackCta">
                        <Icon iconName="color-clinic" className="icon" />
                        <h2>{_('label/quest_have_clinic')}</h2>
                        <Button text={_('action/contact')} onClick={this.handleShowIntercom} />
                      </div>
                    </div>
                  </div> */}
                </Slider>
                )}
              </div>
              <div className="iosPaymentText">
                {_('label/ios_payment_text')}
              </div>
              <Button
                className="restore"
                text={_('action/restore_purchases')}
                onClick={this.handleRestorePurchases}
              />
              <br/>
              <a href="https://www.doutore.com/termos_de_uso" className="pricing-terms-of-use">Termos de Uso e Privacidade</a>
              <br/>
            </div>
          </div>
        </section>
      </div>
    );
  }

  handleSelectPlan = (productID: string) => () => {
    this.props.onSelectPlan(productID);
  };

  handleRestorePurchases = () => {
    this.props.onRestorePurchases();
  };
  handleShowIntercom = () => {
    if (window.Intercom) {
      window.Intercom('show');
    }
  };
}

export default withTranslation()(PricingIos);
