import axios from 'axios';
import { get } from 'lodash';
import { Log } from '../../helpers';
import { appControlTypes } from '../modules/appControl';
import { actionTypes as authActions } from '../modules/auth';
import { userActions, actionTypes as userActionTypes } from '@context/user';
import { clinicActions, actionTypes as clinicsActionTypes, fetchPrescriptionLayout } from '@context/clinics';
import { drugsPrescriptionsTemplateActions } from '@context/drugsPrescriptionsTemplates';
import { examsPrescriptionsTemplateActions } from '@context/examsPrescriptionsTemplates';
import { tagTemplateActions } from '@context/tagTemplates';


export default store => next => action => {
  if (typeof window === 'undefined') {
    // server-side rendering
    Log.error('Cannot dump state on the server!');
    return next(action);
  }

  // Update storage and reload on app update
  if (action.type === appControlTypes.UPDATE_APP) {
    const state = store.getState();
    const { dispatch } = store;


    const { token } = state.auth;
    const { id: clinicId } = state.context.clinics.activeClinic;
    const doctor = get(state, ['context', 'doctors', 'data', get(state, ['context', 'user', 'advancedData', 'doctorId'])], null);

    dispatch({ type: appControlTypes.RESET_STORE });
    dispatch({ type: authActions.AUTH_SET_TOKEN, token });

    axios.defaults.headers.clinicId = clinicId;

    const getUserAction = userActions.getUser();
    getUserAction.call().then((getUserResponse) => {
      getUserAction.parseResponse(getUserResponse);

      if (getUserResponse.data.user) {
        dispatch({ type: userActionTypes.USER_LOADED, user: getUserResponse.data.user });

        const getClinicsAction = clinicActions.getClinics(null, clinicId);
        getClinicsAction.call().then((getClinicsResponse) => {
          const userClinicsData = getClinicsAction.parseResponse(getClinicsResponse.data);

          dispatch({ type: clinicsActionTypes.CLINICS_LOADED, ...userClinicsData });

          dispatch(clinicActions.setMemedLoadedStatus(true));

          dispatch(drugsPrescriptionsTemplateActions.getDrugsPrescriptionsTemplates(true));

          dispatch(examsPrescriptionsTemplateActions.getExamsPrescriptionsTemplates());

          dispatch(tagTemplateActions.listTagTemplate(true));

          if (doctor) dispatch(fetchPrescriptionLayout(doctor.id));
        });
      }
    });
  }

  next(action);
};
