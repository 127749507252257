// @flow
import React from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { uniq, concat, get, difference, toString, isEqual, forEach } from 'lodash';
import { isPresent, UserNotifications } from '@helpers/helpers';
import { router } from '@router';
import { mapPlanIncludes, mapPermissionTo } from '@helpers/connect';
import { actions } from '../redux/modules/context/modals';
import { directShare, recordActions, restoreRecord, updateRecordDate } from '../redux/modules/context/records';
import { drugsPrescriptionActions } from '../redux/modules/context/drugsPrescriptions';
import { examsPrescriptionActions } from '../redux/modules/context/examsPrescriptions';
import { patientActions } from '../redux/modules/context/patients';
import { documentActions } from '../redux/modules/context/documents';
import { customFormActions } from '../redux/modules/context/customForms';
import { customFormTemplateActions } from '../redux/modules/context/customFormTemplates';
import { tagTemplateActions } from '../redux/modules/context/tagTemplates';
import { attachmentActions, donwloadRecordAttachment } from '../redux/modules/context/attachments';

import type {
  Dispatch, RecordForm, Patient,
  RecordsState, FutureRecordsState, DrugsPrescriptionsState,
  ExamsPrescriptionsState, DocumentsState, CustomFormTemplatesState,
  CustomFormsState, AttachmentsState, PlanInclude, PermissionTo,
} from '../types';
import { PatientTimeline as PatientTimelineView } from '../components/views';
import { openToast } from '@context/toasts';
import { withTranslation } from 'react-i18next';

type State = {
  newRecords: Array<number>,
};

type Props = {
  dispatch: Function,
  patient: Patient,
  isPatientPrinting: boolean,
  patientId: number,
  futureRecords: FutureRecordsState,
  records: RecordsState,
  drugsPrescriptions: DrugsPrescriptionsState,
  examsPrescriptions: ExamsPrescriptionsState,
  documents: DocumentsState,
  customFormTemplates: CustomFormTemplatesState,
  customForms: CustomFormsState,
  attachments: AttachmentsState,
  isPatientLoading: boolean,
  isRecordCreating: boolean,
  historyNoteLoading: boolean,
  recordDeletingIds: Object,
  isRecordLoading: boolean,
  recordsEditModesList: Object,
  updatingIds: Object,
  planInclude: PlanInclude,
  isMemedLoaded: boolean,
  memedToken: string,
  doctorId: number,
  clinicId: number,
  userEmail: string,
  permissions:PermissionTo,
  t: Function;
};

const nl2Br = text => String(text).replace(/(?:\r\n|\r|\n)/g, '<br>');

class PatientTimeline extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      newRecords: [],
    };
  }

  componentWillMount() {
    const {
      dispatch, patient, patientId,
    } = this.props;


    if (isPresent(get(patient, 'deletedAt'))) {
      this.props.dispatch(router.pushPath('patientDeleted', { id: patientId }));
    }

    if (!get(patient, 'full', false) && !this.props.isPatientLoading) {
      dispatch(patientActions.getPatient(patientId));
    }

    if (patient) {
      this.loadTimelineData(patient.id, this.props);
    }
  }

  componentWillReceiveProps(nextProps: Props) {
    const { patient, dispatch } = this.props;

    if (isPresent(get(patient, 'deletedAt'))) {
      this.props.dispatch(router.pushPath('patientDeleted', { id: nextProps.patientId }));
    }

    // Patient received, get corresponding patient timeline data
    if (!patient && nextProps.patient && !nextProps.isPatientLoading) {
      this.loadTimelineData(nextProps.patient.id, nextProps);
    } else if (nextProps.patient) { // Patient already loaded
      const patientId = nextProps.patient.id;
      const currentRecordIds = get(this.props, ['records', 'byPatient', toString(patientId)]);
      const nextRecordIds = get(nextProps, ['records', 'byPatient', toString(patientId)]);

      const newRecords = difference(nextRecordIds, currentRecordIds);

      if (newRecords.length > 0) {
        this.setState({ newRecords: uniq(concat(this.state.newRecords, newRecords)) });
      }

      // if (!nextProps.getFullPatientError && !nextProps.patient.full && !this.props.isPatientLoading) {
      //   dispatch(patientActions.getPatient(patientId));
      // }

      // Update tags manager modal on patient change
      const isTagsFormModal = get(nextProps, ['modals', 'active'], '') === 'TagsFormModal';
      if (!isEqual(this.props.patient, nextProps.patient) && isTagsFormModal) {
        const tagTemplates = get(nextProps, ['tagTemplates', 'data']);
        dispatch(patientActions.updateTagsModal(nextProps.patient, tagTemplates));
      }
    }
  }

  render() {
    const {
      records, futureRecords, patient, isPatientPrinting, isRecordCreating, recordDeletingIds,
      drugsPrescriptions, examsPrescriptions, documents, isRecordLoading,
      customForms, customFormTemplates, attachments, historyNoteLoading,
      recordsEditModesList, updatingIds, planInclude, isMemedLoaded, memedToken,
      permissions, clinicId, userEmail,
    } = this.props;

    const { newRecords } = this.state;

    return (
      <PatientTimelineView
        isLoading={!patient}
        patient={patient}
        clinicId={clinicId}
        userEmail={userEmail}
        isPatientPrinting={isPatientPrinting}
        records={records}
        futureRecords={futureRecords}
        drugsPrescriptions={drugsPrescriptions}
        examsPrescriptions={examsPrescriptions}
        documents={documents}
        customForms={customForms}
        customFormTemplates={customFormTemplates}
        newRecords={newRecords}
        attachments={attachments}
        onUpdateVisibility={this.handleUpdateVisibility}
        onNewDocumentClick={this.handleNewDocumentClick}
        onPrintDocumentClick={this.handlePrintDocumentClick}
        onEditDocumentClick={this.handleEditDocumentClick}
        onNewPrescriptionDrugsClick={this.handleNewPrescriptionDrugsClick}
        onEditPrescriptionDrugsClick={this.handleEditPrescriptionDrugsClick}
        onPrintPrescriptionDrugsClick={this.handlePrintPrescriptionDrugsClick}
        onNewPrescriptionExamsClick={this.handleNewPrescriptionExamsClick}
        onEditPrescriptionExamsClick={this.handleEditPrescriptionExamsClick}
        onPrintPrescriptionExamsClick={this.handlePrintPrescriptionExamsClick}
        onNewCustomFormClick={this.handleNewCustomFormClick}
        onEditCustomFormClick={this.handleEditCustomFormClick}
        onPrintCustomFormClick={this.handlePrintCustomFormClick}
        onMedicationsFormClick={this.handleMedicationsFormClick}
        onProblemsFormClick={this.handleProblemsFormClick}
        onTagsFormClick={this.handleTagsFormClick}
        onCreateRecord={this.handleCreateRecord}
        onAutosaveRecord={this.handleAutoSaveRecord}
        onSaveRecord={this.handleUpdateRecord}
        onChangeVisibilityLevel={this.handleUpdateVisibility}
        onDeleteRecord={this.handleDeleteRecord}
        onSelectFile={this.handleSelectFile}
        onPrintSingleRecord={this.handlePrintSingleRecord}
        onPrintAllRecords={this.handlePrintAllRecords}
        onDeleteAttachment={this.handleDeleteAttachment}
        onSaveHistoryNote={this.handleSaveHistoryNote}
        onRemoveTag={this.handleRemoveTag}
        historyNoteLoading={historyNoteLoading}
        isRecordCreating={isRecordCreating}
        recordDeletingIds={recordDeletingIds}
        isRecordLoading={isRecordLoading}
        onSetEditMode={this.handleSetEditMode}
        recordsEditModesList={recordsEditModesList}
        updatingIds={updatingIds}
        planInclude={planInclude}
        onSelectPatientProfileImage={this.handleSelectPatientProfileImage}
        onDeletePatientProfileImage={this.handleDeletePatientProfileImage}
        isMemedLoaded={isMemedLoaded}
        onAddMemedId={this.handleAddMemedIdMemedId}
        memedToken={memedToken}
        onPrintMemedPrescription={this.handlePrintMemedPrescription}
        onDeleteMemedId={this.handleDeleteMemedId}
        permissions={permissions}
        onRestoreRecord={this.handleRestoreRecord}
        onDownloadAttachment={this.handleDownloadAttachment}
        onDisplayAttachmentFileName={this.handleDisplayAttachmentFileName}
        onUpdateRecordDate={this.handleUpdateRecordDate}
        onDirectShare={this.handleDirectShare}
      />
    );
  }

  handleDisplayAttachmentFileName = (success: boolean, fileName?: string) => {
    const { dispatch, t } = this.props;
    if (success && fileName) {
      // dispatch(openToast({
      //   type: 'success',
      //   text: `${t('message/images_saved')} ${fileName}`,
      //   close: true,
      // }));
    } else {
      dispatch(openToast({
        type: 'error',
        text: t('message/download_error'),
        close: true,
        timeout: 3000,
      }));
    }
  };

  handleDirectShare = (recordId: number, resourceName: any, resourceId: number, respondWithUrls: (wtt: string | null, mail: string | null) => void) => {
    const { dispatch, patient } = this.props;
    dispatch(directShare(patient.id, recordId, resourceName, resourceId, respondWithUrls));
  };

  handleDownloadAttachment = (recordId: number, attachmentId: number, callBack: Function, disableShare: boolean) => {
    const { dispatch } = this.props;
    dispatch(donwloadRecordAttachment(recordId, attachmentId, callBack, disableShare));
  };

  handleUpdateRecordDate = (recordId: number, date: string) => {
    this.props.dispatch(updateRecordDate(recordId, date));
  };

  loadTimelineData = (patientId: number, { planInclude }: Props) => {
    if (planInclude.timeline) {
      this.props.dispatch(recordActions.getRecords(patientId));
      this.props.dispatch(recordActions.getFutureRecords(patientId));
      this.props.dispatch(customFormTemplateActions.getCustomFormTemplates());
    }
    this.props.dispatch(tagTemplateActions.listTagTemplate());
  };

  handlePrintMemedPrescription = (id: number, token: string) => {
    this.props.dispatch(recordActions.printMemedPrescription(id, token));
  };

  handleRemoveTag = (tag: Object) => {
    const { patient } = this.props;
    this.props.dispatch(patientActions.removePatientTag(tag.id, patient.id));
  };

  handlePrintSingleRecord = (recordId: number, simple: boolean, withSignature: boolean) => {
    this.props.dispatch(recordActions.showSingleRecordPDF(recordId, simple, withSignature));
  };

  handlePrintAllRecords = (patientId: number) => {
    this.props.dispatch(recordActions.showAllRecordsPDF(patientId));
  };

  handleNewDocumentClick = (data: Object) => {
    const { patient } = this.props;
    this.props.dispatch(actions.openModal('DocumentFormModal', { ...data, patientName: get(patient, 'name') }));
  };

  handleEditDocumentClick = (data: Object) => {
    const { patient } = this.props;
    this.props.dispatch(actions.openModal('DocumentFormModal', { ...data, patientName: get(patient, 'name') }));
  };

  handlePrintDocumentClick = (recordId: number, documentId: number, showSignature: boolean, sendEmail: boolean) => {
    const { patient } = this.props;

    if (sendEmail) {
      if (!isPresent(patient.email)) {
        UserNotifications.info('info/patient_needs_email').then(() => {});
      } else {
        this.props.dispatch(documentActions.showDocumentPDF(recordId, documentId, showSignature, sendEmail));
      }
    } else {
      this.props.dispatch(documentActions.showDocumentPDF(recordId, documentId, showSignature, sendEmail));
    }
  };

  handleNewPrescriptionDrugsClick = (data: Object) => {
    this.props.dispatch(actions.openModal('PrescriptionDrugsModal', data));
  };

  handleEditPrescriptionDrugsClick = (data: Object) => {
    this.props.dispatch(actions.openModal('PrescriptionDrugsModal', data));
  };

  handlePrintPrescriptionDrugsClick = (recordId: number, prescriptionId: number, showSignature: boolean, sendEmail: boolean) => {
    const { patient } = this.props;

    if (sendEmail) {
      if (!isPresent(patient.email)) {
        UserNotifications.info('info/patient_needs_email').then(() => {});
      } else {
        this.props.dispatch(drugsPrescriptionActions.showDrugsPrescriptionPDF(recordId, prescriptionId, showSignature, sendEmail));
      }
    } else {
      this.props.dispatch(drugsPrescriptionActions.showDrugsPrescriptionPDF(recordId, prescriptionId, showSignature, sendEmail));
    }
  };

  handleNewPrescriptionExamsClick = (data: Object) => {
    this.props.dispatch(actions.openModal('PrescriptionExamsModal', data));
  };

  handleEditPrescriptionExamsClick = (data: Object) => {
    this.props.dispatch(actions.openModal('PrescriptionExamsModal', data));
  };

  handlePrintPrescriptionExamsClick = (recordId: number, prescriptionId: number, showSignature: boolean, sendEmail: boolean) => {
    const { patient } = this.props;

    if (sendEmail) {
      if (!isPresent(patient.email)) {
        UserNotifications.info('info/patient_needs_email').then(() => {});
      } else {
        this.props.dispatch(examsPrescriptionActions.showExamsPrescriptionPDF(recordId, prescriptionId, showSignature, sendEmail));
      }
    } else {
      this.props.dispatch(examsPrescriptionActions.showExamsPrescriptionPDF(recordId, prescriptionId, showSignature, sendEmail));
    }
  };

  handleNewCustomFormClick = (data: Object) => {
    this.props.dispatch(actions.openModal('CustomFormModal', data));
  };

  handleEditCustomFormClick = (data: Object) => {
    this.props.dispatch(actions.openModal('CustomFormModal', data));
  };

  handlePrintCustomFormClick = (recordId: number, customFormId: number) => {
    this.props.dispatch(customFormActions.showCustomFormPDF(recordId, customFormId));
  };

  handleMedicationsFormClick = () => {
    this.props.dispatch(actions.openModal('MedicationsFormModal', { id: this.props.patientId }));
  };

  handleProblemsFormClick = () => {
    this.props.dispatch(actions.openModal('ProblemsFormModal', { id: this.props.patientId }));
  };

  handleTagsFormClick = () => {
    const data = {
      tagTemplates: get(this.props, ['tagTemplates', 'data']),
      patient: this.props.patient,
    };
    this.props.dispatch(actions.openModal('TagsFormModal', data));
  };

  handleGetRecord = (recordId: number) => {
    this.props.dispatch(recordActions.getRecord(recordId));
  };

  handleDeleteAttachment = (recordId: number, attachmentId: number) => {
    this.props.dispatch(attachmentActions.deleteRecordAttachment(recordId, attachmentId));
  };

  handleCreateRecord = () => {
    const record = {
      content: '',
      date: moment().toISOString(),
      visibility_level: 1,
    };

    this.props.dispatch(recordActions.createRecord(this.props.patient.id, record));
  };

  handleAutoSaveRecord = (record: RecordForm) => {
    this.props.dispatch(recordActions.autosaveRecord({
      ...record,
      content_draft: nl2Br(get(record, 'content_draft', '')),
    }));
  };

  handleUpdateRecord = (record: RecordForm) => {
    const { patient } = this.props;
    this.props.dispatch(recordActions.updateRecord({
      ...record,
      content: nl2Br(get(record, 'content', '')),
    }, patient.id));
  };

  handleDeleteRecord = (recordId: number) => {
    const patientId = get(this.props, ['patient', 'id']);
    this.props.dispatch(recordActions.deleteRecord(recordId, patientId));
  };

  handleSelectFile = (recordId: number, file: Object, type: string) => {
    this.props.dispatch(recordActions.uploadRecordAttachment(recordId, file, type));
  };

  handleSaveHistoryNote = (content: string) => {
    const { dispatch, patient } = this.props;
    const patientId = get(patient, 'id');

    if (patientId) {
      dispatch(patientActions.updatePatientHistoryNote(patientId, { content }));
    }
  };

  handleUpdateVisibility = (id: number, level: number) => {
    const { dispatch } = this.props;
    dispatch(recordActions.updateRecordVisibility(id, level));
  };

  handleSetEditMode = (id: number, edit: boolean) => {
    const { dispatch, patient } = this.props;
    dispatch(recordActions.setEditMode(id, edit, patient.id));
  };

  handleSelectPatientProfileImage = (file: Object, fileType: string) => {
    const patientId = get(this.props, ['patient', 'id']);
    this.props.dispatch(patientActions.uploadPatientProfileImage(patientId, file, fileType));
  };

  handleDeletePatientProfileImage = () => {
    const patientId = get(this.props, ['patient', 'id']);
    const imageId = get(this.props, ['patient', 'profileImage', 'id']);
    this.props.dispatch(patientActions.deletePatientProfileImage(patientId, imageId));
  };

  handleAddMemedIdMemedId = (recordId:number, memedPrescriptionId: number, prescription: Object) => {
    const { dispatch, doctorId } = this.props;
    dispatch(recordActions.addMemedIds(recordId, memedPrescriptionId, doctorId, prescription));
  };
  handleDeleteMemedId = (recordId:number, memedPrescriptionId: number) => {
    this.props.dispatch(recordActions.removeMemedId(recordId, memedPrescriptionId));
  };

  handleRestoreRecord = (recordId: number, cb: any) => {
    const { dispatch } = this.props;
    dispatch(restoreRecord(recordId, cb));
  };
}

const resampleEditModeList = (editModeList: any) => {
  const newList = {};

  forEach(editModeList, (item, id) => {
    newList[id] = item.editing;
  });
  return newList;
};

const mapStateToProps = (state, ownProps: Object) => ({
  records: state.context.records,
  drugsPrescriptions: state.context.drugsPrescriptions,
  examsPrescriptions: state.context.examsPrescriptions,
  documents: state.context.documents,
  customForms: state.context.customForms,
  customFormTemplates: state.context.customFormTemplates,
  patient: get(state.context, ['patients', 'data', ownProps.match.params.id]),
  getFullPatientError: get(state.context, ['patients', 'getFullPatientError'], false),
  patientId: ownProps.match.params.id,
  futureRecords: state.context.futureRecords,
  attachments: state.context.attachments,
  tagTemplates: state.context.tagTemplates,
  modals: state.context.modals,
  isPatientLoading: get(state, ['context', 'patients', 'isLoading'], false),
  isPatientPrinting: get(state, ['context', 'records', 'isPrinting'], false),
  isRecordCreating: get(state, ['context', 'records', 'isCreating'], false),
  isRecordLoading: get(state, ['context', 'records', 'isLoading'], false),
  recordsEditModesList: resampleEditModeList(get(state, ['context', 'records', 'editModeList'], {})),
  recordDeletingIds: get(state, ['context', 'records', 'deletingIds'], {}),
  updatingIds: get(state, ['context', 'records', 'updatingIds'], {}),
  historyNoteLoading: get(state, ['context', 'patients', 'isSavingHistoryNote'], false),
  planInclude: mapPlanIncludes(state),
  permissions: mapPermissionTo(state),
  isMemedLoaded: get(state, ['context', 'clinics', 'isMemedLoaded'], false),
  memedToken: get(state, ['context', 'user', 'advancedData', 'memedToken'], ''),
  doctorId: get(state, ['user', 'advancedData', 'doctorId']),
  userEmail: get(state, ['context', 'user', 'email']),
  clinicId: get(state, ['context', 'clinics', 'activeClinic', 'id']),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({ dispatch });

export default connect<any, any, any, any, any, any>(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation('global')(PatientTimeline));
