// @flow
import { isNaN } from 'lodash';
import { isPresent as _isPresent, isPtBR } from '../../helpers';

type Tester = {
  run: Function;
  name: string;
};

/**
 * When a tester return false the test is failed
 */
const allowEmpty = (value: any, params: Object) => ((params || {}).allowEmpty && !_isPresent(value));

export const isPresent:Tester = {
  run: (value: any) => _isPresent(value),
  name: 'isPresent',
};

export const isNumber:Tester = {
  run: (value: any, params: Object) => !isNaN(parseFloat(value)) || allowEmpty(value, params),
  name: 'isNumber',
};

export const minNumber:Tester = {
  run: (value: any, params: Object) => {
    if (!isNaN(parseFloat(value)) || allowEmpty(value, params)) {
      const val = parseFloat(value);
      return allowEmpty(value, params) ? true : val >= params.min;
    }
    return false;
  },
  name: 'minNumber',
};

export const maxNumber:Tester = {
  run: (value: any, params: Object) => {
    if (!isNaN(parseFloat(value))) {
      const val = parseFloat(value);
      return allowEmpty(value, params) ? true : val <= params.max;
    }
    return false;
  },
  name: 'maxNumber',
};


export const inMinChars:Tester = {
  run: (value: any, params: Object) => (isPresent.run(value) && String(value).length >= params.min) || allowEmpty(value, params),
  name: 'inMinChars',
};

export const inMaxChars:Tester = {
  run: (value: any, params: Object) => (isPresent.run(value) && String(value).length <= params.max) || allowEmpty(value, params),
  name: 'inMaxChars',
};

export const inBetweenChars:Tester = {
  run: (value: any, params: Object) => (inMinChars.run(value, params.min) && inMaxChars.run(value, params.max)) || allowEmpty(value, params),
  name: 'inBetweenChars',
};
export const isNumberBetween:Tester = {
  run: (value: any, params: Object) => (minNumber.run(value, params) && maxNumber.run(value, params)) || allowEmpty(value, params),
  name: 'isNumberBetween',
};

export const isValidBrPhone:Tester = {
  run: (value:any, params: Object) => {
    if (!isPtBR() && (isPresent.run(value) || (params || {}).allowEmpty)) return true;

    const phone = String(value).replace(/[^\d]+/g, '').trim();
    return (phone.length >= 10 || allowEmpty(value, params)) || !params.isSubmit;
  },
  name: 'isValidBrPhone',
};

export const isValidHoursAndMinutes:Tester = {
  run: (value: any, params: Object) => {
    if (!isPresent.run(value) && params.allowEmpty) return true;
    return (/^(2[0-3]|[01]?[0-9]):([0-5]?[0-9])$/).test(String(value));
  },
  name: 'isValidHoursAndMinutes',
};

/**
 * Validate brazilian cpf
 * @param {*} value
 */
export const isValidCpf:Tester = {
  name: 'isValidCpf',
  run: (value: any, params: Object) => {
    if (!isPtBR() && (isPresent.run(value) || (params || {}).allowEmpty)) return true;

    const cpf = String(value).replace(/[^\d]+/g, '');
    if (cpf.length < 11 && !params.isSubmit) {
      return true;
    }

    if (cpf === '' && params.allowEmpty) return true;
    if (cpf === '' && !params.allowEmpt) return false;

    if (
      (cpf.length !== 11) || cpf === '00000000000' || cpf === '11111111111' || cpf === '22222222222' ||
      cpf === '33333333333' || cpf === '44444444444' || cpf === '55555555555' || cpf === '66666666666' ||
      cpf === '77777777777' || cpf === '88888888888' || cpf === '99999999999'
    ) {
      return false;
    }

    let add = 0;
    for (let i = 0; i < 9; i++) {
      add += parseInt(cpf.charAt(i), 10) * (10 - i);
    }
    let rev = 11 - (add % 11);

    if (rev === 10 || rev === 11) {
      rev = 0;
    }
    if (rev !== parseInt(cpf.charAt(9), 10)) {
      return false;
    }

    let add2 = 0;
    for (let i = 0; i < 10; i++) {
      add2 += parseInt(cpf.charAt(i), 10) * (11 - i);
    }
    let rev2 = 11 - (add2 % 11);
    if (rev2 === 10 || rev2 === 11) {
      rev2 = 0;
    }
    if (rev2 !== parseInt(cpf.charAt(10), 10)) {
      return false;
    }
    return true;
  },
};


export const isValidEmail:Tester = {
  run: (value: any, params: Object) => {
    const re = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/g;
    return allowEmpty(value, params) ? true : re.test(value);
  },
  name: 'isValidEmail',
};

export const isValidBrDate:Tester = {
  run: (value: any, params: Object) => {
    const re = /(\d\d)\/(\d\d)\/(\d\d\d\d)/g;

    const passed = re.test(value);
    return allowEmpty(value, params) ? true : passed;
  },
  name: 'isValidBrDate',
};

