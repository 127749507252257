import { applyMiddleware, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import Reactotron, { trackGlobalErrors } from 'reactotron-react-js';
import { reactotronRedux } from 'reactotron-redux';
import { routerMiddleware } from 'connected-react-router';
import makeRootReducer from './modules/combined';
import lsDumpReload from './middleware/lsDumpReload';
import callAPI from './middleware/callAPI';
import showPDF from './middleware/showPDF';
import { validateStoreSize } from './middleware/validateStoreSize';
import { cordovaPurchaseMiddleware } from './modules/context/cordovaPurchase';
import lsSubscribeDump from './middleware/subscribeDump';
import type { State } from '../types';
import { Log } from '../helpers';

export default (initialState: State, history: Object) => {
  const reactotron = Reactotron.use(reactotronRedux()).use(trackGlobalErrors());
  window.tron = {
    log: reactotron.log,
    error: reactotron.error,
  };

  // ======================================================
  // Middleware Configuration
  // ======================================================
  const middleware = [
    thunk,
    validateStoreSize,
    showPDF,
    cordovaPurchaseMiddleware,
    callAPI,
    lsDumpReload,
    routerMiddleware(history),
  ];
  if (window.isCordovaIos) {
    middleware.push(Log.reduxMiddleware);
  }

  // ======================================================
  // Store Enhancers
  // ======================================================
  const enhancers = [reactotron.createEnhancer()];

  let composeEnhancers = compose;

  if (window.ENV === 'development' || window.ENV === 'staging') {
    const composeWithDevToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;
    if (typeof composeWithDevToolsExtension === 'function') {
      composeEnhancers = composeWithDevToolsExtension;
    }
  }

  // ======================================================
  // Store Instantiation and HMR Setup
  // ======================================================
  const newStore = createStore(
    makeRootReducer({}, history),
    initialState,
    composeEnhancers(
      applyMiddleware(...middleware),
      ...enhancers,
    ),
  );

  newStore.subscribe(lsSubscribeDump(newStore));
  // newStore.subscribe(() => {
  //   // eslint-disable-next-line
  //   console.warn(newStore.getState());
  // });

  const store = {
    ...newStore,
    asyncReducers: {},
  };

  window.initReactotron = (host: string) => {
    reactotron.configure({
      name: `Doutore v${window.APP_VERSION}`,
      host,
    }).connect();
  };

  reactotron.setReduxStore(newStore);

  reactotron.onCustomCommand('reload_window', () => window.location.reload(true));

  return store;
};
