// @flow
import React from 'react';
import { connect } from 'react-redux';
import { SubscriptionPayment as SubscriptionPaymentView } from '@views';
import type { SubscriptionState } from '@types';
import { get } from 'lodash';
import { signPlan, getSubscriptionUserInfo } from '@context/subscription';

type Props = {
  subscription: SubscriptionState,
  dispatch: Function,
}
class SubscriptionPayment extends React.Component<Props> {
  componentWillMount() {
    const { dispatch } = this.props;
    dispatch(getSubscriptionUserInfo());
  }
  render() {
    const { subscription } = this.props;

    return (
      <SubscriptionPaymentView
        onSave={this.handleSave}
        subscription={subscription}
      />
    );
  }
  handleSave = (paymentType: string, creditCardData: Object, cardType: string) => {
    const { dispatch, subscription } = this.props;
    if (!get(subscription, 'isSaving', false)) {
      dispatch(signPlan(paymentType, creditCardData, true, cardType));
    }
  };
}

const mapStateToProps = (state) => ({
  subscription: get(state, ['context', 'subscription'], {}),
});

export default connect<any, any, any, any, any, any>(
  mapStateToProps,
)(SubscriptionPayment);
