// @flow
import React from 'react';
import { AppMobileHeader, ReportsMenu, AppMobileFooter } from '../../blocks';
import './ReportsList.scss';

export default class ReportsList extends React.Component<any> {
  render() {
    return (
      <React.Fragment>
        <AppMobileHeader title="Relatórios" hideBack />
        <div id="reportsListPage" className="appContent">
          <div className="mainSection noPadding">
            <ReportsMenu />
          </div>
        </div>
        <AppMobileFooter />
      </React.Fragment>
    );
  }
}
