// @flow
import Immutable from 'seamless-immutable';
import { get } from 'lodash';

import type {
  AppVersionState, Action, Dispatch,
  AppVersionNotificationData,
} from '../../types';

// ------------------------------------
// Constants
// ------------------------------------
export const OPEN_APP_VERSION_CONTROL_NOTIFICATION = 'appVersion.OPEN_APP_VERSION_CONTROL_NOTIFICATION';
export const CLOSE_APP_VERSION_CONTROL_NOTIFICATION = 'appVersion.CLOSE_APP_VERSION_CONTROL_NOTIFICATION';

// ------------------------------------
// Actions
// ------------------------------------
export const openAppVersionControlNotification = (data: AppVersionNotificationData) => (
  (dispatch: Dispatch) => {
    dispatch({ type: OPEN_APP_VERSION_CONTROL_NOTIFICATION, data });
  }
);

export const closeAppVersionControlNotification = () => (
  (dispatch: Dispatch) => {
    dispatch({ type: CLOSE_APP_VERSION_CONTROL_NOTIFICATION });
  }
);

export const appVersionActions = {
  openAppVersionControlNotification,
  closeAppVersionControlNotification,
};

// ------------------------------------
// Reducer
// ------------------------------------
export default function appVersionReducer (state: AppVersionState = Immutable({}), action: Action) {
  switch (action.type) {
    case OPEN_APP_VERSION_CONTROL_NOTIFICATION:
      return state
        .setIn(['notification', 'active'], true)
        .setIn(['notification', 'data'], action.data);

    case CLOSE_APP_VERSION_CONTROL_NOTIFICATION:
      return state
        .setIn(['notification', 'active'], false)
        .setIn(['notification', 'data'], null);

    default:
      const currentAppVersion = get(state, 'version');
      const newAppVersion = (!currentAppVersion && window.APP_VERSION) ? window.APP_VERSION : currentAppVersion;
      return state
        .setIn(['version'], newAppVersion);
  }
}
