// @flow
import React from 'react';
import { Icon } from '../';
import './Tag.scss';

type Props = {
  label: string,
  isLoading?: boolean,
  onRemove?: Function,
};

export default class Tag extends React.Component<Props> {
  render() {
    const { label, isLoading } = this.props;

    return (
      <div className="tag">
        <span>{label}</span>
        <Icon
          iconName="close"
          className="icon"
          fill={isLoading ? 'rgba(255, 255, 255, 0.0)' : 'rgba(255, 255, 255, 0.7)'}
          size={14}
          onClick={this.onRemove.bind(this, label)}
        />
      </div>
    );
  }

  onRemove = (tagLabel: string) => {
    if (this.props.onRemove && !this.props.isLoading) {
      this.props.onRemove(tagLabel);
    }
  }
}
