// @flow
import { find, uniqBy, filter, get, forEach, map, toString } from 'lodash';
import { durations as baseDurations } from '@constants';
import { sort, numberCompare, formatMinutesToHoursMin, alphabeticCompare } from '@helpers/helpers';
import type { Patient, Doctors, Services, Service } from '@types';

export const getDurations = (selectedDuration: string, defaultAppointmentDuration: string) => {
  let durations;
  const durationExits = find(baseDurations, (d) => d.value === (selectedDuration || defaultAppointmentDuration));

  if (!durationExits) {
    const dur = selectedDuration || defaultAppointmentDuration;
    durations = [...baseDurations, { text: formatMinutesToHoursMin(dur), value: dur }];
  } else {
    durations = baseDurations;
  }

  durations = uniqBy(durations, 'value');
  durations = sort(durations, numberCompare('value'));

  return durations;
};


export const filterForceHidden = (items: any, isNew: boolean) => {
  const newItems = filter<any>(items, item => get(item, ['customRendererData', 'forceHidden'], false) !== true);

  if (isNew) {
    return filter<any>(newItems, item => get(item, ['customRendererData', 'action']) !== 'status');
  }

  return newItems;
};

export const sortPatients = (list: any) => sort(list, alphabeticCompare('name'));

export const mapSuggestedPatients = (suggestedPatients: Array<Patient>, doctors: Doctors) => {
  let suggested;
  if (suggestedPatients && suggestedPatients.length) {
    suggested = [
      {
        type: 'heading',
        text: 'Pacientes Sugeridos',
      },
    ];
    forEach(sortPatients(suggestedPatients), (patient) => {
      const doctor = get(doctors, toString(patient.doctorId), {});
      const data = {
        customRendererData: {
          patientType: 'suggested',
          patient,
          doctor,
        },
      };
      suggested.push(data);
    });
  }
  return suggested;
};


export const mapSearchablePatients = (searchablePatients: Array<Patient>, doctors: Doctors, isListComplete: boolean, heading: boolean) => {
  let searchable;
  if (searchablePatients && searchablePatients.length) {
    searchable = heading ? [
      {
        type: 'heading',
      },
    ] : [];
    forEach(sortPatients(searchablePatients), (patient) => {
      const doctor = get(doctors, toString(patient.doctorId), {});
      const data = {
        customRendererData: {
          patientType: 'searchable',
          patient,
          doctor,
        },
      };
      searchable.push(data);
    });

    const patientsListNote = {
      className: 'patientsListNote',
      type: 'patientsListNote',
      text: '* Essa lista de pacientes está incompleta - para encontrar outros pacientes, digite o nome em "Buscar Pacientes"',
    };

    if (!isListComplete) {
      searchable.push(patientsListNote);
    }
  }

  return searchable;
};


export const mapServicesIds = (ids: Array<number>, servicesTemplates: Services) => map<any, any>(ids, id => get(servicesTemplates, ['data', toString(id)]));

export const createServicesHeading = (text: string) => ({
  type: 'heading',
  text,
  customRendererData: {
    type: 'heading',
  },
});

export const serviceToListItem = (service: Service) => ({
  type: !service.healthInsurance ? 'particular' : 'healthInsurance',
  className: 'listItem chk',
  key: service.id,
  customRendererData: {
    service,
  },
});
