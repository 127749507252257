// @flow
import { get, isFunction } from 'lodash';
import { createCachedAction } from '@helpers/helpers';
import { Api } from '@helpers';
import { openToast } from '@context/toasts';
import { router } from '../../../routes/router';
import type
{
  Action,
  Dispatch,
  ContextState,
  Measure,
  MeasureData,
} from '@types';

// ------------------------------------
// Constants
// ------------------------------------
const INDICATORS_LOADING = 'indicators.INDICATORS_LOADING';
const INDICATORS_LOADED = 'indicators.INDICATORS_LOADED';
const INDICATORS_LOADING_FAILED = 'indicators.INDICATORS_LOADING_FAILED';

const MEASURE_CREATE_IN_PROGRESS = 'indicators.MEASURE_CREATE_IN_PROGRESS';
const MEASURE_CREATE_SUCCESS = 'indicators.MEASURE_CREATE_SUCCESS';
const MEASURE_CREATE_FAILED = 'indicators.MEASURE_CREATE_FAILED';

const MEASURE_UPDATE_IN_PROGRESS = 'indicators.MEASURE_UPDATE_IN_PROGRESS';
const MEASURE_UPDATE_SUCCESS = 'indicators.MEASURE_UPDATE_SUCCESS';
const MEASURE_UPDATE_FAILED = 'indicators.MEASURE_UPDATE_FAILED';

const MEASURE_DELETE_IN_PROGRESS = 'indicators.MEASURE_DELETE_IN_PROGRESS';
const MEASURE_DELETE_SUCCESS = 'indicators.MEASURE_DELETE_SUCCESS';
const MEASURE_DELETE_FAILED = 'indicators.MEASURE_DELETE_FAILED';

const MEASURES_LOADING = 'indicators.MEASURES_LOADING';
const MEASURES_LOADED = 'indicators.MEASURES_LOADED';
const MEASURES_LOADING_FAILED = 'indicators.MEASURES_LOADING_FAILED';

// ------------------------------------
// Actions
// ------------------------------------
export const listIndicators = () => (dispatch: Dispatch) => {
  dispatch(createCachedAction(
    INDICATORS_LOADING, INDICATORS_LOADED, INDICATORS_LOADING_FAILED,
    12,
    () => Api.get(router.getApiRoute('listIndicators', null)),
    ['context', 'indicators', 'data'],
    (response: any) => ({ indicators: response.indicators }),
  )(true, {}));
};

export const createMeasure = (payload: MeasureData, callback: Function = () => {}) => (dispatch: Dispatch) => {
  dispatch({ type: MEASURE_CREATE_IN_PROGRESS });

  const { patientId, measure } = payload;

  Api.post(router.getApiRoute('createMeasure', { patientId }), {
    data: { measure },
    success: (response) => {
      dispatch({
        type: MEASURE_CREATE_SUCCESS,
        data: {
          patientId,
          measure: response.data,
        },
      });
      if (isFunction(callback)) {
        callback(true);
      }
    },
    error: (error) => {
      dispatch({ type: MEASURE_CREATE_FAILED, error: { message: error.message } });

      dispatch(openToast({
        type: 'error',
        text: error.message,
        close: true,
        timeout: 3000,
      }));

      if (isFunction(callback)) {
        callback(false);
      }
    },
  });
};

export const updateMeasure = (payload: MeasureData, callback: Function = () => {}) => (dispatch: Dispatch) => {
  dispatch({ type: MEASURE_UPDATE_IN_PROGRESS });

  const { patientId, measure } = payload;

  Api.patch(router.getApiRoute('updateMeasure', { patientId, measureId: measure.id }), {
    data: { measure },
    success: (response) => {
      dispatch({
        type: MEASURE_UPDATE_SUCCESS,
        data: {
          patientId,
          measure: response,
        },
      });
      if (isFunction(callback)) {
        callback(true);
      }
    },
    error: (error) => {
      dispatch({ type: MEASURE_UPDATE_FAILED, error: { message: error.message } });

      dispatch(openToast({
        type: 'error',
        text: error.message,
        close: true,
        timeout: 3000,
      }));
      if (isFunction(callback)) {
        callback(false);
      }
    },
  });
};

export const deleteMeasure = (measureId: number, patientId: number, callback: Function = () => {}) => (dispatch: Dispatch) => {
  dispatch({ type: MEASURE_DELETE_IN_PROGRESS });

  Api.destroy(router.getApiRoute('deleteMeasure', { measureId, patientId }), {
    success: () => {
      dispatch({ type: MEASURE_DELETE_SUCCESS, data: { measureId, patientId } });
      if (isFunction(callback)) {
        callback(true);
      }
    },
    error: (error) => {
      dispatch({ type: MEASURE_DELETE_FAILED, error: { message: error.message } });

      dispatch(openToast({
        type: 'error',
        text: error.message,
        close: true,
        timeout: 3000,
      }));

      if (isFunction(callback)) {
        callback(false);
      }
    },
  });
};

export const listPatientMeasures = (patientId: number) => (dispatch: Dispatch) => {
  dispatch({ type: MEASURES_LOADING });
  Api.get(router.getApiRoute('listMeasures', { patientId }), {
    success: (response) => {
      dispatch({
        type: MEASURES_LOADED,
        data: {
          patientId,
          measures: response.measures,
        },
      });
    },
    error: (error) => {
      dispatch({ type: MEASURES_LOADING_FAILED, error: { message: error.message } });

      dispatch(openToast({
        type: 'error',
        text: error.message,
        close: true,
        timeout: 3000,
      }));
    },
  });
};


export const indicatorsActions = {
  listIndicators,
  createMeasure,
  updateMeasure,
  deleteMeasure,
  listPatientMeasures,
};

const buildPatientsMeasures = (state: ContextState, patientId: number, measures: Measure[] | Measure) => {
  const patientsMeasures = { ...get(state, ['indicators', 'patientsMeasures'], {}) };

  const currentPatientMeasures = patientsMeasures[patientId] || [];

  patientsMeasures[patientId] = Array.isArray(measures)
    ? measures
    : [...currentPatientMeasures, measures];


  return patientsMeasures;
};

export default function indicatorsReducer (state: ContextState, action: Action): ContextState {
  switch (action.type) {
    case INDICATORS_LOADING:
      return state.setIn(['indicators', 'isLoadingIndicators'], true);
    case INDICATORS_LOADED:
      return state.setIn(['indicators', 'isLoadingIndicators'], false)
        .setIn(['indicators', 'data'], action.indicators);
    case INDICATORS_LOADING_FAILED:
      return state.setIn(['indicators', 'isLoadingIndicators'], false)
        .setIn(['indicators', 'errors'], action.error);
    case MEASURE_CREATE_IN_PROGRESS:
      return state
        .setIn(['indicators', 'isCreating'], true)
        .setIn(['indicators', 'errors'], null);
    case MEASURE_CREATE_SUCCESS:
      return state
        .setIn(['indicators', 'isCreating'], false)
        .setIn(['indicators', 'errors'], null)
        .setIn(['indicators', 'patientsMeasures'], buildPatientsMeasures(state, action.data.patientId, action.data.measure));
    case MEASURE_CREATE_FAILED:
      return state
        .setIn(['indicators', 'isCreating'], false)
        .setIn(['indicators', 'errors'], [action.error.message]);
    case MEASURE_UPDATE_IN_PROGRESS:
      return state
        .setIn(['indicators', 'isUpdating'], true)
        .setIn(['indicators', 'errors'], null);
    case MEASURE_UPDATE_SUCCESS:
      const updatePatientMeasures = { ...get(state, ['indicators', 'patientsMeasures'], {}) };

      const updatedMeasures = (updatePatientMeasures[action.data.patientId] || []).map(mapMeasure => {
        if (mapMeasure.id === action.data.measure.id) return action.data.measure;

        return mapMeasure;
      });

      return state
        .setIn(['indicators', 'isUpdating'], false)
        .setIn(['indicators', 'errors'], null)
        .setIn(['indicators', 'patientsMeasures'], buildPatientsMeasures(state, action.data.patientId, updatedMeasures));
    case MEASURE_UPDATE_FAILED:
      return state
        .setIn(['indicators', 'isUpdating'], false)
        .setIn(['indicators', 'errors'], [action.error.message]);
    case MEASURE_DELETE_IN_PROGRESS:
      return state
        .setIn(['indicators', 'isDeleting'], true)
        .setIn(['indicators', 'errors'], null);
    case MEASURE_DELETE_SUCCESS:
      const { patientId, measureId } = action.data;

      const patientsMeasures = { ...get(state, ['indicators', 'patientsMeasures'], {}) };

      const measures = (patientsMeasures[patientId] || []).filter(measure => measure.id !== measureId);

      return state
        .setIn(['indicators', 'isDeleting'], false)
        .setIn(['indicators', 'errors'], null)
        .setIn(['indicators', 'patientsMeasures'], { ...patientsMeasures, [patientId]: measures });
    case MEASURE_DELETE_FAILED:
      return state
        .setIn(['indicators', 'isDeleting'], false)
        .setIn(['indicators', 'errors'], [action.error.message]);
    case MEASURES_LOADING:
      return state.setIn(['indicators', 'isLoadingMeasures'], true);
    case MEASURES_LOADED:
      return state.setIn(['indicators', 'isLoadingMeasures'], false)
        .setIn(['indicators', 'patientsMeasures'], buildPatientsMeasures(state, action.data.patientId, action.data.measures));
    case MEASURES_LOADING_FAILED:
      return state.setIn(['indicators', 'isLoadingMeasures'], false)
        .setIn(['indicators', 'errors'], action.error);
    default:
      return state;
  }
}
