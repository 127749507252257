// @flow
import Immutable from 'seamless-immutable';
import { get, omit, toNumber } from 'lodash';
import { downloadOrShare, isReportDownloadByEmail, reportError } from '@helpers/helpers';
import { Api } from '../../../helpers';
import PaymentService from '../../../services/PaymentService';
import { router } from '../../../routes/router';
import ServiceService from '../../../services/ServiceService';
import { getPatientFinancial, setPatientFinancialLoading } from './patients';
import { CLOSE_ACTIVE_TOAST, OPEN_TOAST } from './toasts';
import type
{
  Action,
  Dispatch,
  ContextState,
  PaymentInput,
  PaymentCreateInput,
} from '../../../types';

// ------------------------------------
// Constants
// ------------------------------------
const PAYMENTS_LOADING_IN_PROGRESS = 'payments.PAYMENTS_LOADING_IN_PROGRESS';
const PAYMENTS_LOADED = 'payments.PAYMENTS_LOADED';
const PAYMENTS_LOADING_FAILED = 'payments.PAYMENTS_LOADING_FAILED';

const PAYMENT_CREATE_IN_PROGRESS = 'payments.PAYMENT_CREATE_IN_PROGRESS';
const PAYMENT_CREATE_SUCCESS = 'payments.PAYMENT_CREATE_SUCCESS';
const PAYMENT_CREATE_FAILED = 'payments.PAYMENT_CREATE_FAILED';

const PAYMENT_UPDATE_IN_PROGRESS = 'payments.PAYMENT_UPDATE_IN_PROGRESS';
const PAYMENT_UPDATE_SUCCESS = 'payments.PAYMENT_UPDATE_SUCCESS';
const PAYMENT_UPDATE_FAILED = 'payments.PAYMENT_UPDATE_FAILED';

const PAYMENT_DELETE_IN_PROGRESS = 'payments.PAYMENT_DELETE_IN_PROGRESS';
const PAYMENT_DELETE_SUCCESS = 'payments.PAYMENT_DELETE_SUCCESS';
const PAYMENT_DELETE_FAILED = 'payments.PAYMENT_DELETE_FAILED';

const PAYMENT_REPORT_IN_PROGRESS = 'payments.PAYMENT_REPORT_IN_PROGRESS';
const PAYMENT_REPORT_SUCCESS = 'payments.PAYMENT_REPORT_SUCCESS';
const PAYMENT_REPORT_FAILED = 'payments.PAYMENT_REPORT_FAILED';

// ------------------------------------
// Actions
// ------------------------------------

export const getPayments = (startDate: string, endDate: string, doctorId?: number, notaFiscal?: boolean = false) => (
  (dispatch: Dispatch) => {
    dispatch({ type: PAYMENTS_LOADING_IN_PROGRESS });
    const route = router.getApiRoute('getPayments', null);

    const queryFilters = {
      'filter[doctorId]': doctorId || null,
      'filter[startDate]': startDate,
      'filter[endDate]': endDate,
      'filter[notaFiscal]': notaFiscal || false,
    };

    Api.get(route, {
      query: queryFilters,
      success: (response) => {
        dispatch({
          type: PAYMENTS_LOADED,
          payments: response.payments,
          summary: response.summary,
        });
      },
      error: (error) => {
        dispatch({ type: PAYMENTS_LOADING_FAILED, error: { message: error.message } });
        dispatch(reportError(error));
      },
    });
  }
);

export const getPaymentsReport = (startDate: string, endDate: string, doctorId?: number, notaFiscal?: boolean = false) => (
  (dispatch: Dispatch, getState: Function) => {
    dispatch({ type: OPEN_TOAST, data: { type: 'inProgress', text: 'Preparando relatório' } });
    dispatch({ type: PAYMENT_REPORT_IN_PROGRESS });
    const route = router.getApiRoute('getPaymentsReport', null);

    const userEmail = get(getState(), ['context', 'user', 'email']);

    const queryFilters = {
      'filter[doctorId]': doctorId || null,
      'filter[startDate]': startDate,
      'filter[endDate]': endDate,
      'filter[notaFiscal]': notaFiscal || false,
    };

    const isDownload = !isReportDownloadByEmail(getState);

    if (isDownload) {
      downloadOrShare({
        url: route.path,
        state: getState(),
        mime: 'application/vnd.ms-excel',
        fileName: 'report_payments_{t}.xlsx',
        dispatch,
        params: queryFilters,
      }).then(({ shared }) => {
        if (!shared) {
          dispatch({
            type: CLOSE_ACTIVE_TOAST,
          });
        }
        dispatch({
          type: PAYMENT_REPORT_SUCCESS,
        });
      }).catch((error) => {
        dispatch({
          type: OPEN_TOAST,
          data: {
            type: 'error', text: 'Ocorreu um erro ao processar seu relatório.', close: true, timeout: 10000,
          },
        });
        dispatch({ type: PAYMENT_REPORT_FAILED, error: { message: error.message } });
      });
    } else {
      Api.get(route, {
        query: queryFilters,
        success: () => {
          dispatch({
            type: OPEN_TOAST,
            data: {
              type: 'success', text: `Relatório enviado para o seu email ${userEmail}`, close: true, timeout: 10000,
            },
          });
          dispatch({
            type: PAYMENT_REPORT_SUCCESS,
          });
        },
        error: (error) => {
          dispatch({
            type: OPEN_TOAST,
            data: {
              type: 'error', text: 'Ocorreu um erro ao processar seu relatório.', close: true, timeout: 10000,
            },
          });
          dispatch({ type: PAYMENT_REPORT_FAILED, error: { message: error.message } });
          dispatch(reportError(error));
        },
      });
    }
  }
);

export const getPatientPayments = (patientId: number) => (
  (dispatch: Dispatch) => {
    dispatch({ type: PAYMENTS_LOADING_IN_PROGRESS, patientId });
    const route = router.getApiRoute('getPatientPayments', { patientId });
    Api.get(route, {
      success: (response) => {
        dispatch({
          type: PAYMENTS_LOADED,
          payments: response.payments,
          summary: response.summary,
          patientId,
        });
      },
      error: (error) => {
        dispatch({ type: PAYMENTS_LOADING_FAILED, error: { message: error.message } });
        dispatch(reportError(error));
      },
    });
  }
);

export const createPayment = (patientId: number, data: PaymentCreateInput, cb?: Function) => (
  (dispatch: Dispatch) => {
    dispatch({ type: PAYMENT_CREATE_IN_PROGRESS });
    dispatch(setPatientFinancialLoading(patientId));
    const route = router.getApiRoute('createPayment', { patientId });
    Api.post(route, {
      data,
      success: (response) => {
        dispatch({
          type: PAYMENT_CREATE_SUCCESS,
          payment: get(response, ['data', 'payment']),
          createdServices: get(response, ['data', 'createdServices']),
        });

        if (cb) {
          cb(true);
        }

        dispatch(getPatientFinancial(patientId));
      },
      error: (error) => {
        dispatch({ type: PAYMENT_CREATE_FAILED, error: { message: error.message } });
        dispatch(reportError(error));
        if (cb) {
          cb(false);
        }
      },
    });
  }
);

export const updatePayment = (patientId: number, id: number, data: PaymentInput) => (
  (dispatch: Dispatch) => {
    dispatch({ type: PAYMENT_UPDATE_IN_PROGRESS, updateId: id });
    dispatch(setPatientFinancialLoading(patientId));
    const route = router.getApiRoute('updatePayment', { patientId, id });
    Api.patch(route, {
      data,
      success: (response) => {
        dispatch({
          type: PAYMENT_UPDATE_SUCCESS,
          payment: get(response, 'payment'),
        });
        dispatch(getPatientFinancial(patientId));
      },
      error: (error) => {
        dispatch({ type: PAYMENT_UPDATE_FAILED, error: { message: error.message }, updateId: id });
        dispatch(reportError(error));
      },
    });
  }
);

export const deletePayment = (patientId: number, id: number) => (
  (dispatch: Dispatch) => {
    dispatch({ type: PAYMENT_DELETE_IN_PROGRESS });
    dispatch(setPatientFinancialLoading(patientId));
    const route = router.getApiRoute('deletePayment', { patientId, id });
    Api.destroy(route, {
      success: () => {
        dispatch({
          type: PAYMENT_DELETE_SUCCESS,
          deletedId: id,
        });
        dispatch(getPatientFinancial(patientId));
      },
      error: (error) => {
        dispatch({ type: PAYMENT_DELETE_FAILED, error: { message: error.message } });
        dispatch(reportError(error));
      },
    });
  }
);

export const paymentActions = {
  getPayments,
  getPatientPayments,
  createPayment,
  updatePayment,
  deletePayment,
  getPaymentsReport,
};

// ------------------------------------
// Reducer
// ------------------------------------
export default function paymentsReducer (state: ContextState, action: Action): ContextState {
  switch (action.type) {
    case PAYMENTS_LOADING_IN_PROGRESS:
      return state
        .setIn(['payments', 'isLoading'], true)
        .setIn(['payments', 'isLoadingReport'], false)
        .setIn(['payments', 'currentPatientId'], toNumber(action.patientId))
        .setIn(['payments', 'updatingIds'], {})
        .setIn(['payments', 'errors'], null);

    case PAYMENTS_LOADED:
      const normalized = PaymentService.normalizePayments(action.payments);
      return state
        .setIn(['payments', 'isLoading'], false)
        .setIn(['payments', 'errors'], null)
        .setIn(['payments', 'currentPatientId'], toNumber(action.patientId))
        .setIn(['payments', 'data'], normalized.payments)
        .setIn(['payments', 'summary'], get(action, 'summary', []));

    case PAYMENTS_LOADING_FAILED:
      return state
        .setIn(['payments', 'isLoading'], false)
        .setIn(['payments', 'errors'], [action.error.message]);

    case PAYMENT_CREATE_IN_PROGRESS:
      return state
        .setIn(['payments', 'isSaving'], true)
        .setIn(['payments', 'errors'], null);

    case PAYMENT_CREATE_SUCCESS:
      const normalizedServices = ServiceService.normalizeServices(action.createdServices);
      return state
        .setIn(['payments', 'isSaving'], false)
        .setIn(['payments', 'errors'], null)
        .setIn(['services', 'data'], {
          ...get(state, ['services', 'data'], {}),
          ...normalizedServices.services,
        })
        .setIn(['payments', 'data', action.payment.id], action.payment);

    case PAYMENT_CREATE_FAILED:
      return state
        .setIn(['payments', 'isSaving'], false)
        .setIn(['payments', 'errors'], [action.error.message]);

    case PAYMENT_UPDATE_IN_PROGRESS:
      return state
        .setIn(['payments', 'errors'], null)
        .setIn(['payments', 'updatingIds', String(action.updateId)], true);

    case PAYMENT_UPDATE_SUCCESS:
      return state
        .setIn(['payments', 'isSaving'], false)
        .setIn(['payments', 'errors'], null)
        .setIn(['payments', 'data', String(action.payment.id)], action.payment)
        .setIn(['payments', 'updatingIds', String(action.payment.id)], false);

    case PAYMENT_UPDATE_FAILED:
      return state
        .setIn(['payments', 'isSaving'], false)
        .setIn(['payments', 'errors'], [action.error.message])
        .setIn(['payments', 'updatingIds', String(action.updateId)], false);

    case PAYMENT_DELETE_IN_PROGRESS:
      return state
        .setIn(['payments', 'isSaving'], true)
        .setIn(['payments', 'errors'], null);

    case PAYMENT_DELETE_SUCCESS:
      const paymnents = get(state, ['payments', 'data'], Immutable({}));
      const newPaymnents = omit(paymnents, [String(action.deletedId)]);
      return state
        .setIn(['payments', 'isSaving'], false)
        .setIn(['payments', 'data'], newPaymnents);

    case PAYMENT_DELETE_FAILED:
      return state
        .setIn(['payments', 'isSaving'], false)
        .setIn(['payments', 'errors'], [action.error.message]);


    case PAYMENT_REPORT_IN_PROGRESS:
      return state
        .setIn(['payments', 'isLoadingReport'], true);

    case PAYMENT_REPORT_SUCCESS:
      return state
        .setIn(['payments', 'isLoadingReport'], false);

    case PAYMENT_REPORT_FAILED:
      return state
        .setIn(['payments', 'isLoadingReport'], false);

    default:
      return state;
  }
}
