// @flow
import React from 'react';
import { get, map } from 'lodash';
import { connect } from 'react-redux';
import { ServiceTemplatesList as ServiceTemplatesListView } from '@views';
import { serviceTemplateActions } from '@context/serviceTemplates';
import { withRouter } from 'react-router-dom';
import type { Dispatch, User, DoctorsState, ServicesState, BundledPermissions, PermissionTo } from '@types';
import { sort, alphabeticCompare } from '@helpers/helpers';
import { mapBundledPermission, mapPermissionTo } from '@helpers/connect';
import { logout } from '@context/user';

type Props = {
  dispatch: Function,
  doctorId: number,
  doctors: DoctorsState,
  user: User,
  services: ServicesState,
  permissions: BundledPermissions,
  permissionTo: PermissionTo,
  history: Object;
};

class ServiceTemplatesList extends React.Component<Props> {
  componentWillMount() {
    const { doctorId, dispatch } = this.props;
    dispatch(serviceTemplateActions.getServiceTemplates(doctorId));
  }

  componentWillReceiveProps(next: Props) {
    const { doctorId, dispatch } = next;
    if (this.props.doctorId !== doctorId) {
      dispatch(serviceTemplateActions.getServiceTemplates(doctorId));
    }
  }

  render() {
    const {
      services, doctors, user,
      doctorId, permissions,
      permissionTo,
    } = this.props;

    const doctorsList = [];
    if (doctors && doctors.data) {
      map(doctors.data, (doctor: any) => {
        const item = {
          text: doctor.nameWithTitle,
          name: doctor.name,
          urlTo: `/ajustes/servicos/${doctor.id}`,
          id: doctor.id,
          ...doctor,
        };
        doctorsList.push(item);
      });
    }

    const servicesItems = [];
    const healthInsuranceItems = [];
    if (services && services.data) {
      map(services.data, (service) => {
        const item = {
          linkTo: `/ajustes/servicos/${doctorId}/${service.id}`,
          customRendererData: {
            name: service.name,
            price: service.price,
          },
        };
        if (service.healthInsurance) {
          healthInsuranceItems.push(item);
        } else {
          servicesItems.push(item);
        }
      });
    }

    return (
      <ServiceTemplatesListView
        doctorId={doctorId}
        user={user}
        doctorsData={doctors}
        doctorsList={sort(doctorsList, alphabeticCompare(['name']))}
        servicesItems={servicesItems}
        healthInsuranceItems={healthInsuranceItems}
        servicesLoading={get(services, 'isLoading')}
        isFeatureEnabled={permissions.planInclude.financial}
        onLogout={this.handleLogout}
        permissions={this.props.permissions}
        reportsSelectDoctor={permissionTo.reportsSelectDoctor}
        editAllServicesTemplates={permissionTo.editAllServicesTemplates}
        pushPath={this.handlePushPath}
      />
    );
  }
  handleLogout = () => this.props.dispatch(logout());
  handlePushPath = (url: string) => {
    this.props.history.push(url);
  };
}

const mapStateToProps = (state, ownProps) => ({
  services: state.context.serviceTemplates,
  doctors: state.context.doctors,
  user: state.context.user,
  doctorId: get(ownProps, ['match', 'params', 'doctorId']),
  permissions: mapBundledPermission(state),
  permissionTo: mapPermissionTo(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({ dispatch });

export default withRouter(connect<any, any, any, any, any, any>(
  mapStateToProps,
  mapDispatchToProps,
)(ServiceTemplatesList));
