// @flow
import React from 'react';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import { Button } from '@elements';
import { withTranslation } from 'react-i18next';
import { createNsTranslator } from '@helpers/helpers';
import './AppHome.scss';

type Props = {
  t: Function
}
class AppHome extends React.Component<Props> {
  render() {
    const _ = createNsTranslator('onboarding', this.props.t);

    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false,
    };

    return (
      <div id='appOnboarding' className="onboardingLayout">
        {/* <span className="circle" /> */}
        <div className="onboardingLayoutHeader">
          <span className="logo" />
          <hr />
        </div>
        <div className="onboardingLayoutBody">

          <Slider {...settings}>
            <div>
              {/* <div className="art onboarding1" /> */}
              <img src="images/onboarding1.png" alt="onboarding1" className="artImg" />
              <h1>{_('label/onboarding/text1')}</h1>
            </div>
            <div>
              {/* <div className="art onboarding2" /> */}
              <img src="images/onboarding2.png" alt="onboarding2" className="artImg" />
              <h1>{_('label/onboarding/text2')}</h1>
            </div>
            <div>
              {/* <div className="art onboarding3" /> */}
              <img src="images/onboarding3.png" alt="onboarding3" className="artImg" />
              <h1>{_('label/onboarding/text3')}</h1>
            </div>
            <div>
              {/* <div className="art onboarding4" /> */}
              <img src="images/onboarding4.png" alt="onboarding4" className="artImg" />
              <h1>{_('label/onboarding/text4')}</h1>
            </div>
          </Slider>

        </div>

        <div className="onboardingLayoutFooter">
          <Button className="primary" text="Criar Conta" linkTo="/cadastro" />
          <p>{_('label/have_account')} <Link to='/entrar'>{_('action/login_now')}</Link></p>
        </div>
      </div>
    );
  }
}

export default withTranslation()(AppHome);
