// @flow
import React from 'react';
import { NewPassword as NewPasswordView } from '../components/views';

export default class NewPassword extends React.Component<void> {
  render() {
    return (
      <NewPasswordView />
    );
  }
}
