// @flow
import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { isFunction } from 'lodash';
import { checkIfIsMobile } from '@helpers/helpers';

type Props = {
  desktop: Function,
  mobile: Function,
};

export type State = {
  window: {
    width: number,
    height: number,
  },
  orientation: string,
};

const ExternalPlatformSwitch = ({ desktop, mobile }: Props) => {
  const [uiState, setUiState] = useState({
    window: {
      width: window.innerWidth,
      height: window.innerHeight,
    },
    orientation: window.innerHeight > window.innerWidth ? 'landscape' : 'portrait',
  });

  const updateDeviceOrientation = useCallback(() => {
    setUiState({
      window: {
        width: window.innerWidth,
        height: window.innerHeight,
      },
      orientation: (window.orientation === 90 || window.orientation === -90) ? 'landscape' : 'portrait',
    });
  }, []);

  useEffect(() => {
    window.addEventListener('resize', updateDeviceOrientation);
    window.addEventListener('orientationchange', updateDeviceOrientation);
    return () => {
      window.removeEventListener('resize', updateDeviceOrientation);
      window.removeEventListener('orientationchange', updateDeviceOrientation);
    };
  });

  const ui: any = useMemo(() => ({
    window: uiState.window,
    orientation: uiState.orientation,
  }), [uiState.orientation, uiState.window]);

  const isMobile = useMemo(() => checkIfIsMobile(ui) || window.isCordovaApp, [ui]);

  const mobileContent: Function = useMemo(() => (isFunction(mobile) ? mobile : () => mobile), [mobile]);
  const dektopContent: Function = useMemo(() => (isFunction(desktop) ? desktop : () => desktop), [desktop]);

  return (
    <React.Fragment>
      {isMobile ? mobileContent(window.isCordovaApp) : dektopContent(window.isCordovaApp)}
    </React.Fragment>
  );
};

// $FlowFixMe
export default React.memo(ExternalPlatformSwitch);
