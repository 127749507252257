import React from 'react';
import moment from 'moment';
import { isEqual } from 'lodash';
import { CustomDayPicker } from '@elements';


type Props = {
    initialDate: string | Object,
    onChange: Function,
    onClose: Function,
};

type State = {
    selectedDate: Object,
};

const fmtDt = (date: any) => moment(date).format('YYYY-MM-DD[T]HH:mm:ss.SSSZZ');

export default class DayPickerSelect extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    let selectedDate = moment(this.props.initialDate);
    selectedDate = selectedDate.isValid() ? selectedDate : moment();

    this.state = {
      selectedDate: fmtDt(selectedDate),
    };
  }

  shouldComponentUpdate(nextProps: Props, nextState: State) {
    return !isEqual(this.props, nextProps) || !isEqual(this.state, nextState);
  }

  render() {
    return (
      <CustomDayPicker
        advancedMode
        withOverlay
        showButtons
        isOpen
        selectedDate={moment(this.state.selectedDate)}
        onSelectDay={this.handleSelect}
        onCancel={this.props.onClose}
        onAccept={this.handleAccept}
      />
    );
  }

  handleSelect = (date: string) => {
    this.setState({ selectedDate: fmtDt(date) });
  };

  handleAccept = () => {
    this.props.onChange(fmtDt(this.state.selectedDate));
    this.props.onClose();
  }
}
