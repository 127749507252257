// @flow
import React from 'react';
import { get } from 'lodash';
import { connect } from 'react-redux';
import { parseUrlParams } from '@helpers/helpers';
import { Login as LoginView } from '../components/views';
import { router } from '../routes/router';
import { actions as authActions } from '../redux/modules/auth';
import type { AuthState, Dispatch } from '../types';

type Props = {
  auth: AuthState,
  dispatch: Function,
};

class Login extends React.Component<Props> {
  componentWillMount() {
    if (this.props.auth.authenticated) {
      this.props.dispatch(router.pushPath('root'));
    } else {
      this.props.dispatch(authActions.setLoadingState(false));
    }
  }

  render() {
    const { auth } = this.props;
    const defaultEmail = get(parseUrlParams(window.location.href), 'email');

    return (
      <LoginView
        authErrors={get(auth, ['errors'])}
        inProgress={auth.inProgress}
        onSubmit={this.handleLogin}
        defaultEmail={defaultEmail}
      />
    );
  }

  handleLogin = (email: string, password: string) => {
    const data = {
      email,
      password,
      user: {
        email,
        password,
      },
    };
    this.props.dispatch(authActions.login(data, true));
  };
}

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({ dispatch });

export default connect<any, any, any, any, any, any>(
  mapStateToProps,
  mapDispatchToProps,
)(Login);
