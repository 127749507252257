// @flow
import Immutable from 'seamless-immutable';
import { get, omit, toNumber } from 'lodash';
import { downloadOrShare, isReportDownloadByEmail, reportError } from '@helpers/helpers';
import { Api } from '../../../helpers';
import ServiceService from '../../../services/ServiceService';
import { router } from '../../../routes/router';
import { CLOSE_ACTIVE_TOAST, OPEN_TOAST } from './toasts';
import { getPatientFinancial, setPatientFinancialLoading } from './patients';
import type
{
  Action,
  Dispatch,
  ContextState,
  ServiceInput,
  ServiceCreateInput,
} from '../../../types';

// ------------------------------------
// Constants
// ------------------------------------
const SERVICES_LOADING_IN_PROGRESS = 'services.SERVICES_LOADING_IN_PROGRESS';
const SERVICES_LOADED = 'services.SERVICES_LOADED';
const SERVICES_LOADING_FAILED = 'services.SERVICES_LOADING_FAILED';

const SERVICES_CREATE_PROGRESS = 'services.SERVICES_CREATE_PROGRESS';
const SERVICES_CREATED = 'services.SERVICES_CREATED';
const SERVICES_CREATE_FAILED = 'services.SERVICES_CREATE_FAILED';

const SERVICES_UPDATING_PROGRESS = 'services.SERVICES_UPDATING_PROGRESS';
const SERVICES_UPDATED = 'services.SERVICES_UPDATED';
const SERVICES_UPDATE_FAILED = 'services.SERVICES_UPDATE_FAILED';

const SERVICES_DELETING_PROGRESS = 'services.SERVICES_DELETING_PROGRESS';
const SERVICES_DELETED = 'services.SERVICES_DELETED';
const SERVICES_DELETE_FAILED = 'services.SERVICES_DELETE_FAILED';

const SERVICES_REPORT_IN_PROGRESS = 'services.SERVICES_REPORT_IN_PROGRESS';
const SERVICES_REPORT_SUCCESS = 'services.SERVICES_REPORT_SUCCESS';
const SERVICES_REPORT_FAILED = 'services.SERVICES_REPORT_FAILED';


const SERVICES_CREATE_WITH_TEMPLATE_PROGRESS = 'services.SERVICES_CREATE_WITH_TEMPLATE_PROGRESS';
const SERVICES_CREATE_WITH_TEMPLATE_SUCCESS = 'services.SERVICES_CREATE_WITH_TEMPLATE_SUCCESS';
const SERVICES_CREATE_WITH_TEMPLATE_FAILED = 'services.SERVICES_CREATE_WITH_TEMPLATE_FAILED';
// ------------------------------------
// Actions
// -----------------------------------


export const createTemplateWithService = (patientId: number, doctorId:number, serviceTemplate: Object) => (dispatch:Dispatch) => {
  dispatch({ type: SERVICES_CREATE_WITH_TEMPLATE_PROGRESS });
  Api.post(router.getApiRoute('createTemplateWithService', { doctorId, patientId }), {
    data: {
      serviceTemplate,
    },
    success: (response) => {
      dispatch({
        type: SERVICES_CREATE_WITH_TEMPLATE_SUCCESS,
        serviceTemplate: get(response, ['data', 'serviceTemplate']),
        service: get(response, ['data', 'service']),
      });
      dispatch(getPatientFinancial(patientId));
    },
    error: (error) => {
      dispatch({ type: SERVICES_CREATE_WITH_TEMPLATE_FAILED, error: { message: error.message } });
      dispatch(reportError(error));
    },
  });
};


export const getServicesReport = (startDate: string, endDate: string, doctorId?: number, status?: string) => (
  (dispatch: Dispatch, getState: Function) => {
    dispatch({ type: OPEN_TOAST, data: { type: 'inProgress', text: 'Preparando relatório' } });
    dispatch({ type: SERVICES_REPORT_IN_PROGRESS });
    const route = router.getApiRoute('getServicesReport', { doctorId });

    const userEmail = get(getState(), ['context', 'user', 'email']);

    const isDownload = !isReportDownloadByEmail(getState);


    const queryFilters = {
      'filter[doctorId]': doctorId || null,
      'filter[startDate]': startDate,
      'filter[endDate]': endDate,
      'filter[status]': status || null,
    };

    if (isDownload) {
      downloadOrShare({
        url: route.path,
        state: getState(),
        mime: 'application/vnd.ms-excel',
        fileName: 'report_services_{t}.xlsx',
        dispatch,
        params: queryFilters,
      }).then(({ shared }) => {
        if (!shared) {
          dispatch({
            type: CLOSE_ACTIVE_TOAST,
          });
        }
        dispatch({
          type: SERVICES_REPORT_SUCCESS,
        });
      }).catch((error) => {
        dispatch({
          type: OPEN_TOAST,
          data: {
            type: 'error', text: 'Ocorreu um erro ao processar seu relatório.', close: true, timeout: 10000,
          },
        });
        dispatch({ type: SERVICES_REPORT_FAILED, error: { message: error.message } });
      });
    } else {
      Api.get(route, {
        query: queryFilters,
        success: () => {
          dispatch({
            type: OPEN_TOAST,
            data: {
              type: 'success', text: `Relatório enviado para o seu email ${userEmail}`, close: true, timeout: 10000,
            },
          });
          dispatch({
            type: SERVICES_REPORT_SUCCESS,
          });
        },
        error: (error) => {
          dispatch({
            type: OPEN_TOAST,
            data: {
              type: 'error', text: 'Ocorreu um erro ao processar seu relatório.', close: true, timeout: 10000,
            },
          });
          dispatch({ type: SERVICES_REPORT_FAILED, error: { message: error.message } });
        },
      });
    }
  }
);

export const createServices = (patientId: number, services: Array<ServiceCreateInput>) => (dispatch: Dispatch) => {
  dispatch({ type: SERVICES_CREATE_PROGRESS });
  dispatch(setPatientFinancialLoading(patientId));
  Api.post(router.getApiRoute('createService', { patientId }), {
    data: { services },
    success: (response) => {
      dispatch({
        type: SERVICES_CREATED,
        services: get(response, ['data', 'services']),
      });
      dispatch(getPatientFinancial(patientId));
    },
    error: (error) => {
      dispatch({ type: SERVICES_CREATE_FAILED, error: { message: error.message } });
      dispatch(reportError(error));
    },
  });
};

export const updateService = (patientId: number, id: number, service: ServiceInput) => (dispatch:Dispatch) => {
  dispatch({ type: SERVICES_UPDATING_PROGRESS, updateId: id });
  dispatch(setPatientFinancialLoading(patientId));
  Api.patch(router.getApiRoute('updateService', { id, patientId }), {
    data: { service },
    success: (response) => {
      dispatch({
        type: SERVICES_UPDATED,
        service: get(response, ['service']),
      });
      dispatch(getPatientFinancial(patientId));
    },
    error: (error) => {
      dispatch({ type: SERVICES_UPDATE_FAILED, error: { message: error.message }, updateId: id });
      dispatch(reportError(error));
    },
  });
};

export const deleteService = (patientId: number, id: number) => (dispatch: Dispatch) => {
  dispatch({ type: SERVICES_DELETING_PROGRESS });
  dispatch(setPatientFinancialLoading(patientId));
  Api.destroy(router.getApiRoute('deleteService', { id, patientId }), {
    success: () => {
      dispatch({ type: SERVICES_DELETED, deletedId: id });
      dispatch(getPatientFinancial(patientId));
    },
    error: (error) => {
      dispatch({ type: SERVICES_DELETE_FAILED, error: { message: error.message } });
      dispatch(reportError(error));
    },
  });
};

export const getServices = (startDate: string, endDate: string, doctorId?: number, status?: string) => (
  (dispatch: Dispatch) => {
    dispatch({ type: SERVICES_LOADING_IN_PROGRESS });
    const route = router.getApiRoute('getServices', { doctorId });

    const queryFilters = {
      'filter[doctorId]': doctorId || null,
      'filter[startDate]': startDate,
      'filter[endDate]': endDate,
      'filter[status]': status || null,
    };

    Api.get(route, {
      query: queryFilters,
      success: (response) => {
        dispatch({
          type: SERVICES_LOADED,
          services: response.services,
          summary: response.summary,
          maxLimit: response.maxLimit,
          maxLimitReached: response.maxLimitReached,
        });
      },
      error: (error) => {
        dispatch({ type: SERVICES_LOADING_FAILED, error: { message: error.message } });
        dispatch(reportError(error));
      },
    });
  }
);

export const getPatientServices = (patientId: number) => (
  (dispatch: Dispatch) => {
    dispatch({ type: SERVICES_LOADING_IN_PROGRESS, patientId });
    const route = router.getApiRoute('getPatientServices', { patientId });
    Api.get(route, {
      success: (response) => {
        dispatch({
          type: SERVICES_LOADED,
          services: response.services,
          summary: response.summary,
          patientId,
        });
      },
      error: (error) => {
        dispatch({ type: SERVICES_LOADING_FAILED, error: { message: error.message } });
        dispatch(reportError(error));
      },
    });
  }
);

export const serviceActions = {
  getServices,
  getPatientServices,
  updateService,
  deleteService,
  createServices,
  getServicesReport,
  createTemplateWithService,
};

// ------------------------------------
// Reducer
// ------------------------------------
export default function servicesReducer (state: ContextState, action: Action): ContextState {
  let normalizedServices;

  switch (action.type) {
    case SERVICES_LOADING_IN_PROGRESS:
      return state
        .setIn(['services', 'isLoading'], true)
        .setIn(['services', 'currentPatientId'], toNumber(action.patientId))
        .setIn(['services', 'isLoadingReport'], false)
        .setIn(['services', 'updatingIds'], {})
        .setIn(['services', 'errors'], null)
        .setIn(['services', 'maxLimit'], null)
        .setIn(['services', 'maxLimitReached'], false);

    case SERVICES_LOADED:
      const normalized = ServiceService.normalizeServices(action.services);
      const maxLimit = get(action, 'maxLimit', null);
      const maxLimitReached = get(action, 'maxLimitReached', false);
      return state
        .setIn(['services', 'isLoading'], false)
        .setIn(['services', 'errors'], null)
        .setIn(['services', 'currentPatientId'], toNumber(action.patientId))
        .setIn(['services', 'data'], normalized.services)
        .setIn(['services', 'summary'], action.summary)
        .setIn(['services', 'maxLimit'], maxLimit)
        .setIn(['services', 'maxLimitReached'], maxLimitReached);

    case SERVICES_LOADING_FAILED:
      return state
        .setIn(['services', 'isLoading'], false)
        .setIn(['services', 'errors'], [action.error.message]);

    case SERVICES_CREATE_PROGRESS:
      return state
        .setIn(['services', 'isSaving'], true)
        .setIn(['services', 'errors'], null);

    case SERVICES_CREATED:
      normalizedServices = ServiceService.normalizeServices(action.services);
      return state
        .setIn(['services', 'isSaving'], false)
        .setIn(['services', 'errors'], null)
        .setIn(['services', 'data'], {
          ...get(state, ['services', 'data'], {}),
          ...normalizedServices.services,
        });

    case SERVICES_CREATE_FAILED:
      return state
        .setIn(['services', 'isSaving'], false)
        .setIn(['services', 'errors'], [action.error.message]);

    case SERVICES_UPDATING_PROGRESS:
      return state
        .setIn(['services', 'errors'], null)
        .setIn(['services', 'updatingIds', String(action.updateId)], true);

    case SERVICES_UPDATED:
      return state
        .setIn(['services', 'isSaving'], false)
        .setIn(['services', 'errors'], null)
        .setIn(['services', 'data', String(action.service.id)], action.service)
        .setIn(['services', 'updatingIds', String(action.service.id)], false);

    case SERVICES_UPDATE_FAILED:
      return state
        .setIn(['services', 'isSaving'], false)
        .setIn(['services', 'errors'], [action.error.message])
        .setIn(['services', 'updatingIds', String(action.updateId)], false);

    case SERVICES_DELETING_PROGRESS:
      return state
        .setIn(['services', 'isSaving'], true)
        .setIn(['services', 'errors'], null);

    case SERVICES_DELETED:
      const servicesList = get(state, ['services', 'data'], Immutable({}));
      const newservicesList = omit(servicesList, [String(action.deletedId)]);
      return state
        .setIn(['services', 'isSaving'], false)
        .setIn(['services', 'data'], newservicesList);

    case SERVICES_DELETE_FAILED:
      return state
        .setIn(['services', 'isSaving'], false)
        .setIn(['services', 'errors'], [action.error.message]);


    case SERVICES_REPORT_IN_PROGRESS:
      return state
        .setIn(['services', 'isLoadingReport'], true);

    case SERVICES_REPORT_SUCCESS:
      return state
        .setIn(['services', 'isLoadingReport'], false);

    case SERVICES_REPORT_FAILED:
      return state
        .setIn(['services', 'isLoadingReport'], false);


    case SERVICES_CREATE_WITH_TEMPLATE_PROGRESS:
      return state
        .setIn(['services', 'isSaving'], true)
        .setIn(['services', 'errors'], null)
        .setIn(['serviceTemplates', 'isSaving'], true);

    case SERVICES_CREATE_WITH_TEMPLATE_SUCCESS:
      return state
        .setIn(['services', 'isSaving'], false)
        .setIn(['services', 'errors'], null)
        .setIn(['serviceTemplates', 'isSaving'], false)
        .setIn(['services', 'data', get(action, ['service', 'id'])], action.service)
        .setIn(['serviceTemplates', 'data', get(action, ['serviceTemplate', 'id'])], action.serviceTemplate);

    case SERVICES_CREATE_WITH_TEMPLATE_FAILED:

      return state
        .setIn(['services', 'isSaving'], false)
        .setIn(['serviceTemplates', 'isSaving'], false)
        .setIn(['services', 'errors'], [action.error.message]);


    default:
      return state;
  }
}
