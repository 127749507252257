/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { get } from 'lodash';
import moment from 'moment';
import { userActions, actionTypes as userActionTypes } from '@context/user';
import { clinicActions, actionTypes as clinicsActionTypes, fetchPrescriptionLayout } from '@context/clinics';
import { customFormTemplateActions } from '@context/customFormTemplates';
import { documentsTemplateActions } from '@context/documentsTemplates';
import { tagTemplateActions } from '@context/tagTemplates';
import { drugsPrescriptionsTemplateActions } from '@context/drugsPrescriptionsTemplates';
import { examsPrescriptionsTemplateActions } from '@context/examsPrescriptionsTemplates';

const expireInHours = {
  user: 24,
  clinics: 24,
  tagTemplates: 24 * 7,
  prescriptionConfig: 24 * 7,
  documentsTemplates: 24 * 7,
  customFormTemplates: 24 * 7,
  drugsPrescriptionsTemplates: 24 * 7,
  examsPrescriptionsTemplates: 24 * 7,
};

const oneHour = 1000 * 60 * 60;

type Props = {
  children: React.Node,
}

let timer;

const RefreshData = ({ children }: Props) => {
  const dispatch = useDispatch();

  const lastUserFetch = useSelector(state => state.context.user?.lastFetch);
  const lastClinicsFetch = useSelector(state => state.context.clinics?.lastFetch);
  const lastTagTemplatesFetch = useSelector(state => state.context.tagTemplates?.lastFetch);
  const lastPrescriptionConfigFetch = useSelector(state => state.context.clinics?.prescritionPrintConfig?.lastFetch);
  const lastDocumentsTemplatesFetch = useSelector(state => state.context.documentsTemplates?.lastFetch);
  const lastCustomFormTemplatesFetch = useSelector(state => state.context.customFormTemplates?.lastFetch);
  const lastDrugsPrescriptionsTemplatesFetch = useSelector(state => state.context.drugsPrescriptionsTemplates?.lastFetch);
  const lastExamsPrescriptionsTemplatesFetch = useSelector(state => state.context.examsPrescriptionsTemplates?.lastFetch);

  const doctor = useSelector(state => get(state, ['context', 'doctors', 'data', get(state, ['context', 'user', 'advancedData', 'doctorId'])], null));

  const currentTime = moment();

  const shouldRefresh = (lastFetch: string | null = null, refreshTime: number) => {
    if (!lastFetch) return true;

    const hoursSinceLastRefresh = moment.duration(
      currentTime.diff(moment(lastFetch)),
    ).asHours();

    return hoursSinceLastRefresh >= refreshTime;
  };

  const refreshUser = () => {
    const getUserAction = userActions.getUser();
    getUserAction.call().then((getUserResponse) => {
      getUserAction.parseResponse(getUserResponse);

      if (getUserResponse.data.user) {
        dispatch({ type: userActionTypes.USER_LOADED, user: getUserResponse.data.user });
      }
    });
  };

  const refreshClinics = () => {
    const getClinicsAction = clinicActions.getClinics();
    getClinicsAction.call().then((getClinicsResponse) => {
      const userClinicsData = getClinicsAction.parseResponse(getClinicsResponse.data);
      dispatch({ type: clinicsActionTypes.CLINICS_LOADED, ...userClinicsData });
    });
  };

  const refreshTagTemplates = () => {
    dispatch(tagTemplateActions.listTagTemplate(true));
  };

  const refreshCustomFormTemplates = () => {
    dispatch(customFormTemplateActions.getCustomFormTemplates(true));
  };

  const refreshDocumentsTemplates = () => {
    dispatch(documentsTemplateActions.getDocumentsTemplates(true));
  };

  const refreshDrugsPrescriptionsTemplates = () => {
    dispatch(drugsPrescriptionsTemplateActions.getDrugsPrescriptionsTemplates(true));
  };

  const refreshLastExamsPrescriptionsTemplates = () => {
    dispatch(examsPrescriptionsTemplateActions.getExamsPrescriptionsTemplates());
  };

  const refreshPrescriptionConfig = () => {
    if (!doctor) return;

    dispatch(fetchPrescriptionLayout(doctor.id));
  };

  const dataToRefresh = [
    { lastFetch: lastUserFetch, fetchFunction: refreshUser, expiresIn: expireInHours.user },
    { lastFetch: lastClinicsFetch, fetchFunction: refreshClinics, expiresIn: expireInHours.clinics },
    { lastFetch: lastTagTemplatesFetch, fetchFunction: refreshTagTemplates, expiresIn: expireInHours.tagTemplates },
    { lastFetch: lastPrescriptionConfigFetch, fetchFunction: refreshPrescriptionConfig, expiresIn: expireInHours.documentsTemplates },
    { lastFetch: lastDocumentsTemplatesFetch, fetchFunction: refreshDocumentsTemplates, expiresIn: expireInHours.prescriptionConfig },
    { lastFetch: lastCustomFormTemplatesFetch, fetchFunction: refreshCustomFormTemplates, expiresIn: expireInHours.customFormTemplates },
    { lastFetch: lastDrugsPrescriptionsTemplatesFetch, fetchFunction: refreshDrugsPrescriptionsTemplates, expiresIn: expireInHours.drugsPrescriptionsTemplates },
    { lastFetch: lastExamsPrescriptionsTemplatesFetch, fetchFunction: refreshLastExamsPrescriptionsTemplates, expiresIn: expireInHours.examsPrescriptionsTemplates },
  ];

  const refreshData = () => {
    dataToRefresh.forEach(({ lastFetch, expiresIn, fetchFunction }) => {
      if (shouldRefresh(lastFetch, expiresIn)) fetchFunction();
    });
  };

  useEffect(() => {
    if (timer) clearInterval(timer);

    timer = setInterval(refreshData, oneHour);

    return () => {
      clearInterval(timer);
    };
  }, dataToRefresh);

  return (
    <>
      {children}
    </>
  );
};

export default RefreshData;
