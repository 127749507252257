// @flow
import React from 'react';
import { get } from 'lodash';
import { SettingsMenu, PlatformSwitch, AppMobileHeader, AppMobileFooter } from '@blocks';
import { Loading } from '@elements';
import type { User, SubscriptionState, BundledPermissions } from '@types';
import { withTranslation } from 'react-i18next';
import { createNsTranslator, utcFormat, formatAnonymousCreditCard, isPresent, nl2br } from '@helpers/helpers';
import SubscriptionInfoIOS from './Partials/SubscriptionInfoIOS';
import PaymentDetails from './Partials/PaymentDetails';
import SubscriptionWarnings from './Partials/SubscriptionWarnings';
import './SubscriptionInfo.scss';

type Props = {
  user: User,
  accessToken: string,
  subscription: SubscriptionState,
  onLogout: Function,
  t: Function,
  permissions: BundledPermissions,
  onGetNewBoleto: Function,
  onPayClick: Function,
  onResubscribeClick: Function,
  isRequestingNewBoleto: boolean,
};

class SubscriptionInfo extends React.Component<Props> {
  render() {
    const {
      accessToken, user, subscription, onLogout, onGetNewBoleto, isRequestingNewBoleto, onPayClick, onResubscribeClick,
    } = this.props;

    const paymentInfoError = get(subscription, ['subscriptionData', 'paymentInfoError']);
    const _ = createNsTranslator('my_plan', this.props.t);

    const isLoading = get(subscription, 'isLoading', false);
    const nextPaymentDate = get(subscription, ['subscriptionData', 'nextPaymentDate'], '');
    const subscribedAt = get(subscription, ['subscriptionData', 'subscribedAt'], '');

    const sbInfo = {
      paymentPeriod: get(subscription, ['subscriptionData', 'paymentPeriod'], 'month'),
      dealSummary: get(subscription, ['subscriptionData', 'dealSummary'], null),
      price: get(subscription, ['subscriptionData', 'subscriptionPrice'], 0),
      paymentMethod: get(subscription, ['subscriptionData', 'paymentMethod'], false),
      planName: get(subscription, ['subscriptionData', 'planName'], ''),
      nextPaymentDate: utcFormat(nextPaymentDate, 'DD/MM/YYYY', ' '),
      nextPaymentDay: utcFormat(nextPaymentDate, 'YYYY-MM-DD', ' '),
      subscribedAtDate: utcFormat(subscribedAt, 'DD/MM/YYYY', ' '),
      creditCard: formatAnonymousCreditCard(get(subscription, ['subscriptionData', 'creditCard', 'maskedCardNumber'])),
      cardType: get(subscription, ['subscriptionData', 'creditCard', 'cardType']),
      smsActivated: get(subscription, ['subscriptionData', 'smsActivated']),
      smsesLeftOver: get(subscription, ['subscriptionData', 'smsesLeftOver']),
      warning: get(subscription, ['subscriptionData', 'warning']),
      boleto: {
        barcode: get(subscription, ['subscriptionData', 'boleto', 'barcode']),
        dueDate: utcFormat(get(subscription, ['subscriptionData', 'boleto', 'dueDate']), 'DD/MM/YYYY'),
        price: get(subscription, ['subscriptionData', 'boleto', 'price']),
        url: get(subscription, ['subscriptionData', 'boleto', 'url']),
      },
      paymentInfoError,
    };

    if (!isPresent(sbInfo.dealSummary)) {
      sbInfo.dealSummary = null;
    } else {
      sbInfo.dealSummary = nl2br(sbInfo.dealSummary);
    }


    const renderContent = () => {
      if (sbInfo.paymentMethod === 'apple') {
        return (
          <SubscriptionInfoIOS />
        );
      }
      return (
        <React.Fragment>
          <SubscriptionWarnings onResubscribeClick={onResubscribeClick} sbInfo={sbInfo} onGetNewBoleto={onGetNewBoleto} isRequestingNewBoleto={isRequestingNewBoleto} onPayClick={onPayClick} />
          <PaymentDetails accessToken={accessToken} user={user} sbInfo={sbInfo} isLoading={isLoading} />
        </React.Fragment>
      );
    };


    return (
      <PlatformSwitch
        inject
        desktop={() => (
          <div id="myPlansPage" className="appContent settingsPage">
            <aside className="sidebar">
              <SettingsMenu user={user} onLogout={onLogout} permissions={this.props.permissions} />
            </aside>
            {isLoading && <section className="mainSection"><Loading /></section>}
            {!isLoading && (
              <section className="mainSection">
                <div className="centerContent">
                  {renderContent()}
                </div>
              </section>
            )}
          </div>
        )}
        mobile={() => (
          <React.Fragment>
            <AppMobileHeader title={_('label/title')} />
            <div id="myPlansPage" className="appContent settingsPage">
              {isLoading && <section className="mainSection"><Loading /></section>}
              {!isLoading && (
              <section className="mainSection">
                <div className="centerContent">
                  {renderContent()}
                </div>
              </section>
              )}
            </div>
            <AppMobileFooter />
          </React.Fragment>
        )}
      />


    );
  }
}


export default withTranslation()(SubscriptionInfo);
