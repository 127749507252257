export default {
  'alert/delete/record': '¿Estás seguro de que deseas eliminar este registro?',
  'alert/delete/document': '¿Estás seguro de que deseas eliminar este documento?',
  'alert/delete/prescription': '¿Estás seguro de que deseas eliminar esta prescripción?',
  'alert/delete/exam': '¿Estás seguro de que deseas eliminar esta solicitud de exámenes?',
  'alert/delete/appointment': '¿Estás seguro de que deseas eliminar esta cita?',
  'alert/hide/appointment': '¿También deseas eliminar esta cita de la agenda?',
  'alert/confirm_delete_file': '¿Estás seguro de que deseas eliminar este archivo?',
  'alert/confirm_delete_account': '¿Estás seguro de que deseas eliminar permanentemente esta cuenta?',
  'alert/confirm_delete_measure': '¿Estás seguro de que deseas eliminar este indicador?',
  'alert/confirm_quit_form': 'Hay cambios sin guardar. ¿Quieres salir sin guardar?',
  'alert/confirm_delete_form': '¿Estás seguro de que deseas eliminar este formulario?',
  'alert/secretaries/form/confirm': '¿Realmente deseas eliminar a esta secretaria?',
  'alert/delete_patient_question': '¿Realmente deseas eliminar a este paciente?',
  'alert/wrong_clinic': 'Ya no perteneces a esta clínica, actualiza para cargar la clínica correcta.',
  'info/file_not_allowed': 'Archivo no permitido.',
  'info/finalize_record_tip/print': 'Finaliza tu cita para habilitar la impresión.',
  'info/finalize_record_tip/change_date': 'Finaliza tu cita para cambiar la fecha.',
  'info/finalize_record_tip/change_date/with_appointment': 'No puedes cambiar la fecha de las citas creadas en la agenda.',
  'info/patient_needs_email': 'Este paciente no tiene un correo electrónico registrado.',
  'error/invalid_recurrency_days': 'Selecciona al menos un día.',
  'info/finalize_record_tip/blocked_edit': 'Finaliza un registro en curso para poder editar otro.',
  'error/mobile/file_not_downloadable': 'Este tipo solo puede ser compartido por computadora.',
  'error/direct_share/email/not_present': 'Este paciente no tiene un correo electrónico registrado, ¿deseas registrar uno?',
  'error/direct_share/mobile_phone/not_present': 'Este paciente no tiene un número de teléfono móvil registrado, ¿deseas registrar uno?',
  'error/direct_share/birthdate/not_present': 'Este paciente no tiene una fecha de nacimiento registrada, ¿deseas registrarla?',
  'button/direct_share/confirm_button/ok': 'Ir al registro',
  'button/direct_share/confirm_button/cancel': 'Hacerlo después',
};
