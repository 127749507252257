// @flow
import React from 'react';
import { connect } from 'react-redux';
import { get } from 'lodash';
import { AppVersionControl } from '../components/blocks';
import { appVersionActions } from '../redux/modules/appVersion';
import { appControlActions } from '../redux/modules/appControl';

import type { AppVersionNotificationData, Dispatch } from '../types';

type Props = {
  appVersionNotification: AppVersionNotificationData,
  dispatch: Function,
};

class AppVersionControlContainer extends React.Component<Props> {
  render() {
    const { appVersionNotification } = this.props;
    const notificationData = get(appVersionNotification, 'data', {});

    return (
      <AppVersionControl
        notificationData={notificationData}
        onClose={this.handleCloseNotification}
        onUpdateDoutore={this.handleUpdateDoutore}
      />
    );
  }

  handleCloseNotification = () => {
    this.props.dispatch(appVersionActions.closeAppVersionControlNotification());
  };

  handleUpdateDoutore = () => {
    const platform = get(this.props, ['appVersionNotification', 'notification', 'data', 'platform']);

    let updateURL;
    if (window.isCordovaApp) {
      updateURL = (platform === 'ios') ? 'https://itunes.apple.com/br/app/doutore/id898141356?mt=8' : 'https://play.google.com/store/apps/details?id=io.gonative.ios.dyeqa';
      window.location.href = updateURL;
    }
    this.props.dispatch(appControlActions.updateApp());
  };
}

const mapStateToProps = (state) => ({
  appVersionNotification: get(state.appVersion, 'notification', {}),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({ dispatch });

export default connect<any, any, any, any, any, any>(
  mapStateToProps,
  mapDispatchToProps,
)(AppVersionControlContainer);
