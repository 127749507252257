// @flow
import React from 'react';
import { get, map } from 'lodash';
import moment from 'moment';
// import 'moment/locale/pt'; // Include the locale utils designed for moment
import { Button, Icon } from '../../../elements';
import type { DrugsPrescription } from '../../../../types';
import './DrugsPrescription.scss';

type Props = {
  drugsPrescription: DrugsPrescription,
  patientName: string,
  onPrintPrescriptionDrugsClick: Function,
  onEditPrescriptionDrugsClick: Function,
};

export default class DrugPrescription extends React.Component<Props> {
  render() {
    const { drugsPrescription, patientName } = this.props;

    const recordId = get(drugsPrescription, ['record', 'id']);
    const isEditable = get(drugsPrescription, ['record', 'details', 'isEditable']);
    const owner = get(drugsPrescription, ['record', 'userName']);
    const date = get(drugsPrescription, ['record', 'date']);

    return (
      <div className="prescriptionWrapper">
        <div className="prescription" key={drugsPrescription.id}>
          <div className='drugsPrescriptionsTitleWraper'>
            <span className="prescriptionTitle">
              {drugsPrescription.controlledDrugs ? 'Receituário Controlado' : 'Receituário'}
            </span>
            <div className="drugsPrescriptionActions">
              {isEditable &&
                <Button
                  text="Editar"
                  onClick={this.onEditDrugsPrescriptionClick.bind(this, recordId, drugsPrescription.id, patientName)}
                />
              }

              <Button
                onClick={this.onPrintDrugsPrescriptionClick.bind(this, recordId, drugsPrescription.id)}
              >
                <Icon iconName="print" className="icon" />
              </Button>
            </div>
          </div>
          <div className="prescriptionContent">
            {this.parseNotes()}
          </div>
        </div>
        <div className="prescriptionInfo">
          <span className='createdAt'>{moment(date).format('DD [de] MMM [de] YYYY')}</span>&nbsp;&nbsp;&nbsp;
          <span className='owner'>{owner}</span>
        </div>
      </div>
    );
  }

  onPrintDrugsPrescriptionClick = (recordId: number, prescriptionId: number) => {
    this.props.onPrintPrescriptionDrugsClick(recordId, prescriptionId);
  };

  onEditDrugsPrescriptionClick = (recordId: number, prescriptionId: number, patientName: string) => {
    this.props.onEditPrescriptionDrugsClick({ recordId, prescriptionId, patientName });
  };

  parseNotes = () => map<any, any>(String(this.props.drugsPrescription.notes).split(/<br\s*\/?>/g),
    (line: string) => (
      <p>{line}</p>
    ),
  )
}
