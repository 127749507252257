// @flow
import React from 'react';
import { Modal } from '../../../../elements';

type Props = {
  onClose: Function,
};

export default class MessageFormModal extends React.Component<Props> {
  render() {
    const { onClose } = this.props;

    const modalTitle = 'MessageFormModal';

    return (
      <Modal className="fullScreen" onClose={onClose}>
        <div className="modalGrid">
          {modalTitle}
        </div>
      </Modal>
    );
  }
}
