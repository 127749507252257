// @flow
import React from 'react';
import { isEqual } from 'lodash';
import {
  PatientFormModalContainer, MessageFormModalContainer,
  DocumentFormModalContainer, PrescriptionDrugsFormModalContainer, PrescriptionExamsFormModalContainer, CustomFormModalContainer,
  MedicationsFormModalContainer, ProblemsFormModalContainer, TagsFormModalContainer,
  ReceiptModalContainer, ReceiptFormModalContainer, ServicesNewListModalContainer,
  PaymentNewFormModalContainer, PaymentEditFormModalContainer, ServiceFormModalContainer,
  ReceiptNewFormModalContainer, ProfileImageCaptureModalContainer, AppointmentModalFormContainer,
  PatientIndicatorsGraphModalContainer, PatientIndicatorsFormModalContainer,
} from './modals';

type Props = {
  activeModal: string,
};

// Avoid use of component if not needed (prefer funcional components)

class MainModalContainer extends React.Component<Props> {
  shouldComponentUpdate(nextProps: Props) {
    return !isEqual(this.props, nextProps);
  }
  render () {
    const { activeModal } = this.props;
    let activeModalComponent;
    switch (activeModal) {
      case 'AppointmentModalForm':
        activeModalComponent = <AppointmentModalFormContainer />;
        break;
      case 'NewAppointmentModal':
        activeModalComponent = <AppointmentModalFormContainer />;
        break;
      case 'PatientFormModal':
        activeModalComponent = <PatientFormModalContainer />;
        break;
      case 'MessageFormModal':
        activeModalComponent = <MessageFormModalContainer />;
        break;
      case 'DocumentFormModal':
        activeModalComponent = <DocumentFormModalContainer />;
        break;
      case 'PrescriptionDrugsModal':
        activeModalComponent = <PrescriptionDrugsFormModalContainer />;
        break;
      case 'PrescriptionExamsModal':
        activeModalComponent = <PrescriptionExamsFormModalContainer />;
        break;
      case 'CustomFormModal':
        activeModalComponent = <CustomFormModalContainer />;
        break;
      case 'MedicationsFormModal':
        activeModalComponent = <MedicationsFormModalContainer />;
        break;
      case 'ProblemsFormModal':
        activeModalComponent = <ProblemsFormModalContainer />;
        break;
      case 'TagsFormModal':
        activeModalComponent = <TagsFormModalContainer />;
        break;
      case 'PatientIndicatorsGraphModal':
        activeModalComponent = <PatientIndicatorsGraphModalContainer />;
        break;
      case 'PatientIndicatorsFormModal':
        activeModalComponent = <PatientIndicatorsFormModalContainer />;
        break;
      case 'PaymentNewFormModal':
        activeModalComponent = <PaymentNewFormModalContainer />;
        break;
      case 'PaymentEditFormModal':
        activeModalComponent = <PaymentEditFormModalContainer />;
        break;
      case 'ReceiptFormModal':
        activeModalComponent = <ReceiptFormModalContainer />;
        break;
      case 'ReceiptModal':
        activeModalComponent = <ReceiptModalContainer />;
        break;
      case 'ServicesNewListModal':
        activeModalComponent = <ServicesNewListModalContainer />;
        break;
      case 'ServiceFormModal':
        activeModalComponent = <ServiceFormModalContainer />;
        break;
      case 'ReceiptNewFormModal':
        activeModalComponent = <ReceiptNewFormModalContainer />;
        break;
      case 'ProfileImageCaptureModal':
        activeModalComponent = <ProfileImageCaptureModalContainer />;
        break;
      default:
        return null;
    }

    return activeModalComponent;
  }
}

export default MainModalContainer;
