export default {
  'label/plan_concierge': 'Concierge',
  'label/plan_complete': 'Completo',
  'label/plan_basic': 'Básico',
  'label/plan_clinic': 'Clínica',
  'label/payment_interval': 'mes',
  'label/plan_not_selected': 'No Seleccionado',
  'label/end_of_trial_counter': 'Su período de prueba ha terminado.',
  'label/end_of_trial_subscribe_now': '¡Suscríbete ahora!',
  'label/trialing_counter': 'Quedan {0} para el final del período de prueba.',
  'label/trialing_subscribe_now': '¡Suscríbete ahora!',
  'label/message_essential': 'Con Doutore tienes lo esencial.',
  'label/agenda_promo': 'Accede a las citas programadas por ti o por tu secretaria desde cualquier lugar.',
  'label/records_promo': 'Tan práctico como papel y bolígrafo. 100% digital, seguro y con acceso rápido desde cualquier lugar.',
  'label/all_plans_includes': 'Todos los Planos Incluyen',
  'label/unlimited_patients_and_secretaries': 'Pacientes y Secretarias Ilimitados',
  'label/unlimited_storage': 'Almacenamiento Ilimitado',
  'label/pc_ios_and_android': 'Acceso desde la computadora y las aplicaciones de iOS y Android',
  'label/training': 'Entrenamiento',
  'label/month_price': 'mes',
  'label/continue': 'Continuar',
  'label/subscription_data_title': 'Datos para la Suscripción',
  'label/name_input': 'Nombre Completo',
  'label/credit_card_number': 'Número de la tarjeta',
  'label/cvv': 'Código de Seguridad',
  'label/expiration_date': 'Fecha de caducidad',
  'label/credit_card_name': 'Nombre tal como aparece en la tarjeta',
  'label/security_text_1': '¡Tus informaciones están seguras!',
  'label/security_text_2': 'Esté tranquilo. Tus datos no se guardan en nuestro servidor - se envían directamente a la Institución Financiera que realizará el cobro.',
  'label/regulatory_text/explanation': 'Esta es una compra internacional, sujeta a una operación de cambio, que será procesada por EBANX de acuerdo con estos ',
  'label/regulatory_text/terms': 'Términos y Condiciones',
  'label/regulatory_text/agreement': '. Al continuar, reconoces y aceptas los términos de esta transacción. Todos los impuestos y IOF ya están incluidos en el valor de la suscripción.',
  'label/bitcoin/paymnent_info/text_1': 'Doutore entiende que las monedas digitales ya son parte del presente de la sociedad y lo serán cada vez más. Por eso, somos la primera herramienta para Consultorios y Clínicas en ofrecer pago a través de Bitcoin.',
  'label/bitcoin/paymnent_info/text_2': 'Si estás interesado, contáctanos. Estaremos encantados de ayudarte a suscribirte a Doutore con Bitcoin.',
  'label/credit_card/payment_success_title': '¡Todo bien! Ya puedes usar Doutore :)',
  'label/credit_card/payment_success_text': '¡Tu suscripción ya está activa! Para cualquier sugerencia, duda o problema, nuestro canal de atención por correo electrónico estará siempre disponible.',
  'label/boleto/payment_success_title': 'Boleto / Código PIX generado!',
  'label/boleto/payment_success_subtitle': 'El boleto se enviará en unos minutos a su correo electrónico',
  'label/boleto/payment_success_text_0': 'Su suscripción está activa pero será cancelada si el pago no se identifica en el plazo de',
  'label/boleto/payment_success_text_1': '3 días hábiles',
  'label/boleto/paymnent_info/title': 'El pago a través de Boleto/PIX no activará su cuenta inmediatamente.',
  'label/boleto/paymnent_info/days': 'El Boleto Bancario tarda 3 días hábiles en procesarse.',
  'label/boleto/paymnent_info/text': 'Si desea activar su cuenta inmediatamente, use una tarjeta de crédito o envíe el comprobante de pago del boleto al soporte de Doutore.',
  'action/view_plans': 'Ver Planes',
  'action/select_a_plan': 'Elige un Plan',
  'action/choose_plan': 'Suscribir',
  'action/boleto_signup': 'Suscribir con Boleto',
  'action/payment_success_back': 'Volver a Doutore',
  'action/goto_payment': 'Ir a la Forma de Pago',
  'action/subscribe_with_credit_card': 'Suscribir con Tarjeta',
  'label/quest_have_clinic': '¿Tiene una Clínica?',
  'action/contact': 'Ponte en contacto',
  'label/plans': 'Planes',
  'action/restore_purchases': 'Restaurar Compra',
  'placeholder/mmaa': 'MM/AA',
  'label/select_plan_loading': 'Enviando',
  'label/stripe/payment_success_title': ' ¡Su suscripción está siendo procesada!',
  'label/stripe/payment_success_subtitle': 'Nos pondremos en contacto contigo por correo electrónico para enviarte el enlace de pago',
  'label/ios_payment_text': `El pago se realizará a través de tu cuenta de iTunes.
  La renovación se realiza automáticamente en un período de 24 horas antes del último día del servicio. La renovación automática puede cancelarse en cualquier momento.
  Solo tienes que ir a la página de configuración en la tienda de iTunes después de la compra.`,
};
