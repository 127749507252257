// @flow
import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MemedSignup } from '@views';
import { get } from 'lodash';
import { memedSignup, logout, clearMemedSignupError } from '@context/user';
import { mapBundledPermission } from '@helpers/connect';



const MemedSignupContainer = () => {
  const dispatch = useDispatch();
  const isLoading = useSelector(state => get(state, ['context', 'user', 'advancedData', 'memedSignupLoading']));
  const error = useSelector(state => get(state, ['context', 'user', 'advancedData', 'memedError']));
  const token = useSelector(state => get(state, ['context', 'user', 'advancedData', 'memedToken']));
  const user = useSelector(state => get(state, ['context', 'user']));
  const planIncludeMemed = useSelector(state => get(state, ['context', 'user', 'advancedData', 'planInclude', 'memed'], false));
  const doctor = useSelector((state) => {
    const doctorId = get(state, ['context', 'user', 'advancedData', 'doctorId']);
    return get(state, ['context', 'doctors', 'data', doctorId]);
  });
  const permissions = useSelector(state => mapBundledPermission(state));

  const handleSignup = useCallback(({ addressState, birthDate, cpf, gender, councilDoc }) => {
    dispatch(memedSignup(addressState, birthDate, cpf, gender, councilDoc, () => {}));
  }, [dispatch]);

  const handleLogout = useCallback(() => {
    dispatch(logout());
  }, [dispatch]);

  useEffect(() => {
    dispatch(clearMemedSignupError());
  }, [dispatch]);


  return (
    <MemedSignup
      isLoading={isLoading}
      error={error}
      token={token}
      onSignup={handleSignup}
      user={user}
      onLogout={handleLogout}
      permissions={permissions}
      doctor={doctor}
      planIncludeMemed={planIncludeMemed}
    />
  );
};

// $FlowFixMe
export default React.memo(MemedSignupContainer);
