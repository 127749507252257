// @flow
import React from 'react';
import { toString, get, forEach } from 'lodash';
import { SettingsMenu, FormFooter, PlatformSwitch, AppMobileHeader, AppMobileFooter } from '@blocks';
import { Breadcrumb, Input, SelectBox } from '@elements';
import type { User, Room, BundledPermissions } from '@types';
import { isPresent, createNsTranslator, handleSubmitEvent } from '@helpers/helpers';
import { withTranslation } from 'react-i18next';

type Props = {
  user: User,
  onSave: Function,
  onLogout: Function,
  t: Function,
  rooms: Array<Room>,
  isLoading: boolean,
  permissions: BundledPermissions,
};

type State = {
  numberOfRooms: number,
  errors: Object,
  rooms: Object,
  errors: Object,
  edited?: boolean,
};

const roomName = (index: number) => `room_ ${index} _name`;

const roomsToObjectKey = (rooms: Array<Room>) => {
  const newRooms = {};
  forEach(rooms, (room: Room) => {
    newRooms[roomName(room.number)] = room.name;
  });
  return newRooms;
};

class ClinicConfigRooms extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      numberOfRooms: this.props.rooms ? this.props.rooms.length : 1,
      rooms: roomsToObjectKey(this.props.rooms),
      errors: {},
      edited: false,
    };
  }

  componentDidMount() {
    handleSubmitEvent('#clinicConfigRoomsDesktopForm', () => {
      if (this.state.edited) {
        this.onSave();
      }
    });
  }

  render() {
    const { user, isLoading, onLogout } = this.props;
    const {
      numberOfRooms, rooms, errors, edited,
    } = this.state;

    const _ = createNsTranslator('settings', this.props.t);

    const breadcrumbLinks = [
      { text: _('label/agenda'), linkTo: '/ajustes/clinica' },
      { text: _('label/rooms/title') },
    ];

    const _qtdFields = [];
    for (let n = 1; n <= 15; n++) {
      const item = { text: toString(n), value: toString(n) };
      _qtdFields.push(item);
    }

    const items = [];
    for (let i = 1; i <= numberOfRooms; i++) {
      const name = roomName(i);
      items.push(
        <Input
          value={get(rooms, name, '')}
          name={name}
          className="field"
          label={_('label/rooms/room_name') + ' ' + i + ' '}
          required
          disabled
          error={get(errors, name, null)}
          onChange={this.onChangeRoom}
        />,
      );
    }

    const pageContent = (
      <React.Fragment>
        <div className="toolbar dashed">
          <h1>{_('label/rooms/title')}</h1>
        </div>
        <SelectBox
          disabled
          name="number_of_rooms"
          id="selectQtdFields"
          className="field"
          label={_('label/rooms/number_of_rooms')}
          value={numberOfRooms}
          options={_qtdFields}
          onChange={this.onSelectRooms}
        />
        { items }
        <FormFooter onSave={this.onSave} onCancel={this.onCancel} isLoading={isLoading} edited={edited} editFeature />
      </React.Fragment>
    );

    return (
      <PlatformSwitch
        inject
        desktop={() => (
          <div id="clinicConfigRoomsDesktopForm" className="appContent settingsPage">
            <aside className="sidebar">
              <SettingsMenu user={user} onLogout={onLogout} permissions={this.props.permissions} />
            </aside>
            <section className="mainSection">
              <div className="centerContent">
                <Breadcrumb items={breadcrumbLinks} />
                {pageContent}
              </div>
            </section>
          </div>
        )}
        mobile={() => (
          <React.Fragment>
            <AppMobileHeader title={_('label/rooms/title')} backLinkTo="/ajustes/clinica" />
            <div className="appContent settingsPage">
              <section className="mainSection">
                <div className="centerContent">{pageContent}</div>
              </section>
            </div>
            <AppMobileFooter />
          </React.Fragment>
        )}
      />
    );
  }

  onCancel = () => {
    this.setState({
      numberOfRooms: this.props.rooms ? this.props.rooms.length : 1,
      rooms: roomsToObjectKey(this.props.rooms),
      errors: {},
      edited: false,
    });
  };

  onSave = () => this.handleErrors().then(() => {
    if (Object.keys(this.state.errors).length === 0) {
      this.setState({ edited: false }, () => {
        const { rooms } = this.state;
        const errors = {};

        Object.keys(rooms).forEach(key => {
          if (!isPresent(get(rooms, key, ''))) {
            errors[key] = this.props.t('global:error/name');
          }
        });

        this.setState({ errors });

        if (Object.keys(errors).length === 0) {
          this.props.onSave(rooms);
        }
      });
    }
  });

  onChangeRoom = (data: Object) => {
    const rooms = {
      ...this.state.rooms,
      [get(data, 'name', '')]: get(data, 'value', ''),
    };

    this.setState({
      rooms,
      edited: true,
    });
  };

  handleErrors = () => new Promise((resolve: Function) => {
    const errors = {};
    Object.keys(this.state.rooms).forEach(key => {
      if (!isPresent(get(this.state.rooms, key, ''))) {
        errors[key] = this.props.t('global:error/name');
      }
    });
    this.setState({ errors }, resolve);
  });

  onSelectRooms = (data: Object) => {
    const numberOfRooms = parseInt(get(data, 'value', '1'), 10);
    const rooms = {};
    // Clear unselected rooms from state to prevent invisible room name

    for (let n = 1; n <= numberOfRooms; n++) {
      const name = roomName(n);
      rooms[name] = get(this.state.rooms, name, '');
    }

    this.setState({
      numberOfRooms,
      rooms,
      errors: {},
      edited: true,
    });
  };
}

export default withTranslation()(ClinicConfigRooms);
