// @flow
import React from 'react';
import { get, map } from 'lodash';
import { BlockedFeature, ReportsMenu, ReportPage, PlatformSwitch, AppMobileHeader, BlockedReport, AppMobileFooter } from '@blocks';
import { Spinner, Loading, FormattedText, Table } from '@elements';
import type { ReportSmsData } from '@types';
import { sort, dateCompare, createNsTranslator } from '@helpers/helpers';
import { withTranslation } from 'react-i18next';

type Props = {
  isLoading: boolean,
  data: ReportSmsData,
  isFeatureEnabled: boolean,
  isReportBlocked: boolean,
  t: Function,
};

// Easing to avoid complex and repeated code
const orderByDate = (data) => sort(data, dateCompare('date', 'desc'));

class ReportsSms extends React.Component<Props> {
  render() {
    const {
      data, isLoading, isReportBlocked, isFeatureEnabled,
    } = this.props;

    const _ = createNsTranslator('reports', this.props.t);

    if (isReportBlocked) {
      return (
        <BlockedReport
          backLinkTo="/relatorios"
          title={_('sms/messages_per_sms')}
        />
      );
    }

    const confirmedSmsesCount = parseInt(get(data, 'confirmedSmsesCount', 0), 10);
    const paidSmses = parseInt(get(data, 'paidSmses', 0), 10);
    const smsesLeftOver = parseInt(get(data, 'smsesLeftOver', 0), 10);
    const smsPayments = get(data, 'smsPayments', []);
    const smsesByDate = get(data, 'smsesByDate', []);

    const loadWrapper = value => (isLoading ? <Spinner /> : <FormattedText text={value} type="number" />);


    const smsesByDateItens = map<any, any>(orderByDate(smsesByDate), (item, key: number) => (
      <tr key={key}>
        <td><FormattedText type="date" text={item.date} /></td>
        <td>{item.count}</td>
      </tr>
    ));

    const smsPaymentsItensTable = (
      <Table
        itemKey="id"
        columns={[
          {
            dataIndex: 'name',
            content: _('sms/package'),
          },
          {
            dataIndex: 'date',
            content: _('label/date', 'global'),
            onRenderItem: (item) => <FormattedText type="date" text={item.date} />,
          },
          {
            dataIndex: 'price',
            content: _('label/value', 'global'),
            onRenderItem: (item) => <FormattedText type="currency" text={item.price} />,
          },
        ]
        }
        fullTable
        items={orderByDate(smsPayments)}
      />
    );

    const smsesByDateItensTable = (
      <table className="table">
        <thead>
          <tr>
            <th>{_('label/date', 'global')}</th>
            <th>{_('sms/sent')}</th>
          </tr>
        </thead>
        <tbody>
          {smsesByDateItens}
        </tbody>
      </table>
    );

    const resumeTable = (withTitle: boolean) => (
      <table className="table">
        {withTitle &&
          <thead>
            <tr>
              <th>{_('label/resume', 'global')}</th>
              <th>&nbsp;</th>
            </tr>
          </thead>
        }
        <tbody>
          <tr>
            <td>{_('sms/remaining_sms_balance')}</td>
            <td>{loadWrapper(smsesLeftOver)}</td>
          </tr>
          <tr>
            <td>{_('sms/total_sent_sms')}</td>
            <td>{loadWrapper(confirmedSmsesCount)}</td>
          </tr>
          <tr>
            <td>{_('sms/total_contrated_sms')}</td>
            <td>{loadWrapper(paidSmses)}</td>
          </tr>
        </tbody>
      </table>
    );

    return (
      <div id="reportsSmsPage" className="appContent reportsPage">
        <PlatformSwitch
          inject
          desktop={() => (
            <React.Fragment>
              <ReportsMenu />

              <section className="mainSection">
                {!isFeatureEnabled && (
                  <BlockedFeature
                    customMessage={_('sms/not_contracted_message')}
                    onBtnClick={this.onSupportButtonClick}
                    customButton={_('msg/contact_support', 'global')}
                  />
                )}
                {isFeatureEnabled && (
                  <div className="centerContent">
                    <div className="toolbar">
                      <h1>{_('sms/messages_per_sms')}</h1>
                    </div>
                    <div className="desktopCard">
                      <section>
                        {resumeTable(true)}
                      </section>

                      <section>
                        <div className="toolbar">
                          <h2>{_('sms/contracted_sms_packages')}</h2>
                        </div>
                        {isLoading && <Loading />}
                        {!isLoading && smsPaymentsItensTable }
                      </section>

                      <section>
                        <div className="toolbar">
                          <h2>{_('sms/sent_report')}</h2>
                        </div>
                        {isLoading && <Loading />}
                        {!isLoading && smsesByDateItensTable}
                      </section>
                    </div>
                  </div>
                )}
              </section>
            </React.Fragment>
          )}
          mobile={() => (
            <React.Fragment>
              <AppMobileHeader title={_('sms/messages_per_sms')} />
              <ReportPage
                id="reportsSmsPage"
                isLoading={isLoading}
                isFeatureEnabled={isFeatureEnabled}
                renderBlockedFeature={() => (
                  <BlockedFeature
                    className="blockedFeatureTransparent"
                    customMessage={_('sms/not_contracted_message')}
                    customButton={_('msg/contact_support', 'global')}
                    onBtnClick={this.onSupportButtonClick}
                  />)}
                noPadding
              >
                <ReportPage.Table title={_('label/resume', 'global')}>
                  {resumeTable(false)}
                </ReportPage.Table>
                <ReportPage.Table title={_('sms/contracted_sms_packages')}>
                  {smsPaymentsItensTable}
                </ReportPage.Table>
                <ReportPage.Table title={_('sms/sent_report')}>
                  {smsesByDateItensTable}
                </ReportPage.Table>
              </ReportPage>
              <AppMobileFooter />
            </React.Fragment>
          )}
        />
      </div>
    );
  }

  onSupportButtonClick = () => {
    if (window.Intercom) {
      window.Intercom('show');
    }
  };
}

export default withTranslation()(ReportsSms);
