// @flow
import React, { useState, useCallback, useMemo, useEffect } from 'react';
import moment from 'moment';
import { map, get, difference, includes, first, differenceWith } from 'lodash';
import type Moment from 'moment';
import {
  AgendaFilters, PlatformSwitch,
  AppMobileHeader,
  AppMobileFooter,
} from '@blocks';
import {
  Icon, Button, Calendar,
  CustomDayPicker, CalendarSwitcher, ProgressBar,
} from '@elements';
import type {
  CompactAppointment,
  Clinic,
  Doctors,
  User,
  PermissionTo,
} from '@types';
import { sort, alphabeticCompare, useTranslationNs } from '@helpers/helpers';
import './Agenda.scss';

type Props = {
  activeModal: ?string,
  activeClinic: Clinic,
  doctors: Doctors,
  user: User,
  appointments: Array<CompactAppointment>,
  onGetAppointments: Function,
  onShowAppointment: Function,
  onUpdateAppointment: Function,
  onNewAppointmentClick: Function,
  onChangeAgendaFilters: Function,
  // Agenda Filters
  agendaFilters: Object,
  loadingItems: Object,
  isLoading: boolean,
  showProgressBar: boolean,
  highPrivacy: boolean,
  orderDoctorsByProfession: boolean,
  isFinatialEnabled: boolean,
  permissions: PermissionTo,
};


const Agenda = ({
  activeClinic, activeModal, agendaFilters, appointments, doctors, highPrivacy,
  isFinatialEnabled, isLoading, loadingItems, onChangeAgendaFilters, onGetAppointments,
  onNewAppointmentClick, onShowAppointment, onUpdateAppointment, orderDoctorsByProfession, permissions, showProgressBar, user }: Props) => {
  const [showMobileAgendaFilter, setShowMobileAgendaFilter] = useState(false);


  const {
    switchMode, isMobilePickerOpened,
  } = agendaFilters;

  const [_, i18n] = useTranslationNs('agenda');

  const clinicDoctors = useMemo(() => {
    const clinicDoctorsTmp = {};
    map(get(activeClinic, ['doctorIds']), (doctorId) => {
      const doctor = get(doctors, ['data', doctorId]);
      clinicDoctorsTmp[doctorId] = doctor;
    });

    return clinicDoctorsTmp;
  }, [activeClinic, doctors]);

  useEffect(() => {
    window.lastSettingsScroll = 0;
  }, []);


  const selectedDate = useMemo(() => (agendaFilters.selectedDate ? moment(agendaFilters.selectedDate) : moment()), [agendaFilters.selectedDate]);
  const roomsFilter = useMemo(() => agendaFilters.roomsFilter || map(get(activeClinic, 'rooms', []), (room) => (room.number)), [activeClinic, agendaFilters.roomsFilter]);
  const allRoomsClinicIds = useMemo(() => map<any, any>(get(activeClinic, 'rooms', []), (room) => (room.number)), [activeClinic]);
  const isAllRoomsFilter = useMemo(() => differenceWith(allRoomsClinicIds, roomsFilter).length === 0, [allRoomsClinicIds, roomsFilter]);
  // const doctorsFilter = agendaFilters.doctorsFilter || (user ? [get(user, ['advancedData', 'doctorId'])] : []);
  const doctorsFilter = useMemo(() => get(agendaFilters, 'doctorsFilter', []), [agendaFilters]);
  const allDoctorClinicIds = useMemo(() => get(activeClinic, 'doctorIds', []), [activeClinic]);
  const isAllDoctorsFilter = useMemo(() => differenceWith(allDoctorClinicIds, doctorsFilter).length === 0, [allDoctorClinicIds, doctorsFilter]);
  // const firstId = doctors => Object.keys(doctors)[0] || null;
  // Filter appointments
  const filteredAppointments = useMemo(() => {
    const filteredAppointmentsTemp = [];
    map(appointments, (appointment) => {
      const { doctorId, room: roomId } = appointment;

      const doctorMatch = includes(doctorsFilter, doctorId) || isAllDoctorsFilter;
      const roomMatch = (roomId && includes(roomsFilter, roomId)) || isAllRoomsFilter;

      // check for matches with edge case - Blocked time have room defaulted to zero (0)
      if (doctorMatch && (roomMatch || roomId === 0)) {
        const highPrivacyBlocked = get(appointment, 'restricted', false) ? get(appointment, 'doctorId') !== get(user, ['advancedData', 'doctorId']) : false;

        filteredAppointmentsTemp.push({
          ...appointment,
          title: highPrivacyBlocked ? 'Consulta' : appointment.title,
        });
      }
    });
    return filteredAppointmentsTemp;
  }, [appointments, doctorsFilter, isAllDoctorsFilter, isAllRoomsFilter, roomsFilter, user]);

  const workHours = useMemo(() => {
    let workHoursTmp: any = [];
    if (doctorsFilter.length === 1) {
      const firstId = first(doctorsFilter);
      workHoursTmp = get(doctors, ['data', String(firstId), 'workHours']);
    }
    return workHoursTmp;
  }, [doctors, doctorsFilter]);

  const handleCancelDayPicker = useCallback(() => {
    onChangeAgendaFilters({ isMobilePickerOpened: false });
  }, [onChangeAgendaFilters]);

  const allRoomsIds = useMemo(() => map<any, any>(activeClinic.rooms, (room) => (room.number)), [activeClinic.rooms]);

  const calendarFilter = useMemo(() => ({
    doctor: doctorsFilter,
    room: roomsFilter,
    showAllRooms: difference(allRoomsIds, roomsFilter).length === 0,
    showAllDoctors: difference(activeClinic.doctorIds, doctorsFilter).length === 0,
  }), [activeClinic.doctorIds, allRoomsIds, doctorsFilter, roomsFilter]);

  const orderedDoctors = useMemo(() => sort(clinicDoctors, alphabeticCompare('name')), [clinicDoctors]);

  const handleShowMobileFilter = useCallback(() => setShowMobileAgendaFilter(true), []);
  const handleHideMobileFilter = useCallback(() => setShowMobileAgendaFilter(false), []);

  const handleSetSelectedToCurrentDate = useCallback(() => {
    onChangeAgendaFilters({ selectedDate: moment() });
  }, [onChangeAgendaFilters]);

  const handlePrevDayClick = useCallback(() => {
    let prevDate = moment(agendaFilters.selectedDate).clone();
    prevDate = prevDate.isValid() ? prevDate.subtract(1, 'days') : moment().subtract(1, 'days');
    onChangeAgendaFilters({ selectedDate: prevDate });
  }, [agendaFilters.selectedDate, onChangeAgendaFilters]);

  const handleNextDayClick = useCallback(() => {
    let nextDate = moment(agendaFilters.selectedDate).clone();
    nextDate = nextDate.isValid() ? nextDate.add(1, 'days') : moment().add(1, 'days');
    onChangeAgendaFilters({ selectedDate: nextDate });

    // buggy code
    // const daysInCurrentMonth = selectedDate.daysInMonth();

    // let day;
    // let month;
    // let year;
    // if (selectedDate.date() === daysInCurrentMonth) {
    //   const isNextYear = (selectedDate.month() + 1) === 12;
    //   day = 1;
    //   month = isNextYear ? 0 : selectedDate.month() + 1;
    //   year = isNextYear ? selectedDate.year() + 1 : selectedDate.year();
    // } else {
    //   day = selectedDate.date() + 1;
    //   month = selectedDate.month();
    //   year = selectedDate.year();
    // }
  }, [onChangeAgendaFilters, agendaFilters.selectedDate]);

  const handleNextWeek = useCallback(() => {
    onChangeAgendaFilters({ selectedDate: moment(agendaFilters.selectedDate).clone().add(1, 'week') });
  }, [agendaFilters.selectedDate, onChangeAgendaFilters]);

  const handlePrevWeek = useCallback(() => {
    onChangeAgendaFilters({ selectedDate: moment(agendaFilters.selectedDate).clone().subtract(1, 'week') });
  }, [agendaFilters.selectedDate, onChangeAgendaFilters]);

  const handlePickerMonthChange = useCallback((date: string) => {
    const parsedDate = moment(date);
    selectedDate.month(parsedDate.month());
    selectedDate.year(parsedDate.year());
    onChangeAgendaFilters({ selectedDate });
  }, [onChangeAgendaFilters, selectedDate]);

  const handleSelectDay = useCallback((date: string) => {
    onChangeAgendaFilters({ selectedDate: moment(date) });
  }, [onChangeAgendaFilters]);

  const handleModeChange = useCallback((newSwitchMode: string) => {
    onChangeAgendaFilters({ switchMode: newSwitchMode });
  }, [onChangeAgendaFilters]);

  const toggleMobileDayPicker = useCallback(() => {
    onChangeAgendaFilters({ isMobilePickerOpened: !agendaFilters.isMobilePickerOpened });
  }, [agendaFilters.isMobilePickerOpened, onChangeAgendaFilters]);

  const handleAcceptDayPicker = useCallback(() => {
    onChangeAgendaFilters({ isMobilePickerOpened: false });
    return selectedDate;
  }, [onChangeAgendaFilters, selectedDate]);

  const handleFiltersChange = useCallback((selectedDoctors: Array<number>, selectedRooms: Array<number>) => {
    if (selectedRooms && selectedDoctors) {
      onChangeAgendaFilters({
        roomsFilter: selectedRooms,
        doctorsFilter: selectedDoctors,
      });
    } else if (selectedRooms && !selectedDoctors) {
      onChangeAgendaFilters({
        roomsFilter: selectedRooms,
      });
    } else if (!selectedRooms && selectedDoctors) {
      onChangeAgendaFilters({
        doctorsFilter: selectedDoctors,
      });
    }
    handleHideMobileFilter();
  }, [handleHideMobileFilter, onChangeAgendaFilters]);

  const handleNewAppointmentClick = useCallback((start: Moment, end: Moment, isDayClick: boolean) => {
    onNewAppointmentClick(doctorsFilter, start, end, isDayClick);
  }, [doctorsFilter, onNewAppointmentClick]);

  const handleRefresh = useCallback(() => {
    onGetAppointments(null, true);
  }, [onGetAppointments]);

  return (
    <PlatformSwitch
      inject
      desktop={() => (
        <div id="agendaPage" className="appContent">
          <ProgressBar show={showProgressBar} />
          <aside className="sidebar">
            <div className="sidebarActions">
              <Button className="primary" text={_('label/new_appointment')} onClick={handleNewAppointmentClick} />
            </div>
            <div className="datepicker">
              <CalendarSwitcher
                selectedDate={selectedDate}
                onChangeMode={handleModeChange}
                onNextWeek={handleNextWeek}
                onPrevWeek={handlePrevWeek}
                onPrevDayClick={handlePrevDayClick}
                onNextDayClick={handleNextDayClick}
                onSetSelectedToCurrentDate={handleSetSelectedToCurrentDate}
                switchCalendarBy={switchMode === 'week' ? 'week' : 'day'}
              />

              <div className="dayPicker">
                <CustomDayPicker
                  disableWeekends={!get(activeClinic, 'showWeekends', false) && switchMode === 'week'}
                  advancedMode
                  selectedDate={selectedDate}
                  onMonthChange={handlePickerMonthChange}
                  onSelectDay={handleSelectDay}
                />
              </div>

              <Button
                className="linkButton"
                text={_('action/refresh')}
                onClick={handleRefresh}
              />
            </div>
            <AgendaFilters
              isLoading={isLoading}
              rooms={sort(activeClinic.rooms, alphabeticCompare('name'))}
              doctors={orderedDoctors}
              defaultRooms={roomsFilter}
              defaultDoctorId={doctorsFilter}
              onFiltersChange={handleFiltersChange}
              highPrivacy={highPrivacy}
              user={user}
              orderDoctorsByProfession={orderDoctorsByProfession}
              permissions={permissions}
            />
          </aside>
          <section className="mainSection">
            <Calendar
              showProgressBar={showProgressBar}
              loadingItems={loadingItems}
              isLoading={isLoading}
              activeModal={activeModal}
              clinic={activeClinic}
              clinicDoctors={clinicDoctors}
              calendarView={switchMode === 'week' ? 'timeGridWeek' : 'timeGridDay'}
              selectedDate={selectedDate.format('YYYY-MM-DD')}
              appointments={filteredAppointments}
              onGetAppointments={onGetAppointments}
              onShowAppointment={onShowAppointment}
              onUpdateAppointment={onUpdateAppointment}
              onNewAppointmentClick={handleNewAppointmentClick}
              user={user}
              webView
              filter={calendarFilter}
              workHours={workHours}
              isFinatialEnabled={isFinatialEnabled}
              language={get(i18n, 'language')}
            />
          </section>
        </div>
      )}
      mobile={() => (
        <React.Fragment>
          {!showMobileAgendaFilter &&
          <AppMobileHeader hideBack disableHeader>
            <CalendarSwitcher
              isMobile
              onToggleMobileDayPicker={toggleMobileDayPicker}
              selectedDate={selectedDate}
              onChangeMode={handleModeChange}
              onNextWeek={handleNextWeek}
              onPrevWeek={handlePrevWeek}
              onPrevDayClick={handlePrevDayClick}
              onNextDayClick={handleNextDayClick}
              onSetSelectedToCurrentDate={handleSetSelectedToCurrentDate}
              onMobileFilterClick={handleShowMobileFilter}
              switchCalendarBy="day"
              mobileGreenFilter={!calendarFilter.showAllDoctors || !calendarFilter.showAllRooms}
            />
            <Icon iconName="add" className="icon" onClick={handleNewAppointmentClick} />
            <Icon iconName="refresh" className="icon" onClick={onGetAppointments} />
          </AppMobileHeader>
            }
          { !showMobileAgendaFilter &&
          <div className="mobileProgressbarHolder"><ProgressBar show={showProgressBar} /></div>
            }
          {showMobileAgendaFilter &&
          <AppMobileHeader title="Filtros" onBackClick={handleHideMobileFilter} />
            }
          <div id="agendaPage" className="appContent">
            {/* <section className="mainSection"> */}
            {!showMobileAgendaFilter &&
              <Calendar
                showProgressBar={showProgressBar}
                loadingItems={loadingItems}
                isLoading={isLoading}
                activeModal={activeModal}
                clinic={activeClinic}
                clinicDoctors={clinicDoctors}
                calendarView="timeGridDay"
                selectedDate={selectedDate.format('YYYY-MM-DD')}
                appointments={filteredAppointments}
                onGetAppointments={onGetAppointments}
                onShowAppointment={onShowAppointment}
                onUpdateAppointment={onUpdateAppointment}
                onNewAppointmentClick={handleNewAppointmentClick}
                user={user}
                filter={calendarFilter}
                workHours={workHours}
                isFinatialEnabled={isFinatialEnabled}
                language={get(i18n, 'language')}
              />
                }
            {showMobileAgendaFilter &&
              <AgendaFilters
                isLoading={isLoading}
                rooms={sort(activeClinic.rooms, alphabeticCompare('name'))}
                doctors={orderedDoctors}
                defaultRooms={roomsFilter}
                defaultDoctorId={doctorsFilter}
                onFiltersChange={handleFiltersChange}
                highPrivacy={highPrivacy}
                user={user}
                orderDoctorsByProfession={orderDoctorsByProfession}
                permissions={permissions}
              />
                }
            {/* </section> */}

            <CustomDayPicker
              disableWeekends={!get(activeClinic, 'showWeekends', false)}
              acceptOnSelect
              withOverlay
              showButtons
              advancedMode
              isOpen={isMobilePickerOpened}
              selectedDate={selectedDate}
              onMonthChange={handlePickerMonthChange}
              onSelectDay={handleSelectDay}
              onCancel={handleCancelDayPicker}
              onAccept={handleAcceptDayPicker}
              showToday
            />

          </div>
          {!showMobileAgendaFilter && <AppMobileFooter />}
        </React.Fragment>
      )}
    />
  );
};

// $FlowFixMe
export default React.memo(Agenda);
