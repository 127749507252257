// @flow
import React from 'react';
import { get } from 'lodash';
import { SettingsMenu, FormFooter, PlatformSwitch, AppMobileHeader, AppMobileFooter } from '@blocks';
import { Breadcrumb, Input, Toggle } from '@elements';
import type { User, BundledPermissions } from '@types';
import { withForm } from '@hocs';
import { createValidator } from '@validator';
import { withTranslation } from 'react-i18next';
import { createNsTranslator, handleSubmitEvent, UserNotifications } from '@helpers/helpers';
import './SecretariesForm.scss';

type Props = {
  user: User,
  onCancel: Function,
  onSave: Function,
  onDelete: Function,
  handleFormChange: Function,
  onLogout: Function,
  bindValidation: Function,
  formEdited: Function,
  formData: Object,
  touchedFields: Object,
  isLoading: boolean,
  isEdit: boolean,
  isDeleting: boolean,
  permissions: BundledPermissions,
  t: Function,
};

type State = {
  errors: Object,
};

const validator = createValidator()
  .field('name')
  .present(_ => _('error/name'))
  .field('email')
  .present(_ => _('error/email'));

class SecretaryForm extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      errors: {},
    };
  }

  componentWillMount() {
    this.props.bindValidation(this.handleValidation);
  }

  componentDidMount() {
    handleSubmitEvent('#secretariesForm', () => {
      if (this.props.formEdited()) {
        this.onSave();
      }
    });
  }

  render() {
    const {
      user, handleFormChange, formData, isLoading,
      onCancel, isEdit, onLogout, isDeleting,
    } = this.props;

    const _ = createNsTranslator('settings', this.props.t);

    const title = isEdit ? _('label/secretaries/form/edit_secretary') : _('label/secretaries/list/invite');

    const breadcrumbLinks = [
      { text: _('label/secretaries/list/title'), linkTo: '/ajustes/secretarias' },
      { text: title },
    ];

    const pageContent = (
      <React.Fragment>
        <Input
          type="text"
          name="name"
          autofocus
          disabled={isEdit}
          className="field"
          label={_('label/name', 'global')}
          onChange={handleFormChange}
          value={get(formData, 'name', '')}
          error={get(this.state.errors, 'name')}
          required
        />
        <Input
          type="text"
          name="email"
          disabled={isEdit}
          className="field"
          label={_('label/email', 'global')}
          onChange={handleFormChange}
          value={get(formData, 'email', '')}
          error={get(this.state.errors, 'email')}
          required
        />
        {/* <InputMask
          type='phone'
          name='mobilePhone'
          label={_('label/mobile_phone')}
          value={get(formData, 'mobilePhone', '')}
          onChange={handleFormChange}
        /> */}
        <Toggle
          label={_('label/manager', 'global')}
          name="manager"
          onChange={handleFormChange}
          isChecked={get(formData, 'manager', false)}
          error={get(this.state.errors, 'manager')}
        />
        <p>{_('label/secretaries/form/manager_hint')}</p>
        {/* {!isEdit && (
          <Toggle
            label={_('action/train_secretary')}
            name="wantTraining"
            onChange={handleFormChange}
            isChecked={get(formData, 'wantTraining', false)}
            error={get(this.state.errors, 'wantTraining')}
          />
        )} */}
        <FormFooter
          savingText={isEdit ? _('label/saving_simple', 'global') : _('label/inviting', 'global')}
          saveText={isEdit ? _('action/save', 'global') : _('action/invite', 'global')}
          onSave={this.onSave}
          isLoading={isLoading || isDeleting}
          onCancel={onCancel}
          onDelete={isEdit ? this.handleDelete : null}
        />
      </React.Fragment>
    );

    return (
      <PlatformSwitch
        inject
        desktop={() => (
          <div id="secretariesForm" className="appContent settingsPage">
            <aside className="sidebar">
              <SettingsMenu user={user} onLogout={onLogout} permissions={this.props.permissions} />
            </aside>
            <section className="mainSection">
              <div className="centerContent">
                <Breadcrumb items={breadcrumbLinks} />
                <div className="toolbar dashed">
                  <h1>{title}</h1>
                </div>
                {pageContent}
              </div>
            </section>
          </div>
        )}
        mobile={() => (
          <React.Fragment>
            <AppMobileHeader title={title} backLinkTo="/ajustes/secretarias" />
            <div id="secretariesForm" className="appContent settingsPage">
              <section className="mainSection" >
                <div className="centerContent">{pageContent}</div>
              </section>
            </div>
            <AppMobileFooter />
          </React.Fragment>
        )}
      />
    );
  }

  handleValidation = () => {
    const { formData, touchedFields } = this.props;
    this.setState({ errors: validator.validate(formData, touchedFields, false).errors });
  };

  onSave = () => {
    const { formData, onSave, touchedFields } = this.props;
    const validation = validator.validate(formData, touchedFields, true);

    this.setState({ errors: validation.errors });

    if (validation.valid) {
      onSave(formData);
    }
  };

  handleDelete = () => {
    UserNotifications.confirmI18n('alert/secretaries/form/confirm', 'warning', '#FFA433', true)
      .then((value) => {
        if (value) {
          this.props.onDelete();
        }
      });
  };
}

export default withTranslation()(withForm()(SecretaryForm));
