// @flow
import { map, find, get } from 'lodash';
import { push } from 'connected-react-router';
import { API_ROUTES_CONSTRUCTOR } from './api.routes';
import { ROUTES_CONSTRUCTOR } from './app.routes';

type RouteParamsType = ?{
  [key: string]: any,
};

type Routes = {
  [key: string]: string,
};

export class Router {
  routes() {
    return ROUTES_CONSTRUCTOR;
  }

  getNamedRoutes() {
    const namedRoutes: Routes = {};
    map(ROUTES_CONSTRUCTOR, (route) => {
      namedRoutes[route.name] = route.path;
    });
    return namedRoutes;
  }

  getRoutePath(routeName: string, params: RouteParamsType = null, api: boolean = false) {
    const routes = api ? API_ROUTES_CONSTRUCTOR : ROUTES_CONSTRUCTOR;

    const route = find(routes, (r) => (
      r.name === routeName
    ));

    if (!route) {
      return `-path for "${routeName}" not found-`;
    }

    let path;
    if (params) {
      path = route.path.replace(/:(\w+)/g, (match) => {
        const key = match.substring(1);
        const port = window.API.split(':')[2];

        if (key === port) {
          return `:${key}`;
        }
        return params ? params[key] : `-param ${key} not specified-`;
      });
    } else {
      return route.path;
    }
    return path;
  }

  getApiRoute(routeName: string, params: RouteParamsType = null) {
    const route = find(API_ROUTES_CONSTRUCTOR, (r) => (
      r.name === routeName
    ));
    const path = this.getRoutePath(routeName, params, true);

    return {
      fallback: get(route, 'fallback', false),
      path,
    };
  }

  pushPath(name: string, params: RouteParamsType) {
    return push(this.getRoutePath(name, params, false));
  }
}

// export router instance
export const router = new Router();
