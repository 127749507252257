// @flow
import React from 'react';
import { SettingsMenu, FormFooter, PlatformSwitch, AppMobileHeader, AppMobileFooter } from '@blocks';
import { Breadcrumb, Input, SelectBox } from '@elements';
import type { User, BundledPermissions } from '@types';
import { withForm } from '@hocs';
import { get } from 'lodash';
import { brStates } from '@constants';
import { createValidator } from '@validator';
import { withTranslation } from 'react-i18next';
import { createNsTranslator, handleSubmitEvent } from '@helpers/helpers';
import './ControlledPrescriptionsPrintConfig.scss';

type Props = {
  formData: Object,
  touchedFields: Object,
  bindValidation: Function,
  handleFormChange: Function,
  formEdited: Function,
  handleTouchField: Function,
  user: User,
  isLoading: boolean,
  onSave: Function,
  onLogout: Function,
  t: Function,
  permissions: BundledPermissions,
};

type State = {
  errors: Object,
};


const validator = createValidator()
  .field('fullName')
  .present(_ => _('error/full_name'));

class ControlledPrescriptionsPrintConfig extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      errors: {},
    };
    this.props.bindValidation(this.handleValidation);
  }

  componentDidMount() {
    handleSubmitEvent('#printConfigSubmitHandler', () => {
      if (this.props.formEdited()) {
        this.onSave();
      }
    });
  }

  render() {
    const {
      user, handleFormChange, formData, handleTouchField, isLoading,
      onLogout,
    } = this.props;
    const { errors } = this.state;

    const _ = createNsTranslator('settings', this.props.t);

    const breadcrumbLinks = [
      { text: _('label/print_config/menu/title'), linkTo: '/ajustes/impressao' },
      { text: _('label/print_config/controlled/title') },
    ];

    const pageContent = (
      <React.Fragment>
        <Input
          type="text"
          className="field"
          label={_('label/full_name', 'global')}
          name="fullName"
          error={get(errors, 'fullName', null)}
          value={get(formData, 'fullName', null)}
          onChange={handleFormChange}
          onBlur={handleTouchField}
          required
        />
        <div className="lineFields">
          <Input
            type="text"
            className="rightFields"
            label={_('label/print_config/controlled/council_number')}
            name="councilDoc"
            error={get(errors, 'councilDoc', null)}
            value={get(formData, 'councilDoc', null)}
            onChange={handleFormChange}
            onBlur={handleTouchField}
          />
          <Input
            type="text"
            className="leftFields"
            label={_('label/phone', 'global')}
            name="phone"
            error={get(errors, 'phone', null)}
            value={get(formData, 'phone', null)}
            onChange={handleFormChange}
            onBlur={handleTouchField}
          />
        </div>
        <Input
          type="text"
          className="cep"
          label={_('label/adress_zipcode', 'global')}
          name="addressZipcode"
          error={get(errors, 'addressZipcode', null)}
          value={get(formData, 'addressZipcode', null)}
          onChange={handleFormChange}
          onBlur={handleTouchField}
        />
        <div className="lineFields">
          <Input
            type="text"
            className="rightFields"
            label={_('label/adress_streeet', 'global')}
            name="addressStreet"
            error={get(errors, 'addressStreet', null)}
            value={get(formData, 'addressStreet', null)}
            onChange={handleFormChange}
            onBlur={handleTouchField}
          />
          <Input
            type="text"
            className="leftFields"
            label={_('label/adress_number', 'global')}
            name="addressNumber"
            error={get(errors, 'addressNumber', null)}
            value={get(formData, 'addressNumber', null)}
            onChange={handleFormChange}
            onBlur={handleTouchField}
          />
        </div>
        <div className="lineFields">
          <Input
            type="text"
            className="rightFields"
            label={_('label/adress_complement', 'global')}
            name="addressExtra"
            error={get(errors, 'addressExtra', null)}
            value={get(formData, 'addressExtra', null)}
            onChange={handleFormChange}
            onBlur={handleTouchField}
          />
          <Input
            type="text"
            className="leftFields"
            label={_('label/address_neighborhood', 'global')}
            name="addressNeighborhood"
            error={get(errors, 'addressNeighborhood', null)}
            value={get(formData, 'addressNeighborhood', null)}
            onChange={handleFormChange}
            onBlur={handleTouchField}
          />
        </div>
        <div className="lineFields">
          <Input
            type="text"
            className="rightFields"
            label={_('label/adress_city', 'global')}
            name="addressCity"
            error={get(errors, 'addressCity', null)}
            value={get(formData, 'addressCity', null)}
            onChange={handleFormChange}
            onBlur={handleTouchField}
          />
          <SelectBox
            options={brStates}
            className="leftFields"
            name="addressState"
            label={_('label/adress_state', 'global')}
            error={get(errors, 'addressState', null)}
            value={get(formData, 'addressState', null)}
            onChange={handleFormChange}
            onBlur={handleTouchField}
          />
        </div>
        <FormFooter onSave={this.onSave} isLoading={isLoading} />
      </React.Fragment>
    );
    return (
      <PlatformSwitch
        inject
        desktop={() => (
          <div id="controlledPrescriptionsPrintConfig" className="appContent settingsPage">
            <aside className="sidebar">
              <SettingsMenu user={user} onLogout={onLogout} permissions={this.props.permissions} />
            </aside>
            <section className="mainSection controlledPrescriptionsPrintConfig" id="printConfigSubmitHandler">
              <div className="centerContent">
                <Breadcrumb items={breadcrumbLinks} />
                <div className="toolbar dashed">
                  <h1>{_('label/print_config/controlled/title')}</h1>
                </div>
                {pageContent}
              </div>
            </section>
          </div>
        )}
        mobile={() => (
          <React.Fragment>
            <AppMobileHeader title={_('label/print_config/controlled/title')} backLinkTo="/ajustes/impressao" />
            <div id="controlledPrescriptionsPrintConfig" className="appContent settingsPage">
              <section className="mainSection">
                <div className="centerContent">
                  {pageContent}
                </div>
              </section>
            </div>
            <AppMobileFooter />
          </React.Fragment>
        )}
      />
    );
  }

  handleValidation = (isSubmit: boolean = false): Promise<boolean> => new Promise((resolve: Function) => {
    const { formData, touchedFields } = this.props;
    const result = validator.validate(formData, touchedFields, isSubmit);
    this.setState({ errors: result.errors }, () => resolve(result.valid));
  });

  onSave = () => this.handleValidation(true).then(valid => {
    if (valid) {
      const { onSave, formData } = this.props;
      onSave(formData);
    }
  });
}


export default withTranslation()(withForm({})(ControlledPrescriptionsPrintConfig));
