// @flow
import React from 'react';
import { get } from 'lodash';
import { connect } from 'react-redux';
import { ReportsDebt as ReportsDebtView } from '@views';
import { getReportsDebit } from '@context/reportsDebit';
import { router } from '@router';
import { mapPlanIncludes, mapPermissionTo } from '@helpers/connect';
import type { ReportsDebitState, Dispatch, PlanInclude, PermissionTo } from '@types';

type Props = {
  dispatch: Function,
  reportState: ReportsDebitState,
  planInclude: PlanInclude,
  singleDoctor: boolean,
  permissionTo : PermissionTo,
};

class ReportsDebt extends React.Component<Props> {
  componentDidMount() {
    if (this.props.planInclude.financial) {
      this.handleRefresh();
    }
  }

  render() {
    const {
      reportState, planInclude, singleDoctor, permissionTo,
    } = this.props;
    return (
      <ReportsDebtView
        data={get(reportState, 'data')}
        isLoading={get(reportState, 'isLoading', false)}
        onItemClick={this.handleItemClick}
        isFeatureEnabled={planInclude.financial}
        singleDoctor={singleDoctor}
        isReportBlocked={!permissionTo.reports}
      />
    );
  }

  handleItemClick = (id: number) => {
    const { dispatch } = this.props;
    dispatch(router.pushPath('patientFinancial', { id }));
  };

  handleRefresh = () => {
    const { dispatch } = this.props;
    dispatch(getReportsDebit());
  };
}

const mapStateToProps = (state) => ({
  reportState: get(state, ['context', 'reportsDebit'], {}),
  planInclude: mapPlanIncludes(state),
  singleDoctor: get(state, ['context', 'clinics', 'activeClinic', 'singleDoctor'], false),
  permissionTo: mapPermissionTo(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({ dispatch });

export default connect<any, any, any, any, any, any>(mapStateToProps, mapDispatchToProps)(ReportsDebt);
