// @flow
import React, { useMemo, useEffect, useState, useRef, useCallback } from 'react';
import { useDispatch, useStore } from 'react-redux';
import type
{
  Record as RecordType,
  DrugsPrescriptionsState,
  ExamsPrescriptionsState,
  DocumentsState,
  CustomFormsState,
  Patient,
  AttachmentsState,
  CustomFormTemplatesState,
  Attachment,
  PlanInclude,
} from '@types';
import {
  get, map, pick, size, keys, forEach, debounce, isEqual,
} from 'lodash';
import { useIsMobile, useTranslationNs, isPresent, replaceHtmlEntitesToText, handleMomenti18n, UserNotifications, sort, numberCompare, fileExtensionIsExecutable, isCordovaApp, isShareable } from '@helpers/helpers';
import moment from 'moment';

import { Dropdown, ModalClickableList, Button, CustomDayPicker, Icon, DragAndDropFile, Spinner, TextArea } from '@elements';
import classNames from 'classnames';
import { VisibilityLevelIcon, RecordDateHeader } from './mixins';
import RecordDetails from './RecordDetails/RecordDetails';
import { actions } from '@context/modals';
import './Styles/FileAttachment.scss';
import './Styles/Lightbox.scss';
import './Styles/Record.scss';
import './Styles/RecordBlocked.scss';
import './Styles/RecordCanceled.scss';
import './Styles/RecordCard.scss';
import './Styles/RecordDetails.scss';
import './Styles/RecordPrescription.scss';
import './Styles/MobileExcluise.scss';


type AttachmentActionItem = {
  text: string,
  action: string,
};

type VisibilityLevelItemType = {
  level: number,
  text: string,
  rightIcon?: string,
  leftIcon?: string,
  disabled?: boolean,
};

type Props = {
    onDirectShare: (recordId: number, resource: string, resourceId: number, respondWithUrls: (wtt: string | null, mail: string | null) => void) => void,
    attachments: AttachmentsState,
    onPrintDocumentClick?: Function,
    onPrintPrescriptionDrugsClick?: Function,
    onPrintPrescriptionExamsClick: Function,
    onPrintCustomFormClick?: Function,
    record: RecordType,
    patient: Patient,
    clinicId: number,
    userEmail: string,
    drugsPrescriptions: DrugsPrescriptionsState,
    examsPrescriptions: ExamsPrescriptionsState,
    documents: DocumentsState,
    customForms: CustomFormsState,
    onChangeVisibilityLevel: Function,
    onDeleteRecord: Function,
    onSelectFile: Function,
    onPrintSingleRecord: Function,
    onPrintAllRecords: Function,
    onDeleteAttachment: Function,
    onToggleEditMode: Function,
    onUpdateRecordDate: Function,
    customFormTemplates: CustomFormTemplatesState,
    mainSection: any,
    onNewDocumentClick: Function,
    onNewPrescriptionDrugsClick: Function,
    onNewPrescriptionExamsClick: Function,
    onNewCustomFormClick: Function,
    onDisplayAttachmentFileName: Function,
    showPDF: Function,
    onPrintMemedPrescription: Function,
    memedToken: string,
    isEdit: boolean,
    // Edit
    planInclude?: PlanInclude,
    singleUser?: boolean,
    experimentalFeatures: boolean,
    onEditCustomFormClick?: Function,
    onEditPrescriptionDrugsClick?: Function,
    onEditPrescriptionExamsClick?: Function,
    onEditDocumentClick?: Function,
    onDownloadAttachment: Function,

    isDeleting?: boolean,
    isNew?: boolean,
    isSaving?: boolean,
    isAutoSaving?: boolean,
    isMemedLoaded?: boolean,
    onSaveRecord?: Function,
    onAddMemedId?: Function,
    onDeleteMemedId?: Function,
    onAutosaveRecord?: Function,
    onPrintMemedPrescription?: Function,
};

const presentRecordDate = (record: any) => isPresent(get(record, 'date'));

const limitName = (name: string, limit = 50) => (String(name).trim().length > limit ? `${String(name).substr(0, limit).trim()}...` : name);

const RecordElement = ({
  record, onPrintDocumentClick, onSelectFile, onPrintSingleRecord, onPrintPrescriptionExamsClick, onPrintPrescriptionDrugsClick,
  attachments, onPrintMemedPrescription, onPrintCustomFormClick, onPrintAllRecords, onNewPrescriptionExamsClick, onNewPrescriptionDrugsClick,
  customFormTemplates, onNewDocumentClick, onNewCustomFormClick, onDeleteRecord, onDeleteAttachment, onChangeVisibilityLevel,
  customForms, memedToken, mainSection, examsPrescriptions, drugsPrescriptions, documents, patient, clinicId, userEmail,
  onToggleEditMode, showPDF, isEdit, planInclude,
  onEditCustomFormClick, onEditPrescriptionDrugsClick, onEditPrescriptionExamsClick, onEditDocumentClick, isDeleting, isMemedLoaded, onAddMemedId,
  onDeleteMemedId, isSaving, isAutoSaving, onSaveRecord, onAutosaveRecord, isNew, singleUser, onDownloadAttachment,
  onUpdateRecordDate, onDirectShare, experimentalFeatures,
}: Props) => {
  const [isMobile, isIpad] = useIsMobile();
  const systemDialogEl = useRef(null);
  const contentText = useRef(replaceHtmlEntitesToText(get(record, ['details', 'content'], '') || ''));

  const [autoSave, setAutoSave] = useState(false);
  const [isDaypickerOpen, setIsDaypickerOpen] = useState(false);
  const [showMobileContentMenu, setShowMobileContentMenu] = useState(false);
  const [selectedDate, setSelectedDate] = useState(presentRecordDate(record) ? moment(get(record, ['date'])) : moment());
  const [tempSelectedDate, setTempSelectedDate] = useState(presentRecordDate(record) ? moment(get(record, ['date'])) : moment());

  const tplanInclude = planInclude || {};

  const dispatch = useDispatch();

  const store = useStore();
  const accessToken = store.getState().auth.token;

  useEffect(() => {
    setIsDaypickerOpen(false);
    setShowMobileContentMenu(false);
    setSelectedDate(presentRecordDate(record) ? moment(get(record, 'date')) : moment());
    setTempSelectedDate(presentRecordDate(record) ? moment(get(record, 'date')) : moment());
    contentText.current = replaceHtmlEntitesToText(get(record, ['details', 'content'], ''));
    return () => {};
    // eslint-disable-next-line
  }, []);

  useEffect(() => () => {
    if (autoSave) {
      setAutoSave(false);
    }
  }, [isSaving, autoSave]);

  // ========================== Basic initializations and vars ==========================
  const [_, i18n] = useTranslationNs('timeline');

  handleMomenti18n(i18n, moment);

  const appointmentNote = useMemo(() => get(record, ['appointmentData', 'appointmentNotes']), [record]);
  const detailsContent = useMemo(() => get(record, ['details', 'content']), [record]);
  const visibilityLevelIsEditable = useMemo(() => get(record, ['details', 'visibilityLevelIsEditable']), [record]);
  const permissionClasses = useMemo(() => (visibilityLevelIsEditable ? 'permission' : 'permission disabled'), [visibilityLevelIsEditable]);

  const selectedVisibility = useCallback((level: number) =>
    (parseInt(level, 10) === parseInt(get(record, 'visibilityLevel'), 10) ? 'selectedVisibilityLevelItem' : ''), [record]);

  // ========================== Details Variables ==========================

  // retrive only those drugs prescriptions which are associated with this particular record
  const drugsPrescriptionsIds = useMemo(() => get(record, ['details', 'drugsPrescriptions'], []), [record]);
  const drugsPrescriptionsContent = useMemo(() => drugsPrescriptions && drugsPrescriptions.data && pick(drugsPrescriptions.data, drugsPrescriptionsIds), [drugsPrescriptions, drugsPrescriptionsIds]);

  // retrive only those exams prescriptions which are associated with this particular record
  const examsPrescriptionsIds = useMemo(() => get(record, ['details', 'examsPrescriptions'], []), [record]);
  const examsPrescriptionsContent = useMemo(() => examsPrescriptions && examsPrescriptions.data && pick(examsPrescriptions.data, examsPrescriptionsIds), [examsPrescriptions, examsPrescriptionsIds]);

  // retrive only those documents which are associated with this particular record
  const documentsIds = useMemo(() => get(record, ['details', 'documents'], []), [record]);
  const documentsContent = useMemo(() => documents && documents.data && pick(documents.data, documentsIds), [documents, documentsIds]);

  // retrive only those custom forms which are associated with this particular record
  const customFormsIds = useMemo(() => get(record, ['details', 'customForms'], []), [record]);
  const customFormsContent = useMemo(() => customForms && customForms.data && pick(customForms.data, customFormsIds), [customFormsIds, customForms]);

  const recordFilesIds = useMemo(() => get(record, ['details', 'fileAttachments'], []), [record]);
  const recordFiles = useMemo(() => map(recordFilesIds, (recordId) => (
    get(attachments, ['data', recordId], {})
  )), [recordFilesIds, attachments]);

  const tempFiles = useMemo(() => get(record, ['tempFiles', 'fileAttachments']), [record]);
  const showFileAttachments = useMemo(() => size(tempFiles) > 0 || recordFilesIds.length > 0, [tempFiles, recordFilesIds]);

  const fileAttachmentDropdownList = useMemo(() => [
    ...(window.isCordovaApp ? [{ text: _('action/share'), action: 'download' }] : []),
    ...(isEdit ? [{ text: 'Deletar', action: 'delete' }] : []),
  ], [_, isEdit]);

  // ========================== Images handling ==========================
  const recordImagesIds = useMemo(() => get(record, ['details', 'imageAttachments'], []), [record]);

  const recordImages = useMemo(() => sort(map(recordImagesIds, (recordId) => (
    get(attachments, ['data', recordId], {})
  )), numberCompare('id')), [attachments, recordImagesIds]);

  const tempImages = useMemo(() => get(record, ['tempFiles', 'imageAttachments']), [record]);
  const showImageAttachments = useMemo(() => size(tempImages) > 0 || recordImagesIds.length > 0, [tempImages, recordImagesIds]);

  const handleDownloadAttachment = useCallback((attachmentId: number, callback: Function = () => {}, disableShare: boolean = false) => {
    onDownloadAttachment(record.id, attachmentId, callback, disableShare);
  }, [onDownloadAttachment, record]);

  // ========================== Simplifications ==========================

  const recordSpl = useMemo(() => ({
    isEditable: get(record, ['details', 'isEditable'], false),
    isMine: get(record, ['details', 'isMine'], false),
    highlightEdit: get(record, ['details', 'highlightEdit'], false),
    fromMigration: get(record, 'fromMigration', false),
    actualDate: moment(record.date).format('DD/MM/YYYY'),
    initialDate: moment(record.createdAt).format('DD/MM/YYYY'),
  }), [record]);

  // ========================== Lists ==========================

  const visibilitLevelItems = useMemo(() => [
    {
      text: _('action/visibility/label'), break: true, className: 'headingDropDownRecord', hideMobile: true,
    },
    {
      level: 3, text: _('action/visibility/all'), leftIcon: 'earth', className: selectedVisibility(3),
    },
    {
      level: 2, text: _('action/visibility/only_doctors'), leftIcon: 'account-multiple', className: selectedVisibility(2),
    },
    {
      level: 1, text: _('action/visibility/only_me'), leftIcon: 'lock', className: selectedVisibility(1),
    },
  ], [selectedVisibility, _]);

  const optionsDropdownList = [
    ...(recordSpl.isEditable ? [{ text: _('action/change_date'), action: 'edit-record-date' }] : []),
    { text: _(window.isCordovaApp ? 'action/print_record_share' : 'action/print_record'), action: 'print-single-record', id: record.id },
    // ...(recordSpl.fromMigration ? [] : [{ text: _(window.isCordovaApp ? 'action/print_full_record_share' : 'action/print_full_record'), action: 'print-all-records' }]),
    ...(recordSpl.isMine ? [{ text: _('action/delete_record'), action: 'delete-record', id: record.id }] : []),
  ];

  const templateIds = keys(get(customFormTemplates, 'data'));

  const customFormsDropdownList = map<any, any>(templateIds, (templateId) => {
    const template = get(customFormTemplates, ['data', templateId]);
    return {
      templateId,
      text: limitName(template.name),
      action: 'new-custom-form',
    };
  });

  const memedLinkInBackEndApp = useCallback(() => {
    let baseUrl = '';

    if (process.env.NODE_ENV === 'development') {
      baseUrl = 'http://localhost:3000';
    } else if (process.env.NODE_ENV === 'staging') {
      baseUrl = 'https://staging-api.doutore.com';
    } else {
      baseUrl = 'https://beta.doutore.com';
    }

    return `${baseUrl}/dashboard/access?access_token=${accessToken}}&redirect_to=${baseUrl}`;
  }, [accessToken]);

  const prescriptionsDropdownList: Object[] = [
    ...(tplanInclude.memed ? [{
      text: <span>{_('action/content_add/memed')}&nbsp;{!isMemedLoaded && <Spinner />}</span>,
      action: 'memed',
    }] : []),
    ...(tplanInclude.newMemed ? [{
      text: `${_('action/content_add/memed')} 'Beta'`,
      externalLinkTo: `${memedLinkInBackEndApp()}/app/clinics/${clinicId}/patients/${record.patientId}/records/${record.id}/memed_prescriptions/new`,
    }] : []),
    { text: _('action/content_add/receipts'), action: 'new-drugs-prescription' },
    { text: _('action/content_add/exam'), action: 'new-exams-prescription' },
    { text: _('action/content_add/docs'), action: 'new-document' },
  ];

  const customFormHeading: any = useMemo(() => (get(customFormsDropdownList, 'length') > 0
    ? [{ text: _('action/content_add/forms'), action: '', className: 'headingSeparator' }] : []
  ), [customFormsDropdownList, _]);

  const shouldRenderActions = (recordSpl.isEditable && (tplanInclude.customForms || tplanInclude.examsDrugsDocuments || tplanInclude.attachments));

  const mobileContentMenuActions = useMemo(() => {
    const items = [];

    if (tplanInclude.attachments) {
      items.push(
        { text: _('action/content_add/attachments'), action: '', className: 'headingSeparator' },
        { text: _('action/content_add/camera'), action: 'attach_cam' },
        { text: _('action/content_add/from_files'), action: 'attach_file' },
      );
    }

    if (tplanInclude.examsDrugsDocuments) {
      items.push(
        { text: _('action/content_add/receipts'), action: '', className: 'headingSeparator' },
      );

      if (tplanInclude.newMemed) {
        items.push({
          text: `${_('action/content_add/memed')} 'Beta'`,
          externalLinkTo: `${memedLinkInBackEndApp()}/app/clinics/${clinicId}/patients/${record.patientId}/records/${record.id}/memed_prescriptions/new`,
        });
      }

      items.push(
        { text: _('action/content_add/prescription'), action: 'add_prescription' },
        { text: _('action/content_add/exam'), action: 'add_exam' },
        { text: _('action/content_add/docs'), action: 'add_documents' },
      );
    }

    if (tplanInclude.customForms && get(customFormsDropdownList, 'length') > 0) {
      items.push(
        ...customFormHeading,
        ...customFormsDropdownList,
      );
    }

    return items;
  }, [_, customFormHeading, customFormsDropdownList, tplanInclude.attachments, tplanInclude.customForms, tplanInclude.examsDrugsDocuments, tplanInclude.newMemed, record, memedLinkInBackEndApp, clinicId]);

  // ========================== Callbacks ==========================

  const onGetImageSuccess = useCallback((imageUrl: string) => {
    const imgSrc = 'data:image/jpeg;base64,' + imageUrl;

    let file;
    fetch(imgSrc)
      .then(res => res.blob())
      .then(blob => {
        const recordId = get(record, 'id');
        const fileExtension = blob.type.split('/')[1];
        file = new W3C_File([blob], `record_attachment_${Date.now()}.${fileExtension}`, { type: blob.type });

        if (recordId && (blob.type === 'image/png' || blob.type === 'image/jpg' || blob.type === 'image/jpeg' || blob.type === 'image/jfif')) {
          const img = new Image();
          img.src = imgSrc;
          img.onload = () => {
            file.width = img.naturalWidth;
            file.height = img.naturalHeight;
            onSelectFile(recordId, file, 'imageAttachments');
          };
        } else {
          UserNotifications.info('info/file_not_allowed');
        }
      });
  }, [record, onSelectFile]);

  const onGetImageFail = useCallback((message: string) => {
    // eslint-disable-next-line
    console.log('Image fetch failed because: ' + message);
  }, []);

  const onGetImageFromCamera = useCallback(() => {
    navigator.camera.getPicture(onGetImageSuccess, onGetImageFail, {
      quality: 80,
      destinationType: navigator.camera.DestinationType.DATA_URL,
      encodingType: navigator.camera.EncodingType.JPEG,
      targetHeight: 1024,
      targetWidth: 1024,
      correctOrientation: true,
    });
  }, [onGetImageSuccess, onGetImageFail]);

  const hideMobileContentMenuItemMenu = useCallback(() => {
    setShowMobileContentMenu(false);
  }, []);

  const showMobileContentMenuItemMenu = useCallback(() => {
    setShowMobileContentMenu(true);
  }, []);

  const handleOpenSystemDialog = useCallback(() => {
    if (systemDialogEl.current) {
      systemDialogEl.current.click();
    }
  }, [systemDialogEl]);

  const handleMobileContentMenuItemClick = useCallback((item: Object) => {
    switch (item.action) {
      case 'attach_cam': onGetImageFromCamera(); break;
      case 'attach_file': handleOpenSystemDialog(); break;
      case 'add_prescription': onNewPrescriptionDrugsClick({ recordId: record.id, patientName: patient.name }); break;
      case 'add_exam': onNewPrescriptionExamsClick({ recordId: record.id, patientName: patient.name }); break;
      case 'add_documents': onNewDocumentClick({ recordId: record.id }); break;
      case 'new-custom-form': onNewCustomFormClick({ recordId: record.id, templateId: item.templateId }); break;
      default: break;
    }
    hideMobileContentMenuItemMenu();
  }, [
    hideMobileContentMenuItemMenu, onGetImageFromCamera,
    handleOpenSystemDialog, onNewPrescriptionDrugsClick, record.id, patient.name, onNewPrescriptionExamsClick, onNewDocumentClick, onNewCustomFormClick,
  ]);

  const handleOpenPicker = useCallback(() => {
    setIsDaypickerOpen(true);
  }, []);

  const printSimplifiedRecord = useCallback((withSignature: boolean) => () => {
    onPrintSingleRecord(record.id, true, withSignature);
  }, [onPrintSingleRecord, record]);

  const onSelectRecordOption = useCallback((item: Object) => {
    switch (item.action) {
      case 'edit-record-date':
        if (record.appointmentData) {
          UserNotifications.recordFriendlyAlert('info/finalize_record_tip/change_date/with_appointment', 'ban', '#666666');
        } else {
          handleOpenPicker();
        }
        break;
      case 'delete-record':
        UserNotifications.confirmI18n('alert/delete/record', 'warning', '#FFA433', true).then(deleteConfirm => {
          if (deleteConfirm) {
            onDeleteRecord(item.id);
          }
        });
        break;
      case 'print-single-record':
        if (isEdit) {
          UserNotifications.recordFriendlyAlert('info/finalize_record_tip/print');
        } else {
          onPrintSingleRecord(item.id, false);
        }
        break;
      case 'print-single-record-simple':
        if (isEdit) {
          UserNotifications.recordFriendlyAlert('info/finalize_record_tip/print');
        } else {
          onPrintSingleRecord(item.id, true);
        }
        break;
      case 'print-all-records':
        if (isEdit) {
          UserNotifications.recordFriendlyAlert('info/finalize_record_tip/print');
        } else {
          onPrintAllRecords(patient.id);
        }
        break;
      default:
        break;
    }
  }, [record.appointmentData, isEdit, handleOpenPicker, onDeleteRecord, onPrintSingleRecord, onPrintAllRecords, patient.id]);

  const handleSelectDate = useCallback((date: string) => {
    const day = moment(date).date();
    const month = moment(date).month();
    const year = moment(date).year();
    setTempSelectedDate(moment([year, month, day]));
  }, []);

  const handleCancelDayPicker = useCallback(() => {
    setTempSelectedDate(selectedDate);
    setIsDaypickerOpen(false);
  }, [selectedDate]);

  const handleAcceptDayPicker = useCallback(() => {
    onUpdateRecordDate(record.id, tempSelectedDate.toISOString());

    setSelectedDate(tempSelectedDate);
    setIsDaypickerOpen(false);

    return tempSelectedDate;
  }, [record, tempSelectedDate, onUpdateRecordDate]);

  const onStartButtonClick = useCallback(() => {
    onToggleEditMode(get(record, 'id'), true);
  }, [onToggleEditMode, record]);

  const handleDeleteAttachment = useCallback((attachmentId: number, disableNotification: boolean = false) => () => {
    if (!disableNotification) {
      UserNotifications.confirmI18n('alert/confirm_delete_file', 'warning', '#FFA433', true)
        .then((value) => {
          if (value) {
            onDeleteAttachment(record.id, attachmentId);
          }
        });
    } else {
      onDeleteAttachment(record.id, attachmentId);
    }
  }, [onDeleteAttachment, record.id]);

  const onSelectFileAction = useCallback((file: Attachment) => (item: AttachmentActionItem) => {
    if (item.action === 'share') {
      handleDownloadAttachment(file.id, (downloadUrl) => {
        if ((downloadUrl && !isCordovaApp()) || (downloadUrl && isShareable(file.name))) {
          const win = window.open(downloadUrl, '_blank');
          if (win) {
            win.focus();
          }
        }
      });
    }

    if (item.action === 'showPDF') {
      handleDownloadAttachment(file.id, (downloadUrl) => {
        if (downloadUrl) {
          showPDF(downloadUrl, file.name)();
        }
      }, true);
    }

    if (item.action === 'delete') {
      UserNotifications.confirmI18n('alert/confirm_delete_file', 'warning', '#FFA433', true)
        .then((value) => {
          if (value) {
            onDeleteAttachment(record.id, file.id);
          }
        });
    }
  }, [showPDF, onDeleteAttachment, record.id, handleDownloadAttachment]);

  const handlePrintDrugsPrescriptionClick = useCallback((recordId: number, prescriptionId: number, showSignature: boolean, endEmail: boolean) => () => {
    if (onPrintPrescriptionDrugsClick) {
      onPrintPrescriptionDrugsClick(recordId, prescriptionId, showSignature, endEmail);
    }
  }, [onPrintPrescriptionDrugsClick]);

  const handlePrintExamsPrescriptionClick = useCallback((recordId: number, prescriptionId: number, showSignature: boolean, sendEmail: boolean) => () => {
    if (onPrintPrescriptionExamsClick) {
      onPrintPrescriptionExamsClick(recordId, prescriptionId, showSignature, sendEmail);
    }
  }, [onPrintPrescriptionExamsClick]);

  const handlePrintCustomFormClick = useCallback((recordId: number, customFormId: number) => () => {
    if (onPrintCustomFormClick) {
      onPrintCustomFormClick(recordId, customFormId);
    }
  }, [onPrintCustomFormClick]);

  const handlePrintDocumentClick = useCallback((recordId: number, documentId: number, showSignature: boolean, sendEmail: boolean) => () => {
    if (onPrintDocumentClick) {
      onPrintDocumentClick(recordId, documentId, showSignature, sendEmail);
    }
  }, [onPrintDocumentClick]);

  const handleEditCustomFormClick = useCallback((recordId: number, customFormId: number) => () => {
    if (onEditCustomFormClick) {
      onEditCustomFormClick({ recordId, customFormId });
    }
  }, [onEditCustomFormClick]);

  const handleEditDrugsPrescriptionClick = useCallback((recordId: number, prescriptionId: number, patientName: string) => () => {
    if (onEditPrescriptionDrugsClick) {
      onEditPrescriptionDrugsClick({ recordId, prescriptionId, patientName });
    }
  }, [onEditPrescriptionDrugsClick]);

  const handleEditExamsPrescriptionClick = useCallback((recordId: number, prescriptionId: number, patientName: string) => () => {
    if (onEditPrescriptionExamsClick) {
      onEditPrescriptionExamsClick({ recordId, prescriptionId, patientName });
    }
  }, [onEditPrescriptionExamsClick]);

  const handleEditDocumentClick = useCallback((recordId: number, documentId: number) => () => {
    if (onEditDocumentClick) {
      onEditDocumentClick({ recordId, documentId });
    }
  }, [onEditDocumentClick]);

  const handleChangeVisibilityLevel = useCallback((visibilityLevelItem: VisibilityLevelItemType) => {
    onChangeVisibilityLevel(record.id, visibilityLevelItem.level);
  }, [onChangeVisibilityLevel, record.id]);

  const handleDropFiles = useCallback((fileList: Array<Object>) => {
    forEach(fileList, file => {
      if (file.type === 'image/png' || file.type === 'image/jpg' || file.type === 'image/jpeg' || file.type === 'image/jfif') {
        // eslint-disable-next-line
        const img = new Image();
        img.src = window.URL.createObjectURL(file);
        img.onload = () => {
          file.width = img.naturalWidth;
          file.height = img.naturalHeight;
        };
        onSelectFile(record.id, file, 'imageAttachments');
      } else if (!fileExtensionIsExecutable(file.name)) {
        onSelectFile(record.id, file, 'fileAttachments');
      } else {
        UserNotifications.info('info/file_not_allowed');
      }
    });
  }, [onSelectFile, record.id]);

  const handleSelectFile = useCallback((e: Object) => {
    e.stopPropagation();
    e.preventDefault();

    const selectedFiles = e.target.files;

    map(selectedFiles, (file) => {
      if (file.type === 'image/png' || file.type === 'image/jpg' || file.type === 'image/jpeg' || file.type === 'image/jfif') {
        // eslint-disable-next-line
        // const img = new Image();
        // img.src = window.URL.createObjectURL(file);
        // img.onload = () => {
        //   file.width = img.naturalWidth;
        //   file.height = img.naturalHeight;
        // };
        onSelectFile(record.id, file, 'imageAttachments');
      } else if (!fileExtensionIsExecutable(file.name)) {
        onSelectFile(record.id, file, 'fileAttachments');
      } else {
        UserNotifications.info('info/file_not_allowed');
      }
    });
  }, [onSelectFile, record.id]);

  const handleSaveMemed = ((integrationId: number, prescription: Object) => {
    if (onAddMemedId && isMemedLoaded) {
      onAddMemedId(record.id, integrationId, prescription);
    }
  });

  const handleDeleteMemed = ((integrationId: number) => {
    if (onDeleteMemedId) {
      onDeleteMemedId(record.id, integrationId);
    }
  });

  const handleMemedPrescription = useCallback(() => {
    if (!isMemedLoaded) return;

    const memedPrescriptionData = {
      patient,
      record,
      saveCallBack: handleSaveMemed,
      onDelete: handleDeleteMemed,
    };

    dispatch(actions.openMemedModal(memedPrescriptionData));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMemedLoaded, patient, record]);

  const handleActionsDropdownClick = useCallback((item: any) => {
    switch (item.action) {
      case 'new-document':
        onNewDocumentClick({ recordId: record.id });
        break;
      case 'new-drugs-prescription':
        onNewPrescriptionDrugsClick({ recordId: record.id, patientName: patient.name });
        break;
      case 'new-exams-prescription':
        onNewPrescriptionExamsClick({ recordId: record.id, patientName: patient.name });
        break;
      case 'new-custom-form':
        onNewCustomFormClick({ recordId: record.id, templateId: item.templateId });
        break;
      case 'memed':
        handleMemedPrescription();
        break;
      default:
        break;
    }
  }, [onNewDocumentClick, record.id, onNewPrescriptionDrugsClick, patient.name, onNewPrescriptionExamsClick, onNewCustomFormClick, handleMemedPrescription]);


  const handleSaveRecord = useCallback(() => {
    const tmpRecord = {
      ...record,
      content: contentText.current,
      content_draft: null,
      visibility_level: get(record, 'visibilityLevel'),
    };

    if (onSaveRecord) {
      onSaveRecord(tmpRecord);
    }
    setAutoSave(true);
  }, [onSaveRecord, record]);

  const handleOpenAssistantDoctor = useCallback(() => {
    window.open(`https://app.doutorassistente.com.br/transcriptions/new?user_email=${userEmail}&record_id=${record.id}&patient_name=${patient.name}&patient_gender=${patient.gender || ''}`, '_blank');
  }, [record.id, patient, userEmail]);

  // ========================== Renders ==========================
  const actionsRender = () => [
    <div className={classNames('actions', { ipad: isIpad })} key="action1">
      {isEdit && shouldRenderActions && (
        <>
          <Dropdown
            items={[]}
            placeholder='Adicionar Conteúdo'
            className={isIpad ? 'add-content-action ipad-add-icon' : 'add-content-action'}
            icon='add-circle-outline'
            onClick={showMobileContentMenuItemMenu}
          />
          {experimentalFeatures && (
            <Button
              onClick={handleOpenAssistantDoctor}
              text="Transcrição por IA"
              className="assistant-doctor-button"
            />
          )}
        </>
      )}
      {showMobileContentMenu &&
        <ModalClickableList
          items={mobileContentMenuActions}
          onListItemClick={handleMobileContentMenuItemClick}
          onClose={hideMobileContentMenuItemMenu}
        />
      }
    </div>,
    <div className={classNames('right record-add-action', { ipad2: isIpad })} key="action2">
      { !isMobile && (recordSpl.isEditable && !recordSpl.fromMigration) && <EditButton /> }
      <Dropdown
        className="recordOptions"
        placeholder={_('label/options', 'global')}
        items={optionsDropdownList}
        onSelectItem={onSelectRecordOption}
      />
    </div>];

  const EditButton = ({ className }: Object) => (recordSpl.highlightEdit ? null : (
    <div className={classNames('action', className)}>
      <Button onClick={onStartButtonClick}>
        <Icon iconName="edit" className="icon" size={15} />
        <span>{_('action/edit', 'global')}</span>
      </Button>
    </div>
  ));

  const handleAutoSaveRecord = useCallback((value: (string | number | boolean)) => {
    if (isSaving) return;
    const tmpRecord = {
      ...record,
      content_draft: value,
      visibility_level: get(record, 'visibilityLevel'),
    };

    if (onAutosaveRecord) {
      onAutosaveRecord(tmpRecord);
    }
    setAutoSave(true);
  }, [isSaving, onAutosaveRecord, record]);

  const debouceAutoSave = useRef(debounce(handleAutoSaveRecord, 1000));

  useEffect(() => {
    debouceAutoSave.current = debounce(handleAutoSaveRecord, 1000);
    return () => {
      debouceAutoSave.current = debounce(handleAutoSaveRecord, 1000);
    };
  }, [handleAutoSaveRecord]);

  const handleAutosaveContentText = useCallback((data: Object) => {
    const { value } = data;
    contentText.current = value;
    debouceAutoSave.current(value);
  }, [contentText]);

  // ========================== Details Props ==========================

  const detailsCardProps = {
    appointmentNote,
    detailsContent,
    customFormsContent,
    drugsPrescriptionsContent,
    showFileAttachments,
    tempFiles,
    recordFiles,
    fileAttachmentDropdownList,
    showImageAttachments,
    examsPrescriptionsContent,
    documentsContent,
    record,
    tempImages,
    recordImages,
    onSelectFileAction,
    onPrintCustomFormClick: handlePrintCustomFormClick,
    onPrintDrugsPrescriptionClick: handlePrintDrugsPrescriptionClick,
    onPrintDocumentClick: handlePrintDocumentClick,
    onPrintExamsPrescriptionClick: handlePrintExamsPrescriptionClick,
    mainSection,
    showPDF,
    memedToken,
    onPrintMemedPrescription,
    onDirectShare,
    isMine: recordSpl.isMine,
    canDeleteAttachment: recordSpl.isEditable,
    patient,
  };

  const detailsEditProps = {
    isMine: recordSpl.isMine,
    appointmentNote,
    customFormsContent,
    drugsPrescriptionsContent,
    showFileAttachments,
    tempFiles,
    recordFiles,
    fileAttachmentDropdownList,
    showImageAttachments,
    examsPrescriptionsContent,
    documentsContent,
    record,
    tempImages,
    recordImages,
    onSelectFileAction,
    onPrintCustomFormClick: handlePrintCustomFormClick,
    onPrintDrugsPrescriptionClick: handlePrintDrugsPrescriptionClick,
    onPrintDocumentClick: handlePrintDocumentClick,
    onPrintExamsPrescriptionClick: handlePrintExamsPrescriptionClick,
    isEditable: recordSpl.isEditable,
    onEditCustomFormClick: handleEditCustomFormClick,
    patient,
    onEditDrugsPrescriptionClick: handleEditDrugsPrescriptionClick,
    onEditExamsPrescriptionClick: handleEditExamsPrescriptionClick,
    onEditDocumentClick: handleEditDocumentClick,
    planInclude,
    mainSection,
    showPDF,
    memedToken,
    onPrintMemedPrescription,
    canDeleteAttachment: recordSpl.isEditable,
    onDirectShare,
  };

  // ========================== Content ==========================

  return (
    <div className={classNames('register', { openRegister: isEdit, 'deleting-register': isDeleting, isNewRecord: isNew })}>
      <div style={{ display: 'none' }} id={`recordAnchor-${record.id}`} />
      <input
        id="systemDialogEl"
        type="file"
        multiple
        ref={systemDialogEl}
        style={{ display: 'none' }}
        onChange={handleSelectFile}
      />
      {!singleUser && (
        <div className="doctorTabHolder">
          <div className="doctorTab">
            <span>{record.userName}</span>
            <span className={permissionClasses}>
              <VisibilityLevelIcon visibilityLevel={get(record, 'visibilityLevel')} />
              {visibilityLevelIsEditable && (
                <Dropdown
                  usePortal
                  mobileLabel={_('action/visibility/label')}
                  className="visibilityLevelDropdown"
                  items={visibilitLevelItems}
                  showSelected={false}
                  onSelectItem={handleChangeVisibilityLevel}
                />
              )}
            </span>
          </div>
        </div>
      )}
      <DragAndDropFile onDropFiles={handleDropFiles} disabled={!isEdit || !tplanInclude.attachments}>
        <div className="registerTop">
          <RecordDateHeader
            actualDate={recordSpl.actualDate}
            fromMigration={recordSpl.fromMigration}
            initialDate={recordSpl.initialDate}
            record={record}
          />
          {!isEdit && (
            <React.Fragment>
              {isMobile ? actionsRender().reverse() : actionsRender()}
            </React.Fragment>
          )}

          {isEdit && !isMobile && (
            <div className="right">
              {shouldRenderActions &&
              <div className="actions">
                <label>Adicionar:</label>
                {tplanInclude.attachments &&
                <Button
                  text={_('label/attachment')}
                  onClick={isDeleting ? (() => {}) : handleOpenSystemDialog}
                />
                    }
                {tplanInclude.examsDrugsDocuments &&
                <Dropdown
                  className="prescriptionsDropdown"
                  placeholder={
                    <span className="prescriptionDropdownPlaceholder">
                      {_('label/receipts')}
                    </span>
                  }
                  items={isDeleting && !isMemedLoaded ? [] : prescriptionsDropdownList}
                  onSelectItem={handleActionsDropdownClick}
                />
                    }
                {tplanInclude.customForms && get(customFormsDropdownList, 'length') > 0 && (
                <Dropdown
                  className="customFormsDropdown"
                  placeholder={_('label/forms')}
                  items={isDeleting ? [] : customFormsDropdownList}
                  onSelectItem={handleActionsDropdownClick}
                />
                )}
                {experimentalFeatures && (
                  <Button
                    onClick={handleOpenAssistantDoctor}
                    text="Transcrição por IA"
                    className="assistant-doctor-button"
                  />
                )}
              </div>
                }
              <Dropdown
                className="recordOptions"
                placeholder={_('label/options', 'global')}
                items={isDeleting ? [] : optionsDropdownList}
                onSelectItem={isDeleting ? (() => {}) : onSelectRecordOption}
              />
            </div>
          )}
          {isMobile && isEdit && (
            <React.Fragment>
              {isMobile ? actionsRender().reverse() : actionsRender()}
            </React.Fragment>
          )}
        </div>

        { isMobile && (recordSpl.isEditable && !recordSpl.fromMigration) && !isEdit && <EditButton className="fill-width-edit-btn" />}

        <div className={classNames('registerBody', { editMode: isEdit })}>

          {!isEdit && recordSpl.highlightEdit && (
            <React.Fragment>
              <Button className="startAppointmentBtn primary" text={_('action/start_treatment')} onClick={onStartButtonClick} />
              {/* {!isEmpty(appointmentNote) && (
                <div className="record freeText">
                  <span className="recordTitle">{_('label/appointment_note')}</span>
                  <div className="recordContent">
                    <p>{parseHtml(appointmentNote)}</p>
                  </div>
                </div>
              )} */}
            </React.Fragment>
          )}

          {isEdit && (
            <div className='record recordTextContainer'>
              <div className='recordContent'>
                <TextArea
                  name="contentText"
                  readOnly={isDeleting}
                  placeholder={_('label/write_annotations')}
                  onChange={handleAutosaveContentText}
                  defaultValue={replaceHtmlEntitesToText(contentText.current || '')}
                  uncontrolled
                />
              </div>
            </div>
          )}

          {isEdit && (
            <div className="recordSaveAction">
              <div className='actions'>
                {isAutoSaving && <Spinner text={_('label/saving_simple', 'global')} />}
                <Button
                  text={_('action/finalize_appointment')}
                  className='primary'
                  disabled={isSaving || isAutoSaving}
                  onClick={handleSaveRecord}
                />
              </div>
            </div>
          )}

          { (record.detailsVisible || isEdit) && (
            <RecordDetails
              {...(isEdit ? detailsEditProps : detailsCardProps)}
              handleDeleteAttachment={handleDeleteAttachment}
              handleDownloadAttachment={handleDownloadAttachment}
              onPrintSimplifiedRecord={printSimplifiedRecord}
            />
          )}
        </div>
        <CustomDayPicker
          withOverlay
          showButtons
          advancedMode
          isOpen={isDaypickerOpen}
          onSelectDay={handleSelectDate}
          onMonthChange={handleSelectDate}
          selectedDate={tempSelectedDate || moment()}
          onCancel={handleCancelDayPicker}
          onAccept={handleAcceptDayPicker}
        />
      </DragAndDropFile>
    </div>
  );
};


// $FlowFixMe
export default React.memo(RecordElement, (prev, next) => isEqual(prev, next));
