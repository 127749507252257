// @flow
import React from 'react';
import { map, findIndex, get, toNumber, isEqual } from 'lodash';
import { Icon, Button, Modal, ClickableList, Spinner } from '@elements';
import { sort, alphabeticCompare } from '@helpers/helpers';
import type { Doctor, Doctors } from '@types';

type Props = {
  setModal: Function,
  doctors: Doctors,
  selectedDoctor: number,
  onSetServicesRequest: Function,
  onChange: Function,
  _: Function,
};

const mapRoomsToClickableList = ({ name, id, nameWithTitle }) => ({
  text: nameWithTitle,
  name,
  id,
});

const findselectedDoctorPredicate = (selectedDoctor: number) => (doctor: Doctor) => toNumber(doctor.id) === toNumber(selectedDoctor);

export default class DoctorModal extends React.Component<Props> {
  shouldComponentUpdate(nextProps: Props) {
    return !isEqual(this.props, nextProps);
  }
  render() {
    const {
      selectedDoctor, doctors, _,
    } = this.props;

    const doctorsData = get(doctors, 'data', {});
    const isLoading = get(doctors, 'isLoading', false);
    const doctorItens = sort(map(doctorsData, mapRoomsToClickableList), alphabeticCompare('name'));


    return (
      <Modal id="doctorModal" className="secondaryModal">
        <div className="modalHeader actionHeader">
          <Button className="backButton" onClick={this.handleBack}>
            <Icon iconName="arrow-left" className="icon" />
          </Button>
          <span className="backButtonLabel">{_('label/doctor_select/title')}</span>
        </div>
        {isLoading && <Spinner text={_('label/doctor_select/loading')} /> }
        {!isLoading && (
          <ClickableList
            activeItemIndex={findIndex(doctorItens, findselectedDoctorPredicate(selectedDoctor))}
            items={doctorItens}
            onListItemClick={this.onListItemClick}
          />
        )}
      </Modal>
    );
  }

  onListItemClick = (doctor: Doctor) => {
    this.props.onChange({ name: 'doctorId', value: doctor.id });
    this.props.onSetServicesRequest();
    this.handleBack();
  };

  handleBack = () => this.props.setModal(null);
}
