// @flow
import React from 'react';
import type { Node } from 'react';

type TitleProps = {
  children?: Node,
};
class ReportPageTitle extends React.Component<TitleProps> {
  render() {
    return (
      <section className="toolbar">
        <h2>
          {this.props.children}
        </h2>
      </section>
    );
  }
}

export default ReportPageTitle;
