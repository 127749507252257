// @flow
import { map, filter, forEach } from 'lodash';
import type { Services } from '../types';

function normalizeServiceTemplates (inputServiceTemplates: Services) {
  const serviceTemplates = {};

  forEach(inputServiceTemplates, (serviceTemplate) => {
    serviceTemplates[serviceTemplate.id] = serviceTemplate;
  });
  // here we separate object's types
  let normal = filter(serviceTemplates, item => item.healthInsurance === false);
  let healthInsurance = filter(serviceTemplates, item => item.healthInsurance === true);
  // Map objects to id's
  normal = map<any, any>(normal, item => item.id);
  healthInsurance = map<any, any>(healthInsurance, item => item.id);

  return {
    serviceTemplates,
    normal,
    healthInsurance,
  };
}

export default {
  normalizeServiceTemplates,
};
