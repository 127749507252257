export default {
  'label/new_appointment': 'Nova Consulta',
  'action/refresh': 'Atualizar Agenda',
  'action/all_rooms': 'Todas as Salas',
  'label/all_subfilters': 'Todos',
  'label/calendar_day': 'Dia',
  'label/calendar_week': 'Semana',
  'label/calendar_today': 'Hoje',
  'label/blocked_time': 'Horário Bloqueado',
  'label/hour': 'Hora',
  'action/select_month': 'Selecione o mês',
  'label/duration': 'Duração',
  'action/hide': 'Remover da Agenda',
  'action/delete_only': 'Deletar somente este evento',
  'action/delete_future': 'Deletar todos os eventos futuros',
  'action/schedule_for': 'Agendar para',
  'label/title': 'Título',
  'label/recurring_schedule': 'Agendamento Recorrente',
  'label/status': 'Status',
  'label/whatsApp': 'Confirmar via WhatsApp',
  'label/sendMessage': 'Enviar mensagem',
  'label/contracted_services': 'Serviços Contratados',
  'label/show_obs': 'Mostrar observações no prontuário',
  'label/status/scheduled': 'Consulta Agendada',
  'label/status/sms_sent': 'SMS Enviado',
  'label/status/sms_confirmed': 'Confirmada pelo Paciente',
  'label/status/sms_canceled': 'Cancelada pelo Paciente',
  'label/status/confirmed': 'Consulta Confirmada',
  'label/status/patient_arrived': 'Paciente Chegou',
  'label/status/patient_attended': 'Paciente Atendido',
  'label/status/patient_missed': 'Paciente Faltou a Consulta',
  'label/status/patient_canceled': 'Paciente Cancelou a Consulta',
  'label/status/canceled_by_doctor': 'Consultório Cancelou a Consulta',
  'label/change_status': 'Alterar Status',
  'label/services/empty_edit': 'A consulta não possui serviços',
  'label/services/empty_new': 'O Doutor selecionado não possui serviços',
  'label/services/empty_search': 'Nenhum serviço encontrado para esta busca',
  'label/services/recents': 'Mais Recentes',
  'label/services/private': 'Particular',
  'label/services/health_plan': 'Plano de Saúde',
  'label/services/loading': 'Carregando serviços',
  'action/services/add_services': 'Adicionar serviços',
  'label/patient_vip': 'Paciente com Gratuidade',
  'label/services/service_list': 'Lista de Serviços',
  'action/services/view_selected_services': 'Ver todos os serviços selecionados',
  'label/rooms/select_one_room': 'Selecione uma sala',
  'label/recurrency/recurrent_appointment': 'Agendamento Recorrente',
  'action/recurrency/enable_recurrency': 'Agendamento semanal',
  'action/recurrency/enable_biweekly_recurrency': 'Agendamento quinzenal',
  'label/recurrency/ends_after': 'Termina após',
  'label/recurrency/delete_all_future_events': 'Deletar todos os eventos futuros',
  'label/doctor_select/title': 'Selecione um Profissional',
  'label/doctor_select/loading': 'Carregando doutores',
  'label/patient_select/add_patient': 'Cadastrar novo paciente',
  'label/patient_select/search_patient': 'Buscar paciente',
  'label/patient_select/search_all_patients': 'Todos os pacientes',
  'label/patient_select/reschedule_patients': 'Pacientes com etiqueta reagendar',
  'label/patient_select/loading': 'Carregando pacientes',
  'label/patient_form/name': 'Nome',
  'label/patient_form/birthdate': 'Data de nascimento',
  'label/patient_form/cpf': 'CPF',
  'label/patient_form/legalId': 'Identidade',
  'label/patient_form/mobile_phone': 'Celular',
  'label/patient_form/home_phone': 'Telefone Fixo',
  'label/patient_form/email': 'E-mail',
  'label/patient_form/recommended_by': 'Indicação',
  'label/patient_form/profession': 'Profissão',
  'label/patient_form/placeOfBirth': 'Naturalidade',

  'label/patient_form/maritalStatusCode': 'Estado Civil',
  'label/patient_form/doctorId': 'Responsável',
  'label/patient_form/medic': 'Médico',
  'label/patient_form/mobilePhone': 'Celular',
  'label/patient_form/homePhone': 'Telefone Fixo',
  'label/patient_form/otherPhone': 'Telefone Adicional',
  'label/patient_form/father': 'Pai',
  'label/patient_form/mother': 'Mãe',
  'label/patient_form/guardianName': 'Responsável',
  'label/patient_form/guardianRelationship': 'Parentesco',
  'label/patient_form/insuranceCompany': 'Convênio',
  'label/patient_form/insurancePlan': 'Plano',
  'label/patient_form/insuranceNumber': 'Número da Carteira',
  'label/patient_form/insuranceExpirationDate': 'Data de Validade',

  'label/patient_form/insurance_plan': 'Plano de Saúde',
  'label/patient_form/title': 'Novo Paciente',
  'label/patient_info/title': 'Cadastro Resumido',
  'label/patient_info/responsible': 'Responsável',
  'label/patient_info/other_phone': 'Telefone Adicional',
  'label/appointment_with': 'Consulta com',
  'label/appointment_sms_canceled': 'Consulta cancelada pelo paciente',
  'label/appointment_patient_msg': 'Paciente deixou a Mensagem',

  'label/patient_form/patient_code': 'Código',

  'action/click_to_create_appointment_at': 'Clique para criar consulta ás',
  'action/click_to_create_appointment': 'Clique para criar uma consulta',
};
