// @flow
import React from 'react';
import { connect } from 'react-redux';
import { get } from 'lodash';
import { DocumentFormModal } from '../../components/views';
import { actions } from '../../redux/modules/context/modals';
import { openToast } from '../../redux/modules/context/toasts';
import { documentActions } from '../../redux/modules/context/documents';
import { documentsTemplateActions } from '../../redux/modules/context/documentsTemplates';
import type {
  Dispatch, Document, DocumentInput,
  DocumentsTemplatesState,
} from '../../types';

type Props = {
  recordId: number,
  document: Document,
  documentsTemplates: DocumentsTemplatesState,
  dispatch: Function,
  patientName: string,
  isSaving: boolean,
  isDeleting: boolean
};

class DocumentFormModalContainer extends React.Component<Props> {
  componentWillMount() {
    this.props.dispatch(documentsTemplateActions.getDocumentsTemplates());
  }

  render() {
    const {
      recordId, document, documentsTemplates, patientName, isSaving, isDeleting,
    } = this.props;

    return (
      <DocumentFormModal
        recordId={recordId}
        document={document}
        documentsTemplates={documentsTemplates}
        onAddNewDocument={this.handleCreateDocument}
        onAddNewAndPrintDocument={this.handleCreateAndPrintDocument}
        onUpdateDocument={this.handleUpdateDocument}
        onUpdateAndPrintDocument={this.handleUpdateAndPrintDocument}
        onDeleteDocument={this.handleDeleteDocument}
        onClose={this.closeModal}
        patientName={patientName}
        onCopyToast={this.handleCopyToast}
        isSaving={isSaving}
        isDeleting={isDeleting}
      />
    );
  }
  handleCopyToast=(text: string) => {
    this.props.dispatch(openToast({
      type: 'success',
      text,
      close: true,
      timeout: 2000,
    }));
  };

  closeModal = () => {
    this.props.dispatch(actions.closeActiveModal());
  };

  handleCreateDocument = (recordId: number, document: DocumentInput) => {
    this.props.dispatch(documentActions.createDocument(recordId, document, this.closeModalOnSuccess));
  };

  handleCreateAndPrintDocument = (recordId: number, document: DocumentInput) => {
    this.props.dispatch(documentActions.createAndShowDocumentPDF(recordId, document, this.closeModalOnSuccess));
  };

  handleUpdateDocument = (recordId: number, documentId: number, document: DocumentInput) => {
    this.props.dispatch(documentActions.updateDocument(recordId, documentId, document, this.closeModalOnSuccess));
  };

  handleUpdateAndPrintDocument = (recordId: number, documentId: number, document: DocumentInput) => {
    this.props.dispatch(documentActions.updateAndShowDocumentPDF(recordId, documentId, document, this.closeModalOnSuccess));
  };

  handleDeleteDocument = (recordId: number, documentId: number) => {
    this.props.dispatch(documentActions.deleteDocument(recordId, documentId, this.closeModalOnSuccess));
  };

  closeModalOnSuccess = (success: boolean) => {
    if (success) {
      this.closeModal();
    }
  };
}

const mapStateToProps = (state) => ({
  recordId: state.context.modals.data.recordId,
  patientName: get(state, ['context', 'modals', 'data', 'patientName'], ''),
  document: get(state.context, ['documents', 'data', state.context.modals.data.documentId], {}),
  isSaving: get(state.context, ['documents', 'isSaving'], false),
  isDeleting: get(state.context, ['documents', 'isDeleting'], false),
  documentsTemplates: get(state.context, ['documentsTemplates'], {}),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({ dispatch });

export default connect<any, any, any, any, any, any>(
  mapStateToProps,
  mapDispatchToProps,
)(DocumentFormModalContainer);
