// @flow
import React from 'react';
import { Icon } from '../';
import './Spinner.scss';

type Props = {
  finished?: boolean,
  text?: string,
  fill: string,
  className?: string,
  white?: boolean,
};

export default class Spinner extends React.Component<Props> {
  static defaultProps = {
    fill: 'rgb(0, 0, 0)',
    size: 24,
  };

  render() {
    const {
      finished, text, fill, className, white,
    } = this.props;

    const spinnerIcon = white ? 'spinner-wh' : 'spinner';
    const icon = finished ? 'check-circle' : spinnerIcon;

    return (
      <span className={`element-spinner ${className || ''}`}>
        <Icon iconName={icon} size={18} fill={fill} />
        { text && <span>&nbsp;{text}</span> }
      </span>
    );
  }
}
