// @flow
import React from 'react';
import { get, keys, map } from 'lodash';
import { SettingsMenu, PlatformSwitch, AppMobileHeader, ConditionalFeature, AppMobileFooter } from '@blocks';
import {
  Breadcrumb, Button, ClickableList,
  Loading,
  ProgressBar,
} from '@elements';
import type { DrugsPrescriptionsTemplatesState, User, BundledPermissions } from '@types';
import { sort, alphabeticCompare, createNsTranslator } from '@helpers/helpers';
import { withTranslation } from 'react-i18next';

type Props = {
  drugsPrescriptionsTemplates: DrugsPrescriptionsTemplatesState,
  user: User,
  onLogout: Function,
  t: Function,
  permissions: BundledPermissions,
  featureEnabled: boolean,
};
class DrugsPrescriptionsTemplatesList extends React.Component<Props> {
  render() {
    const {
      drugsPrescriptionsTemplates, user, onLogout, featureEnabled,
    } = this.props;

    const _ = createNsTranslator('settings', this.props.t);

    const breadcrumbLinks = [
      { text: _('label/prescription_menu/title'), linkTo: '/ajustes/modelos' },
      { text: _('label/drugs_prescription_teplates/list/title') },
    ];

    const templateIds = keys(drugsPrescriptionsTemplates.data);
    const templateItems = sort(map(templateIds, (templateId) => {
      const template = get(drugsPrescriptionsTemplates, ['data', templateId]);
      return {
        text: template.name,
        linkTo: `/ajustes/modelos/receituarios/${template.id}`,
      };
    },
    ), alphabeticCompare('text'));

    const templatesLoading = get(drugsPrescriptionsTemplates, ['isLoading']);
    const noTemplates = (!templateItems.length && !templatesLoading);

    let noTemplatesContent;
    if (noTemplates) {
      noTemplatesContent = (
        <div>
          <h1 className='noResultsMessage'>
            {_('label/drugs_prescription_teplates/list/empty_title')}
          </h1>
          <span>{_('label/drugs_prescription_teplates/list/empty_hint')}</span>
        </div>
      );
    } else if (templatesLoading && templateItems.length === 0) {
      noTemplatesContent = (
        <div className='loadingWrapper'>
          <Loading />
        </div>
      );
    }

    const pageContent = (
      <React.Fragment>
        <div className="toolbar dashed">
          <h1>{_('label/drugs_prescription_teplates/list/title')}</h1>
          <Button
            className="primary"
            text={_('label/drugs_prescription_teplates/list/new')}
            linkTo="/ajustes/modelos/receituarios/novo"
          />
        </div>
        {noTemplatesContent}
        {templateItems.length > 0 && (
          <ClickableList
            items={templateItems}
            card
          />
        )}
      </React.Fragment>
    );

    return (
      <PlatformSwitch
        inject
        desktop={() => (
          <div id="drugsPrescriptionsTemplatesList" className="appContent settingsPage">
            {!noTemplates && templatesLoading && <ProgressBar show />}
            <aside className="sidebar">
              <SettingsMenu user={user} onLogout={onLogout} permissions={this.props.permissions} />
            </aside>
            <section className="mainSection">
              <ConditionalFeature enabled={featureEnabled}>
                <div className="centerContent">
                  <Breadcrumb items={breadcrumbLinks} />
                  {pageContent}
                </div>
              </ConditionalFeature>
            </section>
          </div>
        )}
        mobile={() => (
          <React.Fragment>
            <AppMobileHeader title={_('label/drugs_prescription_teplates/list/title')} backLinkTo="/ajustes/modelos" />
            <div id="drugsPrescriptionsTemplatesList" className="appContent settingsPage">
              {!noTemplates && templatesLoading && <ProgressBar show />}
              <section className="mainSection">
                <ConditionalFeature enabled={featureEnabled}>
                  <div className="centerContent">{pageContent}</div>
                </ConditionalFeature>
              </section>
            </div>
            <AppMobileFooter />
          </React.Fragment>
        )}
      />
    );
  }
}

export default withTranslation()(DrugsPrescriptionsTemplatesList);
