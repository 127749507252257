// @flow
import React, { useMemo } from 'react';
import { get, size } from 'lodash';
import { Loading, FormattedText, Table } from '@elements';
import type { ReceiptsState } from '@types';
import { sort, useTranslationNs } from '@helpers/helpers';

type Props = {
    receipts: ReceiptsState,
    maxListItens: number,
    handleShowReceiptClick: Function,
    singleDoctor: boolean,
};

const PatientFinancialReceipts = ({
  receipts, maxListItens, handleShowReceiptClick,
  singleDoctor,
}: Props) => {
  const [_] = useTranslationNs('financial');

  const receiptsData = sort(get(receipts, ['data'], {}), (a, b) => b.id - a.id);

  const onItemClick = (receipt) => handleShowReceiptClick(receipt.id)();


  const columns = useMemo(() => [
    {
      dataIndex: 'number',
      content: _('label/number', 'global'),
    },
    {
      dataIndex: 'date',
      content: _('label/date', 'global'),
      onRenderItem: (receipt) => <FormattedText type="date" text={receipt.date} />,
    },
    {
      dataIndex: 'doctorName',
      content: _('label/doctor', 'global'),
      hidden: singleDoctor,
    },
    {
      dataIndex: 'price',
      content: _('label/value', 'global'),
      onRenderItem: (receipt) => <FormattedText type="currency" text={receipt.price} />,
    },
  ], [_, singleDoctor]);

  if (get(receipts, 'isLoading') && size(get(receipts, 'data', [])) === 0) {
    return <div className={window.isCordovaApp ? 'mobileLoaderFinacialFix' : ''}><Loading /></div>;
  }

  return (
    <Table
      enablePagination
      itemKey="id"
      limit={maxListItens}
      columns={columns}
      onItemClick={onItemClick}
      items={receiptsData}
      mobileClickLabel="Editar"
      emptyMessage={_('label/payments_empty/title')}
    />
  );
};

// $FlowFixMe
export default React.memo(PatientFinancialReceipts);
