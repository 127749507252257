// @flow
import React from 'react';
import { map, includes, difference, isEqual, get, find, filter, toNumber } from 'lodash';
import { isPresent, sort, alphabeticCompare, numberCompare, createNsTranslator, localizeListItems, translateConstant } from '@helpers/helpers';
import { professionCodes } from '@constants';
import { ClickableList, Checkbox, Tooltip, Icon } from '@elements';
import type { User, PermissionTo } from '@types';
import { withTranslation } from 'react-i18next';
import './AgendaFilters.scss';


type Room = {
  number: number,
  name: string,
};

type State = {
  selectedRooms: Array<number>,
  selectedDoctors: Array<number>,
};

type Props = {
  rooms: Array<Room>,
  doctors: Array<Object>,
  defaultRooms: Array<number>,
  defaultDoctorId: Array<number>,
  onFiltersChange: Function,
  isLoading: boolean,
  // eslint-disable-next-line
  highPrivacy: boolean,
  user: User,
  orderDoctorsByProfession: boolean,
  t: Function,
  permissions: PermissionTo,
};

// const stAllRoomsIds = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15];

const createProfessionTile = (code: number | string, selectAll: boolean) => ({
  className: 'headingSeparator',
  customRendererData: {
    text: get(find(localizeListItems(translateConstant(professionCodes)), it => it.value === parseInt(code, 10)), 'text', 'unknown'),
    group: code,
    type: 'customHeading',
    selectAll,
  },
});

class AgendaFilters extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      selectedRooms: props.defaultRooms,
      selectedDoctors: props.defaultDoctorId,
    };
  }

  componentWillReceiveProps(nextProps: Props) {
    if (!isEqual(this.state.selectedRooms, nextProps.defaultRooms)) {
      this.setState({ selectedRooms: nextProps.defaultRooms });
    }
    if (!isEqual(this.state.selectedDoctors, nextProps.defaultDoctorId)) {
      this.setState({ selectedDoctors: nextProps.defaultDoctorId });
    }
  }

  shouldComponentUpdate(nextProps: Props, nextState: State) {
    return !isEqual(this.state, nextState) || !isEqual(this.props, nextProps);
  }

  render() {
    const {
      rooms, doctors, user, orderDoctorsByProfession, permissions,
    } = this.props;
    const { selectedRooms, selectedDoctors } = this.state;

    const _ = createNsTranslator('agenda', this.props.t);

    const doctorId = get(user, ['advancedData', 'doctorId']);

    const ordernedRooms = sort(rooms, numberCompare('number'));

    const allRoomsIds = map<any, any>(ordernedRooms, (room) => (room.number));
    const allDoctorsIds = map<any, any>(doctors, (doctor) => (get(doctor, 'id', '')));

    const allRoomsItem = {
      customRendererData: {
        key: 'all-rooms-1',
        label: _('action/all_rooms'),
        value: 'all',
        type: 'rooms',
        isChecked: difference(allRoomsIds, selectedRooms).length === 0,
      },
    };

    const allDoctorsItem = {
      customRendererData: {
        key: 'all-doctors-1',
        label: _('action/all_doctors', 'global'),
        value: 'all',
        type: 'doctors',
        isChecked: difference(allDoctorsIds, selectedDoctors).length === 0,
      },
    };

    const items = [];

    if (allRoomsIds.length > 1 && permissions.showRooms) {
      items.push(allRoomsItem);


      map(ordernedRooms, (room) => {
        const item = {
          customRendererData: {
            key: room.number,
            label: room.name,
            value: room.number,
            type: 'rooms',
            isChecked: includes(selectedRooms, room.number),
          },
        };
        items.push(item);
      });
    }

    const doctorsHeader = {
      type: 'heading',
      text: _('label/doctors', 'global'),
    };

    const pushDoctors = (doctorsItemsList) => map(doctorsItemsList, (doctor) => {
      if (permissions.singleDoctor) {
        return;
      }

      const doctorItemId = get(doctor, 'id', '');
      const doctorColor = get(doctor, 'color', null);
      const doctorNameWithTitle = get(doctor, 'nameWithTitle', '');
      const agendaNotes = get(doctor, 'agendaNotes', '');

      const item = {
        customRendererData: {
          className: doctorColor !== null ? `indicator-color${doctorColor}` : '',
          label: doctorNameWithTitle,
          agendaNotes,
          value: doctorItemId,
          type: 'doctors',
          isChecked: includes(selectedDoctors, doctorItemId),
        },
      };
      if (permissions.agendaDoctorFilter) {
        items.push(item);
      } else if (parseInt(doctorItemId, 10) === parseInt(doctorId, 10)) {
        items.push(item);
      }
    });

    if (!orderDoctorsByProfession || !permissions.agendaDoctorFilter) {
      if (allDoctorsIds.length > 1 || (permissions.agendaDoctorFilter && allDoctorsIds.length > 0)) {
        if (!permissions.singleDoctor) {
          items.push(doctorsHeader);
          items.push(allDoctorsItem);
        }


        pushDoctors(sort(doctors, alphabeticCompare('name')));
      }
    } else if (allDoctorsIds.length > 1 || (permissions.agendaDoctorFilter && allDoctorsIds.length > 0)) {
      if (!permissions.singleDoctor) {
        items.push(doctorsHeader);
        items.push(allDoctorsItem);
      }

      const professionGroups = {};

      map(doctors, doctor => {
        const professionCode = get(doctor, 'professionCode', 'default');
        const profs = get(professionGroups, professionCode, []);
        profs.push(doctor);
        professionGroups[professionCode] = profs;
      });

      const defaultGroup = get(professionGroups, 'default', []);
      pushDoctors(sort(defaultGroup, alphabeticCompare('name')));

      map(professionGroups, (professionGroup, code) => {
        if (professionGroup.length > 0 && code !== 'default') {
          items.push(createProfessionTile(code, professionGroup.length > 1));
          pushDoctors(sort(professionGroup, alphabeticCompare('name')));
        }
      });
    }

    // const filteredDoctors = highPrivacy ?
    // filter(orderedDoctors, doctor => parseInt(doctor.id, 10) === parseInt(get(user, ['advancedData', 'doctorId']), 10))
    // : orderedDoctors;


    const renderer = (item, key) => {
      if (item.type === 'customHeading') {
        return [
          <span key={`customHeading-text-${key}`}>{item.text}</span>,
          item.selectAll && <span key={`customHeading-button-${key}`} className="doctorGroupSelect" onClick={this.handleSelectGroup(item.group)}>{_('label/all_subfilters')}</span>,
        ];
      }
      return [
        <Checkbox
          key={`checkbox-${key}`}
          className={'indicator ' + item.className}
          chkColor={item.color}
          label={item.label}
          isChecked={item.isChecked}
          onChange={this.handleChange.bind(this, item.type, item.value)}
        />,
        item.type === 'doctors' && isPresent(item.agendaNotes) ? (
          <Tooltip text={item.agendaNotes} key={`tooltip-${key}`}>
            <Icon iconName="info" key={`tooltip-icon-${key}`}/>
          </Tooltip>
        ) : null,
        <div key={`dv-${key}`}>&nbsp;&nbsp;</div>,
      ];
    };

    return (
      <ClickableList
        className="agendaFilters filter flexStart"
        items={items}
        customRenderer={renderer}
      />
    );
  }

  handleSelectGroup = (groupId: number) => () => {
    const { doctors, onFiltersChange } = this.props;
    const selectedDoctors = map<any, any>(filter(doctors, doc => {
      const professionCode = get(doc, 'professionCode', 'default');

      return toNumber(professionCode) === toNumber(groupId);
    }), doc => get(doc, 'id', ''));

    this.setState({ selectedDoctors }, () => onFiltersChange(selectedDoctors, this.state.selectedRooms));
  };

  handleChange = (type: string, value: any) => {
    if (this.props.isLoading) return;
    const allRoomsIds = map<any, any>(this.props.rooms, (room) => (room.number));
    const allDoctorsIds = map<any, any>(this.props.doctors, (doctor) => (get(doctor, 'id', '')));


    let selectedRooms: any;
    let selectedDoctors: any;
    if (value === 'all' && type === 'rooms') {
      selectedRooms = allRoomsIds;
    } else if (value === 'all' && type === 'doctors') {
      selectedDoctors = allDoctorsIds;
    } else if (type === 'rooms') {
      selectedRooms = [value];
      selectedDoctors = allDoctorsIds;
    } else if (type === 'doctors') {
      selectedDoctors = [value];
      selectedRooms = allRoomsIds;
    }

    if (selectedRooms && selectedDoctors) {
      this.setState({
        selectedRooms,
        selectedDoctors,
      });
    } else if (selectedRooms && !selectedDoctors) {
      this.setState({
        selectedRooms,
      });
    } else if (!selectedRooms && selectedDoctors) {
      this.setState({
        selectedDoctors,
      });
    }

    this.props.onFiltersChange(selectedDoctors, selectedRooms);
  }
}

export default withTranslation()(AgendaFilters);
