import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';

// @flow

type Props = {
  linkTo: string,
}

export default ({ linkTo }: Props) => {
  const port = useMemo(() => (window.location.port.length > 0 ? `:${window.location.port}` : ''), []);
  return (
    <Link to={linkTo} className="signUpLogo">
      <img src={`${window.location.protocol}//${window.location.hostname}${port}/images/logo-dark.png`} alt="logo"/>
    </Link>
  );
};
