// @flow
import React from 'react';
import './StepProgressBar.scss';

type Props = {
  currentyStep: number,
  steps: number,
};

export default class StepProgressBar extends React.Component<Props> {
  render() {
    const {
      currentyStep,
      steps,
    } = this.props;

    const mainClasses = 'stepsNavigator step' + currentyStep;

    const stepsGroup = [];
    for (let i = 1; i <= steps; i++) {
      let cssClasses = 'step';
      if (i === currentyStep) {
        cssClasses += ' active';
      } else if (i < currentyStep) {
        cssClasses += ' past';
      }
      stepsGroup.push(<div key={i} className={cssClasses} />);
    }

    return (
      <div className={mainClasses} >
        <div className="track" />
        <div className="steps">
          {stepsGroup}
        </div>
      </div>
    );
  }
}
