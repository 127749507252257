// @flow
import React from 'react';
import { connect } from 'react-redux';
import { get, isEqual } from 'lodash';
import { isPresent } from '@helpers/helpers';
import { Icon, Button, Modal, Spinner, ClickableList, FormattedText, MobilePhoneLinks } from '@elements';
import type { Doctors, Patient, Clinic } from '@types';

type Props = {
  formChange: Function,
  setModal: Function,
  selectedPatient: ?Patient,
  doctors: Doctors,
  isNew: boolean,
  patientLoading: boolean,
  clinic: Clinic,
  appointmentId: string,
  accessToken: string,
  _: Function,
};

const resetSelectionItems = [
  {
    className: 'listItem resetSelectionItem',
    customRendererData: {
      icon: 'close-circle',
      text: 'Selecionar outro paciente',
    },
  },
];

const resetSelectionRenderer = (item: Object) => (
  <span key="appointmentModalText">
    <Icon iconName={item.icon} className="icon" size={24} />
    <span>{item.text}</span>
  </span>
);

class PatientInfoModal extends React.Component<Props> {
  shouldComponentUpdate(nextProps: Props) {
    return !isEqual(this.props, nextProps);
  }
  render() {
    const {
      selectedPatient, doctors, isNew, patientLoading, _, accessToken, clinic, appointmentId,
    } = this.props;

    const selectedPatientDoctor = get(doctors, ['data', get(selectedPatient, 'doctorId')]);

    const linkInBackEndApp = (() => {
      let baseUrl = '';

      if (process.env.NODE_ENV === 'development') {
        baseUrl = 'http://localhost:3000';
      } else if (process.env.NODE_ENV === 'staging') {
        baseUrl = 'https://staging-api.doutore.com';
      } else {
        baseUrl = 'https://beta.doutore.com';
      }

      return `${baseUrl}/dashboard/access?access_token=${accessToken}}&redirect_to=${baseUrl}`;
    });

    const renderData = {
      name: get(selectedPatient, 'name', null),
      doctor: get(selectedPatientDoctor, 'nameWithTitle', null),
      mobilePhone: get(selectedPatient, 'mobilePhone', null),
      homePhone: get(selectedPatient, 'homePhone', null),
      otherPhone: get(selectedPatient, 'otherPhone', null),
      email: get(selectedPatient, 'email', null),
      recommendedBy: get(selectedPatient, 'recommendedBy', null),
      insuranceCompany: get(selectedPatient, 'insuranceCompany', null),
    };

    return (
      <Modal id="patientModal" className="secondaryModal">
        <div className="modalHeader actionHeader">
          <Button className="backButton" onClick={this.handleClose}>
            <Icon iconName="arrow-left" className="icon" />
          </Button>
          <span className="backButtonLabel">{_('label/patient_info/title')}</span>
        </div>

        {isNew &&
          <ClickableList
            items={resetSelectionItems}
            customRenderer={resetSelectionRenderer}
            onListItemClick={this.onChangePatient}
          />
        }


        <div className='selectedPatientInfoWrapper'>
          {isPresent(renderData.name) && (
          <div className='infoItem'>
            <p className='label'>{_('label/patient_form/name')}</p>
            <h3 className='value'>{renderData.name}</h3>
          </div>
          )}

          {isPresent(renderData.doctor) && (
          <div className='infoItem'>
            <p className='label'>{_('label/patient_info/responsible')}</p>
            <h3 className='value'>{renderData.doctor}</h3>
          </div>
          )}

          {isPresent(renderData.mobilePhone) && (
          <div className='infoItem'>
            <p className='label'>
              {_('label/patient_form/mobile_phone')}
            </p>
            <FormattedText type="phone" text={renderData.mobilePhone} />
            <MobilePhoneLinks mobilePhone={renderData.mobilePhone} whatsapp />
          </div>
          )}

          {isPresent(renderData.homePhone) && (
          <div className='infoItem'>
            <p className='label'>{_('label/patient_form/homePhone')}</p>
            <FormattedText type="phone" text={renderData.homePhone} />
            <MobilePhoneLinks mobilePhone={renderData.homePhone} />
          </div>
          )}

          {isPresent(renderData.otherPhone) && (
          <div className='infoItem'>
            <p className='label'>{_('label/patient_info/other_phone')}</p>
            <FormattedText type="phone" text={renderData.otherPhone} />
            <MobilePhoneLinks mobilePhone={renderData.otherPhone} whatsapp />
          </div>
          )}

          {isPresent(renderData.email) && (
          <div className='infoItem'>
            <p className='label'>{_('label/patient_form/email')}</p>
            <a href={`mailto:${renderData.email}`}>{renderData.email}</a>
          </div>
          )}

          {isPresent(renderData.recommendedBy) && (
          <div className='infoItem'>
            <p className='label'>{_('label/patient_form/recommended_by')}</p>
            <h3 className='value'>{renderData.recommendedBy}</h3>
          </div>
          )}

          {isPresent(renderData.insuranceCompany) && (
          <div className='infoItem'>
            <p className='label'>{_('label/patient_form/insurance_plan')}</p>
            <h3 className='value'>{renderData.insuranceCompany}</h3>
          </div>
          )}

          {(clinic.showPatientProfileExternalLink) && (
            <div className='infoItem'>
              <br/>
              <a href={`${linkInBackEndApp()}/app/clinics/${clinic.id}/appointments/${appointmentId}/patient_link`} className="btn secondary" target="_blank">
                Link de cadastro do paciente
              </a>
            </div>
          )}

          {patientLoading && <Spinner />}
        </div>

      </Modal>
    );
  }

  onChangePatient = () => {
    const { setModal, formChange } = this.props;
    formChange('patientId', null);
    setModal('PatientSelectModal');
  };

  handleClose = () => {
    this.props.setModal(null);
  };
}

const mapStateToProps = (state) => ({
  accessToken: state.auth.token,
});

export default connect(mapStateToProps)(PatientInfoModal);
