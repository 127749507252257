// @flow
import React from 'react';
import { get } from 'lodash';
import { connect } from 'react-redux';
import { PatientIndicators as PatientIndicatorsView } from '@views';
import type { Patient } from '@types';
import { getPatient } from '@context/patients';
import { openModal, closeActiveModal } from '@context/modals';

type Props = {
  dispatch: Function,
  patient: Patient,
  patientId: number,
};

class PatientIndicatorsContainer extends React.Component<Props> {
  componentDidMount() {
    this.props.dispatch(getPatient(this.props.patientId, false));
  }

  render() {
    const { patient } = this.props;
    return (
      <PatientIndicatorsView
        patient={patient}
        onShowGraph={this.handleShowGraph}
        onNew={this.handleNew}
        onEdit={this.handleEdit}
      />
    );
  }

  handleCloseModal = () => {
    this.props.dispatch(closeActiveModal());
  }
  handleShowGraph = (data: Object) => {
    this.props.dispatch(openModal('PatientIndicatorsGraphModal', data || {}));
  };
  handleNew = (data?: Object) => {
    this.props.dispatch(openModal('PatientIndicatorsFormModal', data));
  };
  handleEdit = (measure: Object) => {
    this.props.dispatch(openModal('PatientIndicatorsFormModal', {
      measure,
      patient: this.props.patient,
    }));
  };
}

const mapStateToProps = (state, ownProps: any) => ({
  user: state.context.user,
  patientId: ownProps.match.params.id,
  patient: get(state, ['context', 'patients', 'data', ownProps.match.params.id]),
});

export default connect<any, any, any, any, any, any>(mapStateToProps)(PatientIndicatorsContainer);
