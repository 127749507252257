// @flow

import React, { useMemo, useCallback, useState } from 'react';
import classNames from 'classnames';
import { map, size, isEmpty, get, clamp, isEqual } from 'lodash';
import { Button, Icon, HiddenLines, TinyProgressBar, Dropdown, Gallery } from '@elements';
import type { Record, DrugsPrescription, InputOptions, Patient, PlanInclude } from '@types';
import { parteHtmlToReact as parseHtml, useTranslationNs, useIsMobile, useNotifyUserAboutRequiredShareInfo, isPresent } from '@helpers/helpers';
import RecordPrescription from '../mixins/RecordPrescription';
import FileAttachment from '../mixins/FileAttachment';
import { usePermissionTo } from '@helpers/connect';
import CustomFormContent from './CustomFormContent';
import DrugsPrescriptionsContent from './DrugsPrescriptionsContent';
import ExamsPrescriptionsContent from './ExamsPrescriptionsContent';
import DocumentsContent from './DocumentsContent';


type Props = {
  appointmentNote: string,
  detailsContent?: string,
  customFormsContent: Object,
  drugsPrescriptionsContent: DrugsPrescription,
  showFileAttachments: boolean,
  tempFiles: Array<Object>,
  recordFiles: Array<Object>,
  fileAttachmentDropdownList: InputOptions,
  showImageAttachments: boolean,
  examsPrescriptionsContent: Object,
  documentsContent: Object,
  record: Record,
  tempImages: Array<Object>,
  recordImages: Array<Object>,
  isEditable?: boolean,
  canDeleteAttachment?: boolean,
  patient?: Patient,
  onDirectShare: (recordId: number, resource: string, resourceId: number, respondWithUrls: (wtt: string | null, mail: string | null) => void) => void,
  // Events
  onSelectFileAction: Function,
  onPrintCustomFormClick: Function,
  onPrintDrugsPrescriptionClick: Function,
  onPrintDocumentClick: Function,
  openLightBox: Function,
  onPrintExamsPrescriptionClick: Function,
  onEditDrugsPrescriptionClick?: Function,
  onEditExamsPrescriptionClick?: Function,
  onEditCustomFormClick?: Function,
  showPDF?: Function,
  onEditDocumentClick?: Function,
  planInclude?: ?PlanInclude,
  mainSection: any,
  memedToken: string,
  onPrintMemedPrescription: Function,
  onPrintSimplifiedRecord: Function,
  isMine: boolean,
  handleDeleteAttachment: Function,
  handleDownloadAttachment: Function,
  isEdit: boolean,
};

const maxTextChars = 1000;
const maxTextCharsRecords = 2000;
const maxTextCharsDocuments = 300;

const RecordDetails = ({
  // Data
  appointmentNote, detailsContent, customFormsContent, drugsPrescriptionsContent, showFileAttachments,
  tempFiles, recordFiles, fileAttachmentDropdownList, showImageAttachments, examsPrescriptionsContent,
  documentsContent, record, tempImages, recordImages, isMine,
  // Events
  onSelectFileAction, onPrintCustomFormClick, onPrintDrugsPrescriptionClick, onPrintDocumentClick,
  openLightBox, onPrintExamsPrescriptionClick,
  isEditable, onEditCustomFormClick, patient, onEditDrugsPrescriptionClick, onEditExamsPrescriptionClick, onDirectShare,
  onEditDocumentClick, mainSection, showPDF, memedToken, onPrintMemedPrescription, onPrintSimplifiedRecord, handleDeleteAttachment,
  handleDownloadAttachment, isEdit, canDeleteAttachment, ...props
}: Props) => {
  const [_] = useTranslationNs('timeline');
  const [directShareUrls, setDirectShareUrl] = useState({
    wtt: null,
    mailTo: null,
  });

  const shareErrorType = useMemo(() => ({
    email: !isPresent(get(patient, 'email')),
    wtt: !isPresent(get(patient, 'mobilePhone')),
  }), [patient]);

  const [directShareLoading, setDirectShareLoading] = useState(false);

  const permissionsTo = usePermissionTo();

  const notifyUserAboutShareRequiresHookCB = useNotifyUserAboutRequiredShareInfo(patient, directShareUrls);

  const notifyUserAboutShareRequires = useCallback((method: 'email' | 'wtt') => () => {
    notifyUserAboutShareRequiresHookCB(method);
  }, [notifyUserAboutShareRequiresHookCB]);


  const printDropdownItems: any = useCallback((header: string) => [
    { text: header, break: true, action: undefined, className: 'headingSeparator mobile' },
    ...(isMine ? [{ text: 'Com Assinatura', action: true }] : []),
    { text: 'Sem Assinatura', action: false },
  ], [isMine]);

  const shareDropdownItems: any = useMemo(() => [
    { text: _('action/share_header'), break: true, action: undefined, className: 'headingSeparator mobile' },
    {
      text: 'Por Email',
      onClick: shareErrorType.email ? notifyUserAboutShareRequires('email') : undefined,
      href: shareErrorType.email ? undefined : directShareUrls.mailTo,
      action: 'email',
      target: '_blank',
    },
    {
      text: 'Por WhatsApp',
      onClick: shareErrorType.wtt ? notifyUserAboutShareRequires('wtt') : undefined,
      href: shareErrorType.wtt ? undefined : directShareUrls.wtt,
      action: 'wtt',
      target: '_blank',
    },
  ], [_, directShareUrls, shareErrorType, notifyUserAboutShareRequires]);

  const [isMobile] = useIsMobile();
  const isCordova = window.isCordovaApp;

  const memedPrescriptions = useMemo(() => get(record, 'memedPrescriptions', get(record, ['details', 'memedPrescriptions'])) || [], [record]);

  // TODO: this code is :poop:
  // const planInclude: any = useMemo(() => (props.planInclude), [props.planInclude]);
  const planInclude: any = useMemo(() => (props.planInclude || {
    financial: true,
    intercom: true,
    basicWidgets: true,
    newFeatures: true,
    documents: true,
    examsDrugsDocuments: true,
    customForms: true,
    advancedWidgets: true,
    attachments: true,
    inviteDoctors: true,
  }), [props.planInclude]);

  const onShareClick = useCallback((resource: 'examsPrescriptions' | 'customForms' | 'drugsPrescriptions' | 'documents' | 'record', resourceId: number) => () => {
    setDirectShareLoading(true);
    onDirectShare(record.id, resource, resourceId, (wtt, mailTo) => {
      setDirectShareLoading(false);
      setDirectShareUrl({
        mailTo,
        wtt,
      });
    });
  }, [record, onDirectShare]);

  const actionClasses = useMemo(() => ({ iconOnlyButtons: !!window.isCordovaApp, withTextbuttons: !window.isCordovaApp }), []);

  const isPureHtml = useMemo(() => get(record, ['details', 'pureHtml'], false), [record]);


  const drugsPrescriptionSelectItem = useCallback((prescription: any) => ({ action }) => {
    if (action === 'share') {
      onPrintDrugsPrescriptionClick(record.id, prescription.id)();
    } else {
      onPrintDrugsPrescriptionClick(record.id, prescription.id, action)();
    }
  }, [onPrintDrugsPrescriptionClick, record]);

  const memedPrescriptionsRenderContent = useMemo(() => map(memedPrescriptions, (prescription: Object, index: number) => {
    if (!prescription) return null;

    return (
      <RecordPrescription
        key={index}
        id={prescription.memedId}
        prescription={prescription}
        onPrintMemedPrescription={onPrintMemedPrescription}
      />
    );
  }), [memedPrescriptions, onPrintMemedPrescription]);


  const tempImagesRenderContent = useMemo(() => size(tempImages) > 0 && map(tempImages, (tempImage) => (
    <div key={tempImage.id} className='imageHolder uploadingImage' style={{ backgroundImage: `url(${tempImage.blob})`, backgroundSize: 'cover' }}>
      <div className="uploadingImageContent">
        <Icon fill="#fff" size={50} iconName="rolling-spinner" />
        <div className="statusText">
          {tempImage.uploading ? _('label/sending', 'global') : _('label/processing', 'global')}
        </div>
      </div>
    </div>
  )), [_, tempImages]);

  const recordFilesRenderContent = useMemo(() => map(recordFiles, (file, key: number) => (
    <FileAttachment
      key={`file-${file.name}-${key}`}
      file={file}
      fileAttachmentDropdownList={fileAttachmentDropdownList}
      onSelectFileAction={onSelectFileAction}
      showPDF={showPDF}
    />
  )), [fileAttachmentDropdownList, onSelectFileAction, recordFiles, showPDF]);

  const tempFilesRenderContent = useMemo(() => map(tempFiles, (file, key: number) => (
    <div key={key} className='fileAttachment'>
      <Icon iconName='file-solid' className='documentIcon icon' size={18} />
      <div className="progress">
        <span>{_('label/sending', 'global')}</span>
        <TinyProgressBar
          progress={clamp(file.progress, 10, 100)}
        />
      </div>
    </div>
  )), [_, tempFiles]);

  return (
    <div className={classNames('registerBody recordDetailsContainer')}>
      {!isEmpty(detailsContent) && !isEditable && (
      <div className='record freeText recordActionsWraper'>
        <div className={classNames('recordTextActions', { isMobile: isMobile || isCordova })}>
          {(permissionsTo.doctorShowSignature && isMine) && (
            <Dropdown
              className={window.isCordovaApp ? 'outlineButton singleIconFix buttonLike' : 'outlineButton buttonLike'}
              placeholder={<Icon iconName={window.isCordovaApp ? 'share' : 'print'} className='icon' size={18} />}
              disableAutoModalTitle
              disableArrowIcon={isMobile}
              items={printDropdownItems(window.isCordovaApp ? 'Compartilhar impressão de Registros Simplificado' : 'Imprimir Registros Simplificado')}
              onSelectItem={({ action }) => onPrintSimplifiedRecord(action)()}
            />
          )}
          {!(permissionsTo.doctorShowSignature && isMine) && (
            <Button
              className='outlineButton buttonLike'
              onClick={onPrintSimplifiedRecord(false)}
            >
              <Icon iconName={window.isCordovaApp ? 'share' : 'print'} className='icon' size={18} />
            </Button>
          )}
        </div>
        <div className='recordContent ritchTextContent'>
          <HiddenLines maxChars={maxTextCharsRecords} text={detailsContent} scrollContainer={mainSection}>
            {/* TODO: Use preparse html special chars but keep tags to use new parser */}
            {htmlContent => parseHtml(htmlContent, true)}
          </HiddenLines>
        </div>
      </div>
      )}

      {!isEmpty(appointmentNote) &&
      <div className='record freeText'>
        <span className='recordTitle'>{_('label/appointment_note')}</span>
        <div className='recordContent ritchTextContent'>
          <HiddenLines maxChars={maxTextChars} text={appointmentNote} scrollContainer={mainSection}>
            {text => <div>{parseHtml(text, isPureHtml)}</div>}
          </HiddenLines>
        </div>
      </div>
      }

      {!isEmpty(customFormsContent) && (
        <CustomFormContent
          _={_}
          isMine={isMine}
          actionClasses={actionClasses}
          customFormsContent={customFormsContent}
          isCordova={isCordova}
          isEditable={isEditable}
          isMobile={isMobile}
          mainSection={mainSection}
          maxTextChars={maxTextChars}
          onEditCustomFormClick={onEditCustomFormClick}
          onPrintCustomFormClick={onPrintCustomFormClick}
          planInclude={planInclude}
          record={record}
        />
      )}

      {!isEmpty(drugsPrescriptionsContent) && (
        <DrugsPrescriptionsContent
          _={_}
          isMine={isMine}
          actionClasses={actionClasses}
          drugsPrescriptionSelectItem={drugsPrescriptionSelectItem}
          drugsPrescriptionsContent={drugsPrescriptionsContent}
          isCordova={isCordova}
          isEditable={isEditable}
          isMobile={isMobile}
          mainSection={mainSection}
          maxTextChars={maxTextChars}
          onEditDrugsPrescriptionClick={onEditDrugsPrescriptionClick}
          onPrintDrugsPrescriptionClick={onPrintDrugsPrescriptionClick}
          onShareClick={onShareClick}
          directShareLoading={directShareLoading}
          patient={patient}
          permissionsTo={permissionsTo}
          printDropdownItems={printDropdownItems}
          record={record}
          shareDropdownItems={shareDropdownItems}
        />
      )}

      {!isEmpty(examsPrescriptionsContent) && (
        <ExamsPrescriptionsContent
          _={_}
          isMine={isMine}
          actionClasses={actionClasses}
          examsPrescriptionsContent={examsPrescriptionsContent}
          isCordova={isCordova}
          isEditable={isEditable}
          isMobile={isMobile}
          mainSection={mainSection}
          maxTextChars={maxTextChars}
          onEditExamsPrescriptionClick={onEditExamsPrescriptionClick}
          onPrintExamsPrescriptionClick={onPrintExamsPrescriptionClick}
          onShareClick={onShareClick}
          directShareLoading={directShareLoading}
          patient={patient}
          permissionsTo={permissionsTo}
          planInclude={planInclude}
          printDropdownItems={printDropdownItems}
          record={record}
          shareDropdownItems={shareDropdownItems}
        />
      )}

      {!isEmpty(documentsContent) && (
        <DocumentsContent
          maxTextCharsDocuments={maxTextCharsDocuments}
          _={_}
          isMine={isMine}
          actionClasses={actionClasses}
          documentsContent={documentsContent}
          isCordova={isCordova}
          isEditable={isEditable}
          isMobile={isMobile}
          mainSection={mainSection}
          onEditDocumentClick={onEditDocumentClick}
          onPrintDocumentClick={onPrintDocumentClick}
          onShareClick={onShareClick}
          directShareLoading={directShareLoading}
          permissionsTo={permissionsTo}
          printDropdownItems={printDropdownItems}
          record={record}
          shareDropdownItems={shareDropdownItems}
        />
      )}

      {!isEmpty(memedPrescriptions) && memedPrescriptions.length > 0 && (
        <div className="memedPrescriptionsList">
          {memedPrescriptionsRenderContent}
        </div>
      )}


      {(showImageAttachments || showFileAttachments) && (
        <div className='record attachments'>
          <span className='recordTitle'>Anexos</span>
          {showImageAttachments &&
          <div className="recordContent flex-attachments">
            <Gallery
              images={recordImages}
              onClickDelete={canDeleteAttachment ? handleDeleteAttachment : undefined}
              onClickDownload={handleDownloadAttachment}
            />
            {tempImagesRenderContent}
          </div>
          }

          {showFileAttachments && (
          <div className='recordContent fileAttachments'>
            {recordFilesRenderContent}
            {tempFilesRenderContent}
          </div>
          )}

        </div>
      )}
    </div>
  );
};

// $FlowFixMe
export default React.memo(RecordDetails, (prev, next) => isEqual(prev, next));
