// @flow
import { map, pick } from 'lodash';
import type { Appointments, FullAppointment } from '../types';

// expects array of appointment objects: [{id: 1, ...}, {id: 2, ...}, ...]
// => returns appointment object: {1: {...}, 2: {...}, ...}
function normalizeAppointments (inputAppointments: Appointments) {
  const appointments = {};

  map(inputAppointments, (appointment: any) => {
    appointments[appointment.id] = appointment;
  });

  return {
    appointments,
  };
}

// takes a 'full' appointment object and extracts only those properties which are contained in compact appointments as well
function getCompactFromFullAppointment (fullAppointment: FullAppointment) {
  const compactPropertiesList = ['id', 'full', 'start', 'end', 'allDay', 'date', 'duration',
    'time', 'doctorId', 'restricted', 'room', 'notes', 'title', 'badge', 'patient'];
  const compactAppointment = pick(fullAppointment, compactPropertiesList);
  compactAppointment.full = false;
  compactAppointment.patient = pick(compactAppointment.patient, ['id', 'name', 'indebt']);

  return compactAppointment;
}

export default {
  normalizeAppointments,
  getCompactFromFullAppointment,
};
