// @flow
import React from 'react';
import { withRouter } from 'react-router-dom';
import type { Node } from 'react';
import { map, filter, isEqual, get, isBoolean } from 'lodash';
import classnames from 'classnames';
import { isPresent } from '@helpers/helpers';
import { Icon, ModalClickableList, RelativePortal } from '../';
import './Dropdown.scss';

type Item = {
  text: string,
  linkTo?: string,
  externalLinkTo?: string,
  break?: boolean,
  leftIcon?: string,
  rightIcon?: string,
  className?: string,
  href?: string;
  download?: string;
  target?: string;
  onClick?: Function,
};

type Props = {
  items: Array<Item>,
  placeholder?: any,
  changeablePlaceholder?: boolean,
  disableAutoModalTitle?: boolean,
  disableArrowIcon?: boolean,
  icon?: string,
  className: string, // required due to outside click handling
  label?: string,
  children?: Node,
  onSelectItem?: Function,
  history: Object, // from withRouter
  selectedItem?: Item,
  secondaryText?: string,
  mobileLabel?: string,
  onClick?: Function,
  forceDesktop?: boolean,
  usePortal?: boolean,
  forceToBeClosed?: boolean,
};

type State = {
  isOpen: boolean,
  selectedItem: ?Item,
};

/**
 * Fallback for event.path in firefox|safari
 * @param {*} el
 */
const composedPath = (el) => {
  const path = [];

  while (el) {
    path.push(el);

    if (el.tagName === 'HTML') {
      path.push(document);
      path.push(window);

      return path;
    }

    el = el.parentElement;
  }
  return path;
};

const getItemLabel = (item: any, fallBack = null) => get(item, 'text', get(item, 'label', fallBack));

class Dropdown extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isOpen: false,
      selectedItem: props.selectedItem || null,
    };

    this.wrapperRef = React.createRef();
  }


  componentWillMount() {
    document.addEventListener('click', this.handleOutsideClick);
  }
  componentWillReceiveProps(nextProps: Props) {
    if (
      !isEqual(this.props.items, nextProps.items) ||
      !isEqual(this.props.selectedItem, nextProps.selectedItem)
    ) {
      this.setState({ selectedItem: nextProps.selectedItem || null });
    }
  }

  shouldComponentUpdate(nextProps: Props, nextState: State) {
    return !isEqual(this.props, nextProps) || !isEqual(this.state, nextState);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleOutsideClick);
  }

  render() {
    const {
      items, placeholder, icon,
      className, label, children, usePortal,
      secondaryText, mobileLabel, forceDesktop,
      disableAutoModalTitle, disableArrowIcon,
    } = this.props;
    const { selectedItem } = this.state;
    const isOpen = isBoolean(this.props.forceToBeClosed) && this.props.forceToBeClosed === true ? false : this.state.isOpen;

    const cssClasses = classnames(['inputHolder', className]);

    const displayIcon = icon ? <Icon iconName={icon} className="icon" size={24} /> : '';

    let selected = '';
    if (selectedItem) {
      selected = <div className={selectedItem.className || ''}>{selectedItem.text}</div>;
    } else if (placeholder) {
      if (secondaryText) {
        selected = (
          <span className="doubleText">
            <span className="firstText">
              {placeholder}
            </span>
            <span className="secondaryText">
              {secondaryText}
            </span>
          </span>
        );
      } else {
        selected = <span>{placeholder}</span>;
      }
    }

    const listItems = map<any, any>(items, (item: Item, key) => {
      const itemClass = get(item, 'className');
      if (item.break) {
        return (
          <li key={key} className={`headingSeparator ${itemClass}`}>
            {item.leftIcon && <Icon iconName={item.leftIcon} className="icon" />}
            {item.text}
            {item.rightIcon && <Icon iconName={item.rightIcon} className="icon" />}
          </li>
        );
      }

      const content = (
        <React.Fragment>
          {item.leftIcon && <Icon iconName={item.leftIcon} className="icon" />}
          {item.text}
          {item.rightIcon && <Icon iconName={item.rightIcon} className="icon" />}
        </React.Fragment>
      );

      return (
        <li
          key={key}
          onClick={this.onSelectItem.bind(this, item)}
          role="presentation"
          className={classnames(itemClass, { isLink: !!item.href })}
        >
          {item.href && (
            <a href={item.href} target={item.target}>{content}</a>
          )}
          {!item.href && content}
        </li>
      );
    });

    const renderModalItens = () => {
      let itLabel = isPresent(mobileLabel) ? mobileLabel : label;
      itLabel = isPresent(itLabel) ? itLabel : placeholder;
      let modalItems: any = [
        ...(disableAutoModalTitle ? [] : [{ text: itLabel, className: 'headingSeparator mobile', noClick: true }]),
        ...map(items, opt => ({
          ...opt,
          text: getItemLabel(opt),
          customRendererData: {
            ...opt,
          },
          className: getItemLabel(this.props.selectedItem, undefined) === getItemLabel(opt, null) ?
            classnames('listItem active', opt.className, { isLink: !!opt.href }) :
            classnames('listItem', opt.className, { isLink: !!opt.href }),
        })),
      ];

      modalItems = filter(modalItems, it => it.hideMobile !== true);

      if (modalItems.length <= 1) {
        return null;
      }

      const customRenderer = (item) => {
        if (item.break) {
          return (
            <React.Fragment>
              {item.leftIcon && <Icon iconName={item.leftIcon} className="icon" />}
              {item.text}
              {item.rightIcon && <Icon iconName={item.rightIcon} className="icon" />}
            </React.Fragment>
          );
        }

        const content = (
          <React.Fragment>
            {item.leftIcon && <Icon iconName={item.leftIcon} className="icon" />}
            {item.text}
            {item.rightIcon && <Icon iconName={item.rightIcon} className="icon" />}
          </React.Fragment>
        );

        return (
          <React.Fragment>
            {item.href && (
              <a href={item.href} target={item.target} download={item.download}>{content}</a>
            )}
            {!item.href && content}
          </React.Fragment>
        );
      };

      return (
        <ModalClickableList
          items={modalItems}
          onClose={this.closeDropdown}
          onListItemClick={this.onSelectItem}
          customRenderer={customRenderer}
        />
      );
    };

    return (
      <div className={cssClasses} onClick={this.handleOnClick} ref={this.wrapperRef}>
        { label ? <label>{label}</label> : null }

        <div className="dropdown" onClick={this.toggleDropdown}>
          <div className="dropdownTrigger">
            {displayIcon}<span className="tiny-space">&nbsp;</span>
            <span className="text">{selected}</span>

            {!disableArrowIcon && (<Icon iconName="arrow-down" className="icon" />)}
          </div>
          {((!window.isCordovaApp || forceDesktop) && isOpen) &&
            <React.Fragment>
              {!usePortal && (
                <ul className={'dropdownOptions' + (isOpen ? ' open' : '')}>
                  {children || listItems}
                </ul>
              )}
              {usePortal && (
                <RelativePortal
                  component="div"
                  top={10}
                  right={0}
                  onOutClick={this.closeDropdown}
                  className="dropdown-portal-div"
                >
                  <ul className={'dropdownOptions withPortal' + (isOpen ? ' open' : '')}>
                    {children || listItems}
                  </ul>
                </RelativePortal>
              )}
            </React.Fragment>
          }
          {isOpen && window.isCordovaApp && !forceDesktop && renderModalItens()}
        </div>
      </div>
    );
  }

  wrapperRef: any;

  /**
   * @param  {React.MouseEvent<HTMLDivElement, MouseEvent>} e
   */
  toggleDropdown = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };

  showDropDown = () => {
    this.setState({ isOpen: true });
  };

  closeDropdown = () => {
    this.setState({ isOpen: false });
  };

  onSelectItem = (item: Item) => {
    if (get(item, 'noClick', false)) return;

    if (item.onClick) {
      item.onClick(item);
    }

    if (item.externalLinkTo) {
      window.open(item.externalLinkTo, '_blank');
    } else if (item.linkTo) {
      this.props.history.push(item.linkTo);
    } else if (this.props.onSelectItem) {
      this.props.onSelectItem(item);
    }
    if (this.props.changeablePlaceholder) {
      this.setState({ selectedItem: item });
    }
  };

  handleOnClick = () => {
    if (typeof this.props.onClick === 'function' && !this.state.isOpen) {
      this.props.onClick();
    }
  }

  handleOutsideClick = (e: Object) => {
    const clickIdentifier = `inputHolder ${this.props.className}`;
    const path = e.path ? e.path : composedPath(e.target);
    const dropdownInteraction = filter(path, (element: Object) => element.className === clickIdentifier);

    if (!dropdownInteraction.length && this.state.isOpen && !(window.isCordovaApp && !this.props.forceDesktop)) {
      this.setState({ isOpen: false });
    }
  };
}
export default withRouter(Dropdown);
