// @flow
import React from 'react';
import { AppMobileHeader } from '../../../../blocks';

export default class PatientPayments extends React.Component<any> {
  render() {
    return (
      <div className="appContent">
        <AppMobileHeader title="PatientPayments" className="showBack" />
        PatientPayments
      </div>
    );
  }
}
