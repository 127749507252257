// @flow
import React from 'react';
import type { Node } from 'react';
import classnames from 'classnames';
import './Tabs.scss';

type Props = {
  children: Node,
  className?: string,
  id?: string,
};


export default class Tabs extends React.Component<Props> {
  render() {
    const {
      children,
      className,
      id,
    } = this.props;

    const cssClasses = classnames(['tabs', className]);

    return (
      <div id={id} className={cssClasses}>
        { children }
      </div>
    );
  }
}
