// @flow
import React from 'react';
import { get } from 'lodash';
import { connect } from 'react-redux';
import { withRouter, Link as a } from 'react-router-dom';
import type { Node } from 'react';
import moment from 'moment';
import { countryLanguages } from '@constants';
import { mapPlanIncludes, mapBundledPermission } from '@helpers/connect';
import { withTranslation } from 'react-i18next';
import { createNsTranslator, checkIfIsMobile } from '@helpers/helpers';
import { AppHeader, PlatformSwitch, Window } from '@blocks';
import { Intercom } from '@elements';
import { LoadingPage, MemedModal } from '@views';
import { MainModalContainer, ToastContainer, AppVersionControlContainer } from '../containers';
import { actions as authActions } from '../redux/modules/auth';
import { clinicActions } from '../redux/modules/context/clinics';
import { userActions } from '../redux/modules/context/user';
import { closeActiveToast } from '../redux/modules/context/toasts';
import { appControlActions } from '../redux/modules/appControl';
import type { ContextState, Dispatch, AppVersionState, BundledPermissions } from '@types';
import '../flexboxgrid.scss';
import '../styles.entry.scss';
import '../styles.resources.scss';
import '../layouts/AppLayout.scss';
import '../layouts/ExternalLayout.scss';
import 'animate.css/animate.min.css';
// Flow fix
const withTranslationTf: any = withTranslation;
const withRouterTf: any = withRouter;
type Props = {
  children: Node,
  context: ContextState & { accessToken: string },
  appVersion: AppVersionState,
  dispatch: Function,
  t: Function,
  isMobile: boolean,
  permissions: BundledPermissions,
};


class AppLayout extends React.Component<Props> {
  componentWillMount() {
    let countryCode = get(this.props.context, ['user', 'countryCode']);
    countryCode = countryCode || window.countryCode;
    countryCode = countryCode || 'BR';

    if (localStorage.getItem('debugLng') !== 'true') {
      window.i18n.changeLanguage(get(countryLanguages, countryCode, 'pt-BR'));
    }

    this.props.dispatch(clinicActions.getClinics(() => {
      this.props.dispatch(closeActiveToast());

      const clinicId = get(this.props, ['context', 'clinics', 'activeClinic', 'id']);
      if (clinicId) {
        this.props.dispatch(userActions.getUser());
      }

      if (window.StatusBar) {
        window.StatusBar.overlaysWebView(false);
      }
    }));

    // window.addEventListener('keyboardDidShow', this.handleKeyboardVisibility(true));
    // window.addEventListener('keyboardDidHide', this.handleKeyboardVisibility(false));
    // // eslint-disable-next-line
    // window.addEventListener('keyboardDidShow', () => console.warn('keyboardDidShow'));
    // // eslint-disable-next-line
    // window.addEventListener('keyboardDidHide', () => console.warn('keyboardDidHide'));
  }

  componentWillReceiveProps(nextProps: Props) {
    const clinicId = get(this.props, ['context', 'clinics', 'activeClinic', 'id']);
    const nextClinicId = get(nextProps, ['context', 'clinics', 'activeClinic', 'id']);
    // Re-fetch user on clinic change
    if ((clinicId && nextClinicId) && (clinicId !== nextClinicId)) {
      this.props.dispatch(userActions.getUser());
    }

    window.clearAppStorage = this.handleUpdateApp;
  }

  render() {
    const {
      appVersion, context, isMobile,
    } = this.props;
    const {
      user, clinics, modals,
      toasts, userUpdating, accessToken,
    } = context;

    const _ = createNsTranslator('layout', this.props.t);


    const activeModal = get(modals, 'active');
    const activeToast = get(toasts, 'active');
    const activeAppVersionNotification = get(appVersion, ['notification', 'active']);
    const loadingApp = Boolean(!clinics || userUpdating);
    const planInclude = mapPlanIncludes(context, true); // Required user on context
    const subscription = get(context, ['user', 'advancedData', 'notifications', 'subscription'], false);
    const willCancel = subscription === 'will_cancel';
    const pastDue = subscription === 'past_due';
    // eslint-disable-next-line
    window.hashUid = () => (':' + get(context, ['user', 'id'])).split('').reduce((a, b) => { a = ((a << 5) - a) + b.charCodeAt(0); return a & a; }, 0);

    window.debugInfo = {
      userId: get(context, ['user', 'id']),
      clinicId: get(context, ['clinics', 'activeClinic', 'id']),
    };

    const headerAlerts = willCancel || pastDue ? (
      <div className="dangerContainer" ref={this.addRef('dangerContainer')}>
        {willCancel &&
          <a href="/#/ajustes/meu-plano" className="notificationHeader dangerHeader" >
            <span>{_('label/account_will_be_canceled')}</span>
            <span className="link">&nbsp;{_('action/see_details')}</span>
          </a>
        }
        {pastDue &&
          <a href="/#/ajustes/meu-plano" className="notificationHeader dangerHeader" >
            <span>{_('label/payment_problem')}</span>
            <span className="link">&nbsp;{_('action/click_to_resolve')}</span>
          </a>
        }
      </div>
    ) : null;

    const mainContainerStyle = {
      top: this.calculateMainContentTop(isMobile ? 50 : 50),
    };
    return (
      <Window>
        <MemedModal />

        { loadingApp ? <LoadingPage /> :
        <PlatformSwitch
          inject
          className={`
            platformSwitcher
            appMainSwitch
            ${isMobile ? 'mobileApp' : ''}
            ${headerAlerts ? 'withHeaderAlert' : ''}
          `}
          desktop={() => (
            <React.Fragment>
              {headerAlerts}
              <AppHeader
                user={user}
                accessToken={accessToken}
                clinics={clinics}
                onLogout={this.handleLogout}
                onUpdateApp={this.handleUpdateApp}
                onChangeClinic={this.handleChangeClinic}
                isIntercomEnabled={planInclude.intercom}
                trial={subscription === 'trial'}
                willCancel={willCancel}
                trialDays={get(context, ['user', 'advancedData', 'trialEndsInDays'], 0)}
                _={_}
                permissions={this.props.permissions}
              />
              <main id="desktopApp" className="mainContainer" style={mainContainerStyle}>
                {activeAppVersionNotification && <AppVersionControlContainer />}
                {this.props.children}
                {activeModal && <MainModalContainer activeModal={activeModal} />}
                {activeToast && <ToastContainer />}
                {user && user.name && (
                  <div>
                    <Intercom
                      email={user.email}
                      name={user.name}
                      lastSeen={moment().format()}
                      userId={user.id}
                      userHash={get(user, ['advancedData', 'intercomHash'])}
                      appId={get(user, ['advancedData', 'intercomAppId'])}
                      appVersion={`v${appVersion.version}`}
                    />
                  </div>
                )}
              </main>
            </React.Fragment>
          )
          }
          mobile={
            () => (
              <React.Fragment>
                {user && user.name && (
                  <div>
                    <Intercom
                      email={user.email}
                      name={user.name}
                      lastSeen={moment().format()}
                      userId={user.id}
                      userHash={get(user, ['advancedData', 'intercomHash'])}
                      appId={get(user, ['advancedData', 'intercomAppId'])}
                      appVersion={`v${appVersion.version}`}
                    />
                  </div>
                )}
                <main id="mobileApp" className="mainContainer">
                  {activeAppVersionNotification && <AppVersionControlContainer />}
                  {this.props.children}
                  {activeModal && <MainModalContainer activeModal={activeModal} />}
                  {activeToast && <ToastContainer />}
                </main>
              </React.Fragment>
            )
          }
        />
        }
      </Window>
    );
  }

  refsList: {[key: string]: any} = {};

  addRef = (name: string) => (ref: any) => {
    this.refsList[name] = ref;
  };

  calculateMainContentTop = (defaultPadding: number) => {
    const { dangerContainer } = this.refsList;

    let top = defaultPadding;

    if (dangerContainer) {
      top += dangerContainer.offsetHeight;
    }

    return `${top}px`;
  };

  handleLogout = () => {
    this.props.dispatch(authActions.logout());
  };

  handleUpdateApp = () => {
    this.props.dispatch(appControlActions.updateApp());
  };

  handleChangeClinic = (clinicId: number) => {
    this.props.dispatch(clinicActions.setActiveClinic(clinicId));
  };

  handleMemedLoad = () => {
    this.props.dispatch(clinicActions.setMemedLoadedStatus(true));
  };

  handleMemedUnLoad = () => {
    this.props.dispatch(clinicActions.setMemedLoadedStatus(false));
  };
}

const mapStateToProps = (state, ownProps) => ({
  context: {
    accessToken: get(state, ['auth', 'token'], null),
    user: get(state, ['context', 'user']),
    clinics: get(state, ['context', 'clinics']),
    modals: get(state, ['context', 'modals']),
    toasts: get(state, ['context', 'toasts']),
    userUpdating: get(state, ['context', 'userUpdating']),
    layout: get(state, ['context', 'layout']),
  },
  memedToken: get(state, ['context', 'user', 'advancedData', 'memedToken'], ''),
  appVersion: state.appVersion,
  location: get(ownProps, ['location']),
  isMobile: checkIfIsMobile(state.ui || {}),
  permissions: mapBundledPermission(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({ dispatch });

export default connect<any, any, any, any, any, any>(
  mapStateToProps,
  mapDispatchToProps,
)(withRouterTf(withTranslationTf()(AppLayout)));
