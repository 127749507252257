import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { Modal, Input, Icon, DayPickerInput } from '@elements';
import { get } from 'lodash';
import { FormFooterModal } from '@blocks';
import { useNotifyUserAboutRequiredShareInfo, getExtensiveAge, useTranslationNs, UserNotifications } from '@helpers/helpers';
import { useDispatch, useSelector } from 'react-redux';
import { IndicatorType, Indicator } from '@types';
import { indicatorsActions } from '@context/indicators';
import { openToast } from '@context/toasts';
import moment from 'moment';
import './PatientIndicatorsFormModal.scss';

type Props = {
  onCloseModal: Function,
  modalData: Object,
};

const PatientIndicatorsFormModal = ({ onCloseModal, modalData }: Props) => {
  const [formData, setFormData] = useState({});
  const [date, setDate] = useState(() => {
    if (modalData?.measure) return moment(get(modalData, ['measure', 'date'], null));

    return moment();
  });

  const [_] = useTranslationNs('indicators');

  const dispatch = useDispatch();
  const indicators = useSelector((state) => get(state, ['context', 'indicators', 'data'], []));
  const isCreating = useSelector((state) => get(state, ['context', 'indicators', 'isCreating'], false));
  const isUpdating = useSelector((state) => get(state, ['context', 'indicators', 'isUpdating'], false));
  const isDeleting = useSelector((state) => get(state, ['context', 'indicators', 'isDeleting'], false));

  const { patient, measure } = modalData;

  const isEditing = Boolean(measure);

  const notifyUserAboutRequiredInfo = useNotifyUserAboutRequiredShareInfo(modalData.patient, onCloseModal);

  useEffect(() => {
    if (!indicators) return;

    const _formData = indicators.reduce((acc, curr) => {
      acc[curr.type] = get(measure, ['values', curr.id], '').replace('.', ',');

      return acc;
    }, {});

    setFormData(_formData);
  }, [indicators, measure]);

  useEffect(() => {
    const birthdate = get(patient, 'birthdate', '');
    if (birthdate) return;

    notifyUserAboutRequiredInfo('birthdate');
  }, [notifyUserAboutRequiredInfo, onCloseModal, patient]);

  const age = useMemo(() => {
    const birthdate = get(patient, 'birthdate', '');
    if (!birthdate) return '';

    const selectedDate = date.format('YYYY-MM-DD');

    return getExtensiveAge(birthdate, selectedDate);
  }, [patient, date]);

  const handleSave = () => {
    const measureValues = indicators.reduce((acc, curr) => {
      acc[curr.id] = formData[curr.type].replace(curr.unit, '').replace(',', '.').trim();

      return acc;
    }, {});

    if (isEditing) {
      dispatch(indicatorsActions.updateMeasure({
        patientId: patient.id,
        measure: {
          id: measure.id,
          date: moment(date).toISOString(),
          values: measureValues,
        },
      }, (success) => {
        if (!success) return;

        dispatch(openToast({
          type: 'success',
          text: _('toast/measureUpdated'),
          close: true,
          timeout: 3000,
        }));

        onCloseModal();
      }));

      return;
    }


    dispatch(indicatorsActions.createMeasure({
      patientId: patient.id,
      measure: {
        date: moment(date).toISOString(),
        values: measureValues,
      },
    }, (success) => {
      if (!success) return;

      dispatch(openToast({
        type: 'success',
        text: _('toast/measureCreated'),
        close: true,
        timeout: 3000,
      }));

      onCloseModal();
    }));
  };

  const handleCancel = useCallback(() => {
    onCloseModal();
  }, [onCloseModal]);

  const handleSelectDate = ({ value }) => {
    setDate(value);
  };

  const handleDelete = async () => {
    const isDeleteConfirmed = await UserNotifications.confirmI18n('alert/confirm_delete_measure', 'warning', '#FFA433', true);

    if (!isDeleteConfirmed) return;

    dispatch(indicatorsActions.deleteMeasure(measure.id, patient.id, (success) => {
      if (!success) return;

      dispatch(openToast({
        type: 'success',
        text: _('toast/measureDeleted'),
        close: true,
        timeout: 3000,
      }));

      onCloseModal();
    }));
  };

  const renderTodayButton = (selectedDate: string, callback: Function) => {
    const momentSelectedDate = moment(selectedDate, 'DD/MM/YYYY');
    const formattedDate = momentSelectedDate.format('DD/MM/YYYY');
    const formattedToday = moment().format('DD/MM/YYYY');

    const isToday = formattedDate === formattedToday;

    return (
      <>
        <label className='dateLabel'>{_('label/date')} </label>
        <button className="calendarTrigger btn todayButton" onClick={callback}>
          <Icon iconName="calendar" className="icon" size={16} />
          <span className="dateLabel">{isToday ? `${_('label/today')} - ${formattedDate}` : formattedDate}</span>
        </button>
      </>
    );
  };

  const onChange = (type: IndicatorType, value: string = '') => {
    if (!value.match(/^[0-9]+$/)) return;

    const _formData = { ...formData, [type]: value };

    if (_formData?.height && _formData?.weight) {
      const parsedHeight = Number(_formData.height) / 100;
      const parsedWeight = Number(_formData.weight.replace(',', '.'));
      const imcResult = parsedWeight / (parsedHeight * parsedHeight);

      const formattedImc = Intl.NumberFormat('pt-BR', {
        style: 'decimal',
        maximumFractionDigits: 2,
      }).format(imcResult);

      _formData.imc = formattedImc;
    }

    setFormData(_formData);
  };

  const getFieldProps = (ind: Indicator) => {
    const { id, type, name, unit } = ind;

    const textInputs = ['imc'];

    const fieldProps = {
      key: id,
      name: type,
      label: `${name} (${unit})`,
      value: formData[type] || '',
      onChange: ({ value }) => onChange(type, value),
      disabled: type === 'imc',
      type: textInputs.includes(type) ? 'text' : 'number',
    };

    return fieldProps;
  };

  return (
    <Modal id="patientIndicatorsFormModal" onClose={onCloseModal} className="desktop">
      <div className="modalHeader">
        <span className="modalTitle">
          {isEditing ? _('button/editIndicator') : _('button/addIndicator')}
        </span>
      </div>
      <div className="modalContent">
        <DayPickerInput
          advancedMode
          customRenderer={renderTodayButton}
          date={date}
          onSelect={handleSelectDate}
        />

        <Input
          name="age"
          value={age}
          label={_('label/age')}
          disabled
        />

        {indicators.map((indicatorData) => (
          <Input {...getFieldProps(indicatorData)} />
        ))}

        <FormFooterModal
          onSave={handleSave}
          isSaving={isEditing ? isUpdating : isCreating}
          onCancel={handleCancel}
          onDelete={isEditing && handleDelete}
          isDeleting={isDeleting}
        />
      </div>
    </Modal>
  );
};


export default React.memo(PatientIndicatorsFormModal);

