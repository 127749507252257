// @flow
import React from 'react';
import moment from 'moment';
import { router } from '@router';
import { connect } from 'react-redux';
import { get, isEmpty, toString, findKey, find, first, toArray } from 'lodash';
import { mapPlanIncludes, mapPermissionTo } from '@helpers/connect';
import { isPresent, utcFormat } from '@helpers/helpers';
import { patientActions } from '@context/patients';
import { appointmentActions } from '@context/appointments';
import { serviceTemplateActions } from '@context/serviceTemplates';
import { AppointmentModalForm as AppointmentModalFormView } from '@views';
import type {
  Dispatch,
  AppointmentNewRequestData,
  PatientsState,
  PlanInclude,
  Services,
  ClinicsState,
  AppointmentPatients,
  PatientInput,
  ModalsState,
  Appointments,
  User,
  PermissionTo,
} from '@types';
import { withConfirmOnClose } from '@hocs';

type Props = {
  dispatch: Function,
  highPrivacy: boolean,
  singleRoom: boolean,
  singleDoctor: boolean,
  modals: ModalsState,
  newData: AppointmentNewRequestData,
  closeModal: Function,
  id: number, // Only for edit
  patients: PatientsState,
  doctors: any,
  planInclude: PlanInclude,
  services: Services,
  clinics: ClinicsState,
  appointmentPatients: AppointmentPatients,
  currentDoctorID: number,
  appointments: Appointments,
  agendaFilters: Object,
  rooms: any,
  user: User,
  permissions: PermissionTo,
  isMobileSmallScreen: boolean,
  setConfirmation: (confirm: boolean) => void, // Set confirm on close
};

type State = {
  immediateSaving: boolean;
}

const getHighPrivacy = (state: Object) => {
  const highPrivacyPatients = get(state, ['context', 'clinics', 'activeClinic', 'highPrivacyPatients'], false);
  const isManager = get(state, ['context', 'user', 'advancedData', 'manager'], false);
  const isDoctor = get(state, ['context', 'user', 'roleType'], '') === 'Doctor';

  return highPrivacyPatients && !isManager && isDoctor;
};

const formatEditData = (data, full: boolean) => {
  if (full) {
    // console.warn(data);
  }
  return {
    ...data,
    time: utcFormat(get(data, 'time'), 'HH:mm', null),
    patientId: get(data, ['patient', 'id']),
  };
};

class AppointmentModalFormContainer extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      immediateSaving: false,
    };
  }
  componentWillMount() {
    if (!this.props.id || this.isNew()) return;

    this.props.dispatch(appointmentActions.getAppointment(this.props.id));
  }

  render() {
    const {
      newData, setConfirmation, patients, doctors,
      singleDoctor, singleRoom, highPrivacy, planInclude,
      clinics, services, currentDoctorID, appointmentPatients,
      appointments, id, isMobileSmallScreen, permissions,
    } = this.props;

    const { immediateSaving } = this.state;

    const fullData = get(appointments, ['data', 'full', toString(id)], null);
    const isLoadingFullData = get(appointments, ['loadingItems', toString(id)], false);

    return (
      <AppointmentModalFormView
        isSaving={immediateSaving}
        onCreate={this.handleCreate}
        onQuit={this.closeModal}
        newData={newData}
        osSetConfirmation={setConfirmation}
        isNew={this.isNew()}
        isBlockedAppointment={this.isBlockedAppointment()} // Use only for edit mode
        patients={patients}
        doctors={doctors}
        highPrivacy={highPrivacy}
        singleRoom={singleRoom}
        formData={this.getFormData()}
        fullData={formatEditData(fullData, true)}
        singleDoctor={singleDoctor}
        defaultAppointmentDuration={this.getAppointmentDuration()}
        isFinatialEnabled={planInclude.financial}
        clinic={get(clinics, 'activeClinic')}
        servicesTemplates={services}
        currentDoctorID={currentDoctorID}
        appointmentPatients={appointmentPatients}
        onGetPatients={this.handleGetPatients}
        onGetPatient={this.handleGetPatient}
        onCreatePatient={this.handleCreatePatient}
        onDeleteRecurrentEvents={this.handleDeleteRecurrentEvents}
        onGetServicesTemplates={this.handleGetServiceTemplates}
        onGetRecomendedServices={this.handleGetRecomendedServices}
        isLoadingFullData={isLoadingFullData}
        onUpdate={this.handleUpdate}
        onPushRoute={this.handlePushRoute}
        onDelete={this.handleDelete}
        onUpdateStatus={this.handleUpdateStatus}
        closeModal={this.closeModal}
        onHide={this.handleHideAppointment}
        isMobileSmallScreen={isMobileSmallScreen}
        permissions={permissions}
        planInclude={planInclude}
      />
    );
  }
  closeModal = () => this.props.closeModal(false);

  handleUpdateStatus = (status: Object, appointment: Object) => {
    this.props.dispatch(appointmentActions.updateStatusAndAppointment(status, appointment));
  };

  handleHideAppointment = (appointmentId: number) => () => {
    this.props.dispatch(appointmentActions.hideAppointment(appointmentId, () => this.closeModal()));
  };

  isNew = () => !isPresent(this.props.id);

  handleDelete = (deleteAllFutureRecurring: boolean) => {
    const { dispatch, id } = this.props;
    dispatch(appointmentActions.deleteAppointment(id, deleteAllFutureRecurring));
    this.props.closeModal(true);
  };

  handlePushRoute = (route: string, params: Object) => {
    const { dispatch } = this.props;
    dispatch(router.pushPath(route, params));
    this.props.closeModal(true);
  };

  isBlockedAppointment = () => {
    const isNew = !this.isNew();
    const havePatient = !isEmpty(get(this.getEditFormData(), 'patient'));

    return isNew && !havePatient;
  };

  handleCreate = (data: Object) => {
    const newData = this.props.permissions.appointmentDoctorSelect ? data : {
      ...data,
      doctorId: null,
    };

    this.setState({ immediateSaving: true }, () => {
      this.props.dispatch(appointmentActions.createAppointment(newData, (success) => {
        this.setState({ immediateSaving: false });
        if (success) {
          this.props.closeModal(true);
        }
      }));
    });
  };

  handleUpdate = (data: Object) => {
    this.setState({ immediateSaving: true }, () => {
      this.props.dispatch(appointmentActions.updateAppointment(data, (success) => {
        this.setState({ immediateSaving: false });
        if (success) {
          this.props.closeModal(true);
        }
      }));
    });
  };

  getFormData = () => {
    if (this.isNew()) {
      return this.getNewFormData();
    }
    return this.getEditFormData();
  };

  getNewFormData = () => {
    const {
      newData, appointments, doctors, currentDoctorID, clinics, rooms,
    } = this.props;
    const {
      date, duration, isDayClick,
    } = newData;

    const roomsFilter: Array<any> = get(appointments, ['filters', 'roomsFilter'], []);
    const doctorsFilter: Array<any> = get(appointments, ['filters', 'doctorsFilter'], []);

    let doctorId = doctorsFilter.length === 1 ? doctorsFilter[0] : currentDoctorID;
    doctorId = doctorId || (doctorsFilter[0] || get(first(toArray(doctors.data)), 'id'));

    const currentDoctor = get(doctors, ['data', String(doctorId)]);

    let room = roomsFilter.length === 1 ? roomsFilter[0] : get(currentDoctor, 'defaultAppointmentRoom', null);
    room = room || get(first(rooms), 'number');

    let nTime = get(newData, 'time', null);
    const clinicDoctors = get(this.props.doctors, 'data', {});

    if (!isDayClick && !isPresent(nTime)) {
      const businessHoursStart = get(clinics, ['activeClinic', 'businessHoursStart'], '09:00');


      const defDoctor = clinicDoctors[(
        get(this.props.agendaFilters, ['doctorsFilter', String(0)]) ||
      (!get(this.props.user, ['roleType']) === 'Doctor' && !get(this.props.user, ['advancedData', 'doctorId'])) ||
      get(this.props.agendaFilters, ['doctorsFilter', String(1)]) ||
      findKey(clinicDoctors)
      )];
      const workHours = get(defDoctor, 'workHours', []);
      if (workHours.length > 0) {
        const weekDay = find(workHours, wh => wh.weekDay === moment().weekday() + 1); // api starts in 1
        if (weekDay) {
          nTime = get(weekDay, 'start', businessHoursStart);
        } else {
          nTime = businessHoursStart;
        }
      } else {
        nTime = businessHoursStart;
      }
    }

    // businessHoursStart
    const formData = {
      date,
      duration,
      time: nTime || '09:00', // TODO: Fix time to defalt from doctor
      room,
      doctorId,
    };

    if (!formData.duration) {
      formData.duration = this.getAppointmentDuration();
    }

    return formData;
  };

  getEditFormData = () => {
    const { appointments, id } = this.props;
    const data = get(appointments, ['data', 'compact', toString(id)]);

    return {
      ...formatEditData(data, false),
    };
  };

  getAppointmentDuration = (ignoreDayClick: boolean = false) => {
    const {
      duration, isDayClick,
    } = this.props.newData;

    if (isDayClick || !ignoreDayClick) {
      const clinicDoctors = get(this.props.doctors, 'data', {});

      const defaultDuration = get(clinicDoctors[(
        get(this.props.agendaFilters, ['doctorsFilter', String(0)]) ||
        (!get(this.props.user, ['roleType']) === 'Doctor' && !get(this.props.user, ['advancedData', 'doctorId'])) ||
        get(this.props.agendaFilters, ['doctorsFilter', String(1)]) ||
        findKey(clinicDoctors)
      )], 'defaultAppointmentDuration');

      return defaultDuration;
    }

    return duration;
  };

  handleGetPatients = (search: string, rescheduled: boolean) => {
    if (rescheduled) {
      this.props.dispatch(patientActions.getSuggestedRescheduledPatients());
    } else {
      this.props.dispatch(patientActions.getSuggestedPatients(search));
    }
  };

  handleGetPatient = (id: number) => {
    this.props.dispatch(patientActions.getPatient(id, false));
  };

  handleCreatePatient = (data: PatientInput) => {
    this.props.dispatch(patientActions.createPatient(data));
  };

  handleDeleteRecurrentEvents = () => {
    const { modals, id } = this.props;

    this.props.dispatch(appointmentActions.deleteAppointment(id, true), () => {
      const selectedDate = get(modals, ['data', 'agendaFilters', 'selectedDate'], moment().format('YYYY-MM-DD HH:mm:ss'));

      const startDate = moment(selectedDate).startOf('week').add(1, 'day').format('YYYY-MM-DD');
      const endDate = moment(selectedDate).endOf('week').add(1, 'day').format('YYYY-MM-DD');

      this.props.dispatch(appointmentActions.getAppointments(startDate, endDate));
    });
    this.closeModal();
  };

  handleGetServiceTemplates = (doctorId: number, cb: Function) => {
    const { dispatch } = this.props;
    dispatch(serviceTemplateActions.getServiceTemplates(doctorId, cb));
  };

  handleGetRecomendedServices = (doctorId: number, patientId: number, cb: Function) => {
    const { dispatch } = this.props;
    dispatch(serviceTemplateActions.getRecomendedService(doctorId, patientId, cb));
  };
}

const mapStateToProps = (state) => ({
  isMobileSmallScreen: get(state, ['ui', 'window', 'width'], 1024) <= 760 && window.isCordovaApp,
  modals: get(state.context.modals),
  appointmentPatients: get(state, ['context', 'appointmentPatients'], {}),
  services: get(state, ['context', 'serviceTemplates'], {}),
  user: get(state, ['context', 'user'], {}),
  doctors: get(state, ['context', 'doctors'], {}),
  clinics: get(state, ['context', 'clinics'], {}),
  highPrivacy: getHighPrivacy(state),
  id: get(state, ['context', 'modals', 'data', 'appointmentId'], null),
  patients: get(state, ['context', 'patients'], {}),
  singleDoctor: get(state, ['context', 'clinics', 'activeClinic', 'singleDoctor'], false),
  planInclude: mapPlanIncludes(state),
  singleRoom: get(state, ['context', 'clinics', 'activeClinic', 'singleRoom'], false),
  rooms: get(state, ['context', 'clinics', 'activeClinic', 'rooms'], []),
  appointments: get(state, ['context', 'appointments'], {}),
  permissions: mapPermissionTo(state),
  state,


  // isSecretary: get(state, ['context', 'user', 'roleType'], '') !== 'Doctor',
  currentDoctorID: get(state, ['context', 'user', 'advancedData', 'doctorId'], 0),
  agendaFilters: get(state, ['context', 'appointments', 'filters'], {}),

  newData: {
    isDayClick: get(state, ['context', 'modals', 'data', 'isDayClick'], false),
    date: get(state, ['context', 'modals', 'data', 'date'], null),
    time: get(state, ['context', 'modals', 'data', 'time'], null),
    duration: get(state, ['context', 'modals', 'data', 'duration'], null),
    startDate: get(state, ['context', 'modals', 'data', 'startDate'], null),
    endDate: get(state, ['context', 'modals', 'data', 'endDate'], null),
  },
});

const mapDispatchToProps = (dispatch: Dispatch) => ({ dispatch });

export default withConfirmOnClose(false)(connect(
  mapStateToProps,
  mapDispatchToProps,
)(AppointmentModalFormContainer));
