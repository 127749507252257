// @flow
import React from 'react';
import { connect } from 'react-redux';
import { get } from 'lodash';
import { logout } from '@context/user';
import { withRouter } from 'react-router-dom';
import { router } from '@router';
import type { Dispatch, DrugsPrescriptionsTemplate, DrugsPrescriptionsTemplateInput, User, BundledPermissions } from '@types';
import { mapBundledPermission } from '@helpers/connect';
import { DrugsPrescriptionsTemplateForm as DrugsPrescriptionsTemplateFormView } from '@views';
import { drugsPrescriptionsTemplateActions } from '@context/drugsPrescriptionsTemplates';


type Props = {
  drugsPrescriptionsTemplate: DrugsPrescriptionsTemplate,
  dispatch: Function,
  user: User,
  permissions: BundledPermissions,
  isSaving: boolean,
  history: Object,
};


class DrugsPrescriptionsTemplateForm extends React.Component<Props> {
  render() {
    const { drugsPrescriptionsTemplate, user, isSaving } = this.props;
    return (
      <DrugsPrescriptionsTemplateFormView
        drugsPrescriptionsTemplate={drugsPrescriptionsTemplate}
        onCreateDrugsPrescriptionsTemplate={this.handleCreateDrugsPrescriptionsTemplate}
        onUpdateDrugsPrescriptionsTemplate={this.handleUpdateDrugsPrescriptionsTemplate}
        onDeleteDrugsPrescriptionsTemplate={this.handleDeleteDrugsPrescriptionsTemplate}
        user={user}
        isSaving={isSaving}
        onLogout={this.handleLogout}
        permissions={this.props.permissions}
        redirectToPrescriptionsList={this.handleRedirect}
      />
    );
  }

  handleLogout = () => this.props.dispatch(logout());

  handleCreateDrugsPrescriptionsTemplate = (drugsPrescriptionsTemplate: DrugsPrescriptionsTemplateInput) => {
    this.props.dispatch(drugsPrescriptionsTemplateActions.createDrugsPrescriptionsTemplate(drugsPrescriptionsTemplate, this.handleRedirect));
  };

  handleUpdateDrugsPrescriptionsTemplate = (id: number, drugsPrescriptionsTemplate: DrugsPrescriptionsTemplateInput) => {
    this.props.dispatch(drugsPrescriptionsTemplateActions.updateDrugsPrescriptionsTemplate(id, drugsPrescriptionsTemplate, this.handleRedirect));
  };

  handleDeleteDrugsPrescriptionsTemplate = (id: number) => {
    this.props.dispatch(drugsPrescriptionsTemplateActions.deleteDrugsPrescriptionsTemplate(id, this.handleRedirect));
  };

  handleRedirect = (success: boolean) => {
    if (success) {
      this.props.history.push(router.getRoutePath('drugsPrescriptionsTemplatesList'));
    }
  };
}

const mapStateToProps = (state, ownProps: any) => ({
  drugsPrescriptionsTemplate: get(state.context, ['drugsPrescriptionsTemplates', 'data', ownProps.match.params.id], {}),
  isSaving: get(state.context, ['drugsPrescriptionsTemplates', 'isSaving'], false),
  user: state.context.user,
  permissions: mapBundledPermission(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({ dispatch });

export default connect<any, any, any, any, any, any>(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(DrugsPrescriptionsTemplateForm));
