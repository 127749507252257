// @flow
import React from 'react';
import classnames from 'classnames';
import { DoutoreSpinner } from '../../blocks';
import './Loading.scss';

type Props = {
  className?: string,
}
export default class Loading extends React.Component<Props> {
  render() {
    // to-do simplify for mobile
    // if window.isCordovaApp
    // content =
    // <div className="loadingHolder">
    //   <div className="smallLoading">
    //     <DoutoreSpinner />
    //   </div>
    // </div>
    return (
      <div className={classnames('desktopCard', 'internalLoadingContainer', this.props.className)}>
        <div className={classnames('loadingHolder', this.props.className)}>
          <div className="smallLoading">
            <DoutoreSpinner />
          </div>
        </div>
      </div>
    );
  }
}
