// @flow
import React from 'react';
import { get, isNaN, isEmpty, map, forEach, filter, sumBy } from 'lodash';
import {
  Modal, Input, InputMask,
  Toggle, DayPickerInput, ClickableList,
  Checkbox, SelectBox, Loading,
  FormattedText, Button, Icon,
} from '@elements';
import { FormFooterModal, PlatformSwitch } from '@blocks';
import { paymentMethods } from '@constants';
import type { FormElementData, ServicesState } from '@types';
import { withForm } from '@hocs';
import { withTranslation } from 'react-i18next';
import { createNsTranslator, fSufixTwoPoints, handleSubmitEvent, translateConstant, toDate, formatDate } from '@helpers/helpers';
import './PaymentFormModal.scss';
import classNames from 'classnames';


type FormErrors = { [field: string]: string };
type ServicesCheckList = { [key: number]: boolean };

type Props = {
  indebt?: boolean,
  financialBalance?: number,
  onClose: Function,
  onSave: Function,
  onDelete?: Function,
  title: string,
  onSetConfirmOnClose: Function,
  serviceTemplates?: ?ServicesState,
  formData: Object,
  touchedFields: Object,
  bindValidation: Function,
  handleFormChange: Function,
  formChange: Function,
  handleTouchField: Function,
  formEdited: Function,
  t: Function,
};

type State = {
  errors: FormErrors,
  services: ServicesCheckList,
  isPriceChanged: boolean,
  installments: number,
  showInstallments: boolean,
  renderServices: boolean,
};

const servicesItems = (itens, inDebit?: ?boolean, debitValue: number, showHeading: boolean, _: Function, renderServices: boolean) => [
  ...(showHeading ? [
    {
      type: 'heading',
      text: _('action/add_new_services'),
    },
  ] : []),
  ...(inDebit || !renderServices ? [] : map(itens, item => ({
    customRendererData: {
      type: 'clickItem',
      id: item.id,
      text: item.name,
      price: item.price,
    },
  }))),
  ...(!inDebit || !renderServices ? [] : [
    {
      customRendererData: {
        type: 'clickItem',
        text: _('label/debt_balance'),
        price: debitValue > 0 ? debitValue : debitValue * -1,
      },
    },
  ]),
];

const installmentsOptions = [
  { text: '2x', value: 2 },
  { text: '3x', value: 3 },
  { text: '4x', value: 4 },
  { text: '5x', value: 5 },
  { text: '6x', value: 6 },
  { text: '7x', value: 7 },
  { text: '8x', value: 8 },
  { text: '9x', value: 9 },
  { text: '10x', value: 10 },
  { text: '11x', value: 11 },
  { text: '12x', value: 12 },
];

class PaymentFormModal extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      errors: {},
      services: {},
      isPriceChanged: false,
      installments: parseInt(get(this.props.formData, 'installments', 0) || 0, 10),
      showInstallments: parseInt(get(this.props.formData, 'installments', 0) || 0, 10) > 1,
      renderServices: true,
    };
    this.scroolContainer = React.createRef();
  }

  componentDidMount() {
    const { bindValidation } = this.props;
    bindValidation(this.handleValidation);
    handleSubmitEvent('#paymentFormModalSubmit', this.onSave);
  }

  renderServiceItem = (item: Object, key: string) => ([
    <Checkbox
      key={`check-${key}`}
      name="price"
      label={item.text}
      onChange={this.handleToggleService(item.id)}
      isChecked={get(this.state, ['services', String(item.id)], false)}
    />,
    <span key={`span-${key}`}><FormattedText type="currency" text={item.price} /></span>,
  ]);

  render() {
    const {
      onClose, onDelete, title, serviceTemplates, indebt, financialBalance,
      handleFormChange, handleTouchField, formData,
    } = this.props;
    const {
      method, price, notes, withNotaFiscal, notaFiscalNumber, date, id,
    } = formData;

    const { errors, showInstallments } = this.state;

    const _ = createNsTranslator('financial', this.props.t);
    const translatedPaymentMethods = translateConstant(paymentMethods);

    const selectedDate = toDate(date);

    const isLoading = get(serviceTemplates, 'isLoading', false);
    const serviceTemplatesData = get(serviceTemplates, 'data', {});

    const showHeading = !isEmpty(serviceTemplatesData) && parseFloat(financialBalance) >= 0;

    const installments = parseInt(get(formData, 'installments', 0) || 0, 10);

    const headingRenderer = (item: any, key: string, classes: string) => (
      <li key={key} className={classNames(classes, 'checkableHeading')} onClick={this.toggleRenderServices}>
        <div className="textContent">
          {item.text}
        </div>
        <div className="checkboxHolder">
          {(this.state.renderServices) && (
            <React.Fragment>{_('action/hide_list', 'global')}&nbsp;<Icon iconName="arrow-down" /></React.Fragment>
          )}

          {!(this.state.renderServices) && (
            <React.Fragment>{_('action/show_list', 'global')}&nbsp;<Icon iconName="arrow-left" /></React.Fragment>
          )}
        </div>
      </li>
    );

    const content = (
      <React.Fragment>
        {isLoading && <Loading />}
        {!isLoading &&
          <ClickableList
            items={servicesItems(serviceTemplatesData, indebt, parseFloat(financialBalance), showHeading, _, this.state.renderServices)}
            customRenderer={this.renderServiceItem}
            customHeadingRenderer={headingRenderer}
            onListItemClick={this.handleClickService}
            className="checkboxList"
          />
        }

        <form className="form">
          <div className="split">
            <InputMask
              type="currency"
              name="price"
              label={_('label/value', 'global', fSufixTwoPoints)}
              value={price}
              onChange={this.handlePriceChange}
              onBlur={handleTouchField}
              error={get(errors, 'price', null)}
              autofocus
            />
            <DayPickerInput
              label={_('label/date', 'global', fSufixTwoPoints)}
              name="date"
              date={selectedDate}
              onSelect={handleFormChange}
            />
          </div>
          <br />

          <SelectBox
            name="method"
            label={_('label/payment_method', null, fSufixTwoPoints)}
            options={translatedPaymentMethods}
            value={method}
            withoutBorder
            onChange={this.handleMethodChange}
            onBlur={handleTouchField}
          />
          {!id && showInstallments && (
            <div className="inputHolder">
              <Toggle
                name="installments"
                label={_('label/with_installments')}
                isChecked={installments > 1}
                onChange={this.handleSwitchInstallments}
                onBlur={handleTouchField}
              />
            </div>
          )}
          {!id && installments > 1 && showInstallments && (
            <SelectBox
              name="installments"
              label={_('label/installments', null, fSufixTwoPoints)}
              options={installmentsOptions}
              value={installments}
              withoutBorder
              onChange={handleFormChange}
              onBlur={handleTouchField}
            />
          )}

          <Input
            name="notes"
            label={method === 'check' ? _('label/check_number', null, fSufixTwoPoints) : _('label/notes', 'global', fSufixTwoPoints)}
            value={notes}
            onChange={handleFormChange}
            onBlur={handleTouchField}
          />

          <div className="inputHolder">
            <Toggle
              name="withNotaFiscal"
              label={_('label/with_invoice')}
              isChecked={withNotaFiscal}
              onChange={this.handleSwitch}
              onBlur={handleTouchField}
            />
          </div>

          <Input
            name="notaFiscalNumber"
            id="notaFiscalNumber"
            label={_('label/invoice_number', null, fSufixTwoPoints)}
            value={notaFiscalNumber}
            onChange={handleFormChange}
            onBlur={handleTouchField}
            className={withNotaFiscal ? '' : 'hiddenElement'}
          />

        </form>

      </React.Fragment>
    );

    return (
      <PlatformSwitch
        inject
        desktop={() => (
          <Modal id="PaymentsFormModal" onClose={onClose} className="desktop">
            <div className="modalHeader">
              <span className="modalTitle">{title}</span>
            </div>

            <div className="modalGrid">
              <div className="modalContainer">
                <div className="modalContent" ref={this.scroolContainer} id="paymentFormModalSubmit">
                  <div>
                    {content}
                  </div>
                </div>
                <FormFooterModal
                  onCancel={onClose}
                  onDelete={onDelete}
                  onSave={this.onSave}
                />
              </div>

            </div>
          </Modal>
        )}
        mobile={() => (
          <Modal id="PaymentsFormModal" className="asView" onClose={onClose}>
            <div className="modalHeader">
              <Button onClick={onClose}>
                <Icon iconName="close" className="icon" />
              </Button>
              <span className="title">{title}</span>
              {onDelete && <Button className="primary" text={_('action/delete', 'global')} onClick={onDelete} />}
              <Button className="primary" text={_('action/save', 'global')} onClick={this.onSave} />
            </div>
            <div className="modalGrid">
              <div className="modalContainer">
                <div className="modalContent" ref={this.scroolContainer}>
                  {content}
                </div>
              </div>

            </div>
          </Modal>
        )}
      />
    );
  }

  scroolContainer: any;

  handleSwitch = (data: FormElementData) => {
    setTimeout(() => {
      if (data.value === true && this.scroolContainer && this.scroolContainer.current) {
        this.scroolContainer.current.scrollTo(0, this.scroolContainer.current.scrollHeight);
      }
    }, 200);
    this.props.handleFormChange(data);
  };

  handleMethodChange = (data: FormElementData) => {
    if (data.value === 'check' || data.value === 'credit_card' || data.value === 'bank_transfer') {
      this.setState({ showInstallments: true });
    } else {
      this.setState({ showInstallments: false });
    }
    this.props.handleFormChange(data);
  };

  handleSwitchInstallments = (data: FormElementData) => {
    this.props.handleFormChange({ name: 'installments', value: data.value ? 2 : 0 });
    this.setState({ installments: data.value ? 2 : 0 });
  };

  onSave = () => this.handleValidation(true).then(() => {
    const { onSave, serviceTemplates, formData } = this.props;
    const { services, errors } = this.state;

    const {
      date, method, price, notes, withNotaFiscal, notaFiscalNumber, installments,
    } = formData;

    if (isEmpty(errors)) {
      const selectedServicesIds = [];

      forEach(services, (selected, id) => {
        if (selected) {
          selectedServicesIds.push(id);
        }
      });

      const selectedServices = map(selectedServicesIds, (id: any) => {
        const template = get(serviceTemplates, ['data', String(id)], {});

        return {
          templateId: template.id,
          price: parseFloat(template.price),
        };
      });
      onSave({
        payment: {
          date,
          method,
          price,
          notes,
          withNotaFiscal,
          notaFiscalNumber,
          installments,
        },
        serviceTemplates: filter(selectedServices, item => item.templateId !== null && item.templateId !== undefined),
      });
    }
  });

  handleToggleService = (id: number) => (data: FormElementData) => this.setState({
    services: {
      ...this.state.services,
      [id]: get(data, 'value', false),
    },
  }, this.handleItemTotal);

  handleClickService = (item: Object) => this.setState({
    services: {
      ...this.state.services,
      [item.id]: !get(this.state, ['services', String(item.id)], false),
    },
  }, this.handleItemTotal);

  handleItemTotal = () => {
    const {
      formChange, serviceTemplates, indebt, financialBalance,
    } = this.props;

    const serviceTemplatesData = get(serviceTemplates, 'data', {});

    if (!this.state.isPriceChanged) {
      const selectedServices = map<any, any>(filter(serviceTemplatesData, item => get(this.state.services, item.id, false)), item => ({
        ...item,
        price: parseFloat(item.price),
      }));

      let balance = parseFloat(financialBalance);
      balance = balance > 0 ? balance : balance * -1;

      const total = sumBy(selectedServices, 'price');
      formChange('price', indebt ? balance : total, true).then(this.handleConfirmation);
    } else {
      this.handleConfirmation();
    }
  };

  handleValidation = (isSave: boolean = true): Promise<any> => new Promise((resolve: Function) => {
    const {
      formData, touchedFields,
    } = this.props;

    const _ = createNsTranslator('financial', this.props.t);

    const isTouched = field => touchedFields[field] || isSave;

    const errors = {};
    const parsedPrice = parseFloat(formData.price);

    if (isTouched('price') && (parsedPrice === 0 || isNaN(parsedPrice))) {
      errors.price = _('label/error/valid_value');
    }

    this.handleConfirmation();

    this.setState({ errors: { ...errors } }, resolve);
  });

  handleConfirmation = () => {
    const { onSetConfirmOnClose, formEdited } = this.props;

    const haveSelectedServices = Object.keys(this.state.services).length > 0;

    onSetConfirmOnClose(formEdited() || haveSelectedServices);
  }

  handlePriceChange = (data: Object) => {
    this.setState({ isPriceChanged: true }, () => this.props.handleFormChange(data));
  };

  toggleRenderServices = () => this.setState(s => ({ renderServices: !s.renderServices }));
}


const initialData = {
  date: formatDate(new Date()),
  method: 'money',
  price: null,
};

export default withForm(initialData)(withTranslation()(PaymentFormModal));
