// @flow
import React, { useState, useEffect } from 'react';
import { get } from 'lodash';
import { Icon, Button, Modal, Spinner } from '@elements';
import { appointmentActions } from '@context/appointments';
import '../Styles/WhatsAppConfirmationModal.scss';

type Confirmation = {
  message: string;
  link: string;
  error_msg: string;
}

type Props = {
  setModal: Function;
  _: Function,
  appointmentId: number;
}

const WhatsAppConfirmationModal = ({ setModal, _, appointmentId }: Props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [confirmation, setConfirmation] = useState<Confirmation | null>(null);

  useEffect(() => {
    if (!appointmentId) return;

    setIsLoading(true);
    appointmentActions.getAppointmentWhatsAppConfirmationLink(appointmentId, (success, data) => {
      if (!success) return;

      setConfirmation(data);
      setIsLoading(false);
    });
  }, [appointmentId]);

  const handleGoBack = () => {
    setModal(null);
  };

  const handleOpenWhatsAppConfirmationLink = () => {
    if (confirmation === null) return;

    if (window.isCordovaApp) {
      window.open(confirmation.link, '_self');
    } else {
      window.open(confirmation.link, '_blank');
    }
  };

  const hasErrorMessage = Boolean(get(confirmation, 'error_msg', null));

  const message = hasErrorMessage ? get(confirmation, 'error_msg', '') : get(confirmation, 'message', '');

  return (
    <Modal id="patientModal" className="secondaryModal ">
      <div className="modalHeader actionHeader">
        <Button className="backButton" onClick={handleGoBack}>
          <Icon iconName="arrow-left" className="icon" />
        </Button>
        <span className="backButtonLabel">{_('label/whatsApp')}</span>
      </div>
      <div id="whatsAppConfirmationModal">
        <div className={`whatsAppConfirmationModalBody ${isLoading ? 'isLoading' : ''}`}>
          {isLoading && <Spinner text="Carregando..." />}

          {!isLoading && (
            <div className="confirmationContainer">
              <p className={hasErrorMessage ? 'error' : ''}>{message}</p>

              {!hasErrorMessage && (
                <Button className='whatsAppButton' onClick={handleOpenWhatsAppConfirmationLink}>
                  <Icon iconName="whatsapp" className="icon" />

                  <span>{_('label/sendMessage')}</span>
                </Button>
              )}
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default WhatsAppConfirmationModal;
