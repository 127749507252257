// Avoid use of flow due to a buggy testing
import Swal from 'sweetalert2';
import React from 'react';
import ReactDOM from 'react-dom';

import { Icon } from '@elements';

const _ = (v: any) => window.i18n.t(v);

const SwalContenti18nTextRender = ({ i18nKey }: any) => <span>{_(i18nKey)}</span>;

const i18nTextAlert = (textI18n: string) => {
  const htmlContent = document.createElement('div');
  ReactDOM.render((
    <div>
      <SwalContenti18nTextRender i18nKey={`alerts:${textI18n}`} />
    </div>
  ), htmlContent, () => {
    Swal.fire({
      html: `<div>${htmlContent.innerHTML}</div>`,
      customClass: {
        confirmButton: 'btn primary',
      },
    });
  });
};

const recordFriendlyAlert = (textI18n: string, icon: string = 'color-timeline', iconFill?: string) => new Promise(resolve => {
  const htmlContent = document.createElement('div');
  ReactDOM.render((
    <div>
      <Icon iconName={icon} size={128} {...(iconFill ? { fill: iconFill } : {})} />
      <div className="textContent">
        <br />
        <SwalContenti18nTextRender i18nKey={`alerts:${textI18n}`} />
      </div>
    </div>
  ), htmlContent, () => {
    Swal.fire({
      html: `<div>${htmlContent.innerHTML}</div>`,
      customClass: {
        confirmButton: 'btn primary',
      },
    }).then(() => resolve());
  });
});

const confirmI18n = (textI18n: string, icon?: string, iconFill: string = '#000', focusCancel?: boolean = true): Promise<boolean> => new Promise(resolve => {
  const htmlContent = document.createElement('div');
  ReactDOM.render((
    <div>
      {icon && <div style={{ width: '100%' }}><Icon iconName={icon} size={128} {...(iconFill ? { fill: iconFill } : {})} /></div>}
      <SwalContenti18nTextRender i18nKey={`alerts:${textI18n}`} />
    </div>
  ), htmlContent, () => {
    Swal.fire({
      html: `<div>${htmlContent.innerHTML}</div>`,
      showCancelButton: true,
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não',
      reverseButtons: true,
      focusCancel,
      customClass: {
        confirmButton: 'btn danger',
        cancelButton: 'btn',
      },
    }).then(({ value }) => {
      resolve(value);
    }).catch(() => resolve(false));
  });
});

const customConfirmI18n = (textI18n: string,
  confirmButtonText: string,
  cancelButtonText: string,
  icon?: string,
  iconFill: string = '#000',
  focusCancel?: boolean = true,
): Promise<boolean> => new Promise(resolve => {
  const htmlContent = document.createElement('div');
  ReactDOM.render((
    <div>
      {icon && <div style={{ width: '100%' }}><Icon iconName={icon} size={128} {...(iconFill ? { fill: iconFill } : {})} /></div>}
      <SwalContenti18nTextRender i18nKey={`alerts:${textI18n}`} />
    </div>
  ), htmlContent, () => {
    Swal.fire({
      html: `<div>${htmlContent.innerHTML}</div>`,
      showCancelButton: true,
      confirmButtonText: _(`alerts:${confirmButtonText}`),
      cancelButtonText: _(`alerts:${cancelButtonText}`),
      reverseButtons: true,
      focusCancel,
    }).then(({ value }) => {
      resolve(value);
    }).catch(() => resolve(false));
  });
});

const info = (textI18n: string, icon?: string, iconFill: string = '#000'): Promise<boolean> => new Promise(resolve => {
  const htmlContent = document.createElement('div');
  ReactDOM.render((
    <div>
      {icon && <div style={{ width: '100%' }}><Icon iconName={icon} size={128} {...(iconFill ? { fill: iconFill } : {})} /></div>}
      <SwalContenti18nTextRender i18nKey={`alerts:${textI18n}`} />
    </div>
  ), htmlContent, () => {
    Swal.fire({
      html: `<div>${htmlContent.innerHTML}</div>`,
      showCancelButton: false,
      confirmButtonText: 'Ok',
    }).then(({ value }) => {
      resolve(value);
    }).catch(() => resolve(false));
  });
});


export default {
  i18nTextAlert, recordFriendlyAlert, confirmI18n, info, customConfirmI18n,
};
