// @flow
import React from 'react';
import { PlatformSwitch, AppMobileHeader, SettingsMenu, AppMobileFooter, ConditionalFeature } from '@blocks';
import { Breadcrumb, Button } from '@elements';
import type { User, BundledPermissions } from '@types';
import { createNsTranslator } from '@helpers/helpers';
import { withTranslation } from 'react-i18next';
import './BackupRequest.scss';

type Props = {
  isLoading: boolean,
  onRequest: Function,
  success: boolean,
  userEmail: string,
  user: User,
  onLogout: Function,
  t: Function,
  permissions: BundledPermissions,
};

class BackupRequest extends React.Component<Props> {
  render() {
    const {
      isLoading, success, userEmail, user, onLogout, permissions,
    } = this.props;

    const _ = createNsTranslator('settings', this.props.t);

    const breadcrumbLinks = [
      { text: _('action/menu/support'), linkTo: '/ajustes/suporte' },
      { text: _('label/support/menu/backup') },
    ];


    let pageContent;
    if (success) {
      pageContent = (
        <React.Fragment>
          <h1>{_('label/support/backup/success/title')}</h1>
          {_('label/support/backup/success/text')} <br />
          <span className="userEmail">{userEmail}</span>
          <div className="requestButtonContainer">
            <Button
              className="primary"
              linkTo="/pacientes/lista"
            >
              Voltar ao Doutore
            </Button>
          </div>
        </React.Fragment>
      );
    } else {
      pageContent = (
        <React.Fragment>
          <div className="toolbar dashed">
            <h1>{_('label/support/menu/backup')}</h1>
          </div>
          <p>{_('label/support/backup/text/line1')}&nbsp;{_('label/support/backup/text/line2')}&nbsp;{_('label/support/backup/text/line3')}</p>
          <br/>

          <p><b>{_('label/support/backup_excel/text/line1_title')}&nbsp;</b>{_('label/support/backup_excel/text/line1')}{_('label/support/backup_excel/text/line2')}</p>
          <div className="requestButtonContainer">
            <Button
              className="secondary"
              onClick={this.handleRequestPatientsBackup}
              loadingMessage={_('label/support/backup/wait')}
              isLoading={isLoading}
            >
              {_('label/support/backup/request_backup_patients')}
            </Button>
          </div>

          <p><b>{_('label/support/backup_complete/text/line1_title')}&nbsp;</b>{_('label/support/backup_complete/text/line1')}{_('label/support/backup_complete/text/line2')}</p>
          <div className="requestButtonContainer">
            <Button
              className="secondary"
              onClick={this.handleRequestCompleteBackup}
              loadingMessage={_('label/support/backup/wait')}
              isLoading={isLoading}
            >
              {_('label/support/backup/request_backup_complete')}
            </Button>
          </div>


          <p>
            <br />{_('label/support/backup/support_action_lbl')}&nbsp;<a onClick={this.handleSupport}>{_('label/support/backup/support_action_btn')}</a>
          </p>
        </React.Fragment>
      );
    }

    return (
      <PlatformSwitch
        inject
        desktop={() => (
          <div className="appContent settingsPage" id="manualBackupPage">
            <aside className="sidebar">
              <SettingsMenu user={user} onLogout={onLogout} permissions={permissions} />
            </aside>
            <section className="mainSection">
              <ConditionalFeature
                enabled={permissions.permissionTo.backup}
                customMessage={_('label/backup_request_blocked')}
                hint={_('label/blocked/hint/global', 'global')}
                hideUpgradeButton
              >
                <div className="centerContent">
                  <Breadcrumb items={breadcrumbLinks} />
                  {pageContent}
                </div>
              </ConditionalFeature>
            </section>
          </div>
        )}
        mobile={() => (
          <React.Fragment>
            <AppMobileHeader title={_('label/support/menu/backup')} backLinkTo="/ajustes/suporte" />
            <div className="appContent settingsPage" id="manualBackupPage">
              <section className="mainSection">
                <ConditionalFeature
                  enabled={permissions.permissionTo.backup}
                  customMessage={_('label/backup_request_blocked')}
                  hint={_('label/blocked/hint/global', 'global')}
                  hideUpgradeButton
                >
                  <div className="centerContent">{pageContent}</div>
                </ConditionalFeature>
              </section>
            </div>
            <AppMobileFooter />
          </React.Fragment>
        )}
      />
    );
  }
  handleRequestPatientsBackup = () => {
    this.props.onRequest(false);
  };
  handleRequestCompleteBackup = () => {
    this.props.onRequest(true);
  };
  handleSupport = () => {
    if (window.Intercom) {
      window.Intercom('show');
    }
  };
}

export default withTranslation()(BackupRequest);
