import { map } from 'lodash';

function normalizeServices (inputServices) {
  const services = {};

  map(inputServices, (service) => {
    services[service.id] = service;
  });

  return {
    services,
  };
}

export default {
  normalizeServices,
};
