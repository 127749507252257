import React from 'react';
import { connect } from 'react-redux';
import { UserNotifications } from '@helpers/helpers';
import { closeActiveModal } from '../redux/modules/context/modals';


type Props = {
    dispatch: Function,
};

type State = {
    canConfirmModalClose: boolean,
};

/**
 * When use compose pass withConfirmOnClose() before of redux connect
 * @param {boolean} defaultConfirm
 * @property {Function(boolean)} setConfirmation
 * @property {Function(boolean)} closeModal
 */
export const withConfirmOnClose = (defaultConfirm: boolean = false) => (WrappedComponent: any) => {
  class TComponent extends React.Component<Props, State> {
    constructor(props: Props) {
      super(props);
      this.state = {
        canConfirmModalClose: defaultConfirm,
      };
    }

    render() {
      const newProps = {
        ...this.props,
        setConfirmation: this.handleSetConfirmation,
        closeModal: this.closeModal,
      };

      return (<WrappedComponent {...newProps} />);
    }

    handleSetConfirmation = (confirm: boolean) => this.setState({ canConfirmModalClose: confirm });

    handleCloseModal = () => this.props.dispatch(closeActiveModal());

    closeModal = (forceClose: boolean = false) => {
      if (this.state.canConfirmModalClose && !forceClose) {
        UserNotifications.confirmI18n('alert/confirm_quit_form', null, null, true)
          .then((value) => {
            if (value) {
              this.handleCloseModal();
            }
          });
      } else {
        this.handleCloseModal();
      }
    };
  }

  return connect(null, (dispatch) => ({ dispatch }))(TComponent);
};
