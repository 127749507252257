// @flow
import React from 'react';
import { connect } from 'react-redux';
import { get } from 'lodash';
import { RecordsPrivacy as RecordsPrivacyView } from '@views';
import type { User, BundledPermissions } from '@types';
import { updateDoctorPrivacy, logout } from '@context/user';
import { mapBundledPermission } from '@helpers/connect';


type Props = {
  user: User,
  doctorDefaultVisibilityLevel: number,
  isUpdatingPrivacy: boolean,
  userLoading: boolean,
  dispatch: Function,
  clinicId: number,
  doctorId: number,
  permissions: BundledPermissions,
};

class RecordsPrivacy extends React.Component<Props> {
  render() {
    const {
      user, doctorDefaultVisibilityLevel, isUpdatingPrivacy, userLoading,
    } = this.props;
    return (
      <RecordsPrivacyView
        user={user}
        onSave={this.handleOnSave}
        formData={{ doctorDefaultVisibilityLevel: parseInt(doctorDefaultVisibilityLevel, 10) }}
        isLoading={isUpdatingPrivacy}
        userLoading={userLoading}
        onLogout={this.handleLogout}
        permissions={this.props.permissions}
      />
    );
  }

  handleLogout = () => this.props.dispatch(logout());

  handleOnSave = (doctorDefaultVisibilityLevel: number) => {
    const { dispatch, clinicId, doctorId } = this.props;
    dispatch(updateDoctorPrivacy(clinicId, doctorId, doctorDefaultVisibilityLevel));
  };
}

const mapStateToProps = (state) => ({
  user: state.context.user,
  doctorDefaultVisibilityLevel: get(state, ['context', 'user', 'advancedData', 'doctorDefaultVisibilityLevel'], null),
  doctorId: get(state, ['context', 'user', 'advancedData', 'doctorId'], null),
  clinicId: get(state, ['context', 'clinics', 'activeClinic', 'id'], null),
  isUpdatingPrivacy: get(state, ['context', 'user', 'isUpdatingPrivacy'], false),
  userLoading: get(state, ['context', 'user', 'userLoading'], false),
  permissions: mapBundledPermission(state),
});

export default connect<any, any, any, any, any, any>(
  mapStateToProps,
)(RecordsPrivacy);
