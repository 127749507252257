// @flow
import React from 'react';
import { SettingsMenu, PlatformSwitch, AppMobileHeader, AppMobileFooter } from '@blocks';
import { ClickableList, Alert } from '@elements';
import { redirectToDashboard } from '@context/user';
import type{ User, BundledPermissions } from '@types';
import { isBetaApp, useTranslationNs } from '@helpers/helpers';
import { useSelector } from 'react-redux';
import { get } from 'lodash';

type Props = {
  user: User,
  accessToken: string,
  appVersion: string,
  onUpdateApp: Function,
  onLogout: Function,
  isIntercomEnabled: boolean,
  subscriptionActive: boolean,
  permissions: BundledPermissions,
};

type Item = {
  text: string,
  type: string,
  to?: string,
};

const Support = ({
  accessToken, user, appVersion, isIntercomEnabled, onLogout, permissions, onUpdateApp, subscriptionActive,
}: Props) => {
  const clinicId = useSelector((state) => get(state, ['context', 'clinics', 'activeClinic', 'id']));

  const onListItemClick = (item: Item) => {
    switch (item.type) {
      case 'onlineSupport':
        if (window.Intercom) {
          window.Intercom('show');
        }
        break;
      case 'update':
        onUpdateApp();
        break;
      case 'navigateToDashboard':
        if (item.to) redirectToDashboard(item.to, accessToken);
        break;
      default:
        break;
    }
  };

  const onSecretClick = () => {
    window.secretClicks = (window.secretClicks || 0) + 1;
    if (window.secretClicks >= 10) {
      window.secretClicks = 0;
      // eslint-disable-next-line
      window.initReactotron(prompt('Reactotron IP', ''));
    }
  };

  const [_] = useTranslationNs('settings');

  const items = [
    ...(permissions.dashboard ? [{ text: _('label/dashboard', 'layout'), type: 'navigateToDashboard', to: `/users/${user.id}/clinics/${clinicId}/appointments` }] : []),

    {
      text: _('label/support/menu/refresh'),
      type: 'update',
    },
    ...(isIntercomEnabled ? [{
      text: _('label/support/menu/chat'),
      type: 'onlineSupport',
    }] : []),
    ...(subscriptionActive && !isBetaApp() ? [
      {
        text: _('label/support/menu/to_appx'),
        type: 'appx',
        externalLinkTo: 'https://appx.doutore.com',
      },
    ] : []),
    ...(isBetaApp() ? [
      {
        text: _('label/support/menu/to_app'),
        type: 'appx',
        externalLinkTo: 'https://app4.doutore.com',
      },
    ] : []),
    {
      text: _('label/support/menu/backup'),
      type: 'backup',
      linkTo: '/ajustes/suporte/backup',
    },
    {
      text: _('label/support/menu/terms'),
      type: 'termsOfUse',
      externalLinkTo: 'https://www.doutore.com/termos_de_uso',
    },
    {
      text: _('label/support/menu/privacyPolicy'),
      externalLinkTo: 'https://www.doutore.com/politica_de_privacidade/',
    },
    ...(window.isCordovaApp ? [
      {
        text: _('label/support/menu/deleteMyAccount'),
        linkTo: '/ajustes/deletar-minha-conta',
      },

    ] : []),
  ];

  return (
    <PlatformSwitch
      inject
      desktop={() => (
        <div className="appContent settingsPage">
          <aside className="sidebar">
            <SettingsMenu user={user} onLogout={onLogout} permissions={permissions} />
          </aside>
          <section className="mainSection">
            <div className="centerContent">
              <div className="toolbar dashed">
                <h1>Suporte Técnico</h1>
              </div>
              <Alert text={`${_('label/support/menu/version')}: ${appVersion}`} onClick={onSecretClick} />
              <ClickableList
                items={items}
                card
                onListItemClick={onListItemClick}
              />
            </div>
          </section>
        </div>
      )}
      mobile={() => (
        <React.Fragment>
          <AppMobileHeader title="Suporte Técnico" backLinkTo="/ajustes" />
          <div className="appContent settingsPage">
            <section className="mainSection">
              <div className="centerContent">
                <Alert text={`${_('label/support/menu/version')}: ${appVersion}`} onClick={onSecretClick} />
                <ClickableList
                  items={items}
                  card
                  onListItemClick={onListItemClick}
                />
              </div>
            </section>
          </div>
          <AppMobileFooter />
        </React.Fragment>
      )}
    />
  );
};

export default Support;
