export default {
  'label/agenda': 'Agenda',
  'label/patients': 'Pacientes',
  'label/reports': 'Informes',
  'label/settings': 'Configuraciones',
  'label/support': 'Soporte',
  'label/user_guide': 'Guía del Doctore',
  'label/help': 'Ayuda',
  'label/remaining_trial_days': 'días de prueba gratuitos restantes',
  'action/see_details': 'Ver detalles',
  'action/reload': 'Actualizar',
  'action/ai': 'Inteligencia Artificial',
  'action/click_to_resolve': 'Haz clic aquí para resolver',
  'action/logout': 'Salir de Doutore',
  'label/account_will_be_canceled': 'Tu cuenta será cancelada pronto.',
  'label/payment_problem': 'No hemos recibido el pago del Doctor.',
  'label/online_support': 'Soporte en línea',
  'label/dashboard': 'Panel de Control',
  'label/beta_app': 'Versión Beta',
};
