// @flow
import React from 'react';
import classnames from 'classnames';
import { get, map, find } from 'lodash';
import { AppMobileHeader, AppMobileFooter } from '@blocks';
import type { FormElementData, Patient, PlanInclude, ComponentStorage } from '@types';
import { Icon, Button, Tag, TextArea, Spinner } from '@elements';
import { withTranslation } from 'react-i18next';
import { createNsTranslator } from '@helpers/helpers';
import { withStorage } from '@hocs';
import './PatientWidgets.scss';

type Props = {
  onTagsFormClick: Function,
  componentStorage: ComponentStorage,
  onMedicationsFormClick: Function,
  onProblemsFormClick: Function,
  onSaveHistoryNote: Function,
  onRemoveTag: Function,
  patient: Patient,
  historyNoteLoading: boolean,
  planInclude: PlanInclude,
  visible?: ?boolean,
  isMobile?: ?boolean,
  onMobileBackClick?: ?Function,
  t: Function,
};

type State = {
  showSideBar: boolean,
  toogledMobileVisible: boolean,
  historyNote: string,
  touchedFields: {[key: string]: string},
};

class PatientWidgets extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      showSideBar: this.props.componentStorage.get('showSideBar', true),
      historyNote: get(props.patient, 'historyNote', ''),
      touchedFields: {},
      toogledMobileVisible: false,
    };
  }

  componentWillMount() {
    if (this.state.showSideBar) {
      window.document.body.classList.add('withTimelineWidgets');
    } else {
      window.document.body.classList.remove('withTimelineWidgets');
    }
  }

  componentWillReceiveProps(next: Props) {
    if (next.visible) {
      this.setState({ toogledMobileVisible: true });
    }
    if (get(this.props, ['patient', 'historyNote'], '') !== get(next, ['patient', 'historyNote'], '')) {
      this.setState({ historyNote: get(next, ['patient', 'historyNote'], '') });
    }
  }

  componentWillUnmount() {
    window.document.body.classList.remove('withTimelineWidgets');
  }

  render() {
    const {
      onTagsFormClick, onMedicationsFormClick, onProblemsFormClick,
      patient, historyNoteLoading, planInclude, visible, isMobile,
    } = this.props;
    const {
      showSideBar, historyNote, touchedFields, toogledMobileVisible,
    } = this.state;

    const _ = createNsTranslator('timeline', this.props.t);

    const patientTags = get(this.props, ['patient', 'tags'], []);

    const historyNoteTouched = get(touchedFields, 'historyNote', false);

    const sidebarClasses = classnames(
      [
        'sidebar',
        'right',
        visible ? 'visible' : toogledMobileVisible && 'invisible',
      ],
      {
        hide: !showSideBar,
        mobile: isMobile,
      },
    );

    const medications = get(patient, 'medications', []);
    const problems = get(patient, 'problems', []);

    return (
      <aside id="patientWidgets" className={sidebarClasses}>
        {isMobile && <AppMobileHeader backIcon="close" title={_('label/medic_info')} onBackClick={this.handleMobileBackCLick} inline />}
        {!isMobile && (
          <div className="sidebarTrigger" onClick={this.handleToggleSideBar}>
            <Icon iconName="arrow-right" className="icon" />
          </div>
        )}
        <div className={classnames(['container'], { androidNavBarPadding: isMobile })}>
          <div className={`reminderWidget ${(historyNoteTouched || historyNoteLoading) ? 'saveEnable' : ''}`}>
            <span className="title">{_('label/reminder')}</span>
            <TextArea
              name="historyNote"
              value={historyNote}
              onChange={this.handleFormChange}
              readOnly={historyNoteLoading}
              autoGrow={false}
            />
            {historyNoteTouched && !historyNoteLoading &&
            <Button
              className="saveNoteBtn"
              text={_('action/save_reminder')}
              onClick={this.handleSaveHistoryNote}
            />
            }
            {historyNoteLoading &&
            <Button
              className="saveNoteBtn"
              text={<Spinner text={_('label/saving', 'global')} />}
              onClick={this.handleSaveHistoryNote}
            />
            }
          </div>

          <div className="tagsWidget widget">
            <span className="title">
              <span>{_('label/tag_plural', 'global')}</span>
              <span className="edit" onClick={onTagsFormClick}>Editar</span>
            </span>

            <div className="buttonHolder">
              <Button onClick={onTagsFormClick}>
                <span>{_('action/add_tag')}</span>
                <Icon iconName="add-circle-outline" className="icon" size={15} />
              </Button>
            </div>

            <div className="tagsHolder">
              {map(patientTags, (tag) => {
                if (!tag) return null;
                return (
                  <Tag
                    key={tag.templateId || tag.id}
                    label={tag.name}
                    onRemove={this.onRemoveTag}
                  />
                );
              })}
            </div>
          </div>

          {planInclude.advancedWidgets &&
          <div className="listWidget widget">
            <span className="title">
              <span>Problemas</span>
              <span className="edit" onClick={onProblemsFormClick}>
                {_('action/edit', 'global')}
              </span>
            </span>

            <ul className="listWidgetContent">
              {map(problems, (problem) => (
                <li>{problem.name}</li>
              ))}
            </ul>

            <div className="buttonHolder">
              <Button onClick={onProblemsFormClick}>
                <span>{_('action/add_problem')}</span>
                <Icon iconName="add-circle-outline" className="icon" size={15} />
              </Button>
            </div>
          </div>
          }
          {planInclude.advancedWidgets &&
            <div className="listWidget widget">
              <span className="title">
                <span>{_('label/medications')}</span>
                <span className="edit" onClick={onMedicationsFormClick}>
                  {_('action/edit', 'global')}
                </span>
              </span>

              <ul className="listWidgetContent">
                {map(medications, (medication) => (
                  <li>{medication.name}</li>
                ))}
              </ul>

              <div className="buttonHolder">
                <Button onClick={onMedicationsFormClick}>
                  <span>{_('action/add_medications')}</span>
                  <Icon iconName="add-circle-outline" className="icon" size={15} />
                </Button>
              </div>
            </div>
          }
        </div>
        {isMobile && <AppMobileFooter />}
      </aside>
    );
  }

  handleToggleSideBar = () => {
    this.setState({ showSideBar: !this.state.showSideBar }, () => {
      this.props.componentStorage.set('showSideBar', this.state.showSideBar);
      if (this.state.showSideBar) {
        window.document.body.classList.add('withTimelineWidgets');
      } else {
        window.document.body.classList.remove('withTimelineWidgets');
      }
    });
  };

  handleFormChange = (data: FormElementData) => {
    const { name, value } = data;
    this.setState({
      [name]: value,
      touchedFields: {
        [name]: true,
      },
    });
  };

  handleSaveHistoryNote = () => {
    const { historyNote } = this.state;
    const { onSaveHistoryNote } = this.props;
    this.setState({ touchedFields: {} }, () => onSaveHistoryNote(historyNote));
  };

  onRemoveTag = (tagLabel: string) => {
    const patientTags = get(this.props, ['patient', 'tags'], []);
    const forRemove = find(patientTags, (tag) => (tag.name === tagLabel));

    this.props.onRemoveTag(forRemove);
  };

  handleMobileBackCLick = () => {
    if (typeof this.props.onMobileBackClick === 'function') {
      this.props.onMobileBackClick();
    }
    return false;
  };
}

export default withStorage('widget-state', 'sidebar')(withTranslation()(PatientWidgets));
