// @flow
import React from 'react';
import { connect } from 'react-redux';
import { get } from 'lodash';
import { logout } from '@context/user';
import { mapBundledPermission, mapPlanIncludes } from '@helpers/connect';
import { CustomFormsList as CustomFormsListView } from '../components/views';
import { customFormTemplateActions } from '../redux/modules/context/customFormTemplates';
import type { Dispatch, CustomFormTemplatesState, User, BundledPermissions, PlanInclude } from '../types';

type Props = {
  customFormTemplates: CustomFormTemplatesState,
  user: User,
  dispatch: Function,
  permissions: BundledPermissions,
  planInclude: PlanInclude,
};

class CustomFormsList extends React.Component<Props> {
  componentWillMount() {
    this.props.dispatch(customFormTemplateActions.getCustomFormTemplates(true));
  }

  render() {
    const { user, planInclude } = this.props;

    return (
      <CustomFormsListView
        customFormTemplates={this.props.customFormTemplates}
        user={user}
        onLogout={this.handleLogout}
        permissions={this.props.permissions}
        featureEnabled={planInclude.customForms}
      />
    );
  }
  handleLogout = () => this.props.dispatch(logout());
}

const mapStateToProps = (state) => ({
  customFormTemplates: get(state.context, ['customFormTemplates'], {}),
  user: state.context.user,
  permissions: mapBundledPermission(state),
  planInclude: mapPlanIncludes(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({ dispatch });

export default connect<any, any, any, any, any, any>(
  mapStateToProps,
  mapDispatchToProps,
)(CustomFormsList);
