// @flow
import { get } from 'lodash';
import type { UiState, LayoutState } from '@types';


export const checkIfIsMobile = (ui: UiState | LayoutState) => window.isCordovaApp && (get(ui, ['window', 'width'], 0) < 1020);

export const handlePrecisionVisibility = (value: number, floor: number = 1.0, fractionDigits: number = 2) => (value >= floor ? value.toFixed(0) : value.toFixed(fractionDigits));


