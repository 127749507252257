// @flow
import React from 'react';
import { map } from 'lodash';
import { Icon, Input, Button, Modal, Spinner } from '@elements';
import { PlatformSwitch } from '@blocks';
import type { Problem } from '@types';
import classNames from 'classnames';
import { withTranslation } from 'react-i18next';
import { createNsTranslator } from '@helpers/helpers';

import './Widgets.scss';

type Props = {
  onClose: Function,
  onAddNewItem: Function,
  problems: Array<Problem>,
  onDeleteItem: Function,
  t: Function,
  isLoading: boolean,
};

type State = {
  newItem: string,
  addMode: boolean,
};

class ProblemsFormModal extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      newItem: '',
      addMode: false,
    };
  }
  render() {
    const { onClose, problems, isLoading } = this.props;
    const { newItem, addMode } = this.state;
    const isSaveEnabled = String(newItem).trim().length > 0;

    const _ = createNsTranslator('timeline', this.props.t);

    const content = (mobile: boolean) => (
      <React.Fragment>
        {addMode &&
        <form method="POST" onSubmit={this.handleSubmit}>
          <div className={classNames(['addToList', mobile && 'mobile'])}>
            <Input
              name="name"
              autofocus
              placeholder={_('label/problem_input')}
              value={newItem}
              onChange={this.handleNewItemChange}
            />
            {isSaveEnabled &&
            <Button
              className={classNames('primary', mobile && 'addMobileButtonFull')}
              text={_('action/add', 'global')}
              onClick={this.handleAddNewItem}
            />
            }
          </div>
        </form>
        }
        {!addMode &&
        <div className={classNames(['addToListBtn', mobile && 'btn'])} onClick={this.handleAddItem}>
          <Icon iconName="add-circle-outline" className="icon" size={15} fill="rgb(63, 81, 181)" />&nbsp;{_('label/create_new_problem')}
        </div>
        }
        <ul className="list">
          {map(problems, (medication, index: number) => (
            <li key={`medicationItem${index}`}>
              <span>{medication.name}</span>
              {!isLoading && <Icon iconName="delete" size={20} onClick={this.handleDeleteMedication(medication.id)} />}
              {isLoading && <Spinner />}
            </li>
          ))}
        </ul>
      </React.Fragment>
    );

    return (
      <PlatformSwitch
        inject
        desktop={() => (
          <Modal className="widgetModal" onClose={onClose}>
            <div className="modalHeader">
              <span className="modalTitle">{_('label/problems')}&nbsp;{isLoading && <Spinner />}</span>
            </div>

            <div className="modalGrid">
              <div className="modalContainer">
                <div className="modalContent manageTagsContainer">
                  {content(false)}
                </div>
              </div>
            </div>
          </Modal>
        )}
        mobile={() => (
          <Modal className="asView" onClose={onClose}>
            <div className="modalHeader">
              <Button onClick={onClose}>
                <Icon iconName="close" className="icon" />
              </Button>
              <span className="title">{_('label/problems')}</span>
              {isLoading && <Spinner />}
              <Button onClick={onClose}>
                <Icon iconName="done" className="icon" />
              </Button>
            </div>
            <div className='problemsMobileContainer'>
              <div>
                {content(true)}
              </div>
            </div>
          </Modal>
        )}
      />
    );
  }

  handleNewItemChange = (data: Object) => {
    this.setState({ newItem: data.value });
  };

  handleAddNewItem = () => {
    this.props.onAddNewItem(this.state.newItem);
    this.setState({ newItem: '', addMode: false });
  };

  handleDeleteMedication = (id: number) => () => {
    this.props.onDeleteItem(id);
  };

  handleAddItem = () => {
    this.setState({ addMode: true });
  };

  handleSubmit = (e: Object) => {
    e.stopPropagation();
    e.preventDefault();
    this.handleAddNewItem();
  };
}

export default withTranslation()(ProblemsFormModal);
