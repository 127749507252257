// @flow
import React from 'react';
import { connect } from 'react-redux';
import { get } from 'lodash';
import type { SubscriptionState } from '@types';
import { getSubscriptionTrack } from '@context/subscription';
import { Benefits as BenefitsView } from '@views';

type Props = {
  subscription: SubscriptionState,
  dispatch: Function,
};


class Benefits extends React.Component<Props> {
  componentWillMount() {
    this.props.dispatch(getSubscriptionTrack('benefits'));
  }

  render() {
    const { subscription } = this.props;

    return (
      <BenefitsView isLoading={get(subscription, 'isLoading', false)} />
    );
  }
}

const mapStateToProps = (state) => ({
  subscription: get(state, ['context', 'subscription'], {}),
});

export default connect<any, any, any, any, any, any>(
  mapStateToProps,
)(Benefits);
