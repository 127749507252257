export default {
  'label/service_menu/confirmed': 'Marcar como Contratado',
  'label/service_menu/canceled': 'Marcar como Cancelado',
  'label/service_menu/forecasted': 'Marcar como Previsto',
  'label/service_menu/edit': 'Editar',
  'label/payment_menu/verified': 'Marcar como Verificado',
  'label/payment_menu/unverified': 'Marcar como Não Verificado',
  'label/payment_menu/on_debt': 'Marcar como em Débito',
  'label/payment_menu/new_receipt': 'Gerar Recibo',
  'label/payment_menu/edit': 'Editar',
  'label/tab_items/profile': 'Cadastro',
  'label/tab_items/timeline': 'Prontuário',
  'label/tab_items/financial': 'Financeiro',
  'action/new_service': 'Novo Serviço',
  'action/new_payment': 'Novo Pagamento',
  'action/new_receipt': 'Novo Recibo',
  'action/create_new_receipt': 'Criar Novo Serviço',
  'action/edit_services': 'Editar Serviços',
  'label/with_installments': 'Parcelar pagamento',
  'label/installments': 'Nº de parcelas',
  'label/services': 'Serviços',
  'label/payments': 'Pagamentos',
  'label/receipts': 'Recibos',
  'label/balance': 'Saldo',
  'label/financial': 'Financeiro',
  'label/services_empty/title': 'Não há serviços registrados.',
  'label/receipts_empty/title': 'Não há recibos registrados.',
  'label/payments_empty/title': 'Não há pagamentos registrados.',
  'label/new_service_to': 'Novo Serviço para',
  'label/edit_service': 'Editar Serviço',
  'label/error/name': 'Informe um nome',
  'label/error/date': 'Selecione uma data',
  'label/error/value': 'Informe um valor válido',
  'label/new_receipt_to': 'Novo Recibo para',
  'label/service_provided': 'Serviço Prestado',
  'label/additional_informations': 'Informações Adicionais',
  'label/seller_name': 'Nome Completo do Profissional',
  'label/seller_cpf': 'CPF do Profissional',
  'action/patient_not_buyer': 'Pagamento não realizado pelo paciente',
  'label/buyer': 'Responsável pelo pagamento',
  'label/buyer_name': 'Nome do Responsável pelo Pagamento',
  'label/buyer_cpf': 'CPF do Responsável pelo Pagamento',
  'label/error/service': 'Informe um serviço',
  'label/error/price': 'Informe um preço',
  'label/error/cpf': 'Informe um CPF válido',
  'label/error/patient_name': 'Nome do paciente requerido',
  'label/error/seller': 'Profissional requerido',
  'label/error/buyer': 'Informe o nome do responsável',
  'label/service_taker': 'Tomador dos serviços',
  'label/responsible_info': 'Responsável pelo pagamento e tomador dos serviços',
  'label/service_provider': 'Prestador dos serviços',
  'label/receipt_title': 'Recibo de Honorários nº',
  'label/payment_method': 'Meio de Pagamento',
  'label/check_number': 'Núm. do Cheque/Boleto',
  'label/with_invoice': 'Com Nota Fiscal',
  'label/invoice_number': 'Número da Nota',
  'label/error/valid_value': 'Informe um valor válido.',
  'action/add_new_services': 'Adicionar Novos Serviços',
  'label/debt_balance': 'Saldo Devedor',
  'action/search_service': 'Buscar serviço existente',
};
