// @flow
import { map } from 'lodash';
import type { TagTemplate } from '../types';

function normalizeTagTemplates (inputTagTemplates: Array<TagTemplate>) {
  const tagTemplates = {};

  map(inputTagTemplates, (tagTemplate) => {
    tagTemplates[tagTemplate.id] = tagTemplate;
  });

  return {
    tagTemplates,
  };
}

export default {
  normalizeTagTemplates,
};
