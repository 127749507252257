// @flow
import React from 'react';
import { clamp, isEqual } from 'lodash';
import classNames from 'classnames';
// import { CSSTransition } from 'react-transition-group';
import './ProgressBar.scss';

type Props = {
  show?: boolean,
};

type State = {
  percent: number,
  ended: boolean,
  destroyed: boolean,
};

const STEP = 5;
const DURATION = 300; // based on transition-duration in css
const END_DURATION = 300; // based on transition-duration in css

export default class ProgressBar extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      percent: 0,
      ended: false,
      destroyed: false,
    };
  }

  componentDidMount() {
    this.initializeProgress();
  }

  componentWillReceiveProps(nextProps: Props) {
    if (nextProps.show === true && this.props.show === false) {
      this.initializeProgress();
      this.setState({ ended: false, percent: 0, destroyed: false });
    }

    if ((this.props.show === true || !this.state.ended) && nextProps.show === false) {
      clearInterval(this.interval);
      this.setState({ percent: 100 }, () => setTimeout(
        () => this.setState({ ended: true },
          () => setTimeout(() => this.setState({ percent: 0, destroyed: true }), END_DURATION),
        ), DURATION));
    }
  }

  shouldComponentUpdate(nextProps: Props, nextState: State) {
    return !isEqual(this.props, nextProps) || !isEqual(this.state, nextState);
  }

  componentWillUnmount() {
    if (this.interval) {
      clearInterval(this.interval);
    }
  }

  render() {
    const { ended, percent, destroyed } = this.state;

    if (destroyed) {
      return null;
    }

    return (
      <div className={classNames('progressBarWrapper', { ended })}>
        <span className="progressBar" style={{ width: `${percent}%` }} />
      </div>
    );
  }
  interval: any = null;

  initializeProgress = () => {
    if (this.interval) {
      clearInterval(this.interval);
    }
    this.interval = setInterval(() => this.setState({ percent: clamp(this.state.percent + STEP, 0, 90) }), 500);
  }
}
