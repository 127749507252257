// @flow
import React from 'react';
import { get } from 'lodash';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { PatientFormModal } from '@views';
import { createPatient, updatePatient, resetLoadingState } from '@context/patients';
import { withConfirmOnClose } from '@hocs';
import { router } from '@router';
import { isPresent } from '@helpers/helpers';
import { mapPermissionTo } from '@helpers/connect';
import moment from 'moment';
import type { Dispatch, Doctors, Clinic, PatientInput, PermissionTo } from '@types';

type Props = {
  dispatch: Function,
  patient?: ?PatientInput,
  id?: ?number,
  doctors: Doctors,
  clinic: Clinic,
  setConfirmation: Function,
  closeModal: Function,
  isSaving: boolean,
  permissionTo : PermissionTo,
  rawError?: any;
  focus: string;
};

class PatientFormModalContainer extends React.Component<Props> {
  componentWillMount() {
    this.props.dispatch(resetLoadingState());
  }
  render() {
    const {
      doctors, clinic, patient,
      setConfirmation, isSaving, permissionTo, rawError, focus,
    } = this.props;

    const birthdate = moment(get(patient, 'birthdate', null));

    const formData = {
      ...patient,
      birthdate: birthdate.isValid() ? birthdate.format('DD/MM/YYYY') : '',
    };

    return (
      <PatientFormModal
        onClose={this.closeModal}
        onSave={this.onSave}
        doctors={doctors}
        clinic={clinic}
        formData={this.isEdit ? formData : {}}
        onSetConfirmation={setConfirmation}
        isSaving={isSaving}
        isEdit={this.isEdit()}
        permissionTo={permissionTo}
        rawError={rawError}
        focus={focus}
      />
    );
  }

  isEdit = () => isPresent(this.props.id);

  closeModal = this.props.closeModal;

  onSave = (data: PatientInput) => {
    const { id, dispatch } = this.props;
    data.completed = true;
    if (id) {
      dispatch(updatePatient(id, data, () => this.closeModal(true)));
    } else {
      dispatch(createPatient(data, (success: boolean, createdId: number) => {
        if (success) {
          this.closeModal(true);
          dispatch(router.pushPath('patientProfile', { id: createdId }));
        }
      }));
    }
  };
}

const mapDispatchToProps = (dispatch: Dispatch) => ({ dispatch });


const mapStateToProps = (state) => ({
  patient: get(state, ['context', 'patients', 'data', get(state, ['context', 'modals', 'data', 'id'], {})], {}),
  focus: get(state, ['context', 'modals', 'data', 'focus'], null),
  doctors: get(state, ['context', 'doctors', 'data'], {}),
  clinic: get(state, ['context', 'clinics', 'activeClinic'], {}),
  id: get(state, ['context', 'modals', 'data', 'id'], null),
  isSaving: get(state, ['context', 'patients', 'isSaving'], false),
  patients: get(state, ['context', 'patients'], false),
  rawError: get(state, ['context', 'patients', 'rawError'], false),
  permissionTo: mapPermissionTo(state),
});

export default compose(
  connect<any, any, any, any, any, any>(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withConfirmOnClose(),
)(PatientFormModalContainer);
