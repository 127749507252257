// @flow
import React from 'react';
import { get, sumBy, size } from 'lodash';
import { AppMobileHeader, ReportPage, ReportsMenu, PlatformSwitch, EmptyState, ConditionalFeature, BlockedReport, AppMobileFooter } from '@blocks';
// import { AppMobileHeader, ReportsMenu, PlatformSwitch, EmptyState } from '@blocks';
import { Loading, FormattedText, Table } from '@elements';
import type { ReportsDebitData } from '@types';
import { withTranslation } from 'react-i18next';
import { createNsTranslator } from '@helpers/helpers';
import './ReportsDebt.scss';

type Props = {
  data: ReportsDebitData,
  isLoading: boolean,
  onItemClick: Function,
  // onRefresh: Function,
  isFeatureEnabled: boolean,
  t: Function,
  singleDoctor: boolean,
  isReportBlocked: boolean,
}

class ReportsDebt extends React.Component<Props> {
  render() {
    const {
      isLoading, data, isFeatureEnabled, singleDoctor, isReportBlocked,
    } = this.props;

    const _ = createNsTranslator('reports', this.props.t);

    if (isReportBlocked) {
      return (
        <BlockedReport
          backLinkTo="/relatorios"
          title={_('debt/debtor_patients')}
        />
      );
    }

    const indebtPatients = get(data, 'patients', []);
    const indebtPayments = get(data, 'payments', []);

    const patientsTotal = sumBy(indebtPatients, item => parseFloat(item.financialBalance));

    const renderEmptyState = () => (
      <EmptyState
        title={_('debt/empty_title')}
        hint={_('debt/empty_hint')}
      />
    );


    const isPaymentsItensEmpty = size(indebtPayments) === 0;
    const isPatientsItensEmpty = size(indebtPatients) === 0;
    const isAllTablesEmpty = isPaymentsItensEmpty && isPatientsItensEmpty;

    const indebtTable = () => (
      <Table
        itemKey="id"
        columns={[
          {
            dataIndex: 'date',
            content: _('label/date', 'global'),
            onRenderItem: (item) => <FormattedText type="date" text={item.date} />,
          },
          {
            dataIndex: 'method',
            content: _('label/payment_method_short', 'global'),
            onRenderItem: (item) => <FormattedText type="paymentMethod" text={item.method} />,
          },
          {
            dataIndex: 'name',
            content: _('label/value', 'global'),
            rowClass: 'negative',
            onRenderItem: (item) => <FormattedText type="currency" text={item.price} />,
          },
          {
            dataIndex: 'patientName',
            content: _('label/patient', 'global'),
          },
          {
            dataIndex: 'doctorName',
            content: _('label/doctor', 'global'),
            hidden: singleDoctor,
          },
          {
            dataIndex: 'id',
            content: <React.Fragment>&nbsp;</React.Fragment>,
            className: 'actionCell',
            desktopOnly: true,
            onRenderItem: () => <span className="linkAction">{_('action/view_patient', 'global')}</span>,
          },
        ]}
        fullTable
        isLoading={isLoading}
        items={indebtPayments}
        onItemClick={this.onItemClick}
        emptyMessage={_('services/empty_title')}
        mobileClickLabel={_('action/view_patient', 'global')}
      />
    );


    const reportTable = () => (
      <Table
        itemKey="id"
        columns={[
          {
            dataIndex: 'indebtedAt',
            content: _('label/last_change', 'global'),
            onRenderItem: (item) => <FormattedText type="date" text={item.indebtedAt} />,
          },
          {
            dataIndex: 'financialBalance',
            content: _('label/balance', 'global'),
            onRenderItem: (item) => <FormattedText type="currency" text={item.financialBalance} options={{ allowColor: true }} />,
          },
          {
            dataIndex: 'name',
            content: _('label/patient', 'global'),
            rowClass: 'negative',
          },
          {
            dataIndex: 'id',
            content: <React.Fragment>&nbsp;</React.Fragment>,
            className: 'actionCell',
            desktopOnly: true,
            onRenderItem: () => <span className="linkAction">{_('action/view_patient', 'global')}</span>,
          },
        ]}
        fullTable
        isLoading={isLoading}
        items={indebtPatients}
        onItemClick={this.onItemClick}
        mobileClickLabel={_('action/view_patient', 'global')}
        renderFooter={(isMobile: boolean) => (
          <React.Fragment>
            {!isMobile && (
              <tr>
                <td>
                  {_('label/total', 'global')}
                </td>
                <td><FormattedText type="currency" text={patientsTotal} options={{ allowColor: true }} /></td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
              </tr>
            )}
            {isMobile && (
              <table className="table">
                <tbody>
                  <tr>
                    <td>
                      {_('label/total', 'global')}
                    </td>
                    <td><FormattedText type="currency" text={patientsTotal} options={{ allowColor: true }} /></td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                  </tr>
                </tbody>
              </table>
            )}
          </React.Fragment>
        )}
      />
    );


    return (
      <PlatformSwitch
        inject
        desktop={() => (
          <div id="reportsDebtPage" className="appContent reportsPage">
            <ReportsMenu />
            <section className="mainSection">
              <ConditionalFeature enabled={isFeatureEnabled} transparent>
                <div className="centerContent">
                  <div className="toolbar">
                    <h1>{_('debt/debtor_patients')}</h1>
                  </div>
                  {isLoading &&
                    <Loading />
                  }
                  {!isLoading && isAllTablesEmpty &&
                    renderEmptyState()
                  }
                  {!isAllTablesEmpty && !isLoading &&
                    <div className="desktopCard">
                      {!isPaymentsItensEmpty &&
                        <section>
                          <div className="toolbar">
                            <h2>{_('debt/debit_playments')}</h2>
                          </div>
                          {indebtTable()}
                        </section>
                      }

                      {!isPatientsItensEmpty &&
                        <section>
                          <div className="toolbar">
                            <h2>{_('debt/patients_with_negative_balance')}</h2>
                          </div>
                          {reportTable()}
                        </section>
                      }
                    </div>
                  }
                </div>
              </ConditionalFeature>
            </section>
          </div>
        )}
        mobile={() => (
          <React.Fragment>
            <AppMobileHeader title={_('debt/debtor_patients')} />
            <ReportPage
              id="reportsDebtPage"
              isLoading={isLoading}
              isFeatureEnabled={isFeatureEnabled}
              emptyState={() => isAllTablesEmpty && renderEmptyState()}
              noPadding
            >
              <ReportPage.Table title={_('debt/patients_with_negative_balance')}>
                {reportTable()}
              </ReportPage.Table>
            </ReportPage>
            <AppMobileFooter />
          </React.Fragment>
        )}
      />
    );
  }

  onItemClick = (item: Object) => this.props.onItemClick(item.patientId || item.id);
}

export default withTranslation()(ReportsDebt);
