// @flow
import React from 'react';
import type { Node } from 'react';
import { AppMobileNoHeader } from '@elements';

type Props = {
  children: Node,
};

const MainLayout = ({ children }: Props) => {
  const cssId = window.isCordovaApp ? 'mobileApp' : 'desktopApp';
  return (
    <main id={cssId} className="externalLayout mainContainer">
      <AppMobileNoHeader />
      {children}
    </main>
  );
};

export default MainLayout;
