import React, { useState, useMemo } from 'react';
import Lightbox from 'react-image-lightbox';
import { Icon } from '@elements';

import './ImageView.scss';

type Props = {
  src: string,
  thumbnailSrc?: string,
  thumbnailClassName?: string,
  alt?: string,
  onClickDelete?: Function,
  onErrorLoad?: Function,
}

const ImageView = ({
  src,
  thumbnailSrc,
  thumbnailClassName = '',
  alt = '',
  onClickDelete,
  onErrorLoad,
}: Props) => {
  const [isOpen, setIsOpen] = useState(false);

  const onOpenLightbox = () => {
    setIsOpen(true);
  };

  const onCloseLightbox = () => {
    setIsOpen(false);
  };

  const toolbarButtons = useMemo(() => {
    const buttons = [];

    if (onClickDelete) {
      buttons.push(
        <Icon iconName='delete' className='icon' size={23} onClick={onClickDelete} />,
      );
    }

    return buttons;
  }, [onClickDelete]);

  return (
    <>
      <button className={`unstyled-button ${thumbnailClassName}`} onClick={onOpenLightbox}>
        <img className="photo" src={thumbnailSrc} alt={alt} onError={onErrorLoad} />
      </button>

      {isOpen && (
        <Lightbox
          mainSrc={src}
          toolbarButtons={toolbarButtons}
          onCloseRequest={onCloseLightbox}
        />
      )}
    </>
  );
};

export default ImageView;
