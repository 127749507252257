// @flow
import React from 'react';
import { map, get } from 'lodash';
import { connect } from 'react-redux';
import { logout } from '@context/user';
import { mapBundledPermission } from '@helpers/connect';
import { TagTemplatesList as TagTemplatesListView } from '@views';
import type { User, TagTemplateState, BundledPermissions } from '@types';
import { tagTemplateActions } from '@context/tagTemplates';
import { openToast } from '@context/toasts';

type Props = {
  user: User,
  tagTemplates: TagTemplateState,
  dispatch: Function,
  permissions: BundledPermissions,
};

const mapTagTemplatesToClickableList = item => ({
  linkTo: item.special && item.id ? null : `/ajustes/etiquetas/${item.id}`,
  customRendererData: {
    id: item.id,
    text: item.name,
    special: item.special,
  },
});

class TagTemplatesList extends React.Component<Props> {
  componentDidMount() {
    this.props.dispatch(tagTemplateActions.listTagTemplate(true));
  }

  render() {
    const { user, tagTemplates } = this.props;
    return (
      <TagTemplatesListView
        user={user}
        tagTemplates={map(get(tagTemplates, 'data', {}), mapTagTemplatesToClickableList)}
        isLoading={get(tagTemplates, 'isLoading', false) || get(tagTemplates, 'isSaving', false)}
        onLogout={this.handleLogout}
        permissions={this.props.permissions}
        onShowBlockMessage={this.handleShowBlockedMessage}
      />
    );
  }

  handleLogout = () => this.props.dispatch(logout());

  handleShowBlockedMessage = () => {
    this.props.dispatch(openToast({
      type: 'inProgress',
      text: 'Essa etiqueta não pode ser editada',
      close: true,
      timeout: 4000,
    }));
  };
}

const mapStateToProps = (state) => ({
  user: state.context.user,
  tagTemplates: state.context.tagTemplates,
  permissions: mapBundledPermission(state),
});

export default connect<any, any, any, any, any, any>(
  mapStateToProps,
)(TagTemplatesList);
