// @flow
import React from 'react';
import { isEmpty, get, filter, includes, forEach, size } from 'lodash';
import { Icon, Button, Modal, Spinner, ClickableList, SearchInput } from '@elements';
import { Link } from 'react-router-dom';
import type { ServicesState, Service, Doctor, Patient } from '@types';
import { router } from '@router';
import { isPresent, sort, alphabeticCompare, filterSearch } from '@helpers/helpers';
import { servicesRenderer, storedServicesRenderer } from '../utils/customRenderers';
import { createServicesHeading, serviceToListItem } from '../utils/utils';
import classNames from 'classnames';
import { OFFLINE_SEARCH_EDGE } from '@helpers/constants';

type State = {
  previewServicesMode: boolean,
  searchTerm: string,
  privateVisible: boolean,
  healthInsuranceVisible: boolean,
};

type Props = {
  onChange: Function,
  servicesTemplates: ServicesState,
  currentServices: Array<Object>,
  serviceIds: Array<number>,
  setModal: Function,
  isNew: boolean,
  recomendedService: Service,
  fetchServices: Function,
  isLockedForLoading: boolean,
  _: Function,
  doctor: Doctor,
  patient: Patient,
};

const noServices = (isNew: boolean, _: Function, doctorId: number, isSearch: boolean, previewServicesMode: boolean) => (
  <div className="noServicesMsg">
    {!isSearch && (
      <React.Fragment>
        {isNew || !previewServicesMode ? _('label/services/empty_new') : _('label/services/empty_edit')}.
      </React.Fragment>
    )}
    {isSearch && (
      <React.Fragment>
        {_('label/services/empty_search')}.
      </React.Fragment>
    )}
    {doctorId && <Link to={router.getRoutePath('serviceTemplatesList', { doctorId })}>{}</Link>}
  </div>
);

export default class ServicesSelectModal extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      previewServicesMode: !this.props.isNew,
      searchTerm: '',
      privateVisible: true,
      healthInsuranceVisible: true,
    };
  }

  componentWillMount() {
    this.props.fetchServices();
  }

  render() {
    const {
      servicesTemplates, serviceIds, recomendedService,
      currentServices, isNew, _, doctor, patient,
    } = this.props;
    const { previewServicesMode } = this.state;

    const services = !previewServicesMode ? get(servicesTemplates, 'data') : currentServices;
    // console.warn(currentServices)

    let servicesItems = [];


    if (!previewServicesMode) {
      let particularServices: Array<Service> = filter(services, s => !s.healthInsurance && get(recomendedService, 'id') !== s.id);
      let healthInsuranceServices: Array<Service> = filter(services, s => s.healthInsurance && get(recomendedService, 'id') !== s.id);

      particularServices = sort(particularServices, alphabeticCompare('name'));
      healthInsuranceServices = sort(healthInsuranceServices, alphabeticCompare('name'));

      if (!isEmpty(recomendedService)) {
        servicesItems.push(createServicesHeading(_('label/services/recents')));
        servicesItems.push({
          type: 'recomended',
          className: 'listItem chk',
          key: `recomended-service-${recomendedService.id}`,
          customRendererData: {
            service: recomendedService,
          },
        });
      }

      if (particularServices.length > 0) {
        servicesItems.push({ ...createServicesHeading(_('label/services/private')), private: true });
        [...particularServices || []].forEach(service => servicesItems.push({ ...serviceToListItem(service), private: true }));
      }

      if (healthInsuranceServices.length > 0) {
        servicesItems.push(createServicesHeading(_('label/services/health_plan')));
        [...healthInsuranceServices || []].forEach(service => servicesItems.push({ ...serviceToListItem(service), private: false }));
      }
    } else {
      forEach(services, service => {
        servicesItems.push(serviceToListItem(service));
      });
    }

    const isLoading = (get(servicesTemplates, 'isLoading') || this.props.isLockedForLoading);

    const servicesCount = size(servicesItems);

    servicesItems = filter(filterSearch(servicesItems, this.state.searchTerm, ['customRendererData', 'service', 'name']));


    const particularCount = size(filter(servicesItems, (it: any) => !!it.private));
    const healthInsuranceCount = size(filter(servicesItems, (it: any) => !it.private));

    if (!this.state.privateVisible) {
      servicesItems = filter(servicesItems, (item: any) => (item.private && item.type === 'heading') || !item.private);
    }

    if (!this.state.healthInsuranceVisible) {
      servicesItems = filter(servicesItems, (item: any) => (!item.private && item.type === 'heading') || item.private);
    }

    if (particularCount === 1) {
      servicesItems = filter(servicesItems, (item: any) => (item.private && item.type !== 'heading') || !item.private);
    }

    if (healthInsuranceCount === 1) {
      servicesItems = filter(servicesItems, (item: any) => (!item.private && item.type !== 'heading') || item.private);
    }

    const headingRenderer = (item: any, key: string, classes: string) => (
      <li key={key} className={classNames(classes, 'checkableHeading')} onClick={item.private ? this.handleTogglePrivateVisibility : this.handleToggleHealthInsuranceVisibility}>
        <div className="textContent">
          {item.text}
        </div>
        <div className="checkboxHolder">
          {(item.private ? this.state.privateVisible : this.state.healthInsuranceVisible) && (
            <React.Fragment>{_('action/hide_list', 'global')}&nbsp;<Icon iconName="arrow-down" /></React.Fragment>
          )}

          {!(item.private ? this.state.privateVisible : this.state.healthInsuranceVisible) && (
          <React.Fragment>{_('action/show_list', 'global')}&nbsp;<Icon iconName="arrow-left" /></React.Fragment>
          )}
        </div>
      </li>
    );

    const content = () => (
      <React.Fragment>
        {(servicesItems.length <= 0) && noServices(isNew, _, get(doctor, 'id'), isPresent(this.state.searchTerm), previewServicesMode)}
        {servicesItems.length > 0 && (
          <ClickableList
            key='services'
            items={servicesItems}
            customHeadingRenderer={headingRenderer}
            customRenderer={!previewServicesMode ? servicesRenderer(serviceIds, this.handleServiceClick) : storedServicesRenderer(this.handleServiceClick)}
          />
        )}
      </React.Fragment>
    );

    const actions = (
      <React.Fragment>
        {!previewServicesMode && !isNew && (
        <div className="listItem backToPreview" onClick={this.backToPreview}>
          <span
            key="backToPreviewRendererText"
            role='presentation'
          >
            <Icon iconName="arrow-left" className="icon" size={20} />
            <span>{_('action/services/view_selected_services')}</span>
          </span>
        </div>
        )}
        {previewServicesMode && (
        <div className="listItem addServiceIcon" onClick={this.onAddService}>
          <span
            key="storedServices"
            role='presentation'
          >
            <Icon iconName="plus" className="icon" size={20} />
            <span>{_('action/services/add_services')}</span>
          </span>
        </div>
        )}
      </React.Fragment>
    );

    return (
      <Modal id="patientModal" className="secondaryModal appointmentServicesModal">
        <div className="modalHeader actionHeader">
          <Button className="backButton" onClick={this.handleBack}>
            <Icon iconName="arrow-left" className="icon" />
          </Button>
          <span className="backButtonLabel">{_('label/services/service_list')}</span>
        </div>
        {get(patient, 'vip', false) && (
          <div className="patientVipMsg">
            {_('label/patient_vip')}
          </div>
        )}
        {actions}
        {!previewServicesMode && servicesCount >= OFFLINE_SEARCH_EDGE && !isLoading && (
          <div className="serviceSelectModalSearchContainer">
            <SearchInput onChange={this.handleSearch} debounced autofocus mobileAutofocus/>
          </div>
        )}
        <div className="scrollContent">
          {(!(isLoading && !previewServicesMode) || (previewServicesMode)) && content()}
          {!previewServicesMode && isLoading && <Spinner text={_('label/services/loading')} />}
        </div>
      </Modal>
    );
  }

  handleSearch = (term: string) => {
    this.setState({ searchTerm: term, healthInsuranceVisible: true, privateVisible: true });
  };

  onAddService = () => {
    if (this.props.isLockedForLoading) return;
    this.setState({ previewServicesMode: false });
  };
  backToPreview = () => this.setState({ previewServicesMode: true });

  handleServiceClick = (id: number) => () => {
    const { serviceIds } = this.props;
    if (!isPresent(id)) return;
    if (!includes(serviceIds, id)) {
      const value = [...serviceIds, id];
      this.props.onChange({ name: 'serviceIds', value });
    } else {
      const value = filter(serviceIds, i => i !== id);
      this.props.onChange({ name: 'serviceIds', value });
    }
  };

  handleTogglePrivateVisibility = () => {
    this.setState(s => ({
      privateVisible: !s.privateVisible,
    }));
  };

  handleToggleHealthInsuranceVisibility = () => {
    this.setState(s => ({
      healthInsuranceVisible: !s.healthInsuranceVisible,
    }));
  };

  handleBack = () => this.props.setModal(null);
}
