// @flow
import React from 'react';
import { PatientPayments as PatientPaymentsView } from '../components/views';

export default class PatientPayments extends React.Component<void> {
  render() {
    return (
      <PatientPaymentsView />
    );
  }
}
