// @flow
import { values, get, toString, merge, debounce, size, pick } from 'lodash';
import moment from 'moment';
import React, { useState, useMemo, useCallback, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Agenda as AgendaView } from '@views';
import type { AgendaFilters } from '@types';
import { actions } from '@context/modals';
import { isPresent, Log, formatDateToApi } from '@helpers/helpers';
import { appointmentActions } from '@context/appointments';
import { mapPlanIncludes, mapPermissionTo } from '@helpers/connect';
import { deepEqual } from 'fast-equals';

const shallowEqual = (l, r) => deepEqual(l, r);

const getHighPrivacy = (state: Object) => {
  const highPrivacyPatients = get(state, ['context', 'clinics', 'activeClinic', 'highPrivacyPatients'], false);
  const isManager = get(state, ['context', 'user', 'advancedData', 'manager'], false);
  const isDoctor = get(state, ['context', 'user', 'roleType'], '') === 'Doctor';

  return highPrivacyPatients && !isManager && isDoctor;
};



const Agenda = () => {
  const appointments = useSelector(state => state.context.appointments, shallowEqual);
  const highPrivacy = useSelector(state => getHighPrivacy(state), shallowEqual);
  const agendaFilters = useSelector(state => get(state, ['context', 'appointments', 'filters'], {}), shallowEqual);
  const loadingItems = useSelector(state => get(state, ['context', 'appointments', 'loadingItems'], {}), shallowEqual);
  const user = useSelector(state => state.context.user, shallowEqual);
  const activeClinic = useSelector(state => get(state, ['context', 'clinics', 'activeClinic'], {}), shallowEqual);
  const isSecretary = useSelector(state => get(state, ['context', 'user', 'roleType'], '') !== 'Doctor', shallowEqual);
  const currentDoctorID = useSelector(state => get(state, ['context', 'user', 'advancedData', 'doctorId'], 0), shallowEqual);
  const orderDoctorsByProfession = useSelector(state => get(state, ['context', 'clinics', 'activeClinic', 'orderDoctorsByProfession'], false), shallowEqual);
  const doctors = useSelector(state => state.context.doctors, shallowEqual);
  const modals = useSelector(state => state.context.modals, shallowEqual);
  const planInclude = useSelector(state => mapPlanIncludes(state), shallowEqual);
  const permissions = useSelector(state => mapPermissionTo(state), shallowEqual);

  const [appointmentId, setAppointmentId] = useState(null);
  const [immediateLoading, setImmediateLoading] = useState(false);
  const dispatch = useDispatch();
  const initialized = useRef(false);

  const debouncedSearch = useRef(debounce((start: any, end: any, hardReload: boolean) => {
    if (start) {
      dispatch(appointmentActions.getAppointments(start, end, hardReload));
    } else {
      Log.error(`Invalid date ${start}`);
    }
  }, 300));

  const isLoading = useMemo(() => get(appointments, 'isLoading', false) || immediateLoading, [appointments, immediateLoading]);


  useEffect(() => {
    setImmediateLoading(appointments ? appointments.isLoading : false);
  }, [appointments]);


  const getDoctorsFilter = useCallback(() => {
    const doctorsFilter = get(agendaFilters, 'doctorsFilter', null);

    if (doctorsFilter) {
      return doctorsFilter;
    }

    if (isSecretary) {
      return [parseInt(Object.keys(get(doctors, 'data', {}))[0], 10)];
    }

    return [currentDoctorID];
  }, [agendaFilters, currentDoctorID, doctors, isSecretary]);


  const handleGetAppointments = useCallback((singleDay, hardReload: boolean = false, incCalendarDate) => {
    let start;
    let end;

    let selectedDate = get(agendaFilters, ['selectedDate'], formatDateToApi(new Date()));

    if (incCalendarDate) {
      selectedDate = moment.utc(incCalendarDate).format('YYYY-MM-DD HH:mm:ss');
    }

    selectedDate = moment(selectedDate).isValid() ? selectedDate : formatDateToApi(new Date());

    if (singleDay) {
      start = moment.utc(selectedDate, 'YYYY-MM-DD HH:mm:ss').startOf('day').format('YYYY-MM-DD HH:mm:ss');
      end = moment.utc(selectedDate, 'YYYY-MM-DD HH:mm:ss').endOf('day').format('YYYY-MM-DD HH:mm:ss');
    } else {
      start = moment.utc(selectedDate, 'YYYY-MM-DD HH:mm:ss').startOf('isoWeek').format('YYYY-MM-DD HH:mm:ss');
      end = moment.utc(selectedDate, 'YYYY-MM-DD HH:mm:ss').endOf('isoWeek').format('YYYY-MM-DD HH:mm:ss');
    }



    const appointmentDateFilters = get(appointments, ['filters', 'data']);
    if (!appointmentDateFilters) {
      debouncedSearch.current(start, end, hardReload === true);
    } else {
      debouncedSearch.current(start, end, hardReload === true);
    }
    setImmediateLoading(true);
  }, [agendaFilters, appointments]);

  const handleShowAppointment = useCallback((event) => {
    if (get(loadingItems, String(get(event, 'id', '')), false)) return;

    const data = {
      appointmentId: get(event, 'id', ''),
      doctorIds: Object.keys(get(doctors, 'data', {})),
      agendaFilters,
    };

    dispatch(actions.openModal('AppointmentModalForm', data));
    setAppointmentId(get(event, 'id', ''));
  }, [agendaFilters, dispatch, doctors, loadingItems]);

  const handleUpdateAppointment = useCallback((appointment) => {
    dispatch(appointmentActions.updateAppointment(appointment));
  }, [dispatch]);

  const handleNewAppointment = useCallback((doctorsFilter, start, end, isDayClick) => {
    const date = get(agendaFilters, 'selectedDate') ?
      moment(get(agendaFilters, 'selectedDate', moment())).format('YYYY-MM-DD HH:mm:ss')
      : moment(start).format('YYYY-MM-DD HH:mm:ss');

    const data: any = {
      doctorIds: Object.keys(get(doctors, 'data', {})),
      agendaFilters,
      date: moment(date).format('YYYY-MM-DD HH:mm:ss'),
      isDayClick,
    };

    if (moment.isMoment(start)) {
      data.time = moment(start).format('HH:mm');
    } else {
      data.time = moment().hour(9).minute(0).format('HH:mm');
    }

    if (start && end) {
      merge(data, {
        date: moment(start).format('YYYY-MM-DD HH:mm:ss'),
        time: moment(start).format('HH:mm'),
        duration: moment.duration(moment.utc(end).diff(moment.utc(start))).asMinutes(),
        startDate: moment(start).format('YYYY-MM-DD HH:mm:ss'),
        endDate: moment(end).format('YYYY-MM-DD HH:mm:ss'),
      });
    }

    dispatch(actions.openModal('NewAppointmentModal', data));
    setAppointmentId(null);
  }, [agendaFilters, dispatch, doctors]);

  const handleChangeAgendaFilters = useCallback((filters: AgendaFilters) => {
    dispatch(appointmentActions.setAgendaFilters(filters));
  }, [dispatch]);

  useEffect(() => {
    if (initialized.current === false) {
      const params = window.location.hash.replace('#/agenda?', '').split(/&|=/g);
      const doctorId = params[3];
      const date = params[1];

      const newFilters = {};

      if (isPresent(date)) {
        newFilters.selectedDate = moment(date, 'DD-MM-YYYY').format('YYYY-MM-DD HH:mm:ss');
      }

      if (doctorId && isPresent(doctorId) && doctorId !== 'null' && parseInt(doctorId, 10) > 0) {
        newFilters.doctorsFilter = [parseInt(doctorId, 10)];
      }

      handleChangeAgendaFilters(newFilters);
      handleGetAppointments(false, true);
      initialized.current = true;
    }
  },
  // eslint-disable-next-line
  []);


  const activeModal = useMemo(() => get(modals, ['active'], false), [modals]);
  const fullAppointment = useMemo(() => get(appointments, ['data', 'full', toString(appointmentId)]), [appointmentId, appointments]);
  const selectedDate = useMemo(() => get(agendaFilters, 'selectedDate', moment().format('YYYY-MM-DD HH:mm:ss')) || moment().format('YYYY-MM-DD HH:mm:ss'), [agendaFilters]);
  const compactData = useMemo(() => get(appointments, ['data', 'compact'], {}), [appointments]);
  // const startOfSelectionWeek = useMemo(() => moment(selectedDate).startOf('isoWeek').isoWeekday(1).format('YYYY-MM-DD'), [selectedDate]);
  // const appointmentDateFilter = useMemo(() => get(appointments, ['filters', 'data', startOfSelectionWeek]), [appointments, startOfSelectionWeek]);

  const compactAppointments = useMemo(() => {
    const activeIncomingKey = moment.utc(selectedDate).isoWeekday(1).startOf('isoWeek').format('YYYY-MM-DD');
    const incomingKeys = get(appointments, ['incomingKeys', activeIncomingKey], []) || [];
    return pick(compactData, incomingKeys);
  }, [appointments, selectedDate, compactData]);


  const showProgressBar = useMemo(() => isLoading === true && size(compactData) > 0, [compactData, isLoading]);


  const agendaFiltersRender = useMemo(() => ({
    selectedDate,
    switchMode: get(agendaFilters, 'switchMode', 'week'),
    isMobilePickerOpened: get(agendaFilters, 'isMobilePickerOpened', false),
    roomsFilter: get(agendaFilters, 'roomsFilter', null),
    doctorsFilter: getDoctorsFilter(),
  }), [agendaFilters, getDoctorsFilter, selectedDate]);


  return (
    <AgendaView
      agendaFilters={agendaFiltersRender}
      activeModal={activeModal}
      appointments={values(compactAppointments)}
      isLoading={isLoading}
      showProgressBar={showProgressBar}
      activeClinic={activeClinic}
      doctors={doctors}
      user={user}
      fullAppointment={fullAppointment}
      onGetAppointments={handleGetAppointments}
      onShowAppointment={handleShowAppointment}
      onUpdateAppointment={handleUpdateAppointment}
      onNewAppointmentClick={handleNewAppointment}
      onChangeAgendaFilters={handleChangeAgendaFilters}
      loadingItems={loadingItems}
      highPrivacy={highPrivacy}
      orderDoctorsByProfession={orderDoctorsByProfession}
      isFinatialEnabled={planInclude.financial}
      permissions={permissions}
    />
  );
};



export default Agenda;
