// @flow
import _ from 'lodash';
import type {
  Action,
  ContextState,
  Dispatch,
  MiddlewareApi,
  CordovaPurchaseOrder,
  CordovaPurchaseProduct,
} from '@types';
import Immutable from 'seamless-immutable';
import { router } from '../../../routes/router';
import { Api, Log } from '../../../helpers';

// ------------------------------------
// Constants
// ------------------------------------
export const CORDOVA_PURCHASE_INIT = 'cordovaPurchase.INIT';
export const CORDOVA_PURCHASE_READY = 'cordovaPurchase.READY';
export const CORDOVA_PURCHASE_ORDER = 'cordovaPurchase.ORDER';
export const CORDOVA_PURCHASE_UPDATE = 'cordovaPurchase.UPDATE';
export const CORDOVA_PURCHASE_APPROVED = 'cordovaPurchase.APPROVED';
export const CORDOVA_PURCHASE_REFRESH = 'cordovaPurchase.REFRESH';
export const CORDOVA_PURCHASE_VERIFY = 'cordovaPurchase.VERIFY';
export const CORDOVA_PURCHASE_VERIFIED = 'cordovaPurchase.VERIFIED';
export const CORDOVA_PURCHASE_FINISH = 'cordovaPurchase.FINISH';
export const CORDOVA_PURCHASE_OWNED = 'cordovaPurchase.OWNED';
export const CORDOVA_PURCHASE_RESTORE_PURCHASES = 'cordovaPurchase.RESTORE_PURCHASES';
export const CORDOVA_PURCHASE_ERROR = 'cordovaPurchase.ERROR';

export const CORDOVA_PURCHASE_POST_APPSTORE_SUBSCRIPTION =
  'cordovaPurchase.POST_APPSTORE_SUBSCRIPTION';
export const CORDOVA_PURCHASE_POST_APPSTORE_SUBSCRIPTION_FAILED =
  'cordovaPurchase.POST_APPSTORE_SUBSCRIPTION_FAILED';
export const CORDOVA_PURCHASE_POST_APPSTORE_SUBSCRIPTION_SUCCESS =
  'cordovaPurchase.POST_APPSTORE_SUBSCRIPTION_SUCCESS';

// ------------------------------------
// Actions
// ------------------------------------

export const order = (data: CordovaPurchaseOrder) => (dispatch: Dispatch) => {
  if (window.cordova) {
    dispatch({
      type: CORDOVA_PURCHASE_ORDER,
      order: data,
    });
    // applicationUsername is already set at login.
    // const additionalData = {};
    // if (data.applicationUsername) {
    //   window.store.applicationUsername = '' + data.applicationUsername;
    //   if (data.applicationUsername) {
    //     additionalData.applicationUsername = '' + data.applicationUsername;
    //   }
    // }

    // Make sure the product still exists instead of crashing
    const product = window.store.get(data.productID);
    if (product && product.state === 'valid') {
      window.store.order(product);
    }
  }
};

export const refresh = () => ({ type: CORDOVA_PURCHASE_REFRESH });

export const restorePurchases = (userId: string) => (dispatch: Dispatch) => {
  dispatch({
    type: CORDOVA_PURCHASE_RESTORE_PURCHASES,
    userId,
  });
  dispatch(refresh());
};

function onDeviceReady (dispatch: Dispatch) {
  if (!window.cordova || !window.store) {
    return;
  }
  const { store } = window;

  const VALIDATOR_URL = 'https://validator.fovea.cc/v1/validate?appName=doutore.com&apiKey=9e3b8cf5-4b4b-4d5d-aaa1-94bc04340168';
  if (window.store.validator === VALIDATOR_URL) {
    // purchase plugin has already been initialized
    return;
  }

  store.verbosity = store.DEBUG;
  // store.autoFinishTransactions = true; (use to cleanup transactions in development)
  store.validator = VALIDATOR_URL;

  store.register([{
    id: 'basic_21',
    alias: 'basic',
    type: store.PAID_SUBSCRIPTION,
  }, {
    id: 'complete_19',
    alias: 'complete',
    type: store.PAID_SUBSCRIPTION,
  }, {
    id: 'concierge_21',
    alias: 'concierge',
    type: store.PAID_SUBSCRIPTION,
  }]);

  store.ready(() =>
    dispatch({ type: CORDOVA_PURCHASE_READY }));

  store.when()
    .approved((p: CordovaPurchaseProduct) =>
      dispatch({ type: CORDOVA_PURCHASE_APPROVED, product: Immutable(p) }))
    .verified((p: CordovaPurchaseProduct) =>
      dispatch({ type: CORDOVA_PURCHASE_VERIFIED, product: Immutable(p) }))
    .owned((p: CordovaPurchaseProduct) =>
      dispatch({ type: CORDOVA_PURCHASE_OWNED, product: Immutable(p) }))
    .updated((p: CordovaPurchaseProduct) =>
      dispatch({ type: CORDOVA_PURCHASE_UPDATE, product: Immutable(p) }));
  store.error((error: string) =>
    dispatch({ type: CORDOVA_PURCHASE_ERROR, error }));

  dispatch(refresh());
}

export const init = () => (dispatch: Dispatch) => {
  dispatch({ type: CORDOVA_PURCHASE_INIT });
  if (window.cordova) {
    // Note: if device is ready, onDeviceReady will be called right away.
    // document.addEventListener('deviceready', () => onDeviceReady(dispatch), false);
  }
};

export const finish = (productId: string) => (dispatch: Dispatch) => {
  dispatch({ type: CORDOVA_PURCHASE_FINISH, productId });
  if (window.cordova) {
    window.store.get(productId).finish();
  }
};

export const cordovaPurchaseActions = {
  init,
  order,
  refresh,
  restorePurchases,
};

// ------------------------------------
// Middleware
// ------------------------------------
function postSubscriptionToApi (dispatch: Function, product: CordovaPurchaseProduct) {
  return new Promise((resolve: Function, reject: Function) => {
    dispatch({ type: CORDOVA_PURCHASE_POST_APPSTORE_SUBSCRIPTION, product });
    Api.post(router.getApiRoute('appstoreSubscription'), {
      data: { product },
      success: data => {
        dispatch({ type: CORDOVA_PURCHASE_POST_APPSTORE_SUBSCRIPTION_SUCCESS, data });
        resolve(data);
      },
      error: error => {
        dispatch({ type: CORDOVA_PURCHASE_POST_APPSTORE_SUBSCRIPTION_FAILED, error });
        reject(error);
      },
    });
  });
}

function activateSubscription(dispatch: Function, getState: Function, product: CordovaPurchaseProduct) {
  function tryActivate() {
    // Activate subscription if the user is logged-in, or try again.
    const isUserConnected =
      Number.isInteger(_.get(getState(), ['context', 'user', 'id']))
      && _.get(getState(), ['auth', 'authenticated']) === 'user';
    if (!isUserConnected) {
      return setTimeout(tryActivate, 3000);
    }
    // When product is expired, do not post to server.
    if (+product.expiryDate > +new Date()) {
      dispatch(finish(product.id));
      return;
    }
    postSubscriptionToApi(dispatch, product)
      .then(() => dispatch(finish(product.id)))
      .catch(error => {
        Log.warn('POST appstore_subscription failed', error);
        setTimeout(tryActivate, 3000);
      });
  }
  tryActivate();
}

export const cordovaPurchaseMiddleware = ({ dispatch, getState }: MiddlewareApi) =>
  (next: Dispatch) => (action: Object) => {
    const ret = next(action);
    const { product } = action;
    switch (action.type) {
      case 'user.USER_LOADED':
      case 'subscription.SUBSCRIPTION_GET_TRACK_INFO_LOADED': // XXX: any better event?
        if (window.cordova && window.store && !window.store.applicationUsername) {
          window.store.applicationUsername = () => {
            const userId = _.get(getState(), ['context', 'user', 'id']);
            return Number.isInteger(userId) ? '' + userId : undefined;
          };
          // Make sure the applicationUsername is available before initializing IAP plugin
          document.addEventListener('deviceready', () => onDeviceReady(dispatch), false);
          window.store.ready(() => {
            dispatch({ type: CORDOVA_PURCHASE_READY });
            window.store.products.forEach(p => p.trigger('updated'));
          });
        }
        return ret;

      case CORDOVA_PURCHASE_REFRESH:
        window.store.refresh();
        return ret;

      case CORDOVA_PURCHASE_APPROVED:
        dispatch({ type: CORDOVA_PURCHASE_VERIFY, product });
        window.store.get(product.id).verify();
        return ret;

      case CORDOVA_PURCHASE_VERIFIED:
        activateSubscription(dispatch, getState, product);
        return ret;

      default:
        return ret;
    }
  };

// ------------------------------------
// Reducer
// ------------------------------------
export default function cordovaPurchaseReducer (state: ContextState, action: Action): ContextState {
  switch (action.type) {
    case CORDOVA_PURCHASE_INIT:
      return state
        .setIn(['cordovaPurchase', 'status'], 'INIT')
        .setIn(['cordovaPurchase', 'errors'], Immutable([]))
        .setIn(['cordovaPurchase', 'products'], Immutable({}))
        .setIn(['cordovaPurchase', 'order'], null);

    case CORDOVA_PURCHASE_READY:
      return state
        .setIn(['cordovaPurchase', 'status'], 'READY');

    case CORDOVA_PURCHASE_ORDER:
      return state
        .setIn(['cordovaPurchase', 'status'], 'ORDER')
        .setIn(['cordovaPurchase', 'order'], action.order);

    case CORDOVA_PURCHASE_OWNED:
      return state
        .setIn(['cordovaPurchase', 'order'], null)
        .setIn(['cordovaPurchase', 'status'], 'SUBSCRIBED');

    case CORDOVA_PURCHASE_UPDATE:
      return state
        .setIn(['cordovaPurchase', 'products', action.product.id], action.product);

    case CORDOVA_PURCHASE_ERROR:
      return state
        .setIn(['cordovaPurchase', 'errors'], Immutable([action.error]));

    case CORDOVA_PURCHASE_POST_APPSTORE_SUBSCRIPTION:
      return state
        .setIn(['cordovaPurchase', 'posting'], true)
        .setIn(['cordovaPurchase', 'status'], 'POSTING');

    case CORDOVA_PURCHASE_POST_APPSTORE_SUBSCRIPTION_SUCCESS:
      return state
        .setIn(['cordovaPurchase', 'posting'], false)
        .setIn(['cordovaPurchase', 'subscription'], Immutable(action.data));

    case CORDOVA_PURCHASE_POST_APPSTORE_SUBSCRIPTION_FAILED:
      return state
        .setIn(['cordovaPurchase', 'posting'], false)
        .setIn(['cordovaPurchase', 'errors'], Immutable([action.error]));

    default:
      return state;
  }
}
