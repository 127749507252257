// @flow
import React from 'react';
import { connect } from 'react-redux';
import { get } from 'lodash';
import { router } from '@router';
import { serviceActions } from '@context/services';
import type { Dispatch, DoctorsState, ServicesState, PlanInclude, PermissionTo } from '@types';
import { ReportsServices as ReportsServicesView } from '@views';
import { mapPlanIncludes, mapPermissionTo } from '@helpers/connect';

type Props = {
  dispatch: Function,
  doctors: DoctorsState,
  services: ServicesState,
  defaultDoctorId: number,
  isManager: boolean,
  planInclude: PlanInclude,
  permissionTo : PermissionTo,
};

class ReportsServices extends React.Component<Props> {
  render() {
    const {
      doctors, services, defaultDoctorId, isManager, planInclude, permissionTo,
    } = this.props;
    return (
      <ReportsServicesView
        onGetServices={this.handleGetServices}
        services={services}
        doctors={doctors}
        onItemClick={this.handleItemClick}
        onDownloadReport={this.handleDownloadReport}
        defaultDoctorId={defaultDoctorId}
        isManager={isManager}
        isFeatureEnabled={planInclude.financial}
        isReportBlocked={!permissionTo.reports}
        reportsSelectDoctor={permissionTo.reportsSelectDoctor}
      />
    );
  }

  handleGetServices = (startDate: string, endDate: string, doctorId?: number, status?: string) => {
    const { dispatch } = this.props;
    dispatch(serviceActions.getServices(startDate, endDate, doctorId, status));
  };

  handleItemClick = (id: number) => {
    const { dispatch } = this.props;
    dispatch(router.pushPath('patientFinancial', { id }));
  };

  handleDownloadReport = (startDate: string, endDate: string, doctorId?: number, status?: string) => {
    const { dispatch } = this.props;
    dispatch(serviceActions.getServicesReport(startDate, endDate, doctorId, status));
  };
}

const mapStateToProps = (state) => ({
  doctors: state.context.doctors,
  services: state.context.services,
  defaultDoctorId: get(state, ['context', 'user', 'advancedData', 'doctorId']),
  isManager: get(state, ['context', 'user', 'advancedData', 'manager']),
  planInclude: mapPlanIncludes(state),
  permissionTo: mapPermissionTo(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({ dispatch });

export default connect<any, any, any, any, any, any>(
  mapStateToProps,
  mapDispatchToProps,
)(ReportsServices);
