import Bugsnag from '@bugsnag/js';
// @flow
import React from 'react';
import { get } from 'lodash';
import './ErrorBoundaryView.scss';

type Props = {
  children: any;
}

type State = {
  error: Error | null;
};

class ErrorBoundaryView extends React.Component<Props, State> {
  static getDerivedStateFromError(error: Error) {
    return { error };
  }

  constructor(props: any) {
    super(props);
    this.state = {
      error: null,
    };
  }

  componentDidCatch(error: Error) {
    if (this.shouldFilterErrorByMessage(error)) return;

    const info = {
      userId: get(window, ['debugInfo', 'userId']) || '?',
      clinicId: get(window, ['debugInfo', 'clinicId']) || '?',
    };

    const e = new Error(`UserID: ${info.userId} | ClinicID: ${info.clinicId} | Message: ${error.message}`);
    e.stack = error.stack;
    e.name = error.name;

    Bugsnag.notify(e);
  }

  render() {
    const { error } = this.state;
    if (error && !this.shouldFilterErrorByMessage(error)) {
      return (
        <div id="errorBoundaryView">
          <h1>Desculpe, ocorreu um erro no Doutore</h1>
          <div className="errorCopy">
            Por favor tire um print dessa tela e nos envie por WhatsApp. Nossa equipe de suporte irá te auxiliar na solução.
          </div>
          <div>
            Mensagem de erro:
          </div>

          <div className="error">{error.message}</div>

          <p>
            <a target="_blank" className="btn primary" href="https://wa.me/5521998007587?text=Oi,%20apareceu%20uma%20mensagem%20de%20erro%20no%20meu%20Doutore%20agora.%20Segue%20o%20print">
              Enviar WhatsApp
            </a>
          </p>

        </div>
      );
    }

    return this.props.children;
  }

  shouldFilterErrorByMessage(error: Error) {
    const message = String(error.message);

    return /MdHub is not defined/gi.test(message);
  }
}

export default ErrorBoundaryView;
