// @flow
import React from 'react';
import { Button, Icon, Alert } from '@elements';
import { withTranslation } from 'react-i18next';
import { createNsTranslator } from '@helpers/helpers';
import './NewClinic.scss';

type Props = {
  isLoading: boolean,
  isSecretary: boolean,
  onLogout: Function,
  onCreateClinic: Function,
  t: Function,
};

class NewClinic extends React.Component<Props> {
  render() {
    const {
      isLoading, onLogout, onCreateClinic, isSecretary,
    } = this.props;

    const _ = createNsTranslator('onboarding', this.props.t);

    return (
      <div id="newClinicPage">
        <div className="logoWrapper">
          <span className="logo" />
        </div>

        <div className="newClinicContent">

          <div className="form">
            <h1>
              <Icon iconName="lock" className="icon" size={28} />
              <span>{_('label/define_new_clinic')}</span>
            </h1>

            <Alert text={_('label/clinic_not_found')} />
            {!isSecretary && (
              <Button
                className="primary"
                text={_('action/create_new_clinic')}
                isLoading={isLoading}
                onClick={onCreateClinic}
                loadingMessage={_('label/wait', 'global')}
              />
            )}
            <Button
              className=""
              text={_('action/talk_support', 'global')}
              onClick={this.onShowIntercom}
              disabled={isLoading}
            />
            <Button
              className=""
              text={_('action/exit', 'global')}
              disabled={isLoading}
              onClick={onLogout}
            />
          </div>
        </div>
      </div>
    );
  }

  onShowIntercom = () => {
    if (window.Intercom) {
      window.Intercom('show');
    }
  };
}

export default withTranslation()(NewClinic);
