// @flow
import {
  get, omit, concat,
  without,
} from 'lodash';
import { reportError } from '@helpers/helpers';
import { Api } from '../../../helpers';
import { router } from '../../../routes/router';
import type {
  Action,
  Dispatch,
  ContextState,
  DocumentInput,
} from '../../../types';
import showPDF from '../../middleware/showPDF';


const NOP = () => {};

// ------------------------------------
// Constants
// ------------------------------------
const DOCUMENT_SHOW_PDF_IN_PROGRESS = 'documents.DOCUMENT_SHOW_PDF_IN_PROGRESS';
const DOCUMENT_SHOW_PDF_SUCCESS = 'documents.DOCUMENT_SHOW_PDF_SUCCESS';
const DOCUMENT_SHOW_PDF_FAILED = 'documents.DOCUMENT_SHOW_PDF_FAILED';

const DOCUMENT_CREATE_IN_PROGRESS = 'documents.DOCUMENT_CREATE_IN_PROGRESS';
const DOCUMENT_CREATE_SUCCESS = 'documents.DOCUMENT_CREATE_SUCCESS';
const DOCUMENT_CREATE_FAILED = 'documents.DOCUMENT_CREATE_FAILED';

const DOCUMENT_UPDATE_IN_PROGRESS = 'documents.DOCUMENT_UPDATE_IN_PROGRESS';
const DOCUMENT_UPDATE_SUCCESS = 'documents.DOCUMENT_UPDATE_SUCCESS';
const DOCUMENT_UPDATE_FAILED = 'documents.DOCUMENT_UPDATE_FAILED';

const DOCUMENT_DELETE_IN_PROGRESS = 'documents.DOCUMENT_DELETE_IN_PROGRESS';
const DOCUMENT_DELETE_SUCCESS = 'documents.DOCUMENT_DELETE_SUCCESS';
const DOCUMENT_DELETE_FAILED = 'documents.DOCUMENT_DELETE_FAILED';

// ------------------------------------
// Actions
// ------------------------------------
export const showDocumentPDF = (recordId: number, documentId: number, showSignature: boolean, sendEmail: boolean = false) => showPDF.action({
  title: 'Document PDF',
  apiRoute: router.getApiRoute('showDocumentPDF', {
    recordId,
    id: documentId,
    showSignature: showSignature ? 'true' : 'false',
    sendEmail: sendEmail ? 'true' : 'false',
  }),
  prepare: DOCUMENT_SHOW_PDF_IN_PROGRESS,
  success: DOCUMENT_SHOW_PDF_SUCCESS,
  error: DOCUMENT_SHOW_PDF_FAILED,
  sendEmail,
});

export const createDocument = (recordId: number, document: DocumentInput, cb: Function = NOP) => (
  (dispatch: Dispatch) => {
    dispatch({ type: DOCUMENT_CREATE_IN_PROGRESS });
    Api.post(router.getApiRoute('createDocument', { recordId }), {
      data: { document },
      success: (response) => {
        dispatch({ type: DOCUMENT_CREATE_SUCCESS, recordId, document: response.data.document });
        cb(true);
      },
      error: (error) => {
        dispatch({ type: DOCUMENT_CREATE_FAILED, error: { message: error.message } });
        dispatch(reportError(error));
        cb(false);
      },
    });
  }
);

export const createAndShowDocumentPDF = (recordId: number, document: DocumentInput, cb: Function = NOP) => (
  (dispatch: Dispatch) => {
    dispatch({ type: DOCUMENT_CREATE_IN_PROGRESS });
    Api.post(router.getApiRoute('createDocument', { recordId }), {
      data: { document },
      success: (createResponse) => {
        dispatch({ type: DOCUMENT_CREATE_SUCCESS, recordId, document: createResponse.data.document });
        dispatch(showDocumentPDF(recordId, createResponse.data.document.id, false));
        cb(true);
      },
      error: (error) => {
        dispatch({ type: DOCUMENT_CREATE_FAILED, error: { message: error.message } });
        dispatch(reportError(error));
        cb(false);
      },
    });
  }
);

export const updateDocument = (recordId: number, documentId: number, document: DocumentInput, cb: Function = NOP) => (
  (dispatch: Dispatch) => {
    dispatch({ type: DOCUMENT_UPDATE_IN_PROGRESS });
    Api.patch(router.getApiRoute('updateDocument', { recordId, id: documentId }), {
      data: { document },
      success: (response) => {
        dispatch({ type: DOCUMENT_UPDATE_SUCCESS, document: response.document });
        cb(true);
      },
      error: (error) => {
        dispatch({ type: DOCUMENT_UPDATE_FAILED, error: { message: error.message } });
        dispatch(reportError(error));
        cb(false);
      },
    });
  }
);

export const updateAndShowDocumentPDF = (recordId: number, documentId: number, document: DocumentInput, cb: Function = NOP) => (
  (dispatch: Dispatch) => {
    dispatch({ type: DOCUMENT_UPDATE_IN_PROGRESS });
    Api.patch(router.getApiRoute('updateDocument', { recordId, id: documentId }), {
      data: { document },
      success: (updateResponse) => {
        dispatch({ type: DOCUMENT_UPDATE_SUCCESS, document: updateResponse.document });
        dispatch(showDocumentPDF(recordId, documentId, false));
        cb(true);
      },
      error: (error) => {
        dispatch({ type: DOCUMENT_UPDATE_FAILED, error: { message: error.message } });
        dispatch(reportError(error));
        cb(false);
      },
    });
  }
);


export const deleteDocument = (recordId: number, documentId: number, cb: Function = NOP) => (
  (dispatch: Dispatch) => {
    dispatch({ type: DOCUMENT_DELETE_IN_PROGRESS });
    Api.destroy(router.getApiRoute('deleteDocument', { recordId, id: documentId }), {
      success: () => {
        dispatch({ type: DOCUMENT_DELETE_SUCCESS, recordId, deletedDocumentId: documentId });
        cb(true);
      },
      error: (error) => {
        dispatch({ type: DOCUMENT_DELETE_FAILED, error: { message: error.message } });
        dispatch(reportError(error));
        cb(false);
      },
    });
  }
);

export const documentActions = {
  showDocumentPDF,
  createDocument,
  createAndShowDocumentPDF,
  updateDocument,
  updateAndShowDocumentPDF,
  deleteDocument,
};

// ------------------------------------
// Reducer
// ------------------------------------
export default function documentsReducer (state: ContextState, action: Action): ContextState {
  let recordId;
  let recordDocuments;
  switch (action.type) {
    case DOCUMENT_CREATE_IN_PROGRESS:
      return state
        .setIn(['documents', 'isSaving'], true)
        .setIn(['documents', 'errors'], null);

    case DOCUMENT_CREATE_SUCCESS:
      recordId = action.recordId.toString();
      const newDocumentId = action.document.id;
      const currentDocumentsByRecord = get(state, ['documents', 'byRecord', recordId], []);
      recordDocuments = get(state, ['records', 'data', recordId, 'details', 'documents'], []);
      return state
        .setIn(['documents', 'isSaving'], false)
        .setIn(['documents', 'errors'], null)
        .setIn(['documents', 'data', newDocumentId], action.document)
        .setIn(['documents', 'byRecord', recordId], concat(currentDocumentsByRecord, [newDocumentId]))
        .setIn(['records', 'data', recordId, 'details', 'documents'], concat(recordDocuments, [newDocumentId]));

    case DOCUMENT_CREATE_FAILED:
      return state
        .setIn(['documents', 'isSaving'], false)
        .setIn(['documents', 'errors'], [action.error.message]);

    case DOCUMENT_UPDATE_IN_PROGRESS:
      return state
        .setIn(['documents', 'isSaving'], true)
        .setIn(['documents', 'errors'], null);

    case DOCUMENT_UPDATE_SUCCESS:
      return state
        .setIn(['documents', 'isSaving'], false)
        .setIn(['documents', 'errors'], null)
        .setIn(['documents', 'data', action.document.id], action.document);

    case DOCUMENT_UPDATE_FAILED:
      return state
        .setIn(['documents', 'isSaving'], false)
        .setIn(['documents', 'errors'], [action.error.message]);

    case DOCUMENT_DELETE_IN_PROGRESS:
      return state
        .setIn(['documents', 'isDeleting'], true)
        .setIn(['documents', 'errors'], null);

    case DOCUMENT_DELETE_SUCCESS:
      const deletedId = action.deletedDocumentId;
      recordId = action.recordId.toString();
      const data = get(state, ['documents', 'data'], {});
      const byRecord = get(state, ['documents', 'byRecord', recordId], []);
      recordDocuments = get(state, ['records', 'data', recordId, 'details', 'documents'], []);
      return state
        .setIn(['documents', 'isDeleting'], false)
        .setIn(['documents', 'errors'], null)
        .setIn(['documents', 'data'], omit(data, deletedId.toString()))
        .setIn(['documents', 'byRecord', recordId], without(byRecord, deletedId))
        .setIn(['records', 'data', recordId, 'details', 'documents'], without(recordDocuments, deletedId));

    case DOCUMENT_DELETE_FAILED:
      return state
        .setIn(['documents', 'isDeleting'], false)
        .setIn(['documents', 'errors'], [action.error.message]);

    default:
      return state;
  }
}
