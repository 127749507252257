// @flow
import { first, last, filter, map } from 'lodash';


export const stringifyData = (data: any) => JSON.stringify(data, null, 2);

export const stringifyError = (error: any) => (
  JSON.stringify(error, Object.getOwnPropertyNames(error), 2)
);


export const shortenName = (fullName: any, ignore: Array<string> = ['de', 'da', 'do', 'dos']) => {
  const names = filter(String(fullName).split(' '), name => ignore.indexOf(String(name).trim().toLowerCase()) === -1);

  const lastJunction = last(filter(String(fullName).split(' '), name => ignore.indexOf(String(name).trim().toLowerCase()) !== -1));

  if (names.length <= 4 || String(fullName).length <= 40) return String(fullName).trim();

  const firstName = first(names);
  const lastName = last(names);

  let middleNames = filter(names, name => name !== firstName && name !== lastName);

  middleNames = map<any, any>(middleNames, name => `${String(name).substring(0, 1).toUpperCase()}.`);

  return `${firstName} ${middleNames.join(' ')} ${lastJunction || ''} ${lastName}`;
};


export function titleCase(str: string) {
  const splitStr = str.toLowerCase().split(' ');
  for (let i = 0; i < splitStr.length; i++) {
    // You do not need to check if i is larger than splitStr length, as your for does that for you
    // Assign it back to the array
    splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
  }
  // Directly return the joined string
  return splitStr.join(' ');
}

export const fSufixTwoPoints = (s: any) => `${s}:`;

export const fsPrintf: Function = (...args) => s => String(s).replace(/{(\d+)}/g, (match, num) => (typeof args[num] !== 'undefined'
  ? args[num]
  : match));

export const nl2br = (str: any) => String(str).replace(/(?:\r\n|\r|\n)/g, '<br>');
