import 'core-js';
import 'regenerator-runtime';
import React from 'react';
import ReactDOM from 'react-dom';
import { cold, setConfig, AppContainer } from 'react-hot-loader';
import AppEntryPoint from './app';
import Bugsnag from '@bugsnag/js';
import { get } from 'lodash';
import { getPlatform } from '@helpers/helpers';
import BugsnagPluginReact from '@bugsnag/plugin-react';

/**
 * Setup HMR settings
 */
if (RUNNING_ON_DEV_SERVER && module.hot) {
  setConfig({
    ignoreSFC: false,
    pureRender: false,
    pureSFC: true,
    reloadHooks: true,
    onComponentCreate: (type) => (String(type).indexOf('useState') > 0 || String(type).indexOf('useEffect') > 0) && cold(type),
  });
}
/**
 * Initialize bugsnag client in entry of app avoiding invalid instance problems
 */
if (window.ENV === 'production') {
  Bugsnag.start({
    apiKey: BUGSNAG_API_KEY,
    appVersion: PACKAGE_APP_VERSION,
    plugins: [new BugsnagPluginReact()],
    appType: getPlatform(),
    releaseStage: window.ENV,
    onError: (event) => {
      event.setUser(get(window, ['debugInfo', 'userId'], null));

      event.addMetadata('clinic', {
        clinicId: get(window, ['debugInfo', 'clinicId'], null),
      });
    },

  });
}

// Setup Simple global's
window.APP_VERSION = PACKAGE_APP_VERSION;
window.APP_LS_KEY = `doutore-${window.btoa(window.location.hostname)}`;
window.hashUid = () => 'initial_hash_uid' + Math.random();

if (localStorage.getItem('force-cordova') === 'true') {
  window.isCordovaApp = true;
  window.isCordovaIos = localStorage.getItem('force-ios') === 'true';
}



const startRender = (COMPONENT: React.FunctionComponent | React.ElementType) => (RUNNING_ON_DEV_SERVER && module.hot ? (
  <AppContainer>
    <COMPONENT />
  </AppContainer>
) : (
  <COMPONENT />
));

if (window.cordova) {
  document.addEventListener('deviceready', () => {
    ReactDOM.render(startRender(AppEntryPoint), document.getElementById('app'));
  });
} else {
  ReactDOM.render(startRender(AppEntryPoint), document.getElementById('app'));
}


if (RUNNING_ON_DEV_SERVER && module.hot) {
  module.hot.accept('./app.js', () => {
    const newEntryPoint = require('./app').default;
    ReactDOM.render(startRender(newEntryPoint), document.getElementById('root'));
  });
}
