// @flow
import React from 'react';
import { Button, Icon, Modal } from '@elements';
import { withTranslation } from 'react-i18next';
import { createNsTranslator } from '@helpers/helpers';
import { SubscriptionHeader } from '@blocks';
import './Benefits.scss';

type State = {
  showModal: boolean,
};

class Benefits extends React.Component<any, State> {
  constructor(props: any) {
    super(props);
    this.state = {
      showModal: false,
    };
  }
  render() {
    const { isLoading } = this.props;
    const _ = createNsTranslator('subscription', this.props.t);

    return (
      <div id="benefitsPage" className="appContent plansPage settingsPage">
        <SubscriptionHeader backTo="/assinar"/>
        {this.state.showModal &&
          <Modal id="smsModal" onClose={this.handleCloseModal}>
            <div className="modalGrid">

              <div className="modalContainer">
                <div className="modalContent">

                  <div className="pricingPack">
                    <h2>Pacotes de SMS</h2>
                    <span className="price">
                      <span className="value">2000 SMS</span>
                      <span className="suffix">por R$199</span>
                    </span>
                    <span className="price">
                      <span className="value">600 SMS</span>
                      <span className="suffix">por R$99</span>
                    </span>
                    {/* <p className="contactText">Entre em contato agora para contratar um pacote de SMS ou tirar dúvidas.</p> */}
                  </div>

                </div>
                <div className="modalFooter">
                  <Button className="primary" text="OK" onClick={this.handleCloseModal} />
                </div>
              </div>
            </div>
          </Modal>
        }

        <section className="mainSection">

          <h1>{_('label/all_plans_includes')}:</h1>
          <ul className="plansBullets">
            <li>
              <Icon iconName="color-patient-unlimited" size={24} />
              <span>{_('label/unlimited_patients_and_secretaries')}</span>
            </li>
            <li>
              <Icon iconName="color-storage-unlimited" size={24} />
              <span>{_('label/unlimited_storage')}</span>
            </li>
            <li>
              <Icon iconName="color-all-devices" size={24} />
              <span>{_('label/pc_ios_and_android')}</span>
            </li>
            <li>
              <Icon iconName="color-training" size={24} />
              <span>{_('label/training')}</span>
            </li>
            {window.isBR && (
              <li>
                <Icon iconName="color-sms-confirmation" />
                <span>
                  <span>Módulo de Confirmação Automática de Consultas via SMS Disponível</span>
                  <span className="textDetail">
                    O envio de SMS é cobrado à parte por cada mensagem enviada.
                    <a onClick={this.handleShowModal}>Saber mais</a>
                  </span>
                </span>
              </li>
            )}
          </ul>
          <div className="actionsFooter">
            <Button className="primary" text={_('label/continue')} linkTo="/planos" isLoading={isLoading} />
          </div>
        </section>
      </div>
    );
  }

  handleCloseModal = () => {
    this.setState({ showModal: false });
  };
  handleShowModal = () => {
    this.setState({ showModal: true });
  };
}

export default withTranslation()(Benefits);
