// @flow
import React from 'react';
import { DoutoreSpinner } from '../../blocks';

export default class LoadingPage extends React.Component<any> {
  render() {
    return (
      <div id="loadingPage">
        <div className="bigLoading">
          <div className="logo" />
          <DoutoreSpinner />
        </div>
      </div>
    );
  }
}
