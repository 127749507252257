// @flow
import React from 'react';
import {
  concat, get, includes,
  keys, map, without, toLower, filter, size,
} from 'lodash';
import moment from 'moment';
import { withRouter } from 'react-router-dom';
import classnames from 'classnames';
import { router } from '@router';
import {
  Button, Icon, Modal,
  Spinner, ClickableList,
  Toggle,
  SearchInput, TextArea,
} from '@elements';
import type { ExamsPrescription, ExamsPrescriptionsTemplatesState, FormElementData } from '@types';
import { sort, alphabeticCompare, createNsTranslator, UserNotifications } from '@helpers/helpers';
import { withTranslation } from 'react-i18next';
import { ViewPrintSettings, PlatformSwitch } from '@blocks';
import './PrescriptionExamsFormModal.scss';

type Props = {
  recordId: number,
  patientName: string,
  examsPrescription: ExamsPrescription,
  examsPrescriptionsTemplates: ExamsPrescriptionsTemplatesState,
  onClose: Function,
  onAddNewExamsPrescription: Function,
  onAddNewAndPrintExamsPrescription: Function,
  onUpdateExamsPrescription: Function,
  onUpdateAndPrintExamsPrescription: Function,
  onDeleteExamsPrescription: Function,
  t: Function,
  history: Object,
  isSaving: boolean,
  isDeleting: boolean,
};

type Item = {
  name: string,
  type: string,
  value: number,
};

type State = {
  notes: string,
  showDate: boolean,
  showTitle: boolean,
  errors: Array<string>,
  searchTerm: string,
};

const filterSearch = (itens, searchTerm) => {
  const search = toLower(searchTerm).normalize('NFD').replace(/[\u0300-\u036f]/g, '');

  const searchPredicate = item => includes(toLower(get(item, ['customRendererData', 'name'])).normalize('NFD').replace(/[\u0300-\u036f]/g, ''), search);
  return search && String(search).trim().length > 0 ? filter(itens, searchPredicate) : itens;
};

class PrescriptionExamsFormModal extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    const { examsPrescription } = this.props;
    this.state = {
      notes: examsPrescription.notes || '',
      showDate: examsPrescription.showDate || false,
      showTitle: examsPrescription.showTitle || true,
      errors: [],
      searchTerm: '',
    };
  }

  render() {
    const {
      patientName, examsPrescription, examsPrescriptionsTemplates, isSaving, isDeleting,
    } = this.props;
    const {
      notes, errors,
      showTitle, showDate,
    } = this.state;

    const _ = createNsTranslator('timeline', this.props.t);

    const modalTitle = !examsPrescription.id ? _('label/new_request') : _('label/new_request_edit');
    const templateIds = keys(examsPrescriptionsTemplates.data);
    let templateItems = map<any, any>(templateIds, (templateId) => {
      const template = get(examsPrescriptionsTemplates, ['data', templateId]);
      return {
        customRendererData: {
          name: template.name,
          value: template.id,
          type: 'examsPresctiptionTemplate',
        },
      };
    });
    const showSearch = size(templateItems) >= 10 && !examsPrescription.id;
    templateItems = sort(filterSearch(templateItems, this.state.searchTerm), alphabeticCompare('name'));
    const renderer = (item) => (
      <div>
        <span>{item.name}</span>
        <Icon iconName="arrow-right" className="icon" />
      </div>
    );
    const templatesLoading = get(examsPrescriptionsTemplates, ['isLoading']);
    const noTemplates = !templateItems.length;

    const renderContent = (isMobile: boolean) => () => (
      <Modal id="newExamModal" className={classnames('fullScreen', { asView: isMobile, isMobile })} onClose={this.onClose}>
        {isMobile && (
        <div className="modalHeader">
          <Button onClick={this.onClose} disabled={isSaving || isDeleting}>
            <Icon iconName="close" className="icon" />
          </Button>
          <span className="title">{modalTitle}</span>
          { examsPrescription.id && (
            <Button className="primary" onClick={this.onDeleteExamsPrescription} disabled={isSaving || isDeleting}>
              <Icon
                iconName="delete"
                className="icon"
              />
            </Button>
          )}
          <Button className="primary" text="Salvar" onClick={this.onSaveExamsPrescription.bind(this, 'save')} disabled={isDeleting} isLoading={isSaving} />
        </div>
        )}
        <div className="modalGrid">
          {!isMobile && (
            <aside className="modalSidebar">
              <span className="sidebarTitle">{_('label/exams')}</span>
              {!examsPrescription.id &&
              <ul className="sidebarNav">
                <li className="active">{modalTitle}</li>
              </ul>
            }
              {!examsPrescription.id &&
              <div className="sidebarSubtitle">
                <span>{_('label/saved_exams_models')}</span>
                <span
                  className="editModels"
                  onClick={this.onEditTemplatesClick}
                >
                  Editar
                </span>
              </div>
            }
              {showSearch && <SearchInput onChange={this.handleSearch}/>}
              {!examsPrescription.id && templatesLoading && noTemplates && (
              <div className='loadingWrapper'>
                <Spinner text={_('label/loading', 'global')} />
              </div>
              )
            }
              {!examsPrescription.id && !noTemplates && (
              <ClickableList
                items={templateItems}
                customRenderer={renderer}
                className="sidebarNav savedModels"
                onListItemClick={this.onTemplateClick}
              />
              )
            }
            </aside>
          )}

          <div className="modalContainer">
            <div className="modalContent withSidebar">
              <form className="form">
                {showDate &&
                  <div className="row prescribedTo">
                    <span className="label">{_('label/date', 'global')}:</span>
                    <span className="value">{moment().format('DD/MM/YYYY')}</span>
                  </div>
                }
                <div className="row prescribedTo">
                  <span className="label">{_('label/exam_prescription_to')}:</span>
                  <span className="value">{patientName}</span>
                </div>
                {showTitle &&
                  <div className="row prescribedTo">
                    <span className="label">{_('label/i_request')}:</span>
                  </div>
                }
                <TextArea
                  autoGrow={false}
                  name="notes"
                  className="textAreaHolder"
                  placeholder={_('label/exam_textarea_placeholder')}
                  onChange={this.handleFormChange}
                  onBlur={this.handleFormBlur}
                  error={includes(errors, 'notes') ? _('error/empty') : ''}
                  value={notes}
                  disabled={isSaving || isDeleting}
                />
              </form>

              <div className="modalSidebar modalRightSidebar">
                <span className="sidebarTitle">{_('label/print_configs')}:</span>
                <ul className="sidebarSetup">
                  <li className="toggleHolder">
                    <Toggle
                      name="showDate"
                      label={_('label/today_date')}
                      isChecked={showDate}
                      onChange={this.handleFormChange}
                      disabled={isSaving || isDeleting}
                    />
                  </li>
                  <li className="toggleHolder">
                    <Toggle
                      name="showTitle"
                      label={_('label/include_request_in_header')}
                      isChecked={showTitle}
                      onChange={this.handleFormChange}
                      disabled={isSaving || isDeleting}
                    />
                  </li>
                  <li><ViewPrintSettings/></li>
                </ul>
              </div>

            </div>
            {!isMobile && (
              <div className="modalFooter">
                <div>
                  {examsPrescription.id && (
                  <Button onClick={this.onDeleteExamsPrescription} disabled={isSaving || isDeleting}>
                    <Icon iconName="delete" className="icon" />
                    {isDeleting && <Spinner />}
                  </Button>
                  )
                }
                  <Button
                    text="Cancelar"
                    onClick={this.onClose}
                    disabled={isSaving || isDeleting}
                  />
                </div>
                <div>
                  <Button
                    text={_('action/save_print', 'global')}
                    onClick={this.onSaveExamsPrescription.bind(this, 'save and print')}
                    disabled={isSaving || isDeleting}
                  />
                  <Button
                    text={_('action/save', 'global')}
                    className="primary"
                    onClick={this.onSaveExamsPrescription.bind(this, 'save')}
                    disabled={isDeleting}
                    isLoading={isSaving}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </Modal>
    );

    return (
      <PlatformSwitch
        desktop={renderContent(false)}
        mobile={renderContent(true)}
      />
    );
  }

  checkForErrors = (value: (string | number | boolean), fieldName: string) => {
    const { errors } = this.state;

    if (value === '') {
      this.setState({ errors: concat(errors, [fieldName]) });
    } else if (includes(errors, fieldName)) {
      this.setState({ errors: without(errors, fieldName) });
    }
  };

  checkForUnsavedChanges = () => {
    const { examsPrescription } = this.props;
    const { notes } = this.state; // TODO: add showDate and showTitle
    let unsavedChanges;

    if (examsPrescription.id) { // on edit
      if (notes !== examsPrescription.notes) {
        unsavedChanges = true;
      } else {
        unsavedChanges = false;
      }
    } else if (notes !== '') { // on create check against default state
      unsavedChanges = true;
    } else {
      unsavedChanges = false;
    }

    return unsavedChanges;
  };

  handleFormChange = (data: FormElementData) => {
    const { name, value } = data;
    this.setState({ [name]: value }, () => this.checkForErrors(value, name));
  };

  handleFormBlur = (data: FormElementData) => {
    const { name, value } = data;
    this.checkForErrors(value, name);
  };

  onEditTemplatesClick = () => {
    const { history, onClose } = this.props;
    history.push(router.getRoutePath('examsPrescriptionsTemplatesList'));
    onClose();
  };

  onTemplateClick = (item: Item) => {
    const templateId = item.value.toString();
    const template = get(this.props.examsPrescriptionsTemplates, ['data', templateId]);
    this.setState({
      notes: template.notes || '',
    }, () => this.checkForErrors(template.notes || '', 'notes'));
  };

  onClose = () => {
    const { onClose } = this.props;
    const unsavedChanges = this.checkForUnsavedChanges();
    if (unsavedChanges) {
      UserNotifications.confirmI18n('alert/confirm_quit_form', null, '#FFA433', true)
        .then((value) => {
          if (value) {
            onClose();
          }
        });
    } else {
      onClose();
    }
  }

  handleSearch = (text) => this.setState({ searchTerm: text });

  onSaveExamsPrescription = (option: string) => {
    const {
      recordId,
      examsPrescription,
      onAddNewExamsPrescription,
      onUpdateExamsPrescription,
      onAddNewAndPrintExamsPrescription,
      onUpdateAndPrintExamsPrescription,
    } = this.props;
    const { notes, showDate, showTitle } = this.state;

    const data = {
      notes,
      showDate,
      showTitle,
    };

    const errors = [];

    if (notes === '') {
      if (!includes(this.state.errors, 'notes')) {
        this.setState({ errors: concat(errors, ['notes']) });
      }
      errors.push('notes');
    }

    if (!errors.length) {
      if (examsPrescription.id) { // if we are editing an existing exams prescription
        switch (option) {
          case 'save and print':
            onUpdateAndPrintExamsPrescription(recordId, examsPrescription.id, data);
            break;
          case 'save':
            onUpdateExamsPrescription(recordId, examsPrescription.id, data);
            break;
          default: break;
        }
      } else { // if we are creating a new exams prescription
        switch (option) {
          case 'save and print':
            onAddNewAndPrintExamsPrescription(recordId, data);
            break;
          case 'save':
            onAddNewExamsPrescription(recordId, data);
            break;
          default: break;
        }
      }
    }
  };

  onDeleteExamsPrescription = () => {
    const {
      recordId, examsPrescription, onDeleteExamsPrescription,
    } = this.props;

    UserNotifications.confirmI18n('alert/delete/exam', 'warning', '#FFA433', true)
      .then((value) => {
        if (value) {
          onDeleteExamsPrescription(recordId, examsPrescription.id);
        }
      });
  };
}

export default withTranslation()(withRouter(PrescriptionExamsFormModal));
