export default {
  'label/no_results_records': 'Não há nenhum registro no prontuário desse paciente.',
  'label/no_results_records_deleted': 'Não há nenhum registro Excluído no prontuário desse paciente.',
  'label/no_results_attachment': 'Nenhum anexo encontrado no prontuário desse paciente.',
  'label/no_results_prescription': 'Nenhuma prescrição encontrada no prontuário desse paciente.',
  'label/no_results_docs': 'Nenhum documento encontrado no prontuário desse paciente.',
  'label/no_results_exams': 'Nenhum exame encontrado no prontuário desse paciente.',
  'label/no_results_forms': 'Nenhum formulário encontrado no prontuário desse paciente.',
  'label/creating': 'Criando...',
  'label/medic_info': 'Informações Médicas',
  'label/reminder': 'Lembrete',
  'label/medications': 'Medicamentos',
  'label/tag_input': 'Digite o nome de uma etiqueta...',
  'label/problem_input': 'Digite um problema...',
  'label/create_new_tag': 'Criar nova etiqueta:',
  'label/create_new_problem': 'Adicionar Novo Problema',
  'label/create_new_medication': 'Digite o nome de um medicamento...',
  'label/no_tags': 'Sem etiquetas.',
  'label/problems': 'Problemas',
  'label/not_permission': 'Você não tem permissão para ver o conteúdo desse registro',
  'label/record_created_in': 'Registro criado em ',
  'label/migration_data_label': 'Registro de uma migração de dados',
  'label/message/canceled': 'Registro excluído no dia',
  'label/message/canceled_in_day': 'Consulta Cancelada pelo Consultório no dia',
  'label/message/patient_canceled_in_day': 'Consulta Cancelada pelo Paciente no dia',
  'label/message/sms_canceled_in_day': 'Consulta Cancelada pelo Paciente por SMS no dia',
  'label/message/patient_missed': 'Paciente faltou a consulta',
  'label/appointment_note': 'Observações da Consulta na Agenda',
  'label/receipts': 'Receituário',
  'label/drop_file': 'Solte aqui para anexar a este registro',
  'label/forms': 'Formulários',
  'label/constrolled_receipts': 'Receituário Controlado',
  'label/exam_request': 'Pedido de Exames',
  'label/write_annotations': 'Escreva suas anotações',
  'label/future_appointments': 'Consultas Futuras',
  'label/errors/future_appointments': 'Ocorreu um erro ao carregar consultas futuras.',
  'label/loading_memed_prescriptions': 'Carregando Memed...',
  'label/memed_medication': 'Medicamento',
  'label/memed_posology': 'Posologia',
  'label/memed_qtd': 'Qtd',
  'label/memed_': '',
  'label/memed_error': 'Ocorreu um erro ao consultar o Memed',
  'label/memed_error_link': 'clique aqui para tentar novamente',
  'label/docs': 'Documentos',
  'label/saved_doc_models': 'Modelos Salvos',
  'label/doc_title_opt': 'Título do Documento (opcional)',
  'label/doc_content_placeholder': 'Escreva aqui o texto do documento ou escolha um modelo ao lado.',
  'label/doc_title': 'Título do Documento',
  'label/today_date': 'Data de Hoje',
  'label/saved_receipts_models': 'Modelos Salvos',
  'label/prescriptions_drugs': 'Prescrições',
  'label/receipt_for': 'Prescrição para',
  'label/receipt_textarea_placeholder': 'Escreva aqui a prescrição ou escolha um modelo ao lado.',
  'label/controlled_drugs': 'Receituário Controlado',
  'label/new_request': 'Novo Pedido de Exames',
  'label/new_request_edit': 'Editar Pedido de Exames',
  'label/exams': 'Pedidos de Exames',
  'label/saved_exams_models': 'Modelos Salvos',
  'label/exam_prescription_to': 'Pedido de exames para',
  'label/i_request': 'Solicito',
  'label/exam_textarea_placeholder': 'Escreva aqui o pedido de exames ou escolha um modelo ao lado.',
  'label/include_request_in_header': '\'Solicito:\' no topo do pedido',
  'label/white_paper': 'Folha em Branco',
  'label/letterhead_paper': 'Papel Timbrado',
  'label/print': 'Impressão',
  // MSG
  'msg/patient_name_copied': 'Nome do paciente copiado.',
  // Actions
  'action/filter_records': 'Filtrar registros',
  'action/edit_perint_settings': 'Editar',
  'action/new_record': 'Novo Registro',
  'action/save_reminder': 'Salvar Lembrete',
  'action/add_tag': 'Adicionar Etiqueta',
  'action/add_problem': 'Adicionar Problema',
  'action/add_medications': 'Adicionar Medicamento',
  'action/filter/all_records': 'Todos os Registros',
  'action/filter/forms': 'Somente Formulários',
  'action/filter/my_records': 'Meus Registros',
  'action/filter/attachments': 'Somente Anexos',
  'action/filter/prescriptions': 'Somente Prescrições',
  'action/download': 'Fazer download',
  'action/delete_file': 'Deletar',
  'action/fast_preview': 'Visualização Rápida',
  'action/add_new_medication': 'Adicionar Novo Medicamento',
  'action/change_date': 'Alterar Data',
  'action/print_record': 'Imprimir Registro',
  'action/share_header': 'Compartilhar',
  'action/print_record_share': 'Compartilhar Registro',
  'action/print_full_record': 'Imprimir Prontuário Completo',
  'action/print_full_record_share': 'Compartilhar Prontuário Completo',
  'action/delete_record': 'Excluir Registro',
  'action/visibility/label': 'Quem pode ver o conteúdo desse registro?',
  'action/visibility/all': 'Todos - Doutores e secretárias da clínica',
  'action/visibility/only_doctors': 'Doutores - Somente os doutores da clínica',
  'action/visibility/only_me': 'Somente eu',
  'action/content_add/attachments': 'Anexos',
  'action/content_add/camera': 'Da Camera',
  'action/content_add/from_files': 'Dos Arquivos',
  'action/content_add/receipts': 'Prescrição',
  'action/content_add/prescription': 'Prescrição',
  'action/content_add/exam': 'Exames',
  'action/content_add/docs': 'Documento',
  'action/content_add/forms': 'Formulários',
  'action/content_add/memed': 'Receituário Memed',
  'action/start_treatment': 'Iniciar Atendimento',
  'action/finalize_appointment': 'Finalizar Consulta',
  'action/new_doc': 'Novo Documento',
  'action/new_receipt': 'Nova Prescrição',
  'action/choose_an_action': 'Escolha uma opção',
  'action/take_photo': 'Tirar uma foto',
  'action/album': 'Galeria de fotos',
  'action/filter/docs': 'Somente Documentos',
  'action/filter/not_empty': 'Registros com Conteúdo',
  'action/filter/exams': 'Somente Exames',
  'action/filter/deleted': 'Registros Excluídos',
  'action/restore_deleted_record': 'Restaurar',
  'message/url_share_receipt': '{0} enviou sua receita digital.\nClique no link para acessar: {1}',
  'message/url_share_exam': '{0} enviou seu pedido de exame.\nClique no link para acessar: {1}',
  'message/url_share_doc': '{0} enviou um documento.\nClique no link para acessar: {1}',
  'message/url_share_receipt/subject': 'Sua receita digital',
  'message/url_share_exam/subject': 'Seu pedido de exame',
  'message/url_share_doc/subject': 'Seu documento',
  'action/cancel': 'Cancelar',
};
