// @flow
import React from 'react';
import { isEqual } from 'lodash';
import type { Record } from '@types';
import { Icon } from '@elements';
import RecordDateHeader from './RecordDateHeader';


type Props = {
  record: Record,
  _: Function,
};

export default class RecordBlocked extends React.Component<Props> {
  shouldComponentUpdate(nextProps: Props) {
    return !isEqual(this.props, nextProps);
  }

  render() {
    const {
      record, _,
    } = this.props;

    return (
      <div className="register registerBlocked">
        <div className='doctorTabHolder'>
          <div className='doctorTab'>
            <span>{record.userName}&nbsp;<Icon iconName="lock" /></span>
          </div>
        </div>

        <RecordDateHeader
          record={record}
        />

        <div className="recordBlockedText">
          <Icon iconName="lock" />&nbsp; {_('label/not_permission')}
        </div>


      </div>
    );
  }
}
