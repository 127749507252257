// @flow
import React from 'react';
import Webcam from 'react-webcam';
import { Button, Modal } from '../../../../elements';
import './ProfileImageCaptureModal.scss';

type Props = {
  onClose: Function,
  onSavePicture: Function,
};

type State = {
  capturedFile: ?Object,
  base64Img: ?string,
};

export default class ProfileImageCaptureModal extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      capturedFile: null,
      base64Img: null,
    };
  }

  render() {
    const { onClose } = this.props;
    const { base64Img } = this.state;

    const videoConstraints = {
      width: 1280,
      height: 720,
      facingMode: 'user',
    };

    const content = base64Img ? (
      <img src={base64Img} alt='captured_image' />
    ) : (
      <Webcam
        audio={false}
        height={350}
        ref={webcam => { this.webcam = webcam; }}
        screenshotFormat='image/jpeg'
        width={350}
        videoConstraints={videoConstraints}
      />
    );

    const actionButtons = base64Img ? [
      <Button
        key='cancle'
        text='Cancelar'
        onClick={onClose}
      />,
      <Button
        key='clear'
        text='Limpar'
        onClick={this.handleClear}
      />,
      <Button
        key='save'
        text='Salvar'
        className='primary'
        onClick={this.onSavePicture}
      />,
    ] : [
      <Button
        key='cancle'
        text='Cancelar'
        onClick={onClose}
      />,
      <Button
        key='takePicture'
        text='Tirar Foto'
        className='primary'
        onClick={this.handleTakePicture}
      />,
    ];

    return (
      <Modal className='profileImageCaptureModal' onClose={onClose}>
        <div className='modalHeader actionHeader'>
          <span className='modalTitle'>Tirar Foto</span>
        </div>

        <div className='modalGrid'>
          <div className='modalContainer'>
            <div className='modalContent'>
              {content}
            </div>
            <div className='modalFooter'>
              {actionButtons}
            </div>
          </div>
        </div>
      </Modal>
    );
  }

  webcam: ?Object;

  handleTakePicture = () => {
    if (this.webcam) {
      const base64Img = this.webcam.getScreenshot();
      fetch(base64Img)
        .then(res => res.blob())
        .then(blob => {
          const capturedFile = new File([blob], 'profile_image.jpeg', { type: 'image/jpeg' });
          this.setState({ capturedFile, base64Img });
        });
    }
  };

  handleClear = () => {
    this.setState({
      capturedFile: null,
      base64Img: null,
    });
  };

  onSavePicture = () => {
    const { capturedFile } = this.state;

    if (capturedFile) {
      const img = new Image();
      img.src = window.URL.createObjectURL(capturedFile);
      img.onload = () => {
        capturedFile.width = img.naturalWidth;
        capturedFile.height = img.naturalHeight;
      };

      this.props.onSavePicture(capturedFile);
    }
  }
}
