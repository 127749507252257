// @flow
import React, { useEffect, useMemo, useCallback, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchPrescriptionLayout } from '@context/clinics';
import { get, size } from 'lodash';
import { router } from '@router';
import { Spinner, Button } from '@elements';
import { useTranslationNs, useIsMobile } from '@helpers/helpers';
import { closeActiveModal } from '@context/modals';
import './ViewPrintSettings.scss';

const ViewPrintSettings = () => {
  const [_] = useTranslationNs('timeline');
  const isMobile = useIsMobile();
  const dispatch = useDispatch();
  const data = useSelector((state) => get(state, ['context', 'clinics', 'prescritionPrintConfig', 'data'], {}) || {});
  const doctorId = useSelector((state) => get(state, ['context', 'user', 'advancedData', 'doctorId']));
  const isLoading = useSelector((state) => get(state, ['context', 'clinics', 'prescritionPrintConfig', 'isLoading']));
  const fetched = useRef(false);

  const showLoading = useMemo(() => size(data) > 0 && isLoading, [data, isLoading]);

  const handleViewSettings = useCallback(() => {
    dispatch(closeActiveModal());
    dispatch(router.pushPath('prescriptionsPrintConfig'));
  }, [dispatch]);

  useEffect(() => {
    if (!fetched.current) {
      dispatch(fetchPrescriptionLayout(doctorId));
      fetched.current = true;
    }
  }, [doctorId, dispatch, data, isLoading]);


  return (
    <div id="viewPrintSettings">
      {showLoading && <span><Spinner/>{_('label/loading', 'global')}</span>}
      {!showLoading && (
        <React.Fragment>
          <label>{_('label/print')}</label>: {data.printHeaderAndFooter ? _('label/white_paper') : _('label/letterhead_paper')}
          {!isMobile && (
            <Button onClick={handleViewSettings} className="linkAction" >{_('action/edit_perint_settings')}</Button>
          )}
        </React.Fragment>
      )}
    </div>
  );
};



export default ViewPrintSettings;
