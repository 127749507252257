import { map } from 'lodash';

function normalizePayments (inputPayments) {
  const payments = {};

  map(inputPayments, (payment) => {
    payments[payment.id] = payment;
  });

  return {
    payments,
  };
}

export default {
  normalizePayments,
};
