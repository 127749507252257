// @flow
import { get, forEach } from 'lodash';

/**
 * Use only for string
 * @param {any} value
 */
export const isPresent = (value: any) => value !== null && value !== undefined && String(value).trim().length > 0 && String(value) !== 'undefined' && String(value) !== 'null';
export const isPresentOneOf = (object: any, keys: Array<any>) => {
  let found = 0;
  forEach(keys, (key: string) => {
    if (isPresent(get(object, key))) {
      found++;
    }
  });
  return found > 0;
};

export const rndId = () => { // Public Domain/MIT
  let result = '';
  let i;
  let j;
  for (j = 0; j < 32; j++) {
    i = Math.floor(Math.random() * 16).toString(16).toUpperCase();
    result += i;
  }
  return result;
};

export const isEmailValid = (email: any) => {
  // eslint-disable-next-line
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email));
};


