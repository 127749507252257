/* eslint-disable no-console */

import _ from 'lodash';
import type { MiddlewareApi, Dispatch } from '@types';

function prefix (level) {
  return `[${level}]`;
}

function info () {
  if (window.ENV === 'production') return;
  console.log(prefix('INFO'), ...arguments);
}

function debug () {
  if (window.ENV === 'production') return;
  console.log(prefix('DEBUG'), ...arguments);
}

function debugObject () {
  if (window.ENV === 'production') return;
  console.log(prefix('DEBUG'), arguments);
}

function warn () {
  if (window.ENV === 'production') return;
  console.warn(prefix('WARN'), ...arguments);
}

function error () {
  if (window.ENV === 'production') return;
  console.error(prefix('ERROR'), ...arguments);
}

/**
 * Deep diff between two object, using lodash
 * @param  {Object} object Object compared
 * @param  {Object} base   Object to compare with
 * @return {Object}        Return a new object who represent the diff
 */
function diff (object, base) {
  function changes (o, b) {
    return _.transform(o, (result, value, key) => {
      if (!_.isEqual(value, b[key])) {
        result[key] = (_.isObject(value) && _.isObject(b[key]))
          ? changes(value, b[key])
          : value;
      }
    });
  }
  return changes(object, base);
}

const reduxMiddleware = ({ getState }: MiddlewareApi) => (next: Dispatch) => (action: Object) => {
  console.group(action.type);
  const oldState = getState();
  console.info('action', action);
  const result = next(action);
  const newState = getState();
  console.info('old state', oldState);
  console.info('diff', diff(newState, oldState));
  console.groupEnd();
  return result;
};

export default {
  info,
  debug,
  debugObject,
  warn,
  error,
  reduxMiddleware,
};
