// @flow
import type { ErrorsResult, AllErrorsResult } from './validatorTypes';

export default class ValidationResult {
    __result: ErrorsResult;
    __allResult: AllErrorsResult;
    constructor(result: ErrorsResult, allResult: AllErrorsResult) {
      this.__result = result;
      this.__allResult = allResult;
    }
    /**
     * Get errors as  [fieldName: string]: string
     */
    get errors() {
      return this.__result;
    }
    /**
     * Get errors as [fieldName: string]: Array<string>
     */
    get allErrors() {
      return this.__allResult;
    }
    /**
     * Form data is valid
     */
    get valid() {
      return Object.keys(this.__result).length === 0;
    }
}
