export default {
  'label/service_menu/confirmed': 'Marcar como Contratado',
  'label/service_menu/canceled': 'Marcar como Cancelado',
  'label/service_menu/forecasted': 'Marcar como Previsto',
  'label/service_menu/edit': 'Editar',
  'label/payment_menu/verified': 'Marcar como Verificado',
  'label/payment_menu/unverified': 'Marcar como No Verificado',
  'label/payment_menu/on_debt': 'Marcar como en Deuda',
  'label/payment_menu/new_receipt': 'Generar Recibo',
  'label/payment_menu/edit': 'Editar',
  'label/tab_items/profile': 'Registro',
  'label/tab_items/timeline': 'Expediente',
  'label/tab_items/financial': 'Financiero',
  'action/new_service': 'Nuevo Servicio',
  'action/new_payment': 'Nuevo Pago',
  'action/new_receipt': 'Nuevo Recibo',
  'action/create_new_receipt': 'Crear Nuevo Servicio',
  'action/edit_services': 'Editar Servicios',
  'label/with_installments': 'Parcelar pago',
  'label/installments': 'Nº de parcelas',
  'label/services': 'Servicios',
  'label/payments': 'Pagos',
  'label/receipts': 'Recibos',
  'label/balance': 'Saldo',
  'label/financial': 'Financiero',
  'label/services_empty/title': 'No hay servicios registrados.',
  'label/receipts_empty/title': 'No hay recibos registrados.',
  'label/payments_empty/title': 'No hay pagos registrados.',
  'label/new_service_to': 'Nuevo Servicio para',
  'label/edit_service': 'Editar Servicio',
  'label/error/name': 'Informe un nombre',
  'label/error/date': 'Seleccione una fecha',
  'label/error/value': 'Informe un valor válido',
  'label/new_receipt_to': 'Nuevo Recibo para',
  'label/service_provided': 'Servicio Prestado',
  'label/additional_informations': 'Informaciones Adicionales',
  'label/seller_name': 'Nombre Completo del Profesional',
  'label/seller_cpf': 'Tax ID del Profesional',
  'action/patient_not_buyer': 'Pago no realizado por el paciente',
  'label/buyer': 'Responsable del pago',
  'label/buyer_name': 'Nombre del Responsable del Pago',
  'label/buyer_cpf': 'Tax ID del Responsable del Pago',
  'label/error/service': 'Informe un servicio',
  'label/error/price': 'Informe un precio',
  'label/error/cpf': 'Informe un Tax ID válido',
  'label/error/patient_name': 'Nombre del paciente requerido',
  'label/error/seller': 'Profesional requerido',
  'label/error/buyer': 'Informe el nombre del responsable',
  'label/service_taker': 'Tomador de los servicios',
  'label/responsible_info': 'Responsable del pago y tomador de los servicios',
  'label/service_provider': 'Prestador de los servicios',
  'label/receipt_title': 'Recibo de Honorarios nº',
  'label/payment_method': 'Medio de Pago',
  'label/check_number': 'Núm. del Cheque',
  'label/with_invoice': 'Con Factura',
  'label/invoice_number': 'Número de la Factura',
  'label/error/valid_value': 'Informe un valor válido.',
  'action/add_new_services': 'Agregar Nuevos Servicios',
  'label/debt_balance': 'Saldo Deudor',
  'action/search_service': 'Buscar servicio existente',
};
