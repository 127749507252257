// @flow
import React from 'react';
import { SettingsMenu, FormFooter, PlatformSwitch, AppMobileHeader, AppMobileFooter } from '@blocks';
// import { Breadcrumb, Input, Checkbox, RadioGroup, Loading } from '@elements';
import { Breadcrumb, Input, RadioGroup, Loading, Toggle, Button, TextArea, SelectBox } from '@elements';
import type { User } from '@types';
import { withForm } from '@hocs';
import { get, cloneDeep, isEqual } from 'lodash';
import { createValidator } from '@validator';
import { withTranslation, useTranslation } from 'react-i18next';
import { createNsTranslator, handleSubmitEvent } from '@helpers/helpers';
import { pdfFonts } from '@helpers/constants';

type Props = {
  formData: Object,
  touchedFields: Object,
  changedFields: Object,
  bindValidation: Function,
  handleFormChange: Function,
  handleTouchField: Function,
  formChange: Function,
  formEdited: Function,
  onPreviewPDF: Function,
  touchField: Function,
  resetData: Function,
  user: User,
  isSaving: boolean,
  isLoading: boolean,
  onSave: Function,
  onLogout: Function,
  t: Function,
};

type State = {
  errors: Object,
  initialFormData: Object,
};


const validator = createValidator();
//   .field('pageMarginTop')
//   .numberBetween(45, 150, _ => _('error/validation/print/margin_top'))
// //
//   .field('pageMarginBottom')
//   .numberBetween(60, 150, _ => _('error/validation/print/margin_bottom'))
// //
//   .field('pageMarginLeft')
//   .numberBetween(0, 100, _ => _('error/validation/print/margin_left'))
// //
//   .field('pageMarginRight')
//   .numberBetween(0, 100, _ => _('error/validation/print/margin_left'));


const FormContainer = (props: Object) => {
  const [_] = useTranslation('settings');

  const breadcrumbLinks = [
    { text: _('label/print_config/menu/title'), linkTo: '/ajustes/impressao' },
    { text: _('label/print_config/menu/eletronic_receipts') },
  ];

  return (
    <PlatformSwitch
      inject
      desktop={() => (
        <div className="appContent settingsPage">
          <aside className="sidebar">
            <SettingsMenu user={props.user} onLogout={props.onLogout} permissions={props.permissions} />
          </aside>
          <section className="mainSection">
            <div className="centerContent prescriptionsPrintConfig" id="printConfigSubmitHandler">
              <Breadcrumb items={breadcrumbLinks} />
              <div className="toolbar dashed">
                <h1>{_('label/print_config/prescription/title')}</h1>
              </div>
              {props.children}
            </div>
          </section>
        </div>
      )}
      mobile={() => (
        <React.Fragment>
          <AppMobileHeader title={_('label/print_config/prescription/title')} backLinkTo="/ajustes/impressao" />
          <div className="appContent settingsPage">
            <section className="mainSection">
              <div className="centerContent">{props.children}</div>
            </section>
          </div>
          <AppMobileFooter />
        </React.Fragment>
      )}
    />
  );
};

class PrescriptionsPrintConfig extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      errors: {},
      initialFormData: cloneDeep(this.props.formData),
    };
    this.props.bindValidation(this.handleValidation);
  }

  componentDidMount() {
    handleSubmitEvent('#printConfigSubmitHandler', () => {
      if (this.props.formEdited()) {
        this.onSave();
      }
    });
  }

  render() {
    const {
      handleFormChange, formData, handleTouchField, isLoading, isSaving,
    } = this.props;

    const _ = createNsTranslator('settings', this.props.t);

    const { errors } = this.state;
    const printHeaderAndFooter = get(formData, 'printHeaderAndFooter', null);

    const typePrintItems = [
      { text: _('label/print_config/prescription/letterhead'), value: 'false' },
      { text: _('label/print_config/prescription/nohead'), value: 'true' },
    ];

    const needsSave = !isEqual(this.state.initialFormData, this.props.formData);

    return (
      <FormContainer {...this.props} >
        <RadioGroup
          disabled={isLoading || isSaving}
          name="printHeaderAndFooter"
          selectGroupName="printHeaderAndFooter"
          label={_('label/print_config/prescription/paper_type')}
          items={typePrintItems}
          error={get(errors, 'printHeaderAndFooter', null)}
          value={String(get(formData, 'printHeaderAndFooter', null))}
          onChange={handleFormChange}
          onBlur={handleTouchField}
        />

        {(printHeaderAndFooter === true || printHeaderAndFooter === 'true') &&
          <React.Fragment>
            <p>{_('label/print_config/prescription/heading')}</p>
            <Toggle
              disabled={isLoading}
              name="clinicLogo"
              label={_('label/clinic_logo')}
              error={get(errors, 'clinicLogo', null)}
              isChecked={get(formData, 'clinicLogo', null)}
              onChange={handleFormChange}
              onBlur={handleTouchField}
            />
            <br />
            <Input
              disabled={isLoading}
              name="headerName"
              className="field"
              label={_('label/print_config/prescription/line_1')}
              error={get(errors, 'headerName', null)}
              value={get(formData, 'headerName', null)}
              onChange={handleFormChange}
              onBlur={handleTouchField}
            />

            <TextArea
              disabled={isLoading}
              name="headerText"
              className="field"
              label={_('label/print_config/prescription/line_2')}
              error={get(errors, 'headerText', null)}
              value={get(formData, 'headerText', null)}
              onChange={handleFormChange}
              onBlur={handleTouchField}
            />
            <p>{_('label/print_config/prescription/footer')}</p>
            <TextArea
              disabled={isLoading}
              name="footerText"
              className="field"
              error={get(errors, 'footerText', null)}
              value={get(formData, 'footerText', null)}
              onChange={handleFormChange}
              onBlur={handleTouchField}
            />
          </React.Fragment>
        }

        <SelectBox
          options={pdfFonts}
          type="tel"
          disabled={isLoading}
          name="font"
          className="field"
          label={_('label/print_config/prescription/font')}
          error={get(errors, 'font', null)}
          value={get(formData, 'font', 'font_arial') || 'font_arial'}
          onChange={handleFormChange}
          onBlur={handleTouchField}
        />

        {/* <div className="split">
          <Input
            type="tel"
            disabled={isLoading}
            name="pageMarginTop"
            className="field"
            label={_('label/print_config/prescription/margin_top')}
            error={get(errors, 'pageMarginTop', null)}
            value={get(formData, 'pageMarginTop', 45)}
            onChange={handleFormChange}
            onBlur={handleTouchField}
          />
          <Input
            type="tel"
            disabled={isLoading}
            name="pageMarginBottom"
            className="field"
            label={_('label/print_config/prescription/margin_bottom')}
            error={get(errors, 'pageMarginBottom', null)}
            value={get(formData, 'pageMarginBottom', 60)}
            onChange={handleFormChange}
            onBlur={handleTouchField}
          />
        </div>
        <div className="split">
          <Input
            type="tel"
            disabled={isLoading}
            name="pageMarginLeft"
            className="field"
            label={_('label/print_config/prescription/margin_left')}
            error={get(errors, 'pageMarginLeft', null)}
            value={get(formData, 'pageMarginLeft', 30)}
            onChange={handleFormChange}
            onBlur={handleTouchField}
          />
          <Input
            type="tel"
            disabled={isLoading}
            name="pageMarginRight"
            className="field"
            label={_('label/print_config/prescription/margin_right')}
            error={get(errors, 'pageMarginRight', null)}
            value={get(formData, 'pageMarginRight', 30)}
            onChange={handleFormChange}
            onBlur={handleTouchField}
          />
        </div> */}
        <FormFooter onSave={this.onSave} isLoading={isLoading || isSaving}>
          <Button onClick={this.handlePreview} disabled={needsSave}>
            {_('label/print_config/prescription/preview_pdf')}
          </Button>
        </FormFooter>
      </FormContainer>
    );
  }

  handleValidation = (isSubmit: boolean = false): Promise<boolean> => new Promise((resolve: Function) => {
    const { formData, touchedFields } = this.props;
    // const handleMargin = (name: string, def: number) => {
    //   const v = toNumber(get(formData, name));
    //   if (isNumber(v)) {
    //     return v;
    //   }

    //   return def;
    // };

    const newFormData = {
      ...formData,
      version: 2,
      // pageMarginTop: handleMargin('pageMarginTop', 45),
      // pageMarginBottom: handleMargin('pageMarginBottom', 60),
      // pageMarginLeft: handleMargin('pageMarginLeft', 30),
      // pageMarginRight: handleMargin('pageMarginRight', 30),
      printHeaderAndFooter: formData.printHeaderAndFooter === 'true' || formData.printHeaderAndFooter === true,
      clinicLogo: formData.clinicLogo === 'true' || formData.clinicLogo === true,
    };

    const result = validator.validate(newFormData, touchedFields, isSubmit);
    this.setState({ errors: result.errors }, () => resolve(result.valid));
  });

  onSave = () => this.handleValidation(true).then(valid => {
    if (valid) {
      const { onSave, formData } = this.props;
      // const handleMargin = (name: string, def: number) => {
      //   const v = toNumber(get(formData, name));
      //   if (isNumber(v)) {
      //     return v;
      //   }

      //   return def;
      // };
      onSave({
        ...formData,
        version: 2,
        // pageMarginTop: handleMargin('pageMarginTop', 45),
        // pageMarginBottom: handleMargin('pageMarginBottom', 60),
        // pageMarginLeft: handleMargin('pageMarginLeft', 30),
        // pageMarginRight: handleMargin('pageMarginRight', 30),
        printHeaderAndFooter: formData.printHeaderAndFooter === 'true' || formData.printHeaderAndFooter === true,
        clinicLogo: formData.clinicLogo === 'true' || formData.clinicLogo === true,
      });
      this.setState({ initialFormData: formData });
    }
  });

  handlePreview = () => {
    this.props.onPreviewPDF(this.props.formData);
  };
}

const initialData = {
  pageMarginTop: 45,
  pageMarginBottom: 60,
  pageMarginLeft: 30,
  pageMarginRight: 30,
};

const PrescriptionsPrintConfigForm = withTranslation()(withForm(initialData)(PrescriptionsPrintConfig));

export default (props: any) => {
  if (props.isLoading) {
    return (
      <FormContainer {...props} >
        <Loading />
      </FormContainer>
    );
  }

  return <PrescriptionsPrintConfigForm {...props} />;
};

