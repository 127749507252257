// @flow
import React from 'react';
import { connect } from 'react-redux';
import { ProblemsFormModal } from '@views';
import { get } from 'lodash';
import { actions } from '@context/modals';
import { addProblem, deleteProblem } from '@context/patients';
import type { Dispatch, Patient } from '@types';

type Props = {
  dispatch: Function,
  isLoading: boolean,
  patient: Patient,
};

class ProblemsFormModalContainer extends React.Component<Props> {
  render() {
    const { isLoading, patient } = this.props;

    return (
      <ProblemsFormModal
        onClose={this.closeModal}
        onAddNewItem={this.handleAddNewItem}
        problems={get(patient, 'problems', [])}
        onDeleteItem={this.handleDeleteItem}
        isLoading={isLoading === true}
      />
    );
  }

  closeModal = () => {
    this.props.dispatch(actions.closeActiveModal());
  };

  handleAddNewItem = (item: string) => {
    this.props.dispatch(addProblem(this.props.patient.id, item));
  };
  handleDeleteItem = (id: number) => {
    this.props.dispatch(deleteProblem(this.props.patient.id, id));
  };
}

const mapStateToProps = (state) => ({
  patient: get(state, ['context', 'patients', 'data', get(state, ['context', 'modals', 'data', 'id'], {})], {}),
  isLoading: get(state, ['context', 'patients', 'problemsLoading', get(state, ['context', 'modals', 'data', 'id'], {})], {}),
});


const mapDispatchToProps = (dispatch: Dispatch) => ({ dispatch });

export default connect<any, any, any, any, any, any>(
  mapStateToProps,
  mapDispatchToProps,
)(ProblemsFormModalContainer);
