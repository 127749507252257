// @flow
import React from 'react';
import { SettingsMenu, PlatformSwitch, AppMobileHeader, AppMobileFooter } from '@blocks';
import type { User, BundledPermissions } from '@types';
import { ClickableList } from '@elements';
import { withTranslation } from 'react-i18next';
import { createNsTranslator } from '@helpers/helpers';

type Props = {
  user: User,
  onLogout: Function,
  t: Function,
  permissions: BundledPermissions,
};

class ClinicConfigMenu extends React.Component<Props> {
  render() {
    const { user, onLogout } = this.props;

    const _ = createNsTranslator('settings', this.props.t);

    const items = [
      {
        text: _('label/agenda_menu/workhours'),
        linkTo: '/ajustes/clinica/horario',
      },
      {
        text: _('label/agenda_menu/rooms'),
        linkTo: '/ajustes/clinica/salas',
      },
    ];

    return (
      <PlatformSwitch
        inject
        desktop={() => (
          <div className="appContent settingsPage">
            <aside className="sidebar">
              <SettingsMenu user={user} onLogout={onLogout} permissions={this.props.permissions} />
            </aside>
            <section className="mainSection">
              <div className="centerContent">
                <ClickableList items={items} card />
              </div>
            </section>
          </div>
        )}
        mobile={() => (
          <React.Fragment>
            <AppMobileHeader title={_('label/agenda_menu/title')} backLinkTo="/ajustes" />
            <div className="appContent settingsPage">
              <section className="mainSection">
                <ClickableList items={items} card />
              </section>
            </div>
            <AppMobileFooter />
          </React.Fragment>
        )}
      />
    );
  }
}

export default withTranslation()(ClinicConfigMenu);
