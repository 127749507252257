// @flow
import React from 'react';
import { get } from 'lodash';
import { NewClinic as NewClinicView } from '@views';
import { logout, createClinic } from '@context/user';
import { connect } from 'react-redux';

type Props = {
  dispatch: Function,
  isLoading: boolean,
  isSecretary: boolean,
}

class NewClinic extends React.Component<Props> {
  render() {
    return (
      <NewClinicView
        isLoading={this.props.isLoading}
        isSecretary={this.props.isSecretary}
        onCreateClinic={this.handleCreateClinic}
        onLogout={this.handleLogout}
      />
    );
  }
  handleCreateClinic = () => {
    this.props.dispatch(createClinic());
  };

  handleLogout = () => {
    this.props.dispatch(logout(true));
  };
}

const mapStateToProps = (state) => ({
  isLoading: get(state, ['context', 'user', 'isCreatingClinic'], false),
  isSecretary: get(state, ['context', 'user', 'roleType'], '') === 'Secretary',
});

export default connect<any, any, any, any, any, any>(mapStateToProps)(NewClinic);
