// @flow
import React from 'react';
import { Link } from 'react-router-dom';
import { get, map } from 'lodash';
import { SettingsMenu, PlatformSwitch, AppMobileHeader, ConditionalFeature, AppMobileFooter, FormFooter } from '@blocks';
import { Input, Breadcrumb, SelectBox, InputMask, RadioGroup, Toggle, DoctorColorIcon } from '@elements';
import type { User, Room, BundledPermissions } from '@types';
import { createValidator } from '@validator';
import { withForm } from '@hocs';
import { durations, indicatorColors, professionCodes } from '@constants';
import { localizeListItems, createNsTranslator, fSufixTwoPoints, handleSubmitEvent } from '@helpers/helpers';
import { withTranslation } from 'react-i18next';

type Props = {
  user: User,
  onCancel: Function,
  onSave: Function,
  handleFormChange: Function,
  handleTouchField: Function,
  bindValidation: Function,
  onLogout: Function,
  formEdited: Function,
  formData: Object,
  touchedFields: Object,
  rooms: Array<Room>,
  id: number,
  isFeatureEnabled: boolean,
  permissions: BundledPermissions,
  t: Function,
  isSaving: boolean,
};


type State = {
  errors: Object,
};

const validator = isEdit => (isEdit ? (
  createValidator()
    .field('fullName')
    .present(_ => _('error/full_name'))
    .field('cpf')
    .cpf(_ => _('error/cpf'), true)
) : (
  createValidator()
    .field('name')
    .present(_ => _('error/name'))
    .field('email')
    .present(_ => _('error/email'))
    .field('profession_code')
    .present(_ => _('error/profession_spec'))
    .field('cpf')
    .cpf(_ => _('error/cpf'), true)
));

class DoctorForm extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      errors: {},
    };
  }

  componentDidMount() {
    this.props.bindValidation(this.handleValidation);
    handleSubmitEvent('#clinicConfigDoctorDesktopForm', () => {
      if (this.props.formEdited()) {
        this.onSave();
      }
    });
  }

  render() {
    const {
      user, formData, id, handleFormChange, handleTouchField,
      onCancel, rooms, isFeatureEnabled,
      onLogout, isSaving,
    } = this.props;

    const _ = createNsTranslator('settings', this.props.t);

    const isEdit = id !== 'novo';

    const colors = map<any, any>(localizeListItems(indicatorColors), item => ({
      text: <div className="doctorFormInidcatorColor"><DoctorColorIcon colorId={item.value} /><span>{item.text}</span></div>,
      value: item.value,
    }));

    const roomsItens = map<any, any>(rooms, item => ({
      text: item.name,
      value: item.number,
    }));

    const olProfessionCodes = localizeListItems(professionCodes);


    const breadcrumbLinks = [
      { text: _('label/clinic_doctors/list/title_mobile'), linkTo: '/ajustes/doutores' },
      { text: isEdit ? _('action/edit', 'global') : _('action/invite', 'global') },
    ];

    const pageContent = (
      <React.Fragment>
        <div className="toolbar dashed">
          <h1>{isEdit ? _('label/clinic_doctors/form/title_edit') : _('label/clinic_doctors/form/title_invite')}</h1>
        </div>

        <Input
          disabled={isEdit}
          label={_('label/name', 'global', fSufixTwoPoints)}
          name="name"
          onChange={handleFormChange}
          value={get(formData, 'name', '')}
          error={get(this.state.errors, 'name')}
          required={!isEdit}
          onBlur={handleTouchField}
          autofocus={!isEdit}
        />
        <Input
          disabled={isEdit}
          label={_('label/email', 'global', fSufixTwoPoints)}
          name="email"
          required={!isEdit}
          onChange={handleFormChange}
          value={get(formData, 'email', '')}
          error={get(this.state.errors, 'email')}
          onBlur={handleTouchField}
        />
        {!isEdit &&
          <React.Fragment>
            <RadioGroup
              name="gender"
              label={_('label/gender', 'global', fSufixTwoPoints)}
              defaultType="gender"
              selectGroupName="gender"
              value={get(formData, 'gender', '')}
              error={get(this.state.errors, 'gender', null)}
              onChange={handleFormChange}
              onBlur={handleTouchField}
            />
            <SelectBox
              options={olProfessionCodes}
              label={_('label/profession_spec', 'global', v => `${v} :*`)}
              required
              name="profession_code"
              onChange={handleFormChange}
              value={get(formData, 'profession_code', '')}
              error={get(this.state.errors, 'profession_code')}
            />
            <InputMask
              type="phone"
              label={_('label/mobile_phone', 'global', fSufixTwoPoints)}
              name="mobile_phone"
              onChange={handleFormChange}
              value={get(formData, 'mobile_phone', '')}
              error={get(this.state.errors, 'mobile_phone')}
              onBlur={handleTouchField}
            />
          </React.Fragment>
        }
        {isEdit &&
          <React.Fragment>
            <Input
              label={_('label/clinic_doctors/form/name_in_receipt', null, fSufixTwoPoints)}
              name="fullName"
              onChange={handleFormChange}
              value={get(formData, 'fullName', '')}
              error={get(this.state.errors, 'fullName')}
              onBlur={handleTouchField}
              required
              autofocus={isEdit}
            />
            <InputMask
              type="cpf"
              label={_('label/cpf', 'global', fSufixTwoPoints)}
              name="cpf"
              onChange={handleFormChange}
              value={get(formData, 'cpf', '')}
              error={get(this.state.errors, 'cpf')}
              onBlur={handleTouchField}
            />
            <Input
              label={_('label/clinic_doctors/form/council_doc', null, fSufixTwoPoints)}
              name="councilDoc"
              onChange={handleFormChange}
              value={get(formData, 'councilDoc', '')}
              error={get(this.state.errors, 'councilDoc')}
              onBlur={handleTouchField}
            />
            <SelectBox
              options={colors}
              label={_('label/clinic_doctors/form/agenda_color', null, fSufixTwoPoints)}
              required
              name="color"
              onChange={handleFormChange}
              value={get(formData, 'color', '')}
              error={get(this.state.errors, 'color')}
            />
            <Input
              type="text"
              name="agendaNotes"
              label={_('label/clinic_doctors/form/agenda_info', null, fSufixTwoPoints)}
              onChange={handleFormChange}
              value={get(formData, 'agendaNotes', '')}
              error={get(this.state.errors, 'agendaNotes')}
            />
            <SelectBox
              options={durations}
              label={_('label/clinic_doctors/form/default_appointment_duration', null, fSufixTwoPoints)}
              name="defaultAppointmentDuration"
              onChange={handleFormChange}
              value={get(formData, 'defaultAppointmentDuration', '')}
              error={get(this.state.errors, 'defaultAppointmentDuration')}
            />
            <SelectBox
              options={roomsItens}
              label={_('label/clinic_doctors/form/default_room', null, fSufixTwoPoints)}
              name="defaultAppointmentRoom"
              onChange={handleFormChange}
              value={get(formData, 'defaultAppointmentRoom', '')}
              error={get(this.state.errors, 'defaultAppointmentRoom')}
            />
          </React.Fragment>
        }
        {isEdit &&
          <Link to={`/ajustes/doutores/${id}/horarios-de-atendimento`} className="link">{_('label/clinic_doctors/form/edit_workhours')}</Link>
        }
        <Toggle
          label={_('label/clinic_doctors/form/is_administrator')}
          name="manager"
          onChange={handleFormChange}
          isChecked={get(formData, 'manager', false)}
          error={get(this.state.errors, 'manager')}
        />
        <p>
          {_('label/clinic_doctors/form/administrator_hint')}
        </p>
        <FormFooter
          savingText={isEdit ? _('label/saving_simple', 'global') : _('label/inviting', 'global')}
          saveText={isEdit ? _('action/save', 'global') : _('action/invite', 'global')}
          onSave={this.onSave}
          isLoading={isSaving}
          onCancel={onCancel}
        />
      </React.Fragment>
    );

    return (
      <PlatformSwitch
        inject
        desktop={() => (
          <div id="clinicConfigDoctorDesktopForm" className="appContent settingsPage">
            <aside className="sidebar">
              <SettingsMenu user={user} onLogout={onLogout} permissions={this.props.permissions} />
            </aside>
            <section className="mainSection">
              <ConditionalFeature enabled={isFeatureEnabled} transparent>
                <div className="centerContent form">
                  <Breadcrumb items={breadcrumbLinks} />
                  {pageContent}
                </div>
              </ConditionalFeature>
            </section>
          </div>
        )}
        mobile={() => (
          <React.Fragment>
            <AppMobileHeader
              title={isEdit ? _('label/clinic_doctors/form/title_edit') : _('label/clinic_doctors/form/title_invite')}
              backLinkTo="/ajustes/doutores"
            />
            <div id="doctorsPage" className="appContent settingsPage">
              <section className="mainSection">
                <ConditionalFeature enabled={isFeatureEnabled} transparent>
                  <div className="centerContent">{pageContent}</div>
                </ConditionalFeature>
              </section>
            </div>
            <AppMobileFooter />
          </React.Fragment>
        )}
      />
    );
  }

  handleValidation = (isSubmit: boolean = false): Promise<boolean> => new Promise((resolve: Function) => {
    const { formData, touchedFields } = this.props;
    const result = validator(this.props.id !== 'novo').validate(formData, touchedFields, isSubmit);
    this.setState({ errors: result.errors }, () => resolve(result.valid));
  });

  onSave = () => {
    const { formData, onSave } = this.props;
    const result = validator(this.props.id !== 'novo').validate(formData, {}, true);

    if (result.valid) {
      onSave(formData);
    }
    this.setState({ errors: result.errors });
  };
}


export default withTranslation()(withForm()(DoctorForm));
