// @flow
import React from 'react';
import moment from 'moment';
import { sort, dateCompare, createNsTranslator, handleReportPeriods } from '@helpers/helpers';
import { get, sumBy, isNaN, first, toArray, size, toNumber } from 'lodash';
import {
  Icon, Button, ModalClickableList,
  Badge, Loading, FormattedText, Table, Tooltip,
} from '@elements';
import { ReportsMenu, ReportPage, PlatformSwitch, AppMobileHeader, ReportsFilters, EmptyState, ConditionalFeature, BlockedReport, AppMobileFooter } from '@blocks';
import type { DoctorsState, PaymentsState, FormElementData } from '@types';
import { withStorage } from '@hocs';
import { withTranslation } from 'react-i18next';
import './ReportsPayments.scss';

type Item = {
  text: string | number,
  value: any,
};

type Props = {
  doctors: DoctorsState,
  payments: PaymentsState,
  onLoadData: (startDate: string, endDate: string, doctorId?: number | null, notaFiscal?: boolean) => void,
  onUpdatePayment: Function,
  componentStorage: Object,
  onViewPatient: Function,
  onDownloadReport: (startDate: string, endDate: string, doctorId?: number | null, notaFiscal?: boolean) => void,
  defaultDoctorId: number,
  isManager: boolean,
  isFeatureEnabled: boolean,
  reportsSelectDoctor: boolean,
  singleDoctor: boolean,
  isReportBlocked: boolean,
  t: Function,
};

type State = {
  period: string,
  doctor: string,
  startDate: string,
  endDate: string,
  showPaymentActions: boolean,
  paymentActionId: number,
  paymentActionPatientId: number,
  year: number,
  month: number,
  notaFiscal: boolean,
  showMobileFilters: boolean,
};

const paymentMenuItems = [
  { text: 'Marcar como Verificado', value: 'verified' },
  { text: 'Marcar como Não Verificado', value: 'unverified' },
  { text: 'Marcar como em Débito', value: 'on_debt' },
  { text: 'Ver Paciente', value: 'see_pacient' },
];

class ReportsPayments extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    const state = {
      period: 'today',
      doctor: this.props.defaultDoctorId || get(first(toArray(get(this.props.doctors, 'data'))), 'id'),
      showPaymentActions: false,
      paymentActionId: 0,
      paymentActionPatientId: 0,
      month: moment().month() + 1,
      year: moment().year(),
      notaFiscal: false,
      showMobileFilters: false,
      startDate: moment().startOf('month').format('YYYY-MM-DD'),
      endDate: moment().endOf('month').format('YYYY-MM-DD'),
      ...props.componentStorage.get('filters', {}),
    };
    this.state = {
      ...state,
      month: parseInt(state.month, 10) > 0 ? state.month : moment().month() + 1,
    };
  }

  componentDidMount() {
    if (this.props.isFeatureEnabled) {
      this.handleRequest();
    }
  }

  render() {
    const {
      doctors, payments, isManager, isFeatureEnabled, reportsSelectDoctor, singleDoctor, isReportBlocked,
    } = this.props;
    const {
      period, doctor, startDate, endDate, showPaymentActions,
      month, year, notaFiscal, showMobileFilters,
    } = this.state;

    const _ = createNsTranslator('reports', this.props.t);


    if (isReportBlocked) {
      return (
        <BlockedReport
          backLinkTo="/relatorios"
          title={_('menu/payments')}
        />
      );
    }

    const isLoadingResume = get(doctors, 'isLoading', false);
    const isLoadingPayment = get(payments, 'isLoading', false);
    const isLoadingReport = get(payments, 'isLoadingReport', false);

    const momentStartDate = moment(startDate, 'YYYY-MM-DD');
    const momentEndDate = moment(endDate, 'YYYY-MM-DD');

    const isLoading = isLoadingResume || isLoadingPayment;

    const summary = get(payments, 'summary', []);

    const sumSummary = sumBy(summary, item => parseFloat(item.sumPrices));

    if (this.props.isFeatureEnabled && !payments) return false;

    const paymentsData = sort(get(payments, ['data'], {}), dateCompare('date', 'desc'));

    const getEmptyState = () => (
      <EmptyState
        onReportFilter={this.toggleMobileFilter}
        title={_('payments/empty_title')}
        hint={_('payments/empty_hint')}
      />
    );

    const summaryTable = () => (
      <Table
        itemKey="id"
        columns={[
          {
            dataIndex: 'doctorName',
            content: _('label/doctor', 'global'),
          },
          {
            dataIndex: 'sumPrices',
            content: _('label/value', 'global'),
            onRenderItem: (item) => <FormattedText type="currency" text={item.sumPrices} />,
          },
        ]}
        fullTable
        items={summary}
        renderFooter={(isMobile: boolean) => (
          <React.Fragment>
            {isMobile && (
              <table className="table">
                <tbody>
                  {size(summary) > 1 &&
                  <tr>
                    <td><strong>{_('label/total', 'global')}</strong></td>
                    <td><strong><FormattedText type="currency" text={sumSummary} /></strong></td>
                  </tr>
                  }
                </tbody>
              </table>
            )}
            {!isMobile && (
              <React.Fragment>
                {size(summary) > 1 &&
                <tr>
                  <td><strong>{_('label/total', 'global')}</strong></td>
                  <td><strong><FormattedText type="currency" text={sumSummary} /></strong></td>
                </tr>
                    }
              </React.Fragment>
            )}
          </React.Fragment>
        )}
      />
    );

    const reportTable = () => (
      <Table
        itemKey="id"
        fullTable
        items={paymentsData}
        onItemClick={this.handlePaymentItemClick}
        mobileClickLabel={_('action/change', 'global')}
        columns={[
          {
            dataIndex: 'date',
            content: _('label/date', 'global'),
            onRenderItem: (item) => <FormattedText type="date" text={item.date} />,
          },
          {
            dataIndex: 'patientName',
            content: _('label/patient', 'global'),
          },
          {
            dataIndex: 'doctorName',
            content: _('label/doctor', 'global'),
            hidden: singleDoctor || this.state.doctor !== 'all',
          },
          {
            dataIndex: 'method',
            content: _('label/payment_method_short', 'global'),
            onRenderItem: (payment) => (
              <React.Fragment>
                <FormattedText type="paymentMethod" text={payment.method} />
                <div className="paymentNote">{payment.notes}</div>
              </React.Fragment>
            ),
          },
          {
            dataIndex: 'withNotaFiscal',
            content: <React.Fragment>&nbsp;</React.Fragment>,
            desktopOnly: true,
            onRenderItem: (item) => (item.withNotaFiscal ? <Tooltip text="Nota Fiscal" className="tolltipNF">NF</Tooltip> : ''),
          },
          {
            dataIndex: 'withNotaFiscal',
            content: 'Nota Fiscal',
            mobileOnly: true,
            onRenderItem: (item) => (item.withNotaFiscal ? 'Sim' : 'Não'),
          },
          {
            dataIndex: 'price',
            content: _('label/value', 'global'),
            onRenderItem: (payment) => <FormattedText type="currency" text={payment.price} />,
          },
          {
            dataIndex: 'status',
            content: _('label/status', 'global'),
            onRenderItem: (payment) => <Badge className={payment.status} />,
          },
        ]}
      />
    );

    const isAllEmpty = size(summary) === 0 && size(paymentsData) === 0;

    return (
      <PlatformSwitch
        className="platformSwitcher"
        desktop={() => (
          <div id="reportsPaymentsPage" className="appContent reportsPage">
            <ReportsMenu />
            {showPaymentActions &&
              <ModalClickableList
                items={paymentMenuItems}
                onListItemClick={this.handlePaymentActionClick}
                onClose={this.handlePaymentActionOnClose}
              />
            }
            <section className="mainSection">
              <ConditionalFeature enabled={isFeatureEnabled} transparent>
                <div className="centerContent">

                  <div className="toolbar">
                    <h1>{_('payments/title')}</h1>
                    <div className="actions">
                      <Button disable={isAllEmpty || isLoadingReport} onClick={this.handleDownloadReport}>
                        <Icon iconName="download" className="icon" size={18} />
                        <span>{_('action/download_report')}</span>
                      </Button>
                    </div>
                  </div>
                  <div className="desktopCard">
                    <ReportsFilters
                      reportsSelectDoctor={reportsSelectDoctor}
                      isManager={isManager}
                      withNotaFiscal
                      notaFiscal={notaFiscal}
                      doctors={doctors}
                      isLoading={isLoading}
                      onChangeFilter={this.handleFilterChange}
                      doctor={doctor}
                      period={period}
                      year={year}
                      month={month}
                      startDate={momentStartDate.format('YYYY-MM-DD')}
                      endDate={momentEndDate.format('YYYY-MM-DD')}
                    />
                    {isAllEmpty && !isLoading && !isLoadingReport ?
                      getEmptyState()
                      :
                      <React.Fragment>

                        {!isLoading &&
                        <section>
                          <div className="toolbar">
                            <h2>{_('label/resume', 'global')}</h2>
                          </div>
                          {summaryTable()}
                        </section>
                      }

                        <section>
                          <div className="toolbar">
                            <h2>{_('payments/title')}</h2>
                          </div>
                          {isLoading && <Loading />}
                          {!isLoading && reportTable()}
                        </section>

                      </React.Fragment>
                  }
                  </div>
                </div>
              </ConditionalFeature>
            </section>
          </div>
        )}
        mobile={() => (
          <React.Fragment>
            <AppMobileHeader title={_('menu/payments')} backLinkTo="/relatorios">
              <div className="headerActions">
                {!isAllEmpty && !isLoadingReport && (
                <Icon iconName="download" className="icon" onClick={this.handleDownloadReport} />
                )}
                {(!isAllEmpty && isLoadingReport) && (
                <Icon iconName="download" className="icon" />
                )}
                <Icon iconName="filter" className="icon" onClick={this.toggleMobileFilter} />
                {/* <Icon iconName="refresh" className="icon" onClick={this.handleRefreshClick} /> */}
              </div>
            </AppMobileHeader>
            <ReportPage
              id="reportsPaymentsPage"
              menu={() => showPaymentActions && (
                <ModalClickableList
                  items={paymentMenuItems}
                  onListItemClick={this.handlePaymentActionClick}
                  onClose={this.handlePaymentActionOnClose}
                />)}
              filters={() => showMobileFilters && (
                <ReportsFilters
                  isMobile
                  onCloseMobileModal={this.toggleMobileFilter}
                  reportsSelectDoctor={reportsSelectDoctor}
                  isManager={isManager}
                  withNotaFiscal
                  notaFiscal={notaFiscal}
                  doctors={doctors}
                  isLoading={isLoading}
                  onChangeFilter={this.handleFilterChange}
                  doctor={doctor}
                  period={period}
                  year={year}
                  month={month}
                  startDate={momentStartDate.format('YYYY-MM-DD')}
                  endDate={momentEndDate.format('YYYY-MM-DD')}
                />)}
              isFeatureEnabled={isFeatureEnabled}
              isLoading={isLoading}
              emptyState={() => isAllEmpty && !isLoadingReport && getEmptyState()}
              noPadding
            >
              <ReportPage.Table title={_('label/resume', 'global')}>
                {summaryTable()}
              </ReportPage.Table>
              <ReportPage.Table title={_('payments/title')}>
                {reportTable()}
              </ReportPage.Table>
            </ReportPage>
            <AppMobileFooter />
          </React.Fragment>
        )}
      />
    );
  }

  handleFilterChange = (data: FormElementData) => {
    if (data.name === 'month') {
      let m = parseInt(get(data, 'value', ''), 10);
      m = m > 0 ? m : moment().month() + 1;
      m = m <= 12 ? m : moment().month() + 1;
      this.setState({ [data.name]: m }, this.handleRequest);
    } else {
      this.setState({ [data.name]: get(data, 'value', '') }, this.handleRequest);
    }
  }

  handleMomentFilterChange = (data: FormElementData) => {
    this.setState({ [data.name]: moment(get(data, 'value', '')).format('YYYY-MM-DD') }, this.handleRequest);
  }

  toggleMobileFilter = () => this.setState({ showMobileFilters: !this.state.showMobileFilters });

  handleRefreshClick = () => {
    this.handleRequest();
  };

  handleRequest = () => {
    const {
      doctor, period, month, year, notaFiscal,
      startDate, endDate,
    } = this.state;

    this.props.componentStorage.set('filters', { ...this.state, showMobileFilters: false });

    const doctorFilter = doctor !== 'all' ? toNumber(doctor) : null;

    const reportPeriod = handleReportPeriods(startDate, endDate, period, month, year);

    this.props.onLoadData(
      reportPeriod.startDate,
      reportPeriod.endDate,
      doctorFilter, notaFiscal,
    );
  }

  handleDownloadReport = () => {
    const {
      doctor, period, month, year, notaFiscal,
      startDate, endDate,
    } = this.state;

    this.props.componentStorage.set('filters', { ...this.state, showMobileFilters: false });

    const doctorFilter = doctor !== 'all' ? toNumber(doctor) : null;

    const reportPeriod = handleReportPeriods(startDate, endDate, period, month, year);


    this.props.onDownloadReport(
      reportPeriod.startDate,
      reportPeriod.endDate,
      doctorFilter, notaFiscal,
    );
  }


  handlePaymentItemClick = ({ id, patientId }: Object) => this.setState({
    showPaymentActions: true,
    paymentActionId: id,
    paymentActionPatientId: patientId,
  })

  handlePaymentActionClick = (item: Item) => {
    const { onUpdatePayment } = this.props;
    const { paymentActionId, paymentActionPatientId } = this.state;

    const statusActions = ['verified', 'unverified', 'on_debt'];

    if (!isNaN(paymentActionId) && !isNaN(paymentActionPatientId) && statusActions.indexOf(item.value) !== -1) {
      onUpdatePayment(paymentActionPatientId, paymentActionId, {
        status: item.value,
      });
    } else if (item.value === 'see_pacient') {
      this.props.onViewPatient(paymentActionPatientId);
    }
    this.handlePaymentActionOnClose();
  };

  handlePaymentActionOnClose = () => this.setState({
    showPaymentActions: false,
    paymentActionId: 0,
    paymentActionPatientId: 0,
  });
}


export default withTranslation()(withStorage('reportsFiltersV3', 'reportsPayments')(ReportsPayments));
