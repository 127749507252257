// @flow
// $FlowFixMe
import React, { SyntheticKeyboardEvent } from 'react';
import { map, omit, isFunction, isEqual } from 'lodash';
import CurrencyInput from 'react-currency-input';
import type { Node } from 'react';
import { wrapValue, isPtBR } from '@helpers/helpers';
import { FormInput } from '../../blocks';
import './Input.scss';

type Props = {
  name?: string, // to-do change name to required
  autofocus?: boolean,
  mobileAutofocus?: boolean,
  autoComplete?: string,
  className?: string,
  disabled?: boolean,
  error?: string,
  hint?: string,
  id?: string,
  inputRenderer?: Array<{
    // reserved
    innerCssClasses?: string,
    node: Node,
    // classic input props
    className?: string,
    name?: string,
    id?: string,
  }>,
  required?: boolean,
  label?: string,
  labelClassName?: string,
  optional?: boolean,
  placeholder?: string,
  type?: string,
  value?: any,
  options?: Array<any>,
  withoutBorder?: boolean,
  readOnly?: boolean,
  onBlur?: Function,
  onChange?: Function,
  onCheck?: Function,
  onClick?: Function,
  onKeyUp?: Function,
  unit?: string,
  defaultValue?: string,
  forceDisabled?: boolean,
  uncontrolled?: boolean,
};

type HTMLInputElement = Object;
export default class Input extends React.Component<Props> {
  componentDidMount() {
    if (this.props.autofocus && this.input && this.input) {
      this.input.focus();
    }
  }

  shouldComponentUpdate(nextProps: Props) {
    return !isEqual(this.props, nextProps);
  }

  render() {
    const {
      value, id, label,
      className, labelClassName, optional,
      placeholder, disabled, type,
      error, required, hint, forceDisabled,
      name, inputRenderer, withoutBorder,
      autoComplete, readOnly, unit, defaultValue,
      uncontrolled,
    } = this.props;

    const autofocus = this.props.autofocus && !window.isCordovaApp ? true : this.props.mobileAutofocus;

    const optionlCoreInputParams = {};
    if (id) optionlCoreInputParams.id = id;
    if (name) optionlCoreInputParams.name = name;
    if (placeholder) optionlCoreInputParams.placeholder = placeholder;
    if (autoComplete) optionlCoreInputParams.autoComplete = autoComplete;

    let inputClasses = '';
    if (value) inputClasses += ' hasContent';
    if (withoutBorder) inputClasses += ' withoutBorder';

    const inputElement = !inputRenderer ? (
      <FormInput
        label={label}
        required={required}
        onLabelClick={this.handleLabelClick}
        error={error}
        hint={hint}
        optional={optional}
        className={className}
        labelClassName={labelClassName}
        unit={unit}
      >
        {type === 'currency' &&
        <CurrencyInput
          allowEmpty
          prefix={isPtBR(window.i18n.language) ? 'R$ ' : ''}
          inputType="tel"
          // decimalSeparator=","
          // thousandSeparator="."
          {...wrapValue(defaultValue, value, uncontrolled)}
          onBlur={this.onBlur.bind(this)}
          onClick={this.handleInputClick}
          onKeyUp={this.onKeyUp.bind(this)}
          onChangeEvent={this.handleOnChangeCurrency}
          disabled={disabled || forceDisabled}
          readOnly={readOnly}
          autoFocus={autofocus}
        />
        }
        {type !== 'currency' &&
        <input
          {...optionlCoreInputParams}
          ref={input => { this.input = input; }}
          className={inputClasses}
          type={type || 'text'}
          {...wrapValue(defaultValue, value, uncontrolled)}
          onBlur={this.onBlur.bind(this)}
          onClick={this.handleInputClick}
          onChange={this.onChange.bind(this)}
          onKeyUp={this.onKeyUp.bind(this)}
          disabled={disabled || forceDisabled}
          readOnly={readOnly}
          autoFocus={autofocus}
          autoCorrect="false"
          autoComplete="false"
          autoCapitalize="false"
          spellCheck={false}
        />
        }
      </FormInput>
    ) : (
      <FormInput
        label={label}
        required={required}
        onLabelClick={this.handleLabelClick}
        error={error}
        hint={hint}
        optional={optional}
        className={className}
        labelClassName={labelClassName}
        unit={unit}
      >
        {map(inputRenderer, (inputEl: any, key: any) => (
          <div className={inputEl.innerCssClasses} key={key}>
            {type === 'currency' &&
              <CurrencyInput
                allowEmpty
                prefix={isPtBR(window.i18n.language) ? 'R$ ' : ''}
                // decimalSeparator=","
                // thousandSeparator="."
                inputType="tel"
                {...wrapValue(defaultValue, value, uncontrolled)}
                onBlur={this.onBlur.bind(this)}
                onClick={this.handleInputClick}
                onKeyUp={this.onKeyUp.bind(this)}
                onChangeEvent={this.handleOnChangeCurrency}
                disabled={disabled || forceDisabled}
                readOnly={readOnly}
                autoFocus={autofocus}
              />
              }
            {type !== 'currency' &&
            <input
              {...omit(inputEl, ['labelElement', 'innerCssClasses', 'node'])}
              ref={input => { this.input = input; }}
              type={type || 'text'}
              onBlur={this.onBlur.bind(this)}
              onChange={this.onChange.bind(this)}
              onKeyUp={this.onKeyUp.bind(this)}
              onClick={this.handleInputClick}
              readOnly={readOnly}
              disabled={disabled || forceDisabled}
              autoFocus={autofocus}
              autoCorrect="false"
              autoComplete="false"
              autoCapitalize="false"
              spellCheck={false}
            />
            }
            {inputEl.node}
          </div>
        ))}
      </FormInput>
    );

    return inputElement;
  }

  input: ?HTMLInputElement;

  handleLabelClick = () => {
    if (this.input) {
      this.input.focus();
    }
  };

  handleInputClick = (e: Object) => {
    if (this.props.onClick) {
      this.props.onClick(e);
    }
  };

  onChange = (event: SyntheticKeyboardEvent) => {
    const { name, onChange, onCheck } = this.props;
    const { value } = event.target;
    const data = { name, value };

    if (onChange) {
      onChange(data);
    } else if (onCheck) {
      onCheck(data);
    }
  };

  handleOnChangeCurrency = (event: SyntheticKeyboardEvent, maskedvalue: String, floatvalue: Number) => {
    const { name, onChange } = this.props;
    const data = { name, value: floatvalue };
    if (isFunction(onChange)) {
      onChange(data);
    }
  };

  onBlur = (event: SyntheticKeyboardEvent) => {
    const { name, onBlur } = this.props;
    const { value } = event.target;
    const data = { name, value };
    if (onBlur) {
      onBlur(data);
    }
  };

  onKeyUp = (event: SyntheticKeyboardEvent) => {
    const { name, onKeyUp } = this.props;
    const { value } = event.target;
    if (onKeyUp) {
      onKeyUp({ name, value });
    }
  };
}
