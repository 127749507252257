// @flow
import React from 'react';
import { connect } from 'react-redux';
import { get } from 'lodash';
import { SubscriptionComplete as SubscriptionCompleteView } from '@views';

type Props = {
  paymentType: string,
  paymentMethod: string,
};


class SubscriptionComplete extends React.Component<Props> {
  componentWillMount() {
  }

  render() {
    const { paymentType, paymentMethod } = this.props;
    return (
      <SubscriptionCompleteView paymentType={paymentType} paymentMethod={paymentMethod} />
    );
  }
}


const mapStateToProps = (state) => ({
  paymentType: get(state, ['context', 'subscription', 'paymentType'], ''),
  paymentMethod: get(state, ['context', 'subscription', 'subscriptionData', 'paymentMethod']),
});

export default connect<any, any, any, any, any, any>(
  mapStateToProps,
)(SubscriptionComplete);

