// @flow
import React from 'react';
import { find, isEmpty } from 'lodash';
import { Button, Icon, Loading } from '@elements';
import type { PlanType, UserAvailablePlans } from '@types';
import { withTranslation } from 'react-i18next';
import { createNsTranslator } from '@helpers/helpers';
import Slider from 'react-slick';
import PlanItem from './PlanItem';
import { SubscriptionHeader, PlatformSwitch } from '@blocks';
import './Pricing.scss';

type Props = {
  onSelectPlan: Function,
  availablePlans: UserAvailablePlans,
  t: Function,
  availablePlansLoading: boolean,
  isSelectingPlan: boolean,
};


class Pricing extends React.Component<Props> {
  render() {
    const { availablePlans, availablePlansLoading, isSelectingPlan } = this.props;
    const _ = createNsTranslator('subscription', this.props.t);

    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: true,
    };


    const planBasic = find(availablePlans, it => String(it.plan).startsWith('basic'));
    const planComplete = find(availablePlans, it => String(it.plan).startsWith('complete'));
    const planConcierge = find(availablePlans, it => String(it.plan).startsWith('concierge'));
    // /pagamento/metodo

    return (
      <PlatformSwitch
        inject
        desktop={() => (
          (
            <div id="pricingPage" className="appContent plansPage settingsPage">
              <section className="mainSection">
                <div className="centerContent">
                  <h1>{_('action/select_a_plan')}</h1>
                  <div className="pricingOptions">
                    {availablePlansLoading && <Loading />}
                    <div className="princing-row">
                      {!availablePlansLoading && (
                        <React.Fragment>
                          {!isEmpty(planBasic) && (
                            <PlanItem
                              isSelectingPlan={isSelectingPlan}
                              plan={planBasic}
                              tmpType="basic"
                              handleSelectPlan={this.handleSelectPlan}
                              isLoading={availablePlansLoading}
                              _={_}
                            />
                          )}
                          {!isEmpty(planComplete) && (
                            <PlanItem
                              isSelectingPlan={isSelectingPlan}
                              plan={planComplete}
                              tmpType="complete"
                              handleSelectPlan={this.handleSelectPlan}
                              isLoading={availablePlansLoading}
                              _={_}
                            />
                          )}
                          {!isEmpty(planConcierge) && (
                            <PlanItem
                              isSelectingPlan={isSelectingPlan}
                              plan={planConcierge}
                              tmpType="concierge"
                              handleSelectPlan={this.handleSelectPlan}
                              isLoading={availablePlansLoading}
                              _={_}
                            />
                          )}
                        </React.Fragment>
                      )}
                    </div>
                    {!availablePlansLoading && (
                      <div className="princinf-footer-plan">
                        <div className="pricingPack clinicPackCta">
                          <Icon iconName="color-clinic" className="icon" />
                          <h2>{_('label/quest_have_clinic')}</h2>
                          {/* <Button text="Ver Plano" /> */}
                          <Button text={_('action/contact')} onClick={this.handleShowIntercom} />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </section>
            </div>
          )
        )}
        mobile={() => (
          (
            <div id="pricingPage" className="appContent plansPage settingsPage mobilePricingPageView">
              <SubscriptionHeader backTo="/beneficios"/>
              <section className="mainSection">
                <div className="centerContent">
                  <h1>{_('action/select_a_plan')}</h1>
                  {availablePlansLoading && <Loading className="localloadingpage"/>}
                  <div className="pricingOptions">
                    <div className="princing-row">
                      {!availablePlansLoading && (
                        <Slider {...settings}>
                          {!isEmpty(planBasic) && (
                            <div className="slideItem">
                              <PlanItem
                                isSelectingPlan={isSelectingPlan}
                                plan={planBasic}
                                tmpType="basic"
                                handleSelectPlan={this.handleSelectPlan}
                                isLoading={availablePlansLoading}
                                _={_}
                              />
                            </div>
                          )}
                          {!isEmpty(planComplete) && (
                            <div className="slideItem">
                              <PlanItem
                                isSelectingPlan={isSelectingPlan}
                                plan={planComplete}
                                tmpType="complete"
                                handleSelectPlan={this.handleSelectPlan}
                                isLoading={availablePlansLoading}
                                _={_}
                              />
                            </div>
                          )}
                          {!isEmpty(planConcierge) && (
                            <div className="slideItem">
                              <PlanItem
                                isSelectingPlan={isSelectingPlan}
                                plan={planConcierge}
                                tmpType="concierge"
                                handleSelectPlan={this.handleSelectPlan}
                                isLoading={availablePlansLoading}
                                _={_}
                              />
                            </div>
                          )}
                          <div className="slideItem">
                            <div className="princinf-footer-plan">
                              <div className="pricingPack clinicPackCta">
                                <Icon iconName="color-clinic" className="icon" />
                                <h2>{_('label/quest_have_clinic')}</h2>
                                {/* <Button text="Ver Plano" /> */}
                                <Button text={_('action/contact')} onClick={this.handleShowIntercom} />
                              </div>
                            </div>
                          </div>
                        </Slider>
                      )}
                    </div>
                  </div>
                </div>
              </section>
            </div>
          )
        )}
      />
    );
  }

  handleSelectPlan = (plan: PlanType, value: number) => () => {
    this.props.onSelectPlan(plan, value);
  };

  handleShowIntercom = () => {
    if (window.Intercom) {
      window.Intercom('show');
    }
  };
}

export default withTranslation()(Pricing);
