// @flow
/**
 * Formatters when executed they receiver 3 parameters
 * (value: string, locale: string, options: Object)
 */
import React from 'react';
import moment from 'moment';
import { get, find, map, toString } from 'lodash';
import { isEqualValue, isPtBR, translateConstant } from '@helpers/helpers';
import { patientMaritalStatuses, paymentMethodsMap } from '@constants';

/**
 * Quick snippet to Format date using moment
 * @param {*} format
 * @param {*} fallback
 * @param {*} date
 */
const dateFormat = (format: string, fallback: string, date: Object) => (date.isValid() ? date.format(format) : fallback);

/**
 * Remove chars in list
 * @param {*} str
 * @param {*} chars
 */
const clean = (str: string, chars: Array<string> = ['\\', '.', '-', '/']) =>
  String(str).replace(new RegExp(`[${chars.map(i => `\\${i}`).join()}]`, 'g'), '');

/**
 * Format date to locale
 * @param {*} value
 * @param {*} locale
 */
export const date = (value: string, locale: string) => {
  switch (locale) {
    // Fallback Date Fomat
    default: return dateFormat('DD/MM/YYYY', 'Data Inválida', moment(value, 'YYYY-MM-DD'));
  }
};

/**
 * Format phone number
 * @param {string} value
 */
export const phone = (value: string, lang: string) => {
  if (!isPtBR(lang)) {
    return value;
  }
  const cleanChars = ['\\', '.', '-', '/', '(', ')'];
  if (value.length <= 10) {
    return clean(value, cleanChars).replace(/(\d{2})(\d{4})(\d{4})/g, '($1) $2-$3'); // (99) 9999-9999
  }
  return clean(value, cleanChars).replace(/(\d{2})(\d{1})(\d{4})(\d{4})/g, '($1) $2 $3-$4'); // (99) 9 9999-999
};
/**
 * Format CPF
 * @param {string} value
 */
export const cpf = (value: string, lang: string) => (isPtBR(lang) ? clean(value).replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, '$1.$2.$3-$4') : value);
/**
 * Format CNPJ
 * @param {string} value
 */
export const cnpj = (value: string, lang: string) =>
  (isPtBR(lang) ? clean(value)
    .replace(/\D/g, '')
    .replace(/^(\d{2})(\d)/, '$1.$2')
    .replace(/^(\d{2})\.(\d{3})(\d)/, '$1.$2.$3')
    .replace(/\.(\d{3})(\d)/, '.$1/$2')
    .replace(/(\d{4})(\d)/, '$1-$2') : value);
/**
 * Format CEP
 * @param {string} value
 */
export const cep = (value: string, lang: string) => (isPtBR(lang) ? clean(value).replace(/(\d{5})(\d{3})/g, '$1-$2') : value);

/**
 * Format to locale Currency
 * @param {string} locale
 * @param {object} options
 */
export const currency = (value: string, locale: string, options: Object) => {
  const prefix = get(options, 'prefix', true);
  const allowColor = get(options, 'allowColor', false);
  const coin = isPtBR(locale) ? 'R$' : '$';
  switch (locale) {
    // Fallback currency Fomat
    default:
      const isNegative = parseFloat(value) < 0;
      const cleanValue = isNegative ? String(value).replace('-', '') : value;
      let real = parseFloat(cleanValue).toFixed(2).split('.');
      real[0] = real[0].split(/(?=(?:...)*$)/).join('.');
      real = real.join(',');
      const formattedValue = `${prefix ? `${coin} ` : ''}${isNegative ? '-' : ''}${real}`;
      if (allowColor === true) {
        const colorClass = isNegative ? 'formattedTextNegative' : 'formattedTextPositive';
        return <span className={colorClass}>{formattedValue}</span>;
      }
      return formattedValue;
  }
};
/**
 * Keep only chars
 * @param {string} value
 */
export const chars = (value: string) => value.replace(/[^A-Za-z\s]+/g, '');
/**
 * Keep only numbers
 * @param {string} value
 */
export const numbers = (value: string) => value.replace(/[^0-9,]/g, '');

/**
 * Format number
 * @param {string} value
 */
export const number = (value: string) => String(value).replace(/(\d)(?=(?:\d{3})+(?:\.|$))|(\.\d\d?)\d*$/g, (m, s1, s2) => s2 || (s1 + '.'));

/**
 *
 * @param {*} value
 */
export const paymentMethod = (value: string, locale: string, opt: Object) => opt._(get(paymentMethodsMap, [value], value));

export const maritalStatus = (value: string) => {
  const translatedPatientMaritalStatuses = translateConstant(patientMaritalStatuses);

  const option = find(translatedPatientMaritalStatuses, item => isEqualValue(item.value, value));
  return get(option, 'text', '');
};

export const breaklines = (value: string) => {
  const sanitizedValue = toString(value).replace(/<br\s*[/]?>/gi, '\n').replace(/<(?:.|\n)*?>/gm, '');
  const lines = sanitizedValue.split('\n');
  return map<any, any>(lines, (line: string, key: number) => <span key={key}>{line}<br /></span>);
  // return map(sanitizedValue, (char: string) => (char === '\n' ? <br /> : char));
};

export const extendedAge = (value: string) => {
  const _ = window.i18n.t;

  const patientDateParts = [];

  const dateDiff = moment.duration(moment().diff(moment(value, 'YYYY-MM-DD')));
  const patientYears = dateDiff.years();
  const patientMonths = dateDiff.months();
  const patientDays = dateDiff.days();

  if (patientYears > 0) {
    patientDateParts.push(`${patientYears} ` + (patientYears > 1 ? _('label/years') : _('label/year')));
  }

  if (patientMonths > 0 && patientYears <= 15) {
    patientDateParts.push(`${patientMonths} ` + (patientMonths > 1 ? _('label/months') : _('label/month')));
  }

  if (patientDays > 0 && patientYears < 1) {
    patientDateParts.push(`${patientDays} ` + (patientDays > 1 ? _('label/days') : _('label/low_day')));
  }

  return patientDateParts.length > 1 ? patientDateParts.join(' ' + _('label/date_and') + ' ') : patientDateParts[0];
};

export const weekday = (value: number, lang: string, opt: Object) => {
  switch (value) {
    case 1: return opt._('constants:weekday/monday');
    case 2: return opt._('constants:weekday/tuesday');
    case 3: return opt._('constants:weekday/wednesday');
    case 4: return opt._('constants:weekday/thursday');
    case 5: return opt._('constants:weekday/friday');
    case 6: return opt._('constants:weekday/saturday');
    case 7: return opt._('constants:weekday/sunday');

    default: return '?';
  }
};

