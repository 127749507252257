// @flow
import React from 'react';
import { Button, Loading } from '@elements';
import type { CordovaPurchaseProduct } from '@types';
import { get } from 'lodash';

type Props = {
    handleSelectPlan: Function,
    plan: CordovaPurchaseProduct,
    tmpType: 'basic' | 'complete' | 'concierge'; // temporary
    isLoading: boolean,
    isSelectingPlan: boolean,
    _: Function,
};

const PlanItem = ({
  handleSelectPlan, plan, tmpType, _, isLoading, isSelectingPlan,
}:Props) => (
  <div className={get(plan, 'recommended') ? 'pricingPack popular' : 'pricingPack'}>
    {!isLoading && (
      <React.Fragment>
        {get(plan, 'recommended') && <span className="popularTag">Mais Popular</span>}
        <h2>{plan.title}</h2>
        <span className="price">
          <span className="value">{plan.price}</span>
          <span className="suffix">/{_('label/month_price')}</span>
        </span>
      </React.Fragment>
    )}
    {isLoading && <Loading className="noBorder" />}
    {!isLoading && (
      <React.Fragment>
        {tmpType === 'basic' && (
          <ul className="packBenefits">
            <li>Agenda e Prontuário</li>
            <li>1 Profissional de Saúde</li>
            <li>Suporte via Chat e Email</li>
          </ul>
        )}
        {tmpType === 'complete' && (
          <ul className="packBenefits">
            <li>Agenda e Prontuário</li>
            <li>1 Profissional de Saúde</li>
            <li className="highlight">Receituário Eletrônico</li>
            <li className="highlight">Anamnese e outros Formulários</li>
            <li className="highlight">Controle Financeiro</li>
            <li className="highlight">20Gb de Armazenamento</li>
            <li>Suporte via Chat e Email</li>
          </ul>
        )}
        {tmpType === 'concierge' && (
          <ul className="packBenefits">
            <li>1 Profissional de Saúde</li>
            <li className="highlight">Personalização de Formulários</li>
            <li className="highlight">Controle de Múltiplas Clínicas</li>
            <li className="highlight">100Gb de Armazenamento</li>
            <li>Suporte Prioritário via chat, email e WhatsApp</li>
          </ul>
        )}
      </React.Fragment>
    )}
    {!isLoading && (
      <Button
        className="primary"
        text={_('action/choose_plan')}
        loadingMessage={_('label/select_plan_loading')}
        isLoading={isSelectingPlan}
        onClick={handleSelectPlan(get(plan, 'id'))}
      />
    )}
  </div>
);

export default PlanItem;
