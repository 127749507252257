export default {
  'label/empty/title': 'Nenhum indicador encontrado',
  'label/title': 'Indicadores',
  'alert/beta_feature_disclamer': 'Esta é uma funcionalidade em modo de teste! Os dados salvos aqui são temporários e serão pedidos eventualmente.',
  'button/addData': 'Adicionar dados',
  'button/edit': 'Editar',
  'button/editIndicator': 'Editar Indicador',
  'button/addIndicator': 'Adicionar Indicador',
  'label/age': 'Idade',
  'label/height': 'Altura',
  'label/heightCm': 'Altura (cm)',
  'label/weight': 'Peso',
  'label/weightKg': 'Peso (kg)',
  'label/date': 'Data',
  'label/imc': 'IMC',
  'label/today': 'Hoje',
  'label/0-2': '0 - 2 anos',
  'label/2-5': '2 - 5 anos',
  'label/5-19': '5 - 19 anos',
  'label/all': 'Todos',
  'text/cm': 'cm',
  'text/kg': 'kg',
  'text/kgm2': 'kg/m2',
  'label/ageMonths': 'Idade (meses)',
  'label/patient': 'Paciente',
  'title/graphic': 'Curva de Crescimento',
  'title/boy': 'Menino',
  'title/girl': 'Menina',
  'title/0-2': '0 a 2 anos',
  'title/2-5': '2 a 5 anos',
  'title/5-19': '5 a 19 anos',
  'toast/measureCreated': 'Registro cadastrado com sucesso',
  'toast/measureUpdated': 'Registro atualizado com sucesso',
  'toast/measureDeleted': 'Registro deletado com sucesso',
};
