// @flow
/**
 * ChainedValidator classes and helpers
 * To add a new rule add a new method to ValidatorRules class on rules folder, add a element with key as same name to validationRules table
 * and implement the checker in ./testers.js on rules folder
 * Note: When a tester return false the test is failed.
 */
import ChainedValidator from './core/ChainedValidator';

export { validationRules } from './rules/ValidatorRules';


/**
 * We export this rules names to use in removeRule method
 * Ex: ...field('name').removeRule(validationRules.present);
 */

/**
 * Instantiate a new field validator for formData
 */
export const createValidator = () => new ChainedValidator();
export const Validator = ChainedValidator;
