// @flow
import React from 'react';
import { Link } from 'react-router-dom';
import { get, map, isEmpty, isEqual } from 'lodash';
import { router } from '@router';
import { redirectToDashboard, redirectToDoutoreAI } from '@context/user';
import { Dropdown, Icon } from '@elements';
// import { shortenName } from '@helpers/helpers';
import type { User, ClinicsState, BundledPermissions } from '@types';
import { createNsTranslator, isBetaApp } from '@helpers/helpers';
import { withTranslation } from 'react-i18next';
import './AppHeader.scss';

type Props = {
  user: ?User,
  accessToken: string,
  clinics: ?ClinicsState,
  onLogout: Function,
  onUpdateApp: Function,
  onChangeClinic: Function,
  isIntercomEnabled: boolean,
  trial: boolean,
  trialDays: number,
  t: Function,
  permissions: BundledPermissions,
};

const helpList = (intercom: boolean, _: Function, dashboard: boolean, userId: number, clinicId: number, isDoutoreAIEnabled: boolean) => [
  { text: _('label/user_guide'), externalLinkTo: 'https://suporte.doutore.com' },
  ...(dashboard ? [{ text: _('label/dashboard'), action: 'navigateToDashboard', to: `/users/${userId}/clinics/${clinicId}/appointments` }] : []),
  ...(isDoutoreAIEnabled ? [{ text: _('action/ai'), action: 'navigateToDoutoreAI' }] : []),
  ...(intercom ? [{ text: _('label/online_support'), action: 'onlineSupport' }] : []),
  { text: _('action/reload'), action: 'update' },
];

class AppHeader extends React.Component<Props> {
  shouldComponentUpdate(nextProps: Props) {
    return isEqual(this.props, nextProps);
  }

  render() {
    const {
      user, clinics, isIntercomEnabled,
      trial, trialDays, permissions,
    } = this.props;

    const _ = createNsTranslator('layout', this.props.t);

    // Guard against potential null values
    if (!user || !clinics) return false;

    const advancedData = get(user, 'advancedData', {});
    const isDoutoreAIEnabled = get(advancedData, ['planInclude', 'doutoreAi'], false);

    const clinicNames = [];
    let activeClinicName;
    if (!clinics.isLoading) {
      const multiClinics = get(clinics, 'data', {});

      if (!isEmpty(multiClinics)) {
        activeClinicName = get(clinics, ['activeClinic', 'name'], '');
        clinicNames.push({
          break: true, text: 'Mudar de Clínica', className: 'headingSeparator',
        });
        map(multiClinics, (clinic) => {
          clinicNames.push({
            text: clinic.name,
            clinicId: get(clinic, 'id'),
            action: 'selectClinic',
          });
        });
      }
    }

    let settingLinkTo = !permissions.isDependent ? '/ajustes/meu-plano' : '/ajustes/meu-perfil';

    switch (advancedData.subscriptionStatus) {
      case 'trialing': settingLinkTo = '/ajustes/meu-perfil'; break;
      case 'end_of_trial': settingLinkTo = '/ajustes/meu-perfil'; break;
      case 'canceled': settingLinkTo = '/ajustes/meu-perfil'; break;
      case 'banned': settingLinkTo = '/ajustes/meu-perfil'; break;
      default: break;
    }

    const menuList = [
      { text: _('label/settings'), linkTo: settingLinkTo },
      ...clinicNames,
      { break: true, className: 'headingSeparator', text: '  ' },
      { text: _('action/logout'), action: 'logout' },
    ];

    const isBeta = isBetaApp();

    return (
      <div className="appHeader">
        <div className="left">
          <Link to='/' className="logo" />
          <nav>
            <Link to={router.getRoutePath('agenda')}>{_('label/agenda')}</Link>
            <Link to={router.getRoutePath('patientsList')}>{_('label/patients')}</Link>
            <Link to={router.getRoutePath('reportsDebt')}>{_('label/reports')}</Link>
          </nav>
        </div>
        <div className="right">
          <nav>
            {trial && <Link to='/assinar' className="header-notification" ><Icon iconName="time" fill="#fff" />&nbsp;{trialDays} {_('label/remaining_trial_days')}</Link>}
            {isBeta && <a href="#" className="header-notification" >{_('label/beta_app')}</a>}
            <Dropdown
                // forceDesktop
              mobileLabel={_('label/help')}
              className="headerHelpDropdown"
              icon="help"
              items={helpList(isIntercomEnabled, _, permissions.dashboard, get(user, 'id'), get(clinics, ['activeClinic', 'id']), isDoutoreAIEnabled)}
              onSelectItem={this.handleDropdownItemClick}
            />
            <Link to={settingLinkTo}>{_('label/settings')}</Link>
            <Dropdown
                // forceDesktop
              className="headerMenuDropdown"
              // placeholder={shortenName(user.nameWithTitle)}
              placeholder={user.nameWithTitle || ''}
              secondaryText={activeClinicName}
              items={menuList}
              onSelectItem={this.handleDropdownItemClick}
            />
          </nav>
        </div>
      </div>
    );
  }

  handleDropdownItemClick = (item: any) => {
    const { onLogout, onChangeClinic, accessToken } = this.props;

    switch (item.action) {
      case 'logout':
        onLogout();
        if (window.Intercom) {
          window.Intercom('shutdown');
        }
        break;
      case 'selectClinic':
        onChangeClinic(item.clinicId);
        break;
      case 'onlineSupport':
        if (window.Intercom) {
          window.Intercom('show');
        }
        break;
      case 'update':
        this.props.onUpdateApp();
        break;
      case 'navigateToDashboard':
        redirectToDashboard(item.to, accessToken);
        break;
      case 'navigateToDoutoreAI':
        redirectToDoutoreAI(accessToken);
        break;
      default:
        break;
    }
  }
}

export default withTranslation()(AppHeader);
