// @flow
import React from 'react';
import { PatientReceipts as PatientReceiptsView } from '../components/views';

export default class PatientReceipts extends React.Component<void> {
  render() {
    return (
      <PatientReceiptsView />
    );
  }
}
