// @flow
import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { get } from 'lodash';
import { ReceiptFormModal } from '../../components/views';
import { receiptActions } from '../../redux/modules/context/receipts';
import type { Dispatch, ReceiptInput, Receipt } from '../../types';
import { withConfirmOnClose } from '../../hocs';

type Props = {
  dispatch: Function,
  patientId: number,
  receiptId: number,
  receipt: Receipt,
  setConfirmation: Function,
  closeModal: Function,
};

class ReceiptFormModalContainer extends React.Component<Props> {
  render() {
    const { receipt, setConfirmation } = this.props;

    return (
      <ReceiptFormModal
        formData={receipt}
        onSave={this.handleSave}
        onDelete={this.handleDelete}
        onClose={this.closeModal}
        onSetConfirmOnClose={setConfirmation}
      />
    );
  }

  closeModal = this.props.closeModal;

  handleSave = (data: ReceiptInput) => {
    const { dispatch, patientId, receiptId } = this.props;
    dispatch(receiptActions.updateReceipt(patientId, receiptId, data));
    this.closeModal(true);
  };

  handleDelete = () => {
    const { dispatch, patientId, receiptId } = this.props;
    dispatch(receiptActions.deleteReceipt(patientId, receiptId));
    this.closeModal(true);
  }
}

const getReceipt = state => {
  const id = get(state, ['context', 'modals', 'data', 'receiptId']);
  return get(state, ['context', 'receipts', 'data', id], {});
};

const mapStateToProps = (state) => ({
  patientId: get(state, ['context', 'modals', 'data', 'patientId']),
  receiptId: get(state, ['context', 'modals', 'data', 'receiptId']),
  receipt: getReceipt(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({ dispatch });

export default compose(
  connect<any, any, any, any, any, any>(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withConfirmOnClose(),
)(ReceiptFormModalContainer);

