// @flow
import React from 'react';
import { connect } from 'react-redux';
import { get } from 'lodash';
import { TagsFormModal } from '../../components/views';
import { actions } from '../../redux/modules/context/modals';
import { patientActions } from '../../redux/modules/context/patients';
import type { Dispatch, TagTemplates, Patient } from '../../types';

type Props = {
  dispatch: Function,
  tagTemplates: TagTemplates,
  patient: Patient,
  isLoading: boolean;
};

class TagsFormModalContainer extends React.Component<Props> {
  render() {
    const { tagTemplates, patient, isLoading } = this.props;
    const patientTags = get(patient, 'tags', []);

    return (
      <TagsFormModal
        isLoading={isLoading}
        tagTemplates={tagTemplates}
        patientTags={patientTags}
        onClose={this.closeModal}
        onSelectTag={this.handleSelectTag}
        onRemoveTag={this.handleRemoveTag}
        onAddNewtag={this.handleAddNewtag}
      />
    );
  }

  closeModal = () => {
    this.props.dispatch(actions.closeActiveModal());
  };

  handleSelectTag = (tag: Object) => {
    const { patient } = this.props;
    this.props.dispatch(patientActions.addPatientTag(tag.id, patient.id));
  };

  handleRemoveTag = (tag: Object) => {
    const { patient } = this.props;
    this.props.dispatch(patientActions.removePatientTag(tag.id, patient.id));
  };

  handleAddNewtag = (tagLabel: string) => {
    const { patient } = this.props;
    this.props.dispatch(patientActions.createAndConnectPatientTag(tagLabel, patient.id));
  };
}

const mapStateToProps = (state) => ({
  tagTemplates: get(state.context, ['modals', 'data', 'tagTemplates']),
  patient: get(state.context, ['modals', 'data', 'patient']),
  isLoading: get(state, ['context', 'patients', 'tagsLoading', get(state.context, ['modals', 'data', 'patient', 'id'], 0)], false),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({ dispatch });

export default connect<any, any, any, any, any, any>(
  mapStateToProps,
  mapDispatchToProps,
)(TagsFormModalContainer);
