// @flow
import Immutable from 'seamless-immutable';
import axios from 'axios';
import moment from 'moment';
import { get } from 'lodash';
import ClinicService from '../services/ClinicService';
import { userActions } from './modules/context/user';
import { clinicActions } from './modules/context/clinics';
import { Log } from '../helpers';
import type { State } from '../types';


export default (initialState: State = {}): Promise<any> => new Promise(resolve => {
  window.idb.fetchInitialState().then(async (storedState) => {
    try {
      if (storedState) {
        const token = get(storedState, ['auth', 'token'], null);
        const clinicId = get(storedState, ['context', 'clinics', 'activeClinic', 'id'], null);
        const storedStateVersion = get(storedState, ['appVersion', 'version'], '');
        const isStoredStateValid = !storedStateVersion || (storedStateVersion === window.APP_VERSION);

        // If Code version different than version stored in LC, retreive auth and user objects for signed in user
        if (!isStoredStateValid) {
          axios.defaults.headers.clinicId = clinicId;
          axios.defaults.headers['access-token'] = token;

          const getUserAction = userActions.getUser();
          const getUserResponse = await getUserAction.call();
          getUserAction.parseResponse(getUserResponse);

          const userData = getUserResponse.data.user || {};

          const getClinicsAction = clinicActions.getClinics(null, clinicId);
          const getClinicsResponse = await getClinicsAction.call();
          const userClinicsData = getClinicsAction.parseResponse(getClinicsResponse.data);


          if (userData && userClinicsData) {
            const { activeClinic, multiClinics, userWithoutClinic } = userClinicsData;

            const normalizedActiveClinic = ClinicService.normalizeActiveClinic(activeClinic);
            const normalizedMulticlinics = ClinicService.normalizeMulticlinics(multiClinics);

            const lastFetch = moment().toISOString();

            storedState = {
              ...storedState,
              appVersion: {
                version: window.APP_VERSION,
              },
              context: Immutable({
                user: {
                  ...userData,
                  lastFetch,
                },
                clinics: {
                  activeClinic: normalizedActiveClinic.activeClinic,
                  data: normalizedMulticlinics,
                  userWithoutClinic: userWithoutClinic || false,
                  doctors: {
                    data: normalizedActiveClinic.doctors,
                  },
                  secretaries: {
                    data: normalizedActiveClinic.secretaries,
                    managerIds: normalizedActiveClinic.secretaryManagerIds,
                    nonManagerIds: normalizedActiveClinic.secretaryNoManagerIds,
                    lastFetch,
                  },
                  lastFetch,
                },
              }),
              ui: Immutable({}),
            };
          }
        }

        return resolve(Immutable(storedState));
      }
    } catch (e) {
      Log.error('ERROR');
    }

    // fallthrough: return initial state
    resolve({
      ...initialState || {},
      appVersion: Immutable({}),
      context: Immutable({}),
      auth: Immutable({}),
      ui: Immutable({}),
    });
  });
});


// it safe to modify the passed initialState here
// export default function fetchInitialState (initialState: State = {}) {
//   let storedState;
//   try {
//     const localStorageState = localStorage.getItem(window.APP_LS_KEY);
//     if (localStorageState) {
//       storedState = JSON.parse(localStorageState);
//     }
//     if (storedState) {
//       const user = get(storedState, ['context', 'user'], {});
//       const storedStateVersion = get(storedState, ['appVersion', 'version'], '');
//       const isStoredStateValid = !storedStateVersion || (storedStateVersion === window.APP_VERSION);

//       // If Code version different than version stored in LC, retreive auth and user objects for signed in user
//       if (!isStoredStateValid) {
//         storedState = {
//           ...storedState,
//           appVersion: {
//             version: window.APP_VERSION,
//           },
//           context: Immutable({
//             user,
//           }),
//           ui: Immutable({}),
//         };
//       }
//       return Immutable(storedState);
//     }
//   } catch (e) {
//     Log.error('ERROR');
//   }

//   // fallthrough: return initial state
//   return {
//     ...initialState,
//     appVersion: Immutable({}),
//     context: Immutable({}),
//     auth: Immutable({}),
//     ui: Immutable({}),
//   };
// }
