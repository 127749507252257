// @flow
import React from 'react';
import ReactDOM from 'react-dom';
import JQuery from 'jquery';
import { map, get, filter, forEach, first } from 'lodash';
import { PlatformSwitch } from '@blocks';
import {
  Input, RadioGroup, Button,
  Modal, Icon, // SwipeTabs,
  SelectBox, TextArea, InputMask, AddressFields,
} from '@elements';
import type { Clinic, Doctors, PatientInput, ActionItems, PermissionTo } from '@types';
import { patientMaritalStatuses } from '@constants';
import { createValidator } from '@validator';
import { withUnForm } from '@hocs';
import { sort, alphabeticCompare, isPresent, isPresentOneOf, createNsTranslator, fSufixTwoPoints, handleSubmitEvent, replaceHtmlEntitesToText, translateConstant } from '@helpers/helpers';
import { withTranslation } from 'react-i18next';
import './PatientFormModal.scss';

type State = {
  modalMenuItems: ActionItems,
  activeMenu: string,
  errors: { [field: string]: string },
  formIsValid: boolean,
  focused: boolean,
}

type Props = {
  onClose: Function,
  doctors: Doctors,
  clinic: Clinic,
  onSetConfirmation: Function,
  initialData: PatientInput,
  formData: () => PatientInput,
  handleFormChange: Function,
  handleTouchField: Function,
  bindValidation: Function,
  formChanged: () => boolean,
  isSaving: boolean,
  isEdit: boolean,
  rehydrating: boolean,
  touchedFields: Function,
  onSave: Function,
  permissionTo: PermissionTo,
  t: Function,
  rawError: any;
  focus: string;
};

const mapDoctorsToSelect = (doctors: Doctors) => sort(
  map(doctors, (item) => ({
    text: item.nameWithTitle,
    name: item.name,
    value: item.id,
    findString: item.name,
  })),
  alphabeticCompare('name'),
);


class PatientFormModal extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      modalMenuItems: [
        { text: this.props.t('patientProfile:label/patientform/basic_data'), value: 'basic' },
        { text: this.props.t('patientProfile:label/patientform/contacts'), value: 'contacts' },
        { text: this.props.t('patientProfile:label/patientform/address'), value: 'address' },
        { text: this.props.t('patientProfile:label/patientform/insurance'), value: 'insurance' },
        { text: this.props.t('patientProfile:label/patientform/notes'), value: 'notes' },
      ],
      activeMenu: 'basic',
      errors: {},
      formIsValid: true,
      focused: false,
    };
    this.props.bindValidation(this.handleFormValidation);
  }

  componentDidMount() {
    handleSubmitEvent('#patientFormModalFormElement', this.handleSave);

    if (!this.state.focused && isPresent(this.props.focus)) {
      if (this.modalContentRef) {
        const recordsContentWrapper = document.querySelector('#patientFormModalFormElement > .modalContent > .flex-row');
        let scrollTo = document.querySelector(`input[name=${this.props.focus}]`);
        const container = document.querySelector('#patientFormModalFormElement > .modalContent');

        // Handle masked input without names
        if (!scrollTo) {
          scrollTo = document.querySelector(`.input-name-${this.props.focus}`);
          if (scrollTo) {
            scrollTo = scrollTo.querySelector('input');
          }
        }

        if (recordsContentWrapper && scrollTo && container) {
          JQuery(container).animate({
            scrollTop: JQuery(scrollTo).offset().top - JQuery(recordsContentWrapper).offset().top - 10,
          }, () => {
            if (scrollTo) {
              scrollTo.focus();
            }
          });
        }

        this.setState({ focused: true });
      }
    }
  }

  render() {
    const {
      onClose, doctors, clinic, initialData,
      handleFormChange, handleTouchField, isSaving, isEdit,
      permissionTo, rehydrating, rawError,
    } = this.props;
    const {
      activeMenu,
    } = this.state;

    const errors = {
      ...this.state.errors,
    };

    forEach(rawError, (err) => {
      errors[err.property] = first(err.errorMessages);
    });

    // console.info(errors);

    const _ = createNsTranslator('agenda', this.props.t);
    const translatedPatientMaritalStatuses = translateConstant(patientMaritalStatuses);

    const modalTitle = isEdit ? _('label/patientform/edit_patient', 'patientProfile') : _('label/patientform/new_patient', 'patientProfile');

    const customFields = {
      patientsLegalIdFieldActivated: clinic.patientsLegalIdFieldActivated || isPresent(initialData.legalId),
      patientsProfessionFieldActivated: clinic.patientsProfessionFieldActivated || isPresent(initialData.profession),
      patientsPlaceOfBirthFieldActivated: clinic.patientsPlaceOfBirthFieldActivated || isPresent(initialData.placeOfBirth),
      patientsMaritalStatusFieldActivated: clinic.patientsMaritalStatusFieldActivated || isPresent(initialData.maritalStatusCode),
      patientsExtraField1Activated: clinic.patientsExtraField1Activated || isPresent(initialData.extraField1),
      patientsExtraField2Activated: clinic.patientsExtraField2Activated || isPresent(initialData.extraField2),
      patientsExtraField3Activated: clinic.patientsExtraField3Activated || isPresent(initialData.extraField3),
      patientsNumberFieldActivated: clinic.patientsNumberFieldActivated || isPresent(initialData.number),
      patientsInstagramFieldActivated: clinic.patientsInstagramFieldActivated || isPresent(initialData.instagram),
      patientsParentsFieldsActivated: clinic.patientsParentsFieldsActivated || isPresent(initialData.father),
      patientsGuardianFieldsActivated: clinic.patientsGuardianFieldsActivated || isPresent(initialData.guardianName),
      patientsExtraContactField1Activated: clinic.patientsExtraContactField1Activated || isPresent(initialData.extraContactField1),
      patientsExtraContactField2Activated: clinic.patientsExtraContactField2Activated || isPresent(initialData.extraContactField2),
      patientsExtraContactField3Activated: clinic.patientsExtraContactField3Activated || isPresent(initialData.extraContactField3),
      patientsInsurancePlanFieldsActivated: clinic.patientsInsurancePlanFieldsActivated || isPresentOneOf(initialData, ['insuranceCompany', 'insurancePlan', 'insuranceNumber', 'insuranceExpirationDate']),
    };

    const modalMenuItems = customFields.patientsInsurancePlanFieldsActivated ? this.state.modalMenuItems : filter(this.state.modalMenuItems, item => item.value !== 'insurance');

    const formContent = (
      <div className="flex-row">
        <span className="formHeading col-12" id="scroolto-basic">{_('label/patientform/basic_data', 'patientProfile')}</span>

        <Input
          className="col-12 input-name"
          uncontrolled
          name="name"
          label={_('label/patient_form/name', null, fSufixTwoPoints)}
          onChange={handleFormChange}
          defaultValue={get(initialData, 'name', '')}
          error={get(errors, 'name', null)}
          required
          onBlur={handleTouchField}
          autoComplete="off"
          autofocus={!isEdit}
        />
        <RadioGroup
          className="col-12 input-gender"
          uncontrolled
          label={_('label/gender', 'global', fSufixTwoPoints)}
          name="gender"
          defaultType="gender"
          selectGroupName="gender"
          defaultValue={get(initialData, 'gender', '')}
          onChange={handleFormChange}
          onBlur={handleTouchField}
        />
        {customFields.patientsNumberFieldActivated &&
          <Input
            className="col-xl-4 col-lg-4 col-md-6 col-sm-12 input-number"
            uncontrolled
            name="number"
            label={_('label/patient_form/patient_code')}
            onChange={handleFormChange}
            defaultValue={get(initialData, 'number', '')}
            onBlur={handleTouchField}
          />
        }
        <Input
          className="col-xl-4 col-lg-4 col-md-6 col-sm-12 input-recommendedBy"
          uncontrolled
          label={_('label/patient_form/recommended_by', null, fSufixTwoPoints)}
          name="recommendedBy"
          onChange={handleFormChange}
          defaultValue={get(initialData, 'recommendedBy', '')}
          onBlur={handleTouchField}
        />
        <InputMask
          className="col-xl-4 col-lg-4 col-md-12 col-sm-12 input-birthdate"
          uncontrolled
          type="date"
          name="birthdate"
          label={_('label/patient_form/birthdate', null, fSufixTwoPoints)}
          defaultValue={get(initialData, 'birthdate', '')}
          onChange={handleFormChange}
          onBlur={handleTouchField}
          error={get(errors, 'birthdate', null)}
        />
        <InputMask
          className="col-xl-4 col-lg-4 col-md-6 col-sm-12 input-cpf"
          uncontrolled
          type="cpf"
          name="cpf"
          label={_('label/patient_form/cpf', null, fSufixTwoPoints)}
          defaultValue={get(initialData, 'cpf', '')}
          error={get(errors, 'cpf', null)}
          onChange={this.handleFormCpfChance}
          onBlur={handleTouchField}
        />

        {customFields.patientsLegalIdFieldActivated &&
          <Input
            className="col-xl-4 col-lg-4 col-md-6 col-sm-12 input-legalId"
            uncontrolled
            name="legalId"
            label={_('label/patient_form/legalId', null, fSufixTwoPoints)}
            onChange={handleFormChange}
            defaultValue={get(initialData, 'legalId', '')}
            onBlur={handleTouchField}
          />
        }

        {customFields.patientsProfessionFieldActivated &&
          <Input
            className="col-xl-4 col-lg-4 col-md-6 col-sm-12 input-profession"
            uncontrolled
            name="profession"
            label={_('label/patient_form/profession', null, fSufixTwoPoints)}
            onChange={handleFormChange}
            defaultValue={get(initialData, 'profession', '')}
            onBlur={handleTouchField}
          />
        }
        {customFields.patientsPlaceOfBirthFieldActivated &&
          <Input
            className="col-xl-4 col-lg-4 col-md-6 col-sm-12 input-placeOfBirth"
            uncontrolled
            name="placeOfBirth"
            label={_('label/patient_form/placeOfBirth', null, fSufixTwoPoints)}
            onChange={handleFormChange}
            defaultValue={get(initialData, 'placeOfBirth', '')}
            onBlur={handleTouchField}
          />
        }

        {customFields.patientsMaritalStatusFieldActivated && (
          <SelectBox
            className="col-xl-4 col-lg-4 col-md-12 col-sm-12 input-maritalStatusCode"
            uncontrolled
            name="maritalStatusCode"
            label={_('label/patient_form/maritalStatusCode', null, fSufixTwoPoints)}
            options={translatedPatientMaritalStatuses}
            defaultValue={get(initialData, 'maritalStatusCode', '')}
            onChange={handleFormChange}
            onBlur={handleTouchField}
          />
        )}

        {customFields.patientsExtraField1Activated &&
          <Input
            className="col-xl-4 col-lg-4 col-md-12 col-sm-12 input-extraField1"
            uncontrolled
            name="extraField1"
            label={get(clinic, 'patientsExtraField1Label', '')}
            onChange={handleFormChange}
            defaultValue={get(initialData, 'extraField1', '')}
            onBlur={handleTouchField}
          />
        }
        {customFields.patientsExtraField2Activated &&
          <Input
            className="col-xl-4 col-lg-4 col-md-12 col-sm-12 input-extraField2"
            uncontrolled
            name="extraField2"
            label={get(clinic, 'patientsExtraField2Label', '')}
            onChange={handleFormChange}
            defaultValue={get(initialData, 'extraField2', '')}
            onBlur={handleTouchField}
          />
        }
        {customFields.patientsExtraField3Activated &&
          <Input
            className="col-xl-4 col-lg-4 col-md-12 col-sm-12 input-extraField3"
            uncontrolled
            name="extraField3"
            label={get(clinic, 'patientsExtraField3Label', '')}
            onChange={handleFormChange}
            defaultValue={get(initialData, 'extraField3', '')}
            onBlur={handleTouchField}
          />
        }

        {!permissionTo.singleDoctor && permissionTo.patientFormSelectDoctor && (
          <SelectBox
            className="col-xl-6 col-lg-6 col-md-6 col-sm-12 input-doctorId"
            uncontrolled
            name="doctorId"
            label={_('label/patient_form/doctorId', null, fSufixTwoPoints)}
            placeholder={_('label/patient_form/medic', null, fSufixTwoPoints)}
            options={mapDoctorsToSelect(doctors)}
            defaultValue={get(initialData, 'doctorId', '')}
            onChange={handleFormChange}
            onBlur={handleTouchField}
          />
        )}

        <span className="formHeading ptopFix col-12" id="scroolto-contacts">{_('label/patientform/contacts', 'patientProfile')}</span>


        <InputMask
          className="col-xl-4 col-lg-4 col-md-6 col-sm-12 input-mobilePhone"
          uncontrolled
          type="phone"
          name="mobilePhone"
          label={_('label/patient_form/mobilePhone', null, fSufixTwoPoints)}
          defaultValue={get(initialData, 'mobilePhone', '')}
          onChange={handleFormChange}
          onBlur={handleTouchField}
          error={get(errors, 'mobilePhone', null)}
        />

        <InputMask
          className="col-xl-4 col-lg-4 col-md-6 col-sm-12 input-homePhone"
          uncontrolled
          type="phone"
          name="homePhone"
          label={_('label/patient_form/homePhone', null, fSufixTwoPoints)}
          defaultValue={get(initialData, 'homePhone', '')}
          onChange={handleFormChange}
          onBlur={handleTouchField}
          error={get(errors, 'homePhone', null)}
        />

        <InputMask
          className="col-xl-4 col-lg-4 col-md-6 col-sm-12 input-phone"
          uncontrolled
          type="phone"
          name="otherPhone"
          label={_('label/patient_form/otherPhone', null, fSufixTwoPoints)}
          defaultValue={get(initialData, 'otherPhone', '')}
          onChange={handleFormChange}
          onBlur={handleTouchField}
          error={get(errors, 'otherPhone', null)}
        />
        <Input
          className="col-xl-6 col-lg-6 col-md-6 col-sm-12 input-email"
          uncontrolled
          label={_('label/email', 'global', fSufixTwoPoints)}
          name="email"
          defaultValue={get(initialData, 'email', '')}
          onChange={handleFormChange}
          onBlur={handleTouchField}
          error={get(errors, 'email', null)}
        />

        {customFields.patientsInstagramFieldActivated &&
          <Input
            className="col-xl-4 col-lg-4 col-md-6 col-sm-12 input-instagram"
            uncontrolled
            name="instagram"
            label="Instagram:"
            onChange={handleFormChange}
            defaultValue={get(initialData, 'instagram', '')}
            onBlur={handleTouchField}
          />
        }

        {customFields.patientsParentsFieldsActivated &&
          <div className="row-12">
            <Input
              className="col-xl-6 col-lg-6 col-md-6 col-sm-12 input-father"
              uncontrolled
              name="father"
              label={_('label/patient_form/father', null, fSufixTwoPoints)}
              onChange={handleFormChange}
              defaultValue={get(initialData, 'father', '')}
              onBlur={handleTouchField}
            />
            <Input
              className="col-xl-6 col-lg-6 col-md-6 col-sm-12 input-mother"
              uncontrolled
              name="mother"
              label={_('label/patient_form/mother', null, fSufixTwoPoints)}
              onChange={handleFormChange}
              defaultValue={get(initialData, 'mother', '')}
              onBlur={handleTouchField}
            />
          </div>
        }
        {customFields.patientsGuardianFieldsActivated &&
          <div className="row-12">
            <Input
              className="col-xl-6 col-lg-6 col-md-6 col-sm-12 input-guardianName"
              uncontrolled
              name="guardianName"
              label={_('label/patient_form/guardianName', null, fSufixTwoPoints)}
              onChange={handleFormChange}
              defaultValue={get(initialData, 'guardianName', '')}
              onBlur={handleTouchField}
            />
            <Input
              className="col-xl-6 col-lg-6 col-md-6 col-sm-12 input-guardianRelationship"
              uncontrolled
              name="guardianRelationship"
              label={_('label/patient_form/guardianRelationship', null, fSufixTwoPoints)}
              onChange={handleFormChange}
              defaultValue={get(initialData, 'guardianRelationship', '')}
              onBlur={handleTouchField}
            />
          </div>
        }

        {customFields.patientsExtraContactField1Activated &&
          <Input
            className="col-xl-4 col-lg-4 col-md-6 col-sm-12 input-extraContactField1"
            uncontrolled
            name="extraContactField1"
            label={get(clinic, 'patientsExtraContactField1Label', '')}
            onChange={handleFormChange}
            defaultValue={get(initialData, 'extraContactField1', '')}
            onBlur={handleTouchField}
          />
        }
        {customFields.patientsExtraContactField2Activated &&
          <Input
            className="col-xl-4 col-lg-4 col-md-6 col-sm-12 input-extraContactField2"
            uncontrolled
            name="extraContactField2"
            label={get(clinic, 'patientsExtraContactField2Label', '')}
            onChange={handleFormChange}
            defaultValue={get(initialData, 'extraContactField2', '')}
            onBlur={handleTouchField}
          />
        }
        {customFields.patientsExtraContactField3Activated &&
          <Input
            className="col-xl-4 col-lg-4 col-md-6 col-sm-12 input-extraContactField3"
            uncontrolled
            name="extraContactField3"
            label={get(clinic, 'patientsExtraContactField3Label', '')}
            onChange={handleFormChange}
            defaultValue={get(initialData, 'extraContactField3', '')}
            onBlur={handleTouchField}
          />
        }

        <span className="formHeading ptopFix col-12" id="scroolto-address">{_('label/patientform/address', 'patientProfile')}</span>
        <div className="col-12">
          <AddressFields
            unform
            onChange={handleFormChange}
            onBlur={handleTouchField}
            stateFieldCanBeEmpty
            addressZipcode={get(initialData, 'addressZipcode', '')}
            addressStreet={get(initialData, 'addressStreet', '')}
            addressNumber={get(initialData, 'addressNumber', '')}
            addressNeighborhood={get(initialData, 'addressNeighborhood', '')}
            addressCity={get(initialData, 'addressCity', '')}
            addressState={get(initialData, 'addressState', '')}
            addressExtra={get(initialData, 'addressExtra', '')}
          />
        </div>
        {customFields.patientsInsurancePlanFieldsActivated &&
          <div className="row-12">

            <span className="formHeading ptopFix col-12" id="scroolto-insurance">{_('label/patientform/insurance', 'patientProfile')}</span>

            <Input
              className="col-xl-4 col-lg-4 col-md-6 col-sm-12 input-insuranceCompany"
              uncontrolled
              name="insuranceCompany"
              label={_('label/patient_form/insuranceCompany', null, fSufixTwoPoints)}
              onChange={handleFormChange}
              defaultValue={get(initialData, 'insuranceCompany', '')}
              onBlur={handleTouchField}
            />
            <Input
              className="col-xl-4 col-lg-4 col-md-6 col-sm-12 input-insurancePlan"
              uncontrolled
              name="insurancePlan"
              label={_('label/patient_form/insurancePlan', null, fSufixTwoPoints)}
              onChange={handleFormChange}
              defaultValue={get(initialData, 'insurancePlan', '')}
              onBlur={handleTouchField}
            />
            <Input
              className="col-xl-4 col-lg-4 col-md-6 col-sm-12 input-insuranceNumber"
              uncontrolled
              name="insuranceNumber"
              label={_('label/patient_form/insuranceNumber', null, fSufixTwoPoints)}
              onChange={handleFormChange}
              defaultValue={get(initialData, 'insuranceNumber', '')}
              onBlur={handleTouchField}
            />
            <Input
              className="col-xl-4 col-lg-4 col-md-6 col-sm-12 input-insuranceExpirationDate"
              uncontrolled
              name="insuranceExpirationDate"
              label={_('label/patient_form/insuranceExpirationDate', null, fSufixTwoPoints)}
              onChange={handleFormChange}
              defaultValue={get(initialData, 'insuranceExpirationDate', '')}
              onBlur={handleTouchField}
            />
          </div>
        }

        <span className="formHeading ptopFix col-12" id="scroolto-notes">{_('label/patientform/notes', 'patientProfile')}</span>

        <TextArea
          className="col-12"
          uncontrolled
          name="notes"
          onChange={handleFormChange}
          defaultValue={replaceHtmlEntitesToText(get(initialData, 'notes', ''))}
          onBlur={handleTouchField}
        />
      </div>
    );


    return (
      <PlatformSwitch
        desktop={() => (
          <Modal id="patientFormModal" onClose={onClose}>
            <div className="modalGrid">
              <aside className="modalSidebar">
                <span className="sidebarTitle">{modalTitle}</span>
                <ul className="sidebarNav">
                  {map(modalMenuItems, (modalMenuItem) => {
                    const isActive = activeMenu === modalMenuItem.value;
                    if (!customFields.patientsInsurancePlanFieldsActivated && modalMenuItem.value === 'insurance') return null;
                    return (
                      <li
                        key={modalMenuItem.value}
                        onClick={this.handleModalMenuItemClick(modalMenuItem)}
                        className={isActive ? 'active' : null}
                        role="presentation"
                      >
                        {modalMenuItem.text}
                      </li>
                    );
                  })}
                </ul>
              </aside>
              <form className="modalContainer form" id="patientFormModalFormElement">
                <div className="modalContent" ref={ref => { this.modalContentRef = ref; }} >
                  {rehydrating ? null : formContent}
                </div>
                <div className="modalFooter">
                  <Button text={_('action/cancel', 'global')} onClick={onClose} type="button" disabled={isSaving} />
                  <Button type="button" className="primary" text={_('action/save', 'global')} onClick={this.handleSave} isLoading={isSaving} />
                </div>
              </form>
            </div>
          </Modal>
        )}
        mobile={() => (
          <Modal id="patientFormModal" className="asView" onClose={onClose}>
            <div className="modalHeader">
              <Button onClick={onClose}>
                <Icon iconName="close" className="icon" />
              </Button>
              <span className="title">{modalTitle}</span>
              <Button className="primary" text={_('action/save', 'global')} onClick={this.handleSave} isLoading={isSaving} />
            </div>

            <div className="modalContainer">
              {/*
              <SwipeTabs
                items={modalMenuItems}
                pageSize={2}
                active="Dados Básicos"
                onTabChange={this.handleModalMenuItemClick({})}
              /> */}
              <form className="modalContent">
                <div className="modalContent form" ref={ref => { this.modalContentRef = ref; }} >
                  {rehydrating ? null : formContent}
                </div>
              </form>
            </div>
          </Modal>
        )}
      />
    );
  }

  modalContentRef = null;

  handleModalMenuItemClick = (item: Object) => (value: string) => {
    const scroolto = get(item, 'value', value);
    if (this.modalContentRef) {
      this.setState({ activeMenu: scroolto });
      const id = `scroolto-${scroolto}`;
      const node = ReactDOM.findDOMNode(this.modalContentRef);
      const targetNode = document.getElementById(id);
      if (node) {
        JQuery(node).animate({ scrollTop: get(targetNode, 'offsetTop', 0) - (window.isCordovaApp ? 100 : 10) });
      }
    }
  }

  handleFormValidation = (isSubmit: boolean = false): Promise<void> => new Promise(resolve => {
    const {
      formData, touchedFields, onSetConfirmation, formChanged,
    } = this.props;

    this.validator = createValidator()
      .field('name')
      .present(_ => _('error/name'))
      .field('cpf')
      .cpf(_ => _('error/cpf'), true)
      .field('email')
      .email(_ => _('error/invalid_email'), true);

    const result = this.validator.validate(formData(), touchedFields(), isSubmit);

    if (isSubmit) {
      const fieldName = first(Object.keys(result.errors || {}));

      if (fieldName) {
        const fieldLabel = document.querySelector(`.input-${fieldName} > label`);
        const field = document.querySelector(`.input-${fieldName} > input`);

        if (fieldLabel) {
          fieldLabel.scrollIntoView();
        }

        if (field) {
          field.focus();
        }
      }
    }

    onSetConfirmation(formChanged());

    this.setState({ errors: result.errors, formIsValid: result.valid }, resolve);
  });

  handleSave = () => {
    this.handleFormValidation(true).then(() => {
      if (this.state.formIsValid) {
        this.props.onSave(this.props.formData());
      }
    });
  };

  handleFormCpfChance = (data: Object) => {
    this.props.handleFormChange(data);
  };

  validator = createValidator()
    .field('name')
    .present(_ => _('error/name'))
    .field('cpf')
    .cpf(_ => _('error/cpf'), true)
    .field('email')
    .email(_ => _('error/invalid_email'), true);
}


export default withTranslation()(withUnForm({ autoRehydrate: false })(PatientFormModal));
