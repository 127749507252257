// @flow
import Immutable from 'seamless-immutable';
import axios from 'axios';
import { get } from 'lodash';
import { Api } from '../../helpers';
import { router } from '../../routes/router';
import type {
  AuthState,
  LoginRequest,
  GhostLoginRequest,
  Dispatch,
  Action,
} from '../../types';
import { appControlTypes } from '../modules/appControl';
import { actionTypes as userActions } from './context/user';
import { clinicActions } from './context/clinics';

type RegistrationStep1Request = {
  email: string,
  password: string,
  countryCode: ? string,
};

type RegistrationStep2Request = {
  userId: number,
  name: string,
  gender: string,
  mobilePhone: string,
  subscribeEmailList: boolean,
};

type RegistrationStep3Request = {
  userId: number,
  professionCode: string,
};

type RegistrationStep4Request = {
  userId: number,
  clinicSize: string,
};

// ------------------------------------
// Constants
// ------------------------------------
const AUTH_STARTED = 'auth.STARTED';
const AUTH_SET_TOKEN = 'auth.SET_TOKEN';
const AUTH_SET_LOADING_STATE = 'auth.AUTH_SET_LOADING_STATE';
const AUTH_LOGGED_IN = 'auth.LOGGED_IN';
const AUTH_FAILED = 'auth.FAILED';

const AUTH_LOGGED_OUT = 'auth.LOGGED_OUT';
const AUTH_LOGOUT_FAILED = 'auth.LOGOUT_FAILED';

// Signup process
const SIGNUP_STEP1_STARTED = 'auth.SIGNUP_STEP1_STARTED';
const SIGNUP_STEP1_SUCCESS = 'auth.SIGNUP_STEP1_SUCCESS';
const SIGNUP_STEP1_ERROR = 'auth.SIGNUP_STEP1_ERROR';

const SIGNUP_STEP2_STARTED = 'auth.SIGNUP_STEP2_STARTED';
const SIGNUP_STEP2_SUCCESS = 'auth.SIGNUP_STEP2_SUCCESS';
const SIGNUP_STEP2_ERROR = 'auth.SIGNUP_STEP2_ERROR';

const SIGNUP_STEP3_STARTED = 'auth.SIGNUP_STEP3_STARTED';
const SIGNUP_STEP3_SUCCESS = 'auth.SIGNUP_STEP3_SUCCESS';
const SIGNUP_STEP3_ERROR = 'auth.SIGNUP_STEP3_ERROR';

const SIGNUP_STEP4_STARTED = 'auth.SIGNUP_STEP4_STARTED';
const SIGNUP_STEP4_SUCCESS = 'auth.SIGNUP_STEP4_SUCCESS';
const SIGNUP_STEP4_ERROR = 'auth.SIGNUP_STEP4_ERROR';
const SIGNUP_RESET_LOADING_STATE = 'auth.SIGNUP_RESET_LOADING_STATE';

// ------------------------------------
// Actions
// ------------------------------------

export const login = (requestData: LoginRequest, disableRedirect?: boolean = false) => (
  (dispatch: Dispatch) => {
    dispatch({ type: AUTH_STARTED });
    Api.post(router.getApiRoute('auth', null), {
      ignoreBugSnag: true,
      data: requestData,
      success: (response) => {
        if (response.accessToken) {
          const user = get(response, ['data', 'user']);
          dispatch({ type: AUTH_LOGGED_IN, token: response.accessToken });
          dispatch({ type: userActions.USER_LOADED, user });
          dispatch(clinicActions.getClinics(() => {
            // Redirect after login
            dispatch(router.pushPath('patientsList'));
          }));
        } else {
          dispatch({ type: AUTH_FAILED, error: { message: response.error } });
          // Redirect back to last signup step
          if (!disableRedirect) {
            dispatch(router.pushPath('signUpProfession'));
          }
        }
      },
      error: (error) => {
        const errorMessage = error.message;
        dispatch({ type: AUTH_FAILED, error: { message: errorMessage } });
      },
    });
  }
);

export const ghostLogin = (requestData: GhostLoginRequest) => (
  (dispatch: Dispatch) => {
    dispatch({ type: AUTH_STARTED });
    Api.post(router.getApiRoute('ghostLogin', null), {
      data: requestData,
      success: (response) => {
        if (response.accessToken) {
          dispatch({ type: appControlTypes.RESET_STORE });

          const user = get(response, ['data', 'user']);
          dispatch({ type: AUTH_LOGGED_IN, token: response.accessToken });
          dispatch({ type: userActions.USER_LOADED, user });

          dispatch(clinicActions.getClinics(() => {
            dispatch(router.pushPath('patientsList'));
          }));
        } else {
          dispatch({ type: AUTH_FAILED, error: { message: response.error } });
        }
      },
      error: (error) => {
        const errorMessage = error.message;
        dispatch({ type: AUTH_FAILED, error: { message: errorMessage } });
      },
    });
  }
);

export const logout = (reload: boolean = true) => (
  (dispatch: Dispatch) => {
    if (window.MdHub) {
      window.MdHub.command.send('plataforma.sdk', 'logout');
      dispatch(clinicActions.setMemedLoadedStatus(true));
    }

    Api.destroy(router.getApiRoute('logout', null), {
      data: {},
      success: () => {
        dispatch({ type: AUTH_LOGGED_OUT });
        // Get all keys in LocalStorage
        const keys = Object.keys(localStorage);

        // Check if the keys array is not empty
        if (keys.length > 0) {
          // Loop through all keys
          for (const key of keys) {
            // Check if the key is not null or undefined
            if (key) {
              // Check if the key starts with the desired string
              if (key.startsWith('doutore-persist-data-v2')) {
                // If so, remove the item from LocalStorage
                localStorage.removeItem(key);
              }
            }
          }
        }


        dispatch(router.pushPath('login'));
        window.caches.keys().then((names) => {
          for (const name of names) { window.caches.delete(name); }
        });
        window.idb.reset().then(() => {
          if (reload) {
            window.location.reload();
          }
        });
      },
      error: (error) => {
        const errorMessage = error.message;
        dispatch({ type: AUTH_LOGOUT_FAILED, error: { message: errorMessage } });
      },
    });
  }
);

export const setLoadingState = (isLoading: boolean) => (
  (dispatch: Dispatch) => {
    dispatch({ type: AUTH_SET_LOADING_STATE, isLoading });
  }
);


export const signupStep1 = (registrationStepData: RegistrationStep1Request) => (dispatch: Dispatch) => {
  const {
    email, password, countryCode,
  } = registrationStepData;
  const data = {
    user: {
      email,
      password,
      countryCode,
    },
  };

  dispatch({ type: SIGNUP_STEP1_STARTED });
  Api.post(router.getApiRoute('signupStep1', null), {
    data,
    success: (response) => {
      const user = get(response, ['data', 'user']);
      // Will set userId and email to context/user
      dispatch({ type: userActions.USER_LOADED, user });
      dispatch({
        type: SIGNUP_STEP1_SUCCESS,
        token: response.accessToken,
      });
      // Redirect to step2
      dispatch(router.pushPath('signUpBasic'));
    },
    error: (error) => {
      dispatch({ type: SIGNUP_STEP1_ERROR, error: { message: error.message } });
    },
  });
};

export const signupStep2 = (registrationStepData: RegistrationStep2Request) => (dispatch: Dispatch) => {
  const {
    userId, name, gender,
    mobilePhone, subscribeEmailList,
  } = registrationStepData;

  const data = {
    user: {
      id: userId,
      name,
      mobilePhone,
      gender,
      subscribeEmailList,
    },
  };

  dispatch({ type: SIGNUP_STEP2_STARTED });
  Api.post(router.getApiRoute('signupStep2', null), {
    data,
    success: (response) => {
      const user = get(response, ['data', 'user']);
      dispatch({ type: userActions.USER_LOADED, user });
      dispatch({ type: SIGNUP_STEP2_SUCCESS });
      // Redirect to step3
      dispatch(router.pushPath('signUpProfession'));
    },
    error: (error) => {
      dispatch({ type: SIGNUP_STEP2_ERROR, error: { message: error.message } });
    },
  });
};

export const resetSignUpState = () => (dispatch: Dispatch) => {
  dispatch({ type: SIGNUP_RESET_LOADING_STATE });
};

export const signupStep3 = (registrationStepData: RegistrationStep3Request) => (dispatch: Dispatch) => {
  const { userId, professionCode } = registrationStepData;
  const data = {
    user: {
      id: userId,
      professionCode,
    },
  };
  dispatch({ type: SIGNUP_STEP3_STARTED });
  Api.post(router.getApiRoute('signupStep3', null), {
    data,
    success: (response) => {
      const user = get(response, ['data', 'user']);
      dispatch({ type: userActions.USER_LOADED, user });

      dispatch(clinicActions.getClinics(() => {
        dispatch({ type: SIGNUP_STEP3_SUCCESS });

        // Redirect to step4
        dispatch(router.pushPath('signUpClinic'));
      }));
    },
    error: (error) => {
      dispatch({ type: SIGNUP_STEP3_ERROR, error: { message: error.message } });
    },
  });
};

export const signupStep4 = (registrationStepData: RegistrationStep4Request) => (dispatch: Dispatch) => {
  const { userId, clinicSize } = registrationStepData;
  const data = {
    user: {
      id: userId,
      clinicSize,
    },
  };
  dispatch({ type: SIGNUP_STEP4_STARTED });
  Api.post(router.getApiRoute('signupStep4', null), {
    data,
    success: (response) => {
      const user = get(response, ['data', 'user']);
      dispatch({ type: userActions.USER_LOADED, user });
      dispatch({ type: SIGNUP_STEP4_SUCCESS });
      // Redirect to step4
    },
    error: (error) => {
      dispatch({ type: SIGNUP_STEP4_ERROR, error: { message: error.message } });
    },
  });
};

export const actionTypes = {
  AUTH_STARTED,
  AUTH_LOGGED_IN,
  AUTH_LOGGED_OUT,
  AUTH_FAILED,
  AUTH_SET_LOADING_STATE,
  AUTH_SET_TOKEN,
};

export const actions = {
  login,
  logout,
  setLoadingState,
  signupStep1,
  signupStep2,
  signupStep3,
  signupStep4,
  resetSignUpState,
};

// ------------------------------------
// Reducer
// ------------------------------------

export default function authReducer (state: AuthState = Immutable({}), action: Action): AuthState {
  switch (action.type) {
    case AUTH_STARTED:
      return state
        .set('inProgress', true)
        .set('errors', null);

    case AUTH_SET_TOKEN:
      return Immutable({
        authenticated: 'user',
        token: action.token || '',
        errors: null,
      });

    case AUTH_LOGGED_IN:
      axios.defaults.headers['access-token'] = action.token;

      return Immutable({
        inProgress: false,
        authenticated: 'user',
        token: action.token,
        errors: null,
      });

    case AUTH_FAILED:
      return state
        .set('inProgress', false)
        .set('errors', [action.error.message]);

    case AUTH_SET_LOADING_STATE:
      return state
        .set('inProgress', action.isLoading)
        .set('errors', null);

    case AUTH_LOGGED_OUT:
      return state
        .set('inProgress', false)
        .set('authenticated', false)
        .set('token', null);

    case AUTH_LOGOUT_FAILED:
      return state
        .set('inProgress', false)
        .set('errors', [action.error.message]);

    // Signup step 1
    case SIGNUP_STEP1_STARTED:
      return state
        .set('inProgress', true)
        .set('errors', null);

    case SIGNUP_STEP1_SUCCESS:
      return state
        .set('inProgress', false)
        .set('authenticated', 'user')
        .set('token', action.token);

    case SIGNUP_STEP1_ERROR:
      return state
        .set('inProgress', false)
        .set('errors', [action.error.message]);

    // Signup step 2
    case SIGNUP_STEP2_STARTED:
      return state
        .set('inProgress', true)
        .set('errors', null);

    case SIGNUP_STEP2_SUCCESS:
      return state
        .set('inProgress', false);

    case SIGNUP_STEP2_ERROR:
      return state
        .set('inProgress', false)
        .set('errors', [action.error.message]);

    // Signup step 3
    case SIGNUP_STEP3_STARTED:
      return state
        .set('inProgress', true)
        .set('errors', null);

    case SIGNUP_STEP3_SUCCESS:
      return state
        .set('inProgress', false);

    case SIGNUP_STEP3_ERROR:
      return state
        .set('inProgress', false)
        .set('errors', [action.error.message]);

    // Signup step 4
    case SIGNUP_STEP4_STARTED:
      return state
        .set('inProgress', true)
        .set('errors', null);

    case SIGNUP_STEP4_SUCCESS:
      return state
        .set('inProgress', false);

    case SIGNUP_STEP4_ERROR:
      return state
        .set('inProgress', false)
        .set('errors', [action.error.message]);

    case SIGNUP_RESET_LOADING_STATE:
      return state
        .set('inProgress', false)
        .set('errors', null);

    default:
      return state;
  }
}
