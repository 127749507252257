// @flow
import React from 'react';
import { get } from 'lodash';
import { connect } from 'react-redux';
import { ReceiptModal } from '../../components/views';
import { actions } from '../../redux/modules/context/modals';
import { receiptActions } from '../../redux/modules/context/receipts';
import type { Dispatch, Receipt } from '../../types';

type Props = {
  dispatch: Function,
  patientId: number,
  receiptId: number,
  isLoading: boolean,
  receipt: Receipt,
};

class ReceiptModalContainer extends React.Component<Props> {
  render() {
    const { isLoading, receipt } = this.props;

    return (
      <ReceiptModal
        onEditReceiptClick={this.handleEditReceiptClick}
        onClose={this.closeModal}
        isLoading={isLoading}
        receipt={receipt}
        onPrint={this.handlePrintClick}
      />
    );
  }

  closeModal = () => {
    this.props.dispatch(actions.closeActiveModal());
  };


  handleEditReceiptClick = () => {
    const { patientId, receiptId, dispatch } = this.props;
    this.closeModal();
    dispatch(actions.openModal('ReceiptFormModal', { patientId, receiptId }));
  };

  handlePrintClick = () => {
    const { receiptId, patientId, dispatch } = this.props;
    dispatch(receiptActions.showReceiptPDF(receiptId, patientId));
  }
}

const mapStateToProps = (state) => ({
  patientId: get(state, ['context', 'modals', 'data', 'patientId']),
  receiptId: get(state, ['context', 'modals', 'data', 'receiptId']),
  isLoading: get(state, ['context', 'receipts', 'isLoading'], false),
  receipt: get(state, ['context', 'receipts', 'data', state.context.modals.data.receiptId], {}),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({ dispatch });

export default connect<any, any, any, any, any, any>(
  mapStateToProps,
  mapDispatchToProps,
)(ReceiptModalContainer);
