// @flow
import React from 'react';
import type { Node } from 'react';
import classnames from 'classnames';
import ReactDOM from 'react-dom';
import './Modal.scss';

type Props = {
  children: Node,
  className?: string,
  id?: string,
  preventCloseOnOverlayClick?: boolean,
  onClose?: Function,
  ignoreEscape?: boolean,
  throughPortal?: boolean,
  onEscape?: Function,
  onKeyDown?: Function,
};


export default class Modal extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
    this.bindPortal();
  }
  componentWillMount() {
    document.addEventListener('keyup', this.handleKeyClose);
    this.bindPortal();
  }

  componentDidMount() {
    if (window.isCordovaApp) {
      window.document.activeElement.blur();
    }
  }

  componentWillUnmount() {
    document.removeEventListener('keyup', this.handleKeyClose);
  }

  render() {
    const {
      children,
      className,
      id,
      throughPortal,
      onKeyDown,
    } = this.props;

    const cssClasses = classnames(['modal', className]);

    const modal = (
      <div id={id} className={cssClasses} onKeyDown={onKeyDown || (() => { })}>
        <div className="modalOverlay" onClick={this.handleOverlayClick} />
        <div className="modalLayout">
          <div className="modalClose" onClick={this.handleCloseButtonClick} />
          {children}
        </div>
      </div>
    );

    return throughPortal ? ReactDOM.createPortal(modal, this.portalModalContainer) : modal;
  }

  portalModalContainer: any = null;

  handleOverlayClick = () => {
    const { preventCloseOnOverlayClick, onClose } = this.props;
    if (!preventCloseOnOverlayClick && onClose) {
      onClose();
    }
  };

  handleCloseButtonClick = () => {
    const { onClose } = this.props;
    if (onClose) {
      onClose();
    }
  };

  bindPortal = () => {
    let el: any = document.getElementById('portalModalContainer');
    if (!el) {
      el = document.createElement('div');
      el.setAttribute('id', 'portalModalContainer');
      if (document.body) {
        document.body.appendChild(el);
      }
    }
    this.portalModalContainer = el;
  };

  handleKeyClose = (e: Object) => {
    if (e.code === 'Escape' && document.body && document.body.getElementsByClassName('swal2-container').length === 0) {
      if (this.props.onClose && !this.props.ignoreEscape) {
        this.props.onClose();
      }
      if (this.props.onEscape) {
        this.props.onEscape();
      }
    }
  };
}
