// @flow

import React from 'react';
import classNames from 'classnames';
import { map } from 'lodash';
import { Button, Icon, HiddenLines, Dropdown } from '@elements';
import type { Record, PermissionTo } from '@types';
import { parteHtmlToReact as parseHtml } from '@helpers/helpers';

type Props = {
  maxTextCharsDocuments: number;
  shareDropdownItems: Function,
  actionClasses: any,
  onShareClick: Function,
  _: Function,
  documentsContent: any,
  isCordova: boolean,
  isEditable: any,
  isMobile: boolean,
  mainSection: any,
  onEditDocumentClick: Function,
  onPrintDocumentClick: Function,
  record: Record,
  printDropdownItems: Function,
  permissionsTo: PermissionTo,
  isMine: boolean,
  directShareLoading: boolean,
}

const DocumentsContent = ({ maxTextCharsDocuments, _, actionClasses, documentsContent, isCordova, isEditable,
  isMobile, mainSection, onEditDocumentClick, onPrintDocumentClick, onShareClick, permissionsTo, isMine, directShareLoading,
  printDropdownItems, record, shareDropdownItems }: Props) => map(documentsContent, (document) => (
    <div className={classNames('record document freeText', actionClasses)} key={document.id}>
      <div className='documentTitleWraper'>
        <span className={classNames('recordTitle', { isMobile: isCordova })}>
          {document.name}
        </span>
        <div className={classNames('documentActions recordContentActions', { isMobile: isMobile || isCordova })}>
          {isEditable && onEditDocumentClick &&
          <Button
            text={<React.Fragment><Icon iconName="edit" size={18} />{!window.isCordovaApp && (<React.Fragment>&nbsp;&nbsp;</React.Fragment>)}{!window.isCordovaApp && _('action/edit', 'global')}</React.Fragment>}
            className='outlineButton buttonLike'
            onClick={onEditDocumentClick(record.id, document.id)}
          />
        }

          {(permissionsTo.doctorShowSignature && isMine) && (
          <Dropdown
            className={window.isCordovaApp ? 'outlineButton singleIconFix buttonLike' : 'outlineButton buttonLike'}
            placeholder={(
              <React.Fragment>
                {window.isCordovaApp && (
                <React.Fragment><Icon iconName="print" className='icon' size={18} /></React.Fragment>
                )}
                {!window.isCordovaApp && (
                  <React.Fragment><Icon iconName="print" className='icon' size={18} />{!window.isCordovaApp && (<React.Fragment>&nbsp;&nbsp;</React.Fragment>)}{_('action/print')}</React.Fragment>
                )}
              </React.Fragment>
            )}
            disableAutoModalTitle
            disableArrowIcon={isMobile}
            items={printDropdownItems(window.isCordovaApp ? 'Compartilhar impressão de Documento' : 'Imprimir Documento')}
            onSelectItem={({ action }) => onPrintDocumentClick(record.id, document.id, action)()}
          />
          )}
          {!(permissionsTo.doctorShowSignature && isMine) && (
          <Button
            className='outlineButton buttonLike'
            onClick={onPrintDocumentClick(record.id, document.id)}
          >
            <React.Fragment>
              {window.isCordovaApp && (
              <React.Fragment><Icon iconName="print" className='icon' size={18} /></React.Fragment>
              )}
              {!window.isCordovaApp && (
              <React.Fragment><Icon iconName="print" className='icon' size={18} />{!window.isCordovaApp && (<React.Fragment>&nbsp;&nbsp;</React.Fragment>)}{_('action/print')}</React.Fragment>
              )}
            </React.Fragment>
          </Button>
          )}
          {isMine && (
            <Dropdown
              className={window.isCordovaApp ? 'outlineButton singleIconFix newShareFeature buttonLike' : 'outlineButton newShareFeature buttonLike'}
              placeholder={(
                <React.Fragment>
                  {window.isCordovaApp && (
                  <React.Fragment><Icon iconName={directShareLoading ? 'spinner' : 'share'} className='icon' size={18} /></React.Fragment>
                  )}
                  {!window.isCordovaApp && (
                  <React.Fragment><Icon iconName={directShareLoading ? 'spinner' : 'share'} className='icon' size={18} />{!window.isCordovaApp && (<React.Fragment>&nbsp;&nbsp;</React.Fragment>)}{_('action/share')}</React.Fragment>
                  )}
                </React.Fragment>
            )}
              disableAutoModalTitle
              disableArrowIcon={isMobile}
              items={shareDropdownItems}
              onClick={onShareClick('documents', document.id)}
              forceToBeClosed={directShareLoading}
            />
          )}
          {/* <Button
          className='outlineButton buttonLike'
          onClick={onPrintDocumentClick(record.id, document.id, true, true)}
        >
          <Icon iconName='mail-send' className='icon' size={18} />
        </Button> */}
        </div>
      </div>
      <div className="recordContent ritchTextContent ritchTextContent">
        <HiddenLines maxChars={maxTextCharsDocuments} text={document.content} scrollContainer={mainSection}>
          {text => <div>{parseHtml(text, false)}</div>}
        </HiddenLines>
      </div>
    </div>
));


// $FlowFixMe
export default React.memo(DocumentsContent);
