// @flow
import React from 'react';
import { connect } from 'react-redux';
import { get } from 'lodash';
import { router } from '../routes/router';

import type { User, AuthState } from '../types';
import { ErrorBoundaryView } from '@blocks';

type Props = {
  auth: AuthState,
  user: User,
  dispatch: Function,
  history: Object,
  clinics: Object,
};

export function requireAuth(Component: Object, path: string) {
  class AuthenticatedRoute extends React.Component<Props> {
    componentWillMount() {
      this.checkAuth();
    }

    componentWillReceiveProps() {
      this.checkAuth();
    }

    render() {
      const isAuthenticated = get(this.props, ['auth', 'authenticated']);
      return (
        <ErrorBoundaryView>
          {(isAuthenticated === 'user') ? <Component {...this.props} /> : null}
        </ErrorBoundaryView>
      );
    }

    checkAuth() {
      const routes = router.getNamedRoutes();
      const { user, auth, clinics } = this.props;
      const authenticationInProgress = get(auth, 'inProgress');
      const isAuthenticated = get(auth, 'authenticated');
      const userName = get(user, 'name');
      const userEmail = get(user, 'email');
      const advancedData = get(user, 'advancedData', null);
      const userProfession = get(user, 'professionCode');
      const userRole = get(user, 'roleType');
      const blocked = get(advancedData, 'blocked', false);
      const subscriptionStatus = get(advancedData, 'subscriptionStatus', false);
      const haveActiveClinic = !!get(clinics, ['activeClinic', 'id'], null);
      const clinicLoading = !!get(clinics, ['isLoading'], false);

      if (!isAuthenticated) {
        if (window.isCordovaApp) {
          this.props.dispatch(router.pushPath('appHome'));
        } else {
          this.props.dispatch(router.pushPath('login'));
        }
      }

      // Check registration process step data and redirect to specific step depending on criteria
      // - step 1 - email
      // - step 2 - username
      // - step 3 - profession
      if (isAuthenticated && userEmail && !userName) {
        this.props.dispatch(router.pushPath('signUpBasic'));
      } else if (isAuthenticated && !authenticationInProgress && userEmail && userName && !userProfession && (userRole !== 'Secretary')) {
        this.props.dispatch(router.pushPath('signUpProfession'));
      } else if (isAuthenticated && !authenticationInProgress && userEmail && userName && (userProfession || (userRole === 'Secretary')) && !advancedData) {
        this.props.dispatch(router.pushPath('newClinic'));
      } else if (isAuthenticated && blocked && (
        path !== routes.userConfig &&
        path !== routes.benefits &&
        path !== routes.pricing &&
        path !== routes.subscriptionAddress &&
        path !== routes.subscriptionWall &&
        path !== routes.subscriptionInfo &&
        path !== routes.subscriptionComplete &&
        path !== routes.subscriptionPayment &&
        path !== routes.notFound &&
        path !== routes.backupRequest &&
        path !== routes.support
      )) {
        if (subscriptionStatus === 'end_of_trial') {
          this.props.dispatch(router.pushPath('subscriptionWall'));
        } else {
          this.props.dispatch(router.pushPath('subscriptionInfo'));
        }
      } if (!haveActiveClinic && userProfession && advancedData && isAuthenticated && !authenticationInProgress && path !== '/cadastro/clinica' && !clinicLoading) {
        this.props.dispatch(router.pushPath('newClinic'));
        return path === '/cadastro/clinica/nova';
      }
    }
  }

  const mapStateToProps: any = (state) => ({
    auth: state.auth,
    user: state.context.user,
    clinics: state.context.clinics,
  });

  const connected: any = connect<any, any, any, any, any, any>(mapStateToProps);

  return connected(AuthenticatedRoute);
}
