import React from 'react';
import WrappedLocalStorage from './WrappedLocalStorage';

/**
 * @param {*} namespace group of data
 * @param {*} storage unique storage name
 */
export const withStorage = (namespace: string, storage: string) => (WrappedComponent: any) =>
  class TComponent extends React.Component {
    constructor() {
      super();
      this.componentStorage = new WrappedLocalStorage(`${namespace}.${storage}`);
    }
    render() {
      const newProps = {
        ...this.props,
        componentStorage: {
          get: this.componentStorage.getItem,
          set: this.componentStorage.setItem,
        },
      };

      return (<WrappedComponent {...newProps} />);
    }
  };

