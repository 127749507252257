// @flow
import React from 'react';
import moment from 'moment';
import { get, isNaN, toNumber, find } from 'lodash';
import { Icon, Button, Input, Toggle, CheckboxGroup, Modal } from '@elements';
import type { FormElementDataString } from '@types';


type Props = {
  recurrent: Object,
  setModal: Function,
  onChange: Function,
  isNew: boolean,
  onDeleteRecurrentEvents: Function,
  appointmentDate: string,
  _: Function,
};

const weekCheckboxes = (isNew: boolean, _: Function) => [
  {
    label: _('weekday/monday', 'constants'),
    value: 'monday',
    disabled: !isNew,
    day: 0,
  },
  {
    label: _('weekday/tuesday', 'constants'),
    value: 'tuesday',
    disabled: !isNew,
    day: 1,
  },
  {
    label: _('weekday/wednesday', 'constants'),
    value: 'wednesday',
    disabled: !isNew,
    day: 2,
  },
  {
    label: _('weekday/thursday', 'constants'),
    value: 'thursday',
    disabled: !isNew,
    day: 3,
  },
  {
    label: _('weekday/friday', 'constants'),
    value: 'friday',
    disabled: !isNew,
    day: 4,
  },
  {
    label: _('weekday/saturday', 'constants'),
    value: 'saturday',
    disabled: !isNew,
    day: 5,
  },
  {
    label: _('weekday/sunday', 'constants'),
    value: 'sunday',
    disabled: !isNew,
    day: 6,
  },
];
export default class RecurrencyModal extends React.Component<Props> {
  render() {
    const {
      isNew, onDeleteRecurrentEvents, recurrent, _,
    } = this.props;
    const { recurrentEnabled, recurrentDays, appointmentsCount, mode } = recurrent || {};

    const renderControls = () => (
      <React.Fragment>
        {recurrentEnabled && mode === 'weekly' &&
        <div className="form checkboxGroup">
          <CheckboxGroup
            className={!isNew ? 'editRecurrentCheckboxes recurrCheckbox' : 'recurrCheckbox'}
            items={weekCheckboxes(isNew, _)}
            onChange={this.onChangeRecurrentDays}
            checked={recurrentDays}
          />
        </div>
              }
        {recurrentEnabled &&
        <React.Fragment>
          <div className="listItem notClickable flexStart recurringLimit">
            {!isNew && (
            <span className="inputInlineLabel1" key="inputInlineLabel1">
              {_('label/recurrency/ends_after')} <b>{get(this.props, ['recurrent', 'appointmentsCount'])}</b> {_('label/appointments', 'global')}
            </span>
            )}
            {isNew &&
            <React.Fragment>
              <span className="inputInlineLabel1" key="inputInlineLabel1">{_('label/recurrency/ends_after')}</span>
              <Input
                name="appointmentsCount"
                key="inputField"
                type="text"
                value={appointmentsCount}
                onChange={this.onAppointmentsCountChange}
                onBlur={this.onAppointmentsCountBurl}
              />
              <span className="inputInlineLabel2" key="inputInlineLabel2">{_('label/appointments', 'global')}</span>
            </React.Fragment>
                    }
          </div>
          {!isNew && (
            <div className="recurrentDelete">
              <Button className="deleteButton" onClick={onDeleteRecurrentEvents}>
                {_('label/recurrency/delete_all_future_events')}
              </Button>
            </div>
          )}
        </React.Fragment>
              }
      </React.Fragment>
    );

    return (
      <Modal id="recurringModal" className="secondaryModal">
        <div className="modalHeader actionHeader">
          <Button className="backButton" onClick={this.handleBack}>
            <Icon iconName="arrow-left" className="icon" />
          </Button>
          <span className="modalTitle">{_('label/recurrency/recurrent_appointment')}</span>
        </div>
        <div className="modalGrid">
          <div className="modalContainer">
            <div className="modalContent">
              {isNew && (
                <React.Fragment>
                  <div className="listItem toggleHolder" onClick={this.onToggleWeeklyRecurrent}>
                    <Toggle
                      name="enableReccurency"
                      key="toggleSwitch"
                      label={_('action/recurrency/enable_recurrency')}
                      isChecked={recurrentEnabled && mode === 'weekly'}
                    />
                  </div>
                  {recurrentEnabled && mode === 'weekly' && renderControls()}
                  <div className="listItem toggleHolder" onClick={this.onToggleBiWeeklyRecurrent}>
                    <Toggle
                      name="enableReccurency"
                      key="toggleSwitch"
                      label={_('action/recurrency/enable_biweekly_recurrency')}
                      isChecked={recurrentEnabled && mode === 'biweekly'}
                    />
                  </div>
                  {recurrentEnabled && mode === 'biweekly' && renderControls()}
                </React.Fragment>
              )}

              {!isNew && renderControls()}

            </div>
          </div>
        </div>
      </Modal>
    );
  }

  onAppointmentsCountChange = (data: FormElementDataString) => {
    let appointmentsCount = String(data.value).replace(/\D+/g, '');
    appointmentsCount = String(appointmentsCount).trim().length > 0 ? toNumber(appointmentsCount) : '';
    appointmentsCount = String(appointmentsCount).substring(0, 2);
    this.props.onChange({
      name: 'recurrent',
      value: {
        ...this.props.recurrent,
        appointmentsCount,
      },
    });
  };

  onAppointmentsCountBurl = (data: FormElementDataString) => {
    let appointmentsCount = String(data.value).replace(/\D+/g, '');
    appointmentsCount = String(appointmentsCount).trim().length > 0 ? toNumber(appointmentsCount) : '';
    appointmentsCount = String(appointmentsCount).substring(0, 2);
    if (!isNaN(appointmentsCount)) {
      appointmentsCount = toNumber(appointmentsCount);
      appointmentsCount = appointmentsCount < 2 ? 2 : appointmentsCount;
      appointmentsCount = appointmentsCount > 99 ? 99 : appointmentsCount;
      appointmentsCount = isNaN(appointmentsCount) ? 2 : appointmentsCount;
    }
    this.props.onChange({
      name: 'recurrent',
      value: {
        ...this.props.recurrent,
        appointmentsCount,
      },
    });
  }

  onToggleWeeklyRecurrent = (e: any) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }

    const recurrentDay = find(weekCheckboxes(this.props.isNew, this.props._), d => d.day === moment(this.props.appointmentDate).weekday() - 1);
    const recurrentDays = recurrentDay ? [recurrentDay.value] : [];

    if (get(this.props.recurrent, 'recurrentEnabled', false)) {
      if (get(this.props.recurrent, 'mode', 'weekly') === 'weekly') {
        this.clearRecurrent();
      } else {
        this.setRecurrent('weekly', recurrentDays);
      }
    } else {
      this.setRecurrent('weekly', recurrentDays);
    }
  };

  onToggleBiWeeklyRecurrent = (e: any) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
    const recurrentDay = find(weekCheckboxes(this.props.isNew, this.props._), d => d.day === moment(this.props.appointmentDate).weekday() - 1);
    const recurrentDays = recurrentDay ? [recurrentDay.value] : [];

    if (get(this.props.recurrent, 'recurrentEnabled', false)) {
      if (get(this.props.recurrent, 'mode', 'weekly') === 'biweekly') {
        this.clearRecurrent();
      } else {
        this.setRecurrent('biweekly', recurrentDays);
      }
    } else {
      this.setRecurrent('biweekly', recurrentDays);
    }
  };

  setRecurrent = (mode: 'weekly' | 'biweekly', recurrentDays: Array<any>) => {
    this.props.onChange({
      name: 'recurrent',
      value: {
        appointmentsCount: 10,
        ...this.props.recurrent,
        recurrentEnabled: true,
        mode,
        recurrentDays,
      },
    });
  };

  clearRecurrent = () => {
    this.props.onChange({
      name: 'recurrent',
      value: null,
    });
  };

  onChangeRecurrentDays = (recurrentDays: Array<string>) => {
    this.props.onChange({
      name: 'recurrent',
      value: {
        ...this.props.recurrent,
        recurrentDays,
      },
    });
  };

  handleBack = () => this.props.setModal(null);
}
