// @flow
import React from 'react';
import { connect } from 'react-redux';
import { get } from 'lodash';
import { logout } from '@context/user';
import { mapBundledPermission, mapPlanIncludes } from '@helpers/connect';
import { DrugsPrescriptionsTemplatesList as DrugsPrescriptionsTemplatesListView } from '../components/views';
import { drugsPrescriptionsTemplateActions } from '../redux/modules/context/drugsPrescriptionsTemplates';
import type { Dispatch, DrugsPrescriptionsTemplatesState, User, BundledPermissions, PlanInclude } from '../types';

type Props = {
  drugsPrescriptionsTemplates: DrugsPrescriptionsTemplatesState,
  user: User,
  dispatch: Function,
  permissions: BundledPermissions,
  planInclude: PlanInclude,
};

class DrugsPrescriptionsTemplatesList extends React.Component<Props> {
  componentWillMount() {
    this.props.dispatch(drugsPrescriptionsTemplateActions.getDrugsPrescriptionsTemplates(true));
  }

  render() {
    const { user, planInclude } = this.props;

    return (
      <DrugsPrescriptionsTemplatesListView
        drugsPrescriptionsTemplates={this.props.drugsPrescriptionsTemplates}
        user={user}
        onLogout={this.handleLogout}
        permissions={this.props.permissions}
        featureEnabled={planInclude.examsDrugsDocuments}
      />
    );
  }
  handleLogout = () => this.props.dispatch(logout());
}

const mapStateToProps = (state) => ({
  drugsPrescriptionsTemplates: get(state.context, ['drugsPrescriptionsTemplates'], {}),
  user: state.context.user,
  permissions: mapBundledPermission(state),
  planInclude: mapPlanIncludes(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({ dispatch });

export default connect<any, any, any, any, any, any>(
  mapStateToProps,
  mapDispatchToProps,
)(DrugsPrescriptionsTemplatesList);
