export default {
  // login
  'label/password': 'Senha',
  'label/lost_password': 'Esqueci minha senha',
  'label/login': 'Entrar',
  'label/signup_call': 'Ainda não possui uma conta?',
  'label/login/error/email': 'E-mail faltando',
  'label/login/error/password': 'Falta a senha',
  'label/login/error/invalid_email': 'O email não é válido',
  'action/signup_now': 'Cadastrar agora',
  // singup
  'label/create_your_account': 'Crie sua conta',
  'label/email': 'E-mail',
  'label/create_your_password': 'Escolha uma senha',
  'placeholder/password': 'Pelo menos 6 caracteres',
  'label/step2_call': 'Para personalizar sua experiência, precisamos saber um pouco mais sobre você.',
  'label/step2_call_email': 'Quero receber e-mails ocasionais de treinamento e suporte (promessa: jamais enviaremos spam)',
  'label/complete_name': 'Qual o seu nome completo?',
  'label/mobile_phone': 'Telefone Celular (opcional)',
  'placeholder/optional': '(opcional)',
  'action/create_account': 'Criar Conta',
  'action/next': 'Avançar',
  'label/received_invite': 'Recebeu um convite ou possui uma conta?',
  'label/login_now': 'Entrar agora',
  'error/password': 'Informe uma senha.',
  'error/name': 'Preencha o seu nome.',
  'error/phone': 'O telefone não é válido.',
  'error/email_empty': 'Informe o seu email.',
  'error/email_invalid': 'E-mail inválido.',
  'label/select_feedback': 'Ótimo! Já que você selecionou',
  'label/whats_your_specialty': 'Qual é a sua especialidade?',
  'label/whats_your_profession': 'Qual é a sua profissão?',
  'label/clinic_work_style_label': 'Como você trabalha atualmente?',
  'label/clinic_work_style_alone': 'Sozinho sem secretária',
  'label/clinic_work_style_with_secretary': 'Sozinho com secretária',
  'label/clinic_work_style_up_to_5': 'Com 2 a 5 profissionais de saúde',
  'label/clinic_work_style_more_than_5': 'Com mais de 5 profissionais de saúde',
  // newClinic
  'label/define_new_clinic': 'Definir Nova Clínica',
  'label/clinic_not_found': 'Sua conta não pertence mais a uma clínica. É necessário estar associado a uma clínica ou consultório para ter acesso ao Doutore',
  'action/create_new_clinic': 'Criar Nova Clínica',
  // GetStarted
  'label/lets_start': 'Vamos começar!',
  'action/create_a_patient': 'Criar um Paciente',
  'action/create_a_appointment': 'Agendar uma Consulta',
  'action/view_patient_example': 'Ver um Paciente Exemplo',
  'action/explore_app': 'Explorar o Aplicativo',
  // ForgotPassword
  'label/forgot_password': 'Esqueci a Senha',
  'label/recover_success': 'Um mensagem foi enviada ao seu email com as instruções para recuperar a sua senha.',
  'label/email_error': 'Este email não está cadastrado em nossa base.',
  'label/your_email': 'Seu e-mail',
  'label/q_remember_your_password': 'Lembrou sua senha?',
  'action/send_email_instrunctions': 'Enviar Instruções por E-mail',
  // NewPassword
  'action/create_password': 'Criar Senha',
  'label/requre_define_password': 'Antes de acessar o Doutore, é necessário definir uma senha pessoal para login.',
  'label/create_password_input': 'Crie sua senha:',
  // AppHome
  'label/onboarding/text1': 'Acesso ao Prontuário do seu paciente sempre que você precisar.',
  'label/onboarding/text2': 'Agenda Médica sempre atualizada, sincronizada e de fácil acesso.',
  'label/onboarding/text3': 'Sua secretária utiliza o Doutore online, pelo computador.',
  'label/onboarding/text4': 'E você, acessa de qualquer lugar. Pelo computador, tablet e celular.',
  'label/have_account': 'Já tem uma conta?',
  'action/login_now': 'Entrar agora',
};
