// @flow
import React from 'react';
import { connect } from 'react-redux';
import { GhostLogin as GhostLoginView } from '@views';
import type { GhostLoginRequest } from '@types';
import { ghostLogin } from '../redux/modules/auth';

type Props = {
  dispatch: Function,
};

class GostLogin extends React.Component<Props> {
  render() {
    return (
      <GhostLoginView
        onLogin={this.handleOnLogin}
      />
    );
  }

  handleOnLogin = (data: GhostLoginRequest) => {
    this.props.dispatch(ghostLogin(data));
  };
}

const mapStateToProps = (state) => ({
  user: state.context.user,
});

export default connect<any, any, any, any, any, any>(
  mapStateToProps,
)(GostLogin);
