// @flow
import React from 'react';
import { connect } from 'react-redux';
import { get } from 'lodash';
import { ProfileImageCaptureModal } from '../../components/views';
import { actions } from '../../redux/modules/context/modals';
import { patientActions } from '../../redux/modules/context/patients';
import type { Dispatch } from '../../types';

type ModalData = {
  patientId: number,
};

type Props = {
  modalData: ModalData,
  dispatch: Function,
};

class ProfileImageCaptureModalContainer extends React.Component<Props> {
  render() {
    const { modalData } = this.props;

    return (
      <ProfileImageCaptureModal
        data={modalData}
        onSavePicture={this.handleSavePicture}
        onClose={this.closeModal}
      />
    );
  }

  closeModal = () => {
    this.props.dispatch(actions.closeActiveModal());
  };

  handleSavePicture = (image: any) => {
    const patientId = get(this.props, ['modalData', 'patientId']);
    if (patientId) {
      this.props.dispatch(patientActions.uploadPatientProfileImage(patientId, image));
      this.closeModal();
    }
  };
}

const mapStateToProps = (state) => ({
  modalData: state.context.modals.data,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({ dispatch });

export default connect<any, any, any, any, any, any>(
  mapStateToProps,
  mapDispatchToProps,
)(ProfileImageCaptureModalContainer);
