// @flow
import React from 'react';
import { map, filter, get, toLower, includes, size } from 'lodash';
import {
  Modal, ClickableList, SearchInput,
  Button, Checkbox, InputMask, Loading,
  FormattedText,
  Icon,
} from '@elements';
import { PlatformSwitch } from '@blocks';
import type { ServicesState, FormElementData } from '@types';
import { sort, alphabeticCompare, createNsTranslator, handleSubmitEvent } from '@helpers/helpers';
import { withTranslation } from 'react-i18next';
import './ServicesNewListModal.scss';
import classNames from 'classnames';
import { OFFLINE_SEARCH_EDGE } from '@helpers/constants';

type Props = {
  onClose: Function,
  serviceTemplates: ServicesState,
  onSaveTemplates: Function,
  isLoading: boolean,
  onSetConfirmation: Function,
  onEditServices: Function,
  doctorName?: ?string,
  onCreateService: Function,
  t: Function,
};

type State = {
  searchTerm: string,
  selectedServices: {
    [key: number | string]: boolean,
  },
  customPrices: {
    [key: number | string]: number,
  },
  privateVisible: boolean,
  healthInsuranceVisible: boolean,
};


const maptoList = (serviceTemplates) => map(
  serviceTemplates,
  item => ({
    customRendererData: {
      id: item.id,
      text: item.name,
      price: item.price,
      templateId: item.id,
    },
  }),
);

const filterSearch = (serviceTemplates, searchTerm) => {
  const search = toLower(searchTerm).normalize('NFD').replace(/[\u0300-\u036f]/g, '');

  const searchPredicate = item => includes(toLower(item.name).normalize('NFD').replace(/[\u0300-\u036f]/g, ''), search);
  return search && String(search).trim().length > 0 ? filter(serviceTemplates, searchPredicate) : serviceTemplates;
};

const mapFromIdList = (serviceTemplates, idList) => filter(
  map(idList, id => get(serviceTemplates, String(id), null)),
  item => item !== null,
);

const servicesItems = (serviceTemplates, searchTerm, normalIds, healthInsuranceIds, _, privateVisible: boolean, healthInsuranceVisible: boolean) => {
  let normalItems = mapFromIdList(serviceTemplates, normalIds);
  let healthInsuranceItems = mapFromIdList(serviceTemplates, healthInsuranceIds);

  normalItems = filterSearch(normalItems, searchTerm);
  healthInsuranceItems = filterSearch(healthInsuranceItems, searchTerm);

  normalItems = maptoList(normalItems);
  healthInsuranceItems = maptoList(healthInsuranceItems);

  normalItems = sort(normalItems, alphabeticCompare('text'));
  healthInsuranceItems = sort(healthInsuranceItems, alphabeticCompare('text'));

  return [
    ...(normalItems.length > 0 ? [{
      type: 'heading',
      private: true,
      text: _('label/private'),
    }] : []),
    ...(privateVisible ? normalItems || [] : []),
    ...(healthInsuranceItems.length > 0 ? [{
      type: 'heading',
      private: false,
      text: _('label/status_health_insurance'),
    }] : []),
    ...(healthInsuranceVisible ? healthInsuranceItems || [] : []),
  ];
};


class ServicesNewListModal extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      selectedServices: {},
      customPrices: {},
      searchTerm: '',
      privateVisible: true,
      healthInsuranceVisible: true,
    };
  }


  componentDidMount() {
    handleSubmitEvent('#servicesNewListModalSubmit', this.handleFormSave);
  }

  renderListItem = (item: Object, key: string) => {
    const { selectedServices, customPrices } = this.state;
    const isChecked = get(selectedServices, String(item.id), false) && !item.healthInsurance;
    const isActive = isChecked && !item.healthInsurance;
    const price = get(customPrices, String(item.id), item.price);

    return [
      <Checkbox
        key={`chkbox-${key}`}
        name="price"
        label={item.text}
        isChecked={isChecked}
        onChange={this.handleItemSelectionChange(item.id, item.price)}
      />,
      <div className={`selectItem ${isActive && 'active'}`} key={`selectItem-${key}`}>
        <div className={`selectItemValueTransition  ${isActive ? 'visible' : 'invisible'}`}>
          {isActive && (
            <InputMask
              type="currency"
              value={price}
              onChange={this.handleItemCustomPriceChange(item.id)}
              onClick={this.onInputClick}
            />
          )}
        </div>
        <span className={`selectItemValueTransition  ${isActive ? 'invisible' : 'visible'}`}>
          <FormattedText type="currency" text={item.price} />
        </span>
      </div>,
    ];
  };

  render() {
    const {
      onClose, serviceTemplates, isLoading, onEditServices, doctorName,
    } = this.props;
    const { searchTerm, privateVisible, healthInsuranceVisible } = this.state;
    const _ = createNsTranslator('financial', this.props.t);

    const modalTitle = doctorName ? `${_('label/new_service_to')} ${doctorName}` : _('action/new_service');

    const serviceTemplatesData = get(serviceTemplates, 'data', {});
    const normalIds = get(serviceTemplates, 'normal', {});
    const healthInsuranceIds = get(serviceTemplates, 'healthInsurance', {});
    const showSerch = size(serviceTemplatesData) > OFFLINE_SEARCH_EDGE;

    const headingRenderer = (item: any, key: string, classes: string) => (
      <li key={key} className={classNames(classes, 'checkableHeading')} onClick={item.private ? this.handleTogglePrivateVisibility : this.handleToggleHealthInsuranceVisibility}>
        <div className="textContent">
          {item.text}
        </div>
        <div className="checkboxHolder">
          {(item.private ? this.state.privateVisible : this.state.healthInsuranceVisible) && (
            <React.Fragment>{_('action/hide_list', 'global')}&nbsp;<Icon iconName="arrow-down" /></React.Fragment>
          )}

          {!(item.private ? this.state.privateVisible : this.state.healthInsuranceVisible) && (
            <React.Fragment>{_('action/show_list', 'global')}&nbsp;<Icon iconName="arrow-left" /></React.Fragment>
          )}
        </div>
      </li>
    );

    const content = (
      <div>
        <div className="form mainActionHolder">
          {showSerch && (
            <SearchInput
              debounced
              placeholder={_('action/search_service')}
              onChange={this.handleSearchChange}
            />
          )}
          <div className="addNewTemplateButton" onClick={this.handleCreateService}>
            <Icon iconName="add" />&nbsp;{_('action/create_new_receipt')}
          </div>
        </div>


        {isLoading && <Loading />}
        {!isLoading &&
          <ClickableList
            items={servicesItems(serviceTemplatesData, searchTerm, normalIds, healthInsuranceIds, _, privateVisible, healthInsuranceVisible)}
            customRenderer={this.renderListItem}
            className="checkboxList"
            onListItemClick={this.handleItemSelectionClick}
            customHeadingRenderer={headingRenderer}
          />
        }
      </div>
    );

    return (
      <PlatformSwitch
        inject
        desktop={() => (
          <Modal id="ServicesNewListModal" onClose={onClose}>
            <div className="modalHeader">
              <span className="modalTitle">{modalTitle}</span>
            </div>

            <div className="modalGrid">
              <div className="modalContainer" id="servicesNewListModalSubmit">
                <div className="modalContent">
                  {content}
                </div>

                <div className="modalFooter">
                  <Button text={_('action/cancel', 'global')} onClick={onClose} />
                  <Button text={_('action/edit_services')} onClick={onEditServices} />
                  <Button className="primary" text={_('action/save', 'global')} onClick={this.handleFormSave} />
                </div>
              </div>

            </div>
          </Modal>
        )}
        mobile={() => (
          <Modal id="ServicesNewListModal" className="asView" onClose={onClose}>
            <div className="modalHeader">
              <Button onClick={onClose}>
                <Icon iconName="close" className="icon" />
              </Button>
              <span className="title">{modalTitle}</span>
              <Button className="primary" text={_('action/save', 'global')} onClick={this.handleFormSave} />
            </div>
            <div className="modalGrid">
              <div className="modalContainer">
                <div className="modalContent">
                  {content}
                </div>
              </div>

            </div>
          </Modal>
        )}
      />
    );
  }

  handleSearchChange = (value: string) => this.setState({
    searchTerm: value || '',
  });

  handleItemSelectionClick = (item: Object) => {
    const isChecked = get(this.state.selectedServices, String(item.id), false);
    this.handleItemSelectionChange(item.id, item.price)({ value: !isChecked, name: '' });
  }

  onInputClick = (event: Object) => {
    event.stopPropagation();
  };

  handleItemSelectionChange = (id: number, price: number) => (data: Object) =>
    this.setState({
      selectedServices: {
        ...this.state.selectedServices,
        [id]: data.value,
      },
    },
    () => {
      if (!data.value) {
        this.handleItemCustomPriceChange(id)({ name: String(id), value: String(price) });
      }
      this.handleConfirmation();
    });

  handleItemCustomPriceChange = (id: number) => (data: FormElementData) => this.setState({
    customPrices: {
      ...this.state.customPrices,
      [id]: String(data.value).replace(/[^0-9.]/g, ''),
    },
  });

  handleConfirmation = () => this.props.onSetConfirmation(Object.keys(this.state.selectedServices).length > 0);

  handleFormSave = () => {
    const { selectedServices, customPrices } = this.state;
    const { serviceTemplates, onSaveTemplates } = this.props;

    const services = Object.keys(this.state.selectedServices)
      .filter(key => selectedServices[key] === true)
      .map(id => {
        const serviceTemplate = get(serviceTemplates, ['data', String(id)], {});
        const itemPrice = get(customPrices, String(id), get(serviceTemplate, 'price', 0));

        return serviceTemplate ? {
          templateId: get(serviceTemplate, 'id', null),
          price: itemPrice,
        } : null;
      })
      .filter(item => item !== null);
    onSaveTemplates(services);
  };

  handleCreateService = () => {
    this.props.onCreateService(this.state.searchTerm);
  };

  handleTogglePrivateVisibility = () => {
    this.setState(s => ({
      privateVisible: !s.privateVisible,
    }));
  };

  handleToggleHealthInsuranceVisibility = () => {
    this.setState(s => ({
      healthInsuranceVisible: !s.healthInsuranceVisible,
    }));
  };
}

export default withTranslation()(ServicesNewListModal);

