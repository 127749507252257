// @flow
import React from 'react';
import { connect } from 'react-redux';
import { WorkHours as WorkHoursview } from '@views';
import type { User, Doctor, WorkHours as WorkHoursType, BundledPermissions } from '@types';
import { get } from 'lodash';
import { createDoctorWorkHours, deleteDoctorWorkHours, resetWorkhoursFormState, logout } from '@context/user';
import { mapBundledPermission } from '@helpers/connect';

type Props = {
  user: User,
  doctor: Doctor,
  dispatch: Function,
  clinicId: number,
  doctorId: number,
  deletingIds: Object,
  futureWorkHours: Array<WorkHoursType>,
  permissions: BundledPermissions,
};

class WorkHours extends React.Component<Props> {
  componentDidMount() {
    this.props.dispatch(resetWorkhoursFormState());
  }
  render() {
    const {
      user, doctor, deletingIds, futureWorkHours,
    } = this.props;

    return (
      <WorkHoursview
        user={user}
        doctor={doctor}
        onAdd={this.handleOnAdd}
        onDelete={this.handleOnDelete}
        deletingIds={deletingIds}
        futureWorkHours={futureWorkHours}
        onLogout={this.handleLogout}
        permissions={this.props.permissions}
      />
    );
  }

  handleLogout = () => this.props.dispatch(logout());

  handleOnAdd = (data: WorkHoursType) => {
    const { dispatch, doctorId, clinicId } = this.props;
    dispatch(createDoctorWorkHours(clinicId, doctorId, data));
  };


  handleOnDelete = (id: number) => {
    const { dispatch, doctorId, clinicId } = this.props;
    dispatch(deleteDoctorWorkHours(clinicId, doctorId, id));
  };
}

const getDoctorID = (ownProps: Object) => get(ownProps, ['match', 'params', 'id']);
const getDoctor = (state: Object, ownProps: Object) => get(state, ['context', 'doctors', 'data', getDoctorID(ownProps)]);

const mapStateToProps = (state, ownProps) => ({
  user: state.context.user,
  deletingIds: get(state, ['context', 'doctors', 'deletingWorkHours', getDoctorID(ownProps)], {}),
  futureWorkHours: get(state, ['context', 'doctors', 'futureWorkHours', getDoctorID(ownProps)], {}),
  doctor: getDoctor(state, ownProps),
  doctorId: get(getDoctor(state, ownProps), 'id', null),
  clinicId: get(state, ['context', 'clinics', 'activeClinic', 'id'], null),
  permissions: mapBundledPermission(state),
});

export default connect<any, any, any, any, any, any>(
  mapStateToProps,
)(WorkHours);
