// @flow
import React from 'react';
import { withTranslation } from 'react-i18next';
import { createNsTranslator, fsPrintf } from '@helpers/helpers';
import { redirectToDashboard } from '@context/user';
import { Button } from '@elements';
import moment from 'moment';
import RenderHTML from 'html-react-parser';
import type { User } from '@types';
import './PaymentDetails.scss';

type Props = {
    accessToken: string,
    user: User,
    sbInfo: Object,
    isLoading: boolean,
    t: Function,
}

class PaymentDetails extends React.Component <Props> {
  render() {
    const { sbInfo, isLoading } = this.props;
    const _ = createNsTranslator('my_plan', this.props.t);

    let paymentMethod = null;

    if (sbInfo.paymentMethod === 'credit_card') {
      paymentMethod = (
        <div className="row">
          <div className="info">
            <span className="subtitle">{_('label/payment_method')}</span>
            <span className="value">
              <span className={`cc-image ${sbInfo.cardType || 'card-placeholder'}`} />
              <span>{_('label/credit_card')}</span>
              <span className="digits">{sbInfo.creditCard}</span>
            </span>
          </div>
          <div className="action">
            <Button text={_('action/change')} onClick={this.onCreditCardButtonClick} />
          </div>
        </div>
      );
    } else if (sbInfo.paymentMethod === 'boleto') {
      paymentMethod = (
        <div className="row">
          <div className="info">
            <span className="subtitle">{_('label/payment_method')}</span>
            <span className="value">
              <span>{_('label/boleto')}</span>
            </span>
          </div>
          <div className="action">
            {/* <Button text={_('action/change')} linkTo="/pagamento/metodo?backTo=/ajustes/meu-plano" /> */}
            <Button text={_('action/change')} onClick={this.onCreditCardButtonClick} />
          </div>
          <span className="message">{_('label/boleto_message_days')}</span>
        </div>
      );
    } else if (sbInfo.paymentMethod === 'stripe') {
      paymentMethod = (
        <div className="row">
          <div className="info">
            <span className="subtitle">{_('label/payment_method')}</span>
            <span className="value">
              <span>{_('label/special')}</span>
            </span>
          </div>
          <div className="action">
            <Button className="btn" text={_('action/fix_payment_info_problems_edit')} intercom />
          </div>
        </div>
      );
    } else {
      paymentMethod = (
        <div className="row">
          <div className="info">
            <span className="subtitle">{_('label/payment_method')}</span>
            <span className="value">
              <span>{_('label/special')}</span>
            </span>
          </div>
          <div className="action">
            <Button text={_('action/change')} onClick={this.onCreditCardButtonClick} />
          </div>
        </div>
      );
    }
    return (
      <div id="paymentDetails">
        <div className="plan-summary">
          <div className="row">
            <div className="info">
              <h2 className="value">
                {_('label/plan')} {sbInfo.planName ? sbInfo.planName : ''} {sbInfo.planName ? '-' : ''} { sbInfo.price ? String(sbInfo.price) : ''}
              </h2>
              {sbInfo.paymentPeriod === 'month' && (
              <span className="deadline">
                {_('label/summary_text', null, fsPrintf(sbInfo.subscribedAtDate, moment.utc(sbInfo.nextPaymentDay).format('DD')))}
              </span>
              )}
              {sbInfo.paymentPeriod === 'year' && (
              <span className="deadline">
                {_('label/summary_text_year', null, fsPrintf(sbInfo.subscribedAtDate, moment.utc(sbInfo.nextPaymentDay).format('DD/MM/YYYY')))}
              </span>
              )}
              <div className="deadline">
                {RenderHTML(sbInfo.dealSummary || '')}
              </div>
            </div>
            {!isLoading && (
              <div className="actions">
                {sbInfo.smsActivated && (
                  <Button text={_('action/cancel_sms_plan')} onClick={this.onSupportButtonClick} />
                )}
                {!sbInfo.smsActivated && (
                  <Button text={_('action/request_sms_plan')} onClick={this.onSupportButtonClick} />
                )}
                <Button text={_('action/change_plan')} onClick={this.onSupportButtonClick} />
                <a className="btn default" href={`https://beta.doutore.com/cancelar_assinatura?email=${this.props.user.email}&user_id=${this.props.user.id}`} target="_blank">
                  {_('action/cancel_plan')}
                </a>
              </div>
            )}
          </div>
          {paymentMethod}
          {sbInfo.paymentInfoError && (
            <div className="row">
              <div className="info infoFix">
                <span className="subtitle danger">{_('action/fix_payment_info_problems_label')}</span>
                <span className="value danger">
                  {_('action/fix_payment_info_problems')}
                </span>
              </div>
              <div className="action">
                <Button className="btn deleteButtonForm" text={_('action/fix_payment_info_problems_edit')} linkTo="/pagamento/endereco?backTo=/ajustes/meu-plano" />
              </div>
            </div>
          )}
          {sbInfo.smsActivated && (
            <div className="row">
              <div className="info">
                <span className="subtitle">{_('label/sms_title')}</span>
                <span className="value">
                  <span>{_('label/sms_credits_2')}: </span>
                  <span className="digits">{sbInfo.smsesLeftOver}</span>
                </span>
              </div>
              <div className="action">
                <Button text={_('action/details')} onClick={this.onSupportButtonClick} />
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }

  onSupportButtonClick = () => {
    if (window.Intercom) {
      window.Intercom('show');
    }
  };

  onCreditCardButtonClick = () => {
    const { accessToken } = this.props;

    const url = '/customer';

    redirectToDashboard(url, accessToken);
  };
}

export default withTranslation()(PaymentDetails);
