// @flow
import React from 'react';
import {
  keys, get, map,
  concat, includes, without, toLower, filter, size,
} from 'lodash';
import moment from 'moment';
import { withRouter } from 'react-router-dom';
import { router } from '@router';
import classnames from 'classnames';
import {
  Button, Icon, Toggle,
  Modal, Spinner,
  ClickableList,
  SearchInput, TextArea,
} from '@elements';
import type { DrugsPrescription, DrugsPrescriptionsTemplatesState, FormElementData } from '@types';
import { sort, alphabeticCompare, createNsTranslator, UserNotifications } from '@helpers/helpers';
import { withTranslation } from 'react-i18next';
import { ViewPrintSettings, PlatformSwitch } from '@blocks';
import './PrescriptionDrugsFormModal.scss';

type Props = {
  recordId: number,
  patientName: string,
  drugsPrescription: DrugsPrescription,
  drugsPrescriptionsTemplates: DrugsPrescriptionsTemplatesState,
  onClose: Function,
  onAddNewDrugsPrescription: Function,
  onAddNewAndPrintDrugsPrescription: Function,
  onUpdateDrugsPrescription: Function,
  onUpdateAndPrintDrugsPrescription: Function,
  onDeleteDrugsPrescription: Function,
  onClose: Function,
  history: Object,
  t: Function,
  isSaving: boolean,
  isDeleting: boolean,
};

type State = {
  notes: string,
  controlledDrugs: boolean,
  showDate: boolean,
  errors: Array<string>,
  searchTerm: string;
};

type Item = {
  name: string,
  type: string,
  value: number,
};

const filterSearch = (itens, searchTerm) => {
  const search = toLower(searchTerm).normalize('NFD').replace(/[\u0300-\u036f]/g, '');

  const searchPredicate = item => includes(toLower(get(item, ['customRendererData', 'name'])).normalize('NFD').replace(/[\u0300-\u036f]/g, ''), search);
  return search && String(search).trim().length > 0 ? filter(itens, searchPredicate) : itens;
};

class PrescriptionDrugsFormModal extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    const { drugsPrescription } = this.props;
    this.state = {
      notes: drugsPrescription.notes || '',
      controlledDrugs: drugsPrescription.controlledDrugs || false,
      showDate: drugsPrescription.showDate || false,
      errors: [],
      searchTerm: '',
    };
  }

  render() {
    const {
      patientName, drugsPrescription, drugsPrescriptionsTemplates, isSaving, isDeleting,
    } = this.props;
    const {
      notes, errors,
      controlledDrugs, showDate,
    } = this.state;

    const _ = createNsTranslator('timeline', this.props.t);

    const modalTitle = _('label/prescriptions_drugs');
    const templateIds = keys(drugsPrescriptionsTemplates.data);
    let templateItems = map<any, any>(templateIds, (templateId) => {
      const template = get(drugsPrescriptionsTemplates, ['data', templateId]);
      return {
        customRendererData: {
          name: template.name,
          value: template.id,
          type: 'drugsPrescriptionsTemplate',
        },
      };
    });

    const showSearch = size(templateItems) >= 10 && !drugsPrescription.id;

    templateItems = sort(filterSearch(templateItems, this.state.searchTerm), alphabeticCompare('name'));


    const renderer = (item) => (
      <div>
        <span>{item.name}</span>
        <Icon iconName="arrow-right" className="icon" />
      </div>
    );
    const templatesLoading = get(drugsPrescriptionsTemplates, ['isLoading']);
    const noTemplates = !templateItems.length;


    const renderContent = (isMobile: boolean) => () => (
      <Modal id="newPrescriptionModal" className={classnames('fullScreen', { asView: isMobile, isMobile })} onClose={this.onClose}>
        {isMobile && (
          <div className="modalHeader">
            <Button onClick={this.onClose} disabled={isSaving || isDeleting}>
              <Icon iconName="close" className="icon" />
            </Button>
            <span className="title">{modalTitle}</span>
            { drugsPrescription.id && (
              <Button className="primary" onClick={this.onDeleteDrugsPrescription} disabled={isSaving || isDeleting}>
                <Icon
                  iconName="delete"
                  className="icon"
                />
              </Button>
            )}
            <Button className="primary" text="Salvar" onClick={this.onSaveDrugsPrescription.bind(this, 'save')} disabled={isDeleting} isLoading={isSaving} />
          </div>
        )}
        <div className="modalGrid">
          {!isMobile && (
            <aside className="modalSidebar">
              <span className="sidebarTitle">{modalTitle}</span>
              {!drugsPrescription.id &&
                <ul className="sidebarNav">
                  <li className="active">{_('action/new_receipt')}</li>
                </ul>
              }
              {!drugsPrescription.id &&
                <div className="sidebarSubtitle">
                  <span>{_('label/saved_receipts_models')}</span>
                  <span
                    className="editModels"
                    onClick={this.onEditTemplatesClick}
                  >
                    {_('action/edit', 'global')}
                  </span>
                </div>
              }
              {showSearch && <SearchInput onChange={this.handleSearch}/>}
              {!drugsPrescription.id && templatesLoading && noTemplates && (
                <div className='loadingWrapper'>
                  <Spinner text={_('label/loading', 'global')} />
                </div>
              )}
              {!drugsPrescription.id && !noTemplates && (
                <ClickableList
                  items={templateItems}
                  customRenderer={renderer}
                  className="sidebarNav savedModels"
                  onListItemClick={this.onTemplateClick}
                />
              )}
            </aside>
          )}

          <div className="modalContainer">
            <div className={classnames('modalContent', { withSidebar: !isMobile })}>
              <form className="form">
                {showDate &&
                  <div className="row prescribedTo">
                    <span className="label">{_('label/date', 'global')}:</span>
                    <span className="value">{moment().format('DD/MM/YYYY')}</span>
                  </div>
                }
                <div className="row prescribedTo">
                  <span className="label">{_('label/receipt_for')}:</span>
                  <span className="value">{patientName}</span>
                </div>
                <TextArea
                  autoGrow={false}
                  name="notes"
                  className="textAreaHolder"
                  placeholder={_('label/receipt_textarea_placeholder')}
                  onChange={this.handleFormChange}
                  onBlur={this.handleFormBlur}
                  error={includes(errors, 'notes') ? _('error/empty', 'global') : ''}
                  value={notes}
                  disabled={isSaving || isDeleting}
                />
              </form>

              <div className={classnames('modalSidebar', 'modalRightSidebar', { isMobile })}>
                <span className="sidebarTitle">{_('label/print_configs', 'global')}:</span>
                <ul className="sidebarSetup">
                  <li className="toggleHolder">
                    <Toggle
                      name="controlledDrugs"
                      label={_('label/controlled_drugs')}
                      isChecked={controlledDrugs}
                      onChange={this.handleFormChange}
                      disabled={isSaving || isDeleting}
                    />
                  </li>
                  <li className="toggleHolder">
                    <Toggle
                      name="showDate"
                      label={_('label/today_date')}
                      isChecked={showDate}
                      onChange={this.handleFormChange}
                      disabled={isSaving || isDeleting}
                    />
                  </li>
                  <li><ViewPrintSettings /></li>
                </ul>
              </div>
            </div>
            {!isMobile && (
              <div className="modalFooter">
                <div>
                  {drugsPrescription.id && (
                  <Button onClick={this.onDeleteDrugsPrescription} disabled={isSaving || isDeleting}>
                    <Icon iconName="delete" className="icon" />
                    {isDeleting && <Spinner />}
                  </Button>
                  )}
                  <Button
                    text={_('action/cancel', 'global')}
                    onClick={this.onClose}
                    disabled={isSaving || isDeleting}
                  />
                </div>
                <div>
                  <Button
                    text={_('action/save_print', 'global')}
                    onClick={this.onSaveDrugsPrescription.bind(this, 'save and print')}
                    disabled={isDeleting || isSaving}
                  />
                  <Button
                    text={_('action/save', 'global')}
                    className="primary"
                    onClick={this.onSaveDrugsPrescription.bind(this, 'save')}
                    disabled={isDeleting}
                    isLoading={isSaving}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </Modal>
    );

    return (
      <PlatformSwitch
        desktop={renderContent(false)}
        mobile={renderContent(true)}
      />
    );
  }

  handleSearch = (text) => this.setState({ searchTerm: text });

  checkForErrors = (value: (string | number | boolean), fieldName: string) => {
    const { errors } = this.state;

    if (value === '') {
      this.setState({ errors: concat(errors, [fieldName]) });
    } else if (includes(errors, fieldName)) {
      this.setState({ errors: without(errors, fieldName) });
    }
  };

  checkForUnsavedChanges = () => {
    const { drugsPrescription } = this.props;
    const { notes, controlledDrugs, showDate } = this.state;
    let unsavedChanges;

    if (drugsPrescription.id) { // on edit
      if (notes !== drugsPrescription.notes ||
        controlledDrugs !== drugsPrescription.controlledDrugs ||
        showDate !== drugsPrescription.showDate) {
        unsavedChanges = true;
      } else {
        unsavedChanges = false;
      }
    } else if (notes !== '' || controlledDrugs !== false || showDate !== false) { // on create check against default state
      unsavedChanges = true;
    } else {
      unsavedChanges = false;
    }

    return unsavedChanges;
  };

  handleFormChange = (data: FormElementData) => {
    const { name, value } = data;
    this.setState({ [name]: value }, () => this.checkForErrors(value, name));
  };

  handleFormBlur = (data: FormElementData) => {
    const { name, value } = data;
    this.checkForErrors(value, name);
  };

  onEditTemplatesClick = () => {
    const { history, onClose } = this.props;
    history.push(router.getRoutePath('drugsPrescriptionsTemplatesList'));
    onClose();
  };

  onTemplateClick = (item: Item) => {
    const templateId = item.value.toString();
    const template = get(this.props.drugsPrescriptionsTemplates, ['data', templateId]);
    this.setState({
      notes: template.notes || '',
      controlledDrugs: template.controlledDrugs || false,
    }, () => this.checkForErrors(template.notes || '', 'notes'));
  };

  onClose = () => {
    const { onClose } = this.props;
    const unsavedChanges = this.checkForUnsavedChanges();
    if (unsavedChanges) {
      UserNotifications.confirmI18n('alert/confirm_quit_form', null, '#FFA433', true)
        .then((value) => {
          if (value) {
            onClose();
          }
        });
    } else {
      onClose();
    }
  };

  onSaveDrugsPrescription = (option: string) => {
    const {
      recordId,
      drugsPrescription,
      onAddNewDrugsPrescription,
      onUpdateDrugsPrescription,
      onAddNewAndPrintDrugsPrescription,
      onUpdateAndPrintDrugsPrescription,
    } = this.props;
    const { notes, controlledDrugs, showDate } = this.state;

    const data = {
      notes,
      controlledDrugs,
      showDate,
    };
    const errors = [];

    if (notes === '') {
      if (!includes(this.state.errors, 'notes')) {
        this.setState({ errors: concat(errors, ['notes']) });
      }
      errors.push('notes');
    }

    if (!errors.length) {
      if (drugsPrescription.id) { // if we are editing an existing drugs prescription
        switch (option) {
          case 'save and print':
            onUpdateAndPrintDrugsPrescription(recordId, drugsPrescription.id, data);
            break;
          case 'save':
            onUpdateDrugsPrescription(recordId, drugsPrescription.id, data);
            break;
          default:
        }
      } else { // if we are creating a new drugs prescription
        switch (option) {
          case 'save and print':
            onAddNewAndPrintDrugsPrescription(recordId, data);
            break;
          case 'save':
            onAddNewDrugsPrescription(recordId, data);
            break;
          default:
        }
      }
    }
  };

  onDeleteDrugsPrescription = () => {
    const { recordId, drugsPrescription, onDeleteDrugsPrescription } = this.props;

    UserNotifications.confirmI18n('alert/delete/prescription', 'warning', '#FFA433', true)
      .then((value) => {
        if (value) {
          onDeleteDrugsPrescription(recordId, drugsPrescription.id);
        }
      });
  };
}

export default withTranslation()(withRouter(PrescriptionDrugsFormModal));
