// @flow
import React from 'react';
import moment from 'moment';
import { Button, Input, Icon, FormattedText, InputMask } from '@elements';
import { months } from '@constants';
import type { SubscriptionState } from '@types';
import { createValidator } from '@validator';
import { get, first, pick, isNaN } from 'lodash';
import { withForm } from '@hocs';
import cct from 'credit-card-type';
import { withTranslation } from 'react-i18next';
import { createNsTranslator, fSufixTwoPoints, handleSubmitEvent, parseUrlParams } from '@helpers/helpers';
import { PlatformSwitch, AppMobileHeader } from '@blocks';
import classNames from 'classnames';
import './SubscriptionPayment.scss';

type Props = {
  formData: Object,
  touchedFields: Object,
  bindValidation: Function,
  handleFormChange: Function,
  handleTouchField: Function,
  onSave: Function,
  subscription: SubscriptionState,
  t: Function,
};

type State = {
  errors: Object,
  paymentType: string,
};


const creditCardValidator = createValidator()
  .field('name')
  .present(_ => _('error/credit_card_name'))
  .field('number')
  .present(_ => _('error/credit_card_num'))
  .cpf(_ => _('error/cpf'), true)
  .field('exp_month')
  .present(_ => _('error/credit_card_month'))
  .field('exp_year')
  .present(_ => _('error/credit_card_year'))
  .field('cvv')
  .present(_ => _('error/credit_card_cvv'));

const getCardIcon = (number: string) => {
  let cardIcon;
  if (String(number).trim().length >= 4) {
    cardIcon = cct(number);
    if (cardIcon.length > 0) {
      cardIcon = get(cardIcon, '0', {});
      cardIcon = cardIcon.type;
    }
  }
  return cardIcon || null;
};

const toNumOrEmpty = (val: any) => (isNaN(parseInt(val, 10)) ? '' : parseInt(val, 10));

class SubscriptionPayment extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      errors: {},
      paymentType: 'credit_card',
    };
  }

  componentWillMount() {
    this.props.bindValidation(this.handleValidation);
  }


  componentDidMount() {
    if (!window.isCordovaApp) {
      handleSubmitEvent('#subscriptionPaymentSubmitHandler', this.onSave);
    }
  }


  render() {
    const {
      handleFormChange, handleTouchField, formData, subscription,
    } = this.props;

    const _ = createNsTranslator('subscription', this.props.t);

    const { backto } = parseUrlParams(window.location.hash) || {};

    const { errors, paymentType } = this.state;

    const isSaving = get(subscription, 'isSaving', false);

    let cardType;
    if (String(get(formData, 'number', '')).trim().length >= 4) {
      cardType = first(cct(get(formData, 'number', '')));
    }

    const ccvMask = 'ccv' + get(cardType, ['code', 'size'], 4);


    const cardIcon = getCardIcon(get(formData, 'number', ''));

    const years = [];

    for (let i = moment().year(); i <= moment().year() + 10; i++) {
      years.push({ text: i, value: i });
    }

    let planName = '';

    if (String(subscription.selectedPlan).startsWith('concierge')) {
      planName = _('label/plan_concierge');
    } else if (String(subscription.selectedPlan).startsWith('complete')) {
      planName = _('label/plan_complete');
    } else if (String(subscription.selectedPlan).startsWith('basic')) {
      planName = _('label/plan_basic');
    } else if (String(subscription.selectedPlan).startsWith('clinic')) {
      planName = _('label/plan_clinic');
    } else {
      planName = _('label/plan_not_selected');
    }

    const renderContent = (isMobile: boolean) => (
      <div id="paymentPage" className="appContent formPage">
        {isMobile && (
          <AppMobileHeader backLinkTo={backto || '/pagamento/endereco'}>
            <div className="headerTitle ">{_('label/payment_method')}</div>
            <div>&nbsp;</div>
          </AppMobileHeader>
        )}
        <section className="mainSection">
          <div className="centerContent">
            <div className={classNames('desktopCard', { isMobile })}>
              <div className="toolbar">
                {!isMobile && (
                  <h1>{_('label/payment_method')}</h1>
                )}
                <span className="planName">{planName} - <FormattedText type="money" text={get(subscription, 'planValue', '')} />/{_('label/payment_interval')}</span>
              </div>
              <div id="subscriptionPaymentSubmitHandler">
                {paymentType === 'credit_card' &&
                  <div id="ccForm" className="paymentForm">
                    <InputMask
                      type="creditCard"
                      disabled={isSaving}
                      name="number"
                      label={_('label/credit_card_number', null, fSufixTwoPoints)}
                      className={`ccInput ${cardIcon || ''}`}
                      required
                      onChange={handleFormChange}
                      onBlur={handleTouchField}
                      value={get(formData, 'number', '')}
                      error={get(errors, 'number', null)}
                      autofocus
                    />
                    <Input
                      disabled={isSaving}
                      type="text"
                      name="name"
                      label={_('label/credit_card_name', null, fSufixTwoPoints)}
                      required
                      onChange={handleFormChange}
                      onBlur={handleTouchField}
                      value={get(formData, 'name', '')}
                      error={get(errors, 'name', null)}
                    />
                    <div className={classNames('split', { isMobile })}>
                      <InputMask
                        type="mmYY"
                        disabled={isSaving}
                        placeholder={_('placeholder/mmaa')}
                        name="exp"
                        label={_('label/expiration_date', null, fSufixTwoPoints)}
                        required
                        options={months}
                        onChange={handleFormChange}
                        onBlur={handleTouchField}
                        value={get(formData, 'exp_month', '')}
                        error={get(errors, 'exp_month', null) || get(errors, 'exp_year', null)}
                      />
                      <InputMask
                        disabled={isSaving}
                        name="cvv"
                        type={ccvMask}
                        label={_('label/cvv', null, fSufixTwoPoints)}
                        required
                        onChange={handleFormChange}
                        onBlur={handleTouchField}
                        value={get(formData, 'cvv', '')}
                        error={get(errors, 'cvv', null)}
                      />
                    </div>

                    <div className="actionsFooter">
                      <Button className="primary" type="button" text={_('action/subscribe_with_credit_card')} onClick={this.onSave} isLoading={isSaving} loadingMessage={_('label/wait', 'global')} />
                    </div>

                    <div className="securityAdvise">
                      <Icon iconName="secure" size={22} />
                      <div className="text">
                        <strong>{_('label/security_text_1')}</strong>
                        <p>{_('label/security_text_2')}</p>
                      </div>
                    </div>
                  </div>
                }


                {paymentType === 'boleto' &&
                  <div id="boletoForm" className="paymentForm">
                    <div className="title">
                      <Icon iconName="info" size={20} />
                      <span>{_('label/boleto/paymnent_info/title')}</span>
                    </div>

                    <p>{_('label/boleto/paymnent_info/text')}</p>

                    <div className="actionsFooter">
                      <Button className="primary" text={_('action/boleto_signup')} onClick={this.onSave} isLoading={isSaving} loadingMessage={_('label/wait', 'global')} />
                    </div>
                  </div>
                }
                {paymentType === 'bit_coin' &&
                  <div id="bitcoinForm" className="paymentForm">
                    <p>{_('label/bitcoin/paymnent_info/text_1')}</p>
                    <p>{_('label/bitcoin/paymnent_info/text_2')}</p>

                    <div className="actionsFooter">
                      <Button
                        className="primary"
                        text={_('action/contact', 'global')}
                        onClick={this.onShowIntercom}
                      />
                    </div>
                  </div>
                }


              </div>
            </div>

            <div className="regulatoryAdvise">
              <div className="text">
                <p>
                  {_('label/regulatory_text/explanation')}
                  <a href='https://business.ebanx.com/pt-br/legal/ebankers/termos-e-condicoes-gerais/' target='_blank'>{_('label/regulatory_text/terms')}</a>
                  {_('label/regulatory_text/agreement')}
                </p>
              </div>
            </div>

          </div>
        </section>
      </div>
    );

    return (
      <PlatformSwitch
        inject
        desktop={() => renderContent(false)}
        mobile={() => renderContent(true)}
      />
    );
  }

  onSave = () => {
    if (this.state.paymentType === 'boleto') {
      const { onSave } = this.props;
      onSave(this.state.paymentType, {}, null);
    } else if (this.state.paymentType === 'credit_card') {
      this.handleValidation(true).then(valid => {
        const cardIcon = getCardIcon(get(this.props.formData, 'number', ''));

        if (valid && cardIcon) {
          const { onSave } = this.props;

          const [expMonth, expYear] = String(this.props.formData.exp || '').split('/');

          const century: any = moment().format('YYYY').substr(0, 2);


          const formData = {
            ...pick(this.props.formData, ['cvv', 'name', 'number']),
            exp_month: toNumOrEmpty(expMonth),
            exp_year: toNumOrEmpty(`${century}${expYear}`),
          };

          onSave(this.state.paymentType, this.state.paymentType === 'credit_card' ? formData : {}, cardIcon);
        } else if (!cardIcon) {
          this.setState({
            errors: {
              ...this.state.errors,
              number: this.props.t('error/invalid_credit_card'),
            },
          });
        }
      });
    } else if (this.state.paymentType === 'bit_coin') {
      if (window.Intercom) {
        window.Intercom('show');
      }
    }
  };


  handleValidation = (isSubmit: boolean = false): Promise<boolean> => new Promise((resolve: Function) => {
    if (this.state.paymentType === 'credit_card') {
      const { touchedFields } = this.props;

      const [expMonth, expYear] = (this.props.formData.exp || '').split('/');

      const formData = {
        ...pick(this.props.formData, ['cvv', 'name', 'number']),
        exp_month: expMonth,
        exp_year: expYear,
      };


      const result = creditCardValidator.validate(formData, touchedFields, isSubmit);
      this.setState({ errors: result.errors }, () => resolve(result.valid));
    } else {
      this.setState({ errors: {} }, () => resolve(true));
    }
  });

  handleSubmit = (e: Object) => {
    e.preventDefault();
    e.stopPropagation();
    this.onSave();
  };

  handleSelectPaymentType = (paymentType: string) => () => this.setState({ paymentType });

  onShowIntercom = () => {
    if (window.Intercom) {
      window.Intercom('show');
    }
  };
}

export default withTranslation()(withForm()(SubscriptionPayment));
