/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { get } from 'lodash';
import Lightbox from 'react-image-lightbox';
import { Icon, Spinner } from '@elements';
import AttachmentImages from '@blocks/Records/mixins/AttachmentImages';
import { useIsMobile, saveToDeviceDownloadFolder } from '@helpers/helpers';

import './ImageView.scss';

type Props = {
  onClickDelete?: Function,
  onClickDownload?: Function,
  images: Array<{
    id: number,
    thumbUrl: string,
    largeUrl: string,
    name: string,
  }>,
}

const Gallery = ({ images = [], onClickDelete, onClickDownload }: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [imgIndex, setImgIndex] = useState(0);
  const [isSharing, setIsSharing] = useState(false);

  const [isMobile] = useIsMobile();

  const onOpenLightbox = (id) => () => {
    const _imgIndex = images.findIndex(img => img.id === id);

    setImgIndex(_imgIndex);
    setIsOpen(true);
  };

  const onCloseLightbox = () => {
    setIsOpen(false);
  };

  const onClickNextImg = () => {
    setImgIndex(state => state + 1);
  };

  const onClickPrevImg = () => {
    setImgIndex(state => state - 1);
  };

  const onClickDeleteImg = () => {
    if (!onClickDelete) return;

    const imgId = get(images[imgIndex], 'id', '');

    onClickDelete(imgId)();
  };

  const onClickShare = () => {
    setIsSharing(true);
    onClickDownload(get(images[imgIndex], 'id', null), (url: string | null) => {
      if (!url) {
        setIsSharing(false);
        return;
      }

      const onCatch = (e) => {
        // eslint-disable-next-line
          console.error(e);
        setIsSharing(false);
      };
        // eslint-disable-next-line
        const filename = `${(new Date()).getTime()}-${url.replace(/[\#\?].*$/, '').replace(/^.*[\\\/]/, '')}`;
      fetch(url, { method: 'GET' })
        .then(res => res.blob().then((blob) => {
          saveToDeviceDownloadFolder(blob, filename).then(() => {
            setIsSharing(false);
          }).catch(onCatch);
        }).catch(onCatch))
        .catch(onCatch);
    });
  };

  const getToolbarButtons = () => {
    const getDeleteButton = () => {
      if (!onClickDelete) return null;

      return (
        <Icon iconName='delete' className='margin6px' size={23} onClick={onClickDeleteImg} />
      );
    };


    const getSharingButton = () => {
      if (!isMobile || !onClickDownload) return null;

      if (isSharing) return <Spinner className='margin6px' fil="#fff" />;

      return (
        <Icon
          iconName="share"
          className='margin6px'
          size={23}
          onClick={onClickShare}
        />
      );
    };

    const getDownloadButton = () => {
      if (!onClickDownload) return null;

      const downloadUrl = get(images[imgIndex], 'downloadUrl', '');
      const name = get(images[imgIndex], 'name', '');

      return (
        <a href={downloadUrl} download={name} target="_blank">
          <Icon
            iconName='download'
            className='margin6px'
            size={23}
          />
        </a>
      );
    };

    const buttons = [getDeleteButton(), getSharingButton(), getDownloadButton()];



    return buttons.filter(Boolean);
  };

  const mainSrc = get(images, `[${imgIndex}].largeUrl`, '');

  useEffect(() => {
    if (mainSrc) return;

    setIsOpen(false);
  }, [mainSrc]);

  return (
    <>
      <AttachmentImages recordImages={images} openLightBox={onOpenLightbox} />

      {isOpen && (
        <Lightbox
          mainSrc={mainSrc}
          nextSrc={get(images, `[${imgIndex + 1}].largeUrl`, '')}
          prevSrc={get(images, `[${imgIndex - 1}].largeUrl`, '')}
          toolbarButtons={getToolbarButtons()}
          onCloseRequest={onCloseLightbox}
          onMovePrevRequest={onClickPrevImg}
          onMoveNextRequest={onClickNextImg}
        />
      )}
    </>
  );
};

export default Gallery;
