// @flow
import React from 'react';
import moment from 'moment';
import { get, filter, isEmpty, toNumber } from 'lodash';
import { Modal, DayPickerInput, Input, InputMask, Toggle, Button, Icon } from '@elements';
import { FormFooterModal, PlatformSwitch } from '@blocks';
import { withForm } from '@hocs';
import { isPresent, createNsTranslator, fSufixTwoPoints, handleSubmitEvent } from '@helpers/helpers';
import { withTranslation } from 'react-i18next';

type Props = {
  onClose: Function,
  formData: Object,
  touchedFields: Object,
  handleFormChange: Function,
  handleTouchField: Function,
  bindValidation: Function,
  formChange: Function,
  onDelete: Function,
  onSave: Function,
  onSetConfirmOnClose: Function,
  formEdited: Function,
  isNewTemplate: boolean,
  t: Function,
};

type State = {
  errors: { [field: string]: string },
}


class ServiceFormModal extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      errors: {},
    };
    this._ = createNsTranslator('financial', this.props.t);
  }

  componentDidMount() {
    const { bindValidation } = this.props;
    bindValidation(this.handleValidation);
    handleSubmitEvent('#serviceFormModalFormElement', this.handleSave);
  }

  componentWillReceiveProps(next: Props) {
    this._ = createNsTranslator('financial', next.t);
  }

  render() {
    const {
      onClose, handleFormChange, handleTouchField, formData, onDelete,
      isNewTemplate,
    } = this.props;
    const { _ } = this;
    const { errors } = this.state;

    const title = !isNewTemplate ? _('label/edit_service') : _('action/new_service');

    const isHealthInsurance = formData.status === 'health_insurance';

    const date = moment(get(formData, 'date', ''), 'YYYY-MM-DD');

    const content = (
      <React.Fragment>
        <form className="form">
          <Input
            name="name"
            label={_('label/service', 'global', fSufixTwoPoints)}
            value={isNewTemplate ? get(formData, 'name', '') : 'Consulta'}
            onChange={handleFormChange}
            onBlur={handleTouchField}
            disabled={!isNewTemplate}
            error={get(errors, 'name', null)}
            autofocus
          />
          <InputMask
            type="currency"
            name="price"
            label={_('label/value', 'global', fSufixTwoPoints)}
            disabled={isHealthInsurance}
            onChange={this.handleNumbersFormChange}
            onBlur={handleTouchField}
            error={get(errors, 'price', null)}
            value={get(formData, 'price', '')}
          />
          <br />
          {!isNewTemplate &&
            <DayPickerInput
              name="date"
              label={_('label/date', 'global', fSufixTwoPoints)}
              onSelect={handleFormChange}
              onBlur={handleTouchField}
              error={get(errors, 'date', null)}
              disabled={!get(formData, 'allowEditDate', false)}
              date={date}
            />
          }
          &nbsp;
          {!isNewTemplate &&
            <Input
              name="notes"
              label={_('label/notes', 'global', fSufixTwoPoints)}
              onChange={handleFormChange}
              onBlur={handleTouchField}
              error={get(errors, 'notes', null)}
              value={get(formData, 'notes', '')}
            />
          }
          {isNewTemplate &&
            <Toggle
              name="healthInsurance"
              key="toggleSwitch"
              label={_('label/status_health_insurance', 'global')}
              onChange={handleFormChange}
              isChecked={get(formData, 'healthInsurance')}
            />
          }
        </form>
      </React.Fragment>
    );

    return (
      <PlatformSwitch
        inject
        desktop={() => (
          <Modal onClose={onClose}>
            <div className="modalHeader">
              <span className="modalTitle">{title}</span>
            </div>
            <div className="modalGrid">
              <div className="modalContainer">
                <div className="modalContent greyBg" id="serviceFormModalFormElement">
                  {content}
                </div>
                <FormFooterModal
                  onSave={this.handleSave}
                  onCancel={onClose}
                  onDelete={!isNewTemplate ? onDelete : null}
                />
              </div>
            </div>
          </Modal>
        )}
        mobile={() => (
          <Modal id="ServiceFormModal" className="asView" onClose={onClose}>
            <div className="modalHeader">
              <Button onClick={onClose}>
                <Icon iconName="close" className="icon" />
              </Button>
              <span className="title">{title}</span>
              {!isNewTemplate && <Button className="primary" text={_('action/delete', 'global')} onClick={onDelete} />}
              <Button className="primary" text={_('action/save', 'global')} onClick={this.handleSave} />
            </div>
            <div className="modalGrid">
              <div className="modalContainer">
                <div className="modalContent greyBg">
                  {content}
                </div>
              </div>

            </div>
          </Modal>
        )}
      />
    );
  }

  _: Function;

  handleNumbersFormChange = (data) => this.props.formChange(data.name, String(data.value).replace(/[^\d,.-]/g, ''));

  handleValidation = (isSubmit: boolean = false) => new Promise(resolve => {
    const {
      formData, touchedFields, formEdited, onSetConfirmOnClose,
      isNewTemplate,
    } = this.props;
    const price = formData.price === null || formData.price === undefined ? -1 : toNumber(formData.price);
    const errors = {};

    if (isNewTemplate) {
      if (!isPresent(formData.name) && (touchedFields.name || isSubmit)) {
        errors.name = this._('label/error/name');
      }
    }

    if (!isNewTemplate) {
      if (!moment(formData.date).isValid() && (touchedFields.date || isSubmit)) {
        errors.date = this._('label/error/date');
      }
    }

    if (price < 0 && (touchedFields.price || isSubmit)) {
      errors.price = this._('label/error/value');
    }

    onSetConfirmOnClose(formEdited());

    this.setState({ errors }, resolve);
  });

  handleSave = () => this.handleValidation(true).then(() => {
    const { onSave, formData } = this.props;
    if (!this.haveErrors()) {
      onSave({
        ...formData,
        price: toNumber(formData.price) || 0,
      });
    }
  });

  haveErrors = () => filter(this.state.errors, item => !isEmpty(item)).length > 0;
}


const initialData = {
  price: null,
};

export default withForm(initialData)(withTranslation()(ServiceFormModal));
