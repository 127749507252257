import { map } from 'lodash';

function normalizeReceipts (inputReceipts) {
  const receipts = {};

  map(inputReceipts, (receipt) => {
    receipts[receipt.id] = receipt;
  });

  return {
    receipts,
  };
}

export default {
  normalizeReceipts,
};
