// @flow
import { get } from 'lodash';
import { downloadOrShare, isReportDownloadByEmail, reportError } from '@helpers/helpers';
import { Api } from '@helpers';
import { router } from '@router';
import type
{
  Action,
  Dispatch,
  ContextState,
} from '../../../types';
import { CLOSE_ACTIVE_TOAST, OPEN_TOAST } from './toasts';

// ------------------------------------
// Constants
// ------------------------------------
const REPORTS_APPOINTMENTS_LOADING = 'reportsAppointments.REPORTS_APPOINTMENTS_LOADING';
const REPORTS_APPOINTMENTS_LOADED = 'reportsAppointments.REPORTS_APPOINTMENTS_LOADED';
const REPORTS_APPOINTMENTS_DOWNLOADED = 'reportsAppointments.REPORTS_APPOINTMENTS_DOWNLOADED';
const REPORTS_APPOINTMENTS_LOADING_FAILED = 'reportsAppointments.REPORTS_APPOINTMENTS_LOADING_FAILED';

export const getReportsAppointments = (startDate: string, endDate: string, doctorId: number | null) =>
  (dispatch: Dispatch) => {
    const queryFilters = {
      'filter[doctorId]': doctorId,
      'filter[startDate]': startDate,
      'filter[endDate]': endDate,
    };

    dispatch({
      type: REPORTS_APPOINTMENTS_LOADING,
      filters: {
        startDate, endDate, doctorId,
      },
    });
    Api.get(router.getApiRoute('getReportsAppointments', {}), {
      query: queryFilters,
      success: (response) => {
        dispatch({
          type: REPORTS_APPOINTMENTS_LOADED,
          appointments: get(response, ['appointmentsSummary']),
        });
      },
      error: (error) => {
        dispatch({ type: REPORTS_APPOINTMENTS_LOADING_FAILED, error: { message: error.message } });
        dispatch(reportError(error));
      },
    });
  };

export const getReportsAppointmentsDownload = (startDate: string, endDate: string, doctorId: number | null) =>
  (dispatch: Dispatch, getState: Function) => {
    const queryFilters = {
      'filter[doctorId]': doctorId,
      'filter[startDate]': startDate || null,
      'filter[endDate]': endDate || null,
    };

    const userEmail = get(getState(), ['context', 'user', 'email']);
    const isDownload = !isReportDownloadByEmail(getState);
    const route = router.getApiRoute('getReportsAppointmentsDownload', {});


    if (isDownload) {
      downloadOrShare({
        url: route.path,
        state: getState(),
        mime: 'application/vnd.ms-excel',
        fileName: 'report_appointments_{t}.xlsx',
        dispatch,
        params: queryFilters,
      }).then(({ shared }) => {
        if (!shared) {
          dispatch({
            type: CLOSE_ACTIVE_TOAST,
          });
        }
        dispatch({
          type: REPORTS_APPOINTMENTS_DOWNLOADED,
        });
      }).catch((error) => {
        dispatch({
          type: OPEN_TOAST,
          data: {
            type: 'error', text: 'Ocorreu um erro ao processar seu relatório.', close: true, timeout: 10000,
          },
        });
        dispatch({ type: REPORTS_APPOINTMENTS_LOADING_FAILED, error: { message: error.message } });
      });
    } else {
      dispatch({
        type: REPORTS_APPOINTMENTS_LOADING,
        filters: {
          doctorId, startDate, endDate,
        },
      });
      Api.get(route, {
        query: queryFilters,
        success: (response) => {
          dispatch({
            type: OPEN_TOAST,
            data: {
              type: 'success', text: `Relatório enviado para o seu email ${userEmail}`, close: true, timeout: 10000,
            },
          });
          dispatch({
            type: REPORTS_APPOINTMENTS_LOADED,
            appointments: get(response, ['appointmentsSummary']),
          });
        },
        error: (error) => {
          dispatch({
            type: OPEN_TOAST,
            data: {
              type: 'error', text: 'Ocorreu um erro ao processar seu relatório.', close: true, timeout: 10000,
            },
          });
          dispatch({ type: REPORTS_APPOINTMENTS_LOADING_FAILED, error: { message: error.message } });
          dispatch(reportError(error));
        },
      });
    }
  };

// ------------------------------------
// Reducer
// ------------------------------------
export default function reportsAppointmentsReducer (state: ContextState, action: Action): ContextState {
  switch (action.type) {
    case REPORTS_APPOINTMENTS_LOADING:
      return state
        .setIn(['reportsAppointments', 'isLoading'], true)
        .setIn(['reportsAppointments', 'filters'], get(action, 'filters', {}))
        .setIn(['reportsAppointments', 'errors'], null);

    case REPORTS_APPOINTMENTS_LOADED:
      return state
        .setIn(['reportsAppointments', 'isLoading'], false)
        .setIn(['reportsAppointments', 'data'], get(action, 'appointments', {}))
        .setIn(['reportsAppointments', 'errors'], null);

    case REPORTS_APPOINTMENTS_DOWNLOADED:
      return state
        .setIn(['reportsAppointments', 'isLoading'], false)
        .setIn(['reportsAppointments', 'errors'], null);

    case REPORTS_APPOINTMENTS_LOADING_FAILED:
      return state
        .setIn(['reportsAppointments', 'isLoading'], false)
        .setIn(['reportsAppointments', 'errors'], [action.error.message]);

    default:
      return state;
  }
}
