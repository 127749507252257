import React from 'react';
import { Icon } from '@elements';

type Props = {
    visibilityLevel: number,
};

const VisibilityLevelIcon = ({ visibilityLevel }: Props) => {
  if (visibilityLevel === 1) { return <Icon iconName='lock' className='icon' size={18} />; }

  if (visibilityLevel === 2) {
    return <Icon iconName='account-multiple' className='icon' size={18} />;
  }

  if (visibilityLevel === 3) {
    return <Icon iconName='earth' className='icon' size={18} />;
  }

  return null;
};

export default VisibilityLevelIcon;
