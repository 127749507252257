// @flow
import React from 'react';
import { Button } from '@elements';
import { withTranslation } from 'react-i18next';
import { createNsTranslator } from '@helpers/helpers';
import './SubscriptionComplete.scss';

type Props = {
  paymentType: string,
  paymentMethod: string,
  t: Function,
}

class SubscriptionComplete extends React.Component<Props> {
  render() {
    const { paymentType, paymentMethod } = this.props;
    const _ = createNsTranslator('subscription', this.props.t);

    return (
      <div id="successPage" className="appContent formPage">
        <section className="mainSection">
          <div className="centerContent">
            {paymentType === 'credit_card' && paymentMethod !== 'stripe' &&
              <div className="desktopCard">
                <span className="ccArt" />
                <h1>{_('label/credit_card/payment_success_title')}</h1>
                <p>{_('label/credit_card/payment_success_text')}</p>

                <div className="actionsFooter">
                  <Button className="primary" text={_('action/payment_success_back')} linkTo="/pacientes/lista" />
                </div>
              </div>
            }
            {paymentType === 'boleto' && paymentMethod !== 'stripe' &&
              <div className="desktopCard">
                <span className="boletoArt" />
                <h1>{_('label/boleto/payment_success_title')}</h1>
                <p>
                  <span>{_('label/boleto/payment_success_subtitle')}</span>
                </p>

                <p>{_('label/boleto/payment_success_text_0')} <strong>{_('label/boleto/payment_success_text_1')}</strong>.</p>

                <div className="actionsFooter">
                  <Button className="primary" text={_('action/payment_success_back')} linkTo="/pacientes/lista" />
                </div>
              </div>
            }
            {(paymentType !== 'credit_card' && paymentMethod !== 'stripe' && paymentType !== 'boleto') &&
              <div className="desktopCard">
                <div className="actionsFooter">
                  <Button className="primary" text={_('action/back', 'global')} linkTo="/pacientes/lista" />
                </div>
              </div>
            }

            {paymentMethod === 'stripe' &&
            <div className="desktopCard">
              <span className="ccArt" />
              <h1>{_('label/stripe/payment_success_title')}</h1>
              <p>
                <span>{_('label/stripe/payment_success_subtitle')}</span>
              </p>

              <div className="actionsFooterStripe">
                <Button className="default" text={_('action/talk_support')} onClick={this.handleHelpClick} />
                <Button className="primary" text={_('action/payment_success_back')} linkTo="/pacientes/lista" />
              </div>
            </div>
            }
          </div>
        </section>
      </div>
    );
  }

  handleHelpClick = () => {
    if (window.Intercom) {
      window.Intercom('show');
    }
  };
}

export default withTranslation()(SubscriptionComplete);
