import { appControlTypes } from '../modules/appControl';

const updatingAppActions = [
  appControlTypes.UPDATE_APP,
  appControlTypes.RESET_STORE,
];

let actionCount = 0;
const ACTION_THRESHOLD = 100; // Check every 100 actions

function checkStoreSize(dispatch, getState) {
  const store = getState();
  const storeSizeInBytes = new TextEncoder().encode(JSON.stringify(store)).length;
  const storeSizeInMB = storeSizeInBytes / (1024 * 1024);

  if (storeSizeInMB >= 2) {
    dispatch({ type: appControlTypes.UPDATE_APP });
  }
}

export const validateStoreSize = ({ dispatch, getState }) => next => action => {
  // Bypass the store size check for updatingAppActions
  if (updatingAppActions.includes(action.type)) return next(action);

  if (actionCount === 0) {
    // If actionCount is 0, check the store size immediately
    checkStoreSize(dispatch, getState);
    actionCount++; // Increment the counter after checking
  } else {
    actionCount++;
    if (actionCount >= ACTION_THRESHOLD) {
      // Check the store size once the threshold is reached
      checkStoreSize(dispatch, getState);
      actionCount = 1; // Reset the counter to 1
    }
  }

  return next(action);
};
