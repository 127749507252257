// @flow
import React from 'react';
import { get, isEmpty } from 'lodash';
import { FormFooter } from '@blocks';
import { Checkbox, Input, RadioGroup, SelectBox } from '@elements';
import { withForm } from '@hocs';
import { professionCodes } from '@constants';
import { withTranslation } from 'react-i18next';
import { createNsTranslator, fSufixTwoPoints, localizeListItems, handleSubmitEvent } from '@helpers/helpers';
import './UserConfig.scss';

type Props = {
  handleFormChange: Function,
  handleTouchField: Function,
  bindValidation: Function,
  formData: Object,
  touchedFields: Object,
  onUpdateUser: Function,
  isLoading: boolean,
  resetData: Function,
  t: Function,
};

type State = {
  errors: {[key: string]: string},
}


class UserConfig extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      errors: {},
    };
  }
  componentDidMount() {
    this.props.bindValidation(this.onValidation);
    handleSubmitEvent('#userConfigHandler', this.onSave);
  }

  render() {
    const {
      formData, handleFormChange, handleTouchField, isLoading,
      resetData,
    } = this.props;

    const _ = createNsTranslator('settings', this.props.t);

    return (
      <div id="userConfigHandler">
        <div className="toolbar dashed">
          <h1>{_('label/my_profile')}</h1>
          <a href="/#/ajustes/trocar-senha" className="changePassword">{_('label/change_password')}</a>
        </div>

        <Input
          disabled={isLoading}
          name="name"
          label={_('label/name', 'global', fSufixTwoPoints)}
          value={get(formData, 'name', '')}
          error={get(this.state.errors, 'name', null)}
          onChange={handleFormChange}
          onBlur={handleTouchField}
          required
          autofocus
        />
        <Input
          name="email"
          label={_('label/email', 'global', fSufixTwoPoints)}
          disabled
          value={get(formData, 'email', '')}
          error={get(this.state.errors, 'email', null)}
          onBlur={handleTouchField}
        />
        <RadioGroup
          disabled={isLoading}
          name="gender"
          label={_('label/gender', 'global', fSufixTwoPoints)}
          defaultType="gender"
          selectGroupName="gender"
          value={get(formData, 'gender', '')}
          error={get(this.state.errors, 'gender', null)}
          onChange={handleFormChange}
          onBlur={handleTouchField}
        />
        <SelectBox
          disabled={isLoading}
          options={localizeListItems(professionCodes)}
          name="professionCode"
          label={_('label/profession', 'global', fSufixTwoPoints)}
          required
          value={get(formData, 'professionCode', '')}
          error={get(this.state.errors, 'professionCode', null)}
          onChange={handleFormChange}
          onBlur={handleTouchField}
        />


        <Checkbox
          label={_('label/mail_accept_text')}
          name="subscribeEmailList"
          isChecked={get(formData, 'subscribeEmailList', true)}
          onChange={handleFormChange}
        />

        <FormFooter onSave={this.onSave} onCancel={resetData} isLoading={isLoading} cancelLinkTo="/ajustes" />
      </div>
    );
  }

  onSave = () => this.onValidation(true).then(() => {
    const { formData } = this.props;
    if (isEmpty(this.state.errors)) {
      this.props.onUpdateUser({
        name: get(formData, 'name', ''),
        gender: get(formData, 'gender', ''),
        professionCode: get(formData, 'professionCode', ''),
        subscribeEmailList: get(formData, 'subscribeEmailList', true),
      });
    }
  });

  onValidation = (isSave: boolean = false): Promise<void> => new Promise(resolve => {
    const { formData, touchedFields } = this.props;
    const isNotEmptyStr = str => str !== undefined && str !== null && String(str).trim().length > 0;
    const isTouched = field => get(touchedFields, field, false) || isSave;
    const errors = {};

    const _ = createNsTranslator('settings', this.props.t);

    if (isTouched('name') && !isNotEmptyStr(formData.name)) {
      errors.name = _('error/name', 'global');
    }

    if (isTouched('professionCode') && !isNotEmptyStr(formData.professionCode)) {
      errors.professionCode = _('error/profession', 'global');
    }

    this.setState({ errors }, resolve);
  });
}

export default withTranslation()(withForm()(UserConfig));
