// @flow
import React from 'react';
import { get } from 'lodash';
import { withTranslation } from 'react-i18next';
import { redirectToDashboard } from '@context/user';
import { createNsTranslator, fsPrintf } from '@helpers/helpers';
import { Button } from '@elements';
import './SubscriptionWarnings.scss';

type Props = {
  accessToken: string,
  sbInfo: Object,
  t: Function,
  onGetNewBoleto: Function,
  onPayClick: Function,
  onResubscribeClick: Function,
  isRequestingNewBoleto: boolean,
}

class SubscriptionWarnings extends React.Component <Props> {
  render() {
    const {
      sbInfo, onGetNewBoleto, isRequestingNewBoleto, onPayClick, onResubscribeClick,
    } = this.props;
    const _ = createNsTranslator('my_plan', this.props.t);

    let warningContent = null;

    if (sbInfo.warning === 'will_cancel') {
      warningContent = (

        <div className="warning">
          <h1>{_('label/will_cancel/text_line_1')} {sbInfo.nextPaymentDate}</h1>
          <p>{_('label/will_cancel/text_line_2')}</p>
          <p>{_('label/will_cancel/text_line_3')}</p>
          <div className="actions">
            <Button text={_('action/talk_support')} onClick={this.handleHelpClick} className="supportBtn" />
          </div>
        </div>

      );
    } else if (sbInfo.warning === 'cc_past_due') {
      warningContent = (

        <div className="warning">
          <h1>{_('label/cc_past_due/text_line_1')} {sbInfo.nextPaymentDate}</h1>
          <p>{_('label/cc_past_due/text_line_2')}</p>
          <div className="actions">
            <Button text={_('action/updated_credit_card')} className="primary" onClick={this.onCreditCardButtonClick} />
            <Button text={_('action/talk_support')} onClick={this.handleHelpClick} />
          </div>
        </div>

      );
    } else if (sbInfo.warning === 'boleto_active') {
      warningContent = (

        <div className="warning">
          <h1>{_('label/boleto_active/text_line_1', null, fsPrintf(sbInfo.nextPaymentDate))}</h1>
          <p>{_('label/boleto_active/text_line_2', null, fsPrintf(sbInfo.boleto.price, sbInfo.boleto.dueDate))}</p>
          <p>{_('label/boleto_active/text_line_3')}</p>
          <div className="actions">
            <a href={get(this.props.sbInfo, ['boleto', 'url'])} target="_blank" className="btn primary" >
              {_('action/view_boleto')}
            </a>
            <Button text={_('action/talk_support')} onClick={this.handleHelpClick} />
          </div>
        </div>

      );
    } else if (sbInfo.warning === 'canceled') {
      warningContent = (
        <div className="warning">
          <h1>{_('label/canceled/text_line_1', null)}</h1>
          <p>{_('label/canceled/text_line_2', null)}</p>
          <div className="actions">
            <Button text={_('action/subscribe_again')} onClick={onResubscribeClick} className="primary" />
            <Button text={_('action/talk_support')} onClick={this.handleHelpClick} />
          </div>
        </div>

      );
    } else if (sbInfo.warning === 'expired') {
      warningContent = (
        <div className="warning">
          <h1>{_('label/expired/text_line_1', null)}</h1>
          <p>{_('label/expired/text_line_2', null, fsPrintf(sbInfo.nextPaymentDate))}</p>
          <div className="actions">
            <Button text={_('action/pay')} onClick={onPayClick} className="primary" />
            <Button text={_('action/talk_support')} onClick={this.handleHelpClick} />
          </div>
        </div>

      );
    } else if (sbInfo.warning === 'boleto_active_expired_charge') {
      warningContent = (

        <div className="warning">
          <h1>{_('label/boleto_active_expired_charge/title', null, fsPrintf(sbInfo.nextPaymentDate))}</h1>
          <hr />
          <p>{_('label/boleto_active_expired_charge/text_line_1', null, fsPrintf(sbInfo.boleto.price, sbInfo.boleto.dueDate))}</p>
          <br />
          <p>{_('label/boleto_active_expired_charge/text_line_2')}</p>
          <br />
          <p>{_('label/boleto_active_expired_charge/text_line_3')}</p>
          <div className="actions">
            <Button
              text={_('action/request_new_boleto')}
              isLoading={isRequestingNewBoleto}
              onClick={onGetNewBoleto}
              className="primary"
            />
            <Button text={_('action/talk_support')} onClick={this.handleHelpClick} />
          </div>
        </div>

      );
    } else if (sbInfo.warning === 'boleto_past_due_expired_charge') {
      warningContent = (

        <div className="warning">
          <h1>{_('label/boleto_past_due_expired_charge/title', null, fsPrintf(sbInfo.nextPaymentDate))}</h1>
          <hr />
          <p>{_('label/boleto_past_due_expired_charge/text_line_1', null, fsPrintf(sbInfo.boleto.dueDate))}</p>
          <br />
          <p>{_('label/boleto_past_due_expired_charge/text_line_2')}</p>
          <br />
          <p>{_('label/boleto_past_due_expired_charge/text_line_3')}</p>
          <div className="actions">
            <Button
              text={_('action/request_new_boleto')}
              isLoading={isRequestingNewBoleto}
              onClick={onGetNewBoleto}
              className="primary"
            />
            <Button text={_('action/talk_support')} onClick={this.handleHelpClick} />
          </div>
        </div>

      );
    } else if (sbInfo.warning === 'boleto_past_due') {
      warningContent = (

        <div className="warning">
          <h1>{_('label/boleto_past_due/title', null, fsPrintf(sbInfo.nextPaymentDate))}</h1>
          <hr />
          <p>{_('label/boleto_past_due/text_line_1', null, fsPrintf(sbInfo.boleto.price, sbInfo.boleto.dueDate))}</p>
          <br />
          <p>{_('label/boleto_past_due/text_line_2')}</p>
          <div className="actions">
            <a href={get(this.props.sbInfo, ['boleto', 'url'])} target="_blank" className="btn primary" >
              {_('action/view_boleto')}
            </a>
            <Button text={_('action/talk_support')} onClick={this.handleHelpClick} />
          </div>
        </div>

      );
    }


    return (
      <div id="subscriptionWarnings">
        {warningContent}
      </div>
    );
  }

  handleHelpClick = () => {
    if (window.Intercom) {
      window.Intercom('show');
    }
  };

  onCreditCardButtonClick = () => {
    const { accessToken } = this.props;

    const url = '/customer?new_card=t';

    redirectToDashboard(url, accessToken);
  };
}

export default withTranslation()(SubscriptionWarnings);
