// @flow
import Immutable from 'seamless-immutable';
import type {
  Action,
  UiState,
  Dispatch,
} from '../../../types';

// ------------------------------------
// Constants
// ------------------------------------
const UPDATE_WINDOW_SIZE = 'ui.UPDATE_WINDOW_SIZE';

// ------------------------------------
// Actions
// ------------------------------------
export const updateWindowSize = (width: number, height: number) => (
  (dispatch: Dispatch) => {
    dispatch({ type: UPDATE_WINDOW_SIZE, windowSize: { width, height } });
  }
);

export const windowActions = {
  updateWindowSize,
};

// ------------------------------------
// Reducer
// ------------------------------------
export default function modalsReducer (state: UiState = Immutable({}), action: Action): UiState {
  switch (action.type) {
    case UPDATE_WINDOW_SIZE:
      return state
        .set('window', action.windowSize);
    default:
      return state;
  }
}
