// @flow
import React from 'react';
import { ReportsList as ReportsListView } from '../components/views';

export default class ReportsList extends React.Component<void> {
  render() {
    return (
      <ReportsListView />
    );
  }
}
