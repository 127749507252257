// @flow
import React from 'react';
import { isFunction } from 'lodash';
import classnames from 'classnames';
import { Icon, Button } from '../../elements';
import './EmptyState.scss';

type Props = {
  title: string | Function,
  hint?: string | Function,
  icon?: string,
  className?: string,
  padding?: number,
  onReportFilter?: Function,
  button?: any,
};

export default class EmptyState extends React.Component<Props> {
  render() {
    const {
      title, hint, icon,
      className, padding, onReportFilter,
      button,
    } = this.props;

    const cssClasses = classnames(['emptyState', className]);
    const hintContent = isFunction(hint) ? hint() : hint;
    const titleContent = isFunction(title) ? title() : title;

    return (
      <div
        className={cssClasses}
        style={{
          ...(padding && padding > 0 ? { padding } : {}),
        }}
      >
        <div className="emptyStatecontent">
          {icon &&
            <Icon iconName={icon} size={90} />
          }
          <h1>{ titleContent }</h1>
          <p>
            { hintContent }
          </p>
          {!button && onReportFilter && window.isCordovaApp && (
            <div onClick={onReportFilter} className="reportFilterBtn">
              <Button>
                <Icon iconName="filter" />&nbsp;Filtros
              </Button>
            </div>
          )}

          {button && (
            <div className="reportFilterBtn">
              {button}
            </div>
          )}
        </div>

      </div>
    );
  }
}
