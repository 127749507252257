// @flow
import { useMemo, useEffect } from 'react';
import { get } from 'lodash';

const colors = {
  default: '#26363E',
  white: '#FFFFFF',
};

type Props = {
    color: 'default' | 'white',
    prevColor: 'default' | 'white',
};

const AppStatusBar = ({ color, prevColor }: Props) => {
  const colorVal = useMemo(() => get(colors, color, colors.default), [color]);
  const prevColorVal = useMemo(() => get(colors, prevColor, colors.default), [prevColor]);
  useEffect(() => {
    if (window.StatusBar) {
      window.StatusBar.overlaysWebView(false);
      window.StatusBar.backgroundColorByHexString(colorVal);
    }

    return () => {
      if (window.StatusBar) {
        window.StatusBar.overlaysWebView(false);
        window.StatusBar.backgroundColorByHexString(prevColorVal);
      }
    };
  }, [colorVal, prevColorVal]);

  return null;
};

export default AppStatusBar;
