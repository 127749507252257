// @flow
import type { Action, ContextState } from '../../../types';

// ------------------------------------
// Constants
// ------------------------------------
const LAYOUT_SET_DEVICE_ORIENTATION = 'layout.LAYOUT_SET_DEVICE_ORIENTATION';
const LAYOUT_SET_WINDOW_SIZE = 'layout.LAYOUT_SET_WINDOW_SIZE';
// ------------------------------------
// Actions
// ------------------------------------
export const setDeviceOritentation = (orientation: 'landscape' | 'portrait') => ({
  type: LAYOUT_SET_DEVICE_ORIENTATION,
  orientation,
});

export const updateWindowSize = (width: number, height: number) => ({
  type: LAYOUT_SET_WINDOW_SIZE,
  windowSize: { width, height },
});

export const layoutActions = {
  setDeviceOritentation,
  updateWindowSize,
};

// ------------------------------------
// Reducer
// ------------------------------------
export default function layoutReducer (state: ContextState, action: Action): ContextState {
  switch (action.type) {
    case LAYOUT_SET_DEVICE_ORIENTATION:
      return state
        .setIn(['layout', 'orientation'], action.orientation);
    case LAYOUT_SET_WINDOW_SIZE:
      return state
        .setIn(['layout', 'window'], action.windowSize);
    default:
      return state;
  }
}
