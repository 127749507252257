// @flow
import React from 'react';
import { connect } from 'react-redux';
import type { Node } from 'react';
import { get } from 'lodash';
import { mapPlanIncludes } from '@helpers/connect';
import { PatientSidebar } from '../components/blocks';
import { patientActions } from '../redux/modules/context/patients';
import { actions as modalActions } from '../redux/modules/context/modals';
import type { Patient, PatientPrifileImageType, Dispatch, PlanInclude } from '../types';

type Props = {
  children: Node,
  patient: Patient,
  dispatch: Function,
  planInclude: PlanInclude,
};

class PatientLayout extends React.Component<Props> {
  render() {
    const { patient, planInclude } = this.props;

    return (
      <React.Fragment>
        <PatientSidebar
          patient={patient}
          onSelectPatientProfileImage={this.handleSelectPatientProfileImage}
          onCaptureProfileImage={this.handleCaptureProfileImage}
          onDeletePatientProfileImage={this.handleDeletePatientProfileImage}
          isFinatialEnabled={planInclude.financial}
        />
        {this.props.children}
      </React.Fragment>
    );
  }

  handleSelectPatientProfileImage = (file: PatientPrifileImageType) => {
    const patientId = get(this.props, ['patient', 'id']);
    this.props.dispatch(patientActions.uploadPatientProfileImage(patientId, file));
  };

  handleCaptureProfileImage = () => {
    const data = {
      patientId: get(this.props, ['patient', 'id']),
    };
    this.props.dispatch(modalActions.openModal('ProfileImageCaptureModal', data));
  };

  handleDeletePatientProfileImage = () => {
    const patientId = get(this.props, ['patient', 'id']);
    const imageId = get(this.props, ['patient', 'profileImage', 'id']);
    this.props.dispatch(patientActions.deletePatientProfileImage(patientId, imageId));
  };
}

const mapStateToProps = (state) => ({
  planInclude: mapPlanIncludes(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({ dispatch });

export default connect<any, any, any, any, any, any>(
  mapStateToProps,
  mapDispatchToProps,
)(PatientLayout);
