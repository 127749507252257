// @flow
import React from 'react';
import classnames from 'classnames';
import { BlockedFeature } from '../';

type Props = {
  className?: string,
  enabled?: boolean;
  children?: any,
  transparent?: boolean,
};

export default class ConditionalPermission extends React.Component<Props> {
  render() {
    const {
      className, enabled, children, transparent,
    } = this.props;

    const cssClasses = classnames([className, transparent ? 'blockedFeatureTransparent' : null]);

    if (!enabled) {
      return <BlockedFeature customMessage="Você não possui permissão para executar esta ação !" className={cssClasses} hideUpgradeButton />;
    }

    return React.Children.map(children, item => item);
  }
}
