// @flow
import React from 'react';
import moment from 'moment';
import { PlatformSwitch } from '@blocks';
import { Spinner } from '@elements';
import type { Record } from '@types';
import { useTranslationNs, handleMomenti18n } from '@helpers/helpers';

type Props = {
  record: Record,
  actualDate?: string,
  initialDate?: string,
  fromMigration?: boolean,
  isDeleting?: boolean,
};

const dateFormat = (record: Record, _: Function) => (record.appointmentData ?
  `${_('format/date_01_at', 'global')} ${record.appointmentData.time}` :
  _('format/date_01', 'global'));

const RecordDateHeader = ({
  record, actualDate, initialDate, fromMigration, isDeleting,
}: Props) => {
  const [_, i18n] = useTranslationNs('timeline');
  handleMomenti18n(i18n, moment);

  return (
    <PlatformSwitch
      desktop={() => (
        <div className='date'>
          {!isDeleting && (
            <React.Fragment>
              <div className='actual'>{`${moment.utc(record.date).format(dateFormat(record, _))}`}</div>
              {(actualDate !== undefined && actualDate !== initialDate && !record.appointmentData && !fromMigration) && (
                <div className='initial'>{`${_('label/record_created_in')} ${initialDate || ''}`}</div>
              )}
              {fromMigration &&
              <div className='initial'>{_('label/migration_data_label')}</div>
            }
            </React.Fragment>
          )}
          {isDeleting && <Spinner text={_('label/deleting', 'global')} />}
        </div>
      )}
      mobile={() => (
        <div className='date'>
          {!isDeleting && (
            <React.Fragment>
              <div className='actual'>{`${moment.utc(record.date).format(dateFormat(record, _))}`}</div>
              {(actualDate !== undefined && actualDate !== initialDate && !record.appointmentData) && (
                <div className='initial'>{`${_('label/record_created_in')} ${moment.utc(record.createdAt).format('DD/MM/YYYY')}`}</div>
              )}
            </React.Fragment>
          )}
          {isDeleting && <Spinner text={_('label/deleting', 'global')} />}
        </div>
      )}
    />
  );
};

export default RecordDateHeader;
