// @flow
import React from 'react';
import { get, map, findIndex, size, find } from 'lodash';
import { Icon, ClickableList, DoctorColorIcon } from '@elements';
import type { DoctorsState, TagTemplateState } from '@types';
import { sort, alphabeticCompare, createNsTranslator, localizeListItems, translateConstant } from '@helpers/helpers';
import { professionCodes } from '@constants';
import { withTranslation } from 'react-i18next';
import './PatientsFilters.scss';

type Props = {
  activeFilter: ?{
    filterBy: string,
    filterValue: string | number,
    listLabel: string,
  },
  doctors: ?DoctorsState,
  onSelectFilter: Function,
  tagTemplates: TagTemplateState,
  orderDoctorsByProfession: boolean,
  patientsListFilterDoctor: boolean,
  t: Function,
};


const createProfessionTile = code => ({
  type: 'heading',
  text: get(find(localizeListItems(translateConstant(professionCodes)), it => it.value === parseInt(code, 10)), 'text', 'unknown'),
});

const sidebarItens = (doctorNames, tags, singleDoctor, _: Function) => [
  {
    key: 'todos',
    text: _('action/all_patients', 'global'),
  },
  ...(singleDoctor ? [] : [
    {
      key: 'doutor',
      type: 'heading',
      text: _('label/doctors', 'global'),
    },
    ...doctorNames,
  ]),
  {
    key: 'status',
    type: 'heading',
    text: _('label/status', 'global'),
  },
  {
    key: 'birthday',
    customRendererData: {
      icon: 'birthday',
      type: 'bornInMonth',
      text: _('label/born_in_month'),
    },
  },
  {
    key: 'calendar',
    customRendererData: {
      icon: 'calendar',
      type: 'lastAppointmentTime',
      text: _('label/recently_served'),
    },
  },
  {
    key: 'folder',
    customRendererData: {
      icon: 'folder',
      type: 'deleted',
      text: _('label/deleted_patients'),
    },
  },
  {
    key: 'etiqueta',
    type: 'heading',
    text: _('label/tag', 'global'),
  },
  ...(map(tags, item => ({
    key: item.id,
    customRendererData: {
      icon: 'label',
      type: 'tagIds',
      value: item.id, // HC id
      text: item.name,
    },
  }))),
];

class PatientsFilters extends React.Component<Props> {
  render() {
    const {
      doctors, tagTemplates, orderDoctorsByProfession, patientsListFilterDoctor,
    } = this.props;


    const _ = createNsTranslator('patient_list', this.props.t);

    const tagTemplatesData = sort(get(tagTemplates, 'data', {}), alphabeticCompare('name'));

    // Guard against potential null values
    if (!doctors) return false;

    const doctorData: any = patientsListFilterDoctor ? sort(get(doctors, 'data', []), alphabeticCompare('name')) : [];

    const singleDoctor = size(doctorData) <= 1;

    let doctorNames = [{ text: 'Loading...' }];


    if (!doctors.isLoading && !orderDoctorsByProfession) {
      doctorNames = map<any, any>(doctorData, (doctor: any) => ({
        key: doctor.id,
        customRendererData: {
          color: doctor.color,
          key: doctor.id,
          // icon: 'doctor',
          type: 'doctorId',
          text: doctor.nameWithTitle,
          name: doctor.name,
          value: doctor.id,
        },
      }));
    } else if (!doctors.isLoading && orderDoctorsByProfession) {
      const professionGroups = {};
      const items = [];

      const mapDoctorItems = doctorsItems => map(doctorsItems, doctor => ({
        key: doctor.id,
        customRendererData: {
          key: doctor.id,
          color: doctor.color,
          // icon: 'circle',
          type: 'doctorId',
          text: doctor.nameWithTitle,
          name: doctor.name,
          value: doctor.id,
        },
      }));

      map(doctorData, doctor => {
        const professionCode = get(doctor, 'professionCode', 'default');
        const profs = get(professionGroups, professionCode, []);
        profs.push(doctor);
        professionGroups[professionCode] = profs;
      });

      const defaultGroup = get(professionGroups, 'default', []);
      let listItems = mapDoctorItems(sort(defaultGroup, alphabeticCompare('name')));
      map(listItems, it => items.push(it));

      map(professionGroups, (professionGroup, code) => {
        if (professionGroup.length > 0 && code !== 'default') {
          items.push(createProfessionTile(code));
          listItems = mapDoctorItems(sort(professionGroup, alphabeticCompare('name')));
          map(listItems, it => items.push(it));
        }
      });
      doctorNames = items;
    }

    const sbItems = sidebarItens(doctorNames, tagTemplatesData, singleDoctor, _);

    const activeIndex = this.getActiveItemIndex(sbItems);


    const renderer = (item, key) => [
      item.icon ? <Icon key={`icon-${key}`} iconName={item.icon} className={item.className} size={18} /> : <DoctorColorIcon key={`icon-${key}`} colorId={item.color} />,
      <span key={`text-${key}`} className={!item.icon ? 'doctorNameFix' : ''}>{item.text}</span>,
    ];

    return (
      <ClickableList
        activeItemIndex={activeIndex}
        className="patientsFilters filter"
        items={sbItems}
        customRenderer={renderer}
        onListItemClick={this.handleFilterClick}
      />
    );
  }

  handleFilterClick = (item: Object) => {
    this.props.onSelectFilter(item);
  };

  getActiveItemIndex = (items: Array<Object>) => {
    const { activeFilter } = this.props;
    if (!activeFilter || activeFilter.filterBy === '') return 0;

    let itemData;
    return findIndex(items, (item) => {
      itemData = item.customRendererData || item;
      const { type, value } = itemData;
      const { filterBy, filterValue } = activeFilter;
      return (filterBy === type) && (!value || filterValue === value);
    });
  };
}

export default withTranslation()(PatientsFilters);
