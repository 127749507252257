// @flow
import React, { useEffect, useCallback, useMemo, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import classNames from 'classnames';
import { filter, find, get, includes, keys, map, toString, set, forEach, merge } from 'lodash';
import { Button, Modal, ClickableList, Icon, Spinner } from '@elements';
import { PlatformSwitch } from '@blocks';
import type { CustomForm, CustomFormTemplatesState, FormElementData } from '@types';
import { UserNotifications } from '@helpers/helpers';
import CustomFormFields from './CustomFormFields';
import './CustomForms.scss';

type Props = {
  recordId: number,
  templateId: number,
  customForm: CustomForm,
  customFormTemplates: CustomFormTemplatesState,
  onAddNewCustomForm: Function,
  onUpdateCustomForm: Function,
  onDeleteCustomForm: Function,
  onClose: Function,
  isSaving: boolean,
  isDeleting: boolean
};

const useForceUpdate = () => useState()[1];

type Item = {
  name: string,
  type: string,
  value: number,
};

const CustomFormModal = ({
  customForm, customFormTemplates, isDeleting, isSaving, onAddNewCustomForm, onClose, onDeleteCustomForm, onUpdateCustomForm,
  recordId, templateId,
}: Props) => {
  const forceUpdate = useForceUpdate();

  const [vState, _setVState] = useState({
    fields: {},
    template: {},
  });

  const setVState = useCallback((value: Object) => {
    _setVState((data) => merge(value, data));
  }, []);

  useEffect(() => {
    const propsFields = {};
    forEach(customForm.fields, (field) => { propsFields[field.id] = field.value; });

    const tplId = customForm.customFormTemplateId ? toString(customForm.customFormTemplateId) : toString(templateId);
    setVState({
      fields: propsFields,
      template: get(customFormTemplates, ['data', tplId], {}),
    });
  }, [templateId, customForm, customFormTemplates, setVState, forceUpdate]);


  const modalTitle = useMemo(() => vState.template.name, [vState]);

  const templateHeaders = useMemo(() => filter(vState.template.fields, { type: 'header' }), [vState]);

  const headerItems = useMemo(() => map(templateHeaders, (header) => ({
    customRendererData: {
      name: header.label,
      value: header.id,
      type: 'customFormTemplateHeader',
    },
  })), [templateHeaders]);

  const onTemplateHeaderClick = useCallback((item: Item) => {
    const headerElement = document.getElementById(`header-${item.value}`);
    if (headerElement) {
      headerElement.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'nearest',
      });
    }
  }, []);

  const { callback: debouncedHandleFormChange } = useDebouncedCallback((data: FormElementData) => {
    const { name, value } = data;
    setVState({
      fields: set(vState.fields, [name], value),
    });
  }, 200);

  const handleFormChange = useCallback((fd) => debouncedHandleFormChange(fd), [debouncedHandleFormChange]);

  const handleChangeCheckboxGroup = useCallback((id: number) => (values: Array<string>) => {
    setVState({ fields: set(vState.fields, [toString(id)], values) });
  }, [setVState, vState.fields]);


  const handleRadioGroupChange = useCallback((id: number) => (data: { name: string, value: string }) => {
    setVState({ fields: set(vState.fields, [toString(id)], data.value) });
  }, [setVState, vState.fields]);

  const sideRenderer = useCallback((item) => (
    <div>
      <span>{item.name}</span>
      <Icon iconName="arrow-right" className="icon" />
    </div>
  ), []);

  /**
   * Debounce callback at same ms avoiding data lost
   */
  const { callback: onSaveCustomForm } = useDebouncedCallback(() => {
    const { fields } = vState;

    // transform fields state object to array of objects
    const fieldsArray = [];
    map(keys(fields), (key) => {
      // get all field data from the template
      const fieldData = find(vState.template, (field) => (field.id === key)) || {};
      // get conditional field info from field data
      const { conditionalFieldId, conditionalFieldValue } = fieldData;
      const storedConditionalFieldValue = get(fields, toString(conditionalFieldId), []);
      const conditionMet = includes(storedConditionalFieldValue, conditionalFieldValue);
      // if there is no conditional field or if there is and the value of the conditional field still meets the requirements,
      // include the field value in the request
      if (!conditionalFieldId || conditionMet) {
        fieldsArray.push({ id: key, value: fields[key] });
      } else if (customForm.id) {
        // on edit, set the field value to null in the request (this overrides previously stored value in the database, if there was any)
        fieldsArray.push({ id: key, value: null });
      }
    });

    const data = {
      templateId,
      fields: fieldsArray,
    };

    if (customForm.id) {
      onUpdateCustomForm(recordId, customForm.id, data);
    } else {
      onAddNewCustomForm(recordId, data);
    }
  }, 200);

  const handleDeleteCustomForm = useCallback(() => {
    UserNotifications.confirmI18n('alert/confirm_delete_form', 'warning', '#FFA433', true)
      .then((value) => {
        if (value) {
          onDeleteCustomForm(recordId, customForm.id);
        }
      });
  }, [onDeleteCustomForm, customForm.id, recordId]);

  const checkForUnsavedChanges = useCallback(() => {
    const { fields } = vState;
    let unsavedChanges;

    if (customForm.id) { // on edit
      // transform fields array of objects from custom form into fields object to match the state
      const propsFields = {};
      map(customForm.fields, (field) => { propsFields[field.id] = field.value; });
      if (fields !== propsFields) {
        unsavedChanges = true;
      } else {
        unsavedChanges = false;
      }
    } else if (keys(fields).length > 0) { // on create check against default state
      unsavedChanges = true;
    } else {
      unsavedChanges = false;
    }

    return unsavedChanges;
  }, [customForm.fields, customForm.id, vState]);

  const handleClose = useCallback(() => {
    const unsavedChanges = checkForUnsavedChanges();
    if (unsavedChanges) {
      UserNotifications.confirmI18n('alert/confirm_quit_form', null, null, true)
        .then((value) => {
          if (value) {
            onClose();
          }
        });
    } else {
      onClose();
    }
  }, [checkForUnsavedChanges, onClose]);

  const drawSidebarView = useCallback(() => (
    <aside className="modalSidebar modalSidebarFixForm">
      <span className="sidebarTitle">{modalTitle}</span>
      <ClickableList
        items={headerItems}
        customRenderer={sideRenderer}
        className="sidebarNav savedModels"
        onListItemClick={onTemplateHeaderClick}
      />
    </aside>
  ), [sideRenderer, headerItems, modalTitle, onTemplateHeaderClick]);


  const renderModal = useCallback((ipad: boolean, isMobile: boolean) => (
    <Modal id="customFormModal" onClose={handleClose} className={classNames({ asView: isMobile, isMobile })} >
      {isMobile && (
        <div className="modalHeader">
          <Button onClick={handleClose} disabled={isSaving || isDeleting}>
            <Icon iconName="close" className="icon" />
          </Button>
          <span className="title">{modalTitle}</span>
          { customForm.id && (
            <Button className="primary" onClick={handleDeleteCustomForm} disabled={isSaving || isDeleting}>
              <Icon
                iconName="delete"
                className="icon"
              />
            </Button>
          )}
          <Button className="primary" text="Salvar" onClick={onSaveCustomForm} disabled={isDeleting} isLoading={isSaving} />
        </div>
      )}
      <div className={classNames('modalGrid', 'modalGridFixForm', { isMobile })}>
        {!isMobile && drawSidebarView()}
        {isMobile && <span className="sidebarTitle">{modalTitle}</span>}
        <div className={classNames('modalContainer', { isMobile })}>
          <div className={classNames('modalContent greyBg', { isMobile })}>
            <form className="form">
              <CustomFormFields
                fieldsValues={vState.fields}
                templateFields={vState.template.fields}
                handleChangeCheckboxGroup={handleChangeCheckboxGroup}
                handleFormChange={handleFormChange}
                handleRadioGroupChange={handleRadioGroupChange}
                isDeleting={isDeleting}
                isSaving={isSaving}
              />
            </form>
          </div>
        </div>
      </div>
      {!isMobile && (
        <div className="modalFooter">
          <Button
            className="primary"
            text="Salvar"
            onClick={onSaveCustomForm}
            disabled={isDeleting}
            isLoading={isSaving}
          />
          <div className="blockActions">
            { customForm.id && (
              <Button onClick={handleDeleteCustomForm} disabled={isSaving || isDeleting}>
                <Icon
                  iconName="delete"
                  className="icon"
                />
                {isDeleting && <Spinner />}
              </Button>
            )}
            <Button
              text="Cancelar"
              onClick={handleClose}
              disabled={isSaving || isDeleting}
            />
          </div>
        </div>
      )}
    </Modal>
  ), [customForm.id, drawSidebarView, handleChangeCheckboxGroup, handleClose,
    handleDeleteCustomForm, handleFormChange, handleRadioGroupChange, isDeleting,
    isSaving, modalTitle, onSaveCustomForm, vState.fields, vState.template.fields]);

  return (
    <PlatformSwitch
      inject
      desktop={(ipad: boolean) => renderModal(ipad, false)}
      mobile={(ipad: boolean) => renderModal(ipad, true)}
    />
  );
};

// $FlowFixMe
export default React.memo(CustomFormModal);
