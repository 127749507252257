// @flow
import React from 'react';
import { map, filter, toLower, find, get } from 'lodash';
import { SearchInput, Modal, Tag, Button, Icon, Spinner } from '@elements';
import type { TagTemplates, TagTemplate } from '@types';
import { PlatformSwitch } from '@blocks';
import { withTranslation } from 'react-i18next';
import { createNsTranslator } from '@helpers/helpers';


type Props = {
  onClose: Function,
  tagTemplates: TagTemplates,
  patientTags: Array<TagTemplate>,
  onSelectTag: Function,
  onRemoveTag: Function,
  onAddNewtag: Function,
  isLoading: boolean,
  t: Function,
};

type State = {
  searchValue: string,
};

class TagsFormModal extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      searchValue: '',
    };
    this.inputRef = React.createRef();
  }

  render() {
    const { searchValue } = this.state;
    const {
      onClose, tagTemplates, patientTags, isLoading,
    } = this.props;

    const _ = createNsTranslator('timeline', this.props.t);

    const availableTagTemplates = [];
    map(tagTemplates, (tag) => {
      const selectedTag = find(patientTags, (patientTag) => (get(patientTag, 'name') === get(tag, 'name')));
      if (!selectedTag) {
        availableTagTemplates.push(tag);
      }
    });

    const filteredTags = filter(availableTagTemplates, (tag) => {
      const forMatch = toLower(tag.name);
      const matchWith = toLower(searchValue);

      return forMatch.search(matchWith) >= 0;
    });

    const fullMatch = filter(tagTemplates, (tagTemplate) => (toLower(tagTemplate.name) === toLower(searchValue)));

    const content = (
      <React.Fragment>
        <div className="tagsHolder">
          {map<any, any>(patientTags, (tag, key) => {
            if (!tag) return null;
            return (
              <Tag
                isLoading={isLoading}
                key={key}
                label={get(tag, 'name')}
                onRemove={this.onRemoveTag.bind(this, tag)}
              />
            );
          })}
        </div>
        <div className="searchBar">
          <SearchInput
            name="name"
            autofocus
            placeholder={_('label/tag_input')}
            value={searchValue}
            className={isLoading ? 'loading' : ''}
            onChange={this.handleChange}
            ref={this.inputRef}
          />
        </div>

        <ul className="list">
          {map<any, any>(filteredTags, (filteredTag, key) => (
            <li
              key={key}
              role="presentation"
              className={isLoading ? 'suggestion loading' : 'suggestion'}
              onClick={this.onSelectTag.bind(this, filteredTag)}
            >
              {filteredTag.name}
            </li>
          ))}
          {!fullMatch.length && searchValue &&
          <li
            role="presentation"
            className='suggestion addNew'
            onClick={this.onAddNewtag}
          >
            <span>{_('label/create_new_tag')} <span className='newTagSuggestion'>{`'${searchValue}'`}</span></span>
          </li>
          }
          {filteredTags.length === 0 &&
          <li
            role="presentation"
            className={isLoading ? 'suggestion addNew loading' : 'suggestion addNew'}
          >
            {!searchValue && <span>{_('label/no_tags')}</span>}
          </li>
          }
        </ul>
      </React.Fragment>
    );

    return (
      <PlatformSwitch
        inject
        desktop={() => (
          <Modal className="widgetModal" onClose={onClose}>
            <div className="modalHeader">
              <span className="modalTitle">{_('label/tag_plural', 'global')}:&nbsp;{isLoading && <Spinner />}</span>
            </div>

            <div className="modalGrid">
              <div className="modalContainer">
                <div className="modalContent manageTagsContainer">
                  {content}
                </div>
              </div>
            </div>
          </Modal>
        )}
        mobile={() => (
          <Modal className="asView" onClose={onClose}>
            <div className="modalHeader">
              <Button onClick={onClose}>
                <Icon iconName="close" className="icon" />
              </Button>
              <span className="title">{_('label/tag_plural', 'global')}&nbsp;{isLoading && <Spinner />}</span>
              <Button onClick={onClose}>
                <Icon iconName="done" className="icon" />
              </Button>
            </div>
            <div className="modalContainer">
              <div className="modalContent">
                {content}
              </div>
            </div>
          </Modal>
        )}
      />
    );
  }

  inputRef: any;

  handleChange = (searchValue: string) => {
    this.setState({ searchValue });
  };

  onSelectTag = (tag: TagTemplate) => {
    if (!this.props.isLoading) {
      this.props.onSelectTag(tag);
      this.setState({ searchValue: '' }, this.focusInput);
    }
  };

  onRemoveTag = (tag: TagTemplate) => {
    const { patientTags } = this.props;
    const fullTag = find(patientTags, (patientTag) => (patientTag.name === tag.name));
    this.props.onRemoveTag(fullTag);
    this.focusInput();
  };

  onAddNewtag = () => {
    this.props.onAddNewtag(this.state.searchValue);
    this.setState({ searchValue: '' }, this.focusInput);
  };

  focusInput = () => {
    if (this.inputRef.current) {
      this.inputRef.current.inputRef.focus();
    }
  };
}

export default withTranslation()(TagsFormModal);
