import React from 'react';
import { Spinner, Button } from '@elements';
import { get, map, isFunction } from 'lodash';
import type { TableColumItem } from '@types';
import classnames from 'classnames';

const isLoadingItem = (it: any, onGetLoadingItem: any) => (onGetLoadingItem ? onGetLoadingItem(it) : false);

const calcClass = (cl: any, item: any) => (isFunction(cl) ? cl(item) : cl);

const mapRenderItemMobile = (fColumns: any, onItemClick: Function, onGetLoadingItem: Function, itemKey: string, mobileClickText?: string) => (item, key) => (
  <div className="mobileItem" key={`line-${key}-${get(item, itemKey, '')}`}>
    <table className="itemContent">
      <tbody>
        {map(fColumns, (header: TableColumItem, key2) => (
          <tr key={`${header.dataIndex}-${key2}-td-${get(item, itemKey, '')}`} className="line">
            <td className="key">
              {header.content}
            </td>
            <td className={classnames('value', calcClass(get(header, 'rowClass', ''), item), '')}>
              <p>{header.onRenderItem ? header.onRenderItem(item) : get(item, header.dataIndex, '')}</p>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
    {onItemClick && !isLoadingItem(item, onGetLoadingItem) && (
      <div className="clickActionBtn">
        <Button className="default" onClick={() => (onItemClick ? onItemClick(item) : null)}>{mobileClickText}</Button>
      </div>
    )}
    {onItemClick && isLoadingItem(item, onGetLoadingItem) && (
      <div className="clickActionBtn">
        <Button className="default"><Spinner /></Button>
      </div>
    )}
  </div>
);

export default mapRenderItemMobile;

