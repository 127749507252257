// @flow
import React from 'react';
import { connect } from 'react-redux';
import { get } from 'lodash';
import { CustomFormModal } from '../../components/views';
import { actions } from '../../redux/modules/context/modals';
import { customFormActions } from '../../redux/modules/context/customForms';
import type {
  Dispatch, CustomForm, CustomFormInput,
  CustomFormTemplatesState,
} from '../../types';

type Props = {
  recordId: number,
  templateId: number,
  customForm: CustomForm,
  customFormTemplates: CustomFormTemplatesState,
  dispatch: Function,
  isSaving: boolean,
  isDeleting: boolean
};

class CustomFormModalContainer extends React.Component<Props> {
  render() {
    const {
      recordId, templateId, customForm,
      customFormTemplates, isDeleting, isSaving,
    } = this.props;

    return (
      <CustomFormModal
        recordId={recordId}
        templateId={templateId}
        customForm={customForm}
        customFormTemplates={customFormTemplates}
        onAddNewCustomForm={this.handleCreateCustomForm}
        onUpdateCustomForm={this.handleUpdateCustomForm}
        onDeleteCustomForm={this.handleDeleteCustomForm}
        onClose={this.closeModal}
        isSaving={isSaving}
        isDeleting={isDeleting}
      />
    );
  }

  closeModal = () => {
    this.props.dispatch(actions.closeActiveModal());
  };

  handleCreateCustomForm = (recordId: number, customForm: CustomFormInput) => {
    this.props.dispatch(customFormActions.createCustomForm(recordId, customForm, this.closeModalOnSuccess));
  };

  handleUpdateCustomForm = (recordId: number, customFormId: number, customForm: CustomFormInput) => {
    this.props.dispatch(customFormActions.updateCustomForm(recordId, customFormId, customForm, this.closeModalOnSuccess));
  };

  handleDeleteCustomForm = (recordId: number, customFormId: number) => {
    this.props.dispatch(customFormActions.deleteCustomForm(recordId, customFormId, this.closeModalOnSuccess));
  };

  closeModalOnSuccess = (success: boolean) => {
    if (success) {
      this.closeModal();
    }
  };
}

const mapStateToProps = (state) => ({
  recordId: state.context.modals.data.recordId,
  templateId: state.context.modals.data.templateId,
  customForm: get(state.context, ['customForms', 'data', state.context.modals.data.customFormId], {}),
  isSaving: get(state.context, ['customForms', 'isSaving'], false),
  isDeleting: get(state.context, ['customForms', 'isDeleting'], false),
  customFormTemplates: get(state.context, ['customFormTemplates'], {}),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({ dispatch });

export default connect<any, any, any, any, any, any>(
  mapStateToProps,
  mapDispatchToProps,
)(CustomFormModalContainer);
