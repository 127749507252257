// @flow
import React from 'react';
import { NotFound as NotFoundView } from '../components/views';

export default class NotFound extends React.Component<any> {
  render() {
    return (
      <NotFoundView />
    );
  }
}
