// @flow
import moment from 'moment';
import { get, omit } from 'lodash';
import { reportError, createCachedAction } from '@helpers/helpers';
import { Api } from '../../../helpers';
import { router } from '../../../routes/router';
import DocumentsTemplateService from '../../../services/DocumentsTemplateService';
import type
{
  Action,
  Dispatch,
  ContextState,
  DocumentsTemplateInput,
} from '../../../types';

// ------------------------------------
// Constants
// ------------------------------------
const DOCUMENTS_TEMPLATES_LOADING = 'documentsTemplates.DOCUMENTS_TEMPLATES_LOADING';
const DOCUMENTS_TEMPLATES_LOADED = 'documentsTemplates.DOCUMENTS_TEMPLATES_LOADED';
const DOCUMENTS_TEMPLATES_LOADING_FAILED = 'documentsTemplates.DOCUMENTS_TEMPLATES_LOADING_FAILED';

const DOCUMENTS_TEMPLATE_CREATE_IN_PROGRESS = 'documentsTemplates.DOCUMENTS_TEMPLATE_CREATE_IN_PROGRESS';
const DOCUMENTS_TEMPLATE_CREATE_SUCCESS = 'documentsTemplates.DOCUMENTS_TEMPLATE_CREATE_SUCCESS';
const DOCUMENTS_TEMPLATE_CREATE_FAILED = 'documentsTemplates.DOCUMENTS_TEMPLATE_CREATE_FAILED';

const DOCUMENTS_TEMPLATE_UPDATE_IN_PROGRESS = 'documentsTemplates.DOCUMENTS_TEMPLATE_UPDATE_IN_PROGRESS';
const DOCUMENTS_TEMPLATE_UPDATE_SUCCESS = 'documentsTemplates.DOCUMENTS_TEMPLATE_UPDATE_SUCCESS';
const DOCUMENTS_TEMPLATE_UPDATE_FAILED = 'documentsTemplates.DOCUMENTS_TEMPLATE_UPDATE_FAILED';

const DOCUMENTS_TEMPLATE_DELETE_IN_PROGRESS = 'documentsTemplates.DOCUMENTS_TEMPLATE_DELETE_IN_PROGRESS';
const DOCUMENTS_TEMPLATE_DELETE_SUCCESS = 'documentsTemplates.DOCUMENTS_TEMPLATE_DELETE_SUCCESS';
const DOCUMENTS_TEMPLATE_DELETE_FAILED = 'documentsTemplates.DOCUMENTS_TEMPLATE_DELETE_FAILED';

// ------------------------------------
// Actions
// ------------------------------------
// export const getDocumentsTemplates = () => (
//   (dispatch: Dispatch) => {
//     dispatch({ type: DOCUMENTS_TEMPLATES_LOADING });
//     Api.get(router.getApiRoute('getDocumentsTemplates', null), {
//       success: (response) => {
//         dispatch({ type: DOCUMENTS_TEMPLATES_LOADED, documentsTemplates: response.documentsTemplates });
//       },
//       error: (error) => {
//         dispatch({ type: DOCUMENTS_TEMPLATES_LOADING_FAILED, error: { message: error.message } });
//         dispatch(reportError(error));
//       },
//     });
//   }
// );

export const getDocumentsTemplates = (force?: boolean = false) => (
  (dispatch: Dispatch) => {
    dispatch(createCachedAction(
      DOCUMENTS_TEMPLATES_LOADING, DOCUMENTS_TEMPLATES_LOADED, DOCUMENTS_TEMPLATES_LOADING_FAILED,
      12,
      () => Api.get(router.getApiRoute('getDocumentsTemplates', null)),
      ['context', 'documentsTemplates', 'ttl'],
      (response: any) => ({
        documentsTemplates: response.documentsTemplates,
      }),
    )(force, {}));
  }
);

export const createDocumentsTemplate = (documentsTemplate: DocumentsTemplateInput) => (
  (dispatch: Dispatch) => {
    dispatch({ type: DOCUMENTS_TEMPLATE_CREATE_IN_PROGRESS });
    Api.post(router.getApiRoute('createDocumentsTemplate', null), {
      data: { documentsTemplate },
      success: (response) => {
        dispatch({ type: DOCUMENTS_TEMPLATE_CREATE_SUCCESS, documentsTemplate: response.data.documentsTemplate });
      },
      error: (error) => {
        dispatch({ type: DOCUMENTS_TEMPLATE_CREATE_FAILED, error: { message: error.message } });
        dispatch(reportError(error));
      },
    });
  }
);

export const updateDocumentsTemplate = (id: number, documentsTemplate: DocumentsTemplateInput) => (
  (dispatch: Dispatch) => {
    dispatch({ type: DOCUMENTS_TEMPLATE_UPDATE_IN_PROGRESS });
    Api.patch(router.getApiRoute('updateDocumentsTemplate', { id }), {
      data: { documentsTemplate },
      success: (response) => {
        dispatch({ type: DOCUMENTS_TEMPLATE_UPDATE_SUCCESS, documentsTemplate: response.documentsTemplate });
      },
      error: (error) => {
        dispatch({ type: DOCUMENTS_TEMPLATE_UPDATE_FAILED, error: { message: error.message } });
        dispatch(reportError(error));
      },
    });
  }
);

export const deleteDocumentsTemplate = (id: number) => (
  (dispatch: Dispatch) => {
    dispatch({ type: DOCUMENTS_TEMPLATE_DELETE_IN_PROGRESS });
    Api.destroy(router.getApiRoute('deleteDocumentsTemplate', { id }), {
      success: () => {
        dispatch({ type: DOCUMENTS_TEMPLATE_DELETE_SUCCESS, deletedTemplateId: id });
      },
      error: (error) => {
        dispatch({ type: DOCUMENTS_TEMPLATE_DELETE_FAILED, error: { message: error.message } });
        dispatch(reportError(error));
      },
    });
  }
);

export const documentsTemplateActions = {
  getDocumentsTemplates,
  createDocumentsTemplate,
  updateDocumentsTemplate,
  deleteDocumentsTemplate,
};

// ------------------------------------
// Reducer
// ------------------------------------
export default function documentsTemplatesReducer (state: ContextState, action: Action): ContextState {
  switch (action.type) {
    case DOCUMENTS_TEMPLATES_LOADING:
      return state
        .setIn(['documentsTemplates', 'isLoading'], true)
        .setIn(['documentsTemplates', 'errors'], null);

    case DOCUMENTS_TEMPLATES_LOADED:
      const normalized = DocumentsTemplateService.normalizeDocumentsTemplates(action.documentsTemplates);
      return state
        .setIn(['documentsTemplates', 'isLoading'], false)
        .setIn(['documentsTemplates', 'errors'], null)
        .setIn(['documentsTemplates', 'ttl'], get(action, 'ttl', null))
        .setIn(['documentsTemplates', 'data'], normalized.documentsTemplates)
        .setIn(['documentsTemplates', 'lastFetch'], moment().toISOString());

    case DOCUMENTS_TEMPLATES_LOADING_FAILED:
      return state
        .setIn(['documentsTemplates', 'isLoading'], false)
        .setIn(['documentsTemplates', 'errors'], [action.error.message]);

    case DOCUMENTS_TEMPLATE_CREATE_IN_PROGRESS:
      return state
        .setIn(['documentsTemplates', 'isSaving'], true)
        .setIn(['documentsTemplates', 'errors'], null);

    case DOCUMENTS_TEMPLATE_CREATE_SUCCESS:
      return state
        .setIn(['documentsTemplates', 'isSaving'], false)
        .setIn(['documentsTemplates', 'errors'], null)
        .setIn(['documentsTemplates', 'data', action.documentsTemplate.id], action.documentsTemplate);

    case DOCUMENTS_TEMPLATE_CREATE_FAILED:
      return state
        .setIn(['documentsTemplates', 'isSaving'], false)
        .setIn(['documentsTemplates', 'errors'], [action.error.message]);

    case DOCUMENTS_TEMPLATE_UPDATE_IN_PROGRESS:
      return state
        .setIn(['documentsTemplates', 'isSaving'], true)
        .setIn(['documentsTemplates', 'errors'], null);

    case DOCUMENTS_TEMPLATE_UPDATE_SUCCESS:
      return state
        .setIn(['documentsTemplates', 'isSaving'], false)
        .setIn(['documentsTemplates', 'errors'], null)
        .setIn(['documentsTemplates', 'data', action.documentsTemplate.id], action.documentsTemplate);

    case DOCUMENTS_TEMPLATE_UPDATE_FAILED:
      return state
        .setIn(['documentsTemplates', 'isSaving'], false)
        .setIn(['documentsTemplates', 'errors'], [action.error.message]);

    case DOCUMENTS_TEMPLATE_DELETE_IN_PROGRESS:
      return state
        .setIn(['documentsTemplates', 'isSaving'], true)
        .setIn(['documentsTemplates', 'errors'], null);

    case DOCUMENTS_TEMPLATE_DELETE_SUCCESS:
      const deletedId = action.deletedTemplateId;
      const data = get(state, ['documentsTemplates', 'data'], {});
      return state
        .setIn(['documentsTemplates', 'isSaving'], false)
        .setIn(['documentsTemplates', 'errors'], null)
        .setIn(['documentsTemplates', 'data'], omit(data, deletedId.toString()));

    case DOCUMENTS_TEMPLATE_DELETE_FAILED:
      return state
        .setIn(['documentsTemplates', 'isSaving'], false)
        .setIn(['documentsTemplates', 'errors'], [action.error.message]);

    default:
      return state;
  }
}
