
import React from 'react';
import { map, isEqual, forEach, get } from 'lodash';
import { AttachmentPreview } from '@elements';


type Props = {
    recordImages: Object[],
    openLightBox: Function,
};

const AttachmentImages = ({ recordImages, openLightBox }: Props) => map(recordImages, (img) => (
  <AttachmentPreview
    key={`AttachmentPreview-${img.id}`}
    img={img}
    onClick={openLightBox(img.id)}
  />
));


const shouldUpdate = (prev: Props, next: Props) => {
  if (isEqual(prev, next)) {
    return true;
  }
  const diffs = [];

  forEach(next.recordImages, (image, index: string) => {
    const a = get(prev.recordImages, [index, 'id'], null);
    const b = get(next.recordImages, [index, 'id'], null);
    if (parseInt(a, 10) !== parseInt(b, 10)) {
      diffs.push(`${a} !== ${b}`);
    }
  });

  if (get(prev.recordImages, 'length', 0) !== get(next.recordImages, 'length', 0)) {
    diffs.push('len');
  }

  return diffs.length === 0;
};

// $FlowFixMe
export default React.memo(AttachmentImages, (prev, next) => shouldUpdate(prev, next));
