// @flow
import React from 'react';
import './DoutoreSpinner.scss';

export default class DoutoreSpinner extends React.Component<any> {
  render() {
    return (
      <div className="heartRate">
        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="45px" height="30px" viewBox="0 0 45 30" enableBackground="new 0 0 45 30" xmlSpace="preserve">

          <defs>
            <linearGradient id="linear" x1="0%" y1="0%" x2="100%" y2="0%">
              <stop offset="0%" stopColor="#40ED99" />
              <stop offset="100%" stopColor="#20D862" />
            </linearGradient>
          </defs>
          <polyline
            fill="none"
            stroke="url(#linear)"
            strokeWidth="5"
            strokeMiterlimit="10"
            strokeLinecap="round"
            points="3,14.5 12,14.5 16,3 16.5,3 24,24 24.5,24 30,14.5 39,14.5"
          />
        </svg>
        <div className="heartRateFadeIn" />
        <div className="heartRateFadeOut" />
      </div>
    );
  }
}
