// @flow
import React from 'react';
import classnames from 'classnames';
import {
  map, toLower, isArray,
  uniq, concat, includes,
  without,
} from 'lodash';
import { Input } from '../../elements';

type CheckboxItem = {
  name?: string,
  label: string,
  value?: string,
  inputClassName?: string,
  labelClassName?: string,
  disabled?: boolean,
};

type State = {
  checkedItems: Array<string>,
};

type Props = {
  id?: number,
  label?: string,
  items: Array<CheckboxItem> | CheckboxItem,
  className?: string,
  innerClassName?: string,
  groupLabel?: string,
  checked?: Array<string>,
  onChange?: Function,
  disabled?: boolean,
};

export default class CheckboxGroup extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      checkedItems: props.checked || [],
    };
  }

  render() {
    const { checkedItems } = this.state;
    const {
      id,
      label,
      groupLabel,
      disabled,
      items,
      className, // root element (group wrapper)
      innerClassName, // checkbox holder element (single checkbox wrapper)
    } = this.props;

    const checkboxItems = !isArray(items) ? [items] : items;

    const cssClasses = classnames(['checkboxGroupWrapper', className]);

    let innerCssClasses = 'checkboxHolder';
    if (innerClassName) innerCssClasses += ` ${innerClassName}`;

    const inputRenderer = [];
    map(checkboxItems, (item) => {
      const checkboxId = id ? `checkbox-${toLower(item.label).replace(/\s+/g, '')}-${id}` : `checkbox-${toLower(item.label).replace(/\s+/g, '')}`;

      let inputCssClasses = 'checkbox';
      if (item.inputClassName) inputCssClasses += ` ${item.inputClassName}`;

      let labelCssClasses = 'text';
      if (item.labelClassName) labelCssClasses += ` ${item.labelClassName}`;

      const labelElement = (
        <label
          className={labelCssClasses}
          htmlFor={checkboxId}
        >
          {item.label}
        </label>
      );

      /**
        * Pass all props like you pass in html element
        * e.g. <input id="id" className="class" disabled checked ... />
      */
      const inputProps = {
        // reserved
        innerCssClasses,
        node: labelElement,
        // classic input props
        className: inputCssClasses,
        name: item.name,
        value: item.value,
        id: checkboxId,
        checked: item.value ? includes(checkedItems, item.value) : false,
        disabled: item.disabled || disabled,
      };

      inputRenderer.push(inputProps);
    });

    return (
      <div className={cssClasses}>
        <Input
          label={groupLabel}
          type="checkbox"
          inputRenderer={inputRenderer}
          onCheck={this.toggleCheckbox.bind(this)}
        />
      </div>
    );
  }

  toggleCheckbox = (data: { name: string, value: string }) => {
    let checkedItems;
    if (includes(this.state.checkedItems, data.value)) {
      checkedItems = uniq(without(this.state.checkedItems, data.value));
    } else {
      checkedItems = uniq(concat(this.state.checkedItems, data.value));
    }
    if (this.props.onChange) {
      this.props.onChange(checkedItems);
    }
    this.setState({ checkedItems });
  }
}
