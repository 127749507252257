// @flow
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { get } from 'lodash';
import { PlatformSwitch } from '@blocks';
import { ReceiptFormModal } from '../../components/views';
import { receiptActions } from '../../redux/modules/context/receipts';
import type { Dispatch, ReceiptInput, ReceiptsState, Doctor } from '../../types';
import { withConfirmOnClose } from '../../hocs';
import { Modal, Loading } from '../../components/elements';

type Props = {
  dispatch: Function,
  patientId: number,
  doctorId: number,
  receipts: ReceiptsState,
  price?: number,
  date: string,
  setConfirmation: Function,
  closeModal: Function,
  doctor: Doctor,
};

class ReceiptNewFormModalContainer extends React.Component<Props> {
  componentDidMount() {
    const {
      dispatch, patientId, doctorId,
    } = this.props;
    dispatch(receiptActions.getNewReceipt(patientId, doctorId));
  }

  render() {
    const {
      receipts, price, date, setConfirmation, doctor,
    } = this.props;

    const isLoadingNew = get(receipts, 'isLoadingNew', false);

    const requestedFormData = get(receipts, 'formData', {});

    const formData = {
      ...requestedFormData,
      ...(date ? { date } : {}),
    };

    if (price) {
      formData.price = price;
    }


    if (isLoadingNew) {
      return (
        <PlatformSwitch
          inject
          desktop={() => (
            <Modal onClose={this.closeModal} className="receiptLoadingContainer">
              <div className="modalContainer">
                <div className="modalContent">
                  <Loading />
                </div>
              </div>
            </Modal>
          )}
          mobile={() => (
            <Modal onClose={this.closeModal} className="asView receiptLoadingContainer" >
              <div className="modalHeader">
                <span className="title">Recibos</span>
              </div>
              <div className="modalContainer">
                <div className="modalContent">
                  <Loading />
                </div>
              </div>
            </Modal>
          )}
        />
      );
    }

    return (
      <ReceiptFormModal
        isNew
        requestedFormData={get(receipts, 'formData', {})}
        formData={formData}
        onSave={this.handleSave}
        onClose={this.closeModal}
        onSetConfirmOnClose={setConfirmation}
        doctor={doctor}
      />
    );
  }

  closeModal = this.props.closeModal;

  handleSave = (data: ReceiptInput) => {
    const { dispatch, patientId, doctorId } = this.props;
    dispatch(receiptActions.createReceipt(patientId, data, doctorId));
    this.closeModal(true);
  };
}


const mapStateToProps = (state) => ({
  patientId: get(state, ['context', 'modals', 'data', 'patientId']),
  price: get(state, ['context', 'modals', 'data', 'price']),
  date: get(state, ['context', 'modals', 'data', 'date']),
  doctorId: get(state, ['context', 'modals', 'data', 'doctorId']),
  receipts: get(state, ['context', 'receipts'], {}),
  doctor: get(state, ['context', 'doctors', 'data', get(state, ['context', 'modals', 'data', 'doctorId'])], null),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({ dispatch });

export default compose(
  connect<any, any, any, any, any, any>(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withConfirmOnClose(),
)(ReceiptNewFormModalContainer);
