// @flow
import React from 'react';
import { get, isEmpty } from 'lodash';
import { SettingsMenu, FormFooter, PlatformSwitch, AppMobileHeader, AppMobileFooter } from '@blocks';
import { Checkbox, Input } from '@elements';
import type { User, BundledPermissions } from '@types';
import { withForm } from '@hocs';
import { withTranslation } from 'react-i18next';
import { createNsTranslator, isPresent } from '@helpers/helpers';
import './ClinicConfigPatientFields.scss';

type Props = {
  user: User,
  formData: Object,
  handleFormChange: Function,
  isLoading: boolean,
  onSave: Function,
  onLogout: Function,
  resetData: Function,
  updateDefaultData: Function,
  permissions: BundledPermissions,
  formEdited: Function,
  bindValidation: Function,
  setInitialData: Function,
  t: Function,
};

type State = {
  errors: Object,
}


class ClinicConfigPatientFields extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      errors: {},
    };
    this.props.bindValidation(this.handleValidation);
  }

  componentDidMount() {
    this.handleValidation();
  }

  render() {
    const {
      user, handleFormChange, formData, isLoading, resetData,
      onLogout,
    } = this.props;

    const { errors } = this.state;

    const _ = createNsTranslator('settings', this.props.t);

    const edited = this.props.formEdited();

    const cfLabel = n => _('label/patients_fields/custom_field') + ` ${n}`;

    const pageContent = (
      <React.Fragment>
        <div className="toolbar dashed">
          <h1>{_('label/patients_fields/hint')}</h1>
        </div>
        <p>{_('label/patients_fields/basic_data')}</p>
        <Checkbox
          disabled={isLoading}
          name="patientsNumberFieldActivated"
          label={_('label/patients_fields/code')}
          onChange={handleFormChange}
          isChecked={get(formData, 'patientsNumberFieldActivated', false)}
        />
        <br />
        <Checkbox
          disabled={isLoading}
          name="patientsProfessionFieldActivated"
          label={_('label/profession', 'global')}
          onChange={handleFormChange}
          isChecked={get(formData, 'patientsProfessionFieldActivated', false)}
        />
        <br />
        <Checkbox
          disabled={isLoading}
          name="patientsPlaceOfBirthFieldActivated"
          label={_('label/place_of_birth', 'patientProfile')}
          onChange={handleFormChange}
          isChecked={get(formData, 'patientsPlaceOfBirthFieldActivated', false)}
        />
        <br />
        <Checkbox
          disabled={isLoading}
          name="patientsLegalIdFieldActivated"
          label={_('label/n_legal_id', 'patientProfile')}
          onChange={handleFormChange}
          isChecked={get(formData, 'patientsLegalIdFieldActivated', false)}
        />
        <br />
        <Checkbox
          disabled={isLoading}
          name="patientsMaritalStatusFieldActivated"
          label={_('label/marital_status', 'patientProfile')}
          onChange={handleFormChange}
          isChecked={get(formData, 'patientsMaritalStatusFieldActivated', false)}
        />
        <hr />
        <Checkbox
          disabled={isLoading}
          name="patientsExtraField1Activated"
          label={cfLabel(1)}
          onChange={handleFormChange}
          isChecked={get(formData, 'patientsExtraField1Activated', false)}
        />
        <br />
        <Input
          disabled={isLoading || !get(formData, 'patientsExtraField1Activated', false)}
          name="patientsExtraField1Label"
          value={get(formData, 'patientsExtraField1Label', '') || ''}
          onChange={handleFormChange}
          error={get(errors, 'patientsExtraField1Label')}
        />
        <hr />
        <br />
        <Checkbox
          disabled={isLoading}
          name="patientsExtraField2Activated"
          label={cfLabel(2)}
          onChange={handleFormChange}
          isChecked={get(formData, 'patientsExtraField2Activated', false)}
        />
        <br />
        <Input
          disabled={isLoading || !get(formData, 'patientsExtraField2Activated', false)}
          name="patientsExtraField2Label"
          value={get(formData, 'patientsExtraField2Label', '') || ''}
          onChange={handleFormChange}
          error={get(errors, 'patientsExtraField2Label')}
        />
        <hr />
        <br />
        <Checkbox
          disabled={isLoading}
          name="patientsExtraField3Activated"
          label={cfLabel(3)}
          onChange={handleFormChange}
          isChecked={get(formData, 'patientsExtraField3Activated', false)}
        />
        <br />
        <Input
          disabled={isLoading || !get(formData, 'patientsExtraField3Activated', false)}
          name="patientsExtraField3Label"
          value={get(formData, 'patientsExtraField3Label', '') || ''}
          onChange={handleFormChange}
          error={get(errors, 'patientsExtraField3Label')}
        />
        <hr />
        <p>{_('label/contact', 'global')}</p>
        <Checkbox
          disabled={isLoading}
          name="patientsInstagramFieldActivated"
          label={_('label/instagram', 'patientProfile')}
          onChange={handleFormChange}
          isChecked={get(formData, 'patientsInstagramFieldActivated', false)}
        />
        <br />
        <Checkbox
          disabled={isLoading}
          name="patientsParentsFieldsActivated"
          label={_('label/father_and_mother', 'patientProfile')}
          onChange={handleFormChange}
          isChecked={get(formData, 'patientsParentsFieldsActivated', false)}
        />
        <br />
        <Checkbox
          disabled={isLoading}
          name="patientsGuardianFieldsActivated"
          label={_('label/guardian_and_relationship', 'patientProfile')}
          onChange={handleFormChange}
          isChecked={get(formData, 'patientsGuardianFieldsActivated', false)}
        />
        <hr />
        <Checkbox
          disabled={isLoading}
          name="patientsExtraContactField1Activated"
          label={cfLabel(1)}
          onChange={handleFormChange}
          isChecked={get(formData, 'patientsExtraContactField1Activated', false)}
        />
        <br />
        <Input
          disabled={isLoading || !get(formData, 'patientsExtraContactField1Activated', false)}
          name="patientsExtraContactField1Label"
          value={get(formData, 'patientsExtraContactField1Label', '') || ''}
          onChange={handleFormChange}
          error={get(errors, 'patientsExtraContactField1Label')}
        />
        <hr />
        <Checkbox
          disabled={isLoading}
          name="patientsExtraContactField2Activated"
          label={cfLabel(2)}
          onChange={handleFormChange}
          isChecked={get(formData, 'patientsExtraContactField2Activated', false)}
        />
        <br />
        <Input
          disabled={isLoading || !get(formData, 'patientsExtraContactField2Activated', false)}
          name="patientsExtraContactField2Label"
          value={get(formData, 'patientsExtraContactField2Label', '') || ''}
          onChange={handleFormChange}
          error={get(errors, 'patientsExtraContactField2Label')}
        />
        <hr />
        <Checkbox
          disabled={isLoading}
          name="patientsExtraContactField3Activated"
          label={cfLabel(3)}
          onChange={handleFormChange}
          isChecked={get(formData, 'patientsExtraContactField3Activated', false)}
        />
        <br />
        <Input
          disabled={isLoading || !get(formData, 'patientsExtraContactField3Activated', false)}
          name="patientsExtraContactField3Label"
          value={get(formData, 'patientsExtraContactField3Label', '') || ''}
          onChange={handleFormChange}
          error={get(errors, 'patientsExtraContactField3Label')}
        />
        <hr />
        <p>{_('label/patients_fields/health_insurance')}</p>
        <Checkbox
          disabled={isLoading}
          name="patientsInsurancePlanFieldsActivated"
          label={_('label/patients_fields/health_insurance_fields')}
          onChange={handleFormChange}
          isChecked={get(formData, 'patientsInsurancePlanFieldsActivated', false)}
        />
        <FormFooter
          onSave={this.onSave}
          isLoading={isLoading}
          onCancel={resetData}
          edited={edited}
          editFeature
          cancelLinkTo="/ajustes"
        />
      </React.Fragment>
    );

    return (
      <PlatformSwitch
        inject
        desktop={() => (
          <div className="appContent settingsPage">
            <aside className="sidebar">
              <SettingsMenu user={user} onLogout={onLogout} permissions={this.props.permissions} />
            </aside>
            <section className="mainSection" id="clinicConfigPatientFields">
              <div className="centerContent">
                {pageContent}
              </div>
            </section>
          </div>
        )}
        mobile={() => (
          <React.Fragment>
            <AppMobileHeader title={_('label/patients_fields/title_mobile')} backLinkTo="/ajustes" />
            <div className="appContent settingsPage">
              <section className="mainSection">
                <div className="centerContent">{pageContent}</div>
              </section>
            </div>
            <AppMobileFooter />
          </React.Fragment>
        )}
      />
    );
  }

  onSave = () => {
    this.handleValidation().then(isOk => {
      if (!isOk) return;
      const {
        onSave, formData, updateDefaultData, setInitialData,
      } = this.props;
      setInitialData(formData);
      updateDefaultData(formData);
      onSave(formData);
    });
  };

  handleValidation = () => new Promise(resolve => {
    const { formData } = this.props;
    const errors = {};

    const checkField = (field: string) => (get(formData, `patientsExtra${field}Activated`, false) ?
      isPresent(get(formData, `patientsExtra${field}Label`, '')) : true);
    const errorField = (field: string) => {
      errors[`patientsExtra${field}Label`] = 'Informe um título para este campo';
    };

    const fields = ['Field1', 'Field2', 'Field3', 'ContactField1', 'ContactField2', 'ContactField3'];

    fields.forEach(field => {
      if (!checkField(field)) {
        errorField(field);
      }
    });

    this.setState({ errors }, () => resolve(isEmpty(errors)));
  });
}

const initialData = {
  patientsExtraContactField1Activated: false,
  patientsExtraContactField1Label: '',
  patientsExtraContactField2Activated: false,
  patientsExtraContactField2Label: '',
  patientsExtraField1Activated: false,
  patientsExtraField1Label: '',
  patientsExtraField2Activated: false,
  patientsExtraField2Label: '',
  patientsExtraField3Activated: false,
  patientsExtraField3Label: '',
  patientsInsurancePlanFieldsActivated: false,
  patientsLegalIdFieldActivated: false,
  patientsMaritalStatusFieldActivated: false,
  patientsNumberFieldActivated: false,
  patientsPlace_of_birthFieldActivated: false,
  patientsProfessionFieldActivated: false,
  patientsInstagramFieldActivated: false,
};

export default withTranslation()(withForm(initialData)(ClinicConfigPatientFields));
