export default {
  'empty/title': 'No se encontró ningún paciente',
  'empty/title_reschedule': 'No hay pacientes con la etiqueta para reprogramar',
  'empty/all_patients': 'Ver Todos los Pacientes',
  'empty/hint': '¿Sabías que además de buscar por nombre, también puedes buscar por teléfono móvil?',
  'action/load_more_patients': 'Ver más pacientes',
  'action/new_patient': 'Nuevo Paciente',
  'action/see_birthdays_of': 'Ver cumpleaños de',
  'action/last_appointment': 'Última Cita',
  'action/deleted_patients': 'Pacientes Eliminados',
  'hint/search': 'Introduce el nombre del paciente',
  'label/born_in_month': 'Cumpleañeros del Mes',
  'label/recently_served': 'Atendidos Recientemente',
  'label/recently_served_at_6_months': 'Hace más de 6 meses',
  'label/deleted_patients': 'Eliminados',
  'label/tag': 'Etiqueta',
  'label/reschedule_text': 'Cuando se cancela una cita, el paciente recibe automáticamente una etiqueta para "reagendar".',
  'label/reschedule_video_link': 'https://www.loom.com/share/2d65bdec14764baea964d6db38f7f7f7',
  'label/reschedule_link': 'Ver Video',
};
