// @flow

import React from 'react';
import classNames from 'classnames';
import { isArray, map, get } from 'lodash';
import { Button, Icon, HiddenLines } from '@elements';
import type { Record, PlanInclude } from '@types';
import { parteHtmlToReact as parseHtml } from '@helpers/helpers';

type Props = {
  actionClasses: any,
  customFormsContent: Object,
  isEditable: any,
  onEditCustomFormClick: Function,
  planInclude: PlanInclude,
  isMobile: boolean,
  isCordova: boolean,
  onPrintCustomFormClick: Function,
  record: Record,
  _: Function,
  mainSection: any;
  maxTextChars: number;
}



const CustomFormContent = ({ _, actionClasses, customFormsContent, isCordova,
  isEditable, isMobile, mainSection, onEditCustomFormClick, onPrintCustomFormClick,
  planInclude, record, maxTextChars } : Props) => map<any, any>(customFormsContent, (customForm, key) => (
    <div className={classNames('record customForm', actionClasses)} key={`${customForm.id}-${key}`}>
      <div className='customFormTitleWraper'>
        <span className={classNames('recordTitle', { isMobile: isCordova })}>{customForm.name}</span>
        <div className={classNames('customFormActions recordContentActions', { isMobile: isMobile || isCordova })}>
          {isEditable && onEditCustomFormClick && planInclude.customForms &&
          <Button
            text={<React.Fragment><Icon iconName="edit" size={18} />{!window.isCordovaApp && (<React.Fragment>&nbsp;&nbsp;</React.Fragment>)}{!window.isCordovaApp && _('action/edit', 'global')}</React.Fragment>}
            className='outlineButton buttonLike'
            onClick={onEditCustomFormClick(record.id, customForm.id)}
          />
        }
          <Button
            className='outlineButton buttonLike'
            onClick={onPrintCustomFormClick(record.id, customForm.id)}
          >
            {window.isCordovaApp && (
            <React.Fragment>
              <Icon iconName="print" className='icon' size={18} />
            </React.Fragment>
            )}
            {!window.isCordovaApp && (
            <React.Fragment>
              <Icon iconName="print" className='icon' size={18} />
              &nbsp;{_('action/print')}
            </React.Fragment>
            )}
          </Button>
          {/* <Dropdown
          className={window.isCordovaApp ? 'outlineButton singleIconFix newShareFeature buttonLike' : 'outlineButton newShareFeature buttonLike'}
          placeholder={(
            <React.Fragment>
              {window.isCordovaApp && (
                <React.Fragment><Icon iconName="share" className='icon' size={18} /></React.Fragment>
              )}
              {!window.isCordovaApp && (
                <React.Fragment><Icon iconName="share" className='icon' size={18} />{!window.isCordovaApp && (<React.Fragment>&nbsp;&nbsp;</React.Fragment>)}{_('action/share')}</React.Fragment>
              )}
            </React.Fragment>
          )}
          disableAutoModalTitle
          disableArrowIcon={isMobile}
          items={shareDropdownItems}
          onSelectItem={onShareClick('customForms', customForm.id)}
        /> */}
        </div>
      </div>
      <div className='recordContent'>
        <div className='table tableFormNoBorderFix'>
          {map<any, any>(customForm.fields, (field, index) => {
            let fieldElement;
            const nextElement = customForm.fields[index + 1];
            const isNextHeader = get(nextElement, 'type') === 'header';

            const unit = field.unit ? field.unit : '';

            if (field.type === 'header' && nextElement && !isNextHeader) {
              fieldElement = (
                <div key={field.id} className='headerWrapper'>
                  <span className='recordTitle subtitle'>{field.label}</span>
                </div>
              );
            } else if (field.type !== 'header') {
              fieldElement = (
                <div key={field.id} className='row'>
                  <div>{field.label}</div>
                  { isArray(field.value) ? (
                    <div>
                      <span>{field.value.join(', ')}</span>
                    </div>
                  ) : (
                    <div>
                      {field.value === true && 'Sim'}
                      {field.value !== true &&
                      <HiddenLines maxChars={maxTextChars} text={field.value} scrollContainer={mainSection} >
                        {text => <div>{parseHtml(text + ' ' + unit, false)}</div>}
                      </HiddenLines>
                    }
                      {/* {field.value === true ? 'Sim' : parseHtml(field.value)} */}
                    </div>
                  )}
                </div>
              );
            }
            return fieldElement;
          })}
        </div>
      </div>
    </div>
  ));

// $FlowFixMe
export default React.memo(CustomFormContent);
