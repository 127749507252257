import { map } from 'lodash';

// expects array of drugs prescriptions template objects: [{id: 1, ...}, {id: 2, ...}, ...]
// => returns drugs prescriptions template object: {1: {...}, 2: {...}, ...}
function normalizeDrugsPrescriptionsTemplates (inputTemplates) {
  const templates = {};

  map(inputTemplates, (template) => {
    templates[template.id] = template;
  });

  return {
    drugsPrescriptionsTemplates: templates,
  };
}

export default {
  normalizeDrugsPrescriptionsTemplates,
};
