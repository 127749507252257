import React, { useEffect, useRef } from 'react';

type Props = {
  __html: string;
};

const HMLDOMInjector = ({ __html }: Props) => {
  const ref = useRef(null);

  useEffect(() => {
    if (ref.current) {
      ref.current.innerHTML = __html;
    }
  }, [__html]);


  return (
    <p ref={ref} className="HMLDOMInjector">&nbsp;</p>
  );
};



export default React.memo(HMLDOMInjector);
