// @flow
import React from 'react';
import { AppHome as AppHomeView } from '../components/views';

export default class AppHome extends React.Component<any> {
  render() {
    return (
      <AppHomeView />
    );
  }
}
