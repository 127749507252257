// @flow
import React from 'react';
import { Dropdown, Icon, Tooltip } from '@elements';
import { PlatformSwitch } from '@blocks';
import { withTranslation } from 'react-i18next';
import { createNsTranslator, isShareable, isCordovaApp, UserNotifications } from '@helpers/helpers';

type Props = {
    file: Object,
    onSelectFileAction: Function,
    fileAttachmentDropdownList: any,
    showPDF?: Function,
    t: Function,
}

class FileAttachment extends React.Component<Props> {
  render() {
    const {
      file, showPDF, fileAttachmentDropdownList,
    } = this.props;

    const _ = createNsTranslator('timeline', this.props.t);

    const renderView = (isMobile: boolean, ipad: boolean) => {
      const isPDF = (String(file.extension).toLowerCase() === 'pdf' || String(file.name).endsWith('.pdf')) && showPDF;
      const desktopPDFBehavior = !!(isPDF && (!isMobile && !ipad));

      const dropdownItems = [
        { text: _('action/download'), href: file.downloadUrl, target: '_blank', download: file.name, noClick: true },
        ...fileAttachmentDropdownList,
        ...(isMobile && isPDF ? [{ text: _('action/fast_preview'), action: 'showPDF', file }] : []),
      ];


      return (
        <div className="fileAttachment">
          <button onClick={this.handleClick(desktopPDFBehavior)}>
            <>
              <Icon iconName='file-solid' className='documentIcon icon' size={18} />
              <span className='fileAttachmentText'>{file.name}</span>
              {desktopPDFBehavior && showPDF && (
                <Tooltip text={_('action/fast_preview')} className="previewPDFIcon">
                  <Icon iconName="privacy" onClick={showPDF(file.downloadUrl, file.name) || (() => {})} />
                </Tooltip>
              )}
            </>
          </button>

          <Dropdown
            mobileLabel={file.name}
            className={`${desktopPDFBehavior ? 'showViewIcon' : 'fullWidth'}`}
            items={dropdownItems}
            onSelectItem={this.handleSelectFileAction}
          />
        </div>
      );
    };
    return (
      <PlatformSwitch
        inject
        desktop={(ipad: boolean) => renderView(window.isCordovaApp, ipad)}
        mobile={() => renderView(window.isCordovaApp, false)}
      />
    );
  }

  handleClick = (desktopPDFBehavior: boolean) => () => {
    const { file, showPDF } = this.props;
    if (desktopPDFBehavior && showPDF) {
      showPDF(file.downloadUrl, file.name)();
    }
  };

  handleSelectFileAction = (item: any) => {
    const { file, onSelectFileAction } = this.props;
    if (item.action === 'download' && !isShareable(file.name) && isCordovaApp()) {
      UserNotifications.i18nTextAlert('error/mobile/file_not_downloadable');
    } else {
      onSelectFileAction(file)(item);
    }
  };
}
export default withTranslation()(FileAttachment);
