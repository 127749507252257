// @flow
import React from 'react';
import { ForgotPassword as ForgotPasswordView } from '@views';
import { connect } from 'react-redux';
import { get } from 'lodash';
import type { User } from '@types';
import { recoverPassword, resetRecoverState } from '@context/user';

type Props = {
  user: User,
  dispatch: Function,
};

class ForgotPassword extends React.Component<Props> {
  render() {
    const { user } = this.props;
    return (
      <ForgotPasswordView
        isRecoveringPassword={get(user, 'isRecoveringPassword', false)}
        emailError={get(user, 'recoverPasswordError', false)}
        onSubmit={this.handleSubmit}
        recoverSuccess={get(user, 'recoverSuccess', false)}
        onInitialize={this.handleInitializeState}
      />
    );
  }
  handleSubmit = (email: string) => this.props.dispatch(recoverPassword(email));
  handleInitializeState = () => this.props.dispatch(resetRecoverState());
}

const mapStateToProps = (state) => ({
  user: get(state, ['context', 'user'], {}),
});

export default connect<any, any, any, any, any, any>(
  mapStateToProps,
)(ForgotPassword);
