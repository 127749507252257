// @flow
import { get } from 'lodash';
import { reportError } from '@helpers/helpers';
import { Api } from '../../../helpers';
import ServiceTemplateService from '../../../services/ServiceTemplateService';
import { router } from '../../../routes/router';
import type
{
  Action,
  Dispatch,
  Service,
  ContextState,
} from '../../../types';

// ------------------------------------
// Constants
// ------------------------------------
const SERVICE_TEMPLATES_LOADING = 'serviceTemplates.SERVICE_TEMPLATES_LOADING';
const SERVICE_TEMPLATES_LOADED = 'serviceTemplates.SERVICE_TEMPLATES_LOADED';
const SERVICE_TEMPLATES_LOADING_FAILED = 'serviceTemplates.SERVICE_TEMPLATES_LOADING_FAILED';

const SERVICE_TEMPLATE_CREATE_IN_PROGRESS = 'serviceTemplates.SERVICE_TEMPLATE_CREATE_IN_PROGRESS';
const SERVICE_TEMPLATE_CREATE_SUCCESS = 'serviceTemplates.SERVICE_TEMPLATE_CREATE_SUCCESS';
const SERVICE_TEMPLATE_CREATE_FAILED = 'serviceTemplates.SERVICE_TEMPLATE_CREATE_FAILED';

const SERVICE_TEMPLATE_DELETE_IN_PROGRESS = 'serviceTemplates.SERVICE_TEMPLATE_DELETE_IN_PROGRESS';
const SERVICE_TEMPLATE_DELETE_SUCCESS = 'serviceTemplates.SERVICE_TEMPLATE_DELETE_SUCCESS';
const SERVICE_TEMPLATE_DELETE_FAILED = 'serviceTemplates.SERVICE_TEMPLATE_DELETE_FAILED';

const SERVICE_TEMPLATE_UPDATE_IN_PROGRESS = 'serviceTemplates.SERVICE_TEMPLATE_UPDATE_IN_PROGRESS';
const SERVICE_TEMPLATE_UPDATE_SUCCESS = 'serviceTemplates.SERVICE_TEMPLATE_UPDATE_SUCCESS';
const SERVICE_TEMPLATE_UPDATE_FAILED = 'serviceTemplates.SERVICE_TEMPLATE_UPDATE_FAILED';

const GET_PATIENT_SERVICES = 'serviceTemplates.GET_PATIENT_SERVICES';
const GET_PATIENT_SERVICES_SUCCESS = 'serviceTemplates.GET_PATIENT_SERVICES_SUCCESS';
const GET_PATIENT_SERVICES_FAILED = 'serviceTemplates.GET_PATIENT_SERVICES_FAILED';

const GET_RECOMENDED_SERVICE_IN_PROGRESS = 'serviceTemplates.GET_RECOMENDED_SERVICE_IN_PROGRESS';
const GET_RECOMENDED_SERVICE_SUCCESS = 'serviceTemplates.GET_RECOMENDED_SERVICE_SUCCESS';
const GET_RECOMENDED_SERVICE_FAILED = 'serviceTemplates.GET_PATIENT_SERVICES_FAILED';

// ------------------------------------
// Actions
// ------------------------------------

export const getRecomendedService = (doctorId: number, patientId: number, callback?: Function) => (
  (dispatch: Dispatch) => {
    dispatch({ type: GET_RECOMENDED_SERVICE_IN_PROGRESS });
    const route = router.getApiRoute('getRecomendedService', { doctorId, patientId });
    Api.get(route, {
      success: (response) => {
        dispatch({
          type: GET_RECOMENDED_SERVICE_SUCCESS,
          serviceTemplate: response.lastService,
          doctorId,
          patientId,
        });
        if (callback) {
          callback(true);
        }
      },
      error: (error) => {
        dispatch({ type: GET_RECOMENDED_SERVICE_FAILED, error: { message: error.message } });
        dispatch(reportError(error));
        if (callback) {
          callback(false);
        }
      },
    });
  }
);

export const getServiceTemplates = (doctorId: number, callback?: Function) => (
  (dispatch: Dispatch) => {
    dispatch({ type: SERVICE_TEMPLATES_LOADING });
    const route = router.getApiRoute('getServiceTemplates', { doctorId });
    Api.get(route, {
      success: (response) => {
        dispatch({
          type: SERVICE_TEMPLATES_LOADED,
          serviceTemplates: response.serviceTemplates,
          doctorId,
        });
        if (callback) {
          callback(true);
        }
      },
      error: (error) => {
        dispatch({ type: SERVICE_TEMPLATES_LOADING_FAILED, error: { message: error.message } });
        dispatch(reportError(error));
        if (callback) {
          callback(false);
        }
      },
    });
  }
);

export const createServiceTemplate = (doctorId: number, data: Service) => (
  (dispatch: Dispatch) => {
    dispatch({ type: SERVICE_TEMPLATE_CREATE_IN_PROGRESS });
    const route = router.getApiRoute('createServiceTemplate', { doctorId });
    Api.post(route, {
      data: {
        serviceTemplate: data,
      },
      success: (response) => {
        dispatch({
          type: SERVICE_TEMPLATE_CREATE_SUCCESS,
          serviceTemplate: get(response, ['data', 'serviceTemplate']),
        });
        dispatch(router.pushPath('serviceTemplatesList', { doctorId }));
      },
      error: (error) => {
        dispatch({ type: SERVICE_TEMPLATE_CREATE_FAILED, error: { message: error.message } });
        dispatch(reportError(error));
      },
    });
  }
);

export const updateServiceTemplate = (doctorId: number, id: number, data: Service) => (
  (dispatch: Dispatch) => {
    dispatch({ type: SERVICE_TEMPLATE_UPDATE_IN_PROGRESS });
    const route = router.getApiRoute('updateServiceTemplate', { doctorId, id });
    Api.patch(route, {
      data: {
        serviceTemplate: data,
      },
      success: (response) => {
        dispatch({
          type: SERVICE_TEMPLATE_UPDATE_SUCCESS,
          serviceTemplate: get(response, 'serviceTemplate'),
        });
        dispatch(router.pushPath('serviceTemplatesList', { doctorId }));
      },
      error: (error) => {
        dispatch({ type: SERVICE_TEMPLATE_UPDATE_FAILED, error: { message: error.message } });
        dispatch(reportError(error));
      },
    });
  }
);

export const deleteServiceTemplate = (doctorId: number, id: number) => (
  (dispatch: Dispatch) => {
    dispatch({ type: SERVICE_TEMPLATE_DELETE_IN_PROGRESS });
    const route = router.getApiRoute('deleteServiceTemplate', { doctorId, id });
    Api.destroy(route, {
      success: () => {
        dispatch({
          type: SERVICE_TEMPLATE_DELETE_SUCCESS,
          deletedId: id,
        });
        dispatch(router.pushPath('serviceTemplatesList', { doctorId }));
      },
      error: (error) => {
        dispatch({ type: SERVICE_TEMPLATE_DELETE_FAILED, error: { message: error.message } });
        dispatch(reportError(error));
      },
    });
  }
);

export const getPatientServices = (doctorId: number, patientId: number) => (
  (dispatch: Dispatch) => {
    dispatch({ type: GET_PATIENT_SERVICES });
    const route = router.getApiRoute('getRecommendedServices', { doctorId });
    Api.get(route, {
      success: (response) => {
        dispatch({
          type: GET_PATIENT_SERVICES_SUCCESS,
          serviceTemplates: response.serviceTemplates,
          patientId,
        });
      },
      error: (error) => {
        dispatch({ type: GET_PATIENT_SERVICES_FAILED, error: { message: error.message } });
        dispatch(reportError(error));
      },
    });
  }
);

export const serviceTemplateActions = {
  getServiceTemplates,
  createServiceTemplate,
  deleteServiceTemplate,
  updateServiceTemplate,
  getPatientServices,
  getRecomendedService,
};

// ------------------------------------
// Reducer
// ------------------------------------
export default function serviceTemplatesReducer (state: ContextState, action: Action): ContextState {
  switch (action.type) {
    case SERVICE_TEMPLATES_LOADING:
      return state
        .setIn(['serviceTemplates', 'isLoading'], true)
        .setIn(['serviceTemplates', 'errors'], null);

    case SERVICE_TEMPLATES_LOADED:
      const normalized = ServiceTemplateService.normalizeServiceTemplates(action.serviceTemplates);
      return state
        .setIn(['serviceTemplates', 'isLoading'], false)
        .setIn(['serviceTemplates', 'errors'], null)
        .setIn(['serviceTemplates', 'data'], normalized.serviceTemplates)
        .setIn(['serviceTemplates', 'healthInsurance'], normalized.healthInsurance)
        .setIn(['serviceTemplates', 'normal'], normalized.normal);

    case SERVICE_TEMPLATES_LOADING_FAILED:
      return state
        .setIn(['serviceTemplates', 'isLoading'], false)
        .setIn(['serviceTemplates', 'errors'], [action.error.message]);

    case SERVICE_TEMPLATE_CREATE_IN_PROGRESS:
      return state
        .setIn(['serviceTemplates', 'isSaving'], true)
        .setIn(['serviceTemplates', 'errors'], null);

    case SERVICE_TEMPLATE_CREATE_SUCCESS:
      return state
        .setIn(['serviceTemplates', 'isSaving'], false)
        .setIn(['serviceTemplates', 'errors'], null)
        .setIn(['serviceTemplates', 'data', action.serviceTemplate.id], action.serviceTemplate);

    case SERVICE_TEMPLATE_CREATE_FAILED:
      return state
        .setIn(['serviceTemplates', 'isSaving'], false)
        .setIn(['serviceTemplates', 'errors'], [action.error.message]);

    case SERVICE_TEMPLATE_UPDATE_IN_PROGRESS:
      return state
        .setIn(['serviceTemplates', 'isSaving'], true)
        .setIn(['serviceTemplates', 'errors'], null);

    case SERVICE_TEMPLATE_UPDATE_SUCCESS:
      return state
        .setIn(['serviceTemplates', 'isSaving'], false)
        .setIn(['serviceTemplates', 'errors'], null)
        .setIn(['serviceTemplates', 'data', action.serviceTemplate.id], action.serviceTemplate);

    case SERVICE_TEMPLATE_UPDATE_FAILED:
      return state
        .setIn(['serviceTemplates', 'isSaving'], false)
        .setIn(['serviceTemplates', 'errors'], [action.error.message]);

    case SERVICE_TEMPLATE_DELETE_IN_PROGRESS:
      return state
        .setIn(['serviceTemplates', 'isSaving'], true)
        .setIn(['serviceTemplates', 'errors'], null);

    case SERVICE_TEMPLATE_DELETE_SUCCESS:
      // TODO: delete from redux state
      // newState = newState.setIn(['appointments', 'data'], omit(state.appointments.data, action.deletedId.toString())
      return state
        .setIn(['serviceTemplates', 'isSaving'], false)
        .setIn(['serviceTemplates', 'errors'], null)
        .setIn(['serviceTemplates', 'data'], action.deletedId.toString());

    case SERVICE_TEMPLATE_DELETE_FAILED:
      return state
        .setIn(['serviceTemplates', 'isSaving'], false)
        .setIn(['serviceTemplates', 'errors'], [action.error.message]);

    case GET_PATIENT_SERVICES:
      return state
        .setIn(['serviceTemplates', 'isLoading'], true)
        .setIn(['serviceTemplates', 'errors'], null);

    case GET_PATIENT_SERVICES_SUCCESS:
      return state
        .setIn(['serviceTemplates', 'isLoading'], false)
        .setIn(['serviceTemplates', 'errors'], null)
        .setIn(['serviceTemplates', 'data', 'patients', action.patientId], action.serviceTemplates);

    case GET_PATIENT_SERVICES_FAILED:
      return state
        .setIn(['serviceTemplates', 'isLoading'], false)
        .setIn(['serviceTemplates', 'errors'], [action.error.message]);

    case GET_RECOMENDED_SERVICE_IN_PROGRESS:
      return state
        .setIn(['serviceTemplates', 'isLoading'], true)
        .setIn(['serviceTemplates', 'errors'], null);

    case GET_RECOMENDED_SERVICE_SUCCESS:
      return state
        .setIn(['serviceTemplates', 'isLoading'], false)
        .setIn(['serviceTemplates', 'errors'], null)
        .setIn(['serviceTemplates', 'recomendedService', 'patients', action.patientId], action.serviceTemplate);

    case GET_RECOMENDED_SERVICE_FAILED:
      return state
        .setIn(['serviceTemplates', 'isLoading'], false)
        .setIn(['serviceTemplates', 'errors'], [action.error.message]);

    default:
      return state;
  }
}
