// @flow
import React from 'react';
import { connect } from 'react-redux';
import { get } from 'lodash';
import { SubscriptionWall as SubscriptionWallView } from '@views';
import type { SubscriptionState } from '@types';
import { getSubscriptionTrack, getSubscriptionUserInfo } from '@context/subscription';
import { router } from '@router';

type Props = {
  subscription: SubscriptionState,
  dispatch: Function,
};


class SubscriptionWall extends React.Component<Props> {
  componentWillMount() {
    const { dispatch, subscription } = this.props;
    dispatch(getSubscriptionTrack('wall'));
    dispatch(getSubscriptionUserInfo());
    const subscriptionActive = get(subscription, ['subscriptionData', 'subscriptionStatus']) === 'active';
    if (subscriptionActive) {
      dispatch(router.pushPath('subscriptionInfo'));
    }
  }

  componentWillReceiveProps(nextProps: Props) {
    const { dispatch, subscription } = nextProps;
    const subscriptionActive = get(subscription, ['subscriptionData', 'subscriptionStatus']) === 'active';
    const prevSubscriptionActive = get(this.props.subscription, ['subscriptionData', 'subscriptionStatus']) === 'active';
    if (subscriptionActive !== prevSubscriptionActive) {
      dispatch(router.pushPath('subscriptionInfo'));
    }
  }

  render() {
    const { subscription } = this.props;

    return (
      <SubscriptionWallView
        tackInfo={get(subscription, ['trackInfo', 'wall'], {})}
        isLoading={get(subscription, 'isLoading', false)}
        subscriptionStatus={get(subscription, ['trackInfo', 'wall', 'subscriptionStatus'], 'active')}
        trialEndsInDays={get(subscription, ['trackInfo', 'wall', 'trialEndsInDays'], 0)}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  subscription: get(state, ['context', 'subscription'], {}),
});

export default connect<any, any, any, any, any, any>(
  mapStateToProps,
)(SubscriptionWall);
