// @flow
import { get, uniqBy, filter, forEach, isFunction, isObject } from 'lodash';
import * as validatorTesters from '../rules/testers';
import type { RuleEntry, RulesMap } from './validatorTypes';
import ValidationResult from './ValidationResult';
import { ValidatorRules } from '../rules/ValidatorRules';

export default class ChainedValidator {
    formData: Object;
    rules: RulesMap;

    /**
     * ChainedValidator's Constructor
     * @param {Object} formData the complete form data
     */
    constructor() {
      this.rules = {};
    }
    /**
     * Initializes an field error validation
     * @param {string} name
     */
    field = (name: string) => new ValidatorRules(name, this)
    /**
     * Add an rule to validator
     * @param {string} field
     * @param {string} type
     * @param {string} message
     */
    pushRule = (field: string, tester: Function, message: string | Function, params?: Object = {}) => {
      const fieldRules = get(this.rules, field, []);
      fieldRules.push({ type: tester.name, message, params });
      this.rules[field] = uniqBy(fieldRules, 'rule');
      return this;
    }
    /**
     * Removes a rule from validator
     * @param {string} field
     * @param {string} type
     */
    removeRule = (field: string, type: string) => {
      const fieldRules = get(this.rules, field, []);
      this.rules[field] = filter(fieldRules, item => item.type !== type);
      return this;
    }
    /**
    * Validate form data and returns errors
    * @param {*} formData
    * @param {Object or boolean} touchedFields when pass touched fields this uses touched fields to handle validation
    */
    validate = (formData: Object, touchedFields: Object | null = null, isSubmit: boolean = false): ValidationResult => {
      const errors = {};
      const allErrors = {};
      const handleMessage = m => (typeof m === 'function' ? m(_ => window.i18n.t(_)) : m);
      /*
      * In future we should use isSubmitparams
      */
      if (touchedFields === null) {
        // eslint-disable-next-line
        console.warn('WARNIG: Avoid passing null on touchfields param in validate, prefer use of isSubmit param');
      }

      const useTouchedFields = touchedFields !== null && touchedFields !== undefined && isObject(touchedFields);

      // Set error easing
      const setError = (field: string, message: string | Function) => {
        const errs = get(allErrors, field, []);
        errs.push(handleMessage(message));
        allErrors[field] = errs;
        if (!errors[field]) {
          errors[field] = handleMessage(message);
        }
      };
      // get field value easing
      const getValue = (field: string) => get(formData, field);

      // forEach field
      forEach(Object.keys(this.rules), (fieldName: string) => {
        const rules = get(this.rules, fieldName, []);
        // forEach rules
        forEach(rules, (rule: RuleEntry) => {
          const value = getValue(fieldName);
          const tester: Function = get(validatorTesters, [rule.type, 'run']);
          const isTouch = useTouchedFields === false || get(touchedFields, fieldName, false);
          if (isFunction(tester) && (isTouch || isSubmit)) {
            if (tester(value, {
              ...rule.params,
              isSubmit,
            } || {}) === false) {
              setError(fieldName, rule.message);
            }
          }
        });
      });

      return new ValidationResult(errors, allErrors);
    }
}
