// @flow
import React from 'react';
import { isEqual, get } from 'lodash';
import { Button, Icon } from '@elements';
import classNames from 'classnames';
import { withTranslation } from 'react-i18next';
import Queue from 'smart-request-balancer';
import { easyOpenCache, arrayBufferToBase64 } from '@helpers/helpers';
import './AttachmentPreview.scss';

type ImageType = {
  downloadUrl: string,
  extension: string,
  id: number,
  largeUrl: string,
  lsId: string,
  name: string,
  size: number,
  thumbUrl: string,
};

type Props = {
  img: ImageType,
  className?: string,
  alt?: string,
  attemps?: number,
  onClick?: Function,
  onMaxAttemps?: Function,
  t: Function,
};

type State = {
  isLoading: boolean,
  imageSource: any,
  currErrors: any,
};

window.imageRequestQueue = new Queue({
  rules: {
    attachments: {
      limit: 1,
      rate: 1,
      priority: 1,
    },
  },
});

const pickProps = (p: any) => [p.i18n.language, p.img.id, p.img.thumbUrl, p.img.name];

class AttachmentPreview extends React.Component<Props, State> {
  static defaultProps = {
    attemps: 5,
  };
  constructor(props: Props) {
    super(props);
    this.state = {
      isLoading: false,
      imageSource: null,
      currErrors: 0,
    };
    this.elementRef = React.createRef();
  }

  componentWillMount() {
  }

  componentDidMount() {
    this.handleLoadImage(this.props.img.thumbUrl);
  }

  componentWillReceiveProps(nextProps: Props) {
    if (this.props.img.id !== nextProps.img.id) {
      this.handleLoadImage(nextProps.img.thumbUrl);
    }
    if (get(this.props.img, ['thumbUrl']) !== get(nextProps.img, ['thumbUrl'])) {
      window.imageRequestQueue.remove(get(this.props.img, ['thumbUrl']));
    }
  }

  shouldComponentUpdate(nextProps: Props, nextState: State) {
    return !isEqual(pickProps(this.props), pickProps(nextProps)) || !isEqual(this.state, nextState);
  }

  componentWillUnmount() {
    // const urlCreator = window.URL || window.webkitURL;
    // urlCreator.revokeObjectURL(this.state.imageSource);
    window.imageRequestQueue.remove(get(this.props.img, ['thumbUrl']));
  }

  render() {
    const {
      img, alt, className, attemps,
    } = this.props;

    const { isLoading, currErrors, imageSource } = this.state;
    // const { isLoading, currErrors, imageSource } = { isLoading: false, currErrors: 999, imageSource: null };

    return (
      <div
        className={classNames('attachmentPreview', className, { isLoading, isError: currErrors > attemps })}
        onClick={this.handleClick}
        ref={this.elementRef}
      >
        {(isLoading || !imageSource) && currErrors <= attemps && (
        <div className="loadingContent">
          <Icon fill="#fff" size={50} iconName="rolling-spinner" />
          <div className="statusText">
            {this.props.t('global:label/loading', 'global')}
          </div>
        </div>
        )}
        <div attachmentPreview="attachmentPreviewContent">
          {!currErrors < attemps && imageSource && (
          <img src={imageSource} alt={alt || img.name} onLoad={this.onLoadEnd}/>
          )}
          {currErrors > attemps && (
          <div className="errorBox">
            <Button onClick={this.onReloadClick}>{this.props.t('global:action/reload', 'global')}</Button>
          </div>
          )}
        </div>
      </div>
    );
  }

  elementRef: any;

  handleLoadImage = (thumbUrl: string) => {
    this.setState({ isLoading: true });

    if (this.state.currErrors > this.props.attemps) {
      this.setState({ isLoading: false });
      if (this.props.onMaxAttemps) {
        this.props.onMaxAttemps();
      }
    } else {
      this.fetchImage(thumbUrl);
    }
  };

  handleClick = () => {
    if (this.props.onClick) {
      this.props.onClick();
    }
  };

  onReloadClick = (e: Object) => {
    e.stopPropagation();
    e.preventDefault();
    this.setState({ currErrors: 0 }, () => this.handleLoadImage(this.props.img.thumbUrl));
  };

  handlError = () => {
    this.setState({ currErrors: this.state.currErrors + 1 }, () => setTimeout(() => this.handleLoadImage(this.props.img.thumbUrl), 1000));
  };

  fetchImage = (thumbUrl: string) => {
    easyOpenCache('attachment-thumbs', (cache: window.Cache) => {
      const executeRequest = () => window
        .imageRequestQueue.request(() => fetch(thumbUrl)
          .then((r) => {
            if (!r.ok) {
              this.handlError();
              return;
            }
            if (cache) {
              cache.add(thumbUrl);
            }

            r.arrayBuffer().then((buffer) => {
              try {
                const base64Flag = 'data:image/jpeg;base64,';
                const imageStr = arrayBufferToBase64(buffer);
                this.setState({ imageSource: base64Flag + imageStr, currErrors: 0, isLoading: false });
              } catch (err) {
                this.handlError();
              }
            }).catch(this.handlError);
          })
          .catch(this.handlError), thumbUrl, 'attachments');

      if (cache) {
        cache.match(thumbUrl, {
          ignoreSearch: true,
          ignoreVary: true,
        }).then((response) => {
          if (response && response.ok) {
            response.arrayBuffer().then((buffer) => {
              try {
                const base64Flag = 'data:image/jpeg;base64,';
                const imageStr = arrayBufferToBase64(buffer);
                this.setState({ imageSource: base64Flag + imageStr, currErrors: 0, isLoading: false });
              } catch (err) {
                this.handlError();
              }
            }).catch(this.handlError);
          } else {
            this.setState({ isLoading: true }, () => {
              executeRequest();
            });
          }
        }).catch(() => {
          this.setState({ isLoading: true }, () => {
            executeRequest();
          });
        });
      } else {
        this.setState({ isLoading: true }, () => {
          executeRequest();
        });
      }
    });
  };

  onLoadEnd = () => {
    this.setState({ isLoading: false });
  };
}

export default withTranslation()(AttachmentPreview);
