// @flow
import React from 'react';
import { Button } from '@elements';
import { withTranslation } from 'react-i18next';
import { createNsTranslator } from '@helpers/helpers';
import classNames from 'classnames';

type Props = {
  onDelete?: ?Function,
  onSave?: Function,
  onCancel?: Function,
  isLoading?: boolean,
  disabledSave?: boolean,
  disabledCancel?: boolean,
  disabledDelete?: boolean,
  editFeature?: boolean,
  edited?: boolean,
  t: Function,
  saveText?: String,
  savingText?: String,
  cancelText?: String,
  cancelLinkTo?: string,
  deleteText?: String,
  children: any,
};

class FormFooter extends React.Component<Props> {
  render() {
    const {
      onDelete, isLoading, disabledCancel,
      disabledDelete, disabledSave, onCancel,
      edited, editFeature, saveText, cancelText, deleteText,
      savingText, cancelLinkTo, children,
    } = this.props;

    const _ = createNsTranslator('global', this.props.t);

    const saveLabel = (edited ? saveText || _('action/save') : _('action/saved'));

    return (
      <div className="actionsFooter">
        {(onCancel && ((window.isCordovaApp && window.innerWidth > 1019) || !cancelLinkTo)) &&
          <Button
            text={cancelText || _('action/cancel')}
            disabled={disabledCancel}
            onClick={this.onCancel}
            type="button"
          />
        }

        {onCancel && cancelLinkTo && window.isCordovaApp && window.innerWidth <= 1019 &&
          <Button
            text={cancelText || _('action/cancel')}
            disabled={disabledCancel}
            linkTo={cancelLinkTo}
            type="button"
          />
        }

        {onDelete &&
          <Button
            text={deleteText || _('action/delete')}
            className="deleteButtonForm"
            disabled={disabledDelete || (editFeature && !edited)}
            onClick={onDelete}
            type="button"
          />
        }

        <Button
          disableSpinner={editFeature}
          loadingMessage={savingText || _('label/saving_simple')}
          isLoading={isLoading}
          disabled={disabledSave || (editFeature && !edited)}
          className={classNames('primary', { mobileDelFix: window.isCordovaApp && window.innerWidth <= 450 && onDelete })}
          text={editFeature ? saveLabel : saveText || _('action/save')}
          type="button"
          onClick={this.onSave}
        />

        {children}
      </div>
    );
  }

  onSave = () => {
    if (this.props.onSave) {
      this.props.onSave();
    }
  };

  onCancel = () => {
    if (this.props.onCancel) {
      this.props.onCancel();
    }
  };
}

export default withTranslation()(FormFooter);
