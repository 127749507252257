import React from 'react';
import moment from 'moment';
import { isEmpty, get } from 'lodash';
import { PlatformSwitch, AppMobileHeader } from '@blocks';
import { Icon } from '@elements';
import type { Patient } from '@types';
import classnames from 'classnames';
import { withTranslation } from 'react-i18next';
import { createNsTranslator, isPresent, getExtensiveAge } from '@helpers/helpers';
import './PatientSummary.scss';

type Props = {
    patient: Patient,
    onCloseMobileModal?: Function,
    mobileVisible?: boolean,
    t: Function,
};

type State = {
  toogledMobileVisible: boolean,
}


class PatientSummary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      toogledMobileVisible: false,
    };
  }

  componentWillReceiveProps(next: Props) {
    if (next.mobileVisible) {
      this.setState({ toogledMobileVisible: true });
    }
  }


  render () {
    const { patient, onCloseMobileModal, mobileVisible } = this.props;

    const { toogledMobileVisible } = this.state;
    const birthdate = get(patient, 'birthdate', '');
    const profession = get(patient, 'profession', '');
    const insuranceCompany = get(patient, 'insuranceCompany', '');

    const showSummaryBirthdate = birthdate ? moment(birthdate).isValid() : false;
    const showSummaryProfession = profession && !isEmpty(profession);
    const showSummaryInsuranceCompany = !isEmpty(insuranceCompany);

    const _ = createNsTranslator('global', this.props.t);

    const patientDate = getExtensiveAge(birthdate);

    const isPresentDetails = showSummaryBirthdate || showSummaryProfession || showSummaryInsuranceCompany;

    const mixedContent = (mobile: boolean) => (
      <React.Fragment>
        <ul className="summary">
          {!mobile && isPresentDetails && <li className="headingSeparator">{_('label/details')}</li>}
          {showSummaryBirthdate && isPresent(patientDate) &&
          <li className="listItem notClickable">
            <span>
              <Icon iconName="birthday" size={18} />
              <span>{patientDate}</span>
            </span>
          </li>
          }
          {showSummaryProfession &&
          <li className="listItem notClickable">
            <span>
              <Icon iconName="work" size={18} />
              <span>{patient.profession}</span>
            </span>
          </li>
              }
          {showSummaryInsuranceCompany &&
          <li className="listItem notClickable">
            <span>
              <Icon iconName="healthcare" size={20} />
              <span>{patient.insuranceCompany}</span>
            </span>
          </li>
              }
        </ul>
      </React.Fragment>
    );

    return (
      <PlatformSwitch
        inject
        desktop={() => mixedContent(false)}
        mobile={() => (
          <React.Fragment>
            <div className={classnames('modalSummary', mobileVisible ? 'visible' : toogledMobileVisible && 'invisible')} onClose={onCloseMobileModal}>
              <AppMobileHeader backIcon="close" inline title={_('label/details')} onBackClick={this.handleBackClick} />
              {mixedContent(true)}
            </div>
          </React.Fragment>
        )}
      />
    );
  }

  handleBackClick = () => {
    if (this.props.onCloseMobileModal) {
      this.props.onCloseMobileModal();
    }
    return false;
  }
}

export default withTranslation()(PatientSummary);

