
import React, { useState, useCallback, useMemo, useRef, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { get, findIndex, clamp } from 'lodash';
import { Icon, Spinner } from '@elements';
import { isPresent, UserNotifications, sort, numberCompare, useEventListener, useIsMobile, saveToDeviceDownloadFolder } from '@helpers/helpers';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import CachedImage from './CachedImage';


type Props = {
  first: number,
  images: Object[],
  isEdit: boolean,
  onDelete: Function,
  onClose: Function,
  onDisplayAttachmentFileName: Function,
  isDownloading: boolean,
  recordId: number,
  downloadUrls: {
    [key: string] : {
      [key: string]: string,
    },
  },
  onRequestDownloadImage: Function, // (attachmentId: number) => void
};


const getLightBoxContainer = () => {
  let el = document.getElementById('lightBoxImageContainer');

  if (!el) {
    el = document.createElement('div');
    el.setAttribute('id', 'lightBoxImageContainer');
    document.body.appendChild(el);
  }

  return el;
};

const ImagesPreview = ({
  isEdit, first, onDelete, onClose, onRequestDownloadImage, isDownloading, downloadUrls, recordId, onDisplayAttachmentFileName, ...props
}: Props) => {
  const images = useMemo(() => sort(props.images, numberCompare('id')), [props.images]);
  const [isMobile] = useIsMobile();

  const [photoIndex, setPhotoIndex] = useState(findIndex(images, img => img.id === first));
  const [zoom, setZoom] = useState(1);
  const [enabled, setEnabled] = useState(true);
  const [waitRender, setWaitRender] = useState(false);
  const previewRef = useRef(null);
  const idCacheRef = useRef(null);
  const [donwloading, setDownloading] = useState(false);

  const handleDelete = useCallback(() => {
    const imgId = get(images, [String(photoIndex), 'id'], null);
    if (isPresent(imgId)) {
      UserNotifications.confirmI18n('alert/confirm_delete_file', 'warning', '#FFA433')
        .then((confirmed) => {
          if (confirmed) {
            onDelete(imgId, true)();
            const nextPIndex = ((photoIndex + images.length) - 1) % images.length;
            setPhotoIndex(nextPIndex);
            if (images.length <= 0) {
              onClose();
            }
          }
        });
    }
  }, [images, photoIndex, onDelete, onClose]);

  const deleteToolbarAction = useMemo(() => (isEdit ? [
    <div className="clickAction" onClick={handleDelete}>
      <Icon
        iconName="delete"
        className="icon"
        size={18}
      />
    </div>,
  ] : []), [isEdit, handleDelete]);

  const handleShowNextImage = useCallback(() => {
    const next = clamp(photoIndex + 1, 0, images.length - 1);
    if (next !== photoIndex) {
      setEnabled(false);
      setWaitRender(true);
      setPhotoIndex(next);
      setZoom(1);
      setTimeout(() => setEnabled(true), 100);
      setTimeout(() => setWaitRender(false), 100);
    }
  }, [images, photoIndex]);

  const handleShowPrevImage = useCallback(() => {
    const prev = clamp(photoIndex - 1, 0, images.length - 1);
    if (prev !== photoIndex) {
      setEnabled(false);
      setWaitRender(true);
      setPhotoIndex(prev);
      setZoom(1);
      setTimeout(() => setEnabled(true), 100);
      setTimeout(() => setWaitRender(false), 100);
    }
  }, [images, photoIndex]);

  const currentImage = useMemo(() => get(images, [String(photoIndex)], null), [images, photoIndex]);

  if (!isMobile) {
    // eslint-disable-next-line
    useEventListener('keydown', (e) => {
      if (e.keyCode === 27) {
        onClose();
      } else if (e.keyCode === 39) {
        handleShowNextImage();
      } else if (e.keyCode === 37) {
        handleShowPrevImage();
      }
    });
  }

  const increaseZoom = useCallback(() => {
    setZoom(z => {
      const newZ = clamp(z + 0.5, 1, 5);
      if (newZ === 1) {
        setEnabled(false);
        setTimeout(() => setEnabled(true), 1);
      }
      return newZ;
    });
  }, []);

  const decreaseZoom = useCallback(() => {
    setZoom(z => {
      const newZ = clamp(z - 0.5, 1, 5);
      if (newZ === 1) {
        setEnabled(false);
        setTimeout(() => setEnabled(true), 1);
      }
      return newZ;
    });
  }, []);

  const currentImageUrl = useMemo(() => get(downloadUrls, [recordId, get(currentImage, 'id', null)]), [currentImage, recordId, downloadUrls]);

  useEffect(() => {
    const currId = get(currentImage, 'id', null);
    if (idCacheRef.current !== currId && !currentImageUrl) {
      onRequestDownloadImage(get(currentImage, 'id', null), () => {}, true);
    }
  }, [onRequestDownloadImage, currentImage, currentImageUrl]);

  // const handleRefresh = useCallback(() => onRequestDownloadImage(get(currentImage, 'id', null)), [onRequestDownloadImage, currentImage]);

  const handleDownload = useCallback(() => {
    if (donwloading) return;

    setDownloading(true);

    onRequestDownloadImage(get(currentImage, 'id', null), (url: string | null) => {
      if (!url) {
        setDownloading(false);
        return;
      }

      if (window.isCordovaApp && window.cordova) {
        const onCatch = (e) => {
          // eslint-disable-next-line
          console.error(e);
          setDownloading(false);
          onDisplayAttachmentFileName(false);
        };
        // eslint-disable-next-line
        const filename = `${(new Date()).getTime()}-${url.replace(/[\#\?].*$/, '').replace(/^.*[\\\/]/, '')}`;
        fetch(url, {
          method: 'GET',
        })
          .then(res => res.blob().then((blob) => {
            saveToDeviceDownloadFolder(blob, filename).then((filePath) => {
              onDisplayAttachmentFileName(true, filename, filePath);
              setDownloading(false);
            }).catch(onCatch);
          }).catch(onCatch))
          .catch(onCatch);
      } else {
        setDownloading(false);
        window.open(url, '_blank');
      }
    });
  }, [currentImage, onRequestDownloadImage, donwloading, onDisplayAttachmentFileName]);

  const toolbarActions = useMemo(() => (
    <React.Fragment>
      {deleteToolbarAction}
      <div className="clickAction" onClick={handleDownload} >
        {!donwloading && <Icon iconName={isMobile ? 'share' : 'download'} size={17} />}
        {donwloading && <Spinner fill="#fff"/>}
      </div>
      <div className="clickAction" onClick={increaseZoom} >
        <Icon iconName="zoom-in" size={17} />
      </div>
      <div className="clickAction" onClick={decreaseZoom} >
        <Icon iconName="zoom-out" size={17} />
      </div>
      <div className="clickAction" onClick={onClose} >
        <Icon iconName="close" />
      </div>
    </React.Fragment>
  ), [deleteToolbarAction, increaseZoom, handleDownload, decreaseZoom, donwloading, isMobile, onClose]);

  return images.length > 0 && ReactDOM.createPortal((
    <div className="imageLightBox">
      <div className="toolbar">
        <span className="title">Foto</span>
        {!isMobile && toolbarActions}
      </div>
      <div className="previewBox" ref={previewRef}>
        {photoIndex > 0 && (
          <div className="navigation left" onClick={handleShowPrevImage}><Icon iconName="arrow-left" /></div>
        )}
        {(photoIndex + 1) < images.length && (
          <div className="navigation right" onClick={handleShowNextImage}><Icon iconName="arrow-right" /></div>
        )}
        {enabled && !!currentImageUrl && (
          <TransformWrapper
            scale={zoom}
            options={{
              disabled: zoom === 1,
            }}
            wheel={{ disabled: true }}
          >
            {() => (
              <TransformComponent>
                <CachedImage
                  src={currentImageUrl}
                  className={waitRender ? 'wait' : ''}
                />
              </TransformComponent>
            )}
          </TransformWrapper>
        )}
      </div>
      {isMobile && (
        <div className="toolbar foot">
          {toolbarActions}
        </div>
      )}
    </div>
  ), getLightBoxContainer());
};


// $FlowFixMe
export default React.memo(ImagesPreview);
