import React from 'react';
import { Icon, Tooltip } from '@elements';
import { useTranslationNs } from '@helpers/helpers';
import './MobilePhoneLinks.scss';

type Props = {
    mobilePhone: string;
    whatsapp?: boolean;
};

const MobilePhoneLinks = ({ mobilePhone, whatsapp }: Props) => {
  const [_] = useTranslationNs('global');

  return (
    <div className="mobilePhoneLinks">
      {whatsapp && (
      <Tooltip text="Whatsapp" toolTipClass="phoneLinksTooltip" >
        <a href={`https://wa.me/55${mobilePhone || ''}`} target="_blank" className="callPatientPhoneLink" >
          <Icon iconName="whatsapp" size={18} />
        </a>
      </Tooltip>
      )}
      <Tooltip text={_('label/call_phone')} toolTipClass="phoneLinksTooltip" >
        <a href={`tel:0${mobilePhone || ''}`} className="callPatientPhoneLink"><Icon iconName="call" size={17} /></a>
      </Tooltip>
    </div>
  );
};

// $FlowFixMe
export default React.memo(MobilePhoneLinks);
