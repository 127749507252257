// @flow
import React from 'react';
import { Loading, Button } from '@elements';
import { get } from 'lodash';
import type { Patient } from '@types';
import moment from 'moment';
import { withTranslation } from 'react-i18next';
import { createNsTranslator } from '@helpers/helpers';
import './PatientDeleted.scss';

type Props = {
  patient: Patient,
  isLoading: boolean,
  onRecover: Function,
  t: Function,
};

class PatientDeleted extends React.Component<Props> {
  render() {
    const { patient, isLoading, onRecover } = this.props;

    const _ = createNsTranslator('global', this.props.t);

    if (isLoading || !patient) {
      return <Loading />;
    }

    const deletedAt = moment(patient.deletedAt).format('DD/MM/YYYY');

    return (
      <div id="patientDeleted">
        <div className="centralizedContent">
          <div className="patientPhoto">
            {patient && patient.profileImage &&
              <img className="photo" src={get(patient, ['profileImage', 'thumbUrl'])} alt={patient.name} />
            }
          </div>
          <span className="patientName">{patient.name}</span>
          <span className="deletedText">{_('label/patient_deleted')}</span>
          <span className="baseline">{_('label/patient_deleted_in_day')} {deletedAt}</span>
          <span className="baseline">{_('label/patient_deleted_by')} {patient.deletedByUserName}</span>

          <div className="recoverBtnBox">
            <Button
              className="primary recoverBtn"
              text={_('label/recover_patient')}
              onClick={onRecover}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(PatientDeleted);
