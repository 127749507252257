// @flow
import React from 'react';
import type { Node } from 'react';
import { isEqual } from 'lodash';
import classnames from 'classnames';
import './Checkbox.scss';

// TODO: check if "name" prop should be required
type Props = {
  children?: Node,
  label?: string,
  id?: string,
  name?: string,
  className?: string,
  isChecked?: boolean,
  onChange?: Function,
  disabled?: boolean,
};

export default class Checkbox extends React.Component<Props> {
  shouldComponentUpdate(nextProps: Props) {
    return !isEqual(this.props, nextProps);
  }

  render() {
    const { isChecked } = this.props;
    const {
      label, className, disabled,
      id, name, children,
    } = this.props;

    const cssClasses = classnames(['checkboxHolder', className]);

    return (
      <div
        className={cssClasses}
        onClick={!disabled ? this.onChange.bind(this) : null}
      >
        <input
          className="checkbox"
          id={id}
          name={name}
          type="checkbox"
          checked={this.props.isChecked || isChecked}
          disabled={disabled}
          onChange={() => {}}
        />
        {label && <label className="text" tabIndex="0" onKeyDown={this.onKeyDown}>{label}</label>}
        {children}
      </div>
    );
  }

  onChange = () => {
    const {
      name, onChange, disabled, isChecked,
    } = this.props;
    if (onChange && disabled !== true) {
      onChange({ name, value: !isChecked });
    }
  };

  onKeyDown = (e: any) => {
    const {
      name, onChange, disabled, isChecked,
    } = this.props;

    if (e.keyCode === 32) {
      e.stopPropagation();
      e.preventDefault();
      if (onChange && disabled !== true) {
        onChange({ name, value: !isChecked });
      }
    }
  };
}
