// @flow
import React from 'react';
import { isEqual } from 'lodash';
import type Moment from 'moment';
import { CustomDayPicker, Input } from '../../elements';
import { toDate, formatDate } from '@helpers/helpers';

import './DayPickerInput.scss';

type State = {
  selectedDate: Moment,
  tempSelectedDate: Moment,
  isOpen: boolean,
};

type Props = {
  name?: string,
  label?: string,
  date?: Moment | Date | string,
  onSelect?: Function,
  customRenderer?: Function,
  onClick?: Function,
  required?: boolean,
  className?: string,
  advancedMode?: boolean,
  tabIndex?: any,
  disabled?: boolean,
};

export default class DayPickerInput extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      selectedDate: toDate(props.date),
      tempSelectedDate: toDate(props.date),
      isOpen: false,
    };
  }

  componentWillReceiveProps(nextProps: Props) {
    if (isEqual(nextProps.date, this.props.date)) return;

    this.setState({ selectedDate: toDate(nextProps.date) });
  }

  render() {
    const {
      customRenderer, label, required, className, advancedMode, tabIndex, disabled,
    } = this.props;
    const { isOpen, tempSelectedDate, selectedDate } = this.state;

    return (
      <div className="dayPickerInputWrapper" tabIndex={tabIndex} onKeyDown={this.keyDown}>
        {customRenderer ? (
          customRenderer(formatDate(selectedDate), this.handleToglePicker)
        ) : (
          <Input
            label={label}
            value={formatDate(selectedDate)}
            readOnly
            onClick={this.handleToglePicker.bind(this)}
            required={required}
            className={className}
            disabled={disabled}
          />
        )}
        <CustomDayPicker
          advancedMode={advancedMode}
          withOverlay
          disabled={disabled}
          showButtons
          isOpen={isOpen && !disabled}
          onSelectDay={this.handleSelectDate}
          onMonthChange={this.handleSelectDate}
          selectedDate={tempSelectedDate}
          onCancel={this.handleCancelDayPicker}
          onAccept={this.handleAcceptDayPicker}
        />
      </div>
    );
  }


  keyDown = (e: any) => {
    if (this.props.disabled) return;
    if (e.keyCode === 32 || e.keyCode === 13) {
      e.stopPropagation();
      e.preventDefault();
      this.handleToglePicker();
    }
  };

  handleToglePicker = () => {
    if (this.props.disabled) return;
    if (this.props.onClick) {
      // Manipulate with DayPicker manualy
      this.props.onClick();
    } else {
      this.setState({ isOpen: !this.state.isOpen });
    }
  };

  handleSelectDate = (date: string) => {
    if (this.props.disabled) return;

    this.setState({ tempSelectedDate: toDate(date) });
  };

  handleAcceptDayPicker = () => {
    if (this.props.disabled) return;
    this.setState({
      selectedDate: this.state.tempSelectedDate,
      isOpen: false,
    }, () => {
      const { onSelect, name } = this.props;
      if (onSelect) {
        onSelect({ name, value: this.state.tempSelectedDate });
      }
    });
    return this.state.tempSelectedDate;
  };

  handleCancelDayPicker = () => {
    if (this.props.disabled) return;

    this.setState({
      tempSelectedDate: this.state.selectedDate,
      isOpen: false,
    });
  };
}
