// @flow
import React from 'react';
import { GetStarted as GetStartedView } from '../components/views';

export default class GetStarted extends React.Component<void> {
  render() {
    return (
      <GetStartedView />
    );
  }
}
