// @flow
import React from 'react';
import { connect } from 'react-redux';
import type { SubscriptionUserInfoInput, PlanType, SubscriptionState } from '@types';
import { updateUserInfo, getSubscriptionUserInfo } from '@context/subscription';
import { SubscriptionAddress as SubscriptionAddressView } from '@views';
import { get } from 'lodash';
import { Loading } from '@elements';
import { isPresent } from '@helpers/helpers';
import { router } from '@router';

type Props = {
  dispatch: Function,
  formData: Object,
  selectedPlan: PlanType,
  isSaving: boolean,
  subscription: SubscriptionState,
};

class SubscriptionAddress extends React.Component<Props> {
  componentWillMount() {
    this.props.dispatch(getSubscriptionUserInfo());
  }

  render() {
    const isLoadingData = get(this.props.subscription, 'isLoading', false);

    if (isLoadingData) {
      return (
        <div id="addressPage" className="appContent formPage">
          <section className="mainSection">
            <div className="centerContent"><Loading /></div>
          </section>
        </div>
      );
    }

    if (!isPresent(this.props.subscription.selectedPlan) && !this.props.subscription.isLoading && !this.props.subscription.isSaving) {
      this.props.dispatch(router.pushPath('pricing'));
    }

    return (
      <SubscriptionAddressView
        onSave={this.handleSave}
        formData={this.props.formData}
        isLoading={this.props.isSaving}
      />
    );
  }

  handleSave = (info: SubscriptionUserInfoInput) => {
    const { dispatch, selectedPlan } = this.props;
    dispatch(updateUserInfo(selectedPlan, info));
  };
}


const mapStateToProps = (state) => ({
  formData: get(state, ['context', 'subscription', 'userInfo'], {}),
  isSaving: get(state, ['context', 'subscription', 'isSaving'], false),
  selectedPlan: get(state, ['context', 'subscription', 'selectedPlan'], ''),
  subscription: get(state, ['context', 'subscription'], {}),
});

export default connect<any, any, any, any, any, any>(
  mapStateToProps,
)(SubscriptionAddress);
