// @flow
import React from 'react';
import { concat, includes, without, last, get } from 'lodash';
import { withRouter } from 'react-router-dom';
import { router } from '@router';
import { SettingsMenu, FormFooter, PlatformSwitch, AppMobileHeader, AppMobileFooter } from '@blocks';
import type { ExamsPrescriptionsTemplate, User, FormElementData, BundledPermissions } from '@types';
import { Breadcrumb, Input, TextArea } from '@elements';
import { withTranslation } from 'react-i18next';
import { createNsTranslator } from '@helpers/helpers';

type Props = {
  examsPrescriptionsTemplate: ExamsPrescriptionsTemplate,
  history: Object,
  onCreateExamsPrescriptionsTemplate: Function,
  onUpdateExamsPrescriptionsTemplate: Function,
  onDeleteExamsPrescriptionsTemplate: Function,
  onLogout: Function,
  t: Function,
  user: User,
  permissions: BundledPermissions,
};

type State = {
  name: string,
  notes: string,
  errors: Array<string>,
};

class ExamsPrescriptionsTemplateForm extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    const { examsPrescriptionsTemplate } = this.props;
    this.state = {
      name: examsPrescriptionsTemplate.name || '',
      notes: examsPrescriptionsTemplate.notes || '',
      errors: [],
    };
  }

  render() {
    const { name, notes, errors } = this.state;
    const { examsPrescriptionsTemplate, user, onLogout } = this.props;

    const _ = createNsTranslator('settings', this.props.t);

    const breadcrumbLinks = [
      { text: _('label/prescription_menu/title'), linkTo: '/ajustes/modelos' },
      { text: _('label/exam_models/list/title'), linkTo: '/ajustes/modelos/exames' },
    ];

    if (examsPrescriptionsTemplate.id) { // if we are editing an existing exams prescriptions template
      breadcrumbLinks.push({ text: name });
    } else { // if we are creating a new exams prescriptions template
      breadcrumbLinks.push({ text: _('label/exam_models/list/new_exam') });
    }

    const pageContent = (
      <React.Fragment>
        <div className="toolbar dashed toolbarSteticFix">
          <h1>{get(last(breadcrumbLinks), 'text')}</h1>
        </div>
        <Input
          type="text"
          name="name"
          autofocus
          className="field"
          label={_('label/exam_models/form/model_name')}
          value={name}
          onChange={this.handleFormChange}
          onBlur={this.handleFormBlur}
          error={includes(errors, 'name') ? _('label/exam_models/form/error_empty') : ''}
          required
        />
        <TextArea
          name="notes"
          className="field prescriptionRtEditor"
          label={_('label/text', 'global')}
          value={notes}
          onChange={this.handleFormChange}
          onBlur={this.handleFormBlur}
          error={includes(errors, 'notes') ? _('label/exam_models/form/error_empty') : ''}
          placeholder={_('label/exam_models/form/placeholder_content')}
          required
        />
        {examsPrescriptionsTemplate.id ? (
          <FormFooter
            onSave={this.onSaveExamsPrescriptionsTemplate}
            onCancel={this.onCancel}
            includeDelete
            onDelete={this.onDeleteExamsPrescriptionsTemplate.bind(this, examsPrescriptionsTemplate.id)}
          />) : (
            <FormFooter onSave={this.onSaveExamsPrescriptionsTemplate} onCancel={this.onCancel} />
        )
        }
      </React.Fragment>
    );

    return (
      <div className="appContent settingsPage">
        <PlatformSwitch
          inject
          desktop={() => (
            <React.Fragment>
              <aside className="sidebar">
                <SettingsMenu user={user} onLogout={onLogout} permissions={this.props.permissions} />
              </aside>
              <section className="mainSection" id="drugsPrescriptionsTemplateFormDesktopForm">
                <div className="centerContent">
                  <Breadcrumb items={breadcrumbLinks} />
                  {pageContent}
                </div>
              </section>
            </React.Fragment>
          )}
          mobile={() => (
            <React.Fragment>
              <AppMobileHeader title={_('label/exam_models/list/title')} backLinkTo="/ajustes/modelos/exames" />
              <section className="mainSection">
                <div className="centerContent">{pageContent}</div>
              </section>
              <AppMobileFooter />
            </React.Fragment>
          )}
        />
      </div>
    );
  }

  checkForErrors = (value: (string | number | boolean), fieldName: string) => {
    const { errors } = this.state;

    if (value === '') {
      this.setState({ errors: concat(errors, [fieldName]) });
    } else if (includes(errors, fieldName)) {
      this.setState({ errors: without(errors, fieldName) });
    }
  };

  handleFormChange = (data: FormElementData) => {
    const { name, value } = data;
    this.setState({ [name]: value }, () => this.checkForErrors(value, name));
  };

  handleFormBlur = (data: FormElementData) => {
    const { name, value } = data;
    this.checkForErrors(value, name);
  };

  onSaveExamsPrescriptionsTemplate = () => {
    const {
      examsPrescriptionsTemplate,
      onUpdateExamsPrescriptionsTemplate,
      onCreateExamsPrescriptionsTemplate,
    } = this.props;
    const { name, notes } = this.state;

    const data = {
      name,
      notes,
    };
    const errors = [];

    if (name === '') {
      if (!includes(this.state.errors, 'name')) {
        this.setState({ errors: concat(errors, ['name']) });
      }
      errors.push('name');
    }

    if (notes === '') {
      if (!includes(this.state.errors, 'notes')) {
        this.setState({ errors: concat(errors, ['notes']) });
      }
      errors.push('notes');
    }

    if (!errors.length) {
      if (examsPrescriptionsTemplate.id) { // if we are editing an existing exams prescriptions template
        onUpdateExamsPrescriptionsTemplate(examsPrescriptionsTemplate.id, data);
        this.redirectToPrescriptionsList();
      } else { // if we are creating a new exams prescriptions template
        onCreateExamsPrescriptionsTemplate(data);
        this.redirectToPrescriptionsList();
      }
    }
  };

  onDeleteExamsPrescriptionsTemplate = (templateId: number) => {
    this.props.onDeleteExamsPrescriptionsTemplate(templateId);
    this.redirectToPrescriptionsList();
  };

  onCancel = () => {
    this.redirectToPrescriptionsList();
  };

  redirectToPrescriptionsList = () => {
    this.props.history.push(router.getRoutePath('examsPrescriptionsTemplatesList'));
  };
}

export default withTranslation()(withRouter(ExamsPrescriptionsTemplateForm));
