// @flow
import {
  get, omit, concat,
  without,
} from 'lodash';
import { reportError } from '@helpers/helpers';
import { Api } from '../../../helpers';
import { router } from '../../../routes/router';
import type {
  Action,
  Dispatch,
  ContextState,
  DrugsPrescriptionInput,
} from '../../../types';
import showPDF from '../../middleware/showPDF';


const NOP = () => {};

// ------------------------------------
// Constants
// ------------------------------------
const DRUGS_PRESCRIPTION_SHOW_PDF_IN_PROGRESS = 'drugsPrescriptions.DRUGS_PRESCRIPTION_SHOW_PDF_IN_PROGRESS';
const DRUGS_PRESCRIPTION_SHOW_PDF_SUCCESS = 'drugsPrescriptions.DRUGS_PRESCRIPTION_SHOW_PDF_SUCCESS';
const DRUGS_PRESCRIPTION_SHOW_PDF_FAILED = 'drugsPrescriptions.DRUGS_PRESCRIPTION_SHOW_PDF_FAILED';

const DRUGS_PRESCRIPTION_CREATE_IN_PROGRESS = 'drugsPrescriptions.DRUGS_PRESCRIPTION_CREATE_IN_PROGRESS';
const DRUGS_PRESCRIPTION_CREATE_SUCCESS = 'drugsPrescriptions.DRUGS_PRESCRIPTION_CREATE_SUCCESS';
const DRUGS_PRESCRIPTION_CREATE_FAILED = 'drugsPrescriptions.DRUGS_PRESCRIPTION_CREATE_FAILED';

const DRUGS_PRESCRIPTION_UPDATE_IN_PROGRESS = 'drugsPrescriptions.DRUGS_PRESCRIPTION_UPDATE_IN_PROGRESS';
const DRUGS_PRESCRIPTION_UPDATE_SUCCESS = 'drugsPrescriptions.DRUGS_PRESCRIPTION_UPDATE_SUCCESS';
const DRUGS_PRESCRIPTION_UPDATE_FAILED = 'drugsPrescriptions.DRUGS_PRESCRIPTION_UPDATE_FAILED';

const DRUGS_PRESCRIPTION_DELETE_IN_PROGRESS = 'drugsPrescriptions.DRUGS_PRESCRIPTION_DELETE_IN_PROGRESS';
const DRUGS_PRESCRIPTION_DELETE_SUCCESS = 'drugsPrescriptions.DRUGS_PRESCRIPTION_DELETE_SUCCESS';
const DRUGS_PRESCRIPTION_DELETE_FAILED = 'drugsPrescriptions.DRUGS_PRESCRIPTION_DELETE_FAILED';

// ------------------------------------
// Actions
// ------------------------------------
export const showDrugsPrescriptionPDF = (recordId: number, prescriptionId: number,
  showSignature: boolean, sendEmail: boolean = false) => showPDF.action({
  title: 'Drugs Prescription PDF',
  apiRoute: router.getApiRoute('showDrugsPrescriptionPDF', { recordId, id: prescriptionId, showSignature: showSignature && 'true', sendEmail: sendEmail && 'true' }),
  prepare: DRUGS_PRESCRIPTION_SHOW_PDF_IN_PROGRESS,
  success: DRUGS_PRESCRIPTION_SHOW_PDF_SUCCESS,
  error: DRUGS_PRESCRIPTION_SHOW_PDF_FAILED,
  sendEmail,
});

export const createDrugsPrescription = (recordId: number, drugsPrescription: DrugsPrescriptionInput, cb: Function = NOP) => (
  (dispatch: Dispatch) => {
    dispatch({ type: DRUGS_PRESCRIPTION_CREATE_IN_PROGRESS });
    Api.post(router.getApiRoute('createDrugsPrescription', { recordId }), {
      data: { drugsPrescription },
      success: (response) => {
        dispatch({ type: DRUGS_PRESCRIPTION_CREATE_SUCCESS, recordId, drugsPrescription: response.data.drugsPrescription });
        cb(true);
      },
      error: (error) => {
        dispatch({ type: DRUGS_PRESCRIPTION_CREATE_FAILED, error: { message: error.message } });
        dispatch(reportError(error));
        cb(false);
      },
    });
  }
);

export const createAndShowDrugsPrescriptionPDF = (recordId: number, drugsPrescription: DrugsPrescriptionInput, cb: Function = NOP) => (
  (dispatch: Dispatch) => {
    dispatch({ type: DRUGS_PRESCRIPTION_CREATE_IN_PROGRESS });
    Api.post(router.getApiRoute('createDrugsPrescription', { recordId }), {
      data: { drugsPrescription },
      success: (createResponse) => {
        const drugsPrescriptionResponse = createResponse.data.drugsPrescription;
        dispatch({ type: DRUGS_PRESCRIPTION_CREATE_SUCCESS, recordId, drugsPrescription: drugsPrescriptionResponse });
        dispatch(showDrugsPrescriptionPDF(recordId, drugsPrescriptionResponse.id, false));
        cb(true);
      },
      error: (error) => {
        dispatch({ type: DRUGS_PRESCRIPTION_CREATE_FAILED, error: { message: error.message } });
        dispatch(reportError(error));
        cb(false);
      },
    });
  }
);

export const updateDrugsPrescription = (recordId: number, prescriptionId: number, drugsPrescription: DrugsPrescriptionInput, cb: Function = NOP) => (
  (dispatch: Dispatch) => {
    dispatch({ type: DRUGS_PRESCRIPTION_UPDATE_IN_PROGRESS });
    Api.patch(router.getApiRoute('updateDrugsPrescription', { recordId, id: prescriptionId }), {
      data: { drugsPrescription },
      success: (response) => {
        dispatch({ type: DRUGS_PRESCRIPTION_UPDATE_SUCCESS, drugsPrescription: response.drugsPrescription });
        cb(true);
      },
      error: (error) => {
        dispatch({ type: DRUGS_PRESCRIPTION_UPDATE_FAILED, error: { message: error.message } });
        dispatch(reportError(error));
        cb(false);
      },
    });
  }
);

export const updateAndShowDrugsPrescriptionPDF = (recordId: number, prescriptionId: number, drugsPrescription: DrugsPrescriptionInput, cb: Function = NOP) => (
  (dispatch: Dispatch) => {
    dispatch({ type: DRUGS_PRESCRIPTION_UPDATE_IN_PROGRESS });
    Api.patch(router.getApiRoute('updateDrugsPrescription', { recordId, id: prescriptionId }), {
      data: { drugsPrescription },
      success: (updateResponse) => {
        dispatch({ type: DRUGS_PRESCRIPTION_UPDATE_SUCCESS, drugsPrescription: updateResponse.drugsPrescription });
        dispatch(showDrugsPrescriptionPDF(recordId, prescriptionId, false));
        cb(true);
      },
      error: (error) => {
        dispatch({ type: DRUGS_PRESCRIPTION_UPDATE_FAILED, error: { message: error.message } });
        dispatch(reportError(error));
        cb(false);
      },
    });
  }
);

export const deleteDrugsPrescription = (recordId: number, prescriptionId: number, cb: Function = NOP) => (
  (dispatch: Dispatch) => {
    dispatch({ type: DRUGS_PRESCRIPTION_DELETE_IN_PROGRESS });
    Api.destroy(router.getApiRoute('deleteDrugsPrescription', { recordId, id: prescriptionId }), {
      success: () => {
        dispatch({ type: DRUGS_PRESCRIPTION_DELETE_SUCCESS, recordId, deletedPrescriptionId: prescriptionId });
        cb(true);
      },
      error: (error) => {
        dispatch({ type: DRUGS_PRESCRIPTION_DELETE_FAILED, error: { message: error.message } });
        dispatch(reportError(error));
        cb(false);
      },
    });
  }
);

export const drugsPrescriptionActions = {
  showDrugsPrescriptionPDF,
  createDrugsPrescription,
  createAndShowDrugsPrescriptionPDF,
  updateDrugsPrescription,
  updateAndShowDrugsPrescriptionPDF,
  deleteDrugsPrescription,
};

// ------------------------------------
// Reducer
// ------------------------------------
export default function drugsPrescriptionsReducer (state: ContextState, action: Action): ContextState {
  let recordId;
  let recordPrescriptions;
  switch (action.type) {
    case DRUGS_PRESCRIPTION_CREATE_IN_PROGRESS:
      return state
        .setIn(['drugsPrescriptions', 'isSaving'], true)
        .setIn(['drugsPrescriptions', 'errors'], null);

    case DRUGS_PRESCRIPTION_CREATE_SUCCESS:
      recordId = action.recordId.toString();
      const newPrescriptionId = action.drugsPrescription.id;
      const currentPrescriptionsByRecord = get(state, ['drugsPrescriptions', 'byRecord', recordId], []);
      recordPrescriptions = get(state, ['records', 'data', recordId, 'details', 'drugsPrescriptions'], []);
      return state
        .setIn(['drugsPrescriptions', 'isSaving'], false)
        .setIn(['drugsPrescriptions', 'errors'], null)
        .setIn(['drugsPrescriptions', 'data', newPrescriptionId], action.drugsPrescription)
        .setIn(['drugsPrescriptions', 'byRecord', recordId], concat(currentPrescriptionsByRecord, [newPrescriptionId]))
        .setIn(['records', 'data', recordId, 'details', 'drugsPrescriptions'], concat(recordPrescriptions, [newPrescriptionId]));

    case DRUGS_PRESCRIPTION_CREATE_FAILED:
      return state
        .setIn(['drugsPrescriptions', 'isSaving'], false)
        .setIn(['drugsPrescriptions', 'errors'], [action.error.message]);

    case DRUGS_PRESCRIPTION_UPDATE_IN_PROGRESS:
      return state
        .setIn(['drugsPrescriptions', 'isSaving'], true)
        .setIn(['drugsPrescriptions', 'errors'], null);

    case DRUGS_PRESCRIPTION_UPDATE_SUCCESS:
      return state
        .setIn(['drugsPrescriptions', 'isSaving'], false)
        .setIn(['drugsPrescriptions', 'errors'], null)
        .setIn(['drugsPrescriptions', 'data', action.drugsPrescription.id], action.drugsPrescription);

    case DRUGS_PRESCRIPTION_UPDATE_FAILED:
      return state
        .setIn(['drugsPrescriptions', 'isSaving'], false)
        .setIn(['drugsPrescriptions', 'errors'], [action.error.message]);

    case DRUGS_PRESCRIPTION_DELETE_IN_PROGRESS:
      return state
        .setIn(['drugsPrescriptions', 'isDeleting'], true)
        .setIn(['drugsPrescriptions', 'errors'], null);

    case DRUGS_PRESCRIPTION_DELETE_SUCCESS:
      const deletedId = action.deletedPrescriptionId;
      recordId = action.recordId.toString();
      const data = get(state, ['drugsPrescriptions', 'data'], {});
      const byRecord = get(state, ['drugsPrescriptions', 'byRecord', recordId], []);
      recordPrescriptions = get(state, ['records', 'data', recordId, 'details', 'drugsPrescriptions'], []);
      return state
        .setIn(['drugsPrescriptions', 'isDeleting'], false)
        .setIn(['drugsPrescriptions', 'errors'], null)
        .setIn(['drugsPrescriptions', 'data'], omit(data, deletedId.toString()))
        .setIn(['drugsPrescriptions', 'byRecord', recordId], without(byRecord, deletedId))
        .setIn(['records', 'data', recordId, 'details', 'drugsPrescriptions'], without(recordPrescriptions, deletedId));

    case DRUGS_PRESCRIPTION_DELETE_FAILED:
      return state
        .setIn(['drugsPrescriptions', 'isDeleting'], false)
        .setIn(['drugsPrescriptions', 'errors'], [action.error.message]);

    default:
      return state;
  }
}
