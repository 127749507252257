// @flow
import {
  get, omit, concat,
  without,
} from 'lodash';
import { reportError } from '@helpers/helpers';
import { Api } from '../../../helpers';
import { router } from '../../../routes/router';
import type {
  Action,
  Dispatch,
  ContextState,
  CustomFormInput,
} from '../../../types';
import showPDF from '../../middleware/showPDF';


const NOP = () => {};

// ------------------------------------
// Constants
// ------------------------------------
const CUSTOM_FORM_SHOW_PDF_IN_PROGRESS = 'customForms.CUSTOM_FORM_SHOW_PDF_IN_PROGRESS';
const CUSTOM_FORM_SHOW_PDF_SUCCESS = 'customForms.CUSTOM_FORM_SHOW_PDF_SUCCESS';
const CUSTOM_FORM_SHOW_PDF_FAILED = 'customForms.CUSTOM_FORM_SHOW_PDF_FAILED';

const CUSTOM_FORM_CREATE_IN_PROGRESS = 'customForms.CUSTOM_FORM_CREATE_IN_PROGRESS';
const CUSTOM_FORM_CREATE_SUCCESS = 'customForms.CUSTOM_FORM_CREATE_SUCCESS';
const CUSTOM_FORM_CREATE_FAILED = 'customForms.CUSTOM_FORM_CREATE_FAILED';

const CUSTOM_FORM_UPDATE_IN_PROGRESS = 'customForms.CUSTOM_FORM_UPDATE_IN_PROGRESS';
const CUSTOM_FORM_UPDATE_SUCCESS = 'customForms.CUSTOM_FORM_UPDATE_SUCCESS';
const CUSTOM_FORM_UPDATE_FAILED = 'customForms.CUSTOM_FORM_UPDATE_FAILED';

const CUSTOM_FORM_DELETE_IN_PROGRESS = 'customForms.CUSTOM_FORM_DELETE_IN_PROGRESS';
const CUSTOM_FORM_DELETE_SUCCESS = 'customForms.CUSTOM_FORM_DELETE_SUCCESS';
const CUSTOM_FORM_DELETE_FAILED = 'customForms.CUSTOM_FORM_DELETE_FAILED';

// ------------------------------------
// Actions
// ------------------------------------
export const showCustomFormPDF = (recordId: number, customFormId: number) => showPDF.action({
  title: 'Custom Form PDF',
  apiRoute: router.getApiRoute('showCustomFormPDF', { recordId, id: customFormId }),
  prepare: CUSTOM_FORM_SHOW_PDF_IN_PROGRESS,
  success: CUSTOM_FORM_SHOW_PDF_SUCCESS,
  error: CUSTOM_FORM_SHOW_PDF_FAILED,
});

export const createCustomForm = (recordId: number, customForm: CustomFormInput, cb: Function = NOP) => (
  (dispatch: Dispatch) => {
    dispatch({ type: CUSTOM_FORM_CREATE_IN_PROGRESS });
    Api.post(router.getApiRoute('createCustomForm', { recordId }), {
      data: { customForm },
      success: (response) => {
        dispatch({ type: CUSTOM_FORM_CREATE_SUCCESS, recordId, customForm: response.data.customForm });
        cb(true);
      },
      error: (error) => {
        dispatch({ type: CUSTOM_FORM_CREATE_FAILED, error: { message: error.message } });
        dispatch(reportError(error));
        cb(false);
      },
    });
  }
);

export const updateCustomForm = (recordId: number, customFormId: number, customForm: CustomFormInput, cb: Function = NOP) => (
  (dispatch: Dispatch) => {
    dispatch({ type: CUSTOM_FORM_UPDATE_IN_PROGRESS });
    Api.patch(router.getApiRoute('updateCustomForm', { recordId, id: customFormId }), {
      data: { customForm },
      success: (response) => {
        dispatch({ type: CUSTOM_FORM_UPDATE_SUCCESS, customForm: response.customForm });
        cb(true);
      },
      error: (error) => {
        dispatch({ type: CUSTOM_FORM_UPDATE_FAILED, error: { message: error.message } });
        dispatch(reportError(error));
        cb(false);
      },
    });
  }
);

export const deleteCustomForm = (recordId: number, customFormId: number, cb: Function = NOP) => (
  (dispatch: Dispatch) => {
    dispatch({ type: CUSTOM_FORM_DELETE_IN_PROGRESS });
    Api.destroy(router.getApiRoute('deleteCustomForm', { recordId, id: customFormId }), {
      success: () => {
        dispatch({ type: CUSTOM_FORM_DELETE_SUCCESS, recordId, deletedCustomFormId: customFormId });
        cb(true);
      },
      error: (error) => {
        dispatch({ type: CUSTOM_FORM_DELETE_FAILED, error: { message: error.message } });
        dispatch(reportError(error));
        cb(false);
      },
    });
  }
);

export const customFormActions = {
  showCustomFormPDF,
  createCustomForm,
  updateCustomForm,
  deleteCustomForm,
};

// ------------------------------------
// Reducer
// ------------------------------------
export default function customFormsReducer (state: ContextState, action: Action): ContextState {
  let recordId;
  let recordForms;
  switch (action.type) {
    case CUSTOM_FORM_CREATE_IN_PROGRESS:
      return state
        .setIn(['customForms', 'isSaving'], true)
        .setIn(['customForms', 'errors'], null);

    case CUSTOM_FORM_CREATE_SUCCESS:
      recordId = action.recordId.toString();
      const newCustomFormId = action.customForm.id;
      const currentFormsByRecord = get(state, ['customForms', 'byRecord', recordId], []);
      recordForms = get(state, ['records', 'data', recordId, 'details', 'customForms'], []);
      return state
        .setIn(['customForms', 'isSaving'], false)
        .setIn(['customForms', 'errors'], null)
        .setIn(['customForms', 'data', newCustomFormId], action.customForm)
        .setIn(['customForms', 'byRecord', recordId], concat(currentFormsByRecord, [newCustomFormId]))
        .setIn(['records', 'data', recordId, 'details', 'customForms'], concat(recordForms, [newCustomFormId]));

    case CUSTOM_FORM_CREATE_FAILED:
      return state
        .setIn(['customForms', 'isSaving'], false)
        .setIn(['customForms', 'errors'], [action.error.message]);

    case CUSTOM_FORM_UPDATE_IN_PROGRESS:
      return state
        .setIn(['customForms', 'isSaving'], true)
        .setIn(['customForms', 'errors'], null);

    case CUSTOM_FORM_UPDATE_SUCCESS:
      return state
        .setIn(['customForms', 'isSaving'], false)
        .setIn(['customForms', 'errors'], null)
        .setIn(['customForms', 'data', action.customForm.id], action.customForm);

    case CUSTOM_FORM_UPDATE_FAILED:
      return state
        .setIn(['customForms', 'isSaving'], false)
        .setIn(['customForms', 'errors'], [action.error.message]);

    case CUSTOM_FORM_DELETE_IN_PROGRESS:
      return state
        .setIn(['customForms', 'isDeleting'], true)
        .setIn(['customForms', 'errors'], null);

    case CUSTOM_FORM_DELETE_SUCCESS:
      const deletedId = action.deletedCustomFormId;
      recordId = action.recordId.toString();
      const data = get(state, ['customForms', 'data'], {});
      const byRecord = get(state, ['customForms', 'byRecord', recordId], []);
      recordForms = get(state, ['records', 'data', recordId, 'details', 'customForms'], []);
      return state
        .setIn(['customForms', 'isDeleting'], false)
        .setIn(['customForms', 'errors'], null)
        .setIn(['customForms', 'data'], omit(data, deletedId.toString()))
        .setIn(['customForms', 'byRecord', recordId], without(byRecord, deletedId))
        .setIn(['records', 'data', recordId, 'details', 'customForms'], without(recordForms, deletedId));

    case CUSTOM_FORM_DELETE_FAILED:
      return state
        .setIn(['customForms', 'isDeleting'], false)
        .setIn(['customForms', 'errors'], [action.error.message]);

    default:
      return state;
  }
}
