// @flow
import React from 'react';
import moment from 'moment';
import { get, isNaN, isEmpty } from 'lodash';
import { Link } from 'react-router-dom';
import {
  Modal, DayPickerInput,
  Input, TextArea, Toggle,
  InputMask, Icon, Button,
} from '@elements';
import { FormFooterModal, PlatformSwitch } from '@blocks';
import type { FormElementData, ReceiptInput, Doctor } from '@types';
import { withForm } from '@hocs';
import { isPresent, isValidCpf, createNsTranslator, fSufixTwoPoints, handleMomenti18n, handleSubmitEvent } from '@helpers/helpers';
import { withTranslation } from 'react-i18next';
import './ReceiptFormModal.scss';

type Props = {
  handleFormChange: Function,
  handleTouchField: Function,
  bindValidation: Function,
  formData: ReceiptInput,
  requestedFormData: ReceiptInput,
  formEdited: Function,
  onClose: Function,
  onSave: Function,
  onDelete?: Function,
  touchedFields: Object,
  onSetConfirmOnClose: Function,
  doctor: Doctor,
  t: Function,
  i18n: Object,
};


type State = {
  errors: {[key:string]: string},
};

class ReceiptFormModal extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      errors: {},
    };
    this.props.bindValidation(this.handleValidation);
    this._ = createNsTranslator('financial', this.props.t);
    this.scroolContainer = React.createRef();
  }

  componentDidMount() {
    handleSubmitEvent('#receiptFormModallSubmit', this.handleSave);
  }

  componentWillReceiveProps(next: Props) {
    this._ = createNsTranslator('financial', next.t);
  }

  render() {
    const {
      formData, handleFormChange, requestedFormData,
      onClose, onDelete, handleTouchField,
      doctor,
    } = this.props;

    const { _ } = this;

    const { errors } = this.state;
    const doctorName = get(doctor, 'nameWithTitle', '');
    const doctorId = get(doctor, 'id', '');
    const title = `${_('label/new_receipt_to')} ${doctorName}`;

    handleMomenti18n(this.props.i18n, moment);

    const parsedDate = moment(get(formData, 'date'), 'YYYY-MM-DD');

    const content = (
      <form className="form">
        <div className="split">
          <DayPickerInput
            name="date"
            label={_('label/date', 'global', fSufixTwoPoints)}
            date={parsedDate.isValid() ? parsedDate : moment()}
            onSelect={handleFormChange}
            required
          />
          <InputMask
            type="currency"
            name="price"
            label={_('label/value', 'global', fSufixTwoPoints)}
            value={get(formData, 'price', '')}
            onChange={handleFormChange}
            onBlur={handleTouchField}
            error={get(errors, 'price', null)}
            required
          />
        </div>
        <div className="split">
          <Input
            name="service"
            value={get(formData, 'service', '')}
            label={_('label/service_provided', null, fSufixTwoPoints)}
            onChange={handleFormChange}
            onBlur={handleTouchField}
            required
            error={get(errors, 'service', null)}
          />
        </div>

        <div className="split">
          <TextArea
            name="notes"
            value={get(formData, 'notes', '')}
            label={_('label/additional_informations', null, fSufixTwoPoints)}
            onChange={handleFormChange}
            onBlur={handleTouchField}
          />
        </div>

        <div className="split">
          <Input
            name="patientName"
            value={get(formData, 'patientName', '')}
            label={_('label/patient_name', 'global', fSufixTwoPoints)}
            onChange={handleFormChange}
            onBlur={handleTouchField}
            required
            error={get(errors, 'patientName', null)}
          />
        </div>

        <div className="split">
          <InputMask
            type="cpf"
            name="patientCpf"
            value={get(formData, 'patientCpf', '')}
            label={_('label/patient_cpf', 'global', fSufixTwoPoints)}
            onChange={handleFormChange}
            onBlur={handleTouchField}
            error={get(errors, 'patientCpf', null)}
          />
        </div>

        <div className="split">
          <Input
            name="sellerName"
            value={get(formData, 'sellerName', '')}
            label={_('label/seller_name', null, fSufixTwoPoints)}
            onChange={handleFormChange}
            onBlur={handleTouchField}
            required
            error={get(errors, 'sellerName', null)}
            disabled={isPresent(get(requestedFormData, 'sellerName'))}
          />
        </div>
        <div className="split">
          <InputMask
            disabled={isPresent(get(requestedFormData, 'sellerCpf'))}
            type="cpf"
            name="sellerCpf"
            value={get(formData, 'sellerCpf', '')}
            label={_('label/seller_cpf', null, fSufixTwoPoints)}
            onChange={handleFormChange}
            onBlur={handleTouchField}
            error={get(errors, 'sellerCpf', null)}
            required
          />
          <Input
            disabled={isPresent(get(requestedFormData, 'sellerCouncilDoc'))}
            name="sellerCouncilDoc"
            value={get(formData, 'sellerCouncilDoc', '')}
            label={_('label/council_doc', 'global', fSufixTwoPoints)}
            onChange={handleFormChange}
            onBlur={handleTouchField}
          />
        </div>

        <Link to={`/ajustes/doutores/${doctorId}`} className="link" onClick={onClose} >Alterar dados do profissional em configurações.</Link>

        <Toggle
          name="patientIsNotTheBuyer"
          key="toggleSwitch"
          label={_('action/patient_not_buyer')}
          onChange={this.handleSwitch}
          isChecked={get(formData, 'patientIsNotTheBuyer', false)}
        />

        <div className={`toggleRelatedContent isConditionalField ${formData.patientIsNotTheBuyer ? 'visible' : 'hidden'}`}>
          <div className="split">
            <Input
              name="buyerName"
              value={get(formData, 'buyerName', '')}
              label={_('label/buyer_name', null, fSufixTwoPoints)}
              onChange={handleFormChange}
              onBlur={handleTouchField}
              required
              error={get(errors, 'buyerName', null)}
            />
          </div>
          <div className="split">
            <InputMask
              type="cpf"
              name="buyerCpf"
              value={get(formData, 'buyerCpf', '')}
              label={_('label/buyer_cpf')}
              onChange={handleFormChange}
              onBlur={handleTouchField}
              error={get(errors, 'buyerCpf', null)}
            />
          </div>
        </div>

        <div className="paddingFix" />
      </form>
    );
    return (
      <PlatformSwitch
        inject
        desktop={() => (
          <Modal id="ReceiptFormModal" onClose={onClose} className="desktop noAnimation">
            <div className="modalHeader">
              <span className="modalTitle">{title}</span>
            </div>
            <div className="modalGrid">

              <div className="modalContainer">
                <div className="modalContent greyBg" ref={this.scroolContainer} id="receiptFormModallSubmit">
                  {content}
                </div>

                <FormFooterModal onCancel={onClose} onDelete={onDelete} onSave={this.handleSave} />
              </div>

            </div>
          </Modal>
        )}
        mobile={() => (
          <Modal id="ReceiptFormModal" className="asView noAnimation" onClose={onClose}>
            <div className="modalHeader">
              <Button onClick={onClose}>
                <Icon iconName="close" className="icon" />
              </Button>
              <span className="title">{title}</span>
              {onDelete && <Button className="primary" text="Excluir" onClick={onDelete} />}
              <Button className="primary" text="Salvar" onClick={this.handleSave} />
            </div>
            <div className="modalGrid">
              <div className="modalContainer">
                <div className="modalContent greyBg" ref={this.scroolContainer}>
                  {content}
                </div>
              </div>

            </div>
          </Modal>
        )}
      />
    );
  }
  _: Function;

  scroolContainer: any;

  handleFormChange = (data: FormElementData) => {
    const { name, value } = data;
    this.setState({ [name]: value });
  }

  handleSwitch = (data: FormElementData) => {
    setTimeout(() => {
      if (data.value === true && this.scroolContainer && this.scroolContainer.current) {
        this.scroolContainer.current.scrollTo(0, this.scroolContainer.current.scrollHeight);
      }
    }, 200);
    this.props.handleFormChange(data);
  };

  handleValidation = (isSave: boolean = false): Promise<void> => new Promise(resolve => {
    const { formData, formEdited, onSetConfirmOnClose } = this.props;
    const errors = {};
    const isTouched = field => this.props.touchedFields[field] === true || isSave === true;
    const isEmptyString = text => !text || String(text).trim().length === 0;
    const { _ } = this;

    const parsedPrice = parseFloat(formData.price);

    if ((isTouched('price') && isEmptyString(formData.price)) || (isTouched('price') && isNaN(formData.price)) || (parsedPrice <= 0)) {
      errors.price = _('label/error/price');
    }

    if (isTouched('service') && isEmptyString(formData.service)) {
      errors.service = _('label/error/service');
    }

    if (isTouched('patientCpf') && !isValidCpf.run(formData.patientCpf, { allowEmpty: true })) {
      errors.patientCpf = _('label/error/cpf');
    }


    if (isTouched('patientName') && isEmptyString(formData.patientName)) {
      errors.patientName = _('label/error/patient_name');
    }

    if (isTouched('sellerCpf') && !isValidCpf.run(formData.sellerCpf, { allowEmpty: true })) {
      errors.sellerCpf = _('label/error/cpf');
    }

    if (isTouched('sellerName') && isEmptyString(formData.sellerName)) {
      errors.sellerName = _('label/error/seller');
    }

    if (formData.patientIsNotTheBuyer) {
      if (isTouched('buyerCpf') && !isValidCpf.run(formData.buyerCpf, { allowEmpty: true })) {
        errors.buyerCpf = _('label/error/cpf');
      }


      if (isTouched('buyerName') && isEmptyString(formData.buyerName)) {
        errors.buyerName = _('label/error/buyer');
      }
    }


    onSetConfirmOnClose(formEdited());

    this.setState({ errors });
    resolve();
  });


  getSaveData = (): ReceiptInput => ({
    date: get(this.props.formData, 'date', '') || moment().format('YYYY-MM-DD'),
    price: get(this.props.formData, 'price', ''),
    service: get(this.props.formData, 'service', ''),
    notes: get(this.props.formData, 'notes', ''),
    sellerName: get(this.props.formData, 'sellerName', ''),
    sellerCpf: get(this.props.formData, 'sellerCpf', ''),
    sellerCouncilDoc: get(this.props.formData, 'sellerCouncilDoc', ''),
    buyerName: get(this.props.formData, 'buyerName', ''),
    buyerCpf: get(this.props.formData, 'buyerCpf', ''),
    patientIsNotTheBuyer: get(this.props.formData, 'patientIsNotTheBuyer', ''),
    patientName: get(this.props.formData, 'patientName', ''),
    patientCpf: get(this.props.formData, 'patientCpf', ''),
  });

  handleSave = () => this.handleValidation(true).then(() => {
    const { onSave } = this.props;
    if (isEmpty(this.state.errors)) {
      onSave(this.getSaveData());
    }
  });
}

export default withForm()(withTranslation()(ReceiptFormModal));
