// @flow
import React from 'react';
import { get, isEmpty } from 'lodash';
import { SettingsMenu, FormFooter, PlatformSwitch, AppMobileHeader, AppMobileFooter } from '@blocks';
import { Input, Breadcrumb } from '@elements';
import type { User, TagTemplate, BundledPermissions } from '@types';
import { withForm } from '@hocs';
import { withTranslation } from 'react-i18next';
import { createNsTranslator, handleSubmitEvent } from '@helpers/helpers';

type Props = {
  user: User,
  isNew: boolean,
  onSave: Function,
  onLogout: Function,
  onCancel: Function,
  onDelete: Function,
  formData: TagTemplate,
  handleFormChange: Function,
  handleTouchField: Function,
  touchedFields: {[field: string]: boolean},
  bindValidation: Function,
  isSaving: boolean,
  permissions: BundledPermissions,
  t: Function,
};

type State = {
  errors: {[field: string]: string},
};

const isEmptyString = (text: string) => isEmpty(text) || String(text).trim().length === 0;

class TagTemplateForm extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      errors: {},
    };
  }

  componentDidMount() {
    this.props.bindValidation(this.handleValidation);
    handleSubmitEvent('#tagTemplateFormSubmitHandler', this.onSave);
  }

  render() {
    const {
      user, isNew, formData, handleFormChange, handleTouchField,
      isSaving, onLogout,
    } = this.props;

    const _ = createNsTranslator('settings', this.props.t);

    const { errors } = this.state;

    const title = isNew ? _('label/tags/list/new') : _('label/tags/form/edit');

    const breadcrumbLinks = [
      { text: _('label/tags/form/title'), linkTo: '/ajustes/etiquetas' },
      { text: title },
    ];

    const onDelete = isNew ? null : this.onDelete;

    const pageContent = (
      <React.Fragment>
        <Input
          disabled={isSaving}
          type="text"
          name="name"
          className="field"
          label={_('label/name', 'global')}
          value={get(formData, 'name', '')}
          onChange={handleFormChange}
          onBlur={handleTouchField}
          error={get(errors, 'name', null)}
          required
          autofocus
        />
        <FormFooter
          onSave={this.onSave}
          onCancel={this.onCancel}
          onDelete={onDelete}
          isLoading={isSaving}
        />
      </React.Fragment>
    );
    return (
      <PlatformSwitch
        inject
        desktop={() => (
          <div className="appContent settingsPage">
            <aside className="sidebar">
              <SettingsMenu user={user} onLogout={onLogout} permissions={this.props.permissions} />
            </aside>
            <section className="mainSection" id="tagTemplateFormSubmitHandler">
              <div className="centerContent">
                <Breadcrumb items={breadcrumbLinks} />
                <div className="toolbar dashed">
                  <h1>{title}</h1>
                </div>
                {pageContent}
              </div>
            </section>
          </div>
        )}
        mobile={() => (
          <React.Fragment>
            <AppMobileHeader title={title} backLinkTo="/ajustes/etiquetas" />
            <div className="appContent settingsPage">
              <section className="mainSection" >
                <div className="centerContent">{pageContent}</div>
              </section>
            </div>
            <AppMobileFooter />
          </React.Fragment>
        )}
      />
    );
  }

  onDelete = () => {
    const { onDelete } = this.props;
    onDelete();
  };

  onSave = () => this.handleValidation(true).then(() => {
    const { onSave } = this.props;
    if (isEmpty(this.state.errors)) {
      onSave({
        name: get(this.props, ['formData', 'name'], ''),
      });
    }
  });

  onCancel = () => {
    const { onCancel } = this.props;
    onCancel();
  };

  handleValidation = (isSave: boolean = false): Promise<void> => new Promise(resolve => {
    const { touchedFields, formData } = this.props;
    const errors = {};
    if (isEmptyString(get(formData, 'name', '')) && (get(touchedFields, 'name', false) || isSave)) {
      errors.name = this.props.t('global:error/tag_name');
    }
    this.setState({ errors }, resolve);
  });
}


const initialData = {
  name: '',
};

export default withTranslation()(withForm(initialData)(TagTemplateForm));
