export default {
  'months/january': 'Janeiro',
  'months/february': 'Fevereiro',
  'months/march': 'Março',
  'months/april': 'Abril',
  'months/may': 'Maio',
  'months/june': 'Junho',
  'months/july': 'Julho',
  'months/august': 'Agosto',
  'months/september': 'Setembro',
  'months/october': 'Outubro',
  'months/november': 'Novembro',
  'months/december': 'Dezembro',
  'weekday/monday': 'Segunda',
  'weekday/tuesday': 'Terça',
  'weekday/wednesday': 'Quarta',
  'weekday/thursday': 'Quinta',
  'weekday/friday': 'Sexta',
  'weekday/saturday': 'Sábado',
  'weekday/sunday': 'Domingo',
  'payments_type/money': 'Dinheiro',
  'payments_type/check': 'Cheque/Boleto',
  'payments_type/credit_card': 'Cartão de Crédito',
  'payments_type/debit_card': 'Cartão de Débito',
  'payments_type/bank_transfer': 'Transferência/PIX',
  'profession/medic': 'Médico',
  'profession/dentist': 'Dentista',
  'profession/dental_surgeon': 'Cirurgião Dentista',
  'profession/bucomaxillofacial_surgeon': 'Cirurgião Bucomaxilofacial',
  'profession/general_clinical_dentist': 'Dentista Clínico Geral',
  'profession/aesthetic_dentistry': 'Harmonização Orofacial',
  'profession/endodontist': 'Endodontista',
  'profession/implantodontist': 'Implantodontista',
  'profession/pediatric_dentistry': 'Odontopediatra',
  'profession/orthodontist': 'Ortodontista',
  'profession/periodontist': 'Periodontista',
  'profession/protester': 'Protesista',
  'profession/general_practitioner': 'Médico',
  'profession/allergist_and_immunologist': 'Alergista e Imunologista',
  'profession/anesthesiologist': 'Anestesiologista',
  'profession/angiologist': 'Angiologista',
  'profession/cardiologist': 'Cardiologista',
  'profession/cardiovascular_surgeon': 'Cirurgião Cardiovascular',
  'profession/head_and_neck_surgeon': 'Cirurgião de Cabeça e Pescoço',
  'profession/digestive_system_surgeon': 'Cirurgião do Aparelho Digestivo',
  'profession/general_surgeon': 'Cirurgião Geral',
  'profession/pediatric_surgeon': 'Cirurgião Pediátrico',
  'profession/plastic_surgeon': 'Cirurgião Plástico',
  'profession/thoracic_surgeon': 'Cirurgião Torácico',
  'profession/general_clinic': 'Clínico Médico',
  'profession/coloproctologist': 'Coloproctologista',
  'profession/dermatologist': 'Dermatologista',
  'profession/endocrinologist': 'Endocrinologista',
  'profession/physiatrist': 'Fisiatra',
  'profession/gastroenterologist': 'Gastroenterologista',
  'profession/geneticist': 'Geneticista',
  'profession/geriatra': 'Geriatra',
  'profession/gynecologist': 'Ginecologista e Obstetra',
  'profession/hematologist': 'Hematologista',
  'profession/homeopath': 'Homeopata',
  'profession/infectologist': 'Infectologista',
  'profession/mastologist': 'Mastologista',
  'profession/sports_doctor': 'Médico do Esporte',
  'profession/doctor_of_labor': 'Médico do Trabalho',
  'profession/nephrologist': 'Nefrologista',
  'profession/neurosurgeon': 'Neurocirurgião',
  'profession/neurologist': 'Neurologista',
  'profession/nutrologo': 'Nutrólogo',
  'profession/ophthalmologist': 'Oftalmologista',
  'profession/oncologist': 'Oncologista',
  'profession/orthopaedist_and_traumatologist': 'Ortopedista e Traumatologista',
  'profession/otorhinolaryngologist': 'Otorrinolaringologista',
  'profession/pediatrician': 'Pediatra',
  'profession/pneumologist': 'Pneumologista',
  'profession/psychiatrist': 'Psiquiatra',
  'profession/radiologist': 'Radiologista',
  'profession/rheumatologist': 'Reumatologista',
  'profession/urologist': 'Urologista',
  'profession/other_profession': 'Outra Profissão',
  'profession/acupuncturist': 'Acupunturista',
  'profession/social_worker': 'Assistente Social',
  'profession/physical_educator': 'Educador Físico',
  'profession/physiotherapist': 'Fisioterapeuta',
  'profession/speech_language_pathologist': 'Fonoaudiólogo',
  'profession/beautician': 'Esteticista',
  'profession/nutritionist': 'Nutricionista',
  'profession/psychologist': 'Psicólogo',
  'profession/quiropraxista': 'Quiropraxista',
  'profession/nursing_technician': 'Enfermeiro',
  'profession/oral_health_technician': 'Técnico em Saúde Bucal',
  'profession/occupational_therapist': 'Terapeuta Ocupacional',
  'profession/veterinarian': 'Veterinário',
  'profession/natural_therapist': 'Terapeuta Naturista',
  'profession/biomedical': 'Biomédico',
  'profession/physiologist': 'Fisiologista',
  'profession/pharmaceutical': 'Farmacêutico',
  'profession/psychopedagogue': 'Psicopedagogo',
  'profession/secretary_or_administrator': 'Secretária/Administrador',
  'colors/blue': 'Azul',
  'colors/green': 'Verde',
  'colors/orange': 'Laranja',
  'colors/brown': 'Marrom',
  'colors/pink': 'Rosa',
  'colors/violet': 'Violeta',
  'colors/yellow': 'Amarelo',
  'colors/dark_blue': 'Azul Escuro',
  'colors/dark_green': 'Verde Escuro',
  'colors/dark_orange': 'Laranja Escuro',
  'colors/dark_brown': 'Marrom Escuro',
  'colors/dark_pink': 'Rosa Escuro',
  'colors/dark_violet': 'Violeta Escuro',
  'colors/dark_yellow': 'Amarelo Escuro',
  'colors/grey': 'Cinza',
  'martial_status/not_married': 'Solteiro',
  'martial_status/married': 'Casado',
  'martial_status/separate': 'Separado',
  'martial_status/divorced': 'Divorciado',
  'martial_status/widower': 'Viúvo',
  'martial_status/stable_union': 'União Estável',
  'periods/future': 'Futuros',
  'periods/today': 'Hoje',
  'periods/yesterday': 'Ontem',
  'periods/null': 'Esse Mês',
  'periods/last_month': 'Mês Passado',
  'periods/custom_month': 'Escolha o Mês',
  'periods/custom_date': 'Escolha a Data',
};
