// @flow
import React from 'react';
import moment from 'moment';
import classNames from 'classnames';
import { withForm } from '@hocs';
import { DoubleModal, Modal, SwipeTabs, DayPickerInput, SelectBox, InputMask, ClickableList, Button, Icon, ModalClickableList, AppMobileNoHeader } from '@elements';
import { PlatformSwitch } from '@blocks';
import { get, includes, find, isEmpty, toString, isEqual, map, findIndex, size, omit } from 'lodash';
import { isPresent, shortenName, formatRecurrent, createNsTranslator, UserNotifications } from '@helpers/helpers';
import { withTranslation } from 'react-i18next';
import type {
  PatientsState,
  Doctors,
  Services,
  AppointmentPatients,
  PatientInput,
  Clinic,
  FullAppointment,
  PermissionTo,
  PlanInclude,
} from '@types';

import { appointmentModalTabItems as tabItems, deletePromptItems } from './utils/constants';
import { appointmentModalListRenderer, dayPickerInputRenderer } from './utils/customRenderers';
import { getDurations, filterForceHidden, mapServicesIds } from './utils/utils';
import { blockedTimeListItemsRender, appointmentModalListItemsRender } from './utils/appointmentMenuRender';
import DayPickerSelect from './DayPickerSelect';
import SecondaryModalWrapper from './Submodals/SecondaryModalWrapper';
import './Styles/AppointmentModal.scss';
import './Styles/DoctorModal.scss';
import './Styles/PatientInfoModal.scss';
import './Styles/PatientsModal.scss';
import './Styles/RecurringScheduleModal.scss';
import './Styles/RoomModal.scss';
import './Styles/ServicesModal.scss';
import './Styles/StatusesModal.scss';

type Props = {
    // General use
    onQuit : Function, // Quit all modals
    onGetPatients: Function,
    onUpdateStatus: Function,
    onGetPatient: Function,
    onDeleteRecurrentEvents: Function,
    onPushRoute: Function,
    onCreatePatient: Function,
    onGetServicesTemplates: Function,
    onGetRecomendedServices: Function,
    osSetConfirmation : (confirm: boolean) => void, // Set confirm on close
    isNew: boolean,
    isMobileSmallScreen: boolean,
    isLoadingFullData: boolean,
    isBlockedAppointment: boolean,
    patients: PatientsState,
    doctors: Doctors,
    singleDoctor: boolean,
    singleRoom: boolean,
    isFinatialEnabled: boolean,
    clinic: Clinic,
    currentDoctorID: any,
    servicesTemplates: Services,
    isLoading: boolean,
    appointmentPatients: AppointmentPatients,
    // Creation only use
    defaultAppointmentDuration: string,
    onCreate: Function,
    onHide: Function,
    // Edit Only only use
    fullData: FullAppointment,
    onUpdate: Function,
    onDelete: Function,
    // FormHoc
    formData : Object,
    bindValidation: Function,
    handleFormChange: Function,
    formEdited: Function,
    closeModal: Function,
    formChange: Function,
    setInitialData: Function,
    t: Function,
    permissions: PermissionTo,
    isSaving: boolean,
    planInclude: PlanInclude,
};

type PatientsModals = 'PatientInfoModal' | 'PatientNewModal' | 'PatientSelectModal';
type AppoitnemntsModals = 'StatusModal' | 'RecurrencyModal' | 'RoomSelectModal' | 'DoctorSelectModal' | 'ServicesSelectModal' | 'WhatsAppConfirmationModal';
type MenuListItems = 'patient' | 'recurringSchedule' | 'status' | 'room' | 'doctor' | 'contractedServices' | 'whatsAppConfirmation';

type State ={
    activeModal: null | PatientsModals | AppoitnemntsModals,
    previousModal: null | PatientsModals | AppoitnemntsModals,
    activeMenuItem: MenuListItems | null,
    isBlockedAppointment: boolean,
    errors: Array<string>,
    showDayPicker: boolean,
    initialPickerDate: ?Object,
    modalPayload: ?Object,
    justCreatedPatient: boolean,
    justOpenedPatientModal: boolean,
    fullFillCount: number,
    promptAppointmentDelete: boolean,
    changedBlocked: boolean,
    statusData: Object,
};


class AppointmentModalForm extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      activeModal: this.props.isNew || this.props.isMobileSmallScreen ? null : 'StatusModal',
      isBlockedAppointment: this.props.isBlockedAppointment,
      changedBlocked: false,
      showDayPicker: false,
      justCreatedPatient: false,
      promptAppointmentDelete: false,
      justOpenedPatientModal: false,
      fullFillCount: 0,
      activeMenuItem: this.props.isNew || this.props.isMobileSmallScreen ? null : 'status',
      previousModal: null,
      modalPayload: {},
      errors: [],
      initialPickerDate: moment(),
      statusData: {},
    };
  }

  componentWillMount() {
    this.props.bindValidation(this.handleValidation);

    const isCurrentFull = get(this.props.formData, 'full', false);
    const hasFullData = get(this.props.fullData, 'full', false);

    if (!isCurrentFull && this.state.fullFillCount < 2 && hasFullData) {
      this.fullFillData(this.props.fullData, this.props.setInitialData);
    }

    document.addEventListener('keydown', this.handleKeyDown);
  }

  componentWillReceiveProps(nextProps: Props) {
    // Next
    const lastCreatedPatientId = get(nextProps, ['patients', 'lastCreatedPatientId'], null);
    const nextPatientId = get(nextProps, ['formData', 'patientId']);
    const isLoadingPatient = get(nextProps.patients, 'isLoading', false);
    const isSavingPatient = get(nextProps, ['patients', 'isSaving'], false);
    const isNextFull = get(nextProps.fullData, 'full', false);
    // Current
    const currPatientId = get(this.props, ['formData', 'patientId'], null);
    // const isCurrentFull = get(this.props.formData, 'full', false);

    // Handles new created patient
    if (lastCreatedPatientId !== nextPatientId && this.state.justCreatedPatient && !isSavingPatient) {
      this.props.formChange('patientId', lastCreatedPatientId);
      this.setModal(null);
      this.setState({ justCreatedPatient: false });
    }

    // Handle full patient
    if (nextPatientId !== currPatientId && isPresent(nextPatientId) && !isLoadingPatient) {
      const isFull = get(nextProps.patients, ['data', String(nextPatientId), 'full'], false);
      if (!isFull) {
        this.props.onGetPatient(nextPatientId);
      }
    }

    // const isCurrentFull = get(this.props.formData, 'full', false);

    if (isNextFull && !isEqual(nextProps.fullData, this.props.fullData)) {
      this.fullFillData(nextProps.fullData, nextProps.setInitialData);
    }

    if ((isNextFull && this.state.fullFillCount < 2) || !isEqual(this.props.fullData, nextProps.fullData)) {
      const statusData = {
        canceledByDoctorAt: get(nextProps.fullData, 'canceledByDoctorAt', ''),
        canceledByPatientAt: get(nextProps.fullData, 'canceledByPatientAt', ''),
        canceledBySmsAt: get(nextProps.fullData, 'canceledBySmsAt', ''),
        confirmedAt: get(nextProps.fullData, 'confirmedAt', ''),
        confirmedBySmsAt: get(nextProps.fullData, 'confirmedBySmsAt', ''),
        finishedAt: get(nextProps.fullData, 'finishedAt', ''),
        patientArrivedAt: get(nextProps.fullData, 'patientArrivedAt', ''),
        patientMissedAt: get(nextProps.fullData, 'patientMissedAt', ''),
        smsSentAt: get(nextProps.fullData, 'smsSentAt', ''),
        updatedAt: get(nextProps.fullData, 'updatedAt', ''),
      };
      this.setState({ statusData });
    }
  }

  shouldComponentUpdate(nextProps: Props, nextState: State) {
    return !isEqual(this.props, nextProps) || !isEqual(this.state, nextState);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleKeyDown);
  }

  render() {
    const {
      onQuit, isNew, formData, patients, handleFormChange, defaultAppointmentDuration,
      doctors, singleDoctor, singleRoom, isFinatialEnabled,
      currentDoctorID, onHide, isLoading, appointmentPatients, onGetPatients,
      formChange, clinic, onDeleteRecurrentEvents, servicesTemplates, closeModal, t,
      permissions, isSaving, planInclude, fullData,
    } = this.props;


    const {
      isBlockedAppointment, errors, initialPickerDate, showDayPicker, activeModal, activeMenuItem,
      previousModal, modalPayload, promptAppointmentDelete, changedBlocked,
    } = this.state;

    const _ = createNsTranslator('agenda', t);

    const isLockedForLoading = this.isLockedForLoading();

    let selectedDate = moment(get(formData, 'date'));
    selectedDate = selectedDate.isValid() ? selectedDate : moment();

    let patient = {
      // Assign basic patient info for a soft loading ux
      ...(get(formData, 'patient') ? {
        id: get(formData, ['patient', 'id']),
        name: get(formData, ['patient', 'name']),
        homePhone: get(formData, ['patient', 'homePhone']),
        mobilePhone: get(formData, ['patient', 'mobilePhone']),
        otherPhone: get(formData, ['patient', 'otherPhone']),
        indebt: get(formData, ['patient', 'indebt']),
      } : {}),
      ...find(get(appointmentPatients, 'patients'), p => p.id === formData.patientId),
      ...get(patients, ['data', formData.patientId], {}),
    };
    patient = isEmpty(patient) ? null : patient;

    const alternativeDoctorId = isNew ? get(doctors, ['data', currentDoctorID], null) : null;

    const auxData = {
      patientLoading: get(patients, 'isLoading', null),
      services: [...mapServicesIds(formData.serviceIds, servicesTemplates), ...(!isNew ? get(formData, 'services', []) : [])],
      durations: getDurations(get(formData, 'duration', defaultAppointmentDuration), defaultAppointmentDuration),
      showRecurrent: get(formData, ['recurrent', 'recurrentEnabled'], false) || isNew,
      doctor: permissions.appointmentDoctorSelect ? get(doctors, ['data', formData.doctorId], null) || alternativeDoctorId : null,
      selectedDate,
      canBeHidden: get(formData, 'canBeHidden', false),
      recurrentCount: get(formData, ['recurrent', 'appointmentsCount'], 0),
      recomendedService: get(servicesTemplates, ['recomendedService', 'patients', toString(formData.patientId)], null),
      patientSmsFeedback: get(formData, 'patientSmsFeedback', ''),
      canceledBySmsAt: isPresent(get(formData, 'canceledBySmsAt', '')),
      indebt: get(patient, 'indebt', false),
      appointmentDate: get(formData, 'date', ''),
      isNew,
    };

    this.auxData = auxData;

    const blockedTimeListItems = blockedTimeListItemsRender(auxData.showRecurrent, activeModal, activeMenuItem,
      auxData.doctor, singleDoctor, auxData.recurrentCount, isNew, isLockedForLoading, _);

    const hasPlanWhatsAppConfirmation = get(planInclude, 'whatsappConfimation', false);
    const showWhatsAppConfirmation = get(fullData, 'showWhatsappConfirmation', false);

    const appointmentModalListItems = appointmentModalListItemsRender({
      showRecurrent: auxData.showRecurrent,
      activeMenuItem,
      patient,
      recurrentDaysCount: auxData.recurrentCount,
      isNew,
      singleRoom,
      singleDoctor,
      appointmentDoctorSelect: permissions.appointmentDoctorSelect,
      currentRoom: find(clinic.rooms, r => r.number === formData.room),
      doctor: auxData.doctor,
      services: auxData.services,
      isFinatialEnabled,
      showNotes: get(formData, 'showNotes', false),
      isLockedForLoading,
      activeModal,
      isWhatsAppConfirmationEnabled: hasPlanWhatsAppConfirmation && showWhatsAppConfirmation,
      _,
    });

    const mainMenuItems = filterForceHidden(isBlockedAppointment ? blockedTimeListItems : appointmentModalListItems, isNew);
    this.mainMenuItems = mainMenuItems;
    const mainMenuRenderer = appointmentModalListRenderer(
      errors,
      handleFormChange,
      get(formData, 'notes', ''),
      get(formData, 'showNotes', false),
      get(formData, 'title', ''),
    );

    const listAnimation = isBlockedAppointment ? 'to-blocked-anim' : 'to-normal-anim';

    const contentRender = () => (
      <React.Fragment>
        <AppMobileNoHeader />
        {promptAppointmentDelete && (
        <ModalClickableList items={deletePromptItems(_)} onClose={this.closePromptAppointmentDelete} onListItemClick={this.promptDeleteAction} />
        )}
        {showDayPicker && (
          <DayPickerSelect
            initialDate={initialPickerDate}
            onClose={this.closeDayPicker}
            onChange={this.handleChangeDate}
          />
        )}
        <DoubleModal closeOnOverlayClick={closeModal} className={isPresent(activeModal) ? 'doubleModalAnim withSecondary' : 'doubleModalAnim'}>
          <Modal id="appointmentModal" onClose={onQuit}>
            {isNew && (
              <SwipeTabs
                items={tabItems(_)}
                pageSize={2}
                onTabChange={this.handleTabChange}
              />
            )}
            {!isNew && (
              <div className='modalHeaderWrapper'>
                {auxData.canceledBySmsAt && (
                  <div className="patientSmsFeedback">
                    <p className="canceledMessage">{_('label/appointment_sms_canceled')}</p>
                    {isPresent(auxData.patientSmsFeedback) && (
                      <React.Fragment>
                        <p className="patientMessageTitle">{_('label/appointment_patient_msg')}:</p>
                        <p className="patientMessage">{auxData.patientSmsFeedback}</p>
                      </React.Fragment>
                    )}
                  </div>
                )}
                {!isBlockedAppointment ? (
                  <p className='modalTitle'>{_('label/appointment_with')} {patient ? shortenName(patient.name) : 'Paciente'}</p>
                ) : (
                  // <p className='modalTitle'>Horário Bloqueado</p>
                  null
                )}
                {!isBlockedAppointment && !isNew && (
                  <div className="links">
                    <div className={classNames('link')} onClick={this.handlePatientHaderClick('patientProfile')}>
                      <Icon iconName="patient" />
                      <span>Cadastro</span>
                    </div>
                    <div className={classNames('link')} onClick={this.handlePatientHaderClick('patientTimeline')}>
                      <Icon iconName="assignment" />
                      <span>Prontuário</span>
                    </div>
                    {isFinatialEnabled && (
                      <div className={classNames('link')} onClick={this.handlePatientHaderClick('patientFinancial')}>
                        <Icon iconName="services" />
                        <span>Financeiro</span>
                        {auxData.indebt && <span className="notifyDot" />}
                      </div>
                    )}
                  </div>
                )}
              </div>
            )}
            <div className="modalGrid">
              <div className="modalContainer">
                <div className={isBlockedAppointment ? 'modalContent blockedTimeContent' : 'modalContent'}>
                  <div className="appointmentTimeSelector">

                    <DayPickerInput
                      customRenderer={dayPickerInputRenderer(_)}
                      date={selectedDate}
                      onClick={this.handleOpenDayPicker}
                    />

                    <div className="split">

                      <InputMask
                        name="time"
                        type="time"
                        label={_('label/hour')}
                        placeholder={_('label/hour')}
                        required
                        value={get(formData, 'time', '09:01')}
                        error={includes(errors, 'time') ? 'Invalid format' : ''}
                        onChange={handleFormChange}
                      />
                      <SelectBox
                        name="duration"
                        label={_('label/duration')}
                        placeholder={_('action/select_month')}
                        value={get(formData, 'duration', defaultAppointmentDuration)}
                        required
                        options={auxData.durations}
                        onChange={handleFormChange}
                      />
                    </div>
                  </div>
                  <ClickableList
                    className={changedBlocked ? listAnimation : ''}
                    items={mainMenuItems}
                    customRenderer={mainMenuRenderer}
                    onListItemClick={this.handleMenuItemClick}
                  />
                </div>
                {/* Edit Only */}

                <div className="modalFooter">
                  {!isNew && (
                    <React.Fragment>
                      {!auxData.canBeHidden && (
                      <React.Fragment>
                        <Button
                          className='deleteIconButton'
                          text=""
                          onClick={this.handleDelete}
                        >
                          <Icon iconName="delete" />
                        </Button>
                        <div>
                          <Button text={_('action/cancel', 'global')} onClick={onQuit} />
                          <Button className="primary" text={isSaving ? _('label/saving', 'global') : _('action/save', 'global')} onClick={this.handleSave} isLoading={isLoading || isSaving} />
                        </div>
                      </React.Fragment>
                      )}
                      {auxData.canBeHidden && (
                        <React.Fragment>
                          <Button text={_('action/cancel', 'global')} onClick={onQuit} />
                          <Button className="deleteButton" text={_('action/hide')} onClick={onHide(get(formData, 'id'))} />
                        </React.Fragment>
                      )}
                    </React.Fragment>
                  )}
                  {isNew && (
                    <React.Fragment>
                      {!auxData.canBeHidden && (<Button text={_('action/cancel', 'global')} onClick={onQuit} />)}
                      {!auxData.canBeHidden && (
                        <Button className="primary" text={isSaving ? _('label/saving', 'global') : _('action/save', 'global')} onClick={this.handleCreateNew} isLoading={isLoading || isSaving} />
                      )}
                    </React.Fragment>
                  )}
                </div>
              </div>
            </div>
          </Modal>
          <SecondaryModalWrapper
            statusData={this.state.statusData}
            activeMenuItem={activeMenuItem}
            activeModal={activeModal}
            appointmentPatients={appointmentPatients}
            auxData={auxData}
            clinic={clinic}
            doctors={doctors}
            formChange={formChange}
            formData={formData}
            handleFormChange={handleFormChange}
            isNew={isNew}
            modalPayload={modalPayload}
            onDeleteRecurrentEvents={onDeleteRecurrentEvents}
            onGetPatients={onGetPatients}
            patient={patient}
            patients={patients}
            previousModal={previousModal}
            servicesTemplates={servicesTemplates}
            fetchServices={this.fetchServices}
            handleCreatePatient={this.handleCreatePatient}
            handleSetServiceRequest={this.handleSetServiceRequest}
            setModal={this.setModal}
            currentServices={auxData.services}
            onChangeStatus={this.handleChangeStatus}
            isLockedForLoading={isLockedForLoading}
            appointmentDate={auxData.appointmentDate}
            isBlockedAppointment={isBlockedAppointment}
            doctor={get(auxData, 'doctor')}
            _={_}
          />
        </DoubleModal>
      </React.Fragment>
    );

    return (
      <PlatformSwitch
        inject
        desktop={() => contentRender()}
        mobile={() => contentRender()}
      />
    );
  }

  isLockedForLoading = () => {
    const { isNew, isLoadingFullData } = this.props;
    return !isNew && isLoadingFullData;
  };

  handlePatientHaderClick = (route: string) => () => {
    const navigate = () => this.props.onPushRoute(route, { id: this.props.formData.patientId });

    // if (this.isLockedForLoading()) return;

    if (this.props.formEdited()) {
      UserNotifications.confirmI18n('alert/confirm_quit_form', null, '#000', true)
        .then((value) => {
          if (value) {
            navigate();
          }
        });
    } else {
      navigate();
    }
  };

  handleTabChange = (index) => {
    const isBlockedAppointment = index === 2;
    this.setState(() => {
      if (isBlockedAppointment) {
        return {
          isBlockedAppointment, activeMenuItem: null, activeModal: null, changedBlocked: true,
        };
      }
      return { isBlockedAppointment, changedBlocked: true };
    });
  };

  handleOpenDayPicker = () => {
    let initialPickerDate = get(this.props.formData, 'date');
    initialPickerDate = initialPickerDate ? moment(initialPickerDate) : moment();
    this.setState({ initialPickerDate, showDayPicker: true });
  };

  closeDayPicker = () => {
    this.setState({ initialPickerDate: null, showDayPicker: false });
  };

  handleChangeDate = (date: string) => {
    const { formChange } = this.props;
    formChange('date', date);
  };

  handleMenuItemClick = (item) => {
    const { formData } = this.props;
    if (item.action && !item.disabled) {
      if (item.action === 'patient') {
        if (!formData.patientId) {
          this.setState({ activeMenuItem: item.action, activeModal: 'PatientSelectModal' });
        } else {
          this.setState({ activeMenuItem: item.action, activeModal: 'PatientInfoModal' });
        }
      } else if (item.action === 'room') {
        this.setState({ activeMenuItem: item.action, activeModal: 'RoomSelectModal' });
      } else if (item.action === 'doctor') {
        this.setState({ activeMenuItem: item.action, activeModal: 'DoctorSelectModal' });
      } else if (item.action === 'recurringSchedule') {
        this.setState({ activeMenuItem: item.action, activeModal: 'RecurrencyModal' });
      } else if (item.action === 'contractedServices') {
        this.setState({ activeMenuItem: item.action, activeModal: 'ServicesSelectModal' });
      } else if (item.action === 'status') {
        this.setState({ activeMenuItem: item.action, activeModal: 'StatusModal' });
      } else if (item.action === 'whatsAppConfirmation') {
        this.setState({ activeMenuItem: item.action, activeModal: 'WhatsAppConfirmationModal' });
      } else {
        this.setState({ activeMenuItem: item.action });
      }
    }
  };

  handleChangeStatus = (data: Object, isChecked: boolean, hidden: boolean = false) => {
    this.props.onUpdateStatus({
      state: data.type,
      value: isChecked,
      hidden,
    }, this.getSaveData());
    this.props.closeModal(true);
  };

  setModal = (activeModal: null | PatientsModals | AppoitnemntsModals, modalPayload: Object = {}) => {
    const previousModal = this.state.activeModal;

    if (includes(['PatientInfoModal', 'PatientNewModal', 'PatientSelectModal'], activeModal)) {
      this.setState({
        activeModal,
        activeMenuItem: 'patient',
        previousModal,
        modalPayload,
        justOpenedPatientModal: true,
      });
    } else if (includes(['StatusModal'], activeModal)) {
      this.setState({
        activeModal, activeMenuItem: 'status', previousModal, modalPayload,
      });
    } else if (includes(['RecurrencyModal'], activeModal)) {
      this.setState({
        activeModal, activeMenuItem: 'recurringSchedule', previousModal, modalPayload,
      });
    } else if (includes(['RoomSelectModal'], activeModal)) {
      this.setState({
        activeModal, activeMenuItem: 'room', previousModal, modalPayload,
      });
    } else if (includes(['DoctorSelectModal'], activeModal)) {
      this.setState({
        activeModal, activeMenuItem: 'doctor', previousModal, modalPayload,
      });
    } else if (includes(['ServicesSelectModal', 'ServicesListModal'], activeModal)) {
      this.setState({
        activeModal, activeMenuItem: 'contractedServices', previousModal, modalPayload,
      });
    } else {
      this.setState({
        activeModal, activeMenuItem: null, previousModal, modalPayload,
      });
    }
  };

  handleCreatePatient = (formData: PatientInput) => {
    const data = {
      ...formData,
      doctorId: get(this.props.formData, 'doctorId', null),
    };
    this.setState({ justCreatedPatient: true }, () => this.props.onCreatePatient(data));
  };

  handleGetRecomendedServices = () => {
    const { patientId, doctorId } = this.props.formData;
    if (isPresent(patientId) && isPresent(doctorId)) {
      this.props.onGetRecomendedServices(doctorId, patientId);
    }
  };

  handleGetServicesTemplates = () => {
    const { doctorId } = this.props.formData;
    if (isPresent(doctorId)) {
      this.props.onGetServicesTemplates(doctorId);
    }
  };

  handleSetServiceRequest = () => {
    // this.setState({ canFetchServices: true });
  };

  fetchServices = () => {
    const { patientId, doctorId } = this.props.formData;
    if (isPresent(patientId) && isPresent(doctorId)) {
      this.props.onGetRecomendedServices(doctorId, patientId, () => {
        this.props.onGetServicesTemplates(doctorId);
      });
    } else if (isPresent(doctorId)) {
      this.props.onGetServicesTemplates(doctorId);
    }
  }

  handleValidation = () => {
    const { formEdited, osSetConfirmation } = this.props;
    const errors = [];

    if (this.state.justOpenedPatientModal && !this.state.isBlockedAppointment) {
      if (!isPresent(this.props.formData.patientId)) {
        errors.push('patientId');
      }
    }

    this.setState({ errors });

    osSetConfirmation(formEdited());
  };

  handleCreateNew = () => {
    const { formData, onCreate, osSetConfirmation } = this.props;

    if (get(formData, ['recurrent', 'recurrentEnabled'], false) && get(formData, ['recurrent', 'mode'], false) === 'weekly') {
      const recurrentDays = get(formData, ['recurrent', 'recurrentDays']);
      if (size(recurrentDays) === 0) {
        this.setState({ errors: [...this.state.errors, 'recurrency'], justOpenedPatientModal: this.state.justOpenedPatientModal });
        return;
      } else {
        this.setState({ errors: omit(this.state.errors, 'recurrency'), justOpenedPatientModal: this.state.justOpenedPatientModal });
      }
    }

    if (isPresent(formData.patientId) || this.state.isBlockedAppointment) {
      let data = {};



      if (this.state.isBlockedAppointment) {
        data = {
          title: get(formData, 'title'),
          doctorId: get(formData, 'doctorId'),
          date: get(formData, 'date'),
          duration: get(formData, 'duration'),
          time: get(formData, 'time'),
          recurrent: formatRecurrent(formData.recurrent, true),
        };
      } else {
        data = {
          ...formData,
          recurrent: formatRecurrent(formData.recurrent, true),
          patient: {
            ...get(this.props.patients, ['data', formData.patientId], {}),
            ...get(this.props.appointmentPatients, [formData.patientId], {}),
          },
        };
      }

      osSetConfirmation(false);
      onCreate(data);
    } else {
      this.setState({ errors: [...this.state.errors, 'patientId'], justOpenedPatientModal: true });
    }
  };

  handleSave = () => {
    const { onUpdate, osSetConfirmation } = this.props;
    osSetConfirmation(false);
    onUpdate(this.getSaveData());
  };

  getSaveData = () => {
    const { formData } = this.props;
    if (this.state.isBlockedAppointment) {
      return {
        id: get(formData, 'id', null),
        title: get(formData, 'title', null),
        doctorId: get(formData, 'doctorId', null),
        date: get(formData, 'date', null),
        time: get(formData, 'time', null),
        duration: get(formData, 'duration', null),
        recurrent: formatRecurrent(formData.recurrent),
      };
    }
    return {
      id: get(formData, 'id', null),
      title: get(formData, 'title', null),
      doctorId: get(formData, 'doctorId', null),
      notes: get(formData, 'notes', null),
      date: get(formData, 'date', null),
      time: get(formData, 'time', null),
      duration: get(formData, 'duration', null),
      patientId: get(formData, 'patientId', null),
      room: get(formData, 'room', null),
      showNotes: get(formData, 'showNotes', false),
      withoutPatient: get(formData, 'withoutPatient', false),
      serviceIds: get(formData, 'serviceIds', []),
      recurrent: formatRecurrent(formData.recurrent),
    };
  };

  fullFillData = (fullData: Object, setInitialData: Function) => {
    this.setState({ fullFillCount: this.state.fullFillCount + 1 });
    setInitialData({
      ...fullData,
      recurrent: formatRecurrent(fullData.recurrent, false),
    });
  };

  handleDelete = () => {
    UserNotifications.confirmI18n('alert/delete/appointment', 'warning', '#FFA433', true)
      .then((confirmed) => {
        if (confirmed) {
          const recurrent = !!get(this.props.formData, 'recurrent', null);

          if (recurrent) {
            this.setState({ promptAppointmentDelete: true });
          } else {
            this.props.onDelete(false);
          }
        }
      });
  };

  closePromptAppointmentDelete = () => this.setState({ promptAppointmentDelete: false });

  promptDeleteAction =(item: Object) => {
    switch (item.action) {
      case 'delete_only':
        this.props.onDelete(false);
        break;
      case 'delete_future':
        this.props.onDelete(true);
        break;
      default: break;
    }
    this.closePromptAppointmentDelete();
  };

  mainMenuItems: any;
  auxData: any;

  handleKeyDown = (e: any) => {
    const normalize = (nextIndex: number) => {
      let v = nextIndex;
      v = v < 0 ? 0 : v;
      return v > (this.mainMenuItems.length - 1) ? this.mainMenuItems.length - 1 : v;
    };

    if (window.__hasSelectOpened) {
      return;
    }

    const { activeMenuItem } = this.state || {};
    const { showRecurrent, isNew } = this.auxData || {};

    const mainMenuItems = map<any, any>(this.mainMenuItems, i => get(i, ['customRendererData', 'action']));

    const currentIndex = findIndex(mainMenuItems, item => item === activeMenuItem);


    if (e.keyCode === 38 /* UP */) {
      let nextIndex = normalize(currentIndex - 1);

      if (!showRecurrent && mainMenuItems[nextIndex] === 'recurringSchedule') {
        nextIndex = normalize(nextIndex - 1);
      }
      if (!isNew && mainMenuItems[nextIndex] === 'doctor') {
        nextIndex = normalize(nextIndex - 1);
      }
      // Prevent unecessary updates
      if (nextIndex === currentIndex) {
        return;
      }

      this.handleMenuItemClick(this.mainMenuItems[nextIndex].customRendererData);
    } else if (e.keyCode === 40 /* DOWN */) {
      let nextIndex = normalize(currentIndex + 1);

      if (!showRecurrent && mainMenuItems[nextIndex] === 'recurringSchedule') {
        nextIndex = normalize(nextIndex + 1);
      }

      if (!isNew && mainMenuItems[nextIndex] === 'doctor') {
        nextIndex = normalize(nextIndex + 1);
      }
      // Prevent unecessary updates
      if (nextIndex === currentIndex) {
        return;
      }

      this.handleMenuItemClick(this.mainMenuItems[nextIndex].customRendererData);
    } else if (e.keyCode === 13 /* SPACE */ && this.state.activeModal !== 'PatientNewModal') {
      if (isNew) {
        this.handleCreateNew();
      } else {
        this.handleSave();
      }
    }
  };
}


const initialData = {
};

export default withForm(initialData)(withTranslation()(AppointmentModalForm));

