// @flow
import React from 'react';
import classnames from 'classnames';
import { BlockedFeature } from '../';

type Props = {
  className?: string,
  enabled?: boolean;
  children?: any,
  transparent?: boolean,
  renderBlockedFeature?: Function,
  hint?: string;
  hideUpgradeButton?: boolean,
  customMessage?: string,
};

export default class ConditionalFeature extends React.Component<Props> {
  render() {
    const {
      className, enabled, children, transparent, renderBlockedFeature, hint, hideUpgradeButton, customMessage,
    } = this.props;

    const cssClasses = classnames([className, transparent ? 'blockedFeatureTransparent' : null]);

    if (!enabled) {
      return renderBlockedFeature
        ? renderBlockedFeature()
        : <BlockedFeature className={cssClasses} hint={hint} customMessage={customMessage} hideUpgradeButton={hideUpgradeButton} />;
    }

    return React.Children.map(children, item => item);
  }
}
