// @flow
import React from 'react';
import { get } from 'lodash';
import { SettingsMenu, FormFooter, PlatformSwitch, AppMobileHeader, AppMobileFooter } from '@blocks';
import { InputMask, Breadcrumb, Toggle } from '@elements';
import type { User, BundledPermissions } from '@types';
import { createValidator } from '@helpers/validator';
import { withForm } from '@hocs';
import classnames from 'classnames';
import { withTranslation } from 'react-i18next';
import { createNsTranslator, handleSubmitEvent } from '@helpers/helpers';

type Props = {
  user: User,
  isLoading: boolean,
  handleFormChange: Function,
  resetData: Function,
  touchedFields: Object,
  formData: Object,
  bindValidation: Function,
  onSave: Function,
  onLogout: Function,
  permissions: BundledPermissions,
  formEdited: Function,
  setInitialData: Function,
  t: Function,
};

type State = {
  errors: Object,
};

const validator = createValidator()
  .field('businessHoursStart')
  .hoursAndMinutes(_ => _('error/invalid_hour'))
  .field('businessHoursEnd')
  .hoursAndMinutes(_ => _('error/invalid_hour'));

class ClinicConfigWorkHours extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      errors: {},
    };
    this.props.bindValidation(this.handleValidation);
  }

  componentDidMount() {
    handleSubmitEvent('#clinicConfigWorkHoursDesktopForm', () => {
      if (this.props.formEdited()) {
        this.onSave();
      }
    });
  }

  render() {
    const {
      user, formData, handleFormChange,
      isLoading, resetData, onLogout,
    } = this.props;

    const { errors } = this.state;

    const showWeekends = get(formData, 'showWeekends', false);

    const _ = createNsTranslator('settings', this.props.t);

    const edited = this.props.formEdited();

    const breadcrumbLinks = [
      { text: _('label/agenda', 'global'), linkTo: '/ajustes/clinica' },
      { text: _('label/agenda_menu/workhours') },
    ];

    const pageContent = (mobile: boolean) => (
      <div className="addWorkHourContainer" id="addWorkHours">
        <div className="toolbar dashed">
          <h1>{_('label/agenda_menu/workhours')}</h1>
        </div>
        <div id="form" className={classnames('addWorkHoursForm', { mobile })}>
          <InputMask
            className="inputs"
            type="time"
            label={_('label/start', 'global')}
            name="businessHoursStart"
            disabled={isLoading}
            value={get(formData, 'businessHoursStart', '')}
            onChange={handleFormChange}
            error={get(errors, 'businessHoursStart', null)}
            required
            autofocus
          />
          <InputMask
            className="inputs"
            type="time"
            label={_('label/end', 'global')}
            name="businessHoursEnd"
            disabled={isLoading}
            value={get(formData, 'businessHoursEnd', '')}
            onChange={handleFormChange}
            error={get(errors, 'businessHoursEnd', null)}
            required
          />
        </div>
        <div className="toggleContainerFix">
          <Toggle
            name="showWeekends"
            isChecked={showWeekends === 'true' || showWeekends === true}
            label={_('label/agenda/show_weekends_in_agenda')}
            onChange={handleFormChange}
          />
        </div>
        <div className="footerActions">
          <FormFooter onSave={this.onSave} onCancel={resetData} isLoading={isLoading} edited={edited} editFeature />
        </div>
      </div>
    );

    return (

      <PlatformSwitch
        inject
        desktop={() => (
          <div className="appContent settingsPage" id="clinicConfigWorkHoursDesktopForm">
            <aside className="sidebar">
              <SettingsMenu user={user} onLogout={onLogout} permissions={this.props.permissions} />
            </aside>
            <section className="mainSection">
              <div className="centerContent">
                <Breadcrumb items={breadcrumbLinks} />
                {pageContent(false)}
              </div>
            </section>
          </div>
        )}
        mobile={() => (
          <React.Fragment>
            <AppMobileHeader title={_('label/agenda_menu/workhours')} backLinkTo="/ajustes/clinica" />
            <div className="appContent settingsPage" id="workHours">
              <section className="mainSection">
                <div className="centerContent">{pageContent(true)}</div>
              </section>
            </div>
            <AppMobileFooter />
          </React.Fragment>
        )}
      />

    );
  }

  onSave = () => this.handleValidation(true).then(valid => {
    if (valid) {
      const { onSave, formData, setInitialData } = this.props;
      setInitialData(formData);
      onSave(formData);
    }
  });

  handleValidation = (isSubmit: boolean = false): Promise<boolean> => new Promise((resolve: Function) => {
    const { formData, touchedFields } = this.props;
    const result = validator.validate(formData, touchedFields, isSubmit);
    this.setState({ errors: result.errors }, () => resolve(result.valid));
  });
}

export default withTranslation()(withForm()(ClinicConfigWorkHours));
