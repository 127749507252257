// @flow
import React, { useCallback, useState, useMemo, useEffect } from 'react';
import { get, filter, map, findIndex } from 'lodash';
import classnames from 'classnames';
import type { User, BundledPermissions } from '@types';
import { Icon, Button, ClickableList, ModalClickableList } from '@elements';
import { useTranslationNs, getServicesMenuLinkId, useIsMobile } from '@helpers/helpers';
import { useDispatch, useSelector } from 'react-redux';
import { setActiveClinic } from '@context/clinics';
import { useHistory } from 'react-router';
import jQuery from 'jquery';
import './SettingsMenu.scss';

type Props = {
  className?: string,
  user?: User,
  onLogout: Function,
  permissions: BundledPermissions,
};

const filterManagerItems = (items: Array<Object>) => filter(items, item => !item.hidden);

const mapStateToProps = (state) => ({
  doctorId: get(state, ['context', 'user', 'advancedData', 'doctorId'], null),
  isManager: get(state, ['context', 'user', 'advancedData', 'manager'], null),
  doctors: get(state, ['context', 'clinics', 'activeClinic', 'doctorIds'], []) || [],
  clinicName: get(state, ['context', 'clinics', 'activeClinic', 'name']) || '',
  userEmail: get(state, ['context', 'user', 'email']),
  userName: get(state, ['context', 'user', 'nameWithTitle']),
  appVersion: get(state, ['appVersion', 'version']) || '',
  isMultiClinic: Object.keys(get(state, ['context', 'clinics', 'data'], {}) || {}).length > 1,
  clinics: get(state, ['context', 'clinics', 'data'], {}) || {},
});

const SettingsMenu = ({
  className, user, permissions, onLogout,
}: Props) => {
  const { appVersion, clinicName, clinics, doctorId, doctors, isManager, isMultiClinic, userEmail, userName } = useSelector(mapStateToProps);

  const [_] = useTranslationNs('settings');
  const dispatch = useDispatch();
  const history = useHistory();
  const [isMobile] = useIsMobile();

  const [selectClinicOpen, setSelectClinicOpen] = useState(false);

  const isDoctor = get(user, ['roleType']) === 'Doctor';

  const unsubscribed = useMemo(() => !(
    permissions.subscriptionStatus === 'banned' ||
    permissions.subscriptionStatus === 'end_of_trial' ||
    permissions.subscriptionStatus === 'trialing'
  ),
  [permissions.subscriptionStatus]);

  const handleLogout = useCallback(() => {
    if (onLogout) {
      onLogout();
    }
  }, [onLogout]);

  const handleCloseModal = useCallback(() => {
    setSelectClinicOpen(false);
  }, []);

  const handleChangeClinic = useCallback((item) => {
    if (item.type === 'heading') return;
    dispatch(setActiveClinic(item.id));
  }, [dispatch]);

  const clinicsItems = useMemo(() => [
    {
      type: 'heading',
      text: _('action/menu/change_clinic_title'),
    },
    ...map(clinics, (clinic) => ({
      text: clinic.name,
      id: clinic.id,
    })),
  ], [_, clinics]);

  const items = useMemo(() => [
    {
      className: 'listItem clinicItem',
      customRendererData: {
        clinicOpt: true,
      },
    },
    ...(unsubscribed && !permissions.isDependent ? [{
      linkTo: '/ajustes/meu-plano',
      className: 'listItem noBlock',
      customRendererData: {
        text: _('action/menu/my_plan'),
        icon: 'plan',
      },
    }] : []),
    ...(!unsubscribed ? [{
      linkTo: '/assinar',
      className: 'listItem noBlock',
      customRendererData: {
        text: _('action/menu/plans'),
        icon: 'plan',
        notifyDot: true,
      },
    }] : []),
    {
      linkTo: '/ajustes/meu-perfil',
      customRendererData: {
        text: _('action/menu/my_record'),
        icon: 'patient',
      },
    },
    {
      type: 'heading',
      text: _('action/menu/records_settings'),
      hidden: !isDoctor,
    },
    {
      linkTo: '/ajustes/privacidade',
      disabled: permissions.blocked,
      hidden: !isDoctor,
      customRendererData: {
        text: _('action/menu/privacy'),
        icon: 'privacy',
      },
    },
    {
      linkTo: '/ajustes/formularios',
      disabled: permissions.blocked,
      hidden: !isDoctor,
      customRendererData: {
        text: _('action/menu/forms'),
        icon: 'doc',
      },
    },
    {
      linkTo: '/ajustes/modelos',
      disabled: permissions.blocked,
      hidden: !isDoctor,
      customRendererData: {
        text: _('action/menu/eletronic_records'),
        icon: 'pill',
      },
    },
    {
      type: 'heading',
      text: _('action/menu/clinic_settings'),
      hidden: !isManager,
    },
    {
      linkTo: '/ajustes/clinica/endereco',
      disabled: permissions.blocked,
      hidden: !isManager,
      customRendererData: {
        text: _('action/menu/my_clinic'),
        icon: 'clinic',
      },
    },
    {
      linkTo: '/ajustes/doutores',
      disabled: permissions.blocked,
      hidden: !isManager,
      customRendererData: {
        text: _('action/menu/doctors'),
        icon: 'doctor',
      },
    },
    {
      linkTo: '/ajustes/secretarias',
      disabled: permissions.blocked,
      hidden: !isManager,
      customRendererData: {
        text: _('action/menu/secretaries'),
        icon: 'secretary',
      },
    },
    {
      linkTo: '/ajustes/clinica',
      disabled: permissions.blocked,
      hidden: !isManager,
      customRendererData: {
        text: _('action/menu/agenda'),
        icon: 'calendar',
      },
    },
    {
      linkTo: '/ajustes/clinica/campos-paciente',
      disabled: permissions.blocked,
      hidden: !isManager,
      customRendererData: {
        text: _('action/menu/patients_data'),
        icon: 'contacts',
      },
    },
    {
      type: 'heading',
      text: _('action/menu/advanced_settings'),
    },
    {
      linkTo: '/ajustes/etiquetas',
      requireManagerWhenSecretary: true,
      disabled: permissions.blocked,
      hidden: !isManager,
      customRendererData: {
        text: _('action/menu/tags'),
        icon: 'label',
      },
    },
    ...(permissions.planInclude.financial ? [{
      linkTo: '/ajustes/servicos/' + getServicesMenuLinkId(isDoctor, doctorId, doctors),
      disabled: permissions.blocked,
      customRendererData: {
        text: _('action/menu/sevices_list'),
        icon: 'services',
      },
    }] : []),
    // {
    //   linkTo: '/ajustes/impressao',
    //   customRendererData: {
    //     text: 'SMS',
    //     icon: 'sms',
    //   },
    // },
    {
      linkTo: '/ajustes/impressao',
      requireManagerWhenSecretary: true,
      disabled: permissions.blocked,
      hidden: !isDoctor && !isManager,
      customRendererData: {
        text: _('action/menu/printing'),
        icon: 'print',
      },
    },
    {
      linkTo: '/ajustes/suporte',
      customRendererData: {
        text: _('action/menu/support'),
        icon: 'support',
      },
    },
  ], [_, doctorId, doctors, isDoctor, isManager, permissions.blocked, permissions.isDependent, permissions.planInclude.financial, unsubscribed]);

  const handleSelectClinic = useCallback(() => {
    setSelectClinicOpen(true);
  }, []);

  const filtredItems = useMemo(() => filterManagerItems(items), [items]);

  const activeIndex = useMemo(() => findIndex(filtredItems, item => history.location.pathname === item.linkTo), [filtredItems, history.location.pathname]);

  useEffect(() => {
    const scrollElement = jQuery('.settingsPage aside.sidebar');
    if (scrollElement && !isMobile) {
      if (window.lastSettingsScroll && window.lastSettingsScroll > 0) {
        scrollElement.scrollTop(window.lastSettingsScroll);
      }

      scrollElement.scroll(() => {
        window.lastSettingsScroll = scrollElement.scrollTop();
      });
    }

    return () => {
      scrollElement.unbind('scroll');
    };
  },
  // eslint-disable-next-line
  []);


  const renderer = (item, key) => {
    if (item.clinicOpt) {
      return [
        <div className="clinicItemContainer" onClick={handleSelectClinic} key={key}>
          <div className="userName">{userName}</div>
          {isMultiClinic && (
            <div>{clinicName} - <span className="actionChange">{_('label/action/change_clinic')}</span></div>
          )}
          <div className="smallItem">{userEmail}</div>
          <div className="smallItem">v{appVersion}</div>
        </div>,
      ];
    } else {
      return [
        <span key={key}>
          <Icon key="icon" iconName={item.icon} className="icon" size={18} />
          <span key="text">{item.text}</span>
          {item.notifyDot && <span className="notifyDot">&nbsp;</span>}
        </span>,
      ];
    }
  };

  const cssClasses = classnames(['settingsMenu', className]);

  return (
    <div className={cssClasses}>
      <ClickableList
        items={filtredItems}
        customRenderer={renderer}
        activeItemIndex={activeIndex}
        card
      />
      {selectClinicOpen && isMultiClinic && <ModalClickableList items={clinicsItems} onClose={handleCloseModal} onListItemClick={handleChangeClinic}/>}
      <Button className="linkButton" text={_('action/menu/logout')} onClick={handleLogout} />
    </div>
  );
};


// $FlowFixMe
export default React.memo(SettingsMenu);
