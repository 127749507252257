// @flow
import React from 'react';
import { get } from 'lodash';
import { connect } from 'react-redux';
import { logout } from '@context/user';
import { mapBundledPermission } from '@helpers/connect';
import { ClinicConfigPatientFields as ClinicConfigPatientFieldsView } from '../components/views';
import { updateClinic, setClinicSaving } from '../redux/modules/context/clinics';
import type { User, ClinicInput, Clinic, BundledPermissions } from '../types';

type Props = {
  user: User,
  clinic: Clinic,
  isSaving: boolean,
  dispatch: Function,
  permissions: BundledPermissions,
};

class ClinicConfigPatientFields extends React.Component<Props> {
  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(setClinicSaving(false));
  }

  render() {
    const { user, clinic, isSaving } = this.props;

    return (
      <ClinicConfigPatientFieldsView
        user={user}
        formData={clinic}
        isLoading={isSaving}
        onSave={this.handleSave}
        onLogout={this.handleLogout}
        permissions={this.props.permissions}
      />
    );
  }

  handleLogout = () => this.props.dispatch(logout());

  handleSave = (data: ClinicInput) => {
    const { dispatch, clinic } = this.props;
    dispatch(updateClinic(clinic.id, data));
  }
}

const mapStateToProps = (state) => ({
  user: state.context.user,
  clinic: get(state, ['context', 'clinics', 'activeClinic'], {}),
  isSaving: get(state, ['context', 'clinics', 'isSaving'], false),
  permissions: mapBundledPermission(state),
});

export default connect<any, any, any, any, any, any>(
  mapStateToProps,
)(ClinicConfigPatientFields);
