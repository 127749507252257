// @flow
import { get, first, includes } from 'lodash';
import moment from 'moment';

/**
 * Format numeric minutes to (h)h (m)min
 * EX: 1h 10min
 */
export const formatMinutesToHoursMin = (mins: any) => {
  let h = Math.trunc(mins / 60);
  let m = Math.trunc(mins % 60);
  if (h > 0) {
    h = (h < 10 ? '0' + h : h) + 'h ';
  } else {
    h = '';
  }
  m = m < 0 ? m * -1 : m;

  if (m > 0) {
    m = (m < 10 ? m : m) + ' min';
  } else {
    m = '';
  }
  return `${h}${m}`;
};


export const formatRecurrent = (recurrent: Object, isSaving: boolean = false) => {
  if (!recurrent) return null;

  if (!isSaving) {
    const days = ['monday', 'wednesday', 'tuesday', 'thursday', 'friday', 'saturday', 'sunday'];

    const data = {
      appointmentsCount: get(recurrent, 'appointmentsCount', 0),
      recurrentDays: [],
      recurrentEnabled: false,
    };

    days.forEach(dayName => {
      if (get(recurrent, dayName, false)) {
        data.recurrentDays.push(dayName);
      }
    });

    data.recurrentEnabled = data.recurrentDays.length > 0;

    return data;
  }
  const recurrentDays = get(recurrent, 'recurrentDays', []);

  const data = {
    appointments_count: get(recurrent, 'appointmentsCount', 0),
    enabled: get(recurrent, 'recurrentEnabled', false),
    friday: includes(recurrentDays, 'friday'),
    monday: includes(recurrentDays, 'monday'),
    saturday: includes(recurrentDays, 'saturday'),
    sunday: includes(recurrentDays, 'sunday'),
    thursday: includes(recurrentDays, 'thursday'),
    tuesday: includes(recurrentDays, 'tuesday'),
    wednesday: includes(recurrentDays, 'wednesday'),
    biweekly: get(recurrent, 'mode') === 'biweekly',

  };

  return data;
};

/**
 * Ignores timezone and format a valid date or return fallBack
 * @param {*} dateStr
 * @param {*} format
 * @param {*} fallBack
 */
export const utcFormat = (dateStr: string, format: string, fallBack: any = null) => {
  const t = moment.utc(dateStr);
  if (t.isValid()) {
    return t.format(format);
  }
  return fallBack;
};

export const slugify = (text: string) => {
  const fileExt = first((/[.]/.exec(text)) ? /[^.]+$/.exec(text) : []);
  const a = 'àáâäæãåāăąçćčđďèéêëēėęěğǵḧîïíīįìłḿñńǹňôöòóœøōõṕŕřßśšşșťțûüùúūǘůűųẃẍÿýžźż·/_,:;';
  const b = 'aaaaaaaaaacccddeeeeeeeegghiiiiiilmnnnnooooooooprrsssssttuuuuuuuuuwxyyzzz------';
  const p = new RegExp(a.split('').join('|'), 'g');

  return text.replace(/(.*)\.(.*?)$/, '$1').toString().toLowerCase()
    .replace(/\s+/g, '-') // Replace spaces with -
    .replace(p, c => b.charAt(a.indexOf(c))) // Replace special characters
    .replace(/&/g, '-and-') // Replace & with 'and'
    // eslint-disable-next-line
    .replace(/[^\w\-]+/g, '') // Remove all non-word characters
    // eslint-disable-next-line
    .replace(/\-\-+/g, '-') // Replace multiple - with single -
    .replace(/^-+/, '') // Trim - from start of text
    .replace(/-+$/, '') // Trim - from end of text
    + (fileExt ? `.${fileExt}` : ''); // Concat file ext
};


export const formatAnonymousCreditCard = (str: string) => {
  if (!str) {
    return str;
  }
  const digitGroups = [];

  digitGroups.push(str.substr(0, 4));
  digitGroups.push(str.substr(4, 4));
  digitGroups.push(str.substr(8, 4));
  digitGroups.push(str.substr(12, 4));


  return digitGroups.join(' ');
};

export function formatBytes(bytes: any, decimals: number = 2) {
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));
  // eslint-disable-next-line
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}
