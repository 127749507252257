// @flow
import React from 'react';
import { connect } from 'react-redux';
import { get } from 'lodash';
import { logout } from '@context/user';
import { mapBundledPermission } from '@helpers/connect';
import { router } from '@router';
import { withRouter } from 'react-router-dom';
import { ServiceTemplateForm as ServiceTemplateFormView } from '../components/views';
import { serviceTemplateActions } from '../redux/modules/context/serviceTemplates';
import type { Dispatch, User, Service, BundledPermissions } from '../types';


type Props = {
  dispatch: Function,
  user: User,
  doctorId: number,
  permissions: BundledPermissions,
};

class ServiceTemplateNewForm extends React.Component<Props> {
  render() {
    const { user, doctorId } = this.props;

    const healthInsurance = get(this.props, ['location', 'search'], '').replace('?insurance=', '') === 'true';

    return (
      <ServiceTemplateFormView
        onSave={this.handleSaveService}
        doctorId={doctorId}
        user={user}
        onLogout={this.handleLogout}
        permissions={this.props.permissions}
        onCancel={this.handleBack}
        isSaving={get(this.props, ['services', 'isSaving'], false)}
        formData={{
          healthInsurance,
        }}
      />
    );
  }

  handleBack = () => this.props.dispatch(router.pushPath('serviceTemplatesList', { doctorId: this.props.doctorId }));

  handleLogout = () => this.props.dispatch(logout());

  handleSaveService = (data: Service) => {
    const { dispatch, doctorId } = this.props;
    dispatch(serviceTemplateActions.createServiceTemplate(doctorId, data));
  };
}

const mapStateToProps = (state, ownProps) => ({
  services: state.context.serviceTemplates,
  user: state.context.user,
  doctorId: get(ownProps, ['match', 'params', 'doctorId']),
  permissions: mapBundledPermission(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({ dispatch });

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps,
)(ServiceTemplateNewForm));
