// @flow
import React from 'react';
import { connect } from 'react-redux';
import { get } from 'lodash';
import { ChangePassword as ChangePasswordView } from '@views';
import type { User, BundledPermissions } from '@types';
import { mapBundledPermission } from '@helpers/connect';
import { updateUserPassword, updateUserPasswordReset, logout } from '@context/user';
import { router } from '@router';

type Props = {
  user: User,
  dispatch: Function,
  permissions: BundledPermissions,
};

class ChangePassword extends React.Component<Props> {
  componentDidMount() {
    this.props.dispatch(updateUserPasswordReset());
  }

  render() {
    const { user } = this.props;

    return (
      <ChangePasswordView
        user={user}
        onSubmit={this.handleSubmit}
        isLoading={get(user, 'isSavingPassword', false)}
        savingPasswordSuccess={get(user, 'savingPasswordSuccess', false)}
        changePasswordError={get(user, 'changePasswordError', false)}
        onLogout={this.handleLogout}
        permissions={this.props.permissions}
        onCancel={this.handleCancel}
      />
    );
  }

  handleLogout = () => this.props.dispatch(logout());

  handleCancel = () => {
    this.props.dispatch(router.pushPath('userConfig', {}));
  };

  handleSubmit = (password: string, oldPassword: string) => {
    this.props.dispatch(updateUserPassword(password, oldPassword));
  };
}

const mapStateToProps = (state) => ({
  user: state.context.user,
  permissions: mapBundledPermission(state),
});

export default connect<any, any, any, any, any, any>(
  mapStateToProps,
)(ChangePassword);
