import { concat, get, map } from 'lodash';
import Immutable from 'seamless-immutable';

function replaceEntitiesWithTheirIds (record, entityName) {
  const entityIds = [];
  const entityArray = get(record, ['details', entityName], []);
  if (entityArray.length > 0) { // if record has those entities, replace all entity objects in array with their ids
    map(entityArray, (entity) => {
      entityIds.push(entity.id);
    });
    return Immutable.setIn(record, ['details', entityName], entityIds);
  }
  return record; // if there are no entities, just return the record as it is
}

// expects array of record objects: [{id: 1, ...}, {id: 2, ...}, ...]
// => returns records object: {1: {...}, 2: {...}, ...}
function normalizeRecords (inputRecords) {
  const records = {};

  map(inputRecords, (record) => {
    let newRecord;

    newRecord = replaceEntitiesWithTheirIds(record, 'drugsPrescriptions');
    newRecord = replaceEntitiesWithTheirIds(newRecord, 'examsPrescriptions');
    newRecord = replaceEntitiesWithTheirIds(newRecord, 'documents');
    newRecord = replaceEntitiesWithTheirIds(newRecord, 'customForms');
    newRecord = replaceEntitiesWithTheirIds(newRecord, 'imageAttachments');
    newRecord = replaceEntitiesWithTheirIds(newRecord, 'fileAttachments');

    records[record.id] = newRecord;
  });

  return records;
}

// expects patient id (ex. 11) and an array of record objects: [{id: 1, ...}, {id: 2, ...}, ...]
// => returns recordIdsByPatient object: {11: [1, 2, ...]}
function extractRecordIdsByPatient (patientId, inputRecords) {
  const recordIdsByPatient = {};
  recordIdsByPatient[patientId] = [];

  map(inputRecords, (record) => {
    recordIdsByPatient[patientId].push(record.id);
  });

  return recordIdsByPatient;
}

// expects array of record objects: [
//  { id: 1,
//    ...
//    details: {
//      drugsPrescriptions: [{id: 10, ...}, {id: 11, ...}],
//      examsPrescriptions: [{id: 100, ...}, {id: 101, ...}],
//      documents: [{id: 110, ...}, {id: 111, ...}],
//      customForms: [{id: 120, ...}, {id: 121, ...}],
//      ...
//    },
//  },
//  { id: 2,
//    ...
//    details: {
//      drugsPrescriptions: [{id: 20, ...}, {id: 21, ...}],
//      examsPrescriptions: [{id: 200, ...}, {id: 201, ...}],
//      documents: [{id: 210, ...}, {id: 211, ...}],
//      customForms: [{id: 220, ...}, {id: 221, ...}],
//      ...
//    },
//  },
//  ...
// ]
// => returns entity (drugsPrescriptions/examsPrescriptions/documents/customForms)
//    object, i.e. {10: {...}, 11: {...}, 20: {...}, 21: {...}, ...} for drugsPrescriptions
function extractEntityFromRecords (inputRecords, entityName) {
  const extractedEntities = {};

  map(inputRecords, (record) => {
    let entityArray;
    if (entityName === 'attachments') { // attachments are stored under 'imageAttachments' and 'fileAttachments' in record object
      const imageAttachmentsArray = get(record, ['details', 'imageAttachments'], []);
      const fileAttachmentsArray = get(record, ['details', 'fileAttachments'], []);
      entityArray = concat(imageAttachmentsArray, fileAttachmentsArray);
    } else {
      entityArray = get(record, ['details', entityName], []);
    }
    map(entityArray, (entity) => {
      extractedEntities[entity.id] = entity;
    });
  });

  return extractedEntities;
}

// expects array of record objects like in extractEntityFromRecords function
// => returns entityIdsByRecords object, i.e. {1: [10, 11, ...], 2: [20, 21, ...], ...} for drugsPrescriptions
function extractEntityIdsByRecords (inputRecords, entityName) {
  const entityIdsByRecords = {};

  map(inputRecords, (record) => {
    let entityArray;
    if (entityName === 'attachments') { // attachments are stored under 'imageAttachments' and 'fileAttachments' in record object
      const imageAttachmentsArray = get(record, ['details', 'imageAttachments'], []);
      const fileAttachmentsArray = get(record, ['details', 'fileAttachments'], []);
      entityArray = concat(imageAttachmentsArray, fileAttachmentsArray);
    } else {
      entityArray = get(record, ['details', entityName], []);
    }
    entityIdsByRecords[record.id] = [];
    map(entityArray, (entity) => {
      entityIdsByRecords[record.id].push(entity.id);
    });
  });

  return entityIdsByRecords;
}

function normalizeRecord (inputRecord) {
  let newRecord = {};

  newRecord = replaceEntitiesWithTheirIds(inputRecord, 'drugsPrescriptions');
  newRecord = replaceEntitiesWithTheirIds(newRecord, 'examsPrescriptions');
  newRecord = replaceEntitiesWithTheirIds(newRecord, 'documents');
  newRecord = replaceEntitiesWithTheirIds(newRecord, 'customForms');
  newRecord = replaceEntitiesWithTheirIds(newRecord, 'imageAttachments');
  newRecord = replaceEntitiesWithTheirIds(newRecord, 'fileAttachments');

  return newRecord;
}

export default {
  normalizeRecords,
  extractRecordIdsByPatient,
  extractEntityFromRecords,
  extractEntityIdsByRecords,
  normalizeRecord,
};
