// @flow
import React from 'react';
import { isArray, isObject, isFunction, get, isNumber, isNaN, filter, map, forEach, includes, toLower } from 'lodash';
import Immutable from 'seamless-immutable';
import moment from 'moment';
import { Translation } from 'react-i18next';
import { isPresent } from './validations';


export const sort = (items: any, compareFunction: Function): Array<any> => {
  if (isArray(items) && isFunction(compareFunction)) {
    items = Immutable.isImmutable(items) ? JSON.parse(JSON.stringify(items)) : items;
    return items.sort((a, b) => compareFunction(a && a.customRendererData ? a.customRendererData : a, b && b.customRendererData ? b.customRendererData : b, items));
  } else if (isObject(items) && isFunction(compareFunction)) {
    const sortenedKeys = Object.keys(items).sort((a, b) =>
      compareFunction(
        items[a] && items[a].customRendererData ? items[a].customRendererData : items[a],
        items[b] && items[b].customRendererData ? items[b].customRendererData : items[b],
        items,
      ),
    );
    return sortenedKeys.map((key: string) => items[key]);
  }

  return items;
};

type OrderDir = 'asc' | 'desc';

/**
 * Compare function used to order alpha chars
 * @param {*} attr
 * @param {*} dir
 */
export const alphabeticCompare = (attr?: any, dir?: OrderDir = 'asc') => (a: any, b: any) => {
  const A = String(attr ? get(a, attr, '') : a);
  const B = String(attr ? get(b, attr, '') : b);

  return dir === 'asc' ? String(A).localeCompare(B) : String(B).localeCompare(A);
};

/**
 * Compare function to order dates
 * @param {*} attr
 * @param {*} dir
 */
export const dateCompare = (attr?: any, dir?: OrderDir = 'desc') =>
  (a: any, b: any) => {
    const A = attr ? get(a, attr, {}) : a;
    const B = attr ? get(b, attr, {}) : b;
    const mA = moment.isMoment(A) ? A : moment(A);
    const mB = moment.isMoment(B) ? B : moment(B);

    return dir === 'asc' ? mA.diff(mB) : mB.diff(mA);
  };

export const isEqualValue = (a: any, b: any) => {
  const aNum = parseFloat(a);
  const bNum = parseFloat(b);
  if (isNumber(aNum) && isNumber(bNum) && !isNaN(aNum) && !isNaN(bNum)) {
    return aNum === bNum;
  }
  return a === b;
};

/**
 *
 * @param {*} attr
 * @param {*} dir
 */
export const numberCompare = (attr?: any, dir?: OrderDir = 'asc') =>
  (a: any, b: any) => {
    const A = attr ? get(a, attr, 0) : a;
    const B = attr ? get(b, attr, 0) : b;

    return dir === 'asc' ? A - B : B - A;
  };

/**
 * Get first present property
 * @param {*} target
 * @param {*} keys
 */
export const keepFirstPresent = (target: ?Object, keys: Array<string>) => {
  if (!target) return null;
  let found = null;
  forEach(keys, key => {
    if (!found && isPresent(get(target, key))) {
      found = get(target, key);
    }
  });
  return found;
};


/**
 * Translate list items
 * @param {*} items
 * @param {*} destKey
 */
export const localizeListItems = (items: any[], destKey: string = 'text') => map<any, any>(items, (item: any) => {
  if (!isPresent(get(item, '_locale')) && !get(window, ['i18n', 't'])) {
    return item;
  }
  return {
    ...item,
    [destKey]: <Translation>{_ => _(get(item, '_locale'))}</Translation>,
  };
});

/**
 * Translate list items
 * @param {*} items
 * @param {*} destKey
 */
export const localizeListItemsNs = (items: any[], t: Function, destKey: string = 'text') => map<any, any>(items, (item) => {
  if (!isPresent(get(item, '_locale')) && !get(window, ['i18n', 't'])) {
    return item;
  }
  return {
    ...item,
    [destKey]: t(get(item, '_locale'), null),
  };
});

export const filterSearch = (items: any, searchText: string, searchAttr: string[] = ['name']) => {
  const search = toLower(searchText).normalize('NFD').replace(/[\u0300-\u036f]/g, '');
  const searchPredicate: any = item => includes(toLower(get(item, searchAttr)).normalize('NFD').replace(/[\u0300-\u036f]/g, ''), search) || get(item, 'type') === 'heading';
  return search && String(search).trim().length > 0 ? filter<any, any>(items, searchPredicate) : items;
};
