/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
// Importing necessary React and Redux functions, as well as third-party libraries and components
import React, { useEffect } from 'react';
import classnames from 'classnames';
import { useSelector, useDispatch } from 'react-redux';
import { get } from 'lodash';
import * as Dialog from '@radix-ui/react-dialog';

import { DoutoreSpinner } from '@blocks';
import { Button, Icon } from '@elements';
import { actions } from '@context/modals';
import { clinicActions } from '@context/clinics';
import { createMemedPrescription } from '@helpers/memed';
import { useIsMobile, useTranslationNs } from '@helpers/helpers';

import './MemedModal.scss';

// Main component definition
export const MemedModal = () => {
  // Redux hooks for dispatching actions and selecting state
  const dispatch = useDispatch();

  // Selector hooks to retrieve specific parts of the Redux state
  const isMemedLoaded = useSelector(state =>
    get(state, ['context', 'clinics', 'isMemedLoaded'], false),
  );
  const memedToken = useSelector(state =>
    get(state, ['context', 'user', 'advancedData', 'memedToken'], null),
  );
  const isOpen = useSelector(state => get(state, ['context', 'modals', 'isMemedOpen'], false));
  const modals = useSelector(state => get(state, ['context', 'modals'], {}));

  // Custom hooks for responsive design and internationalization
  const [isMobile] = useIsMobile();
  const [_] = useTranslationNs('timeline');

  // Extracting data from the modals state
  const data = get(modals, ['data'], null);

  // Function to handle modal closure
  const onClose = () => {
    console.log('### Modal close called');
    dispatch(actions.closeActiveModal());

    // Hides additional modules if they are present
    if (!window.MdHub) return;
    window.MdHub.module.hide('plataforma.prescricao');
    window.MdHub.module.hide('platform.sms-modal');
  };

  // Effect hook to handle modal opening and data changes
  useEffect(() => {
    console.log(`### useEffect for isOpen and data, isOpen: ${isOpen}, data: ${JSON.stringify(data)}`);
    // Exit the function early if the modal is not open (isOpen is false) or if Memed is not yet loaded (isMemedLoaded is false). This check ensures that the following code only runs when the modal is open and Memed is ready to be used.
    if (!isOpen || !isMemedLoaded) return;
    // lets console log isOpen and isMemedLoaded
    console.log(`### isOpen: ${isOpen}, isMemedLoaded: ${isMemedLoaded}`);
    // If there is data available (data is not null or undefined), call the createMemedPrescription function with this data. This function presumably creates a prescription using the Memed service, utilizing the provided data.
    if (data) createMemedPrescription(data);
  }, [isOpen, data]);

  // Check if user has a Memed Token. If true, then hasMemedEnabled
  const hasMemedEnabled = Boolean(memedToken);
  console.log(`### hasMemedEnabled: ${hasMemedEnabled}`);

  // Function to set the Memed token
  const setMemedToken = () => {
    if (!hasMemedEnabled) return;
    window.memedToken = memedToken;
    if (window.MdSinapsePrescricao) MdSinapsePrescricao.setToken(memedToken);
  };

  // Function to load the Memed script
  const loadMemed = () => {
    console.log('### loadMemed called');
    if (document.getElementById('memedScript')) {
      console.log('### TRIED TO LOAD MEMED SCRIPT AGAIN ###');
      return; // Prevents loading the script multiple times
    }
    // update the Redux state by setting the 'isMemedLoaded' status to false. Memed script has not yet finished loading.
    dispatch(clinicActions.setMemedLoadedStatus(false));
    console.log('### Creating Memed script element');
    const script = document.createElement('script');
    script.id = 'memedScript';
    script.type = 'text/javascript';
    script.src = MEMED_SCRIPT_URL;
    script.dataset.token = memedToken;
    script.dataset.color = '#576cff';
    script.dataset.container = 'memedModalContent';

    // Error handling for the script loading
    script.onerror = (e) => {
      console.error('### Error loading Memed script', e);
    };

    // On successful script loading
    script.onload = () => {
      console.log('### Memed script loaded');
      MdSinapsePrescricao.event.add('core:moduleInit', (modulo) => {
        console.log(`### Memed module init: ${modulo.name}`);
        if (modulo.name === 'plataforma.prescricao') {
          setMemedToken();
          setTimeout(() => {
            dispatch(clinicActions.setMemedLoadedStatus(true));
          }, 3000);
        }
      });
    };
    console.log('### Appending Memed script to the body');
    document.body.appendChild(script);
  };

  // Effect hook for token changes
  useEffect(() => {
    console.log(`### useEffect for memedToken: ${memedToken}`);
    if (!hasMemedEnabled) {
      console.log('### Memed token not enabled');
      return;
    }
    setMemedToken();
  }, [memedToken]);

  // Effect hook for component mount
  useEffect(() => {
    console.log('### useEffect for component mount');
    // If the user has Memed enabled, load the Memed script
    if (hasMemedEnabled) loadMemed();
  }, []);

  // Component render
  return (
    <Dialog.Root open modal={false}>
      <Dialog.Portal>
        <div className={classnames(['memedModalOverlay', { isOpen }])} onClick={onClose} />

        <Dialog.Content
          className={classnames(['modal', 'memedModal', { isOpen }])}
          onEscapeKeyDown={onClose}
          onClick={onClose}
        >
          <div className={classnames(['modalLayout', 'memedModalContent'], { isOpen, isMobile })}>
            {/* Mobile header */}
            {isMobile && (
              <div className="memedMobileModalHeader">
                <Button onClick={onClose}>
                  <Icon iconName="close" className="icon" />
                </Button>
                <span className="title">{_('action/content_add/memed')}</span>
              </div>
            )}

            {/* Close button for non-mobile devices */}
            {!isMobile && <div className="modalClose" onClick={onClose} />}

            {/* Content area */}
            <div id="memedModalContent" />
            {console.log('### Rendering memedModalContent')}

            {/* Loading spinner */}
            <div className='loadingMemedModal'>
              <DoutoreSpinner />
            </div>
          </div>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
};
/* eslint-enable no-console */
