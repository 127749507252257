import createAutoCorrectedDatePipe from 'text-mask-addons/dist/createAutoCorrectedDatePipe';
import emailMask from 'text-mask-addons/dist/emailMask';
// Helpers
const _D = /\d/; // Digit
const _ANY = /.*/g;
// const D_1_9 = /[1-9]/; // Digit

const phoneMask = (value: string) => {
  const numbers = value.match(/\d/g);

  if (numbers && numbers.join('').length < 11) {
    return ['(', _D, _D, ')', ' ', _D, _D, _D, _D, '-', _D, _D, _D, _D, _D];
  }

  return ['(', _D, _D, ')', ' ', _D, _D, _D, _D, _D, '-', _D, _D, _D, _D];
};

const anyMask = [
  _ANY, _ANY, _ANY, _ANY, _ANY, _ANY, _ANY, _ANY, _ANY, _ANY, _ANY, _ANY, _ANY, _ANY, _ANY, _ANY, _ANY, _ANY, _ANY, _ANY, _ANY, _ANY, _ANY, _ANY, _ANY,
  _ANY, _ANY, _ANY, _ANY, _ANY, _ANY, _ANY, _ANY, _ANY, _ANY, _ANY, _ANY, _ANY, _ANY, _ANY, _ANY, _ANY, _ANY, _ANY, _ANY, _ANY, _ANY, _ANY, _ANY, _ANY,
  _ANY, _ANY, _ANY, _ANY, _ANY, _ANY, _ANY, _ANY, _ANY, _ANY, _ANY, _ANY, _ANY, _ANY, _ANY, _ANY, _ANY, _ANY, _ANY, _ANY, _ANY, _ANY, _ANY, _ANY, _ANY,
  _ANY, _ANY, _ANY, _ANY, _ANY, _ANY, _ANY, _ANY, _ANY, _ANY, _ANY, _ANY, _ANY, _ANY, _ANY, _ANY, _ANY, _ANY, _ANY, _ANY, _ANY, _ANY, _ANY, _ANY, _ANY,
  _ANY, _ANY, _ANY, _ANY, _ANY, _ANY, _ANY, _ANY, _ANY, _ANY, _ANY, _ANY, _ANY, _ANY, _ANY, _ANY, _ANY, _ANY, _ANY, _ANY, _ANY, _ANY, _ANY, _ANY, _ANY,
  _ANY, _ANY, _ANY, _ANY, _ANY, _ANY, _ANY, _ANY, _ANY, _ANY, _ANY, _ANY, _ANY, _ANY, _ANY, _ANY, _ANY, _ANY, _ANY, _ANY, _ANY, _ANY, _ANY, _ANY, _ANY,
  _ANY, _ANY, _ANY, _ANY, _ANY, _ANY, _ANY, _ANY, _ANY, _ANY, _ANY, _ANY, _ANY, _ANY, _ANY, _ANY, _ANY, _ANY, _ANY, _ANY, _ANY, _ANY, _ANY, _ANY, _ANY,
  _ANY, _ANY, _ANY, _ANY, _ANY, _ANY, _ANY, _ANY, _ANY, _ANY, _ANY, _ANY, _ANY, _ANY, _ANY, _ANY, _ANY, _ANY, _ANY, _ANY, _ANY, _ANY, _ANY, _ANY, _ANY,
  _ANY, _ANY, _ANY, _ANY, _ANY, _ANY, _ANY, _ANY, _ANY, _ANY, _ANY, _ANY, _ANY, _ANY, _ANY, _ANY, _ANY, _ANY, _ANY, _ANY, _ANY, _ANY, _ANY, _ANY, _ANY,
];

export default (locale: string) => ({
  phone: {
    mask: locale !== 'pt-br' ? anyMask : phoneMask,
    filter: locale !== 'pt-br' ? [] : ['(', '-', ' ', ')'],
    type: 'tel',
  },
  date: {
    mask: [_D, _D, '/', _D, _D, '/', _D, _D, _D, _D],
    pipe: createAutoCorrectedDatePipe('dd/mm/yyyy'),
    type: 'tel',
  },
  time: {
    mask: [_D, _D, ':', _D, _D],
    type: 'tel',
  },
  cpf: {
    mask: locale !== 'pt-br' ? anyMask : [_D, _D, _D, '.', _D, _D, _D, '.', _D, _D, _D, '-', _D, _D],
    filter: locale !== 'pt-br' ? [] : ['.', '-'],
    conform: true,
    // Remove . and - due to an bug that does not allow to use backspace key
    pipe: (conformedValue: string) => {
      if (conformedValue[conformedValue.length - 1] === '.' || conformedValue[conformedValue.length - 1] === '-') {
        return conformedValue.substring(0, conformedValue.length - 1);
      }
      return conformedValue;
    },
    type: 'tel',
  },
  cep: {
    mask: locale !== 'pt-br' ? anyMask : [_D, _D, _D, _D, _D, '-', _D, _D, _D],
    filter: locale !== 'pt-br' ? [] : ['-'],
    type: 'tel',
  },
  email: {
    mask: emailMask,
    filter: [' '],
    type: 'email',
  },
  creditCard: {
    mask: [/\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/],
    filter: [' '],
    type: 'tel',
  },
  mmYY: {
    mask: [/[0-1]/, /[0-9]/, '/', /\d/, /\d/],
    filter: [' '],
    pipe: createAutoCorrectedDatePipe('mm/yy'),
    type: 'tel',
  },
  ccv3: {
    mask: [/\d/, /\d/, /\d/],
    filter: [' '],
    type: 'tel',
  },
  ccv4: {
    mask: [/\d/, /\d/, /\d/, /\d/],
    filter: [' '],
    type: 'tel',
  },
});
