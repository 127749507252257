// @flow
import React from 'react';
import classNames from 'classnames';
import './DoctorColorIcon.scss';

type Props = {
  className?: string,
  colorId: number,
  solid?: boolean,
};

const DoctorColorIcon = ({ className, colorId, solid }: Props) => (
  <div className={classNames('doctorColorIcon', `indicator-color${colorId}`, { solid }, className)} />
);

export default DoctorColorIcon;

