// @flow
import React from 'react';
// import PatientLayout from '../../../../layouts/PatientLayout';
import { Button } from '../../../elements';
import './PatientMessages.scss';

type Props = {
  onNewMessageClick: Function,
};

export default class PatientMessages extends React.Component<Props> {
  render() {
    const { onNewMessageClick } = this.props;

    return (
      <div className="appContent">
        {/* <PatientLayout /> */}

        <section className="mainSection">
          <section className="mainSection">
            <div className="patientTimeline">
              <div className="toolbar">
                <h1>Mensagens Enviadas</h1>

                <Button className="primary" text="Enviar SMS" onClick={onNewMessageClick} />
                <Button className="primary" text="Enviar Email" onClick={onNewMessageClick} />
              </div>
            </div>
          </section>

        </section>
      </div>
    );
  }
}
