// @flow
import React from 'react';
import { get, isEqual, debounce, size, isString } from 'lodash';
import { Modal, Button, Icon, SearchInput, ClickableList, Spinner } from '@elements';
import type { AppointmentPatients, Doctors } from '@types';
import { mapSuggestedPatients, mapSearchablePatients } from '../utils/utils';
import { newPatientRenderer, patientsListRenderer } from '../utils/customRenderers';

type Props = {
  formChange: Function,
  onGetPatients: Function,
  onSetServicesRequest: Function,
  setModal: Function,
  doctors: Doctors,
  appointmentPatients: AppointmentPatients,
  _: Function,
};


type State = {
  debounceLocked: boolean,
  rescheduleMode: boolean,
};

const newPatient = (_: Function, reescheduleMode: boolean) => [
  ...(reescheduleMode ? [] : [
    {
      className: 'listItem addNewPatient flexStart',
      customRendererData: {
        type: 'newPatient',
        icon: 'patient-add',
        text: _('label/patient_select/add_patient'),
      },
    },
    {
      className: 'listItem addNewPatient flexStart',
      customRendererData: {
        type: 'rescheduled',
        icon: 'label',
        text: _('label/patient_select/reschedule_patients'),
      },
    },
  ]),
];

export default class PatientSelectModal extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      debounceLocked: false,
      rescheduleMode: false,
    };
  }
  componentWillMount() {
    this.props.onGetPatients('');
  }

  shouldComponentUpdate(nextProps: Props) {
    return !isEqual(this.props, nextProps); // we dot compare debounceLocked because him isn't used in render
  }

  render() {
    const {
      doctors,
      appointmentPatients, _,
    } = this.props;

    const searchablePatients = get(appointmentPatients, 'patients', []);
    const suggestedPatients = get(appointmentPatients, 'suggested', []);

    const suggested = mapSuggestedPatients(suggestedPatients, doctors);
    const searchable = mapSearchablePatients(searchablePatients, doctors, get(appointmentPatients, 'completePatientsList'), get(suggested, 'length') > 0);

    return (
      <Modal id="patientModal" className="secondaryModal" onClose={this.handleOnBack}>
        <div className="modalHeader actionHeader">
          <Button className="backButton" onClick={this.handleOnBack}>
            <Icon iconName="arrow-left" className="icon" />
          </Button>
          {!this.state.rescheduleMode && (
            <SearchInput
              uncontrolled
              placeholder={_('label/patient_select/search_patient')}
              name="search"
              onChange={this.onSearchChange}
            />
          )}
          {this.state.rescheduleMode && (
            <div className="modalTitle">
              {_('label/patient_select/reschedule_patients')}
            </div>
          )}
        </div>

        <div className="modalGrid">
          <div className="modalContainer">
            <div className="modalContent">

              <ClickableList
                items={newPatient(_, this.state.rescheduleMode)}
                customRenderer={newPatientRenderer}
                onListItemClick={this.onNewPatient}
              />

              {get(appointmentPatients, 'isLoading', false) ? (
                <Spinner text={_('label/patient_select/loading')} />
              ) : (
                <React.Fragment>
                  <ClickableList
                    items={suggested}
                    customRenderer={patientsListRenderer}
                    onListItemClick={this.onSelectPatient}
                  />
                  <ClickableList
                    items={searchable}
                    customRenderer={patientsListRenderer}
                    onListItemClick={this.onSelectPatient}
                  />
                  {size(suggested) === 0 && size(searchable) === 0 && (
                    <div className="emptyPatients">
                      {this.state.rescheduleMode ? _('empty/title_reschedule', 'patient_list') : _('empty/title', 'patient_list')}
                    </div>
                  )}
                </React.Fragment>
              )}

            </div>
          </div>
        </div>
      </Modal>
    );
  }
  newPatientName: string = '';

  debounceSearch = debounce((value: string) => {
    this.setState({ debounceLocked: false }, () => this.props.onGetPatients(value, this.state.rescheduleMode));
  }, 500);

  handleOnBack = () => {
    if (this.state.rescheduleMode) {
      this.props.onGetPatients('', false);
      this.setState({ rescheduleMode: false });
    } else {
      this.props.setModal(null);
    }
  };

  onSearchChange = (value: string) => {
    this.newPatientName = value;
    if (isString(value) && (String(value).trim().length >= 3 || String(value).trim().length === 0)) {
      this.setState({ debounceLocked: true }, () => this.debounceSearch(value));
    }
  };

  onNewPatient = (item: Object) => {
    if (item.type === 'allPatients') {
      this.setState({ rescheduleMode: false });
      this.props.onGetPatients('', false);
    } else if (item.type === 'rescheduled') {
      this.setState({ rescheduleMode: true });
      this.props.onGetPatients('', true);
    } else {
      this.props.setModal('PatientNewModal', { searchValue: this.newPatientName });
    }
  };

  onSelectPatient = (item: Object) => {
    if (this.state.debounceLocked) return;
    const patientId = get(item, ['patient', 'id']);

    if (item.type !== 'patientsListNote' && patientId) {
      this.props.formChange('patientId', patientId);
      this.props.setModal(null);
      this.props.onSetServicesRequest();
    }
  };
}
