// @flow
import React from 'react';
import { connect } from 'react-redux';
import { get } from 'lodash';
import { logout } from '@context/user';
import { mapBundledPermission } from '@helpers/connect';
import { ExamsPrescriptionsTemplateForm as ExamsPrescriptionsTemplateFormView } from '../components/views';
import { examsPrescriptionsTemplateActions } from '../redux/modules/context/examsPrescriptionsTemplates';
import type { Dispatch, ExamsPrescriptionsTemplate, ExamsPrescriptionsTemplateInput, User, BundledPermissions } from '../types';

type Props = {
  examsPrescriptionsTemplate: ExamsPrescriptionsTemplate,
  dispatch: Function,
  user: User,
  permissions: BundledPermissions,
};

class ExamsPrescriptionsTemplateForm extends React.Component<Props> {
  render() {
    const { user } = this.props;

    return (
      <ExamsPrescriptionsTemplateFormView
        examsPrescriptionsTemplate={this.props.examsPrescriptionsTemplate}
        onCreateExamsPrescriptionsTemplate={this.handleCreateExamsPrescriptionsTemplate}
        onUpdateExamsPrescriptionsTemplate={this.handleUpdateExamsPrescriptionsTemplate}
        onDeleteExamsPrescriptionsTemplate={this.handleDeleteExamsPrescriptionsTemplate}
        user={user}
        onLogout={this.handleLogout}
        permissions={this.props.permissions}
      />
    );
  }
  handleLogout = () => this.props.dispatch(logout());
  handleCreateExamsPrescriptionsTemplate = (examsPrescriptionsTemplate: ExamsPrescriptionsTemplateInput) => {
    this.props.dispatch(examsPrescriptionsTemplateActions.createExamsPrescriptionsTemplate(examsPrescriptionsTemplate));
  };

  handleUpdateExamsPrescriptionsTemplate = (id: number, examsPrescriptionsTemplate: ExamsPrescriptionsTemplateInput) => {
    this.props.dispatch(examsPrescriptionsTemplateActions.updateExamsPrescriptionsTemplate(id, examsPrescriptionsTemplate));
  };

  handleDeleteExamsPrescriptionsTemplate = (id: number) => {
    this.props.dispatch(examsPrescriptionsTemplateActions.deleteExamsPrescriptionsTemplate(id));
  };
}

const mapStateToProps = (state, ownProps: any) => ({
  examsPrescriptionsTemplate: get(state.context, ['examsPrescriptionsTemplates', 'data', ownProps.match.params.id], {}),
  user: state.context.user,
  permissions: mapBundledPermission(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({ dispatch });

export default connect<any, any, any, any, any, any>(
  mapStateToProps,
  mapDispatchToProps,
)(ExamsPrescriptionsTemplateForm);
