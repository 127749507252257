import axios from 'axios';
import moment from 'moment';
import { logout } from '@context/user';
import { get as _get, endsWith, indexOf, first, last } from 'lodash';
import Log from './Log';
import { router } from '../routes/router';
import { stringifyData, stringifyError } from '../helpers/helpers';
import { appVersionActions } from '../redux/modules/appVersion';
import userNotifications from './userNotifications';
import { appControlActions, appControlTypes } from '../redux/modules/appControl';
import Bugsnag from '@bugsnag/js';
import { actionTypes } from '../redux/modules/auth';

export function get(route, params = {}) {
  const url = route.path;
  Log.debug('API: GET from', url, 'params:', stringifyData(params.query || ''));
  const config = {
    data: params.data || {}, // hack to keep Content-Type header in request: https://github.com/mzabriskie/axios/issues/86#issuecomment-139638284
    onDownloadProgress: params.onDownloadProgress || (() => {}),
    params: params.query,
    headers: params.headers || {},
    ['api-fallback']: route.fallback, // eslint-disable-line
  };

  const firstUrl = first(String(url).split('?'));
  const lastUrl = last(String(url).split('?'));

  if (endsWith(firstUrl, 'pdf') || endsWith(lastUrl, 'pdf')) {
    config.responseType = 'arraybuffer';
    config.headers = {
      accept: 'application/pdf',
    };
  }

  return axios.get(url, config)
    .then((response) => {
      // success callback
      if (params.success) {
        params.success(response.data);
      }
      return response;
    })
    .catch((error) => {
      Log.error('Something went wrong with the GET call to ' + route.path + ' :' + stringifyError(error));
      // error callback
      if (params.error) {
        params.error(error);
      }
      // reject promise to trigger callAPI error function which calls error dispatch type
      return Promise.reject(error);
    });
}

export function post(route, params = {}) {
  Log.debug('API: POST to ' + route.path + ' query: ' + stringifyData(params.query || '') + ' data: ' + stringifyData(params.data || ''));
  return axios.post(route.path, params.data, {
    params: params.query,
    onUploadProgress: params.onUploadProgress || (() => {}),
  })
    .then((response) => {
      const data = {
        data: response.data,
        accessToken: response.headers['access-token'],
      };
      // success callback
      if (params.success) {
        params.success(data);
      }
      return response;
    })
    .catch((error) => {
      Log.error('Something went wrong with the POST call to ' + route.path + ' :' + stringifyError(error));
      // error callback
      if (params.error) params.error(error);
    });
}

export function put(route, params = {}) {
  Log.debug('API: PUT to ' + route.path + ' query: ' + stringifyData(params.query || '') + ' data: ' + stringifyData(params.data || ''));
  return axios.put(route.path, params.data, {
    params: params.query,
  })
    .then((response) => {
      // success callback
      if (params.success) {
        params.success(response.data);
      }
      return response;
    })
    .catch((error) => {
      Log.error('Something went wrong with the PUT call to ' + route.path + ' :' + stringifyError(error));
      // error callback
      if (params.error) params.error(error);
    });
}

// method is named 'destroy' on purpose since it is not possible to use 'delete'
export function destroy(route, params = {}) {
  Log.debug('API: delete to ' + route.path + ' query: ' + stringifyData(params.query || '') + ' data: ' + stringifyData(params.data || ''));
  return axios.delete(route.path, {
    data: params.data || {}, // hack to keep Content-Type header in request: https://github.com/mzabriskie/axios/issues/86#issuecomment-139638284
    params: params.query,
  })
    .then((response) => {
      // success callback
      if (params.success) {
        params.success(response.data);
      }
      return response;
    })
    .catch((error) => {
      Log.error('Something went wrong with the DELETE call to ' + route.path + ' :' + stringifyError(error));
      // error callback
      if (params.error) params.error(error);
    });
}

export function patch(route, params = {}) {
  Log.debug('API: PATCH to ' + route.path + ' query: ' + stringifyData(params.query || '') + ' data: ' + stringifyData(params.data || ''));
  return axios.patch(route.path, params.data, {
    params: params.query,
  })
    .then((response) => {
      // success callback
      if (params.success) {
        params.success(response.data);
      }
      return response;
    })
    .catch((error) => {
      Log.error('Something went wrong with the PATCH call to ' + route.path + ' :' + stringifyError(error));
      // error callback
      if (params.error) params.error(error);
    });
}

const ignoreStatusCodeError = [401, 422];

const shouldFilterErrorByMessage = (errorMessage) => {
  const message = String(errorMessage);

  return /Network Error/gi.test(message);
};

function initialize(store) {
  const initState = store.getState();

  if (initState.auth.token) {
    axios.defaults.headers['access-token'] = initState.auth.token;
  }

  // REQUEST:
  // - send authorization on every request
  // - redirect on login page on unauthorized requests
  axios.interceptors.request.use(
    (config) => {
      const isApiCall = true; // TODO: add real api call check here
      if (isApiCall) {
        const state = store.getState();
        config.headers['user-date'] = moment().format('YYYY-MM-DD');
        config.headers['app-version'] = window.APP_VERSION;
        config.headers['app-platform'] = window.isCordovaApp ? 'cordova' : 'web';
        const interceptClinicId = _get(state, ['context', 'clinics', 'activeClinic', 'id']);
        if (interceptClinicId) {
          config.headers.clinicId = interceptClinicId && String(interceptClinicId) !== 'undefined' ? interceptClinicId : null;
        }
      }
      return config;
    },
    (error) => {
      Log.error('Request ERROR', stringifyError(error));
    },
  );

  // RESPONSE:
  //  - log
  //  - go to /login if unauthorized (i.e. we are not logged in at all)
  axios.interceptors.response.use((response) => {
    const appVersion = response.headers['app-version'];

    if (response.headers['access-token']) {
      const state = store.getState();

      const oldToken = _get(state, ['auth', 'token']);
      const newToken = response.headers['access-token'];

      if (oldToken !== newToken) {
        store.dispatch({ type: actionTypes.AUTH_LOGGED_IN, token: newToken });
      }
    }

    if (appVersion) {
      const state = store.getState();
      const storedStateVersion = _get(state, ['appVersion', 'version']);

      const iosPlatformInstances = ['iPhone', 'iPad'];
      let platform;
      if (!window.isCordovaApp) {
        platform = 'web';
      } else if (window.navigator && (indexOf(iosPlatformInstances, window.navigator.platform) > -1)) {
        platform = 'ios';
      } else {
        platform = 'android';
      }

      const platformAppVersion = JSON.parse(appVersion)[platform];
      const storedStateVersionChunks = ((storedStateVersion && /^\d*\.\d*\.\d*$/.test(storedStateVersion + '')) ? storedStateVersion.split('.').map(Number) : [0, 0, 0]);
      const platformAppVersionChunks = platformAppVersion.split('.').map(Number);

      const appVersionControlOpened = _get(state, ['appVersion', 'notification', 'active']);
      if ((platformAppVersionChunks[0] > storedStateVersionChunks[0]) || ((platformAppVersionChunks[0] === storedStateVersionChunks[0]) && (platformAppVersionChunks[1] > storedStateVersionChunks[1]))) {
        const data = {
          newVersion: platformAppVersion,
          type: 'main',
          platform,
        };
        store.dispatch(appVersionActions.openAppVersionControlNotification(data));
      } else if ((platformAppVersionChunks[0] === storedStateVersionChunks[0]) && (platformAppVersionChunks[1] === storedStateVersionChunks[1]) && (platformAppVersionChunks[2] > storedStateVersionChunks[2])) {
        const data = {
          newVersion: platformAppVersion,
          type: 'minor',
          platform,
        };
        store.dispatch(appVersionActions.openAppVersionControlNotification(data));
      } else if (appVersionControlOpened) {
        store.dispatch(appVersionActions.closeAppVersionControlNotification());
      }
    }

    const remoteRefreshRequested = response.headers['force-reload']
      ? JSON.parse(response.headers['force-reload'])
      : false;

    if (remoteRefreshRequested) {
      store.dispatch({ type: appControlTypes.UPDATE_APP });
    }

    return response;
  },
  (error) => {
    const { data, status = 'No Status', config, headers } = error.response || {};
    const wrongClinic = String(_get(headers, 'wrongClinic')) === 'true';
    const message = _get(data, ['errors', 0, 'errorMessages', 0]) || _get(data, ['error'], 'No API response.');
    Log.error('Response ERROR', message, `(Status: ${status})`);

    if (status === 401) store.dispatch(logout(false));

    const shouldIgnore = ignoreStatusCodeError.includes(status)
    || shouldFilterErrorByMessage(message)
    || shouldFilterErrorByMessage(error.message);

    if (!shouldIgnore) {
      const reportError = new Error(message);

      reportError.name = `${status} Error`;

      Bugsnag.notify(reportError, event => {
        event.addMetadata('request_data', { data: JSON.stringify(error, null, 2) });
      });
    }

    // Redirect on status code 404 if fallback route is set
    if (status === 404 && config['api-fallback']) {
      let routePath;
      // Check if custom route is set
      if (typeof config['api-fallback'] === 'string') {
        routePath = router.getRoutePath(config['api-fallback']);
      } else {
        // Fallback to not-found paga
        routePath = router.getRoutePath('notFound');
      }
      store.dispatch(router.pushPath(routePath));
    }

    if (wrongClinic) {
      userNotifications.confirmI18n('alert/wrong_clinic').then((reload: boolean) => {
        if (reload) {
          store.dispatch(appControlActions.updateApp());
        }
      });
    }

    const errorDt = new Error(message);
    errorDt.raw = _get(data, ['errors'], []) || [];
    // reject promise to trigger catch(error) within the call function (e.g. GET)
    return Promise.reject(errorDt);
  });

  // Never include cookies in API requests,
  // to be consistent with the behaviour of modern browsers.
  // See https://app.asana.com/0/1115564467030975/1134669226797567
  axios.defaults.withCredentials = false;
  axios.defaults.credentials = 'omit';
}

export default {
  get,
  post,
  destroy,
  put,
  patch,
  initialize,
};
