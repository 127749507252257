// @flow
import React from 'react';
import { get } from 'lodash';
import { SettingsMenu, FormFooter, PlatformSwitch, AppMobileHeader, AppMobileFooter } from '@blocks';
import { Breadcrumb, Input, AddressFields } from '@elements';
import type { User, BundledPermissions } from '@types';
import { createValidator } from '@helpers/validator';
import { withForm } from '@hocs';
import { withTranslation } from 'react-i18next';
import { createNsTranslator, handleSubmitEvent } from '@helpers/helpers';
import './ClinicConfigAddress.scss';

type Props = {
  user: User,
  handleFormChange: Function,
  handleTouchField: Function,
  resetData: Function,
  touchedFields: Object,
  formData: Object,
  bindValidation: Function,
  onSave: Function,
  onLogout: Function,
  isLoading: boolean,
  permissions: BundledPermissions,
  formEdited: Function,
  setInitialData: Function,
  t: Function,
};

type State = {
  errors: Object,
};


const validator = createValidator()
  .field('name')
  .present('Informe um nome');

class ClinicConfigAddress extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      errors: {},
    };
    this.props.bindValidation(this.handleValidation);
  }

  componentDidMount() {
    handleSubmitEvent('#clinicConfigAddresDesktopForm', () => {
      if (this.props.formEdited()) {
        this.onSave();
      }
    });
  }

  render() {
    const {
      user, formData, handleFormChange, handleTouchField, resetData,
      isLoading, onLogout,
    } = this.props;

    const _ = createNsTranslator('settings', this.props.t);

    const breadcrumbLinks = [
      { text: _('label/clinic'), linkTo: '/ajustes/clinica' },
      { text: _('label/address') },
    ];

    const { errors } = this.state;

    const edited = this.props.formEdited();

    const pageContent = (
      <React.Fragment>
        <div className="toolbar dashed">
          <h1>{_('label/clinic_addr/title')}</h1>
        </div>
        <Input
          name="name"
          autofocus
          value={get(formData, 'name', '')}
          onChange={handleFormChange}
          onBlur={handleTouchField}
          className="field"
          label={_('label/clinic_addr/clinic_name')}
          error={get(errors, 'name', null)}
          disabled={isLoading}
          required
        />
        <Input
          name="phone"
          value={get(formData, 'phone', '')}
          onChange={handleFormChange}
          className="field"
          label={_('label/phone', 'global')}
          disabled={isLoading}
        />
        <AddressFields
          unform
          onChange={handleFormChange}
          onBlur={handleTouchField}
          stateFieldCanBeEmpty
          addressZipcode={get(formData, 'addressZipcode', '')}
          addressStreet={get(formData, 'addressStreet', '')}
          addressNumber={get(formData, 'addressNumber', '')}
          addressNeighborhood={get(formData, 'addressNeighborhood', '')}
          addressCity={get(formData, 'addressCity', '')}
          addressState={get(formData, 'addressState', '')}
          addressExtra={get(formData, 'addressExtra', '')}
        />
        <FormFooter onSave={this.onSave} onCancel={resetData} isLoading={isLoading} edited={edited} editFeature cancelLinkTo="/ajustes" />
      </React.Fragment>
    );

    return (
      <PlatformSwitch
        inject
        desktop={() => (
          <div id="clinicConfigAddresDesktopForm" className="appContent settingsPage">
            <aside className="sidebar">
              <SettingsMenu user={user} onLogout={onLogout} permissions={this.props.permissions} />
            </aside>
            <section className="mainSection">
              <div className="centerContent">
                <Breadcrumb items={breadcrumbLinks} />
                {pageContent}
              </div>
            </section>
          </div>
        )}
        mobile={() => (
          <React.Fragment>
            <AppMobileHeader title={_('label/clinic_addr/title2')} />
            <div id="clinicConfigAddres" className="appContent settingsPage">
              <section className="mainSection">
                <div className="centerContent">
                  {pageContent}
                </div>
              </section>
            </div>
            <AppMobileFooter />
          </React.Fragment>
        )}
      />
    );
  }

  onSave = () => this.handleValidation(true).then(valid => {
    if (valid) {
      const { onSave, formData, setInitialData } = this.props;
      setInitialData(formData);
      onSave(formData);
    }
  });


  handleValidation = (isSubmit: boolean = false): Promise<boolean> => new Promise((resolve: Function) => {
    const { formData, touchedFields } = this.props;
    const result = validator.validate(formData, touchedFields, isSubmit);
    this.setState({ errors: result.errors }, () => resolve(result.valid));
  });
}

export default withTranslation()(withForm()(ClinicConfigAddress));
