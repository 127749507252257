// @flow
import type { Action, ContextState, Dispatch, PlanType, SubscriptionUserInfoInput, SubscriptionPaymentCreditCardType } from '@types';
import { reportError } from '@helpers/helpers';
import { get } from 'lodash';
import { Api } from '../../../helpers';
import { router } from '../../../routes/router';
import { directUpdateUser } from './user';


// ------------------------------------
// Constants
// ------------------------------------
export const SUBSCRIPTION_SELECT_PLAN_LOADING = 'subscription.SUBSCRIPTION_SELECT_PLAN_LOADING';
export const SUBSCRIPTION_SELECT_PLAN_LOADED = 'subscription.SUBSCRIPTION_SELECT_PLAN_LOADED';
export const SUBSCRIPTION_SELECT_PLAN_ERROR = 'subscription.SUBSCRIPTION_SELECT_PLAN_ERROR';

export const SUBSCRIPTION_UPDATE_USER_INFO_LOADING = 'subscription.SUBSCRIPTION_UPDATE_USER_INFO_LOADING';
export const SUBSCRIPTION_UPDATE_USER_INFO_LOADED = 'subscription.SUBSCRIPTION_UPDATE_USER_INFO_LOADED';
export const SUBSCRIPTION_UPDATE_USER_INFO_ERROR = 'subscription.SUBSCRIPTION_UPDATE_USER_INFO_ERROR';

export const SUBSCRIPTION_GET_TRACK_INFO_LOADING = 'subscription.SUBSCRIPTION_GET_TRACK_INFO_LOADING';
export const SUBSCRIPTION_GET_TRACK_INFO_LOADED = 'subscription.SUBSCRIPTION_GET_TRACK_INFO_LOADED';
export const SUBSCRIPTION_GET_TRACK_INFO_ERROR = 'subscription.SUBSCRIPTION_GET_TRACK_INFO_ERROR';

export const SUBSCRIPTION_GET_USER_INFO_INFO_LOADING = 'subscription.SUBSCRIPTION_GET_USER_INFO_INFO_LOADING';
export const SUBSCRIPTION_GET_USER_INFO_INFO_LOADED = 'subscription.SUBSCRIPTION_GET_USER_INFO_INFO_LOADED';
export const SUBSCRIPTION_GET_USER_INFO_INFO_ERROR = 'subscription.SUBSCRIPTION_GET_TRACK_INFO_ERROR';

export const SUBSCRIPTION_SIGN_PLAN_LOADING = 'subscription.SUBSCRIPTION_SIGN_PLAN_LOADING';
export const SUBSCRIPTION_SIGN_PLAN_LOADED = 'subscription.SUBSCRIPTION_SIGN_PLAN_LOADED';
export const SUBSCRIPTION_SIGN_PLAN_ERROR = 'subscription.SUBSCRIPTION_SIGN_PLAN_ERROR';

const SUBSCRIPTION_USER_GET_AVAILABLE_PLANS_LOADING = 'subscription.SUBSCRIPTION_USER_GET_AVAILABLE_PLANS_LOADING';
const SUBSCRIPTION_USER_GET_AVAILABLE_PLANS_LOADED = 'subscription.SUBSCRIPTION_USER_GET_AVAILABLE_PLANS_LOADED';
const SUBSCRIPTION_USER_GET_AVAILABLE_PLANS_FAILED = 'subscription.SUBSCRIPTION_USER_GET_AVAILABLE_PLANS_FAILED';

const SUBSCRIPTION_REQUEST_NEW_BOLETO_LOADING = 'subscription.SUBSCRIPTION_REQUEST_NEW_BOLETO_LOADING';
const SUBSCRIPTION_REQUEST_NEW_BOLETO_LOADED = 'subscription.SUBSCRIPTION_REQUEST_NEW_BOLETO_LOADED';
const SUBSCRIPTION_REQUEST_NEW_BOLETO_FAILED = 'subscription.SUBSCRIPTION_REQUEST_NEW_BOLETO_FAILED';

// ------------------------------------
// Actions
// ------------------------------------


export const getSubscriptionTrack = (trackName: string) =>
  (dispatch: Dispatch) => {
    dispatch({ type: SUBSCRIPTION_GET_TRACK_INFO_LOADING });

    Api.get(router.getApiRoute('getSubscriptionTrack', { trackName }), {
      success: (response) => {
        dispatch({
          type: SUBSCRIPTION_GET_TRACK_INFO_LOADED,
          data: get(response, 'subscription'),
          trackName,
        });
      },
      error: (error) => {
        dispatch({ type: SUBSCRIPTION_GET_TRACK_INFO_ERROR, error: { message: error.message } });
      },
    });
  };


export const getActiveSubscriptionUserInfo = () =>
  (dispatch: Dispatch) => {
    dispatch({ type: SUBSCRIPTION_GET_USER_INFO_INFO_LOADING });

    Api.get(router.getApiRoute('getActiveSubscriptionUserInfo', { }), {
      success: (response) => {
        dispatch({
          type: SUBSCRIPTION_GET_USER_INFO_INFO_LOADED,
          data: get(response, 'subscription', {}),
        });
      },
      error: (error) => {
        dispatch({ type: SUBSCRIPTION_GET_USER_INFO_INFO_ERROR, error: { message: error.message } });
      },
    });
  };

export const getSubscriptionUserInfo = () =>
  (dispatch: Dispatch) => {
    dispatch({ type: SUBSCRIPTION_GET_USER_INFO_INFO_LOADING });

    Api.get(router.getApiRoute('getSubscriptionUserInfo', { }), {
      success: (response) => {
        dispatch({
          type: SUBSCRIPTION_GET_USER_INFO_INFO_LOADED,
          data: get(response, 'subscription', {}),
        });
      },
      error: (error) => {
        dispatch({ type: SUBSCRIPTION_GET_USER_INFO_INFO_ERROR, error: { message: error.message } });
      },
    });
  };

export const selectPlan = (plan: PlanType, value: number, isStripe: boolean = false) =>
  (dispatch: Dispatch) => {
    dispatch({ type: SUBSCRIPTION_SELECT_PLAN_LOADING });
    Api.post(router.getApiRoute('selectPlan', { }), {
      data: { plan },
      success: () => {
        dispatch({ type: SUBSCRIPTION_SELECT_PLAN_LOADED, plan, value });
        if (isStripe) {
          dispatch(router.pushPath('subscriptionComplete'));
        } else {
          dispatch(router.pushPath('subscriptionAddress'));
        }
      },
      error: (error) => {
        dispatch({ type: SUBSCRIPTION_SELECT_PLAN_ERROR, error: { message: error.message } });
        dispatch(reportError(error));
      },
    });
  };


export const signPlan = (paymentType: string, creditCardData: SubscriptionPaymentCreditCardType, redirect: boolean = false, type: string) =>
  (dispatch: Dispatch) => {
    dispatch({ type: SUBSCRIPTION_SIGN_PLAN_LOADING });
    Api.patch(router.getApiRoute('subscriptionUpdatePaymentType', null), {
      data: {
        payment: {
          type: paymentType,
          ...(creditCardData ? {
            credit_card: {
              ...creditCardData,
              type,
            },
          } : {}),
        },
      },
      success: (response) => {
        dispatch(directUpdateUser(get(response, 'user', {})));
        dispatch({
          type: SUBSCRIPTION_SIGN_PLAN_LOADED,
          paymentType,
        });
        if (redirect) {
          dispatch(router.pushPath('subscriptionComplete'));
        }
      },
      error: (error) => {
        dispatch({ type: SUBSCRIPTION_SIGN_PLAN_ERROR, error: { message: error.message } });
        dispatch(reportError(error));
      },
    });
  };


export const newBoleto = () => (
  (dispatch: Dispatch) => {
    dispatch({ type: SUBSCRIPTION_REQUEST_NEW_BOLETO_LOADING });
    Api.post(router.getApiRoute('newBoleto', { }), {
      data: { },
      success: () => {
        dispatch({ type: SUBSCRIPTION_REQUEST_NEW_BOLETO_LOADED });
        dispatch(getActiveSubscriptionUserInfo());
      },
      error: (error) => {
        dispatch({ type: SUBSCRIPTION_REQUEST_NEW_BOLETO_FAILED, error: { message: error.message } });
        dispatch(reportError(error));
      },
    });
  });

export const updateUserInfo = (plan: PlanType, userInfo: SubscriptionUserInfoInput, redirect: boolean = true) =>
  (dispatch: Dispatch) => {
    dispatch({ type: SUBSCRIPTION_UPDATE_USER_INFO_LOADING });

    Api.patch(router.getApiRoute('subscriptionUpdateUserInfo', null), {
      data: {
        user: {
          ...userInfo,
          plan,
        },
      },
      success: (response) => {
        dispatch({
          type: SUBSCRIPTION_UPDATE_USER_INFO_LOADED,
          info: get(response, 'subscription'),
        });
        if (redirect) {
          dispatch(router.pushPath('subscriptionPayment'));
        }
      },
      error: (error) => {
        dispatch({ type: SUBSCRIPTION_UPDATE_USER_INFO_ERROR, error: { message: error.message } });
        dispatch(reportError(error));
      },
    });
  };

export const getAvailablePlans = () => (
  (dispatch: Dispatch) => {
    dispatch({ type: SUBSCRIPTION_USER_GET_AVAILABLE_PLANS_LOADING });
    Api.get(router.getApiRoute('getAvailablePlans', { }), {
      success: (response) => {
        dispatch({
          type: SUBSCRIPTION_USER_GET_AVAILABLE_PLANS_LOADED,
          plans: get(response, 'availablePlans', {}),
        });
      },
      error: (error) => {
        dispatch({
          type: SUBSCRIPTION_USER_GET_AVAILABLE_PLANS_FAILED, error: { message: error.message },
        });
      },
    });
  }
);

export const subscruptionActions = {
  selectPlan,
  updateUserInfo,
  getSubscriptionTrack,
  getAvailablePlans,
};

// ------------------------------------
// Reducer
// ------------------------------------
export default function subscruptionReducer (state: ContextState, action: Action): ContextState {
  switch (action.type) {
    case SUBSCRIPTION_USER_GET_AVAILABLE_PLANS_LOADING:
      return state
        .setIn(['subscription', 'availablePlansLoading'], true)
        .setIn(['subscription', 'availablePlansError'], false);

    case SUBSCRIPTION_USER_GET_AVAILABLE_PLANS_LOADED:
      return state
        .setIn(['subscription', 'availablePlansLoading'], false)
        .setIn(['subscription', 'availablePlansError'], false)
        .setIn(['subscription', 'availablePlans'], action.plans);

    case SUBSCRIPTION_USER_GET_AVAILABLE_PLANS_FAILED:
      return state
        .setIn(['subscription', 'availablePlansLoading'], false)
        .setIn(['subscription', 'availablePlansError'], true);


    case SUBSCRIPTION_SELECT_PLAN_LOADING:
      return state
        .setIn(['subscription', 'isSelectingPlan'], true);

    case SUBSCRIPTION_SELECT_PLAN_LOADED:
      return state
        .setIn(['subscription', 'isSelectingPlan'], false)
        .setIn(['subscription', 'selectedPlan'], action.plan)
        .setIn(['subscription', 'planValue'], action.value);

    case SUBSCRIPTION_SELECT_PLAN_ERROR:
      return state
        .setIn(['subscription', 'isSelectingPlan'], false)
        .setIn(['subscription', 'errors'], [action.error.message]);

    case SUBSCRIPTION_UPDATE_USER_INFO_LOADING:
      return state
        .setIn(['subscription', 'isSaving'], true);

    case SUBSCRIPTION_UPDATE_USER_INFO_LOADED:
      return state
        .setIn(['subscription', 'isSaving'], false)
        .setIn(['subscription', 'userInfo'], action.info)
        .setIn(['user', 'countryCode'], get(action.info, ['userInfo', 'countryCode'], null))
        .setIn(['user', 'email'], get(action.info, ['userInfo', 'email'], null))
        .setIn(['user', 'firstNameWithTitle'], get(action.info, ['userInfo', 'firstNameWithTitle'], null))
        .setIn(['user', 'gender'], get(action.info, ['userInfo', 'gender'], null))
        .setIn(['user', 'mobilePhone'], get(action.info, ['userInfo', 'mobilePhone'], null))
        .setIn(['user', 'name'], get(action.info, ['userInfo', 'name'], null))
        .setIn(['user', 'nameWithTitle'], get(action.info, ['userInfo', 'nameWithTitle'], null));
      // .setIn(['user', 'roleType'], get(action.info, ['userInfo', 'roleType'], null))
      // .setIn(['user', 'professionCode'], get(action.info, ['userInfo', 'professionCode'], null));


    case SUBSCRIPTION_UPDATE_USER_INFO_ERROR:
      return state
        .setIn(['subscription', 'isSaving'], false)
        .setIn(['subscription', 'errors'], [action.error.message]);

    case SUBSCRIPTION_SIGN_PLAN_LOADING:
      return state
        .setIn(['subscription', 'isSaving'], true);

    case SUBSCRIPTION_SIGN_PLAN_LOADED:
      return state
        .setIn(['subscription', 'paymentType'], action.paymentType)
        .setIn(['subscription', 'isSaving'], false);

    case SUBSCRIPTION_SIGN_PLAN_ERROR:
      return state
        .setIn(['subscription', 'isSaving'], false)
        .setIn(['subscription', 'errors'], [action.error.message]);


    case SUBSCRIPTION_GET_TRACK_INFO_LOADING:
      return state
        .setIn(['subscription', 'isLoading'], true);

    case SUBSCRIPTION_GET_TRACK_INFO_LOADED:
      return state
        .setIn(['subscription', 'trackInfo', action.trackName], action.data)
        .setIn(['subscription', 'isLoading'], false);

    case SUBSCRIPTION_GET_TRACK_INFO_ERROR:
      return state
        .setIn(['subscription', 'isLoading'], false)
        .setIn(['subscription', 'errors'], [action.error.message]);


    case SUBSCRIPTION_GET_USER_INFO_INFO_LOADING:
      return state
        .setIn(['subscription', 'isLoading'], true);

    case SUBSCRIPTION_GET_USER_INFO_INFO_LOADED:
      return state
        .setIn(['subscription', 'subscriptionData'], action.data)
        .setIn(['subscription', 'selectedPlan'], action.data.plan)
        .setIn(['subscription', 'planValue'], action.data.subscriptionPrice)
        .setIn(['subscription', 'userInfo'], get(action, ['data', 'userInfo'], {}))
        .setIn(['subscription', 'isLoading'], false);

    case SUBSCRIPTION_GET_USER_INFO_INFO_ERROR:
      return state
        .setIn(['subscription', 'isLoading'], false)
        .setIn(['subscription', 'errors'], [action.error.message]);

    case SUBSCRIPTION_REQUEST_NEW_BOLETO_LOADING:
      return state
        .setIn(['subscription', 'isRequestingNewBoleto'], true);

    case SUBSCRIPTION_REQUEST_NEW_BOLETO_LOADED:
      return state
        .setIn(['subscription', 'isRequestingNewBoleto'], false);

    case SUBSCRIPTION_REQUEST_NEW_BOLETO_FAILED:
      return state
        .setIn(['subscription', 'isRequestingNewBoleto'], false)
        .setIn(['subscription', 'errors'], [action.error.message]);

    default:
      return state;
  }
}
