// @flow
import React from 'react';
import { Button } from '../../elements';
import type { AppVersionNotificationData } from '../../../types';
import './AppVersionControl.scss';

type Props = {
  notificationData: AppVersionNotificationData,
  onClose: Function,
  onUpdateDoutore: Function,
};

export default class AppVersionControl extends React.Component<Props> {
  render() {
    const { notificationData } = this.props;

    return (
      notificationData.type === 'main' ? (
        <div className='appVersionNotificationWrapperFull'>
          <div className='content'>
            <h2>{`Nova versão disponível (v${notificationData.newVersion})`}</h2>
            <p>Você não está usando a versão mais atualizada do Doutore.</p>

            <div className='actionsWrapper'>
              <Button
                className='primary intercom'
                text='PEDIR AJUDA'
                onClick={this.onContact}
              />
              <Button
                className='primary update'
                text='ATUALIZAR O DOUTORE'
                onClick={this.onUpdateDoutore}
              />
            </div>
          </div>
        </div>
      ) : (
        <div className='appVersionNotificationWrapperToast'>
          <span className='label'>
            {`Nova versão do Doutore v${notificationData.newVersion}`}
          </span>

          <div className='actionsWrapper'>
            <Button
              className='primary'
              text='ATUALIZAR'
              onClick={this.onUpdateDoutore}
            />
            <Button
              className='deleteButton'
              text='CANCELAR'
              onClick={this.onCloseNotification}
            />
          </div>
        </div>
      )
    );
  }

  onCloseNotification = () => {
    const { onClose } = this.props;
    if (onClose) {
      onClose();
    }
  };

  onContact = () => {
    window.Intercom('show');
  };

  onUpdateDoutore = () => {
    const { onUpdateDoutore } = this.props;
    if (onUpdateDoutore) {
      onUpdateDoutore();
    }
  };
}
