// @flow
import React, { useMemo, useState, useCallback } from 'react';
import type { ActionItems } from '@types';
import classNames from 'classnames';
import { ClickableList, Modal, SearchInput } from '@elements';
import { filterSearch } from '@helpers/helpers';
import { PlatformSwitch } from '@blocks';
import './ModalClickableList.scss';

type Props = {
  items: ActionItems,
  onListItemClick: Function,
  onClose: Function,
  notUsePortal?: boolean,
  search?: boolean,
  customRenderer?: Function, // (item) => (node(item)) -> return custom renderer element / node with corresponding data
};

const ModalClickableList = ({ items, onClose, onListItemClick, notUsePortal, search, customRenderer }: Props) => {
  const [searchText, setSearchText] = useState('');

  const fItems = useMemo(() => filterSearch(items, searchText), [items, searchText]);

  const handleChangeSearchInput = useCallback((value: string) => {
    setSearchText(value);
  }, []);

  const render = (isMobile: boolean) => (
    <Modal className={classNames('modalClickableList', 'clickableList', { searchable: search, isMobile })} onClose={onClose} throughPortal={!notUsePortal}>
      {search && (
        <div className="searchInputBox">
          <SearchInput autofocus onChange={handleChangeSearchInput} uncontrolled className="searchInputList"/>
        </div>
      )}
      <ClickableList customRenderer={customRenderer} items={fItems} onListItemClick={onListItemClick} />
    </Modal>
  );

  return (
    <PlatformSwitch mobile={() => render(true)} desktop={() => render(false)}/>
  );
};

export default ModalClickableList;
