// @flow
import React from 'react';
import { withRouter } from 'react-router-dom';
import { map, find, get, isEqual } from 'lodash';
import Tabs, { TabPane } from 'rc-tabs';
import TabContent from 'rc-tabs/lib/TabContent';
import SwipeableInkTabBar from 'rc-tabs/lib/SwipeableInkTabBar';
import classnames from 'classnames';
import './SwipeTabs.scss';

type Item = {
  title?: ?string,
  text?: ?string,
  linkTo?: string,
  value: string | number,
};

type Props = {
  items: Array<Item>,
  active?: string | number,
  className?: string,
  id?: string,
  pageSize?: number,
  onTabChange?: Function,
  history: Object, // from withRouter
};

const makeMultiTabPane = (items) => {
  const result = map<any, any>(items, (item) => (
    <TabPane
      tab={
        <div data-extra="tab-bar-title">
          {`${get(item, 'title', item.text)}`}
        </div>
      }
      data-extra="tabpane"
      key={`${get(item, 'title', item.text)}`}
      link={item.linkTo}
    />
  ));
  return result;
};

class SwipeTabs extends React.Component<Props> {
  shouldComponentUpdate(nextProps) {
    return !isEqual(this.props, nextProps);
  }

  render() {
    const {
      items,
      className,
      id,
      pageSize,
      active,
    } = this.props;

    const cssClasses = classnames(['swipeTabs', className]);

    const activeTab = active || (items.length > 0 ? get(items[0], 'title', items[0].text) : null);

    return (
      <div id={id} className={cssClasses}>
        <Tabs
          onChange={this.onTabChange.bind(this)}
          data-extra="tabs"
          renderTabBar={() => (
            <SwipeableInkTabBar
              pageSize={pageSize || 2}
              speed={2}
              data-extra="tabbar"
            />

          )}
          renderTabContent={() => <TabContent />}
          defaultActiveKey={activeTab}
        >
          {makeMultiTabPane(items)}
        </Tabs>
      </div>
    );
  }

  onTabChange = (value: string | number) => {
    const item = find(this.props.items, (obj) => (get(obj, 'title', obj.text) === value));
    if (item && item.linkTo) {
      this.props.history.push(item.linkTo);
    }
    if (this.props.onTabChange && item) {
      this.props.onTabChange(item.value);
    }
  }
}
export default withRouter(SwipeTabs);
