// @flow
import React from 'react';
import { map, find, isString, get, findIndex, isEqual } from 'lodash';
import ReactDOM from 'react-dom';
import { isEqualValue, isPresent } from '@helpers/helpers';
import classnames from 'classnames';
import { Icon, ModalClickableList } from '../';
import type { InputOptions } from '../../../types';
import './SelectBox.scss';

type State = {
  opened: boolean,
  activeIndex: number,
  value: any;
};

type Props = {
  name?: string,
  // eslint-disable-next-line
  options: InputOptions,
  className?: string,
  label?: string,
  value?: string | number | boolean | Object | null,
  defaultValue?: string | number | boolean | Object,
  disabled?: boolean,
  onChange: Function,
  optionValueIndicator?: string,
  error?: string,
  mobileLabel?: string,
  searchDebounceTime?: number,
  uncontrolled?: boolean,
  // includeBlank: boolean,
};

export default class SelectBox extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    const value = this.props.defaultValue || this.props.uncontrolled ? this.props.defaultValue : this.props.value;

    this.state = {
      opened: false,
      activeIndex: findIndex(this.props.options, opt => isEqualValue(opt.value, value) || isEqualValue(opt.text, value)),
      value,
    };
  }

  componentDidMount() {
    if (!window.isCordovaApp) {
      document.addEventListener('mousedown', this.handleMouseDown, false);
    }
    document.addEventListener('keydown', this.handleKeyDown);
  }

  shouldComponentUpdate(nextProps: Props, nextState: State) {
    window.__hasSelectOpened = nextState.opened;
    return !isEqual(this.props, nextProps) || !isEqual(this.state, nextState);
  }

  componentWillUnmount() {
    if (!window.isCordovaApp) {
      document.removeEventListener('mousedown', this.handleMouseDown, false);
    }
    document.removeEventListener('keydown', this.handleKeyDown);
    window.__hasSelectOpened = false;
  }

  render() {
    const {
      className, label, disabled,
      optionValueIndicator, error, mobileLabel,
    } = this.props;

    const { opened, activeIndex } = this.state;


    const cssClasses = classnames([
      'inputHolder',
      'selectBoxHolder',
      className,
      disabled ? 'disabled' : null,
      error ? 'error' : null,
    ]);

    const options = this.getOptions();

    const optionsItems = map<any, any>(options, (option, key) => {
      const itemClass = classnames([
        'listItem',
        activeIndex === key ? 'activeIndex' : null,
        this.state.value === option.value ? 'selectedItem' : null,
        option.isEmpty ? 'isEmptyOption' : null,
        option.className,
      ]);

      return (
        <li
          className={itemClass}
          key={key}
          role="presentation"
          onClick={this.onSelect(option)}
          ref={ref => {
            if (activeIndex === key) {
              this.activeNode = ref;
              this.activeIndex = key;
            }
          }}
        >
          {option.isEmpty && <span>&nbsp;</span>}
          {option[optionValueIndicator] || option.text}
        </li>
      );
    });

    let itLabel = isPresent(mobileLabel) ? mobileLabel : label;
    itLabel = isPresent(itLabel) ? itLabel : null;

    const mobileItens: Array<any> = window.isCordovaApp ? [
      ...(isPresent(itLabel) ? [{ text: itLabel, className: 'headingSeparator mobile' }] : []),
      ...map(options, opt => ({
        ...opt,
        className: this.state.value === opt.value ? 'listItem active' : 'listItem',
      })),
    ] : [];

    return (
      <div className={cssClasses} role="presentation" onClick={this.onSelectClick.bind(this)} ref={ref => { this.node = ref; }} tabIndex="0" onKeyDown={this.keyDown}>
        { label && <label className="formInputLabel">{label}</label> }

        <div className="selectBox">
          <div className="selectBoxTrigger">
            {this.getSelectedText()}
            <Icon iconName="arrow-down" className="icon selectIcon" />
          </div>

          {opened && !window.isCordovaApp && (
            <ul className="selectBoxOptions" ref={ref => { this.optionsNode = ref; }}>
              {optionsItems}
            </ul>
          )}

          {opened && window.isCordovaApp && (
            <ModalClickableList
              items={mobileItens}
              onClose={this.closeDropdown}
              onListItemClick={this.onListItemClick}
            />
          )}

        </div>
        {error && <span className="errMessage">{`*${error}`}</span>}

      </div>
    );
  }

  keyDown = (e: any) => {
    if (e.keyCode === 32 || (!this.state.opened && e.keyCode === 13)) {
      this.onSelectClick();
    }
  };

  node: any = null;
  activeNode: any;
  optionsNode: any;
  activeIndex: any;


  handleMouseDown = (e: Object) => {
    if (this.node && this.node.contains(e.target)) {
      return;
    }
    this.closeDropdown();
  };

  getOptions = (): Array<Object> => map(get(this.props, 'options'), opt => (!isString(opt) ? opt : {
    text: opt,
    value: opt,
  }));

  onSelect = (option: Object) => () => {
    const {
      onChange, name, optionValueIndicator, options,
    } = this.props;
    const value = get(option, optionValueIndicator) || get(option, 'value') || get(option, 'text');
    const data = { name, value };
    this.setState({ value, activeIndex: findIndex(options, opt => isEqualValue(opt.value, value)) });
    onChange(data);
  };

  onListItemClick = (option: Object) => {
    if (!option.value) return;

    this.onSelect(option)();
  };

  onSelectClick = () => {
    const opened = this.props.disabled ? false : !this.state.opened;
    window.__hasSelectOpened = opened;
    this.setState({ opened }, () => {
      if (this.state.opened) {
        this.focusSelected();
      }
    });
  };

  getSelectedText() {
    const { optionValueIndicator } = this.props;
    const { value } = this.state;
    const item = find(this.getOptions(), (option) => (isEqualValue(get(option, [optionValueIndicator || 'value']), value)));
    return item ? <span className={classnames('selectedWrapper', item.className, item.isEmpty ? 'isEmptyOption' : null)}>{item[optionValueIndicator] || item.text}{item.isEmpty && <span>&nbsp;</span>}</span> : <span>&nbsp;</span>;
  }

  handleKeyDown = (e: Object) => {
    if (this.state.opened) {
      const options = this.getOptions();
      if (e.key === 'ArrowUp') {
        const nextIndex = this.state.activeIndex - 1;
        const activeIndex = nextIndex < 0 ? 0 : nextIndex;
        this.setState({ activeIndex }, this.focusSelected());
      } else if (e.key === 'ArrowDown') {
        const nextIndex = this.state.activeIndex + 1;
        const len = options.length;
        const activeIndex = (nextIndex + 1) <= len ? nextIndex : len - 1;
        this.setState({ activeIndex }, this.focusSelected());
      } else if (e.key === 'Enter') {
        this.onSelect(options[this.state.activeIndex])();
        this.closeDropdown();
      } else {
        this.handleKeySearch(e.key);
      }
    }
  }

  closeDropdown = () => {
    this.setState({ opened: false }, () => { window.__hasSelectOpened = false; });
  };

  focusSelected = () => {
    if (this.activeNode && this.optionsNode) {
      const node: any = ReactDOM.findDOMNode(this.optionsNode);
      const targetNode: any = ReactDOM.findDOMNode(this.activeNode);
      if (node) {
        node.scrollTop = get(targetNode, 'offsetTop', 0) - targetNode.clientHeight;
      }
    }
  };

  debounceTimer: any = null;
  firstFindLetters = [];
  willClearFind: boolean = true;

  handleKeySearch = (rawKey: string) => {
    const key = rawKey.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
    if (key.length > 1) return; // Alow only char keys
    const { options } = this.props;

    if (this.debounceTimer) {
      clearTimeout(this.debounceTimer);
    }

    if (this.willClearFind) {
      this.firstFindLetters = [];
    }
    this.firstFindLetters.push(key);
    this.willClearFind = false;
    this.debounceTimer = setTimeout(() => { this.willClearFind = true; }, this.props.searchDebounceTime || 800);
    const rStart = new RegExp(`^${this.firstFindLetters.join('')}.*$`, 'g');
    const activeIndex = findIndex(options, o =>
      String(o.findString ? o.findString : o.text)
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .toLowerCase()
        .match(rStart));

    this.setState({ activeIndex }, this.focusSelected);
  };
}
