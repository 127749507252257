// @flow
import React from 'react';
import { Link } from 'react-router-dom';
import { isEmail } from 'validator';
import type { Errors, AuthInProgress, FormElementData } from '@types';
import { Button, Input, Icon } from '@elements';
import { withTranslation } from 'react-i18next';
import { createNsTranslator, fSufixTwoPoints } from '@helpers/helpers';
import './Login.scss';

type Props = {
  authErrors: Errors,
  inProgress: AuthInProgress,
  onSubmit: Function,
  defaultEmail: string,
  t: Function,
};

type State = {
  email: ?string,
  password: ?string,
  errorMessages: Errors,
  isSubmitted: boolean,
};

class Login extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      email: this.props.defaultEmail,
      password: null,
      isSubmitted: false,
      errorMessages: [],
    };
  }

  render() {
    const {
      errorMessages,
      isSubmitted,
      email,
      password,
    } = this.state;

    const {
      authErrors,
      inProgress,
    } = this.props;

    let errors;
    if (isSubmitted) {
      errors = errorMessages.length ? errorMessages : authErrors;
    }

    const _ = createNsTranslator('onboarding', this.props.t);

    return (
      <div id="signUpPage" className="signUpLayout">

        <div className="signUpLayoutLeft">
          <div className="signupHeader">
            <Link className="logo" to='/' />
            <hr className="fade" />
          </div>

          <div className="form">
            <h1 className="loginPageTitle">
              <Icon iconName="lock" className="icon" size={28} />
              <span>{_('label/login')}</span>
              <span className='loginError'>{errors && errors[0]}</span>
            </h1>
            <form onSubmit={this.handleLoginForm} method="POST">

              <Input
                type="email"
                name="email"
                id="loginEmail"
                label={_('label/email', null, fSufixTwoPoints)}
                value={email || ''}
                onChange={this.handleInputChange.bind(this)}
                autoComplete="username"
              />
              <Input
                type="password"
                name="password"
                id="loginPassword"
                label={_('label/password', null, fSufixTwoPoints)}
                value={password || ''}
                onChange={this.handleInputChange.bind(this)}
                autoComplete="current-password"
              />

              <Button
                className="primary"
                text={inProgress ? _('label/wating', 'global') : _('label/login')}
                onClick={this.handleLogin.bind(this)}
                disabled={inProgress}
              />

              <div className="signUpLayoutLeftFooter">
                <Link to='/esqueci-senha'>{_('label/lost_password')}</Link>
              </div>


            </form>
          </div>


        </div>

        <div className="signUpLayoutRight">
          <div className="signUpCTA">
            <p>{_('label/signup_call')}</p>
            <Button className="loginBtn" linkTo="/cadastro" text={_('action/signup_now')} />
          </div>
        </div>
      </div>
    );
  }

  handleInputChange = (data: FormElementData) => {
    const { name, value } = data;
    this.setState({ [name]: value, isSubmitted: false });
  };

  handleLogin = () => {
    const { email, password } = this.state;
    const errorMessages = [];

    const _ = createNsTranslator('onboarding', this.props.t);

    if (!email) {
      errorMessages.push(_('label/login/error/email'));
    }

    if (!password) {
      errorMessages.push(_('label/login/error/password'));
    }

    if (email && !isEmail(email)) {
      errorMessages.push(_('label/login/error/invalid_email'));
    }

    if (!errorMessages.length) {
      this.props.onSubmit(email, password);
      this.setState({ errorMessages: [], isSubmitted: true });
    } else {
      this.setState({ errorMessages, isSubmitted: true });
    }
  };
  handleLoginForm = (e: Object) => {
    e.preventDefault();
    this.handleLogin();
  };
}

export default withTranslation()(Login);
