// @flow
import React from 'react';
import { get, isNaN, size, find } from 'lodash';
import {
  PlatformSwitch,
  AppMobileHeader,
  DoctorSelector,
  ConditionalFeature,
  PatientFinancialServices,
  PatientFinancialPayments,
  PatientFinancialReceipts,
  PatientMobileHeader,
  AppMobileFooter,
} from '@blocks';
import { Button, ModalClickableList, Spinner, FormattedText, ProgressBar, Icon } from '@elements';
import { paymentMethods } from '@helpers/constants';
import type { Patient, DoctorsState } from '@types';
import { LoadingPage } from '@views';
import { createNsTranslator, translateConstant } from '@helpers/helpers';
import { withTranslation } from 'react-i18next';
import PatientLayout from '../../../../layouts/PatientLayout';
import './PatientFinancial.scss';

type Item = {
  text: string | number,
  value: any,
};

type State = {
  serviceMenuOpened: boolean,
  paymentMenuOpened: boolean,
  paymentMenuOpenedId: ?number,
  serviceMenuOpenedId: ?number,
  showDoctorSelector: boolean,
  doctorSelectorCallback: Function,
  viewDetailedBalance: boolean,
};

type Props = {
  patient: Patient,
  services: Object,
  payments: Object,
  receipts: Object,
  paymentLoadingIds: Object,
  serviceLoadingIds: Object,
  onNewServicesClick: Function,
  onRequestBalance: Function,
  onNewPaymentClick: Function,
  onNewReceiptClick: Function,
  onShowReceiptClick: Function,
  onOpenEditService: Function,
  onOpenEditPayment: Function,
  onUpdatePayment: Function,
  onUpdateService: Function,
  onPrintReport: Function,
  isPatientPrinting: boolean,
  isServicesLoading: boolean,
  isPaymentsLoading: boolean,
  doctors: DoctorsState,
  showDoctorSelector: boolean,
  isBalanceLoading: boolean,
  isFeatureEnabled: boolean,
  path: string,
  onRefresh: Function,
  onNavigate: Function,
  t: Function,
  onDeletePatientProfileImage: Function,
  onSelectPatientProfileImage: Function,
  singleDoctor: boolean,
};

const serviceMenuItems = (_: Function) => [
  { text: _('label/service_menu/confirmed'), value: 'confirmed' },
  { text: _('label/service_menu/canceled'), value: 'canceled' },
  { text: _('label/service_menu/forecasted'), value: 'forecasted' },
  { text: _('label/service_menu/edit'), value: 'edit' },
];

const paymentMenuItems = (_: Function) => [
  { text: _('label/payment_menu/verified'), value: 'verified' },
  { text: _('label/payment_menu/unverified'), value: 'unverified' },
  { text: _('label/payment_menu/on_debt'), value: 'on_debt' },
  { text: _('label/payment_menu/new_receipt'), value: 'new_receipt' },
  { text: _('label/payment_menu/edit'), value: 'edit' },
];

const getMobileViewStatus = (path) => ({
  isFinancialView: path.length === 0,
  isServicesView: path === 'servicos',
  isPaymentsView: path === 'pagamentos',
  isReceipetsView: path === 'recibos',
});

class PatientFinancial extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      serviceMenuOpened: false,
      paymentMenuOpened: false,
      paymentMenuOpenedId: null,
      serviceMenuOpenedId: null,
      showDoctorSelector: false,
      doctorSelectorCallback: () => {},
      viewDetailedBalance: false,
    };
  }

  componentWillMount() {
    if (window.isCordovaApp) {
      const status = getMobileViewStatus(this.props.path);
      if (status.isFinancialView) {
        this.handleRefreshClick();
      }
    }
  }

  render() {
    const {
      serviceMenuOpened, paymentMenuOpened, doctorSelectorCallback, showDoctorSelector,
      viewDetailedBalance,
    } = this.state;
    const {
      patient,
      services,
      payments,
      receipts,
      paymentLoadingIds,
      serviceLoadingIds,
      isPaymentsLoading,
      isServicesLoading,
      isBalanceLoading,
      isFeatureEnabled,
      doctors,
      path,
      onDeletePatientProfileImage,
      onSelectPatientProfileImage,
      singleDoctor,
      onPrintReport,
      isPatientPrinting,
    } = this.props;

    const _ = createNsTranslator('financial', this.props.t);
    const translatedPaymentMethods = translateConstant(paymentMethods);

    const maxListItens = window.isCordovaApp ? 10 : 5;

    let mobileViewTitle = '';

    const mobileViewStatus = getMobileViewStatus(path);

    const isLoadingAny = get(services, 'isLoading', false) || get(payments, 'isLoading', false) || get(receipts, 'isLoading', false);

    const showProgress = isLoadingAny && (size(get(services, 'data', [])) > 0 || size(get(payments, 'data', [])) > 0 || size(get(receipts, 'data', [])) > 0);

    if (mobileViewStatus.isServicesView) {
      mobileViewTitle = 'Serviços';
    } else if (mobileViewStatus.isPaymentsView) {
      mobileViewTitle = 'Pagamentos';
    } else if (mobileViewStatus.isReceipetsView) {
      mobileViewTitle = 'Recibos';
    }
    // Guard against potential null objects
    if (isFeatureEnabled && (!services || !payments || !receipts || !patient) && !window.isCordovaApp) {
      return <LoadingPage />;
    }

    const financialBalance = get(patient, 'financialBalance', 0);
    const sumOfServices = parseFloat(get(patient, 'sumOfServices', 0));
    const sumOfPayments = parseFloat(get(patient, 'sumOfPayments', 0));


    const ContentServices = () => (
      <PatientFinancialServices
        singleDoctor={singleDoctor}
        maxListItens={maxListItens}
        isServicesLoading={isServicesLoading}
        serviceLoadingIds={serviceLoadingIds}
        services={services}
        toggleServiceItemMenu={this.toggleServiceItemMenu}
      />
    );

    // PAYMENTS
    const ContentPayments = () => (
      <PatientFinancialPayments
        singleDoctor={singleDoctor}
        maxListItens={maxListItens}
        isPaymentsLoading={isPaymentsLoading}
        paymentLoadingIds={paymentLoadingIds}
        paymentMethods={translatedPaymentMethods}
        payments={payments}
        togglePaymentItemMenu={this.togglePaymentItemMenu}
      />
    );

    // RECEIPTS
    const ContentReceipts = () => (
      <PatientFinancialReceipts
        singleDoctor={singleDoctor}
        handleShowReceiptClick={this.handleShowReceiptClick}
        maxListItens={maxListItens}
        receipts={receipts}
      />
    );


    const print = isPatientPrinting
      ? <Icon iconName="spinner" />
      : (
        <Icon
          iconName={window.isCordovaApp ? 'share' : 'print'}
          onClick={onPrintReport}
          size={window.isCordovaApp ? 20 : 24}
        />
      );


    return (
      <PlatformSwitch
        className="platformSwitcher patientFinancial"
        desktop={() => (
          <div id="timelinePage" className="appContent">
            {showProgress && <ProgressBar show={showProgress} />}
            <DoctorSelector
              doctors={doctors}
              isVisible={showDoctorSelector}
              onSelect={doctorSelectorCallback}
              onClose={this.handleDoctorSelectorClose}
            />
            <PatientLayout patient={patient}>
              <section className="mainSection">
                <ConditionalFeature enabled={isFeatureEnabled} transparent>
                  <div className="centerContent">
                    <div className="toolbar">
                      <h1>{_('label/financial')}</h1>

                      <span className="cursorPointer printButton">{print}</span>
                    </div>

                    <div className="desktopCard financialBalance">

                      <div className="trigger" onClick={this.handleToggleDetailedPayment}>
                        <span>{_('label/balance')}</span>
                        {isBalanceLoading && <Spinner />}
                        {!isBalanceLoading && <FormattedText text={financialBalance} type="currency" options={{ allowColor: true }} />}
                      </div>
                      {viewDetailedBalance &&
                        <React.Fragment>
                          <div className="hiddenDetails">
                            <span>{_('label/contracted_services', 'agenda')}</span>
                            {isBalanceLoading && <Spinner />}
                            {!isBalanceLoading && <span className="negativeValue"><FormattedText text={sumOfServices} type="currency" /></span>}
                          </div>
                          <div className="hiddenDetails">
                            <span>{_('payments/title', 'reports')}</span>
                            {isBalanceLoading && <Spinner />}
                            {!isBalanceLoading && <FormattedText text={sumOfPayments} type="currency" options={{ allowColor: true }} />}
                          </div>
                        </React.Fragment>
                      }
                    </div>

                    <div className="desktopCard padding">
                      <section>
                        <div className="toolbar">
                          <h1>{_('label/services')}</h1>
                          <Button className="secondary" text={_('action/new_service')} onClick={this.onNewServicesClick} />
                        </div>
                        <ContentServices />
                      </section>

                      <section>
                        <div className="toolbar">
                          <h1>{_('label/payments')}</h1>
                          <Button className="secondary" text={_('action/new_payment')} onClick={this.onNewPaymentClick} />
                        </div>
                        <ContentPayments />
                      </section>

                      <section>
                        <div className="toolbar">
                          <h1>{_('label/receipts')}</h1>
                          <Button className="secondary" text={_('action/new_receipt')} onClick={this.onNewReceiptClick} />
                        </div>
                        <ContentReceipts />
                      </section>
                    </div>

                    {serviceMenuOpened &&
                    <ModalClickableList
                      items={serviceMenuItems(_)}
                      onListItemClick={this.handleServiceMenuItemClick}
                      onClose={this.toggleServiceItemMenu(null)}
                    />
                  }
                    {paymentMenuOpened &&
                    <ModalClickableList
                      items={paymentMenuItems(_)}
                      onListItemClick={this.handlePaymentMenuItemClick}
                      onClose={this.togglePaymentItemMenu(null)}
                    />
                  }
                  </div>
                </ConditionalFeature>
              </section>
            </PatientLayout>
          </div>
        )}
        mobile={() => (
          <div className="appContent">
            {serviceMenuOpened &&
            <ModalClickableList
              items={serviceMenuItems(_)}
              onListItemClick={this.handleServiceMenuItemClick}
              onClose={this.toggleServiceItemMenu(null)}
            />
            }
            {paymentMenuOpened &&
            <ModalClickableList
              items={paymentMenuItems(_)}
              onListItemClick={this.handlePaymentMenuItemClick}
              onClose={this.togglePaymentItemMenu(null)}
            />
            }
            <DoctorSelector
              doctors={doctors}
              isVisible={showDoctorSelector}
              onSelect={doctorSelectorCallback}
              onClose={this.handleDoctorSelectorClose}
            />
            {mobileViewStatus.isFinancialView &&
              <AppMobileHeader title={get(patient, 'name')} className="showBack centerTitlefix hasProgressBar" backLinkTo="/pacientes/lista">
                <div className="headerActions">&nbsp;</div>
                {showProgress && <div className="mobileProgressbarHolder"><ProgressBar show={showProgress} /></div>}
              </AppMobileHeader>
            }

            {(mobileViewStatus.isServicesView || mobileViewStatus.isPaymentsView || mobileViewStatus.isReceipetsView) &&
              <AppMobileHeader className="showBack hasProgressBar" disableHeader>
                <div className="headerActions">&nbsp;</div>
                <div className="headerTitle leftAlign">{mobileViewTitle}</div>
                {showProgress && <div className="mobileProgressbarHolder"><ProgressBar show={showProgress} /></div>}
                {/* <div className="headerActions" onClick={this.handleRefreshClick}>
                  <Icon iconName="refresh" className="icon" onClick={this.handleRefreshClick} />
                  <Icon iconName="add" className="icon" onClick={this.handleMobileNewClick} />
                </div> */}
              </AppMobileHeader>
            }

            <section className={`mainSection ${mobileViewStatus.isFinancialView ? 'blueGrey' : ''} noPadding`}>
              {(mobileViewStatus.isServicesView || mobileViewStatus.isPaymentsView || mobileViewStatus.isReceipetsView) && (
                <div className="buttonHolder">
                  <Button onClick={this.handleMobileNewClick} className="primary" >
                    {mobileViewStatus.isServicesView && _('action/new_service')}
                    {mobileViewStatus.isPaymentsView && _('action/new_payment')}
                    {mobileViewStatus.isReceipetsView && _('action/new_receipt')}
                  </Button>
                </div>
              )}

              {mobileViewStatus.isServicesView && <ContentServices />}
              {mobileViewStatus.isPaymentsView && <ContentPayments />}
              {mobileViewStatus.isReceipetsView && <ContentReceipts />}

              {mobileViewStatus.isFinancialView &&
                <ConditionalFeature enabled={isFeatureEnabled} transparent>
                  <PatientMobileHeader
                    onDeletePatientProfileImage={onDeletePatientProfileImage}
                    onSelectPatientProfileImage={onSelectPatientProfileImage}
                    patient={patient}
                    active="finacial"
                  />

                  <div className="desktopCard financialBalance">

                    <div className="trigger" onClick={this.handleToggleDetailedPayment}>
                      <span>{_('label/balance')}</span>
                      {isBalanceLoading && <Spinner />}
                      {!isBalanceLoading && <FormattedText text={financialBalance} type="currency" options={{ allowColor: true }} />}
                    </div>
                    {viewDetailedBalance &&
                    <React.Fragment>
                      <div className="hiddenDetails">
                        <span>{_('label/contracted_services', 'agenda')}</span>
                        {isBalanceLoading && <Spinner />}
                        {!isBalanceLoading && <span className="negativeValue"><FormattedText text={sumOfServices} type="currency" /></span>}
                      </div>
                      <div className="hiddenDetails">
                        <span>{_('payments/title', 'reports')}</span>
                        {isBalanceLoading && <Spinner />}
                        {!isBalanceLoading && <FormattedText text={sumOfPayments} type="currency" options={{ allowColor: true }} />}
                      </div>
                    </React.Fragment>
}
                  </div>

                  <div className="buttonsList">
                    <Button text="Serviços" onClick={this.handleMobileToggleouteView(`/paciente/${get(patient, 'id')}/financeiro/servicos`, 'services')} />
                    <Button text="Pagamentos" onClick={this.handleMobileToggleouteView(`/paciente/${get(patient, 'id')}/financeiro/pagamentos`, 'payments')} />
                    <Button text="Recibos" onClick={this.handleMobileToggleouteView(`/paciente/${get(patient, 'id')}/financeiro/recibos`, 'receipts')} />
                  </div>
                </ConditionalFeature>
              }
            </section>
            <AppMobileFooter />
          </div>
        )}
      />
    );
  }

  handleMobileToggleouteView = (route: string, action: string) => () => {
    this.props.onNavigate(route);
    this.props.onRefresh(action);
  };

  togglePaymentItemMenu = (id: ?number) => () => {
    if (!get(this.props.paymentLoadingIds, String(id), false)) {
      this.setState({ paymentMenuOpened: !this.state.paymentMenuOpened, paymentMenuOpenedId: id });
    }
  };

  handleServiceMenuItemClick = (item: Item) => {
    const { onOpenEditService, onUpdateService } = this.props;
    const { serviceMenuOpenedId } = this.state;

    const statusActions = ['confirmed', 'canceled', 'forecasted'];

    if (item.value === 'edit' && onOpenEditService) {
      onOpenEditService(serviceMenuOpenedId);
    } else if (!isNaN(serviceMenuOpenedId) && statusActions.indexOf(item.value) !== -1) {
      onUpdateService(serviceMenuOpenedId, {
        status: item.value,
      });
    }
    this.setState({ serviceMenuOpened: false, serviceMenuOpenedId: null });
  };

  handlePaymentMenuItemClick = (item: Item) => {
    const {
      onOpenEditPayment, onNewReceiptClick, onUpdatePayment, payments,
    } = this.props;
    const { paymentMenuOpenedId } = this.state;

    const statusActions = ['verified', 'unverified', 'on_debt'];

    if (item.value === 'edit' && onOpenEditPayment && !isNaN(paymentMenuOpenedId)) {
      onOpenEditPayment({
        paymentId: paymentMenuOpenedId,
        patientId: get(this.props, ['patient', 'id']),
      });
    } else if (item.value === 'new_receipt' && onNewReceiptClick && !isNaN(paymentMenuOpenedId)) {
      const payment = find(get(payments, 'data', {}), it => String(it.id) === String(paymentMenuOpenedId));
      if (payment) {
        onNewReceiptClick(payment.doctorId, payment.price, payment.date);
      }
    } else if (!isNaN(paymentMenuOpenedId) && statusActions.indexOf(item.value) !== -1) {
      onUpdatePayment(paymentMenuOpenedId, {
        status: item.value,
      });
    }
    this.setState({ paymentMenuOpened: false, paymentMenuOpenedId: null });
  };

  toggleServiceItemMenu = (id: ?number, serviceMenuHealthInsurance: ?boolean = false) => () => {
    if (serviceMenuHealthInsurance) {
      this.setState({ serviceMenuOpenedId: id }, () => this.handleServiceMenuItemClick({ text: 'Editar', value: 'edit' }));
    } else {
      this.setState({ serviceMenuOpened: !this.state.serviceMenuOpened, serviceMenuOpenedId: id });
    }
  };

  handleDoctorSelectorClose = () => this.setState({ showDoctorSelector: false });

  handleRefreshClick = () => {
    const status = getMobileViewStatus(this.props.path);
    if (status.isFinancialView) {
      this.props.onRefresh('default');
    } else if (status.isPaymentsView) {
      this.props.onRefresh('payments');
    } else if (status.isReceipetsView) {
      this.props.onRefresh('receipts');
    } else if (status.isServicesView) {
      this.props.onRefresh('services');
    }
  };

  handleMobileNewClick = () => {
    const status = getMobileViewStatus(this.props.path);
    if (status.isPaymentsView) {
      this.onNewPaymentClick();
    } else if (status.isReceipetsView) {
      this.onNewReceiptClick();
    } else if (status.isServicesView) {
      this.onNewServicesClick();
    }
  };

  handleShowReceiptClick = (id: number) => () => {
    const { onShowReceiptClick } = this.props;
    onShowReceiptClick(id);
  };

  onNewServicesClick = () => {
    const { onNewServicesClick, showDoctorSelector } = this.props;
    if (showDoctorSelector) {
      this.setState({
        showDoctorSelector: true,
        doctorSelectorCallback: (id: number) => onNewServicesClick(id),
      });
    } else {
      onNewServicesClick(null);
    }
  };

  onNewPaymentClick = () => {
    const { onNewPaymentClick, showDoctorSelector } = this.props;
    if (showDoctorSelector) {
      this.setState({
        showDoctorSelector: true,
        doctorSelectorCallback: (id: number) => onNewPaymentClick(id),
      });
    } else {
      onNewPaymentClick(null);
    }
  };

  onNewReceiptClick = () => {
    const { onNewReceiptClick, showDoctorSelector } = this.props;
    if (showDoctorSelector) {
      this.setState({
        showDoctorSelector: true,
        doctorSelectorCallback: (id: number) => onNewReceiptClick(id),
      });
    } else {
      onNewReceiptClick(null);
    }
  };

  handleToggleDetailedPayment = (e: Object) => {
    e.stopPropagation();
    e.preventDefault();
    this.setState({ viewDetailedBalance: true }, this.props.onRequestBalance);
  };
}

export default withTranslation()(PatientFinancial);
