// @flow
import { get, first } from 'lodash';
import moment from 'moment';
import { isPresent } from './validations';
import type { ContextState } from '@types';
/**
 * Check if current user is a secretary from state
 * @param {*} state
 */
export const isSecretary = (state: Object) => get(state, ['context', 'user', 'roleType']) === 'Secretary';


export const getServicesMenuLinkId = (isDoctor: boolean, doctorId?: ?number, doctorIds: number[]) => {
  if (isDoctor && doctorId) {
    return doctorId;
  } else if (!isDoctor && !doctorId) {
    return first(doctorIds);
  }
  return String(doctorId);
};


export const createCachedAction = (
  loadingAction: string,
  loadedAction: string,
  errorAction: string,
  durationHours: number,
  executeRequest: Function,
  ttlPath: string[],
  parseResponse: Function) =>
  (ignoreCache: boolean, payload: any = {}) => ({
    types: [loadingAction, loadedAction, errorAction],
    payload,
    shouldCall: (state: ContextState) => {
      const ttl = get(state, ttlPath, undefined);
      return (ttl && (parseInt(moment.duration(moment().diff(moment(ttl, 'YYYY-MM-DD HH:mm:ss'))).asHours(), 10) > 0)) || ignoreCache || !ttl;
    },
    call: executeRequest,
    parseResponse: (response: any) => ({
      ...parseResponse(response),
      ttl: moment().add(durationHours, 'hours').set('seconds', 0).format('YYYY-MM-DD HH:mm:ss'),
    }),
  });


export const handleReportPeriods = (startDate: string, endDate: string, period: string, month: number, year: number) => {
  const DT_FORMAT = 'YYYY-MM-DD HH:mm:[00]';

  const ADD_YEARS = 100;


  if (period === 'custom' && isPresent(month) && isPresent(year)) {
    return {
      startDate: moment(startDate).startOf('day').format(DT_FORMAT),
      endDate: moment(endDate).endOf('day').format(DT_FORMAT),
    };
  } else if (period === 'future') {
    return {
      startDate: moment().startOf('day').startOf('day').format(DT_FORMAT),
      endDate: moment().add(ADD_YEARS, 'years').endOf('day').format(DT_FORMAT),
    };
  } else if (period === 'today') {
    return {
      startDate: moment().startOf('day').format(DT_FORMAT),
      endDate: moment().endOf('day').format(DT_FORMAT),
    };
  } else if (period === 'yesterday') {
    return {
      startDate: moment().subtract(1, 'day').startOf('day').format(DT_FORMAT),
      endDate: moment().subtract(1, 'day').endOf('day').format(DT_FORMAT),
    };
  } else if (period === 'this_month') {
    return {
      startDate: moment().startOf('month').startOf('day').format(DT_FORMAT),
      endDate: moment().endOf('month').endOf('day').format(DT_FORMAT),
    };
  } else if (period === 'last_month') {
    return {
      startDate: moment().subtract(1, 'month').startOf('month').startOf('day')
        .format(DT_FORMAT),
      endDate: moment().subtract(1, 'month').endOf('month').endOf('day')
        .format(DT_FORMAT),
    };
  } else if (period === 'custom_month') {
    const dt = moment().set('month', month - 1).set('year', year).set('hour', 12);
    return {
      startDate: dt.clone().startOf('month').startOf('day').format(DT_FORMAT),
      endDate: dt.clone().endOf('month').endOf('day').format(DT_FORMAT),
    };
  } else {
    return {
      startDate: moment(startDate).startOf('day').format(DT_FORMAT),
      endDate: moment(endDate).endOf('day').format(DT_FORMAT),
    };
  }
};

export const isReportDownloadByEmail = (stateFn: Function) => get(stateFn(), ['context', 'clinics', 'activeClinic', 'sendReportsViaEmail']);

