// @flow
import React from 'react';
import { SettingsMenu, FormFooter, PlatformSwitch, AppMobileHeader, AppMobileFooter } from '@blocks';
import { Breadcrumb, Input, AddressFields } from '@elements';
import type { User, BundledPermissions } from '@types';
import { withForm } from '@hocs';
import { get } from 'lodash';
import { createValidator } from '@validator';
import { withTranslation } from 'react-i18next';
import { createNsTranslator, handleSubmitEvent } from '@helpers/helpers';
import './GeneralPrintConfig.scss';

type Props = {
  formData: Object,
  touchedFields: Object,
  bindValidation: Function,
  handleFormChange: Function,
  handleTouchField: Function,
  formEdited: Function,
  onLogout: Function,
  user: User,
  isLoading: boolean,
  onSave: Function,
  t: Function,
  permissions: BundledPermissions,
};

type State = {
  errors: Object,
};

const validator = createValidator()
  .field('name')
  .present(_ => _('error/name'));

class GeneralPrintConfig extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      errors: {},
    };
    this.props.bindValidation(this.handleValidation);
  }

  componentDidMount() {
    handleSubmitEvent('.generalPrintConfig', () => {
      if (this.props.formEdited()) {
        this.onSave();
      }
    });
    handleSubmitEvent('#printConfigSubmitHandler', this.onSave);
  }


  render() {
    const {
      user, handleFormChange, formData, handleTouchField, isLoading,
      onLogout,
    } = this.props;

    const _ = createNsTranslator('settings', this.props.t);

    const { errors } = this.state;

    const breadcrumbLinks = [
      { text: _('label/print_config/menu/title'), linkTo: '/ajustes/impressao' },
      { text: _('label/print_config/menu/general') },
    ];

    const pageContent = (
      <React.Fragment>
        <Input
          type="text"
          className="field"
          name="name"
          autofocus
          label={_('label/print_config/general/clinic_name')}
          error={get(errors, 'name', null)}
          value={get(formData, 'name', null)}
          onChange={handleFormChange}
          onBlur={handleTouchField}
          required
        />
        <div className="lineFields">
          <AddressFields
            unform
            onChange={handleFormChange}
            onBlur={handleTouchField}
            stateFieldCanBeEmpty
            addressZipcode={get(formData, 'addressZipcode', '')}
            addressStreet={get(formData, 'addressStreet', '')}
            addressNumber={get(formData, 'addressNumber', '')}
            addressNeighborhood={get(formData, 'addressNeighborhood', '')}
            addressCity={get(formData, 'addressCity', '')}
            addressState={get(formData, 'addressState', '')}
            addressExtra={get(formData, 'addressExtra', '')}
          />
        </div>
        <FormFooter onSave={this.onSave} isLoading={isLoading} />
      </React.Fragment>
    );

    return (
      <PlatformSwitch
        inject
        desktop={() => (
          <div id="generalPrintConfig" className="appContent settingsPage">
            <aside className="sidebar">
              <SettingsMenu user={user} onLogout={onLogout} permissions={this.props.permissions} />
            </aside>
            <section className="mainSection generalPrintConfig" id="printConfigSubmitHandler">
              <div className="centerContent">
                <Breadcrumb items={breadcrumbLinks} />
                <div className="toolbar dashed">
                  <h1>{_('label/print_config/general/title')}</h1>
                </div>
                {pageContent}
              </div>
            </section>
          </div>
        )}
        mobile={() => (
          <React.Fragment>
            <AppMobileHeader title={_('label/print_config/general/title')} backLinkTo="/ajustes/impressao" />
            <div id="generalPrintConfig" className="appContent settingsPage">
              <section className="mainSection">
                <div className="centerContent">
                  {pageContent}
                </div>
              </section>
            </div>
            <AppMobileFooter />
          </React.Fragment>
        )}
      />
    );
  }

  handleValidation = (isSubmit: boolean = false): Promise<boolean> => new Promise((resolve: Function) => {
    const { formData, touchedFields } = this.props;
    const result = validator.validate(formData, touchedFields, isSubmit);
    this.setState({ errors: result.errors }, () => resolve(result.valid));
  });

  onSave = () => this.handleValidation(true).then(valid => {
    if (valid) {
      const { onSave, formData } = this.props;
      onSave(formData);
    }
  });
}

export default withTranslation()(withForm()(GeneralPrintConfig));
