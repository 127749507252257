// @flow
import React from 'react';
import { withRouter } from 'react-router-dom';
import { has, map, get, isFunction, toString } from 'lodash';
import classnames from 'classnames';
import type { UiState } from '@types';
import { connect } from 'react-redux';
import { sort } from '@helpers/helpers';
import ObjectHash from 'object-hash';
import './ClickableList.scss';

type Item = {
  className?: string, // className of each list item. By default is 'listItem'
  customRendererData?: Object, // use data in combination with custom renderer
  preventClick?: boolean,
  text?: string | number, // raw list item text
  type?: string,
  linkTo?: string,
  externalLinkTo?: string,
  hidden?: boolean,
  disabled?: boolean,
};

type Props = {
  className?: string,
  items: Array<Item>,
  activeItemIndex: ?number,
  customRenderer?: Function, // (item) => (node(item)) -> return custom renderer element / node with corresponding data
  customHeadingRenderer?: Function, // (item, key, headingClasses) => (node(item)) -> return custom renderer element / node with corresponding data
  onListItemClick?: Function, // list item click handler
  history: Object, // from withRouter
  onSort?: Function, // Function (a, b, coolection)
  ui: UiState,
  card?: boolean,
  useTabIndex?: boolean,
};

class ClickableList extends React.Component<Props> {
  // shouldComponentUpdate(nextProps: Props) {
  //   return !isEqual(this.props, nextProps);
  // }
  render() {
    const {
      activeItemIndex,
      className, items, onSort,
      customRenderer, history, ui, card, useTabIndex, customHeadingRenderer,
    } = this.props;

    const isMobile = window.isCordovaApp && (get(ui, ['window', 'width'], 0) < 1020);

    const cardClass = card && (isMobile ? 'mobileCard' : 'desktopCard');

    const cssClasses = classnames(['clickableList', className, cardClass]);

    const listItens = isFunction(onSort) ? sort(items, onSort) : items;
    /**
      * Basic list items can have a heading type.
      * If type is not defined then classic "listItem" is rendered
      * If custom renderer is used then is responsible for rendering
      *   and is handled through parent component (see components/views/Agenda.js)
      * Divider and heading list items are not clickable
      * To prevent listItem click add preventClick in item root (e.g. [{ preventClick: true, ...} ])
    */
    const listItems = map<any, any>(listItens, (item, index: number) => {
      let isActiveItem = false;
      if (has(this.props, 'activeItemIndex')) {
        isActiveItem = activeItemIndex === index;
      } else if (item.linkTo) {
        isActiveItem = history.location.pathname === item.linkTo;
      }

      let listItem;



      const key: any = ObjectHash.sha1({
        key: get(item, 'key') || get(item, ['customRendererData', 'key']),
        value: get(item, 'value') || get(item, ['customRendererData', 'value']),
        linkTo: get(item, 'linkTo') || get(item, ['customRendererData', 'linkTo']),
        index: get(item, 'index') || get(item, ['customRendererData', 'index']) || index,
      });
      // item.key || item.value || item.linkTo || index
      switch (item.type) {
        case 'heading':
          const headingClasses = item.className ? `headingSeparator ${item.className}` : 'headingSeparator';

          if (isFunction(customHeadingRenderer)) {
            listItem = customHeadingRenderer(item, `heading-${key}-rendering-content`, headingClasses);
          } else {
            listItem = (
              <li
                key={`heading-${key}`}
                className={headingClasses}
              >
                {item.text}
              </li>
            );
          }


          break;
        default: // classic listItem
          let listItemClasses = classnames(item.className || 'listItem', { disabled: item.disabled });
          if (get(item, ['customRendererData', 'hidden']) || item.hidden) {
            listItemClasses += ' hidden';
          }

          if (get(item, ['customRendererData', 'preventClick']) || get(item, ['preventClick'])) {
            listItemClasses += ' notClickable';
          }
          // key = item.key || index;
          listItem = (
            <li
              key={`item-${key}`}
              className={isActiveItem ? `${listItemClasses} active` : listItemClasses} // use classic listItem styles or custom
              role="presentation"
              onClick={this.handleListItemClick.bind(this, item)}
              {...(useTabIndex ? { tabIndex: index } : {})}
              onKeyDown={this.handleKeyDown(item)}
            >
              {(item.customRendererData && customRenderer) ? customRenderer(item.customRendererData, key) : item.text}
            </li>
          );
          break;
      }
      return listItem;
    });

    return (
      <div className={cssClasses}>
        <ul>
          {listItems}
        </ul>
      </div>
    );
  }

  handleListItemClick = (item: Item) => {
    if (item.disabled) return;
    if (item.externalLinkTo) {
      const a = document.createElement('a');
      a.target = '_blank';
      a.href = toString(item.externalLinkTo);
      window.document.body.appendChild(a);
      a.click();
      window.document.body.removeChild(a);
    } else if (item.linkTo) {
      this.props.history.push(item.linkTo);
    } else if (this.props.onListItemClick && !item.preventClick) {
      this.props.onListItemClick(item.customRendererData || item);
    }
  };


  handleKeyDown = (item: Item) => (e: any) => {
    if (e.keyCode === 32) {
      this.handleListItemClick(item);
    }
  };
}

const mapStateToProps = (state) => ({
  ui: state.ui,
});

// $FlowFixMe
export default connect(mapStateToProps, null)(withRouter(ClickableList));
