import React from 'react';
import { Spinner } from '@elements';
import { get, map, isFunction } from 'lodash';
import type { TableColumItem } from '@types';

const calcClass = (cl: any, item: any) => (isFunction(cl) ? cl(item) : cl);

const mapRenderItemDesktop = (fColumns: any, onItemClick: Function, onGetLoadingItem: Function, itemKey: string) => (item, key) => {
  const renderItemFn = () => (
    <tr className={item.className || ''} onClick={() => (onItemClick ? onItemClick(item) : null)} key={`line-${key}-${get(item, itemKey, '')}`}>
      {map(fColumns, (header: TableColumItem, key2) => (
        <td key={`${header.dataIndex}-${key2}-td-${get(item, itemKey, '')}`} className={calcClass(get(header, 'rowClass', ''), item)}>
          {header.onRenderItem ? header.onRenderItem(item) : get(item, header.dataIndex, '')}
        </td>
      ))}
    </tr>
  );

  const loadFill = [];

  for (let i = 3; i < [...fColumns].length; i++) {
    loadFill.push(<td>&nbsp;</td>);
  }

  if (onGetLoadingItem) {
    if (onGetLoadingItem(item)) {
      return (
        <tr key={`line-loading-${key}-${get(item, itemKey, '')}`}>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>
            <div className="loading-container-list-tr">
              <Spinner />
            </div>
          </td>
          {loadFill}
        </tr>
      );
    }
    return renderItemFn();
  }
  return renderItemFn();
};

export default mapRenderItemDesktop;

