// @flow
import React from 'react';
import { compose } from 'redux';
import { get } from 'lodash';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { PaymentFormModal } from '@views';
import { paymentActions } from '@context/payments';
import { serviceTemplateActions } from '@context/serviceTemplates';
import type { Dispatch, PaymentCreateInput, ServicesState, Patient } from '@types';
import { withConfirmOnClose } from '@hocs';
import { isSecretary } from '@helpers/helpers';

type Props = {
  dispatch: Function,
  patientId: number,
  patient: Patient,
  doctorId: number,
  serviceTemplates: ServicesState,
  setConfirmation: Function,
  closeModal: Function,
  doctorName: string,
  showDoctorName: boolean,
};

class PaymentNewFormModalContainer extends React.Component<Props> {
  componentDidMount() {
    const { dispatch, doctorId } = this.props;
    dispatch(serviceTemplateActions.getServiceTemplates(doctorId));
  }

  render() {
    const {
      serviceTemplates, patient, setConfirmation, doctorName, showDoctorName,
    } = this.props;

    return (
      <PaymentFormModal
        title={showDoctorName ? `Novo Pagamento para ${doctorName}` : 'Novo Pagamento'}
        onSave={this.handleCreatePayment}
        onClose={this.closeModal}
        serviceTemplates={serviceTemplates}
        indebt={get(patient, 'indebt', false)}
        financialBalance={get(patient, 'financialBalance', false)}
        onSetConfirmOnClose={setConfirmation}
      />
    );
  }

  closeModal = this.props.closeModal;

  handleCreatePayment = (data: PaymentCreateInput) => {
    const {
      dispatch, patientId, doctorId, patient,
    } = this.props;

    dispatch(paymentActions.createPayment(patientId, {
      ...data,
      doctorId,
    }, (success) => {
      if (success) {
        if ((data.payment.installments || 0) > 1) {
          dispatch(paymentActions.getPatientPayments(patient.id));
        }
      }
    }));

    this.closeModal(true);
  };
}

const getDoctorId = (state) => (!isSecretary(state) && get(state, ['context', 'user', 'advancedData', 'doctorId'], null) ? get(state, ['context', 'user', 'advancedData', 'doctorId'], null) : get(state, ['context', 'modals', 'data', 'doctorId'], null));

const mapStateToProps = (state, ownProps) => ({
  serviceTemplates: get(state, ['context', 'serviceTemplates']),
  patientId: parseInt(get(ownProps, ['match', 'params', 'id']), 10),
  patient: get(state, ['context', 'patients', 'data', get(ownProps, ['match', 'params', 'id'])]),
  doctorId: getDoctorId(state),
  doctorName: get(state, ['context', 'modals', 'data', 'doctorName'], null),
  showDoctorName: isSecretary(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({ dispatch });

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withConfirmOnClose(),
)(PaymentNewFormModalContainer);
