// @flow
import React from 'react';
import { isFunction, get } from 'lodash';
import { withTranslation } from 'react-i18next';
import * as formatters from './formatters';
import './FormattedText.scss';


type Props = {
  type: string,
  text: ?(string | number),
  options?: Object,
  children?: any,
  i18n: Object,
  t: Function,
};
/**
 * Return an formated string element
 */
class FormattedText extends React.Component<Props> {
  static defaultProps = {
    type: 'default',
  };

  render() {
    const {
      type, text, children, options, i18n, t,
    } = this.props;

    const fnFormatter = get(formatters, String(type), null);

    if (isFunction(fnFormatter)) {
      const formattedText = fnFormatter(text, String(i18n.language).toLowerCase(), { ...(options || {}), _: t });

      if (isFunction(children)) {
        return children(formattedText);
      }

      return formattedText;
    }

    return String(text);
  }
}

export default withTranslation()(FormattedText);
