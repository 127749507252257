// @flow
import React from 'react';
import { connect } from 'react-redux';
import { get } from 'lodash';
import { TagTemplateForm as TagTemplateFormView } from '@views';
import type { User, TagTemplateState, TagTemplateInput, BundledPermissions } from '@types';
import { mapBundledPermission } from '@helpers/connect';
import { createTagTemplate, updateTagTemplate, deleteTagTemplate } from '@context/tagTemplates';
import { router } from '@router';
import { logout } from '@context/user';

type Props = {
  id: number,
  user: User,
  tagTemplates: TagTemplateState,
  dispatch: Function,
  isSaving: boolean,
  permissions: BundledPermissions,
};

class TagTemplateForm extends React.Component<Props> {
  render() {
    const {
      user, id, tagTemplates, isSaving,
    } = this.props;
    return (
      <TagTemplateFormView
        user={user}
        isSaving={isSaving}
        formData={get(tagTemplates, ['data', String(id)])}
        isNew={this.isNew()}
        onSave={this.handleSave}
        onCancel={this.handleCancel}
        onDelete={this.handleDelete}
        onLogout={this.handleLogout}
        permissions={this.props.permissions}
      />
    );
  }

  handleLogout = () => this.props.dispatch(logout());

  isNew() {
    return this.props.id === 'novo';
  }

  handleSave = (data: TagTemplateInput) => {
    const { dispatch, id } = this.props;

    const redirect = (ok: boolean) => (ok ? dispatch(router.pushPath('tagTemplatesList')) : null);

    if (this.isNew()) {
      dispatch(createTagTemplate(data, redirect));
    } else {
      dispatch(updateTagTemplate(id, data, redirect));
    }
  };

  handleDelete = () => {
    const { dispatch, id } = this.props;
    dispatch(deleteTagTemplate(id, (ok: boolean) => (ok ? dispatch(router.pushPath('tagTemplatesList')) : null)));
  };

  handleCancel = () => this.props.dispatch(router.pushPath('tagTemplatesList'));
}

const mapStateToProps = (state, ownProps) => ({
  user: state.context.user,
  tagTemplates: state.context.tagTemplates,
  isSaving: get(state, ['context', 'tagTemplates', 'isSaving'], false),
  id: get(ownProps, ['match', 'params', 'id']),
  permissions: mapBundledPermission(state),
});

export default connect<any, any, any, any, any, any>(
  mapStateToProps,
)(TagTemplateForm);
