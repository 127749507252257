// @flow
// $FlowFixMe
import React, { SyntheticKeyboardEvent } from 'react';
import { isEqual, debounce } from 'lodash';
import classnames from 'classnames';
import { isPresent } from '@helpers/helpers';
import { Icon } from '../';
import './SearchInput.scss';

type Props = {
  className?: string,
  placeholder?: string,
  label?: string,
  value?: string,
  onChange: Function,
  uncontrolled?: boolean;
  debounced?: boolean;
  clear?: boolean;
  autofocus?: boolean,
  mobileAutofocus?: boolean,
  defaultValue?: string;
  inputRef?: Function;
};

type State = {
  clearButton: boolean,
};

export default class SearchInput extends React.Component<Props, State> {
  static defaultProps = {
    autofocus: true,
  };
  constructor(props: Props) {
    super(props);
    this.state = {
      clearButton: isPresent(this.props.value),
    };
  }

  componentDidMount() {
    const autofocus = this.props.autofocus && !window.isCordovaApp ? true : this.props.mobileAutofocus;
    if (this.inputRef && autofocus) {
      this.inputRef.focus();
    }
  }

  shouldComponentUpdate(nextProps: Props, nextState: State) {
    return !isEqual(this.props, nextProps) || !isEqual(this.state, nextState);
  }

  render() {
    const {
      placeholder, className, label,
      value, uncontrolled, debounced, clear, defaultValue,
    } = this.props;

    const cssClasses = classnames([
      'inputHolder',
      'searchInput',
      className,
    ], {
      clear,
    });

    return (
      <div className={cssClasses}>
        {label}
        <Icon iconName="search" size={20} fill="#000" />
        {clear && window.isCordovaApp && this.state.clearButton && <Icon iconName="close" size={15} fill="#000" onClick={this.handleClear} />}
        {clear && !window.isCordovaApp && this.state.clearButton && <div className="clearIcon"><Icon iconName="close" size={15} fill="#000" onClick={this.handleClear} /></div>}
        <input
          ref={(ref: any) => {
            this.inputRef = ref;
            if (this.props.inputRef) {
              if (ref) {
                ref.onReset = () => this.setState({ clearButton: false });
              }
              this.props.inputRef(ref);
            }
          }}
          type="search"
          autoComplete="off"
          placeholder={placeholder}
          onChange={this.onChange}
          defaultValue={defaultValue || ''}
          tabIndex="0"
          {...(uncontrolled || debounced ? {} : { value })}
        />
      </div>
    );
  }

  inputRef: any = null;

  onChange = (event: SyntheticKeyboardEvent) => {
    const { onChange, debounced } = this.props;
    const { value } = event.target;
    this.setState({ clearButton: isPresent(value) }, () => {
      if (debounced) {
        this.debouncedOnChange(value);
      } else {
        onChange(value);
      }
    });
  };

  handleClear = () => {
    const { onChange, debounced } = this.props;
    this.inputRef.value = '';
    this.setState({ clearButton: false });
    if (debounced) {
      this.debouncedOnChange('');
    } else {
      onChange('');
    }
  };

  debouncedOnChange = debounce((value: string) => {
    if (this.props.onChange) {
      this.props.onChange(value);
    }
  }, 500);
}
