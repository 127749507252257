// @flow
import React, { useMemo } from 'react';
import { get, find, size } from 'lodash';
import { Badge, Loading, FormattedText, Table, Tooltip } from '@elements';
import type { PaymentsState } from '@types';
import { sort, dateCompare, useTranslationNs } from '@helpers/helpers';


type Props = {
    payments: PaymentsState,
    isPaymentsLoading: boolean,
    paymentLoadingIds: Object,
    paymentMethods: Array<Object>,
    maxListItens: number,
    togglePaymentItemMenu: Function,

    singleDoctor: boolean,
};

const PatientFinancialPayments = ({
  isPaymentsLoading, payments, paymentLoadingIds, maxListItens,
  togglePaymentItemMenu, paymentMethods, singleDoctor,
}: Props) => {
  const [_] = useTranslationNs('financial');


  const paymentsData = sort(get(payments, ['data'], {}), dateCompare('date', 'desc'));

  const onItemClick = (payment) => togglePaymentItemMenu(payment.id)();

  const columns = useMemo(() => [
    {
      dataIndex: 'date',
      content: _('label/date', 'global'),
      onRenderItem: (payment) => <FormattedText type="date" text={payment.date} />,
    },
    {
      dataIndex: 'value',
      content: _('label/payment_method', 'global'),
      onRenderItem: (payment) => (
        <React.Fragment>
          <div>{get(find(paymentMethods, item => item.value === payment.method), 'text', '-')}</div>
          <div className="paymentNote">{payment.notes}</div>
        </React.Fragment>
      ),
    },
    {
      dataIndex: 'withNotaFiscal',
      content: <React.Fragment>&nbsp;</React.Fragment>,
      desktopOnly: true,
      onRenderItem: (item) => (item.withNotaFiscal ? <Tooltip text="Nota Fiscal" className="tolltipNF">NF</Tooltip> : ''),
    },
    {
      dataIndex: 'doctorName',
      content: _('label/doctor', 'global'),
      hidden: singleDoctor,
    },
    {
      dataIndex: 'status',
      content: _('label/status', 'global'),
      onRenderItem: (payment) => <Badge className={payment.status} />,
    },
    {
      dataIndex: 'price',
      content: _('label/value', 'global'),
      onRenderItem: (payment) => <FormattedText type="currency" text={payment.price} />,
    },
  ], [_, paymentMethods, singleDoctor]);

  if (isPaymentsLoading && size(get(payments, 'data', [])) === 0) {
    return <div className={window.isCordovaApp ? 'mobileLoaderFinacialFix' : ''}><Loading /></div>;
  }

  return (
    <Table
      enablePagination
      itemKey="id"
      limit={maxListItens}
      columns={columns}
      onItemClick={onItemClick}
      items={paymentsData}
      mobileClickLabel="Editar"
      onGetLoadingItem={(payment) => get(paymentLoadingIds, payment.id, false)}
      emptyMessage={_('label/payments_empty/title')}
    />
  );
};

// $FlowFixMe
export default React.memo(PatientFinancialPayments);
