// @flow
import React from 'react';
import {
  keys, get, map,
  concat, includes, without, toLower, filter, size,
} from 'lodash';
import { withRouter } from 'react-router-dom';
import { router } from '@router';
import classnames from 'classnames';
import {
  Button, Icon, Toggle,
  Input, TextArea, Modal,
  Spinner, ClickableList, SearchInput,
} from '@elements';
import type { Document, DocumentsTemplatesState, FormElementData } from '@types';
import { sort, alphabeticCompare, createNsTranslator, copyToClipboard, UserNotifications } from '@helpers/helpers';
import { withTranslation } from 'react-i18next';
import { ViewPrintSettings, PlatformSwitch } from '@blocks';
import './DocumentFormModal.scss';

type Props = {
  recordId: number,
  document: Document,
  documentsTemplates: DocumentsTemplatesState,
  onClose: Function,
  onAddNewDocument: Function,
  onAddNewAndPrintDocument: Function,
  onUpdateDocument: Function,
  onUpdateAndPrintDocument: Function,
  onDeleteDocument: Function,
  history: Object,
  t: Function,
  patientName: string,
  onCopyToast: Function,
  isSaving: boolean,
  isDeleting: boolean
};

type State = {
  name: string,
  content: string,
  showDate: boolean,
  showTitle: boolean,
  errors: Array<string>,
  searchTerm: string;
};

type Item = {
  name: string,
  type: string,
  value: number,
};


const filterSearch = (itens, searchTerm) => {
  const search = toLower(searchTerm).normalize('NFD').replace(/[\u0300-\u036f]/g, '');

  const searchPredicate = item => includes(toLower(get(item, ['customRendererData', 'name'])).normalize('NFD').replace(/[\u0300-\u036f]/g, ''), search);
  return search && String(search).trim().length > 0 ? filter(itens, searchPredicate) : itens;
};

class DocumentFormModal extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    const { document } = this.props;
    this.state = {
      name: document.name || '',
      content: document.content || '',
      showDate: document.showDate === 'true' || document.showDate === true,
      showTitle: document.showTitle === 'true' || document.showTitle === true,
      errors: [],
      searchTerm: '',
    };
  }

  render() {
    const {
      document, documentsTemplates, patientName, isDeleting, isSaving,
    } = this.props;
    const {
      name, content, showDate,
      showTitle, errors,
    } = this.state;

    const _ = createNsTranslator('timeline', this.props.t);

    const modalTitle = _('label/docs');
    const templateIds = keys(documentsTemplates.data);
    let templateItems = map<any, any>(templateIds, (templateId) => {
      const template = get(documentsTemplates, ['data', templateId]);
      return {
        customRendererData: {
          name: template.name,
          value: template.id,
          type: 'documentsTemplate',
        },
      };
    });
    const showSearch = size(templateItems) >= 10 && !document.id;
    templateItems = sort(filterSearch(templateItems, this.state.searchTerm), alphabeticCompare('name'));
    const renderer = (item) => (
      <div>
        <span>{item.name}</span>
        <Icon iconName="arrow-right" className="icon" />
      </div>
    );
    const templatesLoading = get(documentsTemplates, ['isLoading']);
    const noTemplates = !templateItems.length;

    const renderContent = (isMobile: boolean) => () => (
      <Modal id="newDocumentModal" className={classnames('fullScreen', { asView: isMobile, isMobile })} onClose={this.onClose}>
        {isMobile && (
          <div className="modalHeader">
            <Button onClick={this.onClose} disabled={isSaving || isDeleting}>
              <Icon iconName="close" className="icon" />
            </Button>
            <span className="title">{modalTitle}</span>
            { document.id && (
              <Button className="primary" onClick={this.onDeleteDocument} disabled={isSaving || isDeleting}>
                <Icon
                  iconName="delete"
                  className="icon"
                />
              </Button>
            )}
            <Button className="primary" text="Salvar" onClick={this.onSaveDocument.bind(this, 'save')} disabled={isDeleting} isLoading={isSaving} />
          </div>
        )}
        <div className="modalGrid">
          {!isMobile && (
            <aside className="modalSidebar">
              <span className="sidebarTitle">{modalTitle}</span>
              {!document.id &&
              <ul className="sidebarNav">
                <li className="active">{_('action/new_doc')}</li>
              </ul>
            }
              {!document.id &&
              <div className="sidebarSubtitle">
                <span>{_('label/saved_doc_models')}</span>
                <span
                  className="editModels"
                  onClick={this.onEditTemplatesClick}
                >
                  Editar
                </span>
              </div>
            }
              {showSearch && <SearchInput onChange={this.handleSearch}/>}
              {!document.id && templatesLoading && noTemplates && (
              <div className='loadingWrapper'>
                <Spinner text={_('label/loading', 'global')} />
              </div>
              )
            }
              {!document.id && !noTemplates && (
              <ClickableList
                items={templateItems}
                customRenderer={renderer}
                className="sidebarNav savedModels"
                onListItemClick={this.onTemplateClick}
              />
              )
            }
            </aside>
          )}

          <div className="modalContainer">
            <div className="modalContent withSidebar">
              <form className="form">
                <Input
                  name="name"
                  autofocus
                  placeholder={_('label/doc_title_opt')}
                  onChange={this.handleFormChange}
                  value={name}
                  onBlur={this.handleFormBlur}
                  disabled={isDeleting || isSaving}
                />
                <TextArea
                  autoGrow={false}
                  name="content"
                  className="textAreaHolder"
                  placeholder={_('label/doc_content_placeholder')}
                  onChange={this.handleFormChange}
                  value={content}
                  onBlur={this.handleFormBlur}
                  error={includes(errors, 'content') ? _('error/empty') : ''}
                  disabled={isDeleting || isSaving}
                />
              </form>

              <div className="modalSidebar modalRightSidebar">
                <span className="sidebarTitle">{_('label/patient')}: <span className="patientName" onClick={this.onPatientNameClick}>{patientName}</span></span>
                <span className="sidebarTitle">{_('label/print_configs')}:</span>
                <ul className="sidebarSetup">
                  <li className="toggleHolder">
                    <Toggle
                      name="showTitle"
                      label={_('label/doc_title')}
                      isChecked={showTitle === 'true' || showTitle === true}
                      onChange={this.handleFormChange}
                      disabled={isDeleting || isSaving}
                    />
                  </li>
                  <li className="toggleHolder">
                    <Toggle
                      name="showDate"
                      label={_('label/today_date')}
                      isChecked={showDate === 'true' || showDate === true}
                      onChange={this.handleFormChange}
                      disabled={isDeleting || isSaving}
                    />
                  </li>
                  <li><ViewPrintSettings/></li>
                </ul>
              </div>
            </div>
            {!isMobile && (
              <div className="modalFooter">
                <div>
                  {document.id && (
                  <Button onClick={this.onDeleteDocument} disabled={isDeleting || isSaving}>
                    <Icon
                      iconName="delete"
                      className="icon"
                    />
                    {isDeleting && <Spinner />}
                  </Button>
                  )
                }
                  <Button
                    text={_('action/cancel', 'global')}
                    onClick={this.onClose}
                    disabled={isDeleting || isSaving}
                  />
                </div>
                <div>
                  <Button
                    text={_('action/save_print', 'global')}
                    onClick={this.onSaveDocument.bind(this, 'save and print')}
                    disabled={isDeleting || isSaving}
                  />
                  <Button
                    text={_('action/save', 'global')}
                    className="primary"
                    disabled={isDeleting}
                    isLoading={isSaving}
                    onClick={this.onSaveDocument.bind(this, 'save')}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </Modal>
    );

    return (
      <PlatformSwitch
        desktop={renderContent(false)}
        mobile={renderContent(true)}
      />
    );
  }

  checkForErrors = (value: (string | number | boolean), fieldName: string) => {
    const { errors } = this.state;

    if (value === '') {
      this.setState({ errors: concat(errors, [fieldName]) });
    } else if (includes(errors, fieldName)) {
      this.setState({ errors: without(errors, fieldName) });
    }
  };

  checkForUnsavedChanges = () => {
    const { document } = this.props;
    const {
      name, content, showDate,
      showTitle,
    } = this.state;
    let unsavedChanges;

    if (document.id) { // on edit
      if (name !== document.name || content !== document.content ||
        showDate !== document.showDate || showTitle !== document.showTitle) {
        unsavedChanges = true;
      } else {
        unsavedChanges = false;
      }
    } else if (name !== '' || content !== '' ||
    showDate !== false || showTitle !== false) { // on create check against default state
      unsavedChanges = true;
    } else {
      unsavedChanges = false;
    }

    return unsavedChanges;
  };

  handleFormChange = (data: FormElementData) => {
    const { name, value } = data;
    this.setState({ [name]: value }, () => this.checkForErrors(value, name));
  };

  handleFormBlur = (data: FormElementData) => {
    const { name, value } = data;
    this.checkForErrors(value, name);
  };

  onEditTemplatesClick = () => {
    const { history, onClose } = this.props;
    history.push(router.getRoutePath('documentsTemplatesList'));
    onClose();
  };

  onTemplateClick = (item: Item) => {
    const templateId = item.value.toString();
    const template = get(this.props.documentsTemplates, ['data', templateId]);
    this.setState({
      name: template.name || '',
      content: template.content || '',
      showDate: template.showDate || false,
      showTitle: template.showTitle || false,
    }, () => this.checkForErrors(template.content || '', 'content'));
  };

  onClose = () => {
    const { onClose } = this.props;
    const unsavedChanges = this.checkForUnsavedChanges();
    if (unsavedChanges) {
      UserNotifications.confirmI18n('alert/confirm_quit_form', null, '#FFA433', true)
        .then((value) => {
          if (value) {
            onClose();
          }
        });
    } else {
      onClose();
    }
  }

  onSaveDocument = (option: string) => {
    const {
      recordId,
      document,
      onAddNewDocument,
      onUpdateDocument,
      onAddNewAndPrintDocument,
      onUpdateAndPrintDocument,

    } = this.props;
    const {
      name, content, showDate,
      showTitle,
    } = this.state;

    const data = {
      name,
      content,
      showDate,
      showTitle,
    };
    const errors = [];

    if (content === '') {
      if (!includes(this.state.errors, 'content')) {
        this.setState({ errors: concat(errors, ['content']) });
      }
      errors.push('content');
    }
    if (!errors.length) {
      if (document.id) { // if we are editing an existing document
        switch (option) {
          case 'save and print':
            onUpdateAndPrintDocument(recordId, document.id, data);
            break;
          case 'save':
            onUpdateDocument(recordId, document.id, data);
            break;
          default:
            break;
        }
      } else { // if we are creating a document
        switch (option) {
          case 'save and print':
            onAddNewAndPrintDocument(recordId, data);
            break;
          case 'save':
            onAddNewDocument(recordId, data);
            break;
          default:
            break;
        }
      }
    }
  };

  onDeleteDocument = () => {
    const {
      recordId, document, onDeleteDocument,
    } = this.props;

    UserNotifications.confirmI18n('alert/delete/document', 'warning', '#FFA433', true)
      .then((value) => {
        if (value) {
          onDeleteDocument(recordId, document.id);
        }
      });
  };

  handleSearch = (text) => this.setState({ searchTerm: text });

  onPatientNameClick = () => {
    const { patientName, onCopyToast } = this.props;
    copyToClipboard(patientName);
    onCopyToast(this.props.t('timeline:msg/patient_name_copied'));
  };
}

export default withTranslation()(withRouter(DocumentFormModal));
