// @flow
import { get, omit } from 'lodash';
import moment from 'moment';
import { reportError, createCachedAction } from '@helpers/helpers';
import { Api } from '../../../helpers';
import { router } from '../../../routes/router';
import DrugsPrescriptionsTemplateService from '../../../services/DrugsPrescriptionsTemplateService';
import type
{
  Action,
  Dispatch,
  ContextState,
  DrugsPrescriptionsTemplateInput,
} from '../../../types';

// ------------------------------------
// Constants
// ------------------------------------
const DRUGS_PRESCRIPTIONS_TEMPLATES_LOADING = 'drugsPrescriptionsTemplates.DRUGS_PRESCRIPTIONS_TEMPLATES_LOADING';
const DRUGS_PRESCRIPTIONS_TEMPLATES_LOADED = 'drugsPrescriptionsTemplates.DRUGS_PRESCRIPTIONS_TEMPLATES_LOADED';
const DRUGS_PRESCRIPTIONS_TEMPLATES_LOADING_FAILED = 'drugsPrescriptionsTemplates.DRUGS_PRESCRIPTIONS_TEMPLATES_LOADING_FAILED';

const DRUGS_PRESCRIPTIONS_TEMPLATE_CREATE_IN_PROGRESS = 'drugsPrescriptionsTemplates.DRUGS_PRESCRIPTIONS_TEMPLATE_CREATE_IN_PROGRESS';
const DRUGS_PRESCRIPTIONS_TEMPLATE_CREATE_SUCCESS = 'drugsPrescriptionsTemplates.DRUGS_PRESCRIPTIONS_TEMPLATE_CREATE_SUCCESS';
const DRUGS_PRESCRIPTIONS_TEMPLATE_CREATE_FAILED = 'drugsPrescriptionsTemplates.DRUGS_PRESCRIPTIONS_TEMPLATE_CREATE_FAILED';

const DRUGS_PRESCRIPTIONS_TEMPLATE_UPDATE_IN_PROGRESS = 'drugsPrescriptionsTemplates.DRUGS_PRESCRIPTIONS_TEMPLATE_UPDATE_IN_PROGRESS';
const DRUGS_PRESCRIPTIONS_TEMPLATE_UPDATE_SUCCESS = 'drugsPrescriptionsTemplates.DRUGS_PRESCRIPTIONS_TEMPLATE_UPDATE_SUCCESS';
const DRUGS_PRESCRIPTIONS_TEMPLATE_UPDATE_FAILED = 'drugsPrescriptionsTemplates.DRUGS_PRESCRIPTIONS_TEMPLATE_UPDATE_FAILED';

const DRUGS_PRESCRIPTIONS_TEMPLATE_DELETE_IN_PROGRESS = 'drugsPrescriptionsTemplates.DRUGS_PRESCRIPTIONS_TEMPLATE_DELETE_IN_PROGRESS';
const DRUGS_PRESCRIPTIONS_TEMPLATE_DELETE_SUCCESS = 'drugsPrescriptionsTemplates.DRUGS_PRESCRIPTIONS_TEMPLATE_DELETE_SUCCESS';
const DRUGS_PRESCRIPTIONS_TEMPLATE_DELETE_FAILED = 'drugsPrescriptionsTemplates.DRUGS_PRESCRIPTIONS_TEMPLATE_DELETE_FAILED';

// ------------------------------------
// Actions
// ------------------------------------
// export const getDrugsPrescriptionsTemplates = () => (
//   (dispatch: Dispatch) => {
//     dispatch({ type: DRUGS_PRESCRIPTIONS_TEMPLATES_LOADING });
//     Api.get(router.getApiRoute('getDrugsPrescriptionsTemplates', null), {
//       success: (response) => {
//         dispatch({ type: DRUGS_PRESCRIPTIONS_TEMPLATES_LOADED, drugsPrescriptionsTemplates: response.drugsPrescriptionsTemplates });
//       },
//       error: (error) => {
//         dispatch({ type: DRUGS_PRESCRIPTIONS_TEMPLATES_LOADING_FAILED, error: { message: error.message } });
//         dispatch(reportError(error));
//       },
//     });
//   }
// );

export const getDrugsPrescriptionsTemplates = (force?: boolean = false) => (dispatch: Dispatch) => {
  dispatch(createCachedAction(
    DRUGS_PRESCRIPTIONS_TEMPLATES_LOADING, DRUGS_PRESCRIPTIONS_TEMPLATES_LOADED, DRUGS_PRESCRIPTIONS_TEMPLATES_LOADING_FAILED,
    12,
    () => Api.get(router.getApiRoute('getDrugsPrescriptionsTemplates', null)),
    ['context', 'drugsPrescriptionsTemplates', 'ttl'],
    (response: any) => ({
      drugsPrescriptionsTemplates: response.drugsPrescriptionsTemplates,
    }),
  )(force, {}));
};

export const createDrugsPrescriptionsTemplate = (drugsPrescriptionsTemplate: DrugsPrescriptionsTemplateInput, callback?: Function) => (
  (dispatch: Dispatch) => {
    dispatch({ type: DRUGS_PRESCRIPTIONS_TEMPLATE_CREATE_IN_PROGRESS });
    Api.post(router.getApiRoute('createDrugsPrescriptionsTemplate', null), {
      data: { drugsPrescriptionsTemplate },
      success: (response) => {
        dispatch({ type: DRUGS_PRESCRIPTIONS_TEMPLATE_CREATE_SUCCESS, drugsPrescriptionsTemplate: response.data.drugsPrescriptionsTemplate });
        if (callback) {
          callback(true);
        }
      },
      error: (error) => {
        dispatch({ type: DRUGS_PRESCRIPTIONS_TEMPLATE_CREATE_FAILED, error: { message: error.message } });
        dispatch(reportError(error));
        if (callback) {
          callback(false);
        }
      },
    });
  }
);

export const updateDrugsPrescriptionsTemplate = (id: number, drugsPrescriptionsTemplate: DrugsPrescriptionsTemplateInput, callback?: Function) => (
  (dispatch: Dispatch) => {
    dispatch({ type: DRUGS_PRESCRIPTIONS_TEMPLATE_UPDATE_IN_PROGRESS });
    Api.patch(router.getApiRoute('updateDrugsPrescriptionsTemplate', { id }), {
      data: { drugsPrescriptionsTemplate },
      success: (response) => {
        dispatch({ type: DRUGS_PRESCRIPTIONS_TEMPLATE_UPDATE_SUCCESS, drugsPrescriptionsTemplate: response.drugsPrescriptionsTemplate });
        if (callback) {
          callback(true);
        }
      },
      error: (error) => {
        dispatch({ type: DRUGS_PRESCRIPTIONS_TEMPLATE_UPDATE_FAILED, error: { message: error.message } });
        dispatch(reportError(error));
        if (callback) {
          callback(false);
        }
      },
    });
  }
);

export const deleteDrugsPrescriptionsTemplate = (id: number, callback?: Function) => (
  (dispatch: Dispatch) => {
    dispatch({ type: DRUGS_PRESCRIPTIONS_TEMPLATE_DELETE_IN_PROGRESS });
    Api.destroy(router.getApiRoute('deleteDrugsPrescriptionsTemplate', { id }), {
      success: () => {
        dispatch({ type: DRUGS_PRESCRIPTIONS_TEMPLATE_DELETE_SUCCESS, deletedTemplateId: id });
        if (callback) {
          callback(true);
        }
      },
      error: (error) => {
        dispatch({ type: DRUGS_PRESCRIPTIONS_TEMPLATE_DELETE_FAILED, error: { message: error.message } });
        dispatch(reportError(error));
        if (callback) {
          callback(false);
        }
      },
    });
  }
);

export const drugsPrescriptionsTemplateActions = {
  getDrugsPrescriptionsTemplates,
  createDrugsPrescriptionsTemplate,
  updateDrugsPrescriptionsTemplate,
  deleteDrugsPrescriptionsTemplate,
};

// ------------------------------------
// Reducer
// ------------------------------------
export default function drugsPrescriptionsTemplatesReducer (state: ContextState, action: Action): ContextState {
  switch (action.type) {
    case DRUGS_PRESCRIPTIONS_TEMPLATES_LOADING:
      return state
        .setIn(['drugsPrescriptionsTemplates', 'isLoading'], true)
        .setIn(['drugsPrescriptionsTemplates', 'errors'], null);

    case DRUGS_PRESCRIPTIONS_TEMPLATES_LOADED:
      const normalized = DrugsPrescriptionsTemplateService.normalizeDrugsPrescriptionsTemplates(action.drugsPrescriptionsTemplates);

      return state
        .setIn(['drugsPrescriptionsTemplates', 'isLoading'], false)
        .setIn(['drugsPrescriptionsTemplates', 'errors'], null)
        .setIn(['drugsPrescriptionsTemplates', 'ttl'], get(action, 'ttl', null))
        .setIn(['drugsPrescriptionsTemplates', 'data'], normalized.drugsPrescriptionsTemplates)
        .setIn(['drugsPrescriptionsTemplates', 'lastFetch'], moment().toISOString());

    case DRUGS_PRESCRIPTIONS_TEMPLATES_LOADING_FAILED:
      return state
        .setIn(['drugsPrescriptionsTemplates', 'isLoading'], false)
        .setIn(['drugsPrescriptionsTemplates', 'errors'], [action.error.message]);

    case DRUGS_PRESCRIPTIONS_TEMPLATE_CREATE_IN_PROGRESS:
      return state
        .setIn(['drugsPrescriptionsTemplates', 'isSaving'], true)
        .setIn(['drugsPrescriptionsTemplates', 'errors'], null);

    case DRUGS_PRESCRIPTIONS_TEMPLATE_CREATE_SUCCESS:
      return state
        .setIn(['drugsPrescriptionsTemplates', 'isSaving'], false)
        .setIn(['drugsPrescriptionsTemplates', 'errors'], null)
        .setIn(['drugsPrescriptionsTemplates', 'data', action.drugsPrescriptionsTemplate.id], action.drugsPrescriptionsTemplate);

    case DRUGS_PRESCRIPTIONS_TEMPLATE_CREATE_FAILED:
      return state
        .setIn(['drugsPrescriptionsTemplates', 'isSaving'], false)
        .setIn(['drugsPrescriptionsTemplates', 'errors'], [action.error.message]);

    case DRUGS_PRESCRIPTIONS_TEMPLATE_UPDATE_IN_PROGRESS:
      return state
        .setIn(['drugsPrescriptionsTemplates', 'isSaving'], true)
        .setIn(['drugsPrescriptionsTemplates', 'errors'], null);

    case DRUGS_PRESCRIPTIONS_TEMPLATE_UPDATE_SUCCESS:
      return state
        .setIn(['drugsPrescriptionsTemplates', 'isSaving'], false)
        .setIn(['drugsPrescriptionsTemplates', 'errors'], null)
        .setIn(['drugsPrescriptionsTemplates', 'data', action.drugsPrescriptionsTemplate.id], action.drugsPrescriptionsTemplate);

    case DRUGS_PRESCRIPTIONS_TEMPLATE_UPDATE_FAILED:
      return state
        .setIn(['drugsPrescriptionsTemplates', 'isSaving'], false)
        .setIn(['drugsPrescriptionsTemplates', 'errors'], [action.error.message]);

    case DRUGS_PRESCRIPTIONS_TEMPLATE_DELETE_IN_PROGRESS:
      return state
        .setIn(['drugsPrescriptionsTemplates', 'isSaving'], true)
        .setIn(['drugsPrescriptionsTemplates', 'errors'], null);

    case DRUGS_PRESCRIPTIONS_TEMPLATE_DELETE_SUCCESS:
      const deletedId = action.deletedTemplateId;
      const data = get(state, ['drugsPrescriptionsTemplates', 'data'], {});
      return state
        .setIn(['drugsPrescriptionsTemplates', 'isSaving'], false)
        .setIn(['drugsPrescriptionsTemplates', 'errors'], null)
        .setIn(['drugsPrescriptionsTemplates', 'data'], omit(data, deletedId.toString()));

    case DRUGS_PRESCRIPTIONS_TEMPLATE_DELETE_FAILED:
      return state
        .setIn(['drugsPrescriptionsTemplates', 'isSaving'], false)
        .setIn(['drugsPrescriptionsTemplates', 'errors'], [action.error.message]);

    default:
      return state;
  }
}
