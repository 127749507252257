import { get, toString } from 'lodash';

const localStorageNameKey = () => `doutore-persist-data-v2:${window.hashUid()}`;

export default class WrappedLocalStorage {
  path: string;
  constructor(path: string) {
    this.path = path;
  }

  _fetch = () => {
    const jsonData = localStorage.getItem(localStorageNameKey());
    let storage;
    try {
      storage = JSON.parse(jsonData);
      storage[this.path] = get(storage, this.path, {});
    } catch (e) {
      storage = {};
      localStorage.setItem(localStorageNameKey(), JSON.stringify(storage));
    }
    return storage;
  }

  _save = (storage: Object) => {
    localStorage.setItem(localStorageNameKey(), JSON.stringify(storage));
  }

  getItem = (key: string | number, fallBack: any = null) => {
    const storage = this._fetch();
    return get(storage, [this.path, toString(key)], fallBack);
  }

  setItem = (key: string | number, value: any) => {
    const storage = this._fetch();
    storage[this.path][toString(key)] = value;
    this._save(storage);
  }
}
