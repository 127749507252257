// @flow
import React from 'react';
import { get, map, toString } from 'lodash';
import { connect } from 'react-redux';
import { DoctorsList as DoctorsListView } from '@views';
import type { User, BundledPermissions } from '@types';
import { clinicActions } from '@context/clinics';
import { logout } from '@context/user';
import { mapBundledPermission } from '@helpers/connect';

type Props = {
  dispatch: Function,
  user: User,
  clinicDoctors: Array<number>,
  doctorsData: Object,
  permissions: BundledPermissions,
};

class DoctorsList extends React.Component<Props> {
  componentDidMount() {
    this.props.dispatch(clinicActions.getClinics());
  }

  render() {
    const {
      user, clinicDoctors, doctorsData,
    } = this.props;
    const doctors = map<any, any>(clinicDoctors, (id:number) => get(doctorsData, toString(id)));
    return (
      <DoctorsListView
        user={user}
        doctors={doctors}
        onLogout={this.handleLogout}
        permissions={this.props.permissions}
      />
    );
  }
  handleLogout = () => this.props.dispatch(logout());
}

const mapStateToProps = (state) => ({
  user: state.context.user,
  context: state.context,
  clinicDoctors: get(state, ['context', 'clinics', 'activeClinic', 'doctorIds'], []),
  doctorsData: get(state, ['context', 'doctors', 'data'], {}),
  permissions: mapBundledPermission(state),
});

export default connect<any, any, any, any, any, any>(
  mapStateToProps,
)(DoctorsList);
