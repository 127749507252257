// @flow
import React from 'react';
import { get } from 'lodash';
import { connect } from 'react-redux';
import { ReportsSms as ReportsSmsView } from '@views';
import { getReportsSms } from '@context/reportsSms';
import type { ReportsSmsState, Dispatch, PermissionTo } from '@types';
import { mapPermissionTo } from '@helpers/connect';

type Props = {
  dispatch: Function,
  reportState: ReportsSmsState,
  isFeatureEnabled: boolean,
  permissionTo : PermissionTo,
};

class ReportsSms extends React.Component<Props> {
  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(getReportsSms());
  }

  render() {
    const { reportState, isFeatureEnabled, permissionTo } = this.props;
    return (
      <ReportsSmsView
        data={get(reportState, 'data', {})}
        isLoading={get(reportState, 'isLoading', false)}
        isFeatureEnabled={isFeatureEnabled}
        isReportBlocked={!permissionTo.smsReports}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  reportState: get(state, ['context', 'reportsSms'], {}),
  isFeatureEnabled: get(state, ['context', 'reportsSms', 'data', 'smsActivated'], {}),
  permissionTo: mapPermissionTo(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({ dispatch });

export default connect<any, any, any, any, any, any>(mapStateToProps, mapDispatchToProps)(ReportsSms);
