// @flow
import {
  get, omit, concat,
  without, endsWith, toString,
} from 'lodash';
import { fileExtensionIsImage, reportError, isPresent, isShareable } from '@helpers/helpers';
import { lookup } from 'mime-to-extensions';
import { Api } from '../../../helpers';
import { router } from '../../../routes/router';
import type {
  Action,
  Dispatch,
  ContextState,
  AttachmentInput,
} from '../../../types';

// Import external types
import { recordTypes } from './records';
import { OPEN_TOAST } from './toasts';
import { socialShareFile } from '../../middleware/showPDF';


// ------------------------------------
// Constants
// ------------------------------------

const ATTACHMENT_SIGNED_DATA_LOADING = 'attachments.ATTACHMENT_SIGNED_DATA_LOADING';
const ATTACHMENT_SIGNED_DATA_LOADED = 'attachments.ATTACHMENT_SIGNED_DATA_LOADED';
const ATTACHMENT_SIGNED_DATA_LOADING_FAILED = 'attachments.ATTACHMENT_SIGNED_DATA_LOADING_FAILED';

const CREATE_RECORD_ATTACHMENT = 'attachments.CREATE_RECORD_ATTACHMENT';
const CREATE_RECORD_ATTACHMENT_SUCCESS = 'attachments.CREATE_RECORD_ATTACHMENT_SUCCESS';
const CREATE_RECORD_ATTACHMENT_FAILED = 'attachments.CREATE_RECORD_ATTACHMENT_FAILED';

const GET_RECORD_ATTACHMENT = 'attachments.GET_RECORD_ATTACHMENT';
const GET_RECORD_ATTACHMENT_SUCCESS = 'attachments.GET_RECORD_ATTACHMENT_SUCCESS';
const GET_RECORD_ATTACHMENT_FAILED = 'attachments.GET_RECORD_ATTACHMENT_FAILED';

const DELETE_RECORD_ATTACHMENT = 'attachments.DELETE_RECORD_ATTACHMENT';
const DELETE_RECORD_ATTACHMENT_SUCCESS = 'attachments.DELETE_RECORD_ATTACHMENT_SUCCESS';
const DELETE_RECORD_ATTACHMENT_FAILED = 'attachments.DELETE_RECORD_ATTACHMENT_FAILED';


const ATTACHMENT_DOWNLOAD_LOADING = 'attachments.ATTACHMENT_DOWNLOAD_LOADING';
const ATTACHMENT_DOWNLOAD_LOADED = 'attachments.ATTACHMENT_DOWNLOAD_LOADED';
const ATTACHMENT_DOWNLOAD_LOADING_FAILED = 'attachments.ATTACHMENT_DOWNLOAD_LOADING_FAILED';



export const donwloadRecordAttachment = (recordId: number, attachmentId: number, callBack?: Function, sharedDisabled?: boolean = false) => (
  (dispatch: Dispatch, getState: Function) => {
    dispatch({ type: ATTACHMENT_DOWNLOAD_LOADING });
    Api.get(router.getApiRoute('downloadAttachment', { recordId, id: attachmentId }), {
      success: (response) => {
        const mime = lookup(get(getState(), ['context', 'attachments', 'data', String(attachmentId), 'name']));
        const fileName = get(getState(), ['context', 'attachments', 'data', String(attachmentId), 'name']);
        const pluginEnabled = window.isCordovaApp && ((!!window.plugins && !!window.plugins.socialsharing) || (!!window.plugins && !!window.plugins.PrintPDF));

        if ((isShareable(fileName) && !sharedDisabled) && pluginEnabled) {
          const downloadUrl = get(response, 'downloadUrl', '');

          dispatch({
            type: OPEN_TOAST,
            data: {
              type: 'success', text: 'Compartilhando...', fileURL: undefined, close: true, timeout: 10000,
            },
          });

          fetch(downloadUrl, {
            method: 'GET',
          }).then(r => r.blob()).then(b => {
            b.arrayBuffer().then(buff => {
              const blob = new Blob([buff], { type: mime });
              dispatch({
                type: ATTACHMENT_DOWNLOAD_LOADED,
                recordId,
                attachmentId,
                directUrl: get(response, 'downloadUrl', ''),
              });

              socialShareFile(blob, isPresent(fileName) ? fileName : `arquivo-${attachmentId}`, dispatch);
              if (callBack) {
                callBack(get(response, 'downloadUrl', ''));
              }
            }).catch((error) => {
              // eslint-disable-next-line
              console.error(error);
              dispatch({ type: ATTACHMENT_DOWNLOAD_LOADING_FAILED, error: { message: error.message } });
              dispatch(reportError(error));
              if (callBack) {
                callBack(null);
              }
            });
          })
            .catch((error) => {
              // eslint-disable-next-line
              console.error(error);
              dispatch({ type: ATTACHMENT_DOWNLOAD_LOADING_FAILED, error: { message: error.message } });
              dispatch(reportError(error));
              if (callBack) {
                callBack(null);
              }
            });
        } else {
          dispatch({
            type: ATTACHMENT_DOWNLOAD_LOADED,
            recordId,
            attachmentId,
            directUrl: get(response, 'downloadUrl', ''),
          });
          if (callBack) {
            callBack(get(response, 'downloadUrl', ''));
          }
        }
      },
      error: (error) => {
        dispatch({ type: ATTACHMENT_DOWNLOAD_LOADING_FAILED, error: { message: error.message } });
        dispatch(reportError(error));
        if (callBack) {
          callBack(null);
        }
      },
    });
  }
);

// ------------------------------------
// Actions
// ------------------------------------
export const getSignedData = () => (
  (dispatch: Dispatch) => {
    dispatch({ type: ATTACHMENT_SIGNED_DATA_LOADING });
    Api.get(router.getApiRoute('getSignedData', null), {
      success: (response) => {
        dispatch({ type: ATTACHMENT_SIGNED_DATA_LOADED, signedData: response.signedData });
      },
      error: (error) => {
        dispatch({ type: ATTACHMENT_SIGNED_DATA_LOADING_FAILED, error: { message: error.message } });
        dispatch(reportError(error));
      },
    });
  }
);

export const getRecordAttachment = (recordId: number, attachmentId: number) => (
  (dispatch: Dispatch) => {
    dispatch({ type: GET_RECORD_ATTACHMENT });
    Api.get(router.getApiRoute('getRecordAttachment', { recordId, id: attachmentId }), {
      success: (response) => {
        dispatch({ type: GET_RECORD_ATTACHMENT_SUCCESS, attachment: response.attachment });
      },
      error: (error) => {
        dispatch({ type: GET_RECORD_ATTACHMENT_FAILED, error: { message: error.message } });
        dispatch(reportError(error));
      },
    });
  }
);

export const createRecordAttachment = (recordId: number, attachment: AttachmentInput) => (
  (dispatch: Dispatch) => {
    const attachmentScope = fileExtensionIsImage(attachment.name) ? 'imageAttachments' : 'fileAttachments';

    dispatch({ type: CREATE_RECORD_ATTACHMENT });
    Api.post(router.getApiRoute('createRecordAttachment', { recordId }), {
      data: { attachment },
      success: (response) => {
        dispatch({
          recordId,
          type: CREATE_RECORD_ATTACHMENT_SUCCESS,
          attachment: response.data.attachment,
        });

        // Dispatch records action to delete temp files and set real data from createAttachments api response
        dispatch({
          type: recordTypes.SET_RECORD_ATTACHMENT_SUCCESS,
          data: response.data.attachment,
          tempId: attachment.tempId,
          recordId,
          attachmentScope,
        });
      },
      error: (error) => {
        dispatch({
          type: CREATE_RECORD_ATTACHMENT_FAILED,
          error: { message: error.message },
        });

        dispatch({
          type: recordTypes.SET_RECORD_ATTACHMENT_ERROR,
          recordId,
          tempId: attachment.tempId,
          attachmentScope,
          errorMessage: error.message,
        });
        dispatch(reportError(error));
      },
    });
  }
);

export const deleteRecordAttachment = (recordId: number, attachmentId: number) => (
  (dispatch: Dispatch) => {
    dispatch({ type: DELETE_RECORD_ATTACHMENT });
    Api.destroy(router.getApiRoute('deleteRecordAttachment', { recordId, id: attachmentId }), {
      success: () => {
        dispatch({ type: DELETE_RECORD_ATTACHMENT_SUCCESS, recordId, deletedAttachmentId: attachmentId });
      },
      error: (error) => {
        dispatch({ type: DELETE_RECORD_ATTACHMENT_FAILED, error: { message: error.message } });
        dispatch(reportError(error));
      },
    });
  }
);

export const attachmentActions = {
  getSignedData,
  getRecordAttachment,
  createRecordAttachment,
  deleteRecordAttachment,
  donwloadRecordAttachment,
};

// ------------------------------------
// Reducer
// ------------------------------------
export default function attachmentsReducer (state: ContextState, action: Action): ContextState {
  let recordId;

  switch (action.type) {
    case ATTACHMENT_DOWNLOAD_LOADING:
      return state
        .setIn(['attachments', 'isDownloading'], true)
        .setIn(['attachments', 'errors'], null);

    case ATTACHMENT_DOWNLOAD_LOADED:
      return state
        .setIn(['attachments', 'isDownloading'], false)
        .setIn(['attachments', 'errors'], null)
        .setIn(['attachments', 'downloadUrls', action.recordId, action.attachmentId], action.directUrl);

    case ATTACHMENT_DOWNLOAD_LOADING_FAILED:
      return state
        .setIn(['attachments', 'isDownloading'], false)
        .setIn(['attachments', 'errors'], [action.error.message]);


    case ATTACHMENT_SIGNED_DATA_LOADING:
      return state
        .setIn(['attachments', 'isLoading'], true)
        .setIn(['attachments', 'errors'], null);

    case ATTACHMENT_SIGNED_DATA_LOADED:
      return state
        .setIn(['attachments', 'isLoading'], false)
        .setIn(['attachments', 'errors'], null)
        .setIn(['attachments', 'signedData'], action.signedData);

    case ATTACHMENT_SIGNED_DATA_LOADING_FAILED:
      return state
        .setIn(['attachments', 'isLoading'], false)
        .setIn(['attachments', 'errors'], [action.error.message]);

    case GET_RECORD_ATTACHMENT:
      return state
        .setIn(['attachments', 'isLoading'], true)
        .setIn(['attachments', 'errors'], null);

    case GET_RECORD_ATTACHMENT_SUCCESS:
      return state
        .setIn(['attachments', 'isLoading'], false)
        .setIn(['attachments', 'errors'], null)
        .setIn(['attachments', 'data', action.attachment.id], action.attachment);

    case GET_RECORD_ATTACHMENT_FAILED:
      return state
        .setIn(['attachments', 'isLoading'], false)
        .setIn(['attachments', 'errors'], [action.error.message]);

    case CREATE_RECORD_ATTACHMENT:
      return state
        .setIn(['attachments', 'isSaving'], true)
        .setIn(['attachments', 'errors'], null);

    case CREATE_RECORD_ATTACHMENT_SUCCESS:
      recordId = toString(action.recordId);
      const newAttachmentId = action.attachment.id;
      const currentAttachmentsByRecord = get(state, ['attachments', 'byRecord', recordId], []);

      return state
        .setIn(['attachments', 'isSaving'], false)
        .setIn(['attachments', 'errors'], null)
        .setIn(['attachments', 'data', newAttachmentId], action.attachment)
        .setIn(['attachments', 'byRecord', recordId], concat(currentAttachmentsByRecord, [newAttachmentId]));

    case CREATE_RECORD_ATTACHMENT_FAILED:
      return state
        .setIn(['attachments', 'isSaving'], false)
        .setIn(['attachments', 'errors'], [action.error.message]);

    case DELETE_RECORD_ATTACHMENT:
      return state
        .setIn(['attachments', 'isSaving'], true)
        .setIn(['attachments', 'errors'], null);

    case DELETE_RECORD_ATTACHMENT_SUCCESS:
      recordId = toString(action.recordId);
      const deletedId = action.deletedAttachmentId;
      const data = get(state, ['attachments', 'data'], {});
      const byRecord = get(state, ['attachments', 'byRecord', recordId], []);
      const attachmentName = data[deletedId].name;
      const isImage = endsWith(attachmentName, 'jpg') || endsWith(attachmentName, 'jpeg') || endsWith(attachmentName, 'png');
      const attachmentsScope = isImage ? 'imageAttachments' : 'fileAttachments';
      const recordAttachments = get(state, ['records', 'data', recordId, 'details', attachmentsScope], []);
      return state
        .setIn(['attachments', 'isSaving'], false)
        .setIn(['attachments', 'errors'], null)
        .setIn(['attachments', 'data'], omit(data, toString(deletedId)))
        .setIn(['attachments', 'byRecord', recordId], without(byRecord, deletedId))
        .setIn(['records', 'data', recordId, 'details', attachmentsScope], without(recordAttachments, deletedId));

    case DELETE_RECORD_ATTACHMENT_FAILED:
      return state
        .setIn(['attachments', 'isSaving'], false)
        .setIn(['attachments', 'errors'], [action.error.message]);

    default:
      return state;
  }
}
