export default {
  'alert/delete/record': 'Tem certeza que deseja excluir este registro?',
  'alert/delete/document': 'Tem certeza de que deseja excluir este documento?',
  'alert/delete/prescription': 'Tem certeza de que deseja excluir esta prescrição?',
  'alert/delete/exam': 'Tem certeza de que deseja excluir este pedido de exames?',
  'alert/delete/appointment': 'Tem certeza que deseja excluir este agendamento?',
  'alert/hide/appointment': 'Deseja também remover esta consulta da agenda?',
  'alert/confirm_delete_file': 'Tem certeza que deseja apagar este arquivo?',
  'alert/confirm_delete_account': 'Tem certeza que deseja deletar permanente esta conta?',
  'alert/confirm_delete_measure': 'Tem certeza que deseja apagar este indicador?',
  'alert/confirm_quit_form': 'Há alterações não salvas. Você quer sair sem salvar?',
  'alert/confirm_delete_form': 'Tem certeza de que deseja apagar este formulário?',
  'alert/secretaries/form/confirm': 'Deseja realmente excluir esta secretária?',
  'alert/delete_patient_question': 'Deseja realmente excluir este paciente?',
  'alert/wrong_clinic': 'Você não pertence mais a esta clínica atualize para carregar a clínica correta.',
  'info/file_not_allowed': 'Arquivo não permitido.',
  'info/finalize_record_tip/print': 'Finalize sua consulta para habilitar a impressão.',
  'info/finalize_record_tip/change_date': 'Finalize sua consulta para alterar a data.',
  'info/finalize_record_tip/change_date/with_appointment': 'Você não pode alterar a data de consultas criadas na agenda.',
  'info/patient_needs_email': 'Este paciente não tem nenhum email cadastrado.',
  'error/invalid_recurrency_days': 'Selecione ao menos um dia.',
  'info/finalize_record_tip/blocked_edit': 'Finalize um registro em andamento para poder editar outro.',
  'error/mobile/file_not_downloadable': 'Este tipo só pode ser compartilhado pelo computador.',
  'error/direct_share/email/not_present': 'Este paciente não tem um email cadastrado, deseja cadastrar um?',
  'error/direct_share/mobile_phone/not_present': 'Este paciente não tem número de celular cadastrado deseja cadastrar um?',
  'error/direct_share/birthdate/not_present': 'Este paciente não tem data de nascimento cadastrada, deseja cadastrar?',
  'button/direct_share/confirm_button/ok': 'Ir para o cadastro',
  'button/direct_share/confirm_button/cancel': 'Fazer depois',
};
