// @flow
import React from 'react';
import { get, debounce } from 'lodash';
import { Input, InputMask, SelectBox, Spinner } from '@elements';
import { brStates } from '@helpers/constants';
import type { FormElementData } from '@types';
import { withTranslation } from 'react-i18next';
import { createNsTranslator, isPresent } from '@helpers/helpers';
import jQuery from 'jquery';
import './AddressFields.scss';

type Props = {
    unform?: boolean,
    required?: boolean,
    stateFieldCanBeEmpty?: boolean,
    // values
    addressZipcode?: ?string,
    addressStreet?: ?string,
    addressNumber?: ?string,
    addressNeighborhood?: ?string,
    addressCity?: ?string,
    addressState?: ?string,
    addressExtra?: ?string,
    // events
    onBlur?: Function,
    onChange: Function,
    // errors
    errors?: ?{
        addressZipcode?: string,
        addressStreet?: string,
        addressNumber?: string,
        addressNeighborhood?: string,
        addressCity?: string,
        addressState?: string,
        addressExtra?: string,
    },
    i18n: Object,
    t: Function,
};

type State = {
  isSearching: boolean,
  flick: boolean,
  // fetchError: boolean,
  addressZipcode: any,
  addressStreet: any,
  addressNumber: any,
  addressNeighborhood: any,
  addressCity: any,
  addressState: any,
  addressExtra: any,
};


const getHttp = (url: string) => new Promise((resolve, reject) => {
  const xmlhttp = new window.XMLHttpRequest();
  xmlhttp.onreadystatechange = function readyStateChange() {
    if (this.readyState === 4 && this.status === 200) {
      try {
        resolve(JSON.parse(this.responseText));
      } catch (e) {
        reject();
      }
    } else if (this.readyState === 4 && this.status !== 200) {
      reject();
    }
  };
  xmlhttp.open('GET', url, true);
  xmlhttp.send();
});

class AddressFields extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isSearching: false,
      flick: false,
      // fetchError: false,
      addressZipcode: this.props.addressZipcode,
      addressStreet: this.props.addressStreet,
      addressNumber: this.props.addressNumber,
      addressNeighborhood: this.props.addressNeighborhood,
      addressCity: this.props.addressCity,
      addressState: this.props.addressState,
      addressExtra: this.props.addressExtra,
    };
    this.numberId = 'addressFieldNumber' + (Math.floor(Math.random() * 999999) + 1111111);
  }

  componentWillReceiveProps(props: Props) {
    if (!this.props.unform) {
      this.setState({
        addressZipcode: props.addressZipcode,
        addressStreet: props.addressStreet,
        addressNumber: props.addressNumber,
        addressNeighborhood: props.addressNeighborhood,
        addressCity: props.addressCity,
        addressState: props.addressState,
        addressExtra: props.addressExtra,
      });
    }
  }

  render() {
    const {
      errors, onBlur, required, stateFieldCanBeEmpty,
    } = this.props;

    const {
      addressCity, addressNeighborhood, addressNumber, addressStreet,
      addressZipcode, addressExtra,
    } = this.state;

    const addressState = isPresent(this.state.addressState) ? this.state.addressState : null;

    const _ = createNsTranslator('global', this.props.t);

    const { isSearching, flick } = this.state;

    const statesItems: any = stateFieldCanBeEmpty ? [{ text: null, value: null, isEmpty: true }, ...brStates] : brStates;


    if (flick) return null;

    return (
      <div className="zipcodeAddressFields">
        <div className="split">
          <InputMask
            required={required}
            type="cep"
            name="addressZipcode"
            label={_('label/adress_zipcode') + ':'}
            value={addressZipcode}
            onChange={this.onChangeZipCode}
            onBlur={onBlur}
            // error={fetchError ? 'Cep não encontrado' : get(errors, 'addressZipcode', null)}
          />
          <div>&nbsp;{isSearching ? <Spinner text={_('label/searching2')} className="zipCodeSearching" /> : ''}</div>
        </div>
        <Input
          required={required}
          name="addressStreet"
          label={_('label/adress_streeet') + ':'}
          disabled={isSearching}
          onChange={this.onChange}
          value={addressStreet}
          onBlur={onBlur}
          error={get(errors, 'addressStreet', null)}
        />
        <div className="split responsive">
          <Input
            required={required}
            name="addressNumber"
            id={this.numberId}
            label={_('label/adress_number') + ':'}
            onChange={this.onChange}
            value={addressNumber}
            disabled={isSearching}
            onBlur={onBlur}
            error={get(errors, 'addressNumber', null)}
          />
          <Input
            name="addressExtra"
            label={_('label/adress_complement') + ':'}
            onChange={this.onChange}
            value={addressExtra}
            disabled={isSearching}
            onBlur={onBlur}
            error={get(errors, 'addressExtra', null)}
          />
        </div>
        <div className="split responsive">
          <Input
            required={required}
            name="addressNeighborhood"
            label={_('label/address_neighborhood') + ':'}
            onChange={this.onChange}
            value={addressNeighborhood}
            disabled={isSearching}
            onBlur={onBlur}
            error={get(errors, 'addressNeighborhood', null)}
          />
          <Input
            required={required}
            name="addressCity"
            label={_('label/adress_city') + ':'}
            onChange={this.onChange}
            value={addressCity}
            disabled={isSearching}
            onBlur={onBlur}
            error={get(errors, 'addressCity', null)}
          />
          <SelectBox
            required={required}
            name="addressState"
            label={_('label/adress_state') + ':'}
            options={statesItems}
            onChange={this.onChange}
            value={addressState}
            disabled={isSearching}
            onBlur={onBlur}
            error={get(errors, 'addressState', null)}
          />
        </div>
      </div>
    );
  }

  numberId: any;

  onChangeZipCode = (data: FormElementData) => {
    this.props.onChange(data);
    const lng = String(this.props.i18n.language).toLowerCase();

    if (lng !== 'pt-br' && lng !== 'pt') return;

    const zipValue = get(data, 'value', '');
    if (String(zipValue).trim().length > 7) {
      this.setState({ isSearching: true }, () => this.requestSearch(zipValue));
    }
  };

  onChange = (data: Object) => {
    this.setState({ [data.name]: data.value }, () => this.props.onChange(data));
  };

  requestSearch = debounce((zipValue) => getHttp(`https://viacep.com.br/ws/${zipValue}/json/`)
    .then(result => {
      this.setState({ isSearching: false /* fetchError: false */ }, () => {
        this.props.onChange({
          _isComposedChangeEvent: true,
          composedValue: {
            addressZipcode: zipValue,
            addressStreet: get(result, 'logradouro', ''),
            addressNeighborhood: get(result, 'bairro', ''),
            addressCity: get(result, 'localidade', ''),
            addressState: get(result, 'uf', ''),
          },
        });

        this.setState({
          addressZipcode: zipValue,
          addressStreet: get(result, 'logradouro', ''),
          addressNeighborhood: get(result, 'bairro', ''),
          addressCity: get(result, 'localidade', ''),
          addressState: get(result, 'uf', ''),
        }, () => this.setState({ flick: true }, () => this.setState({ flick: false }, () => {
          jQuery(`#${this.numberId}`).focus();
        })));
      });
    })
    .catch(() => {
      this.setState({ /* fetchError: true */ isSearching: false });
    }), 400);
}

export default withTranslation()(AddressFields);
