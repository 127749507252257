// @flow

//
// Prevent inconsistent DOM layout between similar screens by defining a
// custom framework to use for all Report Pages.
//
// Note: this might also prove useful to share with other screens outside of
// reports. For now it just solves the issue at a smaller scope.
//

import React from 'react';
import type { Node } from 'react';
import { Loading } from '@elements';
import ReportPageTitle from './ReportPageTitle';
import ReportPageTable from './ReportPageTable';
import ConditionalFeature from '../ConditionalFeature/ConditionalFeature';
import PlatformSwitch from '../PlatformSwitch/PlatformSwitch';

type PageProps = {
  id: string,
  noPadding?: boolean,
  isLoading?: boolean,
  isFeatureEnabled?: boolean,
  emptyState?: Function,
  filters?: Function,
  menu?: Function,
  children?: Node,
  renderBlockedFeature?: Function,
};
class ReportPage extends React.Component<PageProps> {
  componentDidMount() {
    window.lastSettingsScroll = 0;
  }

  render() {
    const { isLoading } = this.props;
    const emptyState = this.props.emptyState && this.props.emptyState();
    const isFeatureEnabled = this.props.isFeatureEnabled !== false;

    const renderContent = (isMobile: boolean) => (
      <div id={this.props.id} className="appContent reportsPage">
        <ConditionalFeature
          enabled={isFeatureEnabled}
          renderBlockedFeature={this.props.renderBlockedFeature}
          transparent
        >
          {this.props.menu && this.props.menu()}
          {this.props.filters && this.props.filters()}
          <section className={`mainSection ${this.props.noPadding ? 'noPadding' : ''}`}>
            {isLoading && <Loading className={isMobile ? 'mobileFixedReport' : ''} />}
            {!isLoading && emptyState}
            {!isLoading && !emptyState && this.props.children}
          </section>
        </ConditionalFeature>
      </div>
    );

    return <PlatformSwitch inject desktop={() => renderContent(false)} mobile={() => renderContent(true)} />;
  }
  static Title = ReportPageTitle;
  static Table = ReportPageTable;
}

export default ReportPage;
