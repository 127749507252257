// @flow
import moment from 'moment';
import { createCachedAction } from '@helpers/helpers';
import { get } from 'lodash';
import { Api } from '../../../helpers';
import { router } from '../../../routes/router';
import CustomFormTemplateService from '../../../services/CustomFormTemplateService';
import type
{
  Action,
  Dispatch,
  ContextState,
} from '../../../types';

// ------------------------------------
// Constants
// ------------------------------------
const CUSTOM_FORM_TEMPLATES_LOADING = 'customFormTemplates.CUSTOM_FORM_TEMPLATES_LOADING';
const CUSTOM_FORM_TEMPLATES_LOADED = 'customFormTemplates.CUSTOM_FORM_TEMPLATES_LOADED';
const CUSTOM_FORM_TEMPLATES_LOADING_FAILED = 'customFormTemplates.CUSTOM_FORM_TEMPLATES_LOADING_FAILED';

// ------------------------------------
// Actions
// ------------------------------------
// export const getCustomFormTemplates = () => (
//   (dispatch: Dispatch) => {
//     dispatch({ type: CUSTOM_FORM_TEMPLATES_LOADING });
//     Api.get(router.getApiRoute('getCustomFormTemplates', null), {
//       success: (response) => {
//         dispatch({ type: CUSTOM_FORM_TEMPLATES_LOADED, customFormTemplates: response.customFormTemplates });
//       },
//       error: (error) => {
//         dispatch({ type: CUSTOM_FORM_TEMPLATES_LOADING_FAILED, error: { message: error.message } });
//         dispatch(reportError(error));
//       },
//     });
//   }
// );

export const getCustomFormTemplates = (force?: boolean = false) => (dispatch: Dispatch) => {
  dispatch(createCachedAction(
    CUSTOM_FORM_TEMPLATES_LOADING, CUSTOM_FORM_TEMPLATES_LOADED, CUSTOM_FORM_TEMPLATES_LOADING_FAILED,
    12,
    () => Api.get(router.getApiRoute('getCustomFormTemplates', null)),
    ['context', 'customFormTemplates', 'ttl'],
    (response: any) => ({
      customFormTemplates: response.customFormTemplates,
    }),
  )(force, {}));
};

export const customFormTemplateActions = {
  getCustomFormTemplates,
};

// ------------------------------------
// Reducer
// ------------------------------------
export default function customFormTemplatesReducer (state: ContextState, action: Action): ContextState {
  switch (action.type) {
    case CUSTOM_FORM_TEMPLATES_LOADING:
      return state
        .setIn(['customFormTemplates', 'isLoading'], true)
        .setIn(['customFormTemplates', 'errors'], null);

    case CUSTOM_FORM_TEMPLATES_LOADED:
      const normalized = CustomFormTemplateService.normalizeCustomFormTemplates(action.customFormTemplates);
      return state
        .setIn(['customFormTemplates', 'isLoading'], false)
        .setIn(['customFormTemplates', 'errors'], null)
        .setIn(['customFormTemplates', 'ttl'], get(action, 'ttl', null))
        .setIn(['customFormTemplates', 'data'], normalized.customFormTemplates)
        .setIn(['customFormTemplates', 'lastFetch'], moment().toISOString());

    case CUSTOM_FORM_TEMPLATES_LOADING_FAILED:
      return state
        .setIn(['customFormTemplates', 'isLoading'], false)
        .setIn(['customFormTemplates', 'errors'], [action.error.message]);

    default:
      return state;
  }
}
