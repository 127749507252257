// @flow
import React from 'react';
import { connect } from 'react-redux';
import { get } from 'lodash';
import { Toast } from '../components/elements';
import { toastActions } from '../redux/modules/context/toasts';
import type { ToastData, Dispatch } from '../types';

type Props = {
  toastData: ToastData,
  dispatch: Function,
};

class ToastContainer extends React.Component<Props> {
  render() {
    const { toastData } = this.props;

    return (
      <Toast
        toastData={toastData}
        onClose={this.closeToast}
      />
    );
  }

  closeToast = () => {
    this.props.dispatch(toastActions.closeActiveToast());
  };
}

const mapStateToProps = (state) => ({
  toastData: get(state.context, ['toasts', 'data'], {}),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({ dispatch });

export default connect<any, any, any, any, any, any>(
  mapStateToProps,
  mapDispatchToProps,
)(ToastContainer);
