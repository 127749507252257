// @flow
import Immutable from 'seamless-immutable';
import {
  omit, omitBy, get, isEmpty, isFunction, keys, forEach, toNumber,
} from 'lodash';
import { reportError } from '@helpers/helpers';
import moment from 'moment';
import { Api } from '../../../helpers';
import AppointmentService from '../../../services/AppointmentService';
import { router } from '../../../routes/router';
import type
{
  Action,
  Dispatch,
  ContextState,
  AgendaFilters,
} from '../../../types';


type AppointmentRequest = {
  id: ?number,
  title: ?string,
  doctorId: number,
  notes: ?string,
  date: string,
  time: string,
  duration: number,
  patientId: number,
  room: number,
  showNotes: boolean,
  withoutPatient: boolean,
  serviceIds: ?Array<number>,
  recurrent: ?{
    enabled: boolean,
    biweekly: boolean,
    appointments_count: number,
    monday: boolean,
    tuesday: boolean,
    wednesday: boolean,
    thursday: boolean,
    friday: boolean,
    saturday: boolean,
    sunday: boolean,
  },
};

type AppointmentStatus = {
  state: string,
  value: boolean,
  hidden: boolean,
};

// ------------------------------------
// Constants
// ------------------------------------
const APPOINTMENTS_LOADING = 'appointments.APPOINTMENTS_LOADING';
const APPOINTMENTS_LOADED = 'appointments.APPOINTMENTS_LOADED';
const APPOINTMENTS_LOADING_FAILED = 'appointments.APPOINTMENTS_LOADING_FAILED';

const APPOINTMENT_LOADING = 'appointments.APPOINTMENT_LOADING';
const APPOINTMENT_LOADED = 'appointments.APPOINTMENT_LOADED';
const APPOINTMENT_LOADING_FAILED = 'appointments.APPOINTMENT_LOADING_FAILED';

const APPOINTMENT_CREATE_IN_PROGRESS = 'appointments.APPOINTMENT_CREATE_IN_PROGRESS';
const APPOINTMENT_CREATE_SUCCESS = 'appointments.APPOINTMENT_CREATE_SUCCESS';
const APPOINTMENT_CREATE_FAILED = 'appointments.APPOINTMENT_CREATE_FAILED';

const APPOINTMENT_UPDATE_IN_PROGRESS = 'appointments.APPOINTMENT_UPDATE_IN_PROGRESS';
const APPOINTMENT_UPDATE_SUCCESS = 'appointments.APPOINTMENT_UPDATE_SUCCESS';
const APPOINTMENT_UPDATE_FAILED = 'appointments.APPOINTMENT_UPDATE_FAILED';

const APPOINTMENT_DELETE_IN_PROGRESS = 'appointments.APPOINTMENT_DELETE_IN_PROGRESS';
const APPOINTMENT_DELETE_SUCCESS = 'appointments.APPOINTMENT_DELETE_SUCCESS';
const APPOINTMENT_DELETE_FAILED = 'appointments.APPOINTMENT_DELETE_FAILED';

const APPOINTMENT_STATUS_UPDATE_IN_PROGRESS = 'appointments.APPOINTMENT_STATUS_UPDATE_IN_PROGRESS';
const APPOINTMENT_STATUS_UPDATE_SUCCESS = 'appointments.APPOINTMENT_STATUS_UPDATE_SUCCESS';
const APPOINTMENT_STATUS_UPDATE_FAILED = 'appointments.APPOINTMENT_STATUS_UPDATE_FAILED';

const HIDE_APPOINTMENT_IN_PROGRESS = 'appointments.HIDE_APPOINTMENT_IN_PROGRESS';
const HIDE_APPOINTMENT_SUCCESS = 'appointments.HIDE_APPOINTMENT_SUCCESS';
const HIDE_APPOINTMENT_FAILED = 'appointments.HIDE_APPOINTMENT_FAILED';
const APPOINTMENT_SET_AGENDA_FILTERS = 'appointments.APPOINTMENT_SET_AGENDA_FILTERS';
const REMOVE_APPOINTMENT_FROM_CACHE = 'appointments.REMOVE_APPOINTMENT_FROM_CACHE';

// ------------------------------------
// Actions
// ------------------------------------

export const getAppointments = (startDate: string, endDate: string, hardReload?: boolean = false) => (
  (dispatch: Dispatch) => {
    dispatch({ type: APPOINTMENTS_LOADING, hardReload, startDate });
    const route = router.getApiRoute('getAppointments', { startDate, endDate });

    const apiRoute = {
      ...route,
      path: `${route.path}?startDate=${startDate}&endDate=${endDate}`,
    };

    Api.get(apiRoute, {
      success: (response) => {
        dispatch({ type: APPOINTMENTS_LOADED, appointments: response.appointments, startDate, hardReload });
      },
      error: (error) => {
        dispatch({ type: APPOINTMENTS_LOADING_FAILED, error: { message: error.message } });
        dispatch(reportError(error));
      },
    });
  }
);

export const setAgendaFilters = (filters: AgendaFilters) => (dispatch: Dispatch) => {
  dispatch({
    type: APPOINTMENT_SET_AGENDA_FILTERS,
    filters,
  });
  // const startDate = moment(filters.selectedDate).startOf('isoWeek').format('YYYY-MM-DD');
  // const endDate = moment(filters.selectedDate).endOf('week').format('YYYY-MM-DD');
  // dispatch(getAppointments(startDate, endDate));
};

export const getAppointment = (appointmentId: number) => (
  (dispatch: Dispatch) => {
    dispatch({ type: APPOINTMENT_LOADING, appointmentId });
    Api.get(router.getApiRoute('getAppointment', { id: appointmentId }), {
      success: (response) => {
        dispatch({ type: APPOINTMENT_LOADED, appointment: response.appointment });
      },
      error: (error) => {
        dispatch({ type: APPOINTMENT_LOADING_FAILED, error: { message: error.message }, appointmentId });
        dispatch(reportError(error));
      },
    });
  }
);

export const createAppointment = (_appointment: AppointmentRequest, callBack?: Function) => (
  (dispatch: Dispatch) => {
    const appointment: AppointmentRequest = {
      ..._appointment,
    };

    if (get(appointment, 'recurrent') && get(appointment, 'recurrent', 'enabled')) {
      appointment.recurrent = {
        ...(get(_appointment, 'recurrent') || {}),
        frequency_code: get(_appointment, ['recurrent', 'biweekly']) ? 1 : 0,
      };
    }

    const date = moment(appointment.date);

    const randId = Math.random().toString(36).substring(7);
    dispatch({ type: APPOINTMENT_CREATE_IN_PROGRESS, appointment: { ...appointment, id: randId } });
    Api.post(router.getApiRoute('createAppointment', null), {
      data: { appointment },
      success: (response) => {
        dispatch({ type: APPOINTMENT_CREATE_SUCCESS, appointment: response.data.appointment, id: randId });
        if (!isEmpty(appointment.recurrent)) {
          const startDate = date.isValid() ? date.startOf('isoWeek').format('YYYY-MM-DD') : moment().startOf('isoWeek').format('YYYY-MM-DD');
          const endDate = date.isValid() ? date.endOf('week').format('YYYY-MM-DD') : moment().endOf('isoWeek').format('YYYY-MM-DD');
          dispatch(getAppointments(startDate, endDate));
        }
        if (isFunction(callBack)) {
          callBack(true);
        }
      },
      error: (error) => {
        dispatch({ type: APPOINTMENT_CREATE_FAILED, error: { message: error.message }, id: randId });

        dispatch(reportError(error));

        if (isFunction(callBack)) {
          callBack(false);
        }
      },
    });
  }
);

export const updateAppointment = (appointment: AppointmentRequest, callBack?: Function) => (
  (dispatch: Dispatch, getState: Function) => {
    const backupData = {
      full: get(getState(), ['context', 'appointments', 'data', 'full', get(appointment, 'id')]),
      compact: get(getState(), ['context', 'appointments', 'data', 'compact', get(appointment, 'id')]),
    };

    dispatch({
      type: APPOINTMENT_UPDATE_IN_PROGRESS, appointmentId: get(appointment, 'id'), appointment, backupData,
    });


    Api.patch(router.getApiRoute('updateAppointment', { id: get(appointment, 'id') }), {
      data: { appointment },
      success: (response) => {
        dispatch({ type: APPOINTMENT_UPDATE_SUCCESS, appointment: response.appointment });
        if (isFunction(callBack)) {
          callBack(true);
        }
      },
      error: (error) => {
        dispatch({
          type: APPOINTMENT_UPDATE_FAILED,
          error: { message: error.message },
          appointmentId: get(appointment, 'id'),
          backupData,
        });

        dispatch(reportError(error));

        if (isFunction(callBack)) {
          callBack(false);
        }
      },
    });
  }
);

export const deleteAppointment = (appointmentId: number, deleteAllFutureRecurring?: boolean, callBack?: Function) => (
  (dispatch: Dispatch) => {
    dispatch({ type: APPOINTMENT_DELETE_IN_PROGRESS, appointmentId });
    Api.destroy(router.getApiRoute('deleteAppointment', { id: appointmentId }), {
      query: { deleteAllFutureRecurring },
      success: (response) => {
        dispatch({
          type: APPOINTMENT_DELETE_SUCCESS, deletedId: response.deletedId, futureAppointmentsIds: response.futureAppointmentsIds,
        });
        if (isFunction(callBack)) {
          callBack(true);
        }
      },
      error: (error) => {
        dispatch({ type: APPOINTMENT_DELETE_FAILED, appointmentId, error: { message: error.message } });
        dispatch(reportError(error));
        if (isFunction(callBack)) {
          callBack(false);
        }
      },
    });
  }
);

export const hideAppointment = (appointmentId: number, callback: Function = () => {}) => (
  (dispatch: Dispatch) => {
    dispatch({ type: HIDE_APPOINTMENT_IN_PROGRESS });
    Api.post(router.getApiRoute('hideAppointment', { id: appointmentId }), {
      success: (response) => {
        dispatch({ type: HIDE_APPOINTMENT_SUCCESS, appointment: response.data.appointment });
        if (callback) {
          callback(true);
        }
      },
      error: (error) => {
        dispatch({ type: HIDE_APPOINTMENT_FAILED, error: { message: error.message } });
        dispatch(reportError(error));
        if (callback) {
          callback(false, error.message);
        }
      },
    });
  }
);

export const getAppointmentWhatsAppConfirmationLink = (appointmentId: number, callback?: Function) => {
  Api.get(router.getApiRoute('getAppointmentWhatsAppConfirmationLink', { id: appointmentId }), {
    success: (response) => {
      if (callback) callback(true, response);
    },
    error: (error) => {
      if (callback) callback(false, error.message);
    },
  });
};

export const updateAppointmentStatus = (appointmentId: number, appointmentState: AppointmentStatus) => (
  (dispatch: Dispatch) => {
    dispatch({ type: APPOINTMENT_STATUS_UPDATE_IN_PROGRESS, appointmentId });
    Api.post(router.getApiRoute('updateAppointmentStatus', { id: appointmentId }), {
      data: {
        appointment: {
          update_status: appointmentState,
        },
      },
      success: (response) => {
        dispatch({ type: APPOINTMENT_STATUS_UPDATE_SUCCESS, appointment: response.data.appointment, hidden: appointmentState.hidden });

        // if (appointmentState.hidden) {
        //   dispatch(hideAppointment(appointmentId));
        // }
      },
      error: (error) => {
        dispatch({ type: APPOINTMENT_STATUS_UPDATE_FAILED, error: { message: error.message }, appointmentId });
        dispatch(reportError(error));
      },
    });
  }
);

export const updateStatusAndAppointment = (appointmentState: AppointmentStatus, appointment: AppointmentRequest) => (
  (dispatch: Dispatch, getState: Function) => {
    const backupData = {
      full: get(getState(), ['context', 'appointments', 'data', 'full', get(appointment, 'id')]),
      compact: get(getState(), ['context', 'appointments', 'data', 'compact', get(appointment, 'id')]),
    };


    dispatch({ type: APPOINTMENT_STATUS_UPDATE_IN_PROGRESS, appointmentId: get(appointment, 'id') });

    Api.post(router.getApiRoute('updateAppointmentStatus', { id: get(appointment, 'id') }), {
      data: {
        appointment: {
          update_status: appointmentState,
        },
      },
      success: (statusUpdateResponse) => {
        dispatch({
          type: APPOINTMENT_UPDATE_IN_PROGRESS, appointmentId: get(appointment, 'id'), appointment, backupData,
        });

        Api.patch(router.getApiRoute('updateAppointment', { id: get(appointment, 'id') }), {
          data: { appointment },
          success: (response) => {
            dispatch({ type: APPOINTMENT_UPDATE_SUCCESS, appointment: response.appointment });
            dispatch({ type: APPOINTMENT_STATUS_UPDATE_SUCCESS, appointment: statusUpdateResponse.data.appointment, hidden: appointmentState.hidden });
          },
          error: (error) => {
            dispatch({
              type: APPOINTMENT_UPDATE_FAILED, error: { message: error.message }, appointmentId: get(appointment, 'id'), backupData,
            });
            dispatch(reportError(error));
          },
        });
      },
      error: (error) => {
        dispatch({
          type: APPOINTMENT_STATUS_UPDATE_FAILED,
          error: { message: error.message },
          appointmentId: get(appointment, 'id'),
        });
        dispatch(reportError(error));
      },
    });
  }
);

export const resetPatientAppointmentsCache = (patientId: number) =>
  (dispatch: Dispatch) => {
    dispatch({ type: REMOVE_APPOINTMENT_FROM_CACHE, patientId });
  };


export const appointmentActions = {
  getAppointments,
  getAppointment,
  createAppointment,
  updateAppointment,
  deleteAppointment,
  updateAppointmentStatus,
  updateStatusAndAppointment,
  hideAppointment,
  setAgendaFilters,
  getAppointmentWhatsAppConfirmationLink,
  resetPatientAppointmentsCache,
};

// ------------------------------------
// Reducer
// ------------------------------------
export default function appointmentsReducer (state: ContextState, action: Action): ContextState {
  let newState;
  let compactAppointment;
  let fullTargetAppointment;
  let compactTargetAppointment;
  let tmpDate;
  let tmpTime;
  let tmpStatDt;
  let tmpDutation;
  let compactData;

  switch (action.type) {
    case APPOINTMENTS_LOADING:
      const activeIncomingKey = moment.utc(action.startDate).isoWeekday(1).startOf('isoWeek').format('YYYY-MM-DD');

      return state
        .setIn(['appointments', 'isLoading'], true)
        .setIn(['appointments', 'activeIncomingKey'], activeIncomingKey)
        .setIn(['appointments', 'errors'], null);

    case APPOINTMENTS_LOADED:
      const normalized = AppointmentService.normalizeAppointments(action.appointments);
      const oldData = get(state, ['appointments', 'data', 'compact'], Immutable({}));

      const newFiltersIds = keys(normalized.appointments);
      const startOfWeek = moment.utc(action.startDate).startOf('isoWeek').format('YYYY-MM-DD');
      const endOfWeek = moment.utc(action.startDate).endOf('isoWeek').format('YYYY-MM-DD');

      const appointmentsData = {};
      const incomingKeys = keys(normalized.appointments || {}).map(toNumber);

      // if (action.hardReload === true) {
      //   appointmentsData = normalized.appointments;
      // } else {
      // Deep merge items
      forEach(normalized.appointments, (incoming, idKey) => {
        appointmentsData[idKey] = {
          id: get(incoming, 'id'),
          ...get(oldData, idKey),
          ...incoming,
          full: get(oldData, [idKey, 'full'], false),
        };
      });
      // Add non merged old data items
      forEach(oldData, (old, idKey) => {
        if (!appointmentsData[idKey]) {
          appointmentsData[idKey] = old;
        }
      });
      // }

      return state
        .setIn(['appointments', 'loadingItems'], {})
        .setIn(['appointments', 'isLoading'], false)
        .setIn(['appointments', 'lastDate'], { startOfWeek, endOfWeek })
        .setIn(['appointments', 'incomingKeys', startOfWeek], incomingKeys)
        .setIn(['appointments', 'activeIncomingKey'], startOfWeek)
        .setIn(['appointments', 'errors'], null)
        .setIn(['appointments', 'data', 'compact'], appointmentsData)
        .setIn(['appointments', 'filters', 'data', startOfWeek], newFiltersIds);

    case APPOINTMENTS_LOADING_FAILED:
      return state
        .setIn(['appointments', 'isLoading'], false)
        .setIn(['appointments', 'errors'], [action.error.message]);

    case APPOINTMENT_LOADING:
      return state
        .setIn(['appointments', 'loadingItems', String(action.appointmentId)], true)
        .setIn(['appointments', 'errors'], null);

    case APPOINTMENT_LOADED:
      return state
        .setIn(['appointments', 'loadingItems', String(action.appointment.id)], false)
        .setIn(['appointments', 'errors'], null)
        .setIn(['appointments', 'data', 'full', action.appointment.id], action.appointment);

    case APPOINTMENT_LOADING_FAILED:
      return state
        .setIn(['appointments', 'loadingItems', String(action.appointmentId)], false)
        .setIn(['appointments', 'errors'], [action.error.message]);

    case APPOINTMENT_CREATE_IN_PROGRESS:
      compactAppointment = {
        ...get(action, 'appointment', {}),
      };


      tmpDate = moment.utc(get(action, ['appointment', 'date']), 'YYYY-MM-DD[T]HH:mm:ss.SSSZZ').format('YYYY-MM-DD');
      tmpTime = get(action, ['appointment', 'time']);
      tmpStatDt = moment(`${tmpDate} ${tmpTime}:00`);
      // Here we handle preview time
      if (tmpStatDt) {
        tmpDutation = get(action, ['appointment', 'duration']);
        if (tmpStatDt.isValid()) {
          compactAppointment.start = tmpStatDt.format('YYYY-MM-DD HH:mm:ss');
          compactAppointment.end = tmpStatDt.clone().add(tmpDutation, 'minutes').format('YYYY-MM-DD HH:mm:ss');
        }
      }

      if (compactAppointment.patient) {
        compactAppointment.title = get(compactAppointment, ['patient', 'name']);
      }

      return state
        .setIn(['appointments', 'isSaving'], true)
        .setIn(['appointments', 'data', 'compact', String(compactAppointment.id)], compactAppointment)
        .setIn(['appointments', 'loadingItems', get(action, ['appointment', 'id'], '')], true)
        .setIn(['appointments', 'errors'], null);

    case APPOINTMENT_CREATE_SUCCESS:
      const oldActiveIncomingKey = get(state, ['appointments', 'activeIncomingKey']);
      compactAppointment = AppointmentService.getCompactFromFullAppointment(get(action, 'appointment', {}));
      const oldIncomingKeys = get(state, ['appointments', 'incomingKeys', oldActiveIncomingKey], []) || [];

      compactData = {
        ...get(state, ['appointments', 'data', 'compact'], {}),
        [compactAppointment.id]: compactAppointment,
      };

      compactData = omit(compactData, [String(action.id)]);
      return state
        .setIn(['appointments', 'isSaving'], false)
        .setIn(['appointments', 'errors'], null)
        .setIn(['appointments', 'incomingKeys', oldActiveIncomingKey], [...oldIncomingKeys, toNumber(compactAppointment.id)])
        .setIn(['appointments', 'loadingItems', String(action.id)], false)
        .setIn(['appointments', 'data', 'compact'], compactData);

    case APPOINTMENT_CREATE_FAILED:
      compactData = get(state, ['appointments', 'data', 'compact']);
      return state
        .setIn(['appointments', 'isSaving'], false)
        .setIn(['appointments', 'data', 'compact'], omit(compactData, [String(action.id)]))
        .setIn(['appointments', 'loadingItems', String(action.id)], false)
        .setIn(['appointments', 'errors'], [action.error.message]);

    case APPOINTMENT_UPDATE_IN_PROGRESS:
      // Because update is an incomplete data, we need merge them overwriting old with new changes
      compactAppointment = {
        ...get(action, ['backupData', 'compact'], {}),
        // Ommit props avoiding problems on presentation
        ...omit(AppointmentService.getCompactFromFullAppointment(get(action, 'appointment', {})), ['patient', 'patientId', 'id']),
      };

      tmpDate = moment.utc(get(action, ['appointment', 'date'])).format('YYYY-MM-DD');
      tmpTime = moment.utc(get(action, ['appointment', 'time'])).format('HH:mm:ss');
      tmpStatDt = moment(`${tmpDate} ${tmpTime}`);
      // Here we handle preview time
      if (tmpStatDt) {
        tmpDutation = get(action, ['appointment', 'duration']);
        if (tmpStatDt.isValid()) {
          compactAppointment.start = tmpStatDt.format('YYYY-MM-DD HH:mm:ss');
          compactAppointment.end = tmpStatDt.clone().add(tmpDutation, 'minutes').format('YYYY-MM-DD HH:mm:ss');
        }
      }

      return state
        .setIn(['appointments', 'loadingItems', String(action.appointmentId)], true)
        .setIn(['appointments', 'data', 'compact', action.appointmentId], compactAppointment)
        .setIn(['appointments', 'errors'], null);


    case APPOINTMENT_UPDATE_SUCCESS:
      compactAppointment = AppointmentService.getCompactFromFullAppointment(action.appointment);
      newState = state
        .setIn(['appointments', 'loadingItems', String(action.appointment.id)], false)
        .setIn(['appointments', 'errors'], null)
        .setIn(['appointments', 'data', 'compact', action.appointment.id], compactAppointment);
      fullTargetAppointment = get(newState, ['appointments', 'data', 'full', action.appointment.id.toString()]);
      if (fullTargetAppointment) {
        return newState.setIn(['appointments', 'data', 'full', action.appointment.id], action.appointment);
      }
      return newState;

    case APPOINTMENT_UPDATE_FAILED:
      if (action.backupData) {
        let fullDt = get(state, ['appointments', 'data', 'full'], {});
        let compactDt = get(state, ['appointments', 'data', 'compact'], {});
        const fullBkp = get(action, ['backupData', 'full']);
        const compactBkp = get(action, ['backupData', 'compact']);

        if (fullBkp) {
          fullDt = {
            ...fullDt,
            [action.appointmentId]: fullBkp,
          };
        }

        if (compactBkp) {
          compactDt = {
            ...compactDt,
            [action.appointmentId]: compactBkp,
          };
        }

        return state
          .setIn(['appointments', 'loadingItems', String(action.appointmentId)], false)
          .setIn(['appointments', 'data', 'full'], fullDt)
          .setIn(['appointments', 'data', 'compact'], compactDt)
          .setIn(['appointments', 'errors'], [action.error.message]);
      }
      return state
        .setIn(['appointments', 'loadingItems', String(action.appointmentId)], false)
        .setIn(['appointments', 'errors'], [action.error.message]);

    case APPOINTMENT_DELETE_IN_PROGRESS:
      return state
        .setIn(['appointments', 'loadingItems', String(action.appointmentId)], true)
        .setIn(['appointments', 'isSaving'], true)
        .setIn(['appointments', 'errors'], null);

    case APPOINTMENT_DELETE_SUCCESS:
      newState = state
        .setIn(['appointments', 'loadingItems', String(action.deletedId)], false)
        .setIn(['appointments', 'isSaving'], false)
        .setIn(['appointments', 'errors'], null);
      fullTargetAppointment = get(newState, ['appointments', 'data', 'full', action.deletedId.toString()]);
      compactTargetAppointment = get(newState, ['appointments', 'data', 'compact', action.deletedId.toString()]);

      const futureAppointmentsIds = get(action, 'futureAppointmentsIds', []);

      if (state.appointments && fullTargetAppointment) {
        const delid = action.deletedId.toString();
        newState = newState.setIn(['appointments', 'data', 'full'], omit(get(state, ['appointments', 'data', 'full'], {}), [delid, ...futureAppointmentsIds]));
      }
      if (state.appointments && compactTargetAppointment) {
        const delid = action.deletedId.toString();
        newState = newState.setIn(['appointments', 'data', 'compact'], omit(get(state, ['appointments', 'data', 'compact'], {}), [delid, ...futureAppointmentsIds]));
      }
      return newState;

    case APPOINTMENT_DELETE_FAILED:
      return state
        .setIn(['appointments', 'loadingItems', String(action.appointmentId)], false)
        .setIn(['appointments', 'isSaving'], false)
        .setIn(['appointments', 'errors'], [action.error.message]);

    case APPOINTMENT_STATUS_UPDATE_IN_PROGRESS:
      return state
        .setIn(['appointments', 'loadingItems', String(action.appointmentId)], true)
        .setIn(['appointments', 'errors'], null);

    case APPOINTMENT_STATUS_UPDATE_SUCCESS:
      compactAppointment = AppointmentService.getCompactFromFullAppointment(action.appointment);
      newState = state
        .setIn(['appointments', 'loadingItems', String(action.appointment.id)], false)
        .setIn(['appointments', 'errors'], null)
        .setIn(['appointments', 'data', 'compact', action.appointment.id], compactAppointment);
      fullTargetAppointment = get(newState, ['appointments', 'data', 'full', action.appointment.id.toString()]);

      if (fullTargetAppointment) {
        const newStateV1 = newState.setIn(['appointments', 'data', 'full', action.appointment.id], action.appointment);

        if (action.hidden) {
          const newFullData = omit(get(newStateV1, ['appointments', 'data', 'full'], {}), compactAppointment.id);
          const newCompactData = omit(get(newStateV1, ['appointments', 'data', 'compact'], {}), compactAppointment.id);

          const newStateV2 = Immutable(Immutable.asMutable(newStateV1))
            .setIn(['appointments', 'data', 'full'], newFullData)
            .setIn(['appointments', 'data', 'compact'], newCompactData);
          return newStateV2;
        }
        return newStateV1;
      } if (action.hidden) {
        const newFullData = omit(get(newState, ['appointments', 'data', 'full'], {}), compactAppointment.id);
        const newCompactData = omit(get(newState, ['appointments', 'data', 'compact'], {}), compactAppointment.id);

        const newStateV2 = Immutable(Immutable.asMutable(newState))
          .setIn(['appointments', 'data', 'full'], newFullData)
          .setIn(['appointments', 'data', 'compact'], newCompactData);
        return newStateV2;
      }
      return newState;

    case APPOINTMENT_STATUS_UPDATE_FAILED:
      return state
        .setIn(['appointments', 'loadingItems', String(action.appointmentId)], false)
        .setIn(['appointments', 'errors'], [action.error.message]);

    case HIDE_APPOINTMENT_IN_PROGRESS:
      return state
        .setIn(['appointments', 'isLoading'], true)
        .setIn(['appointments', 'errors'], null);

    case HIDE_APPOINTMENT_SUCCESS:
      newState = state
        .setIn(['appointments', 'isLoading'], false)
        .setIn(['appointments', 'errors'], null);
      fullTargetAppointment = get(newState, ['appointments', 'data', 'full', action.appointment.id.toString()]);
      if (fullTargetAppointment) {
        return newState
          .setIn(['appointments', 'data', 'full'], omit(get(state, ['appointments', 'data', 'full'], {}), fullTargetAppointment.id))
          .setIn(['appointments', 'data', 'compact'], omit(get(state, ['appointments', 'data', 'compact'], {}), fullTargetAppointment.id));
      }
      return newState;

    case HIDE_APPOINTMENT_FAILED:
      return state
        .setIn(['appointments', 'isLoading'], false)
        .setIn(['appointments', 'errors'], [action.error.message]);


    case APPOINTMENT_SET_AGENDA_FILTERS:
      const filtersAction = get(action, 'filters', {});
      const filtersState = get(state, ['appointments', 'filters'], {});
      const validSelectedDate = filtersAction.selectedDate || filtersState.selectedDate;
      const selectedDate = moment.isMoment(validSelectedDate) ? moment(validSelectedDate).format('YYYY-MM-DD HH:mm:ss') : validSelectedDate;
      const currentFilterDataIds = get(state, ['appointments', 'filters', 'data']);

      const factiveIncomingKey = moment.utc(selectedDate).startOf('isoWeek').format('YYYY-MM-DD');

      return state.setIn(['appointments', 'filters'], {
        selectedDate,
        switchMode: get(filtersAction, 'switchMode', get(filtersState, 'switchMode')),
        isMobilePickerOpened: get(filtersAction, 'isMobilePickerOpened', get(filtersState, 'isMobilePickerOpened')),
        roomsFilter: get(filtersAction, 'roomsFilter', get(filtersState, 'roomsFilter')),
        doctorsFilter: get(filtersAction, 'doctorsFilter', get(filtersState, 'doctorsFilter')),
        data: currentFilterDataIds,
      })
        .setIn(['appointments', 'activeIncomingKey'], factiveIncomingKey);


    case REMOVE_APPOINTMENT_FROM_CACHE:
      const fullAppointmentsCache = { ...get(state, ['appointments', 'data', 'full'], {}) };
      const compactAppointmentsCache = { ...get(state, ['appointments', 'data', 'compact'], {}) };

      const { patientId } = action;

      const filteredFullAppointmentsCache = omitBy(fullAppointmentsCache, (appointment) => get(appointment, ['patient', 'id'], null) === patientId);
      const filteredCompactAppointmentsCache = omitBy(compactAppointmentsCache, (appointment) => get(appointment, ['patient', 'id'], null) === patientId);

      return state
        .setIn(['appointments', 'data', 'full'], filteredFullAppointmentsCache)
        .setIn(['appointments', 'data', 'compact'], filteredCompactAppointmentsCache);

    default:
      return state;
  }
}
