// @flow
import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Input, Icon } from '@elements';
import { isPresent, isEmailValid, createNsTranslator } from '@helpers/helpers';
import { withTranslation } from 'react-i18next';
import './ForgotPassword.scss';

type Props = {
  isRecoveringPassword: boolean,
  emailError: boolean,
  onSubmit: Function,
  onInitialize: Function,
  t: Function,
  recoverSuccess: boolean,
};

type State = {
  email: string,
  emailInvalid: boolean,
};

class ForgotPassword extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      email: '',
      emailInvalid: false,
    };
  }
  componentDidMount() {
    this.props.onInitialize();
  }

  render() {
    const { isRecoveringPassword, emailError, recoverSuccess } = this.props;

    const _ = createNsTranslator('onboarding', this.props.t);

    return (
      <div id="signUpPage" className="signUpLayout">
        <div className="signUpLayoutLeft">

          <div className="signupHeader">
            <Link className="logo" to='/' />
            <hr className="fade" />
          </div>

          <div className="form">
            <h1>
              <Icon iconName="lock" className="icon" size={28} />
              <span>{_('label/forgot_password')}</span>
            </h1>
            {recoverSuccess &&
              <p className="recoverSuccess">{_('label/recover_success')}</p>
            }
            {emailError &&
              <p className="emailError">{_('label/email_error')}</p>
            }
            <Input
              name="email"
              type="email"
              label={_('label/your_email')}
              value={this.state.email || ''}
              onChange={this.onChangeEmail}
              error={this.getEmailError()}
            />
            {!recoverSuccess &&
            <Button
              disabled={!isPresent(this.state.email)}
              className="primary"
              text={_('action/send_email_instrunctions')}
              isLoading={isRecoveringPassword}
              onClick={this.handleSubmit}
              loadingMessage={_('label/sending', 'global')}
            />
            }
            {recoverSuccess &&
              <Button className="primary loginBtn" text={_('action/login_now')} linkTo={`/entrar?email=${this.state.email}`} />
            }
          </div>

          <div className='signUpLayoutLeftFooter'>
            <span>{_('label/q_remember_your_password')}</span>
            <Link to='/entrar'>{_('action/login_now')}</Link>
          </div>

        </div>
      </div>
    );
  }

  onChangeEmail = (data: Object) => {
    if (data.value !== this.state.email) {
      this.props.onInitialize();
    }
    this.setState({ email: data.value, emailInvalid: false });
  };

  handleSubmit = () => {
    const emailValid = isEmailValid(this.state.email);
    if (isPresent(this.state.email) && emailValid) {
      this.props.onSubmit(this.state.email);
    } else {
      this.setState({ emailInvalid: true });
    }
  };

  getEmailError = ():any => (this.state.emailInvalid ? this.props.t('global:error/invalid_email') : null);
}

export default withTranslation()(ForgotPassword);
