// @flow
import React from 'react';
import { map, get, toArray } from 'lodash';
import { SettingsMenu, WorkHoursForm, PlatformSwitch, AppMobileHeader, AppMobileFooter } from '@blocks';
import { Button, Breadcrumb, FormattedText, Spinner } from '@elements';
import type { User, Doctor, WorkHours as WorkHoursType, BundledPermissions } from '@types';
import { sort, numberCompare, createNsTranslator } from '@helpers/helpers';
import { withTranslation } from 'react-i18next';
import './WorkHours.scss';

type Props = {
  user: User,
  doctor: Doctor,
  onAdd: Function,
  onDelete: Function,
  onLogout: Function,
  t: Function,
  deletingIds: Object,
  futureWorkHours: Array<WorkHoursType>,
  permissions: BundledPermissions,
};
class WorkHours extends React.Component<Props> {
  render() {
    const {
      user, doctor, onAdd, deletingIds, futureWorkHours, onLogout,
    } = this.props;

    const _ = createNsTranslator('settings', this.props.t);

    const isDeleting = id => get(deletingIds, id, false);
    const workHours = get(doctor, 'workHours', []);
    const orderNedWorkHours = sort([...workHours, ...toArray(futureWorkHours)], numberCompare('weekday', 'asc'));

    const breadcrumbLinks = [
      { text: _('label/clinic_doctors/list/title'), linkTo: '/ajustes/doutores' },
      { text: get(doctor, 'nameWithTitle'), linkTo: `/ajustes/doutores/${get(doctor, 'id')}` },
      { text: _('label/doctors_workhours/title') },
    ];

    const items = [];

    map(orderNedWorkHours, (workHour) => {
      if (!workHour) return;
      items.push(
        <tr className="hourDetailContainer">
          <td className="detailDay"><FormattedText text={workHour.weekday} type="weekday" /></td>
          <td className="detailBegin">{ workHour.start }</td>
          <td className="detailEnd">{ workHour.end }</td>
          <td>
            {workHour.isFuture && <Spinner text={_('label/add_progress')} />}
            {!workHour.isFuture && isDeleting(workHour.id) && <Spinner text={_('label/deleting', 'global')} />}
            {!workHour.isFuture && !isDeleting(workHour.id) &&
              <Button
                className="deleteButton"
                text={_('action/delete', 'global')}
                onClick={this.handleDelete(workHour.id)}
                loadingMessage={_('label/deleting', 'global')}
              />
            }
          </td>
        </tr>,
      );
    });

    const pageContent = (
      <React.Fragment>
        <p>Horários de Atendimento</p>
        { orderNedWorkHours.length > 0 && (
          <section className="tableTrackHolder hours">
            <div className="tableTrack">
              <table className="table">
                <thead>
                  <tr>
                    <th>{_('label/day', 'global')}</th>
                    <th>{_('label/start', 'global')}</th>
                    <th>{_('label/end', 'global')}</th>
                    <th>&nbsp;</th>
                  </tr>
                </thead>
                <tbody>
                  {items}
                </tbody>
              </table>
            </div>
          </section>
        )}
        <WorkHoursForm onSave={onAdd} mobile />
      </React.Fragment>
    );

    return (
      <PlatformSwitch
        inject
        desktop={() => (
          <div id="doctorsPage" className="appContent settingsPage">
            <aside className="sidebar">
              <SettingsMenu user={user} onLogout={onLogout} permissions={this.props.permissions} />
            </aside>
            <section className="mainSection">
              <div className="centerContent">
                <Breadcrumb items={breadcrumbLinks} />
                {pageContent}
              </div>
            </section>
          </div>
        )}
        mobile={() => (
          <React.Fragment>
            <AppMobileHeader title="Horários de Atendimento" />
            <div id="doctorsPage" className="appContent settingsPage">
              <section className="mainSection">
                <div className="centerContent">{pageContent}</div>
              </section>
            </div>
            <AppMobileFooter />
          </React.Fragment>
        )}
      />
    );
  }

  handleDelete = (id: number) => () => this.props.onDelete(id);
}

export default withTranslation()(WorkHours);
