// @flow
import React from 'react';
import { connect } from 'react-redux';
import { get } from 'lodash';
import { SecretaryForm as SecretaryFormView } from '@views';
import type { User, SecretariesState, BundledPermissions } from '@types';
import { router } from '@router';
import { clinicActions } from '@context/clinics';
import { logout } from '@context/user';
import { mapBundledPermission } from '@helpers/connect';


type Props = {
  user: User,
  id: number,
  secretaries: SecretariesState,
  dispatch: Function,
  permissions: BundledPermissions,
  isDeleting: boolean,
};

class SecretaryForm extends React.Component<Props> {
  render() {
    const {
      user, secretaries, id, isDeleting,
    } = this.props;
    const isEdit = id !== 'nova';
    return (
      <SecretaryFormView
        isEdit={isEdit}
        user={user}
        formData={isEdit ? get(secretaries, ['data', String(id)], {}) : {}}
        isLoading={get(secretaries, 'isLoading', false) || get(secretaries, 'isSaving', false)}
        isDeleting={isDeleting}
        onCancel={this.handleCancel}
        onSave={this.handleSave}
        onLogout={this.handleLogout}
        permissions={this.props.permissions}
        onDelete={this.handleDelete}
      />
    );
  }

  handleLogout = () => this.props.dispatch(logout());

  handleCancel = () => {
    this.props.dispatch(router.pushPath('secretariesList'));
  };

  handleDelete = () => {
    const { dispatch, id } = this.props;
    dispatch(clinicActions.deleteSecretary(id, () => {
      dispatch(router.pushPath('secretariesList'));
    }));
  };

  handleSave = (data: Object) => {
    const { dispatch, id } = this.props;
    if (id !== 'nova') {
      dispatch(clinicActions.updateSecretary(id, data, () => {
        this.props.dispatch(router.pushPath('secretariesList'));
      }));
    } else {
      dispatch(clinicActions.createSecretary(data, (ok) => {
        if (ok) {
          this.props.dispatch(router.pushPath('secretariesList'));
        }
      }));
    }
  };
}

const mapStateToProps = (state, ownProps) => ({
  user: state.context.user,
  secretaries: get(state, ['context', 'secretaries'], {}),
  id: get(ownProps, ['match', 'params', 'id']),
  permissions: mapBundledPermission(state),
  isDeleting: get(state, ['secretaries', 'isDeleting'], false),
});

export default connect<any, any, any, any, any, any>(
  mapStateToProps,
)(SecretaryForm);
