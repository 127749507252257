// @flow
import React from 'react';
import { connect } from 'react-redux';
import { get } from 'lodash';
import { logout } from '@context/user';
import { mapBundledPermission } from '@helpers/connect';
import { DocumentsTemplateForm as DocumentsTemplateFormView } from '../components/views';
import { documentsTemplateActions } from '../redux/modules/context/documentsTemplates';
import type { Dispatch, DocumentsTemplate, DocumentsTemplateInput, User, BundledPermissions } from '../types';

type Props = {
  documentsTemplate: DocumentsTemplate,
  dispatch: Function,
  user: User,
  permissions: BundledPermissions,
};

class DocumentsTemplateForm extends React.Component<Props> {
  render() {
    const { documentsTemplate, user } = this.props;
    return (
      <DocumentsTemplateFormView
        documentsTemplate={documentsTemplate}
        onCreateDocumentsTemplate={this.handleCreateDocumentsTemplate}
        onUpdateDocumentsTemplate={this.handleUpdateDocumentsTemplate}
        onDeleteDocumentsTemplate={this.handleDeleteDocumentsTemplate}
        user={user}
        onLogout={this.handleLogout}
        permissions={this.props.permissions}
      />
    );
  }
  handleLogout = () => this.props.dispatch(logout());
  handleCreateDocumentsTemplate = (documentsTemplate: DocumentsTemplateInput) => {
    this.props.dispatch(documentsTemplateActions.createDocumentsTemplate(documentsTemplate));
  };

  handleUpdateDocumentsTemplate = (id: number, documentsTemplate: DocumentsTemplateInput) => {
    this.props.dispatch(documentsTemplateActions.updateDocumentsTemplate(id, documentsTemplate));
  };

  handleDeleteDocumentsTemplate = (id: number) => {
    this.props.dispatch(documentsTemplateActions.deleteDocumentsTemplate(id));
  };
}

const mapStateToProps = (state, ownProps: any) => ({
  documentsTemplate: get(state.context, ['documentsTemplates', 'data', ownProps.match.params.id], {}),
  user: state.context.user,
  permissions: mapBundledPermission(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({ dispatch });

export default connect<any, any, any, any, any, any>(
  mapStateToProps,
  mapDispatchToProps,
)(DocumentsTemplateForm);
