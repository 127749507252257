// @flow
import React from 'react';
import { connect } from 'react-redux';
import { get } from 'lodash';
import { PrescriptionExamsFormModal } from '../../components/views';
import { actions } from '../../redux/modules/context/modals';
import { examsPrescriptionActions } from '../../redux/modules/context/examsPrescriptions';
import { examsPrescriptionsTemplateActions } from '../../redux/modules/context/examsPrescriptionsTemplates';
import type {
  Dispatch, ExamsPrescriptionInput, ExamsPrescription,
  ExamsPrescriptionsTemplatesState,
} from '../../types';

type Props = {
  recordId: number,
  patientName: string,
  examsPrescription: ExamsPrescription,
  examsPrescriptionsTemplates: ExamsPrescriptionsTemplatesState,
  dispatch: Function,
  isSaving: boolean,
  isDeleting: boolean,
};

class PrescriptionExamsFormModalContainer extends React.Component<Props> {
  componentWillMount() {
    this.props.dispatch(examsPrescriptionsTemplateActions.getExamsPrescriptionsTemplates());
  }
  render() {
    const {
      recordId, patientName, examsPrescription,
      examsPrescriptionsTemplates, isSaving, isDeleting,
    } = this.props;

    return (
      <PrescriptionExamsFormModal
        recordId={recordId}
        patientName={patientName}
        examsPrescription={examsPrescription}
        examsPrescriptionsTemplates={examsPrescriptionsTemplates}
        onAddNewExamsPrescription={this.handleCreateExamsPrescription}
        onAddNewAndPrintExamsPrescription={this.handleCreateAndPrintExamsPrescription}
        onUpdateExamsPrescription={this.handleUpdateExamsPrescription}
        onUpdateAndPrintExamsPrescription={this.handleUpdateAndPrintExamsPrescription}
        onDeleteExamsPrescription={this.handleDeleteExamsPrescription}
        onClose={this.closeModal}
        isSaving={isSaving}
        isDeleting={isDeleting}
      />
    );
  }

  closeModal = () => {
    this.props.dispatch(actions.closeActiveModal());
  };

  handleCreateExamsPrescription = (recordId: number, examsPrescription: ExamsPrescriptionInput) => {
    this.props.dispatch(examsPrescriptionActions.createExamsPrescription(recordId, examsPrescription, this.closeModalOnSuccess));
  };

  handleCreateAndPrintExamsPrescription = (recordId: number, examsPrescription: ExamsPrescriptionInput) => {
    this.props.dispatch(examsPrescriptionActions.createAndShowExamsPrescriptionPDF(recordId, examsPrescription, this.closeModalOnSuccess));
  };

  handleUpdateExamsPrescription = (recordId: number, prescriptionId: number, examsPrescription: ExamsPrescriptionInput) => {
    this.props.dispatch(examsPrescriptionActions.updateExamsPrescription(recordId, prescriptionId, examsPrescription, this.closeModalOnSuccess));
  };

  handleUpdateAndPrintExamsPrescription = (recordId: number, prescriptionId: number, examsPrescription: ExamsPrescriptionInput) => {
    this.props.dispatch(examsPrescriptionActions.updateAndShowExamsPrescriptionPDF(recordId, prescriptionId, examsPrescription, this.closeModalOnSuccess));
  };

  handleDeleteExamsPrescription = (recordId: number, prescriptionId: number) => {
    this.props.dispatch(examsPrescriptionActions.deleteExamsPrescription(recordId, prescriptionId, this.closeModalOnSuccess));
  };

  closeModalOnSuccess = (success: boolean) => {
    if (success) {
      this.closeModal();
    }
  };
}

const mapDispatchToProps = (dispatch: Dispatch) => ({ dispatch });

const mapStateToProps = (state) => ({
  recordId: state.context.modals.data.recordId,
  patientName: state.context.modals.data.patientName,
  examsPrescription: get(state.context, ['examsPrescriptions', 'data', state.context.modals.data.prescriptionId], {}),
  isDeleting: get(state.context, ['examsPrescriptions', 'isDeleting'], false),
  isSaving: get(state.context, ['examsPrescriptions', 'isSaving'], false),
  examsPrescriptionsTemplates: get(state.context, ['examsPrescriptionsTemplates'], {}),
});


export default connect<any, any, any, any, any, any>(
  mapStateToProps,
  mapDispatchToProps,
)(PrescriptionExamsFormModalContainer);
