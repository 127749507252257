// @flow
import { get, filter } from 'lodash';
import moment from 'moment';
import { rndId, isPresent, reportError } from '@helpers/helpers';
import { countryLanguages } from '@helpers/constants';
import { Api } from '../../../helpers';
import ClinicService from '../../../services/ClinicService';
import { router } from '../../../routes/router';
import { logout as userLogout } from '../auth';
import type
{
  Action,
  ContextState,
  User,
  UserInput,
  Dispatch,
  WorkHours,
} from '../../../types';

type UserResponseType = {
  user: User,
};


// ------------------------------------
// Constants
// ------------------------------------

const USER_LOADING = 'user.USER_LOADING';
const USER_SET_LOADING = 'user.USER_SET_LOADING';

const USER_UPDATING = 'user.USER_UPDATING';
const USER_UPDATE_SUCCESS = 'user.USER_UPDATE_SUCCESS';
const USER_UPDATE_ERROR = 'user.USER_UPDATE_ERROR';

const USER_DELETING = 'user.USER_DELETING';
const USER_DELETE_SUCCESS = 'user.USER_DELETE_SUCCESS';
const USER_DELETE_ERROR = 'user.USER_DELETE_ERROR';

const USER_LOADED = 'user.USER_LOADED';
const USER_LOADING_FAILED = 'user.USER_LOADING_FAILED';

const USER_UPDATE_DOCTOR_PRIVACY_LOADING = 'users.USER_UPDATE_DOCTOR_PRIVACY_LOADING';
const USER_UPDATE_DOCTOR_PRIVACY_LOADED = 'users.USER_UPDATE_DOCTOR_PRIVACY_LOADED';
const USER_UPDATE_DOCTOR_PRIVACY_FAILED = 'users.USER_UPDATE_DOCTOR_PRIVACY_FAILED';

const USER_CREATE_DOCTOR_WORKHOURS_LOADING = 'users.USER_CREATE_DOCTOR_WORKHOURS_LOADING';
const USER_CREATE_DOCTOR_WORKHOURS_LOADED = 'users.USER_CREATE_DOCTOR_WORKHOURS_LOADED';
const USER_CREATE_DOCTOR_WORKHOURS_FAILED = 'users.USER_CREATE_DOCTOR_WORKHOURS_FAILED';

const USER_DELETE_DOCTOR_WORKHOURS_LOADING = 'users.USER_DELETE_DOCTOR_WORKHOURS_LOADING';
const USER_DELETE_DOCTOR_WORKHOURS_LOADED = 'users.USER_DELETE_DOCTOR_WORKHOURS_LOADED';
const USER_DELETE_DOCTOR_WORKHOURS_FAILED = 'users.USER_DELETE_DOCTOR_WORKHOURS_FAILED';

const USER_RECOVER_PASSWORD_LOADING = 'users.USER_RECOVER_PASSWORD_LOADING';
const USER_RECOVER_PASSWORD_LOADED = 'users.USER_RECOVER_PASSWORD_LOADED';
const USER_RECOVER_PASSWORD_FAILED = 'users.USER_RECOVER_PASSWORD_FAILED';

const USER_RECOVER_PASSWORD_RESET = 'users.USER_RECOVER_PASSWORD_RESET';

const USER_REQUEST_BACKUP_LOADING = 'users.USER_REQUEST_BACKUP_LOADING';
const USER_REQUEST_BACKUP_LOADED = 'users.USER_REQUEST_BACKUP_LOADED';
const USER_REQUEST_BACKUP_FAILED = 'users.USER_REQUEST_BACKUP_FAILED';
const USER_REQUEST_BACKUP_RESET = 'users.USER_REQUEST_BACKUP_RESET';

const USER_CREATE_CLINIC_LOADING = 'users.USER_CREATE_CLINIC_LOADING';
const USER_CREATE_CLINIC_LOADED = 'users.USER_CREATE_CLINIC_LOADED';
const USER_CREATE_CLINIC_FAILED = 'users.USER_CREATE_CLINIC_FAILED';

const USER_UPDATE_PASSWORD_LOADING = 'users.USER_UPDATE_PASSWORD_LOADING';
const USER_UPDATE_PASSWORD_LOADED = 'users.USER_UPDATE_PASSWORD_LOADED';
const USER_UPDATE_PASSWORD_FAILED = 'users.USER_UPDATE_PASSWORD_FAILED';
const USER_UPDATE_PASSWORD_RESET = 'users.USER_UPDATE_PASSWORD_RESET';

const USER_INITIALIZE_WORKHOURS_LOAD_STATE = 'users.USER_INITIALIZE_WORKHOURS_LOAD_STATE';

const USER_MEMED_SIGNUP_LOADING = 'users.USER_MEMED_SIGNUP_LOADING';
const USER_MEMED_SIGNUP_LOADED = 'users.USER_MEMED_SIGNUP_LOADED';
const USER_MEMED_SIGNUP_FAILED = 'users.USER_MEMED_SIGNUP_FAILED';
const USER_MEMED_SIGNUP_CLEAR_ERROR = 'users.USER_MEMED_SIGNUP_CLEAR_ERROR';


export const logout = (reload: boolean = false) => (
  (dispatch: Dispatch) => {
    dispatch(userLogout(reload));
  }
);

export const resetRecoverState = () => (
  (dispatch: Dispatch) => {
    dispatch({
      type: USER_RECOVER_PASSWORD_RESET,
    });
  }
);
export const clearMemedSignupError = () => (
  (dispatch: Dispatch) => {
    dispatch({
      type: USER_MEMED_SIGNUP_CLEAR_ERROR,
    });
  }
);
export const resetRequestBackup = () => (
  (dispatch: Dispatch) => {
    dispatch({
      type: USER_REQUEST_BACKUP_RESET,
    });
  }
);

export const updateUserPasswordReset = () => (
  (dispatch: Dispatch) => {
    dispatch({
      type: USER_UPDATE_PASSWORD_RESET,
    });
  }
);

export const createClinic = () => (
  (dispatch: Dispatch) => {
    dispatch({ type: USER_CREATE_CLINIC_LOADING });
    Api.post(router.getApiRoute('createClinic', { }), {
      success: (response) => {
        dispatch({
          type: USER_CREATE_CLINIC_LOADED,
          activeClinic: get(response.data, 'clinic'),
          user: get(response.data, 'user'),
        });
        dispatch(router.pushPath('patientsList'));
      },
      error: (error) => {
        dispatch({
          type: USER_CREATE_CLINIC_FAILED, error: { message: error.message },
        });
        dispatch(reportError(error));
      },
    });
  }
);
export const requestBackup = (backupType: 'patients' | 'full', cb?: Function = (() => {})) => (
  (dispatch: Dispatch) => {
    dispatch({ type: USER_REQUEST_BACKUP_LOADING });
    Api.post(router.getApiRoute('requestBackup', { }), {
      data: {
        backup_type: backupType,
      },
      success: () => {
        dispatch({
          type: USER_REQUEST_BACKUP_LOADED,
        });
        if (cb) {
          cb(true);
        }
      },
      error: (error) => {
        dispatch({
          type: USER_REQUEST_BACKUP_FAILED, error: { message: error.message },
        });
        if (cb) {
          cb(false);
        }
        dispatch(reportError(error));
      },
    });
  }
);

export const updateUserPassword = (password: string, oldPassword: string) => (
  (dispatch: Dispatch) => {
    dispatch({ type: USER_UPDATE_PASSWORD_LOADING });
    Api.patch(router.getApiRoute('updateUserPassword', { }), {
      data: {
        user: {
          password,
          oldPassword,
        },
      },
      success: () => {
        dispatch({
          type: USER_UPDATE_PASSWORD_LOADED,
        });
      },
      error: (error) => {
        dispatch({
          type: USER_UPDATE_PASSWORD_FAILED, error: { message: error.message },
        });
        dispatch(reportError(error));
      },
    });
  }
);

export const recoverPassword = (email: string) => (
  (dispatch: Dispatch) => {
    dispatch({ type: USER_RECOVER_PASSWORD_LOADING });
    Api.post(router.getApiRoute('recoverPassword', { }), {
      data: {
        reset: {
          email,
        },
      },
      success: () => {
        dispatch({
          type: USER_RECOVER_PASSWORD_LOADED,
        });
      },
      error: (error) => {
        dispatch({
          type: USER_RECOVER_PASSWORD_FAILED, error: { message: error.message },
        });
        dispatch(reportError(error));
      },
    });
  }
);


export const memedSignup = (addressState: string, birthDate: string, cpf: string, gender: string, councilDoc: string, cb: Function) => (
  (dispatch: Dispatch) => {
    dispatch({ type: USER_MEMED_SIGNUP_LOADING });
    Api.post(router.getApiRoute('memedSignup', { }), {
      data: {
        memed: {
          addressState, birthDate, cpf, gender, councilDoc,
        },
      },
      success: (response) => {
        dispatch({
          type: USER_MEMED_SIGNUP_LOADED,
          memedToken: get(response, ['data', 'memedToken']),
          memedError: get(response, ['data', 'memedError']),
        });
        cb(!isPresent(get(response, ['data', 'memedError'])));
      },
      error: (error) => {
        dispatch({
          type: USER_MEMED_SIGNUP_FAILED, error: { message: error.message },
        });
        dispatch(reportError(error));
        cb(false);
      },
    });
  }
);

export const createDoctorWorkHours = (clinicId: number, doctorId: number, workHours: WorkHours) => (
  (dispatch: Dispatch) => {
    const futureId = rndId();
    dispatch({ type: USER_CREATE_DOCTOR_WORKHOURS_LOADING, doctorId, future: { id: futureId, ...workHours, isFuture: true } });
    Api.post(router.getApiRoute('createDoctorWorkHours', { doctorId }), {
      query: { clinicId },
      data: {
        work_hour: {
          startTime: workHours.start,
          endTime: workHours.end,
          weekday: workHours.weekday,
        },
      },
      success: (response) => {
        const respData = get(response, ['data', 'work_hour']);
        dispatch({
          type: USER_CREATE_DOCTOR_WORKHOURS_LOADED,
          workHours: {
            id: respData.id,
            start: respData.startTime,
            end: respData.endTime,
            weekday: respData.weekday,
          },
          futureId,
          doctorId,
        });
      },
      error: (error) => {
        dispatch({
          type: USER_CREATE_DOCTOR_WORKHOURS_FAILED, doctorId, futureId, error: { message: error.message },
        });
        dispatch(reportError(error));
      },
    });
  }
);


export const deleteDoctorWorkHours = (clinicId: number, doctorId: number, id: number) => (
  (dispatch: Dispatch) => {
    dispatch({ type: USER_DELETE_DOCTOR_WORKHOURS_LOADING, doctorId, deleteId: id });
    Api.destroy(router.getApiRoute('deleteDoctorWorkHours', { id, doctorId }), {
      query: { clinicId },
      success: () => {
        dispatch({
          type: USER_DELETE_DOCTOR_WORKHOURS_LOADED,
          deleteId: id,
          doctorId,
        });
      },
      error: (error) => {
        dispatch({
          type: USER_DELETE_DOCTOR_WORKHOURS_FAILED, doctorId, deleteId: id, error: { message: error.message },
        });
        dispatch(reportError(error));
      },
    });
  }
);

export const updateDoctorPrivacy = (clinicId: number, doctorId: number, defaultVisibilityLevel: number) => (
  (dispatch: Dispatch) => {
    if (!isPresent(defaultVisibilityLevel)) {
      dispatch({ type: USER_UPDATE_DOCTOR_PRIVACY_FAILED, error: { message: 'Invalid option' } });
      return;
    }
    dispatch({ type: USER_UPDATE_DOCTOR_PRIVACY_LOADING });
    Api.patch(router.getApiRoute('updateDoctorPrivacy', { id: doctorId }), {
      query: { clinicId },
      data: {
        doctor: {
          defaultVisibilityLevel,
        },
      },
      success: () => {
        dispatch({
          type: USER_UPDATE_DOCTOR_PRIVACY_LOADED,
          doctorDefaultVisibilityLevel: defaultVisibilityLevel,
        });
      },
      error: (error) => {
        dispatch({ type: USER_UPDATE_DOCTOR_PRIVACY_FAILED, error: { message: error.message } });
        dispatch(reportError(error));
      },
    });
  }
);

export const getUser = () => ({
  types: [USER_LOADING, USER_LOADED, USER_LOADING_FAILED],
  payload: {},

  shouldCall: (state: ContextState) => {
    const authenticated = isPresent(get(state, ['auth', 'token']));

    return Boolean(authenticated);
  },
  call: () => Api.get(router.getApiRoute('getUser', null)),
  parseResponse: (response: UserResponseType) => {
    let countryCode = get(response, ['user', 'countryCode']);
    countryCode = countryCode || window.countryCode;
    countryCode = countryCode || 'BR';

    if (localStorage.getItem('debugLng') !== 'true') {
      window.i18n.changeLanguage(get(countryLanguages, countryCode, 'pt-BR'));
    }
    return {
      user: response.user,
    };
  },
});

export const updateUser = (user: UserInput) => (dispatch: Dispatch) => {
  dispatch({ type: USER_UPDATING });
  Api.patch(router.getApiRoute('updateUser', null), {
    data: { user },
    success: (response) => {
      dispatch({
        type: USER_UPDATE_SUCCESS,
        user: get(response, 'user'),
      });
    },
    error: (error) => {
      dispatch({ type: USER_UPDATE_ERROR, error: { message: error.message } });
      dispatch(reportError(error));
    },
  });
};

export const deleteMyAccount = (callback?: Function) => (dispatch: Dispatch) => {
  dispatch({ type: USER_DELETING });
  Api.destroy(router.getApiRoute('deleteMyAccount', null), {
    success: (response) => {
      dispatch({
        type: USER_DELETE_SUCCESS,
        user: get(response, 'user'),
      });

      if (callback) callback();
    },
    error: (error) => {
      dispatch({ type: USER_DELETE_ERROR, error: { message: error.message } });
      dispatch(reportError(error));
    },
  });
};

export const setUserLoading = (isLoading: boolean) => (dispatch: Dispatch) => {
  dispatch({ type: USER_SET_LOADING, isLoading });
};

export const resetWorkhoursFormState = () => (dispatch:Dispatch) => {
  dispatch({ type: USER_INITIALIZE_WORKHOURS_LOAD_STATE });
};

export const redirectToDashboard = (to: string, token: string) => {
  const dashboardUrl = router.getApiRoute('dashboard', null).path;
  const url = `${dashboardUrl}/access?access_token=${token}&redirect_to=${dashboardUrl}${to}`;

  window.open(url, '_blank');
};

export const redirectToDoutoreAI = (token: string) => {
  const dashboardUrl = router.getApiRoute('dashboard', null).path;
  const doutoreAI = router.getApiRoute('doutoreAI', null).path;
  const url = `${dashboardUrl}/access?access_token=${token}&redirect_to=${doutoreAI}`;

  window.open(url, '_blank');
};

export const directUpdateUser = (user: any) => ({ type: USER_LOADED, user });

export const userActions = {
  getUser,
  updateUser,
  deleteMyAccount,
  setUserLoading,
  updateDoctorPrivacy,
  createDoctorWorkHours,
  deleteDoctorWorkHours,
  resetWorkhoursFormState,
  logout,
  createClinic,
  directUpdateUser,
  redirectToDashboard,
};

export const actionTypes = {
  USER_LOADING,
  USER_LOADED,
  USER_LOADING_FAILED,
  USER_UPDATING,
  USER_UPDATE_SUCCESS,
  USER_UPDATE_ERROR,
  USER_UPDATE_DOCTOR_PRIVACY_LOADING,
  USER_UPDATE_DOCTOR_PRIVACY_LOADED,
  USER_UPDATE_DOCTOR_PRIVACY_FAILED,
  USER_INITIALIZE_WORKHOURS_LOAD_STATE,
  USER_SET_LOADING,
};

// ------------------------------------
// Reducer
// userUpdating
// ------------------------------------
export default function userReducer (state: ContextState, action: Action): ContextState {
  switch (action.type) {
    case USER_LOADING: return state
      .setIn(['user', 'userLoading'], true)
      .set('userLoading', true);

    case USER_LOADING_FAILED: return state
      .setIn(['user', 'userLoading'], false)
      .set('userLoading', false);

    case USER_LOADED:
      const user = {
        ...action.user,
        lastFetch: moment().toISOString(),
      };

      return state
        .set('userLoading', false)
        .set('user', user)
        .setIn(['user', 'userLoading'], false)
        .setIn(['doctors', 'savingWorkHours'], false)
        .setIn(['doctors', 'futureWorkHours'], {})
        .setIn(['doctors', 'deletingWorkHours'], {})
        .set('userUpdating', false);

    case USER_UPDATING:
      return state.set('userUpdating', true);

    case USER_UPDATE_SUCCESS:
      return state
        .set('userUpdating', false)
        .set('user', {
          ...state.user,
          ...action.user,
        });

    case USER_UPDATE_ERROR:
      return state.set('userUpdating', false);

    case USER_DELETING:
      return state.set('userDeleting', true);

    case USER_DELETE_SUCCESS:
      return state
        .set('userDeleting', false);

    case USER_DELETE_ERROR:
      return state.set('userDeleting', false);

    case USER_MEMED_SIGNUP_CLEAR_ERROR:
      return state
        .setIn(['user', 'advancedData', 'memedSignupLoading'], false)
        .setIn(['user', 'advancedData', 'memedError'], '');

    case USER_MEMED_SIGNUP_LOADING:
      return state
        .setIn(['user', 'advancedData', 'memedSignupLoading'], true)
        .setIn(['user', 'advancedData', 'memedError'], '');

    case USER_MEMED_SIGNUP_LOADED:
      return state
        .setIn(['user', 'advancedData', 'memedSignupLoading'], false)
        .setIn(['user', 'advancedData', 'memedToken'], get(action, 'memedToken'))
        .setIn(['user', 'advancedData', 'memedError'], get(action, 'memedError'));

    case USER_MEMED_SIGNUP_FAILED:
      return state.setIn(['user', 'advancedData', 'memedSignupLoading'], false);

    case USER_SET_LOADING: return state.set('userUpdating', action.isLoading);

    case USER_UPDATE_DOCTOR_PRIVACY_LOADING:
      return state
        .setIn(['user', 'isUpdatingPrivacy'], true);

    case USER_UPDATE_DOCTOR_PRIVACY_LOADED:
      return state
        .setIn(['user', 'advancedData', 'doctorDefaultVisibilityLevel'], action.doctorDefaultVisibilityLevel)
        .setIn(['user', 'isUpdatingPrivacy'], false);

    case USER_UPDATE_DOCTOR_PRIVACY_FAILED:
      return state
        .setIn(['user', 'isUpdatingPrivacy'], false);


      // create work hour
    case USER_CREATE_DOCTOR_WORKHOURS_LOADING:
      return state
        .setIn(['doctors', 'futureWorkHours', String(action.doctorId), String(action.future.id)], action.future)
        .setIn(['doctors', 'savingWorkHours', String(action.doctorId)], true);

    case USER_CREATE_DOCTOR_WORKHOURS_LOADED:
      const theDoctor = get(state, ['doctors', 'data', String(action.doctorId)], {});
      return state
        .setIn(['doctors', 'data', String(action.doctorId), 'workHours'], [action.workHours, ...get(theDoctor, 'workHours')])
        .setIn(['doctors', 'futureWorkHours', String(action.doctorId), String(action.futureId)], null)
        .setIn(['doctors', 'savingWorkHours', String(action.doctorId), String(action.futureId)], false);

    case USER_CREATE_DOCTOR_WORKHOURS_FAILED:
      return state
        .setIn(['doctors', 'futureWorkHours', String(action.doctorId), String(action.futureId)], null)
        .setIn(['doctors', 'savingWorkHours', String(action.doctorId), String(action.futureId)], false);


      // delete work hour


    case USER_DELETE_DOCTOR_WORKHOURS_LOADING:
      const delid = get(action, 'deleteId', null);
      return state
        .setIn(['doctors', 'deletingWorkHours', String(action.doctorId), delid], true);


    case USER_DELETE_DOCTOR_WORKHOURS_LOADED:
      const deleid = get(action, 'deleteId', null);
      const preWorkHours = get(state, ['doctors', 'data', String(action.doctorId), 'workHours']);
      return state
        .setIn(['doctors', 'data', String(action.doctorId), 'workHours'], filter(preWorkHours, item => String(item.id) !== String(deleid)))
        .setIn(['doctors', 'deletingWorkHours', String(action.doctorId), String(deleid)], false);


    case USER_DELETE_DOCTOR_WORKHOURS_FAILED:
      const deletid = get(action, 'deleteId', null);
      return state
        .setIn(['doctors', 'deletingWorkHours', String(action.doctorId), String(deletid)], false);


    case USER_INITIALIZE_WORKHOURS_LOAD_STATE:
      return state
        .setIn(['doctors', 'savingWorkHours'], false)
        .setIn(['doctors', 'futureWorkHours'], {})
        .setIn(['doctors', 'deletingWorkHours'], {});

      // password

    case USER_RECOVER_PASSWORD_LOADING:
      return state
        .setIn(['user', 'isRecoveringPassword'], true)
        .setIn(['user', 'recoverSuccess'], false)
        .setIn(['user', 'recoverPasswordError'], false);


    case USER_RECOVER_PASSWORD_LOADED:
      return state
        .setIn(['user', 'isRecoveringPassword'], false)
        .setIn(['user', 'recoverSuccess'], true)
        .setIn(['user', 'recoverPasswordError'], false);


    case USER_RECOVER_PASSWORD_FAILED:
      return state
        .setIn(['user', 'isRecoveringPassword'], false)
        .setIn(['user', 'recoverSuccess'], false)
        .setIn(['user', 'recoverPasswordError'], true);

    case USER_RECOVER_PASSWORD_RESET:
      return state
        .setIn(['user', 'isRecoveringPassword'], false)
        .setIn(['user', 'recoverSuccess'], false)
        .setIn(['user', 'recoverPasswordError'], false);


    case USER_UPDATE_PASSWORD_LOADING:
      return state
        .setIn(['user', 'isSavingPassword'], true)
        .setIn(['user', 'changePasswordError'], false)
        .setIn(['user', 'savingPasswordSuccess'], false);


    case USER_UPDATE_PASSWORD_LOADED:
      return state
        .setIn(['user', 'isSavingPassword'], false)
        .setIn(['user', 'changePasswordError'], false)
        .setIn(['user', 'savingPasswordSuccess'], true);

    case USER_UPDATE_PASSWORD_FAILED:
      return state
        .setIn(['user', 'isSavingPassword'], false)
        .setIn(['user', 'changePasswordError'], true)
        .setIn(['user', 'savingPasswordSuccess'], false);

    case USER_UPDATE_PASSWORD_RESET:
      return state
        .setIn(['user', 'isSavingPassword'], false)
        .setIn(['user', 'changePasswordError'], false)
        .setIn(['user', 'savingPasswordSuccess'], false);


    case USER_REQUEST_BACKUP_LOADING:
      return state
        .setIn(['user', 'isRequestingBackup'], true)
        .setIn(['user', 'backupRequestsuccess'], false)
        .setIn(['subscription', 'errors'], null);

    case USER_REQUEST_BACKUP_LOADED:
      return state
        .setIn(['user', 'backupRequestsuccess'], true)
        .setIn(['user', 'isRequestingBackup'], false);

    case USER_REQUEST_BACKUP_FAILED:
      return state
        .setIn(['user', 'isRequestingBackup'], false)
        .setIn(['user', 'backupRequestsuccess'], false)
        .setIn(['user', 'errors'], [action.error.message]);

    case USER_REQUEST_BACKUP_RESET:
      return state
        .setIn(['user', 'backupRequestsuccess'], false)
        .setIn(['user', 'isRequestingBackup'], false);


    case USER_CREATE_CLINIC_LOADING:
      return state
        .setIn(['user', 'isCreatingClinic'], true);

    case USER_CREATE_CLINIC_LOADED:
      const normalizedNew = ClinicService.normalizeActiveClinic(get(action, 'activeClinic', {}));

      const userData = {
        ...action.user,
        lastFetch: moment().toISOString(),
      };

      return state
        .set('userLoading', false)
        .setIn(['doctors', 'savingWorkHours'], false)
        .setIn(['doctors', 'futureWorkHours'], {})
        .setIn(['doctors', 'deletingWorkHours'], {})
        .set('userUpdating', false)
        .set('user', userData)
        .setIn(['user', 'isCreatingClinic'], false)
        .setIn(['clinics', 'isLoading'], false)
        .setIn(['secretaries', 'isLoading'], false)
        .setIn(['secretaries', 'isSaving'], false)
        .setIn(['clinics', 'errors'], null)
        .setIn(['clinics', 'activeClinic'], normalizedNew.activeClinic)
        .setIn(['clinics', 'data'], null)// ???
        .setIn(['clinics', 'lastFetch'], moment().toISOString())
        .setIn(['doctors', 'isLoading'], false)
        .setIn(['doctors', 'data'], normalizedNew.doctors)
        .setIn(['appointments'], {})
        .setIn(['secretaries', 'data'], normalizedNew.secretaries)
        .setIn(['secretaries', 'managerIds'], normalizedNew.secretaryManagerIds)
        .setIn(['secretaries', 'nonManagerIds'], normalizedNew.secretaryNoManagerIds)
        .setIn(['doctors', 'lastFetch'], moment().toISOString()); // TODO: should be moved into DoctorsReducer

    case USER_CREATE_CLINIC_FAILED:
      return state
        .setIn(['user', 'isCreatingClinic'], false)
        .setIn(['user', 'errors'], [action.error.message]);
    default:
      return state;
  }
}
