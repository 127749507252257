// @flow
import React from 'react';
import moment from 'moment';
import { get, first, toArray, size, toNumber } from 'lodash';
import { sort, numberCompare, createNsTranslator, handleReportPeriods } from '@helpers/helpers';
import { Loading, FormattedText, Icon, Button, Table } from '@elements';
import { ReportsMenu, ReportPage, PlatformSwitch, AppMobileHeader, EmptyState, ReportsFilters, ConditionalFeature, BlockedReport, AppMobileFooter } from '@blocks';
import type { DoctorsState, ReceiptsState, FormElementData } from '@types';
import { withStorage } from '@hocs';
import { withTranslation } from 'react-i18next';

type Props = {
  onGetReceipts: (startDate: string, endDate: string, doctorId: number | null) => void,
  defaultDoctorId: number,
  doctors: DoctorsState,
  receipts: ReceiptsState,
  componentStorage: Object,
  onItemClick: Function,
  onDownlodReport: (startDate: string, endDate: string, doctorId: number | null) => void,
  isManager: boolean,
  isFeatureEnabled: boolean,
  isReportBlocked: boolean,
  reportsSelectDoctor: boolean,
  t: Function,
};

type State = {
  period: string,
  doctor: string,
  startDate: string,
  endDate: string,
  year: number,
  month: number,
  showMobileFilters: boolean,
};

class ReportsReceipts extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    const state = {
      period: 'today',
      doctor: this.props.defaultDoctorId || get(first(toArray(get(this.props.doctors, 'data'))), 'id'),
      startDate: moment().startOf('month').format('YYYY-MM-DD'),
      endDate: moment().endOf('month').format('YYYY-MM-DD'),
      month: moment().month() + 1,
      year: moment().year(),
      showMobileFilters: false,
      ...props.componentStorage.get('filters', {}),
    };

    this.state = {
      ...state,
      month: parseInt(state.month, 10) > 0 ? state.month : +1 + 1,
    };
  }

  componentDidMount() {
    if (this.props.isFeatureEnabled) {
      this.handleRequest();
    }
  }

  render() {
    const {
      doctors, receipts, isManager, isFeatureEnabled, reportsSelectDoctor, isReportBlocked,
    } = this.props;
    const {
      period, doctor, startDate, endDate, year, month, showMobileFilters,
    } = this.state;

    const _ = createNsTranslator('reports', this.props.t);

    if (isReportBlocked) {
      return (
        <BlockedReport
          backLinkTo="/relatorios"
          title={_('receipts/title')}
        />
      );
    }


    const momentStartDate = moment(startDate, 'YYYY-MM-DD');
    const momentEndDate = moment(endDate, 'YYYY-MM-DD');

    const isLoading = get(receipts, 'isLoading', false);
    const isLoadingReport = get(receipts, 'isLoadingReport', false);

    // Guard against potential null objects
    if (this.props.isFeatureEnabled && !receipts) return false;

    const receiptsData = sort(get(receipts, ['data'], {}), numberCompare('number', 'desc'));


    const isAllEmpty = size(receiptsData) === 0;
    const renderEmptyState = () => (
      <EmptyState
        onReportFilter={this.toggleMobileFilter}
        title={_('receipts/empty_hint')}
        hint={_('receipts/empty_hint')}
      />);

    const content = !isAllEmpty && (
      <Table
        itemKey="id"
        columns={[
          {
            dataIndex: 'number',
            content: '#',
          },
          {
            dataIndex: 'patientName',
            content: _('label/patient', 'global'),
          },
          {
            dataIndex: 'doctorName',
            content: _('label/doctor', 'doctor'),
            hidden: this.state.doctor !== 'all',
          },
          {
            dataIndex: 'date',
            content: _('label/date', 'global'),
            onRenderItem: (receipt) => <FormattedText type="date" text={receipt.date} />,
          },
          {
            dataIndex: 'price',
            content: _('label/value', 'global'),
            onRenderItem: (receipt) => <FormattedText type="currency" text={receipt.price} />,
          },
          {
            dataIndex: 'id',
            content: <React.Fragment>&nbsp;</React.Fragment>,
            className: 'actionCell',
            desktopOnly: true,
            onRenderItem: () => <span className="linkAction">{_('action/view_patient', 'global')}</span>,
          },
        ]}
        fullTable
        onItemClick={this.onItemClick}
        items={receiptsData}
        mobileClickLabel={_('action/view_patient', 'global')}
      />
    );

    const filters = (
      <ReportsFilters
        reportsSelectDoctor={reportsSelectDoctor}
        isManager={isManager}
        doctors={doctors}
        isLoading={isLoading}
        onChangeFilter={this.handleFilterChange}
        doctor={doctor}
        period={period}
        year={year}
        month={month}
        startDate={momentStartDate.format('YYYY-MM-DD')}
        endDate={momentEndDate.format('YYYY-MM-DD')}
      />
    );

    return (
      <div id="reportsReceiptsPage" className="appContent reportsPage">
        <PlatformSwitch
          inject
          className="platformSwitcher"
          desktop={() => (
            <React.Fragment>
              <ReportsMenu />

              <section className="mainSection">
                <ConditionalFeature enabled={isFeatureEnabled} transparent>
                  <div className="centerContent">
                    <div className="toolbar">
                      <h1>{_('receipts/receipts')}</h1>
                      <Button disable={isAllEmpty || isLoadingReport} onClick={this.handleDownloadReport}>
                        <Icon iconName="download" className="icon" size={18} />
                        <span>{_('action/download_report', 'global')}</span>
                      </Button>
                    </div>
                    <div className="desktopCard">
                      <section className="tableTrackHolder">
                        {filters}
                      </section>
                      <div className="toolbar">
                        <h2>{_('receipts/title')}</h2>
                      </div>
                      {isLoading && <Loading />}
                      {!isLoading && isAllEmpty && renderEmptyState()}
                      {!isLoading && !isAllEmpty && content}
                    </div>
                  </div>
                </ConditionalFeature>
              </section>
            </React.Fragment>
          )}
          mobile={() => (
            <React.Fragment>
              <AppMobileHeader title={_('receipts/title')} backLinkTo="/relatorios">
                <div className="headerActions">
                  {!isAllEmpty && !isLoadingReport && (
                    <Icon iconName="download" className="icon" onClick={this.handleDownloadReport} />
                  )}
                  {(!isAllEmpty && isLoadingReport) && (
                    <Icon iconName="download" className="icon" />
                  )}
                  <Icon iconName="filter" className="icon" onClick={this.toggleMobileFilter} />
                  {/* <Icon iconName="refresh" className="icon" onClick={this.handleRefreshClick} /> */}
                </div>
              </AppMobileHeader>
              <ReportPage
                id="reportsReceiptsPage"
                filters={() => showMobileFilters && (
                  <ReportsFilters
                    isMobile
                    onCloseMobileModal={this.toggleMobileFilter}
                    reportsSelectDoctor={reportsSelectDoctor}
                    isManager={isManager}
                    doctors={doctors}
                    isLoading={isLoading}
                    onChangeFilter={this.handleFilterChange}
                    doctor={doctor}
                    period={period}
                    year={year}
                    month={month}
                    startDate={momentStartDate.format('YYYY-MM-DD')}
                    endDate={momentEndDate.format('YYYY-MM-DD')}
                  />
                )}
                isLoading={isLoading}
                emptyState={() => isAllEmpty && renderEmptyState()}
                noPadding
              >
                <ReportPage.Table title={_('receipts/title')}>
                  {content}
                </ReportPage.Table>
              </ReportPage>
              <AppMobileFooter />
            </React.Fragment>
          )}
        />
      </div>
    );
  }

  handleFilterChange = (data: FormElementData) => {
    if (data.name === 'month') {
      let m = parseInt(get(data, 'value', ''), 10);
      m = m > 0 ? m : moment().month() + 1;
      m = m <= 12 ? m : moment().month() + 1;
      this.setState({ [data.name]: m }, this.handleRequest);
    } else {
      this.setState({ [data.name]: get(data, 'value', '') }, this.handleRequest);
    }
  };

  handleMomentFilterChange = (data: FormElementData) => {
    this.setState({ [data.name]: moment(get(data, 'value', '')).format('YYYY-MM-DD') }, this.handleRequest);
  };

  handleRequest = () => {
    const {
      doctor, period, startDate, endDate, month, year,
    } = this.state;

    this.props.componentStorage.set('filters', { ...this.state, showMobileFilters: false });

    const doctorFilter = doctor !== 'all' ? toNumber(doctor) : null;

    const reportPeriod = handleReportPeriods(startDate, endDate, period, month, year);


    this.props.onGetReceipts(
      reportPeriod.startDate,
      reportPeriod.endDate,
      doctorFilter,
    );
  };

  handleDownloadReport = () => {
    const {
      doctor, period, startDate, endDate, month, year,
    } = this.state;

    this.props.componentStorage.set('filters', { ...this.state, showMobileFilters: false });

    const doctorFilter = doctor !== 'all' ? toNumber(doctor) : null;

    const reportPeriod = handleReportPeriods(startDate, endDate, period, month, year);


    this.props.onDownlodReport(
      reportPeriod.startDate,
      reportPeriod.endDate,
      doctorFilter,
    );
  };

  toggleMobileFilter = () => this.setState({ showMobileFilters: !this.state.showMobileFilters });

  handleRefreshClick = () => {
    this.handleRequest();
  };

  onItemClick = (item: Object) => this.props.onItemClick(item.patientId);
}

export default withTranslation()(withStorage('reportsFiltersV3', 'reportsReceipts')(ReportsReceipts));
