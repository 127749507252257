// @flow
import React from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { PlatformSwitch, AppMobileHeader, AppMobileFooter } from '@blocks';
import { Button } from '@elements';
import { useTranslation } from 'react-i18next';
import { createNsTranslator, UserNotifications } from '@helpers/helpers';
import { userActions } from '@context/user';
import { openToast } from '@context/toasts';
import { router } from '@router';
import './DeleteMyAccount.scss';

const DeleteMyAccount = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const isUserDeleting = useSelector(state => state.context.userDeleting);

  const dispatch = useDispatch();

  const _ = createNsTranslator('deleteMyAccount', t);

  const handleClickDeleteAccount = async () => {
    const isDeleteAccountConfirmed = await UserNotifications
      .confirmI18n('alert/confirm_delete_account', 'warning', '#FFA433', true);

    if (!isDeleteAccountConfirmed) return;

    dispatch(userActions.deleteMyAccount(() => {
      dispatch(openToast({
        type: 'inProgress',
        text: _('toast/request_sent'),
        close: true,
        timeout: 4000,
      }));
    }));
  };

  return (
    <PlatformSwitch
      inject
      desktop={() => (
        <>
          {history.replace('/')}
        </>
      )}
      mobile={() => (
        <React.Fragment>
          <AppMobileHeader
            title={_('label/delete_my_account/title')}
            backLinkTo={router.getRoutePath('support')}
          />

          <div id="deleteMyAccountPage" className="appContent settingsPage">
            <section className="mainSection">
              <div className="centerContent">
                <div id="subscriptionWarnings">
                  <div className="warning">
                    <h1>{_('label/delete_my_account/title')}</h1>
                    <hr />
                    <p>{_('label/delete_my_account/text_line_1')}</p>
                    <br />
                    <p>{_('label/delete_my_account/text_line_2')}</p>

                    <div className="actions">
                      <Button
                        className="primary"
                        text={_('button/delete_my_account')}
                        onClick={handleClickDeleteAccount}
                        isLoading={isUserDeleting}
                        loadingMessage={_('button/deleting_my_account')}
                      />

                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
          <AppMobileFooter />
        </React.Fragment>
      )}
    />


  );
};


export default DeleteMyAccount;
