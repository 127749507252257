// @flow
import React from 'react';
import { get, isEqual } from 'lodash';
import type { Doctors, Patient, PatientsState, Clinic, Services, Doctor } from '@types';
import {
  PatientSelectModal,
  PatientInfoModal,
  NewPatientForm,
  RoomSelectModal,
  DoctorSelectModal,
  RecurrencyModal,
  ServicesSelectModal,
  StatusModal,
  WhatsAppConfirmationModal,
} from './index';

type Props = {
    activeMenuItem: any,
    previousModal: any,
    activeModal: any,
    modalPayload: any,
    auxData: Object,
    statusData: Object,
    doctors: Doctors,
    clinic: Clinic,
    formChange: Function,
    appointmentPatients: Object,
    onGetPatients: Function,
    patient: ?Patient,
    isNew: boolean,
    patients: PatientsState,
    handleFormChange: Function,
    onDeleteRecurrentEvents: Function,
    formData: any,
    servicesTemplates: Services,
    setModal: Function,
    handleSetServiceRequest: Function,
    handleCreatePatient: Function,
    fetchServices: Function,
    currentServices: Array<Object>,
    onChangeStatus: Function,
    isLockedForLoading: boolean,
    appointmentDate: string,
    isBlockedAppointment: boolean,
    doctor: Doctor,
    _: Function,
};

export default class SecondaryModalWrapper extends React.Component<Props> {
  shouldComponentUpdate(nextProps: Props) {
    return !isEqual(this.props, nextProps);
  }
  render() {
    const {
      activeMenuItem, activeModal, auxData, doctors, formChange,
      appointmentPatients, onGetPatients, patient, isNew, patients,
      previousModal, modalPayload, clinic, handleFormChange, formData,
      onDeleteRecurrentEvents, servicesTemplates, setModal, statusData,
      handleSetServiceRequest, handleCreatePatient, fetchServices, currentServices,
      onChangeStatus, isLockedForLoading, appointmentDate, isBlockedAppointment, doctor,
      _,
    } = this.props;

    let secondaryModal = null;

    if (activeMenuItem === 'patient') {
      if (activeModal === 'PatientSelectModal') {
        secondaryModal = (
          <PatientSelectModal
            doctors={get(doctors, 'data', {})}
            appointmentPatients={appointmentPatients}
            formChange={formChange}
            setModal={setModal}
            onGetPatients={onGetPatients}
            onSetServicesRequest={handleSetServiceRequest}
            _={_}
          />
        );
      } else if (activeModal === 'PatientInfoModal') {
        secondaryModal = (
          <PatientInfoModal
            selectedPatient={patient}
            setModal={setModal}
            formChange={formChange}
            doctors={doctors}
            isNew={isNew}
            patientLoading={auxData.patientLoading}
            clinic={clinic}
            appointmentId={formData.id}
            _={_}
          />
        );
      } else if (activeModal === 'PatientNewModal') {
        secondaryModal = (
          <NewPatientForm
            isSavingPatient={get(patients, 'isSaving', false)}
            previousModal={previousModal}
            searchValue={get(modalPayload, 'searchValue')}
            setModal={setModal}
            onCreatePatient={handleCreatePatient}
            _={_}
          />
        );
      }
    } else if (activeModal === 'RoomSelectModal' || activeMenuItem === 'room') {
      secondaryModal = (
        <RoomSelectModal
          clinic={clinic}
          setModal={setModal}
          onChange={handleFormChange}
          selectedRoom={get(formData, 'room')}
          isLockedForLoading={isLockedForLoading}
          _={_}
        />
      );
    } else if (activeModal === 'DoctorSelectModal' || activeMenuItem === 'doctor') {
      secondaryModal = (
        <DoctorSelectModal
          doctors={doctors}
          setModal={setModal}
          onChange={handleFormChange}
          selectedDoctor={get(formData, 'doctorId')}
          onSetServicesRequest={handleSetServiceRequest}
          _={_}
        />
      );
    } else if (activeModal === 'RecurrencyModal' || activeMenuItem === 'recurringSchedule') {
      secondaryModal = (
        <RecurrencyModal
          doctors={doctors}
          setModal={setModal}
          onChange={handleFormChange}
          isNew={isNew}
          recurrent={get(formData, 'recurrent', {})}
          onDeleteRecurrentEvents={onDeleteRecurrentEvents}
          appointmentDate={appointmentDate}
          _={_}
        />
      );
    } else if (activeModal === 'ServicesSelectModal' || activeMenuItem === 'contractedServices') {
      secondaryModal = (
        <ServicesSelectModal
          isNew={isNew}
          onChange={handleFormChange}
          servicesTemplates={servicesTemplates}
          serviceIds={get(formData, 'serviceIds', [])}
          setModal={setModal}
          fetchServices={fetchServices}
          recomendedService={auxData.recomendedService}
          currentServices={currentServices}
          isLockedForLoading={isLockedForLoading}
          doctor={doctor}
          patient={patient}
          _={_}
        />
      );
    } else if ((activeModal === 'StatusModal' || activeMenuItem === 'status') && !isBlockedAppointment) {
      secondaryModal = (
        <StatusModal
          onChangeStatus={onChangeStatus}
          setModal={setModal}
          fullAppointment={{ ...formData, ...statusData }}
          _={_}
          // isLockedForLoading={isLockedForLoading}
        />
      );
    } else if ((activeModal === 'WhatsAppConfirmationModal' || activeMenuItem === 'whatsAppConfirmation')) {
      secondaryModal = (
        <WhatsAppConfirmationModal
          setModal={setModal}
          _={_}
          appointmentId={formData.id}
        />
      );
    }

    return secondaryModal;
  }
}
