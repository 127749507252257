import { debounce, forEach, set, isEmpty } from 'lodash';
import immutable from 'seamless-immutable';
// import { memorySizeOf } from '@helpers/helpers';
import jSum from 'jsum';
import { Log } from '../../helpers';
import persistBlacklist from '../persist.blacklist';

const LS_CHECKSUM_KEY = 'doutore-app-db-checksum';

export const checkStorageHash = (state: any) => jSum.digest(state, 'SHA256', 'hex') === localStorage.getItem(LS_CHECKSUM_KEY) || isEmpty(localStorage.getItem(LS_CHECKSUM_KEY));

// const debugLog = debounce((state) => {
//   // eslint-disable-next-line
//   console.info(`STATE_SIZE: ${memorySizeOf(state)}`);
// }, 100);

// Prevent multiple successive calls to the saving into LS by
// "debouncing". It's saved 1s after the last state update.
const saveToLocalStorage = debounce(store => {
  Log.debug('DUMP: Dumping to local store...\n----------------------------------------------------------------------------');
  // const t0 = window.performance.now();

  const state = immutable(store.getState()).asMutable({ deep: true });

  // (new Promise((resolve => {
  //   debugLog(state);
  //   resolve();
  // }))).then(() => {});

  forEach(persistBlacklist, item => {
    set(state, item.path, item.value);
  });

  localStorage.setItem(LS_CHECKSUM_KEY, jSum.digest(state, 'SHA256', 'hex'));

  window.idb.storeState(state)
    .then(() => {
      Log.debug('DUMP: Dumped to local store in');
    });
}, 1000);

export default (store: Object) => () => {
  if (typeof window === 'undefined') {
    // server-side rendering
    Log.error('Cannot dump state on the server!');
    return;
  }
  // saveToLocalStorage(store.getState());

  saveToLocalStorage(store);
};
