// @flow
import { map } from 'lodash';
import type { Patients } from '../types';

// expects array of patient objects: [{id: 1, ...}, {id: 2, ...}, ...]
// => returns patients object: {1: {...}, 2: {...}, ...}
function normalizePatients (inputPatients: Patients) {
  const patients = {};

  map(inputPatients, (patient: any) => {
    patients[patient.id] = patient;
  });

  return {
    patients,
  };
}

export default {
  normalizePatients,
};
