// @flow
// $FlowFixMe
import React, { SyntheticKeyboardEvent } from 'react';
import autosize from 'autosize';
import { FormInput } from '@blocks';
import { replaceHtmlEntitesToText, wrapValue } from '@helpers/helpers';

type Props = {
  label?: string,
  name: string,
  className?: string,
  placeholder?: string,
  required?: boolean,
  value?: ?string,
  defaultValue?: ?string,
  error?: string,
  onChange: Function,
  onBlur?: Function,
  readOnly?: boolean,
  autoGrow?: boolean,
  uncontrolled?: boolean,
  disabled?: boolean,
};


export default class TextArea extends React.Component<Props> {
  static defaultProps = {
    autoGrow: true,
  };

  componentDidMount() {
    if (this.props.autoGrow) {
      autosize(this.selfRef);
    }
  }

  componentWillReceiveProps(nextProps: Props) {
    if (nextProps.autoGrow !== this.props.autoGrow) {
      if (nextProps.autoGrow) {
        autosize(this.selfRef);
      } else {
        autosize.destroy(this.selfRef);
      }
    }
  }

  render() {
    const {
      label, className, placeholder, disabled,
      required, error, name, readOnly, value, defaultValue, uncontrolled,
    } = this.props;

    // sanitizedValue is stripped of all HTML tags and <br>s are replaced with new-lines
    const sanitizedValue = replaceHtmlEntitesToText(value || '');
    return (
      <FormInput label={label} className={className} required={required} error={error} >
        <textarea
          ref={ref => { this.selfRef = ref; }}
          name={name}
          placeholder={placeholder}
          onChange={this.onChange.bind(this)}
          onBlur={this.onBlur.bind(this)}
          {...wrapValue(defaultValue, sanitizedValue, uncontrolled)}
          readOnly={readOnly}
          disabled={disabled}
          autoCorrect="false"
          autoComplete="false"
          autoCapitalize="false"
          spellCheck={false}
        />
      </FormInput>
    );
  }

  selfRef: any = null ;

  onChange = (event: SyntheticKeyboardEvent) => {
    const { name, onChange, readOnly } = this.props;
    if (readOnly) return;
    const { value } = event.target;
    const data = { name, value };
    onChange(data);
  };

  onBlur = (event: SyntheticKeyboardEvent) => {
    const { name, onBlur } = this.props;
    const { value } = event.target;
    const data = { name, value };
    if (onBlur) {
      onBlur(data);
    }
  };
}
