import React, { useCallback, useMemo, useRef } from 'react';
import './TranslationHelper.scss';
import esTranslations from '../../../../i18n/es';
import ptBrTranslations from '../../../../i18n/pt-br';
import { get, map } from 'lodash';
import { isPresent } from '@helpers/helpers';


type Props = {
  openView: (viewName: string | null) => void;
}

const TranslationHelper = ({ openView }: Props) => {
  const scrollRef = useRef(null);
  const backToMenu = useCallback(() => {
    openView(null);
  }, [openView]);

  const getTranslation = useCallback((lang: 'es' | 'pt', ns: string, key: string) => {
    if (lang === 'es') {
      return get(esTranslations, [ns, key]);
    } else {
      return get(ptBrTranslations, [ns, key]);
    }
  }, []);

  const nameSpaces = useMemo(() => {
    const nss = {};

    Object.keys(ptBrTranslations)
      .map((namespace) => ({ namespace, keys: Object.keys(ptBrTranslations[namespace]) }))
      .forEach((ns) => {
        nss[ns.namespace] = ns.keys;
      });

    return nss;
  }, []);

  const onClickNs = useCallback((nsName: string) => () => {
    const el = document.getElementById(`ns-${nsName}`);
    if (el && scrollRef.current) {
      scrollRef.current.scrollTo(0, el.offsetTop - 120);
    }
  }, []);

  const content = useMemo(() => map(nameSpaces, (ns, nsName) => (
    <div className="translationNamespace" key={nsName}>
      <h3>
        <a name={nsName} id={`ns-${nsName}`}>
          <span role="img" aria-label="emoji">👉</span>{nsName}
        </a>
      </h3>
      {map(ns, (keyName) => (
        <div className="translationentry" key={`nsContent-${nsName}-${keyName}`}>
          <div className={`keyName ${!isPresent(getTranslation('es', nsName, keyName)) && 'isEmpty'}`}>{keyName}</div>
          <div className="keyEntry">
            <label>PT-BR</label>
            <input type="text" value={getTranslation('pt', nsName, keyName)} disabled/>
          </div>
          <div className="keyEntry">
            <label>ES</label>
            <input type="text" value={getTranslation('es', nsName, keyName)} disabled/>
          </div>
        </div>
      ))}
    </div>
  )), [nameSpaces, getTranslation]);

  return (
    <div id="devToolsTranslationHelper">
      <div className="menu">
        <div className="title">Translation Helpers</div>
        <button onClick={backToMenu}><span role="img" aria-label="back">⬅️ Return</span></button>
      </div>
      <div className="ns-list">
        {Object.keys(ptBrTranslations).map((ns) => (
          <div key={ns} className="nsItem" onClick={onClickNs(ns)}>{ns}</div>
        ))}
      </div>
      <div style={{ overflowY: 'scroll' }} ref={scrollRef}>
        {content}
      </div>
    </div>
  );
};


export default TranslationHelper;
