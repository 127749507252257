// @flow
import React from 'react';
import { compose } from 'redux';
import { get } from 'lodash';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { ServiceFormModal } from '../../components/views';
import { serviceActions } from '../../redux/modules/context/services';
import type { Dispatch, ServiceInput, Service } from '../../types';
import { withConfirmOnClose } from '../../hocs';

type Props = {
  dispatch: Function,
  id: number,
  patientId: number,
  doctorId: number,
  service: Service,
  setConfirmation: Function,
  closeModal: Function,
  isNewTemplate: boolean,
  tplName: string,
};

class ServiceFormModalContainer extends React.Component<Props> {
  render() {
    const {
      service, setConfirmation, isNewTemplate, tplName,
    } = this.props;
    return (
      <ServiceFormModal
        onClose={this.closeModal}
        onDelete={this.handleDelete}
        onSave={this.handleSave}
        onSetConfirmOnClose={setConfirmation}
        formData={isNewTemplate ? { name: tplName } : service}
        isNewTemplate={isNewTemplate}
      />
    );
  }

  closeModal = this.props.closeModal;

  handleSave = (data: ServiceInput) => {
    const {
      dispatch, patientId, id, isNewTemplate, doctorId,
    } = this.props;

    if (isNewTemplate) {
      dispatch(serviceActions.createTemplateWithService(patientId, doctorId, data));
    } else {
      dispatch(serviceActions.updateService(patientId, id, data));
    }
    this.closeModal(true);
  };

  handleDelete = () => {
    const { dispatch, patientId, id } = this.props;
    dispatch(serviceActions.deleteService(patientId, id));
    this.closeModal(true);
  };
}

const mapStateToProps = (state, ownProps) => {
  const id = parseInt(get(state, ['context', 'modals', 'data', 'id']), 10);
  return {
    patientId: parseInt(get(ownProps, ['match', 'params', 'id']), 10),
    service: get(state, ['context', 'services', 'data', String(id)], null),
    id,
    isNewTemplate: get(state, ['context', 'modals', 'data', 'isNewTemplate'], false),
    tplName: get(state, ['context', 'modals', 'data', 'name'], 'false'),
    doctorId: get(state, ['context', 'modals', 'data', 'doctorId'], null),
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({ dispatch });

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withConfirmOnClose(),
)(ServiceFormModalContainer);
