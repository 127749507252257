// @flow
import React from 'react';
import { Button } from '@elements';
import { withTranslation } from 'react-i18next';
import { createNsTranslator } from '@helpers/helpers';
import './GetStarted.scss';

class GetStarted extends React.Component<any> {
  render() {
    const _ = createNsTranslator('onboarding', this.props.t);
    return (
      <div>
        <div className="pageGetStarted">
          <h1>{_('label/lets_start')}</h1>
          <Button text={_('action/create_a_patient')} linkTo="/pacientes/lista" />
          <Button text={_('action/create_a_appointment')} linkTo="/agenda" />
          <Button text={_('action/view_patient_example')} linkTo="/pacientes/lista" />
          <Button text={_('action/explore_app')} linkTo="/pacientes/lista" />
        </div>
      </div>
    );
  }
}


export default withTranslation()(GetStarted);
