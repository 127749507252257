// @flow
import { map, omit } from 'lodash';
import type { Clinic, SimpleClinic } from '../types';

// expects a clinic object with an array of doctors: [{id: 1, ...}, {id: 2, ...}, ...]
// => returns the given clinic object, but with 'doctors' replaced with 'doctorIds'
// and returns doctors object: {1: {...}, 2: {...}, ...}
function normalizeActiveClinic (_inputClinic: Clinic) {
  const inputClinic = _inputClinic || {};
  const doctors = {};
  inputClinic.doctorIds = [];
  const secretaryManagerIds = [];
  const secretaryNoManagerIds = [];


  map(inputClinic.doctors, (doctor) => {
    doctors[doctor.id] = doctor;
    inputClinic.doctorIds.push(doctor.id);
  });

  const secretaries = {};
  inputClinic.secretariesIds = [];

  map(inputClinic.secretaries, (secretary: any) => {
    secretaries[secretary.id] = secretary;
    inputClinic.secretariesIds.push(secretary.id);
    if (secretary.manager) {
      secretaryManagerIds.push(secretary.id);
    } else {
      secretaryNoManagerIds.push(secretary.id);
    }
  });

  return {
    activeClinic: omit(inputClinic, ['doctors', 'secretaries']),
    doctors,
    secretaries,
    secretaryManagerIds,
    secretaryNoManagerIds,
  };
}

// expects a an array of multiclinics: [{id: 1, ...}, {id: 2, ...}, ...]
// => returns multiclinics object: {1: {...}, 2: {...}, ...}
function normalizeMulticlinics (inputMulticlinics: Array<SimpleClinic>) {
  const multiclinics = {};

  map(inputMulticlinics, (clinic) => {
    multiclinics[clinic.id] = clinic;
  });

  return multiclinics;
}

export default {
  normalizeActiveClinic,
  normalizeMulticlinics,
};
