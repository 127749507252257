// @flow
import React from 'react';
import moment from 'moment';
import { get, first, toArray, toNumber } from 'lodash';
import { ReportsMenu, ReportPage, PlatformSwitch, AppMobileHeader, ReportsFilters, ConditionalFeature, BlockedReport, AppMobileFooter } from '@blocks';
import { Loading, Icon, Table, Button } from '@elements';
import type { DoctorsState, FormElementData, ReportsAppointmentsData } from '@types';
import { withStorage } from '@hocs';
import { withTranslation } from 'react-i18next';
import { createNsTranslator, handleReportPeriods } from '@helpers/helpers';
import './ReportsAppointments.scss';

type Props = {
  onGetReport: (startDate: string, endDate: string, doctorId: number | null) => void,
  doctors: DoctorsState,
  data: ReportsAppointmentsData,
  isLoading: boolean,
  componentStorage: Object,
  defaultDoctorId: number,
  isManager: boolean,
  isFeatureEnabled: boolean,
  reportsSelectDoctor: boolean,
  isReportBlocked: boolean,
  t: Function,
  onDownloadReport: (startDate: string, endDate: string, doctorId: number | null) => void,
};

type State = {
  period: string,
  doctor: string,
  year: number,
  month: number,
  showMobileFilters: boolean,
  startDate: string,
  endDate: string,
};

type ReportResultItem = {
  label: string | number,
  value: number,
  created: number
};


const reportResultItem = ({ label, value, created }: ReportResultItem) => ({
  label,
  value,
  percent: (created > 0 ? ((value / created) * 100).toFixed(0) : 0) + '%',
});

class ReportsAppointments extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    const state = {
      period: 'today',
      doctor: this.props.defaultDoctorId || get(first(toArray(get(this.props.doctors, 'data'))), 'id'),
      month: moment().month(),
      year: moment().year(),
      showMobileFilters: false,
      startDate: moment().startOf('month').format('YYYY-MM-DD'),
      endDate: moment().endOf('month').format('YYYY-MM-DD'),
      ...props.componentStorage.get('filters', {}),
    };

    this.state = {
      ...state,
      month: parseInt(state.month, 10) > 0 ? state.month : moment().month() + 1,
    };
  }

  componentDidMount() {
    if (this.props.isFeatureEnabled) {
      this.handleRequest();
    }
  }

  render() {
    const {
      period, month, year, doctor, showMobileFilters, startDate, endDate,
    } = this.state;

    const {
      doctors, data, isLoading, isManager, isFeatureEnabled, reportsSelectDoctor, isReportBlocked,
    } = this.props;

    const _ = createNsTranslator('reports', this.props.t);


    if (isReportBlocked) {
      return (
        <BlockedReport
          backLinkTo="/relatorios"
          title="Consultas"
        />
      );
    }


    const canceledByDoctor = parseInt(get(data, 'canceledByDoctor', 0), 10);
    const canceledByPatient = parseInt(get(data, 'canceledByPatient', 0), 10);
    const canceledBySms = parseInt(get(data, 'canceledBySms', 0), 10);
    const confirmed = parseInt(get(data, 'confirmed', 0), 10);
    const created = parseInt(get(data, 'created', 0), 10);
    const finished = parseInt(get(data, 'finished', 0), 10);
    const patientMissed = parseInt(get(data, 'patientMissed', 0), 10);

    const reportItems = [
      {
        label: _('label/scheduled', 'global'),
        value: created,
        percent: '-',
      },
      reportResultItem({
        label: _('appointments/confirmed'),
        value: confirmed,
        created,
      }),
      reportResultItem({
        label: _('appointments/attended_patients'),
        value: finished,
        created,
      }),
      reportResultItem({
        label: _('appointments/patient_missed'),
        value: patientMissed,
        created,
      }),
      reportResultItem({
        label: _('appointments/canceled_by_patient'),
        value: canceledByPatient,
        created,
      }),
      reportResultItem({
        label: _('appointments/canceled_by_sms'),
        value: canceledBySms,
        created,
      }),
      reportResultItem({
        label: _('appointments/canceled_by_doctor'),
        value: canceledByDoctor,
        created,
      }),
    ];

    const tableRender = () => (
      <Table
        itemKey="id"
        columns={[
          {
            dataIndex: 'label',
            content: _('label/status', 'global'),
          },
          {
            dataIndex: 'value',
            content: _('label/ammount', 'global'),
          },
          {
            dataIndex: 'percent',
            content: '%',
          },
        ]}
        fullTable
        items={reportItems}
        mobileClickLabel={_('action/view_patient', 'global')}
      />
    );

    const content = (
      <React.Fragment>
        {isLoading && <Loading />}
        {!isLoading && tableRender()}
      </React.Fragment>
    );


    return (
      <PlatformSwitch
        inject
        desktop={() => (
          <div id="reportsAppointmentsPage" className="appContent reportsPage">
            <ReportsMenu />
            <section className="mainSection">
              <ConditionalFeature enabled={isFeatureEnabled} transparent>
                <div className="centerContent">

                  <div className="toolbar">
                    <h1>{_('appointments/title')}</h1>
                    <Button disable={isLoading} onClick={this.handleDownloadReport}>
                      <Icon iconName="download" className="icon" size={18} />
                      <span>{_('action/download_report', 'global')}</span>
                    </Button>
                  </div>


                  <div className="desktopCard">
                    <ReportsFilters
                      reportsSelectDoctor={reportsSelectDoctor}
                      isManager={isManager}
                      doctors={doctors}
                      isLoading={isLoading}
                      onChangeFilter={this.handleFilterChange}
                      doctor={doctor}
                      period={period}
                      year={year}
                      month={month}
                      startDate={startDate}
                      endDate={endDate}
                    />
                    <div className="toolbar">
                      <h2>Relatório de Consultas</h2>
                    </div>
                    {content}
                  </div>
                </div>
              </ConditionalFeature>
            </section>
          </div>
        )}
        mobile={() => (
          <React.Fragment>
            <AppMobileHeader title="Consultas" backLinkTo="/relatorios">
              <div className="headerActions">
                <Icon iconName="filter" className="icon" onClick={this.toggleMobileFilter} />
                {/* <Icon iconName="refresh" className="icon" onClick={this.handleRefreshClick} /> */}
              </div>
            </AppMobileHeader>
            <ReportPage
              id="reportsAppointmentsPage"
              isFeatureEnabled={isFeatureEnabled}
              filters={() => showMobileFilters && (
                <ReportsFilters
                  isMobile
                  onCloseMobileModal={this.toggleMobileFilter}
                  reportsSelectDoctor={reportsSelectDoctor}
                  isManager={isManager}
                  doctors={doctors}
                  isLoading={isLoading}
                  onChangeFilter={this.handleFilterChange}
                  doctor={doctor}
                  period={period}
                  year={year}
                  month={month}
                  startDate={startDate}
                  endDate={endDate}
                />)}
              noPadding
            >
              <ReportPage.Table title={isLoading ? '' : _('appointments/title')}>
                {content}
              </ReportPage.Table>
            </ReportPage>
            <AppMobileFooter />
          </React.Fragment>
        )}
      />
    );
  }

  handleFilterChange = (data: FormElementData) => {
    if (data.name === 'month') {
      let m = parseInt(get(data, 'value', ''), 10);
      m = m > 0 ? m : moment().month() + 1;
      m = m <= 12 ? m : moment().month() + 1;
      this.setState({ [data.name]: m }, this.handleRequest);
    } else {
      this.setState({ [data.name]: get(data, 'value', '') }, this.handleRequest);
    }
  };

  handleMomentFilterChange = (data: FormElementData) => {
    this.setState({ [data.name]: moment(get(data, 'value', '')).format('YYYY-MM-DD') }, this.handleRequest);
  };

  handleFilterItemSelect = (data: FormElementData) => this.setState({ period: get(data, 'value', '') }, this.handleRequest);

  handleRequest = () => {
    const { onGetReport } = this.props;
    const {
      doctor, period, month, year, startDate, endDate,
    } = this.state;

    this.props.componentStorage.set('filters', { ...this.state, showMobileFilters: false });

    const doctorFilter = doctor !== 'all' ? toNumber(doctor) : null;

    const reportPeriod = handleReportPeriods(startDate, endDate, period, month, year);

    onGetReport(
      reportPeriod.startDate,
      reportPeriod.endDate,
      doctorFilter,
    );
  };

  toggleMobileFilter = () => this.setState({ showMobileFilters: !this.state.showMobileFilters });

  handleDownloadReport = () => {
    const { onDownloadReport } = this.props;
    const {
      doctor, period, month, year, startDate, endDate,
    } = this.state;

    const doctorFilter = doctor !== 'all' ? toNumber(doctor) : null;

    this.props.componentStorage.set('filters', { ...this.state, showMobileFilters: false });
    const reportPeriod = handleReportPeriods(startDate, endDate, period, month, year);

    onDownloadReport(
      reportPeriod.startDate,
      reportPeriod.endDate,
      doctorFilter,
    );
  };

  handleRefreshClick = () => {
    this.handleRequest();
  };
}

export default withTranslation()(withStorage('reportsFiltersV3', 'reportsAppointments')(ReportsAppointments));
