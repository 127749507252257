// @flow
import React from 'react';
import { connect } from 'react-redux';
import type { Node } from 'react';
import { layoutActions } from '@context/layout';
import type { Dispatch } from '../../../types';
import { windowActions } from '../../../redux/modules/ui/window';

type Props = {
  children: Node,
  dispatch: Function,
};

class Window extends React.Component<Props> {
  componentWillMount() {
    this.updateDimensions();
    this.handleOrientationChange();
  }

  componentDidMount() {
    window.addEventListener('resize', this.updateDimensions);
    window.addEventListener('orientationchange', this.handleOrientationChange);
    if (window.screen && window.screen.orientation && window.screen.orientation.addEventListener) {
      window.screen.orientation.addEventListener('change', this.handleOrientationChange);
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
    window.removeEventListener('orientationchange', this.handleOrientationChange);
    if (window.screen && window.screen.orientation && window.screen.orientation.addEventListener) {
      window.screen.orientation.removeEventListener('change', this.handleOrientationChange);
    }
  }

  render() {
    const { children } = this.props;

    return children;
  }

  updateDimensions = () => {
    this.props.dispatch(windowActions.updateWindowSize(window.innerWidth, window.innerHeight));
    this.props.dispatch(layoutActions.updateWindowSize(window.innerWidth, window.innerHeight));
    this.handleOrientationChange();
  }

  handleOrientationChange = () => {
    if ((window.orientation === 90 || window.orientation === -90)) {
      this.props.dispatch(layoutActions.setDeviceOritentation('landscape'));
    } else {
      this.props.dispatch(layoutActions.setDeviceOritentation('portrait'));
    }
  };
}

const mapDispatchToProps = (dispatch: Dispatch) => ({ dispatch });

export default connect<any, any, any, any, any, any>(
  null,
  mapDispatchToProps,
)(Window);
