// @flow
import React from 'react';
import { get } from 'lodash';
import { connect } from 'react-redux';
import { logout } from '@context/user';
import { mapBundledPermission } from '@helpers/connect';
import { SecretariesList as SecretariesListView } from '../components/views';
import type { User, Secretaries, BundledPermissions } from '../types';

type Props = {
  user: User,
  secretariesData: Secretaries,
  dispatch: Function,
  permissions: BundledPermissions,
};

class SecretariesList extends React.Component<Props> {
  render() {
    const {
      user, secretariesData,
    } = this.props;

    return (
      <SecretariesListView
        user={user}
        onLogout={this.handleLogout}
        permissions={this.props.permissions}
        secretaries={secretariesData}
      />
    );
  }
  handleLogout = () => this.props.dispatch(logout());
}

const mapStateToProps = (state) => ({
  user: state.context.user,
  secretariesData: get(state, ['context', 'secretaries', 'data'], {}),
  managerIds: get(state, ['context', 'secretaries', 'managerIds'], []),
  nonManagerIds: get(state, ['context', 'secretaries', 'nonManagerIds'], []),
  permissions: mapBundledPermission(state),
});

export default connect<any, any, any, any, any, any>(
  mapStateToProps,
)(SecretariesList);
