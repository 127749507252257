// @flow
import React from 'react';
import { get } from 'lodash';
import { Input, Button } from '@elements';
import { withForm } from '@hocs';
import { createValidator } from '@validator';
import { handleSubmitEvent } from '@helpers/helpers';
import './GhostLogin.scss';

type Props = {
    onLogin: Function,
    handleFormChange: Function,
    bindValidation: Function,
    formData: Object,
};
type State = {
  errors : Object,
  valid: boolean,
};

const validator = createValidator()
  .field('id')
  .present('Informe um id')
  .field('password')
  .present('Informe uma senha');

class GhostLogin extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      errors: {},
      valid: true,
    };
  }
  componentWillMount() {
    this.props.bindValidation(this.handleValidation);
  }
  componentDidMount() {
    handleSubmitEvent('#ghostLoginPage', this.handleSubmit);
  }
  render() {
    const { handleFormChange, formData } = this.props;
    const { errors } = this.state;
    return (
      <div className="appContent" id="ghostLoginPage">
        <div className="formContainer" >
          <h2><span role="img" aria-label="ghost">👻</span>Ghost</h2>
          <p>Você provelmente não deveria estar aqui, o mundo dos fantasmas não pertence ao mundo dos vivos !</p>
          <Input
            label="ID"
            name="id"
            onChange={handleFormChange}
            value={get(formData, 'id', '')}
            error={get(errors, 'id')}
          />
          <Input
            label="Senha"
            type="password"
            name="password"
            onChange={handleFormChange}
            value={get(formData, 'password', '')}
            error={get(errors, 'password')}
            autoComplete="current-password"
          />
          <Button
            text="Entrar"
            onClick={this.handleSubmit}
          />
        </div>
      </div>
    );
  }

  handleValidation = () => {
    const result = validator.validate(this.props.formData);
    this.setState({
      errors: result.errors,
      valid: result.valid,
    });
  };

  handleSubmit = () => {
    if (this.state.valid) {
      this.props.onLogin({ user: this.props.formData });
    }
  };
}

export default withForm()(GhostLogin);
