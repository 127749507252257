// @flow

import React, { useMemo, useState, useCallback, useEffect, useRef } from 'react';
import { map, get, slice, debounce } from 'lodash';
import { PDFPreview } from '@elements';
import { useTranslationNs, isPresent } from '@helpers/helpers';
import type{
  Record as RecordType,
  DrugsPrescriptionsState,
  ExamsPrescriptionsState,
  DocumentsState,
  CustomFormsState,
  CustomFormTemplatesState,
  Patient,
  AttachmentsState,
  PlanInclude,
  PermissionTo,
} from '@types';
import RecordElement from './RecordElement';
import { RecordCanceled, RecordBlocked } from './mixins';


type Props = {
    patient: Patient,
    clinicId: number,
    userEmail: string,
    records: Array<RecordType>,
    drugsPrescriptions: DrugsPrescriptionsState,
    examsPrescriptions: ExamsPrescriptionsState,
    documents: DocumentsState,
    customForms: CustomFormsState,
    customFormTemplates: CustomFormTemplatesState,
    newRecords: Array<number>,
    isSaving: boolean,
    isAutoSaving: boolean,
    attachments: AttachmentsState,
    onNewCustomFormClick: Function,
    onEditCustomFormClick: Function,
    onPrintCustomFormClick: Function,
    onNewDocumentClick: Function,
    onEditDocumentClick: Function,
    onPrintDocumentClick: Function,
    onNewPrescriptionDrugsClick: Function,
    onEditPrescriptionDrugsClick: Function,
    onPrintPrescriptionDrugsClick: Function,
    onNewPrescriptionExamsClick: Function,
    onEditPrescriptionExamsClick: Function,
    onPrintPrescriptionExamsClick: Function,
    onDisplayAttachmentFileName: Function,
    onUpdateRecordDate: Function,
    onAutosaveRecord: Function,
    onSaveRecord: Function,
    onChangeVisibilityLevel: Function,
    onDeleteRecord: Function,
    onPrintSingleRecord: Function,
    onPrintAllRecords: Function,
    onSelectFile: Function,
    onDeleteAttachment: Function,
    recordDeletingIds: Object,
    recordsEditModesList: Object,
    updatingIds: Object,
    onSetEditMode: Function,
    onAddMemedId: Function,
    onDeleteMemedId: Function,
    onPrintMemedPrescription: Function,
    onDownloadAttachment: Function,
    onRestoreRecord: Function,
    planInclude: PlanInclude,
    mainSection: any,
    isMemedLoaded: boolean,
    memedToken: string,
    permissions: PermissionTo,
    onDirectShare: (recordId: number, resource: string, resourceId: number, respondWithUrls: (wtt: string | null, mail: string | null) => void) => void,
  };


const ITEMS_PER_PAGE = 10;

const Records = ({
  patient, clinicId, userEmail, attachments, customFormTemplates, customForms, documents, drugsPrescriptions, examsPrescriptions, isAutoSaving, isMemedLoaded, isSaving,
  mainSection, memedToken, newRecords, onAddMemedId, onAutosaveRecord, onChangeVisibilityLevel, onDeleteAttachment, onDeleteMemedId, onDeleteRecord,
  onEditCustomFormClick, onEditDocumentClick, onEditPrescriptionDrugsClick, onEditPrescriptionExamsClick, onNewCustomFormClick, onNewDocumentClick,
  onNewPrescriptionDrugsClick, onNewPrescriptionExamsClick, onPrintAllRecords, onPrintCustomFormClick, onPrintDocumentClick, onPrintMemedPrescription,
  onPrintPrescriptionDrugsClick, onPrintPrescriptionExamsClick, onPrintSingleRecord, onSaveRecord, onSelectFile, onSetEditMode, planInclude, recordDeletingIds,
  records, recordsEditModesList, updatingIds, permissions, onRestoreRecord, onDownloadAttachment, onDisplayAttachmentFileName, onUpdateRecordDate, onDirectShare,
}: Props) => {
  const [currPage, setPage] = useState(1);
  const loadMore = useRef(null);

  useEffect(() => {
    loadMore.current = debounce(() => setPage(p => p + 1), 100);
  }, []);

  const onScroll = useCallback(() => {
    if (mainSection) {
      const scrollPercent = mainSection.scrollTop / (mainSection.scrollHeight - mainSection.clientHeight);
      if (scrollPercent >= 0.97 && loadMore.current) {
        loadMore.current();
      }
    }
  }, [mainSection]);

  useEffect(() => {
    if (mainSection) {
      mainSection.addEventListener('scroll', onScroll);
      mainSection.addEventListener('mousewheel', onScroll);
      mainSection.addEventListener('touchmove', onScroll);
      mainSection.addEventListener('mousemove', onScroll);
    }
    return () => {
      if (mainSection) {
        mainSection.removeEventListener('scroll', onScroll);
        mainSection.removeEventListener('mousewheel', onScroll);
        mainSection.removeEventListener('touchmove', onScroll);
        mainSection.removeEventListener('mousemove', onScroll);
      }
    };
  }, [mainSection, onScroll]);

  const [_] = useTranslationNs('timeline');

  const fRecords = useMemo(() => slice(records, 0, currPage * ITEMS_PER_PAGE), [records, currPage]);

  const [pdfState, setPdf] = useState<any>({ pdfPreviewDoc: null, pdfName: null });

  const onShowPdf = useCallback((pdfPreviewDoc: string, pdfName: string) => () => {
    setPdf({ pdfPreviewDoc, pdfName });
  }, [setPdf]);

  const closePdfDoc = useCallback(() => setPdf({ pdfPreviewDoc: null, pdfName: '' }), [setPdf]);

  const recordElements = useMemo(() => map(fRecords, (record: RecordType) => {
    // Conditional data
    const cdIsDeleting = get(recordDeletingIds, String(record.id), false);
    const cdCanceledRecord = get(record, ['appointmentData', 'canceledAppointment'], false);
    const cdDetailsVisible = get(record, ['detailsVisible'], false);
    const cdDeleted = isPresent(get(record, ['deletedAt'], ''));
    const cdEditMode = get(recordsEditModesList, String(record.id), false);


    if (cdCanceledRecord || cdDeleted) {
      return (<RecordCanceled
        key={`record-item-view-${record.id}`}
        record={record}
        isDeleted={cdDeleted}
        onRestoreRecord={onRestoreRecord}
      />);
    }

    if (!cdDetailsVisible) {
      return (<RecordBlocked
        key={`record-item-view-${record.id}`}
        record={record}
        _={_}
      />);
    }

    return (
      <React.Fragment key={`record-element-frag-view-${record.id}`}>
        <RecordElement
          onDirectShare={onDirectShare}
          onDownloadAttachment={onDownloadAttachment}
          isEdit={cdEditMode}
          record={record}
          key={`record-element-view-${record.id}`}
          attachments={attachments}
          customFormTemplates={customFormTemplates}
          customForms={customForms}
          documents={documents}
          drugsPrescriptions={drugsPrescriptions}
          examsPrescriptions={examsPrescriptions}
          mainSection={mainSection}
          memedToken={memedToken}
          onChangeVisibilityLevel={onChangeVisibilityLevel}
          onDeleteAttachment={onDeleteAttachment}
          onDeleteRecord={onDeleteRecord}
          onNewCustomFormClick={onNewCustomFormClick}
          onNewDocumentClick={onNewDocumentClick}
          onNewPrescriptionDrugsClick={onNewPrescriptionDrugsClick}
          onNewPrescriptionExamsClick={onNewPrescriptionExamsClick}
          onPrintAllRecords={onPrintAllRecords}
          onPrintCustomFormClick={onPrintCustomFormClick}
          onPrintDocumentClick={onPrintDocumentClick}
          onPrintMemedPrescription={onPrintMemedPrescription}
          onPrintPrescriptionDrugsClick={onPrintPrescriptionDrugsClick}
          onPrintPrescriptionExamsClick={onPrintPrescriptionExamsClick}
          onPrintSingleRecord={onPrintSingleRecord}
          onSelectFile={onSelectFile}
          onToggleEditMode={onSetEditMode}
          patient={patient}
          clinicId={clinicId}
          userEmail={userEmail}
          showPDF={onShowPdf}
          onEditCustomFormClick={onEditCustomFormClick}
          onEditDocumentClick={onEditDocumentClick}
          onEditPrescriptionDrugsClick={onEditPrescriptionDrugsClick}
          onEditPrescriptionExamsClick={onEditPrescriptionExamsClick}
          planInclude={planInclude}
          isSaving={isSaving || get(updatingIds, String(record.id), false)}
          onAddMemedId={onAddMemedId}
          onDeleteMemedId={onDeleteMemedId}
          onSaveRecord={onSaveRecord}
          isAutoSaving={isAutoSaving}
          isMemedLoaded={isMemedLoaded}
          onAutosaveRecord={onAutosaveRecord}
          isDeleting={cdIsDeleting}
          isNew={get(newRecords, String(record.id))}
          singleUser={permissions.singleUser}
          experimentalFeatures={permissions.experimentalFeatures}
          onDisplayAttachmentFileName={onDisplayAttachmentFileName}
          onUpdateRecordDate={onUpdateRecordDate}
        />
      </React.Fragment>
    );
  }), [
    clinicId, userEmail,
    fRecords, onDirectShare, onUpdateRecordDate, onDisplayAttachmentFileName,
    recordDeletingIds, onDownloadAttachment, recordsEditModesList, attachments, customFormTemplates,
    customForms, documents, drugsPrescriptions, examsPrescriptions, mainSection, memedToken, onChangeVisibilityLevel,
    onDeleteAttachment, onDeleteRecord, onNewCustomFormClick, onNewDocumentClick, onNewPrescriptionDrugsClick, onNewPrescriptionExamsClick,
    onPrintAllRecords, onPrintCustomFormClick, onPrintDocumentClick, onPrintMemedPrescription, onPrintPrescriptionDrugsClick,
    onPrintPrescriptionExamsClick, onPrintSingleRecord, onSelectFile, onSetEditMode, patient, onShowPdf, onEditCustomFormClick,
    onEditDocumentClick, onEditPrescriptionDrugsClick, onEditPrescriptionExamsClick, planInclude, isSaving, updatingIds,
    onAddMemedId, onDeleteMemedId, onSaveRecord, isAutoSaving, isMemedLoaded, onAutosaveRecord, newRecords, permissions.singleUser, permissions.experimentalFeatures,
    onRestoreRecord, _,
  ]);


  return (
    <div className="registers">
      {isPresent(pdfState.pdfPreviewDoc) && (
      <PDFPreview
        docUrl={pdfState.pdfPreviewDoc}
        onClose={closePdfDoc}
        pdfName={pdfState.pdfName}
      />
      )}
      {recordElements}
    </div>
  );
};


// $FlowFixMe
export default React.memo(Records);
