// @flow
import axios from 'axios';
import JQuery from 'jquery';
import { get, map, forEach, endsWith } from 'lodash';
import moment from 'moment';
import Log from '@helpers/Log';
import { lookup } from 'mime-to-extensions';
import { socialShareFile } from '../../redux/middleware/showPDF';
import { isPresent } from './validations';

export const pdfToBase64 = (response: Object): Promise<string> => new Promise((resolve) => {
  const file = new Blob([response], { type: 'application/pdf' });
  const reader = new window.FileReader();
  reader.readAsDataURL(file);
  reader.onloadend = () =>
    resolve(reader.result.split(',').pop());
});

export const getCountryCode = (): Promise<any> => new Promise((resolve => {
  axios.get('https://get-geolocation.doutore.workers.dev')
    .then(({ data }: Object) => {
      const locale = get(data, 'countryCode');
      if (locale) {
        Log.debug(`[LOCALE] Locale found ${locale}`);
      } else {
        Log.debug('[LOCALE] failed to fetch locale');
      }
      resolve(locale || 'BR');
    })
    .catch(() => {
      Log.debug('[LOCALE] failed to fetch locale');
      resolve('BR');
    });
}));

export const copyToClipboard = (text: any) => {
  const docx: any = document;
  if (window.clipboardData && window.clipboardData.setData) {
    return window.clipboardData.setData('Text', text);
  } else if (docx.queryCommandSupported && docx.queryCommandSupported('copy')) {
    const textarea = docx.createElement('textarea');
    textarea.textContent = text;
    textarea.style.position = 'fixed';
    docx.body.appendChild(textarea);
    textarea.select();
    try {
      return docx.execCommand('copy');
    } catch (ex) {
      // eslint-disable-next-line
      console.warn("Copy to clipboard failed.", ex);
      return false;
    } finally {
      docx.body.removeChild(textarea);
    }
  }
};

export const copyToClipboardEv = (text: string) => copyToClipboard(text);

export const handleSubmitEvent = (selector: string, event: Function) => {
  JQuery(selector).on('keydown', (e): any => {
    if (get(e, 'keyCode') === 13 && (get(document, ['activeElement', 'type']) !== 'textarea')) {
      if (window.__hasSelectOpened) {
        return;
      }
      event();
    }
  });
};


export const parseUrlParams = (url: string): any => {
  // get query string from url (optional) or window
  let queryString = url ? url.split('?')[1] : window.location.search.slice(1);

  // we'll store the parameters here
  const obj = {};

  // if query string exists
  if (queryString) {
    // stuff after # is not part of query string, so get rid of it
    // eslint-disable-next-line
    queryString = queryString.split('#')[0];

    // split our query string into its component parts
    const arr = queryString.split('&');

    for (let i = 0; i < arr.length; i++) {
      // separate the keys and the values
      const a = arr[i].split('=');

      // set parameter name and value (use 'true' if empty)
      let paramName = a[0];
      let paramValue = typeof (a[1]) === 'undefined' ? true : a[1];

      // (optional) keep case consistent
      paramName = paramName.toLowerCase();
      if (typeof paramValue === 'string') paramValue = paramValue.toLowerCase();

      // if the paramName ends with square brackets, e.g. colors[] or colors[2]
      if (paramName.match(/\[(\d+)?\]$/)) {
        // create key if it doesn't exist
        const key = paramName.replace(/\[(\d+)?\]/, '');
        if (!obj[key]) obj[key] = [];

        // if it's an indexed array e.g. colors[2]
        if (paramName.match(/\[\d+\]$/)) {
          // get the index value and add the entry at the appropriate position
          // eslint-disable-next-line
          const rExec: any = /\[(\d+)\]/.exec(paramName);
          const index = rExec[1];
          obj[key][index] = paramValue;
        } else {
          // otherwise add the value to the end of the array
          obj[key].push(paramValue);
        }
      } else if (!obj[paramName]) {
        // if it doesn't exist, create property
        obj[paramName] = paramValue;
      } else if (obj[paramName] && typeof obj[paramName] === 'string') {
        // if property does exist and it's a string, convert it to an array
        obj[paramName] = [obj[paramName]];
        obj[paramName].push(paramValue);
      } else {
        // otherwise add the property
        obj[paramName].push(paramValue);
      }
    }
  }

  return obj;
};

export function getExtension(path: string) {
  const basename = path.split(/[\\/]/).pop(); // extract file name from full path ...
  // (supports `\\` and `/` separators)
  const pos = basename.lastIndexOf('.'); // get last position of `.`

  if (basename === '' || pos < 1) { return ''; }

  return basename.slice(pos + 1); // extract extension ignoring `.`
}

export function arrayBufferToBase64(buffer: any) {
  let binary = '';
  const bytes = [].slice.call(new Uint8Array(buffer));
  // eslint-disable-next-line
  bytes.forEach((b) => binary += String.fromCharCode(b));

  return window.btoa(binary);
}

export const matchExtensions = (fileName: string, types: Array<string> = []) => {
  let matches = 0;

  forEach(types, (fType: string) => {
    if (endsWith(String(fileName).toLowerCase(), `.${fType}`)) {
      matches++;
    }
  });

  return matches;
};

export const fileExtensionIsImage = (fileName: string) => matchExtensions(fileName, ['png', 'jpg', 'jpeg', 'jfif']);

export const fileExtensionIsExecutable = (fileName: string) => matchExtensions(fileName, ['exe', 'ipa', 'app', 'osx', 'kext', 'appimage']);

export const easyOpenCache = (name: string, cb: (cache: Cache | null) => any) => {
  if (!window.caches) {
    cb(null);
    return;
  }
  window.caches.open(name).then((cache) => {
    cb(cache);
  }).catch(() => cb(null));
};

export const isShareable = (fileName: string) => {
  const allowedShareMimes = ['application/zip', 'application/pdf', 'application/x-rar-compressed', 'application/x-tar'];

  const isPDF = String(fileName).endsWith('.pdf');
  const isVideo = (lookup(fileName) && lookup(fileName).startsWith('video/'));
  const isImage = (lookup(fileName) && lookup(fileName).startsWith('image/'));
  const isAllowedMime = allowedShareMimes.indexOf(lookup(fileName)) !== -1;

  return isPDF || isVideo || isImage || isAllowedMime;
};


type DownloadOrShareSettings = {
  state: any,
  url: string,
  params: any,
  mime: string,
  dispatch: Function,
  // uses {d} to set unix digits in name and {t} for date/time
  fileName: string;
};

export const downloadOrShare = ({
  params, state, url, mime, dispatch, fileName,
}: DownloadOrShareSettings) => new Promise<{shared: boolean}>((resolve, reject) => {
  const urlParams = map<any, any>(params, (value, key) => `${key}=${value}`).join('&');

  const fullUrl = `${url}?${urlParams}`;
  const sharePluginEnabled = window.isCordovaApp && ((!!window.plugins && !!window.plugins.socialsharing) || (!!window.plugins && !!window.plugins.PrintPDF));

  const fullFileName = fileName.replace('{d}', moment().unix().toString()).replace('{t}', moment().format('DD_MM_YYYY'));

  fetch(fullUrl, {
    method: 'GET',
    headers: {
      'access-token': get(state, ['auth', 'token'], null),
      clinicid: get(state, ['context', 'clinics', 'activeClinic', 'id'], null),
    },
  }).then(r => r.blob()).then((b) => {
    b.arrayBuffer().then((buff) => {
      const blob = new Blob([buff], { type: mime });

      if (sharePluginEnabled) {
        dispatch({
          type: 'toasts.OPEN_TOAST',
          data: {
            type: 'success', text: 'Compartilhando...', close: true, timeout: 10000,
          },
        });
        socialShareFile(blob, isPresent(fullFileName) ? fullFileName : `doc-${(new Date()).getTime() / 1000}.xlsx`, dispatch);
        resolve({ shared: true });
      } else {
        const objectUrl = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.target = '_blank';
        a.href = objectUrl;
        a.download = fullFileName;
        window.document.body.appendChild(a);
        a.click();
        window.document.body.removeChild(a);

        resolve({ shared: false });
      }
    }).catch(reject);
  }).catch(reject);
});
