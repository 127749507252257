// @flow
import ChainedValidator from '../core/ChainedValidator';
import ValidationResult from '../core/ValidationResult';
import * as validatorTesters from './testers';


export const validationRules = {
  present: validatorTesters.isPresent.name,
  number: validatorTesters.isNumber.name,
  minChars: validatorTesters.inMinChars.name,
  maxChars: validatorTesters.inMaxChars.name,
  betweenChars: validatorTesters.inBetweenChars.name,
  cpf: validatorTesters.isValidCpf.name,
  brPhone: validatorTesters.isValidBrPhone.name,
};

export class ValidatorRules {
    __parent: ChainedValidator;
    name: string;

    constructor(name: string, parent: ChainedValidator) {
      this.__parent = parent;
      this.name = name;
    }
    /** ***************************************************************************************** */
    /** ******************************** Insert Rules Here ************************************** */
    /** ***************************************************************************************** */

    /**
     * Check if value is present
     *
     * thorw's error when
     * - value is null
     * - value is undefined
     * - value is space filled
     */
    present = (message: string | Function) => {
      this.__parent.pushRule(this.name, validatorTesters.isPresent, message);
      return this;
    }
    /**
     * Validate an email
     * @param {*} message
     */
    email = (message: string | Function, allowEmpty: boolean = false) => {
      this.__parent.pushRule(this.name, validatorTesters.isValidEmail, message, { allowEmpty });
      return this;
    }
    /**
     * Check if an string is a valid brazilian cpf
     */
    cpf = (message: string | Function, allowEmpty: boolean = false) => {
      this.__parent.pushRule(this.name, validatorTesters.isValidCpf, message, { allowEmpty });
      return this;
    }
    /**
     * Check if an string is a valid hh:mm time
     */
    hoursAndMinutes = (message: string | Function, allowEmpty: boolean = false) => {
      this.__parent.pushRule(this.name, validatorTesters.isValidHoursAndMinutes, message, { allowEmpty });
      return this;
    }
    /**
     * Check if is a valid number
     * @param {string} message
     */
    number = (message: string | Function, allowEmpty: boolean = false) => {
      this.__parent.pushRule(this.name, validatorTesters.isNumber, message, { allowEmpty });
      return this;
    }
    /**
     *
     * @param {*} min
     * @param {*} message
     */
    minNumber = (min: number, message: string | Function, allowEmpty: boolean = false) => {
      this.__parent.pushRule(this.name, validatorTesters.minNumber, message, { min, allowEmpty });
      return this;
    }
    /**
     *
     * @param {*} min
     * @param {*} message
     */
    maxNumber = (max: number, message: string | Function, allowEmpty: boolean = false) => {
      this.__parent.pushRule(this.name, validatorTesters.maxNumber, message, { max, allowEmpty });
      return this;
    }
    /**
     *
     */
    numberBetween = (min: number, max:number, message: string | Function, allowEmpty: boolean = false) => {
      this.__parent.pushRule(this.name, validatorTesters.isNumberBetween, message, { min, max, allowEmpty });
      return this;
    }
    /**
     * Check if a string value have a lenght of >= min
     * @param {number} min
     * @param {string} message
     */
    minChars = (min: number, message: string | Function, allowEmpty: boolean = false, onlySubmitError: boolean) => {
      this.__parent.pushRule(this.name, validatorTesters.inMinChars, message, { min, allowEmpty, onlySubmitError });
      return this;
    }
    /**
     * Br ZipCode Template
     * @param {*} message
     */
    brZipCode = (message: string | Function) => {
      this.minChars(8, message, false, true);
      return this;
    }
    /**
     * Check if a string value have a lenght of <= max
     * @param {number} max
     * @param {string} message
     */
    maxChars = (max: number, message: string | Function, allowEmpty: boolean = false) => {
      this.__parent.pushRule(this.name, validatorTesters.isNumber, message, { max, allowEmpty });
      return this;
    }
    /**
     * Check if a string value is bitwwen min and max (lenght >= min and lenght <= max)
     * @param {number} min
     * @param {number} max
     * @param {string} message
     */
    betweenChars = (min: number, max:number, message: string | Function, allowEmpty: boolean = false) => {
      this.__parent.pushRule(this.name, validatorTesters.inBetweenChars, message, { min, max, allowEmpty });
      return this;
    }

    brPhone = (message: string | Function, allowEmpty: boolean = false) => {
      this.__parent.pushRule(this.name, validatorTesters.isValidBrPhone, message, { allowEmpty });
      return this;
    }

    brDate = (message: string | Function, allowEmpty: boolean = false) => {
      this.__parent.pushRule(this.name, validatorTesters.isValidBrDate, message, { allowEmpty });
      return this;
    }


    /** ***************************************************************************************** */
    /** ******************************** Avoid chages here ************************************** */
    /** ***************************************************************************************** */
    /**
     * Initializes an new field error validation
     * @param {string} name
     */
    field = (name: string) => this.__parent.field(name)
    /**
    * Validate form data and returns errors
    * @param {*} formData
    * @param {Object or boolean} touchedFields when pass touched fields this uses touched fields to handle validation
    * @param {boolean} allErros when true return errors as {[field: string]:Array<string of message>}
    */
    validate = (formData: Object, touchedFields: Object | null = null, isSubmit: boolean = false): ValidationResult => this.__parent.validate(formData, touchedFields, isSubmit)
    /**
     * Removes a rule from validator
     * @param {string} field
     * @param {string} type
     */
    removeRule = (type: string) => {
      this.__parent.removeRule(this.name, type);
      return this;
    }
}
