// @flow
import React from 'react';
import { get } from 'lodash';
import { connect } from 'react-redux';
import { paymentActions } from '@context/payments';
import { ReportsPayments as ReportsPaymentsView } from '@views';
import { router } from '@router';
import { mapPlanIncludes, mapPermissionTo } from '@helpers/connect';
import type { Dispatch, DoctorsState, PaymentsState, PaymentInput, PlanInclude, PermissionTo } from '@types';

type Props = {
  dispatch: Function,
  doctors: DoctorsState,
  payments: PaymentsState,
  defaultDoctorId: number,
  isManager: boolean,
  planInclude: PlanInclude,
  permissionTo : PermissionTo,
  singleDoctor: boolean,
};

class ReportsPayments extends React.Component<Props> {
  render() {
    const {
      doctors, payments, defaultDoctorId, isManager, planInclude, permissionTo, singleDoctor,
    } = this.props;

    return (
      <ReportsPaymentsView
        doctors={doctors}
        payments={payments}
        onLoadData={this.handleGetPayments}
        onUpdatePayment={this.handleUpatePayment}
        onViewPatient={this.handleViewPatient}
        onDownloadReport={this.handleDownloadReport}
        defaultDoctorId={defaultDoctorId}
        isManager={isManager}
        isFeatureEnabled={planInclude.financial}
        isReportBlocked={!permissionTo.reports}
        reportsSelectDoctor={permissionTo.reportsSelectDoctor}
        singleDoctor={singleDoctor}
      />
    );
  }

  handleGetPayments = (startDate: string, endDate: string, doctorId?: number, notaFiscal?: boolean = false) => {
    const { dispatch } = this.props;
    dispatch(paymentActions.getPayments(startDate, endDate, doctorId, notaFiscal));
  };

  handleDownloadReport = (startDate: string, endDate: string, doctorId?: number, notaFiscal?: boolean = false) => {
    const { dispatch } = this.props;
    dispatch(paymentActions.getPaymentsReport(startDate, endDate, doctorId, notaFiscal));
  };

  handleUpatePayment = (patientId: number, id: number, data: PaymentInput) => {
    const { dispatch } = this.props;
    dispatch(paymentActions.updatePayment(patientId, id, data));
  };

  handleViewPatient = (id: number) => {
    const { dispatch } = this.props;
    dispatch(router.pushPath('patientFinancial', { id }));
  };
}

const mapStateToProps = (state) => ({
  doctors: state.context.doctors,
  payments: state.context.payments,
  defaultDoctorId: get(state, ['context', 'user', 'advancedData', 'doctorId']),
  isManager: get(state, ['context', 'user', 'advancedData', 'manager']),
  planInclude: mapPlanIncludes(state),
  permissionTo: mapPermissionTo(state),
  singleDoctor: get(state, ['context', 'clinics', 'activeClinic', 'singleDoctor'], false),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({ dispatch });

export default connect<any, any, any, any, any, any>(
  mapStateToProps,
  mapDispatchToProps,
)(ReportsPayments);
