// @flow
import React from 'react';
import { connect } from 'react-redux';
import { get } from 'lodash';
import { MedicationsFormModal } from '@views';
import { actions } from '@context/modals';
import { addMedication, deleteMedication } from '@context/patients';
import type { Dispatch, Patient } from '@types';

type Props = {
  dispatch: Function,
  patient: Patient,
  isMedicationLoading: boolean,
};

class MedicationsFormModalContainer extends React.Component<Props> {
  render() {
    const { patient, isMedicationLoading } = this.props;
    return (
      <MedicationsFormModal
        onClose={this.closeModal}
        onAddNewItem={this.handleAddNewItem}
        medications={get(patient, 'medications', [])}
        onDeleteItem={this.handleDeleteItem}
        isLoading={isMedicationLoading === true}
      />
    );
  }

  closeModal = () => {
    this.props.dispatch(actions.closeActiveModal());
  };
  handleAddNewItem = (item: string) => {
    this.props.dispatch(addMedication(this.props.patient.id, item));
  };
  handleDeleteItem = (id: number) => {
    this.props.dispatch(deleteMedication(this.props.patient.id, id));
  };
}
const mapStateToProps = (state) => ({
  patient: get(state, ['context', 'patients', 'data', get(state, ['context', 'modals', 'data', 'id'], {})], {}),
  isMedicationLoading: get(state, ['context', 'patients', 'medicationLoading', get(state, ['context', 'modals', 'data', 'id'], {})], {}),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({ dispatch });

export default connect<any, any, any, any, any, any>(
  mapStateToProps,
  mapDispatchToProps,
)(MedicationsFormModalContainer);
