// @flow
import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { get } from 'lodash';
import { PaymentFormModal } from '../../components/views';
import { paymentActions } from '../../redux/modules/context/payments';
import type { Dispatch, PaymentInput, Payment } from '../../types';
import { withConfirmOnClose } from '../../hocs';

type Props = {
  dispatch: Function,
  patientId: number,
  paymentId: number,
  payment: ?Payment,
  setConfirmation: Function,
  closeModal: Function,
};

class PaymentEditFormModalContainer extends React.Component<Props> {
  render() {
    const { payment, setConfirmation } = this.props;
    return (
      <PaymentFormModal
        title="Editar Pagamento"
        onSave={this.handleUpdatePayment}
        onDelete={this.handleDeletePayment}
        onClose={this.closeModal}
        onSetConfirmOnClose={setConfirmation}
        formData={payment}
      />
    );
  }

  closeModal = this.props.closeModal;

  handleUpdatePayment = (data: PaymentInput) => {
    const { dispatch, patientId, paymentId } = this.props;
    dispatch(paymentActions.updatePayment(patientId, paymentId, data));
    this.closeModal(true);
  };

  handleDeletePayment = () => {
    const { dispatch, patientId, paymentId } = this.props;
    dispatch(paymentActions.deletePayment(patientId, paymentId));
    this.closeModal(true);
  };
}

const mapStateToProps = (state) => ({
  patientId: parseInt(state.context.modals.data.patientId, 10),
  payment: get(state.context, ['payments', 'data', String(state.context.modals.data.paymentId)], {}),
  paymentId: get(state, ['context', 'modals', 'data', 'paymentId']),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({ dispatch });


export default compose(
  connect<any, any, any, any, any, any>(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withConfirmOnClose(),
)(PaymentEditFormModalContainer);
