// @flow
import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import appVersionReducer from './appVersion';
import contextReducer from './context';
import uiReducer from './ui';
import authReducer from './auth';

// type ReducerState = {
//   key: string,
//   reducer: Function,
// };

export const makeRootReducer = (asyncReducers: Object, history: Object) => (
  combineReducers<any, any>({
    appVersion: appVersionReducer,
    auth: authReducer,
    context: contextReducer,
    ui: uiReducer,
    router: connectRouter(history),
    ...asyncReducers,
  })
);

// export const injectReducer = (store: Object, {
//   key, reducer,
// }: ReducerState) => {
//   if (Object.hasOwnProperty.call(store.asyncReducers, key)) return;

//   store.asyncReducers[key] = reducer;
//   store.replaceReducer(makeRootReducer(store.asyncReducers));
// };

export default makeRootReducer;
