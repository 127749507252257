// @flow
import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import ExternalLayout from '../layouts/ExternalLayout';
import AppLayout from '../layouts/AppLayout';
import {
  NotFound,
  AppHome, Login, ForgotPassword, NewPassword, NewClinic, GetStarted,
  SignUp, SignUpBasic, SignUpProfession, SignUpClinic,
  Agenda,
  PatientsList, WorkHours, PatientTimeline, PatientMessages, PatientProfile, PatientDeleted,
  PatientFinancial,
  ReportsList, ReportsAppointments, ReportsDebt, ReportsPayments, ReportsReceipts, ReportsServices, ReportsSms,
  SettingsList, UserConfig, RecordsPrivacy, ChangePassword,
  ClinicConfigMenu, ClinicConfigAddress, ClinicConfigWorkHours, ClinicConfigRooms, ClinicConfigPatientFields,
  DoctorsList, DoctorForm, SecretariesList, SecretaryForm,
  PrescriptionsMenu, DocumentsTemplatesList, DocumentsTemplateForm, DrugsPrescriptionsTemplatesList, DrugsPrescriptionsTemplateForm, ExamsPrescriptionsTemplatesList, ExamsPrescriptionsTemplateForm,
  TagTemplatesList, TagTemplateForm,
  ServiceTemplatesList, ServiceTemplateEditForm, ServiceTemplateNewForm,
  SettingsSms,
  PrintConfig, PrescriptionsPrintConfig, ControlledPrescriptionsPrintConfig, GeneralPrintConfig,
  Support, CustomFormsList, GhostLogin, PrintClinicLogo,
  Benefits, Pricing, PricingIos, SubscriptionAddress, SubscriptionPayment, SubscriptionInfo, SubscriptionWall, SubscriptionComplete, BackupRequest,
  MemedSignupContainer,
  PatientIndicatorsContainer,
  DeleteMyAccount,
} from '../containers';
import { requireAuth } from './AuthenticatedRoute';
import { router } from '../routes/router';
import type { Store } from '../types';
import { hot } from 'react-hot-loader';
import { ErrorBoundaryView } from '@blocks';
import { DevTools } from '@views';

type Props = {
  store: Store,
};

// Subscription management is handled in a different screen on iOS.
const PricingComponent = () => (
  window.isCordovaIos ? PricingIos : Pricing);


const App = ({ store }: Props) => {
  const routes = router.getNamedRoutes();

  const HomeRoute = () => {
    let redirectTo;
    const state = store.getState();
    const isAuthenticated = state.auth && state.auth.authenticated;
    if (isAuthenticated) {
      redirectTo = routes.patientsList;
    } else if (window.isCordovaApp) {
      redirectTo = routes.appHome;
    } else {
      redirectTo = routes.login;
    }
    return <Redirect to={redirectTo} />;
  };

  const ExternalLayoutRoutes = ({ component: Component, ...rest }: any) => (
    <Route
      {...rest}
      render={renderProps => (
        <ExternalLayout>
          <ErrorBoundaryView>
            <Component {...renderProps} />
          </ErrorBoundaryView>
        </ExternalLayout>
      )}
    />
  );

  const AppLayoutRoutes = ({ component: Component, ...rest }: any) => (
    <Route
      {...rest}
      render={renderProps => (
        <AppLayout>
          <ErrorBoundaryView>
            <Component {...renderProps} />
          </ErrorBoundaryView>
        </AppLayout>
      )}
    />
  );


  return (
    <Switch>
      <HomeRoute exact path={routes.root} />

      <ExternalLayoutRoutes exact path={routes.appHome} component={AppHome} />
      {window.IS_DEVTOOLS_ENABLED && <ExternalLayoutRoutes exact path="/dev-tools" component={DevTools} />}
      <ExternalLayoutRoutes exact path={routes.login} component={Login} />
      <ExternalLayoutRoutes exact path={routes.forgotPassword} component={ForgotPassword} />
      <ExternalLayoutRoutes exact path={routes.signUp} component={SignUp} />
      <ExternalLayoutRoutes exact path={routes.signUpBasic} component={requireAuth(SignUpBasic, routes.signUpBasic)} />
      <ExternalLayoutRoutes exact path={routes.signUpProfession} component={requireAuth(SignUpProfession, routes.signUpProfession)} />
      <ExternalLayoutRoutes exact path={routes.signUpClinic} component={requireAuth(SignUpClinic, routes.signUpClinic)} />
      <ExternalLayoutRoutes exact path={routes.newPassword} component={requireAuth(NewPassword, routes.newPassword)} />
      <ExternalLayoutRoutes exact path={routes.newClinic} component={requireAuth(NewClinic, routes.newClinic)} />
      <AppLayoutRoutes exact path={routes.getStarted} component={requireAuth(GetStarted, routes.getStarted)} />

      <AppLayoutRoutes exact path={routes.agenda} component={requireAuth(Agenda, routes.agenda)} />

      <AppLayoutRoutes exact path={routes.patientIndicators} component={requireAuth(PatientIndicatorsContainer, routes.patientIndicators)} />

      <AppLayoutRoutes exact path={routes.memedSignup} component={requireAuth(MemedSignupContainer, routes.memedSignup)} />
      <AppLayoutRoutes exact path={routes.patientsList} component={requireAuth(PatientsList, routes.patientsList)} />
      <AppLayoutRoutes exact path={routes.patientTimeline} component={requireAuth(PatientTimeline, routes.patientTimeline)} />
      <AppLayoutRoutes exact path={routes.patientDeleted} component={requireAuth(PatientDeleted, routes.patientDeleted)} />
      <AppLayoutRoutes exact path={routes.patientProfile} component={requireAuth(PatientProfile, routes.patientProfile)} />
      <AppLayoutRoutes exact path={routes.patientMessages} component={requireAuth(PatientMessages, routes.patientMessages)} />

      <AppLayoutRoutes exact path={routes.patientFinancial} component={requireAuth(PatientFinancial, routes.patientFinancial)} />
      <AppLayoutRoutes exact path={routes.patientServices} component={requireAuth(PatientFinancial, routes.patientServices)} />
      <AppLayoutRoutes exact path={routes.patientPayments} component={requireAuth(PatientFinancial, routes.patientPayments)} />
      <AppLayoutRoutes exact path={routes.patientReceipts} component={requireAuth(PatientFinancial, routes.patientReceipts)} />

      <AppLayoutRoutes exact path={routes.reportsList} component={requireAuth(ReportsList, routes.reportsList)} />
      <AppLayoutRoutes exact path={routes.reportsAppointments} component={requireAuth(ReportsAppointments, routes.reportsAppointments)} />
      <AppLayoutRoutes exact path={routes.reportsDebt} component={requireAuth(ReportsDebt, routes.reportsDebt)} />
      <AppLayoutRoutes exact path={routes.reportsPayments} component={requireAuth(ReportsPayments, routes.reportsPayments)} />
      <AppLayoutRoutes exact path={routes.reportsReceipts} component={requireAuth(ReportsReceipts, routes.reportsReceipts)} />
      <AppLayoutRoutes exact path={routes.reportsServices} component={requireAuth(ReportsServices, routes.reportsServices)} />
      <AppLayoutRoutes exact path={routes.reportsSms} component={requireAuth(ReportsSms, routes.reportsSms)} />
      <AppLayoutRoutes exact path={routes.backupRequest} component={requireAuth(BackupRequest, routes.backupRequest)} />

      <AppLayoutRoutes exact path={routes.settingsList} component={requireAuth(SettingsList, routes.settingsList)} />
      <AppLayoutRoutes exact path={routes.userConfig} component={requireAuth(UserConfig, routes.userConfig)} />
      <AppLayoutRoutes exact path={routes.changePassword} component={requireAuth(ChangePassword, routes.changePassword)} />
      <AppLayoutRoutes exact path={routes.recordsPrivacy} component={requireAuth(RecordsPrivacy, routes.recordsPrivacy)} />
      <AppLayoutRoutes exact path={routes.clinicConfigMenu} component={requireAuth(ClinicConfigMenu, routes.clinicConfigMenu)} />
      <AppLayoutRoutes exact path={routes.clinicConfigAddress} component={requireAuth(ClinicConfigAddress, routes.clinicConfigAddress)} />
      <AppLayoutRoutes exact path={routes.clinicConfigWorkHours} component={requireAuth(ClinicConfigWorkHours, routes.clinicConfigWorkHours)} />
      <AppLayoutRoutes exact path={routes.clinicConfigRooms} component={requireAuth(ClinicConfigRooms, routes.clinicConfigRooms)} />
      <AppLayoutRoutes exact path={routes.clinicConfigPatientFields} component={requireAuth(ClinicConfigPatientFields, routes.clinicConfigPatientFields)} />
      <AppLayoutRoutes exact path={routes.doctorsList} component={requireAuth(DoctorsList, routes.doctorsList)} />
      <AppLayoutRoutes exact path={routes.doctorForm} component={requireAuth(DoctorForm, routes.doctorForm)} />
      <AppLayoutRoutes exact path={routes.workhours} component={requireAuth(WorkHours, routes.workhours)} />
      <AppLayoutRoutes exact path={routes.secretariesList} component={requireAuth(SecretariesList, routes.secretariesList)} />
      <AppLayoutRoutes exact path={routes.secretaryForm} component={requireAuth(SecretaryForm, routes.secretaryForm)} />
      <AppLayoutRoutes exact path={routes.customFormsList} component={requireAuth(CustomFormsList, routes.customFormsList)} />
      <AppLayoutRoutes exact path={routes.prescriptionsMenu} component={requireAuth(PrescriptionsMenu, routes.prescriptionsMenu)} />
      <AppLayoutRoutes exact path={routes.documentsTemplatesList} component={requireAuth(DocumentsTemplatesList, routes.documentsTemplatesList)} />
      <AppLayoutRoutes exact path={routes.documentsTemplateForm} component={requireAuth(DocumentsTemplateForm, routes.documentsTemplateForm)} />
      <AppLayoutRoutes exact path={routes.drugsPrescriptionsTemplatesList} component={requireAuth(DrugsPrescriptionsTemplatesList, routes.drugsPrescriptionsTemplatesList)} />
      <AppLayoutRoutes exact path={routes.drugsPrescriptionsTemplateForm} component={requireAuth(DrugsPrescriptionsTemplateForm, routes.drugsPrescriptionsTemplateForm)} />
      <AppLayoutRoutes exact path={routes.examsPrescriptionsTemplatesList} component={requireAuth(ExamsPrescriptionsTemplatesList, routes.examsPrescriptionsTemplatesList)} />
      <AppLayoutRoutes exact path={routes.examsPrescriptionsTemplateForm} component={requireAuth(ExamsPrescriptionsTemplateForm, routes.examsPrescriptionsTemplateForm)} />
      <AppLayoutRoutes exact path={routes.tagTemplatesList} component={requireAuth(TagTemplatesList, routes.tagTemplatesList)} />
      <AppLayoutRoutes exact path={routes.tagTemplateForm} component={requireAuth(TagTemplateForm, routes.tagTemplateForm)} />
      <AppLayoutRoutes exact path={routes.serviceTemplatesList} component={requireAuth(ServiceTemplatesList, routes.serviceTemplatesList)} />
      <AppLayoutRoutes exact path={routes.serviceTemplateEditForm} component={requireAuth(ServiceTemplateEditForm, routes.serviceTemplateEditForm)} />
      <AppLayoutRoutes exact path={routes.serviceTemplateNewForm} component={requireAuth(ServiceTemplateNewForm, routes.serviceTemplateNewForm)} />
      <AppLayoutRoutes exact path={routes.settingsSms} component={requireAuth(SettingsSms, routes.settingsSms)} />
      <AppLayoutRoutes exact path={routes.printConfig} component={requireAuth(PrintConfig, routes.printConfig)} />
      <AppLayoutRoutes exact path={routes.printLogoConfig} component={requireAuth(PrintClinicLogo, routes.printLogoConfig)} />
      <AppLayoutRoutes exact path={routes.prescriptionsPrintConfig} component={requireAuth(PrescriptionsPrintConfig, routes.prescriptionsPrintConfig)} />
      <AppLayoutRoutes exact path={routes.controlledPrescriptionsPrintConfig} component={requireAuth(ControlledPrescriptionsPrintConfig, routes.controlledPrescriptionsPrintConfig)} />
      <AppLayoutRoutes exact path={routes.generalPrintConfig} component={requireAuth(GeneralPrintConfig, routes.generalPrintConfig)} />
      <AppLayoutRoutes exact path={routes.support} component={requireAuth(Support, routes.support)} />
      <AppLayoutRoutes exact path={routes.deleteMyAccount} component={requireAuth(DeleteMyAccount, routes.deleteMyAccount)} />

      <AppLayoutRoutes exact path={routes.benefits} component={requireAuth(Benefits, routes.benefits)} />
      <AppLayoutRoutes exact path={routes.pricing} component={requireAuth(PricingComponent(), routes.pricing)} />
      <AppLayoutRoutes exact path={routes.subscriptionAddress} component={requireAuth(SubscriptionAddress, routes.subscriptionAddress)} />
      <AppLayoutRoutes exact path={routes.subscriptionPayment} component={requireAuth(SubscriptionPayment, routes.subscriptionPayment)} />
      <AppLayoutRoutes exact path={routes.subscriptionInfo} component={requireAuth(SubscriptionInfo, routes.subscriptionInfo)} />
      <AppLayoutRoutes exact path={routes.subscriptionWall} component={requireAuth(SubscriptionWall, routes.subscriptionWall)} />
      <AppLayoutRoutes exact path={routes.subscriptionComplete} component={requireAuth(SubscriptionComplete, routes.subscriptionComplete)} />

      <AppLayoutRoutes exact path={routes.ghostLogin} component={requireAuth(GhostLogin, routes.ghostLogin)} />

      <AppLayoutRoutes exact path='/not-found' component={NotFound} />

      {/* Redirect hashed route to 404 page if not match any preselected pattern */}
      <Redirect from='*' to='/not-found' />
    </Switch>
  );
};

// $FlowFixMe
export default hot(module)(App);
