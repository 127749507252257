// @flow
import React from 'react';
import classNames from 'classnames';
import { clamp } from 'lodash';
import './TinyProgressBar.scss';

type Props = {
    progress: number;
    className?: string,
};

export default class TinyProgressBar extends React.Component<Props> {
  render() {
    const { progress, className } = this.props;
    return (
      <div className={classNames('tinyProgressBar', className)} >
        <div style={{ width: `${clamp(progress, 0, 100)}%` }} />
      </div>
    );
  }
}
