// @flow
import React from 'react';
import { connect } from 'react-redux';
import { get } from 'lodash';
import { professions } from '@helpers/constants';
import { actions as authActions } from '../redux/modules/auth';
import { SignUpProfession as SignUpProfessionView } from '../components/views';
import type { AuthState, Dispatch } from '../types';

type Props = {
  auth: AuthState,
  userId: number,
  dispatch: Function,
  isLoading: boolean,
};

class SignUpProfession extends React.Component<Props> {
  componentWillMount() {
    const { dispatch } = this.props;
    dispatch(authActions.resetSignUpState());
  }

  render() {
    const { auth, userId, isLoading } = this.props;

    return (
      <SignUpProfessionView
        userId={userId}
        professions={professions}
        authErrors={get(auth, ['errors'])}
        onSignupStep3={this.handleSignupStep3}
        isLoading={isLoading}
      />
    );
  }

  handleSignupStep3 = (userId: number, professionCode: string) => {
    // this.props.dispatch(router.pushPath('signUpClinic'));
    this.props.dispatch(authActions.signupStep3({ userId, professionCode }));
  };
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  userId: get(state, ['context', 'user', 'id']),
  isLoading: get(state, ['auth', 'inProgress']),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({ dispatch });

export default connect<any, any, any, any, any, any>(
  mapStateToProps,
  mapDispatchToProps,
)(SignUpProfession);
