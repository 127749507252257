export default {
  'label/day': 'Día',
  'label/low_day': 'día',
  'label/start': 'Comienzo',
  'label/end': 'Fin',
  'label/appointment': 'Cita',
  'label/appointments': 'Citas',
  'label/call_phone': 'Llamar',
  'label/searching': 'Buscando...',
  'label/deleting': 'Eliminando...',
  'label/add_progress': 'Añadiendo...',
  'label/searching2': 'Buscando...',
  'label/adress_zipcode': 'Código Postal',
  'label/adress_streeet': 'Calle/Avenida',
  'label/adress_number': 'Número',
  'label/adress_complement': 'Complemento',
  'label/address_neighborhood': 'Barrio',
  'label/adress_city': 'Ciudad',
  'label/adress_state': 'Estado',
  'label/clinic': 'Clínica',
  'label/address': 'Dirección',
  'label/name': 'Nombre',
  'label/email': 'Correo electrónico',
  'label/profession': 'Profesión',
  'label/number': 'Número',
  'label/date_and': 'y',
  'label/months': 'Meses',
  'label/days': 'días',
  'label/tag': 'Etiqueta',
  'label/tag_plural': 'Etiquetas',
  'label/date': 'Fecha',
  'label/payment_method_short': 'Forma',
  'label/payment_method': 'Forma de Pago',
  'label/month': 'Mes',
  'label/year': 'Año',
  'label/private': 'Privado',
  'label/value': 'Valor',
  'label/patient': 'Paciente',
  'label/service': 'Servicio',
  'label/doctor': 'Doctor',
  'label/doctors': 'Doctores',
  'label/doctor_not_found': 'Doctor no encontrado',
  'label/room': 'Habitación',
  'label/notes': 'Observaciones',
  'label/today': 'Hoy',
  'label/type': 'Tipo',
  'label/total': 'Total',
  'label/cpf': 'Tax ID',
  'label/last_change': 'Última Modificación',
  'label/gender': 'Sexo',
  'label/gender_male': 'Hombre',
  'label/gender_female': 'Mujer',
  'label/balance': 'Saldo',
  'label/status': 'Estado',
  'label/resume': 'Resumen',
  'label/ammount': 'Cantidad',
  'label/agenda': 'Agenda',
  'label/scheduled': 'Programadas',
  'label/quantity': 'Cantidad',
  'label/average_value': 'Valor Medio',
  'label/table_value': 'Valor de Tabla',
  'label/patient_record': 'Registro',
  'label/records': 'Expediente',
  'label/financial': 'Financiero',
  'label/indicators': "Indicadores 'Beta'",
  'label/details': 'Detalles',
  'label/years': 'Años',
  'label/status_forecasted': 'Previsto',
  'label/status_confirmed': 'Contratado',
  'label/status_canceled': 'Cancelado',
  'label/status_health_insurance': 'Seguro de Salud',
  'label/status_unverified': 'No verificado',
  'label/status_verified': 'Verificado',
  'label/text': 'Texto',
  'label/status_on_debt': 'En deuda',
  'label/patient_name': 'Nombre del Paciente',
  'label/patient_cpf': 'Tax ID del Paciente',
  'label/council_doc': 'Documento Profesional',
  'label/saving': 'Guardando...',
  'label/saving_simple': 'Guardando',
  'label/inviting': 'Invitando',
  'label/patient_deleted': 'Paciente eliminado',
  'label/patient_deleted_in_day': 'el día',
  'label/patient_deleted_by': 'Eliminado por',
  'label/recover_patient': 'RECUPERAR PACIENTE',
  'label/sending': 'Enviando...',
  'label/loading': 'Cargando...',
  'label/medications': 'Medicamentos',
  'label/options': 'Opciones',
  'label/processing': 'Procesando...',
  'label/attachment': 'Adjunto',
  'label/phone': 'Teléfono',
  'label/credit_card': 'Tarjeta de Crédito',
  'label/boleto': 'Boleto o PIX',
  'label/wait': 'Espere',
  'label/wating': 'Esperando...',
  'label/mobile_phone': 'Teléfono Móvil',
  'label/profession_spec': 'Profesión/Especialidad',
  'label/manager': 'Administrador',
  'label/contact': 'Contacto',
  'label/full_name': 'Nombre Completo',
  'label/birth_date': 'Fecha de Nacimiento',
  'label/print_configs': 'Configuraciones de Impresión',
  'label/blocked_permission_patient_title': 'No tienes permiso para acceder a este registro del paciente.',
  'label/blocked/hint/global': 'Habla con el responsable de tu clínica.',
  // Actions
  'action/all_doctors': 'Todos los Doctores',
  'action/filter': 'Filtrar',
  'action/reload': 'Recargar',
  'action/cancel': 'Cancelar',
  'action/back': 'Volver',
  'action/save': 'Guardar',
  'action/save_print': 'Guardar e Imprimir',
  'action/saved': 'Guardado',
  'action/all_patients': 'Todos los Pacientes',
  'action/download_report': 'Descargar Informe',
  'action/share': 'Compartir',
  'action/print': 'Imprimir',
  'action/view_patient': 'Ver Paciente',
  'action/view_medical_record': 'Ver Expediente',
  'action/edit': 'Editar',
  'action/invite': 'Invitar',
  'action/delete': 'Eliminar',
  'action/see_more': 'Ver más',
  'action/see_less': 'Ver menos',
  'action/add': 'Añadir',
  'action/contact': 'Ponerse en contacto',
  'action/talk_support': 'Hablar con el soporte',
  'action/exit': 'Salir',
  'action/select_one_doctor': 'Seleccione un Doctor',
  'action/expand': 'Mostrar más',
  'action/hide': 'Ocultar',
  'action/hide_list': 'Ocultar',
  'action/show_list': 'Revelar',
  'action/change': 'Cambiar',
  // Messages
  'msg/contact_support': 'Póngase en contacto con el soporte',
  'msg/required_field': 'Campo requerido',
  'msg/invalid_cpf': 'Tax ID inválido',
  // Global Errors
  'error/date': 'Fecha inválida.',
  'error/email': 'Dirección de correo electrónico inválida.',
  'error/email_empty': 'Introduce un correo electrónico.',
  'error/invalid_email': 'Dirección de correo electrónico inválida.',
  'error/empty': 'No puede estar vacío',
  'error/name': 'Indica un nombre',
  'error/tag_name': 'Introduce el nombre de esta etiqueta',
  'error/invalid_hour': 'Hora inválida',
  'error/full_name': 'Indica el nombre completo',
  'error/profession_spec': 'Selecciona una profesión/especialidad',
  'error/profession': 'Indica una profesión',
  'error/password': 'Introduce una contraseña',
  'error/invalid_password': 'Contraseña inválida',
  'error/cpf': 'Tax ID Inválido',
  'error/phone': 'Indica un teléfono',
  'error/cep': 'Indica un CEP válido',
  'error/street': 'Indica el nombre de la calle',
  'error/addr_num': 'Indica el número',
  'error/neighborhood': 'Indica tu barrio',
  'error/city': 'Indica una ciudad',
  'error/state': 'Selecciona un estado',
  'error/credit_card_num': 'Indica el número de tarjeta',
  'error/credit_card_name': 'Indica el nombre del titular',
  'error/credit_card_cvv': 'Indica el CVV',
  'error/credit_card_month': 'Indica el mes',
  'error/credit_card_year': 'Indica el año',
  'error/invalid_credit_card': 'Tarjeta inválida',
  'error/email_invalid': 'Correo electrónico inválido.',
  'error/request': 'Ocurrió un error al ejecutar esta solicitud',
  'error/request_internet': 'Ocurrió un error. Verifica tu conexión a internet.',
  // print settings
  'error/validation/print/margin_top': 'Introduce un número entre 45 y 150',
  'error/validation/print/margin_bottom': 'Introduce un número entre 60 y 150',
  'error/validation/print/margin_left': 'Introduce un número entre 0 y 100',
  // Formats and masks
  'format/week_date_01': 'dddd, DD [de] MMM [de] YYYY',
  'format/week_date_01_at': 'ddd, DD [de] MMM [de] YYYY [a las]',
  'format/date_01': 'DD [de] MMM [de] YYYY',
  'format/date_01_at': 'DD [de] MMM [de] YYYY [a las]',
  'format/date_02': 'DD [de] MMMM',
  'format/date_02_time': 'DD [de] MMM [de] YYYY [a las] HH:mm',
  'format/dd_mm_yyyy': 'DD-MM-YYYY',
  'format/date_3': '[día] DD [de] MMMM [de] YYYY [a las] HH:mm',
  // Others
  'label/blocked_feature': 'No tienes acceso a esta funcionalidad.',
  'action/blocked_feature_upgrade': 'Hacer Upgrade',
  'message/images_saved': 'Imagen guardada',
  'message/download_error': 'Ocurrió un error al descargar este archivo',
};
