// @flow
import React from 'react';
import { map, get } from 'lodash';
import { SettingsMenu, PlatformSwitch, AppMobileHeader, AppMobileFooter } from '@blocks';
import { Button, ClickableList, Icon, DoctorColorIcon } from '@elements';
import type { User, Doctor, BundledPermissions } from '@types';
import { sort, alphabeticCompare, shortenName, createNsTranslator } from '@helpers/helpers';
import { withTranslation } from 'react-i18next';
import './DoctorsList.scss';

type Props = {
  user: User,
  doctors: Array<Doctor>,
  onLogout: Function,
  t: Function,
  permissions: BundledPermissions,
};

const renderDoctorItem = (item, key) => [
  <div className="customListItem" key={`item-${key}`}>
    <DoctorColorIcon colorId={item.color} />
    <span key="text">&nbsp;{item.text}</span>
  </div>,
  item.manager ? <Icon key={`icon-${key}`} className="icon" iconName="account-manager" /> : null,
];
class DoctorsList extends React.Component<Props> {
  render() {
    const { user, doctors, onLogout } = this.props;

    const _ = createNsTranslator('settings', this.props.t);

    const items = map<any, any>(doctors, (doctor:Doctor) => ({
      linkTo: `/ajustes/doutores/${doctor.id}`,
      customRendererData: {
        color: get(doctor, 'color'),
        name: doctor.name,
        text: shortenName(doctor.nameWithTitle),
        manager: doctor.manager,
      },
    }));

    const sortedItens = sort(items, alphabeticCompare('name'));

    const pageContent = (
      <React.Fragment>
        <div className="toolbar dashed">
          <h1>{_('label/clinic_doctors/list/title')}</h1>

          <Button className="primary" text={_('label/clinic_doctors/list/invite')} linkTo="/ajustes/doutores/novo" />

        </div>

        <ClickableList
          items={sortedItens}
          card
          customRenderer={renderDoctorItem}
        />
      </React.Fragment>
    );

    return (
      <PlatformSwitch
        inject
        desktop={() => (
          <div id="doctorsPage" className="appContent settingsPage">
            <aside className="sidebar">
              <SettingsMenu user={user} onLogout={onLogout} permissions={this.props.permissions} />
            </aside>
            <section className="mainSection">
              <div className="centerContent">
                {pageContent}
              </div>
            </section>
          </div>
        )}
        mobile={() => (
          <React.Fragment>
            <AppMobileHeader title={_('label/clinic_doctors/list/title_mobile')} backLinkTo="/ajustes" />
            <div id="doctorsPage" className="appContent settingsPage">
              <section className="mainSection">
                {pageContent}
              </section>
            </div>
            <AppMobileFooter />
          </React.Fragment>
        )}
      />
    );
  }
}

export default withTranslation()(DoctorsList);
