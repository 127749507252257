// @flow
import React from 'react';
import moment from 'moment';
import { get, first, toArray, size, toNumber } from 'lodash';
import { sort, dateCompare, createNsTranslator, alphabeticCompare, handleReportPeriods } from '@helpers/helpers';
import { Icon, Button, Loading, FormattedText, Table } from '@elements';
import { ReportsMenu, ReportPage, PlatformSwitch, AppMobileHeader, EmptyState, ReportsFilters, ConditionalFeature, BlockedReport, AppMobileFooter } from '@blocks';
import type { DoctorsState, ServicesState, FormElementData, TableColumItems } from '@types';
import { withStorage } from '@hocs';
import { withTranslation } from 'react-i18next';
import './ReportsServices.scss';

type Props = {
  onGetServices: (startDate: string, endDate: string, doctorId?: number | null, status?: string) => void,
  doctors: DoctorsState,
  services: ServicesState,
  componentStorage: Object,
  onItemClick: Function,
  onDownloadReport: (startDate: string, endDate: string, doctorId?: number | null, status?: string) => void,
  defaultDoctorId: number,
  isManager: boolean,
  isFeatureEnabled: boolean,
  reportsSelectDoctor: boolean,
  t: Function,
  isReportBlocked: boolean,
};

type State = {
  period: string,
  doctor: string,
  year: number,
  month: number,
  status: string,
  showMobileFilters: boolean,
  startDate: any,
  endDate: any,
};

class ReportsServices extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    const state = {
      period: 'today',
      doctor: this.props.defaultDoctorId || get(first(toArray(get(this.props.doctors, 'data'))), 'id'),
      month: moment().month() + 1,
      year: moment().year(),
      status: 'confirmed',
      startDate: moment().startOf('month').format('YYYY-MM-DD'),
      endDate: moment().endOf('month').format('YYYY-MM-DD'),
      ...props.componentStorage.get('filters', {}),
    };
    this.state = {
      ...state,
      month: parseInt(state.month, 10) > 0 ? state.month : moment().month() + 1,
    };
  }

  componentDidMount() {
    if (this.props.isFeatureEnabled) {
      this.handleRequestData();
    }
  }

  render() {
    const {
      doctors, services, isManager, isFeatureEnabled, reportsSelectDoctor, isReportBlocked,
    } = this.props;
    const {
      period, doctor, year, month, status, showMobileFilters, startDate, endDate,
    } = this.state;

    const maxLimit = get(services, 'maxLimit', null);
    const maxLimitReached = get(services, 'maxLimitReached', false);
    const showMaxLimitReachedMessage = maxLimitReached && maxLimit !== null;

    const _ = createNsTranslator('reports', this.props.t);

    if (isReportBlocked) {
      return (
        <BlockedReport
          backLinkTo="/relatorios"
          title={_('services/title')}
        />
      );
    }

    const isLoading = get(services, 'isLoading', false);
    const isLoadingReport = get(services, 'isLoadingReport', false);

    // Guard against potential null objects
    if (!services && this.props.isFeatureEnabled) return false;
    const summary = sort(get(services, 'summary', []), alphabeticCompare('name'));
    const servicesData = sort(get(services, ['data'], {}), dateCompare('date', 'desc'));



    const sumaryColumns: TableColumItems = [
      {
        dataIndex: 'name',
        content: _('label/service', 'global'),
      },
      {
        dataIndex: 'count',
        content: _('label/quantity'),
      },
      {
        dataIndex: 'sumPrices',
        content: _('label/total'),
        onRenderItem: (item) => <FormattedText type="currency" text={item.sumPrices} />,
      },
      {
        dataIndex: 'averagePrice',
        content: _('label/average_value'),
        onRenderItem: (item) => <FormattedText type="currency" text={item.averagePrice} />,
      },
      {
        dataIndex: 'templatePrice',
        content: _('label/table_value'),
        onRenderItem: (item) => <FormattedText type="currency" text={item.templatePrice} />,
      },
    ];

    const servicesColumns: TableColumItems = [
      {
        dataIndex: 'date',
        content: _('label/date', 'global'),
        onRenderItem: (service) => <FormattedText type="date" text={service.date} />,
      },
      {
        dataIndex: 'patientName',
        content: _('label/patient', 'global'),
      },
      {
        dataIndex: 'name',
        content: _('label/service', 'global'),
      },
      {
        dataIndex: 'doctorName',
        content: _('label/doctor', 'global'),
        hidden: doctor !== 'all',
      },
      {
        dataIndex: 'averagePrice',
        content: _('label/value', 'global'),
        onRenderItem: (service) => <FormattedText type="currency" text={service.price} />,
      },
      {
        dataIndex: 'id',
        content: <React.Fragment>&nbsp;</React.Fragment>,
        className: 'actionCell',
        desktopOnly: true,
        onRenderItem: () => <span className="linkAction">{_('action/view_patient', 'global')}</span>,
      },
    ];

    const isAllEmpty = size(summary) === 0 && size(servicesData) === 0;


    return (
      <PlatformSwitch
        className="platformSwitcher"
        desktop={() => (
          <div id="reportsServicesPage" className="appContent reportsPage">
            <ReportsMenu />

            <section className="mainSection">
              <ConditionalFeature enabled={isFeatureEnabled} transparent>
                <div className="centerContent">
                  <div className="toolbar">
                    <h1>{_('services/title')}</h1>
                    <div className="actions">
                      <Button disable={isAllEmpty || isLoadingReport} onClick={this.handleDownloadReport}>
                        <Icon iconName="download" className="icon" size={18} />
                        <span>{_('action/download_report', 'global')}</span>
                      </Button>
                    </div>
                  </div>

                  {showMaxLimitReachedMessage && (
                    <p className="alertInfo">
                      {String(_('services/max_limit_reached')).replace('{maxLimit}', maxLimit)}
                    </p>
                  )}

                  <div className="desktopCard">
                    <ReportsFilters
                      reportsSelectDoctor={reportsSelectDoctor}
                      isManager={isManager}
                      doctors={doctors}
                      isLoading={isLoading}
                      onChangeFilter={this.handleFilterChange}
                      doctor={doctor}
                      period={period}
                      startDate={startDate}
                      endDate={endDate}
                      year={year}
                      month={month}
                      status={status}
                    />
                    <React.Fragment>
                      {isAllEmpty && !isLoading ?
                        <EmptyState onReportFilter={this.toggleMobileFilter} title={_('services/empty_title')} hint={_('services/empty_hint')} />
                        :
                        <React.Fragment>
                          {!isLoading && size(summary) > 0 &&
                          <section>
                            <div className="toolbar">
                              <h2>Resumo</h2>
                            </div>
                            <Table
                              itemKey="date"
                              columns={sumaryColumns}
                              fullTable
                              isLoading={isLoading}
                              items={summary}
                              emptyMessage={_('services/empty_title')}
                            />
                          </section>
                          }

                          <section>
                            {!isLoading &&
                              <div className="toolbar">
                                <h2>{_('services/hired_list')}</h2>
                              </div>
                            }
                            {isLoading && <Loading />}
                            {!isLoading &&
                            <Table
                              itemKey="id"
                              columns={servicesColumns}
                              fullTable
                              isLoading={isLoading}
                              items={servicesData}
                              mobileClickLabel={_('action/view_patient', 'global')}
                              emptyMessage={_('services/empty_title')}
                              onItemClick={this.onItemClick}
                            />
                            }
                          </section>
                        </React.Fragment>
                    }
                    </React.Fragment>
                  </div>
                </div>
              </ConditionalFeature>
            </section>
          </div>
        )}
        mobile={() => (
          <React.Fragment>
            <AppMobileHeader title={_('services/title')} backLinkTo="/relatorios">
              <div className="headerActions">
                {!isAllEmpty && !isLoadingReport && (
                  <Icon iconName="download" className="icon" onClick={this.handleDownloadReport} />
                )}
                {(!isAllEmpty && isLoadingReport) && (
                  <Icon iconName="download" className="icon" />
                )}
                <Icon iconName="filter" className="icon" onClick={this.toggleMobileFilter} />
                {/* <Icon iconName="refresh" className="icon" onClick={this.onRefreshClick} /> */}
              </div>
            </AppMobileHeader>
            <ReportPage
              id="reportsServicesPage"
              filters={() => showMobileFilters && (
                <ReportsFilters
                  isMobile
                  reportsSelectDoctor={reportsSelectDoctor}
                  isManager={isManager}
                  doctors={doctors}
                  isLoading={isLoading}
                  onChangeFilter={this.handleFilterChange}
                  doctor={doctor}
                  period={period}
                  year={year}
                  month={month}
                  startDate={startDate}
                  endDate={endDate}
                  status={status}
                  onCloseMobileModal={this.toggleMobileFilter}
                />)}
              isFeatureEnabled={isFeatureEnabled}
              isLoading={isLoading}
              emptyState={() => isAllEmpty && (
                <EmptyState
                  onReportFilter={this.toggleMobileFilter}
                  title={_('services/empty_title')}
                  hint={_('services/empty_hint')}
                />)}
              noPadding
            >
              {!isLoading && size(summary) > 0 &&
                <ReportPage.Table title="Resumo">
                  <Table
                    itemKey="date"
                    columns={sumaryColumns}
                    fullTable
                    isLoading={isLoading}
                    items={summary}
                    emptyMessage={_('services/empty_title')}
                  />
                </ReportPage.Table>
              }
              <ReportPage.Table title={_('services/hired_list')}>
                <Table
                  itemKey="id"
                  columns={servicesColumns}
                  fullTable
                  isLoading={isLoading}
                  items={servicesData}
                  mobileClickLabel={_('action/view_patient', 'global')}
                  emptyMessage={_('services/empty_title')}
                  onItemClick={this.onItemClick}
                />
              </ReportPage.Table>
            </ReportPage>
            <AppMobileFooter />
          </React.Fragment>
        )}
      />
    );
  }

  handleFilterChange = (data: FormElementData) => {
    if (data.name === 'month') {
      let m = parseInt(get(data, 'value', ''), 10);
      m = m > 0 ? m : moment().month() + 1;
      m = m <= 12 ? m : moment().month() + 1;
      this.setState({ [data.name]: m }, this.handleRequestData);
    } else {
      this.setState({ [data.name]: get(data, 'value', '') }, this.handleRequestData);
    }
  };

  handleMomentFilterChange = (data: FormElementData) => {
    this.setState({ [data.name]: moment(get(data, 'value', '')).format('YYYY-MM-DD') }, this.handleRequestData);
  };

  handleRequestData = () => {
    const {
      doctor, period, month, year, status, startDate, endDate,
    } = this.state;
    this.props.componentStorage.set('filters', { ...this.state, showMobileFilters: false });
    const doctorFilter = doctor !== 'all' ? toNumber(doctor) : null;

    const reportPeriod = handleReportPeriods(startDate, endDate, period, month, year);

    this.props.onGetServices(
      reportPeriod.startDate,
      reportPeriod.endDate,
      doctorFilter, status,
    );
  };

  handleDownloadReport = () => {
    const {
      doctor, period, month, year, status, startDate, endDate,
    } = this.state;
    this.props.componentStorage.set('filters', { ...this.state, showMobileFilters: false });

    const doctorFilter = doctor !== 'all' ? toNumber(doctor) : null;

    const reportPeriod = handleReportPeriods(startDate, endDate, period, month, year);

    this.props.onDownloadReport(
      reportPeriod.startDate,
      reportPeriod.endDate,
      doctorFilter, status,
    );
  };

  onRefreshClick = () => {
    this.handleRequestData();
  };

  toggleMobileFilter = () => this.setState({ showMobileFilters: !this.state.showMobileFilters });

  onItemClick = (service: any) => this.props.onItemClick(service.patientId);
}


export default withTranslation()(withStorage('reportsFiltersV3', 'reportsServices')(ReportsServices));
