export default {
  'label/plan_concierge': 'Concierge',
  'label/plan_complete': 'Completo',
  'label/plan_basic': 'Básico',
  'label/plan_clinic': 'Clínica',
  'label/payment_interval': 'mês',
  'label/plan_not_selected': 'Não Selecionado',
  'label/end_of_trial_counter': 'Seu período de teste acabou.',
  'label/end_of_trial_subscribe_now': 'Assine Agora!',
  'label/trialing_counter': 'Restam {0} para fim do perído de testes.',
  'label/trialing_subscribe_now': 'Assine agora!',
  'label/message_essential': 'Com o Doutore você tem o essencial.',
  'label/agenda_promo': 'Acesse as consultas agendadas por você ou por sua secretária de qualquer lugar.',
  'label/records_promo': 'Tão prático quanto papel e caneta. 100% digital, seguro e com acesso rápido de qualquer lugar.',
  'label/all_plans_includes': 'Todos os Planos Incluem',
  'label/unlimited_patients_and_secretaries': 'Pacientes e Secretárias Ilimitados',
  'label/unlimited_storage': 'Armazenamento Ilimitado',
  'label/pc_ios_and_android': 'Acesso pelo computador e pelos aplicativos iOS e Android',
  'label/training': 'Treinamento',
  'label/month_price': 'mês',
  'label/continue': 'Continuar',
  'label/subscription_data_title': 'Dados para Assinatura',
  'label/name_input': 'Nome Completo',
  'label/credit_card_number': 'Número do cartão',
  'label/cvv': 'Código de Segurança',
  'label/expiration_date': 'Validade',
  'label/credit_card_name': 'Nome como está no cartão',
  'label/security_text_1': 'Suas informações estão seguras!',
  'label/security_text_2': 'Fique tranquilo. Seus dados não são guardados no nosso servidor - eles são enviados diretamente para a Instituição Financeira que fará a cobrança.',
  'label/regulatory_text/explanation': 'Essa é uma compra internacional, sujeita a uma operação de câmbio, que vai ser processada pelo EBANX de acordo com estes ',
  'label/regulatory_text/terms': 'Termos e Condições',
  'label/regulatory_text/agreement': '. Ao continuar, você reconhece e aceita os termos desta transação. Todos os impostos e IOF já estão includos do valor da assinatura.',
  'label/bitcoin/paymnent_info/text_1': 'O Doutore entende que moedas digitais já fazem parte do presente da sociedade e fará cada vez mais. Por isso, somos a primeira ferramenta para Consultórios e Clínicas a oferecer pagamento via Bitcoin.',
  'label/bitcoin/paymnent_info/text_2': 'Se você tem interesse, entre em contato com a gente. Teremos todo o prazer de ajudá-lo a assinar o Doutore com Bitcoin.',
  'label/credit_card/payment_success_title': 'Tudo certo! Você já pode usar o Doutore :)',
  'label/credit_card/payment_success_text': 'Sua assinatura já está ativa! Qualquer sugestão, dúvida ou problema, nosso canal de atendimento via email estará sempre disponível.',
  'label/boleto/payment_success_title': 'Boleto / Código PIX gerado!',
  'label/boleto/payment_success_subtitle': 'O boleto será enviado em alguns minutos para o seu email',
  'label/boleto/payment_success_text_0': 'Sua assinatura está ativa mas será cancelada se o pagamento não for identificado no prazo de',
  'label/boleto/payment_success_text_1': '3 dias úteis',
  'label/boleto/paymnent_info/title': 'O pagamento via Boleto/PIX não ativará sua conta imediatamente.',
  'label/boleto/paymnent_info/days': 'O Boleto Bancário demora 3 dias úteis para ser processado.',
  'label/boleto/paymnent_info/text': 'Se desejar ativar sua conta imediatamente, utilize cartão de crédito ou envie o comprovante de pagamento do boleto para o suporte do Doutore.',
  'action/view_plans': 'Conhecer Planos',
  'action/select_a_plan': 'Escolha um Plano',
  'action/choose_plan': 'Assinar',
  'action/boleto_signup': 'Assinar com Boleto',
  'action/payment_success_back': 'Voltar ao Doutore',
  'action/goto_payment': 'Ir para Forma de Pagamento',
  'action/subscribe_with_credit_card': 'Assinar com Cartão',
  'label/quest_have_clinic': 'Você possui uma Clínica?',
  'action/contact': 'Entre em contato',
  'label/plans': 'Planos',
  'action/restore_purchases': 'Restaurar Compra',
  'placeholder/mmaa': 'MM/AA',
  'label/select_plan_loading': 'Enviando',
  'label/stripe/payment_success_title': ' Sua assinatura está sendo processada!',
  'label/stripe/payment_success_subtitle': 'Entraremos em contato pelo seu email para enviar o link de pagamento',
  'label/ios_payment_text': `O pagamento será feito através da sua conta do iTunes.
  A renovação é feita automaticamente num período de 24 horas anterior ao último dia do serviço. A renovação automática pode ser cancelada a qualquer momento.
  É só ir até a página de configurações na loja iTunes depois da compra.`,
};
