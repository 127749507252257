// @flow
import React from 'react';
import { compose } from 'redux';
import { get } from 'lodash';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { ServicesNewListModal } from '@views';
import { actions as modalActions } from '@context/modals';
import { serviceActions } from '@context/services';
import { serviceTemplateActions } from '@context/serviceTemplates';
import type { Dispatch, ServicesState, ServiceCreateInput } from '@types';
import { router } from '@router';
import { withConfirmOnClose } from '@hocs';
import { isSecretary, getServicesMenuLinkId } from '@helpers/helpers';

type Props = {
  dispatch: Function,
  doctorId: number,
  modalDoctorId: number,
  selectedDoctorId: number,
  patientId: number,
  isLoading: boolean,
  serviceTemplates: ServicesState,
  setConfirmation: Function,
  closeModal: Function,
  doctorName: string,
  showDoctorName: boolean,
  isDoctor: boolean,
  isSecretary: boolean,
  doctorIds: number[],
};

class ServicesNewListModalContainer extends React.Component<Props> {
  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(serviceTemplateActions.getServiceTemplates(this.getDoctorId()));
  }

  render() {
    const {
      serviceTemplates, isLoading, setConfirmation, doctorName,
      showDoctorName,
    } = this.props;
    return (
      <ServicesNewListModal
        onNewServiceClick={this.handleNewServiceClick}
        onClose={this.closeModal}
        serviceTemplates={serviceTemplates}
        onSaveTemplates={this.handleSaveServices}
        isLoading={isLoading}
        onSetConfirmation={setConfirmation}
        onEditServices={this.handleEditServices}
        doctorName={showDoctorName ? doctorName : null}
        onCreateService={this.handleNewServiceTemplateClick}
      />
    );
  }

  closeModal = this.props.closeModal;

  handleNewServiceClick = () => {
    const { dispatch } = this.props;
    dispatch(modalActions.openModal('ServiceFormModal'));
  };

  handleNewServiceTemplateClick = (name: string) => {
    const { dispatch, selectedDoctorId, doctorId } = this.props;
    dispatch(modalActions.openModal('ServiceFormModal', {
      isNewTemplate: true,
      name,
      doctorId: selectedDoctorId || doctorId,
    }));
  };

  handleSaveServices = (services: Array<ServiceCreateInput>) => {
    const { dispatch, patientId } = this.props;
    if (services.length > 0) {
      dispatch(serviceActions.createServices(patientId, services));
    }
    this.closeModal(true);
  };

  handleEditServices = () => {
    const {
      dispatch, doctorId, isDoctor, doctorIds, modalDoctorId,
    } = this.props;
    dispatch(router.pushPath('serviceTemplatesList', { doctorId: getServicesMenuLinkId(isDoctor, doctorId || modalDoctorId, doctorIds) }));
    this.closeModal(true);
  };

  getDoctorId = () => (!this.props.isSecretary && this.props.doctorId ? this.props.doctorId : this.props.selectedDoctorId);
}

const mapDispatchToProps = (dispatch: Dispatch) => ({ dispatch });

const mapStateToProps = (state, ownProps) => ({
  patientId: parseInt(get(ownProps, ['match', 'params', 'id']), 10),
  serviceTemplates: get(state, ['context', 'serviceTemplates']),
  isLoading: get(state, ['context', 'serviceTemplates', 'isLoading'], false),
  doctorId: get(state, ['context', 'user', 'advancedData', 'doctorId'], null),
  doctorName: get(state, ['context', 'modals', 'data', 'doctorName'], null),
  modalDoctorId: get(state, ['context', 'modals', 'data', 'doctorId'], null),
  selectedDoctorId: get(state, ['context', 'modals', 'data', 'doctorId'], null),
  showDoctorName: isSecretary(state),
  isManager: get(state, ['context', 'user', 'advancedData', 'manager'], null),
  doctorIds: get(state, ['context', 'clinics', 'activeClinic', 'doctorIds'], []),
  isDoctor: get(state, ['context', 'user', 'roleType'], '') === 'Doctor',
  isSecretary: isSecretary(state),
});

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withConfirmOnClose(),
)(ServicesNewListModalContainer);
