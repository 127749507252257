// @flow
import React from 'react';
import { get } from 'lodash';
import { connect } from 'react-redux';
import { PricingIos as PricingIosView } from '../components/views';
import type { CordovaPurchaseProduct } from '../types';
// import { router } from '../routes/router';
import { getSubscriptionTrack } from '../redux/modules/context/subscription';
import { order, restorePurchases } from '../redux/modules/context/cordovaPurchase';


type Props = {
  dispatch: Function,
  products: Array<CordovaPurchaseProduct>,
  status: string,
  userId: number,
};

class PricingIos extends React.Component<Props> {
  componentWillMount() {
    this.props.dispatch(getSubscriptionTrack('pricing'));
  }
  render() {
    return (
      <PricingIosView
        onSelectPlan={this.handleSelectPlan}
        onRestorePurchases={this.handleRestorePurchases}
        products={this.props.products}
        status={this.props.status}
      />
    );
  }

  handleSelectPlan = (productID: any) => {
    const { dispatch, userId } = this.props;
    dispatch(order({
      productID,
      applicationUsername: String(userId),
    }));
    // dispatch(router.pushPath('subscriptionAddress'));
  }

  handleRestorePurchases = () => {
    const { dispatch, userId } = this.props;
    dispatch(restorePurchases(String(userId)));
    // dispatch(router.pushPath('subscriptionAddress'));
  }
}

const mapStateToProps = (state) => ({
  products: get(state, ['context', 'cordovaPurchase', 'products']),
  status: get(state, ['context', 'cordovaPurchase', 'status']),
  userId: get(state, ['context', 'user', 'id']),
});

export default connect<any, any, any, any, any, any>(
  mapStateToProps,
)(PricingIos);
