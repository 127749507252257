// @flow
import React from 'react';
import moment from 'moment';
import { withTranslation } from 'react-i18next';
import {
  get, map, toString,
  find, orderBy, includes,
  toArray, isEmpty, filter,
  isEqual, isArray, forEach,
} from 'lodash';
import {
  AppMobileHeader, PlatformSwitch, PatientWidgets,
  Records, FutureRecords, DrugsPrescriptions,
  EmptyState, PatientSummary, PatientMobileHeader, AppMobileFooter, BlockedFeature,
} from '@blocks';
import {
  Button, ScrollUP, Icon,
  Dropdown, Loading, HiddenLines,
} from '@elements';
import { LoadingPage } from '@views';
import type {
  RecordsState, FutureRecordsState, Patient,
  DrugsPrescriptionsState, ExamsPrescriptionsState, DocumentsState,
  CustomFormsState, CustomFormTemplatesState, AttachmentsState,
  PlanInclude, PermissionTo,
} from '@types';
import classNames from 'classnames';
import { Log } from '@helpers';
import { sort, dateCompare, isPresent, createNsTranslator, handleMomenti18n, parteHtmlToReact as parseHtml, numberCompare } from '@helpers/helpers';
import AttachmentView from './Attachment/Attachment';
import PatientLayout from '../../../../layouts/PatientLayout';
import './PatientTimeline.scss';
import './Widgets/Widgets.scss';

type State = {
  selectedFilter: number,
  mobileWidgetVisible: boolean,
  mobileDetailsVisible: boolean,
  pdfPreviewDoc: ?string;
  pdfName: string,
};

type Props = {
  patient?: Patient,
  clinicId: number,
  userEmail: string,
  isPatientPrinting?: boolean,
  records: ?RecordsState,
  futureRecords: ?FutureRecordsState,
  newRecords: Array<number>,
  drugsPrescriptions: DrugsPrescriptionsState,
  examsPrescriptions: ExamsPrescriptionsState,
  documents: DocumentsState,
  customForms: CustomFormsState,
  customFormTemplates: CustomFormTemplatesState,
  onNewDocumentClick: Function,
  onDirectShare: (recordId: number, resource: string, resourceId: number, respondWithUrls: (wtt: string | null, mail: string | null) => void) => void,
  onEditDocumentClick: Function,
  onPrintDocumentClick: Function,
  onNewPrescriptionDrugsClick: Function,
  onEditPrescriptionDrugsClick: Function,
  onPrintPrescriptionDrugsClick: Function,
  onNewPrescriptionExamsClick: Function,
  onEditPrescriptionExamsClick: Function,
  onPrintPrescriptionExamsClick: Function,
  onNewCustomFormClick: Function,
  onEditCustomFormClick: Function,
  onPrintCustomFormClick: Function,
  onMedicationsFormClick: Function,
  onUpdateRecordDate: Function,
  onSetEditMode: Function,
  onProblemsFormClick: Function,
  onTagsFormClick: Function,
  onCreateRecord: Function,
  onAutosaveRecord: Function,
  onSaveRecord: Function,
  onChangeVisibilityLevel: Function,
  onDeleteRecord: Function,
  onSelectFile: Function,
  onPrintSingleRecord: Function,
  onPrintAllRecords: Function,
  onDeleteAttachment: Function,
  onUpdateVisibility: Function,
  onSaveHistoryNote: Function,
  onDisplayAttachmentFileName: Function,
  onRestoreRecord: Function,
  onRemoveTag: Function,
  attachments: AttachmentsState,
  historyNoteLoading: boolean,
  isRecordCreating: boolean,
  recordDeletingIds: Object,
  recordsEditModesList: Object,
  updatingIds: Object,
  isRecordLoading: boolean,
  planInclude: PlanInclude,
  onSelectPatientProfileImage: Function,
  onDeletePatientProfileImage: Function,
  onDownloadAttachment: Function,
  onAddMemedId: Function,
  isMemedLoaded: boolean,
  memedToken: string,
  onPrintMemedPrescription: Function,
  onDeleteMemedId: Function,
  t: Function,
  i18n: Object,
  permissions:PermissionTo,
};

type FilterType = {
  id: number,
  text: string,
};


const filterItemsCreator = (_: Function, customForms: boolean) => [
  { id: 1, text: _('action/filter/all_records') },
  { id: 2, text: _('action/filter/my_records') },
  { id: 10, text: _('action/filter/not_empty') }, // onlyNotEmpty
  { id: 3, text: _('action/filter/attachments') },
  { id: 4, text: _('action/filter/prescriptions') },
  { id: 6, text: _('action/filter/exams') },
  { id: 5, text: _('action/filter/docs') },
  ...(customForms ? [{ id: 8, text: _('action/filter/forms') }] : []),
  { id: 7, text: _('action/filter/deleted') },
];

// Avoid header and widgets redrawing on mobile when changes an record
const MobileHeaderRender = React.memo(({
  mobileWidgetVisible, patient, onMedicationsFormClick, onProblemsFormClick, onTagsFormClick,
  onSaveHistoryNote, historyNoteLoading, onRemoveTag, planInclude, mobileDetailsVisible,
  handleToggleMobileWidgets, handleCloseDetailsWidgets,
}: any) => (
  <React.Fragment>
    <PatientWidgets
      isMobile
      visible={mobileWidgetVisible}
      patient={patient}
      onMedicationsFormClick={onMedicationsFormClick}
      onProblemsFormClick={onProblemsFormClick}
      onTagsFormClick={onTagsFormClick}
      onSaveHistoryNote={onSaveHistoryNote}
      historyNoteLoading={historyNoteLoading}
      onRemoveTag={onRemoveTag}
      planInclude={planInclude}
      onMobileBackClick={handleToggleMobileWidgets}
    />

    <PatientSummary
      patient={patient}
      onCloseMobileModal={handleCloseDetailsWidgets}
      mobileVisible={mobileDetailsVisible}
    />
  </React.Fragment>
), isEqual);

class PatientTimeline extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.imagesFetchIntervals = {};
    this.state = {
      selectedFilter: 1,
      mobileWidgetVisible: false,
      mobileDetailsVisible: false,
      pdfPreviewDoc: null,
      pdfName: '',
    };
  }
  componentWillMount() {
    document.addEventListener('touchstart', this.handleSwipeStart);
    document.addEventListener('touchend', this.handleSwipeEnd);
  }

  shouldComponentUpdate(nextProps: Props, nextState: State) {
    return !isEqual(this.props, nextProps) || !isEqual(this.state, nextState);
  }

  componentWillUnmount() {
    document.addEventListener('touchstart', this.handleSwipeStart);
    document.addEventListener('touchend', this.handleSwipeEnd);
  }

  render() {
    const { selectedFilter } = this.state;
    const {
      patient,
      clinicId,
      userEmail,
      isPatientPrinting,
      records,
      futureRecords,
      drugsPrescriptions,
      examsPrescriptions,
      documents,
      customForms,
      customFormTemplates,
      newRecords,
      attachments,
      onNewDocumentClick,
      onEditDocumentClick,
      onPrintDocumentClick,
      onNewPrescriptionDrugsClick,
      onEditPrescriptionDrugsClick,
      onPrintPrescriptionDrugsClick,
      onNewPrescriptionExamsClick,
      onEditPrescriptionExamsClick,
      onPrintPrescriptionExamsClick,
      onNewCustomFormClick,
      onEditCustomFormClick,
      onPrintCustomFormClick,
      onMedicationsFormClick,
      onProblemsFormClick,
      onTagsFormClick,
      onAutosaveRecord,
      onSaveRecord,
      onChangeVisibilityLevel,
      onDeleteRecord,
      onSelectFile,
      onPrintSingleRecord,
      onPrintAllRecords,
      onDeleteAttachment,
      onSaveHistoryNote,
      historyNoteLoading,
      onRemoveTag,
      isRecordCreating,
      recordDeletingIds,
      isRecordLoading,
      onUpdateVisibility,
      onSetEditMode,
      recordsEditModesList,
      updatingIds,
      planInclude,
      isMemedLoaded,
      onAddMemedId,
      memedToken,
      onPrintMemedPrescription,
      onDeleteMemedId,
      onSelectPatientProfileImage,
      onDeletePatientProfileImage,
      permissions,
      onDownloadAttachment,
      onDisplayAttachmentFileName,
      onUpdateRecordDate,
      onDirectShare,
    } = this.props;

    // Wait for patient data before showing page
    if (!patient) {
      return <LoadingPage />;
    }

    const _ = createNsTranslator('timeline', this.props.t);

    const hideTimeLine = (isMobile: boolean) => !planInclude.timeline && (
      <div style={{ width: '100%', height: isMobile ? '100%' : 'auto' }}>
        <BlockedFeature
          simpleStyle
          iconName="lock"
          iconSize={400}
        />
      </div>
    );

    handleMomenti18n(this.props.i18n, moment);

    const filterItems = filterItemsCreator(_, planInclude.customForms);

    const lastFetch = get(records, ['lastFetchFor', toString(patient.id)], null);
    const lastFutureFetch = get(records, ['lastFutureFetchFor', toString(patient.id)], null);

    const selectedItem = find(filterItems, (item) => (item.id === selectedFilter));

    let showFutureRecords = true;
    let noResultsMessage = _('label/no_results_records');

    const recordIds = get(records, ['byPatient', toString(patient.id)]);
    let recordsCollection = [];

    let drugsPrescriptionsCollection = [];
    let onlyAttachments = false;
    let onlyPrescriptions = false;

    // Order descending full records collection and then get just sorted ids declared for selected patient
    const sortedRecords = orderBy(toArray(get(records, 'data')), [(o) => (moment(o.date).unix())], ['desc']);

    const sortedRecordsIds = [];
    map(sortedRecords, (sortedRecord) => {
      if (includes(recordIds, sortedRecord.id)) {
        sortedRecordsIds.push(sortedRecord.id);
      }
    });

    map(sortedRecordsIds, (recordId) => {
      let addRecord = false;

      switch (selectedFilter) {
        case 2:
          const isMine = get(records, ['data', recordId, 'details', 'isMine']);
          if (isMine) {
            addRecord = true;
          }
          break;
        case 3:
          const imageAttachments = get(records, ['data', recordId, 'details', 'imageAttachments'], []);
          const fileAttachments = get(records, ['data', recordId, 'details', 'fileAttachments'], []);
          const hasAttachments = imageAttachments.length > 0 || fileAttachments.length > 0;
          if (hasAttachments) {
            addRecord = true;
            onlyAttachments = true;
          }
          showFutureRecords = false;
          noResultsMessage = _('label/no_results_attachment');
          break;
        case 4:
          const drugsPrescriptionsData = [];
          const recordsData = get(records, 'data', {});
          map(recordsData, (record) => {
            const recordDrugsPrescriptionsIds = get(record, ['details', 'drugsPrescriptions'], []);
            if (recordDrugsPrescriptionsIds.length > 0) {
              map(recordDrugsPrescriptionsIds, (prescriptionId) => {
                const drugsPrescription = {
                  ...get(drugsPrescriptions, ['data', prescriptionId]),
                  record,
                };
                drugsPrescriptionsData.push(drugsPrescription);
              });
            }
          });

          // drugsPrescriptionsCollection = drugsPrescriptionsData;

          drugsPrescriptionsCollection = filter(drugsPrescriptionsData,
            item => parseInt(get(item, ['record', 'patientId']), 10) === parseInt(get(patient, 'id'), 10),
          );

          if (drugsPrescriptionsData.length > 0) {
            addRecord = true;
            onlyPrescriptions = true;
          }
          showFutureRecords = false;
          noResultsMessage = _('label/no_results_prescription');
          break;
        default:
          addRecord = true;
          break;
      }

      if (addRecord) {
        const record = get(records, ['data', recordId]);
        recordsCollection.push(record);
      }
    });

    const emptyCollection = [];

    const onlyNotEmpty = selectedFilter === 10;

    if (onlyNotEmpty) {
      forEach(get(this.props.records, 'data', []), (recordItem) => {
        const noUndef = (collection) => filter(collection, it => !!it);
        const testContent = isPresent(get(recordItem, ['details', 'content'], ''));
        const recordCustomForms = noUndef(map(get(recordItem, ['details', 'customForms'], []), (itemId) => get(this.props.customForms, ['data', itemId])));
        const recordDocuments = noUndef(map(get(recordItem, ['details', 'documents'], []), (docId) => get(this.props.documents, ['data', docId])));
        const recordDrugsPrescriptions = noUndef(map(get(recordItem, ['details', 'drugsPrescriptions'], []), (itemId) => get(this.props.drugsPrescriptions, ['data', itemId])));
        const recordExamsPrescriptions = noUndef(map(get(recordItem, ['details', 'examsPrescriptions'], []), (itemId) => get(this.props.examsPrescriptions, ['data', itemId])));
        const recordFileAttachments = noUndef(map(get(recordItem, ['details', 'fileAttachments'], []), (itemId) => get(this.props.attachments, ['data', itemId])));
        const recordImageAttachments = noUndef(map(get(recordItem, ['details', 'imageAttachments'], []), (itemId) => get(this.props.attachments, ['data', itemId])));
        const recdordMemedPrescriptions = get(recordItem, ['details', 'memedPrescriptions'], []);


        const checks = {
          recordCustomForms: 0,
          recordDocuments: 0,
          recordDrugsPrescriptions: 0,
          recordExamsPrescriptions: 0,
          recordImageAttachments: 0,
          recordFileAttachments: 0,
          recdordMemedPrescriptions: 0,
          contentPresent: 0,
        };

        if (isArray(recordImageAttachments) && recordImageAttachments.length > 0) {
          checks.recordImageAttachments++;
        }

        if (isArray(recordFileAttachments) && recordFileAttachments.length > 0) {
          checks.recordFileAttachments++;
        }


        if (isArray(recdordMemedPrescriptions) && recdordMemedPrescriptions.length > 0) {
          checks.recdordMemedPrescriptions++;
        }

        if (testContent) {
          checks.contentPresent++;
        }


        forEach(recordCustomForms, (cf) => {
          const fields = get(cf, 'fields', []);

          forEach(fields, (field) => {
            if (isPresent(get(field, 'value', ''))) {
              checks.recordCustomForms++;
            }
          });
        });

        forEach(recordDocuments, (dc) => {
          if (isPresent(get(dc, 'content', ''))) {
            checks.recordDocuments++;
          }
        });

        forEach(recordDrugsPrescriptions, (dc) => {
          if (isPresent(get(dc, 'notes', ''))) {
            checks.recordDrugsPrescriptions++;
          }
        });

        forEach(recordExamsPrescriptions, (dc) => {
          if (isPresent(get(dc, 'notes', ''))) {
            checks.recordExamsPrescriptions++;
          }
        });

        let hasContent = 0;

        forEach(checks, (value: number) => {
          if (value > 0) {
            hasContent++;
          }
        });

        if (hasContent > 0 && !isPresent(recordItem.deletedAt)) {
          emptyCollection.push(recordItem.id);
        }
      });
    }

    if (selectedFilter === 7) {
      showFutureRecords = false;
      recordsCollection = filter(recordsCollection, (r: any) => !!r.deletedAt);
    } else if (onlyNotEmpty) {
      recordsCollection = filter(recordsCollection, (r: any) => emptyCollection.find(v => v === r.id));
    } else {
      showFutureRecords = true;
      recordsCollection = filter(recordsCollection, (r: any) => !r.deletedAt);
    }

    const futureRecordIds = get(futureRecords, ['byPatient', toString(patient.id)]);
    const futureRecordsCollection = map<any, any>(futureRecordIds, (recordId) => (
      get(futureRecords, ['data', recordId])
    ));

    const recordsLoading = get(records, ['isLoading']);
    const futureRecordsLoading = get(futureRecords, ['isLoading']);

    const noRecords = (!recordsCollection.length && !recordsLoading);
    const noFutureRecords = (!futureRecordsCollection.length && !futureRecordsLoading) || !showFutureRecords;

    const isLoading = (recordsLoading) || (futureRecordsLoading && showFutureRecords);

    const showProgress = isLoading && sortedRecords.length > 0 && isPresent(lastFetch) && isPresent(lastFutureFetch);

    let noRecordsContent;
    if (noRecords && noFutureRecords) {
      noRecordsContent = <EmptyState title={noResultsMessage} />;
    } else if (isLoading) {
      // Can be moved to each Report list if we want see separated loading states for each section
      noRecordsContent = showProgress ? null : <Loading />;
    }

    const onlyDocs = selectedFilter === 5;
    const onlyExams = selectedFilter === 6;
    const onlyForms = selectedFilter === 8;

    let examsCollection = [];
    let docsCollection = [];
    let formsCollection = [];


    if (onlyDocs) {
      map(get(this.props.records, 'data', []), r => {
        map(get(r, ['details', 'documents'], []), dcId => {
          const completeDoc = get(this.props.documents.data, dcId);
          if (completeDoc && !r.deletedAt) {
            docsCollection.push({
              ...completeDoc,
              recordId: r.id,
              record: r,
            });
          }
        });
      });

      showFutureRecords = false;
      noResultsMessage = _('label/no_results_docs');
    }

    docsCollection = filter(sort(docsCollection, numberCompare('id', 'desc')), (f) => get(f, ['record', 'patientId']) === patient.id);

    if (onlyExams) {
      map(get(this.props.records, 'data', []), r => {
        map(get(r, ['details', 'examsPrescriptions'], []), exmId => {
          const completeExam = get(this.props.examsPrescriptions.data, exmId);
          if (completeExam && !r.deletedAt) {
            examsCollection.push({
              ...completeExam,
              recordId: r.id,
              record: r,
            });
          }
        });
      });

      showFutureRecords = false;
      noResultsMessage = _('label/no_results_exams');
    }

    examsCollection = filter(sort(examsCollection, numberCompare('id', 'desc')), (f) => get(f, ['record', 'patientId']) === patient.id);

    if (onlyForms) {
      map(get(this.props.records, 'data', []), r => {
        map(get(r, ['details', 'customForms'], []), formId => {
          const completeForm = get(this.props.customForms, ['data', formId]);
          if (completeForm && !r.deletedAt) {
            formsCollection.push({
              ...completeForm,
              recordId: r.id,
              record: r,
            });
          }
        });
      });

      showFutureRecords = false;
      noResultsMessage = _('label/no_results_forms');
      formsCollection = filter(sort(formsCollection, numberCompare('id', 'desc')), (f) => get(f, ['record', 'patientId']) === patient.id);
    }



    const maxTextCharsDocuments = 300;

    const recordEditable = (recordId: number) => get(this.props.records, ['data', String(recordId), 'details', 'isEditable'], false);

    let content;
    if (onlyExams) {
      const renderExams = (isMobile, isCordova) => map(examsCollection, (currExam) => {
        const owner = get(currExam, ['record', 'userName']);
        const date = get(currExam, ['record', 'date']);

        return (
          <div className="registers" key={currExam.id}>
            <div className="register">
              <div className='registerBody'>
                <div className='record examsPrescription freeText' key={currExam.id}>
                  <div className='examsPrescriptionsTitleWraper'>
                    <div className={classNames('examsPrescriptionActions', { isMobile: isMobile || isCordova })}>
                      {recordEditable(currExam.recordId) && this.onEditExamsPrescriptionClick && patient && planInclude.examsDrugsDocuments &&
                        <Button
                          text={_('action/edit', 'global')}
                          className='outlineButton'
                          onClick={this.onEditExamsPrescriptionClick(currExam.recordId, currExam.id, patient.name)}
                        />
                      }
                      <Button
                        className='outlineButton'
                        onClick={this.onPrintExamsPrescriptionClick(currExam.recordId, currExam.id)}
                      >
                        <Icon iconName='print' className='icon' size={18} />
                      </Button>
                    </div>
                  </div>
                  <div className='recordContent'>
                    <HiddenLines maxChars={1000} text={currExam.notes} scrollContainer={this.mainSection}>
                      {text => <p>{parseHtml(text, false)}</p>}
                    </HiddenLines>
                  </div>
                </div>
              </div>
              <div className="recordBaseInfo">
                <span className='createdAt'>{moment(date).format('DD [de] MMM [de] YYYY')}</span>&nbsp;&nbsp;&nbsp;
                <span className='owner'>{owner}</span>
              </div>
            </div>
          </div>
        );
      });
      content = examsCollection.length > 0 ? (
        <PlatformSwitch
          inject
          desktop={(isCordova: boolean) => renderExams(false, isCordova)}
          mobile={(isCordova: boolean) => renderExams(true, isCordova)}
        />
      ) : <EmptyState title={noResultsMessage} />;
    } else if (onlyDocs) {
      const renderDocs = (isMobile, isCordova) => map(docsCollection, (document) => {
        const owner = get(document, ['record', 'userName']);
        const date = get(document, ['record', 'date']);

        return (
          <div className="registers" key={document.id}>
            <div className="register">
              <div className='registerBody'>
                <div className='record document freeText' key={document.id}>
                  <div className='documentTitleWraper'>
                    <span className='recordTitle'>
                      {document.name}
                    </span>
                    <div className={classNames('documentActions', { isMobile: isMobile || isCordova })}>
                      {recordEditable(document.recordId) && this.onEditDocumentClick &&
                        <Button
                          text={_('action/edit', 'global')}
                          className='outlineButton'
                          onClick={this.onEditDocumentClick(document.recordId, document.id)}
                        />
                      }
                      <Button
                        className='outlineButton'
                        onClick={this.onPrintDocumentClick(document.recordId, document.id)}
                      >
                        <Icon iconName='print' className='icon' size={18} />
                      </Button>
                    </div>
                  </div>
                  <div className='recordContent'>
                    <HiddenLines maxChars={maxTextCharsDocuments} text={document.content} scrollContainer={this.mainSection}>
                      {text => <p>{parseHtml(text || '', false)}</p>}
                    </HiddenLines>
                  </div>
                </div>
              </div>
              <div className="recordBaseInfo">
                <span className='createdAt'>{moment(date).format('DD [de] MMM [de] YYYY')}</span>&nbsp;&nbsp;&nbsp;
                <span className='owner'>{owner}</span>
              </div>
            </div>
          </div>
        );
      });
      content = docsCollection.length > 0 ? (
        <PlatformSwitch
          inject
          desktop={(isCordova: boolean) => renderDocs(false, isCordova)}
          mobile={(isCordova: boolean) => renderDocs(true, isCordova)}
        />
      ) : <EmptyState title={noResultsMessage} />;
    } else if (onlyForms) {
      const renderForms = (isMobile, isCordova) => map(formsCollection, (customForm) => {
        const formEditable = recordEditable(customForm.recordId);
        const owner = get(customForm, ['record', 'userName']);
        const date = get(customForm, ['record', 'date']);

        return (
          <div className="registers" key={customForm.id}>
            <div className="register">
              <div className='registerBody'>
                <div className='record customForm' key={`${customForm.id}-${customForm.recordId}`}>
                  <div className='customFormTitleWraper'>
                    <span className='recordTitle'>{customForm.name}</span>
                    <div className={classNames('customFormActions', { isMobile: isMobile || isCordova })}>
                      {formEditable &&
                      <Button
                        text={_('action/edit', 'global')}
                        className='outlineButton'
                        onClick={this.handleEditCustomFormClick(customForm.recordId, customForm.id)}
                      />
                     }
                      <Button
                        className='outlineButton'
                        onClick={this.handlePrintCustomFormClick(customForm.recordId, customForm.id)}
                      >
                        <Icon iconName='print' className='icon' size={18} />
                      </Button>
                    </div>
                  </div>
                  <div className='recordContent'>
                    <div className='table tableFormNoBorderFix'>
                      {map<any, any>(customForm.fields, (field, index) => {
                        let fieldElement;
                        const nextElement = customForm.fields[index + 1];
                        const isNextHeader = get(nextElement, 'type') === 'header';

                        const unit = field.unit ? field.unit : '';

                        if (field.type === 'header' && nextElement && !isNextHeader) {
                          fieldElement = (
                            <div key={field.id} className='headerWrapper'>
                              <span className='recordTitle subtitle'>{field.label}</span>
                            </div>
                          );
                        } else if (field.type !== 'header') {
                          fieldElement = (
                            <div key={field.id} className='row'>
                              <div>{field.label}</div>
                              { isArray(field.value) ? (
                                <div>
                                  {/* $FlowFixMe */}
                                  { map(field.value, (val: any, idx: any) => (<span key={`${val}-${idx}`}>{parseHtml(val, false)}</span>)) }
                                </div>
                              ) : (
                                <div>
                                  {field.value === true && 'Sim'}
                                  {field.value !== true &&
                                  <HiddenLines maxChars={1000} text={field.value} scrollContainer={this.mainSection}>
                                    {text => <p>{parseHtml(text + ' ' + unit, false)}</p>}
                                  </HiddenLines>
                                  }
                                  {/* {field.value === true ? 'Sim' : parseHtml(field.value)} */}
                                </div>
                              )}
                            </div>
                          );
                        }
                        return fieldElement;
                      })}
                    </div>
                  </div>
                </div>
              </div>
              <div className="recordBaseInfo">
                <span className='createdAt'>{moment(date).format('DD [de] MMM [de] YYYY')}</span>&nbsp;&nbsp;&nbsp;
                <span className='owner'>{owner}</span>
              </div>
            </div>
          </div>
        );
      });
      content = formsCollection.length > 0 ? (
        <PlatformSwitch
          inject
          desktop={(isCordova: boolean) => renderForms(false, isCordova)}
          mobile={(isCordova: boolean) => renderForms(true, isCordova)}
        />
      ) : <EmptyState title={noResultsMessage} />;
    } else if (onlyPrescriptions) {
      content = drugsPrescriptionsCollection.length > 0 ? (
        <DrugsPrescriptions
          drugsPrescriptions={drugsPrescriptionsCollection}
          patientName={patient.name}
          onEditPrescriptionDrugsClick={onEditPrescriptionDrugsClick}
          onPrintPrescriptionDrugsClick={onPrintPrescriptionDrugsClick}
        />
      ) : <EmptyState title={noResultsMessage} />;
    } else if (onlyAttachments) {
      const att = get(attachments, 'byRecord', []);

      let attachmentsList = filter(map(att, (attachmentIds, recordId: number) => {
        const attachmentsData = map<any, any>(attachmentIds, id => get(attachments, ['data', String(id)]));
        const recordData = get(records, ['data', String(recordId)]);
        if (isEmpty(attachmentsData)) return null;

        return {
          record: recordData,
          attachments: attachmentsData,
        };
      }), item => item !== null && item !== undefined);

      attachmentsList = sort(attachmentsList, dateCompare(['record', 'date']));

      attachmentsList = filter(attachmentsList, f => parseInt(f.record.patientId, 10) === parseInt(patient.id, 10));

      content = map<any, any>(attachmentsList, (attachmentItem, index: number) => (
        <AttachmentView
          key={index}
          record={attachmentItem.record}
          attachments={attachmentItem.attachments}
          allAttachments={attachments}
          onDeleteAttachment={onDeleteAttachment}
          onDownloadAttachment={onDownloadAttachment}
        />
      ));
    } else {
      content = (
        <React.Fragment>
          <Records
            onDirectShare={onDirectShare}
            permissions={permissions}
            onDownloadAttachment={onDownloadAttachment}
            mainSection={this.mainSection}
            patient={patient}
            clinicId={clinicId}
            userEmail={userEmail}
            records={recordsCollection}
            drugsPrescriptions={drugsPrescriptions}
            examsPrescriptions={examsPrescriptions}
            documents={documents}
            customForms={customForms}
            customFormTemplates={customFormTemplates}
            newRecords={newRecords}
            isSaving={get(records, 'isSaving', false)}
            isAutoSaving={get(records, 'isAutoSaving', false)}
            onNewDocumentClick={onNewDocumentClick}
            onEditDocumentClick={onEditDocumentClick}
            onPrintDocumentClick={onPrintDocumentClick}
            onNewPrescriptionDrugsClick={onNewPrescriptionDrugsClick}
            onEditPrescriptionDrugsClick={onEditPrescriptionDrugsClick}
            onPrintPrescriptionDrugsClick={onPrintPrescriptionDrugsClick}
            onNewPrescriptionExamsClick={onNewPrescriptionExamsClick}
            onEditPrescriptionExamsClick={onEditPrescriptionExamsClick}
            onPrintPrescriptionExamsClick={onPrintPrescriptionExamsClick}
            onNewCustomFormClick={onNewCustomFormClick}
            onEditCustomFormClick={onEditCustomFormClick}
            onPrintCustomFormClick={onPrintCustomFormClick}
            onAutosaveRecord={onAutosaveRecord}
            onSaveRecord={onSaveRecord}
            onChangeVisibilityLevel={onChangeVisibilityLevel}
            onDeleteRecord={onDeleteRecord}
            onSelectFile={onSelectFile}
            onPrintSingleRecord={onPrintSingleRecord}
            onPrintAllRecords={onPrintAllRecords}
            onDeleteAttachment={onDeleteAttachment}
            attachments={attachments}
            recordDeletingIds={recordDeletingIds}
            onUpdateVisibility={onUpdateVisibility}
            onRestoreRecord={this.onRestoreRecord}
            onSetEditMode={onSetEditMode}
            recordsEditModesList={recordsEditModesList}
            updatingIds={updatingIds}
            planInclude={planInclude}
            isMemedLoaded={isMemedLoaded}
            onAddMemedId={onAddMemedId}
            memedToken={memedToken}
            onPrintMemedPrescription={onPrintMemedPrescription}
            onDeleteMemedId={onDeleteMemedId}
            onUpdateRecordDate={onUpdateRecordDate}
            onDisplayAttachmentFileName={onDisplayAttachmentFileName}
          />
        </React.Fragment>
      );
    }

    const onClickPrint = () => {
      onPrintAllRecords(patient.id);
    };

    const print = isPatientPrinting
      ? <Icon iconName="spinner" />
      : <Icon iconName={window.isCordovaApp ? 'share' : 'print'} onClick={onClickPrint} size={window.isCordovaApp ? 20 : 24}/>;

    return (
      <PlatformSwitch
        className="platformSwitcher"
        desktop={() => (
          <div id="timelinePage" className={`appContent ${planInclude.timeline ? '' : 'timelineDisabled'}`}>
            <PatientLayout patient={patient}>
              <section className="mainSection" ref={ref => { this.mainSection = ref; }}>
                <ScrollUP scrollElement={this.mainSection} className='pullSideBar' />
                {!planInclude.timeline && hideTimeLine(true)}
                {planInclude.timeline && (
                  <div className="patientTimeline">
                    <div className="toolbar">
                      <Dropdown
                        className="titleFilter withOpacity"
                        icon="filter"
                        items={filterItems}
                        selectedItem={selectedItem}
                        changeablePlaceholder
                        onSelectItem={this.onSelectFilter}
                        mobileLabel={_('action/filter_records')}
                      />

                      <div className="group-btn-tooolbar">
                        <span className="cursorPointer printButton">{print}</span>

                        <Button
                          className="secondary"
                          text={_('action/new_record')}
                          isLoading={isRecordCreating}
                          disabled={isRecordLoading}
                          loadingMessage={_('label/creating')}
                          onClick={this.onCreateRecord.bind(this)}
                        />
                      </div>
                    </div>

                    {noRecordsContent || (
                      <div className='recordsContentWrapper'>
                        {futureRecordsCollection.length > 0 && showFutureRecords &&
                          <FutureRecords
                            futureRecords={futureRecordsCollection}
                            errors={get(futureRecords, 'errors')}
                          />
                        }

                        {/* * TODO - if selected filter value is 3 or 4 then show just attachment / prescription without full record info
                        * Should be implemented when Prescriptions and Attachments modals are done */}

                        {recordsCollection.length > 0 && content}
                      </div>
                    )}
                  </div>
                )}
              </section>
            </PatientLayout>

            <PatientWidgets
              patient={patient}
              onMedicationsFormClick={onMedicationsFormClick}
              onProblemsFormClick={onProblemsFormClick}
              onTagsFormClick={onTagsFormClick}
              onSaveHistoryNote={onSaveHistoryNote}
              historyNoteLoading={historyNoteLoading}
              onRemoveTag={onRemoveTag}
              planInclude={planInclude}
            />
          </div>
        )}
        mobile={() => (
          <React.Fragment>
            <AppMobileHeader
              absoluteBacklink
              className="showBack centerTitlefix"
              backLinkTo="/pacientes/lista"
              title={patient.name}
            />

            <div id="timelinePage" className="appContent">

              <MobileHeaderRender
                historyNoteLoading={historyNoteLoading}
                mobileDetailsVisible={this.state.mobileDetailsVisible}
                mobileWidgetVisible={this.state.mobileWidgetVisible}
                onMedicationsFormClick={onMedicationsFormClick}
                onProblemsFormClick={onProblemsFormClick}
                onRemoveTag={onRemoveTag}
                onSaveHistoryNote={onSaveHistoryNote}
                onTagsFormClick={onTagsFormClick}
                patient={patient}
                planInclude={planInclude}
                handleToggleMobileWidgets={this.handleToggleMobileWidgets}
                handleCloseDetailsWidgets={this.handleCloseDetailsWidgets}
              />

              <section className="mainSection" ref={ref => { this.mainSection = ref; }}>
                <PatientMobileHeader
                  handleToggleMobileWidgets={this.handleToggleMobileWidgets}
                  handleToggleDetailsWidgets={this.handleToggleDetailsWidgets}
                  onDeletePatientProfileImage={onDeletePatientProfileImage}
                  onSelectPatientProfileImage={onSelectPatientProfileImage}
                  patient={patient}
                  active="timeline"
                />
                <div className="patientTimeline">
                  {!planInclude.timeline && hideTimeLine(true)}
                  {planInclude.timeline && (
                    <React.Fragment>
                      <div className="buttonHolder">
                        <Button
                          className="primary"
                          text={_('action/new_record')}
                          isLoading={isRecordCreating}
                          disabled={isRecordLoading}
                          loadingMessage={_('label/creating')}
                          onClick={this.onCreateRecord.bind(this)}
                        />
                      </div>

                      <div className="toolbar">
                        <Dropdown
                          className="titleFilter withOpacity"
                          icon="filter"
                          items={filterItems}
                          selectedItem={selectedItem}
                          changeablePlaceholder
                          onSelectItem={this.onSelectFilter}
                          mobileLabel={_('action/filter_records')}
                        />
                      </div>

                      {noRecordsContent || (
                        <div className='recordsContentWrapper'>
                          {futureRecordsCollection.length > 0 && showFutureRecords &&
                          <FutureRecords
                            futureRecords={futureRecordsCollection}
                            errors={get(futureRecords, 'errors')}
                          />
                              }
                          {recordsCollection.length > 0 && content}
                        </div>
                      )}
                    </React.Fragment>
                  )}
                </div>

              </section>
            </div>
            <AppMobileFooter />
          </React.Fragment>
        )}
      />
    );
  }

  mainSection: any = null;

  imagesFetchIntervals: {
    [key: number]: any,
  };

  handleRefreshClick = () => {
    Log.info('refresh');
  };

  onCreateRecord = () => {
    this.setState({ selectedFilter: 1 }, () => this.props.onCreateRecord());
  };

  onSelectFilter = (f: FilterType) => {
    this.setState({ selectedFilter: f.id });
  };

  handleToggleMobileWidgets = () => {
    this.setState({ mobileWidgetVisible: !this.state.mobileWidgetVisible });
  };

  handleToggleDetailsWidgets = () => {
    this.setState({ mobileDetailsVisible: !this.state.mobileDetailsVisible });
  };

  handleCloseDetailsWidgets = () => {
    this.setState({ mobileDetailsVisible: false });
  };

  fisrtTouch: any;

  handleSwipeStart = (e: any) => {
    const lgbox = document.querySelector('#lightBoxImageContainer');
    if (lgbox && lgbox.children && lgbox.children.length > 0) {
      return;
    }
    this.fisrtTouch = e && e.touches ? e.touches[0] : null;
  };

  handleSwipeEnd = (e: any) => {
    const lgbox = document.querySelector('#lightBoxImageContainer');
    if (lgbox && lgbox.children && lgbox.children.length > 0) {
      return;
    }

    const { mobileDetailsVisible, mobileWidgetVisible } = this.state;

    const end = e && e.changedTouches ? e.changedTouches[0] : null;

    if (!this.fisrtTouch || !end) return;

    const distance = this.fisrtTouch.clientX - end.clientX;
    const direction = distance < 0 ? 'l' : 'r';
    const normalizedDistance = distance < 0 ? distance * -1 : distance;
    const screenPercent = (normalizedDistance / window.innerWidth) * 100;

    if (screenPercent >= 70 && !mobileDetailsVisible && !mobileWidgetVisible && !window.document.querySelector('.pdfPreview') && !window.document.querySelector('.modal.asView')) {
      if (direction === 'l') {
        this.setState({ mobileDetailsVisible: true });
      }
      if (direction === 'r') {
        this.setState({ mobileWidgetVisible: true });
      }
    }
  };

  onEditDocumentClick = (recordId: number, documentId: number) => () => {
    this.props.onEditDocumentClick({
      documentId,
      recordId,
    });
  };

  onPrintDocumentClick = (recordId: number, documentId: number) => () => {
    this.props.onPrintDocumentClick(recordId, documentId);
  };

  onEditExamsPrescriptionClick = (recordId: number, prescriptionId: number, patientName: string) => () => {
    this.props.onEditPrescriptionExamsClick({
      patientName,
      prescriptionId,
      recordId,
    });
  };
  onPrintExamsPrescriptionClick = (recordId: number, examId: number) => () => {
    this.props.onPrintPrescriptionExamsClick(recordId, examId);
  };

  handleEditCustomFormClick = (recordId, customFormId) => () => this.props.onEditCustomFormClick({ recordId, customFormId });
  handlePrintCustomFormClick = (recordId, customFormId) => () => this.props.onPrintCustomFormClick(recordId, customFormId);

  onRestoreRecord = (recordId: number) => {
    this.props.onRestoreRecord(recordId, (success: boolean) => {
      if (success) {
        this.setState({
          selectedFilter: 1,
        });
      }
    });
  };
}

export default withTranslation()(PatientTimeline);

