// @flow
import React, { useMemo, useCallback, useState, useEffect } from 'react';
import { Button, Table, FormattedText, Icon, Alert, Dropdown } from '@elements';
import { map, get } from 'lodash';
import type { Patient, Measure } from '@types';
import PatientLayout from '../../../../layouts/PatientLayout';
import { indicatorsActions } from '@context/indicators';
import { useTranslationNs, sort, dateCompare, getExtensiveAge } from '@helpers/helpers';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import './PatientIndicators.scss';

type Mode = {
  type: 'height' | 'weight' | 'imc';
  interval: '0-2' | '2-5' | '5-19' | 'all';
}

type Props = {
  patient?: Patient,
  onShowGraph: Function,
  onNew: Function,
  onEdit: Function,
};

const getData = (patientId?: number) => {
  try {
    if (!patientId) return {};

    return JSON.parse(window.localStorage.getItem(`indicatorsData-${patientId}`));
  } catch (e) {
    return {};
  }
};

const PatientIndicators = ({ patient, onShowGraph, onNew, onEdit }: Props) => {
  const [_] = useTranslationNs('indicators');
  const [indicatorsData, setIndicatorsData] = useState(getData(get(patient, 'id', '')));

  const dispatch = useDispatch();
  const indicators = useSelector((state) => get(state, ['context', 'indicators', 'data'], []));
  const patientsMeasures: Measure[] = useSelector((state) =>
    get(state, ['context', 'indicators', 'patientsMeasures', get(patient, 'id')], []),
  );

  const measures = useMemo(() => patientsMeasures.map(measure => {
    const formattedDate = moment(measure.date, 'YYYY-MM-DD').format('DD/MM/YYYY');
    const patientBirthDate = get(patient, 'birthdate', '');

    const age = getExtensiveAge(patientBirthDate, measure.date);

    const formattedValues = indicators.map(indicator => {
      const value = get(measure, `values[${indicator.id}]`, '');

      return {
        id: indicator.id,
        value,
        formattedValue: value ? `${value} ${indicator.unit}` : '',
      };
    });

    const formattedValuesMappedById = formattedValues.reduce((acc, curr) => {
      acc[curr.id] = curr;

      return acc;
    }, {});

    return {
      ...measure,
      age,
      formattedDate,
      formattedValues: formattedValuesMappedById,
    };
  }), [patientsMeasures, patient, indicators]);

  useEffect(() => {
    dispatch(indicatorsActions.listIndicators());
    dispatch(indicatorsActions.listPatientMeasures(get(patient, 'id')));
  }, [dispatch, patient]);

  const updateIndicators = useCallback((data) => {
    const patientId = get(patient, 'id', '');
    if (!patientId) return;


    setIndicatorsData(data);
    window.localStorage.setItem(`indicatorsData-${patientId}`, JSON.stringify(data));
  }, [patient]);

  const genOrderedData = (data) => {
    const orderedIndicators = sort(map(data, indic => ({
      ...indic,
      date: moment(indic.date).toDate(),
    })), dateCompare('date', 'asc'));

    return orderedIndicators.map(indicator => ({
      ...indicator,
      date: moment(indicator.date).format('YYYY-MM-DD'),
    }));
  };

  const handleShowGraph = useCallback((mode: Mode) => {
    const indicator = indicators.find(indic => indic.type === mode.type);

    const measureData = measures.map(measure => ({
      ...measure,
      value: get(measure, `values[${indicator.id}]`, ''),
      date: get(measure, 'date', new Date().toISOString()),
      mode,
    }));

    onShowGraph({
      dataSet: measureData,
      indicator,
      mode,
      patient,
    });
  }, [onShowGraph, indicators, patient, measures]);

  const dropdownItems = useMemo(() => [
    { text: _('label/0-2'), value: '0-2' },
    { text: _('label/2-5'), value: '2-5' },
    { text: _('label/5-19'), value: '5-19' },
    { text: _('label/all'), value: 'all' },
  ], [_]);

  const handleEdit = useCallback((measure: Measure) => () => {
    onEdit(measure);
  }, [onEdit]);

  const columns = useMemo(() => {
    const indicatorsColumns = indicators.map(indicator => {
      const { type, name, id } = indicator;

      return {
        dataIndex: type,
        content: (
          <div className="indicatorHeading">
            <Dropdown
              className="indicatorAction"
              disableArrowIcon
              placeholder={
                <span className="dropdownPlaceholderContainer">
                  <Icon iconName='line-chart' className='icon' size={15} />
                  {name}
                </span>
              }
              items={dropdownItems}
              onSelectItem={(item) => handleShowGraph({ type, interval: item.value })}
            />
          </div>
        ),
        onRenderItem: (measure) => {
          const measureValue = get(measure, `formattedValues[${id}]`, {});

          if (!measureValue.formattedValue) return null;

          return (
            <>
              <FormattedText type="number" text={measureValue.formattedValue} />
            </>
          );
        },
      };
    });

    return [
      {
        dataIndex: 'formattedDate',
        content: _('label/date', 'global'),
        onRenderItem: (measure) => <FormattedText type="dateFormat" text={measure.formattedDate} />,
      },
      {
        dataIndex: 'age',
        content: _('label/age'),
        onRenderItem: (measure) => Boolean(measure.age) && measure.age,
      },
      ...indicatorsColumns,
      {
        onRenderItem: (measure) => (
          <Button className='editButton outlineButton' onClick={handleEdit(measure)}>
            <Icon iconName='edit' size={15} />{_('button/edit')}
          </Button>
        ),
      },
    ];
  }, [indicators, _, dropdownItems, handleShowGraph, handleEdit]);

  window.deleteIndicator = (indicatorId: number) => {
    const _indicators = { ...indicatorsData };

    delete _indicators[indicatorId];

    updateIndicators(_indicators);
  };

  const handleNewData = useCallback(() => {
    onNew({ patient });
  }, [onNew, patient]);

  return (
    <div id="patientIndicators" className="appContent">
      <PatientLayout patient={patient}>
        <section className="mainSection">
          <div className="centerContent">
            <div className="toolbar">
              <h1>{_('label/title')}</h1>
              <Button className="secondary" onClick={handleNewData}>{_('button/addData')}</Button>

            </div>
            <Alert text={_('alert/beta_feature_disclamer')} />
            <div className="desktopCard indicatorsCard padding">
              <Table
                enablePagination
                itemKey="id"
                limit={100}
                columns={columns}
                items={genOrderedData(measures)}
                mobileClickLabel={_('button/edit')}
                emptyMessage={_('label/empty/title')}
              />
            </div>
          </div>
        </section>
      </PatientLayout>
    </div>
  );
};


export default PatientIndicators;
