// @flow
import type { Dispatch } from '../../types';

// ------------------------------------
// Constants
// ------------------------------------
export const UPDATE_APP = 'appControl.UPDATE_APP';
export const RESET_STORE = 'appControl.RESET_STORE';

// ------------------------------------
// Actions
// ------------------------------------
const updateApp = () => (
  (dispatch: Dispatch) => {
    dispatch({ type: UPDATE_APP });
  }
);

export const appControlActions = {
  updateApp,
};

export const appControlTypes = {
  UPDATE_APP,
  RESET_STORE,
};

// --------------------------------------------------------------
// NO Reducer - This module is just middleware between reducers
// --------------------------------------------------------------
