import React, { useState, useCallback, useMemo } from 'react';
import { take, map, filter } from 'lodash';
import { useTranslationNs, checkIfIsMobile } from '@helpers/helpers';
import { EmptyState } from '@blocks';
import { Loading } from '@elements';
import type { TableColumItem } from '@types';
import { connect } from 'react-redux';
import mapRenderItemDesktop from './mapRenderItemDesktop';
import mapRenderItemMobile from './mapRenderItemMobile';
import './Table.scss';

type Props = {
  items: any[] | Object, // items, prefer use of array
  itemKey: string, // key used for item render key prop default is id
  emptyMessage?: string, // message when items is empty
  columns: TableColumItem[], // Colums definition
  limit?: number, // Table rendering limitation
  onItemClick?: Function, // On click item (item)=> void
  mobileClickLabel?: any, // a text to be displayed in action button in mobile
  isLoading?: boolean, // All table loadig
  onGetLoadingItem?: Function, // A function used to check if item is loading
  ui: any,
  enablePagination?: boolean,
  forceDesktop?: boolean,
  renderEmptyState?: Function,
  renderFooter?: Function,
};


const TableElement = ({
  items,
  columns,
  limit = 5,
  onItemClick,
  isLoading,
  onGetLoadingItem,
  emptyMessage,
  itemKey = 'id',
  mobileClickLabel,
  ui,
  enablePagination = false,
  renderEmptyState,
  forceDesktop,
  renderFooter,
}: Props) => {
  const [expanded, setExpanded] = useState(false);
  const [_] = useTranslationNs('global');
  const isMobile = checkIfIsMobile(ui);
  const mapItemFn:any = isMobile && !forceDesktop ? mapRenderItemMobile : mapRenderItemDesktop;

  const onExpandClick = useCallback((expand: boolean) => () => {
    setExpanded(expand);
  }, []);

  const fColumns = useMemo(() => {
    const f0 = filter(columns, (h: TableColumItem) => !h.hidden);
    const f1 = filter(f0, (h: TableColumItem) => (h.mobileOnly ? isMobile : true));
    return filter(f1, (h: TableColumItem) => (h.desktopOnly ? !isMobile : true));
  }, [columns, isMobile]);

  const limitedItems = useMemo(() => (expanded || !enablePagination ? items : take(items, limit)), [expanded, items, limit, enablePagination]);

  const contentItems = useMemo(
    () => map(limitedItems, mapItemFn(fColumns, onItemClick, onGetLoadingItem, itemKey, mobileClickLabel)),
    [fColumns, limitedItems, onItemClick, onGetLoadingItem, itemKey, mapItemFn, mobileClickLabel]);

  const rendercolumns = useMemo(() => map(fColumns, (header: TableColumItem) => (
    <th className={header.className || ''} key={header.dataIndex}>
      {header.content}
    </th>
  )), [fColumns]);

  if (contentItems.length === 0) {
    return renderEmptyState ? renderEmptyState() : <EmptyState title={emptyMessage || ''} className="inlineEmptyStateMobile" />;
  }

  if (isLoading) {
    return (
      <div>
        <Loading />
      </div>
    );
  }

  if (!isMobile || forceDesktop) {
    return (
      <div className="tableElement">
        <table className="table clickableTable">
          <thead>
            <tr>
              {rendercolumns}
            </tr>
          </thead>
          <tbody>
            {contentItems}
          </tbody>
          <tfoot>
            {renderFooter ? renderFooter(isMobile && !forceDesktop) : null}
          </tfoot>
        </table>
        <div className="paginationFooter">
          {items.length > limit && enablePagination && !expanded && (
          <div onClick={onExpandClick(true)} className="more-container-list-container">
            {_('action/see_more')}
          </div>
          )}
          {items.length > limit && enablePagination && expanded && (
          <div onClick={onExpandClick(false)} className="more-container-list-container">
            {_('action/see_less')}
          </div>
          )}
        </div>
      </div>
    );
  }

  return (
    <div className="tableElement">
      {contentItems}
      <div className="paginationFooter">
        {items.length > limit && enablePagination && !expanded && (
          <div onClick={onExpandClick(true)} className="more-container-list-container mobile">
            {_('action/see_more')}
          </div>
        )}
        {items.length > limit && enablePagination && expanded && (
          <div onClick={onExpandClick(false)} className="more-container-list-container mobile">
            {_('action/see_less')}
          </div>
        )}
      </div>
      <div className="mobileTableFooter">
        {renderFooter ? renderFooter(isMobile && !forceDesktop) : null}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  ui: state.ui,
});

export default connect(
  mapStateToProps,
  null,
)(TableElement);

