// @flow
import React from 'react';
import { get, padStart } from 'lodash';
import { connect } from 'react-redux';
import { ClinicConfigWorkHours as ClinicConfigWorkHoursView } from '@views';
import { updateClinic } from '@context/clinics';
import type { User, Clinic, BundledPermissions } from '@types';
import { logout } from '@context/user';
import { mapBundledPermission } from '@helpers/connect';

type Props = {
  user: User,
  clinic: Clinic,
  dispatch: Function,
  isLoading: Boolean,
  permissions: BundledPermissions,
};

class ClinicConfigWorkHours extends React.Component<Props> {
  render() {
    const { user, isLoading } = this.props;
    return (
      <ClinicConfigWorkHoursView
        user={user}
        formData={this.getFormData()}
        isLoading={isLoading}
        onSave={this.handleOnSave}
        onLogout={this.handleLogout}
        permissions={this.props.permissions}
      />
    );
  }


  getFormData = () => ({
    businessHoursStart: padStart(get(this, ['props', 'clinic', 'businessHoursStart'], ''), 5, '0'),
    businessHoursEnd: padStart(get(this, ['props', 'clinic', 'businessHoursEnd'], ''), 5, '0'),
    showWeekends: get(this.props.clinic, 'showWeekends', false),
  });

  handleOnSave = (data: Object) => {
    const { dispatch, clinic } = this.props;
    dispatch(updateClinic(get(clinic, 'id'), data));
  };
  handleLogout = () => this.props.dispatch(logout());
}

const mapStateToProps = (state) => ({
  user: state.context.user,
  isLoading: get(state, ['context', 'clinics', 'isSaving'], null),
  clinic: get(state, ['context', 'clinics', 'activeClinic'], {}),
  permissions: mapBundledPermission(state),
});

export default connect<any, any, any, any, any, any>(
  mapStateToProps,
)(ClinicConfigWorkHours);
