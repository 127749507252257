// @flow
import React, { useMemo, useState } from 'react';
import type { Node } from 'react';
import classnames from 'classnames';
import './FormInput.scss';

type Props = {
  children: Node,
  className?: string,
  error?: string,
  hint?: ?string,
  required?: boolean,
  label?: string,
  labelClassName?: string,
  optional?: boolean,
  onLabelClick?: Function,
  disabled?: boolean,
  unit?: string,
};

const FormInput = ({
  children, label, required, error, onLabelClick, hint, optional, className, labelClassName, disabled, unit,
}: Props) => {
  const [unitSize, setUnitSize] = useState(0);


  const cssClasses = classnames([
    'inputHolder',
    className,
    disabled ? 'disabled' : null,
    required ? 'required' : null,
    error ? 'error' : null,
    unit ? 'unitInput' : null,
    `unit-size-${unitSize > 0 ? unitSize : 'none'}`,
  ]);

  const labelCssClasses = useMemo(() => classnames([
    'formInputLabel',
    labelClassName,
  ]), [labelClassName]);




  return (
    <div className={cssClasses}>

      {label &&
        <label onClick={onLabelClick} className={labelCssClasses}>
          { label }
          { optional && <span className="optional"> (optional)</span> }
        </label>
      }

      {(unit) && (
        <div className="inputUnitHolder">
          { children }
          { unit && <div className="formInputUnit" ref={(ref) => setUnitSize(ref ? ref.clientWidth : 0)}>{unit}</div> }
        </div>
      )}
      {(!unit) && (
        <React.Fragment>
          {children}
          {(hint || error) && (
            <div className="formInputBottom">
              {hint && <span className="formInputHint">{hint}</span>}
              {error && <span className="errMessage">{`*${error}`}</span>}
            </div>
          )}
        </React.Fragment>
      )}
    </div>
  );
};

export default FormInput;
