// @flow

import React from 'react';
import classNames from 'classnames';
import { map } from 'lodash';
import { Button, Icon, HiddenLines, Dropdown } from '@elements';
import type { Record, Patient, PermissionTo } from '@types';
import { parteHtmlToReact as parseHtml } from '@helpers/helpers';


type Props = {
  shareDropdownItems: Object[],
  onShareClick: Function,
  actionClasses: any,
  _: Function,
  drugsPrescriptionsContent: Object,
  isCordova: boolean,
  isEditable: any,
  isMobile: boolean,
  mainSection: any,
  onEditDrugsPrescriptionClick: Function,
  onPrintDrugsPrescriptionClick: Function,
  patient?: Patient,
  record: Record,
  printDropdownItems: Function,
  permissionsTo: PermissionTo,
  drugsPrescriptionSelectItem: Function,
  maxTextChars: number,
  isMine: boolean,
  directShareLoading: boolean,
}


const DrugsPrescriptionsContent = ({ _, actionClasses, drugsPrescriptionSelectItem, drugsPrescriptionsContent, isCordova,
  isEditable, isMobile, mainSection, onEditDrugsPrescriptionClick, onPrintDrugsPrescriptionClick, isMine, directShareLoading,
  onShareClick, patient, permissionsTo, printDropdownItems, record, shareDropdownItems, maxTextChars }: Props) => map(drugsPrescriptionsContent, (prescription) => (
    <div className={classNames('record drugsPrescription freeText', actionClasses)} key={prescription.id}>
      <div className='drugsPrescriptionsTitleWraper'>
        <span className={classNames('recordTitle', { isMobile: isCordova })}>
          {prescription.controlledDrugs ? _('label/constrolled_receipts') : _('label/receipts')}
        </span>
        <div className={classNames('drugsPrescriptionActions recordContentActions', { isMobile: isMobile || isCordova })}>
          {isEditable && onEditDrugsPrescriptionClick && patient &&
          <Button
            text={<React.Fragment><Icon iconName="edit" size={18} />{!window.isCordovaApp && (<React.Fragment>&nbsp;&nbsp;</React.Fragment>)}{!window.isCordovaApp && _('action/edit', 'global')}</React.Fragment>}
            className='outlineButton buttonLike'
            onClick={onEditDrugsPrescriptionClick(record.id, prescription.id, patient.name)}
          />
        }
          {(permissionsTo.doctorShowSignature && isMine) && (
          <Dropdown
            className={window.isCordovaApp ? 'outlineButton singleIconFix buttonLike' : 'outlineButton buttonLike'}
            placeholder={(
              <React.Fragment>
                {window.isCordovaApp && (
                <React.Fragment>
                  <Icon iconName="print" className='icon' size={18} />
                </React.Fragment>
                )}
                {!window.isCordovaApp && (
                <React.Fragment>
                  <Icon iconName="print" className='icon' size={18} />
                  {!window.isCordovaApp && (<React.Fragment>&nbsp;&nbsp;</React.Fragment>)}{_('action/print')}
                </React.Fragment>
                )}
              </React.Fragment>
            )}
            disableAutoModalTitle
            disableArrowIcon={isMobile}
            items={printDropdownItems(window.isCordovaApp ? 'Compartilhar impressão de Receituário' : 'Imprimir Receituário')}
            onSelectItem={drugsPrescriptionSelectItem(prescription)}
          />
          )}
          {!(permissionsTo.doctorShowSignature && isMine) && (
          <Button
            className='outlineButton buttonLike'
            onClick={onPrintDrugsPrescriptionClick(record.id, prescription.id)}
          >
            <React.Fragment>
              {window.isCordovaApp && (
                <React.Fragment>
                  <Icon iconName="print" className='icon' size={18} />
                </React.Fragment>
              )}
              {!window.isCordovaApp && (
                <React.Fragment>
                  <Icon iconName="print" className='icon' size={18} />
                  {!window.isCordovaApp && (<React.Fragment>&nbsp;&nbsp;</React.Fragment>)}{_('action/print')}
                </React.Fragment>
              )}
            </React.Fragment>
          </Button>
          )}
          {isMine && (
            <Dropdown
              className={window.isCordovaApp ? 'outlineButton singleIconFix newShareFeature buttonLike' : 'outlineButton newShareFeature buttonLike'}
              placeholder={(
                <React.Fragment>
                  <React.Fragment>
                    {window.isCordovaApp && (
                    <React.Fragment><Icon iconName={directShareLoading ? 'spinner' : 'share'} className='icon' size={18} /></React.Fragment>
                    )}
                    {!window.isCordovaApp && (
                    <React.Fragment><Icon iconName={directShareLoading ? 'spinner' : 'share'} className='icon' size={18} />{!window.isCordovaApp && (<React.Fragment>&nbsp;&nbsp;</React.Fragment>)}{_('action/share')}</React.Fragment>
                    )}
                  </React.Fragment>
                </React.Fragment>
            )}
              disableAutoModalTitle
              disableArrowIcon={isMobile}
              items={shareDropdownItems}
              onClick={onShareClick('drugsPrescriptions', prescription.id)}
              forceToBeClosed={directShareLoading}
            />
          )}
          {/* <Button
          className='outlineButton buttonLike'
          onClick={onPrintDrugsPrescriptionClick(record.id, prescription.id, true, true)}
        >
          <Icon iconName='mail-send' className='icon' size={18} />
        </Button> */}
        </div>
      </div>
      <div className='recordContent ritchTextContent'>
        <HiddenLines maxChars={maxTextChars} text={prescription.notes} scrollContainer={mainSection}>
          {text => <div>{parseHtml(text, false)}</div>}
        </HiddenLines>
      </div>
    </div>
));

// $FlowFixMe
export default React.memo(DrugsPrescriptionsContent);
