// @flow
import React from 'react';
import { connect } from 'react-redux';
import { actions } from '../redux/modules/context/modals';
import type { Dispatch } from '../types';
import { PatientMessages as PatientMessagesView } from '../components/views';

type Props = {
  dispatch: Function,
};

class PatientMessages extends React.Component<Props> {
  render() {
    return (
      <PatientMessagesView
        onNewMessageClick={this.handleNewMessageClick}
      />
    );
  }

  handleNewMessageClick = () => {
    this.props.dispatch(actions.openModal('MessageFormModal'));
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({ dispatch });

export default connect<any, any, any, any, any, any>(
  null,
  mapDispatchToProps,
)(PatientMessages);
