export default {
  'label/my_profile': 'Meu Perfil',
  'label/my_record': 'Meu Cadastro',
  'label/change_password': 'Alterar Senha',
  'label/old_password': 'Senha Antiga',
  'label/old_password_success': 'Senha alterada com sucesso',
  'label/new_password': 'Senha Nova',
  'label/clinic_logo': 'Logo da Clínica',
  'label/mail_accept_text': 'Quero receber e-mails ocasionais de treinamento e suporte (promessa: jamais enviaremos spam)',
  // privacy
  'label/records_privacy/title': 'Privacidade dos Registros no Prontuário',
  'label/records_privacy/opt/input_desctiption': 'Quem pode ver o prontuário dos seus pacientes?',
  'label/records_privacy/opt/only_me': 'Somente Eu',
  'label/records_privacy/opt/doctors': 'Doutores - Somente os doutores da clínica',
  'label/records_privacy/opt/all': 'Todos - Doutores e secretárias da clínica',
  'label/records_privacy/loading': 'Carregando dados do usuário',
  // custom forms
  'label/custom_forms/forms_list': 'Lista de Formulários',
  'label/custom_forms/text1': 'Clientes dos planos Concierge e Clínica podem editar, excluir ou adicionar um novo formulário.',
  'label/custom_forms/contact_text': 'Entre em contato com nosso Chat Online para editar seus formulários',
  // prescription menu
  'label/prescription_menu/title': 'Receituário Eletrônico',
  'label/prescription_menu/prescriptions': 'Modelos de Prescrição',
  'label/prescription_menu/exams': 'Modelos de Pedido de Exames',
  'label/prescription_menu/docs': 'Modelos de Documento',
  'label/prescription_menu/memed': 'Receituário Memed',
  // drugs_prescription_teplates
  'label/drugs_prescription_teplates/list/title': 'Modelos de Prescrição',
  'label/drugs_prescription_teplates/list/empty_title': 'Não Há Modelos de Prescrição Gravados',
  'label/drugs_prescription_teplates/list/empty_hint': 'Você pode usar um modelo para agilizar suas consultas.',
  'label/drugs_prescription_teplates/list/new': 'Novo Modelo',
  'label/drugs_prescription_teplates/list/new_prescription': 'Nova Prescrição',
  'label/drugs_prescription_teplates/form/error_empty': 'não pode estar vazio',
  'label/drugs_prescription_teplates/form/placeholder_content': 'Digite aqui o conteúdo da prescrição',
  'label/drugs_prescription_teplates/form/controlled_checkbox': 'Receituário Controlado',
  'label/drugs_prescription_teplates/form/model_name': 'Nome do Modelo',
  // exams models
  'label/exam_models/list/title': 'Modelos de Pedido de Exames',
  'label/exam_models/list/empty_title': 'Não Há Modelos de Pedido de Exames Gravados',
  'label/exam_models/list/empty_hint': 'Você pode usar um modelo para agilizar suas consultas.',
  'label/exam_models/list/new_exam': 'Novo Modelo',
  'label/exam_models/form/error_empty': 'não pode estar vazio',
  'label/exam_models/form/placeholder_content': 'Digite aqui o conteúdo do pedido de exames',
  'label/exam_models/form/model_name': 'Nome do Modelo',
  // documents
  'label/documents/list/title': 'Modelos de Documento',
  'label/documents/list/empty_title': 'Não Há Modelos de Documento Gravados',
  'label/documents/list/empty_hint': 'Você pode usar um modelo para agilizar suas consultas.',
  'label/documents/list/new_doc': 'Novo Modelo',
  'label/documents/form/title': 'Modelo de Documento',
  'label/documents/form/name': 'Nome do Modelo',
  'label/documents/form/error_empty': 'não pode estar vazio',
  'label/documents/form/placeholder_content': 'Digite aqui o conteúdo do documento',
  'label/documents/form/show_print_model': 'Mostrar o nome do modelo ao imprimir',
  // Clinic config address
  'label/clinic_addr/title': 'Dados da Clínica/Consultório',
  'label/clinic_addr/clinic_name': 'Nome do Consultório/Clínica',
  'label/clinic_addr/title2': 'Endereço da Clínica',
  // Clinic doctors
  'label/clinic_doctors/list/title': 'Lista de Doutores',
  'label/clinic_doctors/list/title_mobile': 'Doutores',
  'label/clinic_doctors/list/invite': 'Convidar Doutor',
  'label/clinic_doctors/form/title_edit': 'Editar Doutor',
  'label/clinic_doctors/form/title_invite': 'Convidar Doutor',
  'label/clinic_doctors/form/name_in_receipt': 'Nome completo para recibos',
  'label/clinic_doctors/form/council_doc': 'Conselho Profissional',
  'label/clinic_doctors/form/agenda_color': 'Cor na Agenda',
  'label/clinic_doctors/form/agenda_info': 'Informações na Agenda',
  'label/clinic_doctors/form/default_appointment_duration': 'Tempo Padrão de Atendimento',
  'label/clinic_doctors/form/default_room': 'Sala Padrão de Atendimento',
  'label/clinic_doctors/form/is_administrator': 'Administrador',
  'label/clinic_doctors/form/edit_workhours': 'Configurar Horários de Atendimento',
  'label/clinic_doctors/form/administrator_hint': 'Administradores podem alterar as configurações e ver os relatórios financeiros completos.',
  // Doctors workhours
  'label/doctors_workhours/title': 'Horários de Atendimento',
  'label/doctors_workhours/weekday': 'Dia da semana',
  'label/doctors_workhours/title2': 'Adicionar novo horário de atendimento',
  // secretaries
  'label/secretaries/list/title': 'Lista de Secretárias',
  'label/secretaries/list/invite': 'Convidar Secretária',
  'label/secretaries/list/empty': 'Convide sua secretária',
  'label/secretaries/list/hint_1': 'Vocês podem usar o Doutore ao mesmo tempo.',
  'label/secretaries/list/hint_2': 'Todas as informações sincronizam automaticamente.',
  'label/secretaries/list/users': 'Usuários',
  'label/secretaries/form/edit_secretary': 'Editar Secretária',
  'label/secretaries/form/manager_hint': 'Administradores podem alterar as configurações e ver os relatórios financeiros completos',
  // Agenda
  'label/agenda_menu/workhours': 'Horários de Funcionamento',
  'label/agenda_menu/rooms': 'Salas de Atendimento',
  'label/agenda_menu/title': 'Agenda',
  'label/agenda/show_weekends_in_agenda': 'Mostrar na agenda os fins de semana',
  // Clinic rooms
  'label/rooms/title': 'Salas de Atendimento',
  'label/rooms/room_name': 'Nome da Sala',
  'label/rooms/title2': 'Dados da Clínica/Consultório',
  'label/rooms/number_of_rooms': 'Número de Salas',
  // Patient fields
  'label/patients_fields/title_mobile': 'Cadastro de Pacientes',
  'label/patients_fields/basic_data': 'Dados básicos',
  'label/patients_fields/hint': 'Exibir campos extras no cadastro de paciente',
  'label/patients_fields/code': 'Código',
  'label/patients_fields/custom_field': 'Campo Personalizavel',
  'label/patients_fields/health_insurance': 'Plano de Saúde',
  'label/patients_fields/health_insurance_fields': 'Convênio, Plano, Número da carteira e Validade',
  // tags
  'label/tags/list/title': 'Lista de Etiquetas',
  'label/tags/list/new': 'Nova Etiqueta',
  'label/tags/form/edit': 'Editar Etiqueta',
  'label/tags/form/title': 'Etiquetas',
  // Service templates
  'label/services/empty_title1': 'Não Há Serviços Particulares Cadastrados',
  'label/services/empty_hint1': 'Aqui ficará sua tabela de preços com seus serviços e valores.',
  'label/services/empty_title2': 'Não Há Serviços de Plano de Saúde Cadastrados',
  'label/services/search_empty': 'Não foram encontrados serviços para o termo pesquisado.',
  'label/services/empty_hint2': 'Aqui ficará sua tabela de preços com seus serviços de plano de saúde e valores.',
  'label/services/add_service': 'Adicionar Serviço',
  'label/services/add_plan': 'Adicionar Plano',
  'label/services/private': 'Particular',
  'label/services/insurance_plan': 'Plano de Saúde',
  'label/services/form/new_service': 'Novo Serviço',
  'label/services/form/service_value': 'Valor dos Serviços',
  // Printing
  'label/print_config/menu/general': 'Geral',
  'label/print_config/menu/logo': 'Logo da Clínica',
  'label/print_config/menu/eletronic_receipts': 'Receituário Eletrônico',
  'label/print_config/menu/controlled_receipts': 'Receituário Controlado',
  'label/print_config/menu/clinic_logo': 'Logo da Clínica',
  'label/print_config/menu/memed_settings': 'Configurações Memed',
  'label/print_config/menu/title': 'Impressão',
  'label/print_config/general/clinic_name': 'Nome do Consultório/Clínica',
  'label/print_config/general/title': 'Layout de Relatórios',
  'label/print_config/prescription/letterhead': 'Papel Timbrado',
  'label/print_config/prescription/nohead': 'Papel em Branco',
  'label/print_config/prescription/paper_type': 'Que tipo de papel você irá colocar na impressora?',
  'label/print_config/prescription/line': 'Linha',
  'label/print_config/prescription/heading': 'Cabeçalho',
  'label/print_config/prescription/line_1': 'Título do Cabeçalho',
  'label/print_config/prescription/line_2': 'Texto do Cabeçalho',
  'label/print_config/prescription/preview_pdf': 'Ver Exemplo',
  'label/print_config/prescription/footer': 'Texto do Rodapé',
  'label/print_config/prescription/margins': 'Margens',
  'label/print_config/prescription/font': 'Fonte',
  'label/print_config/prescription/margin_top': 'Margem Superior',
  'label/print_config/prescription/margin_bottom': 'Margem Inferior',
  'label/print_config/prescription/margin_left': 'Margem Esqueda',
  'label/print_config/prescription/margin_right': 'Margem Direita',
  'label/print_config/prescription/title': 'Configurações de Impressão',
  'label/print_config/controlled/title': 'Receituário Controlado',
  'label/print_config/controlled/council_number': 'Numero do conselho',
  // Support
  'label/support/menu/guide': 'Guia do Doutore',
  'label/support/menu/terms': 'Termos de Uso',
  'label/support/menu/backup': 'Backup Manual',
  'label/support/menu/to_appx': 'Ir para o Doutore (Versão Beta)',
  'label/support/menu/to_app': 'Ir para o Doutore 4 (Versão Atual)',
  'label/support/menu/chat': 'Suporte por Chat Online',
  'label/support/menu/refresh': 'Atualizar o Doutore',
  'label/support/menu/privacyPolicy': 'Política de Privacidade',
  'label/support/menu/deleteMyAccount': 'Deletar Minha Conta',
  'label/support/menu/version': 'Doutore versão',
  'label/support/backup/success/title': 'Tudo certo!',
  'label/support/backup/success/text': 'Iremos processar as suas informações e enviaremos para o seu email',
  'label/support/backup/title': 'Backup Manual',
  'label/support/backup/text/line1': 'No Doutore, seus dados ficam seguros armazenados nos nossos servidores.',
  'label/support/backup/text/line2': 'Fazemos backup diário automático de todas as suas informações e armazenamos o backup em diferentes localidades no mundo: Estados Unidos, São Paulo e Europa.',
  'label/support/backup/text/line3': 'Se mesmo assim você quiser fazer um backup manual do seus dados, utilize os botões abaixo:',
  'label/support/backup_excel/text/line1_title': 'Backup dos Pacientes em Excel: ',
  'label/support/backup_excel/text/line1': 'Planilha excel com os dados de cadastro dos pacientes.',
  'label/support/backup_excel/text/line2': 'Esse Backup é enviado diretamente para o seu email e pode demorar alguns minutos ou horas.',
  'label/support/backup_complete/text/line1_title': 'Backup Completo dos Prontuários:',
  'label/support/backup_complete/text/line1': 'Arquivo com todos os prontuários e fotos dos pacientes.',
  'label/support/backup_complete/text/line2': 'Como esse backup utiliza muito processamento dos nossos servidores, ele é feito apenas em momentos de baixa utilização do sistema, para não afetar a performance do Doutore. Esse Backup é feito manualmente e pode demorar horas ou dias.',
  'label/support/backup/request_backup_patients': 'Pedir Backup dos Pacientes',
  'label/support/backup/request_backup_complete': 'Pedir Backup Completo',
  'label/support/backup/support_action_lbl': 'Em caso de dúvidas',
  'label/support/backup/support_action_btn': 'entre em contato',
  'label/support/backup/attention': 'Atenção',
  'label/support/backup/wait': 'Aguarde...',
  'label/support/backup/attention_text': `Como o backup manual utiliza muito processamento dos nossos servidores,
  ele é feito apenas em momentos de baixa utilização do sistema, para não afetar a performance do Doutore.
  Isso pode levar algumas horas.`,
  // menu
  'action/menu/my_plan': 'Meu Plano',
  'action/menu/plans': 'Planos',
  'action/menu/my_record': 'Meu Cadastro',
  'action/menu/records_settings': 'Ajustes de Prontuário',
  'action/menu/privacy': 'Privacidade',
  'action/menu/forms': 'Formulários',
  'action/menu/eletronic_records': 'Receituário Eletrônico',
  'action/menu/clinic_settings': 'Ajustes da Clínica',
  'action/menu/my_clinic': 'Minha Clínica',
  'action/menu/doctors': 'Doutores',
  'action/menu/secretaries': 'Secretárias',
  'action/menu/agenda': 'Agenda',
  'action/menu/patients_data': 'Cadastro de Pacientes',
  'action/menu/advanced_settings': 'Ajustes Avançados',
  'action/menu/tags': 'Etiquetas',
  'action/menu/sevices_list': 'Tabela de Serviços',
  'action/menu/printing': 'Impressão',
  'action/menu/support': 'Suporte Técnico',
  'action/menu/logout': 'Sair do Doutore',
  'action/remove_logo': 'Remover Logo',
  'action/select_clinic_logo': 'Enviar Logo da Clínica',
  'action/train_secretary': 'Quero que entre contato para treinar minha secretária',
  'label/no_clinic_logo': 'Arraste aqui sua logo',
  'label/drop_logo': 'Solte sua logo aqui.',
  'label/backup_request_blocked': 'Você não tem acesso a esta funcionalidade',
  'label/action/change_clinic': 'Alterar',
  'action/menu/change_clinic_title': 'Selecione uma clínica',
  // memed signup
  'label/memed_singup/form/council_doc': 'CRM',
  'label/memed_singup/form/address_state': 'Estado do Registro CRM',
};
