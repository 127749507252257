// @flow
import React from 'react';
import { connect } from 'react-redux';
import { get } from 'lodash';
import { DoctorForm as DoctorFormView } from '@views';
import { clinicActions } from '@context/clinics';
import { router } from '@router';
import { mapBundledPermission } from '@helpers/connect';
import type { User, DoctorsState, Room, BundledPermissions } from '@types';
import { logout } from '@context/user';

type Props = {
  user: User,
  dispatch: Function,
  doctors: DoctorsState,
  rooms: Array<Room>,
  id: number,
  permissions: BundledPermissions,
  isSaving: boolean,
};

class DoctorForm extends React.Component<Props> {
  render() {
    const {
      user, doctors, id, rooms, permissions, isSaving,
    } = this.props;

    const isEdit = id !== 'novo';

    return (
      <DoctorFormView
        user={user}
        formData={isEdit ? get(doctors, ['data', String(id)], {}) : {}}
        onCancel={this.handleCancel}
        onSave={this.handleSave}
        id={id}
        rooms={rooms}
        isFeatureEnabled={isEdit ? true : (permissions.planInclude.inviteDoctors)}
        onLogout={this.handleLogout}
        permissions={this.props.permissions}
        isSaving={isSaving}
      />
    );
  }

  handleLogout = () => this.props.dispatch(logout());

  handleCancel = () => {
    this.props.dispatch(router.pushPath('doctorsList'));
  };

  handleSave = (data: Object) => {
    const { dispatch, id } = this.props;
    if (id !== 'novo') {
      dispatch(clinicActions.updateDoctor(id, data, (ok) => {
        if (ok) {
          this.props.dispatch(router.pushPath('doctorsList'));
        }
      }));
    } else {
      dispatch(clinicActions.createDoctor(data, (ok) => {
        if (ok) {
          this.props.dispatch(router.pushPath('doctorsList'));
        }
      }));
    }
  };
}

const mapStateToProps = (state, ownProps) => ({
  user: state.context.user,
  doctors: get(state, ['context', 'doctors'], {}),
  isSaving: get(state, ['context', 'doctors', 'isSaving'], false),
  rooms: get(state, ['context', 'clinics', 'activeClinic', 'rooms'], []),
  id: get(ownProps, ['match', 'params', 'id']),
  permissions: mapBundledPermission(state),
});

export default connect<any, any, any, any, any, any>(
  mapStateToProps,
)(DoctorForm);
