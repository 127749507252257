import React, { useState, useCallback } from 'react';
import TranslationHelper from './TranslationHelper/TranslationHelper';



const DevTools = () => {
  const [activeView, setActiveView] = useState(localStorage.getItem('devtools-view') || null);

  const openView = useCallback((viewName: string | null) => {
    setActiveView(viewName);
    if (viewName) {
      localStorage.setItem('devtools-view', viewName);
    } else {
      localStorage.removeItem('devtools-view');
    }
  }, []);


  const handleMenuItemClick = useCallback((viewName: string) => () => {
    setActiveView(viewName);
    if (viewName) {
      localStorage.setItem('devtools-view', viewName);
    } else {
      localStorage.removeItem('devtools-view');
    }
  }, []);

  if (activeView === 'TranslationHelper') {
    return <TranslationHelper openView={openView}/>;
  }

  return (
    <div>
      <div>Here are some devtools <span role="img" aria-label="rocket">🚀</span> </div>
      <br/>
      <ul>
        <li
          onClick={handleMenuItemClick('TranslationHelper')}
          style={{
            cursor: 'pointer',
            fontWeight: 'bold',
            userSelect: 'none',
          }}
        >
          <span role="img" aria-label="wolrd">🌐</span> Translation Helper
        </li>
      </ul>
    </div>
  );
};


export default DevTools;
