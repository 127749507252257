// @flow
import React, { useCallback } from 'react';
import type { Node } from 'react';
import { withRouter } from 'react-router-dom';
import classnames from 'classnames';
import { useTranslationNs } from '@helpers/helpers';
import Spinner from '../Spinner/Spinner';
import './Button.scss';

type Props = {
  text?: string,
  children?: Node,
  linkTo?: string, // route path (e.g. '/cadastro/profissao')
  onClick?: Function,
  className?: string,
  disabled?: boolean,
  history: Object,
  type?: string,
  isLoading?: boolean,
  loadingMessage?: string,
  disableSpinner?: boolean,
  greenSpinner?: boolean,
  intercom?: boolean,
};

const Button = ({
  className,
  disabled,
  text,
  children,
  type,
  isLoading,
  loadingMessage,
  disableSpinner,
  onClick,
  history,
  linkTo,
  greenSpinner,
  intercom,
}: Props) => {
  const [_] = useTranslationNs('financial');

  const cssClasses = classnames(['btn', className], { 'spinner-loading': isLoading && !disableSpinner });

  const loadingContent = disableSpinner ? loadingMessage || _('label/saving') : <Spinner text={loadingMessage || _('label/saving')} white={!greenSpinner} />;

  const handleClick = useCallback((e) => {
    if (disabled) {
      return;
    }

    if (intercom) {
      if (window.Intercom) {
        window.Intercom('show');
      }
      return;
    }

    if (linkTo) {
      history.push(linkTo);
    } else if (onClick) {
      onClick(e);
    }
  }, [onClick, history, linkTo, disabled, intercom]);

  return (
    <button
      className={cssClasses}
      disabled={disabled || isLoading}
      onClick={!disabled && !isLoading ? handleClick : null}
      {...(type ? { type } : {})}
      tabIndex="0"
    >
      {isLoading && loadingContent}
      {!isLoading && text}
      {!isLoading && children}
    </button>
  );
};
export default withRouter(React.memo(Button));
