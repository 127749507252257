import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import detector from 'i18next-browser-languagedetector';
import { getCountryCode } from '@helpers/helpers';
import Log from '@helpers/Log';
import es from './es';
import ptBr from './pt-br';


const resources = {
  es,
  pt: ptBr,
  'pt-BR': ptBr,
};
const languages = Object.keys(resources);
const namespaces = Object.keys(ptBr);

const i18nStorageKey = 'i18nextLng';


i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(detector)
  .init({
    detection: {
      order: ['querystring', 'cookie', 'localStorage', 'htmlTag'],
      lookupQuerystring: 'lng',
      lookupCookie: 'i18n',
      lookupLocalStorage: i18nStorageKey,
    },
    supportedLngs: languages,
    ns: namespaces,
    // lng: 'es',
    fallbackLng: 'pt-BR', // use if detected lng is not available
    defaultNS: 'global',
    fallbackNS: 'global',
    saveMissing: true, // send not translated keys to endpoint
    keySeparator: false, // we do not use keys in form messages.welcome
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    react: {
      bindI18n: 'languageChanged',
      bindI18nStore: '',
      transEmptyNodeValue: '',
      transSupportBasicHtmlNodes: true,
      transKeepBasicHtmlNodesFor: ['br', 'strong', 'i'],
      useSuspense: false,
    },
  });

Object.keys(resources).forEach(lng => {
  const resource = resources[lng];
  Object.keys(resource).forEach(ns => i18n.addResources(lng, ns, resource[ns]));
});

window.missingTranslations = {};

i18n.on('languageChanged', lng => {
  Log.debug(`[LNG] Language changed to ${lng}`);
  const l = String(lng).toLowerCase();

  if (l === 'pt-br' || l === 'pt') {
    window.isBR = true;
  } else {
    window.isBR = false;
  }
});

i18n.on('missingKey', (lngs: string[], namespace: string, key: string, res: string) => {
  if (window.missingTranslations[namespace]) {
    window.missingTranslations[namespace][key] = res;
  } else {
    window.missingTranslations[namespace] = {};
    window.missingTranslations[namespace][key] = res;
  }
});

window.i18n = i18n;
global.i18n = i18n;

const fetchCountryCode = () => new Promise(resolve => {
  Log.debug('Fetching country code...');

  const savedCountryCode = localStorage.getItem(i18nStorageKey);

  if (savedCountryCode) {
    window.countryCode = savedCountryCode.replace(/\w+-/, '');

    resolve();
    return;
  }

  const to = setTimeout(() => {
    window.countryCode = 'pt';
    resolve();
  }, 5000);

  getCountryCode().then(countryCode => {
    window.countryCode = countryCode;

    clearTimeout(to);
    resolve();
  });
});

let initialized = false;
i18n.on('initialized', () => {
  initialized = true;
});

export default () => new Promise(resolve => {
  const done = () => {
    fetchCountryCode().then(resolve);
  };
  if (initialized) {
    done();
  } else {
    i18n.on('initialized', done);
  }
});

