// @flow
import React from 'react';
import type { Node } from 'react';

type Props = {
  children?: Node,
  className?: string,
};

export default class WebHide extends React.Component<Props> {
  render() {
    const { children, className } = this.props;

    const view = className ? <div className={className}>{React.Children.map(children, item => item)}</div> :
    <React.Fragment>{React.Children.map(children, item => item)}</React.Fragment>;

    return !window.isCordovaApp ? null : view;
  }
}
