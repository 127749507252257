export default {
  'label/report_blocked': 'No tienes permiso para acceder a los informes financieros.',
  'label/general/report_blocked': 'No tienes permiso para acceder a este informe.',
  'menu/treatment': 'Atención',
  'menu/inquiries': 'Citas',
  'menu/debts': 'Deudores',
  'menu/financial': 'Financiero',
  'menu/payments': 'Pagos',
  'menu/receipts': 'Recibos',
  'menu/sms': 'SMS',
  'menu/services': 'Servicios',
  'debt/debtor_patients': 'Pacientes Deudores',
  'debt/debit_playments': 'Pagos en Débito',
  'debt/patients_with_negative_balance': 'Pacientes con Saldo Negativo',
  'debt/empty_title': 'No hay ningún paciente en deuda',
  'debt/empty_hint': 'Todos los pacientes deudores aparecerán automáticamente aquí.',
  'appointments/confirmed': 'Confirmadas',
  'appointments/attended_patients': 'Pacientes atendidos',
  'appointments/patient_missed': 'Paciente faltó',
  'appointments/canceled_by_patient': 'Canceladas por el paciente',
  'appointments/canceled_by_sms': 'Canceladas por SMS',
  'appointments/canceled_by_doctor': 'Canceladas por el consultorio',
  'appointments/title': 'Informe de Citas',
  'filters/status/confirmed': 'Contratado',
  'filters/status/forecasted': 'Previsto',
  'filters/status/canceled': 'Cancelado',
  'filters/status/health_insurance': 'Plan de Salud',
  'filters/label_period': 'Período',
  'filters/status': 'Status',
  'filters/fiscal_note_only': 'Solo Nota Fiscal',
  'payments/title': 'Pagos Recibidos',
  'payments/empty_title': 'No hay ningún pago registrado en la fecha seleccionada.',
  'payments/empty_hint': 'Intenta buscar una fecha diferente.',
  'receipts/empty_title': 'No hay ningún recibo en la fecha seleccionada.',
  'receipts/empty_hint': 'Intenta buscar una fecha diferente.',
  'receipts/receipts': 'Recibos',
  'receipts/title': 'Lista de Recibos',
  'services/title': 'Servicios',
  'services/max_limit_reached': 'El período seleccionado tiene muchos servicios. Mostrando solo {maxLimit} servicios.',
  'services/empty_title': 'No hay ningún servicio contratado en la fecha seleccionada.',
  'services/empty_hint': 'Intenta buscar una fecha diferente.',
  'services/hired_list': 'Lista de Servicios Contratados',
  'sms/package': 'Paquete',
  'sms/sent': 'SMS Enviados',
  'sms/remaining_sms_balance': 'Saldo Restante de SMS',
  'sms/total_sent_sms': 'Total de SMS Enviados',
  'sms/total_contrated_sms': 'Total de SMS Contratados',
  'sms/not_contracted_message': 'No has comprado ningún paquete de SMS. Para saber más,',
  'sms/contracted_sms_packages': 'Paquetes de SMS Contratados',
  'sms/sent_report': 'Informe de Envíos',
  'sms/messages_per_sms': 'Mensajes por SMS',
};
