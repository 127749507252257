// @flow
import React from 'react';
import { PlatformSwitch } from '@blocks';
import { Modal, Icon, Button, Loading, FormattedText } from '@elements';
import { withTranslation } from 'react-i18next';
import { createNsTranslator } from '@helpers/helpers';
import type { Receipt } from '@types';
import './ReceiptModal.scss';

type Props = {
  onClose: Function,
  onEditReceiptClick: Function,
  isLoading: boolean,
  receipt: Receipt,
  onPrint: Function,
  t: Function,
};

class ReceiptModal extends React.Component<Props> {
  render() {
    const {
      onClose, onEditReceiptClick, isLoading, receipt, onPrint,
    } = this.props;

    const _ = createNsTranslator('financial', this.props.t);

    const content = (
      <React.Fragment>
        {/* <h3>{_('label/receipt_title')}{receipt.number}</h3> */}
        <p>
          {receipt.text}
          <br />
          <br />
          {receipt.notes &&
          <div>
            {_('label/additional_informations')}: {receipt.notes}
            <br />
            <br />
          </div>
                  }
          {receipt.patientIsNotTheBuyer ? (
            <div>
              <strong>{_('label/buyer')}</strong>
              <br />{receipt.buyerName}
              <br />
              {receipt.buyerCpf && (
                <>
                  {_('label/cpf', 'global')}: <FormattedText type="cpf" text={receipt.buyerCpf} />
                </>
              )}
              <br />
              <br />
              <strong>{_('label/service_taker')}:</strong>
              <br />{receipt.patientName}
              <br />
              {receipt.patientCpf && (
                <>
                  {_('label/cpf', 'global')}: <FormattedText type="cpf" text={receipt.patientCpf} />
                </>
              )}
              <br />
              <br />
            </div>
          ) : (
            <div>
              <strong>{_('label/responsible_info')}:</strong>
              <br />{receipt.patientName}
              <br />
              {receipt.patientCpf && (
                <>
                  {_('label/cpf', 'global')}: <FormattedText type="cpf" text={receipt.patientCpf} />
                </>
              )}
              <br />
              <br />
            </div>
          )}
          <strong>{_('label/service_provider')}:</strong>
          <br />{receipt.sellerName}
          <br />
          {receipt.sellerCpf && (
            <>
              {_('label/cpf', 'global')}: <FormattedText type="cpf" text={receipt.sellerCpf} />
            </>
          )}
          <br />{_('label/council_doc', 'global')}: {receipt.sellerCouncilDoc}
        </p>
      </React.Fragment>
    );

    return (
      <PlatformSwitch
        inject
        desktop={() => (
          <Modal id="receiptPreviewModal" onClose={onClose}>
            <div className="modalHeader">
              <span className="modalTitle">{_('label/receipt_title')}{receipt.number}</span>
            </div>

            <div className="modalGrid">
              <div className="modalContainer">
                {isLoading && <Loading />}
                {!isLoading &&
                <div className="modalContent">
                  {content}
                </div>
            }

                <div className="modalFooter">
                  <Button onClick={onEditReceiptClick}>
                    <Icon iconName="edit" className="icon" size={24} />
                    Editar
                  </Button>
                  <Button onClick={onPrint}>
                    <Icon iconName="print" className="icon" size={24} />
                    Imprimir
                  </Button>
                </div>
              </div>
            </div>
          </Modal>
        )}
        mobile={() => (
          <Modal id="receiptPreviewModal" className="asView" onClose={onClose}>
            <div className="modalHeader">
              <Button onClick={onClose}>
                <Icon iconName="close" className="icon" />
              </Button>
              <span className="title">{_('label/receipt_title')}{receipt.number}</span>
              <Button className="primary" text="Imprimir" onClick={onPrint} />
              <Button className="primary" text="Editar" onClick={onEditReceiptClick} />
            </div>
            <div className="modalGrid">
              <div className="modalContainer">
                <div className="modalContent padding">
                  {content}
                </div>
              </div>

            </div>
          </Modal>
        )}
      />
    );
  }
}

export default withTranslation()(ReceiptModal);
