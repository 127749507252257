export default {
  // login
  'label/password': 'Contraseña',
  'label/lost_password': 'Olvidé mi contraseña',
  'label/login': 'Iniciar sesión',
  'label/signup_call': '¿Aún no tienes una cuenta?',
  'label/login/error/email': 'Falta el correo electrónico',
  'label/login/error/password': 'Falta la contraseña',
  'label/login/error/invalid_email': 'El correo electrónico no es válido',
  'action/signup_now': 'Regístrate ahora',
  // singup
  'label/create_your_account': 'Crea tu cuenta',
  'label/email': 'Correo electrónico',
  'label/create_your_password': 'Elige una contraseña',
  'placeholder/password': 'Al menos 6 caracteres',
  'label/step2_call': 'Para personalizar tu experiencia, necesitamos saber un poco más sobre ti.',
  'label/step2_call_email': 'Quiero recibir correos electrónicos ocasionales de entrenamiento y soporte (prometemos: nunca enviaremos spam)',
  'label/complete_name': '¿Cuál es tu nombre completo?',
  'label/mobile_phone': 'Teléfono móvil (opcional)',
  'placeholder/optional': '(opcional)',
  'action/create_account': 'Crear cuenta',
  'action/next': 'Siguiente',
  'label/received_invite': '¿Recibiste una invitación o ya tienes una cuenta?',
  'label/login_now': 'Inicia sesión ahora',
  'error/password': 'Introduce una contraseña.',
  'error/name': 'Introduce tu nombre.',
  'error/phone': 'El teléfono no es válido.',
  'error/email_empty': 'Introduce tu correo electrónico.',
  'error/email_invalid': 'Correo electrónico no válido.',
  'label/select_feedback': '¡Genial! Ya que has seleccionado',
  'label/whats_your_specialty': '¿Cuál es tu especialidad?',
  'label/whats_your_profession': '¿Cuál es tu profesión?',
  'label/clinic_work_style_label': '¿Cómo trabajas actualmente?',
  'label/clinic_work_style_alone': 'Solo sin secretaria',
  'label/clinic_work_style_with_secretary': 'Solo con secretaria',
  'label/clinic_work_style_up_to_5': 'Con 2 a 5 profesionales de salud',
  'label/clinic_work_style_more_than_5': 'Con más de 5 profesionales de salud',
  // newClinic
  'label/define_new_clinic': 'Definir Nueva Clínica',
  'label/clinic_not_found': 'Tu cuenta ya no pertenece a una clínica. Es necesario estar asociado a una clínica o consultorio para tener acceso a Doutore',
  'action/create_new_clinic': 'Crear Nueva Clínica',
  // GetStarted
  'label/lets_start': '¡Empecemos!',
  'action/create_a_patient': 'Crear un Paciente',
  'action/create_a_appointment': 'Programar una Cita',
  'action/view_patient_example': 'Ver un Ejemplo de Paciente',
  'action/explore_app': 'Explorar la Aplicación',
  // ForgotPassword
  'label/forgot_password': 'Olvidé la Contraseña',
  'label/recover_success': 'Se ha enviado un mensaje a tu correo electrónico con las instrucciones para recuperar tu contraseña.',
  'label/email_error': 'Este correo electrónico no está registrado en nuestra base de datos.',
  'label/your_email': 'Tu correo electrónico',
  'label/q_remember_your_password': '¿Recordaste tu contraseña?',
  'action/send_email_instrunctions': 'Enviar instrucciones por correo electrónico',
  // NewPassword
  'action/create_password': 'Crear Contraseña',
  'label/requre_define_password': 'Antes de acceder a Doutore, es necesario definir una contraseña personal para iniciar sesión.',
  'label/create_password_input': 'Crea tu contraseña:',
  // AppHome
  'label/onboarding/text1': 'Acceso al expediente de tu paciente siempre que lo necesites.',
  'label/onboarding/text2': 'Agenda Médica siempre actualizada, sincronizada y de fácil acceso.',
  'label/onboarding/text3': 'Tu secretaria utiliza Doutore online, desde el ordenador.',
  'label/onboarding/text4': 'Y tú, puedes acceder desde cualquier lugar. Desde el ordenador, tablet y móvil.',
  'label/have_account': '¿Ya tienes una cuenta?',
  'action/login_now': 'Inicia sesión ahora',
};
