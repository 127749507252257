// @flow
import React from 'react';
import classNames from 'classnames';
import { isFunction, get, isNaN, isEqual } from 'lodash';
import type { ToastData } from '../../../types';
import { Icon } from '../../elements';
import { createNsTranslator, isPresent } from '@helpers/helpers';
import './Toast.scss';
import { withTranslation } from 'react-i18next';

type Props = {
  toastData: ToastData,
  onClose: Function,
  t: any,
};

class Toast extends React.Component<Props> {
  componentDidMount() {
    const { toastData } = this.props;
    const timeout = get(toastData, 'timeout', 0);
    if (!isNaN(timeout) && timeout > 0 && !this.timeoutId) {
      this.timeoutId = setTimeout(this.handleOnClose, timeout);
    }
  }

  componentWillReceiveProps(nextProps: Props) {
    if (isEqual(this.props.toastData, nextProps.toastData)) {
      return;
    }
    const timeout = get(nextProps.toastData, 'timeout', 0);
    if (!isNaN(timeout) && timeout > 0 && !this.timeoutId) {
      this.timeoutId = setTimeout(this.handleOnClose, timeout);
    }
  }

  componentWillUnmount() {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
      this.timeoutId = null;
    }
  }

  render() {
    const { toastData, t } = this.props;
    const _ = createNsTranslator('toasts', t);


    if (!toastData) return null;

    const cssClasses = classNames('toast', toastData.type ? toastData.type : '', {
      haveCloseButton: Boolean(toastData.close),
    });

    return (
      <div className={cssClasses}>
        <span className="toastText left">{toastData.text ? toastData.text : _(toastData.i18nText || 'unkdown_translation')}</span>
        {isPresent(toastData.fileURL) && toastData.fileURL &&
          <div
            onClick={this.handleOpenFile(toastData.fileURL)}
            target="_blank"
            className="right downloadLink"
          >
            VISUALIZAR
          </div>
        }
        {toastData.close &&
          <div onClick={this.handleOnClose} className="close">
            <Icon iconName="close" size={20} />
          </div>
        }
      </div>
    );
  }

  timeoutId = null;

  handleLinkClick = () => {
    const { onClose } = this.props;
    if (isFunction(onClose)) {
      onClose();
    }
  };

  handleOnClose = () => {
    const { onClose } = this.props;
    if (isFunction(onClose)) {
      onClose();
    }
  };

  handleOpenFile = (file: string) => () => {
    const { onClose } = this.props;

    window.open(file, '_blank');

    if (isFunction(onClose)) {
      onClose();
    }
  };
}

export default withTranslation()(Toast);
