// @flow
import React from 'react';
import { Icon } from '@elements';
import { isFunction, map, filter } from 'lodash';
import ReactDOM from 'react-dom';
import { withTranslation } from 'react-i18next';
import './DragAndDropFile.scss';

type Props = {
  children?: any,
  disabled?: boolean,
  onDropFiles: Function,
  dropText?: string,
  t: Function,
};

type State = {
  dragging: boolean,
}

class DragAndDropFile extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      dragging: false,
    };
  }

  componentDidMount() {
    if (!this.props.disabled) {
      const div = ReactDOM.findDOMNode(this);
      if (div) {
        div.addEventListener('dragenter', this.handleDragIn);
        div.addEventListener('dragleave', this.handleDragOut);
        div.addEventListener('dragover', this.handleDrag);
        div.addEventListener('drop', this.handleDrop);
      }
    }
  }

  componentWillReceiveProps(nextProps: Props) {
    if (this.props.disabled !== nextProps.disabled) {
      const div = ReactDOM.findDOMNode(this);
      if (nextProps.disabled && div) {
        div.removeEventListener('dragenter', this.handleDragIn);
        div.removeEventListener('dragleave', this.handleDragOut);
        div.removeEventListener('dragover', this.handleDrag);
        div.removeEventListener('drop', this.handleDrop);
      } else if (div) {
        div.addEventListener('dragenter', this.handleDragIn);
        div.addEventListener('dragleave', this.handleDragOut);
        div.addEventListener('dragover', this.handleDrag);
        div.addEventListener('drop', this.handleDrop);
      }
    }
  }

  componentWillUnmount() {
    const div = ReactDOM.findDOMNode(this);
    if (div) {
      div.removeEventListener('dragenter', this.handleDragIn);
      div.removeEventListener('dragleave', this.handleDragOut);
      div.removeEventListener('dragover', this.handleDrag);
      div.removeEventListener('drop', this.handleDrop);
    }
  }


  render() {
    const { dragging } = this.state;
    return (
      <div ref={this.dropRef} className="doutoreDragAndDropFile">
        <div className={`overlay ${dragging ? 'dragging' : ''}`}>
          <Icon iconName="file-solid" />
          <br />
          <h1>{this.props.dropText || this.props.t('timeline:label/drop_file')}</h1>
        </div>
        {this.props.children}
      </div>
    );
  }

  handleDrag = (e: Object) => {
    e.preventDefault();
    e.stopPropagation();
    this.enterTarget = e.target;
  };

  handleDragIn = (e: Object) => {
    e.preventDefault();
    e.stopPropagation();
    this.dragCounter++;
    if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
      this.setState({ dragging: true });
    }
  };

  handleDragOut = (e: Object) => {
    this.dragCounter--;

    e.preventDefault();
    e.stopPropagation();

    if (this.dragCounter === 0) {
      this.setState({ dragging: false });
    }
  };

  handleDrop = (e: Object) => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({ dragging: false });
    if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
      if (isFunction(this.props.onDropFiles)) {
        let files = map<any, any>(e.dataTransfer.items, droppedItem => {
          if (droppedItem.kind === 'file') {
            return droppedItem.getAsFile();
          }
          return null;
        });
        files = filter(files, f => f != null && f !== undefined);
        this.props.onDropFiles(files);
      }

      e.dataTransfer.clearData();

      this.dragCounter = 0;
    }
  };

  enterTarget: ?Object;
  dropRef: ?Object;
  dragCounter: number = 0;
}

export default withTranslation()(DragAndDropFile);
