// @flow
import React, { useMemo, useCallback, useRef, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import FullCalendar from '@fullcalendar/react';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import dayGridPlugin from '@fullcalendar/daygrid';
import momentPlugin from '@fullcalendar/moment';
import ptbrLang from '@fullcalendar/core/locales/pt-br';
import esLang from '@fullcalendar/core/locales/es';
import classNames from 'classnames';
import moment from 'moment';
import { get, find, map, forEach, filter, isEqual, omit, set, findKey } from 'lodash';
import { Calendar as FC } from '@fullcalendar/core';
import { Loading } from '@elements';
import type
{
  WorkHours,
} from '@types';
import { useIsMobile, Log, isPresent, useTranslationNs } from '@helpers/helpers';
// import { Loading } from '@elements';
import { handleFullCalendarTimerHover } from './utils';
import CalendarEvent from './CalendarEvent';
import './Calendar.scss';


type Props = {
  activeModal: ?string,
  calendarView: 'timeGridWeek' | 'timeGridDay',
  selectedDate: string,
  appointments: Object[],
  clinicDoctors: Object,
  onGetAppointments: Function,
  onShowAppointment: Function,
  onUpdateAppointment: Function,
  onNewAppointmentClick: Function,
  isLoading: boolean,
  workHours: WorkHours,
  loadingItems: { [id: number]: boolean },
  isFinatialEnabled: boolean,
  clinic: Object,
  user: Object,
  filter: Object,
  showProgressBar: boolean,
  language: string,
};


const plugins = [interactionPlugin, momentPlugin, timeGridPlugin, dayGridPlugin];

const getNow = (clinic: any) => {
  const forceTimezone = get(clinic, ['forceTimezone'], null);
  return isPresent(forceTimezone) ? moment.utc().add(forceTimezone, 'hours').format() : moment().format();
};

/**
 * Important Notes:
 *
 * - When use startOf and endOf avois using week, prefer use of isoWeek
 */

const Calendar = ({
  activeModal, isLoading, calendarView, clinicDoctors, isFinatialEnabled, language, loadingItems, onGetAppointments,
  onNewAppointmentClick, onShowAppointment, onUpdateAppointment, selectedDate, workHours, clinic, user, showProgressBar, ...props
}: Props) => {
  const [_] = useTranslationNs('agenda');
  const api = useRef(null);
  const [isMobile] = useIsMobile();
  const [ghostAppointment, setGhostAppointment] = useState(null);
  const refHash = useRef({});
  const scrollTimeTriggered = useRef(false);
  const lastScrollTime = useRef(null);
  const lastAppointmentFetch = useRef(null);
  const [firstFetch, setFirstFetch] = useState(false);

  /**
   * Only show appointments rendered in agenda
   */

  const isSameWeekThatSelectedDate = useCallback((date) => (
    date.isBetween(moment.utc(selectedDate).startOf('isoWeek'), moment.utc(selectedDate).endOf('isoWeek'))
      || date.isSame(moment.utc(selectedDate).startOf('isoWeek'), 'day')
      || date.isSame(moment.utc(selectedDate).endOf('isoWeek'), 'day')
  ), [selectedDate]);

  const appointments = useMemo(() => filter(props.appointments, appointment => {
    const start = moment.utc(moment.utc(appointment.start).format('YYYY-MM-DD HH:mm'));
    return isSameWeekThatSelectedDate(start);
  }), [props.appointments, isSameWeekThatSelectedDate]);

  const eventAllow = useCallback((dropLocation: Object, event: Object) => !get(loadingItems, String(get(event, 'id', '')), false), [loadingItems]);

  const clinicNow = useMemo(() => getNow(clinic), [clinic]);

  const scrollTime = useMemo(() => get(clinic, 'businessHoursStart') || '9:00', [clinic]);
  const snapDuration = useMemo(() => get(clinic, 'snapDuration') || '00:15:00', [clinic]);
  const defaultDate = useMemo(() => moment('12', 'HH').toDate(), []);
  const locale = useMemo(() => (language === 'es' ? esLang : ptbrLang), [language]);
  const defaultView = useMemo(() => (isMobile || calendarView === 'timeGridDay' ? 'timeGridDay' : 'timeGridWeek'), [calendarView, isMobile]);

  const showWeekends = useMemo(() => get(clinic, 'showWeekends', false) || calendarView === 'timeGridDay', [calendarView, clinic]);

  // ============================================================================================================================
  // ========================================================= Utilities ================================================
  // ============================================================================================================================

  /**
   * Order event by doctor id
   */
  const eventOrder = useCallback((a, b) => {
    const eventA = find(appointments, ev => String(get(ev, 'id', '')) === String(get(a, 'id', '')));
    const eventB = find(appointments, ev => String(get(ev, 'id', '')) === String(get(b, 'id', '')));
    return parseInt(get(eventA, 'doctorId', 0), 10) - parseInt(get(eventB, 'doctorId', 0), 10);
  }, [appointments]);

  const defaultDuration = get(clinicDoctors[(
    (get(props.filter, ['doctor', 'length']) ? get(props.filter, ['doctor', String(0)]) : null) ||
    (!get(user, ['roleType']) === 'Doctor' && !get(user, ['advancedData', 'doctorId'])) ||
    get(props.filter, ['doctor', String(1)]) ||
    findKey(clinicDoctors)
  )], 'defaultAppointmentDuration');

  /**
   * Calendar vies settings
   */
  const viewsConfig = useMemo(() => ({
    week: {
      columnHeaderFormat: 'ddd, D/M',
      titleFormat: 'DD/MMM',
    },
    day: {
      columnHeaderFormat: 'dddd',
      titleFormat: 'ddd, DD/MM',
    },
  }), []);
  /**
   * Get the appointment by event id, used to merge with event extendedProps
   */
  const getAppointment = useCallback((id: string = '') => ({
    id,
    ...(find(appointments, ap => parseInt(get(ap, 'id', ''), 10) === parseInt(id, 10)) || {}),
  }), [appointments]);

  /**
   * get Business work hours setting
   */
  const businessHours = useMemo(() => ({
    startTime: get(clinic, 'businessHoursStart') || '9:00',
    endTime: get(clinic, 'businessHoursEnd') || '18:00',
    daysOfWeek: [1, 2, 3, 4, 5],
  }), [clinic]);

  // ============================================================================================================================
  // ========================================================= FC Events sources ================================================
  // ============================================================================================================================

  useEffect(() => {
    if (!firstFetch) {
      const start = isMobile ? moment.utc().startOf('day') : moment.utc().startOf('isoWeek').subtract(1, 'day');
      const end = isMobile ? moment.utc().endOf('day') : moment.utc().endOf('isoWeek').add(1, 'day');
      onGetAppointments(isMobile || calendarView === 'timeGridDay');
      refHash.current = JSON.stringify({ selectedDate, defaultDate, isMobile });
      Log.info('onGetAppointments', start, end);
      setFirstFetch(true);
    }
  }, [calendarView, defaultDate, firstFetch, isMobile, onGetAppointments, selectedDate]);

  /**
   * Fetch appoointments when selectedDate or defaultDate ou mobile changes
   */
  const fetchSource = useCallback(({ start, end, timezone }, callback: Function) => {
    if (!isEqual(refHash.current, JSON.stringify({ selectedDate, defaultDate, isMobile }))) {
      onGetAppointments(isMobile || calendarView === 'timeGridDay');
      refHash.current = JSON.stringify({ selectedDate, defaultDate, isMobile });
      Log.info('onGetAppointments', start, end, timezone);
    }
    callback([]);
  }, [calendarView, defaultDate, isMobile, onGetAppointments, selectedDate]);

  /**
   * Get appointments and handle data
   */
  const appointmentSource = useCallback((dt, callback: Function) => {
    const appointmentsList = map<any, any>(appointments, appointment => {
      const canChange = !get(loadingItems, get(appointment, 'id'), false) && String(appointment.doctorId) !== '1';

      return {
        ...appointment,
        startEditable: canChange,
        durationEditable: canChange,
        doctorId: appointment.doctorId,
      };
    });
    const renderAppointments = ghostAppointment ? [...appointmentsList, ghostAppointment] : appointmentsList;
    callback(renderAppointments);
  }, [appointments, ghostAppointment, loadingItems]);

  /**
   * Render doctor workhours background event
   */
  const workHoursSource = useCallback((dt, callback: Function) => {
    const doctorFilterId = get(props.filter, ['doctor', '0']);
    const renderedDays = (defaultView === 'timeGridWeek') ? [1, 2, 3, 4, 5, 6, 7] : [moment(selectedDate).day() || 0];
    if (doctorFilterId > 0) {
      const doctorWorkHours: Object = workHours || {};

      const doctorWorkHourEvents = [];

      forEach(renderedDays, (day) => {
        const dayWorkHourEvents = filter(doctorWorkHours, item => get(item, 'weekday') === day);


        forEach(dayWorkHourEvents, dayWorkHourEvent => {
          if (!dayWorkHourEvent) return;

          const dateStr = moment.utc(selectedDate).startOf('isoWeek').weekday(day).format('YYYY-MM-DD');

          const start = moment.utc(`${dateStr} ${dayWorkHourEvent.start}:00`);
          const end = moment.utc(`${dateStr} ${dayWorkHourEvent.end}:00`);

          const id = doctorFilterId + '-' + moment(dateStr, 'DD/MM/YYYY').unix() + '-';

          const wkEvent = {
            id,
            doctorId: doctorFilterId,
            start: start.toISOString(),
            end: end.toISOString(),
            rendering: 'background',
            classNames: ['bg-doctor-workhour'],
            backgroundColor: 'rgb(143, 223, 130)',
            isBackgroundEvent: true,
          };

          doctorWorkHourEvents.push(wkEvent);
        });
      });
      callback(filter(doctorWorkHourEvents, item => item !== null));
    }
  }, [defaultView, props.filter, selectedDate, workHours]);

  // ============================================================================================================================
  // ========================================================= Rendering related ================================================
  // ============================================================================================================================

  /**
   * Handle event renderig using react-dom
   */
  const eventRenderer = useCallback((rData) => {
    const targetRender = document.createElement('div');
    // We merge eventApi with appointment due to extendedProps limitations
    const eventData = {
      ...rData.event,
      ...rData.event.extendedProps,
      ...getAppointment(get(rData.event, 'id', '')),
    };

    // if(!isPresent(eventData.id)) return null;

    if (!window.renderedEvents) {
      window.renderedEvents = {};
    }

    window.renderedEvents[get(eventData, 'id', '')] = eventData;

    ReactDOM.render((
      <CalendarEvent
        event={eventData}
        isFinatialEnabled={isFinatialEnabled}
        loadingItems={loadingItems}
        clinicDoctors={clinicDoctors}
        agendaFilter={props.filter}
        isMobile={isMobile || window.isCordovaApp}
        classList={rData.el.classList.value}
        clinic={clinic}
        user={user}
        snapDuration={snapDuration}
        _={_}
      />
    ), targetRender, () => {
      /**
       * CalendarEvent's first div is only designed to extract the classes and their
       * contents and apply them to the event element, thus simplifying the code in general.
       */
      rData.el.innerHTML = get(targetRender, ['firstChild', 'innerHTML'], '');
      rData.el.classList.value = get(targetRender, ['firstChild', 'classList', 'value'], '');
    });
    return targetRender.firstChild;
  }, [getAppointment, isFinatialEnabled, loadingItems, clinicDoctors, props.filter, isMobile, clinic, user, snapDuration, _]);

  // =============================================================================================================================
  // ========================================================= Effects ===========================================================
  // =============================================================================================================================
  /**
   * Handle time hover
   */
  useEffect(() => {
    try {
      if (!isMobile && api.current) {
        handleFullCalendarTimerHover(snapDuration);
      }
    } catch (e) {
      Log.error(e);
    }
  }, [isMobile, snapDuration]);

  /**
   * Unselect when modal is closed
   */
  useEffect(() => {
    try {
      if (activeModal !== 'NewAppointmentModal' && api.current) {
        api.current.unselect();
      }
    } catch (e) {
      Log.error(e);
    }
  }, [activeModal]);

  /**
   * Handle scrollTime
   * First scroll
   */
  useEffect(() => {
    try {
      if (!scrollTimeTriggered.current && api.current && !isLoading) {
        scrollTimeTriggered.current = true;
        const _api: FC = api.current;
        setTimeout(() => {
          try {
            _api.scrollToTime(`${scrollTime}`);
          } catch (e) {
            //
          }
        }, 200);
        Log.info(`[Agenda]: Scrolled to ${scrollTime}`);
      }
    } catch (e) {
      // Log.error(e);
    }
  }, [scrollTime, isLoading]);

  /**
   * Refetch events when ghostAppointment or calendar changes
   */
  useEffect(() => {
    try {
      if (api.current) {
        api.current.refetchEvents();
      }
    } catch (e) {
      Log.error(e);
    }
  }, [ghostAppointment]);

  const lcasCalcView = useRef(null);

  useEffect(() => {
    try {
      const calcView = isMobile || calendarView === 'timeGridDay';
      if (calcView !== lcasCalcView.current) {
        lcasCalcView.current = calcView;
        onGetAppointments(calcView);
      }
    } catch (e) {
      Log.error(e);
    }
  }, [calendarView, isMobile, onGetAppointments]);

  // =============================================================================================================================
  // ========================================================= Agenda Events Handlers ============================================
  // =============================================================================================================================

  /**
  * Handle on click in cell of calendar
  */
  const handleDateClick = useCallback((params: Object) => {
    if (isMobile || window.isCordovaApp) {
      const start = moment.utc(params.date);
      let startT = moment(start);
      let endT = moment(start);

      if (moment(start).minutes() >= 30) {
        endT = moment(start).set('minutes', 30).add(defaultDuration, 'minutes');
      } else {
        endT = moment(start).set('minutes', 0).add(defaultDuration, 'minutes');
      }

      if (startT.minutes() >= 30) {
        startT = startT.clone().set('minutes', 30);
      } else {
        startT = startT.clone().set('minutes', 0);
      }

      const _ghostAppointment = {
        start: startT.toISOString(),
        end: endT.toISOString(),
        allDay: false,
        title: '',
        extendedProps: {
          full: false,
          time: moment.utc(start).toISOString(),
          date: moment.utc(start).format('YYYY-MM-DD'),
          badge: null,
          patient: null,
          restricted: false,
          isGhost: true,
          duration: defaultDuration,
          room: 1,
          doctorId: 72,
          notes: '',
        },
      };
      setGhostAppointment(_ghostAppointment);
    }
  }, [defaultDuration, isMobile]);

  /**
   * Handle select interval on calendar
   */
  const handleSelect = useCallback(({ startStr, endStr }: Object) => {
    if (isMobile) return;
    let end = moment.utc(endStr);
    const start = moment.utc(startStr);

    const isDayClick = moment.utc(end).diff(start, 'minutes') === moment(`${moment().format('YYYY-MM-DD')} ${snapDuration}`).minute();

    if (isDayClick) {
      end = start.clone().add(defaultDuration, 'minutes');
    }

    onNewAppointmentClick(
      start,
      end,
      isDayClick,
    );
  }, [defaultDuration, isMobile, onNewAppointmentClick, snapDuration]);

  /**
   * Handle event drop
   */
  const handleEventDrop = useCallback(({ event }: Object) => {
    if (isMobile) return;

    let appointment = omit(event.extendedProps, ['className', 'source', 'start', 'end', '_id']);
    appointment = set(appointment, 'date', event.start.toISOString());
    appointment = set(appointment, 'time', event.start.toISOString());
    const eData = { ...getAppointment(get(event, 'id', '')), ...appointment };
    onUpdateAppointment(eData);
  }, [onUpdateAppointment, isMobile, getAppointment]);

  /**
   * Handle event risize
   */
  const handleEventResize = useCallback(({ event }: Object) => {
    if (isMobile) return;

    const duration = moment.duration(moment(event.end).diff(event.start));
    let appointment = omit(event.extendedProps, ['className', 'source', 'start', 'end', '_id']);
    appointment = set(appointment, 'date', event.start.toISOString());
    appointment = set(appointment, 'time', event.start.toISOString());
    appointment = set(appointment, 'duration', duration.asMinutes());
    const eData = { ...getAppointment(get(event, 'id', '')), ...appointment };
    onUpdateAppointment(eData);
  }, [onUpdateAppointment, isMobile, getAppointment]);

  /**
   * Handle event click
   */
  const handleEventClick = useCallback((dt) => {
    const event = { ...dt.event.extendedProps, ...getAppointment(get(dt, ['event', 'id'], '')) };

    const highPrivacyBlocked = get(event, 'restricted', false);
    if (event.restricted || highPrivacyBlocked) return false;

    if (event.isGhost) {
      const getTime = () => {
        if (isMobile) {
          const minutes = moment.utc(event.time).minutes();

          const eventTime = moment.utc(event.time).set('minutes', minutes >= 30 ? 30 : 0);

          return eventTime;
        }

        return moment.utc(event.time);
      };

      onNewAppointmentClick(getTime().format('YYYY-MM-DD HH:mm:ss'),
        getTime().add(defaultDuration, 'minutes').format('YYYY-MM-DD HH:mm:ss'));
      setGhostAppointment(null);
    } else {
      onShowAppointment(event);
    }
  }, [getAppointment, onNewAppointmentClick, defaultDuration, isMobile, onShowAppointment]);

  useEffect(() => {
    try {
      if (api.current && !isLoading) {
        const _api: FC = api.current;
        if (!isMobile) {
          _api.gotoDate(moment.utc(selectedDate).startOf('isoWeek').toDate());
        } else {
          _api.gotoDate(moment.utc(selectedDate).toDate());
        }
      }
    } catch (e) {
      Log.error(e);
    }
  }, [selectedDate, isLoading, isMobile]);

  useEffect(() => {
    try {
      if (api.current) {
        const _api: FC = api.current;
        _api.gotoDate(selectedDate);
      }
    } catch (e) {
      Log.error(e);
    }
  }, [isLoading, calendarView, selectedDate, isMobile]);


  useEffect(() => {
    try {
      if (api.current && !isLoading) {
        const _api: FC = api.current;
        if (calendarView === 'timeGridDay' || isMobile) {
          _api.changeView(calendarView, selectedDate);
        } else {
          _api.changeView(calendarView, {
            start: moment.utc(selectedDate).startOf('isoWeek'),
            end: moment.utc(selectedDate).endOf('isoWeek'),
          });
        }
        _api.refetchEvents();
      }
    } catch (e) {
      Log.error(e);
    }
  }, [isLoading, calendarView, selectedDate, isMobile]);

  // useEffect(() => {
  //   try {
  //     if ((!scrollTimeTriggered.current || lastSelectedDate.current !== selectedDate) && api.current && !isLoading) {
  //       const _api: FC = api.current;
  //       setTimeout(() => _api.scrollToTime(`${scrollTime}`), 200);
  //       Log.info(`[Agenda]: Scrolled to ${scrollTime}`);
  //       scrollTimeTriggered.current = true;
  //       lastSelectedDate.current = selectedDate;
  //     }
  //   } catch (e) {
  //     Log.error(e);
  //   }
  // }, [scrollTime, isLoading, selectedDate]);

  useEffect(() => {
    try {
      if (api.current && !isLoading && lastScrollTime.current !== scrollTime) {
        const _api: FC = api.current;
        _api.setOption('scrollTime', scrollTime);
        lastScrollTime.current = scrollTime;
      }
    } catch (e) {
      Log.error(e);
    }
  }, [isLoading, scrollTime]);

  useEffect(() => {
    try {
      if (api.current && !isLoading && !isEqual(lastAppointmentFetch.current, appointments)) {
        const _api: FC = api.current;
        _api.removeAllEvents();
        _api.refetchEvents();
        lastAppointmentFetch.current = appointments;
      }
    } catch (e) {
      Log.error(e);
    }
  }, [isLoading, appointments, loadingItems]);

  const nowIndicator = useMemo(() => get(clinic, ['nowIndicator'], true), [clinic]);

  return (
    <div id="agenda-calendar">
      <section className="agenda">
        <div className={classNames('calendar-holder', { isLoading: isLoading && !showProgressBar, isMobile })}>
          <FullCalendar
            allDaySlot={false}
            firstDay={1}
            slotDuration="00:30:00"
            snapDuration={snapDuration}
            slotLabelFormat="HH:mm"
            eventTimeFormat="HH:mm"
            header={false}
            nowIndicator={nowIndicator}
            unselectAuto={false}
            eventOverlap
            selectable={!isMobile && !window.isCordovaApp}
            editable
            eventStartEditable
            eventDurationEditable
            slotEventOverlap={false}
            defaultDate={defaultDate}
            displayEventEnd={false}
            displayEventTime={false}
            weekends={showWeekends}
            timeZoneParam="UTC"
            timeZone={false}
            locale={locale}
            height="parent"
            eventAllow={eventAllow}
            eventOrder={eventOrder}
            defaultView={defaultView}
            now={clinicNow}
            views={viewsConfig}
            eventSources={[fetchSource, appointmentSource, workHoursSource]}
            businessHours={businessHours}
            plugins={plugins}
            eventRender={eventRenderer}
            select={handleSelect}
            eventDrop={handleEventDrop}
            eventResize={handleEventResize}
            dateClick={handleDateClick}
            eventClick={handleEventClick}
            selectMirror
            ref={ref => { api.current = ref && ref.getApi(); }}
          />

          {isLoading && !showProgressBar && (
            <div id="fc-loading">
              <Loading />
            </div>
          )}
        </div>
      </section>
    </div>

  );
};

// $FlowFixMe
export default React.memo(Calendar, isEqual);
