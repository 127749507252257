// @flow
import React from 'react';
import { connect } from 'react-redux';
import { get } from 'lodash';
import { SignUp as SignUpView } from '@views';
import type { AuthState, Dispatch } from '@types';
import { actions as authActions } from '../redux/modules/auth';

type Props = {
  auth: AuthState,
  dispatch: Function,
  isLoading: boolean,
};

type State = {
  inProgress: boolean,
};

class SignUp extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      inProgress: false,
    };
  }

  componentWillMount() {
    const { dispatch } = this.props;
    dispatch(authActions.resetSignUpState());
  }

  componentWillReceiveProps(nextProps: Props) {
    const alreadyInProgress = this.props.auth.inProgress;
    const nextInProgress = nextProps.auth.inProgress;
    if (alreadyInProgress !== nextInProgress) {
      this.setState({ inProgress: nextInProgress });
    }
  }

  render() {
    const { auth, isLoading } = this.props;
    const { inProgress } = this.state;

    return (
      <SignUpView
        authErrors={get(auth, ['errors'])}
        inProgress={inProgress}
        onSignupStep1={this.handleSignupStep1}
        isLoading={isLoading}
      />
    );
  }

  handleSignupStep1 = (email: string, password: string) => {
    this.props.dispatch(authActions.signupStep1({
      email, password, countryCode: window.countryCode,
    }));
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  isLoading: get(state, ['auth', 'inProgress']),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({ dispatch });

export default connect<any, any, any, any, any, any>(
  mapStateToProps,
  mapDispatchToProps,
)(SignUp);
