// @flow
import { get, omit } from 'lodash';
import moment from 'moment';
import { reportError, createCachedAction } from '@helpers/helpers';
import { Api } from '../../../helpers';
import { router } from '../../../routes/router';
import ExamsPrescriptionsTemplateService from '../../../services/ExamsPrescriptionsTemplateService';
import type
{
  Action,
  Dispatch,
  ContextState,
  ExamsPrescriptionsTemplateInput,
} from '../../../types';

// ------------------------------------
// Constants
// ------------------------------------
const EXAMS_PRESCRIPTIONS_TEMPLATES_LOADING = 'examsPrescriptionsTemplates.EXAMS_PRESCRIPTIONS_TEMPLATES_LOADING';
const EXAMS_PRESCRIPTIONS_TEMPLATES_LOADED = 'examsPrescriptionsTemplates.EXAMS_PRESCRIPTIONS_TEMPLATES_LOADED';
const EXAMS_PRESCRIPTIONS_TEMPLATES_LOADING_FAILED = 'examsPrescriptionsTemplates.EXAMS_PRESCRIPTIONS_TEMPLATES_LOADING_FAILED';

const EXAMS_PRESCRIPTIONS_TEMPLATE_CREATE_IN_PROGRESS = 'examsPrescriptionsTemplates.EXAMS_PRESCRIPTIONS_TEMPLATE_CREATE_IN_PROGRESS';
const EXAMS_PRESCRIPTIONS_TEMPLATE_CREATE_SUCCESS = 'examsPrescriptionsTemplates.EXAMS_PRESCRIPTIONS_TEMPLATE_CREATE_SUCCESS';
const EXAMS_PRESCRIPTIONS_TEMPLATE_CREATE_FAILED = 'examsPrescriptionsTemplates.EXAMS_PRESCRIPTIONS_TEMPLATE_CREATE_FAILED';

const EXAMS_PRESCRIPTIONS_TEMPLATE_UPDATE_IN_PROGRESS = 'examsPrescriptionsTemplates.EXAMS_PRESCRIPTIONS_TEMPLATE_UPDATE_IN_PROGRESS';
const EXAMS_PRESCRIPTIONS_TEMPLATE_UPDATE_SUCCESS = 'examsPrescriptionsTemplates.EXAMS_PRESCRIPTIONS_TEMPLATE_UPDATE_SUCCESS';
const EXAMS_PRESCRIPTIONS_TEMPLATE_UPDATE_FAILED = 'examsPrescriptionsTemplates.EXAMS_PRESCRIPTIONS_TEMPLATE_UPDATE_FAILED';

const EXAMS_PRESCRIPTIONS_TEMPLATE_DELETE_IN_PROGRESS = 'examsPrescriptionsTemplates.EXAMS_PRESCRIPTIONS_TEMPLATE_DELETE_IN_PROGRESS';
const EXAMS_PRESCRIPTIONS_TEMPLATE_DELETE_SUCCESS = 'examsPrescriptionsTemplates.EXAMS_PRESCRIPTIONS_TEMPLATE_DELETE_SUCCESS';
const EXAMS_PRESCRIPTIONS_TEMPLATE_DELETE_FAILED = 'examsPrescriptionsTemplates.EXAMS_PRESCRIPTIONS_TEMPLATE_DELETE_FAILED';

// ------------------------------------
// Actions
// ------------------------------------
// export const getExamsPrescriptionsTemplates = () => (
//   (dispatch: Dispatch) => {
//     dispatch({ type: EXAMS_PRESCRIPTIONS_TEMPLATES_LOADING });
//     Api.get(router.getApiRoute('getExamsPrescriptionsTemplates', null), {
//       success: (response) => {
//         dispatch({ type: EXAMS_PRESCRIPTIONS_TEMPLATES_LOADED, examsPrescriptionsTemplates: response.examsPrescriptionsTemplates });
//       },
//       error: (error) => {
//         dispatch({ type: EXAMS_PRESCRIPTIONS_TEMPLATES_LOADING_FAILED, error: { message: error.message } });
//         dispatch(reportError(error));
//       },
//     });
//   }
// );

export const getExamsPrescriptionsTemplates = (force?: boolean = false) => ((dispatch: Dispatch) => {
  dispatch(createCachedAction(
    EXAMS_PRESCRIPTIONS_TEMPLATES_LOADING, EXAMS_PRESCRIPTIONS_TEMPLATES_LOADED, EXAMS_PRESCRIPTIONS_TEMPLATES_LOADING_FAILED,
    12,
    () => Api.get(router.getApiRoute('getExamsPrescriptionsTemplates', null)),
    ['context', 'examsPrescriptionsTemplates', 'ttl'],
    (response: any) => ({
      examsPrescriptionsTemplates: response.examsPrescriptionsTemplates,
    }),
  )(force, {}));
});

export const createExamsPrescriptionsTemplate = (examsPrescriptionsTemplate: ExamsPrescriptionsTemplateInput) => (
  (dispatch: Dispatch) => {
    dispatch({ type: EXAMS_PRESCRIPTIONS_TEMPLATE_CREATE_IN_PROGRESS });
    Api.post(router.getApiRoute('createExamsPrescriptionsTemplate', null), {
      data: { examsPrescriptionsTemplate },
      success: (response) => {
        dispatch({ type: EXAMS_PRESCRIPTIONS_TEMPLATE_CREATE_SUCCESS, examsPrescriptionsTemplate: response.data.examsPrescriptionsTemplate });
      },
      error: (error) => {
        dispatch({ type: EXAMS_PRESCRIPTIONS_TEMPLATE_CREATE_FAILED, error: { message: error.message } });
        dispatch(reportError(error));
      },
    });
  }
);

export const updateExamsPrescriptionsTemplate = (id: number, examsPrescriptionsTemplate: ExamsPrescriptionsTemplateInput) => (
  (dispatch: Dispatch) => {
    dispatch({ type: EXAMS_PRESCRIPTIONS_TEMPLATE_UPDATE_IN_PROGRESS });
    Api.patch(router.getApiRoute('updateExamsPrescriptionsTemplate', { id }), {
      data: { examsPrescriptionsTemplate },
      success: (response) => {
        dispatch({ type: EXAMS_PRESCRIPTIONS_TEMPLATE_UPDATE_SUCCESS, examsPrescriptionsTemplate: response.examsPrescriptionsTemplate });
      },
      error: (error) => {
        dispatch({ type: EXAMS_PRESCRIPTIONS_TEMPLATE_UPDATE_FAILED, error: { message: error.message } });
        dispatch(reportError(error));
      },
    });
  }
);

export const deleteExamsPrescriptionsTemplate = (id: number) => (
  (dispatch: Dispatch) => {
    dispatch({ type: EXAMS_PRESCRIPTIONS_TEMPLATE_DELETE_IN_PROGRESS });
    Api.destroy(router.getApiRoute('deleteExamsPrescriptionsTemplate', { id }), {
      success: () => {
        dispatch({ type: EXAMS_PRESCRIPTIONS_TEMPLATE_DELETE_SUCCESS, deletedTemplateId: id });
      },
      error: (error) => {
        dispatch({ type: EXAMS_PRESCRIPTIONS_TEMPLATE_DELETE_FAILED, error: { message: error.message } });
        dispatch(reportError(error));
      },
    });
  }
);

export const examsPrescriptionsTemplateActions = {
  getExamsPrescriptionsTemplates,
  createExamsPrescriptionsTemplate,
  updateExamsPrescriptionsTemplate,
  deleteExamsPrescriptionsTemplate,
};

// ------------------------------------
// Reducer
// ------------------------------------
export default function examsPrescriptionsTemplatesReducer (state: ContextState, action: Action): ContextState {
  switch (action.type) {
    case EXAMS_PRESCRIPTIONS_TEMPLATES_LOADING:
      return state
        .setIn(['examsPrescriptionsTemplates', 'isLoading'], true)
        .setIn(['examsPrescriptionsTemplates', 'errors'], null);

    case EXAMS_PRESCRIPTIONS_TEMPLATES_LOADED:
      const normalized = ExamsPrescriptionsTemplateService.normalizeExamsPrescriptionsTemplates(action.examsPrescriptionsTemplates);
      return state
        .setIn(['examsPrescriptionsTemplates', 'isLoading'], false)
        .setIn(['examsPrescriptionsTemplates', 'errors'], null)
        .setIn(['examsPrescriptionsTemplates', 'ttl'], get(action, 'ttl', null))
        .setIn(['examsPrescriptionsTemplates', 'data'], normalized.examsPrescriptionTemplates)
        .setIn(['examsPrescriptionsTemplates', 'lastFetch'], moment().toISOString());

    case EXAMS_PRESCRIPTIONS_TEMPLATES_LOADING_FAILED:
      return state
        .setIn(['examsPrescriptionsTemplates', 'isLoading'], false)
        .setIn(['examsPrescriptionsTemplates', 'errors'], [action.error.message]);

    case EXAMS_PRESCRIPTIONS_TEMPLATE_CREATE_IN_PROGRESS:
      return state
        .setIn(['examsPrescriptionsTemplates', 'isSaving'], true)
        .setIn(['examsPrescriptionsTemplates', 'errors'], null);

    case EXAMS_PRESCRIPTIONS_TEMPLATE_CREATE_SUCCESS:
      return state
        .setIn(['examsPrescriptionsTemplates', 'isSaving'], false)
        .setIn(['examsPrescriptionsTemplates', 'errors'], null)
        .setIn(['examsPrescriptionsTemplates', 'data', action.examsPrescriptionsTemplate.id], action.examsPrescriptionsTemplate);

    case EXAMS_PRESCRIPTIONS_TEMPLATE_CREATE_FAILED:
      return state
        .setIn(['examsPrescriptionsTemplates', 'isSaving'], false)
        .setIn(['examsPrescriptionsTemplates', 'errors'], [action.error.message]);

    case EXAMS_PRESCRIPTIONS_TEMPLATE_UPDATE_IN_PROGRESS:
      return state
        .setIn(['examsPrescriptionsTemplates', 'isSaving'], true)
        .setIn(['examsPrescriptionsTemplates', 'errors'], null);

    case EXAMS_PRESCRIPTIONS_TEMPLATE_UPDATE_SUCCESS:
      return state
        .setIn(['examsPrescriptionsTemplates', 'isSaving'], false)
        .setIn(['examsPrescriptionsTemplates', 'errors'], null)
        .setIn(['examsPrescriptionsTemplates', 'data', action.examsPrescriptionsTemplate.id], action.examsPrescriptionsTemplate);

    case EXAMS_PRESCRIPTIONS_TEMPLATE_UPDATE_FAILED:
      return state
        .setIn(['examsPrescriptionsTemplates', 'isSaving'], false)
        .setIn(['examsPrescriptionsTemplates', 'errors'], [action.error.message]);

    case EXAMS_PRESCRIPTIONS_TEMPLATE_DELETE_IN_PROGRESS:
      return state
        .setIn(['examsPrescriptionsTemplates', 'isSaving'], true)
        .setIn(['examsPrescriptionsTemplates', 'errors'], null);

    case EXAMS_PRESCRIPTIONS_TEMPLATE_DELETE_SUCCESS:
      const deletedId = action.deletedTemplateId;
      const data = get(state, ['examsPrescriptionsTemplates', 'data'], {});
      return state
        .setIn(['examsPrescriptionsTemplates', 'isSaving'], false)
        .setIn(['examsPrescriptionsTemplates', 'errors'], null)
        .setIn(['examsPrescriptionsTemplates', 'data'], omit(data, deletedId.toString()));

    case EXAMS_PRESCRIPTIONS_TEMPLATE_DELETE_FAILED:
      return state
        .setIn(['examsPrescriptionsTemplates', 'isSaving'], false)
        .setIn(['examsPrescriptionsTemplates', 'errors'], [action.error.message]);

    default:
      return state;
  }
}
