import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Modal } from '@elements';
import Chart from 'react-apexcharts';
import { useTranslationNs } from '@helpers/helpers';
import './PatientIndicatorsGraphModal.scss';

import { heightSeries, weightSeries, imcSeries } from './mock';

type Mode = {
  type: 'height' | 'weight' | 'imc';
  interval: '0-2' | '2-5' | '5-19' | 'all';
}

const getFixedSeries = (mode: Mode, isBoy: boolean) => {
  const getSliceIndexes = () => {
    switch (mode.interval) {
      case '0-2': return { from: 0, to: 23 };
      case '2-5': return { from: 24, to: 59 };
      case '5-19': return { from: 59, to: undefined };
      default: return { from: 0, to: undefined };
    }
  };

  const getSeries = () => {
    switch (mode.type) {
      case 'height':
        return heightSeries;
      case 'weight':
        return weightSeries;
      default:
        return imcSeries;
    }
  };

  const formattedSeries = getSeries().map(({ name, boy, girl }) => {
    const { from, to } = getSliceIndexes();

    const data = (isBoy ? boy : girl).slice(from, to);

    return { name, data };
  });

  return formattedSeries;
};

const lineColors = {
  black: '#2D2F36',
  red: '#FA2C2F',
  orange: '#FF6E21',
  green: '#0B843B',
  patient: '#2A76B4',
};

const linesIndexes = {
  3: 0,
  2: 1,
  0: 2,
  '-2': 3,
  '-3': 4,
};

const imcLinesIndexes = {
  3: 0,
  2: 1,
  1: 2,
  0: 3,
  '-1': 4,
  '-2': 5,
  '-3': 6,
};

type GraphModalData = {
  mode: Mode,
  dataSet: any,
  patient: any,
  indicator: any,
};

type Props = {
  onCloseModal: Function,
  modalData: GraphModalData,
};

const PatientIndicatorsGraphModal = ({ onCloseModal, modalData }: Props) => {
  const [data, setData] = useState([]);
  const [_] = useTranslationNs('indicators');

  const { patient, mode, dataSet, indicator } = modalData;

  useEffect(() => {
    if (!modalData) return;

    const birthdate = moment(patient.birthdate);

    const _data = dataSet.map(measure => {
      const indicatorDate = moment(measure.date);

      const age = indicatorDate.diff(birthdate, 'months');

      return {
        y: measure.value ? Number(Number(measure.value).toFixed(2)) : null,
        x: age,
      };
    }).filter(({ y }) => Boolean(y));

    setData(_data);
  }, [dataSet, modalData, patient.birthdate]);

  const getYAxisTitle = () => {
    switch (mode.type) {
      case 'height':
        return _('label/heightCm');
      case 'imc':
        return _('label/imc');
      default:
        return _('label/weightKg');
    }
  };

  const isBoy = Boolean(patient.gender) && patient.gender === 'male';

  const getGraphicTitle = () => {
    let title = _('title/graphic');

    const { interval } = mode;

    if (isBoy) title += ` ${_('title/boy')}`;
    else title += ` ${_('title/girl')}`;

    switch (interval) {
      case '0-2':
        return title + ` (${_('title/0-2')})`;
        break;
      case '2-5':
        return title + ` (${_('title/2-5')})`;
        break;
      default:
        return title + ` (${_('title/5-19')})`;
        break;
    }
  };

  const series = [
    ...getFixedSeries(modalData.mode, isBoy),
    { name: _('label/patient'), data },
  ];

  const colors = Array.from({ length: series.length }, (__, index) => {
    if (mode.type === 'imc') {
      switch (index) {
        case imcLinesIndexes[3]: return lineColors.black;
        case imcLinesIndexes[2]: return lineColors.red;
        case imcLinesIndexes[1]: return lineColors.orange;
        case imcLinesIndexes[0]: return lineColors.green;
        case imcLinesIndexes['-1']: return lineColors.orange;
        case imcLinesIndexes['-2']: return lineColors.red;
        case imcLinesIndexes['-3']: return lineColors.black;
        default: return lineColors.patient;
      }
    }

    switch (index) {
      case linesIndexes[3]: return lineColors.black;
      case linesIndexes[2]: return lineColors.red;
      case linesIndexes[0]: return lineColors.green;
      case linesIndexes['-2']: return lineColors.red;
      case linesIndexes['-3']: return lineColors.black;
      default: return lineColors.patient;
    }
  });

  const linesWidth = Array.from({ length: series.length }, (__, index) => {
    const lastSeriesItem = series.length - 1;
    if ((index === lastSeriesItem) && data.length === 1) return 5;

    return 1.5;
  });

  const options = {
    title: {
      text: getGraphicTitle(),
      align: 'center',
      style: {
        fontSize: '16px',
        color: '#25363E',
      },
    },
    chart: {
      // height: 160,
      type: 'line',
    //   zoom: {
    //     enabled: false,
    //   },
    //   sparkline: {
    //     enabled: false,
    //   },
    //   toolbar: {
    //     show: false,
    //   },
    },
    colors,
    // dataLabels: {
    //   enabled: false,
    // },
    stroke: {
      // show: true,
      // curve: 'straight',
      width: linesWidth,
    },
    grid: {
      show: true,
      // borderColor: '#2A76B4',
      xaxis: {
        lines: {
          show: true,
        },
      },
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
    tooltip: {
      enabled: true,
      x: {
        show: false,
      },
      y: {
        show: false,
      },
    },
    yaxis: {
      title: {
        text: getYAxisTitle(),
      },
      labels: {
        formatter: (value) => {
          if (!value) return null;

          return `${value.toFixed(2).replace('.', ',')} ${indicator.unit}`;
        },
      },
    },
    xaxis: {
      type: 'number',
      title: {
        text: _('label/ageMonths'),
      },
    },
    // legend: {
    //   show: true,
    //   position: 'bottom',
    //   horizontalAlign: 'center',
    //   onItemHover: {
    //     highlightDataSeries: true,
    //   },
    // },
  };




  return (
    <Modal id="patientIndicatorsGraphModal" onClose={onCloseModal} className="desktop">
      <div className="chartBox">
        <Chart
          options={options}
          series={series}
        />
      </div>
    </Modal>
  );
};


export default React.memo(PatientIndicatorsGraphModal);

